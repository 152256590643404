import { SelectOption } from 'Types/common'

import { ClassifierSource } from 'constants/gatewayGraphQL'

import { i18n } from 'i18n'

export function classifierSourceToOption(
  source: ClassifierSource,
): SelectOption<ClassifierSource> {
  return {
    label: i18n(`settings.classifiers.sourceAttributes.${source}`),
    value: source,
  }
}

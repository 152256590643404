import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { border, layout, space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { when } from 'components/ui/__v3__/__utils__/styled-system'

import { Flex } from '../Grid'

const Divider = styled(Flex).attrs({ as: 'span' })`
  --border: thin solid ${themeGet('divider.color')};

  width: 100%;
  border-bottom: var(--border);

  ${when(
    'horizontal',
    css`
      width: 0;
      border-bottom: none;

      height: 100%;
      border-left: var(--border);
    `,
  )};

  ${layout};
  ${border};
  ${space};
`

Divider.defaultProps = {
  horizontal: false,
}

Divider.propTypes = {
  ...Flex.propTypes,
  horizontal: PropTypes.bool,
}

export default Divider

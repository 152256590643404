import { createFields, createReducerHandlers } from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  CLEAR_LEAVES_SUMMARY,
  LOAD_LEAVES_SUMMARY,
} from 'store/actions/employees/leavesSummary'

export const LEAVES_SUMMARY_FIELD = 'employeeLeavesSummary'
export const LEAVES_SUMMARY_TYPE = 'employeeLeaveSummary'

const initialState = Immutable({
  ...createFields(LEAVES_SUMMARY_TYPE, LEAVES_SUMMARY_FIELD),
})

const handlers = {
  ...createReducerHandlers(LEAVES_SUMMARY_TYPE, LOAD_LEAVES_SUMMARY, {
    withReplace: true,
    mapToKey: LEAVES_SUMMARY_FIELD,
  }),

  [CLEAR_LEAVES_SUMMARY]: () => initialState,

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

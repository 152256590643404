import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'

export const Text = styled.div<MarginProps>`
  width: 100%;
  color: ${({ theme }) => theme.layout.text};
  font-size: 13px;
  font-weight: 500;
  text-align: center;

  ${margin}
`

import get from 'lodash/get'

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

export const LOAD_POSTS = createAsyncAction('news/LOAD')
export const LOAD_MORE_POSTS = createAsyncAction('news/LOAD_MORE')
export const ADD_POST = createAsyncAction('news/ADD')
export const EDIT_POST = createAsyncAction('news/EDIT')
export const DELETE_POST = createAsyncAction('news/DELETE')
export const ADD_COMMENT = createAsyncAction('news/ADD_COMMENT')
export const EDIT_COMMENT = createAsyncAction('news/EDIT_COMMENT')
export const DELETE_COMMENT = createAsyncAction('news/DELETE_COMMENT')
export const SHOW_COMMENTS = createAsyncAction('news/SHOW_COMMENTS')
export const HIDE_COMMENTS = createAsyncAction('news/HIDE_COMMENTS')
export const UPLOAD_FILE = createAsyncAction('news/UPLOAD_FILE')
export const DELETE_FILE = createAsyncAction('news/DELETE_FILE')
export const REMOVE_LOCAL_FILE = 'news/REMOVE_LOCAL_FILE'
export const RECEIVE_POST = `news/RECEIVE_POST`
export const RECEIVE_DELETED_POST = 'news/RECEIVE_DELETED_POST'
export const ADD_REACTION = {
  posts: createAsyncAction('news/ADD_POST_REACTION'),
  comments: createAsyncAction('news/ADD_COMMENT_REACTION'),
}
export const DELETE_REACTION = {
  posts: createAsyncAction('news/DELETE_POST_REACTION'),
  comments: createAsyncAction('news/DELETE_COMMENT_REACTION'),
}

const includes = [
  'user.profile',
  'comments.user.profile',
  'comments.emojis.user',
  'comments.post',
  'files',
  'emojis.user',
  'branch.images',
]

// POSTS
export const loadPosts = branchId => {
  return apiCall({
    endpoint: `/branches/${branchId}/relationships/posts`,
    query: {
      'page[limit]': 10,
      include: includes.join(),
    },
    types: LOAD_POSTS,
  })
}

export const loadMorePosts = ({ pageLastId, branchId } = {}) => {
  const lastId = pageLastId ? { 'page[last_id]': pageLastId } : {}

  return apiCall({
    endpoint: `/branches/${branchId}/relationships/posts`,
    query: {
      'page[limit]': 10,
      ...lastId,
      include: includes.join(),
    },
    types: LOAD_MORE_POSTS,
  })
}

export const addPost = (post, fileId = null, branchId) => {
  const fileRelations = fileId
    ? {
        files: {
          data: [
            {
              type: 'files',
              id: fileId,
            },
          ],
        },
      }
    : {}

  return apiCall({
    endpoint: '/posts',
    method: 'POST',
    query: {
      include: includes.join(),
      data: {
        type: 'posts',
        attributes: post,
        relationships: {
          branch: {
            data: {
              id: branchId,
              type: 'branches',
            },
          },
          ...fileRelations,
        },
      },
    },
    types: ADD_POST,
    payload: {
      relationId: branchId,
    },
  })
}

export const editPost = ({ id, content, byBranch }, fileId = null) => {
  return apiCall({
    endpoint: `/posts/${id}`,
    method: 'PATCH',
    query: {
      include: includes.join(),
      data: {
        type: 'posts',
        attributes: { content, byBranch },
        ...(fileId
          ? {
              relationships: {
                files: {
                  data: [
                    {
                      type: 'files',
                      id: fileId,
                    },
                  ],
                },
              },
            }
          : {}),
      },
    },
    types: EDIT_POST,
  })
}

export const deletePost = postId => {
  return apiCall({
    endpoint: `/posts/${postId}`,
    method: 'DELETE',
    types: DELETE_POST,
    payload: {
      deletedId: postId,
    },
  })
}

export const deleteFile = (
  fileId,
  isUpdate = false,
  updatingPost = null,
  { postFile, localPostFile } = {},
) => {
  return apiCall({
    endpoint: `/files/${fileId}`,
    method: 'DELETE',
    types: DELETE_FILE,
    payload: {
      isUpdate,
      updatingPost,
      ...(postFile && localPostFile
        ? {
            uploadingFile: {
              postFile,
              localPostFile,
            },
          }
        : {}),
    },
  })
}

// COMMENTS
export const addComment = ({ content, postId }) => {
  return apiCall({
    endpoint: '/comments',
    method: 'POST',
    query: {
      data: {
        type: 'comments',
        attributes: {
          content,
        },
        relationships: {
          post: {
            data: {
              type: 'posts',
              id: postId,
            },
          },
        },
      },
      include: 'user.profile,post',
    },
    payload: {
      relationId: postId,
    },
    types: ADD_COMMENT,
  })
}

export const editComment = ({ id, content }) => {
  return apiCall({
    endpoint: `/comments/${id}`,
    method: 'PATCH',
    query: {
      data: {
        attributes: { content },
      },
    },
    types: EDIT_COMMENT,
  })
}

export const deleteComment = comment => {
  return apiCall({
    endpoint: `/comments/${comment.id}`,
    method: 'DELETE',
    types: DELETE_COMMENT,
    payload: {
      relationId: get(comment, 'post.id'),
      deletedId: get(comment, 'id'),
    },
  })
}

export const showComments = post => ({
  type: SHOW_COMMENTS.SUCCESS,
  payload: { post },
})

export const uploadFile = (
  file,
  localFile,
  post,
  isEditing = false,
  branchId,
) => {
  return apiCall({
    method: 'POST',
    endpoint: '/files',
    file,
    types: UPLOAD_FILE,
    payload: {
      files: {
        [localFile.id]: localFile,
      },
      isEditing,
      post,
      branchId,
    },
  })
}

export const hideComments = post => ({
  type: HIDE_COMMENTS.SUCCESS,
  payload: { post },
})

export const removeLocalFile = fileId => ({
  payload: {
    fileId,
  },
  type: REMOVE_LOCAL_FILE,
})

export const receivePost = payload => ({
  type: RECEIVE_POST,
  payload,
  meta: {
    isSuccess: true,
  },
})

export const receiveDeletedPost = payload => ({
  type: RECEIVE_DELETED_POST,
  payload,
  meta: {
    isSuccess: true,
  },
})

export const addReaction = kind => dispatch => ({ reaction, id }) => {
  return dispatch(
    apiCall({
      endpoint: `/${kind}/${id}/emojis`,
      method: 'POST',
      query: {
        data: {
          type: 'emojis',
          attributes: {
            name: reaction,
          },
        },
        include: 'user',
      },
      payload: {
        relationId: id,
      },
      types: ADD_REACTION[kind],
    }),
  )
}

export const deleteReaction = kind => dispatch => ({ reactionId, id }) => {
  return dispatch(
    apiCall({
      endpoint: `/${kind}/${id}/emojis/${reactionId}`,
      method: 'DELETE',
      payload: {
        relationId: id,
        deletedId: reactionId,
      },
      types: DELETE_REACTION[kind],
    }),
  )
}

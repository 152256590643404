import build from 'redux-object'

import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'

export const createReducer = (initialState, handlers) => (
  state = initialState,
  action,
) => (handlers[action.type] ? handlers[action.type](state, action) : state)

export const createAsyncAction = type => ({
  REQUEST: `${type}.REQUEST`,
  SUCCESS: `${type}.SUCCESS`,
  FAILURE: `${type}.FAILURE`,
})

export const mergeCustomizer = (objValue, srcValue) =>
  isArray(objValue) ? srcValue : undefined

export const denormalize = (entities, type, id) =>
  build(entities, type, id, { eager: true })

export const getFirstEntity = entities => {
  const entityId = Object.keys(entities)[0]
  return entities[entityId]
}

export const createRelationAddHandler = (type, withReplace = false) => (
  state,
  { payload },
) => {
  const dataType = get(payload, `data.${type}`)
  const relationId = get(payload, 'relationId')

  if (isEmpty(dataType)) return state

  const existedRelations = get(
    state,
    `${relationId}.relationships.${type}.data`,
    [],
  )
  const createdRelations = Object.values(dataType).map(relation => ({
    id: relation.id,
    type,
  }))

  return merge({}, state, {
    [relationId]: {
      relationships: {
        [type]: {
          data: withReplace
            ? [...createdRelations]
            : [...existedRelations, ...createdRelations],
        },
      },
    },
  })
}

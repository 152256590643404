import { all, fork } from 'redux-saga/effects'

import branches from './company/branches'
import formTemplates from './company/formTemplates'
import schedule from './company/schedule'
import tasks from './company/tasks'
import findCover from './employeeApp/findCover'
import openClock from './employeeApp/openClock'
import employeesSchedules from './employeeApp/schedules'
import timeClock from './employeeApp/timeClock'
import timeLog from './employeeApp/timeLog'
import tradeShift from './employeeApp/tradeShift'
import annualPerformance from './employees/annualPerformance'
import CNESST from './employees/CNESST'
import disciplinary from './employees/disciplinary'
import employees from './employees/employees'
import leavesSummary from './employees/leavesSummary'
import salaryReview from './employees/salaryReview'
import schedules from './employees/schedules'
import shifts from './employees/shifts'
import workAgreements from './employees/workAgreements'
import branchListener from './listeners/branch'
import groupChatListener from './listeners/groupChat'
import notificationsListener from './listeners/notifications'
import userListener from './listeners/user'
import employerAppAvailability from './availability'
import groupChat from './groupChat'
import locations from './locations'
import news from './news'
import notifications from './notifications'
import payroll from './payroll'
import reportBuilder from './reportBuilder'
import restoreSession from './restoreSession'
import timeoff from './timeoff'
import users from './users'
import viewer from './viewer'

export default function* root() {
  yield all([
    fork(restoreSession),
    fork(notifications),
    fork(groupChat),
    fork(news),
    fork(viewer),
    fork(users),

    fork(branches),

    fork(shifts),
    fork(employees),
    fork(schedules),
    fork(payroll),

    fork(disciplinary),
    fork(CNESST),
    fork(salaryReview),
    fork(annualPerformance),
    fork(workAgreements),

    fork(employeesSchedules),
    fork(timeClock),
    fork(timeLog),
    fork(tradeShift),
    fork(findCover),

    fork(branchListener),
    fork(notificationsListener),
    fork(groupChatListener),

    fork(userListener),

    fork(openClock),

    fork(locations),
    fork(employerAppAvailability),
    fork(schedule),

    fork(timeoff),
    fork(leavesSummary),

    fork(tasks),

    fork(reportBuilder),
    fork(formTemplates),
  ])
}

import { createFields, createReducerHandlers } from 'rapidux'
import Immutable from 'seamless-immutable'

import { KIND } from 'constants/availability'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { CLEAR_DAY, LOAD, LOAD_DAY } from 'store/actions/company/availabilities'

export const COMPANY_AVAILABILITIES_SHORT_SUMMARY_TYPE =
  'availabilitiesShortSummary'

export const COMPANY_AVAILABILITIES_EXPANDED_SUMMARY_TYPE =
  'availabilitiesExpandedSummary'

const initialState = Immutable({
  ...createFields(COMPANY_AVAILABILITIES_SHORT_SUMMARY_TYPE, KIND.SHORT),

  ...createFields(
    COMPANY_AVAILABILITIES_EXPANDED_SUMMARY_TYPE,
    KIND.EXPANDED,
    true,
  ),

  publishing: false,
})

const handlers = {
  ...createReducerHandlers(COMPANY_AVAILABILITIES_SHORT_SUMMARY_TYPE, LOAD, {
    withReplace: true,
    mapToKey: KIND.SHORT,
  }),

  ...createReducerHandlers(
    COMPANY_AVAILABILITIES_EXPANDED_SUMMARY_TYPE,
    LOAD_DAY,
    {
      mapToKey: KIND.EXPANDED,
      withReplace: true,
      singular: true,
    },
  ),

  [CLEAR_DAY]: state =>
    state.merge(
      createFields(
        COMPANY_AVAILABILITIES_EXPANDED_SUMMARY_TYPE,
        KIND.EXPANDED,
        true,
      ),
    ),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

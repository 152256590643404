import {
  createRelationAddHandler,
  createRelationDeleteHandler,
} from 'redux-json-api-handlers'

import filter from 'lodash/filter'
import get from 'lodash/get'
import merge from 'lodash/merge'
import mergeWith from 'lodash/mergeWith'
import reduce from 'lodash/reduce'
import set from 'lodash/set'

import { concatArrayMerger } from 'helpers/mergers'
import {
  createReducer,
  createRelationAddHandler as createRelationAddHandlerWithReplace,
  getFirstEntity,
} from 'helpers/redux'

import { CREATE_TIME_OFF, DELETE_TIME_OFF } from 'store/actions/common/timeoff'
import { CREATE_SHIFT, DELETE_SCHEDULES } from 'store/actions/company/schedule'
import {
  ADD_EMPLOYEES_JOB_TAGS,
  CREATE_ROLE,
  REMOVE_POSITION,
} from 'store/actions/employees/employees'
import { LOAD_NOTIFICATION_TIMEOFF } from 'store/actions/notifications'

const initialState = {}

const handlers = {
  [ADD_EMPLOYEES_JOB_TAGS.SUCCESS]: createRelationAddHandler('jobsEmployees'),

  [LOAD_NOTIFICATION_TIMEOFF.SUCCESS]: (state, { payload }) => {
    const { data } = payload
    const { id: relationId } = getFirstEntity(data.employees)
    const { id, type } = getFirstEntity(data.timeoffs)

    return merge({}, state, {
      [relationId]: {
        relationships: {
          timeoffs: {
            data: [
              ...get(
                state,
                [relationId, 'relationships', 'timeoffs', 'data'],
                [],
              ),
              { id, type },
            ],
          },
        },
      },
    })
  },

  [CREATE_SHIFT.SUCCESS]: (state, { payload }) => {
    return mergeWith(
      {},
      state,
      reduce(
        get(payload, ['data', 'schedules']),
        (acc, schedule) => {
          const employeeId = get(schedule, [
            'relationships',
            'employee',
            'data',
            'id',
          ])
          if (!employeeId) return acc

          const type = get(schedule, 'type')
          const path = [employeeId, 'relationships', type, 'data']

          if (!get(acc, path)) {
            set(acc, path, [])
          }

          acc[employeeId].relationships[type].data.push({
            id: get(schedule, 'id'),
            type: get(schedule, 'type'),
          })

          return acc
        },
        {},
      ),
      concatArrayMerger,
    )
  },

  [CREATE_ROLE.SUCCESS]: createRelationAddHandlerWithReplace(
    'jobsEmployees',
    true,
  ),

  [CREATE_TIME_OFF.SUCCESS]: createRelationAddHandler('timeoffs', 'employee'),

  [DELETE_TIME_OFF.SUCCESS]: createRelationDeleteHandler(
    'timeoffs',
    'employee',
  ),

  [DELETE_SCHEDULES.SUCCESS]: (state, { payload }) =>
    reduce(
      get(payload, ['employeeSchedules']),
      (acc, schedules, employeeId) => {
        if (!acc[employeeId]) return acc

        acc[employeeId].relationships.schedules.data = filter(
          acc[employeeId].relationships.schedules.data,
          schedule => !schedules.includes(schedule.id),
        )

        return acc
      },
      state,
    ),

  [REMOVE_POSITION.SUCCESS]: createRelationDeleteHandler(
    'jobsEmployees',
    'employee',
  ),
}

export default createReducer(initialState, handlers)

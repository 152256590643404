import { useApolloMutation } from 'API/services/Apollo'

import { DeleteRulesetMutation } from './GraphQL'

export function useDeleteRuleset() {
  const [deleteRulesetInner, { loading }] = useApolloMutation<
    MutationData<'deleteRuleset'>,
    Gateway.MutationDeleteRulesetArgs
  >(DeleteRulesetMutation, {
    translationKey: 'ruleset.toasts.delete',
  })

  const deleteRuleset = async ({ id }: Gateway.MutationDeleteRulesetArgs) => {
    return deleteRulesetInner({
      variables: {
        id,
      },
    })
  }

  return { deleteRuleset, deleting: loading }
}

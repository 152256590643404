import { useMemo } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import { SelectOption } from 'Types/common'

import { AldoPayrollRowSortingField } from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

import { AldoPayrollRowsByOffsetQuery } from './GraphQL'

export type AldoPayrollRowsByOffsetArgs = {
  paging: Gateway.QueryAldoPayrollRowsByOffsetArgs['paging']
  // Note: We need to have some order on how we pass form or state args to API calls
  filters: {
    period: { from: string; to: string }
    changeType?: Array<SelectOption<Gateway.AldoPayrollRowChangeType>>
    targetSystem?: SelectOption<Gateway.AldoPayrollRowTargetSystem>
    employee?: SelectOption
    bankCode?: string
    locationExternalId?: string
    wageType?: string
  }
  sorting: {
    [Gateway.AldoPayrollRowSortingField.StartDate]: Gateway.SortingDirection
  }
}

export function useAldoPayrollRowsByOffset({
  filters,
  sorting,
  paging,
}: AldoPayrollRowsByOffsetArgs) {
  const {
    period,
    changeType,
    employee,
    bankCode,
    locationExternalId,
    wageType,
    targetSystem,
  } = filters
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'aldoPayrollRowsByOffset'>,
    Gateway.QueryAldoPayrollRowsByOffsetArgs
  >(AldoPayrollRowsByOffsetQuery, {
    variables: {
      filter: {
        bankCode: bankCode ? Utils.GraphQL.ilikeFilter(bankCode) : undefined,
        wageType: wageType ? Utils.GraphQL.ilikeFilter(wageType) : undefined,
        employeeId: employee
          ? Utils.GraphQL.eqFilter(employee.value)
          : undefined,
        locationExternalId: locationExternalId
          ? Utils.GraphQL.ilikeFilter(locationExternalId)
          : undefined,
        changeType:
          changeType && changeType.length > 0
            ? Utils.GraphQL.inFilter(changeType.map(item => item.value))
            : undefined,
        targetSystem: targetSystem
          ? Utils.GraphQL.eqFilter(targetSystem.value)
          : undefined,
        startDate: Utils.GraphQL.betweenFilter(period),
      },
      sorting: [
        {
          field: AldoPayrollRowSortingField.StartDate,
          direction: sorting[AldoPayrollRowSortingField.StartDate],
        },
      ],
      paging,
    },
    fetchPolicy: 'network-only',
  })

  const payrollRows = useMemo(() => data?.aldoPayrollRowsByOffset?.rows ?? [], [
    data?.aldoPayrollRowsByOffset?.rows,
  ])

  return {
    payrollRows,
    loading,
    refetch,
    total: data?.aldoPayrollRowsByOffset?.pageInfo.total ?? 0,
  }
}

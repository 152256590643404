import StyledPropTypes from '@styled-system/prop-types'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import {
  flexbox,
  layout,
  margin,
  position,
  shadow,
  space,
  typography,
} from 'styled-system'

import { either, when } from 'components/ui/__v3__/__utils__/styled-system'

import Loading from './Loading'

const Button = styled.button`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  appearance: none;
  outline: none;
  cursor: ${either('help', 'help', 'pointer')};
  user-select: none;
  white-space: nowrap;
  text-decoration: none;

  border: none;
  border-radius: ${mapToTheme('buttons.border.radius')}px;

  height: ${mapToTheme('buttons.height')}px;
  padding: ${mapToTheme('buttons.padding')};

  font-family: inherit;
  font-size: ${mapToTheme('buttons.fontSize')}px;
  font-weight: ${mapToTheme('buttons.fontWeight')};

  color: ${mapToTheme('buttons.color')};
  background-color: ${mapToTheme('buttons.backgroundColor')};

  transition: all 0.17s ease-in-out;

  &:hover {
    border-color: ${mapToTheme('buttons.hover.border.color')};
    background-color: ${mapToTheme('buttons.hover.backgroundColor')};
  }

  &:active {
    border-color: ${mapToTheme('buttons.active.border.color')};
    background-color: ${mapToTheme('buttons.active.backgroundColor')};
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    border-color: ${mapToTheme('buttons.disabled.border.color')};
    background-color: ${mapToTheme('buttons.disabled.backgroundColor')};
  }

  ${when(
    'hideLong',
    css`
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
  )}

  ${when(
    'icon',
    css`
      padding: 0;
      width: ${mapToTheme('buttons.height')}px;
      color: ${mapToTheme('buttons.icon.color')};
      border-style: solid;
      border-width: 1px;
      border-color: ${mapToTheme('buttons.icon.border')};
      background-color: ${mapToTheme('buttons.icon.backgroundColor')};

      &:hover {
        color: ${mapToTheme('buttons.icon.hover.color')};
        border-color: ${mapToTheme('buttons.icon.hover.border')};
        background-color: ${mapToTheme('buttons.icon.hover.backgroundColor')};
      }

      &:active {
        color: ${mapToTheme('buttons.icon.active')};
      }

      &:disabled {
        background-color: ${mapToTheme(
          'buttons.icon.disabled.backgroundColor',
        )};
        color: ${mapToTheme('buttons.icon.disabled.color')};
      }
    `,
  )};

  ${when(
    'small',
    css`
      line-height: 0;
    `,
  )};

  ${when(
    'link',
    css`
      color: ${mapToTheme('links.color')};
      background: none !important;
      border: none;
      padding: 0;
      font-weight: normal;
      height: initial;

      &:hover {
        color: ${mapToTheme('links.hover')};
        text-decoration: underline;
      }

      &:active {
        color: ${mapToTheme('links.active')};
      }

      &:disabled {
        color: ${mapToTheme('links.disabled')};
      }
    `,
  )};

  ${layout};
  ${space};
  ${typography};
  ${flexbox};
  ${position};
  ${shadow};
  ${margin};
`

Button.Loading = Loading

Button.propTypes = {
  ...StyledPropTypes.layout,
  ...StyledPropTypes.space,
  ...StyledPropTypes.typography,
  ...StyledPropTypes.flexbox,
  ...StyledPropTypes.position,
  ...StyledPropTypes.shadow,
  big: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  help: PropTypes.bool,
  hideLong: PropTypes.bool,
  icon: PropTypes.bool,
  secondary: PropTypes.bool,
  small: PropTypes.bool,
  squared: PropTypes.bool,
  success: PropTypes.bool,
  xSmall: PropTypes.bool,
}

Button.defaultProps = {
  big: false,
  disabled: false,
  error: false,
  help: false,
  hideLong: false,
  icon: false,
  small: false,
  secondary: false,
  squared: false,
  success: false,
  xSmall: false,
}

export default Button

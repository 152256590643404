import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import {
  clearLocations,
  initLocations,
  loadLocations,
} from 'store/actions/areas'
import { getInitialLocations } from 'store/selectors/areas'

import LocationsPicker from './LocationsPicker'

const selectors = (state, props) => {
  return createStructuredSelector({
    initial: getInitialLocations,
  })(state, props)
}

const actions = {
  onInit: initLocations,
  onLoad: loadLocations,
  onClear: clearLocations,
}

export default connect(selectors, actions)(LocationsPicker)

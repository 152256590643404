import styled from 'styled-components'

export const Text = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.layout.text};
  font-size: 14px;
`

export const List = styled.ul`
  padding: 0;
  margin: 16px 0;
  list-style-position: inside;

  li {
    margin-bottom: 5px;
  }
`

import ApproveTimeEntriesMutation from './approveTimeEntries.graphql'
import CreateTimeEntriesMutation from './createTimeEntries.graphql'
import CreateUpdateTimeEntriesMutation from './createUpdateTimeEntries.graphql'
import DeleteTimeEntriesMutation from './deleteTimeEntries.graphql'
import DiscardTimeEntriesMutation from './discardTimeEntries.graphql'
import RestoreTimeEntriesMutation from './restoreTimeEntries.graphql'
import UpdateTimeEntriesMutation from './updateTimeEntries.graphql'

export {
  ApproveTimeEntriesMutation,
  CreateTimeEntriesMutation,
  CreateUpdateTimeEntriesMutation,
  DeleteTimeEntriesMutation,
  DiscardTimeEntriesMutation,
  RestoreTimeEntriesMutation,
  UpdateTimeEntriesMutation,
}

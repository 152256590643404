import React from 'react'

import { FiCopy as CopyIcon } from 'react-icons/fi'

import { ButtonProps, IconButton } from 'components/ui/__v3__/Button'

type Props = {
  onCopy: () => void
} & ButtonProps

export function Copy({ onCopy, ...rest }: Props) {
  return (
    <IconButton {...rest} secondary onClick={onCopy}>
      <CopyIcon />
    </IconButton>
  )
}

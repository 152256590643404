import { Gender as JsonApiGender } from 'constants/jsonAPI'
import { Gender as AppGender } from 'constants/user'

export function jsonApiGenderToApp(gender: JsonApi.Gender): App.Gender {
  if (gender === JsonApiGender.Male) return AppGender.Male
  return AppGender.Female
}

export function appGenderToJsonApi(gender: App.Gender): JsonApi.Gender {
  if (gender === AppGender.Male) return JsonApiGender.Male
  return JsonApiGender.Female
}

import { useCallback, useState } from 'react'

import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus'
import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import {
  ShiftJobsByCursorWithSchedulesQuery,
  ShiftJobsByCursorWithSchedulesQueryData,
} from './GraphQL'
import { ShiftJobFilter } from './types'

export function useShiftJobsByCursorWithSchedules({
  page,
  filter,
}: {
  page: CursorPage
  filter: ShiftJobFilter
}) {
  const [enabled, setEnabled] = useState(false)

  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, refetch, fetchMore, networkStatus } = useApolloQuery<
    ShiftJobsByCursorWithSchedulesQueryData,
    Gateway.QueryShiftJobsByCursorArgs
  >(ShiftJobsByCursorWithSchedulesQuery, {
    fetchPolicy: 'network-only',
    skip: !enabled,

    variables: {
      filter: { shiftId: { eq: filter.shiftId } },
      paging: cursorPageToGraphqlPaging(page),
    },

    onCompleted(data) {
      const newPageMeta = data.shiftJobsByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const shiftJobsLoadMore = useCallback(async () => {
    await fetchMore({
      variables: {
        paging: {
          limit: page.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })
  }, [currentPageMeta.endCursor, fetchMore, page.size])

  const shiftJobsLoading = isNetworkRequestInFlight(networkStatus)
  const shiftJobs =
    data?.shiftJobsByCursor?.edges?.map(({ node }) => node) ?? []

  return {
    shiftJobs,
    shiftJobsLoading,
    shiftJobsRefetch: refetch,
    shiftJobsLoadMore,
    shiftJobsEnable: setEnabled,
    shiftJobsPageInfo: currentPageMeta,
  }
}

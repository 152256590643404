import { useApolloQuery } from 'API/services/Apollo'

import { EffectiveEmployeeWorkWeekQuery } from './GraphQL'

type Props = {
  employeeCoreId: number
}

export function useWorkWeekByEmployee({ employeeCoreId }: Props) {
  const { data, loading, error } = useApolloQuery<
    QueryData<'effectiveEmployeeWorkWeek'>,
    Gateway.QueryEffectiveEmployeeWorkWeekArgs
  >(EffectiveEmployeeWorkWeekQuery, {
    variables: { employeeCoreId },
  })

  return {
    effectiveWorkWeek: data?.effectiveEmployeeWorkWeek,
    loading,
    error,
  }
}

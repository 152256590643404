export const zIndices = {
  planeLower: 4,

  planeUpper: 5,

  stickyHeader: 10,

  modal: 500,

  dropdown: 1500,

  modalFullScreen: 2000,

  popup: 99999,
}

import { SelectOptionWithId } from 'Types/common'

export function integrationToOption(
  integration: Gateway.Integration,
): SelectOptionWithId {
  return {
    id: integration.id,
    value: integration.id,
    label: integration.name,
  }
}

import { FiCalendar, FiChevronLeft, FiChevronRight } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { margin, space, width } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { either } from 'components/ui/__v3__/__utils__/styled-system'

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  border: 1px solid ${themeGet('layout.border')};
  border-radius: 4px;
  cursor: ${either('disabled', 'default', 'pointer')};
  color: ${either(
    'disabled',
    themeGet('layout.lightText'),
    themeGet('layout.text'),
  )};
  svg {
    stroke: ${either(
      'disabled',
      themeGet('layout.lightText'),
      themeGet('layout.primary'),
    )};
  }

  background-color: ${either(
    'disabled',
    themeGet('input.bg.disabled'),
    themeGet('input.bg.default'),
  )};

  ${width};
  ${space};
`

const angleWrapperLeftCss = ({ left }) =>
  left &&
  css`
    border-right: 1px solid ${themeGet('layout.lightText')};
  `

const angleWrapperRightCss = ({ right }) =>
  right &&
  css`
    border-left: 1px solid ${themeGet('layout.lightText')};
  `

export const AngleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 100%;

  cursor: ${either('disabled', 'default', 'pointer')};
  color: ${either(
    'disabled',
    themeGet('layout.lightText'),
    themeGet('layout.text'),
  )};

  background-color: ${either(
    'disabled',
    themeGet('input.bg.disabled'),
    themeGet('input.bg.default'),
  )};

  ${angleWrapperLeftCss};
  ${angleWrapperRightCss};
`

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 8px;
  user-select: none;
`

export const Content = styled.div`
  display: flex;
  align-items: center;

  ${margin};
`

export const AngleLeft = styled(FiChevronLeft).attrs({ size: 16 })`
  color: ${themeGet('layout.primary')};
`

export const AngleRight = styled(FiChevronRight).attrs({ size: 16 })`
  color: ${themeGet('layout.primary')};
`

export const CalendarIcon = styled(FiCalendar)`
  stroke: ${themeGet('layout.primary')};
`

import { useCallback, useEffect, useRef, useState } from 'react'

import { useApolloClient, WatchQueryFetchPolicy } from '@apollo/client'
import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus'
import { updatePulledFilesByCursorCache } from 'API/PulledFile/GraphQL/Updaters/PulledFilesByCursor'
import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { compact } from 'lodash'

import Utils from 'services/Utils'

import { PulledFilesByCursorQuery } from './GraphQL'

export function usePulledFilesByCursor({
  page,
  filter,
  sorting,
  enabled = true,
  fetchPolicy = 'network-only',
  initialCacheData,
}: {
  page: CursorPage
  filter: { integrationId?: string }
  sorting?: Gateway.PulledFilesByCursorSorting
  enabled?: boolean
  fetchPolicy?: WatchQueryFetchPolicy
  initialCacheData?: Gateway.PulledFilesByCursor
}) {
  const initializationRef = useRef(false)
  const client = useApolloClient()

  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  // Used to add cached data to the query
  useEffect(() => {
    if (!initializationRef.current && initialCacheData) {
      updatePulledFilesByCursorCache(client, {
        variables: {
          sorting,
          ...(filter.integrationId && {
            filter: {
              integrationId: Utils.GraphQL.eqFilter(filter.integrationId),
            },
          }),
          paging: cursorPageToGraphqlPaging(page),
        },
        edges: initialCacheData?.edges,
        pageInfo: initialCacheData.pageInfo,
      })

      initializationRef.current = true
    }
  }, [
    initialCacheData,
    currentPageMeta,
    client,
    sorting,
    filter.integrationId,
    page,
  ])

  const { data, refetch, fetchMore, networkStatus } = useApolloQuery<
    QueryData<'pulledFilesByCursor'>,
    Gateway.QueryPulledFilesByCursorArgs
  >(PulledFilesByCursorQuery, {
    fetchPolicy,
    skip: !enabled,
    variables: {
      sorting,
      ...(filter.integrationId && {
        filter: { integrationId: Utils.GraphQL.eqFilter(filter.integrationId) },
      }),
      paging: cursorPageToGraphqlPaging(page),
    },

    onCompleted(data) {
      const newPageMeta = data?.pulledFilesByCursor?.pageInfo
      if (!newPageMeta) return

      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const pulledFilesFetchMore = useCallback(async () => {
    await fetchMore({
      variables: {
        paging: {
          limit: page.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })
  }, [currentPageMeta.endCursor, fetchMore, page.size])

  const isLoading = isNetworkRequestInFlight(networkStatus)
  const pulledFiles = compact(
    data?.pulledFilesByCursor?.edges?.map(({ node }) => node && node) ?? [],
  )

  return {
    pulledFiles,
    pulledFilesLoading: isLoading,
    pulledFilesRefetch: refetch,
    pulledFilesFetchMore,
    pulledFilesPageMeta: currentPageMeta,
  }
}

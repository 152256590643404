import React, { useCallback, useMemo, useRef, useState } from 'react'

import { Box, Flex, SpacedRow } from 'components/ui/__v2__/Grid'

import { i18n } from 'i18n'

import { SortButton, SortIcons, WrapperRemainingLogs } from './styles'

const LOG_LIMITER = 1

type Props = {
  elements: string[]
}
export function FoldableLogItems({ elements }: Props) {
  const slicedLogs = useMemo(() => elements.slice(0, LOG_LIMITER), [elements])
  const remainingLogs = useMemo(() => elements.slice(LOG_LIMITER), [elements])
  const remainingLogsRef = useRef<HTMLElement>(null)

  const [openMore, setOpenMore] = useState(false)

  const handleMoreClick = useCallback(() => {
    if (openMore) {
      setOpenMore(false)
    } else {
      setOpenMore(true)
    }
  }, [openMore])

  return (
    <Flex flexDirection="column">
      {slicedLogs.map((element, key) => (
        <Box key={key}>{element}</Box>
      ))}

      <WrapperRemainingLogs
        height={openMore ? remainingLogsRef.current?.clientHeight ?? 0 : 0}
      >
        {/* @ts-ignore */}
        <Flex flexDirection="column" ref={remainingLogsRef}>
          {remainingLogs.map((element, key) => (
            <Box key={key}>{element}</Box>
          ))}
        </Flex>
      </WrapperRemainingLogs>

      {elements.length > LOG_LIMITER && (
        <SpacedRow justifyContent="flex-start">
          <SortButton space={1} onClick={handleMoreClick}>
            {openMore ? (
              <>
                {i18n('userLogs.expandedLog.less')}
                <SortIcons.Desc />
              </>
            ) : (
              <>
                {i18n('userLogs.expandedLog.more')}
                <SortIcons.Asc />
              </>
            )}
          </SortButton>
        </SpacedRow>
      )}
    </Flex>
  )
}

import { useApolloMutation } from 'API/services/Apollo'

import { InvalidatePulledFileMutation } from './GraphQL'

export function useInvalidatePulledFile() {
  const [innerInvalidatePulledFile, { loading }] = useApolloMutation<
    MutationData<'invalidatePulledFile'>,
    Gateway.MutationInvalidatePulledFileArgs
  >(InvalidatePulledFileMutation)

  async function invalidatePulledFile(id: string) {
    const result = await innerInvalidatePulledFile({
      variables: {
        id,
      },
    })
    return result?.data?.invalidatePulledFile
  }

  return {
    invalidatePulledFile,
    invalidatingPulledFile: loading,
  }
}

import React, { useMemo, useState } from 'react'

import { LayoutProps } from 'styled-system'

import {
  autoPlacement,
  autoUpdate,
  safePolygon,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react'
import { ShiftValidationConflictAPIItem } from 'API/RuleValidation/GraphQL/Fragments/types'

import { PopoverContainer } from 'components/blocks/__v3__/InteractiveList/Item/styles'
import { Flex } from 'components/ui/__v2__/Grid'

import Utils from 'services/Utils'

import { ShiftValidationConflictRuleInfo } from '../ShiftValidationConflictRuleInfo'
import { ShiftValidationConflictTypeIcon } from '../ShiftValidationConflictTypeIcon'

type Props = LayoutProps & {
  shiftConflicts: ShiftValidationConflictAPIItem[]
  iconSize?: number
  disabled?: boolean
  rulesetId: string
}

export function ShiftValidationConflictTypePopover({
  shiftConflicts,
  iconSize,
  disabled,
  rulesetId,
  ...rest
}: Props) {
  // =====================================================================================
  // REFACTOR TO REUSABLE HOOK
  // =====================================================================================

  const [open, setOpen] = useState(false)
  const { refs, floatingStyles, context } = useFloating({
    open,
    strategy: 'fixed',
    onOpenChange: innerOpen => {
      if (disabled) return
      setOpen(innerOpen)
    },
    middleware: [
      autoPlacement({
        alignment: 'start',
      }),
    ],
    whileElementsMounted: autoUpdate,
  })
  const hover = useHover(context, {
    handleClose: safePolygon(),
  })
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    hover,
  ])

  // =====================================================================================
  // EOF: REFACTOR TO REUSABLE HOOK
  // =====================================================================================

  const sortedShiftConflicts = useMemo(
    () => Utils.Conflict.sortConflictsByConflictTypePriority(shiftConflicts),
    [shiftConflicts],
  )

  return (
    <>
      <Flex alignItems="center" justifyContent="center" minWidth={iconSize}>
        <Flex ref={refs.setReference} {...getReferenceProps()}>
          <ShiftValidationConflictTypeIcon
            conflictType={sortedShiftConflicts[0]?.conflictType ?? null}
            size={iconSize}
          />
        </Flex>
      </Flex>

      {open && (
        <PopoverContainer
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <ShiftValidationConflictRuleInfo
            {...rest}
            rulesetId={rulesetId}
            shiftConflicts={sortedShiftConflicts}
          />
        </PopoverContainer>
      )}
    </>
  )
}

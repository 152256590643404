import React from 'react'

import { FiTrash2 as RemoveIcon } from 'react-icons/fi'

import { ButtonProps, IconButton } from 'components/ui/__v3__/Button'

type Props = {
  onRemove: () => void
} & ButtonProps

export function Remove({ onRemove, ...rest }: Props) {
  return (
    <IconButton {...rest} secondary onClick={onRemove}>
      <RemoveIcon />
    </IconButton>
  )
}

import React, { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { MdClose, MdZoomIn } from 'react-icons/md'

import { Button } from 'components/ui/__v2__/Button'
import { Modal } from 'components/ui/__v2__/Modal'

import { Image } from './styles'

const ImageContainer = ({ canRemove, file, onRemoveClick }) => {
  const [chosenPhoto, setChosenPhoto] = useState(null)

  const { id, content } = file

  const resetChosenPhoto = useCallback(event => {
    event.stopPropagation()
    setChosenPhoto(null)
  }, [])

  const onChoosePhoto = useCallback(
    event => {
      setChosenPhoto(content.url)
      event.stopPropagation()
    },
    [content],
  )

  const onClick = useCallback(
    event => {
      event.stopPropagation()
      onRemoveClick(id)
    },
    [id, onRemoveClick],
  )

  return (
    <>
      <Image url={content.url}>
        <Button icon primary onClick={onChoosePhoto}>
          <MdZoomIn />
        </Button>
        {canRemove && (
          <Button icon ml={1} secondary onClick={onClick}>
            <MdClose />
          </Button>
        )}
      </Image>
      <Modal isOpen={chosenPhoto} onRequestClose={resetChosenPhoto}>
        <Modal.Close onClose={resetChosenPhoto} />
        <div>
          <img
            alt="Custom form field"
            height="500"
            src={chosenPhoto}
            width="500"
          />
        </div>
      </Modal>
    </>
  )
}

ImageContainer.propTypes = {
  canRemove: PropTypes.bool.isRequired,
  file: PropTypes.object.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
}

export default memo(ImageContainer)

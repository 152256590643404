import { SECONDS_IN_HOUR, SECONDS_IN_MINUTE } from 'constants/time'

export function formatRemainingSeconds(remainingSeconds: number) {
  const remainingSecondsWithOffset = remainingSeconds + SECONDS_IN_MINUTE

  const hours = Math.floor(remainingSecondsWithOffset / SECONDS_IN_HOUR)
  const minutes = Math.floor(
    (remainingSecondsWithOffset % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE,
  )
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

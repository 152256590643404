import isNumber from 'lodash/isNumber'
import toNumber from 'lodash/toNumber'

import { LONG_DASH } from 'constants/schedule'

export function localePrertify(value, locale = undefined) {
  let parsed = value

  if (!isNumber(value)) {
    parsed = toNumber(value)
  }

  if (isNaN(parsed)) return LONG_DASH

  return parsed.toLocaleString(locale)
}

import {
  AldoPayrollRowsByOffsetArgs,
  useAldoPayrollRowsByOffset,
} from './useAldoPayrollRowsByOffset'
import {
  ExportAldoPayrollArgs,
  useExportAldoPayroll,
} from './useExportAldoPayroll'
import { useStartAldoPayrollRowsCalculation } from './useStartAldoPayrollRowsCalculation'
import { useStartAldoPayrollRowsSync } from './useStartAldoPayrollRowsSync'

export const AldoPayrollAPI = {
  byOffset: useAldoPayrollRowsByOffset,
  startCalculation: useStartAldoPayrollRowsCalculation,
  startSync: useStartAldoPayrollRowsSync,
  extract: useExportAldoPayroll,
}

export type { AldoPayrollRowsByOffsetArgs, ExportAldoPayrollArgs }

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import { SubmittedForm } from 'components/modals/Forms'

import { FormLine } from './styles'

const FormItem = ({ formSubmission }) => {
  const [open, setOpen] = useState(false)

  const {
    id,
    description,
    fields,
    fieldsValues,
    mandatory: isMandatory,
    name,
  } = formSubmission

  const isNotFilled = isEmpty(fieldsValues)
  const isFilled = !isNotFilled

  const handleOpenClick = useCallback(() => isFilled && setOpen(true), [
    isFilled,
  ])
  const handleCloseClick = useCallback(() => setOpen(false), [])

  return (
    <>
      {open && (
        <SubmittedForm
          formObject={{ name, description, fields }}
          formValues={fieldsValues}
          isOpen
          onClose={handleCloseClick}
        />
      )}

      <FormLine isNotFilled={isNotFilled} key={id} onClick={handleOpenClick}>
        {name}
        {isMandatory && <sup>*</sup>}
      </FormLine>
    </>
  )
}

FormItem.propTypes = {
  formSubmission: PropTypes.object.isRequired,
}

export default FormItem

import * as colors from './colors'

export default {
  color: {
    secondary: colors.CHARCOAL_100,
    error: colors.NEGATIVE,
    success: colors.POSITIVE,
    default: colors.SMASHED_PUMPKIN,
  },
  active: {
    secondary: colors.CHARCOAL_80,
    error: colors.NEGATIVE_LIGHT,
    success: colors.POSITIVE_LIGHT,
    default: colors.PUMPKIN_80,
  },
  hover: {
    secondary: colors.CHARCOAL_120,
    error: colors.NEGATIVE_DARK,
    success: colors.POSITIVE_DARK,
    default: colors.PUMPKIN_120,
  },
  disabled: {
    default: colors.CHARCOAL_40,
  },
}

import { useBulkUpdateCustomCvFieldValues as updateBulk } from './useBulkUpdateCustomCvFieldValues'
import { useCustomCvFieldValuesByCursor as byCursor } from './useCustomCvFieldValuesByCursor'
import { useCustomCvFieldValuesCount as count } from './useCustomCvFieldValuesCount'
import { useUpdateCustomCvFieldValue as update } from './useUpdateCustomCvFieldValue'
import { useUpdateCustomCvFieldValues as updateMany } from './useUpdateCustomCvFieldValues'

export const CustomCvFieldsValuesAPI = {
  count,
  update,
  updateMany,
  byCursor,
  updateBulk,
}

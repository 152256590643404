import { PAGE_SIZE } from 'constants/pagination'

import { areasToFilters } from 'helpers/areas'
import { customFieldValueOptionsToFilter } from 'helpers/customCvFields'
import { createAsyncAction } from 'helpers/redux'
import {
  accessLevelsToFilters,
  searchToFilters,
  statusesToFilters,
} from 'helpers/staff'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const LOAD_STAFF = createAsyncAction('staff/LOAD')
export const LOAD_MORE_STAFF = createAsyncAction('staff/LOAD_MORE')
export const LOAD_STAFF_SILENT = createAsyncAction('staff/LOAD_SILENT')
// prettier-ignore
export const LOAD_MORE_STAFF_SILENT = createAsyncAction('staff/LOAD_MORE_SILENT')
export const LOAD_STAFF_GRID = createAsyncAction('staff/LOAD_GRID')
export const LOAD_MORE_STAFF_GRID = createAsyncAction('staff/LOAD_MORE_GRID')

export const CLEAR_STAFF = 'staff/CLEAR'
export const CLEAR_STAFF_GRID = 'staff/CLEAR_GRID'

const staffIncludes = [
  'employees.activeTimeEntry.branch',
  'employees.activeTimeEntry.department',
  'employees',
  'employees.branch',
  'employees.jobsEmployees.department',
  'employees.jobsEmployees.job',
  'employees.jobsEmployees.jobsTags',
  'employees.sync', // Deluxe PAYWeb synchronization
  'employees.jobsEmployees.branch',
  'manager',
  'manager.branches',
  'manager.departments.branches',
  'manager.all_managed_branches.branch',
  'manager.all_managed_departments.department.branch',
  'manager.managedDepartments.branch',
  'profile.user',
  'profile.profileAvatar',
]

export const loadStaff = (
  { sort, filters = {}, page = { size: PAGE_SIZE['25'] } } = {},
  { withLoading = true, withReplace = true } = {},
) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  const getActionTypes = () => {
    if (withLoading && !withReplace) {
      return LOAD_MORE_STAFF
    }
    if (!withLoading && !withReplace) {
      return LOAD_MORE_STAFF_SILENT
    }
    if (withLoading && withReplace) {
      return LOAD_STAFF
    }
    return LOAD_STAFF_SILENT
  }

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/staff`,
      query: {
        include: staffIncludes.join(),
        sort,
        filter: {
          ...areasToFilters(filters.areas || {}),
          ...accessLevelsToFilters(filters.accessLevels),
          ...statusesToFilters(filters.statuses),
          ...searchToFilters(filters.search),
          ...customFieldValueOptionsToFilter(filters.customCvFields),
          missing_fields: filters.missingFields,
        },
        page,
      },
      paged: true,
      types: getActionTypes(),
    }),
  )
}

export const loadGrid = ({
  sort,
  filters = {},
  page = { size: PAGE_SIZE['25'] },
  more = false,
} = {}) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/staff`,
      query: {
        include: staffIncludes.join(),
        sort,
        filter: {
          ...areasToFilters(filters.areas || {}),
          ...accessLevelsToFilters(filters.accessLevels),
          ...statusesToFilters(filters.statuses),
          ...searchToFilters(filters.search),
          ...customFieldValueOptionsToFilter(filters.customCvFields),
        },
        page,
      },
      paged: true,
      types: more ? LOAD_MORE_STAFF_GRID : LOAD_STAFF_GRID,
    }),
  )
}

export const clearStaff = () => ({
  type: CLEAR_STAFF,
})

export const clearGrid = () => ({
  type: CLEAR_STAFF_GRID,
})

import { useMemo } from 'react'

import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query'
import {
  getNextCursorPageParam,
  getPrevCursorPageParam,
  transformResponseToEntities,
} from 'API/services/utils'

import { PAGE_SIZE } from 'constants/pagination'

import { useAppContext, useLocale } from 'hooks'

import { PROFILE_LOGS_JSON_API_TYPE, ProfileLogQueryKeys } from './constants'
import { profileLogsQuery, ProfileLogsQuerySorting } from './Json'
import { ProfileLogAPIItem } from './types'

interface Props {
  sort: ProfileLogsQuerySorting
  filters: {
    user: { value: string }
    createdAt?: { from: string | null; to: string | null }
  }
  initialSize?: number
  increment?: number
}

export function useProfileLogsInfinite({
  filters,
  sort,
  initialSize = PAGE_SIZE['25'],
  increment = PAGE_SIZE['25'],
}: Props) {
  const { companyId } = useAppContext()
  const locale = useLocale()

  const {
    data,
    isFetching,
    error,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: ProfileLogQueryKeys.infinite(sort, filters),
    queryFn: ({ pageParam = { size: initialSize } }) =>
      profileLogsQuery({
        companyId,
        locale,
        sort,
        filters,
        page: pageParam,
      }),
    select: ({ pages, pageParams }: InfiniteData<JsonApi.Response>) => ({
      pages: pages.map(response => {
        return transformResponseToEntities<ProfileLogAPIItem>({
          response,
          jsonApiType: PROFILE_LOGS_JSON_API_TYPE,
        }).entities
      }),
      pageParams,
    }),
    getPreviousPageParam: page => getPrevCursorPageParam(page, increment),
    getNextPageParam: page => getNextCursorPageParam(page, increment),
    keepPreviousData: true,
    structuralSharing: false,
    refetchOnWindowFocus: false,
  })

  const flattenData = useMemo(() => data?.pages?.flatMap(page => page) ?? [], [
    data,
  ])

  return {
    profileLogs: flattenData,
    isFetching,
    error,
    refetch,
    hasNextPage: Boolean(hasNextPage),
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
  }
}

import styled from 'styled-components'

import SvgIcons from 'assets/images/__v2__/svg'

import { SvgIcon } from 'components/ui/__v2__/SvgIcon'

const Holiday = styled(SvgIcon).attrs({
  glyph: SvgIcons.Holiday,
})``

Holiday.defaultProps = {
  width: 16,
  height: 16,
}

export default Holiday

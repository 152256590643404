import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import CustomLeaveDay from 'components/blocks/CustomLeaveDay'
import { Select } from 'components/ui/__v3__/Select'

import { createDebounce } from 'helpers/debounce'
import { denormalize } from 'helpers/redux'

import { showError } from 'services/API'
import { labelDayToOption } from 'services/Options'

import { CUSTOM_LABEL_DAYS_TYPE } from 'store/reducers/customLabelDays'

const CustomLabelDaysPicker = ({
  cacheOptions,
  customLabelDays,
  isDisabled,
  value,
  onLoadByEmployee,
  onLoadAllLeaves,
  employee,
  targetDateRange,
  year,
  ...rest
}) => {
  const customLabelDayOptions = useMemo(() => {
    return map(customLabelDays.entities, labelDayToOption)
  }, [customLabelDays.entities])

  const loadByEmployee = useCallback(
    async (name = null) => {
      const result = await onLoadByEmployee({
        employeeId: employee?.id,
        filters: name
          ? { effectiveDates: targetDateRange, name }
          : { effectiveDates: targetDateRange },
      })

      return result
    },
    [employee?.id, onLoadByEmployee, targetDateRange],
  )

  const loadAllLeaves = useCallback(
    async (name = null) => {
      const result = await onLoadAllLeaves({
        paged: false,
        filters: { name, year },
      })

      return result
    },
    [onLoadAllLeaves, year],
  )

  const loadLeaves = useCallback(
    async inputValue => {
      const result = employee?.id
        ? await loadByEmployee(inputValue)
        : await loadAllLeaves(inputValue)
      return result
    },
    [employee?.id, loadAllLeaves, loadByEmployee],
  )

  const loadCustomLabelDays = useMemo(() => {
    return createDebounce(
      (inputValue, callback) => {
        if (!inputValue) {
          callback([])
        } else {
          loadLeaves(inputValue).then(result => {
            if (result?.ok) {
              const data = result?.payload?.data
              const customLabelDayIds = map(
                data?.[CUSTOM_LABEL_DAYS_TYPE],
                'id',
              )

              const entities = !isEmpty(customLabelDayIds)
                ? denormalize(data, CUSTOM_LABEL_DAYS_TYPE, customLabelDayIds)
                : []

              const options = map(entities, labelDayToOption)
              callback(options)
            } else {
              showError(result)
              callback([])
            }
          })
        }
      },
      { leading: true, trailing: true },
    )
  }, [loadLeaves])

  const formatOptions = useCallback(
    ({ data }) => <CustomLeaveDay leaveDay={data} />,
    [],
  )

  return (
    <Select
      async
      cacheOptions={cacheOptions}
      closeMenuOnSelect={!rest.isMulti}
      defaultOptions={customLabelDayOptions}
      formatOptionLabel={formatOptions}
      isClearable
      isDisabled={isDisabled}
      isLoading={customLabelDays.isLoading}
      isSearchable
      loadOptions={loadCustomLabelDays}
      value={value}
      width={1}
      onMenuOpen={employee ? loadByEmployee : loadAllLeaves}
      {...rest}
    />
  )
}

CustomLabelDaysPicker.defaultProps = {
  cacheOptions: false,
  isDisabled: false,
  employee: null,
  targetDateRange: { from: null, to: null },
  value: null,
  year: null,
}

CustomLabelDaysPicker.propTypes = {
  ...Select.propTypes,
  cacheOptions: PropTypes.bool,
  customLabelDays: PropTypes.object.isRequired,
  employee: PropTypes.object,
  isDisabled: PropTypes.bool,
  targetDateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  value: PropTypes.object,
  year: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onLoadAllLeaves: PropTypes.func.isRequired,
  onLoadByEmployee: PropTypes.func.isRequired,
}

export default CustomLabelDaysPicker

import { useApolloQuery } from 'API/services/Apollo'

import {
  WorkfloWithEmploymentTypeQuery,
  WorkfloWithEmploymentTypeQueryData,
} from './GraphQL'

export function useWorkflow(id: string, { skip }: { skip: boolean }) {
  const { data, loading, error } = useApolloQuery<
    WorkfloWithEmploymentTypeQueryData,
    Gateway.QueryWorkflowArgs
  >(WorkfloWithEmploymentTypeQuery, {
    variables: { id },
    skip,
  })

  return {
    workflow: data?.workflow,
    loading,
    error,
  }
}

import { useApolloQuery } from 'API/services/Apollo'

import { EffectiveEmployeePayFrequencyQuery } from './GraphQL'

type Props = {
  externalId: string
}

export function usePayFrequencyByEmployee({ externalId }: Props) {
  const { data, loading, error } = useApolloQuery<
    QueryData<'effectiveEmployeePayFrequency'>,
    Gateway.QueryEffectiveEmployeePayFrequencyArgs
  >(EffectiveEmployeePayFrequencyQuery, {
    variables: { externalId },
  })

  return {
    effectivePayFrequency: data?.effectiveEmployeePayFrequency,
    loading,
    error,
  }
}

import validate from 'validate.js/validate'

import isEmpty from 'lodash/isEmpty'

/**
 * @see https://stackoverflow.com/questions/37034877/validate-js-validating-array-elements
 */
validate.validators.array = (array, constraints) => {
  const errors = array.reduce((acc, item) => {
    const error = validate(item, constraints)
    if (error) {
      return {
        ...acc,
        ...error,
      }
    }
    return acc
  }, {})

  return isEmpty(errors) ? null : errors
}

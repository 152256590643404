import { ReactNode } from 'react'

import { usePageTitle } from 'hooks/usePageTitle'

interface TitleProps {
  title: string
  subtitle?: string
  children?: ReactNode
}

// TODO: move title state to storeon
export function Title({ title, subtitle, children }: TitleProps) {
  usePageTitle({ title, subtitle, children })

  return null
}

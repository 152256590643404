import React, { ChangeEvent, useMemo, useState } from 'react'

import { debounce } from 'lodash'

import { SearchInput } from 'components/ui/__v3__/SearchInput'

import { FilterOperator } from 'constants/pagination'

import { useI18n } from 'hooks/useI18n'

import { TableFilterProps } from './types'

import { SEARCH_DEBOUNCE_TIMEOUT } from '../../constants'

export function SearchFilter({
  fieldName: field,
  pagination: { handleFilterChange = () => null, options },
  filterOperator,
}: TableFilterProps) {
  const t = useI18n('table')
  const filter = options?.filter
  const lastFieldUpdated = options?.lastFieldUpdated
  const operator = filterOperator ?? FilterOperator.ILike

  const [inputValue, setInputValue] = useState(
    filter?.[field]?.[operator] ?? '',
  )

  const handleSearchChange = useMemo(() => {
    return debounce(
      value =>
        handleFilterChange({
          field,
          operator,
          value,
          lastFieldUpdated: String(field),
        }),
      SEARCH_DEBOUNCE_TIMEOUT,
    )
  }, [field, handleFilterChange, operator])

  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setInputValue(value)
    handleSearchChange(value)
  }

  return (
    <SearchInput
      // @ts-ignore
      autoFocus={field === lastFieldUpdated}
      fontWeight="normal"
      placeholder={t('search')}
      value={inputValue}
      width={148}
      onChange={handleChange}
    />
  )
}

import { TimeoffsFilter, TimeoffSorting } from './types'

export const TIMEOFFS_JSON_TYPE = 'timeoffs'

export const TimeoffQueryKeys = {
  all: () => [TIMEOFFS_JSON_TYPE],

  byOffset: (
    page: JsonApi.OffsetPagingInput,
    filter: TimeoffsFilter,
    sort: TimeoffSorting,
  ) => [TIMEOFFS_JSON_TYPE, { page, filter, sort }],

  byCursor: ({
    page,
    filter,
    sort,
  }: {
    page: JsonApi.CursorPagingInput
    filter: TimeoffsFilter
    sort: TimeoffSorting
  }) => [TIMEOFFS_JSON_TYPE, { page, filter, sort }],
}

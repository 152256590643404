import { getEntities } from 'rapidux'
import { createSelector } from 'reselect'

import isEmpty from 'lodash/isEmpty'

import { denormalize } from 'helpers/redux'

import { getData } from 'store/selectors/data'

export const getState = state => state.company.managers

export const getIsLoading = createSelector(getState, state => state.isLoading)

export const getIsLoaded = createSelector(getState, state => state.isLoaded)

export const getManagerIds = createSelector(getState, state =>
  Array.from(state.managers),
)

export const getBranchManagerIds = createSelector(getState, state =>
  Array.from(state.branchManagers),
)

export const getManagers = createSelector(
  getData,
  // NOT display department mangers and location managers in locations they dont have access
  getBranchManagerIds, // getManagerIds
  (data, managerIds) =>
    !isEmpty(managerIds) ? denormalize(data, 'managers', managerIds) : [],
)

export const getBranchManagers = createSelector(
  getData,
  getBranchManagerIds,
  (data, managerIds) =>
    !isEmpty(managerIds) ? denormalize(data, 'managers', managerIds) : [],
)

export const getManager = getEntities(getState, getData, {
  type: 'managers',
  field: 'manager',
  singular: true,
})

import { ClassifiersByCursorAPIItem } from 'API/Classifiers/GraphQL/Queries/types'
import { EmploymentLevelPickerFields } from 'API/EmploymentLevel/GraphQL/Fragments/types'

import {
  ClassifierSource,
  CustomCvFieldFieldType,
} from 'constants/gatewayGraphQL'

import { useI18n } from 'hooks/useI18n'

import { TRANSLATIONS } from 'i18n'

import { entityToOptionWithId } from 'services/Options'

export function mapAreasToOptions(
  areas:
    | ClassifiersByCursorAPIItem['locations']
    | ClassifiersByCursorAPIItem['departments']
    | ClassifiersByCursorAPIItem['jobs'],
) {
  return areas?.map(entityToOptionWithId) ?? []
}

export function mapAreasToNames(
  areas:
    | ClassifiersByCursorAPIItem['locations']
    | ClassifiersByCursorAPIItem['departments']
    | ClassifiersByCursorAPIItem['jobs'],
) {
  return areas?.map(area => area.name) ?? []
}

export function mapEmploymentLevelsToPairedName(
  employmentLevels: EmploymentLevelPickerFields[],
) {
  return (
    employmentLevels?.map(
      employmentLevel =>
        `${employmentLevel.employmentType?.name}/${employmentLevel.name}`,
    ) ?? []
  )
}

export function getClassifierSourceName(
  classifier: ClassifiersByCursorAPIItem,
) {
  const t = useI18n<typeof TRANSLATIONS.settings.classifiers>(
    'settings.classifiers',
  )

  if (
    classifier.source === ClassifierSource.CustomCvField &&
    classifier.customCvField
  ) {
    return classifier.customCvField.name
  }

  return t(`sourceAttributes.${classifier.source}`)
}

export function getClassifierConditionsBySource({
  classifier,
  returnAsString,
}: {
  classifier: ClassifiersByCursorAPIItem
  returnAsString?: boolean
}) {
  let result: string[] = []

  if (
    classifier.source === ClassifierSource.CustomCvField &&
    classifier.customCvField &&
    classifier.customCvField.fieldType === CustomCvFieldFieldType.Dropdown &&
    classifier.condition.in?.length
  ) {
    result = classifier.condition.in
  }

  if (
    classifier.source === ClassifierSource.CustomCvField &&
    classifier.customCvField &&
    classifier.customCvField.fieldType === CustomCvFieldFieldType.Checkbox &&
    classifier.condition.eq
  ) {
    result = [classifier.condition.eq]
  }

  if (
    classifier.source === ClassifierSource.Branch &&
    classifier.locations?.length
  ) {
    result = mapAreasToNames(classifier.locations)
  }

  if (
    classifier.source === ClassifierSource.Department &&
    classifier.departments?.length
  ) {
    result = mapAreasToNames(classifier.departments)
  }

  if (classifier.source === ClassifierSource.Job && classifier.jobs?.length) {
    result = mapAreasToNames(classifier.jobs)
  }

  if (
    classifier.source === ClassifierSource.EmploymentTerm &&
    classifier.employmentLevels?.length
  ) {
    result = mapEmploymentLevelsToPairedName(classifier.employmentLevels)
  }

  return returnAsString ? result.join(', ') : result
}

import { DateTime } from 'luxon'

import { DemandForecastingRoles } from 'constants/demandForecastingDemo'

export type FTEDistributionByRole = {
  [DemandForecastingRoles.Cashier]: number
  [DemandForecastingRoles.Manager]: number
  [DemandForecastingRoles.Stocker]: number
}

export type DataSlice = {
  date: DateTime
  [DemandForecastingRoles.Cashier]: number
  [DemandForecastingRoles.Manager]: number
  [DemandForecastingRoles.Stocker]: number
}

export type DataByState = {
  [store: string]: DataSlice[]
}

export type ChartDataSlice = {
  date: string
  scheduled: number | null
  forecasted: number | null
  diffType: 'shortage' | 'abundance'
  diff: [number, number] | null
}

export enum HeatMapDisplayModes {
  Scheduled = 'Scheduled',
  Forecasted = 'Forecasted',
  Difference = 'Difference',
}

export type HeatMapDataSlice = {
  date: string
  count: number
}

/* eslint-disable no-console */
import { onError } from '@apollo/client/link/error'

export function hathorErrorLink() {
  return onError(
    ({ response, graphQLErrors, networkError, operation, forward }) => {
      if (networkError) console.error(`[Network error]: ${networkError}`)

      graphQLErrors?.forEach(({ message, location, path }) => {
        if (message === 'PersistedQueryNotFound') return

        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`,
        )
      })

      if (operation.operationName === 'Me' && response) {
        response.errors = null
      }

      const isUnauthorizedError =
        networkError?.statusCode === 401 ||
        graphQLErrors?.[0]?.extensions?.name === 'AuthenticationError' ||
        graphQLErrors?.[0]?.message === 'generic.notAuthorized'

      if (isUnauthorizedError) console.log('[GraphQL] Unauthorized')
    },
  )
}

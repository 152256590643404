import React from 'react'

import { MdInfo } from 'react-icons/md'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Span } from 'components/ui/__v2__/Grid'

export const ValidationError = styled(Span)`
  color: ${themeGet('layout.primary')};
`

const IconErrorText = styled.span`
  font-size: 12px;
  color: ${themeGet('input.errors.icon.color')};
`

const IconContainer = styled.span`
  color: ${themeGet('icons.primary')};
  margin-right: 4px;
  cursor: help;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
`

export const IconError = (props: { children: React.ReactNode }) => {
  const { children } = props

  return (
    <>
      <IconContainer>
        <MdInfo />
      </IconContainer>
      <IconErrorText>{children}</IconErrorText>
    </>
  )
}

import React, { useMemo } from 'react'
import { FieldValues } from 'react-hook-form'

import { WeekdayNumbers } from 'luxon'

import { FormSelect } from 'components/ui/__v3__/HookForm'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import { i18n } from 'i18n'

import Utils from 'services/Utils'

type Props = {
  calendarStartDay: WeekdayNumbers
}

export function FormWeekDaySingleSelect<T extends FieldValues>({
  control,
  name,
  labelText,
  calendarStartDay,
  required = false,
}: ControllerBaseProps<T> & LabelProps & Props) {
  const weekOptions = useMemo(() => {
    return Utils.DateTime.getWeekDayNumbers(calendarStartDay).map(
      weekDayNumber => {
        const weekDay = Utils.DateTime.mapLuxonToGatewayWeekday(weekDayNumber)
        return {
          value: weekDay,
          label: i18n(`weekdays.long.${weekDay}`),
        }
      },
    )
  }, [calendarStartDay])

  return (
    <FormSelect
      control={control}
      isClearable={false}
      labelText={labelText}
      name={name}
      options={weekOptions}
      required={required}
      returnOptionObject={false}
      withPortal
    />
  )
}

import { useCallback, useState } from 'react'

const initialState = {
  size: 10,
  number: 1,
  sort: 'name',
  filters: {},
}

export function usePaginationQuery(load, defaultQueryOptions = initialState) {
  const [queryOptions, setQueryOptions] = useState(defaultQueryOptions)

  const updateQueryAndLoad = useCallback(
    nextQueryOptions => {
      setQueryOptions(nextQueryOptions)
      load(nextQueryOptions)
    },
    [load],
  )

  const changePageSize = useCallback(
    size => updateQueryAndLoad({ ...queryOptions, number: 1, size }),
    [updateQueryAndLoad, queryOptions],
  )

  const changePageNumber = useCallback(
    number => updateQueryAndLoad({ ...queryOptions, number }),
    [updateQueryAndLoad, queryOptions],
  )

  const changeFilters = useCallback(
    filters => {
      updateQueryAndLoad({
        ...queryOptions,
        number: 1,
        filters,
      })
    },
    [queryOptions, updateQueryAndLoad],
  )

  const sort = useCallback(
    ({ selector }, order) => {
      updateQueryAndLoad({
        ...queryOptions,
        sort: `${order === 'desc' ? '' : '-'}${selector}`,
      })
    },
    [updateQueryAndLoad, queryOptions],
  )

  const refetch = useCallback(() => load(queryOptions), [load, queryOptions])

  return [
    queryOptions,
    {
      changePageNumber,
      changePageSize,
      sort,
      changeFilters,
      refetch,
    },
  ]
}

export default usePaginationQuery

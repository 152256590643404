import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { Employee } from 'Types/app'

import { pick } from 'lodash'

import { EmployeesPicker } from 'components/blocks/__v2__'
import { Flex } from 'components/ui/__v2__/Grid'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import { locationToOption } from 'services/Options'

import { thinFormLocation } from './FormLocationPicker'

type Props<T extends FieldValues> = ControllerBaseProps<T> & LabelProps

type EmployeePickerOption = {
  id: string
  label: string
  value: string
  employee: Employee
}

// NOTE: We need to thin because react-hook-form is not able to clone large objects
export function thinFormEmployee(option: EmployeePickerOption) {
  return {
    id: option.id,
    label: option.label,
    value: option.value,
    employee: {
      id: option.employee.id,
      branch: pick(option.employee?.branch, ['id', 'name', 'settings']),
      branches: option.employee?.branches
        ?.map(locationToOption)
        // @ts-ignore
        .map(thinFormLocation),
      leaveSettings: option.employee?.leaveSettings,
    },
  }
}

export type FormEmployeePickerReturn = ReturnType<typeof thinFormEmployee>

/** @deprecated Pay attention, not all functionality */
export function FormEmployeesPicker<T extends FieldValues>({
  control,
  name,
  labelText,
  required,
}: Props<T>) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  const handleChange = (option: EmployeePickerOption) => {
    onChange(thinFormEmployee(option))
  }

  return (
    <Flex flexDirection="column">
      {labelText && (
        <InputLabel>
          <LabelText labelText={labelText} required={required} />
        </InputLabel>
      )}
      {/* TODO: Need to type underlying component */}
      <EmployeesPicker value={value} onBlur={onBlur} onChange={handleChange} />
      {error && <Error error={error} />}
    </Flex>
  )
}

import styled from 'styled-components'

import SvgIcons from 'assets/images/__v2__/svg'

import { SvgIcon } from 'components/ui/__v2__/SvgIcon'

const FileText = styled(SvgIcon).attrs({
  glyph: SvgIcons.FileText,
})``

FileText.defaultProps = {
  width: 10,
  height: 12,
}

export default FileText

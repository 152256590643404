import { toastr } from 'react-redux-toastr'

import { all, fork, put, select, take } from 'redux-saga/effects'

import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'

import { getFirstEntity } from 'helpers/redux'

import _ from 'i18n'

import {
  CREATE_BRANCH,
  DELETE_BRANCH,
  deleteImageFile,
  refetchBranch,
  UPDATE_BRANCH,
  UPLOAD_IMAGE_FILE,
} from 'store/actions/company/branches'
import { loadBranchManagers } from 'store/actions/company/managers'
import {
  clearAttendance,
  UPDATE_ATTENDANCE,
} from 'store/actions/company/settings'
import { setBranch } from 'store/actions/viewer'
import { getBranches, getImageFiles } from 'store/selectors/company/branches'

function* handleUploadImageFile() {
  while (true) {
    yield take(UPLOAD_IMAGE_FILE.SUCCESS)
    const imageFiles = yield select(getImageFiles)
    if (imageFiles.length > 1) {
      yield put(deleteImageFile(imageFiles[0].id))
    }
  }
}

function* watchCreateBranch() {
  while (true) {
    const { payload } = yield take(CREATE_BRANCH.SUCCESS)
    const branches = get(payload, 'data.branches', null)
    const branch = branches && getFirstEntity(branches)

    if (payload.isNew && branch) {
      yield put(setBranch(branch.id))
    }
  }
}

function* watchDeleteBranch() {
  while (true) {
    const {
      payload: { id, isLocationTab, currentLocation },
    } = yield take(DELETE_BRANCH.SUCCESS)

    if (!isLocationTab || currentLocation) {
      const branches = yield select(getBranches)
      const newBranchId = some(branches, branch => branch.id !== id).id
      yield put(setBranch(newBranchId))
    } else {
      yield put(loadBranchManagers())
    }
  }
}

function* handleBranchBeaconIdError() {
  const ERROR_DETAIL = 'is not a valid UUID'

  while (true) {
    const { payload } = yield take(UPDATE_BRANCH.FAILURE)
    const errors = get(payload, 'errors', [])

    const beaconIdError = find(
      errors,
      error => get(error, 'detail', null) === ERROR_DETAIL,
    )

    if (!isEmpty(beaconIdError)) {
      toastr.info(_('settings.wrongBeaconId'))
    }
  }
}

function* watchUpdateAttendance() {
  while (true) {
    const { payload } = yield take(UPDATE_ATTENDANCE.SUCCESS)

    yield put(clearAttendance())

    const locationId = get(payload, 'refetchLocation')

    if (locationId) {
      yield put(refetchBranch(locationId))
    }
  }
}

export default function* root() {
  yield all([
    fork(watchCreateBranch),
    fork(watchDeleteBranch),
    fork(handleUploadImageFile),
    fork(handleBranchBeaconIdError),
    fork(watchUpdateAttendance),
  ])
}

import React from 'react'

import { ShiftAPI } from 'API'

import { Span } from 'components/ui/__v2__/Grid'
import { Spinner } from 'components/ui/Spinner'

import i18n from 'i18n'

import { InnerShiftAssign } from './InnerShiftAssign'
import { Container, Fallback, LoadingContainer } from './styles'

type Props = {
  shiftId: string
}

export function ShiftAssignment({ shiftId }: Props) {
  // Note: I don't like how we're loading stuff here

  const { shift, loading: loadingShift } = ShiftAPI.byId({ id: shiftId })

  const renderContent = () => {
    if (loadingShift) {
      return (
        <LoadingContainer>
          <Spinner primary size={38} />
        </LoadingContainer>
      )
    }

    if (!shift) {
      return (
        <Fallback>
          <Span>{i18n('shiftAssign.shiftNotFound')}</Span>
        </Fallback>
      )
    }

    return <InnerShiftAssign shift={shift} />
  }

  return <Container>{renderContent()}</Container>
}

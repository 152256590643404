import React, { useMemo } from 'react'

import { API } from 'API'
import { SelectOptionWithId } from 'Types/common'

import { InfoPopover } from 'components/blocks/__v2__'
import { Flex } from 'components/ui/__v2__/Grid'
import { Select } from 'components/ui/__v3__/Select'
import { PickerProps } from 'components/ui/__v3__/Select/types'

import { formatOptionLabel } from 'helpers/areas'

import { i18n } from 'i18n'

import { entityToOptionWithId } from 'services/Options'

import { Error } from './styles'

type Props = Omit<
  PickerProps,
  | 'async'
  | 'closeMenuOnSelect'
  | 'options'
  | 'formatOptionLabel'
  | 'isLoading'
  | 'loadOptions'
  | 'withPortal'
  | 'onMenuOpen'
  | 'onChange'
  | 'value'
> & {
  employeeId: string
  effectiveDate?: string // Date in ISO format
  onChange: (value: SelectOptionWithId[] | SelectOptionWithId) => void
  value?: SelectOptionWithId[] | SelectOptionWithId
}

export function EffectiveEmployeeGroupsPicker({
  employeeId,
  effectiveDate,
  isMulti,
  onChange,
  value,
  ...rest
}: Props) {
  const { effectiveEmployeeGroups, loading } = API.Group.forEmployee(
    employeeId,
    effectiveDate,
  )

  const options = useMemo(() => {
    return effectiveEmployeeGroups?.map(entityToOptionWithId) ?? []
  }, [effectiveEmployeeGroups])

  const hasMissedQualifications = useMemo(() => {
    const effectiveEmployeeGroupsIds = new Set(
      effectiveEmployeeGroups?.map(group => group.id) || [],
    )

    if (Array.isArray(value)) {
      return value.some(group => !effectiveEmployeeGroupsIds.has(group.id))
    }

    return value ? !effectiveEmployeeGroupsIds.has(value?.id) : false
  }, [value, effectiveEmployeeGroups])

  return (
    <>
      <Select
        // @ts-ignore
        closeMenuOnSelect={!isMulti}
        formatOptionLabel={formatOptionLabel()}
        isLoading={loading}
        isMulti={isMulti}
        options={options}
        value={value}
        withPortal
        onChange={onChange}
        {...rest}
      />

      {hasMissedQualifications && (
        <Error>
          <Flex alignItems="center" gap={2}>
            Groups missing error
            <InfoPopover
              iconSize={13}
              maxWidth={300}
              popoverContent={i18n(
                'shifts.groupRestrictionMissingGroupsTooltip',
                {
                  count: Array.isArray(value) ? value.length : 1,
                },
              )}
              trigger="click"
            />
          </Flex>
        </Error>
      )}
    </>
  )
}

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'components/ui/__v2__/Grid'
import { Icons as UIIcons } from 'components/ui/__v2__/Icons'
import { either } from 'components/ui/__v3__/__utils__/styled-system'

const { Trash: DeleteIcon, Cloud: UploadPhotoIcon } = UIIcons

export const CursorPointer = styled(Flex)`
  cursor: ${either('disabled', 'not-allowed', 'pointer')};
`

export const IconContainer = styled(CursorPointer)`
  color: ${either(
    'disabled',
    themeGet('layout.disabled'),
    either(
      'secondary',
      themeGet('layout.text'),
      either('error', themeGet('layout.negative'), themeGet('layout.primary')),
    ),
  )};
  cursor: ${either(
    'disabled',
    'not-allowed',
    either('help', 'help', either('default', 'default', 'pointer')),
  )};
  border-radius: ${either('fab', '100vw', 0)};
  background: ${either('fab', themeGet('layout.bg'), 'transparent')};
  padding: ${either('fab', '6px', 0)};
`
const uploadingCss = ({ uploading }) =>
  uploading &&
  css`
    &:after {
      opacity: 0.5;
    }
  `

IconContainer.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
}

export const Icons = {
  Delete: DeleteIcon,
  UploadPhoto: UploadPhotoIcon,
}

export const ChangePhotoContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 16px;
  // TODO: get z-index from theme
  z-index: 2;
  opacity: 0;
  transition: ${themeGet('layout.transition.fast')};
`

export const DeletePhotoContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 8px;
  right: 16px;
  // TODO: get z-index from theme
  z-index: 2;
  opacity: 0;
  transition: ${themeGet('layout.transition.fast')};
`

export const UploadPhoto = styled(Flex)`
  cursor: pointer;
  position: absolute;
  color: ${themeGet('layout.bg')};
  opacity: 0;
  transition: ${themeGet('layout.transition.fast')};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // TODO: get z-index from theme
  z-index: 2;
`

export const AvatarContainer = styled(Flex)`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0;
    transition: ${themeGet('layout.transition.fast')};
    // TODO: get z-index from theme
    z-index: 1;
  }

  &:hover {
    &:after {
      opacity: 0.5;
    }

    ${ChangePhotoContainer}, ${DeletePhotoContainer}, ${UploadPhoto} {
      opacity: 1;
    }
  }

  ${uploadingCss}
`

import React, { MouseEvent, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { FiChevronDown, FiX } from 'react-icons/fi'

import { Flex } from 'components/ui/__v2__/Grid'

// Do not import {} from hooks to prevent circular dependency
import { useClickOutside } from 'hooks/useClickOutside'
import { useI18n } from 'hooks/useI18n'

import { generateInternalOptions, sortOptions } from './helpers'
import { Menu } from './Menu'
import {
  ClearIconContainer,
  IndicatorIconContainer,
  Placeholder,
  SelectContainer,
  SelectedInfoContainer,
} from './styles'
import { HandleOptionSelect, TableSelectProps } from './types'

export function TableSelect({
  autoFocus,
  options = [],
  onChange,
  value: selectedValues,
}: TableSelectProps) {
  const selectRef = useRef<HTMLDivElement>(null)
  const t = useI18n('table.select')

  const [open, setOpen] = useState(autoFocus)
  const [internalOptions, setInternalOptions] = useState(
    generateInternalOptions(options, selectedValues),
  )

  useClickOutside(selectRef, () => {
    setOpen(false)
  })

  const handleOptionSelect: HandleOptionSelect = selectedValue => {
    const newInternalOptions = []
    const newSelectedValues = []

    for (const option of internalOptions) {
      if (option.value !== selectedValue) {
        if (option.selected) {
          newSelectedValues.push(option.value)
        }

        newInternalOptions.push(option)
      } else {
        const newOption = {
          ...option,
          selected: !option.selected,
        }

        if (newOption.selected) {
          newSelectedValues.push(selectedValue)
        }

        newInternalOptions.push(newOption)
      }
    }

    setInternalOptions(sortOptions(newInternalOptions))
    onChange(newSelectedValues)
  }

  const handleSelectedOptionsClear = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setInternalOptions(generateInternalOptions(options, selectedValues))
    onChange([])
  }

  const handleMenuTogle = () => setOpen(prevState => !prevState)

  let selectedCount = 0
  let selectedValue = ''

  for (const option of internalOptions) {
    if (option.selected) {
      selectedCount += 1
      selectedValue = option.label
    }
  }

  return (
    <Flex position="relative" ref={selectRef}>
      <SelectContainer open={open} onClick={handleMenuTogle}>
        {selectedCount ? (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            mr={2}
            width="100%"
          >
            <SelectedInfoContainer>
              {selectedCount > 1
                ? `${t('selected')} (${selectedCount})`
                : selectedValue}
            </SelectedInfoContainer>

            <ClearIconContainer onClick={handleSelectedOptionsClear}>
              <FiX />
            </ClearIconContainer>
          </Flex>
        ) : (
          <Placeholder>{t('select')}</Placeholder>
        )}

        <IndicatorIconContainer open={open}>
          <FiChevronDown />
        </IndicatorIconContainer>
      </SelectContainer>

      {open && (
        <Menu
          handleOptionSelect={handleOptionSelect}
          options={internalOptions}
        />
      )}
    </Flex>
  )
}

TableSelect.propTypes = {
  autoFocus: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

import React from 'react'

import loadable from '@loadable/component'

import { SpinnerBig } from 'components/ui/Spinner'

const EmployerAppPage = loadable(
  () => import('./Main' /* webpackChunkName: "pages-employerapp" */),
  { fallback: <SpinnerBig /> },
)

export default EmployerAppPage

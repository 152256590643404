import React, { useCallback, useMemo, useState } from 'react'

import map from 'lodash/map'
import omit from 'lodash/omit'

import { Button } from 'components/ui/__v2__/Button'
import { Flex } from 'components/ui/__v2__/Grid'

import { useLocale, useLocaleModal, useSetLocale } from 'hooks'
import useAppContext from 'hooks/useAppContext'

import i18n from 'i18n'

import { LocaleOption, LocaleOptionName, Modal, StyledMdCheck } from './styles'

function LocaleModal() {
  const locale = useLocale()

  const LOCALE_OPTIONS = useMemo(
    () => ({
      en: i18n('localeOptions.long.en'),
      fr: i18n('localeOptions.long.fr'),
      es: i18n('localeOptions.long.es'),
      projectsEn: i18n('localeOptions.long.projectsEn'),
    }),
    [],
  )

  const onSetLocale = useSetLocale()
  const { isOpen, close } = useLocaleModal()

  const [selectedLocale, setLocale] = useState(locale)
  const { isDemo } = useAppContext()

  const handleSelectOption = useCallback(option => {
    setLocale(option)
  }, [])

  const handleSetClick = useCallback(() => {
    onSetLocale(selectedLocale)
    close()
    window.location.reload()
  }, [close, onSetLocale, selectedLocale])

  /**
   * Custom wording is only available for Demo company
   */
  const options = useMemo(() => {
    if (isDemo) return LOCALE_OPTIONS
    return omit(LOCALE_OPTIONS, 'projectsEn')
  }, [LOCALE_OPTIONS, isDemo])

  return (
    <Modal isOpen={isOpen} onRequestClose={close}>
      <Modal.Close size={22} onClose={close} />
      <Modal.Title>{i18n('settings.locale')}</Modal.Title>
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        my={40}
      >
        {map(options, (name, option) => (
          <LocaleOption
            active={selectedLocale === option ? 1 : 0}
            key={option}
            onClick={() => handleSelectOption(option)}
          >
            <StyledMdCheck />
            <LocaleOptionName>{name}</LocaleOptionName>
          </LocaleOption>
        ))}
      </Flex>
      <Modal.Actions justifyContent="center">
        <Button width={120} onClick={handleSetClick}>
          {i18n('actions.set')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default LocaleModal

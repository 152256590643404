import { createSelector } from 'reselect'

import isEmpty from 'lodash/isEmpty'

import { denormalize } from 'helpers/redux'

import { getData } from '../data'

export const getState = state => state.employeeApp.tradeShift

export const getToIds = createSelector(getState, state =>
  Array.from(state.toIds),
)

export const getIsLoadingSuggestions = createSelector(
  getState,
  state => state.isLoadingSuggestions,
)

export const getSuggestionsIds = createSelector(getState, state =>
  Array.from(state.suggestionsIds),
)

export const getIsRequestingTrade = createSelector(
  getState,
  state => state.isRequestingTrade,
)

export const getIsRequestSuccessful = createSelector(
  getState,
  state => state.isRequestSuccessful,
)

export const getSuggestions = createSelector(
  getData,
  getSuggestionsIds,
  (data, suggestionsList) =>
    !isEmpty(suggestionsList)
      ? [...denormalize(data, 'schedules', suggestionsList)]
      : [],
)

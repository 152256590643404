import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Cell = styled.div`
  display: block;
  width: 100%;

  small {
    display: block;
    color: ${themeGet('layout.lightText')};
  }
`

import React from 'react'

import { Checkbox } from 'components/ui/__v2__/Checkbox'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { HeaderCell, HeaderTr } from './styles'

type Props = {
  checked: boolean
  disabled: boolean
  toggleAllSelection: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export function TableHeader({ checked, disabled, toggleAllSelection }: Props) {
  const t = useI18n<typeof TRANSLATIONS.shiftMoveModal.table.fields>(
    'shiftMoveModal.table.fields',
  )

  return (
    <HeaderTr>
      <HeaderCell width={30}>
        <Checkbox
          // @ts-ignore
          checked={checked}
          disabled={disabled}
          size={18}
          wrapper={{ mb: '-5px' }}
          onChange={toggleAllSelection}
        />
      </HeaderCell>

      <HeaderCell width={290}>{t('employee')}</HeaderCell>

      <HeaderCell>
        {t('department')} / {t('role')}
      </HeaderCell>
    </HeaderTr>
  )
}

import { useCallback } from 'react'

import { ACLSubjects, Actions, ScheduleConditions } from 'services/ACL/config'
import { ScheduleFeatures } from 'services/ACL/features'

import { useACL } from '../useACL'

type Props = {
  withAssignedEmployee: boolean
}

export function useSchedulePermissions(props?: Props) {
  const { checkPermissionToAction } = useACL()

  const canPerformScheduleAction = useCallback(
    ({ featureId, withAssignedEmployee, action }) => {
      let condition = {
        [withAssignedEmployee
          ? ScheduleConditions.WithAssignedEmployee
          : ScheduleConditions.WithoutAssignedEmployee]: true,
      }

      if (withAssignedEmployee === undefined) condition = {}

      const subjectItem = ACLSubjects.SchedulesFeature

      return checkPermissionToAction({
        action,
        subjectItem,
        featureId,
        condition,
      })
    },
    [checkPermissionToAction],
  )

  const canCreateShifts = canPerformScheduleAction({
    featureId: ScheduleFeatures.ManagementSchedules,
    withAssignedEmployee: true,
    action: Actions.create,
  })

  const canAccessScheduleTab = canPerformScheduleAction({
    featureId: ScheduleFeatures.AccessScheduleTab,
    action: Actions.doAction,
  })

  const canAutoAssign = canPerformScheduleAction({
    featureId: ScheduleFeatures.AutoAssign,
    action: Actions.doAction,
  })

  const canPublishSchedules = canPerformScheduleAction({
    featureId: ScheduleFeatures.PublishSchedules,
    action: Actions.doAction,
  })

  if (props === undefined) {
    return {
      canCreateShifts,
      canAccessScheduleTab,
      canAutoAssign,
      canPublishSchedules,
    }
  }

  const { withAssignedEmployee } = props

  const canEditShift = canPerformScheduleAction({
    featureId: ScheduleFeatures.ManagementSchedules,
    withAssignedEmployee,
    action: Actions.create,
  })

  const canAssignToShift = canPerformScheduleAction({
    featureId: ScheduleFeatures.ManagementSchedules,
    withAssignedEmployee,
    action: Actions.assign,
  })

  const canRemoveShift = canPerformScheduleAction({
    featureId: ScheduleFeatures.ManagementSchedules,
    withAssignedEmployee,
    action: Actions.remove,
  })

  const canCopyShifts = canPerformScheduleAction({
    featureId: ScheduleFeatures.ManagementSchedules,
    withAssignedEmployee,
    action: Actions.copy,
  })

  return {
    canCreateShifts,
    canEditShift,
    canRemoveShift,
    canAssignToShift,
    canCopyShifts,
    canAutoAssign,
    canAccessScheduleTab,
    canPublishSchedules,
  }
}

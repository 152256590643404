import React from 'react'

import { LayoutProps } from 'styled-system'

import { ShiftValidationConflictAPIItem } from 'API/RuleValidation/GraphQL/Fragments/types'

import { Container, ShiftConflictContainer } from './styles'

import { ShiftValidationConflictItem } from '../ShiftValidationConflictItem'
import { ShiftValidationConflictRulesetLink } from '../ShiftValidationConflictRulesetLink'

type Props = LayoutProps & {
  shiftConflicts: ShiftValidationConflictAPIItem[]
  rulesetId: string
}

export function ShiftValidationConflictRuleInfo({
  shiftConflicts,
  rulesetId,
  ...rest
}: Props) {
  return (
    <Container {...rest}>
      <ShiftValidationConflictRulesetLink rulesetId={rulesetId} />

      <ShiftConflictContainer>
        {shiftConflicts.map((shiftConflict, index) => (
          <ShiftValidationConflictItem
            key={`${shiftConflict.conflictType}-${shiftConflict.rule.ruleType}-${index}`}
            shiftConflict={shiftConflict}
          />
        ))}
      </ShiftConflictContainer>
    </Container>
  )
}

import {
  DocumentNode,
  LazyQueryResult,
  NetworkStatus,
  QueryHookOptions,
  QueryLazyOptions,
  QueryResult,
  TypedDocumentNode,
} from '@apollo/client'

import { PAGE_SIZES } from 'constants/pagination'

import {
  PaginationVariables,
  useOffsetPagination,
} from 'hooks/useOffsetPagination'

import {
  useApolloLazyQuery,
  useApolloQuery,
  UseQueryOptions,
} from '../API/services/Apollo'

const paging: PaginationVariables['paging'] = {
  limit: PAGE_SIZES[0],
  offset: 0,
}
/** @deprecated */
export function useOffsetPaginatedQuery<
  Query,
  QueryVariables extends Partial<PaginationVariables>
>(
  query: DocumentNode | TypedDocumentNode<Query, QueryVariables>,
  options?: QueryHookOptions<Query, Omit<QueryVariables, 'paging'>> &
    UseQueryOptions,
) {
  const queryResults = useApolloQuery<Query, QueryVariables>(query, {
    ...options,
    // @ts-ignore Will be fixed after pagination rework on gateway
    variables: {
      ...options?.variables!,
      paging,
    },
    notifyOnNetworkStatusChange: true,
  })

  const pagination = useOffsetPagination<
    Query,
    QueryVariables & PaginationVariables
  >(queryResults.refetch, {
    ...queryResults.variables,
    paging,
  })

  const loading =
    queryResults.loading || queryResults.networkStatus === NetworkStatus.refetch

  return {
    ...queryResults,
    loading,
    pagination,
  }
}

export function useLazyOffsetPaginatedQuery<
  Query,
  QueryVariables extends Partial<PaginationVariables>
>(
  query: DocumentNode | TypedDocumentNode<Query, QueryVariables>,
  options?: QueryHookOptions<Query, Omit<QueryVariables, 'paging'>> &
    UseQueryOptions,
) {
  const [load, queryResults] = useApolloLazyQuery<Query, QueryVariables>(
    query,
    {
      ...options,
      // @ts-ignore Will be fixed after pagination rework on gateway
      variables: {
        ...options?.variables!,
        paging,
      },
      notifyOnNetworkStatusChange: true,
    },
  )

  const executed = queryExecutedGuard(queryResults)

  const pagination = useOffsetPagination<
    Query,
    QueryVariables & PaginationVariables
  >(
    // @ts-ignore
    executed ? queryResults.refetch : () => null,
    executed
      ? {
          ...queryResults.variables,
          paging,
        }
      : {
          paging,
        },
    true, // FIXME: Note: This is a very messy workaround to unblock external filter being passed, this must be reworked properly
  )

  const preparedLoad = (
    variablesWithouPaging: Omit<
      QueryLazyOptions<QueryVariables>['variables'],
      'paging'
    >,
  ) => {
    const args = { variables: { ...variablesWithouPaging, paging } }
    // @ts-ignore
    return load(args)
  }

  const loading =
    queryResults.loading || queryResults.networkStatus === NetworkStatus.refetch

  return {
    ...queryResults,
    load: preparedLoad,
    loading,
    pagination,
  }
}

function queryExecutedGuard<T, V>(
  queryResults: LazyQueryResult<T, V>,
): queryResults is QueryResult<T, V> {
  return queryResults.called
}

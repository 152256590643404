import { useArchiveEmploymentLevel } from './useArchiveEmploymentLevel'
import { useCreateEmploymentLevel } from './useCreateEmploymentLevel'
import { useDeleteEmploymentLevel } from './useDeleteEmploymentLevel'
import { useEmploymentLevelsByCursor } from './useEmploymentLevelsByCursor'
import { useEmploymentLevelsPicker } from './useEmploymentLevelsPicker'
import { useUnarchiveEmploymentLevel } from './useUnarchiveEmploymentLevel'
import { useUpdateEmploymentLevel } from './useUpdateEmploymentLevel'

export const EmploymentLevelAPI = {
  byCursor: useEmploymentLevelsByCursor,
  create: useCreateEmploymentLevel,
  update: useUpdateEmploymentLevel,
  archive: useArchiveEmploymentLevel,
  unarchive: useUnarchiveEmploymentLevel,
  delete: useDeleteEmploymentLevel,
  picker: useEmploymentLevelsPicker,
}

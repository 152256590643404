import coreApiCall from 'services/API/coreApi'
import Utils from 'services/Utils'

import { statusToFilter } from '../../helpers'
import { AvailabilityRequestFilter } from '../../types'

export function availabilityRequestByOffsetQuery(
  companyId: string,
  page: JsonApi.OffsetPagingInput,
  filter: AvailabilityRequestFilter,
  include?: string,
) {
  const { areas, display, status } = filter
  const query: JsonApi.AvailabilityRequestsByOffsetArgs = {
    include,
    filter: {
      ...(areas ? Utils.JsonApi.areasFilter(areas) : {}),
      ...statusToFilter(status),
    },
    page,
    display,
  }

  return coreApiCall({
    endpoint: `/companies/${companyId}/availability_requests`,
    method: 'GET',
    query,
    paged: true,
  })
}

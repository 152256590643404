import map from 'lodash/map'

import { PAGE_SIZE } from 'constants/pagination'

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getSelectedSuggestedIds } from 'store/selectors/employeeApp/findCover'
import { getFromId } from 'store/selectors/employeeApp/schedules'

export const LOAD_SUGGESTED_EMPLOYEES = createAsyncAction(
  'findCover/LOAD_SUGGESTED_EMPLOYEES',
)

export const CREATE_SHIFT_COVER = createAsyncAction(
  'findCover/CREATE_SHIFT_COVER',
)

export const SELECT_SUGGESTED_EMPLOYEE = 'findCover/SELECT_SUGGESTED_EMPLOYEE'
export const DESELECT_SUGGESTED_EMPLOYEE =
  'findCover/DESELECT_SUGGESTED_EMPLOYEE'

export const selectSuggestedEmployee = employeeId => ({
  type: SELECT_SUGGESTED_EMPLOYEE,
  payload: employeeId,
})

export const deselectSuggestedEmployee = employeeId => ({
  type: DESELECT_SUGGESTED_EMPLOYEE,
  payload: employeeId,
})

export const loadSuggestedEmployees = chosenShift => (dispatch, getState) => {
  const fromId = chosenShift || getFromId(getState())

  return dispatch(
    apiCall({
      endpoint: `/schedules/${fromId}/covers/suggested_employees`,
      query: {
        include: 'profile',
        page: { size: PAGE_SIZE['100'] },
      },
      types: LOAD_SUGGESTED_EMPLOYEES,
      paged: true,
    }),
  )
}

export const createShiftCover = (chosenShift, selectedSuggestedIds) => (
  dispatch,
  getState,
) => {
  const fromId = chosenShift || getFromId(getState())
  const toIds = selectedSuggestedIds || getSelectedSuggestedIds(getState())

  return dispatch(
    apiCall({
      endpoint: '/shifts/covers',
      method: 'POST',
      query: {
        data: {
          type: 'shiftsCovers',
          relationships: {
            schedule: {
              data: {
                type: 'schedules',
                id: fromId,
              },
            },

            toEmployees: {
              data: map(toIds, item => ({
                type: 'employees',
                id: item,
              })),
            },
          },
        },
      },
      types: CREATE_SHIFT_COVER,
    }),
  )
}

import {
  createDeleteHandler,
  createFields,
  createReducerHandlers,
} from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { ANNUAL_REVIEW } from 'store/actions/employees/annualPerformance'

export const ANNUAL_REVIEWS_TYPE = 'perfomanceReviews' // That typo comes from BE
export const ANNUAL_REVIEWS_FIELD = 'performanceReview'

const initialState = Immutable({
  ...createFields(ANNUAL_REVIEWS_TYPE, ANNUAL_REVIEWS_FIELD),
})

const handlers = {
  ...createReducerHandlers(ANNUAL_REVIEWS_TYPE, ANNUAL_REVIEW.LOAD, {
    withReplace: true,
    mapToKey: ANNUAL_REVIEWS_FIELD,
  }),
  ...createReducerHandlers(ANNUAL_REVIEWS_TYPE, ANNUAL_REVIEW.CREATE, {
    withReplace: false,
    mapToKey: ANNUAL_REVIEWS_FIELD,
  }),
  ...createReducerHandlers(ANNUAL_REVIEWS_TYPE, ANNUAL_REVIEW.UPDATE),
  [ANNUAL_REVIEW.DELETE.SUCCESS]: createDeleteHandler(ANNUAL_REVIEWS_FIELD),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

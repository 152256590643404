import { combineReducers } from 'redux'

import cantMake from './cantMake'
import findCover from './findCover'
import openClock from './openClock'
import schedules from './schedules'
import timeClock from './timeClock'
import timeLog from './timeLog'
import tradeShift from './tradeShift'

export default combineReducers({
  schedules,
  findCover,
  tradeShift,
  timeClock,
  timeLog,
  cantMake,
  openClock,
})

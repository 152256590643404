import { useCallback } from 'react'

import { useStoreon } from 'storeon/react'

import { PR_DEPLOYMENT_STATE_KEY } from './constants'
import { PrDeploymentEvents, PrDeploymentState } from './types'

export function usePrDeploymentState() {
  const { [PR_DEPLOYMENT_STATE_KEY]: state, dispatch } = useStoreon<
    PrDeploymentState,
    PrDeploymentEvents
  >(PR_DEPLOYMENT_STATE_KEY)

  const prDeployment = state

  const setFrom = useCallback(
    (payload: string | null) => dispatch('prDeployment/setFrom', payload),
    [dispatch],
  )

  const setLogoutRedirectUrl = useCallback(
    (payload: string | null) =>
      dispatch('prDeployment/setLogoutRedirectUrl', payload),
    [dispatch],
  )

  return { setFrom, setLogoutRedirectUrl, prDeployment }
}

import { useCreateWorkWeek as create } from './useCreateWorkWeek'
import { useDeleteWorkWeek as remove } from './useDeleteWorkWeek'
import { useUpdateWorkWeek as update } from './useUpdateWorkWeek'
import { useWorkWeekByEmployee as byEmployee } from './useWorkWeekByEmployee'
import { useWorkWeekDataByOffset as getByOffset } from './useWorkWeekDataByOffset'

export const WorkWeekAPI = {
  create,
  remove,
  update,
  getByOffset,
  getByEmployee: byEmployee,
}

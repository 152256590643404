import { toHoursDays } from 'helpers/leaves'

import { Employee, EmployeeOption } from '../Types/app'

export function buildFullName(employee?: Employee) {
  const profile = employee?.profile || employee?.user?.profile

  if (!profile) {
    return 'No name'
  }

  const { firstName, lastName, email } = profile

  // TODO: check, that we do not have employees without firstName & lastName
  const fullName = firstName && lastName ? `${firstName} ${lastName}` : email

  return fullName || 'No name'
}

export function buildName(employee?: Employee) {
  const profile = employee?.profile || employee?.user?.profile

  if (!profile) {
    return 'No name'
  }

  const { firstName, lastName } = profile

  if (!firstName && !lastName) {
    return 'No name'
  }

  return `${firstName} ${lastName}`
}

/** @deprecated */
export function getCurrentEmployee(employees: Employee[], branchId: string) {
  return employees.find(employee => {
    const primaryBranchId = employee?.branch?.id
    const secondaryBranchIds = employee?.branches?.map(({ id }) => id) ?? []
    return [primaryBranchId, ...secondaryBranchIds].some(id => id === branchId)
  })
}

export function buildDayHourEquivalenceFromDays(
  employee: Employee | EmployeeOption,
  daysCount = 1,
) {
  const dayHourEquivalence =
    'leaveSettings' in employee
      ? employee.leaveSettings?.dayHourEquivalence
      : employee.employee.leaveSettings?.dayHourEquivalence

  return toHoursDays({ days: daysCount, hours: dayHourEquivalence * daysCount })
}

import { createRelationDeleteHandler } from 'redux-json-api-handlers'

import { createReducer, createRelationAddHandler } from 'helpers/redux'

import { ADD_REACTION, DELETE_REACTION } from 'store/actions/news'

const initialState = {}

const handlers = {
  [ADD_REACTION.comments.SUCCESS]: createRelationAddHandler('emojis'),
  [DELETE_REACTION.comments.SUCCESS]: createRelationDeleteHandler('emojis'),
}

export default createReducer(initialState, handlers)

import React, { ReactNode, useMemo } from 'react'

import { useAppContext } from 'hooks'

import { abilityFor } from 'services/ACL/main'

import AbilityContext from './AbilityContext'

type Props = {
  children: ReactNode
}

export default function AbilityProvider({ children }: Props) {
  const { viewer, company } = useAppContext()

  const ability = useMemo(() => abilityFor({ company, viewer }), [
    company,
    viewer,
  ])

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  )
}

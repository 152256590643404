import { useCallback, useEffect, useMemo } from 'react'

import { DateTime } from 'luxon'
import { StoreonModule } from 'storeon'
import { useStoreon } from 'storeon/react'
import { Areas } from 'Types/app'

import isEqual from 'lodash/isEqual'

import { areasToOptionsWithoutEntities } from 'helpers/areas'

import { useAppContext } from 'hooks'

import Utils from 'services/Utils'

export const HOURS_STATE_KEY = 'hours'

type DateRangeType = {
  start: string
  end: string
}

export interface HoursState {
  [HOURS_STATE_KEY]: HoursSubState
}

export interface HoursEvents {
  'hours/set': Partial<HoursSubState>
}

interface HoursSubState {
  areas: Areas
  disabled: boolean
  dateRange: DateRangeType
}

const INITIAL_STATE = {
  areas: {},
  disabled: false,
  dateRange: Utils.DateTime.getCurrentWeek(),
}

const hoursModule: StoreonModule<HoursState, HoursEvents> = store => {
  store.on('@init', () => ({
    [HOURS_STATE_KEY]: INITIAL_STATE,
  }))

  store.on('hours/set', (state, variables) => {
    const currentState = state[HOURS_STATE_KEY]
    const nextState = { ...currentState, ...variables }

    if (isEqual(nextState, currentState)) return null
    return { [HOURS_STATE_KEY]: nextState }
  })
}

export function useHoursWidgetState() {
  const {
    companySettings: { calendarStartDay },
  } = useAppContext()

  const { [HOURS_STATE_KEY]: state, dispatch } = useStoreon<
    HoursState,
    HoursEvents
  >(HOURS_STATE_KEY)

  const updateState = useCallback(
    (variables: Partial<HoursSubState>) => dispatch('hours/set', variables),
    [dispatch],
  )

  useEffect(() => {
    updateState({
      dateRange: Utils.DateTime.getCurrentWeek(calendarStartDay),
    })
  }, [calendarStartDay, updateState])

  const onAreasChange = useCallback(
    (selectedAreas: Areas) =>
      updateState({
        areas: areasToOptionsWithoutEntities(selectedAreas),
      }),
    [updateState],
  )

  const onResetDateRange = useCallback(
    () =>
      updateState({
        dateRange: Utils.DateTime.getCurrentWeek(calendarStartDay),
      }),
    [calendarStartDay, updateState],
  )

  const areas = useMemo(() => state.areas, [state])

  const dateRange = useMemo(() => {
    const storageStartIsoDate = state?.dateRange?.start
    const storageEndIsoDate = state?.dateRange?.end

    // Fallback to cover data missing in LS at first render
    const { start, end } = Utils.DateTime.getCurrentWeek(calendarStartDay)

    const startISODate = storageStartIsoDate ?? start
    const endISODate = storageEndIsoDate ?? end

    return {
      start: DateTime.fromISO(startISODate),
      end: DateTime.fromISO(endISODate),
    }
  }, [calendarStartDay, state])

  const onDateRangeChange = useCallback(
    ({ start, end }) =>
      updateState({
        dateRange: {
          start: start?.toISODate(),
          end: end?.toISODate(),
        },
      }),
    [updateState],
  )
  const isDisabled = useMemo(() => state?.disabled, [state?.disabled])

  const isInitialWeek = useMemo(
    () =>
      DateTime.fromISO(
        Utils.DateTime.getCurrentWeek(calendarStartDay).start,
      ).hasSame(dateRange.start, 'day'),
    [calendarStartDay, dateRange.start],
  )

  const onRemove = useCallback(() => updateState({ disabled: true }), [
    updateState,
  ])

  const onRestore = useCallback(() => updateState({ disabled: false }), [
    updateState,
  ])

  return {
    areas,
    dateRange,
    onAreasChange,
    onDateRangeChange,
    isDisabled,
    onRemove,
    onRestore,
    isInitialWeek,
    onResetDateRange,
  }
}

export default hoursModule

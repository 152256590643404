import { createSelector } from 'reselect'

import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import orderBy from 'lodash/orderBy'

import { denormalize } from 'helpers/redux'

import { getData } from './data'

export const getState = state => state.notifications

export const getNotificationIds = createSelector(getState, state =>
  Array.from(state.notifications),
)

export const getNotificationId = createSelector(
  getState,
  state => state.notification,
)

export const getRawNotificationShiftTrade = createSelector(
  getState,
  state => state.notificationShiftTrade,
)

export const getRawNotificationShiftCover = createSelector(
  getState,
  state => state.notificationShiftCover,
)

export const getNotificationShiftTrade = createSelector(
  getData,
  getRawNotificationShiftTrade,
  (data, notificationTradeShift) =>
    notificationTradeShift
      ? denormalize(data, 'shiftTradeSchedules', notificationTradeShift)
      : {},
)

export const getNotificationShiftCover = createSelector(
  getData,
  getRawNotificationShiftCover,
  (data, notificationShiftCover) =>
    notificationShiftCover
      ? denormalize(data, 'shiftCovers', notificationShiftCover)
      : {},
)

export const getNotifications = createSelector(
  getData,
  getNotificationIds,
  (data, notificationIds) => {
    const notifications = !isEmpty(notificationIds)
      ? denormalize(data, 'notifications', notificationIds)
      : []

    return orderBy(
      notifications.map(item => ({
        ...item,
        links: get(data, `notifications.${item.id}.links`),
      })),
      ['updatedAt'],
      ['desc'],
    )
  },
)

export const getNotificationsByTypes = types =>
  createSelector(getNotifications, data =>
    data.filter(({ type }) => types.includes(type)),
  )

export const getNotification = createSelector(
  getData,
  getNotificationId,
  (data, notificationId) => {
    const notification = notificationId
      ? denormalize(data, 'notifications', notificationId)
      : {}

    return notificationId
      ? {
          ...notification,
          links: get(data, `notifications.${notificationId}.links`),
        }
      : {}
  },
)

export const getNotificationsCount = createSelector(
  getData,
  getNotificationIds,
  (data, notificationIds) => {
    const notifications = !isEmpty(notificationIds)
      ? denormalize(data, 'notifications', notificationIds)
      : []

    return filter(notifications, note => note.state === 'new').length
  },
)

export const getIsLoading = createSelector(getState, state => state.isLoading)

export const getIsLoaded = createSelector(getState, state => state.isLoaded)

export const getIsNotificationReady = createSelector(
  getState,
  state => state.isNotificationReady,
)

export const getIsLoadingMoreNotifications = createSelector(
  getState,
  state => state.isLoadingMoreNotifications,
)

export const getNotificationsLastId = createSelector(
  getNotifications,
  notifications => get(last(notifications), 'id', ''),
)

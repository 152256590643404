import React from 'react'

import { ShiftValidationRuleAPIItem } from 'API'

import Utils from 'services/Utils'

type Props = {
  rule: ShiftValidationRuleAPIItem
}

// TODO: add common i18n for this component & RuleRenderer->EmployeeDaysOffWeekThreshold
export function EmployeeDaysOffWeekThreshold({ rule }: Props) {
  return (
    <>
      The employee has{' '}
      {Utils.Rule.getConditionText(
        rule.parameters.employeeDaysOffThreshold?.operator,
      )}{' '}
      {Utils.DateTime.secondsToDays(
        rule.parameters.employeeDaysOffThreshold?.fromSeconds!,
      )}{' '}
      {rule.parameters.employeeDaysOffThreshold?.toSeconds && (
        <>
          and{' '}
          {Utils.DateTime.secondsToDays(
            rule.parameters.employeeDaysOffThreshold.toSeconds,
          )}
        </>
      )}{' '}
      days off within the period.
    </>
  )
}

import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { TimeSelect } from 'components/blocks/__v2__'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import { Error } from './components'
import { ControllerBaseProps } from './types'

import { LabelText } from '../Input/LabelText'

type SpecificProps = {
  disabled?: boolean
  step?: number
  disabledSeconds?: {
    after?: number
    before?: number
  }
  disableMeridiem?: boolean
  disableErrorHelper?: boolean
}
type Props<T extends FieldValues> = ControllerBaseProps<T> &
  LabelProps &
  SpecificProps

export function FormTimeSelect<T extends FieldValues>({
  name,
  control,
  disabled,
  labelText,
  required,
  step,
  disabledSeconds,
  disableMeridiem,
  disableErrorHelper,
}: Props<T>) {
  const {
    field: fieldProps,
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  const { onChange, value, onBlur } = fieldProps

  return (
    <InputLabel>
      <LabelText labelText={labelText} required={required} />
      {/* @ts-ignore */}
      <TimeSelect
        disableMeridiem={disableMeridiem}
        disabledSeconds={disabledSeconds}
        isDisabled={disabled}
        maxWidth={115}
        p={1}
        step={step}
        value={value || 0}
        onBlur={onBlur}
        onChange={onChange}
      />
      {!disableErrorHelper && error && <Error error={error} />}
    </InputLabel>
  )
}

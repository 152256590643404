export const DAY_VIEW_FORMAT = {
  month: 'short',
  day: 'numeric',
} as const

export const DAY_FULL_VIEW_FORMAT = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
} as const

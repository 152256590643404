import { useCallback } from 'react'

import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import {
  TimeEntriesByCursorQuery,
  TimeEntrySummariesByCursorQuery,
  UpdateTimeEntriesMutation,
} from './GraphQL'

export function useUpdateTimeEntries() {
  const [innerUpdate, { loading, error }] = useApolloMutation<
    MutationData<'updateTimeEntries'>,
    Gateway.MutationUpdateTimeEntriesArgs
  >(UpdateTimeEntriesMutation, {
    refetchQueries: compact(
      [TimeEntriesByCursorQuery, TimeEntrySummariesByCursorQuery].map(
        getOperationName,
      ),
    ),
  })

  const updateTimeEntries = useCallback(
    async (input: Gateway.UpdateTimeEntriesInput) => {
      const result = await innerUpdate({
        variables: {
          input,
        },
      })

      return graphQLResultHasError(result)
    },
    [innerUpdate],
  )

  return { updateTimeEntries, updating: loading, updateError: error }
}

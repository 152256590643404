import { useApolloMutation } from 'API/services/Apollo'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { EMPLOYMENT_LEVEL_ERRORS_I18N } from './errors'
import { UpdateEmploymentLevelMutation } from './GraphQL'

export function useUpdateEmploymentLevel() {
  const t = useI18n<
    typeof TRANSLATIONS.employmentTerms.employmentLevel.toasts.update
  >('employmentTerms.employmentLevel.toasts.update')

  const [innerUpdate, { loading: updating }] = useApolloMutation<
    MutationData<'updateEmploymentLevel'>,
    Gateway.MutationUpdateEmploymentLevelArgs
  >(UpdateEmploymentLevelMutation, {
    silent: true,
    // TODO: Temporary solution, translated errors should come from Gateway
    onError: error => {
      const errorMessage =
        error.message.includes(EMPLOYMENT_LEVEL_ERRORS_I18N.update.message) &&
        EMPLOYMENT_LEVEL_ERRORS_I18N.update.translation

      showToast({
        type: 'error',
        title: t('error.title'),
        content: errorMessage || t('error.message'),
      })
    },
    onCompleted: () => {
      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const updateEmploymentLevel = async (
    params: Gateway.UpdateEmploymentLevelInput,
  ) =>
    innerUpdate({
      variables: {
        input: params,
      },
    })

  return { updateEmploymentLevel, updating }
}

import React from 'react'
import PropTypes from 'prop-types'

import { CustomLeave } from 'Types/app'

import { CUSTOM_LABEL_TYPE } from 'constants/labelDayType'
import { LONG_DASH } from 'constants/schedule'

import i18n from 'i18n'

import { Cell } from '../styles'

function LeavePolicyCell({ leaveDay }: { leaveDay: CustomLeave }) {
  const { policyKind, kind, referenceYearEndKind } = leaveDay || {}

  const policyText = policyKind
    ? i18n(`leavesSummary.policyKinds.${policyKind}`)
    : LONG_DASH

  return (
    <Cell>
      {kind === CUSTOM_LABEL_TYPE.HOLIDAY ? LONG_DASH : policyText}
      {referenceYearEndKind && (
        <small>
          {i18n(
            `recurrence.select.referenceYearEndDate.${referenceYearEndKind}`,
          )}
        </small>
      )}
    </Cell>
  )
}

LeavePolicyCell.propTypes = {
  leaveDay: PropTypes.object.isRequired,
}

export default LeavePolicyCell

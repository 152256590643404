import { createSelector } from 'reselect'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { getCurrentWeek } from 'helpers/date'

import { getBranchSettings } from 'store/selectors/viewer'

export const getState = state => state.employees.shifts

export const getWeek = createSelector(
  getState,
  getBranchSettings,
  (state, branchSettings) => {
    const workStartWeekDay = get(branchSettings, 'workStartWeekDay', 1)

    return !isEmpty(branchSettings) && isEmpty(state.week)
      ? getCurrentWeek(workStartWeekDay)
      : state.week
  },
)

export const getIsShiftsLoading = createSelector(
  getState,
  state => state.isShiftsLoading,
)

import { toastr } from 'react-redux-toastr'

import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import { DateTime, Interval } from 'luxon'
import { delay } from 'redux-saga'
import {
  all,
  call,
  cancel,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'

import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import reject from 'lodash/reject'

import { CompanyIdentity } from 'constants/ids'
import {
  getInitClockingTime,
  PAUSE_TIMER_OVERDUE_MINUTES as OVERDUE_MINUTES,
  PAUSE_TIMER_STATES,
  TICK_TIME,
  TIMER_STATES,
} from 'constants/timeClock'

import { isSameDay, toISOString } from 'helpers/date'
import { waitForTypes } from 'helpers/sagas'

import _ from 'i18n'

import { showError } from 'services/API'

import { loadLocations } from 'store/actions/areas'
import { LOG_OUT } from 'store/actions/auth'
import {
  CLOCK_IN,
  CLOCK_OUT,
  clockOut,
  INIT,
  initDone,
  LOAD_ACTIVE_TIMER,
  LOAD_SCHEDULES,
  loadActiveTimer,
  loadSchedules,
  RESTORE_TRACKING,
  restoreTracking,
  SET_TIME,
  setPauseTimerId,
  setScheduleId,
  setTime,
  setTimings,
  START_TIMER,
  startTimer,
  STOP_TIMER,
  stopPauseTimer,
  stopTimer,
} from 'store/actions/employeeApp/timeClock'
import {
  getPauseTimer,
  getPauseTimerId,
  getScheduleId,
  getSchedules,
  getTime,
  getTimeEntry,
  getTimeEntryId,
} from 'store/selectors/employeeApp/timeClock'
import { getCompany } from 'store/selectors/viewer'

const isSameDayWithToday = time => {
  return isSameDay(DateTime.local(), time)
}

const isSameDayWithTodayOrTomorrow = time => {
  const today = DateTime.local()
  const tomorrow = today.plus({ days: 1 })

  const interval = Interval.fromDateTimes(
    today.startOf('day'),
    tomorrow.endOf('day'),
  )

  return interval.contains(DateTime.fromISO(time))
}

const isScheduleStartsOrEndsTodayOrTomorrow = schedule => {
  const shiftStartAt = get(schedule, 'shiftsJob.shift.startAt', '')
  const shiftFinishAt = get(schedule, 'shiftsJob.shift.finishAt', '')

  return (
    !!shiftStartAt &&
    !!shiftFinishAt &&
    (isSameDayWithToday(shiftStartAt) ||
      isSameDayWithTodayOrTomorrow(shiftFinishAt)) &&
    DateTime.local().diff(DateTime.fromISO(shiftFinishAt)).toObject()
      .milliseconds < 0
  )
}

// const isScheduleEndsInFuture = schedule =>
//   isBefore(toISOString(), schedule.shiftsJob.shift.finishAt)

const overduesDuration = (time, duration) => {
  const maxAcceptedTime = DateTime.fromISO(time).plus({
    minutes: duration + OVERDUE_MINUTES,
  })
  const overTime = maxAcceptedTime.diff(DateTime.fromISO(toISOString())) < 0

  return overTime
}

function* initialLoad() {
  while (true) {
    yield take(INIT)
    yield call(resetTimings)
    yield put(loadSchedules())
    yield put(loadActiveTimer())
    yield put(loadLocations({ display: 'emergencyOpenClock' }))

    yield waitForTypes([LOAD_SCHEDULES.SUCCESS, LOAD_ACTIVE_TIMER.SUCCESS])

    yield call(restoreTimings)
    yield call(updateSchedule, true)

    const timeEntry = yield select(getTimeEntry)

    if (!isEmpty(timeEntry) && !isEmpty(get(timeEntry, 'schedule'))) {
      yield put(restoreTracking())
    }

    yield put(initDone())
  }
}

function* updateSchedule(withFallback) {
  const timeEntry = yield select(getTimeEntry)
  const schedules = yield select(getSchedules)

  const filteredSchedules = orderBy(
    reject(schedules, schedule => {
      // Main check
      const stopped = get(schedule, ['timeEntry', 'stopped'], false)

      // Fallback checks
      const state = get(schedule, ['timeEntry', 'state'])
      const pending = state === TIMER_STATES.pending
      const approved = state === TIMER_STATES.approved
      const discarded = state === TIMER_STATES.discarded

      return stopped || pending || approved || discarded
    }),
    [
      item => item.shiftsJob.shift.startAt,
      item => item.shiftsJob.shift.finishAt,
    ],
  )

  const matchedSchedule = find(
    filteredSchedules,
    isScheduleStartsOrEndsTodayOrTomorrow,
  )

  let schedule = matchedSchedule || null

  if (withFallback) {
    const timeEntrySchedule = get(timeEntry, 'schedule', null)
    const timeEntryState = get(timeEntry, 'state')
    if (
      // Exclude open clock
      (timeEntryState === 'active' && !isEmpty(timeEntrySchedule)) ||
      isEmpty(schedule)
    ) {
      schedule = timeEntrySchedule
    }
  }

  yield put(setScheduleId(schedule ? schedule.id : null))
}

function* resetTimings() {
  yield put(setTime(getInitClockingTime()))
  yield put(setTimings(null, null))
}

function* restoreTimings() {
  const timeEntry = yield select(getTimeEntry)

  if (isEmpty(timeEntry) || isEmpty(get(timeEntry, 'schedule'))) return

  const startTime = timeEntry.startAt
  const diffInMs = differenceInMilliseconds(
    DateTime.utc().toJSDate(),
    DateTime.fromISO(startTime).toJSDate(),
  )
  const time = getInitClockingTime() + diffInMs

  yield put(setTime(time))
  yield put(setTimings(startTime, null))
}

function* syncTimeClock() {
  while (true) {
    const { clockIn } = yield race({
      clockIn: take(CLOCK_IN),
      restoreTracking: take(RESTORE_TRACKING),
    })

    yield fork(syncStart)

    if (clockIn) {
      const { startTimerFailure } = yield race({
        startTimerSuccess: take(START_TIMER.SUCCESS),
        startTimerFailure: take(START_TIMER.FAILURE),
      })

      if (startTimerFailure) {
        // eslint-disable-next-line no-continue
        continue
      }
    }

    const bgClockingTask = yield fork(bgSync)

    while (true) {
      const { clockOutAction, logOutAction, stopTimerAction } = yield race({
        logOutAction: take(LOG_OUT),
        clockOutAction: take(CLOCK_OUT),
        stopTimerAction: take(STOP_TIMER.SUCCESS),
      })

      if (logOutAction || stopTimerAction) {
        yield cancel(bgClockingTask)
        break
      }

      if (clockOutAction) {
        yield fork(syncEnd, bgClockingTask)
      }
    }
  }
}

function* syncStart() {
  const scheduleId = yield select(getScheduleId)
  const timeEntry = yield select(getTimeEntry)
  const startAt = toISOString()

  if (scheduleId && get(timeEntry, 'state') !== TIMER_STATES.active) {
    yield put(startTimer({ scheduleId, startAt }))

    yield take(START_TIMER.SUCCESS)

    yield put(setTimings(startAt, null))
  }
}

function* syncEnd(bgClockingTask) {
  const scheduleId = yield select(getScheduleId)
  yield put(stopTimer({ scheduleId }))

  if (yield take(STOP_TIMER.SUCCESS)) {
    const timeEntry = yield select(getTimeEntry)
    yield put(setTime(getInitClockingTime()))
    yield put(setTimings(timeEntry.startAt, toISOString()))

    yield call(updateSchedule)
    yield cancel(bgClockingTask)
  }
}

function* bgSync() {
  while (true) {
    yield call(delay, TICK_TIME)
    yield call(tick)
  }
}

function* tick() {
  const time = yield select(getTime)
  yield put(setTime(time + TICK_TIME))
}

export function* actualizePauseTimer() {
  const timer = yield select(getTimeEntry)

  if (!timer) return

  const prevId = yield select(getPauseTimerId)
  const nextId = get(
    find(timer.pauseTimers, { state: PAUSE_TIMER_STATES.active }),
    'id',
    null,
  )

  if (prevId !== nextId) {
    yield put(setPauseTimerId(nextId))
  }
}

export function* watchOverduePauseTimer() {
  while (yield take(SET_TIME)) {
    const company = yield select(getCompany)
    const pauseTimer = yield select(getPauseTimer)

    if (pauseTimer && pauseTimer.state === PAUSE_TIMER_STATES.active) {
      const isDeluxe = company.identity === CompanyIdentity.Deluxe
      const isOverdue = overduesDuration(
        pauseTimer.startAt,
        pauseTimer.pause.duration,
      )

      // eslint-disable-next-line
      if (isDeluxe && !pauseTimer.paid || !isOverdue) continue

      toastr.error(_('timeClock.breakEndedMessage', { count: OVERDUE_MINUTES }))

      const timerId = yield select(getTimeEntryId)

      yield put(stopPauseTimer(timerId))
    }
  }
}

function* watchStartTimerFailure() {
  while (true) {
    const result = yield take(START_TIMER.FAILURE)
    showError(result)

    yield put(clockOut())
    yield call(resetTimings)
  }
}

function* watchStopTimerFailure() {
  while (true) {
    const result = yield take(STOP_TIMER.FAILURE)
    showError(result)
  }
}

export default function* root() {
  yield all([
    fork(initialLoad),
    fork(syncTimeClock),
    fork(watchOverduePauseTimer),
    fork(watchStartTimerFailure),
    fork(watchStopTimerFailure),
    takeLatest(SET_TIME, actualizePauseTimer),
  ])
}

import {
  createDeleteHandler,
  createFields,
  createLoadHandler,
  createReducerHandlers,
} from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import {
  CREATE_PAYROLL_RULE,
  DELETE_PAYROLL_RULE,
  LOAD_PAYROLL_RULES,
} from 'store/actions/payrollRules'

const initialState = Immutable({
  ...createFields('payrollRules'),
})

const handlers = {
  ...createReducerHandlers('payrollRules', LOAD_PAYROLL_RULES),

  [CREATE_PAYROLL_RULE.SUCCESS]: createLoadHandler('payrollRules'),

  [DELETE_PAYROLL_RULE.SUCCESS]: createDeleteHandler('payrollRules'),
}

export default createReducer(initialState, handlers)

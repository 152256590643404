import { setContext } from '@apollo/client/link/context'

import { AuthService } from 'services/Auth'

export function gatewayAuthLink() {
  return setContext(async (_, { headers }) => ({
    headers: {
      ...headers,
      ...(await AuthService.getAuthHeaders()),
    },
  }))
}

import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'

import { AvailabilityRequestsCountQuery } from './GraphQL'

type QueryProps = Pick<QueryHookOptions, 'skip' | 'pollInterval'> | undefined

export function useAvailabilityRequestsCount(
  filter: Gateway.AvailabilityRequestsFilter,
  queryProps: QueryProps,
) {
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'availabilityRequestsCount'>,
    Gateway.QueryAvailabilityRequestsCountArgs
  >(AvailabilityRequestsCountQuery, {
    variables: {
      filter,
    },
    skip: queryProps?.skip,
    pollInterval: queryProps?.pollInterval,
    fetchPolicy: 'network-only',
  })

  const availabilityRequestsCount = data?.availabilityRequestsCount

  return {
    availabilityRequestsCount,
    loading,
    refetch,
  }
}

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

export const WORK_AGREEMENT = {
  LOAD: createAsyncAction('work_agreement/LOAD'),
  CREATE: createAsyncAction('work_agreement/CREATE'),
  UPDATE: createAsyncAction('work_agreement/UPDATE'),
  DELETE: createAsyncAction('work_agreement/DELETE'),
}

const INCLUDE = ['employee', 'attachment'].join(',')

export const loadWorkAgreements = ({
  employeeId,
  number,
  size,
  sort = '-date,created_at',
}) => dispatch => {
  const paged = !!number && !!size
  const page = paged ? { number, size } : {}

  return dispatch(
    apiCall({
      endpoint: `/employees/${employeeId}/work_agreements`,
      types: WORK_AGREEMENT.LOAD,
      method: 'GET',
      query: {
        include: INCLUDE,
        page,
        sort,
      },
      paged,
    }),
  )
}

export const createWorkAgreement = ({
  employeeId,
  attributes,
  attachmentId,
}) => dispatch => {
  const attachment = attachmentId
    ? {
        attachment: {
          data: {
            id: attachmentId,
            type: 'files',
          },
        },
      }
    : null
  dispatch(
    apiCall({
      endpoint: `/employees/${employeeId}/work_agreements`,
      types: WORK_AGREEMENT.CREATE,
      method: 'POST',
      query: {
        include: INCLUDE,
        data: {
          attributes,
          relationships: {
            ...attachment,
          },
        },
      },
    }),
  )
}

export const updateWorkAgreement = ({
  workAgreementId,
  attributes,
  relationships,
}) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/work_agreements/${workAgreementId}`,
      types: WORK_AGREEMENT.UPDATE,
      method: 'PATCH',
      query: {
        include: INCLUDE,
        data: {
          attributes,
          relationships,
        },
      },
    }),
  )
}

export const deleteWorkAgreement = workAgreementId => dispatch =>
  dispatch(
    apiCall({
      endpoint: `/work_agreements/${workAgreementId}`,
      types: WORK_AGREEMENT.DELETE,
      method: 'DELETE',
      payload: {
        deletedId: workAgreementId,
      },
    }),
  )

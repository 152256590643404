import React from 'react'

import { API } from 'API'
import { Schedule } from 'Types/schedule'

import { Divider } from 'components/ui/__v2__/Divider'
import { Box } from 'components/ui/__v2__/Grid'
import { Button } from 'components/ui/__v3__'
import { Modal } from 'components/ui/__v3__/Modal'

import { useI18n } from 'hooks'

import i18n, { TRANSLATIONS } from 'i18n'

import { List, Text } from './styles'

type Props = {
  open: boolean
  schedules: Schedule[]
  onClose: (success: boolean) => void
}

export function UnpublishSchedulesModal({ open, schedules, onClose }: Props) {
  const t = useI18n<
    typeof TRANSLATIONS.schedule.unpublishSchedules.unpublishSchedulesModal
  >('schedule.unpublishSchedules.unpublishSchedulesModal')

  const {
    unpublishSchedules,
    unpublishingSchedules,
  } = API.Schedule.unpublishSchedules()

  async function handleSubmit() {
    const result = await unpublishSchedules({
      id: { in: schedules.map(schedule => schedule.id) },
    })

    onClose(!result.hasError)
  }

  const handleClose = () => {
    onClose(false)
  }

  return (
    <Modal.Container isOpen={open} padding={32}>
      <Modal.Close onClose={handleClose} />
      <Modal.Title>{t('title')}</Modal.Title>

      <Box justifyContent="center" m="0 auto" maxWidth="312px">
        <Text>
          {t('shift', {
            count: schedules?.length,
          })}{' '}
          <b>{schedules?.map(schedule => schedule.shift.name).join(', ')}</b>{' '}
          {t('description')}
        </Text>
        <List>
          <li>{t('configurationStored')}</li>
          <li>{t('futureTimecardsRemoved')}</li>
          <li>{t('employeeWillRemain')}</li>
        </List>

        <Divider />

        <Modal.Actions gap={'17px'} justifyContent="center" mt={'16px'}>
          <Button secondary width={'147px'} onClick={handleClose}>
            {i18n('actions.cancel')}
          </Button>

          <Button
            disabled={unpublishingSchedules}
            width={'147px'}
            onClick={handleSubmit}
          >
            {t('submit')}
          </Button>
        </Modal.Actions>
      </Box>
    </Modal.Container>
  )
}

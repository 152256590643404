import React, { useCallback } from 'react'
import { Field } from 'react-final-form'
import { PropTypes } from 'prop-types'

import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { FormMultiSelect, FormSelect } from 'components/ui/__v2__/Form'
import { Box, Flex, Span } from 'components/ui/Grid'

import { LONG_DASH } from 'constants/schedule'

import _ from 'i18n'

import { Answer, NoAnswer } from '../styles'

function LabeledSelect({
  name,
  isRequired,
  isReadOnly,
  onChange,
  label,
  placeholder,
  isMulti,
  options,
  ...rest
}) {
  const getLabel = useCallback(
    value => {
      return get(
        find(options, ({ value: optionValue }) => optionValue === value),
        'label',
        LONG_DASH,
      )
    },
    [options],
  )

  const element = props => {
    const fieldValue = props.input.value

    if (isReadOnly) {
      if (isMulti) {
        return !isEmpty(fieldValue) ? (
          <Answer>{map(fieldValue, getLabel).join(', ')}</Answer>
        ) : (
          <NoAnswer>{_('formBuilder.fallbacks.noAnswerProvided')}</NoAnswer>
        )
      }
      return fieldValue ? (
        <Answer>{getLabel(fieldValue)}</Answer>
      ) : (
        <NoAnswer>{_('formBuilder.fallbacks.noAnswerProvided')}</NoAnswer>
      )
    }
    return isMulti ? (
      <Box>
        <FormMultiSelect
          {...props}
          {...rest}
          isClearable
          options={options}
          placeholder={placeholder}
          withPortal
          onBlur={onChange}
        />
      </Box>
    ) : (
      <Box maxWidth="180px">
        <FormSelect
          {...props}
          {...rest}
          isClearable
          options={options}
          placeholder={placeholder}
          withPortal
          onBlur={onChange}
        />
      </Box>
    )
  }

  return (
    <Flex flexDirection="column">
      <Span>
        {label}
        {isRequired && <sup>*</sup>}
      </Span>

      <Field name={name}>{element}</Field>
    </Flex>
  )
}

LabeledSelect.defaultProps = {
  isReadOnly: false,
}

LabeledSelect.propTypes = {
  ...Field.propTypes,
  ...FormMultiSelect.propTypes,
  isReadOnly: PropTypes.bool,
  options: PropTypes.array.isRequired,
}

export default LabeledSelect

import { i18n } from 'i18n'

// TODO: Temporary solution, translated errors should come from Gateway
export const RULESET_ERRORS_I18N: {
  uniqueCode: {
    message: string
    translation: string
  }
} = {
  uniqueCode: {
    message:
      'duplicate key value violates unique constraint "rulesets_workflow_id_code_index"',
    translation: i18n('ruleset.toasts.uniqueCode'),
  },
}

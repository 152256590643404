import React from 'react'

import { AiOutlineDownload as DownloadIcon } from 'react-icons/ai'

import { Popover } from 'components/ui/__v2__/Popover'
import { ButtonProps, IconButton } from 'components/ui/__v3__/Button'

type Props = {
  content: string
  onDownload: (args?: any) => void
} & ButtonProps
export function Download({ content, onDownload, ...rest }: Props) {
  return (
    // @ts-ignore
    <Popover content={content} trigger="mouseenter">
      <IconButton {...rest} onClick={onDownload}>
        <DownloadIcon />
      </IconButton>
    </Popover>
  )
}

import {
  createDeleteHandler,
  createFields,
  createReducerHandlers,
} from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { TASK_TEMPLATE } from 'store/actions/company/tasks'

export const TASK_TEMPLATE_TYPE = 'tasks'
export const TASK_TEMPLATE_FIELD = 'task'
export const TASK_BY_ID_TEMPLATE_FIELD = 'taskById'

const initialTaskById = createFields(
  TASK_TEMPLATE_TYPE,
  TASK_BY_ID_TEMPLATE_FIELD,
  true,
)

const initialState = Immutable({
  ...createFields(TASK_TEMPLATE_TYPE, TASK_TEMPLATE_FIELD),
  ...initialTaskById,
})

const handlers = {
  ...createReducerHandlers(TASK_TEMPLATE_TYPE, TASK_TEMPLATE.LOAD, {
    withReplace: true,
    mapToKey: TASK_TEMPLATE_FIELD,
  }),
  ...createReducerHandlers(TASK_TEMPLATE_TYPE, TASK_TEMPLATE.LOAD_BY_ID, {
    withReplace: true,
    mapToKey: TASK_BY_ID_TEMPLATE_FIELD,
    singular: true,
  }),
  ...createReducerHandlers(TASK_TEMPLATE_TYPE, TASK_TEMPLATE.CREATE, {
    withReplace: false,
    mapToKey: TASK_TEMPLATE_FIELD,
  }),
  ...createReducerHandlers(TASK_TEMPLATE_TYPE, TASK_TEMPLATE.UPDATE),
  [TASK_TEMPLATE.DELETE.SUCCESS]: createDeleteHandler(TASK_TEMPLATE_FIELD),

  [TASK_TEMPLATE.CLEAR_TASK_BY_ID]: state => state.merge(initialTaskById),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 15px;
`

export const IconText = styled.span`
  color: ${({ theme }) => theme.layout.primary};
`

import { SelectOption } from 'Types/common'

import { i18n } from 'i18n'

export enum Gender {
  Male = 'male',
  Female = 'female',
}

export const GENDER_OPTIONS: SelectOption<App.Gender>[] = [
  {
    label: i18n('employees.male'),
    value: Gender.Male,
  },
  {
    label: i18n('employees.female'),
    value: Gender.Female,
  },
]

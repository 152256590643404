import ArchiveTimeBucketMutation from './ArchiveTimeBucket.graphql'
import CreateTimeBucketChildMutation from './CreateTimeBucketChild.graphql'
import CreateTimeBucketParentMutation from './CreateTimeBucketParent.graphql'
import DeleteTimeBucketMutation from './DeleteTimeBucket.graphql'
import UnarchiveTimeBucketMutation from './UnarchiveTimeBucket.graphql'
import UpdateTimeBucketChildMutation from './UpdateTimeBucketChild.graphql'
import UpdateTimeBucketParentMutation from './UpdateTimeBucketParent.graphql'

export {
  ArchiveTimeBucketMutation,
  CreateTimeBucketChildMutation,
  CreateTimeBucketParentMutation,
  DeleteTimeBucketMutation,
  UnarchiveTimeBucketMutation,
  UpdateTimeBucketChildMutation,
  UpdateTimeBucketParentMutation,
}

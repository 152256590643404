import ReduxToastr from 'react-redux-toastr'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export default styled(ReduxToastr)`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .top-left,
  .top-right,
  .top-center,
  .bottom-left,
  .bottom-right,
  .bottom-center {
    position: fixed;
    // TODO: get z-index from theme
    z-index: 99999999;
    padding: 0 10px;
  }

  .top-left,
  .top-right,
  .top-center {
    top: 128px;
  }

  .top-right,
  .bottom-right {
    right: 0;
  }

  .bottom-left,
  .bottom-right,
  .bottom-center {
    bottom: 0;
  }

  .top-left,
  .bottom-left {
    left: 0;
  }

  .top-center,
  .bottom-center {
    left: 50%;
  }

  .toastr {
    background-color: ${themeGet('colors.WHITE')};
    width: 100%;
    min-height: 83px;
    padding-right: 30px;
    overflow: hidden;
    margin: 10px 0;
    border-radius: 6px;
    position: relative;
    // TODO: get z-index from theme
    z-index: 2;
    color: ${themeGet('colors.DEFALUT_TEXT')};
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.21);
    display: flex;
    justify-content: center;
    flex-direction: column;

    transition: all ${themeGet('transitionTime')};

    > div {
      display: flex;
      flex-direction: row;
    }

    &:hover:not(.rrt-message) {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.31);
    }

    .toastr-status {
      width: 100%;
      height: 5px;
      background-color: ${themeGet('colors.WHITE')};

      &.success {
      }

      &.warning {
      }

      &.info {
      }

      &.error {
      }
    }

    .rrt-left-container {
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      .rrt-holder {
        width: 30px;
        height: 30px;
        margin-left: 14px;
        margin-top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .toastr-icon {
        fill: ${themeGet('colors.DEFAULT_TEXT')};
      }
    }

    .rrt-middle-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 5px;

      .rrt-title {
        font-size: 14px;
        font-family: ${themeGet('font')};
        font-weight: normal;
      }
    }

    .rrt-right-container {
    }

    .close-toastr {
      width: 14px;
      height: 14px;
      position: absolute;
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      border: none;
      outline: none;
      opacity: 0.7;
      top: 4px;
      right: 4px;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &:focus {
        outline: none;
      }
    }

    &.rrt-info,
    &.rrt-success,
    &.rrt-warning,
    &.rrt-error {
      color: ${themeGet('colors.DEFAULT_TEXT')};
    }

    &.rrt-info {
      .rrt-progressbar {
      }
    }

    &.rrt-success {
      .rrt-progressbar {
      }
    }

    &.rrt-warning {
      .rrt-progressbar {
      }
    }

    &.rrt-error {
      .rrt-progressbar {
      }
    }

    &.rrt-info {
      .rrt-progressbar {
        background-color: #ccc;
      }
      .toastr-icon {
        fill: #333 !important;
        display: none;
      }
      .rrt-left-container {
        width: 30px;
      }
    }

    &.rrt-message {
      opacity: 1;
      border: 1px solid #dbdbdb;

      .rrt-title {
        width: 90%;
        height: 50px;
        text-align: center;
        overflow: hidden;
        font-size: 1.2em;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 50px;
        padding: 0 20px;
      }

      .rrt-text {
        width: 100%;
        max-height: 400px;
        overflow: hidden;
        overflow-y: auto;
        border-top: 1px solid #f1f1f1;
        border-bottom: 1px solid #f1f1f1;
        background-color: white;
        padding: 15px;
        font-size: 1.1em;
        margin-bottom: 20px;

        img {
          display: block;
          margin: 10px auto;
          max-width: 100%;
        }
      }

      .close-toastr {
        height: 50px;
      }
    }

    .rrt-progress-container {
      height: 5px;
      margin: 0 -20px -20px -60px;
      position: absolute;
      bottom: 20px;
      width: 100%;

      .rrt-progressbar {
        -webkit-border-radius: 0 0 0 4px;
        -moz-border-radius: 0 0 0 4px;
        border-radius: 0 0 0 4px;
        height: 100%;
      }
    }
  }

  .toastr-attention {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    // TODO: get z-index from theme
    z-index: 1;
  }

  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;

    &.bounceIn {
      animation-duration: 0.7s;
    }

    &.bounceOut {
      animation-duration: 0.5s;
    }

    &.bounceIn {
      animation-name: bounceIn;
    }

    &.bounceOut {
      animation-name: bounceOut;
    }

    &.fadeIn {
      animation-name: fadeIn;
      animation-duration: 0.7s;
    }

    &.fadeOut {
      animation-name: fadeOut;
      animation-duration: 0.3s;
    }

    &.bounceInDown {
      animation-name: bounceInDown;
    }

    &.bounceOutUp {
      animation-name: bounceOutUp;
    }
  }

  @keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
      opacity: 1;
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes bounceOut {
    20% {
      transform: scale3d(0.9, 0.9, 0.9);
    }

    50%,
    55% {
      opacity: 1;
      transform: scale3d(1.1, 1.1, 1.1);
    }

    to {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes bounceInDown {
    from,
    60%,
    75%,
    90%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      transform: translate3d(0, -3000px, 0);
    }

    60% {
      opacity: 1;
      transform: translate3d(0, 25px, 0);
    }

    75% {
      transform: translate3d(0, -10px, 0);
    }

    90% {
      transform: translate3d(0, 5px, 0);
    }

    to {
      -webkit-transform: none;
      transform: none;
    }
  }

  @keyframes bounceOutUp {
    20% {
      transform: translate3d(0, -10px, 0);
    }

    40%,
    45% {
      opacity: 1;
      transform: translate3d(0, 20px, 0);
    }

    to {
      opacity: 0;
      transform: translate3d(0, -2000px, 0);
    }
  }
`

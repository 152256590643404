import { useApolloMutation } from 'API/services/Apollo'

import { UpdateWorkWeekMutation } from './GraphQL'

export function useUpdateWorkWeek() {
  const [updateWorkWeek, { loading: updating }] = useApolloMutation<
    MutationData<'updateWorkWeek'>,
    Gateway.MutationUpdateWorkWeekArgs
  >(UpdateWorkWeekMutation, {
    translationKey: 'workWeek.toasts.update',
  })

  const update = async (
    variables: Omit<Gateway.UpdateWorkWeekInput, 'companyId'>,
  ) => {
    await updateWorkWeek({
      variables: {
        input: {
          ...variables,
        },
      },
    })
  }

  return { update, updating }
}

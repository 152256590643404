import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'components/ui/__v2__/Grid'
import { TextOverflow } from 'components/ui/__v2__/TextOverflow'

export const Tag = styled(Flex)`
  color: ${themeGet('layout.bg')};
  background: ${themeGet('layout.primary')};
  font-size: 0.8em;
  padding: 0 6px;
  flex: 0 0 auto;
  border-radius: 100vw;
  align-items: center;
  height: 1rem;
`

export const Cell = styled(TextOverflow)`
  display: block;
  width: ${props => (props.fullLength ? 100 : 70)}%;

  small {
    display: block;
    color: ${themeGet('layout.lightText')};
  }
`

export const Details = styled.small`
  display: block;
  white-space: initial;
`

import { toastr } from 'react-redux-toastr'

import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'

import identity from 'lodash/identity'
import map from 'lodash/map'
import pickBy from 'lodash/pickBy'
import trim from 'lodash/trim'

import _ from 'i18n'

import {
  ADD_DEPARTMENTS_AND_ROLES,
  CHANGE_LOCATION_FILTER,
  CHANGE_LOCATION_SORT,
  CHANGE_PAGE_NUMBER,
  CHANGE_PAGE_SIZE,
  INIT,
  loadLocations,
} from 'store/actions/locations'
import {
  getLocationFilters,
  getLocationSort,
  getPageNumber,
  getPageSize,
} from 'store/selectors/locations'

function* watchInitLoad() {
  while (true) {
    yield take(INIT)
    yield call(watchSearch)
  }
}

function* watchSearch({ payload } = {}) {
  const withLoading = payload?.withLoading ?? true

  const locationFilters = yield select(getLocationFilters)
  const sort = yield select(getLocationSort)
  const pageNumber = yield select(getPageNumber)
  const pageSize = yield select(getPageSize)

  const page = {
    number: pageNumber,
    size: pageSize,
  }

  const {
    name,
    address,
    locationCode,
    locationManager,
    tags,
    tagCond,
  } = locationFilters

  const tagIds = map(tags, 'id').join(',')

  const filters = pickBy(
    {
      name: trim(name),
      address: trim(address),
      location_code: trim(locationCode),
      manager_name: trim(locationManager),
      location_tag_ids: tagIds,
      location_tag_cond: tagIds.length && tagCond,
    },
    identity,
  )

  yield put(
    loadLocations(
      {
        filters,
        page,
        sort,
      },
      { withLoading },
    ),
  )
}

function* watchAddDepartmentAndRole() {
  while (true) {
    const { success, error } = yield race({
      success: take(ADD_DEPARTMENTS_AND_ROLES.SUCCESS),
      error: take(ADD_DEPARTMENTS_AND_ROLES.FAILURE),
    })

    if (success) {
      toastr.info(_('locations.depAndRoleCreated'))
    }
    if (error) {
      toastr.error(_('locations.depAndRoleFailed'))
    }
  }
}

export default function* root() {
  yield all([
    fork(watchInitLoad),
    fork(watchAddDepartmentAndRole),
    takeLatest(
      [
        CHANGE_LOCATION_FILTER,
        CHANGE_LOCATION_SORT,
        CHANGE_PAGE_SIZE,
        CHANGE_PAGE_NUMBER,
      ],
      watchSearch,
    ),
  ])
}

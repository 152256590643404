import React, { useCallback, useEffect, useState } from 'react'

import { ShiftValidationEssentialAPIItem } from 'API'

import { Flex, SpacedColumn } from 'components/ui/__v2__/Grid'
import { Button } from 'components/ui/__v3__'
import { Spinner } from 'components/ui/Spinner'

import { i18n } from 'i18n'

import { useShiftAssignmentContext } from '../../../context'
import { getShiftConflictsByEmployeeId } from '../../../helpers'
import { useShiftValidation } from '../../../hooks'
import { SectionTitle, SubtleText } from '../../../styles'
import { IntendedAssignment } from '../components'
import { VALIDATION_CONFLICT_ICON_SIZE } from '../components/constants'
import { AssignmentsList } from '../styles'

type Props = {
  shift: Gateway.Shift
  hasShiftValidation: boolean
  deferredLoading: boolean
  onChangeDeferredLoading: (value: boolean) => void
}

export function DraftAssignments({
  shift,
  hasShiftValidation,
  deferredLoading,
  onChangeDeferredLoading,
}: Props) {
  const [shiftValidations, setShiftValidations] = useState<
    ShiftValidationEssentialAPIItem[]
  >([])

  useEffect(() => {
    setShiftValidations([])
  }, [shift])

  const {
    draftAssignments,
    revertDraftAssignment,
  } = useShiftAssignmentContext()

  const { validatingShift, validateShift } = useShiftValidation()

  const handleValidateShift = useCallback(async () => {
    onChangeDeferredLoading(true)

    const employeeIds = draftAssignments.map(draftAssignment =>
      String(draftAssignment.employee.id),
    )
    const validations = await validateShift({ employeeIds, shift })
    setShiftValidations(validations)

    onChangeDeferredLoading(false)
  }, [draftAssignments, shift, validateShift, onChangeDeferredLoading])

  const handleGetShiftConflictsByEmployeeId = (employeeId: number) =>
    getShiftConflictsByEmployeeId(employeeId, shiftValidations)

  // NOTE: since we have the same ruleset for all validations
  //       we can take the ruleset from any validation
  const rulesetId =
    shiftValidations.length > 0 ? shiftValidations[0].rulesetId : ''

  return (
    <SpacedColumn>
      <Flex alignItems="center" gap={2}>
        <SectionTitle>{i18n('shiftAssign.newAssignments.title')}</SectionTitle>

        {hasShiftValidation && (
          <Button
            disabled={
              draftAssignments.length === 0 ||
              validatingShift ||
              deferredLoading
            }
            minWidth="75px"
            px={0}
            xSmall
            onClick={handleValidateShift}
          >
            {i18n('shiftAssign.newAssignments.validate')}

            {validatingShift && (
              <Spinner size={VALIDATION_CONFLICT_ICON_SIZE} />
            )}
          </Button>
        )}
      </Flex>

      {draftAssignments.length > 0 ? (
        <AssignmentsList>
          {draftAssignments.map(draftAssignment => (
            <IntendedAssignment
              firstName={draftAssignment.employee.user.profile.firstName}
              key={draftAssignment.employee.id}
              lastName={draftAssignment.employee.user.profile.lastName}
              rulesetId={rulesetId}
              shiftConflicts={handleGetShiftConflictsByEmployeeId(
                draftAssignment.employee.id,
              )}
              validatingShift={validatingShift}
              onRevertAssignmentClick={() =>
                revertDraftAssignment(draftAssignment)
              }
            />
          ))}
        </AssignmentsList>
      ) : (
        <SubtleText>
          {i18n('shiftAssign.newAssignments.nooneAssignedYet')}
        </SubtleText>
      )}
    </SpacedColumn>
  )
}

/* eslint-disable no-redeclare */
import { useCallback } from 'react'

import { DeepKeys } from '@tanstack/react-table'

import _, { TranslationKeys } from 'i18n'

export function useI18n(key: string): (subKey: string, opts?: {}) => string
export function useI18n<T>(
  key: TranslationKeys,
): (subKey: DeepKeys<T>, opts?: {}) => string

export function useI18n<T>(key: TranslationKeys | string) {
  return useCallback(
    (subKey: DeepKeys<T> | string, opts?: {}): string =>
      _(`${key}.${subKey}`, opts),
    [key],
  )
}

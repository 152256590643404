import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import noop from 'lodash/noop'
import reduce from 'lodash/reduce'

import { Select } from 'components/ui/__v3__/Select'

import { createDebounce } from 'helpers/debounce'
import { denormalize } from 'helpers/redux'

import _ from 'i18n'

import { showError } from 'services/API'
import { jobEmployeeToOption } from 'services/Options'

function mapOptions(items, { showJobs }) {
  return Object.values(
    reduce(
      items,
      (acc, item) => {
        if (showJobs) {
          const job = get(item, ['job']) || []
          const department = get(job, 'department') || {}
          const branch = get(job, 'branch') || {}

          if (!acc[job.id]) {
            acc[job.id] = {
              label: [branch.name, department.name, job.name].join(' / '),
              options: [],
            }
          }

          acc[job.id].options.push(jobEmployeeToOption(item))
        } else {
          acc[item.id] = jobEmployeeToOption(item)
        }

        return acc
      },
      {},
    ),
  )
}

function JobsEmployeesPicker({
  cacheOptions,
  initial,
  isDisabled,
  loadingText,
  placeholder,
  preload,
  showJobs,
  value,
  onClear,
  onLoad,
  onInit,
  ...rest
}) {
  const defaultOptions = useMemo(
    () => mapOptions(initial.entities || [], { showJobs }),
    [initial.entities, showJobs],
  )

  const locationIds = useMemo(() => {
    if (isEmpty(rest.locationIds)) return null

    return isArray(rest.locationIds) ? rest.locationIds : [rest.locationIds]
  }, [rest.locationIds])

  const departmentIds = useMemo(() => {
    if (isEmpty(rest.departmentIds)) return null

    return isArray(rest.departmentIds)
      ? rest.departmentIds
      : [rest.departmentIds]
  }, [rest.departmentIds])

  const jobIds = useMemo(() => {
    if (isEmpty(rest.jobIds)) return null

    return isArray(rest.jobIds) ? rest.jobIds : [rest.jobIds]
  }, [rest.jobIds])

  const initOptions = useCallback(() => {
    onInit({
      locationIds,
      departmentIds,
      jobIds,
    })
  }, [departmentIds, jobIds, locationIds, onInit])

  const prevLocationIds = useRef(locationIds)
  const prevDepartmentIds = useRef(departmentIds)
  useEffect(() => {
    if (
      !isEqual(prevLocationIds.current, locationIds) ||
      !isEqual(prevDepartmentIds.current, departmentIds)
    ) {
      onClear()
      prevLocationIds.current = locationIds
      prevDepartmentIds.current = departmentIds
    }
  }, [locationIds, departmentIds, onClear])

  useEffect(() => {
    if (!isDisabled && preload && !initial.isLoaded) {
      initOptions()
    }
  }, [initial.isLoaded, preload, isDisabled, initOptions])

  const handleLoadOptions = useMemo(() => {
    return createDebounce(
      (inputValue, callback) => {
        if (!inputValue) {
          callback([])
        } else {
          onLoad({
            departmentIds,
            employeeQuery: inputValue,
            jobIds,
            locationIds,
          }).then(result => {
            if (result?.ok) {
              const data = get(result, ['payload', 'data'])
              const jobsEmployeeIds = map(get(data, 'jobsEmployees'), 'id')

              const denormalizedJobsEmployees = isEmpty(jobsEmployeeIds)
                ? []
                : denormalize(data, 'jobsEmployees', jobsEmployeeIds)

              callback(mapOptions(denormalizedJobsEmployees, { showJobs }))
            } else {
              showError(result)
              callback([])
            }
          })
        }
      },
      { leading: true, trailing: true },
    )
  }, [onLoad, locationIds, departmentIds, jobIds, showJobs])

  const renderLoadingMessage = useCallback(
    () => loadingText ?? `${_('common.loading')}...`,
    [loadingText],
  )

  return (
    <Select
      async
      cacheOptions={cacheOptions}
      closeMenuOnSelect={!rest.isMulti}
      defaultOptions={defaultOptions}
      isDisabled={isDisabled}
      isLoading={initial.isLoading}
      isSearchable
      loadOptions={handleLoadOptions}
      loadingMessage={renderLoadingMessage}
      placeholder={placeholder}
      value={value}
      onMenuOpen={initOptions}
      {...rest}
    />
  )
}

JobsEmployeesPicker.defaultProps = {
  cacheOptions: true,
  departmentIds: null,
  initial: {},
  isDisabled: false,
  jobIds: null,
  loadingText: null,
  locationIds: null,
  placeholder: null,
  preload: false,
  showJobs: false,
  value: null,
  withPortal: true,
  onClear: noop,
  onInit: noop,
  onLoad: noop,
}

JobsEmployeesPicker.propTypes = {
  ...Select.propTypes,
  cacheOptions: PropTypes.bool,
  departmentIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  initial: PropTypes.object,
  isDisabled: PropTypes.bool,
  jobIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  loadingText: PropTypes.string,
  locationIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  placeholder: PropTypes.string,
  preload: PropTypes.bool,
  showJobs: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  withPortal: PropTypes.bool,
  onClear: PropTypes.func,
  onInit: PropTypes.func,
  onLoad: PropTypes.func,
}

export default JobsEmployeesPicker

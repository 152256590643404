import React from 'react'

import { SpacedColumn, SpacedRow } from 'components/ui/__v2__/Grid'
import { Modal } from 'components/ui/__v3__'
import { Button } from 'components/ui/__v3__/Button'

import { i18n } from 'i18n'

import { ShiftLogs } from './ShiftLogs'
import { Container } from './styles'

interface Props {
  shiftId: string
  onClose: () => void
}

export function ShiftLogsModal({ shiftId, onClose }: Props) {
  return (
    <Modal.Container isOpen>
      <Modal.Close onClose={onClose} />
      <Modal.Title>{i18n('shiftLogs.title')}</Modal.Title>

      <Container>
        <SpacedColumn my={5} space={2}>
          <ShiftLogs shiftId={shiftId} />
          <SpacedRow justifyContent="flex-end">
            {/* @ts-ignore */}
            <Button secondary onClick={onClose}>
              {i18n('actions.close')}
            </Button>
          </SpacedRow>
        </SpacedColumn>
      </Container>
    </Modal.Container>
  )
}

import { useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { meQuery } from 'GraphQL/User'

import { AuthService } from 'services/Auth'

export function useViewer(isAuthenticated: boolean) {
  // TODO: refactor to useViewer
  const { data, loading, refetch } = useQuery<
    QueryData<'me'>,
    Gateway.QueryUserArgs
  >(meQuery, {
    skip: !isAuthenticated,
    onError: () => AuthService.logout(),
  })

  useEffect(() => {
    if (isAuthenticated) {
      refetch()
    }
  }, [isAuthenticated, refetch])

  const me = data?.me

  return {
    me,
    meLoading: loading,
  }
}

import { IndependentColors } from 'constants/colors'

import { BLACK, CHARCOAL_20, WHITE } from 'theme/__v2__/light/colors'

const DEFAULT_MAX_PALETTE_COLORS = 16
export const FALLBACK_COLOR = CHARCOAL_20

type ColorOption = {
  key: string
  value: string
}

export function generatePaletteColors(
  colorsAmount = DEFAULT_MAX_PALETTE_COLORS,
) {
  const colors = []

  for (let index = 0; index < IndependentColors.length; index += 1) {
    // TODO: need to add a condition to check for existing colors
    if (colors.length < colorsAmount) {
      const element = IndependentColors[index]
      colors.push(element)
    }
  }

  return colors
}

export function colorKeyToHex(colorName: string): string {
  const colorOption = IndependentColors.find(
    colorKey => colorKey.key === colorName,
  )
  return String(colorOption ? colorOption.value : FALLBACK_COLOR).substring(1)
}

export function hexToColorOption(hex: string | undefined): ColorOption {
  // Note: Hack to provide fallback
  const fallbackColor = { key: 'default', value: FALLBACK_COLOR }

  if (!hex) return fallbackColor

  const hexColor = hex.startsWith('#') ? hex : `#${hex}`
  const colorOption =
    IndependentColors.find(
      independentColor => independentColor.value === hexColor,
    ) ?? fallbackColor

  return colorOption
}

export function blackOrWhiteText(hex: string): `#${string}` {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1)
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }
  if (hex.length !== 6) {
    return BLACK
  }

  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? BLACK : WHITE
}

export function removeHash(hex: string) {
  if (hex.startsWith('#')) return hex.substring(1)
  return hex
}

import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { DateTime } from 'luxon'

import { DayPicker } from 'components/ui/__v2__/DayPicker'
import { DayOnlyProps } from 'components/ui/__v2__/DayPicker/types'
import { Flex } from 'components/ui/__v2__/Grid'

import { Error } from './components'
import { ControllerBaseProps } from './types'

export type FormSingleDayPickerProps<
  T extends FieldValues
> = ControllerBaseProps<T> & Omit<DayOnlyProps, 'dayOnly' | 'onChange'>

export function FormSingleDayPicker<T extends FieldValues>({
  control,
  name,
  ...rest
}: FormSingleDayPickerProps<T>) {
  const {
    field: fieldProps,
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  const { onChange, value, onBlur } = fieldProps

  return (
    <Flex flexDirection="column">
      <DayPicker
        {...rest}
        dayOnly
        from={value ? DateTime.fromISO(value as string).toJSDate() : undefined}
        width="100%"
        onBlur={onBlur}
        onChange={onChange}
      />
      {error && <Error error={error} />}
    </Flex>
  )
}

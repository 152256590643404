import axios from 'axios'
import { v4 as uuid } from 'uuid'

import { FILE_TYPES } from 'constants/news'

import { AuthService } from 'services/Auth'

export const getMessageType = (type: string) => {
  if (type.search('image') !== -1) {
    return FILE_TYPES.IMAGE
  }
  if (type.search('video') !== -1) {
    return FILE_TYPES.VIDEO
  }
  return FILE_TYPES.FILE
}

export const createPostPayloadFileObject = (file: File) => ({
  id: uuid(),
  name: file.name,
  type: getMessageType(file.type),
  size: file.size,
  // @ts-ignore
  preview: file.preview,
  createdAt: new Date(),
})

export async function downloadFileAsync(url: string) {
  const clusterId = (await AuthService.getAuthHeaders())['cluster-id']

  return axios
    .get(url, {
      method: 'GET',
      responseType: 'blob',
      headers: {
        'cluster-id': clusterId,
      },
    })
    .then(response => {
      const href = URL.createObjectURL(response.data)

      const filename = (response.headers['content-disposition'].split(
        '; ',
      )[1] as string)
        .replace(/"/g, '')
        .replace('filename=', '')

      const link = document.createElement('a')
      link.href = href
      link.download = filename
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
}

import { useApolloQuery } from 'API/services/Apollo'

import { RulesetQuery, RulesetQueryData } from './GraphQL'

export function useRuleset(id: string) {
  const { data, loading, error } = useApolloQuery<
    RulesetQueryData,
    Gateway.QueryWorkflowArgs
  >(RulesetQuery, {
    variables: { id },
  })

  return {
    ruleset: data?.ruleset,
    loading,
    error,
  }
}

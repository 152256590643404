import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import { DateTime } from 'luxon'
import { delay } from 'redux-saga'
import {
  all,
  call,
  cancel,
  fork,
  put,
  race,
  select,
  take,
} from 'redux-saga/effects'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  getInitClockingTime,
  TICK_TIME,
  TIMER_STATES,
} from 'constants/timeClock'

import { toISOString } from 'helpers/date'

import { showError } from 'services/API'
import Utils from 'services/Utils'

import { LOG_OUT } from 'store/actions/auth'
import {
  changeNote,
  changeTimeBucket,
  CLOCK_IN,
  CLOCK_OUT,
  clockOut,
  LOAD_ACTIVE_TIMER,
  loadActiveTimer,
  resetIntervalTime,
  RESTORE_TRACKING,
  restoreTracking,
  selectJob,
  setClockingTime,
  setTimings,
  START_PAUSE_TIMER,
  START_TIMER,
  startTimer,
  STOP_PAUSE_TIMER,
  STOP_TIMER,
  stopTimer,
} from 'store/actions/employeeApp/openClock'
import { INIT } from 'store/actions/employeeApp/timeClock'
import {
  getClockingTime,
  getIsEmergency,
  getNote,
  getTimeEntry,
} from 'store/selectors/employeeApp/openClock'

function* initialLoad() {
  while (true) {
    yield take(INIT)

    yield call(resetTimings)
    yield put(loadActiveTimer())

    yield take(LOAD_ACTIVE_TIMER.SUCCESS)

    yield call(restoreJobData)
    yield call(restoreTimings)

    const timeEntry = yield select(getTimeEntry)

    if (!isEmpty(timeEntry) && isEmpty(get(timeEntry, 'schedule'))) {
      yield put(restoreTracking())
    }
  }
}

function* syncTimeClock() {
  while (true) {
    const { clockIn } = yield race({
      clockIn: take(CLOCK_IN),
      restoreTracking: take(RESTORE_TRACKING),
    })

    yield fork(syncStartTimer)

    if (clockIn) {
      const { startTimerFailure } = yield race({
        startTimerSuccess: take(START_TIMER.SUCCESS),
        startTimerFailure: take(START_TIMER.FAILURE),
      })

      if (startTimerFailure) {
        yield put(resetIntervalTime())
        // eslint-disable-next-line no-continue
        continue
      }
    }

    const bgClockingTask = yield fork(bgClocking)

    while (true) {
      const { clockOutAction, logOutAction, stopTimerAction } = yield race({
        clockOutAction: take(CLOCK_OUT),
        logOutAction: take(LOG_OUT),
        stopTimerAction: take(STOP_TIMER.SUCCESS),
      })

      if (logOutAction || stopTimerAction) {
        yield cancel(bgClockingTask)
        yield put(resetIntervalTime())
        break
      }

      if (clockOutAction) {
        yield fork(syncEndTimer, bgClockingTask)
      }
    }
  }
}

function* syncStartTimer() {
  const timeEntry = yield select(getTimeEntry)
  const startAt = toISOString()

  if (timeEntry?.state !== TIMER_STATES.active) {
    const note = yield select(getNote)
    const emergency = yield select(getIsEmergency)

    yield put(startTimer({ note, emergency }))

    yield take(START_TIMER.SUCCESS)

    yield put(setTimings(startAt, null))

    if (note) {
      yield put(changeNote(''))
    }
  }
}

function* syncEndTimer(bgClockingTask) {
  const note = yield select(getNote)
  yield put(stopTimer({ note }))

  if (yield take(STOP_TIMER.SUCCESS)) {
    const timeEntry = yield select(getTimeEntry)
    yield put(setClockingTime(getInitClockingTime()))
    yield put(setTimings(timeEntry.startAt, toISOString()))

    if (note) {
      yield put(changeNote(''))
    }

    yield cancel(bgClockingTask)
    yield put(resetIntervalTime())
  }
}

function* bgClocking() {
  while (true) {
    yield call(delay, TICK_TIME)
    yield call(tick)
  }
}
function* tick() {
  const time = yield select(getClockingTime)
  yield put(setClockingTime(time + TICK_TIME))
}

function* resetTimings() {
  yield put(setClockingTime(getInitClockingTime()))
  yield put(setTimings(null, null))
}

function* restoreJobData() {
  const timeEntry = yield select(getTimeEntry)

  const jobId = timeEntry?.job?.id
  const departmentId = timeEntry?.department?.id
  const branchId = timeEntry?.branch?.id

  const attendanceSettings = timeEntry?.employee?.jobsEmployees?.find(
    item =>
      item?.job?.id === jobId &&
      item?.department?.id === departmentId &&
      item?.branch?.id === branchId,
  )?.companySetting?.attendanceSettings

  const jobData = {
    id: jobId,
    value: jobId,
    label: timeEntry?.job?.name,
    departmentId,
    departmentName: timeEntry?.department?.name,
    branch: {
      id: branchId,
      name: timeEntry?.branch?.name,
    },
    attendanceSettings,
  }
  const emergency = timeEntry?.emergency

  const { timeBucketChild } = Utils.TimeBucket.getJsonApiTimeBucketPickerValues(
    timeEntry.timeBucket,
  )

  yield put(selectJob(jobData, { emergency }))
  yield put(changeTimeBucket(timeBucketChild))
}

function* restoreTimings() {
  const timeEntry = yield select(getTimeEntry)

  if (!isEmpty(timeEntry) && isEmpty(get(timeEntry, 'schedule'))) {
    const startTime = timeEntry.startAt
    const diffInMs = differenceInMilliseconds(
      DateTime.utc().toJSDate(),
      DateTime.fromISO(startTime).toJSDate(),
    )
    const time = getInitClockingTime() + diffInMs

    yield put(setClockingTime(time))
    yield put(setTimings(startTime, null))
  }
}

function* resetNotes() {
  while (true) {
    const { startPause, stopPause } = yield race({
      startPause: take(START_PAUSE_TIMER.SUCCESS),
      stopPause: take(STOP_PAUSE_TIMER.SUCCESS),
    })
    if (startPause || stopPause) {
      const note = yield select(getNote)
      if (note) {
        yield put(changeNote(''))
      }
    }
  }
}

function* watchStartTimerFailure() {
  while (true) {
    const result = yield take(START_TIMER.FAILURE)
    showError(result)

    yield put(clockOut())
    yield call(resetTimings)
  }
}

function* watchStopTimerFailure() {
  while (true) {
    const result = yield take(STOP_TIMER.FAILURE)
    showError(result)
  }
}

export default function* root() {
  yield all([
    fork(initialLoad),
    fork(syncTimeClock),
    fork(resetNotes),
    fork(watchStartTimerFailure),
    fork(watchStopTimerFailure),
  ])
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { FiMinus, FiPlus } from 'react-icons/fi'

import { v4 as uuid } from 'uuid'

import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import InfoPopover from 'components/blocks/__v2__/InfoPopover'
import { Flex, Span } from 'components/ui/__v2__/Grid'
import { Spacer } from 'components/ui/__v2__/Spacer'
import { TextError } from 'components/ui/__v3__/Input/Errors'

import usePrevious from 'hooks/usePrevious'

import { i18n } from 'i18n'

import EffectivePeriod from './EffectivePeriod'
import { checkIfHaveSingleNotAvailableEffectiveDate } from './helpers'
import { ActionButton, Container, InfoIconContainer } from './styles'

const PERIOD_TEMPLATE = {
  id: uuid(),
  start: null,
  end: null,
}

// TODO: Refactor logic of changing effective periods as soon as possible
function EffectivePeriods({
  disabledWarningText,
  disableOverlap,
  disableFuture,
  disablePast,
  infoText,
  value,
  onChange,
  isDisabled,
  isOverlap,
  maximumItems,
  minimalDays,
  changePeriodValidity,
}) {
  const [addingPeriod, setAddingPeriod] = useState(isEmpty(value))
  const [effectivePeriodsToRender, setEffectivePeriodsToRender] = useState([
    ...(value ?? []),
    ...(addingPeriod ? [PERIOD_TEMPLATE] : []),
  ])

  const previousPeriods = usePrevious(value)

  useEffect(() => {
    const periodsAreEmpty = isEmpty(value)
    setAddingPeriod(periodsAreEmpty)

    const periodsWereCleared = periodsAreEmpty && !isEmpty(previousPeriods)
    if (periodsWereCleared) setEffectivePeriodsToRender([PERIOD_TEMPLATE])
  }, [addingPeriod, previousPeriods, value])

  useEffect(() => {
    if (!checkIfHaveSingleNotAvailableEffectiveDate(effectivePeriodsToRender)) {
      onChange(effectivePeriodsToRender)
    } else {
      onChange([])
    }
  }, [onChange, effectivePeriodsToRender])

  const handleAddPeriod = e => {
    setAddingPeriod(true)
    setEffectivePeriodsToRender([...(value ?? []), ...[PERIOD_TEMPLATE]])
  }

  const handleRemovePeriod = index => {
    const nextValue = [...effectivePeriodsToRender]

    nextValue.splice(index, 1)
    setEffectivePeriodsToRender(nextValue)
  }

  const handleChange = (index, period) => {
    const nextValue = [...(value ?? [])]
    nextValue[index] = {
      ...nextValue[index],
      ...period,
    }

    setAddingPeriod(false)
    setEffectivePeriodsToRender(nextValue)

    changePeriodValidity(
      period?.isEndDayValid === true || period?.isStartDayValid === true,
    )
  }

  return (
    <Container>
      {effectivePeriodsToRender.map((period, index, array) => {
        const isAddButtonDisabled =
          addingPeriod ||
          period.isEndDayValid === false ||
          period.isStartDayValid === false ||
          (disableOverlap ? isOverlap : false) ||
          isDisabled ||
          array.length >= maximumItems

        const isRemoveButtomDisabled =
          isDisabled || (index === 0 && array.length === 1)

        return (
          <Flex key={period?.start}>
            <Flex>
              <ActionButton
                disabled={isRemoveButtomDisabled}
                onClick={() => handleRemovePeriod(index)}
              >
                <Span>
                  <FiMinus size={16} />
                </Span>
              </ActionButton>
            </Flex>
            <Spacer axis="horizontal" size={16} />
            <Flex flex={1}>
              <EffectivePeriod
                index={index}
                isOverlap={isOverlap}
                {...period}
                disableFuture={disableFuture}
                disablePast={disablePast}
                disabledWarningText={disabledWarningText}
                isDisabled={isDisabled}
                minimalDays={minimalDays}
                onChange={handleChange}
              />
            </Flex>
            {/* Last row */}
            {index === effectivePeriodsToRender.length - 1 && (
              <>
                <Flex>
                  <ActionButton
                    disabled={isAddButtonDisabled}
                    onClick={handleAddPeriod}
                  >
                    <Span>
                      <FiPlus size={16} />
                    </Span>
                  </ActionButton>
                </Flex>
                {infoText && (
                  <InfoIconContainer>
                    <InfoPopover
                      iconSize={16}
                      maxWidth="280px"
                      placement="right"
                      popoverContent={infoText}
                    />
                  </InfoIconContainer>
                )}
              </>
            )}
          </Flex>
        )
      })}
      {disableOverlap && isOverlap && (
        <TextError>{i18n('effectiveDates.shouldNotOverlap')}</TextError>
      )}
    </Container>
  )
}

EffectivePeriods.defaultProps = {
  disabledWarningText: null,
  disableFuture: false,
  disableOverlap: true,
  disablePast: false,
  value: null,
  onChange: noop,
  changePeriodValidity: noop,
  isDisabled: false,
  isOverlap: false,
  infoText: null,
  maximumItems: 20,
  minimalDays: 1, // 1 means same day start and end date are allowed
}

EffectivePeriods.propTypes = {
  changePeriodValidity: PropTypes.func,
  disableFuture: PropTypes.bool,
  disableOverlap: PropTypes.bool,
  disablePast: PropTypes.bool,
  disabledWarningText: PropTypes.string,
  infoText: PropTypes.string,
  isDisabled: PropTypes.bool,
  isOverlap: PropTypes.bool,
  maximumItems: PropTypes.number,
  minimalDays: PropTypes.number,
  value: PropTypes.array,
  onChange: PropTypes.func,
}

export default EffectivePeriods

import styled from 'styled-components'
import { layout, space, typography } from 'styled-system'

import { inputCss } from './styles'
import { InnerStyleProps } from './types'

export const InputField = styled.input<InnerStyleProps>`
  ${inputCss}
  ${layout};
  ${space};
  ${typography};
`

InputField.defaultProps = {
  errored: false,
}

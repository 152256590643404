import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { createStructuredSelector } from 'reselect'

import { Button } from 'components/ui/__v2__/Button'
import { ModalWindow } from 'components/ui/ModalWindow'

import _ from 'i18n'

import { generateToken } from 'store/actions/viewer'
import {
  getGeneratedToken,
  getGeneratedTokenError,
  getGeneratedTokenLoading,
} from 'store/selectors/viewer'

import {
  Copy,
  CopySuccess,
  LocaleContainer,
  LocaleTitle,
  StyledClose,
  TokenError,
  TokenString,
  TokenText,
  TokenTip,
} from './styles'

function TokenModal({
  generatedToken,
  generatedTokenError,
  generatedTokenLoading,
  onGenerateToken,
  onClose,
}) {
  const [copySuccess, setCopySuccess] = useState('')
  const textAreaRef = useRef(null)
  const timeout = useRef(null)

  const handleGenerateToken = useCallback(async () => {
    await onGenerateToken()
  }, [onGenerateToken])

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [])

  function copyToClipboard(e) {
    if (textAreaRef.current) {
      textAreaRef.current.select()
      document.execCommand('copy')
      // This is just personal preference.
      // I prefer to not show the the whole text area selected.
      e.target.focus()
      setCopySuccess('Copied!')

      timeout.current = setTimeout(() => {
        setCopySuccess('')
      }, 3000)
    }
  }

  return (
    <ModalWindow callback={onClose}>
      <LocaleContainer>
        <StyledClose size={22} onClick={onClose} />
        <LocaleTitle>{_('generateToken.title')}</LocaleTitle>
        {!generatedToken ? (
          <>
            <Button
              disabled={generatedTokenLoading}
              onClick={handleGenerateToken}
            >
              {_('generateToken.generate')}
            </Button>
            {generatedTokenError && (
              <TokenError>{_('generateToken.error')}</TokenError>
            )}
          </>
        ) : (
          <>
            <TokenText>
              <TokenString defaultValue={generatedToken} ref={textAreaRef} />
              <Copy onClick={copyToClipboard}>{_('generateToken.copy')}</Copy>
            </TokenText>
            {copySuccess && (
              <CopySuccess>{_('generateToken.copied')}!</CopySuccess>
            )}
            <TokenTip>{_('generateToken.tip')}</TokenTip>
          </>
        )}
      </LocaleContainer>
    </ModalWindow>
  )
}

TokenModal.defaultProps = {
  generatedToken: null,
}

TokenModal.propTypes = {
  generatedToken: PropTypes.string,
  generatedTokenError: PropTypes.bool.isRequired,
  generatedTokenLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onGenerateToken: PropTypes.func.isRequired,
}

export default connect(
  createStructuredSelector({
    generatedToken: getGeneratedToken,
    generatedTokenError: getGeneratedTokenError,
    generatedTokenLoading: getGeneratedTokenLoading,
  }),
  { onGenerateToken: generateToken },
)(TokenModal)

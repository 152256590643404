import React, { ChangeEvent, useCallback } from 'react'

import { Flex, SpacedColumn, Span } from 'components/ui/__v2__/Grid'
import { Radio } from 'components/ui/__v2__/Radio'

import { ScheduleConflictType } from 'constants/gatewayGraphQL'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

export type ShiftConflictActionSelectorProps = {
  value: ScheduleConflictType
  description: string
  name: string
  excludeNoneAction: boolean
  onChange: (option: ScheduleConflictType) => void
}

// Note: duplicate until we remove the old one and move to GQL
export function ShiftConflictActionSelectorGql({
  value,
  description,
  name,
  excludeNoneAction,
  onChange,
}: ShiftConflictActionSelectorProps) {
  const t = useI18n<typeof TRANSLATIONS.shiftConflictActions>(
    'shiftConflictActions',
  )

  const options = excludeNoneAction
    ? [
        ScheduleConflictType.Split,
        ScheduleConflictType.Unassign,
        ScheduleConflictType.Error,
      ]
    : ([
        ScheduleConflictType.None,
        ScheduleConflictType.Split,
        ScheduleConflictType.Unassign,
        ScheduleConflictType.Error,
      ] as const)

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value as ScheduleConflictType

      onChange(value)
    },
    [onChange],
  )

  return (
    <SpacedColumn maxWidth="400px">
      <Span textAlign="justify">{description}</Span>
      {options.map(option => (
        <Flex alignItems="center" as="label" key={option}>
          <Radio
            // @ts-ignore
            checked={value === option}
            name={name}
            size={16}
            value={option}
            onChange={handleChange}
          />
          <Span ml={2}>{t(`options.${option}`)}</Span>
        </Flex>
      ))}
    </SpacedColumn>
  )
}

import {
  BlankCustomCvFieldPair,
  FieldValuePairType,
} from 'components/blocks/__v3__/HookForm/FormCustomCvFieldsWithValues/types'

export type CandidatesFilterFormState = {
  customCvFields: Array<FieldValuePairType | BlankCustomCvFieldPair>
}

export type CandidateTab =
  | 'available'
  | 'unavailable'
  | 'bidders'
  | 'availableMissingQualifications'

export type UnavailableOptionsFormState = {
  reason: UnavailabilityReasons
}

export enum UnavailabilityReasons {
  AvailabilityConflict = 'availabilityConflict',
  AvailableButShiftConflict = 'availableButShiftConflict',
  AvailableBustTimeoffConflict = 'availableButTimeoffConflict',
  AvailableButMissingQualifications = 'availableButMissingQualifications',
  ShiftConflict = 'shiftConflict',
  TimeoffConflict = 'timeoffConflict',
  Whatever = 'whateverReason',
}

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { PAGE_SIZE, UNPAGINATED_PAGE_SIZE } from 'constants/pagination'

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const GENERATE = createAsyncAction('reportBuilder/GENERATE')
export const LOAD = createAsyncAction('reportBuilder/LOAD')
export const LOAD_ONE = createAsyncAction('reportBuilder/LOAD_ONE')
export const CREATE = createAsyncAction('reportBuilder/CREATE')
export const UPDATE = createAsyncAction('reportBuilder/UPDATE')
export const DELETE = createAsyncAction('reportBuilder/DELETE')
export const EXPORT_REPORT_CSV = createAsyncAction(
  'reportBuilder/EXPORT_REPORT_CSV',
)
export const EXPORT_PREVIEW_CSV = createAsyncAction(
  'reportBuilder/EXPORT_PREVIEW_CSV',
)

export const CLEAR_DATA = 'reportBuilder/CLEAR_DATA'

export const generate = ({
  endpoint,
  dataType,
  include,
  filter,
  paged,
  page = { size: PAGE_SIZE['10'] },
  sort,
}) => (dispatch, getState) => {
  return dispatch(
    apiCall({
      endpoint: endpoint(dispatch, getState),
      types: GENERATE,
      query: {
        include,
        filter,
        sort,
        /**
         * The below two are only related to Task Entries report, but they don't do any harm to other reports
         */
        company_id: getCompanyId(getState()),
        display: 'all',
        page,
      },
      paged,
      payload: { dataType },
    }),
  )
}

export const load = () => (dispatch, getState) => {
  const companyId = getCompanyId(getState())
  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/report_templates`,
      types: LOAD,
      query: {
        page: { size: UNPAGINATED_PAGE_SIZE },
      },
    }),
  )
}

export const loadOne = reportId => (dispatch, getState) => {
  const companyId = getCompanyId(getState())
  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/report_templates/${reportId}`,
      types: LOAD_ONE,
    }),
  )
}

export const create = ({ name, kind, fields, filters = {} }) => (
  dispatch,
  getState,
) => {
  const companyId = getCompanyId(getState())

  const attributes = {
    name,
    kind,
    fields,
  }

  if (!isEmpty(filters)) {
    attributes.filters = filters
  }

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/report_templates`,
      method: 'POST',
      types: CREATE,
      query: {
        data: {
          attributes,
        },
      },
    }),
  )
}

export const update = (reportId, { name, fields, filters = {} }) => (
  dispatch,
  getState,
) => {
  const companyId = getCompanyId(getState())

  const attributes = {}

  if (name) attributes.name = name

  if (!isEmpty(fields)) {
    attributes.fields = fields
  }

  if (!isEmpty(filters)) {
    attributes.filters = filters
  }

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/report_templates/${reportId}`,
      method: 'PATCH',
      types: UPDATE,
      query: {
        data: {
          attributes,
        },
      },
    }),
  )
}

export const remove = reportIds => (dispatch, getState) => {
  const companyId = getCompanyId(getState())
  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/relationships/report_templates`,
      method: 'DELETE',
      types: DELETE,
      query: {
        data: map(reportIds, reportId => ({
          id: reportId,
          type: 'reportTemplates',
        })),
      },
      payload: {
        deletedIds: reportIds,
      },
    }),
  )
}

export const clearData = () => ({
  type: CLEAR_DATA,
})

export const exportReportCSV = reportId => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  return dispatch(
    apiCall({
      method: 'POST',
      endpoint: '/token',
      types: EXPORT_REPORT_CSV,
      payload: {
        companyId,
        reportId,
      },
      isRaw: true,
    }),
  )
}

export const exportPreviewCSV = report => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  return dispatch(
    apiCall({
      method: 'POST',
      endpoint: '/token',
      types: EXPORT_PREVIEW_CSV,
      payload: {
        companyId,
        report,
      },
      isRaw: true,
    }),
  )
}

import { useState } from 'react'

import { download } from 'API/services/JSON'

import { useAppContext, useLocale } from 'hooks'

import { EvaluatedTimesQueryParams } from 'pages/EmployerApp/EvaluatedTime/hooks/types'

import { evaluatedTimesQueryParamsToFilterToQueryString } from './mapper'

export function useExportEvaluatedTime() {
  const [exporting, setExporting] = useState(false)
  const { companyId } = useAppContext()
  const locale = useLocale()

  async function exportEvaluatedTime(
    filter: EvaluatedTimesQueryParams['filters'],
  ) {
    setExporting(true)
    await download({
      companyId,
      queryString: evaluatedTimesQueryParamsToFilterToQueryString(
        locale,
        filter,
      ),
      kind: 'evaluated_times_export',
    })
    setExporting(false)
  }

  return {
    exporting,
    exportEvaluatedTime,
  }
}

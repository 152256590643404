import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import {
  getCursorPageMeta,
  isCursorPageMetaChanged,
  transformResponseToEntities,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { useAppContext } from 'hooks'

import { TimeoffQueryKeys, TIMEOFFS_JSON_TYPE } from './constant'
import { timeoffsQuery } from './Json'
import { TimeoffAPIItem, TimeoffsFilter, TimeoffSorting } from './types'

interface UseTimeoffsByCursorArgs {
  page: CursorPage
  filter: TimeoffsFilter
  sort: TimeoffSorting
}

const TIMEOFF_INCLUDE = [
  'employee.profile',
  'employee.branch',
  'customLeaveDay',
  'document',
  'lastUpdatedBy.profile',
  'processedBy.profile',
  'createdBy.profile',
].join(',')

export function useTimeoffsByCursor({
  page,
  filter,
  sort,
}: UseTimeoffsByCursorArgs) {
  const { companyId } = useAppContext()

  const [currentPageMeta, setCurrentPageMeta] = useState<JsonApi.CursorPaging>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, isFetching, error, refetch } = useQuery({
    queryKey: TimeoffQueryKeys.byCursor({ page, filter, sort }),
    queryFn: () =>
      timeoffsQuery({
        companyId,
        page,
        filter,
        sort,
        include: TIMEOFF_INCLUDE,
      }),
    select: response => {
      const { entities } = transformResponseToEntities<TimeoffAPIItem>({
        response,
        jsonApiType: TIMEOFFS_JSON_TYPE,
      })
      const newPageMeta = getCursorPageMeta(response)
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }

      return entities
    },
    structuralSharing: false,
    refetchOnWindowFocus: false,
  })

  return {
    timeoffs: data ?? [],
    isFetching,
    error,
    refetch,
    currentPageMeta,
  }
}

import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import {
  DeleteTimeBucketMutation,
  TimeBucketChildrenByCursorQuery,
  TimeBucketParentsByCursorQuery,
} from './GraphQL'

export function useDeleteTimeBucket() {
  const t = useI18n<typeof TRANSLATIONS.settings.timeBuckets.toasts.delete>(
    'settings.timeBuckets.toasts.delete',
  )

  const [innerDelete, { loading: deleting }] = useApolloMutation<
    MutationData<'deleteTimeBucket'>,
    Gateway.MutationDeleteTimeBucketArgs
  >(DeleteTimeBucketMutation, {
    silent: true,
    onError: error => {
      showToast({
        type: 'error',
        title: t('error.title'),
        content: error.message || t('error.message'),
      })
    },
    // TODO: split messages for parent and child
    onCompleted: () => {
      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const deleteTimeBucket = async (
    id: Gateway.MutationDeleteTimeBucketArgs['id'],
    child?: boolean,
  ) => {
    const result = await innerDelete({
      variables: {
        id,
      },
      refetchQueries: compact([
        getOperationName(
          child
            ? TimeBucketChildrenByCursorQuery
            : TimeBucketParentsByCursorQuery,
        ),
      ]),
    })

    return graphQLResultHasError(result)
  }

  return { deleteTimeBucket, deleting }
}

import React from 'react'

import noop from 'lodash/noop'

const TableContext = React.createContext({
  data: [],
  onDelete: noop,
  onEdit: noop,
  onToggle: noop,
})

export default TableContext

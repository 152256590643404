import get from 'lodash/get'

import { TimeCardStatus } from 'constants/timeCards'

import { setIn } from 'helpers/data'
import { createReducer } from 'helpers/redux'

import { APPROVE, UNAPPROVE } from 'store/actions/employees/timeCards'

const initialState = {}

const handlers = {
  [APPROVE.SUCCESS]: (state, { payload }) => {
    const employeeId = get(payload, ['employeeId'])
    const timeEntryIds = get(payload, ['timeEntryIds']) || []

    const summary = get(state, [employeeId])

    if (!employeeId || timeEntryIds.length === 0) return state

    const totalCount = get(summary, ['attributes', 'totalCount']) || 0
    const approvedCount = get(summary, ['attributes', 'approvedCount']) || 0

    return setIn(
      state,
      [employeeId, 'attributes', 'approvedCount'],
      Math.min(approvedCount + timeEntryIds.length, totalCount),
    )
  },

  [UNAPPROVE.SUCCESS]: (state, { payload }) => {
    const employeeId = get(payload, ['employeeId'])
    const timeEntryIds = get(payload, ['timeEntryIds']) || []
    const prevState = payload?.prevState

    const summary = get(state, [employeeId])

    if (
      !employeeId ||
      timeEntryIds.length === 0 ||
      prevState === TimeCardStatus.Discarded
    ) {
      return state
    }

    const approvedCount = get(summary, ['attributes', 'approvedCount']) || 0

    const nextApprovedCount = Math.max(approvedCount - timeEntryIds.length, 0)

    return setIn(
      state,
      [employeeId, 'attributes', 'approvedCount'],
      nextApprovedCount,
    )
  },
}

export default createReducer(initialState, handlers)

import React from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'

import {
  DownloadIcon,
  FileLink,
  FileRow,
  FilesList,
  IconButton,
  IconLink,
  NoFilesText,
  PopupContainer,
  RemoveIcon,
} from './styles'

function FilesPopup({ canDownload, canRemoveFile, files, onRemoveFile }) {
  return (
    <PopupContainer>
      <FilesList>
        {map(files, x => (
          <FileRow key={x?.id}>
            {canDownload && (
              <>
                <FileLink href={x?.content?.url} pr={4}>
                  {x.name}
                </FileLink>
                <IconLink href={x?.content?.url}>
                  <DownloadIcon />
                </IconLink>
              </>
            )}
            {canRemoveFile && (
              <IconButton onClick={onRemoveFile(x?.id)}>
                <RemoveIcon />
              </IconButton>
            )}
          </FileRow>
        ))}
      </FilesList>
      {!files?.length && <NoFilesText>No Files</NoFilesText>}
    </PopupContainer>
  )
}

FilesPopup.propTypes = {
  canDownload: PropTypes.bool.isRequired,
  canRemoveFile: PropTypes.bool.isRequired,
  files: PropTypes.array.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
}

export default FilesPopup

import Immutable from 'seamless-immutable'

import get from 'lodash/get'
import map from 'lodash/map'
import union from 'lodash/union'
import without from 'lodash/without'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  CREATE_SHIFT_COVER,
  DESELECT_SUGGESTED_EMPLOYEE,
  LOAD_SUGGESTED_EMPLOYEES,
  SELECT_SUGGESTED_EMPLOYEE,
} from 'store/actions/employeeApp/findCover'
import { RESET_SUCCESSFUL } from 'store/actions/employeeApp/schedules'

const initialState = Immutable({
  error: '',
  selectedSuggestedIds: [],
  suggestedEmployeesIds: [],
  isLoadingSuggestions: false,
  isRequestingShiftCover: false,
  isRequestSuccessful: false,
})

const loadSuggestedEmployeesSuccess = (state, { meta, payload }) =>
  state.merge({
    selectedSuggestedIds: [],
    suggestedEmployeesIds: map(get(payload, ['data', 'employees']), 'id'),
    isLoadingSuggestions: false,
  })

const handlers = {
  [LOAD_SUGGESTED_EMPLOYEES.REQUEST]: state =>
    state.merge({
      suggestedEmployeesIds: [],
      isLoadingSuggestions: true,
    }),
  [LOAD_SUGGESTED_EMPLOYEES.SUCCESS]: loadSuggestedEmployeesSuccess,
  [SELECT_SUGGESTED_EMPLOYEE]: (state, { payload }) => {
    const unionSelection = union(state.selectedSuggestedIds, [payload])
    return state.merge({ selectedSuggestedIds: [...unionSelection] })
  },
  [DESELECT_SUGGESTED_EMPLOYEE]: (state, { payload }) => {
    const withoutSelection = without(state.selectedSuggestedIds, payload)
    return state.merge({ selectedSuggestedIds: [...withoutSelection] })
  },
  [CREATE_SHIFT_COVER.REQUEST]: state =>
    state.merge({ isRequestingShiftCover: true }),
  [CREATE_SHIFT_COVER.SUCCESS]: state =>
    state.merge({
      isRequestingShiftCover: false,
      selectedSuggestedIds: [],
      suggestedEmployeesIds: [],
      isRequestSuccessful: true,
    }),
  [CREATE_SHIFT_COVER.FAILURE]: state =>
    state.merge({ isRequestSuccessful: false }),
  [RESET_SUCCESSFUL]: state =>
    state.isRequestSuccessful
      ? state.merge({ isRequestSuccessful: false })
      : state,

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Grid } from 'components/ui/__v2__/Grid'

export const InputGrid = styled(Grid)`
  grid-template-columns: 50% auto 30px;
  grid-gap: ${themeGet('space.3')}px;
  align-items: center;
`

import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import {
  clearEmployees,
  initEmployees,
  loadEmployees,
} from 'store/actions/areas'
import { getInitialEmployees } from 'store/selectors/areas'

import EmployeesPicker from './EmployeesPicker'

const selectors = createStructuredSelector({
  initial: getInitialEmployees,
})

const actions = {
  onInit: initEmployees,
  onLoad: loadEmployees,
  onClear: clearEmployees,
}

export default connect(selectors, actions)(EmployeesPicker)

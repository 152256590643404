import { useCreateWorkflow } from './useCreateWorkflow'
import { useUpdateWorkflow } from './useUpdateWorkflow'
import { useWorkflow } from './useWorkflow'
import { useWorkflowsByCursor } from './useWorkflowsByCursor'

export const WorfklowAPI = {
  byCursor: useWorkflowsByCursor,
  create: useCreateWorkflow,
  update: useUpdateWorkflow,
  byId: useWorkflow,
}

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

export const DISCIPLINARY = {
  LOAD: createAsyncAction('disciplinary/LOAD'),
  CREATE: createAsyncAction('disciplinary/CREATE'),
  UPDATE: createAsyncAction('disciplinary/UPDATE'),
  DELETE: createAsyncAction('disciplinary/DELETE'),
}

const include = [
  'employee',
  'attachment',
  'updatedBy.profile',
  'createdBy.profile',
].join(',')

export const loadDisciplinary = ({
  employeeId,
  number,
  size,
  filter,
  sort = '-date,created_at',
}) => dispatch => {
  const paged = !!number && !!size
  const page = paged ? { page: { number, size } } : null
  return dispatch(
    apiCall({
      endpoint: `/employees/${employeeId}/disciplinary_records`,
      types: DISCIPLINARY.LOAD,
      method: 'GET',
      query: {
        include,
        ...page,
        ...filter,
        sort,
      },
      paged,
    }),
  )
}

export const createDisciplinary = ({
  employeeId,
  attributes,
  attachmentId,
}) => dispatch => {
  const relationships = attachmentId
    ? {
        attachment: {
          data: {
            id: attachmentId,
            type: 'files',
          },
        },
      }
    : null
  dispatch(
    apiCall({
      endpoint: `/employees/${employeeId}/disciplinary_records`,
      types: DISCIPLINARY.CREATE,
      method: 'POST',
      query: {
        include,
        data: {
          attributes,
          relationships,
        },
      },
    }),
  )
}

export const updateDisciplinary = ({
  disciplinaryRecordId,
  attributes,
  relationships,
}) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/disciplinary_records/${disciplinaryRecordId}`,
      types: DISCIPLINARY.UPDATE,
      method: 'PATCH',
      query: {
        include,
        data: {
          attributes,
          relationships,
        },
      },
    }),
  )
}

export const deleteDisciplinary = disciplinaryRecordId => dispatch =>
  dispatch(
    apiCall({
      endpoint: `/disciplinary_records/${disciplinaryRecordId}`,
      types: DISCIPLINARY.DELETE,
      method: 'DELETE',
      payload: {
        deletedId: disciplinaryRecordId,
      },
    }),
  )

import PropTypes from 'prop-types'

import styled from 'styled-components'

function getHeight({ axis, size }) {
  return axis === 'horizontal' ? 1 : size
}

function getWidth({ axis, size }) {
  return axis === 'vertical' ? 1 : size
}

/**
 * @see {@link https://www.joshwcomeau.com/react/modern-spacer-gif/#the-code}
 */
const Spacer = styled.span`
  display: block;
  width: ${getWidth}px;
  min-width: ${getWidth}px;
  height: ${getHeight}px;
  min-height: ${getHeight}px;
`

Spacer.propTypes = {
  axis: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
  size: PropTypes.number.isRequired,
}

export default Spacer

import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { DEFAULT_JSON_API_OFFSET_PAGING } from 'API/services/JsonApi'
import {
  getOffsetPageMeta,
  transformResponseToEntities,
} from 'API/services/utils'

import { useAppContext } from 'hooks'

import { TimeoffQueryKeys, TIMEOFFS_JSON_TYPE } from './constant'
import { timeoffsQuery } from './Json'
import { TimeoffAPIItem, TimeoffsFilter, TimeoffSorting } from './types'

interface UseTimeoffsByOffsetArgs {
  page: JsonApi.OffsetPagingInput
  filter: TimeoffsFilter
  sort: TimeoffSorting
}

export function useTimeoffsByOffset({
  sort,
  filter,
  page: outerPage,
}: UseTimeoffsByOffsetArgs) {
  const { companyId } = useAppContext()
  const [total, setTotal] = useState(0)

  const page = outerPage ?? DEFAULT_JSON_API_OFFSET_PAGING

  const { data: timeoffs, isFetching, refetch } = useQuery({
    queryKey: TimeoffQueryKeys.byOffset(page, filter, sort),
    queryFn: () =>
      timeoffsQuery({
        companyId,
        filter,
        sort,
        page,
      }),
    select: (response: JsonApi.Response) => {
      const { recordCount } = getOffsetPageMeta(response)
      const { entities } = transformResponseToEntities<TimeoffAPIItem>({
        response,
        jsonApiType: TIMEOFFS_JSON_TYPE,
      })

      if (total !== recordCount) setTotal(recordCount)

      return entities ?? []
    },
    structuralSharing: false,
  })

  return {
    timeoffs: timeoffs ?? [],
    loadingTimeoffs: isFetching,
    totalTimeoffs: total,
    refetchTimeoffs: refetch,
  }
}

import React from 'react'

import { CrossIcon, IconButtonContainer, TagContainer } from './styles'
import { ShiftTagProps } from './types'

import { TextOverflowWithPopover } from '../TextOverflowWithPopover'

export function ShiftTag({
  label,
  tagColor,
  onRemove,
  ...rest
}: ShiftTagProps) {
  return (
    <TagContainer {...rest} tagColor={tagColor}>
      <TextOverflowWithPopover fontSize={0}>{label}</TextOverflowWithPopover>
      {onRemove && (
        <IconButtonContainer ml={2}>
          <CrossIcon onClick={onRemove} />
        </IconButtonContainer>
      )}
    </TagContainer>
  )
}

import { create, test } from 'vest'

import { isUndefined } from 'lodash'

import { i18n } from 'i18n'

import { CandidatesFilterFormState } from '../types'

export const validator = create((data: CandidatesFilterFormState) => {
  test('customCvFields', i18n('validation.required'), () => {
    const { customCvFields } = data

    if (customCvFields.length === 0) return true

    // Note: default state, no custom fields selected
    if (customCvFields.length === 1 && customCvFields[0].field === undefined)
      return true

    return customCvFields.every(
      fieldValuePair =>
        fieldValuePair.field?.id && !isUndefined(fieldValuePair.value),
    )
  })
})

import map from 'lodash/map'

import { PAGE_SIZE } from 'constants/pagination'

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getFromId } from 'store/selectors/employeeApp/schedules'
import { getToIds } from 'store/selectors/employeeApp/tradeShift'

export const CREATE_SHIFT_TRADE = createAsyncAction(
  'tradeShift/CREATE_SHIFT_TRADE',
)

export const LOAD_SUGGESTED_SCHEDULES = createAsyncAction(
  'tradeShift/LOAD_SUGGESTED_SCHEDULES',
)

export const SELECT_SUGGESTED_SCHEDULE = 'tradeShift/SELECT_SUGGESTED_SCHEDULE'
export const DESELECT_SUGGESTED_SCHEDULE =
  'tradeShift/DESELECT_SUGGESTED_SCHEDULE'

export const selectSuggestedSchedule = scheduleId => ({
  type: SELECT_SUGGESTED_SCHEDULE,
  payload: scheduleId,
})

export const deselectSuggestedSchedule = scheduleId => ({
  type: DESELECT_SUGGESTED_SCHEDULE,
  payload: scheduleId,
})

export const loadSuggestedSchedules = chosenShift => (dispatch, getState) => {
  const fromId = chosenShift || getFromId(getState())
  return dispatch(
    apiCall({
      endpoint: `/schedules/${fromId}/trades/suggested_schedules`,
      query: {
        include: 'employee,employee.profile,shifts_job,shifts_job.shift',
        page: { size: PAGE_SIZE['100'] },
      },
      types: LOAD_SUGGESTED_SCHEDULES,
      paged: true,
    }),
  )
}

export const createShiftTrade = (chosenShift, selectedSuggestedIds) => (
  dispatch,
  getState,
) => {
  const fromId = chosenShift || getFromId(getState())
  const toIds = selectedSuggestedIds || getToIds(getState())

  return dispatch(
    apiCall({
      endpoint: '/shifts/trades',
      method: 'POST',
      query: {
        data: {
          type: 'shiftTrades',
          relationships: {
            fromSchedule: {
              data: {
                type: 'schedules',
                id: fromId,
              },
            },
            toSchedules: {
              data: map(toIds, item => ({
                type: 'schedules',
                id: item,
              })),
            },
          },
        },
      },
      types: CREATE_SHIFT_TRADE,
    }),
  )
}

import { createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import union from 'lodash/union'
import without from 'lodash/without'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { RESET_SUCCESSFUL } from 'store/actions/employeeApp/schedules'
import {
  CREATE_SHIFT_TRADE,
  DESELECT_SUGGESTED_SCHEDULE,
  LOAD_SUGGESTED_SCHEDULES,
  SELECT_SUGGESTED_SCHEDULE,
} from 'store/actions/employeeApp/tradeShift'

const initialState = Immutable({
  toIds: [],
  suggestionsIds: [],
  isLoadingSuggestions: false,
  isLoadingSchedules: false,
  isRequestingTrade: false,
  isRequestSuccessful: false,
})

const handlers = {
  [LOAD_SUGGESTED_SCHEDULES.REQUEST]: state =>
    state.merge({
      suggestionsIds: [],
      isLoadingSuggestions: true,
    }),
  [LOAD_SUGGESTED_SCHEDULES.SUCCESS]: createLoadHandler('schedules', {
    mapToKey: 'suggestionsIds',
    withLoading: false,
    addToState: {
      toIds: [],
      isLoadingSuggestions: false,
    },
  }),
  [SELECT_SUGGESTED_SCHEDULE]: (state, { payload }) => {
    const unionSelection = union(state.toIds, [payload])
    return state.merge({
      toIds: [...unionSelection],
    })
  },
  [DESELECT_SUGGESTED_SCHEDULE]: (state, { payload }) => {
    const withoutSelection = without(state.toIds, payload)
    return state.merge({
      toIds: [...withoutSelection],
    })
  },
  [CREATE_SHIFT_TRADE.REQUEST]: state =>
    state.merge({
      isRequestingTrade: true,
    }),
  [CREATE_SHIFT_TRADE.SUCCESS]: state =>
    state.merge({
      toIds: [],
      suggestionsIds: [],
      isRequestingTrade: false,
      isRequestSuccessful: true,
    }),
  [CREATE_SHIFT_TRADE.FAILURE]: state =>
    state.merge({ isRequestSuccessful: false }),
  [RESET_SUCCESSFUL]: state =>
    state.isRequestSuccessful
      ? state.merge({ isRequestSuccessful: false })
      : state,

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

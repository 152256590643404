import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import pointerCoordinate from './pointerCoordinate'
import { Container, StyledInput, Thumb, Track } from './styles'

class Switch extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      checked: !!props.checked,
      hasFocus: false,
    }

    this.input = null
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const checked = get(nextProps, 'checked', '')
    if (typeof checked === 'boolean') {
      this.setState({ checked })
    }
  }

  addInputRef = ref => {
    this.input = ref
  }

  handleClick = event => {
    const { checked: propsChecked } = this.props
    const checkbox = this.input

    if (event.target !== checkbox && !this.moved) {
      this.previouslyChecked = checkbox.checked
      event.preventDefault()
      checkbox.focus()
      checkbox.click()
      return
    }

    const checked = get(this.props, 'checked') ? propsChecked : checkbox.checked

    this.setState({ checked })
  }

  handleTouchStart = event => {
    this.startX = pointerCoordinate(event).x
    this.activated = true
  }

  handleTouchMove = event => {
    if (!this.activated) return

    const { checked } = this.state

    this.moved = true

    if (this.startX) {
      const currentX = pointerCoordinate(event).x
      if (checked && currentX + 15 < this.startX) {
        this.setState({ checked: false })
        this.startX = currentX
        this.activated = true
      } else if (currentX - 15 > this.startX) {
        this.setState({ checked: true })
        this.startX = currentX
        this.activated = currentX < this.startX + 5
      }
    }
  }

  handleTouchEnd = event => {
    if (!this.moved) return

    const { checked } = this.state

    const checkbox = this.input
    event.preventDefault()

    if (this.startX) {
      const endX = pointerCoordinate(event).x
      if (this.previouslyChecked === true && this.startX + 4 > endX) {
        if (this.previouslyChecked !== checked) {
          this.setState({ checked: false })
          this.previouslyChecked = checked
          checkbox.click()
        }
      } else if (this.startX - 4 < endX) {
        if (this.previouslyChecked !== checked) {
          this.setState({ checked: true })
          this.previouslyChecked = checked
          checkbox.click()
        }
      }

      this.activated = false
      this.startX = null
      this.moved = false
    }
  }

  handleFocus = event => {
    const { onFocus } = this.props

    if (onFocus) {
      onFocus(event)
    }

    this.setState({ hasFocus: true })
  }

  handleBlur = event => {
    const { onBlur } = this.props

    if (onBlur) {
      onBlur(event)
    }

    this.setState({ hasFocus: false })
  }

  render() {
    const { checked, hasFocus } = this.state
    const { disabled, readOnly, sameColor, ...inputProps } = this.props

    return (
      <Container
        checked={checked}
        disabled={disabled}
        focus={hasFocus}
        readOnly={readOnly}
        sameColor={sameColor}
        onClick={this.handleClick}
        onTouchEnd={this.handleTouchEnd}
        onTouchMove={this.handleTouchMove}
        onTouchStart={this.handleTouchStart}
      >
        <Track />
        <Thumb />

        <StyledInput
          disabled={disabled}
          readOnly={readOnly}
          {...inputProps}
          ref={this.addInputRef}
          type="checkbox"
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
        />
      </Container>
    )
  }
}

Switch.displayName = 'Switch'

Switch.defaultProps = {
  checked: false,
  disabled: false,
  readOnly: false,
  name: '',
  value: '',
  id: '',
  sameColor: false,
  onChange: null,
  onFocus: null,
  onBlur: null,
  'aria-Labeledby': '',
  'aria-label': '',
}

Switch.propTypes = {
  'aria-label': PropTypes.string,
  // eslint-disable-next-line react/sort-prop-types
  'aria-Labeledby': PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  sameColor: PropTypes.bool,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
}

export default Switch

import React from 'react'

import { ShiftValidationRuleAPIItem } from 'API'

import { HoursRangeCondition } from './HoursRangeCondition'

type Props = {
  rule: ShiftValidationRuleAPIItem
}

// TODO: add common i18n for this component & RuleRenderer->SingleShiftLength
export function SingleShiftLengthThreshold({ rule }: Props) {
  return (
    <>
      The employee is assigned to a shift with a length{' '}
      <HoursRangeCondition formSubPath="shiftLengthThreshold" rule={rule} />.
    </>
  )
}

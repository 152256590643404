import { useApolloQuery } from 'API/services/Apollo'

import {
  SortingDirection,
  WorkWeekSortingField,
} from 'constants/gatewayGraphQL'

import { WorkWeeksByOffsetQuery } from './GraphQL'

type Props = {
  paging: Gateway.QueryWorkWeeksByOffsetArgs['paging']
}

export function useWorkWeekDataByOffset({ paging }: Props) {
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'workWeeksByOffset'>,
    Gateway.QueryWorkWeeksByOffsetArgs
  >(WorkWeeksByOffsetQuery, {
    variables: {
      sorting: [
        {
          field: WorkWeekSortingField.StartDay,
          direction: SortingDirection.Asc,
        },
      ],
      paging,
    },
    fetchPolicy: 'network-only',
  })

  const workWeeks = data?.workWeeksByOffset?.rows ?? []

  return {
    workWeeks,
    loading,
    refetch,
    total: data?.workWeeksByOffset?.pageInfo.total ?? 0,
  }
}

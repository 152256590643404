import { Fields } from './types'

export const schema = {
  [Fields.CurrentHolidayDate]: {},
  [Fields.StartDate]: { presence: true },
  [Fields.Comment]: {
    presence: false,
    type: 'string',
  },
}

import normalizeAlt from 'json-api-normalizer'
import Pusher from 'pusher-js'
import { eventChannel } from 'redux-saga'
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects'

import { api, pusher } from 'constants/config'

import { AuthService } from 'services/Auth'

import { COMPLETE_REFETCH } from 'store/actions/app'
import { LOG_OUT } from 'store/actions/auth'
import { receiveNotification } from 'store/actions/notifications'
import { getId } from 'store/selectors/viewer'

function createPusherChannel(socket, channelName) {
  return eventChannel(emitter => {
    const channel = socket.subscribe(channelName)
    channel.bind('create', data => {
      emitter(data)
    })

    return () => {
      channel.unbind()
    }
  })
}

function* notificationsListener() {
  const userId = yield select(getId)
  const headers = yield AuthService.getAuthHeaders()
  const channelName = `private-users.${userId}.notifications`

  const socket = new Pusher(pusher.token, {
    authEndpoint: `${api?.rubyUrl}/notifications/auth`,
    encrypted: true,
    cluster: 'mt1',
    auth: {
      headers: {
        'Content-Type': 'application/vnd.api+json',
        ...headers,
      },
    },
  })

  const socketChannel = yield call(createPusherChannel, socket, channelName)

  try {
    while (true) {
      const data = yield take(socketChannel)
      const normalized = normalizeAlt(data, {
        camelizeKeys: false,
      })
      yield put(receiveNotification({ data: normalized }))
    }
  } finally {
    if (yield cancelled()) {
      socket.disconnect()
    }
  }
}

export default function* main() {
  while (yield take(COMPLETE_REFETCH)) {
    const createListenerTask = yield fork(notificationsListener)

    yield take(LOG_OUT)
    yield cancel(createListenerTask)
  }
}

import { useState } from 'react'

// @ts-ignore
import {
  EventSourceMessage,
  fetchEventSource,
} from '@microsoft/fetch-event-source'

import { api } from 'constants/config'

import { useLocale } from 'hooks'

import { i18n } from 'i18n'

import { AuthService } from 'services/Auth'
import { showToast } from 'services/Toasts'

// TODO: Refactor to proper API call
export function useNLPInferenceBySSE() {
  const locale = useLocale()

  const [inferring, setInferring] = useState(false)
  const [message, setMessage] = useState('')
  const [data, setData] = useState<Record<string, any>>({})

  const [abortController, setAbortController] = useState(new AbortController())

  const onOpen = async (response: Response) => {
    if (!response.ok) {
      setInferring(false)
      showToast({
        type: 'error',
        title: i18n('alertMessages.default.error.title'),
        content: i18n('alertMessages.default.error.message'),
      })
      throw new Error('Network request failed')
    }
  }

  const onMessage = (msg: EventSourceMessage) => {
    const { data, event } = msg
    switch (event) {
      case 'message':
        setMessage(JSON.parse(data).content || '')
        break
      case 'jsonapi':
        setData(JSON.parse(data).list_timecards || {})
        break
      default:
        break
    }
  }

  const onClose = () => {
    setInferring(false)
  }

  const onError = () => {
    setInferring(false)
    showToast({
      type: 'error',
      title: i18n('alertMessages.default.error.title'),
      content: i18n('alertMessages.default.error.message'),
    })
    throw new Error('Network request failed')
  }

  async function launchInferenceBySSE(query: string) {
    setInferring(true)
    await fetchEventSource(`${api.rubyUrl}/nlp/inference`, {
      method: 'POST',
      body: JSON.stringify({ query, locale }),
      signal: abortController.signal,
      headers: {
        Accept: 'text/event-stream',
        'Content-Type': 'application/json',
        'Accept-Language': locale,
        ...(await AuthService.getAuthHeaders()),
      },
      onopen: onOpen,
      onmessage: onMessage,
      onclose: onClose,
      onerror: onError,
    })
  }

  function pauseInference() {
    abortController.abort()
    setAbortController(new AbortController())
    setInferring(false)
  }

  function clearInference() {
    setMessage('')
    setData({})
  }

  return {
    launchInferenceBySSE,
    pauseInference,
    clearInference,
    inferring,
    inference: { message, data },
  }
}

// eslint-disable-next-line simple-import-sort/imports
import { useStartAutoSchedulingTask as start } from './useStartAutoSchedulingTask'
import { useApplyAutoSchedulingTask as apply } from './useApplyAutoSchedulingTask'
import { useAutoSchedulingTask as byId } from './useAutoSchedulingTask'
import { useAutoSchedulingTasksByCursor as byCursor } from './useAutoSchedulingTasksByCursor'
// old methods
import { useLaunchAutoAssignTask as launch } from './useLaunchAutoAssignTask'
import { useAutoAssignStatus as getStatus } from './useAutoAssignStatus'
import { AutoAssignStatus } from './types'

export const AutoSchedulingTaskAPI = {
  start,
  apply,
  byId,
  byCursor,
  // old methods
  launch,
  getStatus,
  AutoAssignStatus,
}

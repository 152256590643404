import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import noop from 'lodash/noop'

import BatchSelect from 'components/blocks/__v2__/BatchSelect/'
import { Select } from 'components/ui/__v3__/Select'

import { BATCH_LOAD_MORE_SIZE } from 'constants/areas'

import { formatOptionLabel } from 'helpers/areas'
import { createDebounce } from 'helpers/debounce'
import { denormalize } from 'helpers/redux'

import { useAppContext } from 'hooks'

import _ from 'i18n'

import { showError } from 'services/API'
import { locationToOption } from 'services/Options'

import { LOCATIONS_TYPE } from 'store/reducers/areas'

function LocationsPicker({
  activePickerId,
  cacheOptions,
  display,
  include,
  excludedManagerIds,
  initial,
  isDisabled,
  loadingText,
  placeholder,
  preload,
  value,
  onClear,
  onInit,
  onLoad,
  onPreload,
  onActivate,
  manager,
  withBatchSelect,
  withPortal,
  ...rest
}) {
  const { company } = useAppContext()
  const isEtfo = company.identity.Etfo
  const [input, setInput] = useState('')
  const preloaded = useRef(false)
  const [loading, setLoading] = useState(false)

  const defaultOptions = useMemo(
    () => map(initial.entities || [], locationToOption),
    [initial.entities],
  )

  const denormalizeLoadedOptions = useCallback(
    result => {
      if (!result?.ok) {
        showError(result)
        return []
      }

      const type = LOCATIONS_TYPE
      const data = get(result, ['payload', 'data'])
      const locationIds = map(get(data, type), 'id')
      const denormalizedLocations = isEmpty(locationIds)
        ? []
        : denormalize(data, type, locationIds)

      const options = map(denormalizedLocations, locationToOption)

      if (isEtfo) {
        options.sort((a, b) => {
          if (a.label < b.label) {
            return -1
          }
          if (a.label > b.label) {
            return 1
          }
          return 0
        })
      }

      return options
    },
    [isEtfo],
  )

  const loadMoreOptions = useCallback(
    async ({ locations }) => {
      setLoading(true)

      const result = await onLoad({
        ...locations,
        include,
        display,
        excludedManagerIds,
        isEtfo,
      })

      setLoading(false)

      return denormalizeLoadedOptions(result)
    },
    [
      denormalizeLoadedOptions,
      display,
      excludedManagerIds,
      include,
      isEtfo,
      onLoad,
    ],
  )

  const initOptions = useCallback(() => {
    if (!initial.isLoaded) {
      onInit({
        display,
        excludedManagerIds,
        include,
        isEtfo,
      })
    }
  }, [display, excludedManagerIds, include, initial.isLoaded, isEtfo, onInit])

  useEffect(() => {
    if (preload && !preloaded.current && initial.isLoaded) {
      onPreload(loadMoreOptions)(defaultOptions)
      preloaded.current = true
    }
  }, [
    defaultOptions,
    loadMoreOptions,
    initial.entities,
    initial.isLoaded,
    onPreload,
    preload,
  ])

  useEffect(() => {
    onClear()
  }, [onClear])

  useEffect(() => {
    if (!isDisabled && preload && !initial.isLoaded && !initial.isLoading) {
      initOptions()
    }
  }, [initOptions, initial.isLoaded, initial.isLoading, isDisabled, preload])

  const handleLoadOptions = useMemo(() => {
    return createDebounce(
      (inputValue, callback) => {
        if (!inputValue) {
          callback([])
        } else {
          onLoad({
            display,
            include,
            name: inputValue,
            excludedManagerIds,
            isEtfo,
          }).then(result => {
            const options = denormalizeLoadedOptions(result)
            callback(options)
          })
        }
      },
      { leading: true, trailing: true },
    )
  }, [
    onLoad,
    display,
    include,
    excludedManagerIds,
    isEtfo,
    denormalizeLoadedOptions,
  ])

  const handleSelectFilteredLocations = useCallback(() => {
    onLoad({
      display,
      include,
      name: input,
      size: BATCH_LOAD_MORE_SIZE,
      excludedManagerIds,
      isEtfo,
    }).then(result => {
      if (!result?.ok) {
        showError(result)
        return
      }
      const denormalizedDepartments = denormalizeLoadedOptions(result)
      rest.onChange(denormalizedDepartments)
      setInput('')
    })
  }, [
    denormalizeLoadedOptions,
    display,
    excludedManagerIds,
    include,
    input,
    isEtfo,
    onLoad,
    rest,
  ])

  const renderLoadingMessage = useCallback(
    () => loadingText ?? `${_('common.loading')}...`,
    [loadingText],
  )

  const handleInputChange = useCallback(
    (inputValue, action) => {
      if (action.action === 'input-change') setInput(inputValue)
    },
    [setInput],
  )

  const pickerDisabled = isDisabled
  const isSelectAllButtonDisabled =
    !isEmpty(value) || pickerDisabled || initial.isLoading
  const hasBatchSelect = withBatchSelect && rest.isMulti

  return (
    <>
      <Select
        async
        cacheOptions={cacheOptions}
        closeMenuOnSelect={!rest.isMulti}
        defaultOptions={defaultOptions}
        formatOptionLabel={formatOptionLabel('location')}
        isClearable={false}
        isDisabled={pickerDisabled}
        isLoading={initial.isLoading || loading}
        isSearchable
        loadOptions={handleLoadOptions}
        loadingMessage={renderLoadingMessage}
        placeholder={placeholder}
        value={value}
        withBatchSelect={hasBatchSelect}
        withPortal={withPortal}
        onInputChange={handleInputChange}
        onMenuOpen={initOptions}
        {...rest}
      />

      {hasBatchSelect && (
        <BatchSelect
          handleSelect={handleSelectFilteredLocations}
          hasInput={!!input}
          isDisabled={isSelectAllButtonDisabled}
          size={BATCH_LOAD_MORE_SIZE}
        />
      )}
    </>
  )
}

LocationsPicker.defaultProps = {
  cacheOptions: true,
  display: null,
  excludedManagerIds: null,
  include: null,
  initial: {},
  isDisabled: false,
  loadingText: null,
  placeholder: null,
  preload: false,
  value: null,
  withBatchSelect: true,
  withPortal: true,
  onClear: noop,
  onInit: noop,
  onLoad: noop,
  onPreload: () => noop,
}

LocationsPicker.propTypes = {
  ...Select.propTypes,
  cacheOptions: PropTypes.bool,
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  excludedManagerIds: PropTypes.array,
  include: PropTypes.string,
  initial: PropTypes.object,
  isDisabled: PropTypes.bool,
  loadingText: PropTypes.string,
  placeholder: PropTypes.string,
  preload: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  withBatchSelect: PropTypes.bool,
  withPortal: PropTypes.bool,
  onClear: PropTypes.func,
  onInit: PropTypes.func,
  onLoad: PropTypes.func,
  onPreload: PropTypes.func,
}

export default LocationsPicker

import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import {
  loadCustomLabelDays,
  loadCustomLabelDaysForEmployee,
} from 'store/actions/customLabelDays'
import { getCustomLabelDays } from 'store/selectors/customLabelDays'

import CustomLabelDaysPicker from './CustomLabelDaysPicker'

const selectors = createStructuredSelector({
  customLabelDays: getCustomLabelDays,
})

const actions = {
  onLoadByEmployee: loadCustomLabelDaysForEmployee,
  onLoadAllLeaves: loadCustomLabelDays,
}

/**
 * @deprecated split by use cases
 */
export default connect(selectors, actions)(CustomLabelDaysPicker)

import { AiOutlineClose as CrossIcon } from 'react-icons/ai'

import styled, { css } from 'styled-components'
import { layout } from 'styled-system'

import { Flex } from 'components/ui/__v2__/Grid'

import Utils from 'services/Utils'

import { ShiftTagProps } from './types'

type TagContainerProps = Omit<ShiftTagProps, 'label' | 'onRemove'>

const backgroundCss = ({ tagColor }: TagContainerProps) => {
  const hexColor = `#${tagColor}`
  return css`
    background: ${hexColor} !important;
    color: ${Utils.Color.blackOrWhiteText(hexColor)} !important;
  `
}

export const TagContainer = styled(Flex)<TagContainerProps>`
  height: 25px;
  margin: 2px;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space[0]}px ${({ theme }) => theme.space[1]}px;
  border-radius: ${({ theme }) => theme.space[1]}px;
  max-width: 230px;

  ${layout}
  ${backgroundCss}
`

export const IconButtonContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export { CrossIcon }

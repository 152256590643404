import { useCallback } from 'react'

import { useStoreon } from 'storeon/react'

import {
  CLASSIFIED_TIME_TEMPORAL_WORKFLOW_SET,
  CLASSIFIED_TIME_TEMPORAL_WORKFLOW_STATE_KEY,
  CLASSIFIED_TIME_TEMPORAL_WORKFLOW_UNSET,
} from '../constants'
import {
  ClassifiedTimeTemporalWorkflowEvents,
  ClassifiedTimeTemporalWorkflowState,
  TemporalWorkflowPayload,
} from '../types'

export function useClassifiedTimeTemporalWorkflowState() {
  const {
    [CLASSIFIED_TIME_TEMPORAL_WORKFLOW_STATE_KEY]: state,
    dispatch,
  } = useStoreon<
    ClassifiedTimeTemporalWorkflowState,
    ClassifiedTimeTemporalWorkflowEvents
  >(CLASSIFIED_TIME_TEMPORAL_WORKFLOW_STATE_KEY)

  const temporalWorkflow = state

  const addTemporalWorkflow = useCallback(
    (payload: TemporalWorkflowPayload) =>
      dispatch(CLASSIFIED_TIME_TEMPORAL_WORKFLOW_SET, payload),
    [dispatch],
  )

  const clearTemporalWorkflow = useCallback(
    () => dispatch(CLASSIFIED_TIME_TEMPORAL_WORKFLOW_UNSET),
    [dispatch],
  )

  return { temporalWorkflow, addTemporalWorkflow, clearTemporalWorkflow }
}

import { useApolloMutation } from 'API/services/Apollo'

import { StartAutoSchedulingTaskMutation } from './GraphQL'

export function useStartAutoSchedulingTask() {
  const [innerStart, { loading }] = useApolloMutation<
    MutationData<'startAutoSchedulingTask'>,
    Gateway.MutationStartAutoSchedulingTaskArgs
  >(StartAutoSchedulingTaskMutation)

  async function start(input: Gateway.StartAutoSchedulingTaskInput) {
    const result = await innerStart({
      variables: {
        input,
      },
    })
    return Boolean(result.errors)
  }

  return {
    start,
    starting: loading,
  }
}

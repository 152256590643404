import { CreateTimeoffKinds } from 'API'
import { CreateTimeoffMutationArgs } from 'API/Timeoffs/Json'

import { excludedDates } from './general'
import { partialInputsToDateTimes } from './generators'

import { PartalTimeoffFormState, TimeoffFormState } from '../types'

type Props = {
  state: TimeoffFormState
  isNahdi: boolean
  kind: CreateTimeoffKinds
}
export function formToPayload({
  state,
  isNahdi,
  kind,
}: Props): CreateTimeoffMutationArgs {
  function isPartial(state: TimeoffFormState): state is PartalTimeoffFormState {
    return state.partial
  }
  const partial = isPartial(state)

  let args = {
    employeeId: state.employee?.id!,
    leaveTypeId: state.leaveType?.value!,
    locationId: isNahdi && state.location?.id,
    documentId: state.document?.[0].id,
  } as CreateTimeoffMutationArgs

  if (partial) {
    const { startTimeISO, endTimeISO } = partialDateTimeToPayload(
      state.date!,
      state.startTime!,
      state.endTime!,
      state.employee?.employee.branch.settings?.timezone ?? 'local',
    )
    args = {
      ...args,
      reason: state.reason ? state.reason.trim() : undefined,
      scheduleConflictType:
        state.scheduleConflictType && kind === CreateTimeoffKinds.Assign
          ? state.scheduleConflictType
          : undefined,
      partial: true,
      startTime: startTimeISO,
      endTime: endTimeISO,
    }
  } else {
    args = {
      ...args,
      reason: state.reason ? state.reason.trim() : undefined,
      scheduleConflictType:
        state.scheduleConflictType && kind === CreateTimeoffKinds.Assign
          ? state.scheduleConflictType
          : undefined,
      partial: false,
      startAt: state.period?.from!,
      finishAt: state.period?.to!,
      excludedDates: state.dates
        .filter(excludedDates)
        .map(dateItem => dateItem.label),
    }
  }

  return args
}

function partialDateTimeToPayload(
  date: string,
  startTime: number,
  endTime: number,
  employeeTz: string,
) {
  const { startDatetime, endDatetime } = partialInputsToDateTimes(
    date,
    startTime,
    endTime,
  )

  const startTimeISO = startDatetime
    .setZone(employeeTz, { keepLocalTime: true })
    .toFormat('y-LL-dd HH:mm ZZZ')
  const endTimeISO = endDatetime
    .setZone(employeeTz, { keepLocalTime: true })
    .toFormat('y-LL-dd HH:mm ZZZ')

  return { startTimeISO, endTimeISO }
}

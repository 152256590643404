import {
  AutoSchedulingTaskConfigurationFields,
  AutoSchedulingTaskFields,
  EssentialAutoSchedulingTaskFields,
} from '../Fragments/types'

export type AutoSchedulingTaskItem = AutoSchedulingTaskFields & {
  configuration: AutoSchedulingTaskConfigurationFields
}

export type AutoSchedulingTaskQueryData = {
  autoSchedulingTask: AutoSchedulingTaskItem
}

export type AutoSchedulingTasksByCursorQueryData = {
  autoSchedulingTasksByCursor: EntitiesByCursor<EssentialAutoSchedulingTaskFields>
}

/** @description A composite state mapped from two API enums */
export enum AutoSchedulingTaskDisplayState {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
  Failed = 'FAILED',
  Outdated = 'OUTDATED',
  Applied = 'APPLIED',
}

import { useCallback, useEffect, useMemo } from 'react'

import { DateTime } from 'luxon'
import { useStoreon } from 'storeon/react'
import { SelectOption } from 'Types/common'

import { useAppContext } from 'hooks'

import { HistoricDemandWidgetModes } from 'pages/Dashboard/components/HistoricDemand/types'

import Utils from 'services/Utils'

import {
  HISTORIC_DEMAND_STATE_KEY,
  HistoricDemandEvents,
  HistoricDemandState,
  HistoricDemandSubState,
} from './module'

export function useHistoricDemandWidget() {
  const {
    companySettings: { calendarStartDay },
  } = useAppContext()

  const { [HISTORIC_DEMAND_STATE_KEY]: state, dispatch } = useStoreon<
    HistoricDemandState,
    HistoricDemandEvents
  >(HISTORIC_DEMAND_STATE_KEY)

  const dateRange = useMemo(() => {
    const storageStartIsoDate = state?.dateRange?.start
    const storageEndIsoDate = state?.dateRange?.end

    // Fallback to cover data missing in LS at first render
    const { start, end } = Utils.DateTime.getCurrentWeek(calendarStartDay)

    const startISODate = storageStartIsoDate ?? start
    const endISODate = storageEndIsoDate ?? end

    return {
      start: DateTime.fromISO(startISODate),
      end: DateTime.fromISO(endISODate),
    }
  }, [calendarStartDay, state])

  const updateState = useCallback(
    (variables: Partial<HistoricDemandSubState>) =>
      dispatch('historicDemand/set', variables),
    [dispatch],
  )

  useEffect(() => {
    updateState({
      dateRange: Utils.DateTime.getCurrentWeek(calendarStartDay),
    })
  }, [calendarStartDay, updateState])

  const onResetDateRange = useCallback(
    () =>
      updateState({
        dateRange: Utils.DateTime.getCurrentWeek(calendarStartDay),
      }),
    [calendarStartDay, updateState],
  )

  const onDateRangeChange = useCallback(
    ({ start, end }) =>
      updateState({
        dateRange: {
          start: start?.toISODate(),
          end: end?.toISODate(),
        },
      }),
    [updateState],
  )

  const isDisabled = useMemo(() => state?.disabled, [state?.disabled])

  const isInitialWeek = useMemo(
    () =>
      DateTime.fromISO(
        Utils.DateTime.getCurrentWeek(calendarStartDay).start,
      ).hasSame(dateRange.start, 'day'),
    [calendarStartDay, dateRange.start],
  )

  const onRemove = useCallback(() => updateState({ disabled: true }), [
    updateState,
  ])

  const onRestore = useCallback(() => updateState({ disabled: false }), [
    updateState,
  ])

  const onChangeMode = useCallback(
    (modeOption: SelectOption<HistoricDemandWidgetModes>) =>
      updateState({ mode: modeOption.value }),
    [updateState],
  )

  return {
    mode: state.mode,
    onChangeMode,

    dateRange,
    isInitialWeek,
    onDateRangeChange,
    onResetDateRange,

    isDisabled,

    onRemove,
    onRestore,
  }
}

import { useEffect, useState } from 'react'

export function useShiftLogsByCursor() {
  // TODO: Shift logs query
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  return {
    shiftLogs: [],
    shiftLogsLoading: loading,
  }
}

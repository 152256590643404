import React, { useCallback } from 'react'
import { FieldValues, useWatch } from 'react-hook-form'

import { LayoutProps, SpaceProps } from 'styled-system'

import { toPairs } from 'lodash'

import { FormCheckbox, FormCheckboxProps } from 'components/ui/__v3__/HookForm'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'

import { WeekDay } from 'constants/gatewayGraphQL'

import { i18n } from 'i18n'

import { WeekDaysContainer } from './styles'

type SpecificProps = {
  enforceAtLeastOneSelected?: boolean
  wrapper?: LayoutProps & SpaceProps
}
export type WeekdayPickerState = { [weekDay in Gateway.WeekDay]: boolean }
export type FormWeekdaysPickerProps<
  T extends FieldValues
> = ControllerBaseProps<T> & FormCheckboxProps<T> & SpecificProps

export function FormWeekdaysPicker<T extends FieldValues>({
  enforceAtLeastOneSelected,
  name,
  control,
  wrapper,
  ...rest
}: FormWeekdaysPickerProps<T>) {
  const weekValues = useWatch({ name, control }) as WeekdayPickerState

  const disabled = useCallback(
    (weekDay: WeekDay) => {
      if (!enforceAtLeastOneSelected) return rest.disabled
      const totalChecked = toPairs(weekValues).filter(([, enabled]) => enabled)
      return (
        totalChecked?.length === 1 &&
        !!totalChecked.find(([key]) => key === weekDay)
      )
    },
    [enforceAtLeastOneSelected, rest.disabled, weekValues],
  )

  return (
    <WeekDaysContainer {...wrapper}>
      {[
        WeekDay.Monday,
        WeekDay.Tuesday,
        WeekDay.Wednesday,
        WeekDay.Thursday,
        WeekDay.Friday,
        WeekDay.Saturday,
        WeekDay.Sunday,
      ].map(weekDay => (
        <FormCheckbox<T>
          {...rest}
          control={control}
          disabled={disabled(weekDay)}
          key={weekDay}
          labelPlacement="bottom"
          labelText={i18n(`weekdays.short.${weekDay}`)}
          // @ts-ignore
          name={`${name}.${weekDay}`}
          size={18}
          typography={{ fontSize: '12px' }}
          wrapper={{ margin: 1 }}
        />
      ))}
    </WeekDaysContainer>
  )
}

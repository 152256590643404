import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import {
  getCursorPageMeta,
  isCursorPageMetaChanged,
  transformResponseToEntities,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { useAppContext, useLocale } from 'hooks'

import { ProfileLogsPageQueryParams } from 'pages/EmployerApp/People/pages/ProfileEditLogsPage/hooks'

import { PROFILE_LOGS_JSON_API_TYPE, ProfileLogQueryKeys } from './constants'
import { profileLogsQuery } from './Json'
import { ProfileLogAPIItem } from './types'

interface Props {
  sort: ProfileLogsPageQueryParams['sort']
  filters: ProfileLogsPageQueryParams['filters']
  page: CursorPage
}

export function useProfileLogsByCursor({ filters, sort, page }: Props) {
  const { companyId } = useAppContext()
  const locale = useLocale()

  const [currentPageMeta, setCurrentPageMeta] = useState<JsonApi.CursorPaging>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, isFetching, error, refetch } = useQuery({
    structuralSharing: false,
    refetchOnWindowFocus: false,

    queryKey: ProfileLogQueryKeys.byCursor(page, sort, filters),
    queryFn: () =>
      profileLogsQuery({
        companyId,
        locale,
        sort,
        filters,
        page,
      }),

    select: (response: JsonApi.Response) => {
      const { entities } = transformResponseToEntities<ProfileLogAPIItem>({
        response,
        jsonApiType: PROFILE_LOGS_JSON_API_TYPE,
      })
      const newPageMeta = getCursorPageMeta(response)
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }

      return entities
    },
  })

  return {
    profileLogs: data ?? [],
    loading: isFetching,
    profileLogsError: error,
    refetchProfileLogs: refetch,
    currentPageMeta,
  }
}

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const CREATE_TIME_OFF = createAsyncAction(
  'requestTimeoff/CREATE_TIME_OFF',
)

export const LOAD_TIME_OFFS = createAsyncAction('company/LOAD_TIME_OFFS')

export const DELETE_TIME_OFF = createAsyncAction(
  'company/schedule/DELETE_TIME_OFF',
)

const TIMEOFF_INCLUDE = [
  'employee.profile',
  'customLeaveDay',
  'document',
  'lastUpdatedBy.profile',
  'processedBy.profile',
  'createdBy.profile',
].join(',')

export const loadTimeoffs = ({
  number = undefined,
  size = undefined,
  sort = '-name,-created_at',
  filters = {},
}) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())
  const paged = !!size
  const page = paged ? { number, size } : {}

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/timeoffs`,
      types: LOAD_TIME_OFFS,
      method: 'GET',
      query: {
        include: TIMEOFF_INCLUDE,
        page,
        sort,
        filter: filters,
      },
      paged,
    }),
  )
}

export const createTimeOff = ({
  attributes,
  relationships,
  isTimeoffRequest,
}) => {
  return apiCall({
    endpoint: '/timeoffs',
    method: 'POST',
    query: {
      include: TIMEOFF_INCLUDE,
      data: {
        attributes,
        relationships,
      },
    },
    types: CREATE_TIME_OFF,
    payload: {
      isTimeoffRequest,
    },
  })
}

export const deleteTimeoff = ({ timeoffId, employeeId }) => {
  return apiCall({
    endpoint: `/timeoffs/${timeoffId}`,
    method: 'DELETE',
    types: DELETE_TIME_OFF,
    payload: {
      deletedId: timeoffId,
      relationId: employeeId,
    },
  })
}

/**
 * We pass links because BE only provides a show link
 */
export const getTimeoffDetails = link => {
  return apiCall({
    endpoint: link,
    query: {
      include: 'employee.profile,employee.branch,custom_leave_day,document',
    },
  })
}

import { useCreateCustomCvField } from './useCreateCustomCvField'
import { useCustomCvField } from './useCustomCvField'
import { useCustomCvFieldsByCursor } from './useCustomCvFieldsByCursor'
import { useDeleteCustomCvField } from './useDeleteCustomCvField'
import { usePickerCustomCvFields } from './usePickerCustomCvFields'
import { useUpdateCustomCvField } from './useUpdateCustomCvField'
import { useUpdateCustomCvFields } from './useUpdateCustomCvFields'

export const CustomCvFieldsAPI = {
  create: useCreateCustomCvField,
  delete: useDeleteCustomCvField,
  update: useUpdateCustomCvField,
  updateMany: useUpdateCustomCvFields,
  byCursor: useCustomCvFieldsByCursor,
  byId: useCustomCvField,
  picker: usePickerCustomCvFields,
}

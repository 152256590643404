import { TimeBucketChildCoreWithParentAPIItem } from 'API/TimeBucket'

import Utils from 'services/Utils'

import { TimeBucketChildrenOption } from '../types'

export function timeBucketChildrenEntityToOption({
  withParentsNames,
  entity,
}: {
  entity: TimeBucketChildCoreWithParentAPIItem
  withParentsNames?: boolean
}): TimeBucketChildrenOption {
  return {
    value: entity.id,
    label: withParentsNames
      ? Utils.TimeBucket.getTimeBucketJoinedName({
          timeBucketChild: entity,
        })
      : entity.name,
    timeBucketChild: entity,
  }
}

import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { TimeTypesPicker } from 'components/blocks/__v3__/TimeTypesPicker'
import { Flex } from 'components/ui/__v2__/Grid'
import { InputLabel } from 'components/ui/__v3__'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'

type Props = {
  labelText?: string
  required?: boolean
  disabled?: boolean
  width?: number
  minWidth?: number
  placeholder?: string
  isMulti?: boolean
  withPortal?: boolean
}

export function FormTimeTypesPicker<T extends FieldValues>({
  control,
  disabled,
  name,
  labelText,
  isMulti = false,
  required = false,
  ...rest
}: ControllerBaseProps<T> & Props) {
  const {
    field: { onChange, value, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  return (
    <Flex flexDirection="column">
      {labelText && (
        <InputLabel>
          <LabelText labelText={labelText} required={required} />
        </InputLabel>
      )}
      <TimeTypesPicker
        isDisabled={disabled}
        isMulti={isMulti}
        isSearchable
        value={value}
        onBlur={onBlur}
        onSelect={onChange}
        {...rest}
      />
      {error && <Error error={error} />}
    </Flex>
  )
}

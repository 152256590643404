import fromPairs from 'lodash/fromPairs'
import map from 'lodash/map'
import toPairs from 'lodash/toPairs'

import { areasToRelationships } from 'helpers/areas'
import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const UPDATE_ATTENDANCE = createAsyncAction(
  'company/settings/UPDATE_ATTENDANCE',
)

export const CLEAR_ATTENDANCE = 'company/settings/CLEAR_ATTENDANCE'

export const updateAttendance = ({
  attendanceSettings,
  timeSettings: unnormalizedTimeSettings,
  leaveSettings: unnormalizedLeaveSettings,
  areas,
  refetchLocation = null,
}) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  const timeSettings = fromPairs(
    map(toPairs(unnormalizedTimeSettings), ([key, value]) => [
      key,
      parseInt(value, 10),
    ]),
  )

  const leaveSettings = fromPairs(
    map(toPairs(unnormalizedLeaveSettings), ([key, value]) => [
      key,
      parseInt(value, 10),
    ]),
  )

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/settings`,
      method: 'POST',
      types: UPDATE_ATTENDANCE,
      query: {
        data: {
          attributes: {
            attendanceSettings,
            timeSettings,
            leaveSettings,
            global: areas.global,
          },
          relationships: areasToRelationships(areas, { dataAsArray: true }),
        },
      },
      payload: {
        attendanceSettings,
        timeSettings: unnormalizedTimeSettings,
        leaveSettings: unnormalizedLeaveSettings,
        areas,
        refetchLocation,
      },
    }),
  )
}

export const clearAttendance = () => ({ type: CLEAR_ATTENDANCE })

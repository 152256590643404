import styled from 'styled-components'

import findCoverBlockedSvg from 'assets/images/svg/shifts/find-cover-blocked.svg'

import { Icon } from '../Icon'

const FindCoverBlockedIcon = styled(Icon).attrs({
  glyph: findCoverBlockedSvg,
})`
  stroke-width: 1px;
  stroke: none;
  width: ${props => (props.width ? props.width : 17)}px;
  height: ${props => (props.height ? props.height : 17)}px;
`

export default FindCoverBlockedIcon

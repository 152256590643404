import { all, fork, put, select, take } from 'redux-saga/effects'

import get from 'lodash/get'

import {
  CHANGE_DEPARTMENT_OPTION,
  INIT,
  initDone,
  LOAD_EMPLOYEE_SCHEDULE,
  loadDepartmentSchedules,
  loadEmployeeSchedules,
} from 'store/actions/employeeApp/schedules'
import {
  getDepartmentOption,
  getWeek,
} from 'store/selectors/employeeApp/schedules'
import { getBranchSettings } from 'store/selectors/viewer'

/** @deprecated */
export function* initialLoad() {
  while (true) {
    yield take(INIT)

    yield put(loadEmployeeSchedules())

    const branchSettings = yield select(getBranchSettings)
    const isAllowEmployeesSeeSchedules = get(
      branchSettings,
      'allowEmployeesSeeSchedules',
    )
    if (isAllowEmployeesSeeSchedules) {
      yield put(loadDepartmentSchedules)
    }

    yield take(LOAD_EMPLOYEE_SCHEDULE.SUCCESS)

    yield put(initDone())
  }
}

function* handleChangeDepartmentOption() {
  while (true) {
    const prevDepartmentOption = yield select(getDepartmentOption)
    const {
      payload: { departmentId },
    } = yield take(CHANGE_DEPARTMENT_OPTION)
    const week = yield select(getWeek)
    const { start, end } = week
    if (prevDepartmentOption !== departmentId) {
      yield put(loadDepartmentSchedules(start, end))
    }
  }
}

export default function* rootEmployeesSchedule() {
  yield all([fork(initialLoad), fork(handleChangeDepartmentOption)])
}

import { cursorPageToJsonApiPage } from 'API/services/utils'
import { AreasFilter, CursorPage, SelectOption } from 'Types/common'

import coreApiCall from 'services/API/coreApi'
import Utils from 'services/Utils'

import { ProfileLogTriggers } from '../../constants'

export type ProfileLogsQuerySorting = JsonApi.Sorting<'created_at'>

export type ProfileLogsQuery = {
  sort: ProfileLogsQuerySorting
  filter: {
    user_id?: JsonApi.InFilter<string>
    created_at?: JsonApi.BetweenFilter
    event?: JsonApi.EqFilter<string>
  }
  page: JsonApi.CursorPagingInput
}

type ProfileLogsQueryArgs = {
  companyId: string
  locale: string
  filters: {
    user: { value: string } | null
    createdAt?: { from: string | null; to: string | null }
    areas?: AreasFilter
    event?: SelectOption<ProfileLogTriggers>
  }
  sort: ProfileLogsQuerySorting
  page: CursorPage
}
export function profileLogsQuery({
  companyId,
  locale,
  filters,
  sort,
  page,
}: ProfileLogsQueryArgs) {
  const { event, user, createdAt } = filters

  const query: ProfileLogsQuery = {
    sort,
    page: cursorPageToJsonApiPage(page),
    filter: {
      ...(user?.value && { user_id: Utils.JsonApi.inFilter([user.value]) }),
      ...(event && { event: Utils.JsonApi.eqFilter(event.value) }),
      ...(createdAt && { created_at: Utils.JsonApi.betweenFilter(createdAt) }),
    },
  }
  return coreApiCall({
    method: 'GET',
    endpoint: `/companies/${companyId}/users/logs`,
    headers: { 'Accept-Language': locale },
    query: { include: INCLUDE, ...query },
  })
}

const INCLUDE = [
  'updatedBy.profile',
  'customCvFieldValue',
  'user.profile',
  'user.employees', // To obtain employee's status
].join(',')

import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'

import { ProfileLogsCountQuery } from './GraphQL'

type QueryProps = Pick<QueryHookOptions, 'skip' | 'pollInterval'> | undefined

export function useProfileLogsCount(
  filter: Gateway.UserLogFilter,
  queryProps: QueryProps,
) {
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'userLogsCount'>,
    Gateway.QueryUserLogsCountArgs
  >(ProfileLogsCountQuery, {
    variables: {
      filter,
    },
    skip: queryProps?.skip,
    pollInterval: queryProps?.pollInterval,
    fetchPolicy: 'network-only',
  })

  const count = data?.userLogsCount

  return {
    count,
    loading,
    refetch,
  }
}

import React, { useCallback, useRef, useState } from 'react'
import Cropper from 'react-cropper'
import Dropzone from 'react-dropzone'
import { useField } from 'react-final-form'
import PropTypes from 'prop-types'

import { Button } from 'components/ui/__v2__/Button'
import { Flex } from 'components/ui/__v2__/Grid'
import { Avatar } from 'components/ui/Avatar'

import { RASTER_PICS } from 'constants/fs'
import { FILE_UPLOAD_KINDS } from 'constants/ids'

import _ from 'i18n'

import {
  AvatarContainer,
  ChangePhotoContainer,
  DeletePhotoContainer,
  IconContainer,
  Icons,
  UploadPhoto,
} from './styles'

// TODO: TSfy

function FormProfileAvatar({ name, isReadOnly }) {
  const { input } = useField(name)
  const [downloadSrc, setDownloadSrc] = useState(null)
  const [isShowCropper, setIsShowCropper] = useState(false)
  const dropzoneRef = useRef(null)
  const cropperRef = useRef(null)
  const currentAvatar = downloadSrc || input.value

  const handleImageCrop = useCallback(() => {
    if (
      typeof cropperRef.current.cropper.getCroppedCanvas().toDataURL() ===
      'undefined'
    ) {
      return
    }

    cropperRef.current.cropper.getCroppedCanvas().toBlob(blob => {
      const croppedFile = new File([blob], FILE_UPLOAD_KINDS.profileAvatar, {
        type: blob.type,
      })
      getBase64(croppedFile).then(response => {
        setDownloadSrc(response)
      })

      input.onChange(croppedFile)
    })

    setIsShowCropper(false)
  }, [input, cropperRef])

  function handleRemoveFile() {
    setDownloadSrc(null)
    input.onChange(null)
  }

  function handleCloseCropper() {
    setIsShowCropper(false)
    handleRemoveFile()
  }

  function getBase64(value) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(value)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  function handleDrop(value) {
    if (value[0]) {
      getBase64(value[0]).then(result => {
        setDownloadSrc(result)
      })
    }
    setIsShowCropper(true)
  }

  const handleClickUploadPhoto = useCallback(() => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  }, [])

  if (isShowCropper) {
    return (
      <Flex alignItems="center" flexDirection="column">
        <Cropper
          aspectRatio={1}
          guides={false}
          ref={cropperRef}
          src={downloadSrc}
          style={{ width: '300px', height: '300px' }}
        />
        <Flex justifyContent="space-around" width="100%">
          <Button
            fontSize={12}
            height={24}
            mt="2"
            width={110}
            onClick={handleImageCrop}
          >
            {_('actions.save')}
          </Button>
          <Button
            fontSize={12}
            height={24}
            mt="2"
            width={110}
            onClick={handleCloseCropper}
          >
            {_('actions.cancel')}
          </Button>
        </Flex>
      </Flex>
    )
  }

  return (
    <Dropzone
      accept={RASTER_PICS}
      disabled={isReadOnly}
      multiple={false}
      noClick
      ref={dropzoneRef}
      onDrop={handleDrop}
    >
      {({ getRootProps, getInputProps }) => (
        <AvatarContainer
          display="flex"
          position="relative"
          style={{ width: '300px', height: '300px' }}
        >
          {currentAvatar && (
            <DeletePhotoContainer size={24}>
              <IconContainer fab>
                <Icons.Delete onClick={handleRemoveFile} />
              </IconContainer>
            </DeletePhotoContainer>
          )}
          <div cursor="pointer" {...getRootProps()}>
            <input {...getInputProps()} />
            <Avatar alt="avatar" size={300} square src={currentAvatar} />
            <UploadPhoto onClick={handleClickUploadPhoto}>
              <Icons.UploadPhoto />
            </UploadPhoto>
            <ChangePhotoContainer width={1}>
              <Button
                fontSize={12}
                height={24}
                width={110}
                onClick={handleClickUploadPhoto}
              >
                {currentAvatar
                  ? _('employees.changePhoto')
                  : _('employees.uploadPhoto')}
              </Button>
            </ChangePhotoContainer>
          </div>
        </AvatarContainer>
      )}
    </Dropzone>
  )
}

FormProfileAvatar.defaultProps = {
  isReadOnly: false,
}

FormProfileAvatar.propTypes = {
  ...Dropzone.propTypes,
  isReadOnly: PropTypes.bool,
  name: PropTypes.string.isRequired,
}

export default FormProfileAvatar

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'components/ui/__v2__/Grid'

const deviationCss = ({ deviation }: { deviation: boolean }) => css`
  border: 1px solid
    ${themeGet(deviation ? 'layout.deviation' : 'layout.success')};
  background: ${themeGet(
    deviation ? 'layout.deviationLight' : 'layout.successLight',
  )};
  color: ${themeGet(deviation ? 'layout.text' : 'layout.success')};
`

export const Base = styled(Flex)`
  align-items: center;
  width: fit-content;
  padding: 0 ${({ theme }) => theme.space[1]}px;
  border-radius: ${({ theme }) => theme.space[1]}px;
`

export const Container = styled(Base)`
  ${deviationCss}
`

import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import {
  CustomCvFieldValuesByCursorQuery,
  UpdateCustomCvFieldValuesMutation,
} from './GraphQL'

export function useUpdateCustomCvFieldValues() {
  const [updateCustomCvFieldValues, { loading: updating }] = useApolloMutation<
    MutationData<'updateCustomCvFieldValues'>,
    Gateway.MutationUpdateCustomCvFieldValuesArgs
  >(UpdateCustomCvFieldValuesMutation, {
    mutationType: 'update',
    refetchQueries: compact([
      getOperationName(CustomCvFieldValuesByCursorQuery),
    ]),
  })

  const updateMany = async (input: Gateway.UpdateCustomCvFieldValuesInput) => {
    const result = await updateCustomCvFieldValues({
      variables: { input },
    })

    return graphQLResultHasError(result)
  }

  return { updateMany, updating }
}

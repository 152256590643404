import { StoreonModule } from 'storeon'

import {
  CONFIRMATION_MODAL_KEY,
  ConfirmationModalEvents,
  ConfirmationModalState,
} from './types'

const INITIAL_STATE: ConfirmationModalState['confirmationModal'] = {
  isOpen: false,
  title: '',
  content: '',
  handleConfirm: () => undefined,
}

export const confirmationModalModule: StoreonModule<
  ConfirmationModalState,
  ConfirmationModalEvents
> = store => {
  store.on('@init', () => ({
    [CONFIRMATION_MODAL_KEY]: INITIAL_STATE,
  }))

  store.on('confirmationModal/open', (_, variables) => ({
    [CONFIRMATION_MODAL_KEY]: {
      isOpen: true,
      ...variables,
    },
  }))

  store.on('confirmationModal/close', () => ({
    [CONFIRMATION_MODAL_KEY]: INITIAL_STATE,
  }))
}

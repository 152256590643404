import { useContext } from 'react'

import { AppContext } from 'services/AppContext'

/**
 * Application context hook.
 *
 * @example
 * List of deprecated values:
 * // isNahdi, is... etc. use `company.identity[identity]` instead
 * const isDeluxe = company.identity.Deluxe
 */
export function useAppContext() {
  return useContext(AppContext)
}

export default useAppContext

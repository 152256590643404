import { MdPhoto } from 'react-icons/md'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const DragZone = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: ${themeGet('layout.grayBg')};
  border: 1px dashed ${themeGet('colors.CHARCOAL_60')};
  padding: ${themeGet('space.5')}px 0px;
  box-sizing: border-box;
  border-radius: 5px;

  > * {
    color: ${themeGet('colors.CHARCOAL_60')};
  }
`
export const PhotoIcon = styled(MdPhoto)`
  font-size: 18px;
  color: ${themeGet('colors.CHARCOAL_60')};
  margin-right: ${themeGet('space.4')}px;
`

import { StoreonModule } from 'storeon'

import isEqual from 'lodash/isEqual'

import { HistoricDemandWidgetModes } from 'pages/Dashboard/components/HistoricDemand/types'

import Utils from 'services/Utils'

export const HISTORIC_DEMAND_STATE_KEY = 'historicDemand'

type DateRangeType = {
  start: string
  end: string
}

export interface HistoricDemandSubState {
  disabled: boolean
  dateRange: DateRangeType
  mode: HistoricDemandWidgetModes
}

export interface HistoricDemandState {
  [HISTORIC_DEMAND_STATE_KEY]: HistoricDemandSubState
}

export interface HistoricDemandEvents {
  'historicDemand/set': Partial<HistoricDemandSubState>
}

const INITIAL_STATE = {
  disabled: false,
  dateRange: Utils.DateTime.getCurrentWeek(),
  mode: HistoricDemandWidgetModes.Table,
}

export const historicDemandModule: StoreonModule<
  HistoricDemandState,
  HistoricDemandEvents
> = store => {
  store.on('@init', () => ({
    [HISTORIC_DEMAND_STATE_KEY]: INITIAL_STATE,
  }))

  store.on('historicDemand/set', (state, variables) => {
    const currentState = state[HISTORIC_DEMAND_STATE_KEY]
    const nextState = { ...currentState, ...variables }

    if (isEqual(nextState, currentState)) return null
    return { [HISTORIC_DEMAND_STATE_KEY]: nextState }
  })
}

import { useEffect, useState } from 'react'

import { DateTime } from 'luxon'

import { SECONDS_IN_MINUTE } from 'constants/time'

import Utils from 'services/Utils'

type Props = {
  deadline?: string
  onTimeExpired?: () => void
}

export function useRemainingSeconds({ deadline, onTimeExpired }: Props) {
  const [remainingSeconds, setRemainingSeconds] = useState(() => {
    if (!deadline) return 0

    const diffSeconds = Utils.DateTime.mapToDiffSeconds(deadline)
    if (diffSeconds <= 0) return 0

    return diffSeconds
  })

  useEffect(() => {
    if (remainingSeconds <= 0) return onTimeExpired?.()

    const now = DateTime.now().toUTC()

    const millisecondsUntilNextMinute =
      (SECONDS_IN_MINUTE - now.second) * 1000 - now.millisecond

    const timerId = setInterval(() => {
      setRemainingSeconds(prevTime => prevTime - SECONDS_IN_MINUTE)
    }, millisecondsUntilNextMinute)

    return () => clearInterval(timerId)
  }, [remainingSeconds, deadline, onTimeExpired])

  return remainingSeconds
}

import { useApolloLazyQuery } from 'API/services/Apollo'

import {
  ClassifierSortingField,
  SortingDirection,
} from 'constants/gatewayGraphQL'
import { PAGE_SIZE } from 'constants/pagination'

import {
  ClassifiersByCursorAPIItem,
  ClassifiersByCursorQueryData,
} from './GraphQL/Queries/types'
import { ClassifiersByCursorQuery } from './GraphQL'

export function usePickerClassifier() {
  const [
    innerLoad,
    { data, loading, refetch: innerRefetch },
  ] = useApolloLazyQuery<
    ClassifiersByCursorQueryData,
    Gateway.QueryClassifiersByCursorArgs
  >(ClassifiersByCursorQuery, {
    fetchPolicy: 'network-only',
  })

  const loadClassifiers = () => {
    innerLoad({
      variables: {
        sorting: {
          field: ClassifierSortingField.Name,
          direction: SortingDirection.Asc,
        },
        paging: { limit: PAGE_SIZE['10'] },
      },
    })
  }

  const refetch = async (inputValue?: string) => {
    if (!innerRefetch) return []

    const result = await innerRefetch({
      filter: { name: inputValue ? { ilike: `%${inputValue}%` } : undefined },
    })
    return dataToNodes(result.data)
  }

  const classifiers = dataToNodes(data)

  return {
    classifiers,
    loadClassifiers,
    loading,
    refetch,
  }
}

function dataToNodes(
  data?: ClassifiersByCursorQueryData,
): ClassifiersByCursorAPIItem[] {
  return data?.classifiersByCursor?.edges.map(edge => edge.node) ?? []
}

import { FiExternalLink } from 'react-icons/fi'

import styled from 'styled-components'

export const RedirectIcon = styled(FiExternalLink)`
  font-size: 14px;
  color: ${({ theme }) => theme.layout.primary};
  cursor: pointer;
`

export const SecondaryText = styled.span`
  font-size: 0.8em;
  line-height: 0.8em;
  color: ${({ theme }) => theme.layout.darkBorder};
`

import styled from 'styled-components'

import SvgIcons from 'assets/images/__v2__/svg'

import { SvgIcon } from 'components/ui/__v2__/SvgIcon'

const Trash = styled(SvgIcon).attrs({
  glyph: SvgIcons.Trash,
})``

Trash.defaultProps = {
  size: 14,
}

export default Trash

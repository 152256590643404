import styled from 'styled-components'

import SvgIcons from 'assets/images/__v2__/svg'

import { SvgIcon } from 'components/ui/__v2__/SvgIcon'

const Cloud = styled(SvgIcon).attrs({
  glyph: SvgIcons.Cloud,
})``

Cloud.defaultProps = {
  width: 74,
  height: 59,
}

export default Cloud

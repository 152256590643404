import { TimeoffAPIItem } from 'API'
import { DateTime } from 'luxon'

import { CustomLabelDayKinds, TimeoffStates } from 'constants/ids'

import Utils from 'services/Utils'

export type CanModifyWithinThresholdProps = {
  startAt: DateTime
  timezone?: string
  thresholdSettings?: {
    enabled?: boolean
    value?: number
  }
  isAdminOrOwner: boolean
}

export type CanEditProps = Pick<
  CanModifyWithinThresholdProps,
  'thresholdSettings' | 'isAdminOrOwner'
> & {
  timeoff: TimeoffAPIItem
}

export const canModifyWithinThreshold = ({
  startAt,
  timezone = 'local',
  thresholdSettings,
  isAdminOrOwner,
}: CanModifyWithinThresholdProps) => {
  if (!thresholdSettings?.enabled || !startAt) return true

  return Utils.DateTime.datetimeWithinPastPeriod({
    startAt,
    timezone,
    daysInPast: thresholdSettings.value,
    restrictWithoutDaysInPast: !isAdminOrOwner,
  })
}

/**
 * NOTE: Timeoffs with canDelete set to false, deleted statuses and custom leave day kind to be holiday should be
 * not allowed to edit
 */
export const canEdit = ({
  timeoff,
  thresholdSettings,
  isAdminOrOwner,
}: CanEditProps) => {
  const isCantEdit =
    timeoff?.canDelete === false ||
    timeoff?.state === TimeoffStates.Deleted ||
    timeoff?.customLeaveDay?.kind === CustomLabelDayKinds.Holiday
  return (
    !isCantEdit &&
    canModifyWithinThreshold({
      startAt: DateTime.fromISO(timeoff.startAt),
      timezone: timeoff.employee.branch?.settings?.timezone,
      thresholdSettings,
      isAdminOrOwner,
    })
  )
}

import merge from 'lodash/merge'

import { createReducer } from 'helpers/redux'

import { RESET_FACE_ID } from 'store/actions/employees/employees'

const initialState = {}

const handlers = {
  [RESET_FACE_ID.SUCCESS]: (state, { payload }) => {
    const { relationId } = payload

    return merge({}, state, {
      [relationId]: {
        relationships: {
          faceIdPhoto: {
            data: null,
          },
        },
      },
    })
  },
}

export default createReducer(initialState, handlers)

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { withTheme } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import isFunction from 'lodash/isFunction'

import { useTableSearch } from 'hooks/useTableSearch'

import _ from 'i18n'

const Search = SearchComponent => {
  const SearchContainer = props => {
    const { column, data } = props
    const { selector } = column

    const [query, setQuery] = useState('')

    const getCellText = useCallback(
      row => (isFunction(selector) ? selector(row) : selector || ''),
      [selector],
    )

    const renderMarkText = useCallback(
      match => {
        const style = `
        color: ${themeGet('layout.primary')(props)};
        background-color: transparent;
      `
        return `<mark style="${style}">${match}</mark>`
      },
      [props],
    )

    const { onChange } = useTableSearch({
      ...props,
      data,
      getCellText,
      renderMarkText,
    })

    const handleQueryChange = useCallback(
      event => {
        const { value } = event.target
        setQuery(value)
        onChange(value)
      },
      [onChange],
    )

    return (
      <SearchComponent
        placeholder={_('actions.search')}
        value={query}
        onChange={handleQueryChange}
      />
    )
  }

  SearchContainer.propTypes = {
    column: PropTypes.shape({
      selector: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    }).isRequired,
    data: PropTypes.array.isRequired,
  }

  return withTheme(React.memo(SearchContainer))
}

export default Search

import { CompanySettings } from 'Types/app'
import { create, enforce, test } from 'vest'

import { MAX_MINUTES_IN_HOUR } from 'constants/dailyWorkingHours'

import { i18n } from 'i18n'

import { DEFAULT_WEEK_START_DAY } from './constants'
import { GlobalSettingsFormState } from './types'

export function getInitialValues(
  companySettings: CompanySettings,
  hasAutoShiftNameFeature: boolean,
): GlobalSettingsFormState {
  const {
    autoShiftName,
    sendScheduleEmails,
    disableEmployeeProfileEdit,
    managersCanArchiveEmployees,
    allowFutureManualTimecardApprove,
    earlyClockIn,
    earlyClockInLimit,
    showWagesToManagers,
    disableEmployeeBreaks,
    allowDepartmentManagerMutualApprove,
    disableDepartmentManagerLeaveRequestAutoApproval,
    calendarStartDay,
    rapidClockInMode,
    timecardSettings,
    timeoffSettings,
  } = companySettings

  return {
    ...(hasAutoShiftNameFeature && {
      autoShiftName: autoShiftName ?? false,
    }),
    allowFutureManualTimecardApprove: allowFutureManualTimecardApprove ?? false,
    allowDepartmentManagerMutualApprove:
      allowDepartmentManagerMutualApprove ?? false,

    disableEmployeeProfileEdit: disableEmployeeProfileEdit ?? false,
    managersCanArchiveEmployees: managersCanArchiveEmployees ?? false,
    disableEmployeeBreaks: disableEmployeeBreaks ?? false,
    calendarStartDay: calendarStartDay ?? DEFAULT_WEEK_START_DAY,

    earlyClockIn: earlyClockIn ?? false,
    earlyClockInLimitMinutes: (earlyClockInLimit ?? 0) % MAX_MINUTES_IN_HOUR,
    earlyClockInLimitHours: Math.floor(
      (earlyClockInLimit ?? 0) / MAX_MINUTES_IN_HOUR,
    ),

    sendScheduleEmails: sendScheduleEmails ?? false,
    showWagesToManagers: showWagesToManagers ?? false,
    disableDepartmentManagerLeaveRequestAutoApproval:
      disableDepartmentManagerLeaveRequestAutoApproval ?? false,

    rapidClockInMode: {
      enabled: rapidClockInMode?.enabled ?? false,
      limit: rapidClockInMode?.limit
        ? rapidClockInMode.limit.toString()
        : undefined,
    },

    timeoffSettingsThreshold: {
      enabled: timeoffSettings?.updateThreshold?.enabled ?? false,
      value: timeoffSettings?.updateThreshold?.value
        ? timeoffSettings.updateThreshold.value.toString()
        : undefined,
    },

    timecardSettingsThreshold: {
      enabled: timecardSettings?.updateThreshold?.enabled ?? false,
      value: timecardSettings?.updateThreshold?.value
        ? timecardSettings.updateThreshold.value.toString()
        : undefined,
    },
  }
}

export function useSchema(hasAutoShiftNameFeature: boolean) {
  return create((data: GlobalSettingsFormState) => {
    test('autoShiftName', i18n('validation.required'), () => {
      const { autoShiftName } = data
      if (!hasAutoShiftNameFeature) enforce(autoShiftName).isUndefined()
      else enforce(autoShiftName).isBoolean()
    })

    test(
      'allowFutureManualTimecardApprove',
      i18n('validation.required'),
      () => {
        const { allowFutureManualTimecardApprove } = data
        enforce(allowFutureManualTimecardApprove).isBoolean()
      },
    )

    test(
      'allowDepartmentManagerMutualApprove',
      i18n('validation.required'),
      () => {
        const { allowDepartmentManagerMutualApprove } = data
        enforce(allowDepartmentManagerMutualApprove).isBoolean()
      },
    )

    test('disableEmployeeProfileEdit', i18n('validation.required'), () => {
      const { disableEmployeeProfileEdit } = data
      enforce(disableEmployeeProfileEdit).isBoolean()
    })

    test('disableEmployeeBreaks', i18n('validation.required'), () => {
      const { disableEmployeeBreaks } = data
      enforce(disableEmployeeBreaks).isBoolean()
    })

    test('calendarStartDay', i18n('validation.required'), () => {
      const { calendarStartDay } = data
      enforce(calendarStartDay).isNumber()
    })

    test('earlyClockIn', i18n('validation.required'), () => {
      const { earlyClockIn } = data
      enforce(earlyClockIn).isBoolean()
    })

    test('earlyClockInLimitHours', i18n('validation.required'), () => {
      const { earlyClockInLimitHours } = data
      enforce(earlyClockInLimitHours).isNumber().lessThanOrEquals(24)
    })

    test('earlyClockInLimitMinutes', i18n('validation.required'), () => {
      const { earlyClockInLimitMinutes, earlyClockInLimitHours } = data
      if (earlyClockInLimitHours === 24)
        enforce(earlyClockInLimitMinutes).isNumber().equals(0)
      else enforce(earlyClockInLimitMinutes).isNumber().lessThanOrEquals(59)
    })

    test('sendScheduleEmail', i18n('validation.required'), () => {
      const { sendScheduleEmails } = data
      enforce(sendScheduleEmails).isBoolean()
    })

    test('showWagesToManagers', i18n('validation.required'), () => {
      const { showWagesToManagers } = data
      enforce(showWagesToManagers).isBoolean()
    })

    test('rapidMode', i18n('validation.required'), () => {
      const { rapidClockInMode } = data
      if (rapidClockInMode.enabled === true)
        enforce(Number(rapidClockInMode.limit)).isNumber().greaterThan(0)
    })

    test('timeoffSettingsThreshold', i18n('validation.required'), () => {
      const { timeoffSettingsThreshold } = data
      if (timeoffSettingsThreshold.enabled === true)
        enforce(Number(timeoffSettingsThreshold.value))
          .isNumber()
          .greaterThan(0)
    })

    test('timecardSettingsThreshold', i18n('validation.required'), () => {
      const { timecardSettingsThreshold } = data
      if (timecardSettingsThreshold.enabled === true)
        enforce(Number(timecardSettingsThreshold.value))
          .isNumber()
          .greaterThan(0)
    })
  })
}

import RRule from 'rrule'

export const holidayPeriodKeys = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
  NONE: 'none',
}

export const FREQUENCIES = {
  none: 'NONE',
  daily: 'DAILY',
  weekly: 'WEEKLY',
  monthly: 'MONTHLY',
  yearly: 'YEARLY',
}

export const END_CONDITIONS = {
  never: 'never',
  date: 'date',
  count: 'count',
}

export const ORDER_OPTIONS = [
  { name: 'first', bysetpos: 1 },
  { name: 'second', bysetpos: 2 },
  { name: 'third', bysetpos: 3 },
  { name: 'fourth', bysetpos: 4 },
  { name: 'last', bysetpos: -1 },
]

export const WEEKDAYS_OPTIONS = [
  { name: 'monday', byweekday: [RRule.MO] },
  { name: 'tuesday', byweekday: [RRule.TU] },
  { name: 'wednesday', byweekday: [RRule.WE] },
  { name: 'thursday', byweekday: [RRule.TH] },
  { name: 'friday', byweekday: [RRule.FR] },
  { name: 'saturday', byweekday: [RRule.SA] },
  { name: 'sunday', byweekday: [RRule.SU] },
  {
    name: 'day',
    byweekday: [
      RRule.MO,
      RRule.TU,
      RRule.WE,
      RRule.TH,
      RRule.FR,
      RRule.SA,
      RRule.SU,
    ],
  },
  {
    name: 'weekday',
    byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
  },
  { name: 'weekend day', byweekday: [RRule.SA, RRule.SU] },
]

export const MONTH_OPTIONS = [
  { name: 'jan', bymonth: 1 },
  { name: 'feb', bymonth: 2 },
  { name: 'mar', bymonth: 3 },
  { name: 'apr', bymonth: 4 },
  { name: 'may', bymonth: 5 },
  { name: 'jun', bymonth: 6 },
  { name: 'jul', bymonth: 7 },
  { name: 'aug', bymonth: 8 },
  { name: 'sep', bymonth: 9 },
  { name: 'oct', bymonth: 10 },
  { name: 'nov', bymonth: 11 },
  { name: 'dec', bymonth: 12 },
]

export const FIELDS = {
  startOn: 'dtstart',
  frequency: 'freq',
  count: 'count',
  interval: 'interval',
  endOn: 'until',
  endCondition: 'endCondition',
  on: 'on',
  monthDay: 'monthDay',
  dayOfWeek: 'dayOfWeek',
  weekdays: 'weekdays',
  month: 'month',
}

export const everyPeriodKeys = {
  DAILY: [
    'single',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
    '10th',
    '11th',
    '12th',
    '13th',
    '14th',
    '15th',
    '16th',
    '17th',
    '18th',
    '19th',
    '20th',
    '21th',
    '22th',
    '23th',
    '24th',
    '25th',
    '26th',
    '27th',
    '28th',
    '29th',
    '30th',
    '31st',
  ],
  WEEKLY: ['single', '2nd', '3rd', '4th'],
  MONTHLY: [
    'single',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
    '10th',
    '11th',
    '12th',
  ],
  YEARLY: [
    'single',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
    '10th',
  ],
}

export const MONTH_DAYS = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
]

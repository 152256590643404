import { useCallback } from 'react'

import { useApolloMutation } from 'API/services/Apollo'

import { TimeTypeFormState } from 'pages/EmployerApp/GlobalSettings/TimeTypes/types'

import { CreateTimeTypeMutation } from './GraphQL'
import { mapFormStateToServerData } from './helpers'

export function useCreateTimeType() {
  const [innerCreate, { loading: creating, error }] = useApolloMutation<
    MutationData<'createTimetype'>,
    Gateway.MutationCreateTimetypeArgs
  >(CreateTimeTypeMutation, {
    update: cache => {
      cache.evict({ fieldName: 'timetypesByOffset' })
    },
  })

  const createTimeType = useCallback(
    async (values: TimeTypeFormState) => {
      const variables = mapFormStateToServerData(values)

      const result = await innerCreate({
        variables: {
          input: variables,
        },
      })

      return Boolean(result.errors)
    },
    [innerCreate],
  )

  return { createTimeType, creating, createError: error }
}

import { createFields, createReducerHandlers } from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { CREATE_TIME_OFF, LOAD_TIME_OFFS } from 'store/actions/common/timeoff'
import { RESET_SUCCESSFUL } from 'store/actions/employeeApp/schedules'
import { MOVE_TIMEOFFS } from 'store/actions/employees/moveTimeOff'

export const TIMEOFFS_TYPE = 'timeoffs'
export const TIMEOFFS_FIELD = 'timeoff'

const initialState = Immutable({
  ...createFields(TIMEOFFS_TYPE, TIMEOFFS_FIELD),
  isRequestingTimeoff: false,
  isRequestSuccessful: false,
})

const handlers = {
  ...createReducerHandlers(TIMEOFFS_TYPE, LOAD_TIME_OFFS, {
    withReplace: true,
    mapToKey: TIMEOFFS_FIELD,
  }),
  [CREATE_TIME_OFF.REQUEST]: state =>
    state.merge({
      isRequestingTimeoff: true,
      isRequestSuccessful: false,
    }),
  [CREATE_TIME_OFF.SUCCESS]: state =>
    state.merge({
      isRequestingTimeoff: false,
      isRequestSuccessful: true,
    }),
  [CREATE_TIME_OFF.FAILURE]: state =>
    state.merge({ isRequestingTimeoff: false }),
  [RESET_SUCCESSFUL]: state =>
    state.isRequestSuccessful
      ? state.merge({ isRequestSuccessful: false })
      : state,

  [MOVE_TIMEOFFS.REQUEST]: state =>
    state.merge({
      isRequestingTimeoff: true,
      isRequestSuccessful: false,
    }),
  [MOVE_TIMEOFFS.SUCCESS]: state =>
    state.merge({
      isRequestingTimeoff: false,
      isRequestSuccessful: true,
    }),
  [MOVE_TIMEOFFS.FAILURE]: state => state.merge({ isRequestingTimeoff: false }),
  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

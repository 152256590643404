import { useAvailabilityRequestsByOffset as getByOffset } from './useAvailabilityRequestsByOffset'
import { useAvailabilityRequestsCount as count } from './useAvailabilityRequestsCount'
import { useCreateAvailabilityRequest as create } from './useCreateAvailabilityRequest'
import { useMutateAvailabilityRequest as mutate } from './useMutateAvailabilityRequest'

export * from './types'

export const AvailabilityRequestsAPI = {
  getByOffset,
  mutate,
  create,
  count,
}

import { useCallback, useState } from 'react'

import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus'
import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { compact } from 'lodash'

import { IntegrationsByCursorQuery } from './GraphQL'

export function useIntegrationsByCursor(
  {
    page,
    sorting,
    enabled,
  }: {
    page: CursorPage
    sorting?: Gateway.IntegrationsByCursorSorting
    enabled?: boolean
  },
  queryOptions: Partial<Omit<QueryHookOptions, 'skip' | 'variables'>>,
) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, refetch, fetchMore, networkStatus } = useApolloQuery<
    QueryData<'integrationsByCursor'>,
    Gateway.QueryIntegrationsByCursorArgs
  >(IntegrationsByCursorQuery, {
    ...queryOptions,

    skip: !enabled,
    variables: {
      sorting,
      paging: cursorPageToGraphqlPaging(page),
    },

    onCompleted(data) {
      const newPageMeta = data.integrationsByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const integrationsFetchMore = useCallback(async () => {
    await fetchMore({
      variables: {
        paging: {
          limit: page.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })
  }, [currentPageMeta.endCursor, fetchMore, page.size])

  const isLoading = isNetworkRequestInFlight(networkStatus)
  const integrations = compact(
    data?.integrationsByCursor?.edges?.map(({ node }) => node && node) ?? [],
  )

  return {
    integrations,
    integrationsLoading: isLoading,
    integrationsRefetch: refetch,
    integrationsFetchMore,
    integrationsPageMeta: currentPageMeta,
  }
}

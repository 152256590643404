import { getEntities } from 'rapidux'

import {
  FORM_TEMPLATE_FIELD,
  FORM_TEMPLATE_TYPE,
} from 'store/reducers/company/formTemplates'
import { getData } from 'store/selectors/data'

export const getState = state => state.company.formTemplates

export const getFormTemplates = getEntities(getState, getData, {
  type: FORM_TEMPLATE_TYPE,
  field: FORM_TEMPLATE_FIELD,
})

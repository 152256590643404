import { useArchiveEmploymentType } from './useArchiveEmploymentType'
import { useCreateEmploymentType } from './useCreateEmploymentType'
import { useDeleteEmploymentType } from './useDeleteEmploymentType'
import { useEmploymentTypesByCursor } from './useEmploymentTypesByCursor'
import { usePickerEmploymentTypes } from './usePickerEmploymentTypes'
import { useUnarchiveEmploymentType } from './useUnarchiveEmploymentType'
import { useUpdateEmploymentType } from './useUpdateEmploymentType'

export * from './GraphQL'

export const EmploymentTypeAPI = {
  byCursor: useEmploymentTypesByCursor,
  create: useCreateEmploymentType,
  update: useUpdateEmploymentType,
  archive: useArchiveEmploymentType,
  unarchive: useUnarchiveEmploymentType,
  delete: useDeleteEmploymentType,
  picker: usePickerEmploymentTypes,
}

import DatePicker, { DayPickerProps } from 'react-day-picker'

import { FiCalendar } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { inputSelectFocusedCss } from 'components/styles'
import {
  either,
  propGet,
  radius,
  when,
} from 'components/ui/__v3__/__utils__/styled-system'

import { DayPickerExternalProps } from './types'

import { Button } from '../Button'
import { Box, Flex } from '../Grid'

export const CalendarIcon = styled(FiCalendar)`
  stroke: ${either(
    'disabled',
    themeGet('layout.disabled'),
    themeGet('layout.primary'),
  )};

  cursor: ${either('disabled', 'not-allowed', 'default')};
`

const styles = {
  calendarIcon: css`
    position: absolute;
    left: 4px;
    bottom: 3px;
    transform: translateY(-50%);

    ${when(
      'disabled',
      css`
        stroke: ${themeGet('layout.lightText')};
      `,
    )};
  `,
}

type ContainerProps = Pick<DayPickerExternalProps, 'autoWidth'> &
  LayoutProps &
  SpaceProps &
  TypographyProps

export const Container = styled.div<ContainerProps>`
  --font-size: ${propGet('fontSize', 14)}px;
  --button-font-size: ${propGet('button.fontSize', 14)}px;
  --line-height: ${propGet('lineHeight', 1.15)};
  --width: auto;

  ${when(
    '!autoWidth',
    css`
      --width: ${propGet('width', '160px')};
      width: var(--width);
    `,
  )};

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: ${themeGet('layout.text')};

  ${layout}
  ${space};
  ${typography};
`

export const Toggle = styled.div<
  Pick<DayPickerExternalProps, 'disabled' | 'errored'>
>`
  min-width: var(--width);

  background-color: ${either(
    'disabled',
    themeGet('input.bg.disabled'),
    themeGet('date.bg'),
  )};

  &:hover {
    border-color: ${themeGet('colors.MANATEE')};

    ${when(
      'disabled',
      css`
        border-color: ${themeGet('layout.disabled')} !important;
      `,
    )};
  }

  &:focus {
    ${inputSelectFocusedCss}

    ${when(
      'disabled',
      css`
        box-shadow: none;
        border-color: ${themeGet('layout.disabled')} !important;
      `,
    )};
  }

  border-radius: ${themeGet('radii.normal')}px;
  font-size: var(--font-size);
  line-height: var(--line-height);
  cursor: ${either('disabled', 'not-allowed', 'pointer')};
  position: relative;

  border: thin solid
    ${either(
      'errored',
      themeGet('layout.primary'),
      either('disabled', themeGet('layout.disabled'), themeGet('date.border')),
    )};

  padding: 3px 12px 3px 22px;

  color: ${either(
    'disabled',
    themeGet('layout.lightText'),
    themeGet('layout.text'),
  )};

  :focus {
    outline: none;
  }

  ${CalendarIcon} {
    ${styles.calendarIcon};
  }
`

export const Placeholder = styled.div`
  display: flex;
  align-items: center;
  color: ${themeGet('date.placeholder.color')};
  line-height: var(--line-height);
  user-select: none;
`

export const Picker = styled(DatePicker)<
  DayPickerProps & Pick<DayPickerExternalProps, 'dayOnly'>
>`
  width: 100%;

  * {
    outline: none;
  }

  .DayPicker {
    width: 100%;
    font-size: ${propGet('fontSize', 14)}px;

    &-NavButton {
      &--prev {
        left: 8px;
      }

      &--next {
        right: 8px;
      }
    }

    &-Month {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      outline: none;

      .DayPicker-Caption {
        text-align: center;
        font-size: ${propGet('caption.fontSize', 16)}px;
        color: ${themeGet('date.text')};
        margin-bottom: 36px;
      }

      .DayPicker-Weekdays {
        margin-bottom: 8px;
      }
    }

    &-Weekday {
      font-size: ${propGet('fontSize', 14)}px;
      font-weight: 500;
      color: ${themeGet('date.weekday.color')};
    }

    &-Day {
      border-radius: 0 !important;
      width: 44px;
      height: 40px;

      &--today {
        color: ${themeGet('date.today.color')};
      }

      &--selected {
        &:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
          background-color: ${themeGet('date.day.selected.bg')} !important;
          color: ${themeGet('date.day.selected.color')};
        }

        &.DayPicker-Day--outside {
          background-color: ${({ theme }) =>
            theme.date.day.disabled} !important;
        }
      }

      &--start:hover {
        border-bottom-left-radius: ${radius('normal')} !important;
        border-top-left-radius: ${radius('normal')} !important;
        ${when(
          'dayOnly',
          css`
            border-radius: ${radius('normal')} !important;
          `,
        )};

        color: ${themeGet('date.day.color')} !important;
        background-color: ${themeGet('date.day.bg')} !important;

        &.DayPicker-Day--outside {
          background-color: ${({ theme }) =>
            theme.date.day.disabled} !important;
        }
      }

      &--start.DayPicker-Day--selected {
        border-bottom-left-radius: ${radius('normal')} !important;
        border-top-left-radius: ${radius('normal')} !important;
        ${when(
          'dayOnly',
          css`
            border-radius: ${radius('normal')} !important;
          `,
        )};
        background-color: ${themeGet('date.day.bg')};
        color: ${themeGet('date.day.color')} !important;

        :hover {
          color: ${themeGet('date.day.selected.color')};
          background-color: ${themeGet('date.day.selected.bg')};
        }
      }

      &--end.DayPicker-Day--selected {
        border-bottom-right-radius: ${radius('normal')} !important;
        border-top-right-radius: ${radius('normal')} !important;
        background-color: ${themeGet('date.day.bg')};
        color: ${themeGet('date.day.color')} !important;

        :hover {
          color: ${themeGet('date.day.color')};
          background-color: ${themeGet('date.day.bg')};
        }
      }
    }
  }
`

export const PickerButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const PickerButton = styled(Button)`
  width: 134px;
  height: 32px;
  font-size: var(--button-font-size);
  border-radius: ${themeGet('radii.normal')}px;
`

export const PickerInputWrapper = styled(Flex)<
  Pick<DayPickerExternalProps, 'disabled'>
>`
  position: relative;

  ${CalendarIcon} {
    z-index: 1;

    ${styles.calendarIcon};
  }
`

export const PickerInputBoxWrapper = styled(Box)<
  Pick<DayPickerExternalProps, 'disabled'>
>`
  min-width: var(--width);

  .DayPickerInput {
    min-width: 100%;
    position: relative;
    z-index: 0;
  }
`

export const ClearButton = styled(Box)`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
`

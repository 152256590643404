import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { CREATE_ABSENCE } from 'store/actions/employeeApp/cantMake'
import { RESET_SUCCESSFUL } from 'store/actions/employeeApp/schedules'

const initialState = Immutable({
  isRequestingAbsence: false,
  isRequestSuccessful: false,
})

const handlers = {
  [CREATE_ABSENCE.REQUEST]: state =>
    state.merge({
      isRequestingAbsence: true,
    }),
  [CREATE_ABSENCE.SUCCESS]: state =>
    state.merge({ isRequestingAbsence: false, isRequestSuccessful: true }),
  [CREATE_ABSENCE.FAILURE]: state =>
    state.merge({ isRequestingAbsence: false }),
  [RESET_SUCCESSFUL]: state =>
    state.isRequestSuccessful
      ? state.merge({ isRequestSuccessful: false })
      : state,
  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import coreApiCall from 'services/API/coreApi'
import { Utils } from 'services/Utils'

interface CompanySettingsFilter {
  locationId: string
}

interface CompanySettingsQueryArgs {
  companyId: string
  filter: CompanySettingsFilter
}

// TODO: find better name
export function companySettingsQuery({
  companyId,
  filter,
}: CompanySettingsQueryArgs) {
  const query: JsonApi.CompanySettingsArgs = {
    page: { size: 999 },
    filter: {
      branch_id: Utils.JsonApi.eqFilter(filter.locationId),
    },
  }

  return coreApiCall({
    endpoint: `/companies/${companyId}/settings`,
    query,
  })
}

import {
  AutoSchedulingTaskResult,
  AutoSchedulingTaskState,
} from 'constants/gatewayGraphQL'

import { AutoSchedulingTaskDisplayState } from './types'

export function fillDisplayState<
  T extends Pick<Gateway.AutoSchedulingTask, 'result' | 'state'>
>(item: T) {
  return {
    ...item,
    displayState: getTaskDisplayState(item),
  }
}

function getTaskDisplayState({
  state,
  result,
}: Pick<Gateway.AutoSchedulingTask, 'result' | 'state'>) {
  // prettier-ignore
  if (state === AutoSchedulingTaskState.Pending) return AutoSchedulingTaskDisplayState.Pending
  // prettier-ignore
  if (state === AutoSchedulingTaskState.InProgress) return AutoSchedulingTaskDisplayState.InProgress

  if (state === AutoSchedulingTaskState.Finished) {
    // prettier-ignore
    if (result === AutoSchedulingTaskResult.Success) return AutoSchedulingTaskDisplayState.Success
    // prettier-ignore
    if (result === AutoSchedulingTaskResult.Failure) return AutoSchedulingTaskDisplayState.Failed
    // prettier-ignore
    if (result === AutoSchedulingTaskResult.Unspecified) return AutoSchedulingTaskDisplayState.Failed
  }

  // prettier-ignore
  if (state === AutoSchedulingTaskState.Outdated) return AutoSchedulingTaskDisplayState.Outdated
  // prettier-ignore
  if (state === AutoSchedulingTaskState.Applied) return AutoSchedulingTaskDisplayState.Applied

  return AutoSchedulingTaskDisplayState.Pending
}

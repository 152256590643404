export const CONFIRMATION_MODAL_KEY = 'confirmationModal'
export const OPEN_CONFIRMATION_MODAL = 'confirmationModal/open'
export const CLOSE_CONFIRMATION_MODAL = 'confirmationModal/close'

export type ModalCallback = (confirmed: boolean) => void

export interface ConfirmationModalPayload {
  title: string
  content: React.ReactNode
  cancelText?: string
  confirmText?: string
  handleConfirm: ModalCallback
}

export interface ConfirmationModalState {
  [CONFIRMATION_MODAL_KEY]: {
    isOpen: boolean
  } & ConfirmationModalPayload
}

export interface ConfirmationModalEvents {
  [OPEN_CONFIRMATION_MODAL]: ConfirmationModalPayload
  [CLOSE_CONFIRMATION_MODAL]: never
}

import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import {
  CreateCustomCvField as CreateCustomCvFieldMutation,
  CustomCvFieldsByCursorQuery,
} from './GraphQL'

export function useCreateCustomCvField() {
  const [createCustomCvField, { loading: creating }] = useApolloMutation<
    MutationData<'createCustomCvField'>,
    Gateway.MutationCreateCustomCvFieldArgs
  >(CreateCustomCvFieldMutation, {
    translationKey: 'settings.customFieldsPage.toasts.create',
    refetchQueries: compact([getOperationName(CustomCvFieldsByCursorQuery)]),
  })

  const create = async (input: Gateway.CreateCustomCvFieldInput) => {
    const result = await createCustomCvField({ variables: { input } })
    return graphQLResultHasError(result)
  }

  return { create, creating }
}

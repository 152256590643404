import { createRelationDeleteHandler } from 'redux-json-api-handlers'

import differenceBy from 'lodash/differenceBy'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'

import { createReducer } from 'helpers/redux'

import { DELETE_JOBS_TAGS } from 'store/actions/company/jobs'
import { DELETE_EMPLOYEES_JOB_TAGS } from 'store/actions/employees/employees'

const initialState = {}

const handlers = {
  [DELETE_EMPLOYEES_JOB_TAGS.SUCCESS]: createRelationDeleteHandler('jobsTags'),
  [DELETE_JOBS_TAGS.SUCCESS]: (state, { payload }) => {
    const { deletedId: tagId, relationId: jobId } = payload

    const nextState = mapValues(state, jobsEmployee => {
      const jobsEmployeeJobId = get(jobsEmployee, [
        'relationships',
        'job',
        'data',
        'id',
      ])

      if (jobsEmployeeJobId !== jobId) {
        return jobsEmployee
      }

      const jobsTags = get(
        jobsEmployee,
        ['relationships', 'jobsTags', 'data'],
        [],
      )

      return {
        ...jobsEmployee,
        relationships: {
          ...jobsEmployee.relationships,
          jobsTags: {
            data: differenceBy(jobsTags, [{ id: tagId }], 'id'),
          },
        },
      }
    })

    return nextState
  },
}

export default createReducer(initialState, handlers)

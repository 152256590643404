import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import coreApiCall from 'services/API/coreApi'
import { showCoreAPIErrorToast, showToast } from 'services/Toasts'

import { TimeCardQueryKeys } from './constants'

function approveTimeCard({ timeCardIds }: { timeCardIds: string[] }) {
  return coreApiCall({
    method: 'POST',
    endpoint: '/time_entries/authorise',
    query: {
      data: timeCardIds.map(id => ({ id, type: 'timeEntries' })),
    },
  })
}

type Translations = typeof TRANSLATIONS.timeline.toasts

/** @deprecated */
export function useApproveTimeCard() {
  const t = useI18n<Translations>('timeline.toasts')
  const queryClient = useQueryClient()

  const { mutateAsync, isLoading } = useMutation(
    TimeCardQueryKeys.all(),
    approveTimeCard,
    {
      onSuccess: (response: JsonApi.Response, { timeCardIds }) => {
        const isSingle = timeCardIds.length === 1
        if (!response?.ok) {
          if (isSingle) {
            showCoreAPIErrorToast({
              response,
              title: t('singleApproval.error.title'),
            })
          } else {
            showCoreAPIErrorToast({
              response,
              title: t('selectedApproval.error.title'),
            })
          }
        } else {
          if (isSingle) {
            showToast({
              type: 'success',
              title: t('singleApproval.success.title'),
              content: t('singleApproval.success.message'),
            })
          } else {
            showToast({
              type: 'success',
              title: t('selectedApproval.success.title'),
              content: t('selectedApproval.success.message'),
            })
          }

          queryClient.invalidateQueries(TimeCardQueryKeys.all())
        }
      },
    },
  )

  return {
    approveTimeCard: mutateAsync,
    approvingTimeCard: isLoading,
  }
}

import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'

import { CustomCvFieldValuesCountQuery } from './GraphQL'

type QueryProps = Pick<QueryHookOptions, 'skip' | 'pollInterval'> | undefined

export function useCustomCvFieldValuesCount(
  filter: Gateway.QueryCustomCvFieldValuesCountArgs['filter'],
  queryProps: QueryProps,
) {
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'customCvFieldValuesCount'>,
    Gateway.QueryCustomCvFieldValuesCountArgs
  >(CustomCvFieldValuesCountQuery, {
    variables: {
      filter,
    },
    skip: queryProps?.skip,
    pollInterval: queryProps?.pollInterval,
    fetchPolicy: 'network-only',
  })

  const customCvFieldValuesCount = data?.customCvFieldValuesCount

  return {
    customCvFieldValuesCount,
    loading,
    refetch,
  }
}

import { useArchiveShifts } from './useArchiveShifts'
import { useCopyShiftConflicts } from './useCopyShiftConflicts'
import { useCopyShifts } from './useCopyShifts'
import { useCreateShiftsWithAssignments } from './useCreateShiftsWithAssignments'
import { useMoveSchedulesFromShift } from './useMoveSchedulesFromShift'
import { useShiftById } from './useShiftById'
import { useShiftsByCursor } from './useShiftsByCursor'
import { useShiftsByCursorWithShiftJobsWithSchedules } from './useShiftsByCursorWithShiftJobsWithSchedules'
import { useUpdateShift } from './useUpdateShift'
import { useUpdateShifts } from './useUpdateShifts'

export * from './types'

export const ShiftAPI = {
  byCursor: useShiftsByCursor,
  byCursorWithShiftJobsWithSchedules: useShiftsByCursorWithShiftJobsWithSchedules,
  byId: useShiftById,
  archiveMany: useArchiveShifts,
  update: useUpdateShift,
  updateMany: useUpdateShifts,
  createMany: useCreateShiftsWithAssignments,
  shiftConflicts: useCopyShiftConflicts,
  copyShifts: useCopyShifts,
  moveSchedulesFromShift: useMoveSchedulesFromShift,
}

import { AvailabilityRequestFilter } from './types'

export const AVAILABILITY_REQUEST_JSON_API_TYPE = 'employeeAvailabilityRequests'

export const AvailabilityRequestQueryKey = {
  all: () => [AVAILABILITY_REQUEST_JSON_API_TYPE],

  byOffset: (
    page: JsonApi.OffsetPagingInput,
    filter: AvailabilityRequestFilter,
  ) => [AVAILABILITY_REQUEST_JSON_API_TYPE, { page, filter }],
}

import { AreasFilter, SelectOption } from 'Types/common'

import { isArray, isBoolean } from 'lodash'

import {
  BlankCustomCvFieldPair,
  FieldValuePairType,
} from 'components/blocks/__v3__/HookForm/FormCustomCvFieldsWithValues/types'

import { customFieldValueOptionsToFilter } from 'helpers/customCvFields'
import {
  accessLevelsToFilters,
  searchToFilters,
  statusesToFilters,
} from 'helpers/staff'

import apiCall from 'services/API/coreApi'
import Utils from 'services/Utils'

export type StaffQueryFilter = {
  areas?: AreasFilter
  accessLevels?: SelectOption[]
  search?: string
  statuses?: SelectOption[]
  customCvFields?: Array<FieldValuePairType | BlankCustomCvFieldPair>
  missingFields?: boolean
  display?: JsonApi.StaffDisplayType
}

export type StaffQueryArgs = {
  companyId: string
  page: JsonApi.OffsetPagingInput | JsonApi.CursorPagingInput
  filters: StaffQueryFilter
  include?: string
  sort?: JsonApi.StaffSorting
}

export function staffQuery({
  companyId,
  filters,
  page,
  include,
}: StaffQueryArgs) {
  const query: JsonApi.GetStaffArgs = {
    page,
    display: filters.display,
    // TODO: There needs to be a big cleanup for the adapter functions below; Do not start without briefing
    filter: {
      ...(filters?.areas && Utils.JsonApi.areasFilter(filters.areas)),
      ...accessLevelsToFilters(filters.accessLevels),
      ...statusesToFilters(filters.statuses),
      ...searchToFilters(filters.search),
      ...(isArray(filters.customCvFields) &&
        customFieldValueOptionsToFilter(filters.customCvFields)),
      ...{
        missing_fields: isBoolean(filters?.missingFields)
          ? filters.missingFields
          : undefined,
      },
    },
    include,
  }

  return apiCall({
    method: 'GET',
    endpoint: `/companies/${companyId}/staff`,
    query,
  })
}

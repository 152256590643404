import ReactSelect from 'react-select'
import ReactSelectAsync from 'react-select/async'
import ReactSelectAsyncCreatable from 'react-select/async-creatable'
import ReactSelectCreatable from 'react-select/creatable'

import { useTheme } from 'styled-components'

import { compact } from 'lodash'

export function getSelectType({ async, creatable }) {
  if (async && creatable) {
    return ReactSelectAsyncCreatable
  }
  if (async) {
    return ReactSelectAsync
  }
  if (creatable) {
    return ReactSelectCreatable
  }

  return ReactSelect
}

export function getSelectTheme(selectTheme) {
  const globalTheme = useTheme()

  return {
    ...selectTheme,
    ...globalTheme,
    spacing: {
      baseUnit: 2,
      menuGutter: 4,
    },
  }
}

export function searchOption(option, rawInput) {
  const candidateLabel = option?.label?.toLocaleLowerCase()
  const input = rawInput?.toLocaleLowerCase()

  const inputIsMatch = candidateLabel?.includes(input)

  // uniq search condition for location
  if (!inputIsMatch && option?.data?.location) {
    return option?.data?.location.locationCode?.includes(input)
  }

  // uniq search condition for employee by timesheetsExportCode
  if (!inputIsMatch && option?.data?.employee) {
    return option?.data?.employee.timesheetsExportCode?.includes(input)
  }

  return inputIsMatch
}

export function searchGrouped(candidate, rawInput, options) {
  const candidateLabelMatchesInput = searchOption(candidate, rawInput)

  const input = rawInput?.toLocaleLowerCase()
  const optionGroupsWithLabelsMatchingInput = options.filter(group =>
    group.label.toLocaleLowerCase().includes(input),
  )
  const optionsPresentInMatchingGroups = compact(
    optionGroupsWithLabelsMatchingInput?.map(group => group?.options)?.flat(),
  )

  const candidatePartOfMatchingGroup = Boolean(
    optionsPresentInMatchingGroups?.find(
      innerOption => innerOption?.id === candidate?.data?.id,
    ),
  )

  // nested item search
  if (candidateLabelMatchesInput || candidatePartOfMatchingGroup) return true

  return false
}

import { createDeleteHandler, createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import mergeWith from 'lodash/mergeWith'
import omit from 'lodash/omit'
import reject from 'lodash/reject'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import { createReducer, getFirstEntity, mergeCustomizer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  CHAT_MUTED,
  CHAT_UNMUTED,
  CLEAR_HISTORY,
  CREATE_CHAT,
  DELETE_CHAT,
  INIT,
  LOAD_CHAT_MESSAGES,
  LOAD_MORE_CHATS,
  LOAD_MORE_MESSAGES,
  LOAD_MUTED_CHATS,
  LOAD_UNREAD_COUNTS,
  LOAD_USERS_CHATS,
  MARK_CHAT_LOADED,
  MARK_CHAT_READED,
  RECEIVED_CREATED_CHAT,
  RECEIVED_DELETED_MESSAGE,
  RECEIVED_MESSAGE,
  REMOVE_LOCAL_FILE,
  TOGGLE_CHAT,
  UPDATE_UNREAD_COUNTS,
  UPLOAD_FILE,
} from 'store/actions/groupChat'

const initialState = Immutable({
  activeChatId: null,
  chats: [],
  loadedChatIds: [],
  mutedChatIds: [],
  files: {},
  unreadCounts: {},
  isChatsLoaded: false,
  isChatsLoading: false,
  isLoadingMoreChats: false,
  isLoadingMoreMessages: false,
  messages: [],
  isMessagesLoading: false,
})

const handlers = {
  [INIT]: state =>
    state.merge({
      isChatsLoading: true,
      isChatsLoaded: false,
    }),

  [TOGGLE_CHAT]: (state, { payload }) => {
    const { chatId } = payload
    const activeChatId = state.activeChatId === chatId ? '' : chatId
    const clearMessages = state.activeChatId !== chatId ? { messages: [] } : {}
    return state.merge({ activeChatId, ...clearMessages })
  },

  [LOAD_UNREAD_COUNTS.SUCCESS]: (state, { payload }) => {
    const {
      data: { messages },
    } = payload
    const unreadCounts = mergeWith(
      {},
      state.unreadCounts,
      messages,
      mergeCustomizer,
    )
    return state.merge({ unreadCounts })
  },

  [UPDATE_UNREAD_COUNTS]: (state, { payload }) => {
    const { unreadCount } = payload
    const unreadCounts = mergeWith(
      {},
      state.unreadCounts,
      unreadCount,
      mergeCustomizer,
    )
    return state.merge({ unreadCounts })
  },

  [LOAD_MUTED_CHATS]: (state, { payload }) => {
    const mutedChatIds = get(payload, 'mutedChatsIds', [])
    return state.merge({ mutedChatIds })
  },

  [CHAT_MUTED]: (state, { payload }) => {
    const chatId = toNumber(get(payload, 'chatId'))
    let mutedChatIds = [...state.mutedChatIds]
    const isAlreadyMuted = indexOf(mutedChatIds, chatId) !== -1
    mutedChatIds = isAlreadyMuted ? mutedChatIds : [...mutedChatIds, chatId]
    return state.merge({ mutedChatIds })
  },

  [CHAT_UNMUTED]: (state, { payload }) => {
    let mutedChatIds = [...state.mutedChatIds]

    const chatId = toNumber(get(payload, 'chatId'))
    const unmutedIdIndex = indexOf(mutedChatIds, chatId)
    if (unmutedIdIndex !== -1) {
      mutedChatIds = [
        ...mutedChatIds.slice(0, unmutedIdIndex),
        ...mutedChatIds.slice(unmutedIdIndex + 1, mutedChatIds.length),
      ]
    }
    return state.merge({ mutedChatIds })
  },

  [LOAD_USERS_CHATS.SUCCESS]: createLoadHandler('chats', {
    addToState: {
      isChatsLoaded: true,
      isChatsLoading: false,
    },
  }),

  [LOAD_MORE_CHATS.REQUEST]: state => state.merge({ isLoadingMoreChats: true }),

  [LOAD_MORE_CHATS.SUCCESS]: createLoadHandler('chats', {
    addToState: {
      isLoadingMoreChats: false,
    },
  }),

  [LOAD_MORE_CHATS.FAILURE]: state =>
    state.merge({ isLoadingMoreChats: false }),

  [LOAD_CHAT_MESSAGES.REQUEST]: state =>
    state.merge({
      isMessagesLoading: true,
    }),

  [LOAD_CHAT_MESSAGES.SUCCESS]: createLoadHandler('messages', {
    addToState: {
      isMessagesLoading: false,
    },
  }),

  [RECEIVED_MESSAGE]: (state, { payload }) => {
    const {
      data: {
        message: { id },
        chatId,
      },
    } = payload
    const messageId = toString(id)
    const isReceivedEdited = indexOf(state.messages, messageId) !== -1
    const messages =
      isReceivedEdited || chatId !== state.activeChatId
        ? state.messages
        : [...state.messages, messageId]
    return state.merge({ messages })
  },
  [RECEIVED_CREATED_CHAT]: (state, { payload }) => {
    const {
      data: { chat },
    } = payload
    const newChatId = toString(get(chat, 'id', ''))
    if (state.chats.indexOf(newChatId) === -1) {
      const chats = newChatId ? [...state.chats, newChatId] : state.chats
      return state.merge({ chats })
    }
    return state
  },

  [MARK_CHAT_READED.SUCCESS]: (state, { payload }) => {
    const { readedChatId } = payload
    const unreadCounts = omit(state.unreadCounts, readedChatId)
    return state.merge({ unreadCounts })
  },

  [MARK_CHAT_LOADED]: (state, { payload }) => {
    const { chatId } = payload
    const loadedChatIds = [...state.loadedChatIds, chatId]
    return state.merge({ loadedChatIds })
  },

  [RECEIVED_DELETED_MESSAGE]: createDeleteHandler('messages'),

  [LOAD_CHAT_MESSAGES.FAILURE]: state =>
    state.merge({
      isMessagesLoading: false,
    }),

  [LOAD_MORE_MESSAGES.REQUEST]: state =>
    state.merge({ isLoadingMoreMessages: true }),

  [LOAD_MORE_MESSAGES.SUCCESS]: createLoadHandler('messages', {
    addToState: {
      isLoadingMoreMessages: false,
    },
  }),

  [LOAD_MORE_MESSAGES.FAILURE]: state =>
    state.merge({ isLoadingMoreMessages: false }),

  [CLEAR_HISTORY.SUCCESS]: state => state.merge({ messages: [] }),

  [CREATE_CHAT.SUCCESS]: createLoadHandler('chats'),

  [UPLOAD_FILE.REQUEST]: (state, { payload }) =>
    state.merge({
      files: mergeWith({}, state.files, payload.files, mergeCustomizer),
    }),

  [UPLOAD_FILE.FAILURE]: (state, { payload }) => {
    const file = getFirstEntity(get(payload, 'files', {}))
    return state.merge({
      files: reject(state.files, item => item.id === file.id),
    })
  },

  [REMOVE_LOCAL_FILE]: (state, { payload }) => {
    const fileId = get(payload, 'fileId')
    return state.merge({
      files: reject(state.files, item => item.id === fileId),
    })
  },

  [DELETE_CHAT.SUCCESS]: createDeleteHandler('chats'),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.label`
  position: relative;
  cursor: pointer;
  top: 0;
  left: 0;
  user-select: none;

  ${props => css`
    height: ${props.rectangleSize}px;
    width: ${props.rectangleSize}px;
    border-radius: ${props.borderRadius}px;
    border: 1px solid ${props.rectangleBorderColor};
    background: ${props.checked ? props.activeBgColor : props.bgColor};
  `};

  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  :before {
    cursor: pointer;
    content: '';
    position: absolute;
    background: transparent;

    ${props => css`
      opacity: ${props.checked ? 1 : 0};
      top: ${props.tickTopPosition}px;
      left: ${props.tickLeftPosition}px;
      width: ${props.tickWidth}px;
      height: ${props.tickHeight}px;
      border: 1.6px solid ${props.tickColor};
      transform: rotate(${props.tickRotate}deg);
      border-top: none;
      border-right: none;
    `};
  }

  :hover:before {
    opacity: ${props => (props.checked ? 1 : 0.1)};
  }
`

const Checkbox = ({
  activeBgColor,
  borderRadius,
  checked,
  id,
  name,
  rectangleSize,
  tickColor,
  tickHeight,
  tickWidth,
  tickRotate,
  tickTopPosition,
  tickLeftPosition,
  rectangleBorderColor,
  onChange,
  bgColor,
}) => (
  <Container
    activeBgColor={activeBgColor}
    bgColor={bgColor}
    borderRadius={borderRadius}
    checked={checked}
    htmlFor={id}
    rectangleBorderColor={rectangleBorderColor}
    rectangleSize={rectangleSize}
    tickColor={tickColor}
    tickHeight={tickHeight}
    tickLeftPosition={tickLeftPosition}
    tickRotate={tickRotate}
    tickTopPosition={tickTopPosition}
    tickWidth={tickWidth}
  >
    <input
      checked={checked}
      id={id}
      name={name}
      type="checkbox"
      onChange={onChange}
    />
  </Container>
)

Checkbox.defaultProps = {
  checked: true,
  borderRadius: 0,
  rectangleSize: 16,
  tickHeight: 6,
  tickWidth: 10,
  tickRotate: -45,
  tickColor: '#333',
  tickTopPosition: 2,
  tickLeftPosition: 2,
  rectangleBorderColor: themeGet('colors.GRAY'),
  id: 'checkboxId',
  name: '',
  bgColor: 'transparent',
  activeBgColor: 'transparent',
  onChange: () => {},
}

Checkbox.propTypes = {
  activeBgColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  bgColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  borderRadius: PropTypes.number,
  checked: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  rectangleBorderColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  rectangleSize: PropTypes.number,
  tickColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  tickHeight: PropTypes.number,
  tickLeftPosition: PropTypes.number,
  tickRotate: PropTypes.number,
  tickTopPosition: PropTypes.number,
  tickWidth: PropTypes.number,
  onChange: PropTypes.func,
}

export default Checkbox

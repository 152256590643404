import { push } from 'connected-react-router'
import { all, fork, put, take } from 'redux-saga/effects'

import { peoplePath } from 'helpers/routes'

import {
  CHANGE_ACCESS_LEVEL,
  SET_TEMPORARY_ACCESS_LEVEL,
} from 'store/actions/users'

export function* reloadAfterChangeAccessLevel() {
  while (true) {
    yield take(CHANGE_ACCESS_LEVEL.SUCCESS)
  }
}

export function* reloadAfterSetTemporaryAccessLevel() {
  while (true) {
    yield take(SET_TEMPORARY_ACCESS_LEVEL.SUCCESS)
    yield put(push(peoplePath()))
  }
}

export default function* root() {
  yield all([
    fork(reloadAfterChangeAccessLevel),
    fork(reloadAfterSetTemporaryAccessLevel),
  ])
}

import React from 'react'

import { Button, ButtonProps } from 'components/ui/__v3__/Button'

import { i18n } from 'i18n'

type Props = {
  onReset: (() => Promise<void>) | (() => void)
} & ButtonProps

export function Reset({ onReset, ...rest }: Props) {
  return (
    <Button {...rest} secondary onClick={onReset}>
      {i18n('table.resetFilters')}
    </Button>
  )
}

import { createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import get from 'lodash/get'
import omit from 'lodash/omit'
import without from 'lodash/without'

import { FILTER_BY_COND } from 'constants/ids'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { DELETE_BRANCH } from 'store/actions/company/branches'
import {
  CHANGE_LOCATION_FILTER,
  CHANGE_LOCATION_SORT,
  CHANGE_PAGE_NUMBER,
  CHANGE_PAGE_SIZE,
  LOAD_LOCATIONS,
  LOCATIONS_CLEAR,
} from 'store/actions/locations'

const INITIAL_SORT = 'name'
const INITIAL_PAGE_SIZE = 10
const INITIAL_PAGE_NUMBER = 1

const initialState = Immutable({
  locations: [],
  isLoading: false,
  isLoaded: false,

  error: '',

  filters: {
    name: '',
    address: '',
    locationCode: '',
    locationManager: '',
    tags: [],
    tagCond: FILTER_BY_COND.and,
  },

  sort: INITIAL_SORT,
  pageNumber: INITIAL_PAGE_NUMBER,
  pageSize: INITIAL_PAGE_SIZE,
  recordCount: 0,
})

function changeLocationFilter(state, { payload }) {
  // TODO: better to store id's, but ok for now
  const tags = payload?.filters?.tags?.map(tag => omit(tag, ['company'])) ?? []

  return state.merge({
    isLoading: Boolean(payload.withLoading),
    filters: {
      ...state.filters,
      ...(payload?.filters ?? []),
      tags,
    },
  })
}

function changeLocationSort(state, { payload }) {
  return state.merge({
    sort: payload?.sort ?? INITIAL_SORT,
  })
}

function changePageSize(state, { payload }) {
  return state.merge({
    pageSize: payload?.pageSize ?? INITIAL_PAGE_SIZE,
  })
}

function changePageNumber(state, { payload }) {
  return state.merge({
    pageNumber: payload?.pageNumber ?? INITIAL_PAGE_NUMBER,
  })
}

function clear(state) {
  return state.merge(initialState)
}

const handlers = {
  [CHANGE_LOCATION_FILTER]: changeLocationFilter,
  [CHANGE_LOCATION_SORT]: changeLocationSort,
  [CHANGE_PAGE_SIZE]: changePageSize,
  [CHANGE_PAGE_NUMBER]: changePageNumber,
  [LOCATIONS_CLEAR]: clear,

  [LOAD_LOCATIONS.REQUEST]: (state, { payload }) => {
    return state.merge({
      isLoading: payload?.withLoading,
      isLoaded: !payload?.withLoading,
    })
  },

  [LOAD_LOCATIONS.SUCCESS]: (state, action) => {
    const companyId = get(action, ['payload', 'companyId'])

    const recordCount = get(action, [
      'payload',
      'data',
      'meta',
      `/companies/${companyId}/branches`,
      'meta',
      'recordCount',
    ])

    return createLoadHandler('branches', {
      withReplace: true,
      mapToKey: 'locations',
      addToState: {
        recordCount,
        isLoading: false,
        isLoaded: true,
      },
    })(state, action)
  },

  [LOAD_LOCATIONS.FAILURE]: state => {
    return state.merge({ isLoading: false, isLoaded: false })
  },

  [DELETE_BRANCH.SUCCESS]: (state, { payload }) => {
    return state.merge({ locations: without(state.locations, payload.id) })
  },

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

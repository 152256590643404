import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { Select } from 'components/ui/__v3__/Select'

import { createDebounce } from 'helpers/debounce'
import { FORM_STATES } from 'helpers/formBuilder'
import { denormalize } from 'helpers/redux'

import _ from 'i18n'

import { showError } from 'services/API'

import { FORM_TEMPLATE_TYPE } from 'store/reducers/company/formTemplates'

const FormTemplatePicker = ({
  forms,
  isDisabled,
  menuIsOpen,
  loadingText,
  placeholder,
  preload,
  showJobs,
  value,
  onLoad,
  ...rest
}) => {
  const mapOptions = useCallback(items => {
    return items.map(item => {
      const { id, name } = item
      return { id, value: id, label: name, form: item, mandatory: true }
    })
  }, [])

  const initOptions = useCallback(
    () => onLoad({ filters: { state: FORM_STATES.published } }),
    [onLoad],
  )

  /**
   * We want the options to be fetched immediately in this case
   */
  useEffect(() => menuIsOpen && initOptions(), [initOptions, menuIsOpen])

  /**
   * We need this to return all options on input field being cleared
   */
  const handleInputChange = useCallback(
    (input, { action }) => {
      if (
        isEmpty(input) &&
        (action === 'input-change' || action === 'set-value')
      )
        initOptions()
    },
    [initOptions],
  )

  const initial = useMemo(() => mapOptions(forms.entities || []), [
    forms.entities,
    mapOptions,
  ])

  const renderLoadingMessage = useCallback(
    () => loadingText ?? `${_('common.loading')}...`,
    [loadingText],
  )

  const debouncedLoad = useMemo(() => {
    return createDebounce(
      (inputValue, callback) => {
        if (!inputValue) {
          callback([])
        } else {
          onLoad({
            filters: { name: inputValue, state: FORM_STATES.published },
          }).then(result => {
            if (result?.ok) {
              const data = get(result, ['payload', 'data'])
              const formTemplateIds = map(get(data, FORM_TEMPLATE_TYPE), 'id')

              const denormalizedForms = isEmpty(formTemplateIds)
                ? []
                : denormalize(data, FORM_TEMPLATE_TYPE, formTemplateIds)

              callback(mapOptions(denormalizedForms))
            } else {
              showError(result)
              callback([])
            }
          })
        }
      },
      { leading: true, trailing: true },
    )
  }, [onLoad, mapOptions])

  return (
    <Select
      async
      cacheOptions
      closeMenuOnSelect={!rest.isMulti}
      defaultOptions={initial}
      isDisabled={isDisabled}
      isLoading={forms.isLoading}
      isSearchable
      loadOptions={debouncedLoad}
      loadingMessage={renderLoadingMessage}
      menuIsOpen={menuIsOpen}
      placeholder={placeholder}
      value={value}
      onInputChange={handleInputChange}
      onMenuOpen={initOptions}
      {...rest}
    />
  )
}

FormTemplatePicker.defaultProps = {
  onLoad: noop,
  placeholder: null,
  isDisabled: false,
  loadingText: null,
  withPortal: true,
}

FormTemplatePicker.propTypes = {
  ...Select.propTypes,
  forms: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  loadingText: PropTypes.string,
  placeholder: PropTypes.string,
  withPortal: PropTypes.bool,
  onLoad: PropTypes.func,
}

export default FormTemplatePicker

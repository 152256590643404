import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'components/ui/__v2__/Grid'

export const FormElement = styled.div`
  padding: ${themeGet('space.3')}px;
`

export const FormContainer = styled(Flex)`
  flex-direction: column;
  width: 30rem;
  overflow-y: scroll;
`

export const FormHeader = styled(FormElement)`
  display: flex;
  flex-direction: column;
`

// TODO: Maybe reuse this
export const FormTitle = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: ${themeGet('layout.text')};
  margin-top: unset;
`

export const FormDescription = styled.p`
  font-size: 14px;
  text-align: justify;
  line-height: 18px;
  color: ${themeGet('layout.lightText')};
  word-break: break-all;
`

import React from 'react'
import { TypeOptions } from 'react-toastify'

import { Flex, Span } from 'components/ui/__v2__/Grid'

import { Icon } from './Icon'

interface ToastProps {
  title: string
  content?: string
  type: TypeOptions
}

export function Toast({ content, title, type }: ToastProps) {
  return (
    <Flex>
      <Icon
        // @ts-ignore
        mr={8}
        mt="2px"
        type={type}
      />

      <Flex flexDirection="column">
        <Span fontSize={14} fontWeight="bold">
          {title}
        </Span>

        {content && <Span fontSize={12}>{content}</Span>}
      </Flex>
    </Flex>
  )
}

import styled from 'styled-components'

// TODO: create a new Popover component for this
export const PopoverContainer = styled.div`
  background: ${({ theme }) => theme.layout.bg};
  border-radius: ${({ theme }) => theme.space[2]}px;
  box-shadow: ${({ theme }) => theme.tooltip.shadow};
  padding: 0 ${({ theme }) => theme.space[2]}px;
  z-index: ${({ theme }) => theme.zIndices.popup};
  overflow: hidden;
`

import { useEffect } from 'react'

import EventBus from 'services/EventBus'

export function usePageTitle({ title, subtitle, children }) {
  useEffect(() => {
    EventBus.trigger(EventBus.actions.setPageTitle, {
      title,
      subtitle,
      children,
    })

    return () => {
      EventBus.trigger(EventBus.actions.setPageTitle, null)
    }
  }, [title, subtitle, children])
}

export default usePageTitle

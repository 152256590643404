import React, { ChangeEvent, useCallback } from 'react'

import { Flex, SpacedColumn, Span } from 'components/ui/__v2__/Grid'
import { Radio } from 'components/ui/__v2__/Radio'

import { ScheduleConflictActionTypes } from 'constants/ids'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

export type ShiftConflictActionSelectorProps = {
  value: ScheduleConflictActionTypes
  description: string
  name: string
  excludeNoneAction: boolean
  withErrorConflict?: boolean
  onChange: (option: ScheduleConflictActionTypes) => void
}

export function ShiftConflictActionSelector({
  value,
  description,
  name,
  excludeNoneAction,
  withErrorConflict,
  onChange,
}: ShiftConflictActionSelectorProps) {
  const t = useI18n<typeof TRANSLATIONS.shiftConflictActions>(
    'shiftConflictActions',
  )

  const excludeNoneActionOptions = [
    ScheduleConflictActionTypes.Split,
    ScheduleConflictActionTypes.Unassign,
  ]

  const options = excludeNoneAction
    ? excludeNoneActionOptions
    : [
        ScheduleConflictActionTypes.None,
        ScheduleConflictActionTypes.Split,
        ScheduleConflictActionTypes.Unassign,
      ]

  if (withErrorConflict) {
    excludeNoneActionOptions.push(ScheduleConflictActionTypes.Error)
  }

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value as ScheduleConflictActionTypes

      onChange(value)
    },
    [onChange],
  )

  return (
    <SpacedColumn maxWidth="400px">
      <Span textAlign="justify">{description}</Span>
      {options.map(option => (
        <Flex alignItems="center" as="label" key={option}>
          <Radio
            // @ts-ignore
            checked={value === option}
            name={name}
            size={16}
            value={option}
            onChange={handleChange}
          />
          <Span ml={2}>{t(`options.${option}`)}</Span>
        </Flex>
      ))}
    </SpacedColumn>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

import { IconError } from 'components/ui/__v3__/Input/Errors'

import { ValidationError } from './styles'

import { Switch } from '../Switch'

/**
 * @see https://final-form.org/docs/react-final-form/types/FieldRenderProps for FormRenderProps props
 * @see {@link ../Switch/Switch.js} for Switch props
 */
const FormSwitch = React.forwardRef(({ input, meta, ...rest }, ref) => {
  return (
    <Switch
      errored={meta.error && meta.touched}
      ref={ref}
      {...input}
      helper={
        meta.error &&
        meta.touched && (
          <ValidationError>
            <IconError>{meta.error}</IconError>
          </ValidationError>
        )
      }
      {...rest}
    />
  )
})

FormSwitch.propTypes = {
  ...Switch.propTypes,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export default FormSwitch

import React, { useMemo } from 'react'

import { EffectivePeriodsCell } from 'components/tables/Cells'

import _ from 'i18n'

// TODO: Move to new table
const caseInsensitiveSort = (rowA, rowB, entity) => {
  const a = rowA?.[entity]?.name.toLowerCase()
  const b = rowB?.[entity]?.name.toLowerCase()
  if (a > b) {
    return 1
  }
  if (b > a) {
    return -1
  }
  return 0
}

export default function useColumns({ withPeriods, withWage }) {
  return useMemo(
    () => [
      {
        id: 'id',
        selector: row => row?.id,
        omit: true,
      },
      {
        name: _('reportBuilder.fields.role'),
        selector: row => row?.job?.name,
        grow: 1.5,
        sortable: true,
        sortFunction: (rowA, rowB) => caseInsensitiveSort(rowA, rowB, 'job'),
      },
      {
        name: _('reportBuilder.fields.department'),
        grow: 1.5,
        selector: row => row?.department?.name,
        sortable: true,
        sortFunction: (rowA, rowB) =>
          caseInsensitiveSort(rowA, rowB, 'department'),
      },
      {
        name: _('reportBuilder.fields.location'),
        grow: 1.5,
        selector: row => row?.branch?.name,
        sortable: true,
        sortFunction: (rowA, rowB) => caseInsensitiveSort(rowA, rowB, 'branch'),
      },
      {
        name: _('reportBuilder.fields.payWage'),
        selector: row => row?.wage,
        omit: !withWage,
      },
      {
        name: _('reportBuilder.fields.payType'),
        selector: row => row?.wageType,
        omit: !withWage,
      },
      {
        name: _('customLabelDays.effectivePeriod'),
        grow: 2,
        selector: row => <EffectivePeriodsCell row={row} />,
        omit: !withPeriods,
      },
    ],
    [withPeriods, withWage],
  )
}

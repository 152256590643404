import { StoreonModule } from 'storeon'

import { INIT } from './constants'

export const CUSTOM_FIELD_EDIT_KEY = 'customFieldEdit'
export const CUSTOM_FIELD_EDIT_SET = 'customFieldEdit/set'
export const CUSTOM_FIELD_EDIT_UNSET = 'customFieldEdit/unset'

export interface CustomFieldEditState {
  [CUSTOM_FIELD_EDIT_KEY]: string | undefined
}

export interface CustomFieldEditPayload {
  backgroundTaskId: string
}

export interface CustomFieldEditEvents {
  [CUSTOM_FIELD_EDIT_SET]: Pick<CustomFieldEditPayload, 'backgroundTaskId'>
  [CUSTOM_FIELD_EDIT_UNSET]: never
}

const INITIAL_STATE: CustomFieldEditState = {
  [CUSTOM_FIELD_EDIT_KEY]: undefined,
}

export const customFieldEditModule: StoreonModule<
  CustomFieldEditState,
  CustomFieldEditEvents
> = store => {
  store.on(INIT, () => ({ ...INITIAL_STATE }))
  store.on(CUSTOM_FIELD_EDIT_SET, (state, { backgroundTaskId }) => ({
    ...state,
    [CUSTOM_FIELD_EDIT_KEY]: backgroundTaskId,
  }))
  store.on(CUSTOM_FIELD_EDIT_UNSET, state => ({
    ...state,
    [CUSTOM_FIELD_EDIT_KEY]: undefined,
  }))
}

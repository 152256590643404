import { useCallback, useEffect, useMemo, useRef } from 'react'

import { createDebounce } from 'helpers/debounce'

type Args = {
  onBottom: () => void
  bottomOffset?: number
  triggerOnMount?: boolean
}

const BOTTOM_OFFSET_PX = 300

export function useBottomReached({
  onBottom,
  bottomOffset = BOTTOM_OFFSET_PX,
  triggerOnMount = true,
}: Args) {
  const containerRef = useRef<HTMLDivElement>(null)

  const debouncedOnBottom = useMemo(() => createDebounce(onBottom), [onBottom])

  const onScroll = useCallback(() => {
    if (containerRef.current) {
      const { scrollHeight, scrollTop, clientHeight } = containerRef.current
      // Note: Once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
      if (scrollHeight - scrollTop - clientHeight < bottomOffset) {
        debouncedOnBottom()
      }
    }
  }, [debouncedOnBottom, bottomOffset])

  useEffect(() => {
    const ref = containerRef.current
    if (ref) {
      ref.addEventListener('scroll', onScroll)
    }

    if (triggerOnMount) {
      onScroll()
    }

    return () => {
      if (ref) {
        ref.removeEventListener('scroll', onScroll)
      }
    }
  }, [onScroll, triggerOnMount])

  return containerRef
}

import {
  createDeleteHandler,
  createFields,
  createReducerHandlers,
} from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { WORK_AGREEMENT } from 'store/actions/employees/workAgreement'

export const WORK_AGREEMENT_TYPE = 'workAgreements'
export const WORK_AGREEMENT_FIELD = 'workAgreement'

const initialState = Immutable({
  ...createFields(WORK_AGREEMENT_TYPE, WORK_AGREEMENT_FIELD),
})

const handlers = {
  ...createReducerHandlers(WORK_AGREEMENT_TYPE, WORK_AGREEMENT.LOAD, {
    withReplace: true,
    mapToKey: WORK_AGREEMENT_FIELD,
  }),
  ...createReducerHandlers(WORK_AGREEMENT_TYPE, WORK_AGREEMENT.CREATE, {
    withReplace: false,
    mapToKey: WORK_AGREEMENT_FIELD,
  }),
  ...createReducerHandlers(WORK_AGREEMENT_TYPE, WORK_AGREEMENT.UPDATE),
  [WORK_AGREEMENT.DELETE.SUCCESS]: createDeleteHandler(WORK_AGREEMENT_FIELD),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

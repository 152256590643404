import { useApolloMutation } from 'API/services/Apollo'

import { ApplyAutoSchedulingTaskMutation } from './GraphQL'

export function useApplyAutoSchedulingTask() {
  const [innerApply, { loading }] = useApolloMutation<
    MutationData<'applyAutoSchedulingTask'>,
    Gateway.MutationApplyAutoSchedulingTaskArgs
  >(ApplyAutoSchedulingTaskMutation)

  async function apply(id: string) {
    const result = await innerApply({
      variables: {
        id,
      },
    })
    return result.data?.applyAutoSchedulingTask.backgroundTaskId
  }

  return {
    apply,
    applying: loading,
  }
}

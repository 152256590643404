import flatMap from 'lodash/flatMap'
import map from 'lodash/map'

import apiCall from 'services/API'

export const uploadFiles = files => async (dispatch, getState) => {
  const uploadFilePromises = map(files, file =>
    apiCall({
      method: 'POST',
      endpoint: '/files',
      file,
      fileFieldName: 'content',
    })(dispatch, getState),
  )

  const uploadFileResponses = await Promise.all(uploadFilePromises)

  const resultFiles = flatMap(uploadFileResponses, response =>
    map(response?.payload?.data?.files, file => ({
      id: file?.id,
      content: file?.attributes?.content,
      name: file?.attributes?.name,
    })),
  )

  return resultFiles
}

import normalize from 'json-api-normalizer'
import Pusher from 'pusher-js'
import { eventChannel } from 'redux-saga'
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects'

import first from 'lodash/first'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import reject from 'lodash/reject'

import { api, pusher } from 'constants/config'

import { AuthService } from 'services/Auth'

import { COMPLETE_REFETCH } from 'store/actions/app'
import { LOG_OUT } from 'store/actions/auth'
import { handleEmployeeDestroy } from 'store/actions/company/branches'
import {
  receiveProfile,
  setBranch /* showLostAccessModal */,
} from 'store/actions/viewer'
import { getBranches } from 'store/selectors/company/branches'
import { getBranchId, getId } from 'store/selectors/viewer'

const EVENTS = {
  // TODO: typo in accessable - should be accessible
  accessableBranchesChanged: 'accessable-branches-changed',
  updateProfile: 'update-profile',
  employeeDestroyed: 'employee-destroyed',
}

function createPusherChannel(socket, channelName) {
  return eventChannel(emitter => {
    const channel = socket.subscribe(channelName)
    forEach(EVENTS, event => {
      channel.bind(event, data => {
        emitter({ event, data })
      })
    })
    return () => channel.unbind()
  })
}

function* listenUser() {
  const userId = yield select(getId)
  const headers = yield AuthService.getAuthHeaders()
  const channelName = `private-users.${userId}`

  const socket = new Pusher(pusher.token, {
    authEndpoint: `${api?.rubyUrl}/sockets/auth`,
    encrypted: true,
    cluster: 'mt1',
    auth: {
      headers: {
        'Content-Type': 'application/vnd.api+json',
        ...headers,
      },
    },
  })

  const socketChannel = yield call(createPusherChannel, socket, channelName)

  try {
    while (true) {
      const { event, data } = yield take(socketChannel)
      if (data) {
        const normalized = normalize(data, { camelizeKeys: false })
        switch (event) {
          case EVENTS.accessableBranchesChanged: {
            const { branches } = normalized
            if (isEmpty(branches)) {
              yield AuthService.logOut()
            } else {
              const availableBranchId = first(keys(branches))
              yield put(setBranch(availableBranchId))
            }
            break
          }
          case EVENTS.employeeDestroyed: {
            const { branches } = normalized
            if (isEmpty(branches)) {
              yield AuthService.logOut()
              break
            }
            const userBranchId = yield select(getBranchId)
            const deletedBranchId = first(keys(branches))
            const allBranches = yield select(getBranches)
            const existingBranches = reject(allBranches, [
              'id',
              deletedBranchId,
            ])
            if (isEmpty(existingBranches)) {
              yield put(setBranch(deletedBranchId))
            } else if (userBranchId === deletedBranchId) {
              const availableBranchId = first(keys(existingBranches))
              yield put(setBranch(availableBranchId))
            } else {
              yield put(handleEmployeeDestroy(deletedBranchId))
            }
            break
          }
          case EVENTS.updateProfile: {
            yield put(receiveProfile({ data: normalized }))
            break
          }
          default:
            break
        }
      }
    }
  } finally {
    if (yield cancelled()) {
      socket.disconnect()
    }
  }
}

export default function* root() {
  while (yield take(COMPLETE_REFETCH)) {
    const branchTask = yield fork(listenUser)
    yield take(LOG_OUT)
    yield cancel(branchTask)
  }
}

import styled from 'styled-components'

import { Span } from '../Grid'

const TextOverflow = styled(Span)<{
  showHelper?: boolean
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: ${props => (props.showHelper ? 'pointer' : 'inherit')};
`

export default TextOverflow

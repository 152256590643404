import coreApiCall from 'services/API/coreApi'

import {
  CreateAvailabilityRequestArgs,
  CreateAvailabilityRequestKinds,
} from '../../types'

export function createAvailabilityRequestMutation({
  requestType,
  startAt,
  endAt,
  recurrence,
  availabilityId,
  deletedDate,
  conflictAction,
  employeeId,
}: CreateAvailabilityRequestArgs) {
  let query: JsonApi.CreateAvailabilityRequestArgs = {}

  switch (requestType) {
    case CreateAvailabilityRequestKinds.Create:
      query = {
        after: {
          start_at: startAt,
          end_at: endAt,
          ...(recurrence && { recurrence }),
        },
      }
      break

    case CreateAvailabilityRequestKinds.UpdateOne:
      query = {
        availability_id: availabilityId,
        after: {
          start_at: startAt,
          end_at: endAt,
        },
      }
      break

    case CreateAvailabilityRequestKinds.UpdateAll:
      query = {
        availability_id: availabilityId,
        after: {
          start_at: startAt,
          end_at: endAt,
          recurrence,
        },
      }
      break

    case CreateAvailabilityRequestKinds.UpdateAllFromDate:
      query = {
        availability_id: availabilityId,
        after: {
          start_at: startAt,
          end_at: endAt,
          recurrence,
        },
      }
      break

    case CreateAvailabilityRequestKinds.DeleteOne:
      query = {
        availability_id: availabilityId,
        date_to_delete: deletedDate,
      }
      break

    case CreateAvailabilityRequestKinds.DeleteAll:
      query = {
        availability_id: availabilityId,
      }
      break

    case CreateAvailabilityRequestKinds.DeleteAllFromDate:
      query = {
        availability_id: availabilityId,
        date_to_delete: deletedDate,
        delete_type: 'from_date',
      }
      break

    default:
      break
  }

  if (conflictAction) query.schedule_conflict_type = conflictAction

  return coreApiCall({
    endpoint: `/employees/${employeeId}/availability_requests`,
    method: 'POST',
    query,
  })
}

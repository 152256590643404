import { useCreateGroup } from './useCreateGroup'
import { useDeleteGroup } from './useDeleteGroup'
import { useEffectiveEmployeeGroups } from './useEffectiveEmployeeGroups'
import { useGroupsByCursor } from './useGroupsByCursor'
import { useGroupsByOffset } from './useGroupsByOffset'
import { useGroupsPicker } from './useGroupsPicker'
import { useUpdateGroup } from './useUpdateGroup'

export const GroupAPI = {
  byCursor: useGroupsByCursor,
  byOffset: useGroupsByOffset,
  forEmployee: useEffectiveEmployeeGroups,
  create: useCreateGroup,
  update: useUpdateGroup,
  delete: useDeleteGroup,
  picker: useGroupsPicker,
}

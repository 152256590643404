import { DateTime } from 'luxon'

import { DayPickerOnChangeIntervalProp } from 'components/ui/__v2__/DayPicker/types'

import { AssignmentsTableQueryParams } from 'pages/EmployerApp/Schedule/pages/AutomaticAssignmentTasks/components/Details/components/AssignmentsTable/hooks/types'

export function assignmentsTableParamsFilterToApiFilter(
  filter: AssignmentsTableQueryParams['filter'],
) {
  const { hasEmployee, employee, taskId, shiftStartAt } = filter
  return {
    autoSchedulingTaskId: { eq: taskId },
    employeeId: employee ? { eq: employee.value } : undefined,
    // Note: API is requestinh a timestamp filter, not a date filter
    shiftStartAt: shiftStartAtFilter(shiftStartAt),
    // Note: we want either all records (undefined) or only ones that have assignments
    hasEmployee: hasEmployee ? { eq: hasEmployee } : undefined,
  }
}

export function assignmentsTableParamsSortingToApiSorting(
  filter: AssignmentsTableQueryParams['sorting'],
) {
  const { field, direction } = filter
  return [
    {
      direction,
      field,
    },
  ]
}

function shiftStartAtFilter({ from, to }: DayPickerOnChangeIntervalProp) {
  if (!from || !to) {
    return undefined
  }

  return {
    between: [
      DateTime.fromISO(from).toISO(),
      // Note: adding a day due to how BE filters work
      DateTime.fromISO(to).plus({ days: 1 }).toISO(),
    ],
  }
}

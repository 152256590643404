import styled from 'styled-components'

export const Container = styled.div`
  width: 576px;
  user-select: none;
`

export const Fallback = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

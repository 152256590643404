import React from 'react'

import { SpaceProps } from 'styled-system'
import { omit, pick } from '@styled-system/props'

import { HiddenCheckbox, SwitchCheckbox, SwitchContainer } from './styles'

export type SwitchProps = {
  className?: string
  disabled?: boolean
  height?: number
  width?: number
  secondary?: boolean
} & SpaceProps &
  React.InputHTMLAttributes<HTMLInputElement>

export const Switch = React.forwardRef(
  (props: SwitchProps, ref: React.Ref<HTMLInputElement>) => {
    const {
      disabled,
      width = 46,
      height = 24,
      className,
      checked,
      secondary,
      ...rest
    } = props

    return (
      <SwitchContainer
        className={className}
        disabled={disabled}
        height={height}
        width={width}
        {...pick(rest)}
      >
        <HiddenCheckbox
          disabled={disabled}
          ref={ref}
          {...omit(rest)}
          checked={checked}
        />
        <SwitchCheckbox
          checked={checked}
          disabled={disabled}
          secondary={secondary}
        />
      </SwitchContainer>
    )
  },
)

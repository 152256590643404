import React from 'react'

import { Container, DeleteIcon } from './styles'
import { DeleteIconProps } from './types'

export function DeleteIconButton({ disabled, onClick }: DeleteIconProps) {
  const handleClick = () => {
    if (disabled) return
    onClick()
  }
  return (
    <Container disabled={disabled} onClick={handleClick}>
      <DeleteIcon />
    </Container>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { layout, space, typography } from 'styled-system'

const SvgIcon = ({ glyph, ...rest }) => {
  if (!glyph) return null

  return (
    <svg {...rest}>
      <use xlinkHref={`#${glyph.id}`} />
    </svg>
  )
}

SvgIcon.propTypes = {
  glyph: PropTypes.object.isRequired,
}

const StyledSvgIcon = styled(SvgIcon)`
  vertical-align: middle;
  line-height: 0;

  ${layout};
  ${space};
  ${typography};
`

export default StyledSvgIcon

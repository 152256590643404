import { useEffect } from 'react'

import { useQuery } from '@apollo/client'
import { CompaniesQuery } from 'GraphQL/Company'

import { AuthService } from 'services/Auth'

export function useCompany(isAuthenticated: boolean) {
  const { data, loading, refetch } = useQuery<QueryData<'companies'>>(
    CompaniesQuery,
    { skip: !isAuthenticated, onError: () => AuthService.logout() },
  )

  useEffect(() => {
    if (isAuthenticated) {
      refetch()
    }
  }, [isAuthenticated, refetch])

  // Note: At the moment, we just take the first one
  const company = data?.companies?.[0]

  return {
    company,
    companyLoading: loading,
  }
}

import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import {
  CustomCvFieldsByCursorQuery,
  DeleteCustomCvField as DeleteCustomCvFieldMutation,
} from './GraphQL'

export function useDeleteCustomCvField() {
  const [deleteCustomCvField, { loading: deleting }] = useApolloMutation<
    MutationData<'deleteCustomCvField'>,
    Gateway.MutationDeleteCustomCvFieldArgs
  >(DeleteCustomCvFieldMutation, {
    translationKey: 'settings.customFieldsPage.toasts.delete',
    refetchQueries: compact([getOperationName(CustomCvFieldsByCursorQuery)]),
  })

  const remove = async (customCvFieldId: Gateway.Scalars['ID']) => {
    await deleteCustomCvField({
      variables: { id: customCvFieldId, input: { document: true } },
    })
  }

  return { remove, deleting }
}

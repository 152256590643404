import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { height, width } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import omit from 'lodash/omit'

const Icon = ({ glyph, ...rest }) => {
  const props = omit(rest, ['width', 'height'])

  if (!glyph) return null

  return (
    <svg {...props}>
      <use xlinkHref={`#${glyph.id}`} />
    </svg>
  )
}

Icon.defaultProps = {
  height: null,
  width: null,
}

Icon.propTypes = {
  glyph: PropTypes.object.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default styled(Icon)`
  fill: ${themeGet('colors.GRAY_500')};
  stroke: ${themeGet('colors.GRAY_500')};
  vertical-align: middle;
  line-height: 0;
  stroke-width: 0;

  ${height};
  ${width};
`

import React from 'react'

import { Popover } from 'components/ui/__v2__/Popover'
import { IconButton } from 'components/ui/__v3__/'

import { i18n } from 'i18n'

import { LogIcon } from './styles'

type Props = {
  withPopover?: boolean
  onClick: () => void
}

export function LogIconButton({ onClick, withPopover }: Props) {
  return withPopover ? (
    // @ts-ignore
    <Popover content={i18n('shiftLogs.changesLog')} trigger="mouseenter">
      {/* @ts-ignore */}
      <IconButton secondary small onClick={onClick}>
        <LogIcon />
      </IconButton>
    </Popover>
  ) : (
    // @ts-ignore
    <IconButton secondary small onClick={onClick}>
      <LogIcon />
    </IconButton>
  )
}

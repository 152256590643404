import {
  TimeBucketChildCoreWithParentAPIItem,
  TimeBucketParentCoreAPIItem,
} from 'API/TimeBucket'
import { LONG_DASH } from 'Tables/constant'
import { TimeBucket } from 'Types/schedule'

import { TimeBucketChildrenOption } from 'components/blocks/__v3__/TimeBucketComponents/TimeBucketChildrenPicker/types'
import { PickerOptionType } from 'components/ui/__v3__/Select/types'

export type GetTimeBucketNamesProps = {
  timeBucketParent?: TimeBucketParentCoreAPIItem | TimeBucket | null
  timeBucketChild?: TimeBucketChildCoreWithParentAPIItem | TimeBucket | null
}

export function getTimeBucketNames({
  timeBucketParent,
  timeBucketChild,
}: GetTimeBucketNamesProps) {
  let parentName = LONG_DASH
  let childName = LONG_DASH

  // NOTE: in case we have a timeBucket from json-api, but no timeBucket.timeBucketParent, it means we selected only the parent in picker
  //       and timeBucket is the parent (since there is no distinction between child and parent on the BE)
  if (timeBucketChild && !timeBucketChild.timeBucketParent) {
    parentName = timeBucketChild.name
  } else if (timeBucketParent) {
    parentName = timeBucketParent.name
  } else if (timeBucketChild) {
    parentName = timeBucketChild.timeBucketParent?.name ?? LONG_DASH
    childName = timeBucketChild.name
  }

  return {
    parentName,
    childName,
  }
}

export function getTimeBucketJoinedName({
  timeBucketParent,
  timeBucketChild,
}: GetTimeBucketNamesProps) {
  const { parentName, childName } = getTimeBucketNames({
    timeBucketParent,
    timeBucketChild,
  })

  return `${parentName} / ${childName}`
}

export function getJsonApiTimeBucketPickerValues(
  timeBucket?: TimeBucket | null,
) {
  const timeBucketValues: {
    timeBucketParent: PickerOptionType | null
    timeBucketChild: TimeBucketChildrenOption | null
  } = {
    timeBucketParent: null,
    timeBucketChild: null,
  }

  // NOTE: If we have a timeBucket but no timeBucket.parent, it means we selected only the parent in picker
  //       and timeBucket is the parent (since there is no distinction between child and parent on the BE)
  if (timeBucket && !timeBucket.timeBucketParent) {
    timeBucketValues.timeBucketParent = {
      label: timeBucket.name,
      value: timeBucket.id,
    }
  }

  // NOTE: otherwise, timeBucket is the child and timeBucket.parent  is the parent
  if (timeBucket && timeBucket.timeBucketParent) {
    timeBucketValues.timeBucketParent = {
      label: timeBucket.timeBucketParent.name,
      value: timeBucket.timeBucketParent.id,
    }

    timeBucketValues.timeBucketChild = {
      label: timeBucket.name,
      value: timeBucket.id,
      timeBucketChild: timeBucket,
    }
  }

  return { ...timeBucketValues }
}

export function selectedTimeBucketsToFilters({
  timeBucketParents = [],
  timeBucketChildren = [],
}: {
  timeBucketParents: PickerOptionType[]
  timeBucketChildren: TimeBucketChildrenOption[]
}) {
  const filter: Pick<
    Gateway.ShiftFilter,
    'timeBucketChildId' | 'timeBucketParentId'
  > = {}

  const timeBucketsParentIds = timeBucketParents.map(
    timeBucketParent => timeBucketParent.value,
  )
  const timeBucketChildIds = timeBucketChildren.map(
    timeBucketChild => timeBucketChild.value,
  )

  if (timeBucketParents.length > 0 && timeBucketChildren.length === 0) {
    filter.timeBucketParentId = timeBucketsParentIds
  }

  if (timeBucketChildren.length > 0) {
    filter.timeBucketChildId = timeBucketChildIds
  }

  return filter
}

export function selectedTimeBucketsToJsonApiFilters({
  timeBucketParents = [],
  timeBucketChildren = [],
}: {
  timeBucketParents: PickerOptionType[]
  timeBucketChildren: TimeBucketChildrenOption[]
}) {
  const filter: {
    time_bucket_id?: {
      in_or_descendant_of: string[]
    }
  } = {}

  if (timeBucketParents.length > 0 && timeBucketChildren.length === 0) {
    const timeBucketsParentIds = timeBucketParents.map(
      timeBucketParent => timeBucketParent.value,
    )

    filter.time_bucket_id = {
      in_or_descendant_of: timeBucketsParentIds,
    }
  } else if (timeBucketChildren.length > 0) {
    const timeBucketChildIds = timeBucketChildren.map(
      timeBucketChild => timeBucketChild.value,
    )

    filter.time_bucket_id = {
      in_or_descendant_of: timeBucketChildIds,
    }
  }

  return filter
}

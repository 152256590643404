import { DateTime } from 'luxon'

import isNull from 'lodash/isNull'

import Utils from 'services/Utils'

// TODO: clean up and move the good to services/Utils/{Roles | DateTime}

export function getEffectiveDatesValidation(effectiveDates) {
  return effectiveDates
    ? !effectiveDates.find(
        date => date.isEndDayValid === false || date.isStartDayValid === false,
      )
    : true
}

export function getIntervalsFromStrings(value) {
  const intervals = value.map(interval =>
    Utils.DateTime.fromStringsToInterval(interval.start, interval.end),
  )
  return intervals
}

function checkIfIntervalOverlaped(array, interval) {
  let result = false

  array.forEach(item => {
    if (item.overlaps(interval)) {
      result = true
    }
  })

  return result
}

export function checkIfHaveSingleNotAvailableEffectiveDate(effectiveDates) {
  return (
    effectiveDates.length === 1 &&
    isNull(effectiveDates[0].start) &&
    isNull(effectiveDates[0].end)
  )
}

export function checkIfIntervalsOverlapping(values) {
  let result = false
  const intervals = getIntervalsFromStrings(values)

  for (let i = 0; i < intervals.length; i += 1) {
    const clonedValue = [...intervals]
    clonedValue.splice(i, 1)

    if (checkIfIntervalOverlaped(clonedValue, intervals[i])) {
      result = true
      return result
    }
  }

  return result
}

export function fromStingToLocale(string, format = 'yyyy-MM-dd') {
  if (!string) {
    return null
  }
  return DateTime.fromFormat(string, format)
    .toLocaleString()
    .replace(/\./g, '/')
}

import { TimeEntryReturn } from 'API/TimeCard/GraphQL'

import { TimeEntryState } from 'constants/gatewayGraphQL'

import { draftTimesheetRecordGuard } from './guards'

import {
  ExistingTimesheetRecord,
  FormTimesheetRecord,
  TimesheetState,
} from '../types'

export function disableRecordActions(
  record: FormTimesheetRecord | TimeEntryReturn,
  canControl: boolean,
) {
  const draft = draftTimesheetRecordGuard(record)

  if (draft) return !canControl

  return (
    !canControl ||
    [TimeEntryState.Approved, TimeEntryState.Discarded].includes(record.state)
  )
}

export function pendingOrFuture(
  entry: TimeEntryReturn | ExistingTimesheetRecord,
): boolean {
  return (
    entry.state === TimeEntryState.Pending ||
    entry.state === TimeEntryState.Future
  )
}

export function approved(entry: TimeEntryReturn | ExistingTimesheetRecord) {
  return entry.state === TimeEntryState.Approved
}

export function discarded(entry: TimeEntryReturn | ExistingTimesheetRecord) {
  return entry.state === TimeEntryState.Discarded
}

export function submitted(entry: TimeEntryReturn | FormTimesheetRecord) {
  return !!entry.submittedAt
}

export function getTimesheetInfo(timeEntries: TimeEntryReturn[]) {
  const hasSavedEntries = timeEntries.length > 0

  const someSubmitted = timeEntries.some(submitted)
  const allSubmitted = timeEntries.every(submitted)
  const noneSubmitted = !someSubmitted

  const allApproved = timeEntries.every(approved)

  const allDiscarded = timeEntries.every(discarded)

  let timeSheetState = TimesheetState.Draft

  if (hasSavedEntries && noneSubmitted) {
    timeSheetState = TimesheetState.Unsubmitted
  }

  if (hasSavedEntries && allSubmitted) {
    timeSheetState = TimesheetState.Pending
  }

  if (hasSavedEntries && allApproved) {
    timeSheetState = TimesheetState.Approved
  }

  if (hasSavedEntries && allDiscarded) {
    timeSheetState = TimesheetState.Discarded
  }

  return { timeSheetState, hasSavedEntries }
}

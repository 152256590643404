import get from 'lodash/get'
import memoize from 'lodash/memoize'
import reduce from 'lodash/reduce'
import uniq from 'lodash/uniq'

import { misc } from 'constants/config'
import { Feature, Plan, PRODUCT } from 'constants/products'

/**
 * Collect all features from products
 * @param products Array
 * @return Array
 * @type {Function}
 */
const getAllFeatures = memoize((products: Plan[]): Feature[] =>
  uniq(
    reduce(
      products,
      (acc: Feature[], product) => [...acc, ...get(PRODUCT, product, [])],
      [],
    ),
  ),
)

export const hasFeature = (feature: Feature, products: Plan[]) => {
  return misc.disableBilling || getAllFeatures(products).includes(feature)
}

import React from 'react'

import { DateTime } from 'luxon'

import { SpacedRow, Span } from 'components/ui/__v2__/Grid'

type Props = {
  shift: Gateway.Shift
}
export function ShiftInfo({ shift }: Props) {
  const {
    name,
    range,
    location: {
      settings: { timezone },
    },
  } = shift

  return (
    <SpacedRow maxWidth="800px">
      <Span fontWeight="bolder">{name}</Span>
      <Span>{humanReadableShiftPeriod(range, timezone)}</Span>
    </SpacedRow>
  )
}

function humanReadableShiftPeriod(
  range: Gateway.Shift['range'],
  zone: Gateway.Location['settings']['timezone'],
): string {
  const { start, end } = range
  const startDt = DateTime.fromISO(start, { zone })
  const endDt = DateTime.fromISO(end, { zone })

  if (endDt.hasSame(startDt, 'day')) {
    // prettier-ignore
    return `/ ${startDt.toFormat('cccc, d LLLL y')} / ${startDt.toLocaleString(DateTime.TIME_SIMPLE)} - ${endDt.toLocaleString(DateTime.TIME_SIMPLE)}`
  }

  // prettier-ignore
  return `/ ${startDt.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)} - ${endDt.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}`
}

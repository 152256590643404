import { useCallback } from 'react'

import validate from 'validate.js'

import { isoToJsDate, toISOString } from 'helpers/date'
import {
  arrayValidator,
  monetaryValidator,
  tokensValidator,
  weeekDaysValidator,
} from 'helpers/validation'

import _ from 'i18n'

export function useValidate(schema) {
  validate.extend(validate.validators.datetime, {
    parse(value) {
      return isoToJsDate(value)
    },
    format(value) {
      return toISOString(value)
    },
  })

  validate.validators.monetary = monetaryValidator

  validate.validators.weekdays = weeekDaysValidator

  validate.validators.tokens = tokensValidator

  validate.validators.array = arrayValidator

  validate.validators.presence.options = {
    message: `^${_('common.required')}`,
  }

  validate.validators.format.options = {
    message: `^${_('common.invalidValue')}`,
  }

  validate.validators.email.options = {
    message: `^${_('common.invalidEmail')}`,
  }

  return useCallback(
    values => {
      return validate(values, schema)
    },
    [schema],
  )
}

export default useValidate

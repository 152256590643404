import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import {
  CustomCvFieldValuesByCursorQuery,
  UpdateCustomCvFieldValueMutation,
} from './GraphQL'

export function useUpdateCustomCvFieldValue() {
  const [updateCustomCvFieldValue, { loading: updating }] = useApolloMutation<
    MutationData<'updateCustomCvFieldValue'>,
    Gateway.MutationUpdateCustomCvFieldValueArgs
  >(UpdateCustomCvFieldValueMutation, {
    mutationType: 'update',
    refetchQueries: compact([
      getOperationName(CustomCvFieldValuesByCursorQuery),
    ]),
  })

  const update = async (
    id: Gateway.Scalars['ID'],
    input: Gateway.UpdateCustomCvFieldValueInput,
  ) => {
    const result = await updateCustomCvFieldValue({
      variables: {
        id,
        input,
      },
    })

    return graphQLResultHasError(result)
  }

  return { update, updating }
}

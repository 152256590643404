import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { EMPLOYMENT_TYPE_ERRORS_I18N } from './errors'
import {
  CreateEmploymentTypeMutation,
  EmploymentTypesByCursorQuery,
} from './GraphQL'

export function useCreateEmploymentType() {
  const t = useI18n<
    typeof TRANSLATIONS.employmentTerms.employmentTypes.toasts.create
  >('employmentTerms.employmentTypes.toasts.create')

  const [innerCreate, { loading: creating }] = useApolloMutation<
    MutationData<'createEmploymentType'>,
    Gateway.MutationCreateEmploymentTypeArgs
  >(CreateEmploymentTypeMutation, {
    refetchQueries: compact([getOperationName(EmploymentTypesByCursorQuery)]),
    silent: true,
    // TODO: Temporary solution, translated errors should come from Gateway
    onError: error => {
      const serverErrorMessage = error.message
      const isEmploymentTypeError = EMPLOYMENT_TYPE_ERRORS_I18N.create.messages.some(
        errorMessage => serverErrorMessage.includes(errorMessage),
      )

      showToast({
        type: 'error',
        title: t('error.title'),
        content: isEmploymentTypeError
          ? EMPLOYMENT_TYPE_ERRORS_I18N.create.translation
          : t('error.message'),
      })
    },
    onCompleted: () => {
      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const createEmploymentType = async (
    params: Gateway.CreateEmploymentTypeInput,
  ) =>
    innerCreate({
      variables: {
        input: params,
      },
    })

  return { createEmploymentType, creating }
}

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import removeSvg from 'assets/images/svg/remove.svg'

import { Icon } from '../Icon'

const RemoveIcon = styled(Icon).attrs({
  glyph: removeSvg,
})`
  stroke-width: 1px;
  stroke: ${themeGet('colors.ICONS_COLOR')};
  width: ${props => (props.size ? props.size : 28)}px;
  height: ${props => (props.size ? props.size : 28)}px;
`

export default RemoveIcon

import { getCurrentEmployee } from 'helpers/employee'
import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getBranchId, getViewer } from 'store/selectors/viewer'

export const CREATE_ABSENCE = createAsyncAction('cantMake/CREATE_ABSENCE')

export const createAbsence = values => (dispatch, getState) => {
  const state = getState()
  const viewer = getViewer(state)
  const branchId = getBranchId(state)
  const employee = getCurrentEmployee(viewer.employees, branchId)

  const employeeId = employee.id

  return dispatch(
    apiCall({
      endpoint: '/absences',
      method: 'POST',
      query: {
        data: {
          attributes: values,
          relationships: {
            employee: {
              data: {
                type: 'employees',
                id: employeeId,
              },
            },
          },
        },
      },
      types: CREATE_ABSENCE,
    }),
  )
}

import { toastr } from 'react-redux-toastr'

import get from 'lodash/get'

import toasts from 'constants/toasts'

export default store => next => action => {
  const toast = get(toasts, action.type)

  // To avoid empty toasts produced by function
  let isToastReturned = true
  if (typeof toast === 'function') {
    isToastReturned = !!toast(action)
  }
  if (toast && isToastReturned) {
    toastr.info(typeof toast === 'function' ? toast(action) : toast)
  }

  return next(action)
}

import React from 'react'
import PropTypes from 'prop-types'

import styled, { keyframes } from 'styled-components'
import styledMap from 'styled-map'
import { height, space, width } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import loading from './icon-loading.svg'

import { Icon } from '../Icon'

const rotate360 = keyframes`
  100% { transform: rotate(360deg); }
`

const RotatingIcon = styled(Icon)`
  animation: ${rotate360} 0.8s linear infinite;

  fill: ${styledMap`
    white: ${themeGet('layout.bg')};
    primary: ${themeGet('layout.primary')};
    neutral: ${themeGet('layout.neutral')};
    positive: ${themeGet('layout.positive')};
    negative: ${themeGet('layout.negative')};
    default: ${themeGet('layout.lightBorder')};
  `};

  ${space};
  ${height};
  ${width};
`

const getGeometry = size => ({
  width: size,
  height: size,
})

const SIZES = {
  small: 15,
  normal: 20,
  large: 30,
  huge: 500,
}

function Spinner({ size, ...rest }) {
  let geometry

  if (typeof size === 'number') {
    geometry = getGeometry(size)
  } else if (typeof size === 'string') {
    geometry = getGeometry(SIZES[size])
  }

  return (
    <RotatingIcon
      glyph={loading}
      height={geometry.height}
      width={geometry.width}
      {...rest}
    />
  )
}

Spinner.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.oneOf(['small', 'normal', 'large', 'huge']),
    PropTypes.number,
  ]),
  style: PropTypes.object,
}

Spinner.defaultProps = {
  size: 'normal',
  style: null,
}

export default Spinner

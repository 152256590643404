import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { chatApi } from 'constants/config'

export const createAvatarSrc = (profile, branchImages, isSmall = false) => {
  let profileAvatarURL = ''
  let branchAvatarURL = ''

  if (profile) {
    profileAvatarURL = isSmall
      ? get(profile, 'content.notification.url', '')
      : get(profile, 'content.url', '')
    if (profileAvatarURL.indexOf('.com') === -1) {
      // TODO: should move s3Url to other config key
      profileAvatarURL = `${chatApi.s3Url}${profileAvatarURL}`
    }
  }

  if (branchImages && branchImages.length > 0) {
    branchAvatarURL = isSmall
      ? get(
          branchImages[branchImages.length - 1],
          'content.notification.url',
          '',
        )
      : get(branchImages[branchImages.length - 1], 'content.url')
  }
  return profileAvatarURL || branchAvatarURL
}

export const getRowUserAvatar = row => {
  const company = get(row, 'company')

  const branchImages = company
    ? get(company, ['branches', '0', 'images'], null)
    : get(row, ['branch', 'images'], null)

  const profileAvatar = get(row, ['profile', 'profileAvatar'], null)

  return createAvatarSrc(profileAvatar, branchImages)
}

export const findBranchImages = (branchId, employee) => {
  let branchImages = get(employee, 'branch.images', [])
  if (isEmpty(branchImages)) {
    const companyBranches = get(employee, 'company.branches', [])
    const activeBranch = find(
      companyBranches,
      companyBranch => companyBranch.id === branchId,
    )
    branchImages = get(activeBranch, 'images', [])
  }
  return branchImages
}

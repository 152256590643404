import React from 'react'

import { ShiftValidationRuleAPIItem } from 'API'

import { RuleParametersComparison } from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

type Props = {
  rule: ShiftValidationRuleAPIItem
  withSemicolon?: boolean
  formSubPath:
    | 'shiftLengthThreshold'
    | 'shiftRestTime'
    | 'employeeAssignmentThreshold'
}

export function HoursRangeCondition({
  rule,
  withSemicolon,
  formSubPath,
}: Props) {
  const operator = rule.parameters[formSubPath]?.operator
  const showThan = operator
    ? [
        RuleParametersComparison.GreaterThan,
        RuleParametersComparison.LessThan,
      ].includes(operator!)
    : null

  const showTo =
    operator === RuleParametersComparison.Between ||
    operator === RuleParametersComparison.NotBetween

  return (
    <>
      {Utils.Rule.getComparisonText(operator)} {showThan && 'than'}{' '}
      <>
        {Utils.DateTime.secondsToHours(
          operator === RuleParametersComparison.LessThan
            ? rule.parameters[formSubPath]?.toSeconds!
            : rule.parameters[formSubPath]?.fromSeconds!,
        )}
        {!showTo && withSemicolon && ','}
      </>{' '}
      {showTo && (
        <>
          and{' '}
          {Utils.DateTime.secondsToHours(
            rule.parameters[formSubPath]?.toSeconds!,
          )}
          {withSemicolon && ','}
        </>
      )}{' '}
      hours
    </>
  )
}

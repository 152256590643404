import React from 'react'

import { Popover } from 'components/ui/__v2__/Popover'

import { i18n } from 'i18n'

import { IconButton, UnassignIcon } from './styles'

type Props = {
  existing?: boolean
  disabled?: boolean
  onClick: () => void
}

export function UnassignButton({ existing, disabled, onClick }: Props) {
  return (
    // @ts-ignore
    <Popover
      content={i18n(
        `shiftAssign.unassign.${existing ? 'existing' : 'intended'}`,
      )}
    >
      <IconButton disabled={disabled} p={0} onClick={onClick}>
        <UnassignIcon />
      </IconButton>
    </Popover>
  )
}

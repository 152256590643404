import map from 'lodash/map'
import toString from 'lodash/toString'

import { CHAT_KINDS, CHATS_LIMIT, MESSAGES_LIMIT } from 'constants/groupChat'

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getActiveChatId } from 'store/selectors/groupChat'
import { getCompanyId, getViewer } from 'store/selectors/viewer'

export const INIT = 'groupChat/INIT'
export const TOGGLE_CHAT = 'groupChat/TOGGLE_GROUP_CHAT'
export const HOLD_CHAT_CHANNEL = 'groupChat/HOLD_CHAT_CHANNEL'
export const UNHOLD_CHAT_CHANNEL = 'groupChat/UNHOLD_CHAT_CHANNEL'

export const SEND_MESSAGE = 'groupChat/SEND_MESSAGE'
export const EDIT_MESSAGE = 'groupChat/EDIT_MESSAGE'
export const DELETE_MESSAGE = 'groupChat/DELETE_MESSAGE'
export const RECEIVED_MESSAGE = 'groupChat/RECEIVED_MESSAGE'
export const RECEIVED_DELETED_MESSAGE = 'groupChat/RECEIVED_DELETED_MESSAGE'
export const RECEIVED_CREATED_CHAT = 'groupChat/RECEIVED_CREATED_CHAT'
export const UPDATE_UNREAD_COUNTS = 'groupChat/UPDATE_UNREAD_COUNTS'
export const MARK_CHAT_LOADED = 'groupChat/MARK_CHAT_LOADED'
export const LOAD_MUTED_CHATS = 'groupChat/LOAD_MUTE_CHAT'
export const CHAT_MUTED = 'groupChat/CHAT_MUTED'
export const CHAT_UNMUTED = 'groupChat/CHAT_UNMUTED'
export const REMOVE_LOCAL_FILE = 'groupChat/REMOVE_LOCAL_FILE'
export const RECEIVED_SYSTEM_MESSAGE = 'groupChat/RECEIVED_SYSTEM_MESSAGE'
export const LEFT_CHAT = 'groupChat/LEFT_CHAT'

export const CREATE_CHAT = createAsyncAction('groupChat/CREATE_CHAT')
export const UPDATE_CHAT = createAsyncAction('groupChat/UPDATE_CHAT')
export const LOAD_USERS_CHATS = createAsyncAction('groupChat/LOAD_USER_CHATS')
export const LOAD_MORE_CHATS = createAsyncAction('groupChat/LOAD_MORE_CHATS')
export const LOAD_CHAT_MESSAGES = createAsyncAction(
  'groupChat/LOAD_CHAT_MESSAGES',
)
export const LOAD_MORE_MESSAGES = createAsyncAction(
  'groupChat/LOAD_MORE_MESSAGES',
)
export const ADD_USERS_TO_CHAT = createAsyncAction(
  'groupChat/ADD_USERS_TO_CHAT',
)
export const DELETE_USERS_FROM_CHAT = createAsyncAction(
  'groupChat/DELETE_USERS_FROM_CHAT',
)
export const LEAVE_GROUP_CHAT = createAsyncAction('groupChat/LEAVE_GROUP_CHAT')
export const UPLOAD_FILE = createAsyncAction('groupChat/UPLOAD_FILE')
export const MARK_CHAT_READED = createAsyncAction('groupChat/MARK_CHAT_READED')
export const LOAD_UNREAD_COUNTS = createAsyncAction(
  'groupChat/LOAD_UNREAD_COUNTS',
)
export const CLEAR_HISTORY = createAsyncAction('groupChat/CLEAR_HISTORY')

export const MUTE_CHAT = createAsyncAction('groupChat/MUTE_CHAT')
export const UNMUTE_CHAT = createAsyncAction('groupChat/UNMUTE_CHAT')
export const DELETE_CHAT = createAsyncAction('groupChat/DELETE_CHAT')

const chatsIncludes = [
  'message',
  'users',
  'users.profile.profileAvatar',
  'message.file',
]

const messagesIncludes = [
  'file',
  'chat.users.profile',
  'user.profile.profileAvatar',
]

export const init = () => ({ type: INIT })

export const toggleChat = chatId => (dispatch, getState) => {
  const prevChatId = getActiveChatId(getState())
  dispatch({
    type: TOGGLE_CHAT,
    payload: { chatId, unholdPrevChannel: !!prevChatId },
  })
}

export const unholdChatChannel = () => ({
  type: UNHOLD_CHAT_CHANNEL,
})

export const holdChatChannel = chatId => ({
  type: HOLD_CHAT_CHANNEL,
  payload: { chatId },
})

export const sendMessage = (message, fileId = null, contentType = '') => ({
  type: SEND_MESSAGE,
  payload: { message, fileId, contentType },
})

export const editMessage = (messageId, message) => ({
  type: EDIT_MESSAGE,
  payload: { messageId: toString(messageId), message },
})

export const deleteMessage = messageId => ({
  type: DELETE_MESSAGE,
  payload: { messageId },
})

export const receiveMessage = (message, chatId, isMessageUpdated) => ({
  type: RECEIVED_MESSAGE,
  payload: { data: { message, chatId, isMessageUpdated } },
})

export const receiveDeletedMessage = messageId => ({
  type: RECEIVED_DELETED_MESSAGE,
  payload: { deletedId: messageId, data: { deletedId: messageId } },
})

export const receiveCreatedChat = chat => ({
  type: RECEIVED_CREATED_CHAT,
  payload: { data: { chat } },
})

export const removeLocalFile = fileId => ({
  payload: {
    fileId,
  },
  type: REMOVE_LOCAL_FILE,
})

export const updateUnreadCounts = unreadCount => ({
  type: UPDATE_UNREAD_COUNTS,
  payload: { unreadCount },
})

export const loadMutedChats = mutedChatsIds => ({
  type: LOAD_MUTED_CHATS,
  payload: { mutedChatsIds },
})

export const chatMuted = chatId => ({
  type: CHAT_MUTED,
  payload: { chatId },
})

export const chatUnmuted = chatId => ({
  type: CHAT_UNMUTED,
  payload: { chatId },
})

export const markChatLoaded = chatId => ({
  type: MARK_CHAT_LOADED,
  payload: { chatId },
})

// Update users list in chat handler
export const receiveSystemMessage = message => ({
  type: RECEIVED_SYSTEM_MESSAGE,
  payload: { data: { message } },
})

// The same as leaveGroupChat but without apiCall
export const leftChat = (chatId, viewerId) => ({
  type: LEFT_CHAT,
  payload: {
    relationId: chatId,
    deletedId: viewerId,
    data: {},
  },
})

export const createChat = (name, usersIds) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())
  const data = map(usersIds, id => ({ id, type: 'users' }))
  const isPrivate = usersIds.length === 1
  const chatName = name && !isPrivate ? { name } : {}
  return dispatch(
    apiCall({
      endpoint: '/chats',
      method: 'POST',
      query: {
        data: {
          type: 'chats',
          attributes: {
            ...chatName,
            kind: isPrivate ? CHAT_KINDS.private : CHAT_KINDS.group,
          },
          relationships: {
            users: { data },
            company: { data: { id: companyId, type: 'companies' } },
          },
        },
      },
      types: CREATE_CHAT,
      isChatUrl: true,
    }),
  )
}

export const updateChat = name => (dispatch, getState) =>
  dispatch(
    apiCall({
      endpoint: `/chats/${getActiveChatId(getState())}`,
      types: UPDATE_CHAT,
      method: 'PATCH',
      query: {
        data: {
          type: 'chats',
          attributes: {
            name,
          },
        },
      },
      isChatUrl: true,
    }),
  )

export const loadChats = (limit = CHATS_LIMIT) =>
  apiCall({
    endpoint: '/user/chats',
    types: LOAD_USERS_CHATS,
    query: {
      'page[limit]': limit,
      include: chatsIncludes.join(),
    },
    isChatUrl: true,
  })

export const loadMoreChats = pageLastId =>
  apiCall({
    endpoint: '/user/chats',
    types: LOAD_MORE_CHATS,
    query: {
      'page[last_id]': pageLastId,
      'page[limit]': CHATS_LIMIT,
      include: chatsIncludes.join(),
    },
    isChatUrl: true,
  })

export const loadChatMessages = (pageLimit = MESSAGES_LIMIT) => (
  dispatch,
  getState,
) =>
  dispatch(
    apiCall({
      endpoint: `/chats/${getActiveChatId(getState())}/messages`,
      types: LOAD_CHAT_MESSAGES,
      query: {
        'page[limit]': pageLimit,
        include: messagesIncludes.join(),
      },
      isChatUrl: true,
    }),
  )

export const loadMoreMessages = lastMessageId => (dispatch, getState) =>
  dispatch(
    apiCall({
      endpoint: `/chats/${getActiveChatId(getState())}/messages`,
      types: LOAD_MORE_MESSAGES,
      query: {
        'page[last_id]': lastMessageId,
        'page[limit]': MESSAGES_LIMIT,
        include: messagesIncludes.join(),
      },
      isChatUrl: true,
    }),
  )

export const loadUnreadCounts = () =>
  apiCall({
    endpoint: '/user/relationships/unread_counts',
    types: LOAD_UNREAD_COUNTS,
    isRaw: true,
  })

export const markChatReaded = () => (dispatch, getState) => {
  const chatId = getActiveChatId(getState())

  return dispatch(
    apiCall({
      endpoint: `/chats/${chatId}/messages/read`,
      method: 'POST',
      types: MARK_CHAT_READED,
      isChatUrl: true,
      payload: {
        readedChatId: chatId,
      },
    }),
  )
}

export const clearChatHistory = () => (dispatch, getState) => {
  const chatId = getActiveChatId(getState())
  return dispatch(
    apiCall({
      endpoint: `/chats/${chatId}/messages/clear`,
      method: 'POST',
      types: CLEAR_HISTORY,
      isChatUrl: true,
      payload: { data: { chatId } },
    }),
  )
}

export const addUsersToChat = userIds => (dispatch, getState) => {
  const chatId = getActiveChatId(getState())
  const data = map(userIds, id => ({ id, type: 'users' }))
  return dispatch(
    apiCall({
      endpoint: `/chats/${chatId}/relationships/users`,
      method: 'POST',
      types: ADD_USERS_TO_CHAT,
      query: { data },
      isChatUrl: true,
    }),
  )
}

export const removeUsersFromChat = userIds => (dispatch, getState) => {
  const chatId = getActiveChatId(getState())
  const data = map(userIds, id => ({ id }))
  return dispatch(
    apiCall({
      endpoint: `/chats/${chatId}/relationships/users`,
      method: 'DELETE',
      types: DELETE_USERS_FROM_CHAT,
      query: { data },
      isChatUrl: true,
    }),
  )
}

export const leaveGroupChat = () => (dispatch, getState) => {
  const chatId = getActiveChatId(getState())
  const { id } = getViewer(getState())
  const data = [{ id }]
  return dispatch(
    apiCall({
      endpoint: `/chats/${chatId}/relationships/users`,
      method: 'DELETE',
      types: LEAVE_GROUP_CHAT,
      query: { data },
      isChatUrl: true,
    }),
  )
}

export const uploadFile = (file, fileObject) =>
  apiCall({
    method: 'POST',
    endpoint: '/files',
    file,
    types: UPLOAD_FILE,
    payload: {
      files: {
        [fileObject.id]: fileObject,
      },
    },
  })

export const muteChat = () => (dispatch, getState) =>
  dispatch(
    apiCall({
      endpoint: `/chats/${getActiveChatId(getState())}/mute`,
      method: 'POST',
      types: MUTE_CHAT,
      isChatUrl: true,
    }),
  )

export const unmuteChat = () => (dispatch, getState) =>
  dispatch(
    apiCall({
      endpoint: `/chats/${getActiveChatId(getState())}/mute`,
      method: 'DELETE',
      types: UNMUTE_CHAT,
      isChatUrl: true,
    }),
  )

export const deleteChat = () => (dispatch, getState) => {
  const chatId = getActiveChatId(getState())
  return dispatch(
    apiCall({
      endpoint: `/chats/${chatId}`,
      method: 'DELETE',
      types: DELETE_CHAT,
      isChatUrl: true,
      payload: { deletedId: chatId },
    }),
  )
}

import { FilterType } from 'constants/pagination'

import { DateFilter } from './DateFilter'
import { RangeFilter } from './RangeFilter'
import { SearchFilter } from './SearchFilter'
import { SelectFilter } from './SelectFilter'

export const Filters = Object.freeze({
  [FilterType.Search]: SearchFilter,
  [FilterType.Date]: DateFilter,
  [FilterType.Range]: RangeFilter,
  [FilterType.Select]: SelectFilter,
})

import React from 'react'

import { Flex } from 'components/ui/__v2__/Grid'

import { toHoursDays } from 'helpers/leaves'

import { i18n } from 'i18n'

type Props = {
  days: number
  hours: number
}

export function DayHoursBooked({ days, hours }: Props) {
  return (
    <Flex>
      {i18n('timeOffModal.totalBooked', {
        amount: toHoursDays({
          days,
          hours,
        }),
      })}
    </Flex>
  )
}

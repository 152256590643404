import Builder from './builder.svg'
import Business from './business.svg'
import CancelCircle from './cancel-circle.svg'
import Cart from './Cart.svg'
import CheckRound from './check-round.svg'
import ChevronDown from './chevron-down.svg'
import ChevronUp from './chevron-up.svg'
import Cloud from './cloud.svg'
import Community from './community.svg'
import CrossRound from './cross-round.svg'
import Edit from './edit-v2.svg'
import FileText from './file-text.svg'
import File from './file-v2.svg'
import Holiday from './holiday.svg'
import Insights from './insights.svg'
import Integrations from './integrations.svg'
import LocationPoint from './location-point.svg'
import LocationSettings from './location-settings.svg'
import Management from './management.svg'
import MenuFolded from './menu-folded.svg'
import MenuUnfolded from './menu-unfolded.svg'
import Notifications from './notifications.svg'
import PlusRound from './plus-round.svg'
import Prayer from './prayer.svg'
import Settings from './settings.svg'
import Tool from './tool.svg'
import Trash from './trash-v2.svg'

export default {
  Holiday,
  Builder,
  Business,
  ChevronDown,
  ChevronUp,
  Community,
  Settings,
  LocationSettings,
  Management,
  MenuFolded,
  MenuUnfolded,
  Notifications,
  Tool,
  Edit,
  FileText,
  CrossRound,
  CheckRound,
  File,
  LocationPoint,
  PlusRound,
  Trash,
  Cloud,
  Prayer,
  Cart,
  CancelCircle,
  Integrations,
  Insights,
}

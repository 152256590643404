import { useApolloQuery } from 'API/services/Apollo'

import {
  EffectiveEmployeeGroupsQuery,
  EffectiveEmployeeGroupsQueryData,
} from './GraphQL'

export function useEffectiveEmployeeGroups(
  employeeId: string,
  effectiveDate?: string,
) {
  const { data, loading, error } = useApolloQuery<
    EffectiveEmployeeGroupsQueryData,
    Gateway.QueryEffectiveEmployeeGroupsArgs
  >(EffectiveEmployeeGroupsQuery, {
    variables: {
      employeeCoreId: Number(employeeId),
      effectiveDate: effectiveDate || undefined,
    },
    skip: !employeeId,
    fetchPolicy: 'network-only',
  })

  const effectiveEmployeeGroups = data?.effectiveEmployeeGroups || []

  return {
    effectiveEmployeeGroups,
    loading,
    error,
  }
}

import { LOG_OUT } from 'store/actions/auth'
import { shopTypes } from 'store/actions/shop'

const initialState = []

export const shop = (state = initialState, action) => {
  switch (action.type) {
    case shopTypes.ADD_PRODUCT: {
      const orderAlready = {
        ...state,
        [action.payload.id]: {
          ...action.payload,
          orderCount:
            state[action.payload.id]?.orderCount + action.payload.orderCount,
        },
      }

      if (state[action.payload.id]) {
        localStorage.setItem('orders', JSON.stringify(orderAlready))
        return orderAlready
      }

      const newOrder = {
        ...state,
        [action.payload.id]: action.payload,
      }

      localStorage.setItem('orders', JSON.stringify(newOrder))
      return newOrder
    }
    case shopTypes.REMOVE_PRODUCT: {
      const copyState = { ...state }
      delete copyState[action.payload.id]
      localStorage.setItem('orders', JSON.stringify(copyState))
      return copyState
    }
    case shopTypes.CLEAN_CART: {
      localStorage.setItem('orders', JSON.stringify({}))
      return []
    }
    case shopTypes.INCREMENT_ORDER: {
      const copyState = { ...state }
      const item = copyState[action.id]
      if (item.orderCount < item.amount) {
        copyState[action.id].orderCount += 1
        localStorage.setItem('orders', JSON.stringify(copyState))
      }
      return copyState
    }
    case shopTypes.DECREMENT_ORDER: {
      const copyState = { ...state }
      if (copyState[action.id].orderCount > 1) {
        copyState[action.id].orderCount -= 1
        localStorage.setItem('orders', JSON.stringify(copyState))
      }
      return copyState
    }
    case shopTypes.SET_CART_FROM_LOCAL_STORAGE: {
      let copyState = { ...state }
      const ordersLocalStorage = localStorage.getItem('orders')
      if (ordersLocalStorage) {
        copyState = JSON.parse(ordersLocalStorage)
      }
      return copyState
    }
    case LOG_OUT: {
      return initialState
    }
    default:
      return state
  }
}

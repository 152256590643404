import styled from 'styled-components'

export const TextTooltipCellContainer = styled.span`
  display: flex;
  width: 100%;
`

export const Text = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

export const Tooltip = styled.span`
  top: 40px;
  // TODO: get z-index from theme
  z-index: 1;
  background: #f5e1d7;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
  border-radius: 2px;
  white-space: normal;
  position: absolute;
  padding: 10px;
  right: 3px;

  &:before {
    border-bottom: 6px solid #f5e1d7;
    border-right: 8px solid transparent;
    border-top: 0 dotted;
    border-left: 8px solid transparent;
    content: '';
    position: absolute;
    top: -5px;
    right: 4px;
  }
`

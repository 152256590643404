import StyledPropTypes from '@styled-system/prop-types'

import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system'

import { either, propGet } from 'components/ui/__v3__/__utils__/styled-system'

export const Box = styled.div`
  box-sizing: border-box;
  color: ${mapToTheme('layout')};
  cursor: ${propGet('cursor', 'unset')};

  ${space};
  ${color};
  ${typography};
  ${layout};
  ${background};
  ${border};
  ${position};
  ${shadow};
  ${flexbox};
  ${grid};
`

Box.propTypes = {
  ...StyledPropTypes.space,
  ...StyledPropTypes.color,
  ...StyledPropTypes.typography,
  ...StyledPropTypes.layout,
  ...StyledPropTypes.background,
  ...StyledPropTypes.border,
  ...StyledPropTypes.position,
  ...StyledPropTypes.shadow,
  ...StyledPropTypes.grid,
  ...StyledPropTypes.flexbox,
}

export const Span = styled(Box)`
  display: ${either('inline', 'inline', 'inline-block')};
`

Span.propTypes = {
  ...Box.propTypes,
}

Span.defaultProps = {
  as: 'span',
}

export const Flex = styled(Box)``

Flex.defaultProps = {
  display: 'flex',
}

Flex.propTypes = {
  ...Box.propTypes,
}

export const Grid = styled(Box)``

Grid.defaultProps = {
  display: 'grid',
}

Grid.propTypes = {
  ...Box.propTypes,
}

import styled from 'styled-components'
import { flexbox, grid, layout, space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { gap } from 'theme/styles'

import { CommonProps } from './types'

type HeaderProps = CommonProps

export const Header = styled.div<HeaderProps>`
  width: 100%;
  min-height: 64px;
  padding: 16px 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${themeGet('layout.bg')};
  border-bottom: 1px solid ${themeGet('layout.border')};

  ${space};
  ${layout};
  ${flexbox};
  ${gap}
  ${grid};
`

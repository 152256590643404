import { useCallback, useContext } from 'react'

import { subject } from '@casl/ability'
import { createContextualCan } from '@casl/react'

import filter from 'lodash/filter'
import values from 'lodash/values'

import { getViewerEmployeeId } from 'helpers/viewer'

import AbilityContext from 'services/ACL/AbilityContext'
import { ACLSubjects, Actions, ProfileConditions } from 'services/ACL/config'

import useAppContext from './useAppContext'

export function useACL() {
  const abilities = useContext(AbilityContext)
  const { viewer } = useAppContext()

  const viewerEmployeeId = getViewerEmployeeId(viewer)

  const checkPermissionToAction = useCallback(
    ({ action, subjectItem, featureId, condition = {} }) =>
      abilities.can(
        action,
        subject(subjectItem, {
          featureId,
          ...condition,
        }),
      ),
    [abilities],
  )

  const canPerformStaffManagementAction = useCallback(
    ({ featureId, action }) => {
      const subjectItem = ACLSubjects.StaffManagementFeature

      return checkPermissionToAction({
        action,
        subjectItem,
        featureId,
      })
    },
    [checkPermissionToAction],
  )

  const canPerformTimecardManagementAction = useCallback(
    ({ featureId, action }) => {
      const subjectItem = ACLSubjects.TimecardManagementFeature

      return checkPermissionToAction({
        action,
        subjectItem,
        featureId,
      })
    },
    [checkPermissionToAction],
  )

  const canPerformPayrollManagementAction = useCallback(
    ({ featureId, action }) => {
      const subjectItem = ACLSubjects.PayrollManagementFeature

      return checkPermissionToAction({
        action,
        subjectItem,
        featureId,
      })
    },
    [checkPermissionToAction],
  )

  const canPerformTimeoffAction = useCallback(
    ({ featureId, action }) => {
      const subjectItem = ACLSubjects.TimeoffFeature

      return checkPermissionToAction({
        action,
        subjectItem,
        featureId,
      })
    },
    [checkPermissionToAction],
  )

  const canPerformBuilderAction = useCallback(
    ({ featureId, action }) => {
      const subjectItem = ACLSubjects.BuilderFeature

      return checkPermissionToAction({
        action,
        subjectItem,
        featureId,
      })
    },
    [checkPermissionToAction],
  )

  const canPerformCustomFieldsAction = useCallback(
    ({ featureId, action }) => {
      const subjectItem = ACLSubjects.CustomFieldsFeature

      return checkPermissionToAction({
        action,
        subjectItem,
        featureId,
      })
    },
    [checkPermissionToAction],
  )

  const canPerformSettingsAction = useCallback(
    ({ featureId, action }) => {
      const subjectItem = ACLSubjects.SettingsFeature

      return checkPermissionToAction({
        action,
        subjectItem,
        featureId,
      })
    },
    [checkPermissionToAction],
  )

  const canPerformTimeBucketsAction = useCallback(
    ({ featureId, action }) => {
      const subjectItem = ACLSubjects.TimeBucketFeature

      return checkPermissionToAction({
        action,
        subjectItem,
        featureId,
      })
    },
    [checkPermissionToAction],
  )

  const canPerformProfileAction = useCallback(
    ({ featureId, action, isOwnProfile = false }) => {
      const subjectItem = ACLSubjects.ProfileFeature
      const condition = {
        [isOwnProfile
          ? ProfileConditions.OwnProfile
          : ProfileConditions.OthersProfile]: true,
      }

      return checkPermissionToAction({
        action,
        subjectItem,
        featureId,
        condition,
      })
    },
    [checkPermissionToAction],
  )

  const canPerformWeeklyTimesheetsManagementAction = useCallback(
    ({ featureId, action }) => {
      const subjectItem = ACLSubjects.WeeklyTimesheetsManagementFeature

      return checkPermissionToAction({
        action,
        subjectItem,
        featureId,
      })
    },
    [checkPermissionToAction],
  )

  // @ts-ignore // FIXME:
  function getAllAvailableActions(
    params: $TSFixMe,
    method: (args: $TSFixMe) => boolean,
  ): Actions[] {
    return filter(values(Actions), action => method({ ...params, action }))
  }

  const Can = createContextualCan(AbilityContext.Consumer)
  return {
    canPerformStaffManagementAction,
    canPerformTimecardManagementAction,
    canPerformPayrollManagementAction,
    canPerformTimeoffAction,
    canPerformCustomFieldsAction,
    canPerformSettingsAction,
    canPerformTimeBucketsAction,
    canPerformBuilderAction,
    checkPermissionToAction,
    getAllAvailableActions,
    canPerformProfileAction,
    canPerformWeeklyTimesheetsManagementAction,
    Can,
    abilities,
    viewerEmployeeId,
  }
}

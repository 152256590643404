import styled from 'styled-components'

import SvgIcons from 'assets/images/__v2__/svg'

import { SvgIcon } from 'components/ui/__v2__/SvgIcon'

const Tool = styled(SvgIcon).attrs({
  glyph: SvgIcons.Tool,
})``

Tool.defaultProps = {
  size: 12,
}

export default Tool

import { DateTime } from 'luxon'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { ACCESS_LEVEL } from 'constants/ids'

import { buildFullName } from 'helpers/employee'

import _ from 'i18n'

export function getWageOptions() {
  return [
    {
      value: 'HOUR',
      label: _('people.perHour'),
    },
    {
      value: 'YEAR',
      label: _('people.perYear'),
    },
  ]
}

export const EMPLOYEE_STATE_FILTERS = {
  archived: 'archived',
  active: 'active',
  all: 'all',
  deleted: 'deleted',
  story: 'story',
}

export const getManagersRole = (accessLevel, departments) => {
  if (accessLevel === ACCESS_LEVEL.admin) {
    return _('roles.admin')
  }
  if (accessLevel === ACCESS_LEVEL.manager) {
    if (isEmpty(departments)) {
      return _('roles.branchManager')
    }
    return _('roles.departmentManager')
  }
  return _('roles.owner')
}

export const buildArchiveHoverMessage = employee => {
  const fullName = buildFullName(employee)
  const { archivingReason, stateChangedAt } = employee
  const archivedByProfile = get(employee, 'stateChangedByManager.user.profile')
  const archivedByFirstName = get(archivedByProfile, 'firstName')
  const archivedByLastName = get(archivedByProfile, 'lastName')
  const changerAccessLevel = get(employee, 'stateChangedByManager.accessLevel')
  const changerDepartments = get(employee, 'stateChangedByManager.departments')
  const archivedByRole = getManagersRole(changerAccessLevel, changerDepartments)
  const changerFullName = `${archivedByFirstName} ${archivedByLastName} (${archivedByRole})`

  return getArchiveEmployeeToolTip(
    fullName,
    stateChangedAt,
    archivingReason,
    changerFullName,
  )
}

export const getArchiveEmployeeToolTip = (name, date, reason = '', by = '') => {
  const reasonView = reason ? ` [Reason: ${reason}]` : ''
  const byView = by ? ` by ${by}` : ''
  const dateView = date ? DateTime.fromISO(date).toFormat('MM/dd/yyyy') : ''
  return _('employees.hasBeenArchived', {
    name,
    reason: reasonView,
    date: dateView,
    by: byView,
  })
}

export const getActiveJobsEmployees = jobsEmployees =>
  filter(jobsEmployees, item => item.state === 'active')

export const INVITE_ERROR_ID = 'userAlreadyInvited'
export const INVITE_STORAGE_KEY = 'alreadyInvitedUserIdAndAccess'

export const buildAlreadyInvitedErrorMessage = (
  accessLevel,
  locations = [],
) => {
  if (!accessLevel) {
    return ''
  }
  if (
    accessLevel === ACCESS_LEVEL.admin ||
    accessLevel === ACCESS_LEVEL.owner
  ) {
    return _('common.theUserIsAlreadyAn', { accessLevel })
  }
  const article = accessLevel.indexOf('Manager') !== -1 ? 'a ' : 'an '
  const translatedAccessLevel = _(`roles.${accessLevel}`)
  return _('common.theUserAlreadyExistAndIs', {
    accessLevel: `${article}${translatedAccessLevel}`,
    location: isEmpty(locations) ? '' : locations.join(','),
  })
}

export const WAGE_MASK = createNumberMask({
  prefix: '',
  suffix: '',
  allowDecimal: true,
  decimalLimit: 2,
  integerLimit: 7,
  includeThousandsSeparator: false,
})

export const EMPLOYEES_PAGE_SIZE = 10
export const SHIFTS_PAGE_SIZE = EMPLOYEES_PAGE_SIZE * 5

import React from 'react'

import { useTheme } from 'styled-components'

import { SchedulesShiftAssignmentsAPIItem } from 'API/Schedule/GraphQL'

import { TextOverflowWithPopover } from 'components/blocks/__v3__'
import { Checkbox } from 'components/ui/__v2__/Checkbox'
import { Flex, Span } from 'components/ui/__v2__/Grid'
import { Avatar } from 'components/ui/Avatar'

import Utils from 'services/Utils'

import { BodyCell } from './styles'

type Props = {
  checked: boolean
  disabled: boolean
  data: SchedulesShiftAssignmentsAPIItem
  toggleSelection: () => void
}

export function TableItem({ checked, disabled, data, toggleSelection }: Props) {
  const theme = useTheme()

  return (
    <>
      <BodyCell width={30}>
        <Checkbox
          // @ts-ignore
          checked={checked}
          disabled={disabled}
          size={18}
          wrapper={{ mb: '-5px' }}
          onChange={toggleSelection}
        />
      </BodyCell>

      <BodyCell width={290}>
        <Flex alignItems="center" gap={4} height="100%" width="100%">
          <Avatar
            // @ts-ignore
            size={50}
            src={data.employee.user.profile.avatar?.small.url}
          />

          <Flex flexDirection="column" minWidth={0} width="100%">
            <TextOverflowWithPopover fontWeight={2}>
              {Utils.User.buildFullName(data.employee.user.profile)}
            </TextOverflowWithPopover>

            {data.employee.code && (
              <Span
                color={theme.colors.CHARCOAL_80}
                fontSize={0}
                fontWeight="bold"
              >
                {data.employee.code}
              </Span>
            )}
          </Flex>
        </Flex>
      </BodyCell>

      <BodyCell>
        <Span>
          {data.department.name} / {data.job.name}
        </Span>
      </BodyCell>
    </>
  )
}

import { FieldArrayWithId } from 'react-hook-form'

import { TimeEntryReturn } from 'API/TimeCard/GraphQL'
import { DateTime } from 'luxon'
import { Branch, Department, Job, JobEmployee } from 'Types/app'
import { SelectOption } from 'Types/common'

import { TimeEntryState } from 'constants/gatewayGraphQL'

import { TimeBucketChildrenOption } from '../TimeBucketComponents/TimeBucketChildrenPicker/types'

type CommonProps = {
  days: DateTime[]
  employeeId: number
  employeeJobs: JobEmployee[]
}

type EmployeeProps = {
  employeeName?: never
} & CommonProps

type ManagerProps = {
  employeeName: string
} & CommonProps

export type Props = EmployeeProps | ManagerProps

//  ==========================================================================================

export type TimesheetFormState = {
  entries: Array<FormTimesheetRecord>
}
type CommonTimeEntryFields = {
  startDate: DateTime
  startSeconds: number
  endSeconds: number
  position: PositionOption | null
  earningType: SelectOption | null
  activityDetails?: string
  // Not form fields but used to adjust rendering
  employeeId: number
  timeBucketChild?: TimeBucketChildrenOption | null
}
export type ExistingTimesheetRecord = {
  id: number
  // Not form fields but used to adjust rendering
  state: TimeEntryState
  position: PositionOption
  submittedAt: string
} & CommonTimeEntryFields

export type DraftTimesheetRecord = {
  id?: never
  state?: never
  submittedAt?: string
  position: PositionOption
} & CommonTimeEntryFields

export type FormTimesheetRecord = ExistingTimesheetRecord | DraftTimesheetRecord

export type FieldArrayItem = FieldArrayWithId<
  TimesheetFormState,
  'entries',
  'formId'
>

export type FieldArrayItemWithInitialIndex = FieldArrayItem & { index: number }

//  ==========================================================================================

export type PositionOption = SelectOption & {
  data: {
    location: {
      id: string
      name: string
      timezone: string
    }
    department: { id: string; name: string }
    job: { id: string; name: string }
  }
}

export type PositionData = {
  location: TimeEntryReturn['location'] | Branch
  department: TimeEntryReturn['department'] | Department
  job: TimeEntryReturn['job'] | Job
}

// ==========================================================================================

export enum TimesheetState {
  Draft = 'Draft',
  Unsubmitted = 'Unsubmitted',
  Pending = 'Pending',
  Approved = 'Approved',
  Discarded = 'Discarded',
}

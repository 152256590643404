import React from 'react'
import { ArrayPath, Control, FieldValues, useFieldArray } from 'react-hook-form'

import { DateTime } from 'luxon'

import { Flex } from 'components/ui/__v2__/Grid'
import { FormCheckbox } from 'components/ui/__v3__/HookForm'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import { DateContainer } from './styles'

export type DatesListItem = {
  label: string
  value: boolean
  disabled: boolean
}

type Props<T extends FieldValues> = {
  name: ArrayPath<T>
  control: Control<T>
} & LabelProps

export function FormSelectedDatesList<T extends FieldValues>({
  control,
  name,

  labelText,
  required,
}: Props<T>) {
  const { fields } = useFieldArray({
    control,
    name,
  })

  return (
    <Flex flexDirection="column">
      {labelText && (
        <InputLabel>
          <LabelText labelText={labelText} required={required} />
        </InputLabel>
      )}
      <DateContainer>
        {fields.map((field, index) => {
          return (
            <FormCheckbox
              control={control}
              // @ts-ignore
              disabled={field.disabled}
              key={field.id}
              labelPlacement="right"
              // @ts-ignore
              labelText={DateTime.fromISO(field.label)
                .setLocale(navigator.language)
                .toLocaleString()}
              // @ts-ignore
              name={`${name}.${index}.value`}
              size={14}
              typography={{ fontSize: '12px' }}
              wrapper={{ marginLeft: 1 }}
            />
          )
        })}
      </DateContainer>
    </Flex>
  )
}

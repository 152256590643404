import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Row = styled.div`
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 160px;
  width: 100%;
  overflow-y: auto;
  border: 1px solid ${themeGet('colors.ALTO')};
  padding: 16px 16px 16px 24px;
  margin-bottom: 23px;
  margin-top: 18px;

  ${Row}:last-child {
    margin-bottom: 0;
  }
`

export const Name = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.67;
`

export const Role = styled.div`
  font-size: 8px;
  line-height: 1.67;
`

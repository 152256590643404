import React from 'react'

import styled from 'styled-components'

import isFunction from 'lodash/isFunction'

const Hidden = styled.div`
  visibility: hidden;
  pointer-events: none;
`

export default function extendColumnsBySearchAndActions(props) {
  const {
    searchable,
    columns,
    actionable,
    actionComponent: ActionComponent,
  } = props

  function mapColumnToSearch(column, index) {
    return {
      ...column,

      selector: row => {
        if (row.search) {
          return row.cell(column, index)
        }

        return column.selector(row)
      },
    }
  }

  const action = {
    cell: column => {
      if (isFunction(column.cell)) {
        return (
          <Hidden>
            <ActionComponent cell={column} />
          </Hidden>
        )
      }
      return <ActionComponent cell={column} />
    },
    center: true,
    grow: 0,
  }

  return [
    ...(searchable ? columns.map(mapColumnToSearch) : columns),
    ...(actionable ? [action] : []),
  ]
}

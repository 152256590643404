import styled from 'styled-components'

export const LoadMoreContainer = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.space[1]}px;
  align-items: center;
  justify-content: end;
  color: ${({ theme }) => theme.layout.darkBorder};
`

export const LoadMoreHandler = styled.span`
  cursor: pointer;
`

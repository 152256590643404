import { TimeoffAPIItem } from 'API'

import {
  canEdit,
  canModifyWithinThreshold,
  CanModifyWithinThresholdProps,
} from './helpers'

import useAppContext from '../useAppContext'

export function useTimeoffPermissions() {
  const {
    viewer,
    company: {
      settings: { timeoffSettings },
    },
  } = useAppContext()

  const canModifyTimeoffWithinThreshold = (
    props: Omit<
      CanModifyWithinThresholdProps,
      'thresholdSettings' | 'isAdminOrOwner'
    >,
  ) =>
    canModifyWithinThreshold({
      ...props,
      thresholdSettings: timeoffSettings?.updateThreshold,
      isAdminOrOwner: viewer.role.adminOrOwner,
    })

  const canEditTimeoff = (timeoff: TimeoffAPIItem) =>
    canEdit({
      timeoff,
      thresholdSettings: timeoffSettings?.updateThreshold,
      isAdminOrOwner: viewer.role.adminOrOwner,
    })

  return {
    canModifyTimeoffWithinThreshold,
    canEditTimeoff,
  }
}

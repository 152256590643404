import React, { useCallback } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { PropTypes } from 'prop-types'

import get from 'lodash/get'
import includes from 'lodash/includes'

import { Checkbox, CheckboxLabel } from 'components/ui/__v2__/Checkbox'
import Helper from 'components/ui/__v2__/Checkbox/Helper'
import { ValidationError } from 'components/ui/__v2__/Form/styles'
import { IconError } from 'components/ui/__v3__/Input/Errors'
import { Flex, Span } from 'components/ui/Grid'

import { Answer } from '../styles'

function CheckboxGroup({
  disabled,
  name,
  isRequired,
  isReadOnly,
  onChange,
  label,
  options,
  ...rest
}) {
  const { change } = useForm()
  const { values } = useFormState()

  /**
   * TODO: Rework this component
   */
  const handleChange = useCallback(
    event => {
      const clickedOptionId = event.target.value
      const currentlySelected = get(values, name, [])
      const newSelection = includes(currentlySelected, clickedOptionId)
        ? currentlySelected.filter(optionId => optionId !== clickedOptionId)
        : [...currentlySelected, clickedOptionId]
      change(name, newSelection)
    },
    [change, name, values],
  )

  return (
    <Flex flexDirection="column">
      <Span>
        {label}
        {isRequired && <sup>*</sup>}
      </Span>

      <Field name={name} onBlur={onChange}>
        {props => (
          <>
            {options.map(option => (
              <CheckboxLabel key={option.value} mt={2}>
                <>
                  <Checkbox
                    {...props}
                    {...rest}
                    checked={
                      isReadOnly
                        ? includes(props.input.value, option.value)
                        : props.checked
                    }
                    disabled={disabled || isReadOnly}
                    size={18}
                    value={option.value}
                    onChange={handleChange}
                  />
                  {isReadOnly && includes(props.input.value, option.value) ? (
                    <Answer>{option.label}</Answer>
                  ) : (
                    <Span>{option.label}</Span>
                  )}
                </>
              </CheckboxLabel>
            ))}
            <Helper>
              {props.meta.error && props.meta.touched && (
                <ValidationError>
                  <IconError>{props.meta.error}</IconError>
                </ValidationError>
              )}
            </Helper>
          </>
        )}
      </Field>
    </Flex>
  )
}

CheckboxGroup.defaultProps = {
  disabled: false,
  isReadOnly: false,
}

CheckboxGroup.propTypes = {
  ...Field.propTypes,
  disabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  options: PropTypes.array.isRequired,
}

export default CheckboxGroup

import normalize from 'json-api-normalizer'
import Pusher from 'pusher-js'
import { eventChannel } from 'redux-saga'
import {
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects'

import forEach from 'lodash/forEach'

import { api, pusher } from 'constants/config'

import * as cookiesHelpers from 'helpers/cookies'

import { AuthService } from 'services/Auth'

import { COMPLETE_REFETCH } from 'store/actions/app'
import { LOG_OUT } from 'store/actions/auth'
import { loadSchedules } from 'store/actions/employees/schedules'
import { loadShifts } from 'store/actions/employees/shifts'
import { receiveDeletedPost, receivePost } from 'store/actions/news'
import { getWeek } from 'store/selectors/employees/schedules'
import { getBranchId } from 'store/selectors/viewer'

const EVENTS = {
  postCreated: 'post-created',
  postUpdated: 'post-updated',
  postDeleted: 'post-deleted',
  schedulesCreated: 'schedules-created',
  schedulesUpdated: 'schedules-updated',
  schedulesDestroyed: 'schedules-destroyed',
  absenceRejected: 'absence-rejected',
  archiveEmployee: 'archive-employee',
}

function createEventChannel(channel) {
  return eventChannel(emitter => {
    forEach(EVENTS, event => {
      channel.bind(event, data => {
        emitter({ event, data })
      })
    })
    return () => channel.unbind()
  })
}

function createSocket(headers) {
  return new Pusher(pusher.token, {
    authEndpoint: `${api?.rubyUrl}/sockets/auth`,
    encrypted: true,
    cluster: 'mt1',
    auth: {
      headers: {
        'Content-Type': 'application/vnd.api+json',
        ...headers,
      },
    },
  })
}

function* listenBranch() {
  const branchId = yield select(getBranchId)
  if (branchId) {
    const channelName = `private-branch.${branchId}`
    const headers = yield AuthService.getAuthHeaders()
    const socket = yield call(createSocket, headers)
    const channel = socket.subscribe(channelName)

    const socketChannel = yield call(createEventChannel, channel)

    try {
      while (true) {
        const { event, data } = yield take(socketChannel)
        if (data) {
          const normalized = normalize(data, { camelizeKeys: false })
          switch (event) {
            case EVENTS.archiveEmployee:
              cookiesHelpers.remove('CURRENT_BRANCH')
              window.location.reload()
              break
            case EVENTS.postDeleted:
              yield put(receiveDeletedPost({ data: normalized }))
              break
            case EVENTS.postCreated:
            case EVENTS.postUpdated:
              yield put(receivePost({ data: normalized }))
              break
            case EVENTS.schedulesCreated:
            case EVENTS.schedulesDestroyed:
            case EVENTS.schedulesUpdated:
            case EVENTS.absenceRejected: {
              const week = yield select(getWeek)
              yield put(loadSchedules(week.start, week.end))
              if (event === EVENTS.schedulesDestroyed) {
                yield put(loadShifts(week.start, week.end))
              }
              break
            }
            default:
              break
          }
        }
      }
    } finally {
      if (yield cancelled()) {
        socket.disconnect()
      }
    }
  }
}

export default function* root() {
  while (yield take(COMPLETE_REFETCH)) {
    const branchTask = yield fork(listenBranch)
    yield take(LOG_OUT)
    yield cancel(branchTask)
  }
}

import { DateTime } from 'luxon'

import isNil from 'lodash/isNil'

import { DatesListItem } from 'components/blocks/__v3__/HookForm/FormSelectedDatesList'

import { excludedDates, partialInputsToDateTimes } from '../../../helpers'

type FormData = {
  customLeaveDayId?: string
  employeeId?: string
  dates: DatesListItem[]
  date?: string
  startTime?: number
  endTime?: number
  partial: boolean
  period?: {
    from: string
    to: string
  }
}

export function mapFormDataToServerData({
  customLeaveDayId,
  employeeId,
  dates,
  date,
  startTime,
  endTime,
  partial,
  period,
}: FormData): Gateway.EstimateTimeoffBalanceImpactInput | null {
  if (!customLeaveDayId || !employeeId) return null

  const payload = {
    customLeaveDayId,
    employeeId,
    partial,
  }

  if (partial) {
    if (!date || isNil(startTime) || isNil(endTime)) return null

    const { startDatetime, endDatetime } = partialInputsToDateTimes(
      date,
      startTime,
      endTime,
    )

    return {
      ...payload,
      timeRange: {
        partialRangeInput: {
          start: startDatetime.toUTC().toISO(),
          end: endDatetime.toUTC().toISO(),
        },
      },
      excludedDates: [],
    }
  }

  if (!period) return null

  return {
    ...payload,
    timeRange: {
      wholeDayInput: {
        start: DateTime.fromISO(period.from).toISODate(),
        end: DateTime.fromISO(period.to).toISODate(),
      },
    },
    excludedDates: dates.filter(excludedDates).map(dateItem => dateItem.label),
  }
}

import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import first from 'lodash/first'
import get from 'lodash/get'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { Container, Element, Toggle } from './styles'

function SwitchTabs({ titles, value, light, onChange }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const refs = map(titles, () => useRef([]))
  const [stateValue, setStateValue] = useState(null)

  // this crutches here because we don`t have ref on first
  // render (and set new ref don`t call re-render), so we don`t know clientWidth.
  // Solution is to make re-render happens after first render (also could be done
  // with something like forceUpdate()
  useEffect(() => {
    let result = value

    if (result >= titles.length) {
      result = 0
    }
    setStateValue(result)
  }, [value, titles])

  const handleChange = useCallback(() => {
    let result = stateValue + 1

    if (result >= titles.length) {
      result = 0
    }

    onChange(result)
  }, [titles.length, stateValue, onChange])

  return (
    <Container light={light} p={2} onClick={handleChange}>
      <Toggle
        height={get(first(refs), ['current', 'clientHeight'], 'auto')}
        left={get(refs, [stateValue, 'current', 'offsetLeft'])}
        type="button"
        width={get(first(refs), ['current', 'clientWidth'], 'auto')}
      >
        {get(titles, stateValue)}
      </Toggle>
      {map(titles, (title, index) => (
        <Element key={`${title}+${index}`} ref={get(refs, index)}>
          {title}
        </Element>
      ))}
    </Container>
  )
}

SwitchTabs.propTypes = {
  light: PropTypes.bool,
  titles: PropTypes.array.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
}

SwitchTabs.defaultProps = {
  light: true,
  onChange: noop,
}

export default SwitchTabs

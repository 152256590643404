import { useMemo, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import {
  EmploymentTypesByCursorQuery,
  EmploymentTypesByCursorQueryData,
  WithWorkflowsFlag,
} from './GraphQL'

type Props = {
  paging: CursorPage
  filter?: Gateway.EmploymentTypesFilter
  sorting?: Gateway.EmploymentTypesSorting
}

export function useEmploymentTypesByCursor({ paging, filter, sorting }: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const withWorkflows = false as const

  const { data, loading, refetch, fetchMore } = useApolloQuery<
    EmploymentTypesByCursorQueryData<typeof withWorkflows>,
    Gateway.QueryEmploymentTypesByCursorArgs & WithWorkflowsFlag
  >(EmploymentTypesByCursorQuery, {
    fetchPolicy: 'network-only',
    variables: {
      sorting,
      filter,
      paging: cursorPageToGraphqlPaging(paging),
      withWorkflows,
    },
    onCompleted(data) {
      const newPageMeta = data.employmentTypesByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const employmentTypes = useMemo(
    () => data?.employmentTypesByCursor?.edges.map(edge => edge.node) ?? [],
    [data],
  )

  const loadMore = async () => {
    await fetchMore({
      variables: {
        paging: {
          limit: paging.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })
  }

  return {
    employmentTypes,
    loading,
    currentPageMeta,
    refetch,
    loadMore,
  }
}

import React from 'react'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import _ from 'i18n'

import { Spinner } from '../Spinner'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // TODO: get z-index from theme
  z-index: 999999;
  background: ${themeGet('colors.OVERLAY')};
`

const Panel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 180px;
  height: 180px;
  background: white;
`

export const BranchSwitching = () => (
  <Container>
    <Panel>
      <Spinner mb={20} size={40} />
      {_('common.switchingBranch')}
    </Panel>
  </Container>
)

export default BranchSwitching

import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import { CreateGroupMutation, GroupsByCursorQuery } from './GraphQL'

export function useCreateGroup() {
  const [innerCreate, { loading: creating }] = useApolloMutation<
    MutationData<'createGroup'>,
    Gateway.MutationCreateGroupArgs
  >(CreateGroupMutation, {
    translationKey: 'settings.groups.toasts.createGroup',
    refetchQueries: compact([getOperationName(GroupsByCursorQuery)]),
  })

  const createGroup = async (params: Gateway.CreateGroupInput) => {
    return innerCreate({
      variables: {
        input: params,
      },
    })
  }

  return { createGroup, creating }
}

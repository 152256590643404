import React from 'react'

import { FiEdit as ModifyIcon } from 'react-icons/fi'

import { ButtonProps, IconButton } from 'components/ui/__v3__/Button'

type Props = {
  onModify: () => void
} & ButtonProps

export function Modify({ onModify, ...rest }: Props) {
  return (
    <IconButton {...rest} secondary onClick={onModify}>
      <ModifyIcon />
    </IconButton>
  )
}

import styled from 'styled-components'

import SvgIcons from 'assets/images/__v2__/svg'

import { SvgIcon } from 'components/ui/__v2__/SvgIcon'

const CheckRound = styled(SvgIcon).attrs({
  glyph: SvgIcons.CheckRound,
})``

CheckRound.defaultProps = {
  size: 14,
}

export default CheckRound

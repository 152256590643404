import React from 'react'
import { Control } from 'react-hook-form'

import { FormCustomCvFieldsWithValues } from 'components/blocks/__v3__/HookForm/FormCustomCvFieldsWithValues'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { Container } from './styles'

import { CandidatesFilterFormState } from '../../types'

type Props = {
  control: Control<CandidatesFilterFormState>
}

export function FilterForm({ control }: Props) {
  const t = useI18n<typeof TRANSLATIONS.shiftAssign.candidates.form>(
    'shiftAssign.candidates.form',
  )

  return (
    <form>
      <Container>
        <FormCustomCvFieldsWithValues
          addButtonIsSecondary
          canDeleteLast
          control={control}
          labelText={t('customCvFields')}
          maxValuesCount={5}
          name="customCvFields"
        />
      </Container>
    </form>
  )
}

import { useEvaluatedTimeMicropayExport } from './useEvaluatedTimeMicropayExport'
import { useEvaluatedTimesAggregatedByEmployeeByCursor } from './useEvaluatedTimesAggregatedByEmployeeByCursor'
import { useEvaluatedTimesByCursor } from './useEvaluatedTimesByCursor'
import { useEvaluateTime } from './useEvaluateTime'
import { useExportEvaluatedTime } from './useExportEvaluatedTime'
import { useStartEvaluatedTimeSync } from './useStartEvaluatedTimeSync'
import { useTestEvaluateTime } from './useTestEvaluateTime'
import { useTimeEvaluationWorkflowStatus } from './useTimeEvaluationWorkflowStatus'

export const TimeEvaluationAPI = {
  byCursor: useEvaluatedTimesByCursor,
  aggregatedByEmployeeByCursor: useEvaluatedTimesAggregatedByEmployeeByCursor,
  evaluate: useEvaluateTime,
  testEvaluate: useTestEvaluateTime,
  workflowStatus: useTimeEvaluationWorkflowStatus,
  extract: useExportEvaluatedTime,
  extractMicropay: useEvaluatedTimeMicropayExport,
  startSync: useStartEvaluatedTimeSync,
  testEvaluateTime: useTestEvaluateTime,
}

import { cursorPageToJsonApiPage } from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { LeaveDurationTypes, TimeoffStates } from 'constants/ids'

import coreApiCall from 'services/API/coreApi'
import Utils from 'services/Utils'

import { TimeoffsFilter, TimeoffSorting } from '../../types'

interface TimeoffsQueryArgs {
  companyId: string
  include?: string
  sort: TimeoffSorting
  filter?: TimeoffsFilter
  page: CursorPage
}

export function timeoffsQuery({
  companyId,
  filter,
  sort,
  page,
  include,
}: TimeoffsQueryArgs): Promise<JsonApi.Response> {
  const query: JsonApi.TimeoffsArgs = {
    page: cursorPageToJsonApiPage(page),
    filter: {
      ...(filter?.areas && Utils.JsonApi.areasFilter(filter.areas)),

      ...(filter?.leaveType && {
        partial: Utils.JsonApi.eqFilter(
          filter?.leaveType === LeaveDurationTypes.Partial,
        ),
      }),

      ...(filter?.state &&
        filter?.state !== TimeoffStates.All && {
          state: Utils.JsonApi.eqFilter(filter.state),
        }),

      ...(filter?.customLeaves?.length && {
        custom_leave_day_id: Utils.JsonApi.inFilter(
          filter.customLeaves.map(({ value }) => value),
        ),
      }),
    },
    sort: Utils.JsonApi.sortingDirectionToJsonAPI(sort.direction, sort.field),
    include,
  }

  return coreApiCall({
    endpoint: `/companies/${companyId}/timeoffs`,
    method: 'GET',
    query,
    paged: true,
  })
}

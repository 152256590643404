import { useState } from 'react'

import { i18n } from 'i18n'

import coreApiCall from 'services/API/coreApi'
import { showCoreAPIErrorToast, showToast } from 'services/Toasts'

import { LaunchAutoAssignParams } from './types'

export function useLaunchAutoAssignTask() {
  const [loading, setLoading] = useState(false)

  const launchAutoAssingnTask = async (
    companyId: string,
    params: LaunchAutoAssignParams,
  ) => {
    setLoading(true)
    const response = await coreApiCall({
      endpoint: `/companies/${companyId}/auto_scheduling_tasks`,
      method: 'POST',
      query: {
        data: { ...params },
      },
    })
    if (response.ok) {
      showToast({
        type: 'success',
        title: i18n('autoAssign.title'),
        content: i18n('alertMessages.default.success.message'),
      })
      const responseObject = response?.payload?.data.autoSchedulingTasks
      // @ts-ignore
      const taskId = Object.values(responseObject)?.[0]?.id as string
      setLoading(false)
      return taskId
    }

    setLoading(false)
    showCoreAPIErrorToast({ response, title: i18n('autoAssign.title') })
    return null
  }

  return {
    loading,
    launchAutoAssingnTask,
  }
}

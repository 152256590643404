import { createLoadHandler } from 'redux-json-api-handlers'
import undoable from 'redux-undo'
import Immutable from 'seamless-immutable'

import get from 'lodash/get'
import isNumber from 'lodash/isNumber'

import { FILTERS_ORDER, SCHEDULE_VIEW_SORT_OPTIONS } from 'constants/schedule'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { UPDATE_BRANCH } from 'store/actions/company/branches'
import {
  ACTUAL_DELETE,
  AUTHORIZE,
  CLEAR_TIME_ENTRIES_INFO,
  CREATE_TIME_ENTRY,
  INIT,
  INIT_DONE,
  LOAD_RANGE,
  LOAD_SCHEDULE_TIMER_INFO,
  LOAD_SCHEDULES_LOGS,
  LOAD_TIME_ENTRIES,
  PUBLISH,
  REMEMBER_SELECTED_SCHEDULES,
  SELECT_FILTER_EMPLOYEES,
  SELECT_TIMESHEETS_CELL,
  SELECT_TIMESHEETS_COLUMN,
  SELECT_TIMESHEETS_ROW,
  SHIFT_WEEK,
  TOGGLE_ACTUAL_DELETE,
  TOGGLE_SHOW_BUDGET,
  TOGGLE_VIEW_SORT_OPTION,
  UNAUTHORIZE,
  UPDATE_FILTER_JOBS,
  UPDATE_TIMERS,
} from 'store/actions/employees/schedules'
import { LOAD_NOTIFICATION_TIMEOFF } from 'store/actions/notifications'

const initialState = Immutable({
  error: '',
  schedules: [],
  isLoading: false,
  isLoaded: false,
  isLoadingSchedules: false,
  acceptedTimeoffs: [],
  week: null,
  timersUpdating: false,
  timersUpdated: false,
  authorizing: false,
  selectedRowsEmployeeId: null,
  selectedColumnsDayIndex: null,
  selectedSchedules: [],
  isSelectedAuthorized: false,
  isLoadingSchedulesLogs: false,
  schedulesLogs: [],
  isTimerInfoLoading: false,
  timeEntriesInfo: [],
  isShowBudget: true,
  isShowAdjust: false,
  isAdjusting: false,
  adjustErrorDetail: '',
  selectedCellDepartmentId: null,
  selectedCellScheduleId: null,
  isShowActualDelete: false,
  isActualDeleting: false,
  actualTimeDeleteErrorDetail: '',
  timeEntries: [],
  filterJobIds: [],
  viewSortOption: SCHEDULE_VIEW_SORT_OPTIONS.role,
  viewSortOrder: FILTERS_ORDER.asc,
  filterEmployeeIds: [],
})

const handleError = (state, action) =>
  state.merge({
    error: action.payload,
    isLoading: false,
    isLoaded: false,
    isLoadingSchedules: false,
  })

const setAuthorizing = status => state =>
  state.merge({
    authorizing: status,
  })

const handlers = {
  [SHIFT_WEEK]: (state, action) => state.merge({ week: action.payload.week }),

  [SELECT_TIMESHEETS_ROW]: (state, action) => {
    const {
      payload: { employeeId, departmentId, selectedCellDepartmentId },
    } = action
    const { selectedRowsEmployeeId, selectedColumnsDayIndex } = state

    // if previous selection - Cell:
    if (selectedRowsEmployeeId && isNumber(selectedColumnsDayIndex)) {
      return state.merge({
        selectedColumnsDayIndex: null,
        selectedRowsEmployeeId: employeeId,
        selectedCellDepartmentId: departmentId,
        selectedCellScheduleId: null,
      })
    }
    const isSelectedSameEmployee = selectedRowsEmployeeId !== employeeId
    const isSelectedSameDepartment = selectedCellDepartmentId === departmentId
    const isSelectedSameRow = isSelectedSameEmployee && isSelectedSameDepartment
    return state.merge({
      selectedColumnsDayIndex: null,
      selectedRowsEmployeeId: isSelectedSameRow ? null : employeeId,
      selectedCellDepartmentId: isSelectedSameRow ? null : departmentId,
    })
  },

  [SELECT_TIMESHEETS_COLUMN]: (state, action) => {
    const {
      payload: { dayIndex },
    } = action
    const { selectedRowsEmployeeId, selectedColumnsDayIndex } = state
    // if previous selection - Cell:
    if (selectedRowsEmployeeId && isNumber(selectedColumnsDayIndex)) {
      return state.merge({
        selectedRowsEmployeeId: null,
        selectedColumnsDayIndex: dayIndex,
        selectedCellDepartmentId: null,
        selectedCellScheduleId: null,
      })
    }
    return state.merge({
      selectedRowsEmployeeId: null,
      selectedColumnsDayIndex:
        selectedColumnsDayIndex !== dayIndex ? dayIndex : null,
      selectedCellDepartmentId: null,
    })
  },

  [SELECT_TIMESHEETS_CELL]: (state, action) => {
    const {
      payload: { employeeId, dayIndex, departmentId, scheduleId },
    } = action
    const {
      selectedRowsEmployeeId,
      selectedColumnsDayIndex,
      selectedCellDepartmentId,
      selectedCellScheduleId,
    } = state
    // if selection the same:
    if (
      selectedColumnsDayIndex === dayIndex &&
      selectedRowsEmployeeId === employeeId &&
      selectedCellDepartmentId === departmentId &&
      selectedCellScheduleId === scheduleId
    ) {
      return state.merge({
        selectedRowsEmployeeId: null,
        selectedColumnsDayIndex: null,
        selectedCellDepartmentId: null,
        selectedCellScheduleId: null,
      })
    }
    return state.merge({
      selectedRowsEmployeeId: employeeId,
      selectedColumnsDayIndex: dayIndex,
      selectedCellDepartmentId: departmentId,
      selectedCellScheduleId: scheduleId,
    })
  },

  [INIT]: state => state.merge({ isLoading: true }),

  [LOAD_NOTIFICATION_TIMEOFF.SUCCESS]: createLoadHandler('timeoffs', {
    mapToKey: 'acceptedTimeoffs',
    withLoading: false,
  }),

  [LOAD_RANGE.REQUEST]: state => state.merge({ isLoadingSchedules: true }),

  [LOAD_RANGE.SUCCESS]: (state, action) =>
    createLoadHandler('schedules', {
      addToState: { isLoadingSchedules: false },
      withReplace: get(action, 'payload.withReplace', false),
    })(state, action),

  [LOAD_RANGE.FAILURE]: handleError,

  [LOAD_SCHEDULES_LOGS.REQUEST]: state =>
    state.merge({ isLoadingSchedulesLogs: true }),

  [LOAD_SCHEDULES_LOGS.SUCCESS]: createLoadHandler('schedulesLogs', {
    addToState: { isLoadingSchedulesLogs: false },
  }),

  [LOAD_SCHEDULES_LOGS.FAILURE]: (state, action) =>
    state.merge({
      error: action.payload,
      isLoadingSchedulesLogs: false,
    }),

  [PUBLISH.SUCCESS]: createLoadHandler('schedules', {
    addToState: { isLoadingSchedules: false },
  }),

  [INIT_DONE]: state =>
    state.merge({
      isLoading: false,
      isLoaded: true,
    }),

  [UPDATE_TIMERS.REQUEST]: state =>
    state.merge({
      timersUpdating: true,
      timersUpdated: false,
    }),

  [UPDATE_TIMERS.SUCCESS]: state =>
    state.merge({
      timersUpdating: false,
      timersUpdated: true,
    }),

  [UPDATE_TIMERS.FAILURE]: state =>
    state.merge({
      timersUpdating: false,
      timersUpdated: false,
    }),

  [AUTHORIZE.REQUEST]: setAuthorizing(true),

  [AUTHORIZE.SUCCESS]: (state, action) =>
    createLoadHandler('timeEntries', {
      addToState: { authorizing: false },
      withReplace: get(action, 'payload.withReplace', false),
    })(state, action),

  [AUTHORIZE.FAILURE]: setAuthorizing(false),

  [UNAUTHORIZE.REQUEST]: setAuthorizing(true),

  [UNAUTHORIZE.SUCCESS]: (state, action) =>
    createLoadHandler('timeEntries', {
      addToState: { authorizing: false },
      withReplace: get(action, 'payload.withReplace', false),
    })(state, action),

  [UNAUTHORIZE.FAILURE]: setAuthorizing(false),

  [REMEMBER_SELECTED_SCHEDULES]: (state, action) => {
    const {
      payload: { selectedSchedules, isAuthorized },
    } = action
    return state.merge({
      selectedSchedules: [...selectedSchedules],
      isSelectedAuthorized: isAuthorized,
    })
  },
  [LOAD_SCHEDULE_TIMER_INFO.REQUEST]: state =>
    state.merge({
      isTimerInfoLoading: true,
    }),
  [LOAD_SCHEDULE_TIMER_INFO.SUCCESS]: createLoadHandler('timeEntriesInfo', {
    addToState: { isTimerInfoLoading: false },
    withReplace: true,
  }),
  [LOAD_SCHEDULE_TIMER_INFO.FAILURE]: state =>
    state.merge({
      isTimerInfoLoading: false,
    }),
  [CLEAR_TIME_ENTRIES_INFO]: state =>
    state.merge({
      timeEntriesInfo: [],
    }),
  [TOGGLE_SHOW_BUDGET]: state =>
    state.merge({
      isShowBudget: !state.isShowBudget,
    }),

  [TOGGLE_ACTUAL_DELETE]: state =>
    state.merge({
      isShowActualDelete: !state.isShowActualDelete,
      actualTimeDeleteErrorDetail: '',
    }),

  [ACTUAL_DELETE.REQUEST]: state =>
    state.merge({
      timersUpdating: true,
      timersUpdated: false,
      isActualDeleting: true,
    }),

  [ACTUAL_DELETE.SUCCESS]: state =>
    state.merge({
      timersUpdating: false,
      timersUpdated: true,
      isShowActualDelete: !state.isShowActualDelete,
      isActualDeleting: false,
    }),

  [ACTUAL_DELETE.FAILURE]: (state, { payload }) =>
    state.merge({
      timersUpdating: false,
      timersUpdated: true,
      isShowActualDelete: !state.isShowActualDelete,
      isActualDeleting: false,
      actualTimeDeleteErrorDetail: get(
        payload,
        ['errors', 0, 'detail'],
        'no detail',
      ),
    }),

  [LOAD_TIME_ENTRIES.SUCCESS]: (state, action) =>
    createLoadHandler('timeEntries', {
      withReplace: get(action, 'payload.withReplace', true),
    })(state, action),
  [CREATE_TIME_ENTRY.SUCCESS]: createLoadHandler('timeEntries'),
  [UPDATE_FILTER_JOBS]: (state, { payload }) =>
    state.merge({
      filterJobIds: get(payload, 'jobIds', []),
    }),
  [TOGGLE_VIEW_SORT_OPTION]: (state, { payload }) => {
    const { sortOption } = payload
    const { viewSortOrder: prevViewSortOrder } = state
    const { asc, desc } = FILTERS_ORDER
    let viewSortOrder = prevViewSortOrder
    if (sortOption === state.viewSortOption) {
      viewSortOrder = prevViewSortOrder === asc ? desc : asc
    }
    return state.merge({
      viewSortOption: sortOption,
      viewSortOrder,
    })
  },

  [SELECT_FILTER_EMPLOYEES]: (state, { payload }) =>
    state.merge({
      filterEmployeeIds: payload.employeeIds,
    }),

  // Need when updating weekStartDay in settings
  [UPDATE_BRANCH.SUCCESS]: state =>
    state.merge({
      week: null,
    }),

  [LOG_OUT]: () => initialState,
}

const undoableSchedules = undoable(createReducer(initialState, handlers), {
  limit: 20,
  filter: action => action.type === REMEMBER_SELECTED_SCHEDULES,
  syncFilter: true,
  ignoreInitialState: true,
})

export default undoableSchedules

import { useMemo, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import {
  EvaluatedTimesByCursorData,
  EvaluatedTimesByCursorQuery,
} from 'API/TimeEvaluation/GraphQL'
import { CursorPage } from 'Types/common'

import { EvaluatedTimesQueryParams } from 'pages/EmployerApp/EvaluatedTime/hooks/types'

import { evaluatedTimesQueryParamsToFilter } from './mapper'

type Props = {
  page: CursorPage
  filter: EvaluatedTimesQueryParams['filters']
  sorting: EvaluatedTimesQueryParams['sorting']
}

export function useEvaluatedTimesByCursor({ page, filter, sorting }: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, loading, refetch } = useApolloQuery<
    EvaluatedTimesByCursorData,
    Gateway.QueryEvaluatedTimesByCursorArgs
  >(EvaluatedTimesByCursorQuery, {
    fetchPolicy: 'network-only',

    variables: {
      sorting: [sorting],
      filter: evaluatedTimesQueryParamsToFilter(filter),
      paging: cursorPageToGraphqlPaging(page),
    },

    onCompleted(data) {
      const newPageMeta = data.evaluatedTimesByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const evaluatedTimes = useMemo(
    () => data?.evaluatedTimesByCursor?.edges?.map(edge => edge.node) ?? [],
    [data?.evaluatedTimesByCursor],
  )

  return {
    evaluatedTimes,
    evaluatedTimesLoading: loading,
    refetchEvaluatedTimes: refetch,
    evaluatedTimesPageMeta: currentPageMeta,
  }
}

import cond from 'lodash/cond'
import constant from 'lodash/constant'
import isEmpty from 'lodash/isEmpty'
import negate from 'lodash/negate'

import { i18n } from 'i18n'

function valueOrNA(
  value: unknown,
  notAvailableText = i18n('common.na'),
): string {
  return cond([
    [isEmpty, constant(notAvailableText)],
    [negate(isEmpty), constant(value)],
  ])(value) as string
}

export default valueOrNA

import styled, { css } from 'styled-components'
import {
  flexbox,
  height,
  HeightProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

import { ColumnMeta, RowData, TableMeta } from '@tanstack/react-table'

import { Flex, Span } from 'components/ui/__v2__/Grid'

interface TableContainerProps extends HeightProps {
  width?: string
  maxHeight?: string
  borderCollapse?: string
}

type MetaProps = ColumnMeta<RowData, any> & TableMeta<RowData>

export const TableContainer = styled.div<TableContainerProps>`
  overflow: scroll;
  width: ${props => props.width ?? '100%'};
  max-height: ${props => props.maxHeight ?? '100%'};
  border-collapse: ${props => props.borderCollapse ?? 'initial'};

  ${height}
`

interface DataTableContainerProps {
  height?: number | string
}
export const DataTable = styled.table<DataTableContainerProps>`
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
`

export const TableHeaderContainer = styled.div`
  height: 80px;
  padding: 16px;

  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid ${({ theme }) => theme.layout.border};
`

export const TableHeaderTitle = styled.h2`
  font-size: 16px;
`

export const Thead = styled.thead`
  & > tr > th {
    z-index: ${({ theme }) => theme.zIndices.stickyHeader};
  }
`

export const TFoot = styled.tfoot`
  font-weight: bold;
  border-top: 1.5px solid ${({ theme }) => theme.layout.darkBorder};
  & > tr > td {
    z-index: ${({ theme }) => theme.zIndices.stickyHeader};
  }
`

export const Tr = styled.tr`
  vertical-align: top;
  border-bottom: 1px solid ${({ theme }) => theme.layout.border};
`

const actionableCss = ({ actionable }: MetaProps) =>
  actionable
    ? css`
        :last-of-type {
          right: 0;

          width: 0.1%; // To make it shrink to content

          box-shadow: inset 2px -1px 0 ${({ theme }) => theme.layout.border};
        }
        :nth-last-of-type(2) {
          border-right: unset;
        }
      `
    : ''

export const Th = styled.th<MetaProps>`
  padding: ${({ slim }) => (slim ? '4px 12px' : '12px 16px')};

  position: sticky;
  top: 0;

  text-align: start;
  vertical-align: top;

  background-color: ${({ theme, contrastHeader }) =>
    contrastHeader ? theme.layout.grayBg : theme.layout.bg};
  box-shadow: inset 0 -1px 0 ${({ theme }) => theme.layout.border};

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.layout.border};
  }
  ${actionableCss}

  ${flexbox};
  ${layout};
  ${typography}
`

export const HeaderIconContainer = styled(Flex)`
  height: 14px;
  width: 14px;
  padding: 0;

  background: none;
  color: ${({ theme }) => theme.table.headerIcon.color};
`

export const Td = styled.td<MetaProps>`
  padding: ${({ slim }) => (slim ? '4px 12px' : '12px 16px')};

  white-space: ${({ whiteSpace }) => whiteSpace ?? 'nowrap'};

  &:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.layout.border};
  }

  overflow: hidden;
  text-overflow: ellipsis;

  ${actionableCss}

  ${flexbox};
  ${layout};
  ${typography}
`

export const Tf = styled.tfoot<MetaProps>`
  padding: ${({ slim }) => (slim ? '12px 16px 0' : '6px 12px 0')};
  text-align: start;
`
export const TableLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  height: 100%;
`

export const EmptyData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`

export type TitleStyleProps = LayoutProps & SpaceProps & TypographyProps
export const TableTitle = styled.div<TitleStyleProps>`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  white-space: nowrap;

  margin-left: 16px;
  ${layout}
  ${space}
  ${typography}
`

export const SortableTitle = styled(Span)`
  transition: color 0.2s;
  :hover {
    color: ${({ theme }) => theme.table.headerIcon.color};
  }
`

import React from 'react'
import PropTypes from 'prop-types'

import Tippy from '@tippyjs/react/headless'

import { Flex } from 'components/ui/__v2__/Grid'
import { Popover } from 'components/ui/__v2__/Popover'

import { InfoIcon } from './styles'

function InfoPopover({ iconSize, popoverContent, ...rest }) {
  return (
    <Popover content={popoverContent} {...rest}>
      <Flex>
        <InfoIcon size={iconSize} />
      </Flex>
    </Popover>
  )
}

InfoPopover.defaultProps = {
  iconSize: 14,
}

InfoPopover.propTypes = {
  ...Tippy.propTypes,
  iconSize: PropTypes.number,
  popoverContent: PropTypes.any.isRequired,
}

export default InfoPopover

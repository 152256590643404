import { createFields, createReducerHandlers } from 'rapidux'
import { createDeleteHandler, createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import without from 'lodash/without'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  CHECK_UNIQUE_SLUG,
  CLEAR_IMAGE_FILES,
  CREATE_BRANCH,
  CREATE_OR_UPDATE_BULK_BUDGET,
  DELETE_BRANCH,
  DELETE_IMAGE_FILE,
  HANDLE_EMPLOYEE_DESTROY,
  LOAD_BRANCH,
  UPDATE_BRANCH,
  UPLOAD_IMAGE_FILE,
} from 'store/actions/company/branches'
import { LOAD_VIEWER_BRANCHES } from 'store/actions/viewer'

const initialState = Immutable({
  branches: [],
  imageFiles: [],

  isLoading: false,
  isLoaded: false,

  isProcessingImageFiles: false,

  isSlugUnique: false,
  isSlugUniqueChecking: false,
  customCvFields: [],
  isCustomCvFieldsLoading: false,
  isUpdatingBulkBudget: false,

  branch: null,
  isLoadedBranch: true,
  isLoadingBranch: false,

  ...createFields('branch'),

  error: '',
})

function loadRequest(state) {
  return state.merge({
    isLoading: true,
    isLoaded: false,
  })
}

function loadFailure(state, { payload }) {
  return state.merge({
    isLoading: false,
    error: payload,
  })
}

const handlers = {
  [LOAD_VIEWER_BRANCHES.REQUEST]: loadRequest,
  [LOAD_VIEWER_BRANCHES.SUCCESS]: createLoadHandler('branches'),
  [LOAD_VIEWER_BRANCHES.FAILURE]: loadFailure,

  ...createReducerHandlers('branches', LOAD_BRANCH, {
    mapToKey: 'branch',
    singular: true,
  }),

  [DELETE_BRANCH.SUCCESS]: (state, { payload }) => {
    return state.merge({ branches: without(state.branches, payload.id) })
  },

  [UPDATE_BRANCH.REQUEST]: loadRequest,
  [UPDATE_BRANCH.SUCCESS]: createLoadHandler('branches'),
  [UPDATE_BRANCH.FAILURE]: loadFailure,

  [CREATE_BRANCH.REQUEST]: loadRequest,
  [CREATE_BRANCH.SUCCESS]: createLoadHandler('branches'),
  [CREATE_BRANCH.FAILURE]: loadFailure,

  [UPLOAD_IMAGE_FILE.REQUEST]: state => {
    return state.merge({
      isProcessingImageFiles: true,
    })
  },

  [UPLOAD_IMAGE_FILE.SUCCESS]: createLoadHandler('files', {
    mapToKey: 'imageFiles',
    addToState: {
      isProcessingImageFiles: false,
    },
  }),

  [UPLOAD_IMAGE_FILE.FAILURE]: state => {
    return state.merge({ isProcessingImageFiles: false })
  },

  [DELETE_IMAGE_FILE.REQUEST]: state => {
    return state.merge({ isProcessingImageFiles: true })
  },

  [DELETE_IMAGE_FILE.SUCCESS]: createDeleteHandler('imageFiles', {
    addToState: {
      isProcessingImageFiles: false,
    },
  }),

  [DELETE_IMAGE_FILE.FAILURE]: state => {
    return state.merge({ isProcessingImageFiles: false })
  },

  [CLEAR_IMAGE_FILES]: state => state.merge({ imageFiles: {} }),

  [CHECK_UNIQUE_SLUG.REQUEST]: state => {
    return state.merge({ isSlugUnique: false, isSlugUniqueChecking: true })
  },

  [CHECK_UNIQUE_SLUG.SUCCESS]: (state, action) => {
    return state.merge({
      isSlugUnique: action.payload.data.unique,
      isSlugUniqueChecking: false,
    })
  },

  [CHECK_UNIQUE_SLUG.FAILURE]: state => {
    return state.merge({ isSlugUnique: false, isSlugUniqueChecking: false })
  },

  [CREATE_OR_UPDATE_BULK_BUDGET.REQUEST]: state => {
    return state.merge({
      isUpdatingBulkBudget: true,
    })
  },

  [CREATE_OR_UPDATE_BULK_BUDGET.SUCCESS]: state => {
    return state.merge({ isUpdatingBulkBudget: false })
  },

  [CREATE_OR_UPDATE_BULK_BUDGET.FAILURE]: state => {
    return state.merge({ isUpdatingBulkBudget: false })
  },

  [HANDLE_EMPLOYEE_DESTROY.COMPANY]: (state, { payload }) => {
    return state.merge({ branches: without(state.branches, payload.branchId) })
  },

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import FileDownload from 'components/ui/__v2__/FileDownload'
import { FormFileUpload } from 'components/ui/__v2__/Form'
import { Box, Flex, Span } from 'components/ui/Grid'

import _ from 'i18n'

import { NoAnswer } from '../styles'

function LabeledFileUpload({
  name,
  isRequired,
  isReadOnly,
  onChange,
  isMulti,
  label,
  ...rest
}) {
  /**
   * If the element is in readOnly state - show FormFileUpload only if there's a value
   */

  const element = props => {
    const fileInput = props.input.value
    if (isReadOnly) {
      if (!fileInput || isEmpty(fileInput)) {
        return <NoAnswer>{_('formBuilder.fallbacks.noFileUploaded')}</NoAnswer>
      }
      return map(fileInput, file => <FileDownload file={file} />)
    }

    return (
      <FormFileUpload
        {...props}
        {...rest}
        multiple={isMulti}
        name={name}
        required={isRequired}
        onBlur={onChange}
      />
    )
  }

  return (
    <Flex flexDirection="column">
      <Span as="label" htmlFor={name} mr={2}>
        {label}
        {isRequired && <sup>*</sup>}
      </Span>

      <Box maxWidth="180px">
        <Field name={name}>{element}</Field>
      </Box>
    </Flex>
  )
}

LabeledFileUpload.defaultProps = {
  isReadOnly: false,
}

LabeledFileUpload.propTypes = {
  ...Field.propTypes,
  isReadOnly: PropTypes.bool,
}

export default LabeledFileUpload

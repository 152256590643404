import {
  BlankCustomCvFieldPair,
  FieldValuePairState,
  FieldValuePairType,
} from './types'

export function optionProvidedGuard(
  option: FieldValuePairState,
): option is FieldValuePairType {
  return option.field !== undefined && option.field !== null
}

export function optionIsBlankGaurd(
  option: FieldValuePairState,
): option is BlankCustomCvFieldPair {
  return !optionProvidedGuard(option)
}

import { useState } from 'react'

import { download } from 'API/services/JSON/downloadDocument'

import { useAppContext } from 'hooks'

import { DocumentKind } from '../services/JSON'

export function useExportTimeCards() {
  const [exporting, setExporting] = useState(false)
  const { companyId } = useAppContext()

  async function exportTimeCards(kind: DocumentKind, queryString: string) {
    setExporting(true)
    await download({ companyId, queryString, kind })
    setExporting(false)
  }

  return {
    exporting,
    exportTimeCards,
  }
}

import React from 'react'

import { CustomLeave } from 'Types/app'

import { CustomLabelDayKinds } from 'constants/ids'
import { LONG_DASH } from 'constants/schedule'

import i18n from 'i18n'

import { Cell } from '../styles'

type Props = {
  leaveDay: CustomLeave
}
function LeaveTypeCell({ leaveDay }: Props) {
  const { kind = null, statutory } = leaveDay
  const isStatutoryHoliday = kind === CustomLabelDayKinds.Holiday && statutory

  if (!kind) return LONG_DASH

  return (
    <Cell>
      {i18n(`customLabelDays.kindTypes.${kind}`)}
      {isStatutoryHoliday && (
        <small>{i18n('customLabelDays.canadaStatutory')}</small>
      )}
    </Cell>
  )
}

LeaveTypeCell.propTypes = {}

export default LeaveTypeCell

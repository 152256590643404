import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'
import { TIME_BUCKET_ERRORS_I18N } from 'API/TimeBucket/errors'

import { compact } from 'lodash'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import {
  CreateTimeBucketParentMutation,
  TimeBucketParentsByCursorQuery,
} from './GraphQL'

export function useCreateTimeBucketParent() {
  const t = useI18n<
    typeof TRANSLATIONS.settings.timeBuckets.toasts.parent.create
  >('settings.timeBuckets.toasts.parent.create')

  const [create, { loading }] = useApolloMutation<
    MutationData<'createTimeBucketParent'>,
    Gateway.MutationCreateTimeBucketParentArgs
  >(CreateTimeBucketParentMutation, {
    refetchQueries: compact([getOperationName(TimeBucketParentsByCursorQuery)]),
    silent: true,
    // TODO: Temporary solution, translated errors should come from Gateway
    onError: error => {
      const errorMessage: string | undefined =
        TIME_BUCKET_ERRORS_I18N[error.message]

      showToast({
        type: 'error',
        title: t('error.title'),
        content: errorMessage || t('error.message'),
      })
    },
    onCompleted: () => {
      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const createTimeBucketParent = async (
    input: Gateway.CreateTimeBucketParentInput,
  ) => {
    const result = await create({
      variables: {
        input,
      },
    })

    return graphQLResultHasError(result)
  }

  return { createTimeBucketParent, creatingParent: loading }
}

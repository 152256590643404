import { combineReducers } from 'redux'

import availabilities from './availabilities'
import branches from './branches'
import departments from './departments'
import formTemplates from './formTemplates'
import jobs from './jobs'
import managers from './managers'
import prayerBreaks from './prayerBreaks'
import schedule from './schedule'
import settings from './settings'
import shifts from './shifts'
import tasks from './tasks'

export default combineReducers({
  availabilities,
  branches,
  departments,
  jobs,
  managers,
  settings,
  schedule,
  shifts,
  formTemplates,
  prayerBreaks,
  tasks,
})

import styled from 'styled-components'

import { Flex } from 'components/ui/__v2__/Grid'

const IconContainer = styled(Flex)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  width: 16px;
  height: 16px;

  border-radius: 8px;

  color: ${({ theme }) => theme.colors.WHITE};
`

export const SuccessIconContainer = styled(IconContainer)`
  background-color: ${({ theme }) => theme.toasts.secondaryColor.success};
`

export const InfoIconContainer = styled(IconContainer)`
  background-color: ${({ theme }) => theme.toasts.secondaryColor.info};
`

export const WarningIconContainer = styled(IconContainer)`
  background-color: ${({ theme }) => theme.toasts.secondaryColor.warning};
`

export const ErrorIconContainer = styled(IconContainer)`
  background-color: ${({ theme }) => theme.toasts.secondaryColor.error};
`

import Collapse from 'react-css-collapse'

import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { Box, Flex } from 'components/ui/__v2__/Grid'

export const ColumnHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${themeGet('colors.MANATEE')};
  margin-bottom: 4px;
  font-size: 12px;

  ${space};
`

const spacedDirectionCss = ({ orientation }) =>
  orientation === 'column'
    ? css`
        flex-direction: column;
        & > *:not(:first-child) {
          margin-top: 8px;
        }
      `
    : css`
        flex-direction: row;
        & > *:not(:last-child) {
          margin-right: 8px;
        }
      `

export const PickersContainer = styled(Flex)`
  width: 100%;
  ${spacedDirectionCss}
`
export const PickerItem = styled(Flex)`
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
`

export const Container = styled(Box)`
  background-color: ${themeGet('layout.bg')};
  border-radius: 4px;
`

export const Pickers = styled(Collapse).attrs({
  className: 'collapse-transition',
})`
  display: flex;
  flex-direction: row;
`

export const Title = styled.h2`
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin: 0 33px 20px;
  color: ${themeGet('layout.text')};
  font-family: ${themeGet('font')};
  padding: 0;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 22px;
  width: 100%;
`

export const GlobalSwitch = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
`

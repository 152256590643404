import React from 'react'

import { MaxWidthProps } from 'styled-system'

import { TextOverflowWithPopover } from 'components/blocks/__v3__/TextOverflowWithPopover'

import { BadgeContainer } from './styles'

type Props = MaxWidthProps & {
  text: string
}

export function Badge({ text, ...rest }: Props) {
  return (
    <BadgeContainer {...rest}>
      <TextOverflowWithPopover maxWidth={'100%'}>
        {text}
      </TextOverflowWithPopover>
    </BadgeContainer>
  )
}

import { graphQLResultHasError } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'
import { TIME_BUCKET_ERRORS_I18N } from 'API/TimeBucket/errors'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { UpdateTimeBucketChildMutation } from './GraphQL'

export function useUpdateTimeBucketChild() {
  const t = useI18n<
    typeof TRANSLATIONS.settings.timeBuckets.toasts.child.update
  >('settings.timeBuckets.toasts.child.update')

  const [update, { loading }] = useApolloMutation<
    MutationData<'updateTimeBucketChild'>,
    Gateway.MutationUpdateTimeBucketChildArgs
  >(UpdateTimeBucketChildMutation, {
    silent: true,
    // TODO: Temporary solution, translated errors should come from Gateway
    onError: error => {
      const errorMessage: string | undefined =
        TIME_BUCKET_ERRORS_I18N[error.message]

      showToast({
        type: 'error',
        title: t('error.title'),
        content: errorMessage || t('error.message'),
      })
    },
    onCompleted: () => {
      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const updateTimeBucketChild = async (
    id: string,
    input: Gateway.UpdateTimeBucketChildInput,
  ) => {
    const result = await update({
      variables: {
        id,
        input,
      },
    })

    return graphQLResultHasError(result)
  }

  return { updateTimeBucketChild, updatingChild: loading }
}

import { useMemo } from 'react'

import { DateTime, Interval } from 'luxon'
import { Shift, ShiftDateTime } from 'Types/schedule'

import { getShiftTimezone } from 'helpers/shifts'

import Utils from 'services/Utils'

import { useAppContext } from '../useAppContext'

export function useShifts(shifts: Shift[], day: DateTime, locationId: string) {
  const { viewer } = useAppContext()

  return useMemo(
    () =>
      shifts.reduce<ShiftDateTime[]>((acc, shift) => {
        const timezone = getShiftTimezone(shift)

        const todayStartAt = day.startOf('day').setZone(timezone)
        const todayFinishAt = day.endOf('day').setZone(timezone)

        const startAt = DateTime.fromISO(shift.startAt).setZone(timezone)
        const finishAt = DateTime.fromISO(shift.finishAt).setZone(timezone)

        const shiftInterval = Interval.fromDateTimes(startAt, finishAt)
        const dayInterval = Interval.fromDateTimes(todayStartAt, todayFinishAt)

        if (
          !dayInterval.overlaps(shiftInterval) ||
          shift?.branch?.id !== locationId
        ) {
          return acc
        }

        const viewerCanEditShiftByAccessToShiftJobs = Utils.Viewer.checkViewerAccessToAllShiftJobs(
          viewer,
          shift?.shiftsJobs,
        )
        const shiftsJobs = shift?.shiftsJobs.map(shiftJobs => ({
          ...shiftJobs,
          viewerHasAccess: Utils.Viewer.checkViewerAccessToShiftJob({
            viewer,
            affectedDepartmentId: shiftJobs.department.id,
            affectedBranchId: shiftJobs.shift.branch.id,
          }),
        }))

        acc.push({
          ...shift,
          shiftsJobs,
          startAt,
          finishAt,
          viewerCanEditShiftByAccessToShiftJobs,
        })

        return acc
      }, []),
    [day, locationId, viewer, shifts],
  )
}

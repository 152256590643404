import { PAGE_SIZE } from 'constants/pagination'

import coreApiCall from 'services/API/coreApi'

export function customLeaveDayPickerQuery(
  search?: string,
): Promise<JsonApi.Response> {
  const query: JsonApi.CustomLeaveDaysArgs = {
    page: {
      size: PAGE_SIZE['25'],
    },
    filter: {
      ...(search && { name: { ilike: search } }),
    },
  }

  return coreApiCall({
    endpoint: '/custom_leave_days',
    method: 'GET',
    query,
  })
}

import { useApolloMutation } from 'API/services/Apollo'

import { UpdateWorkflowMutation } from './GraphQL'

export function useUpdateWorkflow() {
  const [innerUpdate, { loading: updating }] = useApolloMutation<
    MutationData<'updateWorkflow'>,
    Gateway.MutationUpdateWorkflowArgs
  >(UpdateWorkflowMutation, {
    translationKey: 'workflow.toasts.update',
  })

  const updateWorkflow = async (input: Gateway.UpdateWorkflowInput) =>
    innerUpdate({
      variables: {
        input,
      },
    })

  return { updateWorkflow, updating }
}

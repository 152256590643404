import React, { ChangeEvent, useState } from 'react'

import { FiCheck } from 'react-icons/fi'

import { SearchInput } from 'components/ui/__v3__/SearchInput'

import { useI18n } from 'hooks/useI18n'

import {
  CheckIconContainer,
  InputContainer,
  MenuContainer,
  Option,
  OptionsContainer,
} from './styles'
import { InternalSelectOption, MenuProps } from './types'

export function Menu({ handleOptionSelect, options }: MenuProps) {
  const t = useI18n('table')

  const [inputValue, setInputValue] = useState('')

  const filterByInputValue = (option: InternalSelectOption) => {
    return (
      option.selected ||
      option.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
    )
  }

  return (
    <MenuContainer>
      <InputContainer>
        <SearchInput
          // @ts-ignore
          fontWeight="normal"
          placeholder={t('search')}
          value={inputValue}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setInputValue(event.target.value)
          }
        />
      </InputContainer>

      <OptionsContainer>
        {options
          .filter(filterByInputValue)
          .map(({ value, label, selected }) => (
            <Option
              key={value}
              selected={selected}
              onClick={() => handleOptionSelect(value)}
            >
              {label}
              {selected && (
                <CheckIconContainer>
                  <FiCheck />
                </CheckIconContainer>
              )}
            </Option>
          ))}
      </OptionsContainer>
    </MenuContainer>
  )
}

import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

type SwitchCheckboxProps = {
  disabled?: boolean
  checked?: boolean
  secondary?: boolean
}
export const SwitchCheckbox = styled.span<SwitchCheckboxProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: var(--width);
  height: var(--height);

  background-color: ${({ disabled, theme, checked, secondary }) => {
    if (disabled) return theme.switch.bg.disabled
    if (checked)
      return theme.switch.bg.checked[secondary ? 'secondary' : 'primary']
    return theme.switch.bg.default
  }};

  border-radius: var(--radius);
  border-width: 1px;
  border-style: solid;

  border-color: ${({ disabled, theme, checked }) => {
    if (disabled) return theme.switch.border.disabled
    if (checked) return theme.switch.border.checked
    return theme.switch.border.default
  }};

  transition: all 0.17s ease-in-out;

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translate(var(--offset), -50%);
    width: var(--toggle-size);
    height: var(--toggle-size);
    background-color: ${({ disabled, theme }) => {
      if (disabled) return theme.switch.icon.bg.disabled
      return theme.switch.icon.bg.default
    }};

    border-radius: 50%;

    transition: all 0.17s ease-in-out;
  }
`

export type SwitchContainerProps = {
  width: number
  height: number
  disabled?: boolean
} & SpaceProps
export const SwitchContainer = styled.label.attrs(
  (props: SwitchContainerProps) => ({
    width: props.width,
    height: props.height,
  }),
)<SwitchContainerProps>`
  --width: ${props => props.width}px;
  --height: ${props => props.height}px;
  --radius: var(--height);
  --toggle-size: calc(var(--height) / 1.5);
  --offset: calc(var(--height) / 5);

  display: inline-block;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: var(--width);
  height: var(--height);

  ${space};
`

type HiddenCheckboxProps = {
  disabled?: boolean
}
export const HiddenCheckbox = styled.input.attrs({
  type: 'checkbox',
})<HiddenCheckboxProps>`
  opacity: 0;
  width: var(--width);
  height: var(--height);

  &:checked ~ ${SwitchCheckbox} {
    &:after {
      --transform: calc(var(--width) - var(--toggle-size) - var(--offset));
      transform: translate(var(--transform), -50%);
    }
  }

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

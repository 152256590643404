import React from 'react'
import ReactModal from 'react-modal'
import StyledPropTypes from '@styled-system/prop-types'
import PropTypes from 'prop-types'

import { MdArrowBack, MdClose } from 'react-icons/md'

import styled, { withTheme } from 'styled-components'
import { flexbox, layout, space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import merge from 'lodash/merge'
import noop from 'lodash/noop'

import { either } from 'components/ui/__v3__/__utils__/styled-system'
import { Spinner } from 'components/ui/Spinner'

import { Flex } from '../Grid'

const defaultStyles = theme => ({
  overlay: {
    // TODO: get z-index from theme
    zIndex: 9999,
  },
  content: {
    position: 'relative',
    top: 'initial',
    left: 'initial',
    right: 'initial',
    bottom: 'initial',
    padding: 22,
    margin: 'auto',
    border: 'none',
    fontFamily: theme?.fonts?.main,
    transition: 'all 0.2s ease',
  },
})

const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: ${themeGet('fontWeights.bold')};
  text-align: center;
  margin-bottom: 12px;
  text-transform: ${either('upper', 'uppercase', 'initial')};
`

const ModalCloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const ModalCloseIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: ${either('disabled', 'not-allowed', 'pointer')};

  color: ${either(
    'disabled',
    themeGet('layout.disabled'),
    themeGet('layout.text'),
  )};
`
const ModalBackContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const ModalBackIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: ${either('disabled', 'not-allowed', 'pointer')};

  color: ${either(
    'disabled',
    themeGet('layout.disabled'),
    themeGet('layout.text'),
  )};
`

const ModalActions = styled(Flex)`
  align-items: center;

  ${space};
  ${layout};
`

const ModalActionsWrapper = styled(Flex)`
  align-items: center;

  ${flexbox};
  ${space};
  ${layout};
`

const StyledModalSpinner = styled(Spinner).attrs({
  size: 'large',
  primary: true,
})`
  position: absolute;
  top: 50%;
  left: 50%;
`
const SpinnerContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.7;
`

const ContentContainer = styled.div`
  position: relative;
`

const ModalActionsContainer = ({ children, actions, ...rest }) => (
  <ModalActionsWrapper {...rest}>
    <ModalActions {...actions}>{children}</ModalActions>
  </ModalActionsWrapper>
)

ModalActionsContainer.propTypes = {
  ...StyledPropTypes.flexbox,
  ...StyledPropTypes.space,
  ...StyledPropTypes.layout,
  actions: PropTypes.shape({
    ...StyledPropTypes.space,
    ...StyledPropTypes.layout,
  }),
  children: PropTypes.node,
}

ModalActionsContainer.defaultProps = {
  children: null,
  actions: {},
}

const ModalClose = ({ disabled, onClose }) => (
  <ModalCloseContainer>
    <ModalCloseIcon disabled={disabled} onClick={onClose}>
      <MdClose />
    </ModalCloseIcon>
  </ModalCloseContainer>
)

ModalClose.propTypes = {
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
}

ModalClose.defaultProps = {
  disabled: false,
  onClose: noop,
}

const ModalBack = ({ disabled, onBack }) => (
  <ModalBackContainer>
    <ModalBackIcon disabled={disabled} onClick={onBack}>
      <MdArrowBack />
    </ModalBackIcon>
  </ModalBackContainer>
)

ModalBack.propTypes = {
  disabled: PropTypes.bool,
  onBack: PropTypes.func,
}

ModalBack.defaultProps = {
  disabled: false,
  onBack: noop,
}

/** @deprecated Use components/ui/__v3__/Modal */
const Modal = props => {
  const { isOpen, customStyles, theme, isLoading, ...rest } = props

  function renderContentElement(contentProps, children) {
    return (
      <ContentContainer {...contentProps}>
        {children}
        {isLoading && (
          <SpinnerContainer>
            <StyledModalSpinner />
          </SpinnerContainer>
        )}
      </ContentContainer>
    )
  }

  return (
    <ReactModal
      ariaHideApp={false}
      closeTimeoutMS={270}
      contentElement={renderContentElement}
      isOpen={isOpen}
      style={merge(
        {},
        ReactModal.defaultStyles,
        defaultStyles(theme),
        customStyles(theme),
      )}
      {...rest}
    />
  )
}

Modal.Title = ModalTitle
Modal.Close = ModalClose
Modal.Actions = ModalActionsContainer
Modal.Back = ModalBack

Modal.propTypes = {
  customStyles: PropTypes.func,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  onRequestClose: PropTypes.func,
}

Modal.defaultProps = {
  isLoading: false,
  isOpen: false,
  customStyles: noop,
  onRequestClose: noop,
}

/** @deprecated Use components/ui/__v3__/Modal */
export default withTheme(Modal)

import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import {
  CustomCvFieldsByCursorQuery,
  UpdateCustomCvField as UpdateCustomCvFieldMutation,
} from './GraphQL'

export function useUpdateCustomCvField() {
  const [updateCustomCvField, { loading: updating }] = useApolloMutation<
    MutationData<'updateCustomCvField'>,
    Gateway.MutationUpdateCustomCvFieldArgs
  >(UpdateCustomCvFieldMutation, {
    translationKey: 'settings.customFieldsPage.toasts.update',
    refetchQueries: compact([getOperationName(CustomCvFieldsByCursorQuery)]),
  })

  const update = async (
    id: Gateway.Scalars['ID'],
    input: Gateway.UpdateCustomCvFieldInput,
  ) => {
    const result = await updateCustomCvField({
      variables: { input, id },
    })
    return graphQLResultHasError(result)
  }

  return { update, updating }
}

import React from 'react'

import { DateTime } from 'luxon'

import get from 'lodash/get'
import isNaN from 'lodash/isNaN'

import { getHoursFormatByLocale } from 'helpers/date'

export const TIMER_STATES = {
  active: 'active',
  pending: 'pending',
  approved: 'approved',
  discarded: 'discarded',
}

export const PAUSE_TIMER_STATES = {
  active: 'active',
  stopped: 'stopped',
}

export const PAUSE_TIMER_OVERDUE_MINUTES = 1

export const takeGeolocation = () => {
  const { geolocation } = navigator

  return new Promise((resolve, reject) => {
    if (!geolocation) {
      reject(new Error('Geolocation not supported'))
      return
    }

    geolocation.getCurrentPosition(
      position => resolve(position),
      error => reject(error),
    )
  })
}

export const formatISOTimeToHoursFormat = (time, locale = 'en') => {
  const hoursFormat = getHoursFormatByLocale(locale)
  return time ? DateTime.fromISO(time).toFormat(hoursFormat) : ''
}

export const getInitClockingTime = () => {
  // Create a Luxon DateTime from a JS Unix timestamp
  const ts = new Date().getTime() // 1516717417146
  const dt = DateTime.fromMillis(ts).startOf('day') // { ts: 2018-01-23T09:23:37.146-05:00 ...
  return dt.valueOf()
}

export const TICK_TIME = 1000

export const renderIntervalTime = time => {
  if (time) {
    const formattedTime = DateTime.fromISO(time).toFormat('hh:mm a')
    return <span>{formattedTime}</span>
  }
  return <span>&mdash;:&mdash;</span>
}

export const formatClockingTime = time => {
  if (typeof time === 'number' && !isNaN(time)) {
    return DateTime.fromMillis(time).toFormat('HH:mm:ss')
  }
  return null
}

export const getAmountTimerTime = timer => {
  const startAt = get(timer, 'startAt')
  const endAt = get(timer, 'endAt')
  if (startAt && endAt) {
    const diffInMs = DateTime.fromISO(endAt)
      .diff(DateTime.fromISO(startAt))
      .toObject().milliseconds
    const initTime = DateTime.local().startOf('day').toMillis()
    const amount = initTime + diffInMs
    return DateTime.fromMillis(amount).toFormat('HH:mm:ss')
  }
  return null
}

import { StoreonModule } from 'storeon'

import { INIT } from '../constants'

export const LOCALE_MODAL_KEY = 'localeModal'
export const OPEN_LOCALE_MODAL = 'localeModal/open'
export const CLOSE_LOCALE_MODAL = 'localeModal/close'

export interface LocaleModalState {
  [LOCALE_MODAL_KEY]: boolean // isOpen
}

export interface LocaleModalEvents {
  [OPEN_LOCALE_MODAL]: never
  [CLOSE_LOCALE_MODAL]: never
}

const INITIAL_STATE: LocaleModalState = {
  [LOCALE_MODAL_KEY]: false,
}

export const localenModalModule: StoreonModule<
  LocaleModalState,
  LocaleModalEvents
> = store => {
  store.on(INIT, () => INITIAL_STATE)

  store.on(OPEN_LOCALE_MODAL, () => ({
    [LOCALE_MODAL_KEY]: true,
  }))

  store.on(CLOSE_LOCALE_MODAL, () => ({
    [LOCALE_MODAL_KEY]: false,
  }))
}

import { getOperationName } from '@apollo/client/utilities'
import { EmploymentLevelsByCursorQuery } from 'API/EmploymentLevel/GraphQL'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import {
  ArchiveEmploymentTypeMutation,
  EmploymentTypesByCursorQuery,
} from './GraphQL'

export function useArchiveEmploymentType() {
  const [innerArchive, { loading: archiving }] = useApolloMutation<
    MutationData<'archiveEmploymentType'>,
    Gateway.MutationArchiveEmploymentTypeArgs
  >(ArchiveEmploymentTypeMutation, {
    refetchQueries: compact([
      getOperationName(EmploymentTypesByCursorQuery),
      getOperationName(EmploymentLevelsByCursorQuery),
    ]),
  })

  const archiveEmploymentType = async (id: string) =>
    innerArchive({
      variables: {
        id,
      },
    })

  return { archiveEmploymentType, archiving }
}

import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import { clearStaff, initStaff, loadStaff } from 'store/actions/areas'
import { getInitialStaff } from 'store/selectors/areas'

import StaffPicker from './StaffPicker'

const selectors = createStructuredSelector({
  initial: getInitialStaff,
})

const actions = {
  onInit: initStaff,
  onLoad: loadStaff,
  onClear: clearStaff,
}

export default connect(selectors, actions)(StaffPicker)

import { useACL, useAppContext } from 'hooks'

import { Actions } from 'services/ACL/config'
import { WeeklyTimesheetsFeatures } from 'services/ACL/features'

import { TimesheetState } from '../types'

type Props = {
  timeSheetState: TimesheetState
  hasSavedEntries: boolean
}

export function useTimesheetPermissions({
  timeSheetState,
  hasSavedEntries,
}: Props) {
  const { viewer } = useAppContext()
  const isEmployer = viewer.role.employer

  const { canPerformWeeklyTimesheetsManagementAction } = useACL()

  const canAddRecords = isEmployer
    ? timeSheetState === TimesheetState.Draft ||
      timeSheetState === TimesheetState.Pending
    : timeSheetState === TimesheetState.Draft ||
      timeSheetState === TimesheetState.Unsubmitted

  const canSaveAsEmployee =
    timeSheetState === TimesheetState.Draft ||
    timeSheetState === TimesheetState.Unsubmitted
  const canSubmitAsEmployee = canSaveAsEmployee && hasSavedEntries

  const canUnsubmitAsManager = timeSheetState === TimesheetState.Pending
  const canForceSubmitAsManager = timeSheetState === TimesheetState.Unsubmitted
  const canSaveAndApproveAsManager =
    timeSheetState === TimesheetState.Draft ||
    timeSheetState === TimesheetState.Pending

  const canUnapproveAsManager = timeSheetState === TimesheetState.Approved
  const canDiscardAsManager = timeSheetState === TimesheetState.Pending
  const canRestoreAsManager = timeSheetState === TimesheetState.Discarded

  const canDeleteWeeklyTimesheet = canPerformWeeklyTimesheetsManagementAction({
    featureId: WeeklyTimesheetsFeatures.DeleteWeeklyTimesheet,
    action: Actions.doAction,
  })

  return {
    canAddRecords,

    canSaveAsEmployee,
    canSubmitAsEmployee,

    canUnsubmitAsManager,
    canSaveAndApproveAsManager,
    canForceSubmitAsManager,
    canUnapproveAsManager,
    canDiscardAsManager,
    canRestoreAsManager,

    canDeleteWeeklyTimesheet,

    isEmployer,
  }
}

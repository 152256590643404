import { useApolloMutation } from 'API/services/Apollo'

import { CreatePayFrequencyMutation } from './GraphQL'

export function useCreatePayFrequency() {
  const [createPayFrequency, { loading: creating }] = useApolloMutation<
    MutationData<'createPayFrequency'>,
    Gateway.MutationCreatePayFrequencyArgs
  >(CreatePayFrequencyMutation, {
    translationKey: 'payFrequency.toasts.create',
  })

  const create = async (
    variables: Omit<Gateway.CreatePayFrequencyInput, 'companyId'>,
  ) => {
    await createPayFrequency({
      variables: {
        input: variables,
      },
    })
  }

  return { create, creating }
}

import { useForm, useWatch } from 'react-hook-form'

import { UnavailabilityReasons, UnavailableOptionsFormState } from '../types'

export function useUnavailableOptionsForm() {
  const DEFAULT_VALUES: UnavailableOptionsFormState = {
    reason: UnavailabilityReasons.Whatever,
  }

  const { control } = useForm<UnavailableOptionsFormState>({
    mode: 'all',
    defaultValues: DEFAULT_VALUES,
  })

  const reason = useWatch({
    control,
    name: 'reason',
  })

  return { reason, control }
}

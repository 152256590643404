import { TimetypeEssentialFieldsItem } from 'API/TimeTypes/GraphQL/Fragments/types'

import { TimeTypePickerOption } from './types'

export function timeTypeToOption(
  timeType: TimetypeEssentialFieldsItem,
): TimeTypePickerOption {
  return {
    id: timeType.id,
    value: timeType.id,
    label: timeType.name,
    color: timeType.color,
  }
}

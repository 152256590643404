import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import {
  CustomCvFieldsByCursorQuery,
  UpdateCustomCvFields as UpdateCustomCvFieldsMutation,
} from './GraphQL'

export function useUpdateCustomCvFields() {
  const [updateCustomCvFields, { loading: updating }] = useApolloMutation<
    MutationData<'updateCustomCvFields'>,
    Gateway.MutationUpdateCustomCvFieldsArgs
  >(UpdateCustomCvFieldsMutation, {
    translationKey: 'settings.customFieldsPage.toasts.update',
    refetchQueries: compact([getOperationName(CustomCvFieldsByCursorQuery)]),
  })

  const update = async (input: Gateway.UpdateCustomCvFieldInputWithIds[]) => {
    await updateCustomCvFields({ variables: { input } })
  }

  return { update, updating }
}

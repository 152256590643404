import React from 'react'

import { Divider } from 'components/ui/__v2__/Divider'
import { Flex, Span } from 'components/ui/__v2__/Grid'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { ItemContainer } from './styles'

export function SideInfo() {
  const t = useI18n<typeof TRANSLATIONS.timeOffModal.estimatedBalances>(
    'timeOffModal.estimatedBalances',
  )

  return (
    <ItemContainer minWidth="130px">
      <Flex height="40px">
        <Span fontWeight={2}>{t('title')}</Span>
      </Flex>

      <Flex flexDirection="column">
        <Span>{t('availableBalance')}</Span>
        <Span>{t('totalBooked')}</Span>
      </Flex>

      <Flex flexDirection="column" gap={2} height="29px">
        <Divider position="absolute" width="100%" />
        <Span mt="auto">{t('remainingBalance')}</Span>
      </Flex>
    </ItemContainer>
  )
}

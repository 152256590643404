import { graphQLResultHasError } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { UpdateShiftJobsMutation } from './GraphQL'

export function useUpdateShiftJobs() {
  const t = useI18n<typeof TRANSLATIONS.shiftJobs.toasts.update>(
    'shiftJobs.toasts.update',
  )

  const [innerUpdateShiftJobs, { loading }] = useApolloMutation<
    MutationData<'updateShiftJobs'>,
    Gateway.MutationUpdateShiftJobsArgs
  >(UpdateShiftJobsMutation, {
    silent: true,
    onError: error => {
      showToast({
        type: 'error',
        title: t('error.title'),
        content: error.message || t('error.message'),
      })
    },
  })

  async function updateShiftJobs(input: Gateway.UpdateShiftJobsInput) {
    const result = await innerUpdateShiftJobs({
      variables: {
        input,
      },
    })

    return graphQLResultHasError(result) || !!result.errors
  }

  return {
    updateShiftJobs,
    updatingShiftJobs: loading,
  }
}

import { useCallback } from 'react'

import { useStoreon } from 'storeon/react'

import { QUERY_PARAMS_SET, QUERY_PARAMS_STATE_KEY } from './constants'
import {
  QueryParamsEvents,
  QueryParamsPayload,
  QueryParamsState,
} from './types'

export function useQueryParamsState() {
  const { [QUERY_PARAMS_STATE_KEY]: queryParams, dispatch } = useStoreon<
    QueryParamsState,
    QueryParamsEvents
  >(QUERY_PARAMS_STATE_KEY)

  const updateQueryParams = useCallback(
    (payload: QueryParamsPayload) => dispatch(QUERY_PARAMS_SET, payload),
    [dispatch],
  )

  return { queryParams, updateQueryParams }
}

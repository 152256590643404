import React from 'react'
import { Field, useField } from 'react-final-form'
import { PropTypes } from 'prop-types'

import Helper from 'components/ui/__v2__/Checkbox/Helper'
import { FormRadio } from 'components/ui/__v2__/Form'
import { ValidationError } from 'components/ui/__v2__/Form/styles'
import { Flex, SpacedColumn, Span } from 'components/ui/__v2__/Grid'
import { RadioLabel } from 'components/ui/__v2__/Radio'
import { IconError } from 'components/ui/__v3__/Input/Errors'

import { Answer } from '../styles'

function RadioGroup({
  name,
  disabled,
  isReadOnly,
  isRequired,
  label,
  options,
}) {
  const { meta } = useField(name)
  return (
    <Flex flexDirection="column">
      <Span>
        {label}
        {isRequired && <sup>*</sup>}
      </Span>
      <SpacedColumn>
        {options.map(option => (
          <RadioLabel key={option.value}>
            <Field name={name} type="radio" value={option.value}>
              {({ input }) => (
                <>
                  <FormRadio
                    disabled={disabled || isReadOnly}
                    input={input}
                    meta={{}}
                    size={18}
                    value={option.value}
                  />
                  {isReadOnly && input.checked ? (
                    <Answer>{option.label}</Answer>
                  ) : (
                    <Span>{option.label}</Span>
                  )}
                </>
              )}
            </Field>
          </RadioLabel>
        ))}
      </SpacedColumn>
      <Helper>
        {meta.error && meta.touched && (
          <ValidationError>
            <IconError>{meta.error}</IconError>
          </ValidationError>
        )}
      </Helper>
    </Flex>
  )
}

RadioGroup.defaultProps = {
  disabled: false,
  isReadOnly: false,
}

RadioGroup.propTypes = {
  ...Field.propTypes,
  disabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  options: PropTypes.array.isRequired,
}

export default RadioGroup

import { useStoreon } from 'storeon/react'

import {
  CLOSE_LOCALE_MODAL,
  LOCALE_MODAL_KEY,
  LocaleModalState,
  OPEN_LOCALE_MODAL,
} from 'services/StoreOn/Locale/localeModal'

export function useLocaleModal() {
  const { localeModal, dispatch } = useStoreon<LocaleModalState>(
    LOCALE_MODAL_KEY,
  )

  const open = () => dispatch(OPEN_LOCALE_MODAL)
  const close = () => dispatch(CLOSE_LOCALE_MODAL)

  return { isOpen: localeModal, open, close }
}

import { DateTime } from 'luxon'
import { SelectOption } from 'Types/common'

import { compact, fromPairs, isNull, isNumber, round } from 'lodash'

import { DemandForecastingRoles } from 'constants/demandForecastingDemo'

import Utils from 'services/Utils'

import {
  ChartDataSlice,
  DataSlice,
  FTEDistributionByRole,
  HeatMapDataSlice,
  HeatMapDisplayModes,
} from '../types'

export function dataToHeatMap(
  data: DataSlice[],
  role: SelectOption<DemandForecastingRoles> | null,
): HeatMapDataSlice[] {
  return data.map(item => ({
    date: item.date.toFormat('yyyy-LL-dd'),
    count: !role ? reduceFTEByRoleToSummary(item) : item[role.value] ?? 0,
  }))
}

function reduceFTEByRoleToSummary(distribution: FTEDistributionByRole): number {
  return distribution.Cashier + distribution.Manager + distribution.Stocker
}

type PrepareChartDataArgs = {
  scheduled: DataSlice[]
  forecasted?: DataSlice[]
  role: SelectOption<DemandForecastingRoles> | null
}

export function prepareChartData({
  scheduled,
  forecasted,
  role,
}: PrepareChartDataArgs): ChartDataSlice[] {
  const dateTimes = chartDateTimes({ scheduled, forecasted })

  const scheduledData = fromPairs(
    scheduled.map(item => sliceToTrack(item, role?.value)),
  )

  const forecastedData = forecasted
    ? fromPairs(forecasted.map(item => sliceToTrack(item, role?.value)))
    : {}

  return dateTimes.map(dt => {
    const date = dt.toISODate()
    const scheduled = scheduledData?.[date] ?? 0
    const forecasted = forecastedData?.[date] ?? null
    const hasDiff = isNumber(scheduled) && isNumber(forecasted)
    const isShortage = hasDiff && scheduled < forecasted
    const isAbundance = hasDiff && scheduled > forecasted

    const shortage = isShortage
      ? ([scheduled, forecasted] as [number, number])
      : null
    const abundance = isAbundance
      ? ([forecasted, scheduled] as [number, number])
      : null

    return {
      date,
      scheduled,
      forecasted,
      diff: shortage ?? abundance,
      diffType: isShortage ? 'shortage' : 'abundance',
    }
  })
}

function chartDateTimes({
  scheduled,
  forecasted,
}: Omit<PrepareChartDataArgs, 'role'>): DateTime[] {
  const scheduledStartDate = scheduled[0].date
  const forecastStartDate = forecasted ? forecasted[0].date : null

  const scheduledEndDate = scheduled[scheduled.length - 1].date
  const forecastEndDate = forecasted
    ? forecasted[forecasted.length - 1].date
    : null

  const allDates = compact([
    scheduledStartDate,
    forecastStartDate,
    scheduledEndDate,
    forecastEndDate,
  ])

  const start = DateTime.min(...allDates)
  const end = DateTime.max(...allDates)

  return Utils.DateTime.datetimeListFromStartEnd({ start, end })
}

function sliceToTrack(
  slice: DataSlice,
  role?: DemandForecastingRoles,
): [string, number] {
  if (!role) {
    return [
      slice.date.toISODate(),
      round(slice.Cashier + slice.Manager + slice.Stocker, 1),
    ]
  }

  return [slice.date.toISODate(), slice[role] ?? 0]
}

export function chartDataToHeatMapData(
  chartData: ChartDataSlice[],
  mode: HeatMapDisplayModes,
): HeatMapDataSlice[] {
  if (mode === HeatMapDisplayModes.Scheduled) {
    return chartData
      .filter(item => Boolean(item.scheduled))
      .map(item => ({
        date: item.date,
        count: round(item.scheduled!, 1),
      }))
  }

  if (mode === HeatMapDisplayModes.Forecasted) {
    return chartData
      .filter(item => Boolean(item.forecasted))
      .map(item => ({
        date: item.date,
        count: round(item.forecasted!, 1),
      }))
  }

  return chartData
    .filter(item => !isNull(item.forecasted) && !isNull(item.scheduled))
    .map(item => ({
      date: item.date,
      count: round(item.scheduled! - item.forecasted!, 1),
    }))
}

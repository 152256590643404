import { StoreonModule } from 'storeon'

import {
  CLASSIFIED_TIME_TEMPORAL_WORKFLOW_SET,
  CLASSIFIED_TIME_TEMPORAL_WORKFLOW_STATE_KEY,
  CLASSIFIED_TIME_TEMPORAL_WORKFLOW_UNSET,
  INITIAL_STATE,
} from '../constants'
import {
  ClassifiedTimeTemporalWorkflowEvents,
  ClassifiedTimeTemporalWorkflowState,
} from '../types'

export const classifiedTimeTemporalWorkflowModule: StoreonModule<
  ClassifiedTimeTemporalWorkflowState,
  ClassifiedTimeTemporalWorkflowEvents
> = store => {
  store.on('@init', () => ({
    [CLASSIFIED_TIME_TEMPORAL_WORKFLOW_STATE_KEY]: INITIAL_STATE,
  }))

  store.on(
    CLASSIFIED_TIME_TEMPORAL_WORKFLOW_SET,
    (state, { temporalWorkflowId, temporalRunId }) => ({
      ...state,
      [CLASSIFIED_TIME_TEMPORAL_WORKFLOW_STATE_KEY]: {
        ...state[CLASSIFIED_TIME_TEMPORAL_WORKFLOW_STATE_KEY],
        temporalWorkflowId,
        temporalRunId,
      },
    }),
  )

  store.on(CLASSIFIED_TIME_TEMPORAL_WORKFLOW_UNSET, state => ({
    ...state,
    [CLASSIFIED_TIME_TEMPORAL_WORKFLOW_STATE_KEY]: INITIAL_STATE,
  }))
}

import map from 'lodash/map'

import { PAGE_SIZE } from 'constants/pagination'

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getBranchId } from 'store/selectors/viewer'

export const LOAD_MANAGER = createAsyncAction('managers/LOAD_ONE')
export const LOAD_BRANCH_MANAGERS = createAsyncAction('managers/LOAD_BRANCH')
export const ADD_BRANCHES_TO_MANAGER = createAsyncAction('manager/ADD_BRANCHES')
export const DELETE_BRANCHES_FROM_MANAGER = createAsyncAction(
  'manager/DELETE_BRANCHES',
)
export const ADD_DEPARTMENTS_TO_MANAGER = createAsyncAction(
  'manager/ADD_DEPARTMENTS',
)
export const DELETE_DEPARTMENTS_FROM_MANAGER = createAsyncAction(
  'manager/DELETE_DEPARTMENTS',
)
export const CLEAR_MANAGER = 'managers/CLEAR_ONE'

const managerInclude = [
  'user.profile.profileAvatar',
  'branches.jobs',
  'branches.departments',
  'departments',
  'managerPermissions',
  'employee.departments',
  'employee.jobs',
  'all_managed_departments.department',
  'all_managed_departments.branch',
  'all_managed_branches.branch',
]

export const loadManager = managerId => {
  return apiCall({
    endpoint: `/managers/${managerId}`,
    types: LOAD_MANAGER,
    query: {
      include: managerInclude.join(),
    },
  })
}

export const loadBranchManagers = (branchId = null) => {
  return (dispatch, getState) => {
    dispatch(
      apiCall({
        endpoint: `/branches/${
          branchId || getBranchId(getState())
        }/relationships/managers`,
        types: LOAD_BRANCH_MANAGERS,
        query: {
          include: managerInclude.join(),
          page: { size: PAGE_SIZE['100'] },
        },
        paged: true,
      }),
    )
  }
}

export const clearManager = () => ({
  type: CLEAR_MANAGER,
})

export const addBranchesToManager = (managerId, branchIds) => {
  return apiCall({
    method: 'POST',
    endpoint: `/managers/${managerId}/relationships/branches`,
    types: ADD_BRANCHES_TO_MANAGER,
    query: {
      data: map(branchIds, branchId => ({ id: branchId, type: 'branches' })),
      include: 'branches',
    },
  })
}

export const deleteBranchesFromManager = (managerId, branchIds) => {
  return apiCall({
    method: 'DELETE',
    endpoint: `/managers/${managerId}/relationships/branches`,
    types: DELETE_BRANCHES_FROM_MANAGER,
    query: {
      data: map(branchIds, branchId => ({ id: branchId, type: 'branches' })),
      include: 'branches',
    },
  })
}

export const addDepartmentsToManager = (managerId, departmentIds) => {
  return apiCall({
    method: 'POST',
    endpoint: `/managers/${managerId}/relationships/departments`,
    types: ADD_DEPARTMENTS_TO_MANAGER,
    query: {
      data: map(departmentIds, departmentId => ({
        id: departmentId,
        type: 'departments',
      })),
      include: 'departments',
    },
  })
}

export const deleteDepartmentsFromManager = (managerId, departmentIds) => {
  return apiCall({
    method: 'DELETE',
    endpoint: `/managers/${managerId}/relationships/departments`,
    types: DELETE_DEPARTMENTS_FROM_MANAGER,
    query: {
      data: map(departmentIds, departmentId => ({
        id: departmentId,
        type: 'departments',
      })),
      include: 'departments',
    },
  })
}

import React from 'react'
import { components } from 'react-select'

import { FiX } from 'react-icons/fi'

export const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <FiX />
  </components.ClearIndicator>
)

ClearIndicator.propTypes = {
  ...components.ClearIndicator.propTypes,
}

import { all, fork, put, select, take } from 'redux-saga/effects'

import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import { parseContentType } from 'constants/groupChat'
import { ACCESS_LEVEL } from 'constants/ids'

import { loadDepartments } from 'store/actions/company/departments'
import {
  CREATE_CHAT,
  holdChatChannel,
  INIT,
  LOAD_CHAT_MESSAGES,
  loadChatMessages,
  loadChats,
  loadUnreadCounts,
  markChatLoaded,
  markChatReaded,
  removeLocalFile,
  sendMessage,
  TOGGLE_CHAT,
  toggleChat,
  unholdChatChannel,
  UPLOAD_FILE,
} from 'store/actions/groupChat'
import {
  getActiveChatId,
  getActiveChatUnreadCount,
  getChats,
  getLoadedChatIds,
} from 'store/selectors/groupChat'
import { getViewer } from 'store/selectors/viewer'

function* initialLoad() {
  while (true) {
    yield take(INIT)
    const chats = yield select(getChats)
    if (isEmpty(chats)) {
      yield put(loadChats())
      yield put(loadUnreadCounts())
    }
    const viewer = yield select(getViewer)
    if (get(viewer, 'accessLevel', '') === ACCESS_LEVEL.employee) {
      const employeeBranchId = get(viewer, 'employees[0].branch.id', '')
      if (employeeBranchId) {
        yield put(loadDepartments(employeeBranchId))
      }
    }
  }
}

function* toggleChatСhannel() {
  while (true) {
    const {
      payload: { unholdPrevChannel },
    } = yield take(TOGGLE_CHAT)
    if (unholdPrevChannel) {
      yield put(unholdChatChannel())
    }
    const activeChatId = yield select(getActiveChatId)

    if (activeChatId) {
      yield put(loadChatMessages())
      yield put(holdChatChannel(activeChatId))
    }
  }
}

function* toggleCreatedChat() {
  while (true) {
    const {
      payload: { data },
    } = yield take(CREATE_CHAT.SUCCESS)
    const chats = get(data, 'chats', null)
    const createdChatId = isNull(chats) ? null : Object.keys(chats)[0]
    if (createdChatId) {
      yield put(toggleChat(createdChatId))
    }
  }
}

function* markReadedChat() {
  while (true) {
    yield take(LOAD_CHAT_MESSAGES.SUCCESS)
    const activeChatUnreadCount = yield select(getActiveChatUnreadCount)
    if (!isNull(activeChatUnreadCount)) {
      yield put(markChatReaded())
    }
  }
}

function* markLoadedChat() {
  while (true) {
    const { payload } = yield take(LOAD_CHAT_MESSAGES.SUCCESS)
    const chats = get(payload, 'data.chats', [])
    const chatId = !isEmpty(chats) ? Object.keys(chats)[0] : null
    const loadedChatIds = yield select(getLoadedChatIds)
    if (!isNull(chatId) && indexOf(loadedChatIds, chatId) === -1) {
      yield put(markChatLoaded(chatId))
    }
  }
}

function* fileUploaded() {
  while (true) {
    const { payload } = yield take(UPLOAD_FILE.SUCCESS)
    const files = get(payload, 'files')
    const id = Object.keys(files)[0]
    const file = files[id]

    const uploadedFiles = get(payload, 'data.files')
    const uploadedId = Object.keys(uploadedFiles)
    const uploadedFile = uploadedFiles[uploadedId]
    const contentType = parseContentType(
      get(uploadedFile, 'attributes.type', ''),
    )

    yield put(sendMessage(null, uploadedFile.id, contentType))
    yield put(removeLocalFile(file.id))
  }
}

export default function* root() {
  yield all([
    fork(toggleChatСhannel),
    fork(initialLoad),
    fork(fileUploaded),
    fork(markReadedChat),
    fork(markLoadedChat),
    fork(toggleCreatedChat),
  ])
}

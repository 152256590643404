import React from 'react'
import { Field } from 'react-final-form'
import { PropTypes } from 'prop-types'

import { DateTime } from 'luxon'

import isObject from 'lodash/isObject'

import { FormDayPicker } from 'components/ui/__v2__/Form'
import { Flex, Span } from 'components/ui/Grid'

import { isoToLocaleRange } from 'helpers/date'

import _ from 'i18n'

import { Answer, NoAnswer } from '../styles'

function parseDateInputToText(dateInput) {
  if (isObject(dateInput)) {
    const { from, to, startAt, endAt } = dateInput
    return isoToLocaleRange(from || startAt, to || endAt)
  }
  return DateTime.fromISO(dateInput).toLocaleString()
}
function LabeledDatePicker({
  name,
  isRequired,
  isReadOnly,
  onChange,
  label,
  dayOnly,
  ...rest
}) {
  const element = props => {
    if (isReadOnly) {
      const dateInput = props.input.value
      return dateInput ? (
        <Answer>{parseDateInputToText(dateInput)}</Answer>
      ) : (
        <NoAnswer>{_('formBuilder.fallbacks.noAnswerProvided')}</NoAnswer>
      )
    }
    return (
      <FormDayPicker
        {...props}
        {...rest}
        dayOnly={dayOnly}
        withInput={dayOnly}
        onBlur={onChange}
      />
    )
  }

  return (
    <Flex alignItems="center" flexDirection="row">
      <Span mr={2}>
        {label}
        {isRequired && <sup>*</sup>}
      </Span>
      <Field name={name}>{element}</Field>
    </Flex>
  )
}

LabeledDatePicker.defaultProps = {
  isReadOnly: false,
}

LabeledDatePicker.propTypes = {
  ...Field.propTypes,
  isReadOnly: PropTypes.bool,
}

export default LabeledDatePicker

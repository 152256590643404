import map from 'lodash/map'

export const SORT_OPTIONS = {
  startTime: 'Start time',
  nameAsc: 'Name A-z',
  nameDesc: 'Name Z-a',
}

export const mapSelectOptions = (items, sort = false) =>
  map(items, item => ({
    label: sort ? item : item.name,
    value: sort ? item : item.id,
  }))

import { DateTime, Interval } from 'luxon'

import { thinFormEmployee } from 'components/blocks/__v3__/HookForm/FormEmployeesPicker'
import { DatesListItem } from 'components/blocks/__v3__/HookForm/FormSelectedDatesList'

import { ScheduleConflictActionTypes } from 'constants/ids'

import { employeeToOption } from 'services/Options'
import Utils from 'services/Utils'

import { ModalProps } from '../types'

export function generateDaysList(
  { from, to }: { from: string; to: string },
  disableShoulders: boolean = true,
): DatesListItem[] {
  const datetimes = Utils.DateTime.datetimeListFromStartEnd({
    start: DateTime.fromISO(from),
    end: DateTime.fromISO(to),
  })

  return datetimes.map((datetime, index, all) => {
    const last = index === all.length - 1
    const first = index === 0

    return {
      label: datetime.toISODate(),
      value: true,
      disabled: disableShoulders && (last || first),
    }
  })
}

export function hoursFromDateAndStartEndSeconds(
  date: string,
  start: number,
  end: number,
): number {
  const { startDatetime, endDatetime } = partialInputsToDateTimes(
    date,
    start,
    end,
  )

  return Number(
    Interval.fromDateTimes(startDatetime, endDatetime)
      .toDuration()
      .toFormat('h'),
  )
}

export function partialInputsToDateTimes(
  date: string,
  start: number,
  end: number,
): { startDatetime: DateTime; endDatetime: DateTime } {
  const startDate = DateTime.fromISO(date)
  const startDatetime = DateTime.fromISO(date).plus({ seconds: start })
  const endDatetime = (start < end
    ? startDate
    : startDate.plus({ days: 1 })
  ).plus({ seconds: end })

  return { startDatetime, endDatetime }
}

export function getDefaultValues(targetEmployee: ModalProps['targetEmployee']) {
  const defaultNoEmployee = {
    leaveType: null,
    partial: false as const,
    reason: '',
    document: null,
    period: {}, // Note: This is bad, we need DayPicker to accept undefined
    dates: [],
    scheduleConflictType: ScheduleConflictActionTypes.Split,
  }

  if (targetEmployee) {
    return {
      ...defaultNoEmployee,
      employee: thinFormEmployee(employeeToOption(targetEmployee)),
    }
  }

  return defaultNoEmployee
}

import { Employee, User } from 'Types/app'
import { AreasFilter } from 'Types/common'

import { AvailabilityRequestState } from 'constants/gatewayGraphQL'
import {
  AvailabilityRequestTypes,
  ScheduleConflictActionTypes,
} from 'constants/ids'

export enum CreateAvailabilityRequestKinds {
  Create = 'Create',

  UpdateOne = 'UpdateOne',
  UpdateAll = 'UpdateAll',
  UpdateAllFromDate = 'UpdateAllFromDate',

  DeleteOne = 'DeleteOne',
  DeleteAll = 'DeleteAll',
  DeleteAllFromDate = 'DeleteAllFromDate',
}

export enum Requestor {
  Employee = 'employee',
  Manager = 'manager',
}

export enum RequestSubmissionStatus {
  AwaitingApproval = 'awaitingApproval',
  Confirmed = 'confirmed',
}

interface BaseAvailabilityRequestJson {
  id: string

  employee: Employee

  type: AvailabilityRequestTypes

  before: AvailabilityRequestChangeData | null
  after: AvailabilityRequestChangeData | null
  conflictingAvailabilities: {
    availabilityId: string
    startAt: string
    endAt: string
    conflictingDates: string[]
  }[]

  notes: string | null

  createdAt: string
  createdBy: User | null

  processedAt: string | null
  processedBy: User | null
}

export type AvailabilityRequestChangeData = {
  startAt: string
  endAt: string

  recurrence?: string
  recurrenceUntil?: string
}

// Note: all this is required because of the diffrences between GW and Ruby enum casing

export interface AvailabilityRequestJson extends BaseAvailabilityRequestJson {
  state: Lowercase<Gateway.AvailabilityRequestState>
}

export interface AvailabilityRequest extends BaseAvailabilityRequestJson {
  state: Gateway.AvailabilityRequestState
}

// ==============================================================================================
// Args & Filters
// ==============================================================================================

export interface CreateAvailabilityRequestArgs {
  requestType: CreateAvailabilityRequestKinds
  employeeId: string
  startAt?: string
  endAt?: string
  recurrence?: string
  availabilityId?: number
  deletedDate?: string
  conflictAction?: ScheduleConflictActionTypes
  isRequestorEmployee?: boolean
  availabilitiesAutoApprove?: boolean
}

export interface MutateAvailabilityRequestArgs {
  mode: 'approve' | 'reject' | 'withdraw'
  requestId: string
  notes?: string
  conflictAction?: ScheduleConflictActionTypes
}

export interface AvailabilityRequestFilter {
  areas?: AreasFilter
  status?: AvailabilityRequestState
  display: 'employee' | 'manager'
}

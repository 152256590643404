import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FiTrash2 } from 'react-icons/fi'

import noop from 'lodash/noop'

import { Button } from 'components/ui/__v2__/Button'

import _ from 'i18n'

import { Buttons, Modal, StyledClose, Text, Wrapper } from './styles'

const CLOSE_TIMEOUT_MS = 170
/**
 * @deprecated import { Modal } from 'components/ui/__v3__'
 */
export class ModalWindow extends Component {
  onOkClick = () => {
    const { callback } = this.props
    if (callback) callback(true)
  }

  onCancelClick = () => {
    const { callback } = this.props
    if (callback) callback(false)
  }

  render() {
    const {
      text,
      children,
      cancelText,
      deleteText,
      isOverflow,
      width,
      buttonsWidth,
      removeIcon,
    } = this.props
    return (
      <Modal
        appElement={document.getElementById('app')}
        closeTimeoutMS={CLOSE_TIMEOUT_MS}
        isOpen
        isOverflow={isOverflow}
        onRequestClose={this.onCancelClick}
      >
        {children || (
          <Wrapper width={width}>
            <StyledClose onClick={this.onCancelClick} />

            <Text>{text}</Text>
            <Buttons>
              <Button
                secondary
                width={buttonsWidth}
                onClick={this.onCancelClick}
              >
                {cancelText || _('actions.cancel')}
              </Button>
              <Button width={buttonsWidth} onClick={this.onOkClick}>
                {removeIcon && (
                  <FiTrash2 size={16} style={{ marginRight: 4 }} />
                )}
                {deleteText || _('actions.yesDelete')}
              </Button>
            </Buttons>
          </Wrapper>
        )}
      </Modal>
    )
  }
}

ModalWindow.defaultProps = {
  removeIcon: true,
  text: null,
  cancelText: null,
  deleteText: null,
  callback: noop,
  children: null,
  isOverflow: true,
  width: 600,
  buttonsWidth: 160,
}

ModalWindow.propTypes = {
  buttonsWidth: PropTypes.number,
  callback: PropTypes.func,
  cancelText: PropTypes.string,
  children: PropTypes.node,
  deleteText: PropTypes.string,
  isOverflow: PropTypes.bool,
  removeIcon: PropTypes.bool,
  text: PropTypes.node,
  width: PropTypes.number,
}

export { Text as ModalText }

import React, { useState } from 'react'

import { WidthProps } from 'styled-system'

import { API } from 'API'
import { SelectOption } from 'Types/common'

import { Select } from 'components/ui/__v3__/Select'

import { PAGE_SIZE } from 'constants/pagination'

import { integrationToOption } from './helpers'

type Props = WidthProps & {
  value: SelectOption<string> | null
  disabled: boolean
  onChange: (value: SelectOption<string> | null) => void
}

export function IntegrationsPicker({
  value,
  disabled,
  onChange,
  ...rest
}: Props) {
  const [enabledLoading, setEnabledLoading] = useState(false)
  const { integrations, integrationsLoading } = API.Integration.byCursor(
    {
      enabled: enabledLoading,
      page: {
        size: PAGE_SIZE['25'],
      },
    },
    { fetchPolicy: 'cache-first' },
  )

  const handleMenuOpen = () => setEnabledLoading(true)

  const defaultOptions = integrations.map(integrationToOption)

  return (
    <Select
      {...rest}
      // @ts-ignore
      async
      defaultOptions={defaultOptions}
      isClearable
      isDisabled={disabled || integrationsLoading}
      isLoading={integrationsLoading}
      value={value}
      withPortal
      onChange={onChange}
      onMenuOpen={handleMenuOpen}
    />
  )
}

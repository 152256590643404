import { useQuery } from '@tanstack/react-query'
import { LOCATION_JSON_API_TYPE } from 'API/Location/constants'
import { LocationEntity } from 'API/Location/types'
import { transformResponseToEntities } from 'API/services/utils'

import coreApiCall from 'services/API/coreApi'

type LocationQueryArgs = {
  companyId: string
}

function defaultLocationQuery({ companyId }: LocationQueryArgs) {
  const query = {
    page: {
      size: 1,
    },
    display: 'managed',
  }

  return coreApiCall({
    endpoint: `/companies/${companyId}/branches`,
    query,
  })
}

export function useDefaultLocation(
  isViewerEmployee: boolean,
  companyId?: string,
) {
  const { data, isFetching, error } = useQuery({
    refetchOnWindowFocus: false,
    enabled: Boolean(companyId) && !isViewerEmployee,

    queryKey: ['defaultBranch', companyId],
    queryFn: () => defaultLocationQuery({ companyId: companyId! }),

    select: (response: JsonApi.Response) => {
      const { entities } = transformResponseToEntities<LocationEntity>({
        response,
        jsonApiType: LOCATION_JSON_API_TYPE,
      })

      return entities[0]
    },
  })

  return {
    defaultLocation: data,
    defaultLocationLoading: isFetching,
    defaultLocationError: error,
  }
}

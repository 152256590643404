import React from 'react'

import { Popover } from 'components/ui/__v2__/Popover'

import { i18n } from 'i18n'

import { IconButton, UndoIcon } from './styles'

type Props = {
  disabled?: boolean
  onClick: () => void
}

export function RevertButton({ disabled, onClick }: Props) {
  return (
    // @ts-ignore
    <Popover content={i18n(`shiftAssign.assign.existing`)}>
      <IconButton disabled={disabled} p={0} onClick={onClick}>
        <UndoIcon />
      </IconButton>
    </Popover>
  )
}

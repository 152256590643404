import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

export const MOVE_TIMEOFFS = createAsyncAction('company/MOVE_TIMEOFFS')

export const moveTimeoff = ({ timeoffId, attributes }) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/timeoffs/${timeoffId}/moving_requests`,
      types: MOVE_TIMEOFFS,
      method: 'POST',
      query: {
        data: {
          attributes,
        },
      },
    }),
  )
}

import { createDeleteHandler, createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { ADD_JOB, DELETE_JOB, SAVE_JOB } from 'store/actions/company/jobs'
import { LOAD_VIEWER_BRANCHES } from 'store/actions/viewer'

const initialState = Immutable({
  isLoaded: false,
  isLoading: false,
  jobs: [],
  isSlugUnique: false,
  isSlugUniqueChecking: false,
})

const handlers = {
  [DELETE_JOB.SUCCESS]: createDeleteHandler('jobs'),

  [SAVE_JOB.REQUEST]: state => state.merge({ isLoading: true }),
  [SAVE_JOB.SUCCESS]: createLoadHandler('jobs'),
  [SAVE_JOB.FAILURE]: state => state.merge({ isLoading: false }),

  [ADD_JOB.SUCCESS]: createLoadHandler('jobs'),

  [LOAD_VIEWER_BRANCHES.SUCCESS]: createLoadHandler('jobs'),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

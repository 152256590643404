import { useCreateShiftTag } from './useCreateShiftTag'
import { useRemoveShiftTag } from './useRemoveShiftTag'
import { useShiftTagsByOffset } from './useShiftTagsByOffset'
import { useUpdateShiftTag } from './useUpdateShiftTag'

export * from './types'

export const ShiftTagsAPI = {
  create: useCreateShiftTag,
  remove: useRemoveShiftTag,
  update: useUpdateShiftTag,
  getByOffset: useShiftTagsByOffset,
}

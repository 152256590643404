import * as colors from './colors'

export const input = {
  caret: colors.GARDA_100,

  bg: {
    default: colors.WHITE,
    disabled: colors.SEASHELL,
  },

  border: {
    default: colors.ATHENS_GRAY_40,
    errored: colors.GARDA_100,
    disabled: colors.CHARCOAL_40,
  },

  placeholder: colors.SPUN_PEARL,

  label: {
    color: colors.CHARCOAL_60,
  },

  helper: {
    color: colors.CHARCOAL_60,
  },

  errors: {
    icon: {
      color: colors.CHARCOAL_60,
    },

    text: {
      color: colors.GARDA_100,
    },
  },
}

import clone from 'lodash/clone'
import compact from 'lodash/compact'
import cond from 'lodash/cond'
import constant from 'lodash/constant'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import negate from 'lodash/negate'
import setWith from 'lodash/setWith'

import { valueOrNA } from 'components/ui/__v3__/__helpers__'

export const validateEmail = email =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email,
  )

export function arrayMove(arr, oldIndex, newIndex) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1

    while ((k -= 1)) {
      arr.push(undefined)
    }
  }

  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
}

export function setIn(initial, path, value) {
  return setWith(clone(initial), path, value, clone)
}

export function valueOrNull(value) {
  return cond([
    [isEmpty, constant(null)],
    [negate(isEmpty), constant(value)],
  ])(isArray(value) ? compact(value) : value)
}

export function prepareValueOrNA(value) {
  return valueOrNA(String(value ?? ''))
}

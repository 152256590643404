import { Branch, Department, Job } from 'Types/app'
import { SelectOption } from 'Types/common'

import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'

interface CascadeSelectOption extends SelectOption {
  id: string
  location?: Branch
  department?: Department
  job?: Job
}

export function getAreasIds(
  areas: CascadeSelectOption[] | CascadeSelectOption,
): string | string[] {
  if (isEmpty(areas)) {
    return []
  }

  if (!isArray(areas)) {
    return areas.value
  }

  return areas.map(area => area.value)
}

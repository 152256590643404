import { InternalSelectOption } from './types'

import { SelectOption } from '../../types'

export function sortOptions(options: InternalSelectOption[]) {
  return options
    .sort((a, b) => a.label.localeCompare(b.label))
    .sort((a, b) => Number(b.selected) - Number(a.selected))
}

export function generateInternalOptions(
  options: SelectOption[],
  selectedValues: string[],
) {
  const internalOptions: InternalSelectOption[] = options.map(option => ({
    ...option,
    selected: selectedValues.includes(option.value),
  }))

  return sortOptions(internalOptions)
}

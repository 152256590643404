import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'

import FormGenerator from 'components/blocks/__v2__/FormGenerator/FormGenerator'
import { Modal } from 'components/ui/__v2__/Modal'

import { parseInitialValuesFromSubmitted } from 'helpers/formBuilder'

import _ from 'i18n'

function SubmittedForm({ isOpen, onClose, formObject, formValues }) {
  const initialValues = useMemo(
    () => parseInitialValuesFromSubmitted(formValues),
    [formValues],
  )
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Close onClose={onClose} />
      <Modal.Title>{_('formBuilder.submissionResults')}</Modal.Title>
      <FormGenerator
        formObject={formObject}
        initialValues={initialValues}
        isReadOnly
        onClose={onClose}
      />
    </Modal>
  )
}

SubmittedForm.defaultProps = {
  formValues: [],
}

SubmittedForm.propTypes = {
  ...Modal.propTypes,
  formObject: PropTypes.object.isRequired,
  formValues: PropTypes.array,
}

export default memo(SubmittedForm)

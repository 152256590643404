import { DateTime } from 'luxon'
import createObjectHash from 'object-hash'

import compact from 'lodash/compact'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import floor from 'lodash/floor'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import memoize from 'lodash/memoize'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import round from 'lodash/round'
import slice from 'lodash/slice'
import sortBy from 'lodash/sortBy'

import { isDayShift, isOvernightShift } from 'constants/shifts'
import { isDayContainsTimeOff } from 'constants/timeoff'

import {
  calculateTimeIntervalInMinutes,
  convertMinutesToHM,
  weekDays,
} from 'helpers/date'
import { convertWageToDollars } from 'helpers/monetary'

import _ from 'i18n'

import { WageTypes } from './timeCards'

export const START_AT = 9 * 3600
export const END_AT = START_AT + 8 * 3600

const orderSubjectsByLatestUpdate = subjects =>
  orderBy(subjects, ['updatedAt'], ['desc'])

const getLatestAbsence = (absences, scheduleId) => {
  if (isEmpty(absences)) {
    return {}
  }
  const orderedAbsences = orderSubjectsByLatestUpdate(absences)
  return find(
    orderedAbsences,
    absence => get(absence, 'schedule.id') === scheduleId,
  )
}

export function getWeekDates(week) {
  return weekDays(week)?.map(day =>
    DateTime.fromISO(day).toFormat("yyyy'-'MM'-'dd"),
  )
}

export const getShiftsByDays = (shifts, week) => {
  const weekDates = getWeekDates(week)
  return weekDates?.map(day =>
    compact(
      shifts?.map(employee => {
        const { shift, published } = employee.shiftsJob
        const shiftExists =
          (!published && employee.state === 'unpublished') || published
        return (isDayShift(shift, day) || isOvernightShift(shift, day)) &&
          shiftExists
          ? employee
          : undefined
      }),
    ),
  )
}

export const getTimeoffsByDays = (() => {
  const cache = {}

  return (employee, week, acceptedTimeoffs) => {
    const { id: employeeId, timeoffs } = get(employee, [0, 'employee'], {})

    const hash = createObjectHash({
      employeeId,
      timeoffs,
      week,
      acceptedTimeoffs,
    })

    if (has(cache, hash)) {
      return cache[hash]
    }

    const weekDates = getWeekDates(week)

    const employeeAcceptedTimeoffs = filter(
      acceptedTimeoffs,
      acceptedTimeoff => employeeId === acceptedTimeoff.employee.id,
    )

    const employeeTimeoffs = concat(timeoffs || [], employeeAcceptedTimeoffs)

    cache[hash] = map(weekDates, day => {
      const dayTimeoffs = filter(employeeTimeoffs, employeeTimeoff =>
        isDayContainsTimeOff(employeeTimeoff, day),
      )

      return sortBy(dayTimeoffs, 'updatedAt')[
        dayTimeoffs ? dayTimeoffs.length - 1 : 0
      ]
    })

    return cache[hash]
  }
})()

export const INITIAL_DAY_TIMES_DATA = {
  scheduledTime: 0,
  actualTime: 0,
  scheduledAmount: null,
  actualAmount: null,
  expectedAmount: null,
}

export const getDayScheduledActualData = (daySchedules, dayTimeoffs) => {
  const dayData = { ...INITIAL_DAY_TIMES_DATA }

  let pendingDayMinutes = 0

  if (!isEmpty(daySchedules) && isEmpty(dayTimeoffs)) {
    const { dayActualTime, dayActualAmount } = calculateActualDayData(
      daySchedules,
    )

    dayData.actualTime = dayActualTime
    dayData.actualAmount = dayActualAmount
    // filter mocked schedules with manual time cards
    const clearedDaySchedules = filter(
      daySchedules,
      item => get(item, 'mocked', false) === false,
    )
    const {
      scheduledDayTime,
      pendingDayTime,
      scheduledAmount,
      expectedAmount,
    } = calculateScheduledDayData(clearedDaySchedules)

    dayData.scheduledTime = scheduledDayTime
    dayData.scheduledAmount = scheduledAmount
    dayData.expectedAmount = expectedAmount

    pendingDayMinutes = pendingDayTime
  }
  return { dayData, pendingDayMinutes }
}

export const getEmployeesScheduledActualData = memoize(
  (shiftsByDays, timeOffsByDays, convertToHours = true) => {
    let scheduledTime = 0
    let pendingTime = 0
    let actualTime = 0
    let scheduledAmount = 0
    let actualAmount = 0

    const columnsTimesByDays = []
    forEach(shiftsByDays, (dayShifts, dayIndex) => {
      const { dayData, pendingDayMinutes } = getDayScheduledActualData(
        dayShifts,
        timeOffsByDays[dayIndex],
      )

      actualTime += dayData.actualTime
      scheduledTime += dayData.scheduledTime
      pendingTime += pendingDayMinutes

      scheduledAmount = round(dayData.scheduledAmount + scheduledAmount, 2)
      actualAmount = round(dayData.actualAmount + actualAmount, 2)

      columnsTimesByDays.push(dayData)
    })
    return {
      actualAmount,
      columnsTimesByDays: [...columnsTimesByDays],
      scheduledAmount,
      actualHours: convertToHours ? convertMinutesToHM(actualTime) : actualTime,
      scheduledHours: convertToHours
        ? convertMinutesToHM(scheduledTime)
        : scheduledTime,
      pendingHours: convertToHours
        ? convertMinutesToHM(pendingTime)
        : pendingTime,
    }
  },
)

export const calculateActualDayData = dayShifts => {
  let dayActualTime = 0
  let dayActualAmount = 0
  forEach(dayShifts, dayShift => {
    const actualTime = calculateActualTime(dayShift)
    const wage = findEmployeesWage(dayShift)

    dayActualAmount += calculateAmount(actualTime, wage)
    dayActualTime += actualTime
  })
  return {
    dayActualTime:
      dayActualTime < 1 ? round(dayActualTime) : floor(dayActualTime),
    dayActualAmount,
  }
}

export const calculateScheduledDayData = dayShifts => {
  let scheduledDayTime = 0
  let pendingDayTime = 0
  let scheduledAmount = 0
  let expectedAmount = 0
  forEach(dayShifts, dayShift => {
    const { absences, id, shiftsJob } = dayShift
    if (!isEmpty(shiftsJob)) {
      const isAbsence = !isEmpty(getLatestAbsence(absences, id))
      if (!isAbsence) {
        const { scheduledMinutes, pendingMinutes } = calculateScheduledTimes(
          dayShift,
        )
        const wage = findEmployeesWage(dayShift)
        const expectedMinutes = scheduledMinutes + pendingMinutes

        scheduledAmount += calculateAmount(scheduledMinutes, wage)
        expectedAmount += calculateAmount(expectedMinutes, wage)
        scheduledDayTime += scheduledMinutes
        pendingDayTime += pendingMinutes
      }
    }
  })

  return { scheduledDayTime, pendingDayTime, scheduledAmount, expectedAmount }
}

export const calculateActualTime = (
  shiftJobEmployee,
  changedTimeEntry = {},
  changedPausesTimers = {},
) => {
  const { timeEntry, adjusted, mocked } = shiftJobEmployee

  let actualMinutes = 0
  if (!isEmpty(timeEntry)) {
    const {
      startAt: timeEntryStartAt,
      endAt: timeEntryEndAt,
      pauseTimers,
    } = timeEntry
    const pauses = adjusted
      ? get(shiftJobEmployee, 'shiftsJob.shift.pauses', {})
      : pauseTimers

    const startAt = get(changedTimeEntry, 'startAt', '') || timeEntryStartAt
    const endAt = get(changedTimeEntry, 'endAt', '') || timeEntryEndAt
    // If timer is active - endAt equals to null. Handle It.
    if (startAt && endAt) {
      actualMinutes = calculateTimeIntervalInMinutes(startAt, endAt)
    }
    if (actualMinutes) {
      if (!isEmpty(pauses)) {
        // We should exclude no paid pauses
        forEach(pauses, pauseTimer => {
          if (adjusted) {
            const { paid, duration } = pauseTimer
            if (paid === false) {
              actualMinutes -= duration
            }
          } else {
            const {
              pause,
              endAt: pauseEndAt,
              startAt: pauseStartAt,
              id,
            } = pauseTimer
            const isPausePaid = mocked
              ? get(pauseTimer, 'paid')
              : get(pause, 'paid')
            if (isPausePaid === false) {
              const formatTime = time =>
                typeof time === 'object'
                  ? DateTime.fromJSDate(time)
                  : DateTime.fromISO(time)

              const endAtTime = formatTime(
                get(changedPausesTimers, `${id}.endAt`) || pauseEndAt,
              )
              const startAtTime = formatTime(
                get(changedPausesTimers, `${id}.startAt`) || pauseStartAt,
              )
              const actualPauseSeconds = endAtTime
                .diff(startAtTime, 'seconds')
                .toObject().seconds

              const actualPauseMinutes = actualPauseSeconds / 60

              actualMinutes -= actualPauseMinutes
            }
          }
        })
      }
    }
  }
  return floor(actualMinutes)
}

export const calculateScheduledTimes = shiftJobEmployee => {
  const { state } = shiftJobEmployee
  const shift = get(shiftJobEmployee, 'shiftsJob.shift', {})
  const { startAt, finishAt, pauses } = shift

  let scheduledMinutes = 0
  let pendingMinutes = 0
  let shiftMinutes = calculateTimeIntervalInMinutes(startAt, finishAt)
  if (pauses) {
    forEach(pauses, pause => {
      const { paid, duration } = pause
      if (paid === false) {
        shiftMinutes -= duration
      }
    })
  }

  state === 'published'
    ? (scheduledMinutes += shiftMinutes)
    : (pendingMinutes += shiftMinutes)

  return {
    scheduledMinutes: round(scheduledMinutes),
    pendingMinutes: round(pendingMinutes),
  }
}

const findEmployeesWage = shift => {
  // We get wage from time card to be able save past data
  const { timeEntry } = shift
  if (!isEmpty(timeEntry)) {
    const wage = get(timeEntry, 'wage')
    const wageType = get(timeEntry, 'wageType')
    return wage && wageType === WageTypes.Hour
      ? convertWageToDollars(wage)
      : null
  }
  return null
}

const calculateAmount = (time, wage) => {
  if (wage && time > 0) {
    return round((time / 60) * wage, 2)
  }
  return null
}

export const filterSchedulesByWeek = (schedules, week) =>
  filter(schedules, item => {
    const shift = get(item, 'shiftsJob.shift')

    if (shift) {
      const shiftStart = get(shift, 'startAt')
      const shiftFinish = get(shift, 'finishAt')

      const formatTime = time => DateTime.fromISO(time)

      return (
        formatTime(shiftStart).diff(formatTime(week.start)) >= 0 &&
        formatTime(shiftStart).diff(formatTime(week.end)) < 0 &&
        formatTime(shiftFinish).diff(formatTime(week.start)) > 0 &&
        formatTime(shiftStart).diff(formatTime(week.end)) < 0 // sunday night shift -> shiftFinish > week.end
      )
    }
    return false
  })

export const TIMESHEETS_MODAL_KEY_NAMES = {
  editModal: 'EditModal',
  logModal: 'LogModal',
  geoModal: 'GeoModal',
  addModal: 'AddModal',
  infoModal: 'InfoModal',
}

export const getAdjustErrorDetail = adjustError => {
  const IS_NOT_ENDED = "schedule's shift is not ended yet"
  if (adjustError === IS_NOT_ENDED) {
    return _('schedule.cannotAdjustTimeCard')
  }
  return adjustError
}

export const SCHEDULE_VIEW_SORT_OPTIONS = {
  role: 'common.role',
  firstName: 'userProfile.firstName',
  lastName: 'userProfile.lastName',
}

export const filterManualTimeEntries = (schedules, timeEntries) => {
  const schedulesTimeEntryIds = reduce(
    schedules,
    (ids, schedule) => {
      const timeEntryId = get(schedule, 'timeEntry.id', '')
      if (timeEntryId) {
        ids.push(timeEntryId)
      }
      return ids
    },
    [],
  )
  return filter(
    timeEntries,
    timeEntry => schedulesTimeEntryIds.indexOf(timeEntry.id) === -1,
  )
}

export const FILTERS_ORDER = {
  asc: 'asc',
  desc: 'desc',
}

// NOTE: this is beautiful man
export const getColumnsDayIndexByWorkStartWeekDay = (
  selectedColumnsDayIndex,
  workStartWeekDay,
) => {
  // weekStartIn: 0(Sun) 1(Mon) 2(Tue) 3(Wed) 4(Thu) 5(Fri) 6(Sat)
  // columnIndex: 0(mon) 1(tue) 2(wed) 3(thu) 4(Fri) 5(Sat) 6(Sun)
  // workWeekExample: 0 1 2 3 4 5 6 Sun 0
  // workWeekExample: 1 2 3 4 5 6 0 Mon 1
  // workWeekExample: 3 4 5 6 0 1 2 Wed 3
  // workWeekExample: 5 6 0 1 2 3 4 Fri 5
  const DEFAULT_COLUMN_INDEXES = [0, 1, 2, 3, 4, 5, 6]
  const columnIndexByWorkWeek = DEFAULT_COLUMN_INDEXES.indexOf(workStartWeekDay)
  const firstPart = slice(
    DEFAULT_COLUMN_INDEXES,
    columnIndexByWorkWeek,
    DEFAULT_COLUMN_INDEXES.length,
  )
  const secondPart = slice(DEFAULT_COLUMN_INDEXES, 0, columnIndexByWorkWeek)
  const updatedColumnIndexes = [...firstPart, ...secondPart]
  const updatedColumnsDayIndex = updatedColumnIndexes[selectedColumnsDayIndex]
  return updatedColumnsDayIndex === 0 ? 7 : updatedColumnsDayIndex
}

export const LONG_DASH = '—'

export const VIEW_MODES = {
  LABELS: 'labels',
  SHIFTS: 'shifts',
}

export function getViewModeOptions() {
  return [
    {
      label: _('labelDayType.shifts'),
      value: VIEW_MODES.SHIFTS,
    },
    {
      label: _('labelDayType.labels'),
      value: VIEW_MODES.LABELS,
    },
  ]
}

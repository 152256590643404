import { useMutation, useQueryClient } from '@tanstack/react-query'

import { i18n } from 'i18n'

import { showCoreAPIErrorToast, showToast } from 'services/Toasts'

import { AvailabilityRequestQueryKey } from './config'
import { mutateAvailabilityRequestMutation } from './JSON'

export function useMutateAvailabilityRequest() {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation(
    AvailabilityRequestQueryKey.all(),
    mutateAvailabilityRequestMutation,
    {
      onSuccess: (result, args) => {
        if (!result?.ok) {
          showCoreAPIErrorToast({ response: result })
        } else {
          showToast({
            type: 'success',
            content: i18n('availabilityRequests.title'),
            title: i18n(`availabilityRequests.successToasts.${args.mode}`),
          })
          queryClient.invalidateQueries(AvailabilityRequestQueryKey.all())
        }
      },
    },
  )

  return { mutateAvailabilityRequest: mutateAsync, mutating: isLoading }
}

import React, { useMemo } from 'react'
import Linkify from 'react-linkify'

import { FiMapPin } from 'react-icons/fi'

import { Divider } from 'components/ui/__v2__/Divider'
import { Flex, Span } from 'components/ui/__v2__/Grid'
import { valueOrNA } from 'components/ui/__v3__/__helpers__'
import { Button } from 'components/ui/__v3__/Button'
import { Modal } from 'components/ui/__v3__/Modal'

import { i18n } from 'i18n'

import {
  BranchContainer,
  BranchName,
  Container,
  Content,
  DepartmentJobName,
  Note,
  Time,
  Title,
} from './styles'

type NoteModalProps = {
  time: string
  note?: string
  timeBucketNames?: {
    parentName: string
    childName: string
  }
  onClose: () => void
  placementNames?: {
    branchName: string
    departmentName: string
    jobName: string
  }
}

export function ShiftNoteModal({
  time,
  note,
  timeBucketNames,
  onClose,
  placementNames,
}: NoteModalProps) {
  const renderSchedulePlacement = useMemo(() => {
    if (!placementNames) {
      return null
    }

    const branchName = valueOrNA(placementNames?.branchName)
    const departmentName = valueOrNA(placementNames?.departmentName)
    const jobName = valueOrNA(placementNames?.jobName)

    return (
      <>
        <BranchContainer>
          <FiMapPin size={14} />
          <BranchName>{branchName}</BranchName>
        </BranchContainer>

        <DepartmentJobName>{`${departmentName} / ${jobName}`}</DepartmentJobName>
      </>
    )
  }, [placementNames])

  return (
    <Modal.Container isOpen>
      <Modal.Close onClose={onClose} />
      <Container>
        <Content>
          <Title>{i18n('shifts.shiftNote')}</Title>

          <Time>{time}</Time>

          {renderSchedulePlacement}

          <Flex flexDirection="column" mt={2}>
            {!!timeBucketNames && (
              <Flex flexDirection="column" gap={1}>
                <Flex gap={1}>
                  <Flex minWidth="105px">
                    <Span>{i18n('common.costGroup')}:</Span>
                  </Flex>

                  <Span textAlign="justify">{timeBucketNames.parentName}</Span>
                </Flex>

                <Flex gap={1}>
                  <Flex minWidth="105px">
                    <Span textAlign="justify">{i18n('common.subGroup')}:</Span>
                  </Flex>

                  <Span>{timeBucketNames.childName}</Span>
                </Flex>
              </Flex>
            )}

            {!!timeBucketNames && !!note && <Divider my={'6px'} />}

            {!!note && (
              <Flex gap={1}>
                <Flex minWidth="105px">
                  <Span>{i18n('common.note')}:</Span>
                </Flex>

                {/* @ts-ignore */}
                <Linkify properties={{ target: '_blank' }}>
                  <Note>{note}</Note>
                </Linkify>
              </Flex>
            )}
          </Flex>

          <Button mt="24px" width="100%" onClick={onClose}>
            {i18n('actions.calendarOk')}
          </Button>
        </Content>
      </Container>
    </Modal.Container>
  )
}

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from './ui/__v2__/Grid'

export const inputSelectFocusedCss = css`
  border-color: ${themeGet('layout.primary')};
  box-shadow: ${themeGet('layout.primaryShadow')};
`

export const IconEventContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`

export const OptionsContainer = styled(Flex)`
  flex-wrap: wrap;
  overflow: auto;
  max-width: 480px;
  max-height: 100px;
`

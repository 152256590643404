import { ApolloCache } from '@apollo/client'
import { FragmentOptions } from 'API/services/utils/GraphQL'

export function evictShifts(
  cache: ApolloCache<any>,
  ids?: Array<Gateway.Shift['id']>,
) {
  if (!ids?.length) return

  ids.forEach(id => {
    cache.evict({ id: FragmentOptions.Shift(id).id })
  })

  cache.gc()
}

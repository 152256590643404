import React from 'react'

import { flexRender } from '@tanstack/react-table'

import isEmpty from 'lodash/isEmpty'

import { Spinner } from 'components/ui/Spinner'

import { i18n } from 'i18n'

import { EmptyData, TableLoadingContainer, Td, Tr } from './styles'
import { DataTableProps } from './types'

export function TableBody({ table, loading }: DataTableProps) {
  if (loading) {
    return (
      <tbody>
        <tr>
          <td colSpan={20}>
            <TableLoadingContainer>
              <Spinner primary size={42} />
            </TableLoadingContainer>
          </td>
        </tr>
      </tbody>
    )
  }

  if (isEmpty(table.options.data)) {
    return (
      <tbody>
        <tr>
          <td colSpan={20}>
            <EmptyData>{i18n('common.tableNoData')}</EmptyData>
          </td>
        </tr>
      </tbody>
    )
  }

  return (
    <tbody>
      {table.getRowModel().rows.map(row => (
        <Tr key={row.id}>
          {row.getVisibleCells().map(cell => (
            // @ts-ignore
            <Td
              key={cell.id}
              {...table.options.meta}
              {...cell.column.columnDef.meta}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Td>
          ))}
        </Tr>
      ))}
    </tbody>
  )
}

import { useState } from 'react'

import { download } from 'API/services/JSON'

import { useAppContext } from 'hooks'

import { showDefaultErrorToast } from 'services/Toasts'

import { scheduleExportParamsToQueryString } from './helpers'
import { workersCouncilExportParams } from './types'

export function useFPSWorkersCouncilExport() {
  const [exporting, setExporting] = useState(false)
  const { companyId } = useAppContext()

  async function fpsWorkersCouncilExportToCSV(
    filter: workersCouncilExportParams['filter'],
  ) {
    setExporting(true)
    try {
      await download({
        companyId,
        queryString: scheduleExportParamsToQueryString(filter),
        kind: 'fps_workers_council_extract_export',
      })
    } catch (error) {
      showDefaultErrorToast()
    }

    setExporting(false)
  }

  return {
    fpsWorkersCouncilExporting: exporting,
    fpsWorkersCouncilExportToCSV,
  }
}

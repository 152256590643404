import React from 'react'

import { i18n } from 'i18n'

import { Badge } from '../components'
import { Cell } from '../styles'

interface Props {
  workSchedules: Gateway.WorkSchedule[]
}

export function WorkSchedules({ workSchedules }: Props) {
  if (workSchedules.length === 0) {
    return <span>{i18n('table.cells.workSchedules.empty')}</span>
  }

  return (
    <Cell>
      {workSchedules.map(({ id, name }) => (
        <Badge key={id} text={name} />
      ))}
    </Cell>
  )
}

import { useApolloQuery } from 'API/services/Apollo'

import { GroupsByOffsetQuery, GroupsByOffsetQueryData } from './GraphQL'

export type Props = {
  sorting: Gateway.GroupSorting[]
  paging: Gateway.OffsetPaging
  enabled?: boolean
}

export function useGroupsByOffset({ paging, sorting, enabled }: Props) {
  const { data, loading, refetch } = useApolloQuery<
    GroupsByOffsetQueryData,
    Gateway.QueryGroupsByOffsetArgs
  >(GroupsByOffsetQuery, {
    variables: {
      paging,
      sorting,
    },
    skip: !enabled,
    fetchPolicy: 'network-only',
  })

  const groups = data?.groupsByOffset?.rows ?? []
  const total = data?.groupsByOffset?.pageInfo.total ?? 0

  return {
    groups,
    loading,
    total,
    refetch,
  }
}

import React, { LegacyRef } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MaskedInput from 'react-text-mask'

import styled from 'styled-components'

import { DateTime } from 'luxon'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'

import { includes } from 'lodash'
import toLower from 'lodash/toLower'

import { Input } from 'components/ui/__v3__/Input'

import { CalendarIcon, PickerInputWrapper } from './styles'
import { CustomProps, PickerInputProps } from './types'

// All custom props should be passed to inputProps of PickerInput
export const PickerInputComponent = React.forwardRef(
  (props: PickerInputProps & CustomProps, ref: LegacyRef<MaskedInput>) => {
    const mask = props.placeholder
      ? Array.from(props.placeholder ?? '').map(char =>
          includes(['/', '.', '-', ' '], char) ? char : /\d/,
        )
      : [''] // Example [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]

    return (
      <PickerInputWrapper disabled={props.disabled} width={1}>
        <CalendarIcon />
        <MaskedInput
          mask={mask}
          pipe={
            props.placeholder &&
            createAutoCorrectedDatePipe(toLower(props.placeholder))
          } // 'yyyy-mm-dd' is valid
          ref={ref}
          render={renderMaskedInput}
          {...props}
        />
      </PickerInputWrapper>
    )
  },
)

export const PickerInput = styled(DayPickerInput).attrs(() => ({
  // Don't pass component like a function, it will have a bug with focus losing
  component: PickerInputComponent,
  formatDate: (jsDate: Date, format: string) =>
    DateTime.fromJSDate(jsDate).toFormat(format),
  parseDate: (isoString: string, format: string) => {
    const dt = DateTime.fromFormat(isoString, format)
    return dt.isValid ? dt.toJSDate() : undefined
  },
}))``

const renderMaskedInput = (ref: $TSFixMe, props: PickerInputProps) => (
  <Input pl={22} ref={ref} width={1} wrapper={{ width: 1 }} {...props} />
)

import { createRelationAddHandler } from 'redux-json-api-handlers'

import { createReducer } from 'helpers/redux'

import { START_PAUSE_TIMER as START_OPEN_CLOCK_PAUSE } from 'store/actions/employeeApp/openClock'
import { START_PAUSE_TIMER } from 'store/actions/employeeApp/timeClock'
import { ADD_NOTE } from 'store/actions/employees/timeCards'

const initialState = {}

const handlers = {
  [START_PAUSE_TIMER.SUCCESS]: createRelationAddHandler(
    'pauseTimers',
    'timeEntry',
  ),
  [START_OPEN_CLOCK_PAUSE.SUCCESS]: createRelationAddHandler(
    'pauseTimers',
    'timeEntry',
  ),
  [ADD_NOTE.SUCCESS]: createRelationAddHandler('timerNotes', 'timeEntry'),
}

export default createReducer(initialState, handlers)

import React from 'react'

import { SelectOption } from 'Types/common'

import { Select } from 'components/ui/__v3__/Select'

import { ROWS_PER_PAGE_SELECT_OPTIONS } from 'constants/pagination'

import { generateRowsPerPageSelectOption } from 'helpers/pagination'

type Props = {
  size: number
  onSizeChange: (size: number) => void
}

export const PageSizeSelect = ({ size, onSizeChange }: Props) => {
  const handleRowsPerPageSelect = (option: SelectOption<number>) =>
    onSizeChange(option.value)

  return (
    <div>
      {/* TODO: Update Select and remove div and ignore */}
      <Select
        // @ts-ignore
        isClearable={false}
        options={ROWS_PER_PAGE_SELECT_OPTIONS}
        value={generateRowsPerPageSelectOption(size)}
        width={62}
        withPortal
        onChange={handleRowsPerPageSelect}
      />
    </div>
  )
}

import { getEntities } from 'rapidux'

import {
  DEPARTMENTS_FIELD,
  DEPARTMENTS_TYPE,
  EMPLOYEES_FIELD,
  EMPLOYEES_TYPE,
  JOBS_EMPLOYEES_FIELD,
  JOBS_EMPLOYEES_TYPE,
  JOBS_FIELD,
  JOBS_TYPE,
  LOCATIONS_FIELD,
  LOCATIONS_TYPE,
  STAFF_FIELD,
  STAFF_TYPE,
} from 'store/reducers/areas'
import { getData } from 'store/selectors/data'

export const getState = state => state.areas

export const makeGetInitialEntity = (type, field) => {
  const options = {
    type: type.default,
    field: field.default,
  }

  return getEntities(getState, getData, options)
}

export const getInitialLocations = makeGetInitialEntity(
  {
    default: LOCATIONS_TYPE,
  },
  {
    default: LOCATIONS_FIELD,
  },
)

export const getInitialDepartments = makeGetInitialEntity(
  {
    default: DEPARTMENTS_TYPE,
  },
  {
    default: DEPARTMENTS_FIELD,
  },
)

export const getInitialJobs = makeGetInitialEntity(
  {
    default: JOBS_TYPE,
  },
  {
    default: JOBS_FIELD,
  },
)

export const getInitialEmployees = getEntities(getState, getData, {
  type: EMPLOYEES_TYPE,
  field: EMPLOYEES_FIELD,
})

export const getInitialStaff = getEntities(getState, getData, {
  type: STAFF_TYPE,
  field: STAFF_FIELD,
})

export const getInitialJobsEmployees = getEntities(getState, getData, {
  type: JOBS_EMPLOYEES_TYPE,
  field: JOBS_EMPLOYEES_FIELD,
})

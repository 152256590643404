import { createSelector } from 'reselect'

import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'

import { EMPLOYEE_STATE_FILTERS } from 'constants/employees'

import { denormalize } from 'helpers/redux'

import { getData } from 'store/selectors/data'

export const getState = state => state.employees.employees

export const getEmployeeIds = createSelector(getState, state =>
  Array.from(state.employees),
)

/** @deprecated */
export const getEmployeeId = createSelector(getState, state => state.employee)

export const getHasActiveTimerEntry = createSelector(
  getState,
  state => state.hasActiveTimerEntry,
)

export const getEmployeeStatistics = createSelector(
  getState,
  state => state.employeeStatistics,
)

export const getIsStatisticsLoading = createSelector(
  getState,
  state => state.isStatisticsLoading,
)

export const getIsEmployeeLoading = createSelector(
  getState,
  state => state.isEmployeeLoading,
)

export const getIsEmployeeLoaded = createSelector(
  getState,
  state => state.isEmployeeLoaded,
)

export const getEmployees = (employeeState = EMPLOYEE_STATE_FILTERS.active) => {
  const filterBySelector = typeof employeeState === 'function'
  const selectors = [getData, getEmployeeIds]
  if (filterBySelector) {
    selectors.push(employeeState)
  }
  return createSelector(selectors, (data, employeeIds, filterState) => {
    const employees = !isEmpty(employeeIds)
      ? denormalize(data, 'employees', employeeIds)
      : []
    const employeeFilter = filterBySelector ? filterState : employeeState
    if (employeeState === EMPLOYEE_STATE_FILTERS.story) {
      return employees
    }
    if (employeeFilter !== EMPLOYEE_STATE_FILTERS.all) {
      return filter(employees, employee => employee.state === employeeFilter)
    }
    return filter(
      employees,
      employee => employee.state !== EMPLOYEE_STATE_FILTERS.deleted,
    )
  })
}

/** @deprecated */
export const getEmployee = createSelector(
  getData,
  getEmployeeId,
  (data, employeeId) => {
    return employeeId ? denormalize(data, 'employees', employeeId) : {}
  },
)

export const getNewEmployeeAvatarId = createSelector(
  getState,
  state => state.newEmployeeAvatarId,
)

export const getIsUploadingEmployeeAvatar = createSelector(
  getState,
  state => state.isUploadingEmployeeAvatar,
)

export const getIsUpdatingEmployeeAvatar = createSelector(
  getState,
  state => state.isUpdatingEmployeeAvatar,
)

export const getAlreadyInvitedError = createSelector(
  getState,
  state => state.alreadyInvitedError,
)

/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { Flex } from 'components/ui/__v2__/Grid'
import { Radio } from 'components/ui/__v2__/Radio'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import { Label } from './styles'

import { LabelText } from '../../Input/LabelText'
import { Error } from '../components'
import { ControllerBaseProps } from '../types'

type SpecificProps = {
  disabled?: boolean
  value: string
  size?: number
}
export type FormRadioButtonProps<
  T extends FieldValues
> = ControllerBaseProps<T> & LabelProps & SpecificProps

export function FormRadioButton<T extends FieldValues>({
  control,
  disabled = false,
  required = false,
  name,
  size,
  labelText,
  value: passedValue,
}: FormRadioButtonProps<T>) {
  const {
    field: fieldProps,
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  const { onChange, value } = fieldProps
  // TODO: refactor Radio component, to make label text clickable as well as radio button
  return (
    <Flex flexDirection="column">
      <Flex alignItems="center" justifyContent="start">
        <Radio
          // @ts-ignore
          checked={value === passedValue}
          disabled={disabled}
          id={labelText}
          name={name}
          size={size}
          value={passedValue}
          onChange={onChange}
        />
        <Label htmlFor={labelText}>
          <LabelText labelText={labelText} required={required} />
        </Label>
      </Flex>

      {error && <Error error={error} />}
    </Flex>
  )
}

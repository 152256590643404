import { useCallback, useMemo, useState } from 'react'

import { AvailableEmployeeForShiftJobByCursorNode } from 'API/Employee/GraphQL'
import { ScheduleByCursorNode } from 'API/Schedule/GraphQL'
import { ShiftJobsByCursorNode } from 'API/ShiftJob/GraphQL'

export type DraftAssignment = AvailableEmployeeForShiftJobByCursorNode
export type DraftUnassignment = ScheduleByCursorNode

export function useAssignmentScratchPad(
  selectedShiftJob: ShiftJobsByCursorNode | undefined,
  setSelectedShiftJobId: (id: string) => void,
) {
  const [draftAssignments, setDraftAssignments] = useState<DraftAssignment[]>(
    [],
  )
  const [draftUnassignments, setDraftUnassignments] = useState<
    DraftUnassignment[]
  >([])

  const isDraftAssigned = useCallback(
    (record: DraftAssignment) =>
      draftAssignments.some(
        ({ employee }) => employee.id === record.employee.id,
      ),
    [draftAssignments],
  )

  const isDraftUnassigned = useCallback(
    (record: DraftUnassignment) =>
      draftUnassignments.some(
        ({ employee }) => employee.id === record.employee.id,
      ),
    [draftUnassignments],
  )

  const reset = () => {
    setDraftAssignments([])
    setDraftUnassignments([])
  }

  const draftAssign = useCallback(
    (record: DraftAssignment) => {
      if (isDraftAssigned(record)) return null
      setDraftAssignments(existing => [...existing, record])
      return null
    },
    [isDraftAssigned],
  )

  const revertDraftAssignment = useCallback((record: DraftAssignment) => {
    return setDraftAssignments(existing =>
      existing.filter(item => item.employee.id !== record.employee.id),
    )
  }, [])

  const draftUnassign = useCallback(
    (record: DraftUnassignment) => {
      if (isDraftUnassigned(record)) return null
      setDraftUnassignments(existing => [...existing, record])
      return null
    },
    [isDraftUnassigned],
  )

  const revertDraftUnassignment = useCallback((record: DraftUnassignment) => {
    return setDraftUnassignments(existing =>
      existing.filter(item => item.employee.id !== record.employee.id),
    )
  }, [])

  const missingAssignments = useMemo(
    () =>
      (selectedShiftJob?.unfilledPositions ?? 0) -
      draftAssignments.length +
      draftUnassignments.length,
    [
      draftAssignments.length,
      draftUnassignments.length,
      selectedShiftJob?.unfilledPositions,
    ],
  )

  const hasUnappliedChanges = useMemo(
    () => draftAssignments.length > 0 || draftUnassignments.length > 0,
    [draftAssignments.length, draftUnassignments.length],
  )

  const handleChangeSelectedShiftJob = useCallback(
    (id: string) => {
      setSelectedShiftJobId(id)
      reset()
    },
    [setSelectedShiftJobId],
  )

  return {
    selectedShiftJob,
    handleChangeSelectedShiftJob,

    draftAssignments,
    draftUnassignments,

    isDraftAssigned,
    isDraftUnassigned,

    draftAssign,
    revertDraftAssignment,

    draftUnassign,
    revertDraftUnassignment,

    missingAssignments,
    hasUnappliedChanges,

    reset,
  }
}

export type UseAssignmentScratchPadReturn = ReturnType<
  typeof useAssignmentScratchPad
>

import { all, fork, put, take } from 'redux-saga/effects'

import isEmpty from 'lodash/isEmpty'

import { CREATE_SHIFT } from 'store/actions/company/schedule'
import { updateShiftJobs } from 'store/actions/employees/shiftRoles'
import { createPauses } from 'store/actions/employees/shifts'

function* watchCreateShifts() {
  while (true) {
    const { payload } = yield take(CREATE_SHIFT.SUCCESS)
    const shifts = payload?.data?.shifts
    const pauses = payload?.pauses
    const shiftJobGroups = payload?.shiftJobGroups

    const shiftIds = shifts ? Object.keys(shifts) : []

    if (shifts) {
      if (!isEmpty(pauses)) {
        yield put(
          createPauses({
            shifts: Object.keys(shifts),
            pauses,
          }),
        )
      }
    }

    if (shiftIds.length > 1) {
      const shiftsJobsIds = shiftIds
        .map(shiftId => {
          const shift = shifts[shiftId]
          return shift.relationships?.shiftsJobs?.data?.map(
            shiftJob => shiftJob.id,
          )
        })
        .flat()

      if (shiftsJobsIds.length > 1) {
        yield put(
          updateShiftJobs(
            shiftsJobsIds.map(id => ({
              id,
              type: 'shifts_jobs',
              attributes: {
                quantity: 1,
                shiftJobGroups: shiftJobGroups.map(shiftJobGroup => ({
                  groupId: shiftJobGroup.id,
                  context: 'must',
                })),
              },
            })),
          ),
        )
      }
    }
  }
}

export default function* root() {
  yield all([fork(watchCreateShifts)])
}

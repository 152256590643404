import { useApolloQuery } from 'API/services/Apollo'

import { IntegrationQuery } from './GraphQL'

export function useIntegration({
  id,
  enabled,
}: {
  id: string
  enabled?: boolean
}) {
  const { data, loading } = useApolloQuery<
    QueryData<'integration'>,
    Gateway.QueryIntegrationArgs
  >(IntegrationQuery, {
    fetchPolicy: 'network-only',
    skip: !enabled,
    variables: {
      id,
    },
  })

  return {
    integration: data?.integration,
    integrationLoading: loading,
  }
}

import React from 'react'

import { FiArrowDown, FiArrowUp } from 'react-icons/fi'

import { Flex, Span } from 'components/ui/__v2__/Grid'
import { TableHeaderIconButton } from 'components/ui/__v3__/Button'

import { SortingDirection } from 'constants/gatewayGraphQL'

import { TableHeaderSortProps } from './types'

export function TableHeaderSort<SortFields>({
  pagination: { handleSortChange, options },
  children,
  fieldName,
}: TableHeaderSortProps<SortFields>) {
  const field = (fieldName as unknown) as string
  const sorting = options?.sorting
  const sortedAscending = sorting?.direction === SortingDirection.Asc
  return (
    <Flex
      alignItems="center"
      // @ts-ignore
      cursor="pointer"
      mb={2}
      onClick={() =>
        handleSortChange
          ? handleSortChange({
              field,
              direction: sortedAscending
                ? SortingDirection.Desc
                : SortingDirection.Asc,
            })
          : null
      }
    >
      <Span>{children}</Span>

      <TableHeaderIconButton hidden={sorting?.field !== field} ml={10}>
        {sortedAscending ? <FiArrowUp /> : <FiArrowDown />}
      </TableHeaderIconButton>
    </Flex>
  )
}

import React from 'react'

import { DateTime } from 'luxon'

import { DayPicker } from 'components/ui/__v2__/DayPicker'
import { Flex } from 'components/ui/__v2__/Grid'
import { Spacer } from 'components/ui/__v2__/Spacer'

import { isoToJsDate } from 'helpers/date'

import { DateRangePickerProps } from './types'

export function DateRangePicker({
  start,
  end,
  minimalDays,
  onChange,
  disablePast = false,
  disableFuture = false,
}: DateRangePickerProps) {
  const handleChangeStart = (day: string | null) => {
    return onChange({ start: day, end })
  }

  const handleChangeEnd = (day: string | null) => {
    return onChange({ start, end: day })
  }

  const disabledForStart = end
    ? [
        {
          after: DateTime.fromISO(end)
            .minus({ days: minimalDays - 1 })
            .toJSDate(),
        },
      ]
    : undefined

  const disabledForEnd = start
    ? [
        {
          before: DateTime.fromISO(start)
            .plus({ days: minimalDays - 1 })
            .toJSDate(),
        },
      ]
    : undefined

  return (
    <Flex>
      <DayPicker
        dayOnly
        disableFuture={disableFuture}
        disablePast={disablePast}
        disabledDays={disabledForStart}
        from={start ? isoToJsDate(start) : null}
        withInput
        onChange={handleChangeStart}
      />

      <Spacer axis="horizontal" size={8} />

      <DayPicker
        dayOnly
        disableFuture={disableFuture}
        disablePast={disablePast}
        disabledDays={disabledForEnd}
        from={end ? isoToJsDate(end) : null}
        withInput
        onChange={handleChangeEnd}
      />
    </Flex>
  )
}

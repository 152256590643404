import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const CNESST = {
  LOAD: createAsyncAction('CNESST/LOAD'),
  CREATE: createAsyncAction('CNESST/CREATE'),
  UPDATE: createAsyncAction('CNESST/UPDATE'),
  DELETE: createAsyncAction('CNESST/DELETE'),
}

export const CNESST_STATUSES = {
  LOAD: createAsyncAction('CNESST_statuses/LOAD'),
  CREATE: createAsyncAction('CNESST_statuses/CREATE'),
  UPDATE: createAsyncAction('CNESST_statuses/UPDATE'),
  DELETE: createAsyncAction('CNESST_statuses/DELETE'),
}

const INCLUDE_CNESST = [
  'employee',
  'status',
  'attachments',
  'cnesstFiles',
  'tats',
  'visuals',
  'otherFiles',
  'eventReport',
  'adr',
  'bem',
  'medicalExpertise',
  'createdBy.profile',
  'updatedBy.profile',
].join(',')

const INCLUDE_CNESST_STATUSES = 'company'

export const loadCNESST = ({
  employeeId,
  number,
  size,
  sort = '-event_date,created_at',
}) => dispatch => {
  const paged = !!number && !!size
  const page = paged ? { number, size } : {}

  return dispatch(
    apiCall({
      endpoint: `/employees/${employeeId}/cnesst_records`,
      types: CNESST.LOAD,
      method: 'GET',
      query: {
        include: INCLUDE_CNESST,
        page,
        sort,
      },
      paged,
    }),
  )
}

/**
 * @link https://api.staging.workaxle.com/doc#returns-201-when-everything-is-ok
 */
export const createCNESST = ({
  employeeId,
  attributes,
  relationships,
}) => dispatch => {
  dispatch(
    apiCall({
      endpoint: `/employees/${employeeId}/cnesst_records`,
      types: CNESST.CREATE,
      method: 'POST',
      query: {
        include: INCLUDE_CNESST,
        data: {
          attributes,
          relationships,
        },
      },
    }),
  )
}

/**
 * @link https://api.staging.workaxle.com/doc#returns-200-when-everything-is-ok
 */
export const updateCNESST = ({
  cnesstId,
  attributes,
  relationships,
}) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/cnesst_records/${cnesstId}`,
      types: CNESST.UPDATE,
      method: 'PATCH',
      query: {
        include: INCLUDE_CNESST,
        data: {
          attributes,
          relationships,
        },
      },
    }),
  )
}

export const deleteCNESST = cnesstId => dispatch =>
  dispatch(
    apiCall({
      endpoint: `/cnesst_records/${cnesstId}`,
      types: CNESST.DELETE,
      method: 'DELETE',
      payload: {
        deletedId: cnesstId,
      },
    }),
  )

/**
 * CNESST Statuses
 */

export const loadCNESSTStatuses = (
  number = null,
  size = null,
  sort = '-name,created_at',
) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())
  const paged = !!number && !!size
  const page = paged ? { number, size } : {}

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/cnesst_statuses`,
      types: CNESST_STATUSES.LOAD,
      method: 'GET',
      query: {
        include: INCLUDE_CNESST_STATUSES,
        page,
        sort,
      },
      paged,
    }),
  )
}

export const createCNESSTStatuses = ({ attributes }) => (
  dispatch,
  getState,
) => {
  const companyId = getCompanyId(getState())
  dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/cnesst_statuses`,
      types: CNESST_STATUSES.CREATE,
      method: 'POST',
      query: {
        include: INCLUDE_CNESST_STATUSES,
        data: {
          attributes,
        },
      },
    }),
  )
}

export const updateCNESSTStatus = ({
  CNESSTStatusId,
  attributes,
}) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/cnesst_statuses/${CNESSTStatusId}`,
      types: CNESST_STATUSES.UPDATE,
      method: 'PATCH',
      query: {
        include: INCLUDE_CNESST_STATUSES,
        data: {
          attributes,
        },
      },
    }),
  )
}

export const deleteCNESSTStatus = CNESSTStatusId => dispatch =>
  dispatch(
    apiCall({
      endpoint: `/cnesst_statuses/${CNESSTStatusId}`,
      types: CNESST_STATUSES.DELETE,
      method: 'DELETE',
      payload: {
        deletedId: CNESSTStatusId,
      },
    }),
  )

import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import { FormInput } from 'components/ui/__v2__/Form'
import { Flex, Span } from 'components/ui/Grid'

import _ from 'i18n'

import { Answer, NoAnswer } from '../styles'

function LabeledTextfield({
  name,
  isRequired,
  isReadOnly,
  onChange,
  placeholder,
  label,
  ...rest
}) {
  const element = props => {
    if (isReadOnly) {
      return props.input.value ? (
        <Answer>{props.input.value}</Answer>
      ) : (
        <NoAnswer>{_('formBuilder.fallbacks.noAnswerProvided')}</NoAnswer>
      )
    }
    return (
      <FormInput
        {...props}
        placeholder={placeholder}
        type="text"
        onBlur={onChange}
        {...rest}
      />
    )
  }

  return (
    <Flex alignItems="center" flexDirection="row">
      <Span mr={2}>
        {label}
        {isRequired && <sup>*</sup>}
      </Span>
      <Field name={name}>{element}</Field>
    </Flex>
  )
}
LabeledTextfield.defaultProps = {
  isReadOnly: false,
}

LabeledTextfield.propTypes = {
  ...Field.propTypes,
  isReadOnly: PropTypes.bool,
}

export default LabeledTextfield

import { useApolloMutation } from 'API/services/Apollo'

import { CreateShiftTagMutation } from './GraphQL'

export function useCreateShiftTag() {
  const [createShiftTag, { loading: creating }] = useApolloMutation<
    MutationData<'createShiftTag'>,
    Gateway.MutationCreateShiftTagArgs
  >(CreateShiftTagMutation, { translationKey: 'shiftTags.toasts.create' })

  const create = async (
    variables: Omit<Gateway.CreateShiftTagInput, 'companyId'>,
  ) => {
    const response = await createShiftTag({
      variables: {
        input: variables,
      },
    })

    // FIXME: remove this hack after error stadardization and proper handling
    let errored
    if (response.data?.createShiftTag) {
      errored = false
    } else {
      errored = true
    }

    return errored
  }

  return { create, creating }
}

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Track = styled.div`
  width: 34px;
  height: 15px;
  padding: 0;
  border-radius: 30px;
  background-color: ${themeGet('colors.SWITCH_TRACK')};
  transition: all 0.2s ease;
`

export const Thumb = styled.div`
  position: absolute;
  top: -3px;
  left: -3px;
  width: 20px;
  height: 20px;
  border-image-source: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.12),
    rgba(255, 255, 255, 0.06) 20%,
    rgba(255, 255, 255, 0)
  );
  background-color: ${themeGet('colors.SEA_SHELL')};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  border-image-slice: 1;
  box-sizing: border-box;
  transition: all 0.25s ease;
`

export const Container = styled.div`
  width: 34px;
  height: 14px;
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  border-radius: 30px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s ease;

  ${props =>
    props.sameColor &&
    css`
      ${Track} {
        border-color: ${themeGet('colors.MAIN')(props)};
      }

      ${Thumb} {
        background-color: ${themeGet('colors.MAIN')(props)};
      }
    `};

  ${props =>
    props.checked &&
    css`
      ${Track} {
        background-color: ${themeGet('colors.SWITCH_ACTIVE')(props)};
        opacity: 0.5;
        border-color: ${themeGet('colors.WHITE')(props)};
      }

      ${Thumb} {
        left: 18px;
        background-color: ${themeGet('colors.SWITCH_ACTIVE')(props)};
      }
    `};
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
      transition: opacity 0.25s;

      ${Track} {
        background-color: ${themeGet('colors.WHITE')(props)};
        border-color: ${themeGet('colors.MAIN')(props)};
      }

      ${Thumb} {
        background-color: ${themeGet('colors.MAIN')(props)};
      }
    `};

  ${props =>
    props.readOnly &&
    css`
      pointer-events: none;
    `};
`

export const StyledInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

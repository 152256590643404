import styled, { css } from 'styled-components'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { inputSelectFocusedCss } from 'components/styles'
import { Flex } from 'components/ui/__v2__/Grid'
import {
  either,
  radius,
  when,
} from 'components/ui/__v3__/__utils__/styled-system'

import { IconProps, TextAreaProps, WrapperStyleProps } from './types'

export const InputWrapper = styled.div<WrapperStyleProps>`
  --icon-offset: 8px;

  display: inline;
  position: relative;

  ${layout};
  ${space};
`

export const IconContainer = styled.span<Pick<IconProps, 'iconPlacement'>>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  ${either(
    'iconPlacement',
    'left',
    css`
      left: var(--icon-offset);
    `,
    css`
      right: var(--icon-offset);
    `,
  )};
`

const resizeForTextArea = (props: Pick<TextAreaProps, 'disableResize'>) =>
  props.disableResize && 'resize: none;'

export const inputCss = css`
  color: ${either(
    'disabled',
    themeGet('layout.lightText'),
    themeGet('layout.text'),
  )};

  font-weight: inherit;
  font-family: inherit;
  font-size: 14px;
  outline: none;

  border-radius: ${radius('normal')};
  border: 1px solid ${themeGet('input.border.default')};
  background-color: ${themeGet('input.bg.default')};
  caret-color: ${themeGet('input.caret')};
  position: relative;
  width: 100%;
  height: 32px;
  transition: all ease-in-out ${themeGet('layout.transition.fast')};
  padding: 7px 8px;

  &:hover {
    border-color: ${themeGet('colors.MANATEE')};
  }

  &:focus {
    ${inputSelectFocusedCss}
  }

  ${resizeForTextArea}

  ${when(
    'errored',
    css`
      border-color: ${themeGet('input.border.errored')};
    `,
  )};

  ${when(
    'disabled',
    css`
      background-color: ${themeGet('input.bg.disabled')};
      border: 1px solid ${themeGet('input.border.disabled')};
      cursor: not-allowed;
    `,
  )};

  ::placeholder {
    color: ${either(
      'disabled',
      themeGet('layout.lightText'),
      themeGet('input.placeholder'),
    )};
  }
`

export const LabelTextContainer = styled(Flex)<LayoutProps & SpaceProps>`
  align-items: center;
  word-break: break-word;

  ${layout}
  ${space}
`

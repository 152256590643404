import { useApolloMutation } from 'API/services/Apollo'

import { StartEvaluatedTimeSync } from './GraphQL'
import { evaluatedTimesQueryParamsToFilterToSyncPayload } from './mapper'

import { EvaluatedTimesQueryParams } from '../../pages/EmployerApp/EvaluatedTime/hooks/types'

export function useStartEvaluatedTimeSync() {
  const [start, { loading }] = useApolloMutation<
    MutationData<'startEvaluatedTimesSync'>,
    Gateway.MutationStartEvaluatedTimesSyncArgs
  >(StartEvaluatedTimeSync, {
    translationKey: 'timeEvaluationPage.toast.sync',
  })

  async function startEvaluatedTimeSync(
    filter: EvaluatedTimesQueryParams['filters'],
  ) {
    const result = await start({
      variables: evaluatedTimesQueryParamsToFilterToSyncPayload(filter),
    })

    return result
  }

  return { startEvaluatedTimeSync, startingEvaluatedTimeSync: loading }
}

import { DateTime } from 'luxon'

import ceil from 'lodash/ceil'
import filter from 'lodash/filter'
import get from 'lodash/get'
import merge from 'lodash/merge'

const copyShiftAddingWeeksToItsDates = (shift, week, untilDate) => {
  const {
    attributes: { startAt, finishAt },
  } = shift

  const nextStartAt = DateTime.fromISO(startAt).plus({ weeks: week })
  const diff = DateTime.fromISO(untilDate).diff(nextStartAt, 'day').toObject()

  if (diff.days < -1) return null

  return merge({}, shift, {
    attributes: {
      startAt: nextStartAt.toJSDate(),
      finishAt: DateTime.fromISO(finishAt).plus({ weeks: week }).toJSDate(),
    },
  })
}

export const getWeeksUntilDate = (shift, untilDate) => {
  const firstDayOfTheNextWeek = DateTime.fromISO(
    get(shift, 'attributes.startAt'),
  )
    .endOf('week')
    .plus({ days: 1 })
    .toJSDate()

  return ceil(
    DateTime.fromISO(untilDate)
      .plus({ days: 1 })
      .diff(DateTime.fromJSDate(firstDayOfTheNextWeek), 'weeks')
      .toObject().weeks,
  )
}

export const repeatShifts = (baseShifts, isRepeat, untilDate = null) => {
  const clearedShifts = filter(baseShifts, shift => {
    const startAt = DateTime.fromISO(get(shift, 'attributes.startAt'))
    const diff = DateTime.local().diff(startAt, 'milliseconds').toObject()
    return diff.milliseconds < 0
  })

  if (isRepeat) {
    const weeksTillUntilDate = getWeeksUntilDate(baseShifts[0], untilDate)

    if (weeksTillUntilDate > 0) {
      return baseShifts.reduce(
        (shifts, next) => {
          for (let i = 1; i <= weeksTillUntilDate; i += 1) {
            const nextShift = copyShiftAddingWeeksToItsDates(next, i, untilDate)
            if (nextShift) shifts.push(nextShift)
          }

          return shifts
        },
        [...clearedShifts],
      )
    }
  }

  return baseShifts
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { showCoreAPIErrorToast, showToast } from 'services/Toasts'

import { AvailabilityRequestQueryKey } from './config'
import * as Helpers from './helpers'
import { createAvailabilityRequestMutation } from './JSON'

export function useCreateAvailabilityRequest() {
  const queryClient = useQueryClient()

  const { mutateAsync, isLoading } = useMutation(
    AvailabilityRequestQueryKey.all(),
    createAvailabilityRequestMutation,
    {
      onSuccess: (response, args) => {
        if (response.ok) {
          const { title, content } = Helpers.argsToResponseMessage(args)

          showToast({ type: 'success', title, content })
          queryClient.invalidateQueries(AvailabilityRequestQueryKey.all())
        } else {
          const errorTitle = Helpers.getErrorTitle(args.requestType)
          showCoreAPIErrorToast({
            response,
            title: errorTitle,
            skipErrorField: true,
          })
        }
      },
    },
  )

  return {
    createAvailabilityRequest: mutateAsync,
    isCreatingAvailabilityRequest: isLoading,
  }
}

import { useEffect } from 'react'

export function useClickOutside(elRef, callback) {
  useEffect(() => {
    const handleClickOutside = event => {
      if (elRef.current && !elRef.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener('click', handleClickOutside, { capture: true })

    return () =>
      document.removeEventListener('click', handleClickOutside, {
        capture: true,
      })
  }, [elRef, callback])
}

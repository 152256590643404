import React from 'react'

import { LONG_DASH } from 'Tables/constant'
import { CustomLeave } from 'Types/app'

import { Flex } from 'components/ui/__v2__/Grid'

import { i18n } from 'i18n'

type Props = {
  leaveDay: CustomLeave
}

export function UsageRightsCell({ leaveDay }: Props) {
  const { kind, blockEmployeeRequest } = leaveDay

  if (kind) return LONG_DASH

  return (
    <Flex alignItems="center" justifyContent="space-between" maxWidth={'100%'}>
      {i18n(
        `customLabelDays.usageRights.${
          blockEmployeeRequest ? 'managersOnly' : 'bothManagersEmployees'
        }`,
      )}
    </Flex>
  )
}

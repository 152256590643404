import React, { PropsWithChildren } from 'react'
import ReactModal from 'react-modal'

import { useTheme } from 'styled-components'

import { Box } from 'components/ui/__v2__/Grid'

import { SpinnerContainer, StyledModalSpinner } from './styles'

interface ContainerProps {
  isLoading?: boolean
  isOpen: boolean
  customStyles?: ReactModal.Styles
  padding?: number
}
export function ModalContainer({
  isOpen,
  customStyles,
  isLoading,
  children,
  padding = 22,
}: PropsWithChildren<ContainerProps>) {
  const theme = useTheme()

  return (
    // @ts-ignore
    <ReactModal
      ariaHideApp={false}
      // eslint-disable-next-line react/no-children-prop
      children={children}
      closeTimeoutMS={270}
      contentElement={(props, content) => (
        <Box {...props}>
          {content}
          {isLoading && (
            <SpinnerContainer>
              <StyledModalSpinner />
            </SpinnerContainer>
          )}
        </Box>
      )}
      isOpen={isOpen}
      style={{
        ...ReactModal.defaultStyles,
        overlay: {
          zIndex: theme.zIndices.modal,
        },
        content: {
          position: 'relative',
          top: 'initial',
          left: 'initial',
          right: 'initial',
          bottom: 'initial',
          padding,
          margin: 'auto',
          border: 'none',
          fontFamily: theme.fonts[0],
          transition: 'all 0.2s ease',
        },
        ...customStyles,
      }}
    />
  )
}

import styled from 'styled-components'

import SvgIcons from 'assets/images/__v2__/svg'

import { SvgIcon } from 'components/ui/__v2__/SvgIcon'

const File = styled(SvgIcon).attrs({
  glyph: SvgIcons.File,
})``

File.defaultProps = {
  width: 12,
  height: 14,
}

export default File

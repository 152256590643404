import React, { useCallback, useEffect, useMemo, useState } from 'react'
import MaskedInput from 'react-text-mask'

import {
  Areas,
  AreasBranch,
  Branch,
  Department,
  Job,
  JobEmployee,
  RoleEffectivePeriod,
} from 'Types/app'
import { SelectOption } from 'Types/common'

import groupBy from 'lodash/groupBy'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import pick from 'lodash/pick'

import {
  checkIfIntervalsOverlapping,
  EffectivePeriods,
  getEffectiveDatesValidation,
} from 'components/blocks/__v2__'
import Picker from 'components/blocks/AreasPicker/Picker'
import { PICKER_ORIENTATION } from 'components/blocks/AreasPicker/Picker/Picker'
import AddRolesTable from 'components/tables/AddRoles'
import { Flex } from 'components/ui/__v2__/Grid'
import { Input, InputLabel } from 'components/ui/__v3__/Input'
import { Select } from 'components/ui/__v3__/Select'

import {
  AREAS_ITEM_TYPES,
  LOCATION_DEPARTMENT_DISPLAY_FILTER,
} from 'constants/areas'
import { WAGE_MASK } from 'constants/employees'
import { WageType } from 'constants/ids'

import useAppContext from 'hooks/useAppContext'

import _ from 'i18n'

import { useRolesSectionContext } from 'pages/Profile/pages/General/ProfileInformation/components/RolesSection/context'

import { locationToOption } from 'services/Options'

import { Container, RolesInfo } from './styles'
import {
  AreasInnerState,
  RoleCombintation,
  RoleCombintationStateItem,
  RoleSelectorProps,
} from './types'

const wageOptions: SelectOption<WageType>[] = [
  {
    value: WageType.Hour,
    label: _('people.perHour'),
  },
  {
    value: WageType.Year,
    label: _('people.perYear'),
  },
]

const DEFAULT_WAGE = 0
const DEFAULT_WAGE_TYPE = wageOptions[0]
const DEFAULT_AREAS: Areas = {
  [AREAS_ITEM_TYPES.locations]: [],
  [AREAS_ITEM_TYPES.departments]: [],
  [AREAS_ITEM_TYPES.jobs]: [],
}

// TODO: Note: this compoennt has the minimum TS compatability and need to be properly refactored
function RolesSelector({
  rolesToExclude = [],
  displayWithRows = false,
  setRolesToSubmit,
  isMulti = { locations: true, departments: true, jobs: true },
  lockLocation = false,
  withWage = false,
  withPeriods = false,
  isSubmitSuccessful = false,
}: RoleSelectorProps) {
  const { isEtfo } = useAppContext()

  // @ts-ignore FIXME: Should be reingineered so the component can ve used anywhere
  const { canAddEffectivePeriods } = useRolesSectionContext()

  const pickerDisplay = {
    locations: LOCATION_DEPARTMENT_DISPLAY_FILTER.managed,
    departments: LOCATION_DEPARTMENT_DISPLAY_FILTER.managed,
  }

  const [roles, setRoles] = useState<Array<RoleCombintationStateItem>>([])
  const locationToLock = lockedLocation(roles)
  const defaultState = composeDefaultState(
    !!isMulti.locations,
    lockLocation ? locationToLock : null,
  )

  const [areas, setAreas] = useState<Areas>(defaultState)

  const [effectiveDates, setEffectiveDates] = useState<
    Array<RoleEffectivePeriod>
  >([])
  const [wage, setWage] = useState(DEFAULT_WAGE)
  const [wageType, setWageType] = useState(DEFAULT_WAGE_TYPE)

  const initialCombinationsToExclude = useMemo(
    () => rolesToExclude?.map(role => jobEmployeeToCombination(role)),
    [rolesToExclude],
  )
  const enabledCombinations = useMemo(
    () => [...initialCombinationsToExclude, ...roles],
    [initialCombinationsToExclude, roles],
  )

  useEffect(() => {
    setRolesToSubmit(roles)
  }, [setRolesToSubmit, roles])

  const byExcluded = useCallback(
    ({
      branch: iterableLocation,
      department: iterableDepartment,
      job: iterableJob,
    }) => {
      return !enabledCombinations?.find(
        combination =>
          combination?.branch?.id === iterableLocation?.id &&
          combination?.department?.id === iterableDepartment?.id &&
          combination?.job?.id === iterableJob?.id,
      )
    },
    [enabledCombinations],
  )

  const isEffectiveDatesValid = getEffectiveDatesValidation(effectiveDates)
  const isOverlap = effectiveDates
    ? checkIfIntervalsOverlapping(effectiveDates)
    : false

  const resetForm = useCallback((areasStateToSet: Areas) => {
    setEffectiveDates([])
    setAreas(areasStateToSet)
    setWage(DEFAULT_WAGE)
    setWageType(DEFAULT_WAGE_TYPE)
  }, [])

  const handleAddToTable = useCallback(() => {
    const allCombinations = [] as RoleCombintation[]

    // Each on each
    maybeSingle<'locations'>(areas?.locations)?.forEach(locationItem => {
      maybeSingle<'departments'>(areas?.departments)?.forEach(
        departmentItem => {
          maybeSingle<'jobs'>(areas?.jobs)?.forEach(jobItem => {
            allCombinations.push({
              branch: locationItem?.location,
              department: departmentItem?.department,
              job: jobItem?.job,
            })
          })
        },
      )
    })

    const filteredCombinations = allCombinations
      ?.filter(byHierarchyExistence)
      ?.filter(byExcluded)

    const combinationsToAdd = filteredCombinations?.map(combination => ({
      ...combination,
      wage,
      wageType: wageType?.value,
      effectiveDates: effectiveDates?.map(date => ({
        start: date?.start,
        end: date?.end,
      })),
    }))

    setRoles(prevState => [...prevState, ...combinationsToAdd])
    const locationToLock = lockedLocation(combinationsToAdd)
    resetForm(
      composeDefaultState(
        !!isMulti.locations,
        lockLocation ? locationToLock : null,
      ),
    )
  }, [
    areas?.locations,
    areas?.departments,
    areas?.jobs,
    byExcluded,
    resetForm,
    isMulti.locations,
    lockLocation,
    wage,
    wageType?.value,
    effectiveDates,
  ])

  const handleClearInfo = useCallback(() => {
    setRoles([])
    resetForm(DEFAULT_AREAS)
  }, [resetForm])

  useEffect(() => {
    if (isSubmitSuccessful) handleClearInfo()
  }, [isSubmitSuccessful, handleClearInfo])

  const handleRemoveCombinations = useCallback(
    (combinationsToRemove: RoleCombintation[]) => {
      setRoles(stateCombinations =>
        differenceByIDs(stateCombinations, combinationsToRemove),
      )
    },
    [],
  )

  const addDisabled =
    (isArray(areas?.jobs) ? areas?.jobs?.length === 0 : isEmpty(areas?.jobs)) ||
    (!isEtfo && isNil(wage)) ||
    (!isEtfo && !wageType) ||
    !isEffectiveDatesValid ||
    isOverlap

  const jobIdsThatAreFullyEnabled = useMemo(() => {
    const selectedHierarchyNodes = maybeSingle<'locations'>(areas?.locations)
      ?.map(locationItem => locationItem?.location?.hierarchyNodes)
      ?.flat()

    const combinationsPossibleForHierarchyNodes = selectedHierarchyNodes
      ?.map(hierarchyNode => {
        return hierarchyNode?.jobs?.map(hierarchyNodeJob => ({
          job: hierarchyNodeJob,
          branch: hierarchyNode?.branch,
          department: hierarchyNode?.department,
        }))
      })
      ?.flat()

    const selectedDepartmentIds = maybeSingle<'departments'>(
      areas?.departments,
    )?.map(department => department?.id)
    const combinationsNarrowedByDepartmentSelection = combinationsPossibleForHierarchyNodes?.filter(
      combination =>
        selectedDepartmentIds?.includes(combination?.department?.id),
    )

    // Now we want to find the job ids that have all possible combinations enabled - and exlude them
    const grouped = Object.entries(
      groupBy(combinationsNarrowedByDepartmentSelection, 'job.id'),
    )

    return (
      grouped
        ?.filter(([, combinations]) => {
          // only the ones that have ALL combinations enabled
          // @ts-ignore FIXME:
          return isEmpty(differenceByIDs(combinations, enabledCombinations))
        })
        ?.map(([jobId]) => jobId) ?? []
    )
  }, [areas?.departments, areas?.locations, enabledCombinations])

  return (
    <Container>
      {/* @ts-ignore */}
      <Picker
        cascade
        display={pickerDisplay}
        exclude={{ jobs: jobIdsThatAreFullyEnabled }}
        include={{ locations: 'hierarchyNodes.jobs' }}
        isDisabled={{
          locations: lockLocation && !!locationToLock,
          departments: isEmpty(areas?.locations),
          jobs: isEmpty(areas?.departments),
        }}
        isMulti={isMulti}
        orientation={
          displayWithRows ? PICKER_ORIENTATION.ROW : PICKER_ORIENTATION.COLUMN
        }
        value={areas}
        onChange={(changedAreas: Areas) => setAreas(changedAreas)}
      />
      {withWage && (
        <Flex>
          <InputLabel flex={1} mr={1}>
            {_('employees.wage')}
            <MaskedInput
              mask={WAGE_MASK}
              render={(ref, inputProps) => (
                <Input ref={ref} {...inputProps} fontSize={14} width={1} />
              )}
              value={wage}
              onChange={event => setWage(Number(event.target.value))}
            />
          </InputLabel>
          <InputLabel as="span" flex={1} ml={1}>
            {_('employees.payment')}
            <Select
              // @ts-ignore
              options={wageOptions}
              value={wageType}
              width={1}
              onChange={setWageType}
            />
          </InputLabel>
        </Flex>
      )}
      {(canAddEffectivePeriods || withPeriods) && (
        <Flex mt={4}>
          <InputLabel as="span" flex={1}>
            {_('employees.effectivePeriods')}
            <EffectivePeriods
              disabledWarningText={_('effectiveDates.repeatableRoleMapWarning')}
              infoText={_('effectiveDates.ifNoEffectiveDate')}
              isOverlap={isOverlap}
              minimalDays={2}
              value={effectiveDates}
              onChange={setEffectiveDates}
            />
          </InputLabel>
        </Flex>
      )}
      <AddRolesTable
        addDisabled={addDisabled}
        roles={roles}
        withPeriods={withPeriods}
        withWage={withWage}
        onAdd={handleAddToTable}
        onClear={handleClearInfo}
        onRemove={handleRemoveCombinations}
      />
      {roles.length > 0 && (
        <RolesInfo>{`${roles.length} ${_('common.rolesSelected')}`}</RolesInfo>
      )}
    </Container>
  )
}

export default RolesSelector

function byHierarchyExistence({
  branch,
  department,
  job,
}: {
  branch: Branch
  department: Department
  job: Job
}): boolean {
  // We're checking if a given combination exists. The hierarchy nodes bring location <-> department connection and have jobs[] per each node
  // If no node found that matches the criteria - combination is invalid
  return !!branch?.hierarchyNodes?.find(hierarchyNode => {
    const nodeDepartmentId = hierarchyNode?.department?.id
    const nodeJobsIds = hierarchyNode?.jobs.map(nodeJob => nodeJob.id)
    return nodeDepartmentId === department?.id && nodeJobsIds.includes(job?.id)
  })
}

function differenceByIDs(
  arrayOfCombinations: RoleCombintationStateItem[] | undefined,
  subtractableCombinations: RoleCombintation[],
): RoleCombintationStateItem[] {
  return (
    arrayOfCombinations?.filter(
      stateCombination =>
        !subtractableCombinations?.some(
          combinationToRemove =>
            combinationToRemove?.branch?.id === stateCombination?.branch?.id &&
            combinationToRemove?.department?.id ===
              stateCombination?.department?.id &&
            combinationToRemove?.job?.id === stateCombination?.job?.id,
        ),
    ) ?? []
  )
}

function jobEmployeeToCombination(jobEmployee: JobEmployee): RoleCombintation {
  return pick(jobEmployee, [
    'wage',
    'wageType',
    'effectiveDates',
    'job',
    'department',
    'branch',
  ])
}

function maybeSingle<T extends 'locations' | 'departments' | 'jobs'>(
  areasItem: Areas[T] | undefined,
): AreasInnerState[T] {
  return isArray(areasItem) ? areasItem : [areasItem]
}

function lockedLocation(
  roles: Array<RoleCombintationStateItem>,
): AreasBranch | null {
  if (isEmpty(roles)) return null
  // @ts-ignore
  return locationToOption(roles[0].branch)
}

function composeDefaultState(
  isMulti: boolean,
  locationToLock: AreasBranch | null,
): Areas {
  if (!locationToLock) return DEFAULT_AREAS
  const lockedDefault = isMulti
    ? {
        locations: [locationToLock],
        departments: [],
        jobs: [],
      }
    : {
        locations: locationToLock,
        departments: undefined,
        jobs: undefined,
      }

  return lockedDefault
}

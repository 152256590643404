import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { Select } from 'components/ui/__v3__/Select'

import { EMPLOYEE_STATE_FILTERS } from 'constants/employees'

import { createDebounce } from 'helpers/debounce'
import { denormalize } from 'helpers/redux'

import _ from 'i18n'

import { showError } from 'services/API'
import { staffMemberToOption } from 'services/Options'

// Staff items, not employees
function mapOptions(staffItems) {
  return staffItems.map(item => staffMemberToOption(item))
}

function StaffPicker({
  cacheOptions,
  display,
  initial,
  isDisabled,
  loadingText,
  placeholder,
  preload,
  showJobs,
  value,
  onClear,
  onLoad,
  onInit,
  ...rest
}) {
  const defaultOptions = useMemo(() => {
    return mapOptions(initial.entities ?? [])
  }, [initial.entities])

  const locationIds = useMemo(() => {
    if (isEmpty(rest.locationIds)) return null

    return isArray(rest.locationIds) ? rest.locationIds : [rest.locationIds]
  }, [rest.locationIds])

  const departmentIds = useMemo(() => {
    if (isEmpty(rest.departmentIds)) return null

    return isArray(rest.departmentIds)
      ? rest.departmentIds
      : [rest.departmentIds]
  }, [rest.departmentIds])

  const jobIds = useMemo(() => {
    if (isEmpty(rest.jobIds)) return null

    return isArray(rest.jobIds) ? rest.jobIds : [rest.jobIds]
  }, [rest.jobIds])

  const accessLevels = useMemo(() => {
    if (isEmpty(rest.accessLevels)) return null

    return isArray(rest.accessLevels) ? rest.accessLevels : [rest.accessLevels]
  }, [rest.accessLevels])

  const statuses = useMemo(() => {
    if (isEmpty(rest.statuses)) return null
    return rest.statuses.map(status => ({ value: status }))
  }, [rest.statuses])

  const initOptions = useCallback(() => {
    onInit({
      filters: {
        areas: {
          locations: locationIds,
          departments: departmentIds,
          jobs: jobIds,
        },
        accessLevels,
        statuses,
      },
      display,
    })
  }, [
    accessLevels,
    departmentIds,
    display,
    jobIds,
    locationIds,
    onInit,
    statuses,
  ])

  const prevLocationIds = useRef(locationIds)
  const prevDepartmentIds = useRef(departmentIds)
  const prevJobIds = useRef(jobIds)
  const prevAccessLevels = useRef(accessLevels)
  useEffect(() => {
    if (
      !isEqual(prevLocationIds.current, locationIds) ||
      !isEqual(prevDepartmentIds.current, departmentIds) ||
      !isEqual(prevJobIds.current, jobIds) ||
      !isEqual(prevAccessLevels.current, accessLevels)
    ) {
      onClear()
      prevLocationIds.current = locationIds
      prevDepartmentIds.current = departmentIds
      prevJobIds.current = jobIds
      prevAccessLevels.current = accessLevels
    }
  }, [locationIds, departmentIds, onClear, jobIds, accessLevels])

  useEffect(() => {
    if (!isDisabled && preload && !initial.isLoaded) {
      initOptions()
    }
  }, [initial.isLoaded, preload, isDisabled, initOptions])

  const debouncedLoad = useMemo(() => {
    return createDebounce(
      (inputValue, callback) => {
        if (!inputValue) {
          callback([])
        } else {
          onLoad({
            filters: {
              areas: {
                locations: locationIds,
                departments: departmentIds,
                jobs: jobIds,
              },
              accessLevels,
              statuses,
              search: inputValue,
            },
            display,
          }).then(result => {
            if (result?.ok) {
              const data = get(result, ['payload', 'data'])
              const staffIds = map(get(data, 'staff'), 'id')

              const denormalizedStaff = isEmpty(staffIds)
                ? []
                : denormalize(data, 'staff', staffIds)

              callback(mapOptions(denormalizedStaff))
            } else {
              showError([{ detail: _('common.somethingWentWrong') }]) // BE usually returns no detail
              callback([])
            }
          })
        }
      },
      { leading: true, trailing: true },
    )
  }, [
    onLoad,
    locationIds,
    departmentIds,
    jobIds,
    accessLevels,
    display,
    statuses,
  ])

  const renderLoadingMessage = useCallback(
    () => loadingText ?? `${_('common.loading')}...`,
    [loadingText],
  )

  return (
    <Select
      async
      cacheOptions={cacheOptions}
      closeMenuOnSelect={!rest.isMulti}
      defaultOptions={defaultOptions}
      isDisabled={isDisabled}
      isLoading={initial.isLoading}
      isSearchable
      loadOptions={debouncedLoad}
      loadingMessage={renderLoadingMessage}
      placeholder={placeholder}
      value={value}
      onMenuOpen={initOptions}
      {...rest}
    />
  )
}

StaffPicker.defaultProps = {
  accessLevels: null,
  cacheOptions: true,
  departmentIds: null,
  display: null,
  initial: {},
  isDisabled: false,
  jobIds: null,
  loadingText: null,
  locationIds: null,
  placeholder: null,
  preload: false,
  showJobs: false,
  statuses: null,
  value: null,
  withPortal: true,
  onClear: noop,
  onInit: noop,
  onLoad: noop,
}

StaffPicker.propTypes = {
  ...Select.propTypes,
  accessLevels: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  cacheOptions: PropTypes.bool,
  departmentIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  display: PropTypes.string,
  initial: PropTypes.object,
  isDisabled: PropTypes.bool,
  jobIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  loadingText: PropTypes.string,
  locationIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  placeholder: PropTypes.string,
  preload: PropTypes.bool,
  showJobs: PropTypes.bool,
  statuses: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(EMPLOYEE_STATE_FILTERS)),
  ),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  withPortal: PropTypes.bool,
  onClear: PropTypes.func,
  onInit: PropTypes.func,
  onLoad: PropTypes.func,
}

export default StaffPicker

import { areasFilterToGraphql } from 'API/services/utils'

import { CopyPasteShiftFilter } from '../../types'

export function copyPasteShiftFilterToAPI(
  stateFilters: CopyPasteShiftFilter,
): Gateway.CopyShiftsFilter {
  const apiFilter: Gateway.CopyShiftsFilter = {
    // NOTE: areasFilterToGraphql returns locationId, we don't need locations here
    ...areasFilterToGraphql({
      departments: stateFilters.areas.departments,
      jobs: stateFilters.areas.jobs,
    }),
  }

  if (stateFilters.positionCondition) {
    apiFilter.positionCondition = stateFilters.positionCondition
  }

  if (stateFilters.shiftTags?.length) {
    const shiftTagIds = stateFilters.shiftTags.map(({ value }) =>
      value.toString(),
    )
    apiFilter.shiftTagIds = {
      ...(stateFilters.shiftTagsFilterMode === 'and'
        ? { all: shiftTagIds }
        : { any: shiftTagIds }),
    }
  }

  return apiFilter
}

import { useCallback, useState } from 'react'

import {
  isNetworkRequestInFlight,
  NetworkStatus,
} from '@apollo/client/core/networkStatus'
import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { compact } from 'lodash'

import { IntegrationsWithLastEventAndPulledFilesByCursorQuery } from './GraphQL'

export function useIntegrationsWithEventsAndPulledFilesByCursor({
  page,
  sorting,
  enabled = true,
  refetchWithLoading = true,
}: {
  page: CursorPage
  sorting?: Gateway.IntegrationsByCursorSorting
  enabled?: boolean
  refetchWithLoading?: boolean
}) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, refetch, fetchMore, networkStatus } = useApolloQuery<
    QueryData<'integrationsWithEventsAndPulledFilesByCursor'>,
    Gateway.QueryIntegrationsByCursorArgs
  >(IntegrationsWithLastEventAndPulledFilesByCursorQuery, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !enabled,
    variables: {
      sorting,
      paging: cursorPageToGraphqlPaging(page),
    },

    onCompleted(data) {
      const newPageMeta =
        data.integrationsWithEventsAndPulledFilesByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const integrationsFetchMore = useCallback(async () => {
    await fetchMore({
      variables: {
        paging: {
          limit: page.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })
  }, [currentPageMeta.endCursor, fetchMore, page.size])

  const isLoading = refetchWithLoading
    ? isNetworkRequestInFlight(networkStatus)
    : networkStatus === NetworkStatus.loading

  const integrations = compact(
    data?.integrationsWithEventsAndPulledFilesByCursor?.edges?.map(
      ({ node }) => node && node,
    ) ?? [],
  )

  return {
    integrations,
    integrationsLoading: isLoading,
    integrationsRefetch: refetch,
    integrationsFetchMore,
    integrationsPageMeta: currentPageMeta,
  }
}

import { useApolloMutation } from 'API/services/Apollo'

import { CreateGardaReportMutation } from './GraphQL'

export function useCreateGardaReport() {
  const [createGardaReport, { loading: creating }] = useApolloMutation<
    MutationData<'createGardaReport'>,
    Gateway.MutationCreateGardaReportArgs
  >(CreateGardaReportMutation, {
    translationKey: 'gardaReports.toasts.createReport',
  })

  const create = async (
    params: Gateway.MutationCreateGardaReportArgs['input'],
  ) => {
    return createGardaReport({
      variables: {
        input: params,
      },
    })
  }

  return { create, creating }
}

import { createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import get from 'lodash/get'

import { AS_EMPLOYEE } from 'constants/cookiesKeys'

import * as cookiesHelpers from 'helpers/cookies'
import { createReducer } from 'helpers/redux'

import { AUTHENTICATE, LOG_OUT } from 'store/actions/auth'
import { CREATE_COMPANY } from 'store/actions/company/companies'
import { TOGGLE_TOKEN } from 'store/actions/header'
import {
  GENERATE_TOKEN,
  LOAD_COUNTERS,
  LOAD_VIEWER,
  LOAD_VIEWER_COMPANY,
  RECEIVE_PROFILE,
  SET_AS_EMPLOYEE,
  SET_BRANCH,
  SET_COMPANY,
  TOGGLE_THEME,
  UPDATE_USER_SETTINGS,
} from 'store/actions/viewer'

export const viewerInitialState = Immutable({
  id: null,
  companyId: null,
  branchId: null,
  newAvatarId: null,
  asEmployee: Boolean(cookiesHelpers.get(AS_EMPLOYEE)),
  themeName: 'main',
  isLoading: false,
  isLoaded: false,
  isProcessingProfileAvatar: false,
  isUpdating: false,
  isBranchSwitching: false,
  isInvalidEmail: false,
  isValidating: false,
  isChangePasswordRequest: false,
  isShowLostAccessModal: false,
  generatedToken: null,
  generatedTokenError: false,
  generatedTokenLoading: false,
  error: null,
  counters: {},
})

const authSuccess = state =>
  state.merge({
    isLoading: false,
    error: null,
  })

const loadCompany = createLoadHandler('companies', {
  singular: true,
  mapToKey: 'companyId',
  withLoading: false,
})

const handlers = {
  [LOAD_VIEWER.SUCCESS]: createLoadHandler('users', {
    mapToKey: 'id',
    singular: true,
    withLoading: false,
    addToState: {
      isLoaded: true,
    },
  }),

  [AUTHENTICATE.SUCCESS]: authSuccess,

  [UPDATE_USER_SETTINGS.REQUEST]: state => state.merge({ isUpdating: true }),
  [UPDATE_USER_SETTINGS.SUCCESS]: state => state.merge({ isUpdating: false }),

  [LOAD_VIEWER_COMPANY.SUCCESS]: loadCompany,

  [CREATE_COMPANY.SUCCESS]: loadCompany,

  [SET_BRANCH]: (state, { payload }) =>
    state.merge({ branchId: payload.branchId, isBranchSwitching: true }),

  [SET_AS_EMPLOYEE]: (state, { payload }) =>
    state.merge({ asEmployee: payload.asEmployee }),

  [SET_COMPANY]: (state, { companyId }) => state.merge({ companyId }),

  [TOGGLE_THEME]: (state, { payload }) =>
    state.merge({ themeName: payload.themeName }),

  [RECEIVE_PROFILE]: createLoadHandler('users', {
    mapToKey: 'id',
    singular: true,
    withLoading: false,
  }),

  [LOAD_COUNTERS.SUCCESS]: (state, { payload }) =>
    state.merge({ counters: payload.data }),

  [GENERATE_TOKEN.REQUEST]: state =>
    state.merge({
      generatedToken: null,
      generatedTokenError: false,
      generatedTokenLoading: true,
    }),
  [GENERATE_TOKEN.SUCCESS]: (state, { payload }) =>
    state.merge({
      generatedToken: get(payload, 'data.token'),
      generatedTokenError: false,
      generatedTokenLoading: false,
    }),
  [GENERATE_TOKEN.FAILURE]: state =>
    state.merge({
      generatedToken: null,
      generatedTokenError: true,
      generatedTokenLoading: false,
    }),

  [TOGGLE_TOKEN]: state =>
    state.merge({
      generatedToken: null,
      generatedTokenError: false,
      generatedTokenLoading: false,
    }),

  [LOG_OUT]: state => viewerInitialState.merge({ locale: state.locale }),
}

export default createReducer(viewerInitialState, handlers)

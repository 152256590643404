import { DateTime } from 'luxon'

import { TimeCardStatus } from 'constants/timeCards'

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getWeek } from 'store/selectors/employees/shifts'

export const ADD_TIMER_ID = 'timeLog/ADD_TIMER_ID'

export const LOAD_TIMERS = createAsyncAction('timeLog/LOAD_TIMERS')

const timersIncludes = [
  'schedule.shiftsJob.job.department',
  'schedule.timeEntry,pauseTimers',
  'pauseTimers',
  'pauseTimers.pause',
  'notes',
  'job',
  'job.department',
  'pauseTimers.notes',
  'timeBucket',
  'timeBucket.timeBucketParent',
]

export const loadTimers = () => (dispatch, getState) => {
  const state = getState()
  const { start, end } = getWeek(state)

  const fromDate = DateTime.fromISO(start).startOf('day').toJSDate()
  const toDate = DateTime.fromISO(end).endOf('day').toJSDate()

  dispatch(
    apiCall({
      endpoint: '/user/relationships/time_entries',
      query: {
        filter: {
          state: {
            in: [
              TimeCardStatus.Pending,
              TimeCardStatus.Approved,
              TimeCardStatus.Discarded,
              TimeCardStatus.Future,
            ],
          },
          range: {
            between: [fromDate, toDate],
          },
        },
        include: timersIncludes.join(),
      },
      types: LOAD_TIMERS,
    }),
  )
}

export const addTimerId = timerId => ({
  type: ADD_TIMER_ID,
  payload: { timerId },
})

import { useCallback } from 'react'

import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import {
  DeleteTimeEntriesMutation,
  TimeEntriesByCursorQuery,
  TimeEntrySummariesByCursorQuery,
} from './GraphQL'

export function useDeleteTimeEntries() {
  const [innerDelete, { loading, error }] = useApolloMutation<
    MutationData<'deleteTimeEntries'>,
    Gateway.MutationDeleteTimeEntriesArgs
  >(DeleteTimeEntriesMutation, {
    refetchQueries: compact(
      [TimeEntriesByCursorQuery, TimeEntrySummariesByCursorQuery].map(
        getOperationName,
      ),
    ),
  })

  const deleteTimeEntries = useCallback(
    async (input: Gateway.TimeEntryIdsInput) => {
      const result = await innerDelete({
        variables: {
          input,
        },
      })

      return graphQLResultHasError(result)
    },
    [innerDelete],
  )

  return { deleteTimeEntries, deleting: loading, deleteError: error }
}

import { areasToFilters } from 'helpers/areas'

import coreApiCall from 'services/API/coreApi'

export interface CompanyHolidaysQueryArgs {
  companyId: string
  filter: {
    areas?: {
      locationIds: string[]
      departmentIds: string[]
      roleIds: string[]
    }
    date?: {
      between?: string[]
    }
  }
}

export function companyHolidaysQuery({
  companyId,
  filter,
}: CompanyHolidaysQueryArgs) {
  return coreApiCall({
    endpoint: `/companies/${companyId}/holidays`,
    query: {
      filter: {
        ...filter,
        ...areasToFilters(filter.areas),
      },
    },
  })
}

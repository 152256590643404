import React, { useCallback } from 'react'

import { Flex } from 'components/ui/__v2__/Grid'
import { Popover } from 'components/ui/__v2__/Popover'

import FormSelect from './FormSelect'
import { PlainText } from './styles'

function FormPlainTextSelect({ ...rest }) {
  const formatOptionLabel = useCallback(
    option => (
      <Flex alignItems="center">
        <Popover content={option.label} placement="right">
          <PlainText>{option.label}</PlainText>
        </Popover>
      </Flex>
    ),
    [],
  )

  return <FormSelect {...rest} formatOptionLabel={formatOptionLabel} />
}

export default FormPlainTextSelect

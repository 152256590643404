import { AvailableEmployeeForShiftJobByCursorNode } from 'API/Employee/GraphQL'
import { ShiftValidationEssentialAPIItem } from 'API/RuleValidation/GraphQL/Fragments/types'

export function getShiftConflictsByEmployeeId(
  employeeId: number,
  shiftValidations: ShiftValidationEssentialAPIItem[],
) {
  return (
    shiftValidations.find(
      shiftValidation => shiftValidation.employee.id === employeeId,
    )?.conflicts ?? []
  )
}

export function sortByBid(
  availableEmployees: AvailableEmployeeForShiftJobByCursorNode[],
) {
  const employeesWithBid: AvailableEmployeeForShiftJobByCursorNode[] = []
  const employeesWithoutBid: AvailableEmployeeForShiftJobByCursorNode[] = []

  availableEmployees.forEach(employee => {
    if (employee.hasBid) employeesWithBid.push(employee)
    else employeesWithoutBid.push(employee)
  })

  return employeesWithBid.concat(employeesWithoutBid)
}

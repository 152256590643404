import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'

import { PulledFileQuery } from './GraphQL'

type Props = {
  id: string
  enabled?: boolean
} & Pick<QueryHookOptions, 'pollInterval'>

export function usePulledFile({ id, enabled, pollInterval }: Props) {
  const { data, loading } = useApolloQuery<
    QueryData<'pulledFile'>,
    Gateway.QueryPulledFileArgs
  >(PulledFileQuery, {
    fetchPolicy: 'network-only',
    skip: !enabled,
    pollInterval,
    variables: {
      id,
    },
  })

  return {
    pulledFile: data?.pulledFile,
    pulledFileLoading: loading,
  }
}

import styled from 'styled-components'
import { layout, LayoutProps } from 'styled-system'

export const ItemContainer = styled.div<LayoutProps>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  justify-content: space-between;
  min-width: 150px;
  padding-left: ${({ theme }) => theme.space[1]}px;

  ${layout}
`

import React from 'react'

import { AiOutlineDownload as DownloadIcon } from 'react-icons/ai'

import { Popover } from 'components/ui/__v2__/Popover'
import { IconButton } from 'components/ui/__v3__/Button'

import { useI18n } from 'hooks'

export function Download({ hidden }: { hidden?: boolean }) {
  const t = useI18n('nlpInference')

  if (hidden) return null
  return (
    // @ts-ignore
    <Popover content={t('export')} trigger="mouseenter">
      <IconButton alignSelf="flex-end" mr="15px" onClick={() => {}}>
        <DownloadIcon />
      </IconButton>
    </Popover>
  )
}

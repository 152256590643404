import React from 'react'

import { MdClose } from 'react-icons/md'

import { ModalIcon } from './styles'

interface ModalCloseProps {
  disabled?: boolean
  onClose: React.MouseEventHandler<HTMLButtonElement>
}
export function ModalClose({ disabled, onClose }: ModalCloseProps) {
  return (
    <ModalIcon disabled={disabled} onClick={onClose}>
      <MdClose />
    </ModalIcon>
  )
}

import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import companyTokenQuery from 'GraphQL/Hathor/Queries/companyToken.graphql'
import hathorMeQuery from 'GraphQL/Hathor/Queries/me.graphql'
import { createStructuredSelector } from 'reselect'

import { misc } from 'constants/config'
import { Feature } from 'constants/products'

import { hasFeature } from 'helpers/access'

import { useHathorQuery } from 'hooks'

import HathorContext from 'services/Hathor/HathorContext'

import { getCompany, getProducts } from 'store/selectors/viewer'

export default function HathorProvider({ children }) {
  const { company, products } = useSelector(
    createStructuredSelector({
      company: getCompany,
      products: getProducts,
    }),
  )
  const companyId = +company?.id

  const { disableHathor } = misc

  const hathorEnabled = !disableHathor && hasFeature(Feature.Hathor, products)

  const skip = useMemo(() => !companyId || !hathorEnabled, [
    companyId,
    hathorEnabled,
  ])

  const { data: companyTokenData } = useHathorQuery(companyTokenQuery, {
    variables: { id: companyId },
    skip,
  })

  const {
    data: hathorMeData,
    refetch: refetchHathorMe,
  } = useHathorQuery(hathorMeQuery, { skip })

  const companyTokenTicker = useMemo(() => companyTokenData?.companyToken, [
    companyTokenData?.companyToken,
  ])

  const hathorMe = useMemo(() => hathorMeData?.me, [hathorMeData?.me])

  return (
    <HathorContext.Provider
      value={{
        hathorEnabled,
        companyTokenTicker,
        companyId,
        refetchHathorMe,
        hathorMe,
      }}
    >
      {children}
    </HathorContext.Provider>
  )
}

HathorProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

import { useApolloQuery } from 'API/services/Apollo'

import orderBy from 'lodash/orderBy'

import { PAGE_SIZE } from 'constants/pagination'

import Utils from 'services/Utils'

import { ShiftJobsByCursorQuery, ShiftJobsByCursorQueryData } from './GraphQL'

export function useShiftJobsByCursor({ shiftId }: { shiftId: string }) {
  const { data, loading, refetch } = useApolloQuery<
    ShiftJobsByCursorQueryData,
    Gateway.QueryShiftJobsByCursorArgs
  >(ShiftJobsByCursorQuery, {
    variables: {
      filter: { shiftId: Utils.GraphQL.eqFilter(shiftId) },
      // We want all of them
      paging: { limit: PAGE_SIZE['100'] },
    },
    fetchPolicy: 'network-only',
  })

  // Note: this is until we have server-side ordering
  const shiftJobs = data?.shiftJobsByCursor?.edges
    ? orderBy(
        data?.shiftJobsByCursor?.edges.map(edge => edge.node),
        'unfilledPositions',
        'desc',
      )
    : []

  return {
    shiftJobs,
    loading,
    refetch,
    pageInfo: data?.shiftJobsByCursor?.pageInfo,
  }
}

import { create, enforce, test } from 'vest'

import { i18n } from 'i18n'

import { ShiftMoveModalFormState } from '../types'

export const validator = create((data: ShiftMoveModalFormState) => {
  test('selectedScheduleIds', i18n('validation.required'), () => {
    enforce(Array.from(data.selectedScheduleIds)).isNotEmpty()
  })
})

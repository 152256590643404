import { DateTime } from 'luxon'

type TimeCardData = {
  attributes: {
    startAt: string
    endAt: string
  }
}

export function getChartData(timeCards: TimeCardData[]) {
  let firstColHours = 0
  let secondColHours = 0

  timeCards.forEach(timeCard => {
    const { startAt, endAt } = timeCard.attributes

    const start = DateTime.fromISO(startAt)
    const end = DateTime.fromISO(endAt)

    const hours = end.diff(start, ['hours']).toObject().hours ?? 0

    if (firstColHours === 0) {
      firstColHours += hours
    } else if (secondColHours === 0) {
      secondColHours += hours
    } else {
      const rand = Math.random()
      if (rand < 0.5) {
        firstColHours += hours
      } else if (rand >= 0.5) {
        secondColHours += hours
      }
    }
  })

  return [
    {
      name: 'Salon de Jeux',
      hours: firstColHours,
    },
    {
      name: 'Service Planchers',
      hours: secondColHours,
    },
    {
      name: 'Casino en Ligne',
      hours: 0,
    },
  ]
}

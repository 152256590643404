import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { DEFAULT_JSON_API_OFFSET_PAGING } from 'API/services/JsonApi'
import {
  getOffsetPageMeta,
  transformResponseToEntities,
} from 'API/services/utils'

import { sortBy } from 'lodash'

import { AvailabilityRequestState } from 'constants/gatewayGraphQL'

import { useAppContext } from 'hooks/useAppContext'

import {
  AVAILABILITY_REQUEST_JSON_API_TYPE,
  AvailabilityRequestQueryKey,
} from './config'
import { availabilityRequestByOffsetQuery } from './JSON'
import {
  AvailabilityRequest,
  AvailabilityRequestFilter,
  AvailabilityRequestJson,
} from './types'

export function useAvailabilityRequestsByOffset({
  page,
  ...filter
}: AvailabilityRequestFilter & {
  page?: JsonApi.OffsetPagingInput
}) {
  const { companyId } = useAppContext()

  const [total, setTotal] = useState(0)

  const { data: availabilityRequests, isFetching, refetch } = useQuery({
    queryKey: AvailabilityRequestQueryKey.byOffset(
      page ?? DEFAULT_JSON_API_OFFSET_PAGING,
      filter,
    ),
    queryFn: () =>
      availabilityRequestByOffsetQuery(
        companyId,
        page ?? DEFAULT_JSON_API_OFFSET_PAGING,
        filter,
        INCLUDE,
      ),
    select: (response: JsonApi.Response) => {
      const { recordCount } = getOffsetPageMeta(response)
      const { entities } = transformResponseToEntities<AvailabilityRequestJson>(
        {
          response,
          jsonApiType: AVAILABILITY_REQUEST_JSON_API_TYPE,
        },
      )
      if (total !== recordCount) setTotal(recordCount)
      return sortBy(entities, 'createdAt')
        .reverse()
        .map(request => ({
          ...request,
          state: request.state.toUpperCase() as AvailabilityRequestState,
        })) as AvailabilityRequest[]
    },
    structuralSharing: false,
  })

  return {
    availabilityRequests: availabilityRequests ?? [],
    fetching: isFetching,
    total,
    refetch,
  }
}

const INCLUDE = [
  'employee.branch',
  'employee.profile',
  'createdBy.profile',
  'processedBy.profile',
].join(',')

import { combineReducers } from 'redux'

import annualPerformance from './annualPerformance'
import CNESST from './CNESST'
import disciplinary from './disciplinary'
import employees from './employees'
import leavesSummary from './leavesSummary'
import salaryReview from './salaryReview'
import schedules from './schedules'
import shifts from './shifts'
import timeCards from './timeCards'
import workAgreements from './workAgreements'

export default combineReducers({
  employees,
  disciplinary,
  schedules,
  shifts,
  timeCards,
  salaryReview,
  CNESST,
  annualPerformance,
  workAgreements,
  leavesSummary,
})

import { useApolloMutation } from 'API/services/Apollo'
import { v4 as uuid } from 'uuid'

import {
  TestClassifyTimeMutation,
  TestClassifyTimeMutationData,
} from './GraphQL'

export function useTestClassifyTime() {
  const [innerSimulate, { loading }] = useApolloMutation<
    TestClassifyTimeMutationData,
    Gateway.MutationTestClassifyTimeArgs
  >(TestClassifyTimeMutation, {
    translationKey: 'classifiedTime.toasts.testClassify',
  })

  async function simulateTimeClassification(
    input: Gateway.TestClassifyTimeInput,
  ) {
    const result = await innerSimulate({
      variables: {
        input,
      },
    })

    return (
      result.data?.testClassifyTime.map(item => ({
        ...item,
        listId: uuid(),
      })) ?? []
    )
  }

  return {
    simulateTimeClassification,
    simulating: loading,
  }
}

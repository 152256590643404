import React from 'react'
import StyledPropTypes from '@styled-system/prop-types'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import _ from 'i18n'

import * as Components from './components'
import { Helper } from './Helper'
import { getSelectTheme, searchGrouped, searchOption } from './helpers'
import {
  SELECT_COMPONENT_STYLES,
  SelectContainer,
  StyledSelect,
} from './styles'

import { InputLabel } from '../Input'

export const Select = React.forwardRef(
  (
    {
      isClearable = true,
      async,
      creatable,
      helper,
      isSearchableIncludingGroupLabels,
      noOptionsMessage,
      placeholder,
      withPortal,
      labelContent,
      menuPlacement = 'auto',
      customComponents: CustomComponents,
      ...rest
    },
    ref,
  ) => {
    const theme = getSelectTheme()

    const filterOption = (option, input) => {
      if (rest?.filterOption) return rest.filterOption(option)
      if (isSearchableIncludingGroupLabels)
        return searchGrouped(option, input, rest.options)
      if (rest.isSearchable) return searchOption(option, input)

      return true
    }

    const components = {
      ...Components,
      ...CustomComponents,
    }

    return (
      <SelectContainer {...pick(rest.wrapper)}>
        {labelContent && <InputLabel>{labelContent}</InputLabel>}

        <StyledSelect
          async={async}
          components={components}
          creatable={creatable}
          filterOption={filterOption}
          isClearable={isClearable}
          isSearchable={isSearchableIncludingGroupLabels || rest.isSearchable}
          menuPlacement={menuPlacement}
          menuPortalTarget={withPortal ? document.body : undefined}
          noOptionsMessage={() => noOptionsMessage ?? _('common.noOptions')}
          placeholder={placeholder ?? `${_('actions.select')}...`}
          ref={ref}
          styles={SELECT_COMPONENT_STYLES}
          theme={theme}
          {...rest}
        />

        {helper && <Helper>{helper}</Helper>}
      </SelectContainer>
    )
  },
)

Select.defaultProps = {
  async: false,
  creatable: false,
  customComponents: {},
  isSearchableIncludingGroupLabels: false,
  helper: null,
  noOptionsMessage: null,
  labelContent: '',
  placeholder: null,
  width: 1,
  withPortal: false,
}

Select.propTypes = {
  ...StyledPropTypes.space,
  ...StyledPropTypes.layout,
  async: PropTypes.bool,
  creatable: PropTypes.bool,
  customComponents: PropTypes.object,
  helper: PropTypes.node,
  isSearchableIncludingGroupLabels: PropTypes.bool,
  labelContent: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
  noOptionsMessage: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.any,
  withPortal: PropTypes.bool,
}

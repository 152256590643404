import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { Divider } from 'components/ui/Divider'
import { Flex } from 'components/ui/Grid'

export const Container = styled(Flex)`
  flex-direction: column;
  padding: 32px;
  width: 540px;
  background-color: ${themeGet('colors.WHITE')};
`

export const Title = styled.h2`
  font-size: 18px;
  line-height: normal;
  margin: 20px 0;
  text-align: center;
  color: ${themeGet('colors.DARK_TEXT')};
`

export const SubTitle = styled.label`
  font-size: 18px;
  color: ${themeGet('colors.DARK_TEXT')};
  margin-right: 16px;
  line-height: normal;
`

export const RulesDivider = styled(Divider)`
  width: 100%;
  height: 2px;
  color: ${themeGet('colors.MERCURY')};
  margin-bottom: 17px;
`

export const ButtonsRow = styled(Flex)`
  margin-top: 64px;
  justify-content: space-between;
  align-items: center;
`

export const LabelText = styled.label`
  line-height: 1.43;
  font-weight: 300;
  text-align: left;
  color: ${themeGet('colors.DARK_TEXT')};
  flex: 1;

  ${props =>
    props.disabled &&
    css`
      color: ${themeGet('colors.SILVER')};
    `};

  ${space};
`

import styled from 'styled-components'
import { flexbox, grid, layout, space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { CommonProps } from './types'

export type ContentProps = CommonProps

export const Content = styled.div<ContentProps>`
  margin: 24px;

  border: 1px solid ${themeGet('layout.border')};
  border-radius: 4px;

  background-color: ${themeGet('layout.bg')};

  ${space};
  ${layout};
  ${flexbox};
  ${grid};
`

import {
  createDeleteHandler,
  createFields,
  createLoadHandler,
  createReducerHandlers,
} from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  CLEAR,
  COPY,
  DELETE,
  LOAD,
  LOAD_MORE,
} from 'store/actions/company/shifts'
import { CREATE_SHIFT } from 'store/actions/employees/shifts'

export const COMPANY_SCHEDULE_SHIFTS_TYPE = 'shifts'

const initialState = Immutable({
  ...createFields(COMPANY_SCHEDULE_SHIFTS_TYPE),

  publishing: false,
})

const handlers = {
  ...createReducerHandlers(COMPANY_SCHEDULE_SHIFTS_TYPE, LOAD, {
    withReplace: true,
  }),

  ...createReducerHandlers(COMPANY_SCHEDULE_SHIFTS_TYPE, LOAD_MORE, {
    withReplace: false,
  }),

  ...createReducerHandlers(COMPANY_SCHEDULE_SHIFTS_TYPE, COPY, {
    withReplace: true,
  }),

  [CLEAR]: state => state.set('shifts', []),

  [DELETE.SUCCESS]: createDeleteHandler(COMPANY_SCHEDULE_SHIFTS_TYPE),

  [CREATE_SHIFT.SUCCESS]: createLoadHandler(COMPANY_SCHEDULE_SHIFTS_TYPE, {
    withLoading: false,
  }),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import {
  createDeleteHandler,
  createFields,
  createLoadHandler,
  createReducerHandlers,
} from 'rapidux'
import Immutable from 'seamless-immutable'

import get from 'lodash/get'

import { createReducer } from 'helpers/redux'

import {
  CLEAR_DATA,
  CREATE,
  DELETE,
  GENERATE,
  LOAD,
  LOAD_ONE,
} from 'store/actions/reportBuilder'

const initialState = Immutable({
  ...createFields('reportTemplates'),
  ...createFields('reportTemplates', 'reportTemplate', true),

  reportDataType: null,
  reportDataIds: [],
  reportDataPaged: {},
  isLoadingReportData: false,
  isLoadedReportData: false,
})

const handlers = {
  ...createReducerHandlers('reportTemplates', LOAD, { withReplace: true }),
  ...createReducerHandlers('reportTemplates', CREATE),

  ...createReducerHandlers('reportTemplate', LOAD_ONE, {
    withReplace: true,
    singular: true,
  }),

  [DELETE.SUCCESS]: createDeleteHandler('reportTemplates'),

  [GENERATE.REQUEST]: state =>
    state.merge({
      reportDataIds: [],
      reportDataType: null,
      isLoadingReportData: true,
    }),
  [GENERATE.SUCCESS]: (state, action) => {
    const dataType = get(action, ['payload', 'dataType'])

    if (!dataType) throw new Error('No dataType provided to LOAD_REPORT')

    return createLoadHandler(dataType, {
      mapToKey: 'reportDataIds',
      withLoading: false,
      withReplace: true,
      addToState: {
        reportDataType: dataType,
        isLoadingReportData: false,
        isLoadedReportData: true,
        reportDataPaged: get(action, 'paged') || {},
      },
    })(state, action)
  },
  [GENERATE.FAILURE]: state =>
    state.merge({
      isLoadingReportData: false,
      isLoadedReportData: false,
    }),

  [CLEAR_DATA]: state =>
    state.merge({
      isLoadedReportData: false,
      isLoadingReportData: false,
      reportDataType: null,
      reportDataIds: [],
      ...createFields('reportTemplates', 'reportTemplate', true),
    }),
}

export default createReducer(initialState, handlers)

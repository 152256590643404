import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import orderBy from 'lodash/orderBy'

import deepTextSearchFunction from './deepTextSearchFunction'

/**
 * Custom sort function. Ignores search component.
 * @see https://github.com/jbetancur/react-data-table-component#sorting
 */
export default function searchIgnoredSortFunction(rows, field, direction) {
  const [search, ...rest] = rows

  const predicate = row => {
    const prop = isFunction(field) ? field(row) : get(row, field)

    if (isString(prop)) {
      return prop.toLowerCase()
    }

    return prop
  }

  return [
    search,
    ...deepTextSearchFunction(
      orderBy(rest, predicate, direction),
      field,
      direction,
    ),
  ]
}

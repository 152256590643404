import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import {
  clearJobsEmployees,
  initJobsEmployees,
  loadJobsEmployees,
} from 'store/actions/areas'
import { getInitialJobsEmployees } from 'store/selectors/areas'

import JobsEmployeesPicker from './JobsEmployeesPicker'

const selectors = createStructuredSelector({
  initial: getInitialJobsEmployees,
})

const actions = {
  onInit: initJobsEmployees,
  onLoad: loadJobsEmployees,
  onClear: clearJobsEmployees,
}

export default connect(selectors, actions)(JobsEmployeesPicker)

import { toastr } from 'react-redux-toastr'

import { all, fork, put, select, take } from 'redux-saga/effects'

import get from 'lodash/get'

import { api } from 'constants/config'

import { areasToStringFilters } from 'helpers/areas'
import { downloadFileAsync } from 'helpers/files'

import _ from 'i18n'

import {
  CREATE_OR_UPDATE_BULK_BUDGET,
  UPDATE_BRANCH,
} from 'store/actions/company/branches'
import {
  ACTUAL_DELETE,
  AUTHORIZE,
  CREATE_EXPORT_SCHEDULE_TOKEN,
  INIT,
  initDone,
  LOAD_RANGE,
  loadSchedules,
  loadTimeEntries,
  rememberSelectedSchedules,
  shiftWeekAndLoad,
  UNAUTHORIZE,
} from 'store/actions/employees/schedules'
import {
  loadEmployeesSummary,
  loadTimeCards,
} from 'store/actions/employees/timeCards'
import { getWeek } from 'store/selectors/employees/schedules'

function* initialLoad() {
  while (true) {
    yield take(INIT)

    yield put(loadSchedules())
    yield put(loadTimeEntries())

    yield take(LOAD_RANGE.SUCCESS)

    yield put(initDone())
  }
}

function* handleAuthorize() {
  while (true) {
    const {
      payload: { selectedSchedules, needRemember },
    } = yield take(AUTHORIZE.SUCCESS)
    if (selectedSchedules.length > 0 && needRemember) {
      yield put(rememberSelectedSchedules(selectedSchedules, true))
    }
  }
}

function* handleUnauthorize() {
  while (true) {
    const {
      payload: { selectedSchedules, needRemember },
    } = yield take(UNAUTHORIZE.SUCCESS)
    if (selectedSchedules.length > 0 && needRemember) {
      yield put(rememberSelectedSchedules(selectedSchedules, false))
    }
  }
}

function* exportScheduleCSV() {
  const { filesUrl } = api

  while (true) {
    const { payload } = yield take(CREATE_EXPORT_SCHEDULE_TOKEN.SUCCESS)
    const {
      data,
      areas,
      startAt,
      endAt,
      unpublished,
      published,
      companyId,
    } = payload

    const token = get(data, 'token', '')

    if (token) {
      let url = `${filesUrl}/companies/${companyId}/schedule_export?token=${token}&filter[start_on][between][]=${startAt}&filter[start_on][between][]=${endAt}`
      url += areasToStringFilters(areas)

      if (!published || !unpublished) {
        url += `&state=${unpublished ? 'unpublished' : 'published'}`
      }

      downloadFileAsync(url)
    }
  }
}

function* watchSuccessActualDelete() {
  while (true) {
    yield take(ACTUAL_DELETE.SUCCESS)

    const week = yield select(getWeek)
    yield put(loadSchedules(week.start, week.end))
    yield put(loadTimeEntries(week.start, week.end))
    yield put(loadTimeCards({ from: week.start, to: week.end }))
    yield put(loadEmployeesSummary({ from: week.start, to: week.end }))
  }
}

function* watchSuccessBudgetSave() {
  while (true) {
    yield take(CREATE_OR_UPDATE_BULK_BUDGET.SUCCESS)
    toastr.info(_('budgets.budgetsHaveBeenSaved'))
  }
}

// Update Schedules, Shifts if location settings updated
function* watchLocationSettingsUpdate() {
  while (true) {
    const { payload } = yield take(UPDATE_BRANCH.REQUEST)
    const { prevWeek: week } = payload
    yield take(UPDATE_BRANCH.SUCCESS)
    yield put(shiftWeekAndLoad(week))
  }
}

export default function* rootSchedule() {
  yield all([
    fork(initialLoad),
    fork(handleAuthorize),
    fork(handleUnauthorize),
    fork(watchSuccessActualDelete),
    fork(watchSuccessBudgetSave),
    fork(watchLocationSettingsUpdate),
    fork(exportScheduleCSV),
  ])
}

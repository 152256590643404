import React, { PureComponent } from 'react'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import _ from 'i18n'

import { getBrandConfig, getConfigType } from 'services/Brand'

import RenderBrandText from '../Brand/components/BrandText'
import { Flex } from '../Grid'

const { Loader, primaryColor } = getBrandConfig()

const Container = styled(Flex)`
  flex-direction: column;
  min-height: 100vh;
`

const Title = styled.div`
  color: ${themeGet(primaryColor)};
  font-size: 24px;

  & > svg {
    width: 180px;
    height: 30px;
  }
`

const Stucked = styled.div`
  color: ${themeGet('colors.GRAY')};
  min-height: 32px;
  margin-top: 16px;
`

const Refresh = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

class Big extends PureComponent {
  state = {
    stucked: false,
  }

  componentDidMount() {
    this.timeout = setTimeout(() => {
      this.setState({
        stucked: true,
      })
    }, 6000)
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  handleReload = () => {
    if (window && window.location) {
      window.location.reload()
    }
  }

  render() {
    const { stucked } = this.state

    const type = getConfigType()

    return (
      <Container alignItems="center" global justifyContent="center" width={1}>
        <Loader mb={4} />
        <Title>
          <RenderBrandText type={type} />
        </Title>

        <Stucked>
          {stucked && (
            <>
              {_('common.youAreStuck')}{' '}
              <Refresh onClick={this.handleReload}>
                {' '}
                {_('common.reloadingPage')}
              </Refresh>
            </>
          )}
        </Stucked>
      </Container>
    )
  }
}

export default Big

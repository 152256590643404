import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const notFilled = ({ isNotFilled }) =>
  isNotFilled
    ? css`
        cursor: not-allowed;
        color: ${themeGet('colors.CHARCOAL_60')};
      `
    : css`
        color: ${themeGet('layout.primary')};
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      `

export const FormLine = styled.div`
  ${notFilled}
`

import { getOperationName } from '@apollo/client/utilities'
import { useQueryClient as useTanstackQueryClient } from '@tanstack/react-query'
import {
  ScheduledAndTotalPositionsQuery,
  UnfilledPositionsQuery,
} from 'API/Metrics/GraphQL'
import { useApolloMutation } from 'API/services/Apollo'
import {
  ShiftsByCursorQuery,
  shiftUnfilledPositionsCountUpdater,
} from 'API/Shift/GraphQL'
import {
  AssignEmployeesToShiftJob,
  shiftJobUnfilledPositionsCountUpdater,
} from 'API/ShiftJob/GraphQL'

import compact from 'lodash/compact'

import { i18n } from 'i18n'

import { showToast } from 'services/Toasts'

import { AvailableEmployeesForShiftJobByCursorQuery } from '../Employee/GraphQL'
import {
  MissingQualificationsByCursorQuery,
  SchedulesByShiftJobByCursorQuery,
} from '../Schedule/GraphQL'

type EmployeePortion = { employee: { id: number } }

type ModifyAssignmentsArgs = {
  addEmployees: EmployeePortion[]
  removeEmployees: EmployeePortion[]
  shiftId: string
  updater: {
    shiftJobUnfilledPositionsCount: number
    shiftUnfilledPositionsCount: number
  }
} & Pick<
  Gateway.AssignEmployeesToShiftJobInput,
  'shiftJobId' | 'unassignConflictingSchedules'
>

// TODO: Make the refetch strategy more specific so it only refetches queries for specific Shift

export function useModifyShiftJobAssignments() {
  const tanstackClient = useTanstackQueryClient()

  const [
    innerModifyAssignments,
    { loading: modifying, client },
  ] = useApolloMutation<
    MutationData<'assignEmployeesToShiftJob'>,
    Gateway.MutationAssignEmployeesToShiftJobArgs
  >(AssignEmployeesToShiftJob, {
    refetchQueries: compact([
      // TODO: uncomment when the BE will be able to return scheduleAcceptance
      //       in the schedule entity immediately after assigning an employee to the shift
      // getOperationName(ShiftJobsByCursorQuery),
      getOperationName(AvailableEmployeesForShiftJobByCursorQuery),
      getOperationName(SchedulesByShiftJobByCursorQuery),
      // FIXME: Workaround to force update of the Weekly View in case when we keep the amount of schedules the same
      getOperationName(ShiftsByCursorQuery),
      getOperationName(UnfilledPositionsQuery),
      getOperationName(ScheduledAndTotalPositionsQuery),
      getOperationName(MissingQualificationsByCursorQuery),
    ]),
    update: () => {
      // Note: Forcing invalidation due to load-more policy enabled for 'schedulesByCursor'
      // TODO: uncomment when the BE will be able to return scheduleAcceptance
      //       in the schedule entity immediately after assigning an employee to the shift
      // cache.evict({ fieldName: 'schedulesByCursor' })

      // Note: In order to force update of tanstack part in the Weekly View
      tanstackClient.invalidateQueries({ queryKey: ['shifts'] })
    },
    awaitRefetchQueries: true,
    onCompleted: () => {
      showToast({
        type: 'success',
        title: i18n('shiftAssign.toasts.modification.success.title'),
        content: i18n('shiftAssign.toasts.modification.success.message'),
      })
    },
  })

  const modify = async ({
    shiftJobId,
    shiftId,
    addEmployees,
    removeEmployees,
    unassignConflictingSchedules,
    updater: { shiftJobUnfilledPositionsCount, shiftUnfilledPositionsCount },
  }: ModifyAssignmentsArgs) => {
    await innerModifyAssignments({
      variables: {
        input: {
          removeEmployeeIds: removeEmployees.map(toId),
          addEmployeeIds: addEmployees.map(toId),
          shiftJobId,
          ...(unassignConflictingSchedules && {
            unassignConflictingSchedules,
          }),
        },
      },
    }).then(({ errors }) => {
      if (!errors) {
        shiftUnfilledPositionsCountUpdater(
          client.cache,
          shiftId,
          shiftUnfilledPositionsCount,
        )

        shiftJobUnfilledPositionsCountUpdater(
          client.cache,
          shiftJobId,
          shiftJobUnfilledPositionsCount,
        )
      }
    })
  }

  return { modify, modifying }
}

function toId(item: EmployeePortion) {
  return String(item.employee.id)
}

import React from 'react'

import { Flex } from 'components/ui/__v2__/Grid'

import { Helper } from './Helper'
import { InputField } from './InputField'
import { InputLabel } from './Label'
import { LabelText } from './LabelText'
import { IconContainer, InputWrapper } from './styles'
import { ElementType, InputProps } from './types'

export type { InputProps }
export const Input = React.forwardRef(
  (props: InputProps, ref: React.Ref<ElementType<typeof props.as>>) => {
    const {
      autoComplete = 'new-password',
      icon = null,
      className = undefined,
      iconPlacement = 'right',
      helper = undefined,
      wrapper,
      labelText = undefined,
      required = false,
      content,
      ...rest
    } = props

    return (
      <Flex flexDirection="column">
        <InputLabel>
          <LabelText
            content={content}
            labelText={labelText}
            required={required}
          />
          <InputWrapper className={className} {...wrapper}>
            <InputField
              {...rest}
              autoComplete={autoComplete}
              // @ts-ignore Note: to silence compiler on ref differences
              ref={ref}
            />

            {icon && (
              <IconContainer iconPlacement={iconPlacement}>
                {icon}
              </IconContainer>
            )}
          </InputWrapper>
        </InputLabel>

        {helper && <Helper>{helper}</Helper>}
      </Flex>
    )
  },
)

import { useApolloLazyQuery } from 'API/services/Apollo'

import isNil from 'lodash/isNil'

import {
  SortingDirection,
  TimeBucketSortingField,
} from 'constants/gatewayGraphQL'
import { PAGE_SIZE } from 'constants/pagination'

import Utils from 'services/Utils'

import { TimeBucketParentCoreAPIItem } from './GraphQL/Fragments'
import {
  TimeBucketParentsPickerQuery,
  TimeBucketParentsPickerQueryData,
} from './GraphQL'

export function useTimeBucketParentsPicker() {
  const [
    loadTimeBucketParentsInner,
    { loading: timeBucketParentsLoading, data, refetch: innerRefetch },
  ] = useApolloLazyQuery<
    TimeBucketParentsPickerQueryData,
    Gateway.QueryTimeBucketParentsByCursorArgs
  >(TimeBucketParentsPickerQuery, {
    fetchPolicy: 'network-only',
  })

  const loadTimeBucketParents = (archived?: boolean) => {
    loadTimeBucketParentsInner({
      variables: {
        paging: { limit: PAGE_SIZE['10'] },
        sorting: {
          direction: SortingDirection.Asc,
          field: TimeBucketSortingField.Name,
        },
        filter: {
          archived: !isNil(archived)
            ? Utils.GraphQL.eqFilter(archived)
            : undefined,
        },
      },
    })
  }

  const refetchTimeBucketParents = async ({
    inputValue,
    archived,
  }: {
    inputValue?: string
    archived?: boolean
  } = {}) => {
    if (!innerRefetch) return []

    const result = await innerRefetch({
      filter: {
        search: inputValue ? { ilike: inputValue } : undefined,
        archived: !isNil(archived)
          ? Utils.GraphQL.eqFilter(archived)
          : undefined,
      },
    })
    return dataToNodes(result.data)
  }

  const timeBucketParents = dataToNodes(data)

  return {
    timeBucketParents,
    timeBucketParentsLoading,
    refetchTimeBucketParents,
    loadTimeBucketParents,
  }
}

function dataToNodes(
  data?: TimeBucketParentsPickerQueryData,
): TimeBucketParentCoreAPIItem[] {
  return data?.timeBucketParentsByCursor?.edges.map(edge => edge.node) ?? []
}

import forEach from 'lodash/forEach'
import has from 'lodash/has'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import omit from 'lodash/omit'

import { CASCADE_ORDER } from 'constants/areas'

import { reduceToOption } from 'services/Options'

export function isEmptyAreas(areas = {}) {
  return (
    isEmpty(areas) ||
    (isEmpty(areas.locations) &&
      isEmpty(areas.departments) &&
      isEmpty(areas.jobs) &&
      isEmpty(areas.jobsEmployees) &&
      !areas.global)
  )
}

function getRelationData(type, values) {
  if (isNil(values)) {
    return []
  }

  if (isArray(values)) {
    return map(values, ({ id }) => ({ id, type }))
  }

  return {
    id: values?.id,
    type,
  }
}

export function areasToRelationships(areas = {}, { withEmpty = false } = {}) {
  const relationships = {}

  if (
    !isEmpty(areas.locations) ||
    (withEmpty && !isUndefined(areas.locations))
  ) {
    const type = 'branches'
    relationships[type] = {
      data: getRelationData(type, areas.locations),
    }
  }

  if (
    !isEmpty(areas.departments) ||
    (withEmpty && !isUndefined(areas.departments))
  ) {
    const type = 'departments'
    relationships[type] = {
      data: getRelationData(type, areas.departments),
    }
  }

  if (!isEmpty(areas.jobs) || (withEmpty && !isUndefined(areas.jobs))) {
    const type = 'jobs'
    relationships[type] = {
      data: getRelationData(type, areas.jobs),
    }
  }

  if (
    !isEmpty(areas.employees) ||
    (withEmpty && !isUndefined(areas.employees))
  ) {
    const type = 'employees'
    relationships.employees = {
      data: getRelationData(type, areas.employees),
    }
  }

  if (
    !isEmpty(areas.jobsEmployees) ||
    (withEmpty && !isUndefined(areas.locations))
  ) {
    const type = 'jobsEmployees'

    relationships.jobsEmployees = {
      data: getRelationData(type, areas.jobsEmployees),
    }
  }

  return relationships
}

/**
 * @returns {{branch_id: {in: string[]}, department_id: {in: string[]}, job_id: {in: string[]}, employee_id: {in: string[]}, jobs_employee_id: {in: string[]}}}
 */
export function areasToFilters(areas = {}) {
  const filters = {}

  if (!isEmpty(areas.locations)) {
    filters.branch_id = {
      in: map(
        isArray(areas.locations) ? areas.locations : [areas.locations],
        'id',
      ),
    }
  }

  if (!isEmpty(areas.departments)) {
    filters.department_id = {
      in: map(
        isArray(areas.departments) ? areas.departments : [areas.departments],
        'id',
      ),
    }
  }

  if (!isEmpty(areas.jobs)) {
    filters.job_id = {
      in: map(isArray(areas.jobs) ? areas.jobs : [areas.jobs], 'id'),
    }
  }

  if (!isEmpty(areas.employees)) {
    filters.employee_id = {
      in: map(
        isArray(areas.employees) ? areas.employees : [areas.employees],
        'id',
      ),
    }
  }

  if (!isEmpty(areas.jobsEmployees)) {
    filters.jobs_employee_id = {
      in: map(
        isArray(areas.jobsEmployees)
          ? areas.jobsEmployees
          : [areas.jobsEmployees],
        'id',
      ),
    }
  }

  return filters
}

// TODO: move to Utils.Areas
export function areasToStringFilters(areas, url = '') {
  const filter = areasToFilters(areas)

  if (filter.branch_id) {
    forEach(filter.branch_id.in, branchId => {
      url += `&filter[branch_id][in][]=${branchId}`
    })
  }

  if (filter.department_id) {
    forEach(filter.department_id.in, departmentId => {
      url += `&filter[department_id][in][]=${departmentId}`
    })
  }

  if (filter.job_id) {
    forEach(filter.job_id.in, jobId => {
      url += `&filter[job_id][in][]=${jobId}`
    })
  }

  if (filter.employee_id) {
    forEach(filter.employee_id.in, employeeId => {
      url += `&filter[employee_id][in][]=${employeeId}`
    })
  }

  return url
}

export function areaIdsToFilters(areaIds) {
  const filters = {}

  if (!isEmpty(areaIds.locationIds)) {
    filters.branch_id = { in: areaIds.locationIds }
  }

  if (!isEmpty(areaIds.departmentIds)) {
    filters.department_id = {
      in: areaIds.departmentIds,
    }
  }

  if (!isEmpty(areaIds.jobIds)) {
    filters.job_id = { in: areaIds.jobIds }
  }

  if (!isEmpty(areaIds.employeeIds)) {
    filters.employee_id = { in: areaIds.employeeIds }
  }

  if (!isEmpty(areaIds.jobsEmployeeIds)) {
    filters.jobs_employee_id = { in: areaIds.jobsEmployeeIds }
  }

  return filters
}

/**
 * TODO: wrong format on backend, temporary solution
 */
export function areasToFiltersPayrollExport(areas = {}) {
  const filters = {}

  if (!isEmpty(areas.locations)) {
    filters.branch_ids = map(areas.locations, 'id')
  }

  if (!isEmpty(areas.departments)) {
    filters.department_ids = map(areas.departments, 'id')
  }

  if (!isEmpty(areas.jobs)) {
    filters.job_ids = map(areas.jobs, 'id')
  }

  if (!isEmpty(areas.jobsEmployees)) {
    filters.jobs_employee_ids = map(areas.jobsEmployees, 'id')
  }

  return filters
}

export function formatOptionLabel() {
  return option => {
    return option.label
  }
}

export function defaultAreasMappers(path = '') {
  return {
    [`${path}.locations`]: reduceToOption,
    [`${path}.departments`]: reduceToOption,
    [`${path}.jobs`]: reduceToOption,
    [`${path}.employees`]: reduceToOption,
    [`${path}.jobsEmployees`]: reduceToOption,
  }
}

export function getLowestAreaLevel(areas) {
  const result = {}

  for (const key of CASCADE_ORDER) {
    if (has(areas, key)) {
      result[key] = areas[key]
      break
    }
  }

  return result
}

export function areasToOptionsWithoutEntities(areas) {
  const result = {}

  if (has(areas, 'locations')) {
    result.locations = areas.locations?.map(location =>
      omit(location, 'location'),
    )
  }

  if (has(areas, 'departments')) {
    result.departments = areas.departments?.map(department =>
      omit(department, 'department'),
    )
  }
  if (has(areas, 'jobs')) {
    result.jobs = areas.jobs?.map(job => omit(job, 'job'))
  }

  return result
}

export function areasToFilterPayload(areas) {
  const filters = {}

  if (!isEmpty(areas.locations)) {
    filters.locationIds = map(areas.locations, 'id')
  }

  if (!isEmpty(areas.departments)) {
    filters.departmentIds = map(areas.departments, 'id')
  }

  if (!isEmpty(areas.jobs)) {
    filters.roleIds = map(areas.jobs, 'id')
  }

  return filters
}

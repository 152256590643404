import React, { useMemo } from 'react'

import { ShiftValidationConflictAPIItem } from 'API/RuleValidation/GraphQL/Fragments/types'

import { ShiftValidationConflictTypePopover } from 'components/blocks/__v3__/ShiftValidationConflictComponents'
import { TextOverflowWithPopover } from 'components/blocks/__v3__/TextOverflowWithPopover'
import { Flex } from 'components/ui/__v2__/Grid'
import { Spinner } from 'components/ui/Spinner'

import Utils from 'services/Utils'

import {
  VALIDATION_CONFLICT_ICON_SIZE,
  VALIDATION_CONFLICT_POPOVER_WIDTH,
} from './constants'
import { AssignmentContainer } from './styles'

import { UnassignButton } from '../../Controls'

type Props = {
  firstName?: string
  lastName?: string

  shiftConflicts: ShiftValidationConflictAPIItem[]
  validatingShift: boolean
  rulesetId: string

  onRevertAssignmentClick: () => void
}

export const IntendedAssignment = ({
  firstName,
  lastName,

  shiftConflicts,
  validatingShift,
  rulesetId,

  onRevertAssignmentClick,
}: Props) => {
  const shiftValidationConflictTypeContent = useMemo(() => {
    if (validatingShift) return <Spinner size={VALIDATION_CONFLICT_ICON_SIZE} />

    if (shiftConflicts.length > 0)
      return (
        <ShiftValidationConflictTypePopover
          iconSize={VALIDATION_CONFLICT_ICON_SIZE}
          maxWidth={VALIDATION_CONFLICT_POPOVER_WIDTH}
          rulesetId={rulesetId}
          shiftConflicts={shiftConflicts}
        />
      )

    return null
  }, [shiftConflicts, validatingShift, rulesetId])

  return (
    <AssignmentContainer>
      <TextOverflowWithPopover>
        {Utils.User.buildFullName({ firstName, lastName })}
      </TextOverflowWithPopover>

      <Flex alignItems="center" gap={1}>
        {shiftValidationConflictTypeContent}

        <UnassignButton
          disabled={validatingShift}
          existing={false}
          onClick={() => {
            if (validatingShift) return
            onRevertAssignmentClick()
          }}
        />
      </Flex>
    </AssignmentContainer>
  )
}

import { Body } from './Body'
import { Card } from './Card'
import { Footer } from './Footer'
import { Header } from './Header'
import { Item } from './Item'
import { Sort } from './Sort'
import { Container } from './styles'

export const InteractiveList = {
  Header,
  Body,
  Item,
  Footer,
  Container,
  Sort,
  Card,
}

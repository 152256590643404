import React from 'react'

import { FiMonitor, FiSmartphone, FiTablet } from 'react-icons/fi'

import {
  BeaconTrackingType,
  GeolocationTrackingType,
  TaskTrackingSettings,
  TimeAndAttendanceSettings,
  TimeCaptureModeKind,
} from 'Types/app'

import mapValues from 'lodash/mapValues'
import reduce from 'lodash/reduce'

import { i18n } from 'i18n'

export const FEATURE = Object.freeze({
  CLOCK_IN: 'clockIn',
  OPEN_CLOCK: 'openClock',
  EMERGENCY_OPEN_CLOCK: 'emergencyOpenClock',
  GEOLOCATION: 'geolocation',
  FACIAL_RECOGNITION: 'facialRecognition',
  BEACON: 'beacon',
  TASK_TRACKING: 'taskTracking',
  TIME_CAPTURE_MODE: 'timeCaptureMode',
} as const)

export const APP_TYPE = Object.freeze({
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
  TABLET: 'tablet',
} as const)

export const ADDITIONAL_SETTINGS = Object.freeze({
  TRACKING_TYPE: 'trackingType',
  COUNTDOWN: 'countdown',
  FEMALE_REQUIRE_FACE_ID: 'femaleRequireFaceId',
  ENFORCED_IMMEDIATE_VALIDATION: 'force',
  AUTO_CLOCK_OUT_AFTER: 'autoClockOutAfter',
  CONFIGURABLE_AUTO_CLOCK_OUT: 'autoClockOutAfterElapsedTime',
  TASK_TRACKER: 'trackerSettings',
  TASK_TRACKER_VALIDATION_MODE: 'validationMode',
  TASK_GEOLOCATION_RADIUS: 'geofencingRadius',
  CLOCK_GEOLOCATION_TYPE: 'trackingType',
  CLOCK_GEOLOCATION_RADIUS: 'radius',
  // Open clock
  CLOCK_IN_REMINDER: 'clockInReminder',
  CLOCK_OUT_REMINDER: 'clockOutReminder',
  EARLY_CLOCK_OUT_WARNING: 'earlyClockOutWarning',
  // Time capture mode
  KIND: 'kind',
} as const)

export const CLOCK_OUT_REMINDER_SETTINGS = Object.freeze({
  HOURS: 'hours',
  MINUTES: 'minutes',
  MESSAGE: 'message',
} as const)

export const OPEN_CLOCK_PUNCH_OUT_REMINDER_HOURS = Object.freeze({
  MIN: 0,
  MAX: 23,
} as const)

export const OPEN_CLOCK_PUNCH_OUT_REMINDER_MINUTES = Object.freeze({
  MIN: 0,
  MAX: 59,
} as const)

export const MAX_ALLOWABLE_REMINDER_MESSAGE_LENGTH = 512
export const MIN_ALLOWABLE_AUTO_CLOCK_OUT_AFTER_ELAPSED_TIME = 1 // h

export const DEFAULT_CLOCK_IN_REMINDER_SETTINGS = Object.freeze({
  [CLOCK_OUT_REMINDER_SETTINGS.HOURS]: 9,
  [CLOCK_OUT_REMINDER_SETTINGS.MINUTES]: 0,
  [CLOCK_OUT_REMINDER_SETTINGS.MESSAGE]: i18n(
    'timeAndAttendance.openClockPunchInReminder.defaultReminderMessage',
  ),
} as const)

export const DEFAULT_CLOCK_OUT_REMINDER_SETTINGS = Object.freeze({
  [CLOCK_OUT_REMINDER_SETTINGS.HOURS]: 8,
  [CLOCK_OUT_REMINDER_SETTINGS.MINUTES]: 0,
  [CLOCK_OUT_REMINDER_SETTINGS.MESSAGE]: i18n(
    'timeAndAttendance.openClockPunchOutReminder.defaultReminderMessage',
  ),
} as const)

export const DEFAULT_EARLY_CLOCK_OUT_WARNING_SETTINGS = Object.freeze({
  [CLOCK_OUT_REMINDER_SETTINGS.HOURS]: 6,
  [CLOCK_OUT_REMINDER_SETTINGS.MINUTES]: 0,
  [CLOCK_OUT_REMINDER_SETTINGS.MESSAGE]: i18n(
    'timeAndAttendance.openClockEarlyPunchOutReminder.defaultReminderMessage',
  ),
} as const)

export const TASK_TRACKING_VALIDATION_TYPES = Object.freeze({
  GEOFENCING: 'geofencing',
  BEACON: 'beacon',
  GEOFENCING_AND_BEACON: 'geofencing_and_beacon',
} as const)

export const STATE = Object.freeze({
  ON: 'ON',
  OFF: 'OFF',
} as const)

export const MIN_ALLOWABLE_GEOLOCATION_RADIUS = 50

export const SETTINGS_AVAILABLE = Object.freeze({
  [FEATURE.CLOCK_IN]: {
    [APP_TYPE.DESKTOP]: true,
    [APP_TYPE.MOBILE]: true,
    [APP_TYPE.TABLET]: true,
  },
  [FEATURE.OPEN_CLOCK]: {
    [APP_TYPE.DESKTOP]: true,
    [APP_TYPE.MOBILE]: true,
    [APP_TYPE.TABLET]: true,
  },
  [FEATURE.EMERGENCY_OPEN_CLOCK]: {
    [APP_TYPE.DESKTOP]: true,
    [APP_TYPE.MOBILE]: true,
    [APP_TYPE.TABLET]: true,
  },
  [FEATURE.GEOLOCATION]: {
    [APP_TYPE.DESKTOP]: true,
    [APP_TYPE.MOBILE]: true,
    [APP_TYPE.TABLET]: true,
  },
  [FEATURE.FACIAL_RECOGNITION]: {
    [APP_TYPE.DESKTOP]: false,
    [APP_TYPE.MOBILE]: true,
    [APP_TYPE.TABLET]: true,
  },
  [FEATURE.BEACON]: {
    [APP_TYPE.DESKTOP]: false,
    [APP_TYPE.MOBILE]: true,
    [APP_TYPE.TABLET]: false,
  },
  [FEATURE.TASK_TRACKING]: {
    [APP_TYPE.DESKTOP]: false,
    [APP_TYPE.MOBILE]: true,
    [APP_TYPE.TABLET]: false,
  },
})

export const DEFAULT_GEOLOCATION_RADIUS = 600
export const DEFAULT_BEACON_COUNTDOWN = 15
export const MAX_COUNTDOWN = 180
export const MIN_COUNTDOWN = 1
const RULE_SEPARATOR = ':'

export function buildRule({
  feature,
  appType,
  state,
}: {
  // TODO: move to enums and update types
  feature: string
  appType: string
  state: string
}) {
  return [feature, appType, state].join(RULE_SEPARATOR)
}

export function parseRule(rule: string) {
  const [feature, appType, state] = rule.split(RULE_SEPARATOR)
  return { feature, appType, state }
}

export function getInitialTimeSettings() {
  return {
    dailyTargetWorkingHours: null,
  }
}

export function getInitialLeavesSettings() {
  return {
    dayHourEquivalence: null,
  }
}

// TODO: use enums and types
export function getInitialAttendanceSettings(): TimeAndAttendanceSettings {
  const attendanceSettings = reduce(
    SETTINGS_AVAILABLE,
    (acc, appTypes, feature) => {
      // @ts-ignore
      acc[feature] = mapValues(appTypes, available => available)

      if (feature === FEATURE.GEOLOCATION) {
        acc[feature][
          ADDITIONAL_SETTINGS.CLOCK_GEOLOCATION_RADIUS
        ] = DEFAULT_GEOLOCATION_RADIUS
        acc[feature][ADDITIONAL_SETTINGS.CLOCK_GEOLOCATION_TYPE] =
          GeolocationTrackingType.Geofencing
      }

      if (feature === FEATURE.CLOCK_IN) {
        acc[feature][ADDITIONAL_SETTINGS.AUTO_CLOCK_OUT_AFTER] = undefined
        acc[feature][
          ADDITIONAL_SETTINGS.CONFIGURABLE_AUTO_CLOCK_OUT
        ] = undefined
      }

      if (feature === FEATURE.BEACON) {
        acc[feature][ADDITIONAL_SETTINGS.TRACKING_TYPE] =
          BeaconTrackingType.Single
      }

      if (feature === FEATURE.FACIAL_RECOGNITION) {
        acc[feature][ADDITIONAL_SETTINGS.FEMALE_REQUIRE_FACE_ID] = true
        acc[feature][ADDITIONAL_SETTINGS.ENFORCED_IMMEDIATE_VALIDATION] = false
        acc[feature][ADDITIONAL_SETTINGS.ENFORCED_IMMEDIATE_VALIDATION] = false
      }

      if (feature === FEATURE.TASK_TRACKING) {
        acc[feature][ADDITIONAL_SETTINGS.TASK_TRACKER] =
          TaskTrackingSettings.None
      }

      if (feature === FEATURE.OPEN_CLOCK) {
        acc[feature][ADDITIONAL_SETTINGS.CLOCK_IN_REMINDER] = undefined
        acc[feature][ADDITIONAL_SETTINGS.CLOCK_OUT_REMINDER] = undefined
        acc[feature][ADDITIONAL_SETTINGS.EARLY_CLOCK_OUT_WARNING] = undefined
      }

      return acc
    },
    {} as TimeAndAttendanceSettings,
  )

  return {
    ...attendanceSettings,
    timeCaptureMode: TimeCaptureModeKind.Auto,
  }
}

export function getIsEnabledGeolocation(selectedJob: $TSFixMe) {
  const attendanceSettings = selectedJob?.attendanceSettings
  const geolocationFeature = attendanceSettings?.[FEATURE.GEOLOCATION]
  return geolocationFeature?.[APP_TYPE.DESKTOP] ?? false
}

export const ICONS = {
  [APP_TYPE.DESKTOP]: <FiMonitor />,
  [APP_TYPE.MOBILE]: <FiSmartphone />,
  [APP_TYPE.TABLET]: <FiTablet />,
}

export function hoursMinutesNumberToString(value: number) {
  const str = String(value)
  return str.length === 1 ? `0${str}` : str
}

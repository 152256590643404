import { StoreonModule } from 'storeon'

import { DemandForecastingAlgorithms } from 'constants/demandForecastingDemo'

import { SolverPresetSettings } from 'pages/EmployerApp/Experimental/DemandForecasting/components/SolverDrawer/types'
import {
  enumToOption,
  generateScheduledDataForAllStates,
} from 'pages/EmployerApp/Experimental/DemandForecasting/helpers'

import { applicationToStoreOn } from './helpers'

import { INIT } from '../constants'

export const DEMAND_FORECASTING_KEY = 'demandForecasting'
export const DEMAND_FORECASTING_PRESET_ADD_EVENT =
  'demandForecasting/preset/add'
export const DEMAND_FORECASTING_PRESET_UPDATE_EVENT =
  'demandForecasting/preset/update'
// export const DEMAND_FORECASTING_SOLUTION_ADD_EVENT =
//   'demandForecasting/solution/add'
export const DEMAND_FORECASTING_SOLUTION_SELECT_EVENT =
  'demandForecasting/solution/select'

export const DEMAND_FORECASTING_SOLUTION_SET_EVENT =
  'demandForecasting/solution/set'

const DEFAULT_SOLVER_PRESETS: SolverPresetSettings[] = [
  {
    presetName: 'Minimum overstaffing',
    algorithm: enumToOption(DemandForecastingAlgorithms.Linear),
    considerHolidays: true,
    considerPromotionDays: false,
    considerSeasonalTrends: false,
    historicalWeightage: 20,
    safetyMargin: 20,
  },
  {
    presetName: 'Conservative',
    algorithm: enumToOption(DemandForecastingAlgorithms.Neural),
    considerHolidays: true,
    considerPromotionDays: true,
    considerSeasonalTrends: true,
    historicalWeightage: 40,
    safetyMargin: 80,
  },
]

export type DemandForecastingStoreData = Record<
  string,
  {
    date: string
    Cashier: number
    Manager: number
    Stocker: number
  }[]
>

export interface DemandForecastingState {
  [DEMAND_FORECASTING_KEY]: {
    solverPresets: SolverPresetSettings[]
    // solverSolutions: DemandForecastingStoreData[]
    usedPreset: SolverPresetSettings | undefined
    solution: DemandForecastingStoreData | undefined
    scheduled: DemandForecastingStoreData
  }
}

export interface DemandForecastingEvents {
  [DEMAND_FORECASTING_PRESET_ADD_EVENT]: SolverPresetSettings
  [DEMAND_FORECASTING_PRESET_UPDATE_EVENT]: SolverPresetSettings
  // [DEMAND_FORECASTING_SOLUTION_ADD_EVENT]: DemandForecastingStoreData
  [DEMAND_FORECASTING_SOLUTION_SET_EVENT]: {
    data: DemandForecastingStoreData
    preset: SolverPresetSettings
  }
}

const INITIAL_STATE: DemandForecastingState = {
  [DEMAND_FORECASTING_KEY]: {
    solverPresets: DEFAULT_SOLVER_PRESETS,
    // solverSolutions: [],
    scheduled: applicationToStoreOn(generateScheduledDataForAllStates()),
    solution: undefined,
    usedPreset: undefined,
  },
}

export const demandForecastingModule: StoreonModule<
  DemandForecastingState,
  DemandForecastingEvents
> = store => {
  store.on(INIT, () => INITIAL_STATE)

  store.on(DEMAND_FORECASTING_PRESET_ADD_EVENT, (state, newPreset) => {
    return {
      [DEMAND_FORECASTING_KEY]: {
        ...state[DEMAND_FORECASTING_KEY],
        solverPresets: [
          ...state[DEMAND_FORECASTING_KEY].solverPresets,
          newPreset,
        ],
      },
    }
  })

  store.on(DEMAND_FORECASTING_PRESET_UPDATE_EVENT, (state, updatedPreset) => {
    return {
      [DEMAND_FORECASTING_KEY]: {
        ...state[DEMAND_FORECASTING_KEY],
        solverPresets: state[DEMAND_FORECASTING_KEY].solverPresets.map(
          preset => {
            if (preset.presetName === updatedPreset.presetName) {
              return updatedPreset
            }
            return preset
          },
        ),
      },
    }
  })

  // store.on(DEMAND_FORECASTING_SOLUTION_ADD_EVENT, (state, newSolution) => {
  //   return {
  //     [DEMAND_FORECASTING_KEY]: {
  //       ...state[DEMAND_FORECASTING_KEY],
  //       solverSolutions: [
  //         ...state[DEMAND_FORECASTING_KEY].solverSolutions,
  //         newSolution,
  //       ],
  //     },
  //   }
  // })

  store.on(DEMAND_FORECASTING_SOLUTION_SET_EVENT, (state, { data, preset }) => {
    return {
      [DEMAND_FORECASTING_KEY]: {
        ...state[DEMAND_FORECASTING_KEY],
        solution: data,
        usedPreset: preset,
      },
    }
  })
}

import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import { EvaluatedTimesByCursorQuery, EvaluateTimeMutation } from './GraphQL'

export function useEvaluateTime() {
  const [innerEvaluateTime, { loading }] = useApolloMutation<
    MutationData<'evaluateTime'>,
    Gateway.MutationEvaluateTimeArgs
  >(EvaluateTimeMutation, {
    refetchQueries: compact([getOperationName(EvaluatedTimesByCursorQuery)]),
    translationKey: 'timeEvaluationPage.toast.evaluate',
  })

  const evaluateTime = async (input: Gateway.EvaluateTimeInput) => {
    const result = await innerEvaluateTime({
      variables: {
        input,
      },
    })

    return {
      temporalRunId: result.data?.evaluateTime.temporalRunId,
      temporalWorkflowId: result.data?.evaluateTime.temporalWorkflowId,
    }
  }

  return { evaluateTime, evaluatingTime: loading }
}

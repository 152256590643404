import React, { useState } from 'react'

import { API } from 'API'

import { EmployeePickerReturnOption } from 'components/blocks/__v3__/EmployeePicker'
import { useShiftAssignmentContext } from 'components/blocks/__v3__/ShiftAssignment/context'
import { Table } from 'components/ui/__v3__'

import { AvailableEmployeesForShiftJobCheck } from 'constants/gatewayGraphQL'
import { PAGE_SIZE } from 'constants/pagination'

import { useAppContext, useCursorPagination } from 'hooks'

import { sortByBid } from '../../../../helpers'
import { useCandidatesTable } from '../../hooks'
import { CandidatesTabContainer } from '../../styles'
import { CandidatesFilterFormState } from '../../types'

const CANDIDATES_PAGE_SIZE = PAGE_SIZE[10]

type Props = {
  customCvFields: CandidatesFilterFormState['customCvFields']
  showMissedQualificationsEmployees: boolean
}

export function AvailableTab({
  customCvFields,
  showMissedQualificationsEmployees,
}: Props) {
  const { company } = useAppContext()

  // Move to Context?
  // TODO: need a proper solution
  const AVAILABILITY_IS_A_HARD_CONSTRAINT =
    company.identity.Keywords || company.identity.Icc || company.identity.Tbs

  const {
    selectedShiftJob,
    draftAssign,
    isDraftAssigned,
    missingAssignments,
    revertDraftAssignment,
  } = useShiftAssignmentContext()

  const { page, onShowAfter, onShowBefore } = useCursorPagination(
    CANDIDATES_PAGE_SIZE,
  )

  const [employee, setEmployee] = useState<
    EmployeePickerReturnOption | undefined
  >(undefined)

  const {
    availableEmployees,
    pageInfo,
    loading,
  } = API.Employee.availableForShiftByCursor(
    {
      shiftJobId: selectedShiftJob?.id ?? '',
      options: {
        availabilityConflicts: AVAILABILITY_IS_A_HARD_CONSTRAINT
          ? AvailableEmployeesForShiftJobCheck.False
          : AvailableEmployeesForShiftJobCheck.Skip,
        shiftConflicts: AvailableEmployeesForShiftJobCheck.False,
        timeoffConflicts: AvailableEmployeesForShiftJobCheck.False,
        missingQualifications: showMissedQualificationsEmployees
          ? AvailableEmployeesForShiftJobCheck.True
          : AvailableEmployeesForShiftJobCheck.False,
        useOr: false,
      },
      filter: {
        employee,
        customCvFields,
      },
      paging: {
        limit: page.size,
        startingAfter: page.startingAfter,
        endingBefore: page.endingBefore,
      },
    },
    { skip: !selectedShiftJob?.id },
  )

  const table = useCandidatesTable({
    candidates: sortByBid(availableEmployees),
    isDraftAssigned,
    // NOTE: Disable assignment when showing missed qualifications employees
    cantAssignMore: showMissedQualificationsEmployees
      ? true
      : missingAssignments === 0,
    filters: { employee },
    filterHandlers: { employee: option => setEmployee(option ?? undefined) },
    handlers: {
      onAssign: draftAssign,
      onRevertAssignment: revertDraftAssignment,
    },
  })

  return (
    <CandidatesTabContainer>
      <Table.Container maxHeight="400px">
        <Table.Element>
          <Table.Header table={table} />
          <Table.Body loading={loading} table={table} />
        </Table.Element>
      </Table.Container>
      {pageInfo && (
        <Table.Pagination.Cursor
          hasNextPage={Boolean(pageInfo.hasNextPage)}
          hasPrevPage={Boolean(pageInfo.hasPreviousPage)}
          size={page.size}
          onNextPageClick={() => onShowAfter(pageInfo.endCursor)}
          onPrevPageClick={() => onShowBefore(pageInfo.startCursor)}
        />
      )}
    </CandidatesTabContainer>
  )
}

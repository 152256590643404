import React, { useMemo } from 'react'

import { ShiftValidationRuleAPIItem } from 'API'

import { i18n } from 'i18n'

type Props = {
  rule: ShiftValidationRuleAPIItem
}

type DaysOfWeek = [0, 1, 2, 3, 4, 5, 6]

// TODO: add common i18n for this component & RuleRenderer->DayOfweek
export function DayOfWeek({ rule }: Props) {
  const weekdaysString = useMemo(
    () =>
      (rule.parameters.dayOfWeek?.daysOfWeek as DaysOfWeek)
        ?.map(dayOfWeek =>
          i18n(`workflowBuilder.deck.ruleWeekdays.${dayOfWeek}`),
        )
        .join(', '),
    [rule],
  )

  return <>The employee is assigned on a {weekdaysString}.</>
}

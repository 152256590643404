import styled from 'styled-components'

import SvgIcons from 'assets/images/__v2__/svg'

import { SvgIcon } from 'components/ui/__v2__/SvgIcon'

const LocationPoint = styled(SvgIcon).attrs({
  glyph: SvgIcons.LocationPoint,
})``

LocationPoint.defaultProps = {
  width: 14,
  height: 19,
}

export default LocationPoint

import Immutable from 'seamless-immutable'

import head from 'lodash/head'
import keys from 'lodash/keys'

import { getInitClockingTime } from 'constants/timeClock'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  CHANGE_NOTE,
  CHANGE_TIME_BUCKET,
  CLOCK_IN,
  CLOCK_OUT,
  LOAD_ACTIVE_TIMER,
  RESET_INTERVAL_TIME,
  RESTORE_TRACKING,
  SELECT_JOB,
  SET_CLOCKING_TIME,
  SET_PAUSE_TIMER_ID,
  SET_TIMING,
  START_TIMER,
  STOP_PAUSE_TIMER,
  STOP_TIMER,
} from 'store/actions/employeeApp/openClock'

const initialState = Immutable({
  clockingTime: getInitClockingTime(),
  emergency: false,
  intervalEndTime: null,
  intervalStartTime: null,
  isClocking: false,
  note: '',
  pauseTimerId: null,
  selectedJobData: null,
  timeEntryId: null,
  isPendingClockIn: false,
  selectedTimeBucket: null,
})

const handlers = {
  [CHANGE_NOTE]: (state, { payload }) => {
    return state.merge({ note: payload?.note ?? '' })
  },

  [CHANGE_TIME_BUCKET]: (state, { payload }) => {
    return state.merge({
      selectedTimeBucket: payload?.selectedTimeBucket ?? null,
    })
  },

  [SET_CLOCKING_TIME]: (state, { payload: { time } }) => {
    return state.merge({ clockingTime: time })
  },

  [CLOCK_IN]: (state, { payload }) => {
    return state.merge({
      isPendingClockIn: true,
      emergency: payload?.emergency ?? false,
    })
  },

  [CLOCK_OUT]: state =>
    state.merge({
      ...initialState,
      note: state.note,
      selectedJobData: state.selectedJobData,
    }),

  [RESTORE_TRACKING]: state => state.merge({ isClocking: true }),

  [SET_TIMING]: (state, { payload: { startTime, endTime } }) => {
    return state.merge({
      intervalStartTime: startTime,
      intervalEndTime: endTime,
    })
  },

  [START_TIMER.SUCCESS]: (state, { payload }) => {
    const timeEntryId = head(keys(payload.data.timeEntries)) || null

    return state.merge({
      isClocking: true,
      isPendingClockIn: false,
      timeEntryId,
    })
  },

  [START_TIMER.FAILURE]: state => {
    return state.merge({
      isClocking: false,
      isPendingClockIn: false,
      emergency: false,
      selectedTimeBucket: null,
    })
  },

  [RESET_INTERVAL_TIME]: state => {
    return state.merge({
      intervalStartTime: false,
      intervalEndTime: false,
    })
  },

  [STOP_TIMER.SUCCESS]: state => {
    return state.merge({
      timeEntryId: null,
      note: '',
      selectedJobData: null,
      selectedJobId: null,
      selectedTimeBucket: null,
    })
  },

  [STOP_TIMER.FAILURE]: state => state.merge({ isClocking: true }),

  [SELECT_JOB]: (state, { payload }) => {
    return state.merge({ selectedJobData: payload?.jobData ?? null })
  },

  [LOAD_ACTIVE_TIMER.SUCCESS]: (state, { payload }) => {
    const timeEntries = payload?.data?.timeEntries
    const timeEntryId = head(keys(timeEntries)) ?? null

    return state.merge({ timeEntryId })
  },

  [SET_PAUSE_TIMER_ID]: (state, { payload }) => {
    return state.merge({ pauseTimerId: payload?.pauseTimerId ?? null })
  },

  [STOP_PAUSE_TIMER.SUCCESS]: state => state.merge({ pauseTimerId: null }),

  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers)

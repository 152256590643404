import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { STALE_TIME } from 'API/services/JsonApi'
import { transformResponseToEntities } from 'API/services/utils'

import {
  CUSTOM_LEAVE_DAY_JSON_API_TYPE,
  CustomLeaveDayQueryKey,
} from './constants'
import { customLeaveDayPickerQuery } from './Json'
import { CustomLeaveDayPickerAPIItem } from './types'

export function useCustomLeaveDayPicker(search?: string) {
  const [enabled, setEnabled] = useState(false)

  const { data, isFetching, error } = useQuery({
    queryKey: CustomLeaveDayQueryKey.picker(search),
    queryFn: () => customLeaveDayPickerQuery(search),
    select: response => {
      const {
        entities,
      } = transformResponseToEntities<CustomLeaveDayPickerAPIItem>({
        response,
        jsonApiType: CUSTOM_LEAVE_DAY_JSON_API_TYPE,
      })

      return entities
    },
    structuralSharing: false,
    refetchOnWindowFocus: false,
    enabled, // Note: this is to make sure we're not firing when picker is not open
    staleTime: STALE_TIME.FAST, // Note: this is so when you open/close the picker rapidly - unnecessary calls are not fired. Not suitable for data that is expected to change fast
  })

  return {
    customLeaveDays: data ?? [],
    customLeaveDaysLoading: isFetching,
    customLeaveDaysError: error,
    customLeaveDaysEnableQuery: setEnabled,
  }
}

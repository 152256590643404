import React, { ChangeEvent, useMemo, useState } from 'react'

import { debounce } from 'lodash'

import { Flex } from 'components/ui/__v2__/Grid'
import { Input } from 'components/ui/__v3__/Input'

import { FilterOperator } from 'constants/pagination'

import { useI18n } from 'hooks/useI18n'

import { TableFilterProps } from './types'

import { SEARCH_DEBOUNCE_TIMEOUT } from '../../constants'

export function RangeFilter({
  fieldName: field,
  pagination: { handleFilterChange = () => null, options },
}: TableFilterProps) {
  const t = useI18n('table')
  const filter = options?.filter
  const lastFieldUpdated = options?.lastFieldUpdated

  const startOperator = FilterOperator.GreateThanEqual
  const endOperator = FilterOperator.LowerThanEqual

  const startFilterKey = String(field) + startOperator
  const endFilterKey = String(field) + endOperator

  const [start, setStart] = useState(filter?.[field]?.[startOperator] ?? '')
  const [end, setEnd] = useState(filter?.[field]?.[endOperator] ?? '')

  const handleRangeStartChange = useMemo(() => {
    return debounce(
      value =>
        handleFilterChange({
          field,
          operator: startOperator,
          value,
          lastFieldUpdated: startFilterKey,
        }),
      SEARCH_DEBOUNCE_TIMEOUT,
    )
  }, [field, handleFilterChange, startFilterKey, startOperator])

  const handleRangeEndChange = useMemo(() => {
    return debounce(
      value =>
        handleFilterChange({
          field,
          operator: endOperator,
          value,
          lastFieldUpdated: endFilterKey,
        }),
      SEARCH_DEBOUNCE_TIMEOUT,
    )
  }, [handleFilterChange, field, endOperator, endFilterKey])

  const handleStartChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value)

    setStart(value)
    handleRangeStartChange(value)
  }

  const handleEndChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value)

    setEnd(value)
    handleRangeEndChange(value)
  }

  return (
    <Flex>
      <Input
        autoFocus={lastFieldUpdated === startFilterKey}
        fontWeight="normal"
        mr={1}
        placeholder={t('min')}
        type="number"
        value={start}
        width={100}
        onChange={handleStartChange}
      />
      <Input
        autoFocus={lastFieldUpdated === endFilterKey}
        fontWeight="normal"
        placeholder={t('max')}
        type="number"
        value={end}
        width={100}
        onChange={handleEndChange}
      />
    </Flex>
  )
}

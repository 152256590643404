import { useQuery } from '@tanstack/react-query'

import coreApiCall from 'services/API/coreApi'

import { BackgroundTaskQueryKeys } from './constants'

type Args = {
  taskId?: string
  refetchInterval: number
}

export function useBackgroundTaskStatus({ taskId, refetchInterval }: Args) {
  const { data, isFetching, isError } = useQuery({
    queryKey: BackgroundTaskQueryKeys.byId(taskId ?? ''),
    queryFn: async () => {
      const response: JsonApi.Response = await coreApiCall({
        endpoint: `/background_tasks/${taskId}/status`,
        isRaw: true,
      })

      // Note: we need this for React-Query to understand there's an error
      if (!response.ok) {
        throw new Error('Network request failed')
      }

      return response
    },
    refetchInterval,
    enabled: !!taskId,
  })

  return {
    isError,
    status: data?.payload?.data?.status,
    isFetching,
  }
}

import React from 'react'

import { i18n } from 'i18n'

import { Badge } from '../components'
import { Cell } from '../styles'

interface Props {
  groups: { id: string; name: string }[] // To support case where Groups assignment is optional
}

export function Groups({ groups }: Props) {
  if (!groups.length) {
    return <span>{i18n('table.cells.groups.empty')}</span>
  }

  return (
    <Cell>
      {groups.map(({ id, name }) => (
        <Badge key={id} text={name} />
      ))}
    </Cell>
  )
}

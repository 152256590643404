import React from 'react'
import { toast, TypeOptions } from 'react-toastify'

import { isEmpty, map, upperFirst } from 'lodash'

import { Toast } from 'components/ui/__v3__/Toast'

import { i18n } from 'i18n'

import { formatErrorDetail } from './helpers/formatters'

export interface ToastMessage {
  title: string
  content?: string
}
interface ShowToast extends ToastMessage {
  type: TypeOptions
}
export function showToast({ type, content, title }: ShowToast) {
  return toast(<Toast content={content} title={title} type={type} />, {
    type,
  })
}

export function showDefaultErrorToast() {
  return toast(
    <Toast
      content={i18n('alertMessages.default.error.message')}
      title={i18n('alertMessages.default.error.title')}
      type="error"
    />,
    {
      type: 'error',
    },
  )
}

export function showCoreAPIErrorToast({
  response,
  title,
  skipErrorField = false,
}: {
  response: JsonApi.Response
  title?: string
  skipErrorField?: boolean
}) {
  const content = map(
    isEmpty(response.error)
      ? [{ detail: i18n('alertMessages.default.error.message') }]
      : response.error,
    error => formatErrorDetail(error, skipErrorField),
  ).join(', ')

  showToast({
    type: 'error',
    title: title || i18n('alertMessages.default.error.title'),
    content: upperFirst(content),
  })
}

import { useCallback } from 'react'

import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import {
  ApproveTimeEntriesMutation,
  TimeEntriesByCursorQuery,
  TimeEntrySummariesByCursorQuery,
} from './GraphQL'

export function useApprovedTimeEntries() {
  const [innerApprove, { loading, error }] = useApolloMutation<
    MutationData<'approveTimeEntries'>,
    Gateway.MutationApproveTimeEntriesArgs
  >(ApproveTimeEntriesMutation, {
    refetchQueries: compact(
      [TimeEntriesByCursorQuery, TimeEntrySummariesByCursorQuery].map(
        getOperationName,
      ),
    ),
  })

  const approveTimeEntries = useCallback(
    async (input: Gateway.TimeEntryIdsInput) => {
      const result = await innerApprove({
        variables: {
          input,
        },
      })

      return graphQLResultHasError(result)
    },
    [innerApprove],
  )

  return { approveTimeEntries, approving: loading, approveError: error }
}

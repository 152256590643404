import { toastr } from 'react-redux-toastr'

import { push } from 'connected-react-router'
import { all, fork, put, race, select, take } from 'redux-saga/effects'

import { forEach } from 'lodash'
import find from 'lodash/find'
import first from 'lodash/first'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import toString from 'lodash/toString'

import { api } from 'constants/config'
import { EMPLOYEE_STATE_FILTERS, INVITE_STORAGE_KEY } from 'constants/employees'
import { ACCESS_LEVEL } from 'constants/ids'

import { areasToStringFilters } from 'helpers/areas'
import { downloadFileAsync } from 'helpers/files'
import { peopleEmployeePath, peopleManagerPath } from 'helpers/routes'
import { accessLevelsToFilters, statusesToFilters } from 'helpers/staff'

import _ from 'i18n'

import {
  ARCHIVE_EMPLOYEE,
  CHECK_ACTIVE_TIMER,
  EMPLOYEE_SITE_UPDATE,
  EMPLOYEE_UPLOAD_AVATAR,
  employeeDataReady,
  EXPORT_PEOPLE_CSV,
  LOAD_EMPLOYEE_BY_ID,
  loadEmployee,
  processFailureInvite,
  REACTIVATE_EMPLOYEE,
  REDIRECT_TO_CHANGE_ACCESS_LEVEL,
  selectManager,
  toggleArchive,
  unloadEmployee,
  UPDATE_EMPLOYEE_CUSTOM_CV_FIELDS,
  updateEmployeeAvatar,
} from 'store/actions/employees/employees'
import { toggleInvite } from 'store/actions/header'
import { setBranch } from 'store/actions/viewer'
import { getBranches } from 'store/selectors/company/branches'
import { getManagers } from 'store/selectors/company/managers'
import {
  getAlreadyInvitedError,
  getEmployees,
  getHasActiveTimerEntry,
} from 'store/selectors/employees/employees'
import { getBranchId } from 'store/selectors/viewer'

function* watchLoadById() {
  while (true) {
    yield take([LOAD_EMPLOYEE_BY_ID.SUCCESS])
    yield put(employeeDataReady())
  }
}

function* handleArchiveActiveTimeError() {
  const ERROR_DETAILS = 'employee has active timer'
  while (true) {
    const { payload } = yield take(ARCHIVE_EMPLOYEE.FAILURE)
    const errors = get(payload, 'errors', [])
    const timerError = find(
      errors,
      error => get(error, 'detail', null) === ERROR_DETAILS,
    )
    if (!isEmpty(timerError)) {
      toastr.info(_('employees.canNotArchiveEmployee'))
    }
  }
}

function* checkEmployeeHasActiveTimer() {
  while (true) {
    yield take([CHECK_ACTIVE_TIMER.SUCCESS, CHECK_ACTIVE_TIMER.FAILURE])
    const hasActiveTimerEntry = yield select(getHasActiveTimerEntry)
    if (hasActiveTimerEntry) {
      yield put(toggleArchive(false))
      toastr.info(_('employees.canNotArchiveEmployee'))
    } else {
      yield put(toggleArchive(true))
    }
  }
}

function* reloadAfterArchiveOrReactivateEmployee() {
  while (true) {
    yield take([ARCHIVE_EMPLOYEE.SUCCESS, REACTIVATE_EMPLOYEE.SUCCESS])
    window.location.reload()
  }
}

function* redirectToChangeAccessLevel() {
  while (true) {
    const { payload } = yield take(REDIRECT_TO_CHANGE_ACCESS_LEVEL)
    const tab = get(payload, 'tab')
    // close invite modal
    if (tab) {
      yield put(toggleInvite(tab))
    }
    const inviteError = yield select(getAlreadyInvitedError)
    const locations = yield select(getBranches)

    const activeLocationId = yield select(getBranchId)
    const activeLocation = find(
      locations,
      location => location.id === activeLocationId,
    )
    const activeLocationName = get(activeLocation, 'name')

    const userId = toString(get(inviteError, 'userId'))
    const userAccessLevel = get(inviteError, 'accessLevel')
    const userLocationsNames = get(inviteError, 'locations')

    if (userId && userAccessLevel) {
      localStorage.setItem(
        INVITE_STORAGE_KEY,
        JSON.stringify({
          userId,
          accessLevel: userAccessLevel,
        }),
      )
      const isInActiveLocation =
        userAccessLevel === ACCESS_LEVEL.admin ||
        userLocationsNames.indexOf(activeLocationName) !== -1
      if (isInActiveLocation) {
        yield put(unloadEmployee())
        if (userAccessLevel === ACCESS_LEVEL.employee) {
          const employees = yield select(
            getEmployees(EMPLOYEE_STATE_FILTERS.all),
          )
          const employee = find(
            employees,
            item => get(item, 'user.id') === userId,
          )
          const employeeId = get(employee, 'id')
          if (employeeId) {
            yield put(loadEmployee(employeeId))
            const isArchived =
              employee.state === EMPLOYEE_STATE_FILTERS.archived
            const options = {}
            if (isArchived) {
              options.reactivateEmployee = true
              options.alternativeReactivateText = _(
                'employees.reactivateEmployee',
              )
            } else {
              options.changeAccessLevel = true
            }
            yield put(push(peopleEmployeePath(employeeId), options))
          }
        } else {
          const managers = yield select(getManagers)
          const manager = find(
            managers,
            item => get(item, 'user.id') === userId,
          )
          const managerId = get(manager, 'id')
          if (managerId) {
            yield put(selectManager(managerId))
            const isArchived = manager.state === EMPLOYEE_STATE_FILTERS.archived
            const options = {}
            if (isArchived) {
              options.reactivateEmployee = true
            } else {
              options.changeAccessLevel = true
            }
            yield put(push(peopleManagerPath(managerId), options))
          }
        }
      } else {
        const availableLocation = find(
          locations,
          location => userLocationsNames.indexOf(location.name) !== -1,
        )
        const availableLocationId = get(availableLocation, 'id')
        if (availableLocationId) {
          yield put(setBranch(availableLocationId))
        }
      }
    }

    yield put(processFailureInvite())
  }
}

function* watchUploadAvatar() {
  while (true) {
    const { payload } = yield take(EMPLOYEE_UPLOAD_AVATAR.SUCCESS)
    const { data, employee } = payload
    if (employee) {
      yield put(
        updateEmployeeAvatar({
          employee,
          avatarId: first(keys(data.files)),
        }),
      )
    }
  }
}

function* watchSiteUpdate() {
  while (true) {
    const { success } = yield race({
      success: take(EMPLOYEE_SITE_UPDATE.SUCCESS),
      error: take(EMPLOYEE_SITE_UPDATE.FAILURE),
    })
    if (success) {
      toastr.success(_('people.profileSaved'), {
        disableCloseButtonFocus: true,
      })
    } else {
      toastr.error(_('common.somethingWentWrong'), {
        disableCloseButtonFocus: true,
      })
    }
  }
}

function* watchUpdateCvFields() {
  while (true) {
    const { success } = yield race({
      success: take(UPDATE_EMPLOYEE_CUSTOM_CV_FIELDS.SUCCESS),
      error: take(UPDATE_EMPLOYEE_CUSTOM_CV_FIELDS.FAILURE),
    })
    if (success) {
      toastr.success(_('common.successfullySaved'), {
        // Fix for input losing focus when a notification appears
        disableCloseButtonFocus: true,
      })
    } else {
      toastr.error(_('common.somethingWentWrong'), {
        disableCloseButtonFocus: true,
      })
    }
  }
}

const PEOPLE_EXPORT_TYPES = {
  PEOPLE: 'people_export',
}

function* exportPeopleCSV() {
  while (true) {
    const { payload } = yield take(EXPORT_PEOPLE_CSV.SUCCESS)
    exportCSV(payload, PEOPLE_EXPORT_TYPES.PEOPLE)
  }
}

async function exportCSV(payload, exportType) {
  const { filesUrl } = api

  const { data, companyId, filters, locale, year } = payload

  const token = data?.token

  if (token) {
    // prettier-ignore
    let url = `${filesUrl}/companies/${companyId}/${exportType}?token=${token}&locale=${locale}&year=${year}`

    if (filters?.accessLevels) {
      const filter = accessLevelsToFilters(filters.accessLevels)
      forEach(filter.access_level.in, accessLevel => {
        url += `&filter[access_level][in][]=${accessLevel}`
      })
    }

    if (filters?.statuses) {
      const filter = statusesToFilters(filters.statuses)
      forEach(filter.status.in, status => {
        url += `&filter[status][in][]=${status}`
      })
    }

    if (filters?.areas) {
      url += areasToStringFilters(filters.areas)
    }

    await downloadFileAsync(url)
  }
}

export default function* root() {
  yield all([
    fork(watchLoadById),
    fork(handleArchiveActiveTimeError),
    fork(reloadAfterArchiveOrReactivateEmployee),
    fork(checkEmployeeHasActiveTimer),
    fork(redirectToChangeAccessLevel),
    fork(watchUploadAvatar),
    fork(watchUpdateCvFields),
    fork(exportPeopleCSV),
    fork(watchSiteUpdate),
  ])
}

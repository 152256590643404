import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { Flex } from 'components/ui/__v2__/Grid'
import { InputLabel, LabelText } from 'components/ui/__v3__'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import { EmploymentTypePicker } from '../EmploymentTypePicker'
import { EmploymentTypePickerProps } from '../EmploymentTypePicker/types'

export function FormEmploymentTypePicker<T extends FieldValues>({
  control,
  name,
  labelText,
  required,
  ...selectProps
}: ControllerBaseProps<T> &
  Omit<EmploymentTypePickerProps, 'onSelect' | 'onBlur' | 'value'> &
  LabelProps) {
  const {
    field: { onChange, value, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  return (
    <Flex flexDirection="column">
      {labelText && (
        <InputLabel>
          <LabelText labelText={labelText} required={required} />
        </InputLabel>
      )}
      <EmploymentTypePicker
        value={value}
        onBlur={onBlur}
        onSelect={onChange}
        {...selectProps}
      />
      {error && <Error error={error} />}
    </Flex>
  )
}

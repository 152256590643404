import { DateTime } from 'luxon'

export function isTimeoffStartDateInThePast({
  startISODate,
  startISOTime,
  partial,
  timezone,
}: {
  startISODate?: string
  startISOTime?: string
  partial?: boolean
  timezone: string
}) {
  if (!startISODate) return false

  if (partial && startISOTime) {
    return (
      DateTime.fromISO(startISOTime, {
        zone: timezone,
      }) < DateTime.now().setZone(timezone, { keepLocalTime: true })
    )
  }

  return (
    DateTime.fromISO(startISODate, { zone: timezone }).startOf('day') <
    DateTime.now().setZone(timezone, { keepLocalTime: true }).startOf('day')
  )
}

import { useState } from 'react'

import { download } from 'API/services/JSON/downloadDocument'
import qs from 'qs'

import { useAppContext } from 'hooks'

import Utils from 'services/Utils'

import { ClassifiedTimesAggregatedFilter } from './types'

type ExportClassifiedTimesSummaryProps = ClassifiedTimesAggregatedFilter & {
  timeTypeIds?: string[]
}

export function useClassifiedTimesSummaryExport() {
  const [exporting, setExporting] = useState(false)
  const { companyId } = useAppContext()

  async function exportClassifiedTimesSummary(
    filter: ExportClassifiedTimesSummaryProps,
  ) {
    const queryString = qs.stringify(
      {
        filter: {
          date: Utils.JsonApi.betweenFilter(filter.date),
          ...(!!filter.employeeIds?.length && {
            employee_id: Utils.JsonApi.inFilter(filter.employeeIds),
          }),
          ...(!!filter.timeTypeIds?.length && {
            time_type_id: Utils.JsonApi.inFilter(filter.timeTypeIds),
          }),
        },
      },
      { skipNulls: true, arrayFormat: 'brackets' },
    )

    setExporting(true)
    await download({
      companyId,
      queryString,
      kind: 'classified_times_summary_export',
    })
    setExporting(false)
  }

  return {
    exporting,
    exportClassifiedTimesSummary,
  }
}

import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { InputLabel, LabelText } from 'components/ui/__v3__'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { IconError } from 'components/ui/__v3__/Input/Errors'
import ColorsPalette from 'components/ui/ColorPalette/ColorPalette'

import Utils from 'services/Utils'

import { ValidationError } from './components/Error/styles'

type Props<T extends FieldValues> = ControllerBaseProps<T> & {
  labelText?: string
  required?: boolean
}
const COLORS = Utils.Color.generatePaletteColors()

export function FormColorsPalette<T extends FieldValues>({
  name,
  control,
  labelText,
  required,
  ...rest
}: Props<T>) {
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  return (
    <InputLabel>
      <LabelText labelText={labelText} required={required} />

      <ColorsPalette
        {...rest}
        colors={COLORS}
        // @ts-ignore
        ref={ref}
        selectedColor={value}
        width="100%"
        onColorSelected={onChange}
      />

      {error && (
        <ValidationError>
          <IconError>{error.message ?? ''}</IconError>
        </ValidationError>
      )}
    </InputLabel>
  )
}

import React from 'react'

import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

import { Flex, Span } from 'components/ui/__v2__/Grid'

import { useI18n } from 'hooks/useI18n'

import { PageSizeSelect } from './components'
import { PaginationContainer } from './styles'

import { IconButton } from '../../Button'

type Props = {
  size: number
  totalCountContent?: React.ReactNode
  hasNextPage: boolean
  hasPrevPage: boolean

  // Note: to use this where we want to lock the page size
  onSizeChange?: (size: number) => void
  onPrevPageClick: () => void
  onNextPageClick: () => void
}

export function CursorPagination({
  size,
  totalCountContent,
  hasNextPage,
  hasPrevPage,
  onSizeChange,
  onPrevPageClick,
  onNextPageClick,
}: Props) {
  const t = useI18n('table')

  return (
    <PaginationContainer>
      {totalCountContent && (
        <Flex alignItems={'center'} mr={1}>
          {totalCountContent}
        </Flex>
      )}
      <IconButton
        disabled={!hasPrevPage}
        mr={1}
        secondary
        onClick={onPrevPageClick}
      >
        <FiChevronLeft />
      </IconButton>
      <IconButton disabled={!hasNextPage} secondary onClick={onNextPageClick}>
        <FiChevronRight />
      </IconButton>
      {onSizeChange && (
        <>
          <Span ml={3} mr={3}>
            {t('rowsPerPage')}
          </Span>
          <PageSizeSelect size={size} onSizeChange={onSizeChange} />
        </>
      )}
    </PaginationContainer>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import coreApiCall from 'services/API/coreApi'
import { showToast } from 'services/Toasts'

import { TimeCardQueryKeys } from './constants'

function createTimeCard({
  startAt,
  endAt,
  note,
  pauses,
  locationId,
  departmentId,
  employeeId,
  jobId,
}: {
  startAt: string
  endAt: string
  note?: string
  pauses: Array<{
    startAt: string
    endAt: string
  }>
  locationId: string
  departmentId: string
  employeeId: string
  jobId: string
}) {
  return coreApiCall({
    endpoint: `/timecards`,
    method: 'POST',
    body: {
      data: [
        {
          type: 'timeEntries',
          attributes: {
            startAt,
            endAt,
            pauses,
            note,
          },
          // TODO: Rework to accept Areas and process with Utils
          relationships: {
            branch: {
              data: {
                id: locationId,
                type: 'branches',
              },
            },
            department: {
              data: {
                id: departmentId,
                type: 'departments',
              },
            },
            employee: {
              data: {
                id: employeeId,
                type: 'employee',
              },
            },
            job: {
              data: {
                id: jobId,
                type: 'jobs',
              },
            },
          },
        },
      ],
    },
  })
}

/** @deprecated */
export function useCreateTimeCard() {
  const queryClient = useQueryClient()
  const t = useI18n<typeof TRANSLATIONS.timeline.toasts.creation>(
    'timeline.toasts.creation',
  )

  const { mutateAsync, isLoading, error } = useMutation(
    ['timecards'],
    createTimeCard,
    {
      onSuccess: (response: JsonApi.Response) => {
        if (!response?.ok) {
          showToast({
            type: 'error',
            title: t('error.title'),
            content: t('error.message'),
          })
        } else {
          showToast({
            type: 'success',
            title: t('success.title'),
            content: t('success.message'),
          })

          queryClient.invalidateQueries(TimeCardQueryKeys.all())
        }
      },
    },
  )

  return {
    createTimeCard: mutateAsync,
    createTimeCardLoading: isLoading,
    createTimeCardError: error,
  }
}

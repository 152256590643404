import React from 'react'
import { FieldValues } from 'react-hook-form'

import {
  FormCheckbox,
  FormMaskedNumeric,
  FormSelect,
  FormSingleDayPicker,
  FormText,
} from 'components/ui/__v3__/HookForm'

import { CustomCvFieldFieldType } from 'constants/gatewayGraphQL'
import { DECIMAL_MASK, NUMBER_MASK, PERCENT_MASK } from 'constants/settings'

import Utils from 'services/Utils'

import { CustomFieldInputRendererProps } from './types'

export function FormCustomFieldInputRenderer<T extends FieldValues>({
  control,
  disabled,
  name,

  fieldType,
  options,
  showCheckboxTooltip = true,
  ...rest
}: CustomFieldInputRendererProps<T>) {
  switch (fieldType) {
    case CustomCvFieldFieldType.Text:
    case CustomCvFieldFieldType.Email:
    case CustomCvFieldFieldType.Url:
    case CustomCvFieldFieldType.Phone:
      return (
        <FormText<T>
          {...rest}
          control={control}
          disabled={disabled}
          name={name}
        />
      )

    case CustomCvFieldFieldType.Number:
      return (
        <FormMaskedNumeric<T>
          {...rest}
          control={control}
          disabled={disabled}
          mask={NUMBER_MASK}
          name={name}
        />
      )

    case CustomCvFieldFieldType.Decimal:
      return (
        <FormMaskedNumeric<T>
          {...rest}
          control={control}
          disabled={disabled}
          mask={DECIMAL_MASK}
          name={name}
          placeholder="14.20"
        />
      )

    case CustomCvFieldFieldType.Percent:
      return (
        <FormMaskedNumeric<T>
          {...rest}
          control={control}
          disabled={disabled}
          mask={PERCENT_MASK}
          name={name}
          placeholder="%"
        />
      )

    case CustomCvFieldFieldType.Dropdown:
      return (
        <FormSelect<T>
          {...rest}
          control={control}
          isClearable={false}
          isDisabled={disabled}
          name={name}
          options={(options ?? []).map(Utils.Select.generateOption)}
          returnOptionObject={false}
          withPortal
        />
      )

    case CustomCvFieldFieldType.Checkbox:
      return (
        <FormCheckbox<T>
          wrapper={{ height: showCheckboxTooltip ? 32 : 'auto' }}
          {...rest}
          control={control}
          disabled={disabled}
          name={name}
          showTooltip={showCheckboxTooltip}
          size={20}
          stringState
        />
      )

    case CustomCvFieldFieldType.Date:
      return (
        // @ts-ignore
        <FormSingleDayPicker<T>
          {...rest}
          control={control}
          disabled={disabled}
          name={name}
        />
      )

    default:
      return null
  }
}

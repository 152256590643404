import React, { useCallback, useMemo, useState } from 'react'

import { useTheme } from 'styled-components'
import { SpaceProps } from 'styled-system'

import {
  autoPlacement,
  autoUpdate,
  FloatingPortal,
  Placement,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react'

import { Flex, Span } from 'components/ui/__v2__/Grid'

import { ScheduleAcceptanceState } from 'constants/gatewayGraphQL'
import { ScheduleAcceptanceJsonApiState } from 'constants/scheduleAcceptance'

import { useRemainingSeconds } from 'hooks'

import Utils from 'services/Utils'

import { formatRemainingSeconds } from './helpers'
import { PopoverContainer } from './styles'

type Props = SpaceProps & {
  state: ScheduleAcceptanceJsonApiState | ScheduleAcceptanceState
  color?: string
  acceptDeadline?: string
  autoAccept?: boolean
  allowedPlacements?: Placement[]
}

export function ScheduleAcceptanceStatePopover({
  state,
  color,
  acceptDeadline,
  autoAccept,
  allowedPlacements = ['left'],
  ...rest
}: Props) {
  const theme = useTheme()

  const [modifiedState, setModifiedState] = useState(() =>
    Utils.ScheduleAcceptance.mapJsonAPiStateToGraphQlState({
      state,
      autoAccept,
    }),
  )

  // =====================================================================================
  // REFACTOR TO REUSABLE HOOK
  // =====================================================================================
  const [menu, setMenu] = useState(false)
  const { refs, floatingStyles, context } = useFloating({
    open: menu,
    onOpenChange: innerOpen => {
      setMenu(innerOpen)
    },
    middleware: [
      autoPlacement({
        allowedPlacements,
      }),
    ],
    whileElementsMounted: autoUpdate,
  })
  const hover = useHover(context)
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    hover,
  ])
  // EOF: REFACTOR TO REUSABLE HOOK ======================================================

  const handleDeadlineTimeExpired = useCallback(() => {
    if (
      modifiedState === ScheduleAcceptanceState.TimedOut ||
      // NOTE: sometimes there can be an "acceptDeadline" with an "accepted" status
      //       so this is a check for such cases
      modifiedState !== ScheduleAcceptanceState.Pending ||
      !acceptDeadline
    )
      return

    setModifiedState(ScheduleAcceptanceState.TimedOut)
  }, [modifiedState, acceptDeadline])

  const remainingSeconds = useRemainingSeconds({
    deadline: acceptDeadline,
    // NOTE: auto updater for timed out state when pending runs out of time
    onTimeExpired: handleDeadlineTimeExpired,
  })

  const { Icon, stateColor, stateContent } = useMemo(() => {
    const isPendingWithDeadline =
      acceptDeadline &&
      remainingSeconds > 0 &&
      modifiedState === ScheduleAcceptanceState.Pending

    return {
      Icon: Utils.ScheduleAcceptance.getStateIcon({
        state: modifiedState,
        autoAccept,
      }),
      stateColor: Utils.ScheduleAcceptance.getStateColor({
        state: modifiedState,
        theme,
        autoAccept,
      }),

      stateContent: (
        <Flex gap={1}>
          <Span fontSize="12px" fontWeight={600}>
            {Utils.ScheduleAcceptance.getStateText({
              state: modifiedState,
              autoAccept,
            })}
          </Span>

          {isPendingWithDeadline && (
            <Span fontSize="12px" fontWeight={600}>
              {formatRemainingSeconds(remainingSeconds)}
            </Span>
          )}
        </Flex>
      ),
    }
  }, [modifiedState, acceptDeadline, autoAccept, theme, remainingSeconds])

  return (
    <>
      <Flex {...rest} {...getReferenceProps()} ref={refs.setReference}>
        <Icon color={color ?? stateColor} size="14px" />
      </Flex>

      {menu && (
        <FloatingPortal root={document.body}>
          <PopoverContainer
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <Flex alignItems="center" gap={1} py={2}>
              <Icon color={stateColor} size="14px" />

              {stateContent}
            </Flex>
          </PopoverContainer>
        </FloatingPortal>
      )}
    </>
  )
}

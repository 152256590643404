import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

// BE returns wage in cents
export const convertWageToDollars = wage => {
  if (typeof wage !== 'number') {
    return 0
  }
  return wage / 100
}

// FE wage - can be floating point number
export const convertWageToCents = wage => Math.round(wage * 100)

export const cleanNumericString = value =>
  toString(value).replace(/ /g, '').replace(/[,]/g, '.')

export function prepareMonetaryToSubmit(value) {
  const numeric = convertWageToCents(cleanNumericString(value))
  return numeric === 0 ? null : numeric
}

export function displayableMonetary(cents) {
  return cents
    ? `${convertWageToDollars(toNumber(cents)).toLocaleString()} $`
    : '—'
}

export function centsToMaskedString(integerCents) {
  return toString(convertWageToDollars(integerCents)).replace(/[.]/g, ',')
}

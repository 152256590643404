import { createFields /* , createReducerHandlers */ } from 'rapidux'
import { createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import get from 'lodash/get'

import { SORT_OPTIONS } from 'constants/departmentSchedule'

import { currentWeek } from 'helpers/date'
import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { CREATE_SHIFT_COVER } from 'store/actions/employeeApp/findCover'
import {
  CHANGE_DEPARTMENT_OPTION,
  CHANGE_SORT_OPTION,
  CHANGE_WEEK,
  INIT,
  INIT_DONE,
  LOAD_DEPARTMENT_SCHEDULES,
  LOAD_EMPLOYEE_SCHEDULE,
  SELECT_SCHEDULE,
  TOGGLE_TAB,
} from 'store/actions/employeeApp/schedules'
import { CREATE_SHIFT_TRADE } from 'store/actions/employeeApp/tradeShift'

const employeeScheduleFields = createFields('schedules', 'employeeSchedules')

const initialState = Immutable({
  ...employeeScheduleFields,

  // -- DEPRECATED

  error: '',
  schedules: [],
  isLoadingSchedules: false,
  fromId: null,
  isLoading: false,
  isLoaded: false,
  week: currentWeek,
  isWeekChangeLoader: false,
  isDepartmentTab: false,
  departmentSchedules: [],
  departmentOption: 'all',
  sortOption: SORT_OPTIONS.startTime,
  isLoadingDepartmentSchedules: false,

  // --
})

const handlers = {
  // ...createReducerHandlers('schedules', LOAD_EMPLOYEE_SCHEDULE, {
  //   withReplace: true,
  //   withLoading: true,
  //   mapToKey: 'employeeSchedules',
  // }),

  [LOG_OUT]: () => initialState,

  // -- DEPRECATED

  [INIT]: state => state.merge({ isLoading: true }),

  [INIT_DONE]: state => {
    return state.merge({
      isLoading: false,
      isLoaded: true,
    })
  },

  [CHANGE_WEEK]: (state, action) => {
    return state.merge({ week: action.payload.week, isWeekChangeLoader: true })
  },

  [SELECT_SCHEDULE]: (state, { payload }) => state.merge({ fromId: payload }),

  [LOAD_EMPLOYEE_SCHEDULE.REQUEST]: state => {
    return state.merge({ isLoadingSchedules: true })
  },

  [LOAD_EMPLOYEE_SCHEDULE.SUCCESS]: createLoadHandler('schedules', {
    addToState: { isLoadingSchedules: false },
    withReplace: true,
    withLoading: true,
  }),

  [LOAD_EMPLOYEE_SCHEDULE.FAILURE]: (state, action) => {
    return state.merge({
      error: action.payload,
      isLoading: false,
      isLoaded: false,
      isLoadingSchedules: false,
    })
  },

  [CREATE_SHIFT_COVER.SUCCESS]: state => {
    return state.merge({
      fromId: null,
    })
  },

  [CREATE_SHIFT_TRADE.SUCCESS]: state => {
    return state.merge({
      fromId: null,
    })
  },

  [TOGGLE_TAB]: state => {
    return state.merge({
      isDepartmentTab: !state.isDepartmentTab,
    })
  },

  [CHANGE_DEPARTMENT_OPTION]: (state, { payload }) => {
    return state.merge({
      departmentOption: get(payload, 'departmentId'),
    })
  },

  [CHANGE_SORT_OPTION]: (state, { payload }) => {
    return state.merge({
      sortOption: get(payload, 'sortOption'),
    })
  },

  [LOAD_DEPARTMENT_SCHEDULES.REQUEST]: state => {
    return state.merge({
      isLoadingDepartmentSchedules: true,
    })
  },

  [LOAD_DEPARTMENT_SCHEDULES.SUCCESS]: createLoadHandler('schedules', {
    addToState: { isLoadingDepartmentSchedules: false },
    mapToKey: 'departmentSchedules',
    withReplace: true,
  }),

  [LOAD_DEPARTMENT_SCHEDULES.FAILURE]: state => {
    return state.merge({
      isLoadingDepartmentSchedules: false,
    })
  },

  // --
}

export default createReducer(initialState, handlers)

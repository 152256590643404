import { useApolloMutation } from 'API/services/Apollo'

import { GenerateReportLinkMutation } from './GraphQL'

export function useGenerateReportLink() {
  const [generateReportLink, { loading: generating }] = useApolloMutation<
    MutationData<'generateReportLink'>,
    Gateway.MutationGenerateReportLinkArgs
  >(GenerateReportLinkMutation, { silent: true })

  const generateLink = async (
    id: Gateway.MutationGenerateReportLinkArgs['id'],
  ) => {
    return generateReportLink({
      variables: {
        id,
      },
    })
  }

  return { generateLink, generating }
}

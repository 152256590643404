import { BsDownload } from 'react-icons/bs'

import styled from 'styled-components'
import { flexbox, space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${themeGet('space.2')}px 0;

  & > *:not(:last-child) {
    margin-right: ${themeGet('space.3')}px;
  }

  ${space};
`

export const FileLink = styled.a.attrs({ target: '_blank' })`
  display: flex;
  color: inherit;

  ${space};
  ${flexbox};
`

export const IconLink = styled.a.attrs({ target: '_blank' })`
  display: flex;
  color: ${themeGet('layout.primary')};

  ${space};
  ${flexbox};
`

export const DownloadIcon = styled(BsDownload)`
  color: inherit;
`

import React from 'react'
import { Control } from 'react-hook-form'

import { Divider } from 'components/ui/__v2__/Divider'
import { SpacedColumn, Span } from 'components/ui/__v2__/Grid'
import { FormRadioButton } from 'components/ui/__v3__/HookForm'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { UnavailabilityReasons, UnavailableOptionsFormState } from '../../types'

type Props = {
  control: Control<UnavailableOptionsFormState>
}

export function OptionsForm({ control }: Props) {
  const t = useI18n<
    typeof TRANSLATIONS.shiftAssign.candidates.unavailableDetails
  >('shiftAssign.candidates.unavailableDetails')
  return (
    <form>
      <SpacedColumn p={2}>
        <Span>{t('description')}</Span>
        <Divider />

        <FormRadioButton
          control={control}
          labelText={t('reasons.whateverReason')}
          name="reason"
          size={20}
          value={UnavailabilityReasons.Whatever}
        />
        <FormRadioButton
          control={control}
          labelText={t('reasons.shiftConflict')}
          name="reason"
          size={20}
          value={UnavailabilityReasons.ShiftConflict}
        />
        <FormRadioButton
          control={control}
          labelText={t('reasons.timeoffConflict')}
          name="reason"
          size={20}
          value={UnavailabilityReasons.TimeoffConflict}
        />
        <FormRadioButton
          control={control}
          labelText={t('reasons.availabilityConflict')}
          name="reason"
          size={20}
          value={UnavailabilityReasons.AvailabilityConflict}
        />
        <FormRadioButton
          control={control}
          labelText={t('reasons.availableButShiftConflict')}
          name="reason"
          size={20}
          value={UnavailabilityReasons.AvailableButShiftConflict}
        />
        <FormRadioButton
          control={control}
          labelText={t('reasons.availableButTimeoffConflict')}
          name="reason"
          size={20}
          value={UnavailabilityReasons.AvailableBustTimeoffConflict}
        />
        <FormRadioButton
          control={control}
          labelText={t('reasons.availableButMissingQualifications')}
          name="reason"
          size={20}
          value={UnavailabilityReasons.AvailableButMissingQualifications}
        />
      </SpacedColumn>
    </form>
  )
}

import { useCloneRuleset } from './useCloneRuleset'
import { useCreateRuleset } from './useCreateRuleset'
import { useDeleteRuleset } from './useDeleteRuleset'
import { usePublishRuleset } from './usePublishRuleset'
import { useRuleset } from './useRuleset'
import { useRulesetsByCursor } from './useRulesetsByCursor'
import { useUnpublishRuleset } from './useUnpublishRuleset'
import { useUpdateRuleset } from './useUpdateRuleset'

export const RulesetAPI = {
  create: useCreateRuleset,
  update: useUpdateRuleset,
  delete: useDeleteRuleset,
  clone: useCloneRuleset,
  publish: usePublishRuleset,
  unpublish: useUnpublishRuleset,
  byCursor: useRulesetsByCursor,
  byId: useRuleset,
}

import React, { useMemo } from 'react'

import { ShiftValidationConflictAPIItem } from 'API/RuleValidation/GraphQL/Fragments/types'
import { ScheduleAcceptanceEssentialFields } from 'API/ScheduleAcceptance/GraphQL/Fragments/types'

import { WarningPopover } from 'components/blocks/__v2__'
import { ScheduleAcceptanceStatePopover } from 'components/blocks/__v3__/ScheduleAcceptance'
import { ShiftValidationConflictTypePopover } from 'components/blocks/__v3__/ShiftValidationConflictComponents'
import { TextOverflowWithPopover } from 'components/blocks/__v3__/TextOverflowWithPopover'
import { Flex } from 'components/ui/__v2__/Grid'
import { Spinner } from 'components/ui/Spinner'

import { i18n } from 'i18n'

import Utils from 'services/Utils'

import {
  VALIDATION_CONFLICT_ICON_SIZE,
  VALIDATION_CONFLICT_POPOVER_WIDTH,
} from './constants'
import { PublishedSign } from './PublishedSign'
import { AssignmentContainer } from './styles'

import { UnassignButton } from '../../Controls'
import { RevertButton } from '../../Controls/RevertButton'

type Props = {
  firstName?: string
  lastName?: string

  canReassignBack: boolean
  beingUnassigned: boolean
  published: boolean

  shiftConflicts: ShiftValidationConflictAPIItem[]
  validatingShift: boolean
  rulesetId: string
  scheduleAcceptance: ScheduleAcceptanceEssentialFields

  hasMissingQualifications: boolean

  onUnassignClick: () => void
  onRestoreAssignmentClick: () => void
}

export const ExistingAssignment = ({
  firstName,
  lastName,
  canReassignBack,
  beingUnassigned,
  published,
  shiftConflicts,
  validatingShift,
  rulesetId,
  scheduleAcceptance,
  hasMissingQualifications,
  onUnassignClick,
  onRestoreAssignmentClick,
}: Props) => {
  const readableName = Utils.User.buildFullName({ firstName, lastName })

  const shiftValidationConflictTypeContent = useMemo(() => {
    if (validatingShift) return <Spinner size={VALIDATION_CONFLICT_ICON_SIZE} />

    if (shiftConflicts.length > 0)
      return (
        <ShiftValidationConflictTypePopover
          disabled={beingUnassigned}
          iconSize={VALIDATION_CONFLICT_ICON_SIZE}
          maxWidth={VALIDATION_CONFLICT_POPOVER_WIDTH}
          rulesetId={rulesetId}
          shiftConflicts={shiftConflicts}
        />
      )

    return null
  }, [beingUnassigned, shiftConflicts, validatingShift, rulesetId])

  return (
    <AssignmentContainer opaque={beingUnassigned}>
      <Flex alignItems="center" gap={1}>
        {!!scheduleAcceptance && (
          <ScheduleAcceptanceStatePopover
            acceptDeadline={scheduleAcceptance.acceptDeadline}
            allowedPlacements={['top']}
            autoAccept={scheduleAcceptance.autoAccept}
            ml={1}
            state={scheduleAcceptance.state}
          />
        )}
        <Flex ml={!scheduleAcceptance ? '22px' : '0'}>
          <TextOverflowWithPopover>{readableName}</TextOverflowWithPopover>
        </Flex>
      </Flex>

      <Flex alignItems="center" gap={1}>
        {shiftValidationConflictTypeContent}

        {hasMissingQualifications && (
          <WarningPopover
            iconSize={16}
            placement="top"
            popoverContent={i18n('shiftAssign.missingQualifications')}
          />
        )}
        {published && <PublishedSign />}
        {beingUnassigned ? (
          <RevertButton
            disabled={!canReassignBack || validatingShift}
            onClick={() => {
              if (validatingShift) return
              onRestoreAssignmentClick()
            }}
          />
        ) : (
          <UnassignButton
            disabled={validatingShift}
            existing
            onClick={() => {
              if (validatingShift) return
              onUnassignClick()
            }}
          />
        )}
      </Flex>
    </AssignmentContainer>
  )
}

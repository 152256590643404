import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import {
  DEFAULT_JSON_API_CURSOR_PAGING,
  STALE_TIME,
} from 'API/services/JsonApi'
import { transformResponseToEntities } from 'API/services/utils'

import { useAppContext } from 'hooks'

import { STAFF_JSON_API_TYPE, StaffQueryKey } from './constant'
import { staffQuery, StaffQueryArgs } from './Json'
import { StaffAPIItem } from './types'

const PICKER_INCLUDE = ['profile.user'].join(',')

export function usePickerStaff({ filters }: Pick<StaffQueryArgs, 'filters'>) {
  const [enabled, setEnabled] = useState(false)
  const { company } = useAppContext()

  const { data, isFetching, isError } = useQuery({
    queryKey: StaffQueryKey.picker(PICKER_INCLUDE, filters),
    queryFn: () =>
      staffQuery({
        companyId: company.coreId,
        filters,
        page: DEFAULT_JSON_API_CURSOR_PAGING,
        include: PICKER_INCLUDE,
      }),
    select: (response: JsonApi.Response) =>
      transformResponseToEntities<StaffAPIItem>({
        response,
        jsonApiType: STAFF_JSON_API_TYPE,
      }).entities,
    structuralSharing: false,
    refetchOnWindowFocus: false,
    enabled, // Note: this is to make sure we're not firing when picker is not open
    staleTime: STALE_TIME.FAST, // Note: this is so when you open/close the picker rapidly - unnecessary calls are not fired. Not suitable for data that is expected to change fast
  })

  return {
    staff: data ?? [],
    setEnabled,
    isFetching,
    isError,
  }
}

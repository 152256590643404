import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import { loadFormTemplates } from 'store/actions/company/formTemplates'
import { getFormTemplates } from 'store/selectors/company/formTemplates'

import FormTemplatePicker from './FormTemplatePicker'

const selectors = createStructuredSelector({
  forms: getFormTemplates,
})

const actions = {
  onLoad: loadFormTemplates,
}

export default connect(selectors, actions)(FormTemplatePicker)

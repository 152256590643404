import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { SpacedRow } from 'components/ui/__v2__/Grid'

import { ReferenceYearEndDateKinds } from 'constants/ids'
import {
  hireDateToReferenceYearText,
  leaveYearEndDateRuleStringToReferenceYearText,
} from 'constants/timeoff'

import i18n from 'i18n'

import { Cell, Details, Tag } from './styles'

function CustomLeaveDay({ leaveDay, showReferenceYearEnd, year, employee }) {
  const name = leaveDay?.name
  const paid = leaveDay?.paid
  const referenceYearEndRecurrence = leaveDay?.referenceYearEndRecurrence

  const withEmployeeHireDate =
    leaveDay?.referenceYearEndKind === ReferenceYearEndDateKinds.HireDate

  function renderDetails() {
    if (withEmployeeHireDate) {
      return (
        <Details>
          {employee?.hiredAt
            ? hireDateToReferenceYearText(employee?.hiredAt, year)
            : i18n('customLabelDays.referenceYearEndDateDetails')}
        </Details>
      )
    }

    if (showReferenceYearEnd && referenceYearEndRecurrence) {
      return (
        <Details>
          {leaveYearEndDateRuleStringToReferenceYearText(
            referenceYearEndRecurrence,
            year,
          )}
        </Details>
      )
    }

    return null
  }

  return (
    <SpacedRow
      alignItems="center"
      justifyContent="space-between"
      space={1}
      width="100%"
    >
      <Cell fullLength={!paid}>
        {name}
        {renderDetails()}
      </Cell>
      {paid && <Tag>{i18n('customLabelDays.paid')}</Tag>}
    </SpacedRow>
  )
}

CustomLeaveDay.defaultProps = {
  employee: null,
  year: null,
  showReferenceYearEnd: false,
}

CustomLeaveDay.propTypes = {
  employee: PropTypes.object,
  leaveDay: PropTypes.object.isRequired,
  showReferenceYearEnd: PropTypes.bool,
  year: PropTypes.string,
}

export default memo(CustomLeaveDay)

import { useMemo, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { WorkflowsByCursorQuery, WorkflowsByCursorQueryData } from './GraphQL'

type Props = {
  paging: CursorPage
  filter?: Gateway.WorkflowsByCursorFilterInput
  sorting: [Gateway.WorkflowSortingInput]
}

export function useWorkflowsByCursor({ paging, filter, sorting }: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, refetch, loading } = useApolloQuery<
    WorkflowsByCursorQueryData,
    Gateway.QueryWorkflowsByCursorArgs
  >(WorkflowsByCursorQuery, {
    fetchPolicy: 'network-only',
    variables: {
      filter,
      paging: cursorPageToGraphqlPaging(paging),
      sorting,
    },

    onCompleted(data) {
      const newPageMeta = data.workflowsByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const workflows = useMemo(
    () => data?.workflowsByCursor.edges.map(edge => edge.node) ?? [],
    [data],
  )

  return {
    workflows,
    loading,
    currentPageMeta,
    refetch,
  }
}

import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { omit, pick } from '@styled-system/props'
import { themeGet } from '@styled-system/theme-get'

import { either, when } from 'components/ui/__v3__/__utils__/styled-system'

const SwitchCheckbox = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: var(--width);
  height: var(--height);
  background-color: ${either(
    'disabled',
    themeGet('switch.bg.disabled'),
    themeGet('switch.bg.default'),
  )};
  border-radius: var(--radius);
  border: 1px solid ${themeGet('switch.border.default')};

  transition: all 0.17s ease-in-out;

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translate(var(--offset), -50%);
    width: var(--toggle-size);
    height: var(--toggle-size);
    background-color: ${either(
      'disabled',
      themeGet('switch.icon.bg.disabled'),
      themeGet('switch.icon.bg.default'),
    )};
    border-radius: 50%;

    transition: all 0.17s ease-in-out;
  }
`

const HiddenCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  opacity: 0;
  width: var(--width);
  height: var(--height);

  &:checked ~ ${SwitchCheckbox} {
    background-color: ${either(
      'disabled',
      themeGet('switch.bg.disabled'),
      themeGet('switch.bg.checked.primary'),
    )};
    border-color: ${either(
      'disabled',
      themeGet('switch.border.disabled'),
      themeGet('switch.border.checked'),
    )};

    &:after {
      --transform: calc(var(--width) - var(--toggle-size) - var(--offset));
      transform: translate(var(--transform), -50%);
    }
  }

  ${when(
    'disabled',
    css`
      pointer-events: none;
    `,
  )};
`

const SwitchContainer = styled.label.attrs(props => ({
  width: props.width,
  height: props.height,
}))`
  --width: ${props => props.width}px;
  --height: ${props => props.height}px;
  --radius: var(--height);
  --toggle-size: calc(var(--height) / 1.5);
  --offset: calc(var(--height) / 5);

  display: inline-block;
  position: relative;
  cursor: ${either('disabled', 'not-allowed', 'pointer')};
  width: var(--width);
  height: var(--height);
  ${space};
`

/**
 * @deprecated use components/ui/__v3__/Switch instead
 */
const Switch = props => {
  const { disabled, width, height, className, ...rest } = props

  return (
    <SwitchContainer
      className={className}
      disabled={disabled}
      height={height}
      width={width}
      {...pick(rest)}
    >
      <HiddenCheckbox disabled={disabled} {...omit(rest)} />
      <SwitchCheckbox disabled={disabled} />
    </SwitchContainer>
  )
}

Switch.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
}

Switch.defaultProps = {
  className: undefined,
  disabled: false,
  height: 24,
  width: 46,
}

export default Switch

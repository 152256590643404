import { DateTime } from 'luxon'

import { PAGE_SIZE } from 'constants/pagination'
import { APP_TYPE, FEATURE } from 'constants/timeAndAttendance'
import { takeGeolocation } from 'constants/timeClock'

import { toISOString } from 'helpers/date'
import { getCurrentEmployee } from 'helpers/employee'
import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getSchedule } from 'store/selectors/employeeApp/timeClock'
import { getBranchId, getViewer } from 'store/selectors/viewer'

export const INIT = 'timeClock/INIT'
export const INIT_DONE = 'timeClock/INIT_DONE'

export const CLOCK_IN = 'timeClock/CLOCK_IN'
export const CLOCK_OUT = 'timeClock/CLOCK_OUT'

export const RESTORE_TRACKING = 'timeClock/RESTORE_TRACKING'

export const SET_SCHEDULE_ID = 'timeClock/SET_SCHEDULE_ID'
export const SET_TIME = 'timeClock/SET_TIME'
export const SET_TIMING = 'timeClock/SET_TIMING'

export const SET_PAUSE_TIMER_ID = 'timeClock/SET_PAUSE_TIMER_ID'

export const TOGGLE_TAB = 'timeClock/TOGGLE_TAB'

export const START_PAUSE_TIMER = createAsyncAction(
  'timeClock/START_PAUSE_TIMER',
)
export const STOP_PAUSE_TIMER = createAsyncAction('timeClock/STOP_PAUSE_TIMER')

export const LOAD_SCHEDULES = createAsyncAction('timeClock/LOAD_SCHEDULES')
export const LOAD_ACTIVE_TIMER = createAsyncAction(
  'timeClock/LOAD_ACTIVE_TIMER',
)

export const START_TIMER = createAsyncAction('timeClock/START_TIMER')
export const STOP_TIMER = createAsyncAction('timeClock/STOP_TIMER')

export const init = () => ({ type: INIT })
export const initDone = () => ({ type: INIT_DONE })

export const setScheduleId = scheduleId => ({
  type: SET_SCHEDULE_ID,
  payload: { scheduleId },
})

const schedulesInclude = [
  'shiftsJob.shift.branch',
  'shiftsJob.shift.pauses',
  'shiftsJob.job',
  'shiftsJob.job.department',
  'timeEntry',
  'jobsEmployee',
  'jobsEmployee.companySettings',
  'shift.timeBucket',
  'shift.timeBucket.timeBucketParent',
]

export const loadSchedules = () => (dispatch, getState) => {
  const state = getState()
  const viewer = getViewer(state)
  const branchId = getBranchId(state)
  const employee = getCurrentEmployee(viewer.employees, branchId)

  const employeeId = employee.id

  const fromDate = DateTime.now().minus({ days: 7 }).startOf('day').toJSDate()
  const toDate = DateTime.now().plus({ days: 1 }).endOf('day').toJSDate()

  dispatch(
    apiCall({
      endpoint: `/employees/${employeeId}/relationships/schedules`,
      query: {
        include: schedulesInclude.join(),
        filter: {
          shifts_start_at: fromDate,
          shifts_finish_at: toDate,
        },
        page: { size: PAGE_SIZE['50'] },
      },
      types: LOAD_SCHEDULES,
      paged: true,
    }),
  )
}

export const loadActiveTimer = () =>
  apiCall({
    endpoint: '/user/relationships/timer',
    query: {
      include: 'schedule.shifts_job.shift.pauses,pauseTimers.pause',
    },
    types: LOAD_ACTIVE_TIMER,
  })

export const startTimer = ({ scheduleId, startAt }) => (dispatch, getState) => {
  const schedule = getSchedule(getState())

  const isEnableClockInGeolocationFromDesktop =
    schedule?.jobsEmployee?.companySetting?.attendanceSettings?.[
      FEATURE.GEOLOCATION
    ]?.[APP_TYPE.DESKTOP] ?? false

  const startTimerCall = (location = {}) =>
    dispatch(
      apiCall({
        endpoint: `/schedules/${scheduleId}/timer`,
        method: 'POST',
        query: {
          data: {
            attributes: { ...location, startAt },
          },
          include: 'schedule.shifts_job.shift.pauses,pauseTimers.pause',
        },
        types: START_TIMER,
      }),
    )

  if (isEnableClockInGeolocationFromDesktop) {
    takeGeolocation().then(
      // if result - start timer with location coords
      result => {
        const {
          coords: { latitude, longitude }, // always returned
        } = result
        startTimerCall({ location: { lat: latitude, lng: longitude } })
      },
      // else - start timer without location coords
      error => startTimerCall(), // error.message if need to show it
    )
  } else {
    startTimerCall()
  }
}

export const stopTimer = ({ scheduleId }) => (dispatch, getState) => {
  const schedule = getSchedule(getState())

  const isEnableClockInGeolocationFromDesktop =
    schedule?.jobsEmployee?.companySetting?.attendanceSettings?.[
      FEATURE.GEOLOCATION
    ]?.[APP_TYPE.DESKTOP] ?? false

  const stopTimerCall = (locationQuery = {}) => {
    return dispatch(
      apiCall({
        endpoint: `/schedules/${scheduleId}/timer`,
        method: 'DELETE',
        ...locationQuery,
        types: STOP_TIMER,
        payload: {
          relationId: scheduleId,
        },
      }),
    )
  }

  if (isEnableClockInGeolocationFromDesktop) {
    return takeGeolocation().then(
      // if result - stop timer with location coords
      result => {
        const {
          coords: { latitude, longitude }, // always returned
        } = result
        const locationQuery = {
          query: {
            data: {
              attributes: { location: { lat: latitude, lng: longitude } },
              include: 'schedule',
            },
          },
        }
        stopTimerCall(locationQuery)
      },
      // else - stop timer without location coords
      error => stopTimerCall(), // error.message if need to show it
    )
  }

  return stopTimerCall()
}

export const setTime = time => ({
  type: SET_TIME,
  payload: { time },
})

export const setTimings = (startTime, endTime) => ({
  type: SET_TIMING,
  payload: { startTime, endTime },
})

export const clockIn = () => ({ type: CLOCK_IN })

export const clockOut = () => ({ type: CLOCK_OUT })

export const restoreTracking = () => ({ type: RESTORE_TRACKING })

export const setPauseTimerId = pauseTimerId => ({
  type: SET_PAUSE_TIMER_ID,
  payload: { pauseTimerId },
})

export const startPauseTimer = (timerId, pauseId) =>
  apiCall({
    endpoint: `/time_entries/${timerId}/pause`,
    method: 'POST',
    query: {
      data: {
        type: 'pauseTimers',
        attributes: {
          start_at: toISOString(),
        },
        relationships: {
          pause: {
            data: {
              type: 'pause',
              id: pauseId,
            },
          },
        },
      },
      include: 'timeEntry,pause',
    },
    types: START_PAUSE_TIMER,
  })

export const stopPauseTimer = timerId =>
  apiCall({
    endpoint: `/time_entries/${timerId}/pause`,
    method: 'DELETE',
    types: STOP_PAUSE_TIMER,
  })

export const toggleTab = () => ({
  type: TOGGLE_TAB,
})

import { createFields, createReducerHandlers } from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { CREATE_TIME_ENTRY } from 'store/actions/employees/schedules'
import {
  CLEAR,
  CLEAR_INTERSECTIONS,
  CLEAR_TASKS,
  CREATE,
  LOAD,
  LOAD_EMPLOYEES,
  LOAD_EMPLOYEES_SILENT,
  LOAD_INTERSECTIONS,
  LOAD_MORE_EMPLOYEES,
  LOAD_TASKS,
} from 'store/actions/employees/timeCards'

const initialState = Immutable({
  ...createFields('timeEntriesEmployeeSummary', 'summary'),
  ...createFields('timeEntries'),

  ...createFields('timeEntries', 'taskTimeEntries'),

  ...createFields('timeEntries', 'intersections'),
})

const handlers = {
  ...createReducerHandlers('timeEntriesEmployeeSummary', LOAD_EMPLOYEES, {
    withReplace: true,
    mapToKey: 'summary',
  }),

  ...createReducerHandlers(
    'timeEntriesEmployeeSummary',
    LOAD_EMPLOYEES_SILENT,
    {
      withReplace: true,
      withLoading: false,
      mapToKey: 'summary',
    },
  ),

  ...createReducerHandlers('timeEntriesEmployeeSummary', LOAD_MORE_EMPLOYEES, {
    withReplace: false,
    withLoading: false,
    mapToKey: 'summary',
  }),

  ...createReducerHandlers('timeEntries', LOAD_TASKS, {
    withReplace: true,
    mapToKey: 'taskTimeEntries',
  }),

  ...createReducerHandlers('timeEntries', LOAD, { withReplace: true }),

  ...createReducerHandlers('timeEntries', CREATE),

  ...createReducerHandlers('timeEntries', CREATE_TIME_ENTRY),

  ...createReducerHandlers('timeEntries', LOAD_INTERSECTIONS, {
    mapToKey: 'intersections',
    withReplace: true,
  }),

  [CLEAR_INTERSECTIONS]: state =>
    state.merge({
      intersections: null,
      isLoadingIntersections: false,
      isLoadedIntersections: false,
    }),

  [CLEAR_TASKS]: state =>
    state.merge({
      taskTimeEntries: [],
    }),

  [CLEAR]: () => initialState,

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

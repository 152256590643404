import { createFields, createReducerHandlers } from 'rapidux'
import { createDeleteHandler, createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import get from 'lodash/get'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  CLEAR_MANAGER,
  LOAD_BRANCH_MANAGERS,
  LOAD_MANAGER,
} from 'store/actions/company/managers'
import { REMOVE_USER_FROM_COMPANY } from 'store/actions/employees/employees'

const initialState = Immutable({
  managers: [],
  branchManagers: [],
  isLoading: false,
  isLoaded: false,

  ...createFields('managers', 'manager', true),
})

const handlers = {
  [LOAD_BRANCH_MANAGERS.REQUEST]: state => state.merge({ isLoading: true }),
  [LOAD_BRANCH_MANAGERS.SUCCESS]: createLoadHandler('managers', {
    mapToKey: 'branchManagers',
    addToState: {
      isLoading: false,
      isLoaded: true,
    },
  }),

  ...createReducerHandlers('managers', LOAD_MANAGER, {
    mapToKey: 'manager',
    withReplace: true,
    singular: true,
  }),

  [REMOVE_USER_FROM_COMPANY.SUCCESS]: (state, action) => {
    const result = createDeleteHandler('managers')(state, action)

    const employees = get(result, 'managers')
    const employee = get(result, 'manager')

    return state.merge({
      employees,
      employee,
    })
  },

  [CLEAR_MANAGER]: state =>
    state.merge({
      manager: null,
      isLoadingManager: false,
      isLoadedManager: false,
    }),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import React from 'react'

import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout, space } from 'styled-system'

import { Spinner } from 'components/ui/Spinner'

import { BaseButton, BaseButtonProps } from './BaseButton'

const StyledButton = styled(BaseButton)`
  min-width: ${props => props.width ?? 140}px;

  height: ${mapToTheme('buttons.height')}px;
  box-sizing: border-box;
  padding: 8px;

  background-color: ${mapToTheme('buttons.backgroundColor')};
  color: ${mapToTheme('buttons.color')};

  &:hover {
    background-color: ${mapToTheme('buttons.hover.backgroundColor')};
  }

  &:active {
    background-color: ${mapToTheme('buttons.active.backgroundColor')};
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    background-color: ${mapToTheme('buttons.disabled.backgroundColor')};
  }

  ${layout}
  ${space};
`

export type ButtonProps = BaseButtonProps & {
  loading?: boolean
}

export const Button = ({ children, loading, ...rest }: ButtonProps) => {
  if (loading) {
    // TODO: Move Spinner to V3
    return (
      <StyledButton {...rest} disabled>
        <Spinner size={22} />
      </StyledButton>
    )
  }

  return <StyledButton {...rest}>{children}</StyledButton>
}

import React, { useCallback } from 'react'
import { FieldValues, useController } from 'react-hook-form'

import map from 'lodash/map'

import RolesSelector from 'components/blocks/__v2__/RolesSelector'
import { RoleSelectorProps } from 'components/blocks/__v2__/RolesSelector/types'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'
import { LabelProps } from 'components/ui/__v3__/Input/types'

type ReturnParameter = Parameters<RoleSelectorProps['setRolesToSubmit']>[0][0]
type SpecificProps = Omit<RoleSelectorProps, 'setRolesToSubmit'> & {
  returnMapper?: (arg: ReturnParameter) => any
}
type Props<T extends FieldValues> = ControllerBaseProps<T> &
  LabelProps &
  SpecificProps

export function FormRoleSelector<T extends FieldValues>({
  control,
  name,
  labelText,
  required,
  returnMapper = item => item,
  ...rest
}: Props<T>) {
  const {
    field: fieldProps,
    fieldState: { error },
    formState: { isSubmitSuccessful },
  } = useController({
    control,
    name,
  })

  const { onChange, value } = fieldProps

  const onUpdateRolesToSubmit = useCallback(
    (combinations: ReturnParameter[]) =>
      onChange(map(combinations, returnMapper)),
    [onChange, returnMapper],
  )

  return (
    <>
      <InputLabel>
        <LabelText labelText={labelText} required={required} />
      </InputLabel>
      <RolesSelector
        {...rest}
        isSubmitSuccessful={isSubmitSuccessful && !value.length}
        setRolesToSubmit={onUpdateRolesToSubmit}
      />
      {error && <Error error={error} />}
    </>
  )
}

import styled from 'styled-components'

import glyph from 'assets/images/svg/wa-logo-text.svg'

import { Icon } from '../Icon'

const WaLogoTextIcon = styled(Icon).attrs({
  glyph,
})``

export default WaLogoTextIcon

import { useCallback, useEffect, useState } from 'react'

import { i18n } from 'i18n'

import coreApiCall from 'services/API/coreApi'
import { showToast } from 'services/Toasts'

import { AutoAssignQueryResult, AutoAssignStatus } from './types'

const POOLING_INTERVAL = 5000

export function useAutoAssignStatus(id: string | null) {
  const [status, setStatus] = useState<AutoAssignStatus>(
    AutoAssignStatus.Pending,
  )
  const [taskId, setTaskId] = useState<string | null>(id)

  const startQuery = (id: string) => {
    setTaskId(id)
    setStatus(AutoAssignStatus.InProgress)
  }

  const fetchStatus = useCallback(async (id: string) => {
    const response = await coreApiCall({
      endpoint: `/auto_scheduling_tasks/${id}`,
      method: 'GET',
    })
    if (!response?.ok) {
      showToast({
        type: 'error',
        title: i18n('autoAssign.title'),
        content: i18n('autoAssign.actions.fetchStatus.error'),
      })
    } else {
      const responseObject = response?.payload?.data?.autoSchedulingTasks
      // @ts-ignore
      const responseData = Object.values(responseObject)?.[0]?.attributes
      return responseData as AutoAssignQueryResult
    }
    return null
  }, [])

  const reset = useCallback(() => {
    setTaskId(null)
    setStatus(AutoAssignStatus.Pending)
  }, [])

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined
    if (taskId) {
      if (status === AutoAssignStatus.InProgress) {
        interval = setInterval(async () => {
          const response = await fetchStatus(taskId)
          const { state, failureReason, result } = response || {}

          if (state === AutoAssignStatus.Finished) {
            setStatus(AutoAssignStatus.Finished)
            interval && clearInterval(interval)
            // once state is finished, result will be either 'success', or 'failure' with a failureReason
            result === 'failure'
              ? showToast({
                  type: 'error',
                  title: i18n('autoAssign.title'),
                  content: failureReason,
                })
              : showToast({
                  type: 'success',
                  title: i18n('autoAssign.title'),
                  content: i18n('alertMessages.default.success.message'),
                })
          }
        }, POOLING_INTERVAL)
      } else if (interval) {
        clearInterval(interval)
      }
    }

    return () => interval && clearInterval(interval)
  }, [taskId, status, fetchStatus])

  return {
    status,
    reset,
    startQuery,
  }
}

import { getIsEnabledGeolocation } from 'constants/timeAndAttendance'
import { takeGeolocation } from 'constants/timeClock'

import { toISOString } from 'helpers/date'
import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import {
  getSelectedJobData,
  getSelectedTimeBucket,
} from 'store/selectors/employeeApp/openClock'

export const INIT = 'openClock/INIT'
export const CHANGE_NOTE = 'openClock/CHANGE_NOTE'
export const CHANGE_TIME_BUCKET = 'openClock/CHANGE_TIME_BUCKET'
export const CLOCK_IN = 'openClock/CLOCK_IN'
export const CLOCK_OUT = 'openClock/CLOCK_OUT'
export const RESET_INTERVAL_TIME = 'openClock/RESET_INTERVAL_TIME'
export const RESTORE_TRACKING = 'openClock/RESTORE_TRACKING'
export const SET_CLOCKING_TIME = 'openClock/SET_CLOCKING_TIME'
export const SET_TIMING = 'openClock/SET_TIMING'
export const SELECT_JOB = 'openClock/SELECT_JOB'
export const START_TIMER = createAsyncAction('openClock/START_TIMER')
export const STOP_TIMER = createAsyncAction('openClock/STOP_TIMER')
export const LOAD_ACTIVE_TIMER = createAsyncAction(
  'openClock/LOAD_ACTIVE_TIMER',
)
export const START_PAUSE_TIMER = createAsyncAction(
  'openClock/START_PAUSE_TIMER',
)
export const STOP_PAUSE_TIMER = createAsyncAction('openClock/STOP_PAUSE_TIMER')
export const SET_PAUSE_TIMER_ID = 'openClock/SET_PAUSE_TIMER_ID'

export const init = () => ({
  type: INIT,
})

export const selectJob = (jobData, { emergency = false } = {}) => ({
  type: SELECT_JOB,
  payload: {
    jobData,
    emergency,
  },
})

export const changeNote = note => ({
  type: CHANGE_NOTE,
  payload: { note },
})

export const changeTimeBucket = selectedTimeBucket => {
  return {
    type: CHANGE_TIME_BUCKET,
    payload: { selectedTimeBucket },
  }
}

export const clockIn = ({ emergency = false } = {}) => ({
  type: CLOCK_IN,
  payload: { emergency },
})

export const clockOut = () => ({ type: CLOCK_OUT })

export const setClockingTime = time => ({
  type: SET_CLOCKING_TIME,
  payload: { time },
})

export const resetIntervalTime = () => ({ type: RESET_INTERVAL_TIME })

export const setTimings = (startTime, endTime) => ({
  type: SET_TIMING,
  payload: { startTime, endTime },
})

export const restoreTracking = () => ({ type: RESTORE_TRACKING })

export const startTimer = ({ note = null, emergency = false } = {}) => (
  dispatch,
  getState,
) => {
  const selectedJobData = getSelectedJobData(getState())
  const isEnabledGeolocation = getIsEnabledGeolocation(selectedJobData)
  const selectedTimeBucket = getSelectedTimeBucket(getState())

  function startTimerCall(location = {}) {
    return dispatch(
      apiCall({
        endpoint: '/timer',
        method: 'POST',
        types: START_TIMER,
        query: {
          data: {
            attributes: {
              ...location,
              ...(note ? { note } : {}),
              emergency,
              ...(selectedTimeBucket
                ? { timeBucketId: selectedTimeBucket.value }
                : {}),
            },
            relationships: {
              job: {
                data: {
                  id: selectedJobData.id,
                  type: 'job',
                },
              },
              department: {
                data: {
                  id: selectedJobData?.departmentId,
                  type: 'department',
                },
              },
              branch: {
                data: {
                  id: selectedJobData?.branch?.id,
                  type: 'branch',
                },
              },
            },
          },
          include:
            'pauseTimers,pauseTimers.pause,notes,job,timeBucket,timeBucket.timeBucketParent',
        },
        payload: { emergency },
      }),
    )
  }

  if (isEnabledGeolocation) {
    takeGeolocation().then(
      // if result - start timer with location coords
      result => {
        const { coords } = result
        const { latitude, longitude } = coords // always returned

        startTimerCall({
          location: {
            lat: latitude,
            lng: longitude,
          },
        })
      },
    )
  } else {
    startTimerCall()
  }
}

export const stopTimer = ({ note = null }) => (dispatch, getState) => {
  const selectedJobData = getSelectedJobData(getState())
  const isEnabledGeolocation = getIsEnabledGeolocation(selectedJobData)

  function stopTimerCall(location = {}) {
    return dispatch(
      apiCall({
        endpoint: '/timer',
        method: 'DELETE',
        query: {
          data: {
            attributes: {
              ...location,
              ...(note ? { note } : {}),
            },
          },
          include: 'pauseTimers,pauseTimers.pause,notes,job',
        },
        types: STOP_TIMER,
      }),
    )
  }

  if (isEnabledGeolocation) {
    takeGeolocation().then(
      // if result - stop timer with location coords
      result => {
        const { coords } = result
        const { latitude, longitude } = coords // always returned

        stopTimerCall({
          location: {
            lat: latitude,
            lng: longitude,
          },
        })
      },
      // else - stop timer without location coords
      error => stopTimerCall(), // error.message if need to show it
    )
  } else {
    stopTimerCall()
  }
}

export const loadActiveTimer = () =>
  apiCall({
    endpoint: '/user/relationships/timer',
    query: {
      include:
        'pauseTimers,pauseTimers.pause,notes,job.departments.branches,timeBucket.timeBucketParent',
    },
    types: LOAD_ACTIVE_TIMER,
  })

export const startPauseTimer = (timerId, note = null) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/time_entries/${timerId}/pause`,
      method: 'POST',
      query: {
        data: {
          type: 'pauseTimers',
          attributes: {
            start_at: toISOString(),
            ...(note ? { note } : {}),
          },
        },
        include: 'timeEntry,pause,notes',
      },
      types: START_PAUSE_TIMER,
    }),
  )
}

export const stopPauseTimer = (timerId, note = null) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/time_entries/${timerId}/pause`,
      method: 'DELETE',
      query: {
        data: {
          attributes: {
            ...(note ? { note } : {}),
          },
        },
        include: 'timeEntry,pause,notes',
      },
      types: STOP_PAUSE_TIMER,
    }),
  )
}

import { useModifyShiftJobAssignments } from './useModifyShiftJobAssignments'
import { useShiftJobsByCursor } from './useShiftJobsByCursor'
import { useShiftJobsByCursorWithSchedules } from './useShiftJobsByCursorWithSchedules'
import { useUpdateShiftJobs } from './useUpdateShiftJobs'

export * from './types'

export const ShiftJobsAPI = {
  byShiftIdByCursor: useShiftJobsByCursor,
  byShiftIdByCursorWithSchedules: useShiftJobsByCursorWithSchedules,
  modifyAssignments: useModifyShiftJobAssignments,

  updateShiftJobs: useUpdateShiftJobs,
}

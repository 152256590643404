import { useClassifiersByCursor } from './useClassifiersByCursor'
import { useClassifiersByOffset } from './useClassifiersByOffset'
import { useCreateClassifier } from './useCreateClassifier'
import { useDeleteClassifier } from './useDeleteClassifier'
import { usePickerClassifier } from './usePickerClassifier'
import { useUpdateClassifier } from './useUpdateClassifier'

export const ClassifiersAPI = {
  getByOffset: useClassifiersByOffset,
  byCursor: useClassifiersByCursor,
  create: useCreateClassifier,
  update: useUpdateClassifier,
  delete: useDeleteClassifier,
  picker: usePickerClassifier,
}

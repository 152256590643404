import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import differenceBy from 'lodash/differenceBy'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import noop from 'lodash/noop'

import BatchSelect from 'components/blocks/__v2__/BatchSelect'
import { Select } from 'components/ui/__v3__/Select'

import { BATCH_LOAD_MORE_SIZE } from 'constants/areas'

import { formatOptionLabel } from 'helpers/areas'
import { createDebounce } from 'helpers/debounce'
import { denormalize } from 'helpers/redux'

import { useAppContext } from 'hooks'

import _ from 'i18n'

import { showError } from 'services/API'
import { jobToOption } from 'services/Options'

import { JOBS_TYPE } from 'store/reducers/areas'

function JobsPicker({
  cacheOptions,
  display,
  exclude,
  include,
  initial,
  isDisabled,
  loadingText,
  placeholder,
  preload,
  value,
  onClear,
  onLoad,
  onInit,
  withBatchSelect,
  ...rest
}) {
  const { company } = useAppContext()
  const isEtfo = company.identity.Etfo

  const [input, setInput] = useState('')

  const denormalizeJobsFromResult = useCallback(
    result => {
      const type = JOBS_TYPE

      const data = result?.payload?.data
      const jobIds = map(data?.[type], 'id')
      const options = isEmpty(jobIds) ? [] : denormalize(data, type, jobIds)

      if (isEtfo) {
        options.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
      }

      return options
    },
    [isEtfo],
  )

  // TODO: send excludes in backend filters
  const filterJobs = useCallback(
    jobs => {
      let entities = jobs

      if (!isEmpty(exclude)) {
        const values = map(exclude, id => ({ id }))
        entities = differenceBy(entities, values, 'id')
      }

      return map(entities || [], jobToOption)
    },
    [exclude],
  )

  const defaultOptions = useMemo(() => filterJobs(initial.entities), [
    initial.entities,
    filterJobs,
  ])

  const locationIds = useMemo(() => {
    if (isEmpty(rest.locationIds)) return null

    return isArray(rest.locationIds) ? rest.locationIds : [rest.locationIds]
  }, [rest.locationIds])

  const departmentIds = useMemo(() => {
    if (isEmpty(rest.departmentIds)) return null

    return isArray(rest.departmentIds)
      ? rest.departmentIds
      : [rest.departmentIds]
  }, [rest.departmentIds])

  const initOptions = useCallback(() => {
    if (!initial.isLoaded) {
      onInit({
        locationIds,
        departmentIds,
        display,
        isEtfo,
      })
    }
  }, [initial.isLoaded, onInit, locationIds, departmentIds, display, isEtfo])

  const prevLocationIds = useRef(locationIds)
  const prevDepartmentIds = useRef(departmentIds)

  useEffect(() => {
    if (
      !isEqual(prevLocationIds.current, locationIds) ||
      !isEqual(prevDepartmentIds.current, departmentIds)
    ) {
      onClear()
      prevLocationIds.current = locationIds
      prevDepartmentIds.current = departmentIds
    }
  }, [locationIds, departmentIds, onClear])

  useEffect(() => {
    if (!isDisabled && preload && !initial.isLoaded) {
      initOptions()
    }
  }, [initial.isLoaded, preload, isDisabled, initOptions])

  const handleLoadOptions = useMemo(() => {
    return createDebounce(
      (inputValue, callback) => {
        if (!inputValue) {
          callback([])
        } else {
          onLoad({
            departmentIds,
            display,
            locationIds,
            include,
            name: inputValue,
            isEtfo,
          }).then(result => {
            if (!result?.ok) {
              showError(result)
              callback([])
              return
            }
            const jobs = denormalizeJobsFromResult(result)
            callback(filterJobs(jobs))
          })
        }
      },
      { leading: true, trailing: true },
    )
  }, [
    onLoad,
    departmentIds,
    display,
    locationIds,
    include,
    isEtfo,
    denormalizeJobsFromResult,
    filterJobs,
  ])

  const handleSelectFilteredJobs = useCallback(() => {
    onLoad({
      display,
      locationIds,
      departmentIds,
      include,
      name: input,
      size: BATCH_LOAD_MORE_SIZE,
      isEtfo,
    }).then(result => {
      if (!result?.ok) {
        showError(result)
        return
      }
      const jobs = denormalizeJobsFromResult(result)
      const filtered = filterJobs(jobs)

      rest.onChange(filtered)

      setInput('')
    })
  }, [
    onLoad,
    display,
    locationIds,
    departmentIds,
    include,
    input,
    isEtfo,
    denormalizeJobsFromResult,
    filterJobs,
    rest,
  ])

  const renderLoadingMessage = useCallback(
    () => loadingText ?? `${_('common.loading')}...`,
    [loadingText],
  )

  const handleInputChange = useCallback(
    (inputValue, action) => {
      if (action.action === 'input-change' || action.action === 'set-value')
        setInput(inputValue)
    },
    [setInput],
  )

  const pickerDisabled = isDisabled
  const isSelectAllButtonDisabled =
    !isEmpty(value) || pickerDisabled || initial.isLoading
  const hasBatchSelect = withBatchSelect && rest.isMulti

  return (
    <>
      <Select
        async
        cacheOptions={cacheOptions}
        closeMenuOnSelect={!rest.isMulti}
        defaultOptions={defaultOptions}
        formatOptionLabel={formatOptionLabel('job')}
        isDisabled={pickerDisabled}
        isLoading={initial.isLoading}
        isSearchable
        loadOptions={handleLoadOptions}
        loadingMessage={renderLoadingMessage}
        placeholder={placeholder}
        value={value}
        withBatchSelect={hasBatchSelect}
        withPortal
        onInputChange={handleInputChange}
        onMenuOpen={initOptions}
        {...rest}
      />

      {hasBatchSelect && (
        <BatchSelect
          handleSelect={handleSelectFilteredJobs}
          hasInput={!!input}
          isDisabled={isSelectAllButtonDisabled}
          size={BATCH_LOAD_MORE_SIZE}
        />
      )}
    </>
  )
}

JobsPicker.defaultProps = {
  cacheOptions: true,
  display: null,
  departmentIds: null,
  exclude: [],
  include: null,
  initial: {},
  isDisabled: false,
  loadingText: null,
  locationIds: null,
  placeholder: null,
  preload: false,
  value: null,
  withBatchSelect: true,
  withPortal: true,
  onClear: noop,
  onInit: noop,
  onLoad: noop,
}

JobsPicker.propTypes = {
  ...Select.propTypes,
  cacheOptions: PropTypes.bool,
  departmentIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  display: PropTypes.string,
  exclude: PropTypes.array,
  include: PropTypes.string,
  initial: PropTypes.object,
  isDisabled: PropTypes.bool,
  loadingText: PropTypes.string,
  locationIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  placeholder: PropTypes.string,
  preload: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  withBatchSelect: PropTypes.bool,
  withPortal: PropTypes.bool,
  onClear: PropTypes.func,
  onInit: PropTypes.func,
  onLoad: PropTypes.func,
}

export default JobsPicker

import React, { useCallback, useMemo } from 'react'

import { API } from 'API'

import { isEmpty } from 'lodash'

import { Select } from 'components/ui/__v3__/Select'

import { createpDebounce } from 'helpers/debounce'

import { i18n } from 'i18n'

import Utils from 'services/Utils'

import { generateClassifierDefaultOptions } from './helpers'
import { ClassifierOptionType, ClassifiersPickerProps } from './types'

import { TextOverflowWithPopover } from '../TextOverflowWithPopover'

const SEARCH_DEBOUNCE_TIMEOUT = 350

export function ClassifiersPicker({
  isDisabled,
  placeholder,
  loadingText,
  excludeClassifiers,
  ...rest
}: ClassifiersPickerProps) {
  const {
    loadClassifiers,
    classifiers,
    loading,
    refetch,
  } = API.Classifiers.picker()

  const defaultOptions = useMemo(() => {
    if (classifiers) {
      const options = generateClassifierDefaultOptions(
        classifiers,
        excludeClassifiers,
      )

      return options
    }

    return []
  }, [classifiers, excludeClassifiers])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoadOptions = useCallback(
    createpDebounce(
      async (inputValue: string, callback: Function) => {
        const refetchedData = await refetch(inputValue)

        const newOptions = !isEmpty(refetchedData)
          ? generateClassifierDefaultOptions(refetchedData, excludeClassifiers)
          : []

        callback(newOptions)
      },
      undefined,
      SEARCH_DEBOUNCE_TIMEOUT,
    ),
    [refetch],
  )

  // NOTE: to refresh the default values
  const handleInputChange = (
    inputValue: string,
    action: { action: string },
  ) => {
    if (action.action !== 'input-change' || inputValue) return

    refetch()
  }

  const renderLoadingMessage = () => {
    return loadingText ?? `${i18n('common.loading')}...`
  }

  const formatClassifierOptionLabel = (option: ClassifierOptionType) => {
    const { classifier } = option

    let classifierInfoString = classifier.name
    const stringCondition = Utils.Classifier.getClassifierConditionsBySource({
      classifier,
      returnAsString: true,
    }) as string

    if (stringCondition) {
      classifierInfoString = `${classifierInfoString}: ${stringCondition}`
    }

    return (
      <TextOverflowWithPopover maxWidth="250px">
        {classifierInfoString}
      </TextOverflowWithPopover>
    )
  }

  return (
    <Select
      // @ts-ignore
      async
      defaultOptions={defaultOptions}
      formatOptionLabel={formatClassifierOptionLabel}
      isDisabled={isDisabled}
      isLoading={loading}
      loadOptions={handleLoadOptions}
      loadingMessage={renderLoadingMessage}
      placeholder={placeholder}
      withPortal
      onInputChange={handleInputChange}
      onMenuOpen={loadClassifiers}
      {...rest}
    />
  )
}

import { useApolloQuery } from 'API/services/Apollo'

import { scheduleFilterToAPI } from '../adapters'
import { SchedulesCountQuery } from '../GraphQL'
import { ScheduleFilter } from '../types'

const POLL_INTERVAL = 2 * 60 * 1000

export function useSchedulesCount(filter: ScheduleFilter) {
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'schedulesCount'>,
    Gateway.QuerySchedulesCountArgs
  >(SchedulesCountQuery, {
    variables: {
      filter: scheduleFilterToAPI(filter),
    },
    pollInterval: POLL_INTERVAL,
  })

  const schedulesCount = data?.schedulesCount

  return {
    schedulesCount,
    loading,
    refetch,
  }
}

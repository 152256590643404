import { all, fork, put, take } from 'redux-saga/effects'

import { loadSuggestedEmployees } from 'store/actions/employeeApp/findCover'
import { SELECT_SCHEDULE } from 'store/actions/employeeApp/schedules'

export function* loadSuggested() {
  while (true) {
    yield take(SELECT_SCHEDULE)

    yield put(loadSuggestedEmployees())
  }
}

export default function* rootFindCover() {
  yield all([fork(loadSuggested)])
}

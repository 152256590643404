import { useMemo } from 'react'

import { getStartOfWeek } from 'helpers/date'

import Utils from '../services/Utils'

const DEFAULT_BRANCH_SETTINGS: Pick<
  JsonApi.LocationSettings,
  'timezone' | 'workStartWeekDay'
> = {
  timezone: 'local',
  workStartWeekDay: 1,
}

export function useDisplayedWorkWeek({
  timezone,
  workStartWeekDay,
} = DEFAULT_BRANCH_SETTINGS) {
  const start = useMemo(() => {
    return getStartOfWeek({
      timezone,
      workweekStartDay: Utils.DateTime.mapToLuxonWeekDay(workStartWeekDay),
    })
  }, [timezone, workStartWeekDay])

  const end = useMemo(() => start.plus({ days: 6 }), [start])

  return { start, end }
}

import isEmpty from 'lodash/isEmpty'

import { PAGE_SIZE } from 'constants/pagination'

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getBranchId } from 'store/selectors/viewer'

export const LOAD_DEPARTMENTS = createAsyncAction('departments/LOAD')
export const ADD_DEPARTMENT = createAsyncAction('departments/ADD')
export const DELETE_DEPARTMENT = createAsyncAction('departments/DELETE')
export const SAVE_DEPARTMENT = createAsyncAction('departments/SAVE')

// on week
export const CREATE_DEPARTMENT_BUDGET = createAsyncAction(
  'department/CREATE_DEPARTMENT_BUDGET',
)
export const UPDATE_DEPARTMENT_BUDGET = createAsyncAction(
  'department/UPDATE_DEPARTMENT_BUDGET',
)

// on day
export const CREATE_LABOR_COST = createAsyncAction(
  'department/CREATE_LABOR_COST',
)
export const DELETE_LABOR_COST = createAsyncAction(
  'department/DELETE_LABOR_COST',
)
export const CREATE_SALE_AMOUNT = createAsyncAction(
  'department/CREATE_SALE_AMOUNT',
)
export const DELETE_SALE_AMOUNT = createAsyncAction(
  'department/DELETE_SALE_AMOUNT',
)

export const loadDepartments = branchId => {
  return apiCall({
    endpoint: `/branches/${branchId}/relationships/departments`,
    types: LOAD_DEPARTMENTS,
    query: {
      include: 'jobs',
      page: { size: PAGE_SIZE['50'] },
    },
    paged: true,
  })
}

export const addDepartment = ({ locationId, name, exportCode, externalId }) => (
  dispatch,
  getState,
) => {
  const attributes = {
    name,
  }

  if (!isEmpty(exportCode)) {
    attributes.exportCode = exportCode
  }

  if (!isEmpty(externalId)) {
    attributes.externalId = externalId
  }

  return dispatch(
    apiCall({
      endpoint: `/branches/${
        locationId || getBranchId(getState())
      }/relationships/departments`,
      method: 'POST',
      query: {
        data: {
          type: 'departments',
          attributes,
        },
      },
      types: ADD_DEPARTMENT,
      payload: {
        relationId: locationId,
      },
    }),
  )
}

export const detachDepartmentFromLocation = ({ locationId, id }) => (
  dispatch,
  getState,
) =>
  dispatch(
    apiCall({
      endpoint: `/branches/${locationId}/relationships/departments`,
      method: 'DELETE',
      query: { data: [{ id, type: 'departments' }] },
      types: DELETE_DEPARTMENT,
      payload: {
        deletedId: id,
        relationId: locationId || getBranchId(getState()),
      },
    }),
  )

export const saveDepartment = (department, attributes) => {
  return apiCall({
    endpoint: `/departments/${department.id}`,
    method: 'PATCH',
    query: {
      data: {
        type: 'departments',
        attributes,
      },
    },
    types: SAVE_DEPARTMENT,
  })
}

import { useApolloClient } from '@apollo/client'
import { useApolloHeaders, useApolloLazyQuery } from 'API/services/Apollo'

import {
  SortingDirection,
  TimetypeSortingField,
} from 'constants/gatewayGraphQL'

import {
  PickerTimeTypesByOffsetQuery,
  PickerTimeTypesByOffsetQueryData,
} from './GraphQL'

export function usePickerTimeTypes() {
  const client = useApolloClient()

  const headers = useApolloHeaders()

  const [loadTimeTypes, { data, loading, refetch }] = useApolloLazyQuery<
    PickerTimeTypesByOffsetQueryData,
    Gateway.QueryTimetypesByOffsetArgs
  >(PickerTimeTypesByOffsetQuery, {
    variables: {
      paging: { limit: 999, offset: 0 },
      sorting: [
        {
          field: TimetypeSortingField.Name,
          direction: SortingDirection.Desc,
        },
      ],
    },
    fetchPolicy: 'network-only',
  })

  const timeTypes = data?.timetypesByOffset?.rows ?? []

  const loadTimeTypesPromise = async () => {
    const result = await client.query<
      PickerTimeTypesByOffsetQueryData,
      Gateway.QueryTimetypesByOffsetArgs
    >({
      context: {
        headers,
      },
      query: PickerTimeTypesByOffsetQuery,
      variables: {
        paging: { limit: 999, offset: 0 },
        sorting: [
          {
            field: TimetypeSortingField.Name,
            direction: SortingDirection.Desc,
          },
        ],
      },
      fetchPolicy: 'network-only',
    })

    return result.data?.timetypesByOffset?.rows ?? []
  }

  return {
    loadTimeTypes,
    loadTimeTypesPromise,
    timeTypes,
    fetching: loading,
    refetch,
  }
}

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import WarningPopover from 'components/blocks/__v2__/WarningPopover'
import { DayPicker } from 'components/ui/__v2__/DayPicker'
import { Flex } from 'components/ui/__v2__/Grid'
import { Spacer } from 'components/ui/__v2__/Spacer'

import { isoToJsDate } from 'helpers/date'

import { i18n } from 'i18n'

function EffectivePeriod({
  index,
  start,
  end,
  disabledWarningText,
  disableFuture,
  disablePast,
  minimalDays,
  onChange,
  isDisabled,
  isOverlap,
  ...period
}) {
  const handleChangeStart = useCallback(
    (day, { isFromDayValid: isStartDayValid }) => {
      return onChange(index, { start: day, end, isStartDayValid })
    },
    [end, index, onChange],
  )

  const handleChangeEnd = useCallback(
    (day, { isFromDayValid: isEndDayValid }) => {
      return onChange(index, { start, end: day, isEndDayValid })
    },
    [index, onChange, start],
  )

  const yesterdayJsDate = useMemo(
    () => DateTime.local().minus({ days: 1 }).toJSDate(),
    [],
  )

  const todayJsDate = useMemo(() => new Date(), [])

  const disabledForStart = [
    {
      after:
        end &&
        DateTime.fromISO(end)
          .minus({ days: minimalDays - 1 })
          .toJSDate(),
    },
  ]
  if (disableFuture) disabledForStart.push({ after: todayJsDate })
  if (disablePast) disabledForStart.push({ before: todayJsDate })

  const disabledForEnd = [
    {
      before:
        start &&
        DateTime.fromISO(start)
          .plus({ days: minimalDays - 1 })
          .toJSDate(),
    },
  ]
  if (disableFuture) disabledForEnd.push({ after: yesterdayJsDate })

  return (
    <Flex flex={1}>
      <DayPicker
        dayOnly
        disabled={isDisabled}
        disabledDays={disabledForStart}
        errored={isOverlap}
        from={isoToJsDate(start)}
        withInput
        onChange={handleChangeStart}
      />
      <Spacer axis="horizontal" size={8} />
      <DayPicker
        dayOnly
        disabled={isDisabled}
        disabledDays={disabledForEnd}
        errored={isOverlap}
        from={isoToJsDate(end)}
        withInput
        onChange={handleChangeEnd}
      />
      <Spacer axis="horizontal" size={8} />
      {isDisabled && disabledWarningText && (
        <WarningPopover popoverContent={disabledWarningText} />
      )}
      {(period.isEndDayValid === false || period.isStartDayValid === false) && (
        <WarningPopover
          popoverContent={i18n('effectiveDates.invalidEffectiveDateWarning')}
        />
      )}
    </Flex>
  )
}

export default EffectivePeriod

EffectivePeriod.defaultProps = {
  disabledWarningText: null,
  start: null,
  end: null,
  isDisabled: false,
}

EffectivePeriod.propTypes = {
  disableFuture: PropTypes.bool.isRequired,
  disablePast: PropTypes.bool.isRequired,
  disabledWarningText: PropTypes.string,
  end: PropTypes.string,
  index: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
  isOverlap: PropTypes.bool.isRequired,
  minimalDays: PropTypes.number.isRequired,
  start: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

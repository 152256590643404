import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import {
  DeleteEmploymentTypeMutation,
  EmploymentTypesByCursorQuery,
} from './GraphQL'

export function useDeleteEmploymentType() {
  const [innerDelete, { loading: deleting }] = useApolloMutation<
    MutationData<'deleteEmploymentType'>,
    Gateway.MutationDeleteEmploymentTypeArgs
  >(DeleteEmploymentTypeMutation, {
    refetchQueries: compact([getOperationName(EmploymentTypesByCursorQuery)]),
  })

  const deleteEmploymentType = async (id: string) =>
    innerDelete({
      variables: {
        id,
      },
    })

  return { deleteEmploymentType, deleting }
}

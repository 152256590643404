import auth0MetadataByEmailQuery from './Auth0MetadataByEmail.graphql'
import emailByEmployeeCodeQuery from './emailByEmployeeCode.graphql'
import meQuery from './me.graphql'
import useProfileQuery from './UserProfile.graphql'

export {
  auth0MetadataByEmailQuery,
  emailByEmployeeCodeQuery,
  meQuery,
  useProfileQuery,
}

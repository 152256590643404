import { ApolloLink } from '@apollo/client'

import { gatewayHttpLink } from './gatewayHttpLink'
import { hathorHttpLink } from './hathorHttpLink'

export function requestLink() {
  return ApolloLink.split(
    operation => operation.getContext().hathor === true,
    hathorHttpLink(),
    gatewayHttpLink(),
  )
}

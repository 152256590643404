import { useApolloMutation } from 'API/services/Apollo'

import { ClassifyTimeMutation } from './GraphQL'

export function useClassifyTime() {
  const [innerClassify, { loading }] = useApolloMutation<
    MutationData<'classifyTime'>,
    Gateway.MutationClassifyTimeArgs
  >(ClassifyTimeMutation, {
    translationKey: 'classifiedTime.toasts.classifyTime',
  })

  async function classifyTime(input: Gateway.ClassifyTimeInput) {
    const result = await innerClassify({
      variables: {
        input,
      },
    })

    return result.data?.classifyTime
  }

  return {
    classifyTime,
    classifyingTime: loading,
  }
}

import { useCallback } from 'react'

import { useApolloMutation } from 'API/services/Apollo'

import { TimeTypeFormState } from 'pages/EmployerApp/GlobalSettings/TimeTypes/types'

import { UpdateTimeTypeMutation } from './GraphQL'
import { mapFormStateToServerData } from './helpers'

export function useUpdateTimeType() {
  const [innerUpdate, { loading: updating }] = useApolloMutation<
    MutationData<'updateTimetype'>,
    Gateway.MutationUpdateTimetypeArgs
  >(UpdateTimeTypeMutation, {
    update: cache => {
      cache.evict({ fieldName: 'timetypesByOffset' })
    },
  })

  const updateTimeType = useCallback(
    async (values: TimeTypeFormState, id: string) => {
      const variables = mapFormStateToServerData(values)

      const result = await innerUpdate({
        variables: {
          input: {
            id,
            payload: { ...variables },
          },
        },
      })

      return Boolean(result.errors)
    },
    [innerUpdate],
  )

  return { updateTimeType, updating }
}

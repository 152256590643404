import React from 'react'
import { components, OptionProps } from 'react-select'

import { ValueContainerWrapper } from './styles'

type OptionItem = {
  value: string
  label: string
}

export function ValueContainer({ ...rest }: OptionProps<OptionItem, true>) {
  return (
    <ValueContainerWrapper>
      <components.ValueContainer {...rest} />
    </ValueContainerWrapper>
  )
}

import { PAGE_SIZE } from 'constants/pagination'

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

const DEFAULT_BUFFER_TIME = 20 // 20 minutes

export const LOAD_PRAYER_BREAKS = createAsyncAction('prayerBreaks/LOAD')
export const CREATE_PRAYER_BREAK = createAsyncAction('prayerBreaks/CREATE')
export const DELETE_PRAYER_BREAK = createAsyncAction('prayerBreaks/DELETE')
export const UPDATE_PRAYER_BREAK = createAsyncAction('prayerBreaks/UPDATE')

export const loadPrayerBreaks = ({ branchIds }) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/prayer_settings`,
      types: LOAD_PRAYER_BREAKS,
      query: {
        filter: {
          branch_ids: branchIds,
        },
        page: { size: PAGE_SIZE['100'] },
        include: 'branch',
      },
      paged: true,
    }),
  )
}

export const createPrayerBreak = ({ branchIds }) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/branches/${branchIds[0]}/prayer_settings`,
      method: 'POST',
      types: CREATE_PRAYER_BREAK,
      query: {
        data: {
          attributes: {
            buffer: {
              fajr: DEFAULT_BUFFER_TIME,
              dhuhr: DEFAULT_BUFFER_TIME,
              asr: DEFAULT_BUFFER_TIME,
              maghrib: DEFAULT_BUFFER_TIME,
              isha: DEFAULT_BUFFER_TIME,
            },
          },
        },
        include: 'branch',
      },
    }),
  )
}

export const deletePrayerBreak = ({ prayerSettingsIds, branchIds }) => (
  dispatch,
  getState,
) => {
  const companyId = getCompanyId(getState())

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/prayer_settings`,
      method: 'DELETE',
      types: DELETE_PRAYER_BREAK,
      query: {
        filter: {
          branch_ids: branchIds,
        },
      },
      payload: {
        // TODO: revert after backend changes id to strings
        relationIds: branchIds,
        deletedIds: prayerSettingsIds,
      },
    }),
  )
}

export const updatePrayerBreak = ({ branchId, prayerBreak }) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/branches/${branchId}/prayer_settings`,
      method: 'PATCH',
      types: UPDATE_PRAYER_BREAK,
      query: {
        data: {
          attributes: {
            buffer: prayerBreak.buffer,
          },
        },
      },
    }),
  )
}

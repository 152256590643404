import React from 'react'
import { Control } from 'react-hook-form'

import { Flex, Span } from 'components/ui/__v2__/Grid'
import { FormMaskedNumeric, FormSwitch } from 'components/ui/__v3__/HookForm'

import { POSITIVE_NUMBER_MASK } from 'constants/settings'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { IconText } from '../styles'
import { GlobalSettingsFormState } from '../types'

interface Props {
  control: Control<GlobalSettingsFormState, any>
  rapidClockInMode: GlobalSettingsFormState['rapidClockInMode']
}

const LIMIT_MASK = POSITIVE_NUMBER_MASK(3)

export function FormRapidMode({ control, rapidClockInMode }: Props) {
  const { enabled } = rapidClockInMode || {}
  const t = useI18n<typeof TRANSLATIONS.settings.rapidMode>(
    'settings.rapidMode',
  )

  return (
    <FormSwitch<GlobalSettingsFormState>
      control={control}
      label={
        <Flex alignItems="flex-start">
          <Span inline mr={1}>
            {enabled ? t('enabled') : t('disabled')}

            {enabled && (
              <Span ml={1} width={50}>
                <FormMaskedNumeric<GlobalSettingsFormState>
                  control={control}
                  disabled={!enabled}
                  icon={<IconText>{t('seconds')}</IconText>}
                  mask={LIMIT_MASK}
                  name="rapidClockInMode.limit"
                  required={enabled}
                />
              </Span>
            )}
          </Span>
        </Flex>
      }
      name="rapidClockInMode.enabled"
    />
  )
}

import { MdClose } from 'react-icons/md'

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'components/ui/__v2__/Grid'
import { either } from 'components/ui/__v3__/__utils__/styled-system'

import { DeleteIconProps } from './types'

export const DeleteIcon = styled(MdClose).attrs({ size: 22 })`
  color: ${either(
    'disabled',
    themeGet('layout.disabled'),
    themeGet('layout.negative'),
  )};
`

const disabledCss = ({ disabled }: DeleteIconProps) =>
  css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `

export const Container = styled(Flex)`
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  ${disabledCss}
`

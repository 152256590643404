import { useApolloMutation } from 'API/services/Apollo'

import { UnpublishRulesetMutation } from './GraphQL'

export function useUnpublishRuleset() {
  const [unpublishRuleset, { loading }] = useApolloMutation<
    MutationData<'unpublishRuleset'>,
    Gateway.MutationUnpublishRulesetArgs
  >(UnpublishRulesetMutation)

  const unpublish = async ({ id }: Gateway.MutationUnpublishRulesetArgs) => {
    return unpublishRuleset({
      variables: {
        id,
      },
    })
  }

  return { unpublish, updating: loading }
}

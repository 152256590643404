import { createSelector } from 'reselect'

import isEmpty from 'lodash/isEmpty'

import { denormalize } from 'helpers/redux'

import { getData } from './data'

export const getState = state => state.locations

const getRawLocations = createSelector(getState, state =>
  Array.from(state.locations),
)

export const getLocations = createSelector(
  getData,
  getRawLocations,
  (data, locations) =>
    !isEmpty(locations)
      ? // Renamed locations to branches
        denormalize(data, 'branches', Array.from(locations))
      : [],
)

export const getIsLoading = createSelector(getState, state => state.isLoading)
export const getIsLoaded = createSelector(getState, state => state.isLoaded)

// this selector change only one time, when locations loaded successfully
export const isLocationsLoadInitially = createSelector(
  getState,
  state => state?.isLoadedLocations,
)

export const getLocationFilters = createSelector(
  getState,
  state => state.filters,
)

export const getLocationSort = createSelector(getState, state => state.sort)

export const getPageNumber = createSelector(getState, state => state.pageNumber)

export const getPageSize = createSelector(getState, state => state.pageSize)

export const getRecordCount = createSelector(
  getState,
  state => state.recordCount,
)

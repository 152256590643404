import numeral from 'numeral'

import { LONG_DASH } from 'constants/schedule'

import { i18n } from 'i18n'

type Props = {
  wage?: number
  wageType?: Gateway.EmployeeJobWageType
}

export const Monetary = Object.freeze({
  centsToDollars(cents: number) {
    return cents / 100
  },

  dollarsToCents(dollars: number) {
    return dollars * 100
  },

  displayWage({ wage, wageType }: Props): string {
    if (!!wage && !!wageType)
      return i18n(`wageDisplay.${wageType}`, {
        wage: numeral(this.centsToDollars(wage)).format('$0[.]0'),
      })

    return LONG_DASH
  },
})

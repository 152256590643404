import { StoreonModule } from 'storeon'

import { isEqual, merge } from 'lodash'

import { PR_DEPLOYMENT_STATE_KEY } from 'services/StoreOn/PrDeployment/constants'

import { PrDeploymentEvents, PrDeploymentState } from './types'

const INITIAL_STATE: PrDeploymentState['prDeployment'] = {
  from: null,
  logoutRedirectUrl: null,
}

export const prDeploymentModule: StoreonModule<
  PrDeploymentState,
  PrDeploymentEvents
> = store => {
  store.on('@init', () => ({
    [PR_DEPLOYMENT_STATE_KEY]: INITIAL_STATE,
  }))

  store.on('prDeployment/setFrom', (state, value) => {
    const currentState = state[PR_DEPLOYMENT_STATE_KEY]

    const nextState = merge({}, currentState, {
      from: value,
    })

    if (isEqual(currentState, nextState)) return null

    return { [PR_DEPLOYMENT_STATE_KEY]: nextState }
  })

  store.on('prDeployment/setLogoutRedirectUrl', (state, value) => {
    const currentState = state[PR_DEPLOYMENT_STATE_KEY]

    const nextState = merge({}, currentState, {
      logoutRedirectUrl: value,
    })

    if (isEqual(currentState, nextState)) return null

    return { [PR_DEPLOYMENT_STATE_KEY]: nextState }
  })
}

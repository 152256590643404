import { ApolloCache } from '@apollo/client'
import { FragmentOptions } from 'API/services/utils'

export function shiftJobUnfilledPositionsCountUpdater(
  cache: ApolloCache<any>,
  id: Gateway.ShiftJob['id'],
  unfilledPositionsCount: number,
) {
  const fragmentOptions = FragmentOptions.ShiftJob(id)

  const shiftJob = cache.readFragment<Gateway.ShiftJob>({
    id: fragmentOptions.id,
    fragment: fragmentOptions.fragment,
    fragmentName: fragmentOptions.fragmentName,
  })

  if (!shiftJob) return

  cache.writeFragment<Gateway.ShiftJob>({
    id: fragmentOptions.id,
    fragment: fragmentOptions.fragment,
    fragmentName: fragmentOptions.fragmentName,
    data: {
      ...shiftJob,
      unfilledPositions: unfilledPositionsCount,
    },
  })
}

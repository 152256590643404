import React from 'react'

import { Flex, Span } from 'components/ui/__v2__/Grid'
import { Refetch as RefetchIcon } from 'components/ui/__v3__/DataTable/Controls'

type Props = {
  title: string
  disabledRefetch?: boolean
  children?: React.ReactNode
  onRefetch: () => Promise<any>
}

export function Header({ title, disabledRefetch, children, onRefetch }: Props) {
  return (
    <Flex flexDirection="column" gap={1}>
      <Flex alignItems="center" justifyContent="space-between" pt={3} px={3}>
        <Flex alignItems="center" gap={2}>
          <Span fontWeight={600}>{title}</Span>

          <RefetchIcon disabled={disabledRefetch} small onRefetch={onRefetch} />
        </Flex>

        {children}
      </Flex>
    </Flex>
  )
}

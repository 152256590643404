import { useApolloMutation } from 'API/services/Apollo'

import { EmployeePickerReturnOption } from 'components/blocks/__v3__'

import Utils from 'services/Utils'

import { StartAldoPayrollRowsCalculationMutation } from './GraphQL'

type StartAldoPayrollRowsCalculationArgs = {
  period: {
    from: string
    to: string
  }
  employees: EmployeePickerReturnOption[] | null
}

export function useStartAldoPayrollRowsCalculation({
  period,
  employees,
}: StartAldoPayrollRowsCalculationArgs) {
  const [startAldoPayrollRowsCalculation, { loading }] = useApolloMutation<
    MutationData<'startAldoPayrollRowsCalculation'>,
    Gateway.MutationStartAldoPayrollRowsCalculationArgs
  >(StartAldoPayrollRowsCalculationMutation, {
    variables: {
      employeeIds: employees?.map(employee => employee.value),
      period: Utils.GraphQL.betweenFilter(period),
    },
    translationKey: 'aldo.payroll.toasts.startEvaluation',
  })

  return {
    startCalculation: startAldoPayrollRowsCalculation,
    starting: loading,
  }
}

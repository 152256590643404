import styled from 'styled-components'

import tradeShiftBlockedSvg from 'assets/images/svg/shifts/trade-shift-blocked.svg'

import { Icon } from '../Icon'

const TradeShiftBlockedIcon = styled(Icon).attrs({
  glyph: tradeShiftBlockedSvg,
})`
  stroke-width: 1px;
  stroke: none;
  width: ${props => (props.width ? props.width : 17)}px;
  height: ${props => (props.height ? props.height : 17)}px;
`

export default TradeShiftBlockedIcon

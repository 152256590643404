import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { Job } from 'Types/app'

import { JobsPicker } from 'components/blocks/__v2__'
import { Flex } from 'components/ui/__v2__/Grid'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'
import { LabelProps } from 'components/ui/__v3__/Input/types'

type Props<T extends FieldValues> = ControllerBaseProps<T> &
  LabelProps & {
    isClearable?: boolean
    isDisabled?: boolean
    isMulti?: boolean
    locationIds?: string[]
    placeholder?: string
  }

type JobsPickerOption = {
  id: string
  label: string
  value: string
  jobs: Job
}

// NOTE: We need to thin because react-hook-form is not able to clone large objects
export function thinFormJob(option: JobsPickerOption) {
  return {
    id: option.id,
    label: option.label,
    value: option.value,
  }
}

export type FormJobPickerReturn = ReturnType<typeof thinFormJob>

/** @deprecated Pay attention, not all functionality */
export function FormJobPicker<T extends FieldValues>({
  control,
  name,
  labelText,
  required,
  isMulti,
  locationIds,
  ...rest
}: Props<T>) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  let handleChange
  if (isMulti) {
    handleChange = (options: JobsPickerOption[] | null) =>
      onChange(options ? options.map(thinFormJob) : null)
  } else {
    handleChange = (option: JobsPickerOption | null) =>
      onChange(option ? thinFormJob(option) : null)
  }

  return (
    <Flex flexDirection="column">
      {labelText && (
        <InputLabel>
          <LabelText labelText={labelText} required={required} />
        </InputLabel>
      )}
      {/* TODO: Need to type underlying component */}
      <JobsPicker
        {...rest}
        // @ts-ignore
        isMulti={isMulti}
        // @ts-ignore
        locationIds={locationIds}
        value={value}
        // @ts-ignore
        onBlur={onBlur}
        // @ts-ignore
        onChange={handleChange}
      />
      {error && <Error error={error} />}
    </Flex>
  )
}

import { css } from 'styled-components'

export default css`
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #fffefe;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #cccccc;
  }
`

export const noScrollbar = css`
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
`

import {
  ArrayPath,
  Control,
  FieldArrayWithId,
  useFieldArray,
  useWatch,
} from 'react-hook-form'

export type LimitedFieldArrayProps<S> = {
  control: Control<S>
  name: ArrayPath<S>
  maxItemsLimit?: number
  cantBeEmpty?: boolean
  checkForBlanksToAdd?: (fields: FieldArrayItem<S>) => boolean
}

type FieldArrayItem<S> = FieldArrayWithId<S, ArrayPath<S>, 'id'>

export function useLimitedFieldArray<S>({
  control,
  name,
  maxItemsLimit,
  cantBeEmpty,
  checkForBlanksToAdd,
}: LimitedFieldArrayProps<S>) {
  const { fields, ...rest } = useFieldArray<S>({
    control,
    name,
  })

  let cantAdd = false

  // @ts-ignore
  const watchedFields = useWatch<S>({ name, control }) as any[]

  if (maxItemsLimit) {
    cantAdd = fields.length === maxItemsLimit
  }

  if (checkForBlanksToAdd) {
    cantAdd = watchedFields.some(checkForBlanksToAdd)
  }

  const cantDelete = cantBeEmpty ? fields.length === 1 : false

  const isLast = (index: number, all: FieldArrayItem<S>[]) => {
    return all.length === index - 1
  }

  return { fields, cantAdd, cantDelete, isLast, ...rest }
}

import { createSelector } from 'reselect'

import isEmpty from 'lodash/isEmpty'

import { denormalize } from 'helpers/redux'

import { getData } from '../data'

export const getState = state => state.employeeApp.findCover

export const getSelectedSuggestedIds = createSelector(getState, state =>
  Array.from(state.selectedSuggestedIds),
)

export const getIsRequestingShiftCover = createSelector(
  getState,
  state => state.isRequestingShiftCover,
)

export const getIsRequestSuccessful = createSelector(
  getState,
  state => state.isRequestSuccessful,
)

export const getIsLoadingSuggestions = createSelector(
  getState,
  state => state.isLoadingSuggestions,
)

export const getSuggestionsIds = createSelector(getState, state =>
  Array.from(state.suggestedEmployeesIds),
)

export const getSuggestions = createSelector(
  getData,
  getSuggestionsIds,
  (data, suggestionsList) =>
    !isEmpty(suggestionsList)
      ? [...denormalize(data, 'employees', suggestionsList)]
      : [],
)

import React from 'react'

import { FiRefreshCcw as RefetchIcon } from 'react-icons/fi'

import { ButtonProps, IconButton } from 'components/ui/__v3__/Button'

type Props = {
  onRefetch: (args?: any) => Promise<any>
} & ButtonProps

export function Refetch({ onRefetch, ...rest }: Props) {
  return (
    <IconButton {...rest} secondary onClick={onRefetch}>
      <RefetchIcon />
    </IconButton>
  )
}

import { SortingDirection } from 'constants/gatewayGraphQL'

export function getSortingDirection<Field>(
  sort: {
    field: Field
    direction: SortingDirection
  },
  headerField: Field,
) {
  if (sort.field === headerField) {
    return sort.direction
  }
  return undefined
}

import { IndependentColors } from 'constants/colors'

import { TimeTypeFormState } from 'pages/EmployerApp/GlobalSettings/TimeTypes/types'

export function mapFormStateToServerData(
  formState: TimeTypeFormState,
): Omit<Gateway.CreateTimetypeInput, 'companyId'> {
  return {
    name: formState.name,
    code: formState.code,
    priority: Number(formState.priority),
    color: String(
      IndependentColors.find(colorKey => colorKey.key === formState.color)
        ?.value,
    ).substring(1), // the # sign is removed
    groupIds: formState.groups.map(group => group.value),
  }
}

import { toastr } from 'react-redux-toastr'

import { push } from 'connected-react-router'
import { all, fork, put, race, take } from 'redux-saga/effects'

import { taskBuilderPath } from 'helpers/routes'

import _ from 'i18n'

import { TASK_TEMPLATE } from 'store/actions/company/tasks'

const NAME_ALREADY_TAKEN_DETAIL = 'Task with the same name already exists.'

function getErrorFeedback(error) {
  let message = _('common.somethingWentWrong')
  if (error.error?.find(e => e.detail === NAME_ALREADY_TAKEN_DETAIL)) {
    message = _('taskBuilder.toasts.alreadyTaken')
  }
  toastr.error(message, {
    disableCloseButtonFocus: true,
  })
}

function* watchLoadErrors() {
  while (true) {
    const error = yield take(TASK_TEMPLATE.LOAD.FAILURE)

    if (error)
      toastr.error(_('taskBuilder.toasts.loadFailure'), {
        disableCloseButtonFocus: true,
      })
  }
}

function* watchDeleteTask() {
  while (true) {
    const { success } = yield race({
      success: take(TASK_TEMPLATE.DELETE.SUCCESS),
      error: take(TASK_TEMPLATE.DELETE.FAILURE),
    })
    if (success) {
      toastr.success(_('taskBuilder.toasts.deleteSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      toastr.error(_('common.somethingWentWrong'), {
        disableCloseButtonFocus: true,
      })
    }
  }
}

function* watchCreateTask() {
  while (true) {
    const { success, error } = yield race({
      success: take(TASK_TEMPLATE.CREATE.SUCCESS),
      error: take(TASK_TEMPLATE.CREATE.FAILURE),
    })
    if (success) {
      yield put(push(taskBuilderPath()))
      toastr.success(_('taskBuilder.toasts.createSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      getErrorFeedback(error)
    }
  }
}

function* watchEditTask() {
  while (true) {
    const { success, error } = yield race({
      success: take(TASK_TEMPLATE.UPDATE.SUCCESS),
      error: take(TASK_TEMPLATE.UPDATE.FAILURE),
    })
    if (success) {
      yield put(push(taskBuilderPath()))
      toastr.success(_('taskBuilder.toasts.editSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      getErrorFeedback(error)
    }
  }
}

export default function* root() {
  yield all([
    fork(watchDeleteTask),
    fork(watchCreateTask),
    fork(watchEditTask),
    fork(watchLoadErrors),
  ])
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useI18n } from 'hooks'

import { showError } from 'services/API'
import { showToast } from 'services/Toasts'

import { TimeoffQueryKeys } from './constant'
import { createTimeoffMutation, CreateTimeoffMutationArgs } from './Json'
import { CreateTimeoffKinds } from './types'

const TIMEOFF_INCLUDE = [
  'employee.profile',
  'customLeaveDay',
  'document',
  'lastUpdatedBy.profile',
  'processedBy.profile',
  'createdBy.profile',
].join(',')

export function useCreateTimeoff(kind: CreateTimeoffKinds) {
  const t = useI18n('timeoffTable.action')
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation(
    TimeoffQueryKeys.all(),
    (args: CreateTimeoffMutationArgs) =>
      createTimeoffMutation(args, TIMEOFF_INCLUDE),
    {
      onSuccess: (response: JsonApi.Response) => {
        if (response.ok) {
          showToast({
            type: 'success',
            title: t(`name.${kind}`),
            content: t(`success.${kind}`),
          })
          queryClient.invalidateQueries(TimeoffQueryKeys.all())
        } else {
          showError(response)
        }
      },
    },
  )

  return {
    createTimeoff: mutateAsync,
    isCreatingTimeoff: isLoading,
  }
}

import React, { useMemo } from 'react'
import { Control, useWatch } from 'react-hook-form'

import { DateTime } from 'luxon'

import { compact, uniqBy } from 'lodash'

import { Divider } from 'components/ui/__v2__/Divider'
import { SpacedColumn, SpacedRow, Span } from 'components/ui/__v2__/Grid'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import Utils from 'services/Utils'

import {
  dayToInterval,
  entriesIntersectionWithDay,
  entriesToIntervals,
  timeSheetItemsDuration,
} from '../../helpers'
import { TimesheetFormState } from '../../types'

type Props = {
  control: Control<TimesheetFormState>
  days: DateTime[]
}

export function TimesheetSummary({ control, days }: Props) {
  const t = useI18n<typeof TRANSLATIONS.weeklyTimesheets.summary>(
    'weeklyTimesheets.summary',
  )

  const watched = useWatch({ control, name: 'entries' })

  const earningTypes = useMemo(
    () => uniqBy(compact(watched.map(entry => entry.earningType)), 'id'),
    [watched],
  )

  const secondsByEarningType = useMemo(
    () =>
      earningTypes.map(({ value: earningTypeId, label: earningTypeName }) => {
        return [
          earningTypeName,
          timeSheetItemsDuration(
            (watched ?? []).filter(
              ({ earningType }) => earningType?.value === earningTypeId,
            ),
          ),
        ] as const
      }),
    [earningTypes, watched],
  )

  const secondsByDay = useMemo(
    () =>
      days.map(day => {
        const intervals = entriesIntersectionWithDay(
          entriesToIntervals(watched),
          dayToInterval(day),
        )

        return intervals.reduce(
          (acc, interval) => acc + interval.length('seconds'),
          0,
        )
      }),
    [days, watched],
  )
  const totalSeconds = useMemo(() => timeSheetItemsDuration(watched), [watched])

  return (
    <SpacedColumn>
      <SpacedRow>
        <Span fontWeight="bold">{t('totalHours')}:</Span>
        <Span>{Utils.DateTime.formatDuration(totalSeconds)}</Span>
      </SpacedRow>
      <Divider />
      {days.map((day, index) => (
        <SpacedRow key={day.toISO()}>
          <Span fontWeight="bold">{day.toFormat('EEEE')}:</Span>
          <Span>{Utils.DateTime.formatDuration(secondsByDay[index])}</Span>
        </SpacedRow>
      ))}

      {secondsByEarningType.length > 0 && <Divider />}

      {secondsByEarningType.map(([earningTypeName, seconds]) => (
        <SpacedRow key={earningTypeName}>
          <Span fontWeight="bold">{earningTypeName}:</Span>
          <Span>{Utils.DateTime.formatDuration(seconds)}</Span>
        </SpacedRow>
      ))}
    </SpacedColumn>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { themeGet } from '@styled-system/theme-get'

import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import noop from 'lodash/noop'
import toString from 'lodash/toString'
import without from 'lodash/without'
import xor from 'lodash/xor'

import { Avatar } from 'components/ui/Avatar'
import { Checkbox } from 'components/ui/Checkbox'
import { Flex } from 'components/ui/Grid'
import { Spinner } from 'components/ui/Spinner'

import { createAvatarSrc } from 'constants/avatar'

import { buildFullName } from 'helpers/employee'

import _ from 'i18n'

import { Container, Name, Role, Row } from './styles'

import { LabelText, RulesDivider } from '../styles'

function preventEvent(event) {
  if (event) {
    event.preventDefault()
    event.stopPropagation()
  }
}

function Permissions({
  location,
  managers,
  isLoading,
  onChange,
  onLoadManagers,
}) {
  const [accessIds, setAccessIds] = useState([])

  useEffect(() => {
    onLoadManagers(location.id)
  }, [location.id, onLoadManagers])

  const payrollAccessIds = useMemo(
    () =>
      map(
        filter(
          managers,
          manager =>
            !!find(
              get(manager, 'managerPermissions', []),
              permission =>
                toString(get(permission, ['branch', 'id'])) === location.id,
            ),
        ),
        'id',
      ),
    [location.id, managers],
  )

  useEffect(() => {
    setAccessIds(payrollAccessIds)
  }, [payrollAccessIds])

  const toggleManager = useCallback(
    id => () => {
      const ids = accessIds.includes(id)
        ? without(accessIds, id)
        : accessIds.concat(id)

      setAccessIds(ids)

      const isDiffPayrollAccess = !isEmpty(xor(payrollAccessIds, ids))

      onChange(ids, isDiffPayrollAccess)
    },
    [accessIds, onChange, payrollAccessIds],
  )

  return (
    <>
      <LabelText mt={18}>{_('settings.payrollAccess')}</LabelText>
      <Container>
        {isLoading ? (
          <Spinner />
        ) : (
          map(managers, manager => {
            const { user, id, departments } = manager
            const avatarSrc = createAvatarSrc(
              get(user, 'profile.profileAvatar'),
            )
            const name = buildFullName(user)
            const isSelected = accessIds.includes(id)
            const role = isEmpty(departments)
              ? _('roles.branchManager')
              : _('roles.departmentManager')

            const departmentNames = map(departments, 'name')
            return (
              <Row key={id} onClick={toggleManager(id)}>
                <Checkbox
                  activeBgColor={themeGet('colors.CHILL')}
                  bgColor={themeGet('colors.WHITE_FIVE')}
                  borderRadius={2}
                  checked={isSelected}
                  id={`${user.id}_checkbox`}
                  rectangleBorderColor={themeGet('colors.WHITE_FIVE')}
                  rectangleSize={16}
                  tickColor={themeGet('colors.WHITE')}
                  tickHeight={5}
                  tickLeftPosition={2}
                  tickTopPosition={3}
                  tickWidth={10}
                  onChange={preventEvent}
                />
                <Avatar circle ml={24} mr={12} size={32} src={avatarSrc} />
                <Flex flexDirection="column">
                  <Name>{name}</Name>
                  <Role>
                    {role}
                    {isEmpty(departmentNames)
                      ? ''
                      : ` / ${departmentNames.join(', ')}`}
                  </Role>
                </Flex>
              </Row>
            )
          })
        )}
      </Container>
      <RulesDivider />
    </>
  )
}

Permissions.defaultProps = {
  isLoading: false,
  location: {},
  managers: [],
  onChange: noop,
  onLoadManagers: noop,
}

Permissions.propTypes = {
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  managers: PropTypes.array,
  onChange: PropTypes.func,
  onLoadManagers: PropTypes.func,
}

export default Permissions

import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'

import { FILE_UPLOAD_KINDS } from 'constants/ids'

import { createAsyncAction } from 'helpers/redux'

import apiCall, { resolveNonOk } from 'services/API'

import { getWeek } from 'store/selectors/employees/schedules'
import { getBranchId, getCompanyId } from 'store/selectors/viewer'

export const CREATE_BRANCH = createAsyncAction('branch/CREATE')
export const DELETE_BRANCH = createAsyncAction('branch/DELETE')

export const HANDLE_EMPLOYEE_DESTROY = {
  COMPANY: 'branch/HANDLE_EMPLOYEE_DESTROY.COMPANY',
  DATA: 'branch/HANDLE_EMPLOYEE_DESTROY.DATA',

  toString() {
    return 'branch/HANDLE_EMPLOYEE_DESTROY'
  },
}

export const UPDATE_BRANCH = createAsyncAction('branch/UPDATE')
export const UPDATE_LOCATION_SETTINGS = createAsyncAction(
  'location/UPDATE_SETTINGS',
)
export const LOAD_BRANCH = createAsyncAction('branch/LOAD')
export const REFETCH_BRANCH = createAsyncAction('branch/REFETCH')
export const UPLOAD_IMAGE_FILE = createAsyncAction('branch/UPLOAD_IMAGE_FILE')
export const DELETE_IMAGE_FILE = createAsyncAction('branch/DELETE_IMAGE_FILE')
export const CLEAR_IMAGE_FILES = 'branch/CLEAR_IMAGE_FILES'
export const CHECK_UNIQUE_SLUG = createAsyncAction('branch/CHECK_UNIQUE_SLUG')

export const CREATE_BRANCH_BUDGET = createAsyncAction(
  'branch/CREATE_BRANCH_BUDGET',
)
export const CREATE_BRANCH_LABOR_COST = createAsyncAction(
  'branch/CREATE_BRANCH_LABOR_COST',
)
export const CREATE_BRANCH_SALE_AMOUNT = createAsyncAction(
  'branch/CREATE_BRANCH_SALE_AMOUNT',
)
export const DELETE_BRANCH_SALE_AMOUNT = createAsyncAction(
  'branch/DELETE_BRANCH_SALE_AMOUNT',
)
export const DELETE_BRANCH_LABOR_COST = createAsyncAction(
  'branch/DELETE_BRANCH_LABOR_COST',
)

export const CREATE_OR_UPDATE_BULK_BUDGET = createAsyncAction(
  'branch/CREATE_OR_UPDATE_BULK_BUDGET',
)

export const ADD_LOCATION_TAG = createAsyncAction('branch/ADD_LOCATION_TAG')

export const DELETE_LOCATION_TAG = createAsyncAction(
  'branch/DELETE_LOCATION_TAG',
)

export const createBranch = ({
  attributes,
  branchImageId,
  sectorId = null,
  isNew = false,
}) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  return dispatch(
    apiCall({
      endpoint: '/branches',
      method: 'POST',
      query: {
        data: {
          attributes: {
            ...attributes,
            companyId,
            onboardingSteps: [],
            onboardingCompleted: false,
            openingHours: {},
          },
          type: 'branches',
          relationships: {
            images: {
              data: !isEmpty(branchImageId)
                ? [
                    {
                      id: branchImageId,
                      type: 'file',
                    },
                  ]
                : [],
            },
            ...(!isEmpty(sectorId)
              ? {
                  sector: {
                    data: {
                      id: sectorId,
                      type: 'sector',
                    },
                  },
                }
              : {}),
          },
        },
        include: 'images,departments,jobs,locationTags,sector',
      },
      // TODO: refactor this shit
      payload: {
        isNew,
      },
      types: CREATE_BRANCH,
    }),
  )
}

const loadBranchIncludes = [
  'images',
  'departments',
  'jobs',
  'locationTags',
  'company.locationTags',
  'sector',
  'prayerSettings',
  'beacon_settings',
  'hierarchyNodes.jobs',
  'hierarchyNodes.department',

  // TODO: revert before merge
  // 'managers.user.profile',
]

export const loadBranch = branchId => {
  if (!branchId) {
    return resolveNonOk()
  }

  return apiCall({
    endpoint: `/branches/${branchId}`,
    query: {
      include: loadBranchIncludes.join(),
    },
    types: LOAD_BRANCH,
  })
}

export const refetchBranch = branchId => {
  if (!branchId) {
    return resolveNonOk()
  }

  return apiCall({
    endpoint: `/branches/${branchId}`,
    query: {
      include: loadBranchIncludes.join(),
    },
    types: REFETCH_BRANCH,
  })
}

export const deleteBranch = (branchId = null, isLocationTab = false) => (
  dispatch,
  getState,
) => {
  if (!branchId) {
    branchId = getBranchId(getState())
  }

  if (!branchId) {
    return resolveNonOk()
  }

  const currentLocation = branchId === getBranchId(getState())

  return dispatch(
    apiCall({
      endpoint: `/branches/${branchId}`,
      method: 'DELETE',
      types: DELETE_BRANCH,
      payload: {
        id: branchId,
        isLocationTab,
        currentLocation,
      },
    }),
  )
}

export const handleEmployeeDestroy = branchId => dispatch => {
  if (!branchId) {
    return
  }

  dispatch({
    type: HANDLE_EMPLOYEE_DESTROY.COMPANY,
    payload: {
      branchId,
    },
  })

  dispatch({
    type: HANDLE_EMPLOYEE_DESTROY.DATA,
    payload: {
      branchId,
    },
  })
}

export const updateBranch = ({
  attributes,
  branchImageId = -1,
  isProfileSettingsPage = false,
  isLocationRules = false,
  isDiffPayrollAccess = false,
  branchId = null,
  sectorId = null,
}) => (dispatch, getState) => {
  if (!branchId) {
    branchId = getBranchId(getState())
  }

  if (!branchId) {
    return resolveNonOk()
  }

  const week = getWeek(getState())

  const relationships = {}

  if (branchImageId !== -1) {
    relationships.images = {
      data: !isEmpty(branchImageId)
        ? [
            {
              id: branchImageId,
              type: 'file',
            },
          ]
        : [],
    }
  }

  if (!isEmpty(sectorId)) {
    relationships.sector = {
      data: {
        id: sectorId,
        type: 'sector',
      },
    }
  }

  return dispatch(
    apiCall({
      endpoint: `/branches/${branchId}`,
      method: 'PATCH',
      query: {
        data: {
          attributes: {
            ...attributes,
            onboardingSteps: ['fields', 'hours'],
          },
          type: 'branches',
          relationships,
        },
        include: loadBranchIncludes.join(),
      },
      types: UPDATE_BRANCH,
      payload: {
        isProfileSettingsPage,
        isLocationRules,
        isDiffPayrollAccess,
        branchId,
        prevWeek: week,
      },
    }),
  )
}

export const updateLocationSettings = ({ id, attributes }) => {
  return apiCall({
    endpoint: `/branches/${id}`,
    method: 'PATCH',
    query: {
      data: {
        attributes,
        type: 'branches',
      },
      include: loadBranchIncludes.join(),
    },
    types: UPDATE_LOCATION_SETTINGS,
    payload: {
      id,
      attributes,
    },
  })
}

export const uploadImageFile = file => {
  return apiCall({
    endpoint: '/files',
    method: 'POST',
    file,
    types: UPLOAD_IMAGE_FILE,
    fileParams: {
      kind: FILE_UPLOAD_KINDS.branchImage,
    },
  })
}

export const deleteImageFile = fileId => (dispatch, getState) => {
  const branchId = getBranchId(getState())

  if (!branchId) {
    return resolveNonOk()
  }

  return dispatch(
    apiCall({
      endpoint: `/files/${fileId}`,
      method: 'DELETE',
      types: DELETE_IMAGE_FILE,
      payload: {
        deletedId: fileId,
        relationId: branchId,
      },
    }),
  )
}

export const clearImageFiles = () => ({ type: CLEAR_IMAGE_FILES })

export const addLocationTagToBranch = (branchId, tags) => {
  if (!branchId) {
    return resolveNonOk()
  }

  const data = tags.map(tag => ({ id: tag, type: 'locationTags' }))

  return apiCall({
    method: 'POST',
    endpoint: `/branches/${branchId}/relationships/location_tags`,
    types: ADD_LOCATION_TAG,
    query: {
      data,
    },
    payload: { tags, branchId },
  })
}

export const deleteLocationTagToBranch = (branchId, tags) => {
  if (!branchId) {
    return resolveNonOk()
  }

  const data = tags.map(tag => ({ id: tag, type: 'locationTags' }))

  return apiCall({
    method: 'DELETE',
    endpoint: `/branches/${branchId}/relationships/location_tags`,
    types: DELETE_LOCATION_TAG,
    query: {
      data,
    },
    payload: {
      deletedId: first(tags),
      relationId: branchId,
    },
  })
}

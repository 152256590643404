import { MdArrowDownward, MdArrowUpward } from 'react-icons/md'

import styled from 'styled-components'
import { mapToTheme } from 'styled-map'

import { SpacedRow } from 'components/ui/__v2__/Grid'

const SortAscIcon = styled(MdArrowDownward)`
  fill: ${({ theme }) => theme.layout.primary};
  stroke: ${({ theme }) => theme.layout.primary};
`

const SortDescIcon = styled(MdArrowUpward)`
  fill: ${({ theme }) => theme.layout.primary};
  stroke: ${({ theme }) => theme.layout.primary};
`
export const SortIcons = { Asc: SortAscIcon, Desc: SortDescIcon }

export const SortButton = styled(SpacedRow).attrs({ space: 1 })`
  color: ${({ theme }) => theme.layout.primary};
  cursor: pointer;
  font-family: inherit;
  font-size: ${mapToTheme('buttons.fontSize')}px;
  font-weight: ${mapToTheme('buttons.fontWeight')};
`

type WrapperProps = { height: number }
export const WrapperRemainingLogs = styled.div<WrapperProps>`
  transition: all ease-in-out ${({ theme }) => theme.layout.transition.normal};
  height: ${props => props.height}px;
  overflow: hidden;
`

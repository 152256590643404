import coreApiCall from 'services/API/coreApi'

interface EmployeeQueryArgs {
  employeeId: number
  include: string
}

export function employeeQuery({ employeeId, include }: EmployeeQueryArgs) {
  return coreApiCall({
    endpoint: `/employees/${employeeId}`,
    query: {
      include,
    },
  })
}

import { graphQLResultHasError } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { copyPasteShiftFilterToAPI, CopyShiftsMutation } from './GraphQL'
import { CopyPasteShiftFilter } from './types'

export type CopyProps = {
  mode: Gateway.ShiftsCopyMode
  weekOffsets?: number[]
  locationId: string
  startOn: string
  targetDates?: string[]
  shiftIds?: string[]
  copyFilter: CopyPasteShiftFilter
  pasteFilter: CopyPasteShiftFilter
}

export function useCopyShifts() {
  const [innerCopy, { loading: copying }] = useApolloMutation<
    MutationData<'copyShifts'>,
    Gateway.MutationCopyShiftsArgs
  >(CopyShiftsMutation, { translationKey: 'shifts.toasts.bulkPaste' })

  const copy = async ({
    mode,
    weekOffsets,
    locationId,
    startOn,
    targetDates,
    shiftIds,
    copyFilter,
    pasteFilter,
  }: CopyProps) => {
    const copyApiFilter = copyPasteShiftFilterToAPI(copyFilter)
    const pasteApiFilter = copyPasteShiftFilterToAPI(pasteFilter)

    const hasCopyApiFilter = Object.values(copyApiFilter).length > 0
    const hasPasteApiFilter = Object.values(pasteApiFilter).length > 0

    const result = await innerCopy({
      variables: {
        input: {
          mode,

          payload: {
            weekOffsets,
            locationId: locationId!,
            startOn: startOn!,

            ...(targetDates && { targetDates }),
            ...(shiftIds && { shiftIds }),
            ...(hasCopyApiFilter && {
              copyFilter: copyApiFilter,
            }),
            ...(hasPasteApiFilter && {
              pasteFilter: pasteApiFilter,
            }),
          },
        },
      },
    })

    const hasError = graphQLResultHasError(result)
    if (hasError) {
      return null
    }

    return result.data?.copyShifts.backgroundTaskId
  }

  return { copy, copying }
}

import { FiEdit3 as FiEditIcon } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const color = ({ disabled }) =>
  disabled
    ? css`
        color: ${themeGet('layout.border')};
      `
    : css`
        color: ${themeGet('layout.lightText')};
      `
const EditIcon = styled(FiEditIcon).attrs({ size: 18 })`
  ${color}
`

export default EditIcon

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

import { vestResolver } from '@hookform/resolvers/vest'
import { NLPInferenceAPI } from 'API'
import { useStoreon } from 'storeon/react'

import { isEmpty } from 'lodash'

import { SpacedRow } from 'components/ui/__v2__/Grid'
import { SwitchTabs } from 'components/ui/__v2__/SwitchTabs'
import { Button } from 'components/ui/__v3__'
import { FormText } from 'components/ui/__v3__/HookForm'

import { useI18n } from 'hooks'

import { StoreonEvents, StoreonState } from 'services/StoreOn'
import { NLP_INFERENCE_KEY } from 'services/StoreOn/nlpInference'

import { Download, HoursChart } from './components'
import { validator } from './formConfig'
import { getChartData } from './helpers'
import {
  NLPContainer,
  PauseIcon,
  ReplyContainer,
  TabsContainer,
  VoiceIcon,
} from './styles'
import { NLPInferenceState } from './types'

const INITIAL_VALUES = {
  query: '',
}

export function NLPInference() {
  const t = useI18n('nlpInference')

  const {
    nlpInference: { isOpen },
  } = useStoreon<StoreonState, StoreonEvents>(NLP_INFERENCE_KEY)

  const [tab, setTab] = useState(0)

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<NLPInferenceState>({
    defaultValues: INITIAL_VALUES,
    resolver: vestResolver(validator),
    mode: 'onChange',
  })

  const {
    launchInferenceBySSE,
    pauseInference,
    clearInference,
    inferring,
    inference: { message, data },
  } = NLPInferenceAPI.inferBySSE()

  const messageRef = useRef<HTMLParagraphElement | null>(null)

  useEffect(() => {
    if (messageRef.current)
      messageRef.current.scrollTop = messageRef.current.scrollHeight
  }, [message])

  const onSubmit = async (data: NLPInferenceState) => {
    clearInference()
    await launchInferenceBySSE(data.query)
  }

  const chartData = useMemo(() => getChartData(data?.data || []), [data])

  return (
    <NLPContainer open={isOpen}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SpacedRow alignItems="center">
          <FormText<NLPInferenceState>
            as="textarea"
            control={control}
            height="50px"
            labelText={t('enter')}
            name="query"
            required
            width="25vw"
          />

          <VoiceIcon size={20} onClick={() => {}} />
        </SpacedRow>

        <SpacedRow>
          <Button disabled={inferring || !isValid} small>
            {t('send')}
          </Button>
          {inferring && <PauseIcon onClick={pauseInference} />}
        </SpacedRow>
      </form>

      <TabsContainer hidden={!message && isEmpty(data)}>
        <SwitchTabs
          titles={[t('view.message'), t('view.chart')]}
          value={tab}
          onChange={setTab}
        />
      </TabsContainer>

      {tab === 0 ? (
        <ReplyContainer hidden={!message} ref={messageRef}>
          {message}
        </ReplyContainer>
      ) : (
        <HoursChart data={chartData} hidden={isEmpty(data)} />
      )}

      <Download hidden={inferring || !message} />
    </NLPContainer>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { flexbox, layout, position, space, typography } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { either, when } from 'components/ui/__v3__/__utils__/styled-system'

const CustomRadio = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: var(--size);
  height: var(--size);
  background-color: ${either(
    'disabled',
    themeGet('radio.bg.disabled'),
    themeGet('radio.bg.default'),
  )};
  border-radius: 50%;
  border: 1px solid ${themeGet('radio.border.default')};

  &:after {
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 0;
    height: 0;
  }
`

const HiddenRadio = styled.input.attrs({
  type: 'radio',
})`
  position: absolute;
  opacity: 0;
  width: var(--size);
  height: var(--size);

  &:checked ~ ${CustomRadio} {
    background-color: ${either(
      'disabled',
      themeGet('radio.bg.disabled'),
      themeGet('radio.bg.checked'),
    )};
    border-color: ${either(
      'disabled',
      themeGet('radio.border.disabled'),
      themeGet('radio.border.checked'),
    )};

    &:after {
      width: calc(var(--size) / 2);
      height: calc(var(--size) / 2);
      background-color: ${either(
        'disabled',
        themeGet('radio.icon.bg.disabled'),
        themeGet('radio.icon.bg.default'),
      )};
    }
  }

  ${when(
    'disabled',
    css`
      pointer-events: none;
    `,
  )};
`

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: ${themeGet('space.1')}px;
  }

  ${layout};
  ${space};
  ${flexbox};
  ${typography}
  ${position};
`

const RadioContainer = styled.label`
  --size: ${props => props.size}px;

  position: relative;
  cursor: ${either('disabled', 'not-allowed', 'pointer')};
  width: var(--size);
  height: var(--size);
  display: inline-flex;
`

export const Radio = props => {
  const { disabled, size, className, ...rest } = props

  return (
    <RadioContainer className={className} disabled={disabled} size={size}>
      <HiddenRadio disabled={disabled} {...rest} />
      <CustomRadio disabled={disabled} />
    </RadioContainer>
  )
}

Radio.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.number,
}

Radio.defaultProps = {
  className: undefined,
  disabled: false,
  size: 24,
}

export default Radio

import { useClassifiedTimesAggregatedByEmployeeByCursor } from './useClassifiedTimesAggregatedByEmployeeByCursor'
import { useClassifiedTimesByCursor } from './useClassifiedTimesByCursor'
import { useClassifiedTimesByOffset } from './useClassifiedTimesByOffset'
import { useClassifiedTimesSummaryExport } from './useClassifiedTimesSummaryExport'
import { useClassifyTime } from './useClassifyTime'
import { useTestClassifyTime } from './useTestClassifyTime'
import { useTimeClassificationWorkflowStatus } from './useTimeClassificationWorkflowStatus'

export const ClassifiedTimeAPI = {
  byCursor: useClassifiedTimesByCursor,
  byOffset: useClassifiedTimesByOffset,
  simulate: useTestClassifyTime,
  classifyTime: useClassifyTime,
  aggregatedByEmployeeByCursor: useClassifiedTimesAggregatedByEmployeeByCursor,
  timeClassificationWorkflowStatus: useTimeClassificationWorkflowStatus,
  extract: useClassifiedTimesSummaryExport,
}

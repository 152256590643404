import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

export const ADD_JOB = createAsyncAction('job/ADD')
export const DELETE_JOB = createAsyncAction('job/DELETE')
export const SAVE_JOB = createAsyncAction('job/SAVE')

export const CREATE_JOBS_TAG = createAsyncAction('job/CREATE_JOBS_TAG')
export const DELETE_JOBS_TAGS = createAsyncAction('job/DELETE_JOBS_TAGS')

export const addJob = ({
  branchId,
  departmentId,
  name,
  exportCode,
  externalId,
  allowDoubleBooking,
}) => dispatch => {
  const attributes = {
    name,
  }

  if (!isEmpty(exportCode)) {
    attributes.exportCode = exportCode
  }

  if (!isEmpty(externalId)) {
    attributes.externalId = externalId
  }

  if (allowDoubleBooking) {
    attributes.allowDoubleBooking = allowDoubleBooking
  }

  return dispatch(
    apiCall({
      endpoint: `/departments/${departmentId}/relationships/jobs`,
      method: 'POST',
      query: {
        data: {
          type: 'jobs',
          attributes,
          relationships: {
            branch: {
              data: {
                type: 'branches',
                id: branchId,
              },
            },
          },
        },
        include: 'departments,branches',
      },
      payload: {
        relationId: departmentId,
      },
      types: ADD_JOB,
    }),
  )
}

export const detachJobFromLocationDepartment = ({
  branchId,
  jobId,
  departmentId,
}) =>
  apiCall({
    endpoint: `/branches/${branchId}/relationships/jobs`,
    method: 'DELETE',
    types: DELETE_JOB,
    query: { data: [{ departmentId, jobId }] },
  })

export const saveJob = (job, attributes, relationships = {}) => {
  return apiCall({
    endpoint: `/jobs/${job.id}`,
    method: 'PATCH',
    query: {
      data: {
        type: 'jobs',
        attributes,
        relationships,
      },
    },
    types: SAVE_JOB,
  })
}

export const createJobsTag = (jobId, tag, roleId) => dispatch => {
  const { color, name } = tag

  return dispatch(
    apiCall({
      method: 'POST',
      endpoint: `/jobs/${jobId}/relationships/tags`,
      types: CREATE_JOBS_TAG,
      payload: { roleId },
      query: {
        data: {
          type: 'jobsTags',
          attributes: {
            name,
            color,
          },
          relationships: {
            job: {
              data: {
                type: 'jobs',
                id: jobId,
              },
            },
          },
        },
        include: 'job,job.jobsTags',
      },
    }),
  )
}

export const deleteJobsTags = ({ jobId, roleId, tagIds }) => {
  const data = map(tagIds, tagId => ({
    id: tagId,
    type: 'jobsTags',
  }))

  return apiCall({
    endpoint: `/jobs/${jobId}/relationships/tags`,
    method: 'DELETE',
    query: { data },
    types: DELETE_JOBS_TAGS,
    payload: {
      roleId,
      deletedId: first(tagIds),
      relationId: jobId,
    },
  })
}

import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import {
  clearDepartments,
  initDepartments,
  loadDepartments,
} from 'store/actions/areas'
import { getInitialDepartments } from 'store/selectors/areas'

import DepartmentsPicker from './DepartmentsPicker'

const selectors = createStructuredSelector({
  initial: getInitialDepartments,
})

const actions = {
  onInit: initDepartments,
  onLoad: loadDepartments,
  onClear: clearDepartments,
}

export default connect(selectors, actions)(DepartmentsPicker)

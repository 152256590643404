import { areasFilterToGraphql } from 'API/services/utils'
import { DateTime } from 'luxon'

import compact from 'lodash/compact'

import {
  BulkUpdateCustomCvFieldValuesAccessLevel,
  BulkUpdateCustomCvFieldValuesCustomFieldOperator,
  BulkUpdateCustomCvFieldValuesStatus,
} from 'constants/gatewayGraphQL'
import { StaffAccessLevel, StaffStatus } from 'constants/jsonAPI'

import { EffectiveFieldValuePair } from 'pages/EmployerApp/People/pages/PeopleTable/components/CustomFieldEdit/types'
import { StaffFilters } from 'pages/EmployerApp/People/pages/PeopleTable/hooks/types'

import Utils from 'services/Utils'

export function mapFormToBulkUpdateInput(
  filters: StaffFilters,
  payloads: EffectiveFieldValuePair[],
): Gateway.BulkUpdateCustomCvFieldValuesInput {
  const gqlFilter = {
    ...areasFilterToGraphql(filters.areas),
    ...(filters.search && {
      search: Utils.GraphQL.ilikeFilter(filters?.search),
    }),
    ...(filters.accessLevels && {
      accessLevel: Utils.GraphQL.inFilter(
        filters.accessLevels.map(level => accessLevelToGraphql(level.value)),
      ),
    }),
    ...(filters.statuses && {
      status: Utils.GraphQL.inFilter(
        filters.statuses.map(status => statusToGraphql(status.value)),
      ),
    }),
    ...(filters.customCvFields &&
      filters.customCvFields.length > 0 && {
        customFields: {
          operator: BulkUpdateCustomCvFieldValuesCustomFieldOperator.And,
          ...Utils.GraphQL.inFilter(
            filters.customCvFields.map(({ field, value }) => ({
              fieldId: field.id,
              value,
            })),
          ),
        },
      }),
    ...(filters.missingFields && {
      missingFields: Utils.GraphQL.eqFilter(!!filters?.missingFields),
    }),
  }

  const gqlPayloads = payloads.map(payload => {
    if (!payload.field) return null

    const {
      field: { customCvField },
      value,
      effectivePeriod,
    } = payload
    return {
      value,
      customCvFieldId: customCvField.id,
      effectiveDates: {
        start: effectivePeriod?.start
          ? DateTime.fromISO(effectivePeriod.start, {
              zone: 'utc',
            }).toJSDate()
          : undefined,
        end: effectivePeriod?.end
          ? DateTime.fromISO(effectivePeriod.end, {
              zone: 'utc',
            }).toJSDate()
          : undefined,
      },
    }
  })

  return {
    filter: gqlFilter,
    payloads: compact(gqlPayloads),
  }
}

function accessLevelToGraphql(
  accessLevel: StaffAccessLevel,
): BulkUpdateCustomCvFieldValuesAccessLevel {
  switch (accessLevel) {
    case StaffAccessLevel.Admin:
      return BulkUpdateCustomCvFieldValuesAccessLevel.Admin
    case StaffAccessLevel.LocationManager:
      return BulkUpdateCustomCvFieldValuesAccessLevel.LocationManager
    case StaffAccessLevel.DepartmentManager:
      return BulkUpdateCustomCvFieldValuesAccessLevel.DepartmentManager
    case StaffAccessLevel.Employee:
      return BulkUpdateCustomCvFieldValuesAccessLevel.Employee
    case StaffAccessLevel.TemporaryAccessLevel:
      return BulkUpdateCustomCvFieldValuesAccessLevel.TemporaryAccessLevel
    default:
      return BulkUpdateCustomCvFieldValuesAccessLevel.TemporaryAccessLevel
  }
}

function statusToGraphql(
  status: StaffStatus,
): BulkUpdateCustomCvFieldValuesStatus {
  switch (status) {
    case StaffStatus.Active:
      return BulkUpdateCustomCvFieldValuesStatus.Active
    case StaffStatus.Archived:
      return BulkUpdateCustomCvFieldValuesStatus.Archived
    case StaffStatus.CurrentlyWorking:
      return BulkUpdateCustomCvFieldValuesStatus.CurrentlyWorking
    case StaffStatus.Pending:
      return BulkUpdateCustomCvFieldValuesStatus.Pending
    default:
      return BulkUpdateCustomCvFieldValuesStatus.Pending
  }
}

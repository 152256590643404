import { toastr } from 'react-redux-toastr'

import { all, fork, race, take } from 'redux-saga/effects'

import _ from 'i18n'

import { CNESST, CNESST_STATUSES } from 'store/actions/employees/CNESST'

function* watchLoadFailure() {
  while (true) {
    const error = yield take(CNESST.LOAD.FAILURE)

    if (error)
      toastr.error(_('CNESST.toasts.loadFailure'), {
        disableCloseButtonFocus: true,
      })
  }
}

function* watchStatusLoadFailure() {
  while (true) {
    const error = yield take(CNESST_STATUSES.LOAD.FAILURE)

    if (error)
      toastr.error(_('CNESST.toasts.statusLoadFailure'), {
        disableCloseButtonFocus: true,
      })
  }
}

function* watchDeleteRecord() {
  while (true) {
    const { success } = yield race({
      success: take(CNESST.DELETE.SUCCESS),
      error: take(CNESST.DELETE.FAILURE),
    })
    if (success) {
      toastr.success(_('CNESST.toasts.deleteSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      toastr.error(_('common.somethingWentWrong'), {
        disableCloseButtonFocus: true,
      })
    }
  }
}

function* watchCreateRecord() {
  while (true) {
    const { success } = yield race({
      success: take(CNESST.CREATE.SUCCESS),
      error: take(CNESST.CREATE.FAILURE),
    })
    if (success) {
      toastr.success(_('CNESST.toasts.createSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      toastr.error(_('common.somethingWentWrong'), {
        disableCloseButtonFocus: true,
      })
    }
  }
}

function* watchUpdateRecord() {
  while (true) {
    const { success } = yield race({
      success: take(CNESST.UPDATE.SUCCESS),
      error: take(CNESST.UPDATE.FAILURE),
    })
    if (success) {
      toastr.success(_('CNESST.toasts.updateSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      toastr.error(_('common.somethingWentWrong'), {
        disableCloseButtonFocus: true,
      })
    }
  }
}

export default function* root() {
  yield all([
    fork(watchLoadFailure),
    fork(watchStatusLoadFailure),
    fork(watchDeleteRecord),
    fork(watchCreateRecord),
    fork(watchUpdateRecord),
  ])
}

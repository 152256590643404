import { useQuery } from '@tanstack/react-query'
import { STALE_TIME } from 'API/services/JsonApi'
import { transformResponseToEntities } from 'API/services/utils'

import {
  LOCATION_INCLUDE,
  LOCATION_JSON_API_TYPE,
  LocationQueryKey,
} from './constants'
import { locationQuery } from './Json'
import { LocationAPIItem } from './types'

// https://tanstack.com/query/v4/docs/react/reference/useQuery

export function useLocation(locationId: string) {
  const { data, isFetching, error } = useQuery({
    structuralSharing: false,
    refetchOnWindowFocus: false,
    staleTime: STALE_TIME.VERY_SLOW,

    queryKey: LocationQueryKey.byId({
      id: locationId,
      include: LOCATION_INCLUDE,
    }),
    queryFn: () => locationQuery({ locationId, include: LOCATION_INCLUDE }),

    select: (response: JsonApi.Response) => {
      const { entities } = transformResponseToEntities<LocationAPIItem>({
        response,
        jsonApiType: LOCATION_JSON_API_TYPE,
      })

      return entities[0]
    },
  })

  return {
    location: data,
    locationLoading: isFetching,
    locationError: error,
  }
}

import React from 'react'
import PropTypes from 'prop-types'

import { Span } from 'components/ui/Grid'
import { Spinner } from 'components/ui/Spinner'

function Loading({ loading, size }) {
  if (!loading) {
    return null
  }

  return (
    <Span position="absolute" right={2}>
      <Spinner size={size} />
    </Span>
  )
}

Loading.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Loading.defaultProps = {
  loading: false,
  size: 'normal',
}

export default Loading

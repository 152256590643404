import { createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOAD_SECTORS } from 'store/actions/common/sectors'

const initialState = Immutable({
  isLoaded: false,
  isLoading: false,
  sectors: [],
})

const handlers = {
  [LOAD_SECTORS.REQUEST]: state => state.merge({ isLoading: true }),
  [LOAD_SECTORS.SUCCESS]: createLoadHandler('sectors'),
}

export default createReducer(initialState, handlers)

import { useState } from 'react'

import { download } from 'API/services/JSON'

import { useAppContext } from 'hooks'

import { showDefaultErrorToast } from 'services/Toasts'

import { scheduleExportParamsToQueryString } from './helpers'
import { ScheduleExportParams } from './types'

export function useScheduleExport() {
  const [exporting, setExporting] = useState(false)
  const { companyId } = useAppContext()

  async function scheduleExportToCSV(filter: ScheduleExportParams['filter']) {
    setExporting(true)
    try {
      await download({
        companyId,
        queryString: scheduleExportParamsToQueryString(filter),
        kind: 'schedule_export',
      })
    } catch (error) {
      showDefaultErrorToast()
    }

    setExporting(false)
  }

  return {
    scheduleExporting: exporting,
    scheduleExportToCSV,
  }
}

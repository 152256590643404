import { useMutation, useQueryClient } from '@tanstack/react-query'

import { i18n } from 'i18n'

import { showError } from 'services/API'
import coreApiCall from 'services/API/coreApi'
import { showToast } from 'services/Toasts'

import { TimeoffQueryKeys } from './constant'
import { RejectTimeoffParams } from './types'

// TODO: move to mutations, make similar to useApproveTimeoffRequestMutation
function rejectTimeoffRequest({ timeoffId, comment }: RejectTimeoffParams) {
  const query = {
    data: {
      attributes: {
        comment,
      },
    },
  }
  return coreApiCall({
    endpoint: `/timeoffs/${timeoffId}/reject`,
    method: 'POST',
    query,
  })
}

export function useRejectTimeoffRequest() {
  const queryClient = useQueryClient()
  const { mutateAsync, isLoading } = useMutation(
    TimeoffQueryKeys.all(),
    rejectTimeoffRequest,
    {
      onSuccess: (response: JsonApi.Response) => {
        if (response.ok) {
          showToast({
            type: 'success',
            title: i18n('timeoffTable.action.success.reject'),
            content: i18n('alertMessages.default.success.message'),
          })
          queryClient.invalidateQueries(TimeoffQueryKeys.all())
        } else {
          showError(response)
        }
      },
    },
  )

  return {
    isRejectingTimeoffRequest: isLoading,
    rejectTimeoffRequest: mutateAsync,
  }
}

import { createSelector } from 'reselect'

import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import toNumber from 'lodash/toNumber'

import { EMPLOYEE_STATE_FILTERS } from 'constants/employees'
import { CHAT_KINDS } from 'constants/groupChat'

import { denormalize } from 'helpers/redux'

import { getEmployees } from 'store/selectors/employees/employees'
import { getId } from 'store/selectors/viewer'

import { getData } from './data'

export const getState = state => state.groupChat

export const getRawFiles = createSelector(getState, state => state.files)

export const getIsChatsLoaded = createSelector(
  getState,
  state => state.isChatsLoaded,
)

export const getIsChatsLoading = createSelector(
  getState,
  state => state.isChatsLoading,
)

export const getIsLoadingMoreChats = createSelector(
  getState,
  state => state.isLoadingMoreChats,
)

export const getIsMessagesLoading = createSelector(
  getState,
  state => state.isMessagesLoading,
)

export const getIsLoadingMoreMessages = createSelector(
  getState,
  state => state.isLoadingMoreMessages,
)

export const getActiveChatId = createSelector(
  getState,
  state => state.activeChatId,
)

export const getUnreadCounts = createSelector(
  getState,
  state => state.unreadCounts,
)

export const getMutedChatIds = createSelector(
  getState,
  state => state.mutedChatIds,
)

export const getChatsIds = createSelector(getState, state =>
  Array.from(state.chats),
)

export const getMessagesIds = createSelector(getState, state =>
  Array.from(state.messages),
)

export const getChats = createSelector(getData, getChatsIds, (data, chatsIds) =>
  !isEmpty(chatsIds) ? denormalize(data, 'chats', chatsIds) : [],
)

export const getActiveChat = createSelector(
  getActiveChatId,
  getChats,
  (activeChatId, chats) =>
    !isEmpty(chats) && !!activeChatId ? find(chats, ['id', activeChatId]) : {},
)

export const getIsActiveChatMuted = createSelector(
  getActiveChatId,
  getMutedChatIds,
  (activeChatId, mutedChatIds) =>
    !isEmpty(mutedChatIds) &&
    activeChatId &&
    indexOf(mutedChatIds, toNumber(activeChatId)) !== -1,
)

export const getFiles = createSelector(
  getActiveChat,
  getRawFiles,
  (chat, files) =>
    !isEmpty(chat) ? filter(files, file => file.chatId === chat.id) : [],
)

export const getLoadedChatIds = createSelector(
  getState,
  state => state.loadedChatIds,
)

export const getPrevMessages = createSelector(
  getData,
  getLoadedChatIds,
  getActiveChatId,
  (data, loadedChatIds, activeChatId) => {
    const isChatLoaded = indexOf(loadedChatIds, activeChatId) !== -1
    const dataMessages = !isEmpty(data.messages) ? { ...data.messages } : []
    const chatMessages =
      isChatLoaded &&
      filter(dataMessages, ['relationships.chat.data.id', activeChatId])
    const chatMessagesIds = chatMessages ? map(chatMessages, 'id') : []
    return denormalize(data, 'messages', chatMessagesIds)
  },
)

export const getMessages = createSelector(
  getData,
  getMessagesIds,
  (data, messagesIds) =>
    !isEmpty(messagesIds) ? denormalize(data, 'messages', messagesIds) : [],
)

export const getActiveChatUnreadCount = createSelector(
  getActiveChatId,
  getUnreadCounts,
  (activeChatId, unreadCounts) => get(unreadCounts, `[${activeChatId}]`, null),
)

const getActiveChatUsers = createSelector(getActiveChat, chat =>
  get(chat, 'users', []),
)

export const getIsActiveChatLeft = createSelector(
  getActiveChatUsers,
  getId,
  (users, viewerId) => !find(users, user => user.id === viewerId),
)

export const getIsActiveChatArchived = createSelector(
  getActiveChat,
  getId,
  getEmployees(EMPLOYEE_STATE_FILTERS.archived),
  (chat, viewerId, archivedEmployees) => {
    const isChatPrivate = get(chat, 'kind', null) === CHAT_KINDS.private
    if (isChatPrivate) {
      const users = get(chat, 'users', [])
      const interlocutor = find(users, user => user.id !== viewerId)
      const archivedInterlocutor = find(
        archivedEmployees,
        employee =>
          get(employee, 'user.id', '') === get(interlocutor, 'id', null),
      )
      return !isEmpty(archivedInterlocutor)
    }
    return false
  },
)

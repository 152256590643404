import intersectionBy from 'lodash/intersectionBy'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const INIT = 'locations/INIT'
export const LOCATIONS_CLEAR = 'locations/CLEAR'
export const CHANGE_LOCATION_FILTER = 'locations/CHANGE_LOCATION_FILTER'
export const CHANGE_LOCATION_SORT = 'locations/CHANGE_LOCATION_SORT'
export const CHANGE_PAGE_SIZE = 'locations/CHANGE_PAGE_SIZE'
export const CHANGE_PAGE_NUMBER = 'locations/CHANGE_PAGE_NUMBER'
export const ADD_DEPARTMENTS_AND_ROLES = createAsyncAction(
  'locations/ADD_DEPARTMENTS_AND_ROLES',
)
export const LOAD_LOCATIONS = createAsyncAction('locations/LOAD_LOCATIONS')

export const init = () => ({ type: INIT })

export const locationFilter = (filters, { withLoading = true } = {}) => ({
  type: CHANGE_LOCATION_FILTER,
  payload: {
    filters,
    withLoading,
  },
})

export const locationSort = sort => ({
  type: CHANGE_LOCATION_SORT,
  payload: {
    sort,
  },
})

export const changePageSize = pageSize => ({
  type: CHANGE_PAGE_SIZE,
  payload: {
    pageSize,
  },
})

export const changePageNumber = pageNumber => ({
  type: CHANGE_PAGE_NUMBER,
  payload: {
    pageNumber,
  },
})

const locationsIncludes = [
  'managers.user.profile',
  'locationTags',
  'company.locationTags',
]

export const loadLocations = (
  { filters, page, sort },
  { withLoading = true } = {},
) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/branches`,
      types: LOAD_LOCATIONS,
      query: {
        sort,
        include: locationsIncludes.join(),
        page,
        filter: filters,
        data: {
          type: 'locations',
        },
      },
      payload: {
        companyId,
        withLoading,
      },
    }),
  )
}

export const addDepartmentsAndRoles = ({ locations, departments, jobs }) => {
  const data = map(departments, department => {
    const result = {
      attributes: {
        name: department.name,
      },
      relationships: {
        branches: {
          data: map(locations, loc => ({
            id: loc.id,
            type: 'branches',
          })),
        },
      },
    }
    if (!isEmpty(jobs)) {
      result.attributes.jobs = map(
        intersectionBy(department.jobs, jobs, 'id'),
        'name',
      )
    }
    return result
  })

  return apiCall({
    endpoint: `/departments`,
    types: ADD_DEPARTMENTS_AND_ROLES,
    method: 'POST',
    query: {
      data,
      include: 'branch,department,jobs',
    },
  })
}

import { ChangeEvent } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import { SchedulesShiftAssignmentsAPIItem } from 'API/Schedule/GraphQL'

import { SELECTED_SCHEDULE_IDS_FIELD } from '../../../constants'
import { ShiftMoveModalFormState } from '../../../types'

type Props = {
  schedules: SchedulesShiftAssignmentsAPIItem[]
  selectedScheduleIds: Set<string>
  setValue: UseFormSetValue<ShiftMoveModalFormState>
}

export function useTableSelection({
  schedules,
  selectedScheduleIds,
  setValue,
}: Props) {
  const toggleSelection = (id: string) => {
    const updatedSelected = new Set(selectedScheduleIds)

    if (updatedSelected.has(id)) {
      updatedSelected.delete(id)
    } else {
      updatedSelected.add(id)
    }

    setValue(SELECTED_SCHEDULE_IDS_FIELD, updatedSelected, {
      shouldValidate: true,
    })
  }

  const toggleAllSelection = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedSelected = new Set<string>()

    if (event.target.checked) {
      updatedSelected = new Set(schedules.map(schedule => schedule.id))
    }

    setValue(SELECTED_SCHEDULE_IDS_FIELD, updatedSelected, {
      shouldValidate: true,
    })
  }

  const schedulesCheckedCount = selectedScheduleIds.size
  return {
    schedulesCheckedCount,
    toggleAllSelection,
    toggleSelection,
  }
}

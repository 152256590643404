import { DateTime, Interval } from 'luxon'
import {
  Branch,
  EffectiveDateRange,
  JobEmployee,
  JobEmployeeWithCompanySettings,
  TimeCaptureModeKind,
} from 'Types/app'

import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import { getTimeZone } from 'helpers/branch'

import { AppContextViewerManagementScope } from 'services/AppContext'

import { fromStringsToInterval } from './DateTime'

/** @deprecated */
export function getActiveEffectivePeriod(
  effectiveDates: EffectiveDateRange[],
  branch: Branch,
): EffectiveDateRange | null {
  const timeZone = getTimeZone(branch)

  for (let i = 0; i < effectiveDates?.length; i += 1) {
    if (
      fromStringsToInterval(
        effectiveDates[i].start,
        effectiveDates[i].end,
      ).contains(DateTime.local({ zone: timeZone }))
    ) {
      return effectiveDates[i]
    }
  }
  return null
}
/** @deprecated */
export function isRoleActive(
  effectiveDates: EffectiveDateRange[],
  branch: Branch,
): boolean {
  return (
    effectiveDates.length === 0 ||
    !isNull(getActiveEffectivePeriod(effectiveDates, branch))
  )
}
/** @deprecated */
export function filterRolesByEffectivePeriod(
  jobsEmployees: JobEmployee[],
): false | JobEmployee[] {
  if (isEmpty(jobsEmployees)) {
    return false
  }

  return jobsEmployees.filter(jobEmployee =>
    isRoleActive(jobEmployee.effectiveDates, jobEmployee.branch),
  )
}

export function isRoleEffective({
  employeeJob,
  timezone,
}: {
  employeeJob: JobEmployee
  timezone: string
}): boolean {
  if (employeeJob.effectiveDates.length === 0) return true

  return (
    employeeJob.effectiveDates
      .map(period => isRolePeriodEffective({ period, timezone }))
      .filter(effective => effective === true).length > 0
  )
}

function isRolePeriodEffective({
  period,
  timezone,
}: {
  period: EffectiveDateRange
  timezone: string
}): boolean {
  const { start, end } = period
  return fromStringsToInterval(start, end).contains(
    DateTime.local({ zone: timezone }),
  )
}
export function isRoleEffectiveOnDate(
  employeeJob: JobEmployee,
  targetDate: DateTime,
): boolean {
  if (employeeJob.effectiveDates.length === 0) return true

  return (
    employeeJob.effectiveDates
      .map(({ start, end }) =>
        fromStringsToInterval(start, end).contains(targetDate),
      )
      .filter(effective => effective === true).length > 0
  )
}

export function isRoleEffectiveWithinPeriod(
  employeeJob: JobEmployee,
  { start, end }: { start: DateTime; end: DateTime },
): boolean {
  if (employeeJob.effectiveDates.length === 0) return true

  const targetPeriodInterval = Interval.fromDateTimes(start, end)

  return (
    employeeJob.effectiveDates
      .map(({ start: periodStart, end: periodEnd }) =>
        fromStringsToInterval(periodStart, periodEnd).overlaps(
          targetPeriodInterval,
        ),
      )
      .filter(effective => effective === true).length > 0
  )
}

export function activeRole(
  jobEmployee: JobEmployeeWithCompanySettings,
): boolean {
  return jobEmployee.state !== 'deleted'
}

export function timesheetRole(
  jobEmployee: JobEmployeeWithCompanySettings,
): boolean {
  return (
    jobEmployee.companySetting?.attendanceSettings.timeCaptureMode ===
    TimeCaptureModeKind.Manual
  )
}

export function clockRole(
  jobEmployee: JobEmployeeWithCompanySettings,
): boolean {
  return (
    jobEmployee.companySetting?.attendanceSettings.timeCaptureMode ===
    TimeCaptureModeKind.Auto
  )
}

export function managedRole(
  jobEmployee: JobEmployee,
  {
    locationManagementScope,
    departmentManagementScope,
  }: AppContextViewerManagementScope,
): boolean {
  if (locationManagementScope === null && !departmentManagementScope === null)
    return false

  if (
    locationManagementScope?.length === 0 &&
    departmentManagementScope?.length === 0
  )
    return true

  const hasLocationManagerAccess = Boolean(
    locationManagementScope &&
      locationManagementScope.includes(jobEmployee.branch.id),
  )

  const hasDepartmentManagerAccess = Boolean(
    departmentManagementScope &&
      departmentManagementScope.some(
        pair =>
          pair.departmentId === jobEmployee.department.id &&
          pair.locationId === jobEmployee.branch.id,
      ),
  )

  return hasLocationManagerAccess || hasDepartmentManagerAccess
}

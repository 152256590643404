import { createDeleteHandler, createLoadHandler } from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { DISCIPLINARY } from 'store/actions/employees/disciplinary'

export const DISCIPLINARY_RECORDS = 'disciplinaryRecords'

const initialState = Immutable({
  error: '',
  [DISCIPLINARY_RECORDS]: [],
  isLoading: false,
  isLoaded: false,
})

const handleError = (state, action) =>
  state.merge({
    error: action.payload,
    isLoading: false,
    isLoaded: false,
  })

const handlers = {
  [DISCIPLINARY.LOAD.REQUEST]: state => state.merge({ isLoading: true }),
  [DISCIPLINARY.LOAD.SUCCESS]: createLoadHandler(DISCIPLINARY_RECORDS, {
    withReplace: true,
  }),
  [DISCIPLINARY.LOAD.FAILURE]: (state, action) => handleError(state, action),
  [DISCIPLINARY.CREATE.REQUEST]: state => state.merge({ isLoading: true }),
  [DISCIPLINARY.CREATE.SUCCESS]: createLoadHandler(DISCIPLINARY_RECORDS, {
    withReplace: false,
  }),
  [DISCIPLINARY.CREATE.FAILURE]: (state, action) => handleError(state, action),
  [DISCIPLINARY.UPDATE.REQUEST]: state => state.merge({ isLoading: true }),
  [DISCIPLINARY.UPDATE.SUCCESS]: createLoadHandler(DISCIPLINARY_RECORDS, {
    withReplace: false,
  }),
  [DISCIPLINARY.UPDATE.FAILURE]: (state, action) => handleError(state, action),
  [DISCIPLINARY.DELETE.REQUEST]: state => state.merge({ isLoading: true }),
  [DISCIPLINARY.DELETE.SUCCESS]: createDeleteHandler(DISCIPLINARY_RECORDS, {
    addToState: {
      isLoading: false,
    },
  }),
  [DISCIPLINARY.CREATE.FAILURE]: (state, action) => handleError(state, action),
  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import { SchemaOf } from 'yup'

import { yup } from 'services/FormValidation'

import { AutoAssignConfigFormState } from './types'

export const validationSchema: SchemaOf<AutoAssignConfigFormState> = yup
  .object()
  .shape({
    minRestTime: yup.string(),
    minOvernightRestTime: yup.string(),
    dailyShiftLimit: yup.string(),
    availabilityAsHardConstraint: yup.boolean().required(),
    dateRange: yup
      .object()
      .shape({
        from: yup.string().required(),
        to: yup.string().required(),
      })
      .required(),
    solveDuration: yup.number().required(),
  })

export const defaultValues: AutoAssignConfigFormState = {
  minRestTime: undefined,
  minOvernightRestTime: undefined,
  dailyShiftLimit: undefined,
  dateRange: {
    from: '',
    to: '',
  },
  solveDuration: 3,
  availabilityAsHardConstraint: false,
}

import { useApolloMutation } from 'API/services/Apollo'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { ValidateShiftMutation, ValidateShiftMutationData } from './GraphQL'

export function useValidateShift() {
  const t = useI18n<
    typeof TRANSLATIONS.ruleValidation.shiftValidation.toasts.validateShift
  >('ruleValidation.shiftValidation.toasts.validateShift')

  const [validateShift, { loading, error }] = useApolloMutation<
    ValidateShiftMutationData,
    Gateway.MutationValidateShiftArgs
  >(ValidateShiftMutation, {
    silent: true,
    fetchPolicy: 'no-cache',
    onError: error => {
      showToast({
        type: 'error',
        title: t('error.title'),
        content: error.message || t('error.message'),
      })
    },
    onCompleted: data => {
      const hasValidations = data.validateShift.some(
        validation => validation.conflicts.length > 0,
      )

      if (hasValidations) {
        showToast({
          type: 'warning',
          title: t('warning.title'),
          content: t('warning.message'),
        })
        return
      }

      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const validate = async (input: Gateway.ValidateShiftInput) => {
    const result = await validateShift({
      variables: {
        input,
      },
    })

    return result.data?.validateShift ?? []
  }

  return {
    validate,
    validating: loading,
    hasError: !!error,
  }
}

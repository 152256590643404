import { useCallback } from 'react'

import { useStoreon } from 'storeon/react'

import { SolverPresetSettings } from 'pages/EmployerApp/Experimental/DemandForecasting/components/SolverDrawer/types'

import {
  DEMAND_FORECASTING_KEY,
  DemandForecastingEvents,
  DemandForecastingState,
} from './DemandForecasting'
import { applicationToStoreOn, storeOnToApplication } from './helpers'

export function useSolutionPresets() {
  const { [DEMAND_FORECASTING_KEY]: state, dispatch } = useStoreon<
    DemandForecastingState,
    DemandForecastingEvents
  >(DEMAND_FORECASTING_KEY)

  const addPreset = useCallback(
    (preset: SolverPresetSettings) =>
      dispatch('demandForecasting/preset/add', preset),
    [dispatch],
  )

  const updatePreset = useCallback(
    (preset: SolverPresetSettings) =>
      dispatch('demandForecasting/preset/update', preset),
    [dispatch],
  )
  const presets = state.solverPresets

  return { addPreset, updatePreset, presets }
}

export function useSolutions() {
  const { [DEMAND_FORECASTING_KEY]: state, dispatch } = useStoreon<
    DemandForecastingState,
    DemandForecastingEvents
  >(DEMAND_FORECASTING_KEY)

  const addSolution = useCallback(
    ({ data, preset }) =>
      dispatch('demandForecasting/solution/set', {
        data: applicationToStoreOn(data),
        preset,
      }),
    [dispatch],
  )

  const scheduled = storeOnToApplication(state.scheduled)
  const forecast = state.solution
    ? storeOnToApplication(state.solution)
    : undefined
  const { usedPreset } = state

  return { addSolution, scheduled, forecast, usedPreset }
}

import styled, { css } from 'styled-components'
import { color } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import {
  propGet,
  radius,
  when,
} from 'components/ui/__v3__/__utils__/styled-system'

import { Box } from '../Grid'

export const Arrow = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;

  &:before {
    position: absolute;
    width: 8px;
    height: 8px;
    content: '';
    transform: rotate(45deg);
    background: white;

    ${color}
  }
`

export const PopoverBlock = styled(Box)`
  background: ${themeGet('layout.bg')};
  border-radius: ${radius('normal')};
  box-shadow: ${themeGet('tooltip.shadow')};
  border-color: ${themeGet('layout.border')};
  border-style: solid;
  border-width: ${propGet('borderWidth')};
  overflow: auto;
  cursor: text;

  ${when(
    'floating',
    css`
      visibility: hidden;
      height: 0px;
    `,
  )};

  &[data-placement^='top'] ${Arrow} {
    bottom: -4px;
  }

  &[data-placement^='bottom'] ${Arrow} {
    top: -4px;
  }

  &[data-placement^='left'] ${Arrow} {
    right: -4px;
  }

  &[data-placement^='right'] ${Arrow} {
    left: -4px;
  }

  ${when(
    'animation',
    css`
      transform: translateY(4px) scale3d(0, 0, 0);
      transition: transform ${themeGet('layout.transition.fast')} ease-in-out;

      /* 💩 Poor performance decision 🤔 */
      will-change: transform;
    `,
  )};

  ${when(
    ['animation', 'animated'],
    css`
      transform: translateY(4px) scale3d(1, 1, 1);
    `,
  )};

  ${color}
`

PopoverBlock.defaultProps = {
  fontSize: 12,
  fontWeight: 'bold',
  borderWidth: 0,
  p: 2,
}

import * as colors from './colors'

export const layout = {
  accent: colors.CHARCOAL_100,
  bg: colors.WHITE,
  border: colors.ATHENS_GRAY_40,
  darkBorder: colors.CHARCOAL_60,
  dawamyPrimary: colors.DAWAMY,
  keywordsPrimary: colors.KEYWORDS,
  sobeysPrimary: colors.SOBEYS,
  riteaidPrimary: colors.RITEAID,
  gardaPrimary: colors.GARDA_100,
  disabled: colors.CHARCOAL_40,
  highlight: colors.PUMPKIN_40,
  lightBg: colors.PAPER_WHITE_20,
  darkerBg: colors.PAPER_WHITE_40,
  grayBg: colors.PAPER_WHITE_60,
  deviation: colors.ERROR,
  deviationLight: colors.ERROR_LIGHT,

  success: colors.SUCCESS,
  successLight: colors.SUCCESS_LIGHT,

  lightBorder: colors.CHARCOAL_20,
  lighterBorder: colors.PAPER_WHITE_100,

  blueishBg: colors.SLATE_GRAY,
  lightText: colors.CHARCOAL_60,

  negative: colors.NEGATIVE,
  negativeSoft: colors.NEGATIVE_SOFT,

  neutral: colors.NEUTRAL,
  neutralLight: colors.NEUTRAL_LIGHT,
  neutralSoft: colors.NEUTRAL_SOFT,

  yellowSoft: colors.YELLOW_SOFT,

  positive: colors.POSITIVE,
  positiveLight: colors.POSITIVE_LIGHT,
  primary: colors.SMASHED_PUMPKIN,
  shadow: { dp12: '0 5px 40px 5px rgba(0, 0, 0, 0.2)' },
  primaryShadow: `0px 0px 0px 2px ${colors.PUMPKIN_40}`,
  gradient: `linear-gradient(135deg, ${colors.PAPER_WHITE_20} 8%, ${colors.CHARCOAL_40} 18%, ${colors.PAPER_WHITE_20} 33%)`,
  text: colors.TEXT,
  transition: { fast: '0.1s', normal: '0.27s' },
  violetSoft: colors.VIOLET_SOFT,
  gray: colors.ATHENS_GRAY_40,
  default: 'inherit',
}

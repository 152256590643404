import validate from 'validate.js'

import has from 'lodash/has'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import set from 'lodash/set'
import startsWith from 'lodash/startsWith'

export const COMPONENT_TYPES = {
  CHECKBOX: 'checkbox',
  TEXT_FIELD: 'text-field',
  RADIO: 'radio',
  SELECT: 'select',
  DATE_PICKER: 'date-picker',
  FILE_UPLOAD: 'file-upload',
  POST_PICTURE: 'post-picture',
  CHECKBOXES: 'checkboxes',
}

export const FORM_STATES = {
  published: 'published',
  unpublished: 'unpublished',
}

export const RULE_TYPES = {
  presence: 'presence',
}

export const FIELDS = {
  id: 'id',
  name: 'name',
  description: 'description',
  label: 'label',
  placeholder: 'placeholder',
  isRequired: 'isRequired',
  initialValue: 'initialValue',
  validate: 'validate',
  options: 'options',
  isMulti: 'isMulti',
  hideField: 'hideField',
  dayOnly: 'dayOnly',
  createdAt: 'createdAt',
  hostFormId: 'host_form_id',
  createdBy: 'createdBy',
}

function nonEmpty(value) {
  return validate.single(value, { presence: { allowEmpty: false } })
}

export const fieldLevelValidators = {
  nonEmpty,
}

/**
 * Using this to transform the above validation rules to Validate.js
 * Will ignore disabled fields
 */

export function parseValidationSchema(fieldsArray) {
  const res = {}
  !isEmpty(fieldsArray) &&
    fieldsArray
      .filter(fieldConfig => !fieldConfig.hideField)
      .forEach(fieldConfig => {
        if (
          has(fieldConfig, [FIELDS.validate]) &&
          !isEmpty(fieldConfig[FIELDS.validate])
        ) {
          fieldConfig[FIELDS.validate].forEach(rule => {
            const { type, value } = rule

            if (type === RULE_TYPES.presence && value === true)
              set(res, [fieldConfig.name], { [type]: { allowEmpty: false } })
          })
        }
      })
  return res
}

export function getInitialValuesFromConfig(fieldsArray) {
  const res = {}
  !isEmpty(fieldsArray) &&
    fieldsArray.forEach(fieldConfig => {
      if (has(fieldConfig, [FIELDS.initialValue]))
        set(res, [fieldConfig.name], fieldConfig[FIELDS.initialValue])
    })
  return res
}

// [{
//   field: "text-field_b6df22fc-e2c5-4d95-93ef-535431791cd7",
//   value: "test-value"
// }]
export function parseInitialValuesFromSubmitted(submitted) {
  const res = {}

  !isEmpty(submitted) &&
    submitted.forEach(({ field, value }) => {
      if (
        (startsWith(field, COMPONENT_TYPES.FILE_UPLOAD) ||
          startsWith(field, COMPONENT_TYPES.POST_PICTURE)) &&
        value
      ) {
        set(
          res,
          field,
          isArray(value)
            ? map(value, (url, index) => urlToFile(url, index))
            : [urlToFile(value)],
        )
      } else {
        set(res, field, value)
      }
    })

  return res
}

function urlToFile(content, index = '') {
  return { name: `file ${index}`, content }
}

import { useCallback } from 'react'

import { useStoreon } from 'storeon/react'

import {
  MOVE_SCHEDULES_FROM_SHIFT_SET,
  MOVE_SCHEDULES_FROM_SHIFT_STATE_KEY,
  MOVE_SCHEDULES_FROM_SHIFT_UNSET,
} from './constants'
import {
  MoveSchedulesFromShiftEvents,
  MoveSchedulesFromShiftState,
} from './types'

export function useMoveSchedulesFromShiftState() {
  const {
    [MOVE_SCHEDULES_FROM_SHIFT_STATE_KEY]: backgroundTaskId,
    dispatch,
  } = useStoreon<MoveSchedulesFromShiftState, MoveSchedulesFromShiftEvents>(
    MOVE_SCHEDULES_FROM_SHIFT_STATE_KEY,
  )

  const addBackgroundTaskId = useCallback(
    (backgroundTaskId: string) =>
      dispatch(MOVE_SCHEDULES_FROM_SHIFT_SET, backgroundTaskId),
    [dispatch],
  )

  const clearBackgroundTaskId = useCallback(
    () => dispatch(MOVE_SCHEDULES_FROM_SHIFT_UNSET),
    [dispatch],
  )

  return { backgroundTaskId, addBackgroundTaskId, clearBackgroundTaskId }
}

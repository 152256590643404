import styled from 'styled-components'

import SvgIcons from 'assets/images/__v2__/svg'

import { SvgIcon } from 'components/ui/__v2__/SvgIcon'

const CancelCircle = styled(SvgIcon).attrs({
  glyph: SvgIcons.CancelCircle,
})``

CancelCircle.defaultProps = {
  size: 24,
}

export default CancelCircle

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Button } from 'components/ui/__v2__/Button'
import { Spacer } from 'components/ui/__v2__/Spacer'
import { Table } from 'components/ui/__v2__/Table'

import _ from 'i18n'

import useColumns from './columns'
import { ButtonsContainer, Container } from './styles'

function AddRolesTable({
  roles,
  onRemove,
  onClear,
  addDisabled,
  onAdd,
  withWage,
  withPeriods,
}) {
  const columns = useColumns({ withWage, withPeriods })

  const [selectedRows, setSelectedRows] = useState([])
  const [toggleCleared, setToggleCleared] = useState(false)

  const handleSelectRowClick = useCallback(state => {
    setSelectedRows(state.selectedRows)
  }, [])

  const handleClearClick = useCallback(
    event => {
      event.preventDefault()
      setSelectedRows([])
      setToggleCleared(false)
      onClear()
    },
    [onClear],
  )

  const handleDeleteClick = useCallback(
    event => {
      event.preventDefault()
      setToggleCleared(!toggleCleared)
      onRemove(selectedRows)
    },
    [toggleCleared, onRemove, selectedRows],
  )

  return (
    <Container>
      <ButtonsContainer>
        <Button
          disabled={addDisabled}
          ml="auto"
          mr={2}
          width={120}
          onClick={onAdd}
        >
          {_('actions.add')}
        </Button>

        <Button
          disabled={selectedRows.length === 0}
          width={120}
          onClick={handleDeleteClick}
        >
          {_('actions.delete')}{' '}
          {selectedRows.length > 0 && `(${selectedRows.length})`}
        </Button>
      </ButtonsContainer>

      <Spacer axis="vertical" size={8} />

      <Table
        clearSelectedRows={toggleCleared}
        columns={columns}
        data={roles ?? []}
        pagination
        selectableRows
        onSelectedRowsChange={handleSelectRowClick}
      />

      <Button
        ml="auto"
        mt={10}
        secondary
        width={120}
        onClick={handleClearClick}
      >
        {_('actions.clear')}
      </Button>
    </Container>
  )
}

AddRolesTable.defaultProps = {
  addDisabled: false,
  roles: [],
  onClear: noop,
  onAdd: noop,
  onRemove: noop,
}

AddRolesTable.propTypes = {
  addDisabled: PropTypes.bool,
  roles: PropTypes.array,
  withPeriods: PropTypes.bool.isRequired,
  withWage: PropTypes.bool.isRequired,
  onAdd: PropTypes.func,
  onClear: PropTypes.func,
  onRemove: PropTypes.func,
}

export default AddRolesTable

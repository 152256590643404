import { DateTime } from 'luxon'

import { TimeEvaluationLogSortingField } from 'constants/gatewayGraphQL'

import {
  TimeEvaluationLogAPIItem,
  TimeEvaluationLogFilter,
  TimeEvaluationLogSort,
} from './types'

export function timeEvaluationLogSortingToAPI(
  stateSorting: TimeEvaluationLogSort,
): Gateway.QueryTimeEvaluationLogsByCursorArgs['sorting'] {
  return [
    {
      direction: stateSorting,
      field: TimeEvaluationLogSortingField.CreatedAt,
    },
  ]
}

export function timeEvaluationLogFilterToAPI(
  stateFilters: TimeEvaluationLogFilter,
): Gateway.QueryTimeEvaluationLogsByCursorArgs['filter'] {
  const filter: Gateway.QueryTimeEvaluationLogsByCursorArgs['filter'] = {}

  if (stateFilters.createdAt.from && stateFilters.createdAt.to) {
    filter.createdAt = {
      between: [
        DateTime.fromISO(stateFilters.createdAt.from).toISO(),
        DateTime.fromISO(stateFilters.createdAt.to).plus({ days: 1 }).toISO(),
      ],
    }
  }

  if (stateFilters.event?.value) {
    filter.event = {
      eq: stateFilters.event.value,
    }
  }

  return filter
}

export function timeEvaluationLogToAPIITem(
  timeEvaluationLog: Gateway.TimeEvaluationLog,
): TimeEvaluationLogAPIItem {
  return {
    ...timeEvaluationLog,
    period: {
      start: timeEvaluationLog.period.start,
      end: timeEvaluationLog.period.end,
    },
    triggeredBy: {
      ...timeEvaluationLog.triggeredBy,
      coreId: Number(timeEvaluationLog.triggeredBy.coreId),
    },
  }
}

import React from 'react'

import { RiSearchLine } from 'react-icons/ri'

import styled, { css } from 'styled-components'
import { space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { either } from 'components/ui/__v3__/__utils__/styled-system'
import { Input, InputField } from 'components/ui/__v3__/Input'

const SearchIcon = styled(RiSearchLine)`
  fill: ${themeGet('search.icon.color')};
`

export const SearchInput = styled(Input).attrs({
  icon: <SearchIcon />,
  iconPlacement: 'left',
})`
  --padding: 24px;

  ${InputField} {
    ${either(
      'iconPlacement',
      'left',
      css`
        padding: 3px 4px 3px var(--padding);
      `,
      css`
        padding: 3px var(--padding) 3px 4px;
      `,
    )};
  }

  ${space};
`

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { darken, lighten } from 'polished'

import noop from 'lodash/noop'

import { when } from 'components/ui/__v3__/__utils__/styled-system'

import { ColorsContainer } from './styles'

// TODO: refactor & split components logic
const ColorsPalette = ({
  colors,
  onColorSelected,
  selectedColor,
  size,
  spread,
  disabled,
}) => {
  return (
    <ColorsContainer>
      {colors.map(color => (
        <CircleColor
          color={color}
          disabled={disabled}
          key={color.key}
          selected={selectedColor === color.key}
          size={size}
          spread={spread}
          onSelect={onColorSelected}
        />
      ))}
    </ColorsContainer>
  )
}

ColorsPalette.defaultProps = {
  selectedColor: null,
  size: null,
  spread: null,
  onColorSelected: noop,
  disabled: false,
}

ColorsPalette.propTypes = {
  colors: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  selectedColor: PropTypes.string,
  size: PropTypes.number,
  spread: PropTypes.number,
  onColorSelected: PropTypes.func,
}

export default ColorsPalette

export const Circle = styled.div`
  border-radius: 50%;
  background-color: ${p => p.color};
  position: relative;
  cursor: pointer;
  transition: all ${themeGet('transitionTime')};
  width: ${props => props.size || 16}px;
  height: ${props => props.size || 16}px;
  margin: ${props => props.spread || 7}px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: ${p => darken(0.1, p.color)};
  }

  ${p =>
    p.selected &&
    css`
      :before {
        content: '';
        position: absolute;
        left: 4px;
        top: 4px;
        width: 8px;
        height: 8px;
        background: white;
        border-radius: 8px;
      }
    `};

  ${when(
    'disabled',
    css`
      background-color: ${p => lighten(0.2, p.color)};
      cursor: not-allowed;

      &:hover {
        background-color: ${p => lighten(0.2, p.color)};
      }
    `,
  )};
`

export const ColorCircle = styled(Circle)`
  cursor: default;
  margin: 0;
`

class CircleColor extends PureComponent {
  onClick = () => {
    const { onSelect, color } = this.props
    return onSelect(color.key)
  }

  render() {
    const { color, size, selected, spread, disabled, ...rest } = this.props

    return (
      <Circle
        color={color.value}
        disabled={disabled}
        selected={selected}
        size={size}
        spread={spread}
        onClick={!disabled ? this.onClick : null}
        {...rest}
      />
    )
  }
}

CircleColor.defaultProps = {
  color: null,
  size: 16,
  spread: null,
  selected: false,
  disabled: false,
  onSelect: () => {},
}

CircleColor.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  size: PropTypes.number,
  spread: PropTypes.number,
  onSelect: PropTypes.func,
}

import { useUpdateRule } from './updateUpdateRule'
import { useCreateRule } from './useCreateRule'
import { useRulesByCursor } from './useRulesByCursor'

export * from './GraphQL'

export const RuleAPI = {
  byCursor: useRulesByCursor,
  create: useCreateRule,
  update: useUpdateRule,
}

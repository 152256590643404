import React from 'react'

import { LayoutProps } from 'styled-system'

import { ShiftValidationConflictAPIItem } from 'API/RuleValidation/GraphQL/Fragments/types'

import { Flex, Span } from 'components/ui/__v2__/Grid'

import { i18n } from 'i18n'

import { ShiftValidationConflictRuleConditions } from '../ShiftValidationConflictRuleConditions'
import { ShiftValidationConflictTypeIcon } from '../ShiftValidationConflictTypeIcon'

type Props = LayoutProps & {
  shiftConflict: ShiftValidationConflictAPIItem
  hideTypeIcon?: boolean
}

export function ShiftValidationConflictItem({
  hideTypeIcon,
  shiftConflict,
}: Props) {
  return (
    <Flex alignItems="flex-start" gap={2}>
      {!hideTypeIcon && (
        <Flex minWidth="18px">
          <ShiftValidationConflictTypeIcon
            conflictType={shiftConflict.conflictType}
            size="18px"
          />
        </Flex>
      )}

      <Flex flexDirection="column" gap={0}>
        <Span fontSize="15px" fontWeight={600}>
          {i18n(
            `workflowBuilder.palette.templates.${shiftConflict.rule.ruleType}.title`,
          )}
        </Span>

        <ShiftValidationConflictRuleConditions
          conflictType={shiftConflict.conflictType}
          rule={shiftConflict.rule}
        />
      </Flex>
    </Flex>
  )
}

import styled from 'styled-components'

// FIXME: To reusable?
export const Badge = styled.div`
  align-self: end;
  border: 1px solid ${({ theme }) => theme.colors.CHARCOAL_80};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.CHARCOAL_80};
  padding: 0 ${({ theme }) => theme.space[2]}px;
  font-size: ${({ theme }) => theme.fontSizes[0]}px;
  line-height: 16px;
  text-transform: uppercase;
`

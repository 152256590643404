import React from 'react'

export default function extendDataBySearch(props) {
  const {
    searchable,
    selectableRows,
    searchComponent: SearchComponent,
    data,
  } = props

  const search = (column, index) => (
    <SearchComponent
      column={column}
      data={data}
      index={index}
      searchable={searchable}
      selectableRows={selectableRows}
    />
  )

  return searchable
    ? [
        {
          id: 'search',
          cell: search,
          search: true,
        },
        ...data,
      ]
    : data
}

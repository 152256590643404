import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { when } from 'components/ui/__v3__/__utils__/styled-system'

const otherElementsHeight =
  48 + // app header
  65 + // page header
  50 + // page layout margin + borders
  80 + // table header
  4 + // possible horizontal page scrollbar
  64 // table pagination

const otherElementsWidth =
  48 + // sidebar
  50 + // page layout margin + borders
  4 // possible vertical page scrollbar

interface ContainerProps {
  width?: number
}

export const Container = styled.div<ContainerProps>`
  max-width: ${props => props.width ?? `calc(100vw - ${otherElementsWidth}px)`};
`

interface TableContainerProps {
  height?: string
}
export const TableContainer = styled.div<TableContainerProps>`
  overflow: auto;

  max-height: ${props =>
    props.height ?? `calc(100vh - ${otherElementsHeight}px)`};
`

export const TableHeaderContainer = styled.div`
  height: 80px;
  padding: 16px;

  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid ${themeGet('layout.border')};
`

export const TableHeaderTitle = styled.h2`
  font-size: 16px;
`

export const StyledTable = styled.table`
  width: 100%;

  border-collapse: collapse;
  border-style: hidden;
`

export const Thead = styled.thead`
  & > tr > th {
    z-index: ${themeGet('zIndices.stickyHeader')};
  }
`

export const Tr = styled.tr`
  vertical-align: top;
  border-bottom: 1px solid ${themeGet('layout.border')};
`
interface ThProps {
  cellBorder?: boolean
  noActions?: boolean
}
export const Th = styled.th<ThProps>`
  padding: 12px 16px;

  position: sticky;
  top: 0;

  text-align: start;
  vertical-align: top;

  background-color: ${themeGet('colors.WHITE')};
  box-shadow: inset 0 -1px 0 ${themeGet('layout.border')};

  ${when(
    'cellBorder',
    css`
      border: 1px solid ${themeGet('layout.border')};
    `,
  )}

  ${({ noActions }) =>
    noActions
      ? ''
      : css`
          :last-of-type {
            position: sticky;
            right: 0;

            background-color: ${themeGet('colors.WHITE')};
            z-index: ${themeGet('zIndicies.stickyHeader')};

            box-shadow: inset 2px -1px 0 ${themeGet('layout.border')};
          }
        `}
`

interface TdProps {
  cellBorder?: boolean
}
export const Td = styled.td<TdProps>`
  padding: 12px 16px;
  max-width: 240px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${when(
    'cellBorder',
    css`
      border: 1px solid ${themeGet('layout.border')};
    `,
  )}
`

interface TfProps {
  cellBorder?: boolean
}
export const Tf = styled.th<TfProps>`
  padding: 12px 16px 0;

  text-align: start;

  ${when(
    'cellBorder',
    css`
      border: 1px solid ${themeGet('layout.border')};
    `,
  )}
`

export const PaginationContainer = styled.div`
  height: 64px;
  padding: 0 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  border-top: 1px solid ${themeGet('layout.border')};
`

export const TableLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px; // Note: Urgent WOrkaround here as well
  height: 100%;
`

export const EmptyData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`

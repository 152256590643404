import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import { ClassifiersPicker, ClassifiersPickerProps } from '../ClassifiersPicker'

type Props<T extends FieldValues> = ControllerBaseProps<T> &
  LabelProps &
  Omit<ClassifiersPickerProps, 'value' | 'onSelect' | 'onBlur'>

export function FormClassifiersPicker<T extends FieldValues>({
  control,
  name,
  labelText,
  required,
  ...rest
}: Props<T>) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  return (
    <>
      {labelText && (
        <InputLabel>
          <LabelText labelText={labelText} required={required} />
        </InputLabel>
      )}
      <ClassifiersPicker
        {...rest}
        value={value}
        // @ts-ignore
        onChange={onChange}
      />
      {error && <Error error={error} />}
    </>
  )
}

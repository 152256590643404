import map from 'lodash/map'

import { areasToRelationships } from 'helpers/areas'
import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const LOAD_PAYROLL_RULES = createAsyncAction(
  'payrollRules/LOAD_PAYROLL_RULES',
)
export const UPDATE_PAYROLL_RULE = createAsyncAction(
  'payrollRules/UPDATE_PAYROLL_RULE',
)
export const DELETE_PAYROLL_RULE = createAsyncAction(
  'payrollRules/DELETE_PAYROLL_RULE',
)
export const CREATE_PAYROLL_RULE = createAsyncAction(
  'payrollRules/CREATE_PAYROLL_RULE',
)

const payrollRulesInclude = ['branches', 'departments', 'jobs', 'jobsEmployees']

export const loadPayrollRules = () => (dispatch, getState) =>
  dispatch(
    apiCall({
      endpoint: `/companies/${getCompanyId(getState())}/payroll_rules`,
      method: 'GET',
      types: LOAD_PAYROLL_RULES,
      query: {
        include: payrollRulesInclude.join(),
      },
    }),
  )

export const createPayrollRule = ({ rule, areas = {} }) => (
  dispatch,
  getState,
) =>
  dispatch(
    apiCall({
      endpoint: `/companies/${getCompanyId(getState())}/payroll_rules`,
      method: 'POST',
      query: {
        data: {
          attributes: { ...rule, global: areas.global },
          relationships: areasToRelationships(areas),
        },
        include: payrollRulesInclude.join(),
      },
      types: CREATE_PAYROLL_RULE,
    }),
  )

export const updatePayrollRule = ({ ruleId, rule, areas = {} }) => (
  dispatch,
  getState,
) =>
  dispatch(
    apiCall({
      endpoint: `/companies/${getCompanyId(
        getState(),
      )}/payroll_rules/${ruleId}`,
      method: 'PATCH',
      query: {
        data: {
          type: 'payrollRules',
          attributes: { ...rule, global: areas.global },
          relationships: areasToRelationships(areas, { withEmpty: true }),
        },
        include: payrollRulesInclude.join(),
      },
      types: UPDATE_PAYROLL_RULE,
    }),
  )

export const deletePayrollRules = deletedIds => (dispatch, getState) =>
  dispatch(
    apiCall({
      endpoint: `/companies/${getCompanyId(
        getState(),
      )}/relationships/payroll_rules`,
      method: 'DELETE',
      query: { data: map(deletedIds, id => ({ id, type: 'payrollRules' })) },
      types: DELETE_PAYROLL_RULE,
      payload: {
        deletedIds,
      },
    }),
  )

import React from 'react'
import PropTypes from 'prop-types'

import styled, { DefaultTheme, ThemeProvider } from 'styled-components'

const Base = styled.div.attrs(({ theme }) => ({
  fontFamily: theme.font,
}))``

type Props = {
  theme: DefaultTheme
  [x: string]: $TSFixMe
}
function Provider({ theme, ...rest }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <Base {...rest} />
    </ThemeProvider>
  )
}

Provider.propTypes = {
  theme: PropTypes.object.isRequired,
}

export default Provider

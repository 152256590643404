import { toastr } from 'react-redux-toastr'

import { all, fork, put, race, take } from 'redux-saga/effects'

import _ from 'i18n'

import {
  LOAD_LEAVES_SUMMARY,
  loadLeavesSummary,
  UPDATE_LEAVE_BALANCE,
} from 'store/actions/employees/leavesSummary'

function* watchLoadFailure() {
  while (true) {
    const error = yield take(LOAD_LEAVES_SUMMARY.FAILURE)

    if (error)
      toastr.error(_('leavesSummary.loadError'), {
        disableCloseButtonFocus: true,
      })
  }
}

/**
 * We request update of table in case of success
 * That will also trigger modal to close
 */

function* watchAdjustBalance() {
  while (true) {
    const { success } = yield race({
      success: take(UPDATE_LEAVE_BALANCE.SUCCESS),
      error: take(UPDATE_LEAVE_BALANCE.FAILURE),
    })
    if (success) {
      const { employeeId, year } = success.payload
      yield put(loadLeavesSummary({ employeeId, year }))
      toastr.success(_('leavesSummary.successToast'), {
        disableCloseButtonFocus: true,
      })
    } else {
      toastr.error(_('common.somethingWentWrong'), {
        disableCloseButtonFocus: true,
      })
    }
  }
}

export default function* root() {
  yield all([fork(watchLoadFailure), fork(watchAdjustBalance)])
}

import { useCallback, useMemo, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import {
  SortingDirection,
  TimeEntrySummarySortingField,
} from 'constants/gatewayGraphQL'

import {
  TimeEntrySummariesByCursorQuery,
  TimeEntrySummariesByCursorQueryData,
} from './GraphQL'

type Args = {
  paging: CursorPage
  filter: Gateway.TimeEntryFilter
  sorting?: Gateway.TimeEntrySummarySorting[]
}

export function useTimeEntrySummariesByCursor({
  paging,
  filter,
  sorting,
}: Args) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const [loadingMore, setLoadingMore] = useState(false)

  const { data, loading, refetch, fetchMore } = useApolloQuery<
    TimeEntrySummariesByCursorQueryData,
    Gateway.QueryTimeEntrySummariesByCursorArgs
  >(TimeEntrySummariesByCursorQuery, {
    variables: {
      sorting: sorting ?? [
        {
          field: TimeEntrySummarySortingField.EmployeeId,
          direction: SortingDirection.Desc,
        },
      ],
      filter,
      paging: cursorPageToGraphqlPaging(paging),
    },

    onCompleted(data) {
      const newPageMeta = data.timeEntrySummariesByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const timeEntrySummaries = useMemo(
    () => data?.timeEntrySummariesByCursor?.edges.map(edge => edge.node) ?? [],
    [data?.timeEntrySummariesByCursor],
  )

  const loadMore = useCallback(
    async (lastCursor: string) => {
      setLoadingMore(true)
      await fetchMore({
        variables: {
          paging: { limit: paging.size, startingAfter: lastCursor },
        },
      })

      setLoadingMore(false)
    },
    [fetchMore, paging.size],
  )

  return {
    timeEntrySummaries,
    loadingInitial: loading && !loadingMore,
    refetch,
    currentPageMeta,
    loadMore,
    loadingMore,
  }
}

export const CUSTOM_LEAVE_DAY_JSON_API_TYPE = 'customLeaveDays'

export const CustomLeaveDayQueryKey = {
  all: () => [CUSTOM_LEAVE_DAY_JSON_API_TYPE],

  picker: (search?: string) => [
    CUSTOM_LEAVE_DAY_JSON_API_TYPE,
    'picker',
    search,
  ],
}

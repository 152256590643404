import React from 'react'
import { components } from 'react-select'

import { FiChevronDown } from 'react-icons/fi'

export const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <FiChevronDown />
  </components.DropdownIndicator>
)

DropdownIndicator.propTypes = {
  ...components.DropdownIndicator.propTypes,
}

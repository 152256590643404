import { useCallback } from 'react'

import { useMutation } from '@tanstack/react-query'

import { areasToRelationships } from 'helpers/areas'

import { useAppContext, useI18n } from 'hooks'

import coreApiCall from 'services/API/coreApi'
import { showToast } from 'services/Toasts'

type Params = {
  global: boolean
  dayHourEquivalence: number
  areas: {
    locations: { id: string }[] | null
    departments: { id: string }[] | null
    jobs: { id: string }[] | null
  }
}

export function useUpdateDayHourEquivalence() {
  const { companyId } = useAppContext()
  const t = useI18n('dayHourEquivalence')

  const updateDayHourEquivalence = useCallback(
    ({ global, dayHourEquivalence, areas }: Params) => {
      const endpoint = `/companies/${companyId}/settings`
      return coreApiCall({
        endpoint,
        method: 'POST',
        query: {
          data: {
            attributes: {
              leaveSettings: {
                dayHourEquivalence,
              },
              global,
            },
            relationships: areasToRelationships(areas),
          },
        },
      })
    },
    [companyId],
  )

  const { isLoading, isSuccess, mutateAsync } = useMutation({
    mutationFn: updateDayHourEquivalence,
    onSuccess: data => {
      if (data.ok) {
        showToast({
          type: 'success',
          title: t('title'),
          content: t('saveSuccess'),
        })
      } else {
        showToast({
          type: 'error',
          title: t('title'),
          content: t('saveError'),
        })
      }
    },
  })

  return {
    updateDayHourEquivalence: mutateAsync,
    isUpdated: isSuccess,
    isUpdating: isLoading,
  }
}

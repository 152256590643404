import { DateTime, Interval } from 'luxon'

import { random } from 'lodash'

const MONTHS_PLUS_MINUS = 2

const MIN_SCHEDULED_DAYS_AFTER_TODAY = 14
const MAX_SCHEDULED_DAYS_AFTER_TODAY = 28

export const PRESENTATION_RANGE_INTERVAL = Interval.fromDateTimes(
  DateTime.now().minus({ months: MONTHS_PLUS_MINUS }),
  DateTime.now().plus({ months: MONTHS_PLUS_MINUS }),
)

export const SCHEDULED_RANGE_INTERVAL = Interval.fromDateTimes(
  DateTime.now().minus({ months: MONTHS_PLUS_MINUS }),
  DateTime.now().plus({
    days: random(
      MIN_SCHEDULED_DAYS_AFTER_TODAY,
      MAX_SCHEDULED_DAYS_AFTER_TODAY,
      false,
    ),
  }),
)

export const HOLIDAY_PEAKS_INTERVALS = [
  Interval.fromDateTimes(
    DateTime.fromISO('2023-12-20'),
    DateTime.fromISO('2023-12-25'),
  ),
]

export const PROMOTION_PEAKS_INTERVALS = [
  Interval.fromDateTimes(
    DateTime.fromISO('2023-11-10'),
    DateTime.fromISO('2023-11-12'),
  ),
]

import { CheckIcon } from './CheckIcon'
import { ExpandHandle } from './ExpandHandle'
import { FullName } from './FullName'
import { Groups } from './Groups'
import { Timetypes } from './Timetypes'
import { Weekday } from './Weekday'
import { WorkSchedules } from './WorkSchedules'

export const Cells = {
  FullName,
  Groups,
  ExpandHandle,
  CheckIcon,
  Timetypes,
  Weekday,
  WorkSchedules,
}

export const IndependentColors = [
  { key: 'Crimson', value: '#db1334' },
  { key: 'Mandy', value: '#e2464e' },
  { key: 'Christine', value: '#e05f0f' },
  { key: 'Corn', value: '#d6cf06' },
  { key: 'Gold Tips Sour', value: '#e2ca0d' },
  { key: 'Atlantis', value: '#87d337' },
  { key: 'Emerald', value: '#30cc52' },
  { key: 'Aquamarine Blue Light', value: '#5de2cc' },
  { key: 'Blue Chill Light', value: '#0e6e9e' },
  { key: 'Blue Chill', value: '#1183ad' },
  { key: 'Jacksons Purple', value: '#1c3082' },
  { key: 'Windsor Dark', value: '#430972' },
  { key: 'Windsor', value: '#530984' },
  { key: 'Electric Violet Dark', value: '#5d09ef' },
  { key: 'Heliotrope', value: '#ea57ed' },
  { key: 'Sulu Light', value: '#d6ef8b' },
  { key: 'Blue Gem', value: '#5c11bf' },
  { key: 'Resolution Blue', value: '#011e70' },
  { key: 'Razzmatazz', value: '#d8087e' },
  { key: 'Ecstasy', value: '#fc7f19' },
  { key: 'Keppel', value: '#30a3a5' },
  { key: 'Sulu', value: '#cef291' },
  { key: 'Sahara Sand', value: '#e5f490' },
  { key: 'Silver Chalice', value: '#a3a3a3' },
  { key: 'Amethyst', value: '#8759d1' },
  { key: 'Christine Bright', value: '#e25d0f' },
  { key: 'Shamrock', value: '#34bc96' },
  { key: 'Malachite', value: '#13db52' },
  { key: 'Aquamarine Blue', value: '#63e2d6' },
  { key: 'Tickle Me Pink', value: '#ff82af' },
  { key: 'Mandys Pink', value: '#f7b9b4' },
  { key: 'Wewak', value: '#f4a6a9' },
  { key: 'Cabaret', value: '#d6425d' },
  { key: 'Vesuvius', value: '#a0380b' },
  { key: 'Shiraz', value: '#a0092c' },
  { key: 'Pohutukawa', value: '#89021d' },
  { key: 'Tabasco', value: '#9b290f' },
  { key: 'Cherokee', value: '#fcde9f' },
  { key: 'Sandy brown Light', value: '#f2ae6f' },
  { key: 'Navajo White', value: '#ffe3ad' },
  { key: 'Cape Honey', value: '#fce9a4' },
  { key: 'Rajah', value: '#f9a872' },
  { key: 'Fire', value: '#b53f00' },
  { key: 'Buddha Gold', value: '#d39104' },
  { key: 'Indochine', value: '#bc7203' },
  { key: 'Drover', value: '#fcf8a9' },
  { key: 'Golden Sand', value: '#f4dd77' },
  { key: 'Drover Dark', value: '#fceea6' },
  { key: 'Dolly', value: '#fff187' },
  { key: 'Marzipan', value: '#f7e394' },
  { key: 'Reef', value: '#c0ffb2' },
  { key: 'Gold Tips', value: '#efbf10' },
  { key: 'Corn Sour', value: '#e8ca09' },
  { key: 'Galliano', value: '#d3b806' },
  { key: 'Regal Blue', value: '#045170' },
  { key: 'Blue Stone', value: '#005e63' },
  { key: 'Atoll', value: '#08686d' },
  { key: 'Arapawa', value: '#0b1a70' },
  { key: 'Mindaro', value: '#dff774' },
  { key: 'Elm', value: '#208280' },
  { key: 'St Tropaz', value: '#2a5693' },

  { key: 'Mauve Dim', value: '#d2aefc' },
  { key: 'Biloba Flower', value: '#b47eea' },
  { key: 'Mauve Sour', value: '#d3afff' },
  { key: 'Electric Violet Light', value: '#9a04d6' },
  { key: 'Electric Violet Sour', value: '#b501bf' },
  { key: 'Red Violet', value: '#cc10b9' },
  { key: 'Deep Blush', value: '#e053aa' },
  { key: 'Lavender', value: '#cc69ea' },
  { key: 'Mauve Light', value: '#e9a2f9' },
  { key: 'Pigment Indigo', value: '#47027c' },
  { key: 'Pastel Pink', value: '#ffcce3' },
  { key: 'Mine Shaft', value: '#333333' },
  { key: 'Cod Gray', value: '#191919' },
  { key: 'Silver Dark', value: '#7f8c78' },
  { key: 'Silver Chalice Clay', value: '#dfaaa3' },
  { key: 'Alto', value: '#cecece' },
  { key: 'Piper', value: '#c65223' },
  { key: 'Brick Red', value: '#cc374d' },
  { key: 'Amaranth', value: '#ea3f5b' },
  { key: 'Mojo', value: '#c44244' },
  { key: 'Starship', value: '#daf23e' },
  { key: 'Mint Green', value: '#99fc6f' },
  { key: 'Bright Turquoise Light', value: '#28efd1' },
  { key: 'Turquoise', value: '#55e8a6' },
  { key: 'Goldenrod', value: '#fcdc71' },
  { key: 'Alpine', value: '#ba9037' },
  { key: 'Sandy brown', value: '#f49d66' },
  { key: 'Ronchi', value: '#eab156' },
  { key: 'Danube', value: '#5495c4' },
  { key: 'Purple Heart', value: '#523dbf' },
  { key: 'Mariner', value: '#2374dd' },
  { key: 'Bright Turquoise', value: '#0cc4f2' },
  { key: 'Aquamarine Blue Sour', value: '#6cdbdd' },
  { key: 'Blizzard Blue', value: '#96dfea' },
  { key: 'Viking', value: '#68c2dd' },
  { key: 'Perano', value: '#b0b2f4' },
  { key: 'Cornflower Blue', value: '#8271f2' },
  { key: 'Portage', value: '#8383ea' },
  { key: 'Portage Dim', value: '#9695f4' },
]

import { DateTime } from 'luxon'

import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import pick from 'lodash/pick'
import toString from 'lodash/toString'

import { ACCESS_LEVEL, FILE_UPLOAD_KINDS } from 'constants/ids'
import { PAGE_SIZE } from 'constants/pagination'

import { createAsyncAction } from 'helpers/redux'

import apiCall, { resolveNonOk } from 'services/API'
import Shared from 'services/Shared'

import { getCompanyId } from 'store/selectors/viewer'

import { includeTimeEntries } from './timeCards'

export const RESET_FACE_ID = createAsyncAction('employee/RESET_FACE_ID')
export const UNLOAD_EMPLOYEE = 'employee/UNLOAD_EMPLOYEE'
export const SELECT_MANAGER = 'employee/SELECT_MANAGER'
export const LOAD_EMPLOYEE_BY_ID = createAsyncAction('employee/LOAD_BY_ID')
/**
 * @deprecated
 * */
export const UPDATE_PROFILE = createAsyncAction('viewer/UPDATE_PROFILE')
// prettier-ignore
export const LOAD_EMPLOYEE_BY_ID_SILENT = createAsyncAction('employee/LOAD_BY_ID_SILENT')
export const SELECT_DEPARTMENT = 'schedule/SELECT_DEPARTMENT'
export const FILTER_EMPLOYEES = 'employee/FILTER'
export const CHANGE_POSITION = createAsyncAction('employee/CHANGE_POSITION')
export const CHANGE_WAGE = createAsyncAction('employee/CHANGE_WAGE')
// prettier-ignore
export const CHANGE_EFFECTIVE_DATES = createAsyncAction('employee/CHANGE_EFFECTIVE_DATES')
// prettier-ignore
export const RELOCATION_REQUEST = createAsyncAction('employee/RELOCATION_REQUEST')
export const REMOVE_POSITION = createAsyncAction('employee/REMOVE_POSITION')
export const CREATE_ROLE = createAsyncAction('employee/CREATE_ROLE')
export const SENT_INVITE = createAsyncAction('employee/SENT_INVITE')
/**
 * @deprecated
 */
export const EMPLOYEE_UPLOAD_AVATAR = createAsyncAction(
  'employee/UPLOAD_AVATAR',
)
/**
 * @deprecated
 */
export const EMPLOYEE_UPDATE_AVATAR = createAsyncAction(
  'employee/UPDATE_AVATAR',
)

export const EMPLOYEE_SITE_UPDATE = createAsyncAction('employee/UPDATE_SITE')

export const EMPLOYEE_DATA_READY = 'employee/DATA_READY'
// prettier-ignore
export const REMOVE_USER_FROM_COMPANY = createAsyncAction('employee/REMOVE_USER_FROM_COMPANY')
// prettier-ignore
export const LOAD_EMPLOYEE_STATISTICS = createAsyncAction('employee/LOAD_EMPLOYEE_STATISTICS')

// prettier-ignore
export const ADD_EMPLOYEES_JOB_TAGS = createAsyncAction('employee/ADD_EMPLOYEES_JOB_TAG')
// prettier-ignore
export const DELETE_EMPLOYEES_JOB_TAGS = createAsyncAction('employee/DELETE_EMPLOYEES_JOB_TAGS')
export const EDIT_HIRING_DATE = createAsyncAction('employees/EDIT_HIRING_DATE')
export const EDIT_EXPORT_CODE = createAsyncAction('employees/EDIT_EXPORT_CODE')
// prettier-ignore
export const UPDATE_EMPLOYEE_CUSTOM_CV_FIELDS = createAsyncAction('employees/UPDATE_EMPLOYEE_CUSTOM_CV_FIELDS')
export const ARCHIVE_EMPLOYEE = createAsyncAction('employees/ARCHIVE_EMPLOYEE')
// prettier-ignore
export const CHECK_ACTIVE_TIMER = createAsyncAction('employees/CHECK_ACTIVE_TIMER')
export const TOGGLE_ARCHIVE = 'employees/TOGGLE_ARCHIVE'
export const PROCESS_FAILURE_INVITE = 'employees/PROCESS_FAILURE_INVITE'
// prettier-ignore
export const REDIRECT_TO_CHANGE_ACCESS_LEVEL = 'employees/REDIRECT_TO_CHANGE_ACCESS_LEVEL'
// prettier-ignore
export const REACTIVATE_EMPLOYEE = createAsyncAction('employees/REACTIVATE_EMPLOYEE')
// prettier-ignore
export const CHANGE_EMPLOYEE_PASSWORD = createAsyncAction('employees/CHANGE_EMPLOYEE_PASSWORD')
// prettier-ignore
export const EXPORT_PEOPLE_CSV = createAsyncAction('employees/EXPORT_PEOPLE_CSV')

export const loadEmployeeIncludes = [
  'user.profile',
  'user.faceIdPhoto',
  'profile.languages',
  'profile.experiences',
  'profile.profileAvatar',
  'jobs',
  'jobsEmployees.employee',
  'jobsEmployees.jobsTags',
  'jobsEmployees.job.jobsTags',
  'branch.jobs',
  'branch.departments',
  'branches',
  'departments',
  'manager.departments',
  'manager.allManagedBranches',
  'manager.allManagedDepartments',
  'manager.managedDepartments.branch',
  'supervisors.user.profile',
  'site',
  'sync',
]

export const loadEmployee = (employeeId, { withLoading } = {}) => {
  return apiCall({
    endpoint: `/employees/${employeeId}`,
    types: !withLoading ? LOAD_EMPLOYEE_BY_ID_SILENT : LOAD_EMPLOYEE_BY_ID,
    query: {
      include: loadEmployeeIncludes.join(),
    },
  })
}
//

export const unloadEmployee = () => ({
  type: UNLOAD_EMPLOYEE,
})

export const selectManager = managerId => ({
  type: SELECT_MANAGER,
  payload: { managerId },
})

export const loadEmployeeStatistics = (employeeId, from, to) => {
  return apiCall({
    endpoint: `/employees/${employeeId}`,
    types: LOAD_EMPLOYEE_STATISTICS,
    query: {
      filter: {
        start_at: from,
        finish_at: to,
        role_id: 2,
      },
    },
  })
}

export const addEmployeesJobTags = (jobsEmployeesId, tagIds) => {
  if (isEmpty(tagIds)) {
    return
  }

  const data = map(tagIds, tagId => ({
    id: tagId,
    type: 'jobsTags',
  }))

  // eslint-disable-next-line consistent-return
  return apiCall({
    endpoint: `/jobs_employees/${jobsEmployeesId}/relationships/tags`,
    method: 'POST',
    query: {
      data,
      include: 'jobsTags,employee',
    },
    types: ADD_EMPLOYEES_JOB_TAGS,
  })
}

export const deleteEmployeesJobTags = (jobsEmployeesId, tagIds) => {
  if (isEmpty(tagIds)) {
    return
  }

  const data = map(tagIds, tagId => ({
    id: tagId,
    type: 'jobsTags',
  }))

  // eslint-disable-next-line consistent-return
  return apiCall({
    endpoint: `/jobs_employees/${jobsEmployeesId}/relationships/tags`,
    method: 'DELETE',
    query: { data },
    types: DELETE_EMPLOYEES_JOB_TAGS,
    payload: {
      deletedId: first(tagIds),
      relationId: jobsEmployeesId,
    },
  })
}

export const changeWage = ({ wage, wageType, jobsEmployeeId, showToast }) => {
  return apiCall({
    method: 'PATCH',
    endpoint: `/jobs_employees/${jobsEmployeeId}`,
    types: CHANGE_WAGE,
    query: {
      include: 'employee,job',
      data: {
        type: 'jobs_employees',
        attributes: { wage, wageType },
      },
    },
    payload: {
      showToast: showToast ?? true,
    },
  })
}

export const removePosition = ({ employee, roleId }) => {
  return apiCall({
    method: 'DELETE',
    endpoint: `/jobs_employees/${roleId}`,
    types: REMOVE_POSITION,
    payload: {
      relationId: employee?.id,
      deletedId: roleId,
    },
  })
}

export const changeEffectiveDates = ({ jobId, effectiveDates, showToast }) => {
  return apiCall({
    method: 'PATCH',
    endpoint: `/jobs_employees/${jobId}`,
    types: CHANGE_EFFECTIVE_DATES,
    query: {
      include: 'withSameJobName',
      data: {
        type: 'jobs_employees',
        attributes: { effectiveDates },
      },
    },
    payload: {
      showToast: showToast ?? true,
    },
  })
}

export const createRole = ({
  attributes,
  employeeId,
  jobId,
  branchId,
  departmentId,
}) => {
  return apiCall({
    method: 'POST',
    endpoint: `/employees/${employeeId}/jobs_employees`,
    types: CREATE_ROLE,
    query: {
      data: {
        attributes,
        relationships: {
          job: {
            data: {
              id: jobId,
              type: 'jobs',
            },
          },
          branch: {
            data: {
              id: branchId,
              type: 'branches',
            },
          },
          department: {
            data: {
              id: departmentId,
              type: 'departments',
            },
          },
        },
      },
      include: 'employee.branches,job',
    },
  })
}

export const inviteWithJobs = (companyId, attributes) => {
  const employeeId = attributes?.employeeId

  const options = {
    method: 'POST',
    endpoint: `/companies/${companyId}/invitation_employees`,
    types: employeeId ? CREATE_ROLE : SENT_INVITE,
    query: {
      data: {
        attributes,
      },
      include: 'jobsEmployees,sync',
    },
  }

  if (employeeId) {
    options.payload = {
      relationId: employeeId,
    }
  }

  return apiCall(options)
}

export const removeUserFromCompany = ({ userId, deletedId }) => (
  dispatch,
  getState,
) => {
  const companyId = getCompanyId(getState())

  return dispatch(
    apiCall({
      method: 'DELETE',
      endpoint: `/companies/${companyId}/users/${userId}`,
      types: REMOVE_USER_FROM_COMPANY,
      payload: { deletedId },
    }),
  )
}

// Dispatches by employee saga only
export const employeeDataReady = () => ({
  type: EMPLOYEE_DATA_READY,
})

export const sendInvite = ({
  companyId,
  first: firstName,
  last: lastName,
  email,
  branch,
  department,
  job,
  accessLevel,
  type,
  birthday,
  employeeCode,
  gender,
  hiredAt,
  phone,
  timezone,
}) => {
  const invite = {
    type: 'invitations',
    attributes: {
      firstName,
      lastName,
      email,
      accessLevel,
      birthday,
      employeeCode,
      gender,
      hiredAt,
      phone,
      timezone,
    },
    relationships: {},
  }

  invite.relationships.company = {
    data: {
      type: 'companies',
      id: companyId,
    },
  }

  if (accessLevel === ACCESS_LEVEL.employee) {
    invite.relationships.job = {
      data: {
        type: 'jobs',
        id: job,
      },
    }
    invite.relationships.branch = {
      data: {
        type: 'branches',
        id: branch,
      },
    }
    invite.relationships.department = {
      data: {
        type: 'departments',
        id: department,
      },
    }
  }

  if (accessLevel === ACCESS_LEVEL.manager) {
    if (type === 'branches') {
      invite.relationships.branch = {
        data: {
          type: 'branches',
          id: branch,
        },
      }
    }

    if (type === 'departments') {
      invite.relationships.branch = {
        data: {
          type: 'branches',
          id: branch,
        },
      }

      invite.relationships.department = {
        data: {
          type: 'departments',
          id: department,
        },
      }
    }
  }

  return apiCall({
    method: 'POST',
    endpoint: `/invitations`,
    types: SENT_INVITE,
    query: {
      data: invite,
      include: 'user.profile',
    },
    payload: {
      accessLevel,
    },
  })
}

/** @deprecated */
export const uploadEmployeeAvatar = (file, employee) => {
  return apiCall({
    endpoint: '/files',
    method: 'POST',
    file,
    types: EMPLOYEE_UPLOAD_AVATAR,
    fileParams: {
      kind: FILE_UPLOAD_KINDS.profileAvatar,
    },
    payload: {
      employee: pick(employee, 'id'),
    },
  })
}

export const editHiringDate = ({ employeeId, hiringDate }) => {
  return apiCall({
    method: 'PATCH',
    types: EDIT_HIRING_DATE,
    endpoint: `/employees/${employeeId}`,
    query: {
      data: {
        type: 'employees',
        attributes: {
          hired_at: DateTime.fromISO(hiringDate).toISODate(),
        },
      },
    },
  })
}

export const editExportCode = ({ employeeId, exportCode }) => {
  return apiCall({
    method: 'PATCH',
    types: EDIT_EXPORT_CODE,
    endpoint: `/employees/${employeeId}`,
    query: {
      data: {
        type: 'employees',
        attributes: {
          timesheets_export_code: toString(exportCode),
        },
      },
    },
  })
}

/** @deprecated */
export const updateEmployeeAvatar = ({ employee, avatarId }) => {
  return apiCall({
    method: 'PATCH',
    endpoint: `/employees/${employee.id}/profile`,
    types: EMPLOYEE_UPDATE_AVATAR,
    query: {
      data: {
        relationships: {
          profileAvatar: {
            data: {
              type: 'profileAvatar',
              id: avatarId,
            },
          },
        },
      },
      include: 'profileAvatar',
    },
  })
}

/**
 * @deprecated
 * Used only for old features that was designed for Wiptec
 * Should be removed after removing old features or moved to the Gateway
 */
export const updateCustomCvFields = ({ profileId, fields }) => (
  dispatch,
  getState,
) => {
  const companyId = getCompanyId(getState())

  if (!companyId) {
    return resolveNonOk()
  }

  return apiCall({
    method: 'PATCH',
    endpoint: `/companies/${companyId}/profiles/${profileId}/custom_cv_fields`,
    types: UPDATE_EMPLOYEE_CUSTOM_CV_FIELDS,
    query: {
      include:
        'customCvField,profile.customCvFieldValues,profile.customCvFieldValues.customCvField,attachments',
      data: map(fields, field => {
        const result = {
          id: field.id,
          attributes: {
            value: field.value,
            effectiveDates: field.effectiveDates,
          },
        }

        if (field.attachments) {
          result.relationships = {
            attachments: {
              data: map(field.attachments, attachment => ({
                id: attachment.id,
                type: 'files',
              })),
            },
          }
        }

        return result
      }),
    },
  })(dispatch, getState)
}

export const archiveEmployee = ({
  employeeId,
  archivingReason = '',
  effectiveEndDate = null,
} = {}) => {
  return apiCall({
    method: 'POST',
    types: ARCHIVE_EMPLOYEE,
    endpoint: `/employees/${employeeId}/archive`,
    query: {
      data: {
        attributes: {
          archivingReason,
          effectiveEndDate,
        },
      },
    },
  })
}

export const changeUserPassword = ({ employeeId, password }) => {
  return apiCall({
    method: 'PATCH',
    types: CHANGE_EMPLOYEE_PASSWORD,
    endpoint: `/employees/${employeeId}/password`,
    query: {
      data: {
        attributes: {
          password,
        },
      },
    },
  })
}

export const toggleArchive = showArchive => ({
  type: TOGGLE_ARCHIVE,
  payload: {
    showArchive,
  },
})

export const processFailureInvite = () => ({
  type: PROCESS_FAILURE_INVITE,
})

export const reactivateEmployee = ({
  employeeId,
  effectiveStartDate = null,
}) => {
  return apiCall({
    method: 'POST',
    types: REACTIVATE_EMPLOYEE,
    endpoint: `/employees/${employeeId}/activate`,
    query: {
      data: {
        attributes: {
          effectiveStartDate,
        },
      },
    },
  })
}

export const resetFaceId = ({ fileId, userId, employeeId }) => {
  return apiCall({
    endpoint: `/employees/${employeeId}/face_id_photo`,
    method: 'DELETE',
    types: RESET_FACE_ID,
    payload: {
      deletedId: fileId,
      relationId: userId,
    },
  })
}

export const exportCSV = ({ filters, actionType, leaves, year }) => (
  dispatch,
  getState,
) => {
  const companyId = getCompanyId(getState())
  const locale = Shared.getStoreOnLocale()

  let payload = {
    companyId,
    locale,
    filters,
    year,
  }

  if (!isEmpty(leaves)) {
    payload = {
      ...payload,
      leaves,
    }
  }

  return dispatch(
    apiCall({
      method: 'POST',
      endpoint: '/token',
      types: actionType,
      payload,
      isRaw: true,
    }),
  )
}

export const updateEmployeeSupervisors = ({ employeeId, managersIds }) => {
  return apiCall({
    method: 'PATCH',
    endpoint: `/employees/${employeeId}/relationships/supervisors`,
    query: {
      include: 'user,supervisors',
      data: {
        relationships: {
          managers: {
            data: map(managersIds, id => ({ id, type: 'managers' })),
          },
        },
      },
    },
  })
}

export const updateEmployeeSite = ({ employeeId, siteId }) => {
  return apiCall({
    method: 'PATCH',
    types: EMPLOYEE_SITE_UPDATE,
    endpoint: `/employees/${employeeId}`,
    query: {
      include: 'user,site',
      data: {
        relationships: {
          site: {
            data: {
              type: 'site',
              id: siteId,
            },
          },
        },
      },
    },
  })
}

export const conflictingTimeEntries = ({ jobId, effectiveDates, page }) => {
  const filteredEffectiveDates = effectiveDates.map(date => {
    return { start: date.start, end: date.end }
  })
  return apiCall({
    method: 'GET',
    endpoint: `/jobs_employees/${jobId}/conflicting_time_entries`,
    query: {
      include: includeTimeEntries.join(),
      effective_dates: filteredEffectiveDates,
      target_jobs_employee_id: jobId,
      page,
    },
    paged: true,
  })
}

export const timeEntriesJobs = ({ timeEntryId }) => {
  return apiCall({
    method: 'GET',
    endpoint: `/time_entries/${timeEntryId}/jobs_candidates`,
    query: {
      include: 'departments',
      page: { size: PAGE_SIZE['100'] },
    },
    paged: true,
  })
}

// Deluxe integration //
// Note: We must keep these in Redux as we want the returned syncs to change the cetral state

export const START_DELUXE_EMPLOYEE_SYNC = createAsyncAction(
  'employees/START_DELUXE_EMPLOYEE_SYNC',
)
export const LOAD_DELUXE_EMPLOYEE_SYNC = createAsyncAction(
  'employees/LOAD_DELUXE_EMPLOYEE_SYNC',
)

export const startDeluxeEmployeeSync = employeeId => {
  return apiCall({
    endpoint: `/employees/${employeeId}/sync`,
    types: START_DELUXE_EMPLOYEE_SYNC,
    method: 'POST',
  })
}

export const loadDeluxeEmployeeSync = employeeId => {
  return apiCall({
    endpoint: `/employees/${employeeId}/sync`,
    types: LOAD_DELUXE_EMPLOYEE_SYNC,
    method: 'GET',
  })
}

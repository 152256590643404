import React from 'react'
import { components } from 'react-select'

import { Flex } from 'components/ui/Grid'
import { Spinner } from 'components/ui/Spinner'

export const LoadingIndicator = () => (
  <Flex alignItems="center" height="32px">
    <Spinner primary />
  </Flex>
)

LoadingIndicator.propTypes = {
  ...components.LoadingIndicator.propTypes,
}

import { Branch, Company, CustomLeave, Employee, User } from 'Types/app'
import { AreasFilter, SelectOption } from 'Types/common'

import { SortingDirection } from 'constants/gatewayGraphQL'
import {
  LeaveDurationTypes,
  ScheduleConflictActionTypes,
  TimeoffStates,
} from 'constants/ids'

export type TimeoffsSortingField = 'created_at' | 'start_date' | 'end_date'

export interface TimeoffsFilter {
  areas: AreasFilter
  customLeaves?: SelectOption[]
  leaveType?: LeaveDurationTypes
  state?: TimeoffStates
}

export interface TimeoffSorting {
  field: TimeoffsSortingField
  direction: SortingDirection
}

export enum CreateTimeoffKinds {
  Assign = 'assign',
  Request = 'request',
}

type LeaveUsageQuantity = {
  days: number
  hours: number
}
export type TimeoffAPIItem = {
  id: string

  comment: string | null
  reason: string | null
  externalId: string | null
  requestId: string
  document: $TSFixMe

  startTime: string
  endTime: string
  startAt: string
  finishAt: string

  branch: Branch
  company: Company
  customLeaveDay: CustomLeave
  employee: Employee

  quantity: LeaveUsageQuantity
  state: TimeoffStates // TODO: define JsonAi enum without 'all' value

  partial: boolean
  canDelete: boolean

  excludedDays: string[]

  createdAt: string
  createdBy: User | null

  updatedAt: string
  lastUpdatedBy: User | null

  processedAt: string | null
  processedBy: User | null

  deletedAt: string | null
}

export type MoveTimeoffParams = {
  timeoffId: string
  attributes: {
    startDate: string
    comment?: string
  }
}

export type RejectTimeoffParams = {
  timeoffId: string
  comment?: string
}

export type ApproveTimeoffParams = {
  timeoffId: string
  comment?: string
  scheduleConflictType: ScheduleConflictActionTypes
}

type BaseCreateParams = {
  attributes: {
    reason?: string
    scheduleConflictType?: ScheduleConflictActionTypes // Think of making it requried for assign
  }
  relationships: {
    employee: {
      data: {
        type: 'employees'
        id: string
      }
    }
    custom_leave_day: {
      data: {
        type: 'customLeaveDays'
        id: string
      }
    }
    branch?: {
      data: {
        type: 'branches'
        id: string
      }
    }
    document?: {
      data: {
        type: 'files'
        id: string
      }
    }
  }
}
type PartialCreateParams = BaseCreateParams & {
  attributes: {
    partial: false
    startAt: string
    finishAt: string
    excludedDates: string[]
  }
}
type WholeCreateParams = BaseCreateParams & {
  attributes: {
    partial: true
    startTime: string
    endTime: string
  }
}

export type CreateTimeoffParams = PartialCreateParams | WholeCreateParams

export interface TimeoffsQueryParams {
  sort: {
    field: TimeoffsSortingField
    direction: SortingDirection
  }
  filters: {
    branch_id?: JsonApi.AreasFilter['branch_id']
    department_id?: JsonApi.AreasFilter['department_id']
    employee_id?: JsonApi.AreasFilter['employee_id']
    job_id?: JsonApi.AreasFilter['job_id']

    custom_leave_day_id?: { in: string[] }
    partial?: { eq: boolean | undefined }
    state?: { eq: TimeoffStates | undefined }
  }
}

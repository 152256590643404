import { ScheduledAndTotalPositionsQuery } from 'API/Metrics/GraphQL'
import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'

type QueryProps = Pick<QueryHookOptions, 'skip' | 'pollInterval'> | undefined
export function useScheduledAndTotalPositions(
  variables: Gateway.QueryScheduledAndTotalCountsArgs,
  queryProps?: QueryProps,
) {
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'scheduledAndTotalCounts'>,
    Gateway.QueryScheduledAndTotalCountsArgs
  >(ScheduledAndTotalPositionsQuery, {
    variables,
    fetchPolicy: 'network-only',
    skip: queryProps?.skip,
    pollInterval: queryProps?.pollInterval,
  })

  return {
    scheduledAndTotalPositions: data?.scheduledAndTotalCounts || [],
    loading,
    refetch,
  }
}

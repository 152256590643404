import get from 'lodash/get'

/** @deprecated Is not updated after the user is created
 * Use @link 'helpers/date getBrowserTimezone to get current client timezone instead
 */
export const getTimeZone = viewer =>
  get(viewer, ['settings', 'timezone']) || 'local'

export const getViewerEmployeeId = viewer =>
  get(viewer, 'employees', [])[0]?.id ?? null

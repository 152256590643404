import React from 'react'

import { SpaceProps } from 'styled-system'

import { Flex } from 'components/ui/__v2__/Grid'
import { Popover } from 'components/ui/__v2__/Popover'
import FindCoverBlockedIcon from 'components/ui/Icons/find-cover-blocked'

import { i18n } from 'i18n'

type Props = SpaceProps

export function CoversBlockedInfo({ ...rest }: Props) {
  return (
    // @ts-ignore
    <Popover
      content={i18n('schedule.covers.blockedTooltip')}
      interactive
      offset={[0, 5]}
      trigger="mouseenter"
    >
      <Flex {...rest}>
        <FindCoverBlockedIcon />
      </Flex>
    </Popover>
  )
}

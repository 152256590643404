import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { Branch } from 'Types/app'
import { SelectOptionWithId } from 'Types/common'

import { LocationsPicker } from 'components/blocks/__v2__'
import { Flex } from 'components/ui/__v2__/Grid'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'
import { LabelProps } from 'components/ui/__v3__/Input/types'

type LocationPickerOption = {
  id: string
  label: string
  value: string
  location: Branch
}

export type FormLocationPickerReturn = SelectOptionWithId

type Props<
  T extends FieldValues,
  IsMulti extends boolean
> = ControllerBaseProps<T> &
  LabelProps & {
    isDisabled?: boolean
    isMulti?: IsMulti
    isClearable?: boolean
    withPortal?: boolean
    placeholder?: string
    onChange?: (
      data: IsMulti extends true
        ? FormLocationPickerReturn[]
        : FormLocationPickerReturn | null,
    ) => void
  }

// NOTE: We need to thin because react-hook-form is not able to clone large objects
export function thinFormLocation(
  option: LocationPickerOption,
): FormLocationPickerReturn {
  return {
    id: option.id,
    label: option.label,
    value: option.value,
  }
}

/** @deprecated Pay attention, not all functionality */
export function FormLocationPicker<
  T extends FieldValues,
  IsMulti extends boolean = false
>({
  control,
  name,
  labelText,
  required,
  isDisabled,
  isMulti,
  isClearable,
  withPortal,
  onChange,
  ...rest
}: Props<T, IsMulti>) {
  const {
    field: { onChange: onChangeForm, onBlur, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  const handleChange = onChange || onChangeForm

  const handleSelect = (
    data: LocationPickerOption | LocationPickerOption[] | null,
  ) => {
    if (!data) {
      handleChange(
        null as IsMulti extends true
          ? FormLocationPickerReturn[]
          : FormLocationPickerReturn | null,
      )
      return
    }

    if (Array.isArray(data)) {
      handleChange(
        data.map(thinFormLocation) as IsMulti extends true
          ? FormLocationPickerReturn[]
          : FormLocationPickerReturn | null,
      )
    } else {
      handleChange(
        thinFormLocation(data) as IsMulti extends true
          ? FormLocationPickerReturn[]
          : FormLocationPickerReturn | null,
      )
    }
  }

  return (
    <Flex flexDirection="column">
      {labelText && (
        <InputLabel>
          <LabelText labelText={labelText} required={required} />
        </InputLabel>
      )}
      <LocationsPicker
        {...rest}
        // @ts-ignore
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        value={value}
        withPortal={withPortal}
        onBlur={onBlur}
        onChange={handleSelect}
      />
      {error && <Error error={error} />}
    </Flex>
  )
}

import Immutable from 'seamless-immutable'

import head from 'lodash/head'
import keys from 'lodash/keys'
import map from 'lodash/map'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  CLOCK_IN,
  CLOCK_OUT,
  INIT,
  INIT_DONE,
  LOAD_ACTIVE_TIMER,
  LOAD_SCHEDULES,
  RESTORE_TRACKING,
  SET_PAUSE_TIMER_ID,
  SET_SCHEDULE_ID,
  SET_TIME,
  SET_TIMING,
  START_TIMER,
  STOP_PAUSE_TIMER,
  STOP_TIMER,
  TOGGLE_TAB,
} from 'store/actions/employeeApp/timeClock'

const initialState = Immutable({
  entryId: null,
  scheduleId: null,
  pauseTimerId: null,

  schedules: [],

  syncInProgress: false,
  syncPending: false,

  startTime: null,
  endTime: null,
  time: null,

  isLoading: true,
  isLoaded: false,
  isLogTab: false,
})

const handlers = {
  [INIT]: state =>
    state.merge({
      isLoading: true,
      isLoaded: false,
    }),

  [LOAD_SCHEDULES.SUCCESS]: (state, { payload }) => {
    const obj = payload.data.meta
    const { data } = obj[Object.keys(obj)[0]]
    const schedules = map(data, 'id')

    return state.merge({
      schedules,
      isLoading: false,
    })
  },

  [LOAD_ACTIVE_TIMER.SUCCESS]: (state, { payload }) => {
    const entryId = head(keys(payload.data.timeEntries)) || null

    return state.merge({ entryId })
  },

  [START_TIMER.SUCCESS]: (state, { payload }) => {
    const entryId = head(keys(payload.data.timeEntries)) || null

    return state.merge({
      syncInProgress: true,
      syncPending: false,
      entryId,
    })
  },

  [START_TIMER.FAILURE]: state => {
    return state.merge({
      syncInProgress: false,
      syncPending: false,
    })
  },

  [STOP_TIMER.SUCCESS]: state => state.merge({ entryId: null }),

  [SET_SCHEDULE_ID]: (state, { payload: { scheduleId } }) =>
    state.merge({ scheduleId }),

  [SET_TIME]: (state, { payload: { time } }) => state.merge({ time }),

  [SET_TIMING]: (state, { payload: { startTime, endTime } }) =>
    state.merge({ startTime, endTime }),

  [RESTORE_TRACKING]: state => state.set('syncInProgress', true),

  [CLOCK_IN]: state => state.set('syncPending', true),

  [CLOCK_OUT]: state => state.set('syncInProgress', false),

  [STOP_TIMER.FAILURE]: state => state.set('syncInProgress', true),

  [SET_PAUSE_TIMER_ID]: (state, { payload: { pauseTimerId } }) =>
    state.merge({ pauseTimerId }),

  [STOP_PAUSE_TIMER.SUCCESS]: state => state.merge({ pauseTimerId: null }),

  [TOGGLE_TAB]: state => state.merge({ isLogTab: !state.isLogTab }),

  [INIT_DONE]: state =>
    state.merge({
      isLoading: false,
      isLoaded: true,
    }),

  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers)

// import { loadShifts } from 'store/actions/employees/shifts'

import { all, fork, put, select, /* race */ take } from 'redux-saga/effects'

import get from 'lodash/get'

import {
  NOTIFICATION_LINKS,
  NOTIFICATION_STATE,
  NOTIFICATION_STATUS,
  NOTIFICATION_TYPE,
} from 'constants/notifications'

import { getWeekFromDate } from 'helpers/date'

import { loadSchedules } from 'store/actions/employees/schedules'
import {
  CALL_NOTIFICATION_LINK,
  LOAD_NOTIFICATION,
  LOAD_NOTIFICATION_COVER,
  LOAD_NOTIFICATION_TRADE_SHIFT,
  loadNotificationCover,
  loadNotificationTimeOff,
  loadNotificationTradeShift,
  notificationReady,
  // LOAD_NOTIFICATION_TIMEOFF,
  // LOAD_NOTIFICATION_ABSENCE,
  updateNotification,
} from 'store/actions/notifications'
import { getNotificationWeekSchedules } from 'store/selectors/employees/schedules'
import {
  getNotification,
  getNotificationShiftCover,
  getNotificationShiftTrade,
} from 'store/selectors/notifications'

function* fetchNotification() {
  while (true) {
    yield take(LOAD_NOTIFICATION.SUCCESS)
    const notification = yield select(getNotification)
    const { tradeShift, cover } = NOTIFICATION_TYPE
    const { type } = notification
    const show = get(notification, 'links.show', null)
    if (type === tradeShift && !!show) {
      yield put(loadNotificationTradeShift(show))
    } else if (type === cover && !!show) {
      yield put(loadNotificationCover(show))
    } else {
      yield put(notificationReady())
    }
  }
}

function* handleLoadNotificationTradeShift() {
  while (true) {
    yield take(LOAD_NOTIFICATION_TRADE_SHIFT.SUCCESS)
    const notificationShiftTrade = yield select(getNotificationShiftTrade)
    const notificationWeekSchedules = yield select(getNotificationWeekSchedules)
    if (notificationWeekSchedules.length <= 1) {
      const shiftStartAt = get(
        notificationShiftTrade,
        'schedule.shiftsJob.shift.startAt',
        '',
      )
      const week = shiftStartAt ? getWeekFromDate(shiftStartAt) : ''
      if (week) {
        yield put(loadSchedules(week.start, week.end))
      }
    }
    yield put(notificationReady())
  }
}

function* handleLoadNotificationCover() {
  while (true) {
    yield take(LOAD_NOTIFICATION_COVER.SUCCESS)
    const notificationShiftCover = yield select(getNotificationShiftCover)
    const notificationWeekSchedules = yield select(getNotificationWeekSchedules)
    if (notificationWeekSchedules.length <= 1) {
      const shiftStartAt = get(
        notificationShiftCover,
        'schedule.shiftsJob.shift.startAt',
        '',
      )
      const week = shiftStartAt ? getWeekFromDate(shiftStartAt) : ''
      if (week) {
        yield put(loadSchedules(week.start, week.end))
      }
    }
    yield put(notificationReady())
  }
}

function* handeleSuccessNotificationAction() {
  while (true) {
    const { payload } = yield take(CALL_NOTIFICATION_LINK.SUCCESS)
    const { notification, linkAction } = payload
    const { accept } = NOTIFICATION_LINKS
    const {
      type,
      links: { show },
    } = notification
    const { accepted, rejected } = NOTIFICATION_STATUS
    yield put(
      updateNotification({
        state: NOTIFICATION_STATE.processed,
        ...(linkAction && {
          status: linkAction === accept ? accepted : rejected,
        }),
      }),
    )
    if (
      type === NOTIFICATION_TYPE.timeOff &&
      linkAction === NOTIFICATION_LINKS.accept
    ) {
      yield put(loadNotificationTimeOff(show))
    }
  }
}

export default function* rootEmployees() {
  yield all([
    fork(fetchNotification),
    fork(handeleSuccessNotificationAction),
    fork(handleLoadNotificationTradeShift),
    fork(handleLoadNotificationCover),
  ])
}

import { createDeleteHandler, createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  ADD_DEPARTMENT,
  DELETE_DEPARTMENT,
  LOAD_DEPARTMENTS,
  SAVE_DEPARTMENT,
} from 'store/actions/company/departments'
import { ADD_DEPARTMENTS_AND_ROLES } from 'store/actions/locations'
import { LOAD_VIEWER_BRANCHES } from 'store/actions/viewer'

const initialState = Immutable({
  departments: [],
  isLoading: false,
  isLoaded: false,
})

const handlers = {
  [LOAD_DEPARTMENTS.REQUEST]: state => state.merge({ isLoading: true }),
  [LOAD_DEPARTMENTS.SUCCESS]: createLoadHandler('departments'),

  [ADD_DEPARTMENT.SUCCESS]: createLoadHandler('departments', {
    withLoading: false,
  }),
  [ADD_DEPARTMENTS_AND_ROLES.SUCCESS]: createLoadHandler('departments', {
    withLoading: false,
  }),
  [SAVE_DEPARTMENT.SUCCESS]: createLoadHandler('departments', {
    withLoading: false,
  }),

  [DELETE_DEPARTMENT.SUCCESS]: createDeleteHandler('departments'),

  [LOAD_VIEWER_BRANCHES.SUCCESS]: createLoadHandler('departments'),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

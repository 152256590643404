import find from 'lodash/find'
import get from 'lodash/get'

import {
  buildAlreadyInvitedErrorMessage,
  INVITE_ERROR_ID,
} from 'constants/employees'
import { ACCESS_LEVEL } from 'constants/ids'

import { getFirstEntity } from 'helpers/redux'
import { getTranslate } from 'helpers/translate'

import _ from 'i18n'

import { UPDATE_BRANCH } from 'store/actions/company/branches'
import {
  CHANGE_EFFECTIVE_DATES,
  CHANGE_EMPLOYEE_PASSWORD,
  CHANGE_WAGE,
  SENT_INVITE,
} from 'store/actions/employees/employees'
import {
  COPY_PREV_WEEK_DONE,
  UPDATE_SHIFTS_JOBS,
} from 'store/actions/employees/shifts'
import {
  ADD_POST,
  ADD_REACTION,
  DELETE_POST,
  EDIT_POST,
} from 'store/actions/news'
import { UPDATE_USER_SETTINGS } from 'store/actions/viewer'

// Add action type and handle it with function with action received or just string
export default {
  [ADD_POST.SUCCESS]: ({ payload }) => {
    const post = getFirstEntity(payload.data.posts)
    const isAnnouncement = get(post, 'attributes.announcement')
    return isAnnouncement
      ? _('news.successfullyCreated.announcement')
      : _('news.successfullyCreated.post')
  },
  [EDIT_POST.SUCCESS]: () => _('news.postSavedSuccessfully'),
  [DELETE_POST.SUCCESS]: () => _('news.postDeletedSuccessfully'),

  [SENT_INVITE.SUCCESS]: () => _('news.invitationSentSuccessfully'),
  [SENT_INVITE.FAILURE]: ({ payload }) => {
    const errors = get(payload, 'errors', [])
    const error = find(errors, item => item.id === INVITE_ERROR_ID)
    if (error) {
      const accessLevel = get(error, 'meta.accessLevel')
      // in other case we show modal with redirect to change access level
      if (accessLevel === ACCESS_LEVEL.owner) {
        return buildAlreadyInvitedErrorMessage(accessLevel)
      }
    }
    return null
  },

  [CHANGE_WAGE.SUCCESS]: ({ payload }) => {
    const showToast = get(payload, 'showToast', true)
    return showToast ? _('news.wageUpdatedSuccessfully') : null
  },

  [CHANGE_EFFECTIVE_DATES.SUCCESS]: ({ payload }) => {
    const showToast = get(payload, 'showToast', true)
    return showToast ? _('employees.effectivePeriodsChanged') : null
  },

  [CHANGE_EMPLOYEE_PASSWORD.SUCCESS]: () => {
    return _('employees.passwordSuccessfullyChanged')
  },

  [CHANGE_EMPLOYEE_PASSWORD.FAILURE]: ({ payload }) =>
    getTranslate({
      text: get(payload, 'errors[0].detail'),
      prefix: 'employee',
    }),

  [UPDATE_BRANCH.SUCCESS]: ({ payload }) => {
    const isProfileSettingsPage = get(payload, 'isProfileSettingsPage', false)
    const isLocationRules = get(payload, 'isLocationRules', false)
    if (isProfileSettingsPage) {
      return _('news.settingsUpdatedSuccessfully')
    }
    if (isLocationRules) {
      return _('settings.locationRulesChanged')
    }
    return null
  },
  [UPDATE_BRANCH.FAILURE]: ({ payload }) =>
    getTranslate({
      text: get(payload, 'errors[0].detail'),
      prefix: 'branch',
    }),

  [UPDATE_SHIFTS_JOBS.FAILURE]: ({ payload }) =>
    getTranslate({ text: get(payload, 'errors[0].detail'), prefix: 'shifts' }),

  [COPY_PREV_WEEK_DONE]: () => _('shifts.hasBeenCopied'),

  [UPDATE_USER_SETTINGS.SUCCESS]: () => _('news.settingsUpdatedSuccessfully'),

  [ADD_REACTION.posts.FAILURE]: () => _('news.emojiAlreadyUsed'),
  [ADD_REACTION.comments.FAILURE]: () => _('news.emojiAlreadyUsed'),
}

import React from 'react'

import { flexRender, Table } from '@tanstack/react-table'

import { Spinner } from 'components/ui/Spinner'

import _ from 'i18n'

import { EmptyData, TableLoadingContainer, Td, Th, Tr } from '../styles'

interface TableBodyProps {
  table: Table<any>
  cellBorder?: boolean
  noActions?: boolean
  loading?: boolean
  total: number
}

export function TableBody({
  table,
  cellBorder,
  noActions,
  loading,
  total,
}: TableBodyProps) {
  if (loading) {
    return (
      <tbody>
        <tr>
          <td colSpan={20}>
            <TableLoadingContainer>
              <Spinner primary size={42} />
            </TableLoadingContainer>
          </td>
        </tr>
      </tbody>
    )
  }

  if (!total) {
    return (
      <tbody>
        <tr>
          <td colSpan={20}>
            <EmptyData>{_('common.tableNoData')}</EmptyData>
          </td>
        </tr>
      </tbody>
    )
  }

  return (
    <tbody>
      {table.getRowModel().rows.map(row => (
        <Tr key={row.id}>
          {row.getVisibleCells().map((cell, i, arr) =>
            i !== arr.length - 1 ? (
              <Td cellBorder={cellBorder} key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Td>
            ) : (
              <Th cellBorder={cellBorder} key={cell.id} noActions={noActions}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Th>
            ),
          )}
        </Tr>
      ))}
    </tbody>
  )
}

import divide from 'lodash/divide'
import fill from 'lodash/fill'
import floor from 'lodash/floor'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import map from 'lodash/map'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import reduce from 'lodash/reduce'
import replace from 'lodash/replace'
import unionBy from 'lodash/unionBy'
import upperFirst from 'lodash/upperFirst'

import { getFirstEntity } from 'helpers/redux'

export const getStringFromNumber = value =>
  isNumber(value) ? value.toString() : ''

const convertDailyValuesToFloat = items =>
  map(items, item => convertDailyValueToFloat(item))

export const convertDailyValueToFloat = item => {
  const parsed = parseFloat(
    replace(replace(replace(item, '$', ''), '%', ''), new RegExp(',', 'g'), ''),
  )
  return isNaN(parsed) ? null : parsed
}

export const fillDailyData = fillItem => fill(new Array(7), fillItem)

export const getDailySum = items =>
  reduce(
    convertDailyValuesToFloat(items),
    (sum, item) => (isNaN(item) ? sum : sum + item),
    null,
  )

const getAverageCostDivider = items =>
  reduce(items, (divider, item) => (item ? divider + 1 : divider), 0)

export const getAverageCost = cost => {
  const costSum = getDailySum(cost)
  const costDivider = getAverageCostDivider(cost)

  let averageCost = ''

  if (costSum) {
    averageCost = floor(divide(costSum, costDivider), 2)
  }
  return averageCost
}

export const calcDailyLaborCost = (dayRevenueAmount, actualAmount) =>
  isNumber(dayRevenueAmount) && isNumber(actualAmount)
    ? floor(divide(actualAmount, dayRevenueAmount) * 100, 2)
    : ''

export const calcDailyPredictedLaborCost = (
  dayTargetLaborCostAmount,
  dayPredictedRevenue,
) =>
  isNumber(dayPredictedRevenue) && isNumber(dayTargetLaborCostAmount)
    ? floor(divide(dayTargetLaborCostAmount * dayPredictedRevenue, 100), 2)
    : ''

// Expected Labor % = (expected amount $ * 100% ) / Predicted revenue $,
export const calcDailyExpectedLabor = (expectedAmount, dayPredictedRevenue) => {
  if (isNumber(expectedAmount) && isNumber(dayPredictedRevenue)) {
    const el = expectedAmount * 100
    return floor(divide(el, dayPredictedRevenue), 2)
  }
  return ''
}

export const dailySalesRelationAddHandler = (relationType, relationName) => (
  state,
  { payload },
) => {
  const { relationId, data } = payload
  const { id, type } = getFirstEntity(data[relationType])

  return merge({}, state, {
    [relationId]: {
      relationships: {
        [relationName]: {
          data: [
            ...state[relationId].relationships[relationName].data,
            { id, type },
          ],
        },
      },
    },
  })
}

export const UPDATE_TYPE = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
}

export const BULK_BUDGET_RELATION_TYPES = {
  sales: 'sales',
  laborCosts: 'laborCosts',
  both: 'both',
}

export const bulkBudgetRelationAddHandler = (
  relationType = BULK_BUDGET_RELATION_TYPES.both,
) => (state, { payload }) => {
  const {
    sales: salesType,
    laborCosts: laborCostsType,
    both,
  } = BULK_BUDGET_RELATION_TYPES
  const { data, relationId } = payload

  const dataHashes = get(data, 'hashes', {})

  const updatedData = reduce(
    dataHashes,
    (updated, hash) => {
      forEach(BULK_BUDGET_RELATION_TYPES, type => {
        const item = get(hash, `attributes.${type}`)
        const itemId = get(item, 'id', null)
        if (itemId !== null) {
          const attributes =
            relationType === both ? {} : { attributes: omit(item, ['id']) }
          updated[type][itemId] = {
            id: itemId.toString(),
            type: `branch${upperFirst(type)}`,
            ...attributes,
          }
        }
      })
      return updated
    },
    { sales: {}, laborCosts: {} },
  )

  switch (relationType) {
    case salesType:
    case laborCostsType:
      return merge({}, state, updatedData[relationType])
    case both: {
      const unionRelationById = type => {
        const updatedRelationData = map(updatedData[type])
        const prevRelationData = get(
          state,
          `[${relationId}].relationships.${type}.data`,
          [],
        )
        return unionBy(prevRelationData, updatedRelationData, 'id')
      }
      return merge({}, state, {
        [relationId]: {
          relationships: {
            sales: {
              data: unionRelationById(salesType),
            },
            laborCosts: {
              data: unionRelationById(laborCostsType),
            },
          },
        },
      })
    }
    default: {
      return state
    }
  }
}

import React, { useCallback, useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useField } from 'react-final-form'
import { toastr } from 'react-redux-toastr'
import PropTypes from 'prop-types'

import concat from 'lodash/concat'
import flatten from 'lodash/flatten'
import noop from 'lodash/noop'

import { ValidationError } from 'components/ui/__v2__/Form/styles'
import { Box, Flex, Span } from 'components/ui/__v2__/Grid'
import { IconError } from 'components/ui/__v3__/Input/Errors'
import { Spinner } from 'components/ui/Spinner'

import { RASTER_PICS } from 'constants/fs'

import _ from 'i18n'

import ImageContainer from './ImageContainer'
import { DragZone, PhotoIcon } from './styles'

function FormPostImage({
  multiple,
  name,
  required,
  uploadFiles,
  isReadOnly,
  onBlur,
  ...rest
}) {
  /**
   * Will a view to make this component multiple once the design is ready
   * For now - just a single one
   */

  const [isUploading, setUploading] = useState(false)
  const { input, meta } = useField(name)

  const files = useMemo(() => flatten([input.value || []]), [input.value])

  const handleChange = useCallback(
    value => {
      input.onChange(value)
      onBlur && onBlur()
    },
    [input, onBlur],
  )

  const handleRemoveFile = useCallback(
    id => {
      handleChange(files.filter(file => file.id !== id))
    },
    [files, handleChange],
  )

  const handleDrop = useCallback(
    async inputFiles => {
      try {
        setUploading(true)
        const resultFiles = await uploadFiles(inputFiles)
        if (multiple) {
          handleChange(concat(files || [], resultFiles))
        } else {
          handleChange(resultFiles)
        }
      } catch (error) {
        toastr.error(_('common.somethingWentWrong'))
      } finally {
        setUploading(false)
      }
    },
    [multiple, files, uploadFiles, handleChange],
  )

  const canRemove = useMemo(() => {
    if (multiple) {
      return !(required && files?.length === 1)
    }
    return !required
  }, [multiple, required, files])

  return (
    <Flex flexDirection="column">
      <Flex>
        <Dropzone
          accept={RASTER_PICS}
          disabled={isUploading || isReadOnly}
          multiple={multiple}
          onDrop={handleDrop}
        >
          {({ getRootProps, getInputProps }) => (
            <Box
              cursor="pointer"
              {...getRootProps()}
              {...rest.rootProps}
              {...rest}
            >
              <input {...getInputProps()} {...rest.InputProps} />
              {files?.length > 0 ? (
                <ImageContainer
                  canRemove={canRemove && !isReadOnly}
                  file={files[0]}
                  onRemoveClick={handleRemoveFile}
                />
              ) : (
                <DragZone>
                  {isUploading ? (
                    <Span>
                      <Spinner /> {_('common.uploading')}
                    </Span>
                  ) : (
                    <>
                      <PhotoIcon />
                      <Span>
                        {multiple
                          ? _('common.selectImages')
                          : _('common.selectImage')}
                      </Span>
                    </>
                  )}
                </DragZone>
              )}
            </Box>
          )}
        </Dropzone>
      </Flex>
      {meta.error && meta.touched && (
        <ValidationError>
          <IconError>{meta.error}</IconError>
        </ValidationError>
      )}
    </Flex>
  )
}

FormPostImage.defaultProps = {
  multiple: false,
  isReadOnly: false,
  required: false,
  onBlur: noop,
}

FormPostImage.propTypes = {
  ...Dropzone.propTypes,
  isReadOnly: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  uploadFiles: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
}

export default FormPostImage

import { useCallback } from 'react'

import { useStoreon } from 'storeon/react'

import {
  EVALUATED_TIME_TEMPORAL_WORKFLOW_SET,
  EVALUATED_TIME_TEMPORAL_WORKFLOW_STATE_KEY,
  EVALUATED_TIME_TEMPORAL_WORKFLOW_UNSET,
} from '../constants'
import {
  EvaluatedTimeTemporalWorkflowEvents,
  EvaluatedTimeTemporalWorkflowState,
  TemporalWorkflowPayload,
} from '../types'

export function useEvaluatedTimeTemporalWorkflowState() {
  const {
    [EVALUATED_TIME_TEMPORAL_WORKFLOW_STATE_KEY]: state,
    dispatch,
  } = useStoreon<
    EvaluatedTimeTemporalWorkflowState,
    EvaluatedTimeTemporalWorkflowEvents
  >(EVALUATED_TIME_TEMPORAL_WORKFLOW_STATE_KEY)

  const temporalWorkflow = state

  const addTemporalWorkflow = useCallback(
    (payload: TemporalWorkflowPayload) =>
      dispatch(EVALUATED_TIME_TEMPORAL_WORKFLOW_SET, payload),
    [dispatch],
  )

  const clearTemporalWorkflow = useCallback(
    () => dispatch(EVALUATED_TIME_TEMPORAL_WORKFLOW_UNSET),
    [dispatch],
  )

  return { temporalWorkflow, addTemporalWorkflow, clearTemporalWorkflow }
}

import { createFields, createReducerHandlers } from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  CLEAR_EMPLOYEES,
  LOAD_EMPLOYEES,
  LOAD_EMPLOYEES_SILENT,
  PUBLISH,
} from 'store/actions/company/schedule'

export const COMPANY_SCHEDULE_EMPLOYEES_TYPE = 'employees'

const initialState = Immutable({
  ...createFields(COMPANY_SCHEDULE_EMPLOYEES_TYPE),

  publishing: false,
})

const handlers = {
  ...createReducerHandlers(COMPANY_SCHEDULE_EMPLOYEES_TYPE, LOAD_EMPLOYEES, {
    withLoading: true,
    withReplace: true,
  }),

  ...createReducerHandlers(
    COMPANY_SCHEDULE_EMPLOYEES_TYPE,
    LOAD_EMPLOYEES_SILENT,
    {
      withLoading: false,
      withReplace: false,
    },
  ),

  [CLEAR_EMPLOYEES]: () => initialState,

  [PUBLISH.REQUEST]: state => state.merge({ publishing: true }),
  [PUBLISH.FAILURE]: state => state.merge({ publishing: false }),
  [PUBLISH.SUCCESS]: state => state.merge({ publishing: false }),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

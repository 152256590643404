import { useMemo, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { RulesetSortingField, SortingDirection } from 'constants/gatewayGraphQL'

import { RulesetsByCursorQuery, RulesetsByCursorQueryData } from './GraphQL'

type Props = {
  paging: CursorPage
  filter?: Gateway.RulesetByCursorFilterInput
}

export function useRulesetsByCursor({ paging, filter }: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, refetch, loading } = useApolloQuery<
    RulesetsByCursorQueryData,
    Gateway.QueryRulesetsByCursorArgs
  >(RulesetsByCursorQuery, {
    fetchPolicy: 'network-only',
    variables: {
      filter,
      paging: cursorPageToGraphqlPaging(paging),
      // Note: untill we get more sorting options from the API
      sorting: [
        {
          field: RulesetSortingField.Id,
          direction: SortingDirection.Desc,
        },
      ],
    },

    onCompleted(data) {
      const newPageMeta = data.rulesetsByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const rulesets = useMemo(
    () => data?.rulesetsByCursor?.edges.map(edge => edge.node) ?? [],
    [data],
  )

  return {
    rulesets,
    loading,
    currentPageMeta,
    refetch,
  }
}

import { useCallback } from 'react'

import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import {
  RestoreTimeEntriesMutation,
  TimeEntriesByCursorQuery,
  TimeEntrySummariesByCursorQuery,
} from './GraphQL'

export function useRestoreTimeEntries() {
  const [innerRestore, { loading, error }] = useApolloMutation<
    MutationData<'restoreTimeEntries'>,
    Gateway.MutationRestoreTimeEntriesArgs
  >(RestoreTimeEntriesMutation, {
    refetchQueries: compact(
      [TimeEntriesByCursorQuery, TimeEntrySummariesByCursorQuery].map(
        getOperationName,
      ),
    ),
  })

  const restoreTimeEntries = useCallback(
    async (input: Gateway.TimeEntryIdsInput) => {
      const result = await innerRestore({
        variables: {
          input,
        },
      })

      return graphQLResultHasError(result)
    },
    [innerRestore],
  )

  return { restoreTimeEntries, restoring: loading, restoreError: error }
}

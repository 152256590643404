import { ApolloLink } from '@apollo/client'

import { AuthService } from 'services/Auth'

export function hathorAuthLink() {
  return new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      // TODO: change to gateway or completly remove this link
      const accessToken = AuthService.getAuth0Token()

      return {
        headers: {
          ...headers,
          Authorization: accessToken ? `Bearer ${accessToken}` : null,
        },
      }
    })

    return forward(operation)
  })
}

import { DateTime } from 'luxon'
import { StoreonModule } from 'storeon'
import { v4 as uuid } from 'uuid'

import { RuleBuilderPoCState } from 'pages/EmployerApp/Builder/RulePoC/types'

import { INIT } from '../constants'

export const RULE_BUILDER_KEY = 'ruleBuilder'
export const ADD_RULE_EVENT = 'ruleBuilder/add'
export const MODIFY_RULE_EVENT = 'ruleBuilder/modify'
export const TOGGLE_RULE_EVENT = 'ruleBuilder/toggle'
export const DELETE_RULE_EVENT = 'ruleBuilder/delete'
export const COPY_RULE_EVENT = 'ruleBuilder/copy'
export const CHANGE_PRIORITY_EVENT = 'ruleBuilder/changePriority'

export type RuleBuilderStateSlice = {
  id: string
  updateAt: string
  enabled: boolean
  priority?: number
  ruleData: RuleBuilderPoCState
}

export type RuleBuilderState = {
  [RULE_BUILDER_KEY]: RuleBuilderStateSlice[]
}

export interface RuleBuilderEvents {
  [ADD_RULE_EVENT]: RuleBuilderPoCState
  [MODIFY_RULE_EVENT]: RuleBuilderPoCState & { id: string }
  [TOGGLE_RULE_EVENT]: string
  [DELETE_RULE_EVENT]: string
  [COPY_RULE_EVENT]: string
  [CHANGE_PRIORITY_EVENT]: { id: string; priority: number | undefined }
}

const INITIAL_STATE: RuleBuilderState = {
  [RULE_BUILDER_KEY]: [],
}

export const ruleBuilderModule: StoreonModule<
  RuleBuilderState,
  RuleBuilderEvents
> = store => {
  store.on(INIT, () => INITIAL_STATE)

  store.on(ADD_RULE_EVENT, (state, ruleData) => {
    return {
      [RULE_BUILDER_KEY]: [
        ...state[RULE_BUILDER_KEY],
        {
          id: uuid(),
          updateAt: DateTime.now().toISO(),
          enabled: false,
          ruleData,
        },
      ],
    }
  })

  store.on(MODIFY_RULE_EVENT, (state, { id, ...ruleData }) => {
    return {
      [RULE_BUILDER_KEY]: state[RULE_BUILDER_KEY].map(rule => {
        if (rule.id === id) {
          return {
            ...rule,
            updateAt: DateTime.now().toISO(),
            ruleData,
          }
        }

        return rule
      }),
    }
  })

  store.on(TOGGLE_RULE_EVENT, (state, id) => {
    return {
      [RULE_BUILDER_KEY]: state[RULE_BUILDER_KEY].map(rule => {
        if (rule.id === id) {
          return {
            ...rule,
            enabled: !rule.enabled,
          }
        }

        return rule
      }),
    }
  })

  store.on(DELETE_RULE_EVENT, (state, id) => {
    return {
      [RULE_BUILDER_KEY]: state[RULE_BUILDER_KEY].filter(
        rule => rule.id !== id,
      ),
    }
  })

  store.on(COPY_RULE_EVENT, (state, id) => {
    const copiedItem = state[RULE_BUILDER_KEY].find(rule => rule.id === id)!
    const modifiedItem = {
      id: uuid(),
      enabled: false,
      priority: undefined,
      ruleData: {
        ...copiedItem.ruleData,
        name: `${copiedItem.ruleData.name} (copy)`,
      },
      updateAt: DateTime.now().toISO(),
    }

    return {
      [RULE_BUILDER_KEY]: [...state[RULE_BUILDER_KEY], modifiedItem],
    }
  })

  store.on(CHANGE_PRIORITY_EVENT, (state, { id, priority }) => {
    return {
      [RULE_BUILDER_KEY]: state[RULE_BUILDER_KEY].map(rule => {
        if (rule.id === id) {
          return {
            ...rule,
            priority,
          }
        }

        return rule
      }),
    }
  })
}

import startCase from 'lodash/startCase'

import _ from 'i18n'

const re = new RegExp(`^!.+]$`, 'g')

const convertToKey = text => startCase(text).replace(/\s/g, '')

export const getTranslate = ({ text, prefix = '' }) => {
  const key = `${prefix}.${convertToKey(text)}`

  return _(key).search(re) === -1 ? _(key) : text
}

import { api } from 'constants/config'

import { downloadFileAsync } from 'helpers/files'

import { i18n } from 'i18n'

import coreApiCall from 'services/API/coreApi'
import { showToast } from 'services/Toasts'

import { downloadDocumentParams, RequestDocumentDownloadParams } from './types'

// TODO: Think of better folder structure here

async function createToken() {
  const response = await coreApiCall({
    endpoint: `/token`,
    method: 'POST',
    isRaw: true,
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  })

  if (response.ok) {
    const { token } = await response.payload.data
    return token
  }

  return ''
}

async function requestDocumentDownload({
  companyId,
  token,
  kind,
  queryString,
}: RequestDocumentDownloadParams) {
  if (token) {
    try {
      await downloadFileAsync(
        `${api.filesUrl}/companies/${companyId}/${kind}?token=${token}&${queryString}`,
      )
    } catch (error) {
      showToast({
        type: 'error',
        title: i18n('alertMessages.default.error.title'),
        content: i18n('alertMessages.default.error.message'),
      })
    }
  } else
    showToast({
      type: 'error',
      title: i18n('alertMessages.default.error.title'),
      content: i18n('alertMessages.default.error.message'),
    })
}

export async function download({
  companyId,
  queryString,
  kind,
}: downloadDocumentParams) {
  const token = await createToken()
  requestDocumentDownload({ companyId, token, kind, queryString })
}

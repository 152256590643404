import React, { useEffect, useMemo, useState } from 'react'
import { Control, useWatch } from 'react-hook-form'

import { API } from 'API'

import { Flex } from 'components/ui/__v2__/Grid'
import { Spinner } from 'components/ui/Spinner'

import { mapFormDataToServerData } from './helpers'
import { Item } from './Item'
import { SideInfo } from './SideInfo'

import { TimeoffFormState } from '../../types'

const ESTIMATED_BALANCE_EMPTY = {
  availableBalance: 0,
  remainingBalance: 0,
  totalBookedBalance: 0,
  year: 0,
}

type Props = {
  control: Control<TimeoffFormState>
}

export function EstimatedBalances({ control }: Props) {
  // NOTE: separate useWatch, so that changes to other fields do not trigger updates
  //       to key fields that are used for request
  const [dates, leaveType, startTime, endTime] = useWatch({
    control,
    name: ['dates', 'leaveType', 'startTime', 'endTime'],
  })
  const [partial, employee, date, period] = useWatch({
    control,
    name: ['partial', 'employee', 'date', 'period'],
  })

  const [estimatedBalances, setEstimatedBalances] = useState<
    Gateway.EstimatedTimeoffBalance[]
  >([])

  const serverData = useMemo(
    () =>
      mapFormDataToServerData({
        customLeaveDayId: leaveType?.value,
        employeeId: employee?.id,
        period,
        date,
        dates,
        partial,
        startTime,
        endTime,
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [leaveType, dates, startTime, endTime],
  )

  const {
    data,
    loading,
    hasError,
  } = API.CustomLeaveDay.estimateTimeoffBalanceImpact({
    enabled: !!serverData,
    input: serverData!,
    onCompleted: () => setEstimatedBalances(data),
  })

  useEffect(() => {
    if ((leaveType || estimatedBalances.length === 0) && !hasError) return

    setEstimatedBalances([])
  }, [leaveType, hasError, estimatedBalances])

  const hasLoaded = !loading && estimatedBalances.length > 0
  const isEmpty = !loading && estimatedBalances.length === 0

  return (
    <Flex gap={4} position="relative">
      <SideInfo />

      <Flex
        gap={4}
        mb={estimatedBalances.length > 2 ? '-5px' : 0}
        overflow="auto"
        width="100%"
      >
        {loading && (
          <Flex ml="26px" mt="26px">
            <Spinner size={30} />
          </Flex>
        )}

        {hasLoaded &&
          estimatedBalances.map((estimatedBalance, index) => (
            <Item
              estimatedBalance={estimatedBalance}
              key={`${index}_${estimatedBalance.totalBookedBalance}`}
            />
          ))}

        {isEmpty && (
          <Item estimatedBalance={ESTIMATED_BALANCE_EMPTY} hideYear />
        )}
      </Flex>
    </Flex>
  )
}

import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import lowerCase from 'lodash/lowerCase'

import _ from 'i18n'

export const MIN_DAILY_HOURS = 1

export const MAX_DAILY_HOURS = 24

export const MAX_MINUTES_IN_HOUR = 60

export const DAILY_WORKING_HOURS_MASK = createNumberMask({
  prefix: '',
  suffix: ` ${lowerCase(_('timeline.hours'))}`,
  integerLimit: 2,
  decimalLimit: 1,
  allowDecimal: true,
  allowNegative: false,
})

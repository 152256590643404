import { createContext, useContext } from 'react'

import { UseAssignmentScratchPadReturn } from './hooks'

export const ShiftAssignmentContext = createContext(
  {} as UseAssignmentScratchPadReturn,
)

export function useShiftAssignmentContext() {
  return useContext(ShiftAssignmentContext)
}

import get from 'lodash/get'

import gardaTheme from 'theme/__v2__/garda'
import lightTheme from 'theme/__v2__/light'
import { main } from 'theme/main'

/** @deprecated */
export function getColorFromBg(background) {
  if (background instanceof Object) {
    return ({ theme }) => `${get(theme, background.color)}`
  }

  return ({ theme }) => `${get(theme, `colors.${background}`)}`
}

export default {
  main,
  lightTheme,
  gardaTheme,
}

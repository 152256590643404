import { DateTime } from 'luxon'
import { Areas } from 'Types/app'
import { AreasFilter } from 'Types/common'

import { isArray, isEmpty, map } from 'lodash'

import { SortingDirection } from 'constants/gatewayGraphQL'

function jsonApiSortingToDirection<F extends string>(
  jsonApiSorting: JsonApi.Sorting<F>,
): SortingDirection {
  return jsonApiSorting.startsWith('-')
    ? SortingDirection.Desc
    : SortingDirection.Asc
}

function sortingDirectionToJsonAPI<F extends string>(
  sortingDirection: SortingDirection,
  field: F,
): JsonApi.Sorting<F> {
  return `${sortingDirection === SortingDirection.Desc ? '-' : ''}${field}`
}

function betweenFilter(
  range: { from: string | null; to: string | null } | undefined,
  options: { includeEndDate?: boolean } = { includeEndDate: true },
): JsonApi.BetweenFilter | undefined {
  if (!range) return undefined

  const { from, to } = range
  if (!from || !to) return undefined

  return {
    between: [
      DateTime.fromISO(from).toISODate(),
      (options.includeEndDate
        ? DateTime.fromISO(to).plus({ days: 1 })
        : DateTime.fromISO(to)
      ).toISODate(),
    ],
  }
}

function searchFilter(search?: string) {
  if (!search) return undefined
  if (search.length <= 2) return undefined
  return { search: { ilike: search } }
}

function inFilter(items: string[] | null | undefined) {
  if (!items) return undefined
  if (items.length === 0) return undefined

  return { in: items }
}

function eqFilter<T = string>(value: T) {
  return { eq: value }
}

function iLike(value: string) {
  return { ilike: value }
}

function areasFilter(areas: Areas | AreasFilter) {
  const filters: JsonApi.AreasFilter = {}

  if (!isEmpty(areas.locations)) {
    filters.branch_id = {
      in: map(
        isArray(areas.locations) ? areas.locations : [areas.locations],
        'id',
      ),
    }
  }

  if (!isEmpty(areas.departments)) {
    filters.department_id = {
      in: map(
        isArray(areas.departments) ? areas.departments : [areas.departments],
        'id',
      ),
    }
  }

  if (!isEmpty(areas.jobs)) {
    filters.job_id = {
      in: map(isArray(areas.jobs) ? areas.jobs : [areas.jobs], 'id'),
    }
  }

  if (!isEmpty(areas.employees)) {
    filters.employee_id = {
      in: map(
        isArray(areas.employees) ? areas.employees : [areas.employees],
        'id',
      ),
    }
  }

  return filters
}

// FIXME: type properly on global level
interface Entity {
  id: string
  [arg: string]: any
}
interface Meta {
  meta?: { nextPage: boolean }
  data?: Entity[]
}

interface Result {
  payload?: {
    data?: {
      meta?: Record<string, Meta>
      shifts: Entity[]
    }
  }
  meta: { endpoint: string }
}

function resultToSortedIds(result: Result) {
  const endpoint = result?.meta?.endpoint
  return (
    result?.payload?.data?.meta?.[endpoint]?.data?.map(entity => entity.id) ??
    []
  )
}

export default {
  betweenFilter,
  jsonApiSortingToDirection,
  sortingDirectionToJsonAPI,
  searchFilter,
  inFilter,
  eqFilter,
  iLike,
  areasFilter,
  resultToSortedIds,
}

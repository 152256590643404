import { Locale } from 'date-fns'
import { WeekdayNumbers } from 'luxon'

import {
  CompanyIdentity,
  CustomLabelDayKinds,
  EmployeeStatuses,
  LeavePolicyKinds,
  ReferenceYearEndDateKinds,
  UserAccessLevels,
  WageType,
} from 'constants/ids'
import { CustomCvFieldType } from 'constants/jsonAPI'
// Note: Semityped implementation to support current pickers

export type AreasBranch = Partial<Branch> & {
  location: Branch
}
export type AreasDepartment = Partial<Department> & {
  department: Department
}
export type AreasJob = Partial<Job> & {
  job: Job
}
export type AreasEmployee = Partial<Employee> & {
  employee: Employee
}
/**
 * @deprecated use import { AreasFilter } from 'Types/common'
 */
export interface Areas {
  locations?: AreasBranch[] | AreasBranch
  departments?: AreasDepartment[] | AreasDepartment
  jobs?: AreasJob[] | AreasJob
  employees?: AreasEmployee[] | AreasEmployee
}

export interface CompanySettings {
  autoShiftName?: boolean
  allowFutureManualTimecardApprove?: boolean
  allowDepartmentManagerMutualApprove?: boolean
  earlyClockIn?: boolean
  earlyClockInLimit?: number
  disableEmployeeProfileEdit?: boolean
  disableEmployeeBreaks?: boolean
  sendScheduleEmails?: boolean
  showWagesToManagers?: boolean
  calendarStartDay: WeekdayNumbers
  disableDepartmentManagerLeaveRequestAutoApproval?: boolean
  managersCanArchiveEmployees?: boolean
  rapidClockInMode?: {
    enabled: boolean
    limit?: number
  }
  timecardSettings?: {
    updateThreshold?: {
      enabled?: boolean
      value?: number
    }
  }
  timeoffSettings?: {
    updateThreshold?: {
      enabled?: boolean
      value?: number
    }
  }
}

export interface LocationTag {
  id: string
  color: string
  name: string
  // company
}

export interface Company {
  id: string
  name: string | null
  identity: CompanyIdentity
  nahdi?: boolean
  settings: CompanySettings
  locationTags: LocationTag[]
  // subscription
  // subscriptions
}

export interface UserSettings {
  chatSound: boolean
  displayShiftShort: boolean
  locale: keyof DateLocale
  notifications: boolean
  theme: string
  timezone: string
}

export interface UserProfile {
  id: string
  profileAvatar: { content: { smallThumb?: { url: string } } }
  customCvFieldValues: CustomCvFieldValue[]
  firstName: string
  lastName: string
  userName: string
  email: string
  // We moved essential info to Gateway
}

export interface User {
  id: string
  accessLevel: UserAccessLevels
  microserviceId: string
  onboardingCompleted: boolean
  onboardingSteps: string[]
  profile: UserProfile
  settings: UserSettings
  signedUpAt: string | null
  signedInAt: string | null
}

export interface HieararchyNode {
  branch: Pick<Branch, 'id' | 'name'>
  department: Department
  jobs: Job[]
}
export interface Branch {
  id: string
  name: string
  hierarchyNodes?: HieararchyNode[]
  settings: JsonApi.LocationSettings
  departments?: Department[]
}

export interface ManagedBranch {
  id: string
  branch: Branch
  manager: Manager
  temporary: boolean
  // TODO: it is better to clarify
  state: string
}

export interface Department {
  id: string
  name: string
  branch?: Pick<Branch, 'id' | 'name'>
}

export interface ManagedDepartment {
  id: string
  branch: Branch
  department: Department
  manager: Manager
  temporary: boolean
  // TODO: it is better to clarify
  state: string
}

export interface Job {
  id: string
  name: string
  department?: Department
}

export interface JobEmployee {
  id: string
  name: string
  branch: Branch
  department: Department
  job: Job
  wage: string
  wageType: WageType
  effectiveDates: EffectiveDateRange[]
  // TODO: add enum
  state: string
}

export interface JobEmployeeWithCompanySettings extends JobEmployee {
  companySetting?: {
    attendanceSettings: TimeAndAttendanceSettings
    // Note:There's other settings as well, implement as needed
  }
}

export interface EmployeeLeaveSettings {
  dayHourEquivalence: number
}

export interface EffectiveDateRange {
  start: string | null
  end: string | null
}

export interface DeluxeEmployeeSyncStatus {
  lastSyncAttemptAt: string | null
  lastSuccessSyncAt: string | null
  lastErrorMessage: string | null
  externalId: string | null
  inProgress: boolean
}

export interface Employee {
  id: string
  // activeTimeEntry
  archivingReason: string | null
  branch: Branch | null
  branches: Branch[] | null
  departments?: Department[]
  jobs: Job[] | null
  jobsEmployees: JobEmployee[]
  hiredAt: string
  leaveSettings: EmployeeLeaveSettings
  manager: Manager | null
  pin: string | null
  profile: UserProfile
  requireFaceId: boolean
  startArchiveDate: string | null
  state: EmployeeStatuses
  stateChangedAt: string | null
  stateChangedByManager: Manager | null
  supervisors: Manager[] | null
  sync: DeluxeEmployeeSyncStatus
  timesheetsExportCode: string | null
  user: User
  createdAt: string
  updatedAt: string
  // TODO: fill in when required
}

export interface EmployeeOption {
  id: string
  label: string
  value: string
  employee: Employee
}

export interface Manager {
  id: string
  allManagedDepartments: ManagedDepartment[]
  allManagedBranches: ManagedBranch[]
  branches?: Branch[]
  departments?: Department[]
  permissions?: {
    writePersonalDetails: boolean
  }
  // TODO: fill in when required
}

export interface Viewer extends User {
  currentAccessLevel: UserAccessLevels
  accessLevel: UserAccessLevels
  employees: Employee[]
  managers: Manager[]
}

export interface DateLocale {
  en: Locale
  fr: Locale
  es: Locale
  projectsEn: Locale
}

/** TODO: These types are not finalised! */

export interface AreasAttribution extends Areas {
  global: boolean
}

interface BaseLeaveSettings {
  system: boolean
  useAsLabel: boolean
  paid: boolean
  leaveId: string
  partial: boolean
  earningType: Pick<Gateway.EarningType, 'id' | 'name' | 'code'>
  earningTypeId: string
  name: string | null
  description: string | null
  policyKind: LeavePolicyKinds | null
  effectiveDates: EffectiveDateRange[] | null
  blockEmployeeRequest: boolean
}

export interface CustomLeaveSettings extends BaseLeaveSettings {
  referenceYearEndRecurrence: string
  referenceYearEndKind: ReferenceYearEndDateKinds
}

export interface PublicHolidaySettings extends BaseLeaveSettings {
  kind: CustomLabelDayKinds.Holiday
  statutory: boolean
}

export interface CustomLeave
  extends AreasAttribution,
    CustomLeaveSettings,
    PublicHolidaySettings {
  id: string
  company: Company
  allowMoving?: boolean
}

/** -- end of TODO */

export interface LatLng {
  lat: number
  lng: number
}

export interface CustomCvFieldValue {
  id: string
  value: string
  customCvField: CustomField
}

export interface CustomField {
  id: string

  name: string
  description: string | null
  identity: string | null
  tab: string | null
  fieldType: CustomCvFieldType

  data: {
    defaultValue: string
    options: string[]
  }

  classifier: boolean
  appearsInEmployeeInvite: boolean

  editByAdminsOnly: boolean
  employeeView: boolean
  employeeEdit: boolean
  departmentManagerView: boolean
  departmentManagerEdit: boolean
  branchManagerView: boolean
  branchManagerEdit: boolean

  enabled: boolean
  mandatory: boolean

  file: $TSFixMe | null

  company: Company

  createdAt: string
  updatedAt: string

  externalId?: string
}

export type CustomFieldWithValue = Omit<CustomField, 'company'> & {
  value: string | null
}

export interface RoleEffectivePeriod {
  start: string | null
  end: string | null
}

// TIME AND ATTENDANCE

export enum GeolocationTrackingType {
  Geolocation = 'geolocation',
  Geofencing = 'geofencing',
}

export enum BeaconTrackingType {
  Single = 'single',
  Continous = 'continuous',
}

export enum TaskTrackingSettings {
  None = 'none',
  EnforceLocationSubmission = 'enforce_location_submission',
  EnforceLocationSubmissionAndValidation = 'enforce_location_submission_and_validation',
}

export enum TaskTrackingValidationModes {
  Geofencing = 'geofencing',
  Beacon = 'beacon',
  GeofencingAndBeacon = 'geofencing_and_beacon',
}
export interface TimeAndAttendanceAppTypeFlags {
  desktop: boolean
  mobile: boolean
  tablet: boolean
}

interface ClockGeolocationSettings {
  trackingType: GeolocationTrackingType.Geolocation
}

interface ClockGeofencingSettings {
  radius: number
  trackingType: GeolocationTrackingType.Geofencing
}

interface BeaconSingleTrackingSettings {
  trackingType: BeaconTrackingType.Single
}

interface BeaconContinousTrackingSettings {
  trackingType: BeaconTrackingType.Continous
  countdown: number
}

interface GeofencingValidationSettings {
  validationMode: TaskTrackingValidationModes.Geofencing
  geofencingRadius: number
}

interface BeaconValidationSettings {
  validationMode: TaskTrackingValidationModes.Beacon
}

interface GeofencingAndBeaconValidationSettings {
  validationMode: TaskTrackingValidationModes.GeofencingAndBeacon
  geofencingRadius: number
}
interface NoneTaskTrackingSettings {
  trackerSettings: TaskTrackingSettings.None
}

interface EnforceSubmissionTaskTarckingSettings {
  trackerSettings: TaskTrackingSettings.EnforceLocationSubmission
}

type EnforceSubmissionAndValidationTaskTarckingSettings = {
  trackerSettings: TaskTrackingSettings.EnforceLocationSubmissionAndValidation
} & (
  | GeofencingValidationSettings
  | BeaconValidationSettings
  | GeofencingAndBeaconValidationSettings
)

export enum TimeCaptureModeKind {
  Manual = 'manual',
  Auto = 'auto',
}

export type TimeAndAttendanceSettings = {
  clockIn: TimeAndAttendanceAppTypeFlags & {
    autoClockOutAfter?: number
    autoClockOutAfterElapsedTime?: number
  } & (ClockGeolocationSettings | ClockGeofencingSettings)

  openClock: TimeAndAttendanceAppTypeFlags & {
    clockInReminder?: {
      hours: number
      minutes: number
      message: string
    }
    clockOutReminder?: {
      hours: number
      minutes: number
      message: string
    }
    earlyClockOutWarning?: {
      hours: number
      minutes: number
      message: string
    }
    earlyClockOutReminder?: number
    earlyClockOutReminderText?: string
  }
  emergencyOpenClock: TimeAndAttendanceAppTypeFlags
  geolocation: TimeAndAttendanceAppTypeFlags & {
    radius?: number
    trackingType?: GeolocationTrackingType
  }
  facialRecognition: TimeAndAttendanceAppTypeFlags & {
    femaleRequireFaceId?: boolean
    force?: boolean
  }

  beacon: { mobile: boolean } & (
    | BeaconSingleTrackingSettings
    | BeaconContinousTrackingSettings
  )

  taskTracking: {
    mobile: boolean
  } & (
    | NoneTaskTrackingSettings
    | EnforceSubmissionTaskTarckingSettings
    | EnforceSubmissionAndValidationTaskTarckingSettings
  )

  timeCaptureMode: TimeCaptureModeKind
}

export type UploadedFile = {
  id: string
  name: string
  content: {
    url: string
  }
}

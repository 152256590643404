import React from 'react'

import { Flex, Span } from 'components/ui/__v2__/Grid'
import { Link } from 'components/ui/__v2__/Link'

import { viewRulesetPath } from 'helpers/routes'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

type Props = {
  rulesetId: string
}

export function ShiftValidationConflictRulesetLink({ rulesetId }: Props) {
  const t = useI18n<
    typeof TRANSLATIONS.ruleValidation.shiftValidation.conflicts
  >('ruleValidation.shiftValidation.conflicts')

  return (
    <Flex gap={1}>
      <Span fontWeight="bold">{t('validationErrors')}</Span>
      <Span>
        {/* NOTE: since we have the same ruleset for all conflicts
          we can keep this link outside of each conflict */}
        <Link target="_blank" to={`${viewRulesetPath(rulesetId)}`}>
          ({t('viewRuleSet')})
        </Link>
        :
      </Span>
    </Flex>
  )
}

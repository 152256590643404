import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Header = styled.div`
  display: grid;
  grid-template: auto / auto 24px;
`

export const Title = styled.h2`
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  color: ${themeGet('colors.EMPEROR')};
  font-family: ${themeGet('font')};
  padding: 0;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  padding: ${themeGet('space.4')}px ${themeGet('space.2')}px;
  width: 100%;
  min-width: 400px;
`

export const Content = styled.div`
  font-size: ${themeGet('fontSizes.1')}px;
  margin: ${themeGet('space.5')}px ${themeGet('space.2')}px;
  max-width: 500px;
  text-align: justify;
`

import React from 'react'

import { i18n } from 'i18n'

import { FilterModeOption, FilterModesWrapper } from './styles'

export type FilterMode = 'or' | 'and'
type Props = {
  value: FilterMode
  onChange: (mode: FilterMode) => void
}

export function FilterModeSelector({ value, onChange }: Props) {
  return (
    <FilterModesWrapper>
      <FilterModeOption
        active={value === 'and'}
        onClick={() => {
          onChange('and')
        }}
      >
        {i18n('actions.and')}
      </FilterModeOption>
      <FilterModeOption
        active={value === 'or'}
        onClick={() => {
          onChange('or')
        }}
      >
        {i18n('actions.or')}
      </FilterModeOption>
    </FilterModesWrapper>
  )
}

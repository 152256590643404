import { TimeCard } from 'Types/schedule'

import omit from 'lodash/omit'

import { TimeCardsQueryParams } from 'pages/EmployerApp/TimeCards'

import coreApiCall from 'services/API/coreApi'
import Utils from 'services/Utils'

type Props = {
  filters: TimeCardsQueryParams['filters']
  sort: TimeCardsQueryParams['sort']
  page: JsonApi.OffsetPagingInput | JsonApi.CursorPagingInput
}

// Note: not really possible / feasible to use leaner types here
export type TimecardsQueryEntityReturn = TimeCard

export function timecardsQuery({ filters, sort, page }: Props) {
  const timeBucketFilter = Utils.TimeBucket.selectedTimeBucketsToJsonApiFilters(
    {
      timeBucketChildren: filters.timeBucketChildren,
      timeBucketParents: filters.timeBucketParents,
    },
  )

  const query: JsonApi.TimeCardsArgs = {
    filter: {
      ...omit(Utils.JsonApi.areasFilter(filters.areas), 'employee_id'),
      start_on: Utils.JsonApi.betweenFilter(filters.interval, {
        includeEndDate: false,
      }),
      state: Utils.JsonApi.inFilter(filters.status.value),
      ...(filters.kind.value && {
        kind: Utils.JsonApi.eqFilter(filters.kind.value),
      }),
      ...(filters.employee && {
        employee_id: { eq: filters.employee.value },
      }),

      ...timeBucketFilter,
    },
    page,
    sort,
  }

  return coreApiCall({
    endpoint: '/time_entries',
    query: {
      include: INCLUDE,
      ...query,
    },
  })
}

const INCLUDE = [
  'job',
  'employee',
  'employee.profile',
  'department',
  'branch',
  'earningType',
  'timeBucket',
  'timeBucket.timeBucketParent',
  // Note: these are required for date / time calculation
  'schedule',
  'schedule.shift',
  'statistics',
  'pauseTimers',
  'timeRanges.timeType',
].join()

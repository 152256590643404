import { FieldPolicy, Reference } from '@apollo/client'

type KeyArgs = FieldPolicy<any>['keyArgs']

type TInternalRelay<TNode> = Readonly<{
  edges: Array<{
    cursor: string
    node: TNode
  }>
  pageInfo: Readonly<{
    hasPreviousPage: boolean
    hasNextPage: boolean
    startCursor: string
    endCursor: string
  }>
}>

/**
 * @link https://github.com/apollographql/apollo-client/blob/master/src/utilities/policies/pagination.ts#L93
 * @abstract This is a slight rework of what Apollo provide, matching our naming convention
 */
export function relayStylePagination<TNode = Reference>(
  keyArgs: KeyArgs = false,
): FieldPolicy<TInternalRelay<TNode>> {
  return {
    keyArgs,

    read(existing, { canRead, variables }) {
      // For more information, look at ../types.ts for the definition of RelayStylePaginationVariables type.
      if (!existing || variables?.disableMerge) return
      // @ts-ignore
      const edges = existing.edges.filter(edge => canRead(edge.node))

      // eslint-disable-next-line consistent-return
      return {
        // Some implementations return additional Connection fields, such
        // as existing.totalCount. These fields are saved by the merge
        // function, so the read function should also preserve them.
        ...existing,
        edges,
        pageInfo: {
          ...existing.pageInfo,
          startCursor: cursorFromEdge(edges, 0),
          endCursor: cursorFromEdge(edges, -1),
        },
      }
    },

    merge(existing = makeEmptyData(), incoming, { args, variables }) {
      if (!args) return existing // TODO Maybe throw?

      const incomingEdges = incoming.edges.slice(0)
      if (incoming.pageInfo) {
        updateCursor(incomingEdges, 0, incoming.pageInfo.startCursor)
        updateCursor(incomingEdges, -1, incoming.pageInfo.endCursor)
      }

      let prefix = existing.edges
      let suffix: typeof prefix = []

      if (args.paging.startingAfter) {
        const index = prefix.findIndex(
          edge => edge.cursor === args.paging.startingAfter,
        )
        if (index >= 0) {
          prefix = prefix.slice(0, index + 1)
          // suffix = []; // already true
        }
      } else if (args.paging.endingBefore) {
        const index = prefix.findIndex(
          edge => edge.cursor === args.paging.endingBefore,
        )
        suffix = index < 0 ? prefix : prefix.slice(index)
        prefix = []
      } else {
        // If we have neither args.startingAfter nor args.endingBefore, the incoming
        // edges cannot be spliced into the existing edges, so they must
        // replace the existing edges. See #6592 for a motivating example.
        prefix = []
      }

      const edges = [...prefix, ...incomingEdges, ...suffix]

      const pageInfo = {
        ...incoming.pageInfo,
        ...existing.pageInfo,
        startCursor: cursorFromEdge(edges, 0),
        endCursor: cursorFromEdge(edges, -1),
      }

      const updatePageInfo = (
        name: keyof TInternalRelay<TNode>['pageInfo'],
      ) => {
        const value = incoming.pageInfo[name]

        // eslint-disable-next-line no-void
        if (value !== void 0) {
          ;(pageInfo as any)[name] = value
        }
      }
      if (!prefix.length) updatePageInfo('hasPreviousPage')
      if (!suffix.length) updatePageInfo('hasNextPage')

      return {
        // For more information, look at ../types.ts for the definition of RelayStylePaginationVariables type.
        ...(variables?.disableMerge ? {} : existing),
        ...incoming,
        edges,
        pageInfo,
      }
    },
  }
}

function makeEmptyData() {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: false,
      hasNextPage: true,
      startCursor: '',
      endCursor: '',
    },
  }
}

function cursorFromEdge<TNode>(
  edges: TInternalRelay<TNode>['edges'],
  index: number,
): string {
  if (index < 0) index += edges.length
  const edge = edges[index]
  return (edge && edge.cursor) || ''
}

function updateCursor<TNode>(
  edges: TInternalRelay<TNode>['edges'],
  index: number,
  cursor: string | undefined,
) {
  if (index < 0) index += edges.length
  const edge = edges[index]
  if (cursor && cursor !== edge.cursor) {
    edges[index] = { ...edge, cursor }
  }
}

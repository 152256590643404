import React from 'react'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'components/ui/__v2__/Grid'
import { Spinner } from 'components/ui/Spinner'

import _ from 'i18n'

const BorderWrapper = styled(Flex)`
  border: thin solid ${themeGet('layout.lighterBorder')};
`

export function NoDataWrapper() {
  return <Flex py={6}>{_('common.tableNoData')}</Flex>
}

export function ProgressComponent() {
  return (
    <BorderWrapper
      alignItems="center"
      justifyContent="center"
      primary
      py={6}
      width={1}
    >
      <Spinner primary size="large" />
    </BorderWrapper>
  )
}

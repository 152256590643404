import { useApolloMutation } from 'API/services/Apollo'

import { CreateRuleMutation } from './GraphQL'

export function useCreateRule() {
  const [innerCreate, { loading: creating }] = useApolloMutation<
    MutationData<'createRule'>,
    Gateway.MutationCreateRuleArgs
  >(CreateRuleMutation, {
    translationKey: 'rule.toasts.create',
  })

  const createRule = async (input: Gateway.CreateRuleInput) =>
    innerCreate({
      variables: {
        input,
      },
    })

  return { createRule, creating }
}

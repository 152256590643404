import React, { useState } from 'react'
import { InputActionMeta } from 'react-select'

import { StaffAPI, StaffAPIItem } from 'API'
import { StaffQueryFilter } from 'API/Staff/Json'

import { Select } from 'components/ui/__v3__/Select'

import { createDebounce } from 'helpers/debounce'

import Utils from 'services/Utils'

export type StaffPickerReturnOption = ReturnType<typeof staffMemberToOption>

export type StaffPickerArgs = {
  value: StaffPickerReturnOption | null

  filters?: Omit<StaffQueryFilter, 'search' | 'display'>

  isDisabled?: boolean
  isMulti?: boolean
  isClearable?: boolean
  display?: StaffQueryFilter['display']

  onSelect: (option: StaffPickerReturnOption) => void
  onBlur?: () => void
}

export function StaffPicker({
  value,
  filters,
  onSelect,
  display,
  ...rest
}: StaffPickerArgs) {
  const [input, setInput] = useState<string | undefined>(undefined)

  const { staff, isFetching, setEnabled } = StaffAPI.picker({
    filters: {
      ...filters,
      search: value?.label ?? input, // Note: this is to show meaningful options when an option is selected
      display,
    },
  })

  const handleChange = (option: StaffPickerReturnOption) => {
    onSelect(option)
  }

  const handleInputChange = createDebounce(
    (inputValue: string, { action }: InputActionMeta) => {
      if (action === 'input-change' || action === 'set-value') {
        setInput(inputValue)
      }
    },
  )

  return (
    <Select
      // @ts-ignore
      closeMenuOnSelect
      filterOption={null}
      isLoading={isFetching}
      isSearchable
      options={staff.map(staffMemberToOption)}
      value={value}
      withPortal
      onChange={handleChange}
      onInputChange={handleInputChange}
      onMenuClose={() => setEnabled(false)}
      onMenuOpen={() => setEnabled(true)}
      {...rest}
    />
  )
}

function staffMemberToOption(staff: StaffAPIItem) {
  return {
    value: staff.id,
    label: Utils.User.buildFullName(staff.profile),
  }
}

export const TimeCardQueryKeys = {
  all: () => ['timecards'],

  byId: (id: string) => ['timecards', id],

  byOffset: (
    page: JsonApi.OffsetPagingInput,
    sort: JsonApi.TimeCardsSorting,
    filter: Record<string, any>,
  ) => ['timecards', page, filter, sort],

  byCursor: (
    page: JsonApi.CursorPagingInput,
    sort: JsonApi.TimeCardsSorting,
    filter: Record<string, any>,
  ) => ['timecards', page, filter, sort],

  infinite: (sort: JsonApi.TimeCardsSorting, filter: Record<string, any>) => [
    'timecards',
    filter,
    sort,
  ],
}

export const TIME_CARD_JSON_API_TYPE = 'timeEntries'

import { ApolloCache } from '@apollo/client'
import { FragmentOptions } from 'API/services/utils/GraphQL'

export function evictEmployeeJobsWithEmploymentTerms(
  cache: ApolloCache<any>,
  ids?: Array<Gateway.EmployeeJob['id']>,
) {
  if (!ids?.length) return

  ids.forEach(id => {
    cache.evict({ id: FragmentOptions.EmployeeJobWithEmploymentTerms(id).id })
  })

  cache.gc()
}

export function employeeJobsWithEmploymentTermsEffectiveDatesUpdater(
  cache: ApolloCache<any>,
  id: Gateway.EmployeeJob['id'],
  effectiveDates: Gateway.EmployeeJob['effectiveDates'],
) {
  const fragmentOptions = FragmentOptions.EmployeeJobWithEmploymentTerms(id)

  const employeeJob = cache.readFragment<Gateway.EmployeeJob>({
    id: fragmentOptions.id,
    fragment: fragmentOptions.fragment,
    fragmentName: fragmentOptions.fragmentName,
  })

  if (!employeeJob) return

  cache.writeFragment<Gateway.EmployeeJob>({
    id: fragmentOptions.id,
    fragment: fragmentOptions.fragment,
    fragmentName: fragmentOptions.fragmentName,
    data: {
      ...employeeJob,
      effectiveDates,
    },
  })
}

import { useApolloQuery } from 'API/services/Apollo'

import { TimeTypesByOffsetQuery } from './GraphQL'

type Args = {
  paging: Gateway.OffsetPaging
  sort: {
    field: Gateway.TimetypeSortingField
    direction: Gateway.SortingDirection
  }
  enabled?: boolean
}

export function useTimeTypesByOffset({ paging, sort, enabled = true }: Args) {
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'timetypesByOffset'>,
    Gateway.QueryTimetypesByOffsetArgs
  >(TimeTypesByOffsetQuery, {
    variables: {
      paging,
      sorting: [sort],
    },
    skip: !enabled,
    fetchPolicy: 'network-only',
  })

  const timeTypes = data?.timetypesByOffset?.rows ?? []

  return {
    timeTypes,
    fetching: loading,
    refetch,
    total: data?.timetypesByOffset?.pageInfo.total ?? 0,
  }
}

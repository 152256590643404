import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-final-form'
import PropTypes from 'prop-types'

import forEach from 'lodash/forEach'

import { IconError } from 'components/ui/__v3__/Input/Errors'
import { Select } from 'components/ui/__v3__/Select'

import { ValidationError } from './styles'

/**
 * @see https://final-form.org/docs/react-final-form/types/FieldRenderProps for FormRenderProps props
 * @see {@link ../Select/Select.js} for Select props
 * @param dependentFields if value is cleared it also clears values of dependentFields
 */
const FormSelect = React.forwardRef(
  (
    {
      input,
      meta,
      options,
      dependentFields,
      onBlur,
      mustBeTouchedToShowError,
      ...rest
    },
    ref,
  ) => {
    const { value, name } = input

    const { change } = useForm()
    const selectValue = useMemo(
      () => options.find(item => value === item.value),
      [options, value],
    )

    const handleChange = useCallback(
      option => {
        change(name, option?.value)

        if (!option?.value) {
          forEach(dependentFields, field => {
            change(field, null)
          })
        }

        if (onBlur) {
          onBlur()
        }
      },
      [change, name, onBlur, dependentFields],
    )

    return (
      <Select
        errored={meta.error && meta.touched}
        ref={ref}
        {...input}
        helper={
          meta.error &&
          (mustBeTouchedToShowError ? meta.touched : true) && (
            <ValidationError>
              <IconError> {meta.error}</IconError>
            </ValidationError>
          )
        }
        options={options}
        value={selectValue}
        onChange={handleChange}
        {...rest}
      />
    )
  },
)

FormSelect.defaultProps = {
  dependentFields: [],
  mustBeTouchedToShowError: true,
}

FormSelect.propTypes = {
  ...Select.propTypes,
  dependentFields: PropTypes.array,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  mustBeTouchedToShowError: PropTypes.bool,
}

export default FormSelect

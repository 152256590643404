import { Employee } from 'Types/app'
import { Schedule, ShiftJob } from 'Types/schedule'

import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'

import { AccessLevel, UserAccessLevels, ViewerAccessTypes } from 'constants/ids'

import { AppContextViewer } from 'services/AppContext/types'

// Admin
/**
 * @deprecated use instead:
 * const { viewer } = useAppContext()
 * viewer.role. ....
 */
export function isViewerAdminOwner(viewer: AppContextViewer): boolean {
  return (
    viewer.accessLevel === UserAccessLevels.Admin ||
    viewer.accessLevel === UserAccessLevels.Owner
  )
}

/**
 * @deprecated use instead:
 * const { viewer } = useAppContext()
 * viewer.role. ....
 */
export function isViewerCurrentlyAdminOwner(viewer: AppContextViewer): boolean {
  return (
    viewer.currentAccessLevel === UserAccessLevels.Admin ||
    viewer.currentAccessLevel === UserAccessLevels.Owner
  )
}

// Manager
/**
 * @deprecated use instead:
 * const { viewer } = useAppContext()
 * viewer.role. ....
 */
export function isViewerManager(viewer: AppContextViewer): boolean {
  return viewer.accessLevel === UserAccessLevels.Manager
}

/**
 * @deprecated use instead:
 * const { viewer } = useAppContext()
 * viewer.role. ....
 */
export function isViewerCurrentlyManager(viewer: AppContextViewer): boolean {
  return viewer.currentAccessLevel === UserAccessLevels.Manager
}

/**
 * @deprecated use instead:
 * const { viewer } = useAppContext()
 * viewer.role. ....
 */
export function isViewerPureManager(viewer: AppContextViewer): boolean {
  return isViewerManager(viewer) && !viewer.employees.length
}

/**
 * @deprecated use instead:
 * const { viewer } = useAppContext()
 * viewer.role. ....
 */
export function isViewerDepartmentManager(viewer: AppContextViewer): boolean {
  return (
    isViewerManager(viewer) &&
    viewer.managers[0].allManagedDepartments.length > 0
  )
}

/**
 * @deprecated use instead:
 * const { viewer } = useAppContext()
 * viewer.role. ....
 */
export function isViewerLocationManager(viewer: AppContextViewer): boolean {
  return (
    isViewerManager(viewer) && viewer.managers[0].allManagedBranches.length > 0
  )
}

export function checkViewerAccessToShiftJob({
  viewer,
  affectedDepartmentId,
  affectedBranchId,
}: {
  viewer: AppContextViewer
  affectedDepartmentId?: string
  affectedBranchId?: string
}): boolean {
  if (viewer.role.adminOrOwner) {
    return true
  }

  if (viewer.role.departmentManager) {
    return viewer?.managers[0]?.allManagedDepartments.some(
      ({ department }) => department?.id === affectedDepartmentId,
    )
  }

  if (viewer.role.locationManager) {
    return viewer?.managers[0]?.allManagedBranches.some(
      ({ branch }) => branch?.id === affectedBranchId,
    )
  }

  return false
}

export function filterShiftJobsByViewerAccess(
  viewer: AppContextViewer,
  shiftJobs: ShiftJob[],
): ShiftJob[] {
  const filteredShiftJobs = shiftJobs.filter(shiftJob =>
    checkViewerAccessToShiftJob({
      viewer,
      affectedDepartmentId: shiftJob.department.id,
      affectedBranchId: shiftJob.shift.branch.id,
    }),
  )
  return filteredShiftJobs
}

export function checkViewerAccessToAllShiftJobs(
  viewer: AppContextViewer,
  shiftJobs: ShiftJob[],
): boolean {
  return shiftJobs?.every(shiftJob =>
    checkViewerAccessToShiftJob({
      viewer,
      affectedDepartmentId: shiftJob.department.id,
      affectedBranchId: shiftJob.shift.branch.id,
    }),
  )
}

type CheckViewerAccessToSchedulesProps = {
  viewer: AppContextViewer
  schedules: Schedule[]
}

export function checkViewerAccessToSchedules({
  viewer,
  schedules,
}: CheckViewerAccessToSchedulesProps) {
  return schedules.every(schedule =>
    checkViewerAccessToShiftJob({
      viewer,
      affectedDepartmentId: schedule.department.id,
      affectedBranchId: schedule.shift.branch.id,
    }),
  )
}

// Employee
/**
 * @deprecated use instead:
 * const { viewer } = useAppContext()
 * viewer.role. ....
 */
export function isViewerEmployee(viewer: AppContextViewer): boolean {
  return viewer.accessLevel === UserAccessLevels.Employee
}

/**
 * @deprecated use instead:
 * const { viewer } = useAppContext()
 * viewer.role. ....
 */
export function isViewerCurrentlyEmployee(viewer: AppContextViewer): boolean {
  return viewer.currentAccessLevel === UserAccessLevels.Employee
}

/**
 * @deprecated use instead:
 * const { viewer } = useAppContext()
 * viewer.role. ....
 */
export function isViewerPureEmployee(viewer: AppContextViewer): boolean {
  return isViewerEmployee(viewer) && !viewer.managers.length
}

// Employer
/**
 * @deprecated use instead:
 * const { viewer } = useAppContext()
 * viewer.role. ....
 */
export function isViewerEmployer(viewer: AppContextViewer): boolean {
  return isViewerManager(viewer) || isViewerAdminOwner(viewer)
}

// Mutant
/**
 * @deprecated use instead:
 * const { viewer } = useAppContext()
 * viewer.role. ....
 */
export function isViewerMutant(viewer: AppContextViewer): boolean {
  return Boolean(viewer.managers.length && viewer.employees.length)
}

export function hasMultiRole(viewer: AppContextViewer) {
  switch (viewer.accessLevel) {
    case UserAccessLevels.Manager:
    case UserAccessLevels.Admin:
    case UserAccessLevels.Owner:
      return !isEmpty(viewer.employees)
    case UserAccessLevels.Employee:
      return !isEmpty(viewer.managers)
    default:
      return false
  }
}

export function getManagerBranchIds(viewer: AppContextViewer) {
  const branchIds = new Set()

  forEach(viewer?.managers || [], manager => {
    forEach(manager?.allManagedBranches || [], branch => {
      const branchId = branch.id
      if (branchId) branchIds.add(branchId)
    })
    forEach(manager?.allManagedDepartments || [], department => {
      const branchId = department?.branch?.id
      if (branchId) branchIds.add(branchId)
    })
  })

  return Array.from(branchIds)
}

export function getEmployeeBranchIds(viewer: AppContextViewer) {
  return viewer.employees?.at(0)?.branches?.map(branch => branch.id) ?? []
}

export function getViewerCurrentAccessLevel(
  viewer: AppContextViewer,
): AccessLevel {
  return getViewerAccessLevel(viewer, ViewerAccessTypes.CurrentAccessLevel)
}

export function getViewerMainAccessLevel(
  viewer: AppContextViewer,
): AccessLevel {
  return getViewerAccessLevel(viewer, ViewerAccessTypes.AccessLevel)
}

export function getViewerEmployeeId(
  viewer: AppContextViewer,
): string | undefined {
  return getViewerEmployee(viewer)?.id
}

export function getViewerEmployee(viewer: AppContextViewer): Employee {
  return viewer.employees[0]
}

function getViewerAccessLevel(
  viewer: AppContextViewer,
  typeOfAccessLevel: ViewerAccessTypes,
): AccessLevel {
  if (viewer[typeOfAccessLevel] === UserAccessLevels.Manager) {
    if (isEmpty(viewer.managers[0].allManagedDepartments)) {
      return AccessLevel.BranchManager
    }
    return AccessLevel.DepartmentManager
  }
  if (viewer[typeOfAccessLevel] === UserAccessLevels.Owner)
    return AccessLevel.Owner
  if (viewer[typeOfAccessLevel] === UserAccessLevels.Admin)
    return AccessLevel.Admin

  return AccessLevel.Employee
}

import React, { useMemo } from 'react'

import { Flex } from 'components/ui/__v2__/Grid'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { useShiftAssignmentContext } from '../../context'

export function GroupRestrictions() {
  const t = useI18n<typeof TRANSLATIONS.shiftAssign.positions>(
    'shiftAssign.groupRestrictions',
  )
  const { selectedShiftJob } = useShiftAssignmentContext()

  const groupRestrictions = useMemo(() => {
    if (!selectedShiftJob || selectedShiftJob?.shiftJobGroups?.length === 0)
      return null

    return (
      <div>
        <b>{t('title')}: </b>{' '}
        {selectedShiftJob?.shiftJobGroups
          ?.map(shiftJobGroup => shiftJobGroup?.group?.name)
          .join(', ')}
      </div>
    )
  }, [selectedShiftJob, t])

  return <Flex>{groupRestrictions}</Flex>
}

import { administration } from './config'
import { AbilityTree } from './types'

export function disableEmployeeArchive(tree: AbilityTree) {
  if (tree.archive.isOtherProfile.doAction) {
    tree.archive.isOtherProfile.doAction = administration
  }
}

export function disableEmployeeProfileEdit(tree: AbilityTree) {
  if (tree.profileInfo.isOtherProfile.update) {
    tree.profileInfo.isOtherProfile.update = administration
  }

  if (tree.gender.isOtherProfile.update) {
    tree.gender.isOtherProfile.update = administration
  }

  if (tree.timesheetCode.isOtherProfile.update) {
    tree.timesheetCode.isOtherProfile.update = administration
  }

  if (tree.faceId.isOtherProfile.update) {
    tree.faceId.isOtherProfile.update = administration
  }

  if (tree.roles.isOtherProfile.create) {
    tree.roles.isOtherProfile.create = administration
  }

  if (tree.roles.isOtherProfile.update) {
    tree.roles.isOtherProfile.update = administration
  }

  if (tree.roleTags.isOtherProfile.update) {
    tree.roleTags.isOtherProfile.update = administration
  }

  if (tree.additionalInformation.isOtherProfile.update) {
    tree.additionalInformation.isOtherProfile.update = administration
  }

  if (tree.changeAccessLevel.isOtherProfile.doAction) {
    tree.changeAccessLevel.isOtherProfile.doAction = administration
  }

  if (tree.changeAvatar.isOtherProfile.doAction) {
    tree.changeAvatar.isOtherProfile.doAction = administration
  }

  if (tree.changePassword.isOtherProfile.doAction) {
    tree.changePassword.isOtherProfile.doAction = administration
  }

  if (tree.changeFirstLastName.isOtherProfile.doAction) {
    tree.changeFirstLastName.isOtherProfile.doAction = administration
  }

  disableEmployeeArchive(tree)
}

export function restrictEmployeeSections(tree: AbilityTree) {
  if (tree.address.isOtherProfile.update) {
    tree.address.isOtherProfile.update = administration
  }

  if (tree.dateOfBirth.isOtherProfile.update) {
    tree.dateOfBirth.isOtherProfile.update = administration
  }

  if (tree.dateOfHiring.isOtherProfile.update) {
    tree.dateOfHiring.isOtherProfile.update = administration
  }

  if (tree.health_file.isOtherProfile.update) {
    tree.health_file.isOtherProfile.update = administration
  }

  if (tree.emergency_contact.isOtherProfile.update) {
    tree.emergency_contact.isOtherProfile.update = administration
  }
}

import React, { useCallback, useMemo, useState } from 'react'

import get from 'lodash/get'

import OutlineSvgIcon from 'components/ui/Icons/outline'

import { Text, TextTooltipCellContainer, Tooltip } from './styles'

const TextTooltipCell = cell => {
  const [openedTooltip, setOpenedTooltip] = useState(false)
  const comment = useMemo(() => get(cell, ['comments'], ''), [cell])

  const openTooltip = useCallback(() => {
    setOpenedTooltip(true)
  }, [setOpenedTooltip])

  const closeTooltip = useCallback(() => {
    setOpenedTooltip(true)
  }, [setOpenedTooltip])

  return comment ? (
    <TextTooltipCellContainer>
      <Text>{comment}</Text>
      <div onMouseEnter={openTooltip} onMouseLeave={closeTooltip}>
        <OutlineSvgIcon />
      </div>
      {openedTooltip && <Tooltip>{comment}</Tooltip>}
    </TextTooltipCellContainer>
  ) : null
}

export default TextTooltipCell

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { SpinnerBig } from 'components/ui/Spinner'

/**
 * @typedef WrapeeComponentProps
 * @property {function(): void} onInit - Init handler
 */

/**
 * Spinner HoC
 *
 * @param {React.ComponentType<WrapeeComponentProps>} Component - Component to render
 * @param {object} [options] - HoC options
 * @param {string} [options.prop=isLoading] - Prop name to indicate loading state
 * @param {React.ReactNode} [options.Spinner=SpinnerBig] - Spinner to render if loading
 * @returns {React.ComponentType} HoC wrapper
 * @deprecated Please handle this in your component!
 */
export default function withLoading(Component, options = {}) {
  const {
    prop = 'isLoading',
    Spinner = SpinnerBig,
    ignoreUpdate = false,
  } = options

  const WithLoading = props => {
    const { onInit } = props

    const mounted = useRef(false)

    useEffect(() => {
      async function load() {
        if (!ignoreUpdate || !mounted.current) {
          await onInit()
          mounted.current = true
        }
      }

      load()
    }, [onInit])

    if ((!ignoreUpdate || !mounted.current) && get(props, prop, false)) {
      return <Spinner />
    }

    return <Component {...props} />
  }

  WithLoading.propTypes = {
    onInit: PropTypes.func,
  }

  WithLoading.defaultProps = {
    onInit: noop,
  }

  return WithLoading
}

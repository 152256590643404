import { useApolloQuery } from 'API/services/Apollo'

import { PayFrequenciesByOffsetQuery } from './GraphQL'

type Props = {
  paging: Gateway.QueryPayFrequenciesByOffsetArgs['paging']
  sorting: Gateway.QueryPayFrequenciesByOffsetArgs['sorting']
}

export function usePayFrequenciesByOffset({ paging, sorting }: Props) {
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'payFrequenciesByOffset'>,
    Gateway.QueryPayFrequenciesByOffsetArgs
  >(PayFrequenciesByOffsetQuery, {
    variables: {
      sorting,
      paging,
    },
    fetchPolicy: 'network-only',
  })

  const payFrequencies = data?.payFrequenciesByOffset?.rows ?? []

  return {
    payFrequencies,
    loading,
    refetch,
    total: data?.payFrequenciesByOffset?.pageInfo.total ?? 0,
  }
}

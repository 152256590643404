import { useMemo } from 'react'

import { useApolloLazyQuery } from 'API/services/Apollo'

import isNil from 'lodash/isNil'

import {
  EmploymentTypeSortingField,
  SortingDirection,
} from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

import {
  EmploymentTypesByCursorQuery,
  EmploymentTypesByCursorQueryData,
  WithWorkflowsFlag,
} from './GraphQL'

export type UsePickerEmploymentTypesArgs = {
  withArchived?: boolean
} & WithWorkflowsFlag

type QueryArgs = Gateway.QueryEmploymentTypesByCursorArgs & WithWorkflowsFlag

// Note: we need a proper API-driven search by name / code
export function usePickerEmploymentTypes({
  withArchived,
  withWorkflows,
}: UsePickerEmploymentTypesArgs) {
  const [loadEmploymentTypes, { data, loading, refetch }] = useApolloLazyQuery<
    EmploymentTypesByCursorQueryData<typeof withWorkflows>,
    QueryArgs
  >(EmploymentTypesByCursorQuery, {
    variables: {
      paging: { limit: 999 },
      sorting: {
        field: EmploymentTypeSortingField.Name,
        direction: SortingDirection.Asc,
      },
      filter: {
        archived: !isNil(withArchived)
          ? Utils.GraphQL.eqFilter(withArchived)
          : undefined,
      },
      withWorkflows,
    },
    fetchPolicy: 'network-only',
  })

  // FIXME: Could not find a way for TS to discriminate the returned type based on the withWorkflows parameter. Keep in mind and use with caution.
  const employmentTypes = useMemo(
    () => data?.employmentTypesByCursor?.edges.map(edge => edge.node) ?? [],
    [data],
  )

  return {
    loadEmploymentTypes,
    employmentTypes,
    fetching: loading,
    refetch,
  }
}

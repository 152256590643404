import { createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  LOAD_MORE_NETHRIS_TABLE,
  LOAD_NETHRIS_TABLE,
  TOGGLE_TAB,
} from 'store/actions/payroll'

const initialState = Immutable({
  isPayrollTab: true,
  nethrisPayrollExport: [],
  isNethrisTableLoading: false,
  paged: {},
})

const handlers = {
  [TOGGLE_TAB]: state =>
    state.merge({
      isPayrollTab: !state.isPayrollTab,
    }),
  [LOAD_NETHRIS_TABLE.REQUEST]: state =>
    state.merge({
      isNethrisTableLoading: true,
    }),

  [LOAD_NETHRIS_TABLE.SUCCESS]: createLoadHandler('nethrisPayrollExport', {
    addToState: {
      isNethrisTableLoading: false,
    },
    withReplace: true,
  }),

  [LOAD_NETHRIS_TABLE.FAILURE]: state =>
    state.merge({
      isNethrisTableLoading: false,
      nethrisPayrollExport: [],
    }),

  [LOAD_MORE_NETHRIS_TABLE.REQUEST]: state =>
    state.merge({
      isNethrisTableLoading: true,
    }),

  [LOAD_MORE_NETHRIS_TABLE.SUCCESS]: createLoadHandler('nethrisPayrollExport', {
    addToState: {
      isNethrisTableLoading: false,
    },
  }),

  [LOAD_MORE_NETHRIS_TABLE.FAILURE]: state =>
    state.merge({
      isNethrisTableLoading: false,
      nethrisPayrollExport: [],
    }),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

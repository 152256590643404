import { Employee, JobEmployee } from 'Types/app'

import { isRoleEffective } from './Role'

export function primaryBranch(employee: Employee) {
  return employee.branch
}

export function primaryBranchTZ(employee: Employee) {
  return primaryBranch(employee)?.settings?.timezone ?? 'local'
}

export function activeRoles(employee: Employee): JobEmployee[] {
  const timezone = primaryBranchTZ(employee)
  return employee.jobsEmployees.filter(employeeJob =>
    isRoleEffective({ employeeJob, timezone }),
  )
}

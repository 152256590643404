import React, { useMemo } from 'react'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Box } from 'components/ui/__v2__/Grid'
import WaLogoTextIcon from 'components/ui/Icons/wa-logo-text'

import _ from 'i18n'

import { BRAND_TYPE } from 'services/Brand'

const BrandName = styled(Box)`
  color: ${themeGet('layout.primary')};
  font-size: 20px;
`

const RenderBrandText = ({ type }) => {
  const brandText = useMemo(() => {
    switch (type) {
      case BRAND_TYPE.WORKAXLE:
      case BRAND_TYPE.ALDO:
      case BRAND_TYPE.NETEASE:
        return <WaLogoTextIcon />
      case BRAND_TYPE.GARDA:
      case BRAND_TYPE.DEMO:
        return null

      default:
        return <BrandName>{_('brand')}</BrandName>
    }
  }, [type])

  return brandText
}

export default RenderBrandText

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const RolesInfo = styled.div`
  font-size: 14px;
  color: ${themeGet('layout.lightText')};
`

export const Container = styled.div`
  width: 100%;
`

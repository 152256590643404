import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { BaseButton } from './BaseButton'

export const TableHeaderIconButton = styled(BaseButton)`
  height: 14px;
  width: 14px;
  padding: 0;

  background: none;

  // TODO: add to Buttons or Table theme
  color: ${themeGet('colors.DODGER_BLUE')};

  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`

import includes from 'lodash/includes'

import { CompanyIdentity } from 'constants/ids'

export enum EmployeeProfileFields {
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  Gender = 'gender',
  EmployeeCode = 'employeeCode',
  HiredAt = 'hiredAt',
  Birthday = 'birthday',
  Phone = 'phone',
  Address = 'address',
}

const DEFAULT_REQUIRED_FIELDS = [
  EmployeeProfileFields.Email,
  EmployeeProfileFields.FirstName,
  EmployeeProfileFields.LastName,
] as const

const DELUXE_REQUIRED_FIELDS = [
  EmployeeProfileFields.Email,
  EmployeeProfileFields.FirstName,
  EmployeeProfileFields.LastName,
  EmployeeProfileFields.EmployeeCode,
  EmployeeProfileFields.Birthday,
  EmployeeProfileFields.HiredAt,
] as const

const NAHDI_REQUIRED_FIELDS = [
  EmployeeProfileFields.Email,
  EmployeeProfileFields.FirstName,
  EmployeeProfileFields.LastName,
  EmployeeProfileFields.EmployeeCode,
  EmployeeProfileFields.Gender,
  EmployeeProfileFields.Phone,
] as const

const NETEASE_REQUIRED_FIELDS = [
  EmployeeProfileFields.Email,
  EmployeeProfileFields.FirstName,
  EmployeeProfileFields.LastName,
] as const

const FPS_REQUIRED_FIELDS = [
  EmployeeProfileFields.Email,
  EmployeeProfileFields.FirstName,
  EmployeeProfileFields.LastName,
  EmployeeProfileFields.Birthday,
  EmployeeProfileFields.Address,
  EmployeeProfileFields.Phone,
  EmployeeProfileFields.Gender,
  EmployeeProfileFields.EmployeeCode,
] as const

export function getRequiredProfileFields(identity: CompanyIdentity) {
  function getSettings() {
    switch (identity) {
      case CompanyIdentity.Nahdi:
        return NAHDI_REQUIRED_FIELDS
      case CompanyIdentity.Deluxe:
        return DELUXE_REQUIRED_FIELDS
      case CompanyIdentity.Netease:
        return NETEASE_REQUIRED_FIELDS
      case CompanyIdentity.Fps:
        return FPS_REQUIRED_FIELDS
      default:
        return DEFAULT_REQUIRED_FIELDS
    }
  }

  const settings = getSettings()

  return Object.fromEntries(
    Object.values(EmployeeProfileFields).map(
      fieldName => [fieldName, includes(settings, fieldName)] as const,
    ),
    // Note: this problem is well known and doesn't have a fast solution
  ) as Record<EmployeeProfileFields, boolean>
}

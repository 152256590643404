import { all, fork, put, select, take } from 'redux-saga/effects'

import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import values from 'lodash/values'

import { isAvailableEmployeeOnShift } from 'constants/availability'
import { findEmployeeTimeoff } from 'constants/shifts'

import { getFirstEntity } from 'helpers/redux'

import { UPDATE_BRANCH } from 'store/actions/company/branches'
import {
  loadSchedules,
  loadTimeEntries,
} from 'store/actions/employees/schedules'
import {
  copyPrevWeekDone,
  CREATE_SHIFT,
  CREATE_SHIFTS_JOBS,
  createPauses,
  createShiftsJobs,
  deletePauses,
  EDIT_SHIFT,
  INIT,
  initDone,
  LOAD_RANGE,
  loadShifts,
  UPDATE_SHIFTS_JOBS,
  updatePauses,
  updateShiftsJobsEmployees,
} from 'store/actions/employees/shifts'
import { getIsShiftsLoading, getWeek } from 'store/selectors/employees/shifts'

function* initialLoad() {
  while (true) {
    yield take(INIT)
    const isRestoreSessionLoading = yield select(getIsShiftsLoading)
    // prevent load range on restore session
    if (!isRestoreSessionLoading) {
      yield put(loadShifts())
      yield take(LOAD_RANGE.SUCCESS)
    }
    yield put(initDone())
  }
}

function* watchCreateShifts() {
  while (true) {
    const { payload } = yield take(CREATE_SHIFT.SUCCESS)

    const shifts = payload?.data?.shifts
    const roles = payload?.roles
    const pauses = payload?.pauses
    const isLastCopy = payload?.isLastCopy || false

    if (shifts) {
      if (!isEmpty(roles)) {
        yield put(
          createShiftsJobs(
            {
              shifts: Object.keys(shifts),
              roles,
            },
            isLastCopy,
          ),
        )
      }
      if (!isEmpty(pauses)) {
        yield put(
          createPauses({
            shifts: Object.keys(shifts),
            pauses,
          }),
        )
      }
    } else if (isLastCopy) {
      yield put(copyPrevWeekDone())
    }
  }
}

function* uploadSchedules() {
  while (true) {
    yield take(LOAD_RANGE.SUCCESS)
    const week = yield select(getWeek)
    yield put(loadSchedules(week.start, week.end))
    yield put(loadTimeEntries(week.start, week.end))
  }
}

function* watchEditShift() {
  while (true) {
    const { payload } = yield take(EDIT_SHIFT.SUCCESS)

    const shifts = payload?.data?.shifts
    const newPauses = payload?.newPauses
    const droppedPauses = payload?.droppedPauses
    const updatedPauses = payload?.updatedPauses

    if (shifts) {
      if (!isEmpty(newPauses)) {
        yield put(
          createPauses({
            shifts: Object.keys(shifts),
            pauses: newPauses,
          }),
        )
      }
      if (!isEmpty(updatedPauses)) {
        yield put(
          updatePauses({
            shifts: Object.keys(shifts),
            pauses: updatedPauses,
          }),
        )
      }
      if (!isEmpty(droppedPauses)) {
        yield put(deletePauses(getFirstEntity(shifts).id, droppedPauses))
      }
    }
  }
}

// Same people (assignments) should be copied to the current week from the previous week
function* watchCreateShiftsJobs() {
  while (true) {
    const { payload } = yield take(CREATE_SHIFTS_JOBS.SUCCESS)
    const roles = payload?.roles || []
    const isLastCopy = payload?.isLastCopy || false
    const rolesToCopy = filter(roles, role => !isEmpty(role?.assignedEmployees))

    if (!isEmpty(rolesToCopy)) {
      const shiftsJobs = values(payload?.data?.shiftsJobs || {})
      const shift = values(payload?.data?.shifts, {})[0]
      const shiftStartAt = shift?.attributes?.startAt || ''
      const shiftFinishAt = shift?.attributes?.finishAt || ''

      const selectedEmployeeIds = new Map()
      rolesToCopy.forEach(roleToCopy => {
        const { assignedEmployees: assignedEmployeesJSON } = roleToCopy
        const assignedEmployees = map(assignedEmployeesJSON, employee =>
          JSON.parse(employee),
        )

        const suitableAssignedEmployees = filter(
          assignedEmployees,
          employee => {
            const employeeTimoff = findEmployeeTimeoff(employee, shiftStartAt)
            const isAvailable = isAvailableEmployeeOnShift(
              employee,
              shiftStartAt,
              shiftFinishAt,
            )
            return isEmpty(employeeTimoff) && isAvailable
          },
        )

        if (!isEmpty(suitableAssignedEmployees)) {
          const { id, amount } = roleToCopy
          const suitableAssignedEmployeesIds = map(
            suitableAssignedEmployees,
            'id',
          )
          const relatedShiftsJob = find(shiftsJobs, shiftsJob => {
            const jobId = shiftsJob?.relationships?.job?.data?.id || ''
            const quantity = shiftsJob?.attributes?.quantity || ''
            return jobId && quantity && jobId === id && quantity === amount
          })
          if (!isEmpty(relatedShiftsJob)) {
            selectedEmployeeIds.set(
              relatedShiftsJob.id,
              suitableAssignedEmployeesIds,
            )
          }
        }
      })

      if (selectedEmployeeIds.size > 0) {
        yield put(updateShiftsJobsEmployees(selectedEmployeeIds, isLastCopy))
      } else if (isLastCopy) {
        yield put(copyPrevWeekDone())
      }
    } else if (isLastCopy) {
      yield put(copyPrevWeekDone())
    }
  }
}

function* watchLastCopyStep() {
  while (true) {
    const {
      payload: { isLastCopy },
    } = yield take(UPDATE_SHIFTS_JOBS.SUCCESS)
    if (isLastCopy) {
      yield put(copyPrevWeekDone())
    }
  }
}

// Update Schedules, Shifts if location settings updated
function* watchLocationSettingsUpdate() {
  while (true) {
    yield take(UPDATE_BRANCH.SUCCESS)

    const week = yield select(getWeek)
    yield put(loadShifts(week.start, week.end))
  }
}

export default function* rootSchedule() {
  yield all([
    fork(initialLoad),
    fork(watchCreateShifts),
    fork(watchEditShift),
    fork(uploadSchedules),
    fork(watchCreateShiftsJobs),
    fork(watchLastCopyStep),
    fork(watchLocationSettingsUpdate),
  ])
}

import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import { graphQLResultHasError } from '@apollo/client/utilities'
import { API } from 'API'

import { Box, SpacedColumn } from 'components/ui/__v2__/Grid'
import { Button } from 'components/ui/__v3__'
import { FormText } from 'components/ui/__v3__/HookForm'
import { Modal } from 'components/ui/__v3__/Modal'

import { useI18n } from 'hooks'

import i18n from 'i18n'

import { Text } from './styles'
import { ShiftsArchiveFormState } from './types'

type Props = {
  open: boolean
  shiftIds: string[]
  onClose: (success: boolean) => void
}

export function ShiftArchiveModal({ open, shiftIds, onClose }: Props) {
  const t = useI18n('shiftArchiveModal')

  const { archive, archiving } = API.Shift.archiveMany()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<ShiftsArchiveFormState>({
    mode: 'onBlur',
  })

  async function onSubmit(data: ShiftsArchiveFormState) {
    const { reason } = data

    const response = await archive({
      ids: shiftIds,
      reason,
    })
    const hasError = graphQLResultHasError(response)
    onClose(!hasError)
  }

  const handleClose = useCallback(() => {
    onClose(false)
  }, [onClose])

  return (
    <Modal.Container isOpen={open}>
      <Modal.Close onClose={handleClose} />
      <Modal.Title>{t('title')}</Modal.Title>

      <Box justifyContent="center" m="0 auto" maxWidth="320px">
        <Text>{t('description')}</Text>
        <Text mt={5}>{t('confirmation')}</Text>
      </Box>

      <form>
        <SpacedColumn my={2}>
          <FormText
            as="textarea"
            control={control}
            disableResize
            height="100px"
            labelText={t('form.reason.label')}
            name="reason"
            placeholder={t('form.reason.placeholder')}
            type="text"
          />
        </SpacedColumn>

        <Modal.Actions justifyContent="center">
          <Button mr={17} secondary width={180} onClick={handleClose}>
            {i18n('actions.cancel')}
          </Button>

          <Button
            disabled={!isValid || isSubmitting || archiving}
            width={180}
            onClick={handleSubmit(onSubmit)}
          >
            {t('form.actions.confirm')}
          </Button>
        </Modal.Actions>
      </form>
    </Modal.Container>
  )
}

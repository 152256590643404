import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Span } from '../Grid'

export const ValidationError = styled(Span)`
  color: ${themeGet('layout.primary')};
`

export const PlainText = styled(Span)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

import { useApolloMutation } from 'API/services/Apollo'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { EMPLOYMENT_TYPE_ERRORS_I18N } from './errors'
import { UpdateEmploymentTypeMutation } from './GraphQL'

export function useUpdateEmploymentType() {
  const t = useI18n<
    typeof TRANSLATIONS.employmentTerms.employmentTypes.toasts.update
  >('employmentTerms.employmentTypes.toasts.update')

  const [innerUpdate, { loading: updating }] = useApolloMutation<
    MutationData<'updateEmploymentType'>,
    Gateway.MutationUpdateEmploymentTypeArgs
  >(UpdateEmploymentTypeMutation, {
    silent: true,
    // TODO: Temporary solution, translated errors should come from Gateway
    onError: error => {
      const errorMessage =
        error.message.includes(EMPLOYMENT_TYPE_ERRORS_I18N.update.message) &&
        EMPLOYMENT_TYPE_ERRORS_I18N.update.translation

      showToast({
        type: 'error',
        title: t('error.title'),
        content: errorMessage || t('error.message'),
      })
    },
    onCompleted: () => {
      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const updateEmploymentType = async (
    params: Gateway.UpdateEmploymentTypeInput,
  ) =>
    innerUpdate({
      variables: {
        input: params,
      },
    })

  return { updateEmploymentType, updating }
}

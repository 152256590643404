export enum Fields {
  CurrentHolidayDate = 'currentHolidayDate',
  StartDate = 'startDate',
  Comment = 'comment',
}
export type MoveTimeoffFormState = {
  [Fields.CurrentHolidayDate]: string
  [Fields.StartDate]: string
  [Fields.Comment]: string
}

import { useApolloMutation } from 'API/services/Apollo'

import { StartPulledFileProcessingMutation } from './GraphQL'

export function useStartProcessing() {
  const [innerStartProcessing, { loading }] = useApolloMutation<
    MutationData<'startPulledFileProcessing'>,
    Gateway.MutationStartPulledFileProcessingArgs
  >(StartPulledFileProcessingMutation)

  async function startProcessing(id: string) {
    const result = await innerStartProcessing({
      variables: {
        id,
      },
    })
    return { ok: !result.errors }
  }

  return {
    startProcessing,
    startingProcessing: loading,
  }
}

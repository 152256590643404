import { AiOutlinePlusCircle } from 'react-icons/ai'

import styled, { css } from 'styled-components'

import { BaseButton } from 'components/ui/__v3__/Button/BaseButton'

import { TimesheetState } from './types'

const paddingCss = css`
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[4]}px;
`

export const Days = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]}px;
  ${paddingCss}
`

export const Day = styled.div`
  display: flex;
  flex-direction: column;
`

export const DayHeader = styled.div`
  display: flex;
  flex-direction: column;
`

export const RecordsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]}px;
`

export const NoRecords = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  color: ${({ theme }) => theme.layout.darkBorder};
  height: 38px;
`

export const Layout = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: auto 240px;
  grid-gap: ${({ theme }) => theme.space[2]}px;
  width: 100%;
`

export const AddIcon = styled(AiOutlinePlusCircle)`
  color: ${({ theme }) => theme.layout.primary};
`

export const IconButton = styled(BaseButton)`
  background: none;

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    & > svg {
      color: ${({ theme }) => theme.layout.disabled} !important;
    }
  }
`

export const RightPane = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[3]}px;
  gap: ${({ theme }) => theme.space[2]}px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  ${paddingCss}
`

export const SummaryHeader = styled.div`
  display: flex;
  flex-direction: column;
`

type StatusProps = {
  status: TimesheetState
}

export const stateColorCss = ({ status }: StatusProps) => {
  return css`
    ${({ theme }) => {
      if (status === TimesheetState.Unsubmitted) {
        return theme.layout.darkBorder
      }

      if (status === TimesheetState.Pending) {
        return theme.toasts.secondaryColor.info
      }

      if (status === TimesheetState.Approved) {
        return theme.toasts.secondaryColor.success
      }

      if (status === TimesheetState.Discarded) {
        return theme.toasts.secondaryColor.error
      }

      return theme.layout.darkBorder
    }}
  `
}

export const TimesheetStatus = styled.span<StatusProps>`
  padding: ${({ theme }) => theme.space[2]}px;
  line-height: 14px;
  text-align: center;
  height: 32px;
  border-width: 1px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.space[1]}px;
  width: 140px;
  color: ${stateColorCss};
  border-color: ${stateColorCss};
`

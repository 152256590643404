import { ACCESS_LEVEL_KIND } from 'constants/temporaryAccessLevel'

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

// prettier-ignore
export const CHANGE_ACCESS_LEVEL = createAsyncAction('users/CHANGE_ACCESS_LEVEL')
// prettier-ignore
export const SET_TEMPORARY_ACCESS_LEVEL = createAsyncAction('users/SET_TEMPORARY_ACCESS_LEVEL')
// prettier-ignore
export const DELETE_TEMPORARY_BRANCH_ACCESS = createAsyncAction('users/DELETE_TEMPORARY_BRANCH_ACCESS')
// prettier-ignore
export const DELETE_TEMPORARY_DEPARTMENT_ACCESS = createAsyncAction('users/DELETE_TEMPORARY_DEPARTMENT_ACCESS')

export const changeAccessLevel = ({
  userId,
  accessLevel,
  entities,
  addEmployeeAccess,
  permissions,
}) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  return dispatch(
    apiCall({
      method: 'PATCH',
      endpoint: `/companies/${companyId}/users/${userId}/access_level`,
      types: CHANGE_ACCESS_LEVEL,
      query: {
        data: {
          attributes: {
            accessLevel,
            entities,
            addEmployeeAccess,
            permissions,
          },
        },
      },
    }),
  )
}

// TODO: Fix this to match new endpoints
export const setTemporaryAccessLevel = ({
  accessLevel,
  branchDepartmentPairs,
  from,
  locationIds,
  to,
  userId,
}) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  function getQueryData() {
    const attributes = {
      start_on: from,
      end_on: to,
    }

    if (accessLevel === ACCESS_LEVEL_KIND.DEPARTMENT_MANAGER) {
      // We need to get department + branch pairs

      return branchDepartmentPairs.map(tuple => ({
        attributes,
        relationships: {
          branches: {
            data: {
              id: tuple[0],
              type: 'branches',
            },
          },
          departments: {
            data: {
              id: tuple[1],
              type: 'departments',
            },
          },
        },
      }))
    }

    if (accessLevel === ACCESS_LEVEL_KIND.LOCATION_MANAGER) {
      return locationIds.map(location => ({
        attributes,
        relationships: {
          branches: {
            data: {
              id: location,
              type: 'branches',
            },
          },
        },
      }))
    }

    return []
  }

  return dispatch(
    apiCall({
      method: 'POST',
      endpoint: `/companies/${companyId}/users/${userId}/temporary_access_levels`,
      types: SET_TEMPORARY_ACCESS_LEVEL,
      query: {
        include: 'all_managed_branches,all_managed_departments.branch',
        data: getQueryData(),
      },
    }),
  )
}

export const deleteTemporaryBranchAccess = ({ managerId, accessId }) => {
  return apiCall({
    method: 'DELETE',
    types: DELETE_TEMPORARY_BRANCH_ACCESS,
    endpoint: `/managers/${managerId}/managed_branches/${accessId}`,
    payload: { relationId: managerId, deletedId: accessId },
  })
}

export const deleteTemporaryDepartmentAccess = ({ managerId, accessId }) => {
  return apiCall({
    method: 'DELETE',
    types: DELETE_TEMPORARY_DEPARTMENT_ACCESS,
    endpoint: `/managers/${managerId}/managed_departments/${accessId}`,
    payload: { relationId: managerId, deletedId: accessId },
  })
}

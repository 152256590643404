import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import Tippy from '@tippyjs/react/headless'

import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import noop from 'lodash/noop'

import { Arrow, PopoverBlock } from './styles'

const POPOVER_BLOCK_BOT_PADDING = 24

/**
 * @see https://atomiks.github.io/tippyjs/v6/all-props for tippy props
 * @see https://github.com/atomiks/tippyjs-react for react props
 */
function Popover({
  zIndex,
  animation,
  content,
  noArrow,
  plugins,
  render: RenderOrComponent,
  withMaxHeight,
  onHide,
  onMount,
  onPopoverClick,
  ...rest
}) {
  const [mounted, setMounted] = useState(false)
  const [arrow, setArrow] = useState(null)
  const [animated, setAnimated] = useState(false)
  /**
   * Makes PopoverBlock non-visible with height: 0 to prevent body scroll
   */
  const [blockFloating, setBlockFloating] = useState(true)
  const [maxBlockHeight, setMaxBlockHeight] = useState(null)

  /**
   * @see https://github.com/atomiks/tippyjs-react#lazy-mounting
   */
  const lazy = useMemo(
    () => ({
      fn: () => ({
        onShow: instance => {
          setBlockFloating(true)
          setMounted(true)

          /**
           * Just hope the popover is rendered when this callback is called 🤞
           */
          setTimeout(() => {
            if (instance.popper) {
              const { top } = instance.popper.getBoundingClientRect()

              const maxHeight =
                window.innerHeight - top - POPOVER_BLOCK_BOT_PADDING

              setMaxBlockHeight(maxHeight)
            }

            setBlockFloating(false)
          })
        },

        onHidden: () => {
          setMounted(false)
          setMaxBlockHeight(null)
        },
      }),
    }),
    [],
  )

  const render = useCallback(
    attrs => {
      if (!mounted) {
        return null
      }

      const props = {}

      if (maxBlockHeight != null && withMaxHeight) {
        props.maxHeight = `${maxBlockHeight}px`
      }

      return (
        <PopoverBlock
          animated={animation && animated}
          animation={animation}
          floating={blockFloating}
          {...attrs}
          {...pick(rest)}
          {...props}
          onClick={onPopoverClick}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {isObject(RenderOrComponent) ? (
            <RenderOrComponent {...attrs} />
          ) : isFunction(RenderOrComponent) ? (
            RenderOrComponent(attrs)
          ) : (
            content
          )}
          {!noArrow && (
            <Arrow backgroundColor={rest.backgroundColor} ref={setArrow} />
          )}
        </PopoverBlock>
      )
    },
    [
      mounted,
      maxBlockHeight,
      withMaxHeight,
      animation,
      animated,
      blockFloating,
      rest,
      onPopoverClick,
      RenderOrComponent,
      content,
      noArrow,
    ],
  )

  const handleMount = useCallback(
    (...args) => {
      if (onMount) {
        onMount(...args)
      }
      setAnimated(true)
    },
    [onMount],
  )

  const handleHide = useCallback(
    (...args) => {
      if (onHide) {
        onHide(...args)
      }
      setAnimated(false)
    },
    [onHide],
  )

  const popperOptions = useMemo(
    () => ({
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrow,
          },
        },
        {
          name: 'flip',
          options: {
            padding: {
              top: 64,
            },
          },
        },
      ],
    }),
    [arrow],
  )

  return (
    <Tippy
      animation={animation}
      plugins={[...plugins, lazy]}
      popperOptions={popperOptions}
      render={render}
      zIndex={zIndex}
      {...rest}
      onHide={handleHide}
      onMount={handleMount}
    />
  )
}

Popover.delay = [270, 0]

Popover.defaultProps = {
  ...Tippy.defaultProps,
  appendTo: document.body,
  content: null,
  plugins: [],
  render: null,
  noArrow: false,
  offset: [0, 8],
  withMaxHeight: false,
  onPopoverClick: noop,
}

Popover.propTypes = {
  ...Tippy.propTypes,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  noArrow: PropTypes.bool,
  offset: PropTypes.arrayOf(PropTypes.number),
  plugins: PropTypes.array,
  render: PropTypes.func,
  withMaxHeight: PropTypes.bool,
  onPopoverClick: PropTypes.func,
}

export default Popover

import React, { useMemo } from 'react'

import { useTheme } from 'styled-components'

import { DateTime } from 'luxon'

import { Flex, Span } from 'components/ui/__v2__/Grid'

import { useI18n } from 'hooks'

import { i18n, TRANSLATIONS } from 'i18n'

import { ItemContainer } from './styles'

type Props = {
  estimatedBalance: Gateway.EstimatedTimeoffBalance
  hideYear?: boolean
}

export function Item({ estimatedBalance, hideYear }: Props) {
  const theme = useTheme()
  const t = useI18n<typeof TRANSLATIONS.timeOffModal.estimatedBalances>(
    'timeOffModal.estimatedBalances',
  )

  const {
    year,
    availableBalance,
    totalBookedBalance,
    remainingBalance,
  } = estimatedBalance

  const { startOfYear, endOfYear } = useMemo(
    () => ({
      startOfYear: DateTime.fromObject({ year, month: 1, day: 1 }).toFormat(
        'LLL d, yyyy',
      ),
      endOfYear: DateTime.fromObject({ year, month: 12, day: 31 }).toFormat(
        'LLL d, yyyy',
      ),
    }),
    [year],
  )

  return (
    <ItemContainer>
      {!hideYear && (
        <Flex flexDirection="column">
          <Span fontWeight={2}>{t('referenceYear', { year })}</Span>
          <Span color={theme.colors.GRAY} fontSize="11px">
            ({startOfYear} - {endOfYear})
          </Span>
        </Flex>
      )}

      <Flex flexDirection="column" mt="auto">
        <Span>
          {availableBalance} {i18n('common.hoursShort')}
        </Span>
        <Span>
          {totalBookedBalance} {i18n('common.hoursShort')}
        </Span>
      </Flex>

      <Flex height="29px">
        <Span mt="auto">
          {remainingBalance} {i18n('common.hoursShort')}
        </Span>
      </Flex>
    </ItemContainer>
  )
}

import React, { useCallback } from 'react'
import { useForm } from 'react-final-form'
import PropTypes from 'prop-types'

import forEach from 'lodash/forEach'

import { IconError } from 'components/ui/__v3__/Input/Errors'

import { ValidationError } from './styles'

import { Radio } from '../Radio'

/**
 * @see https://final-form.org/docs/react-final-form/types/FieldRenderProps for FormRenderProps props
 * @see {@link ../Radio/Radio.js} for Radio props
 */
function FormRadio({
  input,
  dependentFields,
  dependentFieldsNullValue,
  meta,
  onBlur,
  ...rest
}) {
  const { change } = useForm()

  const { name } = input

  const handleChange = useCallback(
    event => {
      change(name, event.target.value)

      const isValueNull =
        !event.target.value ||
        (dependentFieldsNullValue &&
          event.target.value === dependentFieldsNullValue)
      if (isValueNull) {
        forEach(dependentFields, field => {
          change(field, null)
        })
      }

      if (onBlur) {
        onBlur()
      }
    },
    [name, change, dependentFields, dependentFieldsNullValue, onBlur],
  )

  return (
    <Radio
      {...input}
      helper={
        meta.error &&
        meta.touched && (
          <ValidationError>
            <IconError> {meta.error}</IconError>
          </ValidationError>
        )
      }
      onChange={handleChange}
      {...rest}
    />
  )
}

FormRadio.defaultProps = {
  dependentFields: [],
  dependentFieldsNullValue: null,
}

FormRadio.propTypes = {
  ...Radio.propTypes,
  dependentFields: PropTypes.array,
  dependentFieldsNullValue: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export default FormRadio

import { DateTime } from 'luxon'

import filter from 'lodash/filter'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import remove from 'lodash/remove'
import replace from 'lodash/replace'
import trim from 'lodash/trim'

import incomingMessageRingtone from 'assets/ringtones/incoming_message.mp3'

import { createEmojiContent } from 'constants/emoji'

import { buildFullName } from 'helpers/employee'

import _ from 'i18n'

import { createAvatarSrc } from './avatar'
import { chatApi } from './config'
import { ACCESS_LEVEL } from './ids'

export const CHAT_KINDS = {
  group: 'group',
  private: 'private',
}

export const SYSTEM_MESSAGES = {
  userJoined: 'user joined',
  userLeft: 'user left',
  deletedFromCompany: 'user deleted from company',
}

export const MESSAGE_TYPES = {
  file: 'file',
  image: 'image',
  video: 'video',
  text: 'text',
}

export const CHATS_LIMIT = 60
export const MESSAGES_LIMIT = 55

export const isGroupChat = chatKind => chatKind === CHAT_KINDS.group

export const parseContentType = type => {
  let contentType = ''
  if (type && typeof type === 'string') {
    if (type.search('video') !== -1) {
      contentType = MESSAGE_TYPES.video
    } else if (type.search('image') !== -1) {
      contentType = MESSAGE_TYPES.image
    } else {
      contentType = MESSAGE_TYPES.file
    }
  }
  return contentType
}

export const sortThreadMembers = (members, viewerId) => {
  const membersWithoutViewer = remove(members, member => member.id !== viewerId)
  const membersWithoutAccess = filter(
    membersWithoutViewer,
    member => get(member, 'accessLevel', '') === '',
  )

  const filterByAccessLevel = (users, accessLevel) =>
    filter(users, user => user.accessLevel === accessLevel)

  let result = []
  let viewer = find(members, ['id', viewerId])
  viewer = !isEmpty(viewer) ? [viewer] : []

  try {
    const filteredMap = map(ACCESS_LEVEL, accessLevel =>
      filterByAccessLevel(membersWithoutViewer, accessLevel),
    )

    forEach(filteredMap, mapArray => {
      result = [...result, ...mapArray]
    })

    return [...viewer, ...result, ...membersWithoutAccess]
  } catch (e) {
    return []
  }
}

export const createChatName = (chat, viewerId) => {
  const { name, users, kind } = chat
  const isGroup = isGroupChat(kind)

  let conversationUser

  if (!isGroup) {
    conversationUser = find(users, user => user.id !== viewerId)
  }

  const chatName = isGroup
    ? name
    : (!isEmpty(conversationUser) && buildFullName(conversationUser)) || ''

  return chatName
}

// no need viewer if create for user like as [user]
export const createConversationAvatar = (
  users,
  branchImages,
  viewerId = null,
) => {
  const getAvatarSrc = profileAvatar =>
    createAvatarSrc(profileAvatar, branchImages)
  let avatarSrc = ''
  if (!isEmpty(users) && users.length > 1 && viewerId) {
    const conversationUser = find(
      users,
      user => get(user, 'id', '') !== viewerId,
    )
    avatarSrc = getAvatarSrc(get(conversationUser.profile, 'profileAvatar'))
  } else if (!isEmpty(users) && users.length === 1) {
    avatarSrc = getAvatarSrc(get(users[0].profile, 'profileAvatar'))
  }

  return avatarSrc
}

export const createContentUrl = file => {
  const { filesUrl } = chatApi

  let fileContentUrl =
    get(file, 'content.url', null) || get(file, 'content', null)

  if (fileContentUrl.indexOf('.com') === -1) {
    fileContentUrl = replace(fileContentUrl, '/files/', '')
  }

  let contentUrl

  if (fileContentUrl && typeof fileContentUrl === 'string') {
    if (fileContentUrl.search(filesUrl) !== -1) {
      contentUrl = fileContentUrl
    } else {
      contentUrl = `${filesUrl}${fileContentUrl}`
    }
  }

  return contentUrl
}

export const getMessagesCreatedAt = message =>
  `${DateTime.fromISO(message.createdAt).toLocaleString(DateTime.TIME_SIMPLE)}${
    message.edited ? ` — ${_('groupChat.edited')}` : ''
  }`

export const createAvatarLetters = chatName => {
  const splitedArray = chatName ? trim(chatName).split(' ') : ['X']
  if (splitedArray.length > 1) {
    return `${splitedArray[0][0]}${splitedArray[1][0]}`
  }
  if (splitedArray[0].length > 1) {
    return splitedArray[0].slice(0, 2)
  }
  return splitedArray[0][0]
}

export const playIncomingMessageRingtone = () => {
  try {
    const ringtone = new Audio(incomingMessageRingtone)
    if (ringtone !== null) {
      ringtone.play()
    }
  } catch (e) {
    //
  }
}

export const getMessageContent = (
  message,
  emoji = true,
  isMessageYour = false,
) => {
  const messageContent = get(message, 'content', '')
  const user = get(message, 'user')
  const fullname = buildFullName(user)
  if (messageContent === SYSTEM_MESSAGES.userJoined) {
    return _('groupChat.joined')
  }
  if (messageContent === SYSTEM_MESSAGES.userLeft) {
    return isMessageYour
      ? _('groupChat.youLeft')
      : _('groupChat.left', {
          fullname,
        })
  }
  if (messageContent === SYSTEM_MESSAGES.deletedFromCompany) {
    return _('groupChat.wasDeletedFromLocation', { fullname })
  }
  return emoji ? createEmojiContent(messageContent) : messageContent
}

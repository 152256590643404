import { useCallback } from 'react'

import attempt from 'lodash/attempt'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isError from 'lodash/isError'
import zip from 'lodash/zip'

import { createRegExp } from 'services/RegExp'

/**
 * Table search helper
 *
 * @param props - Hook options
 * @param {boolean} props.searchable - Whether table is searchable
 * @param {boolean} props.selectableRows - Whether table has selection
 * @param {number} props.index - Index of search column
 * @param {Array<*>} props.data - Table data
 * @param {function(row: *): string} props.getCellText - Get cell text from data (usually via selector)
 * @param {function(match: string): string} props.renderMark - Render match mark
 * @returns {{onChange: function(value: string): void}} - Queries change handler
 */
export function useTableSearch(props) {
  const {
    searchable,
    selectableRows,
    index,
    data,
    getCellText,
    renderMarkText,
  } = props

  const getTableCells = useCallback(() => {
    // prettier-ignore
    const selector = `.rdt_TableRow${searchable ? ':not(:first-of-type)' : ''} .rdt_TableCell:nth-of-type(${selectableRows ? index + 2 : index}) > div`
    return document.querySelectorAll(selector)
  }, [searchable, selectableRows, index])

  const getMarkCell = useCallback(
    (text, regexp) => text.replace(regexp, match => renderMarkText(match)),
    [renderMarkText],
  )

  const setTableCells = useCallback((cells, innerHTML) => {
    for (const cell of cells) {
      try {
        cell.innerHTML = innerHTML
      } catch (e) {
        //
      }
    }
  }, [])

  const handleQueryChange = useCallback(
    value => {
      const regexp = attempt(() => createRegExp(value, 'gi'))

      if (isError(regexp)) {
        return
      }

      const cells = getTableCells()
      const pairs = zip(data, cells)

      forEach(pairs, ([row, cell]) => {
        const rowText = getCellText(row)
        const cellText = get(cell, 'innerText', '')

        // return early to prevent match job
        if (!value) {
          setTableCells([cell], rowText)
          return
        }

        const textMatch = (rowText.match(regexp) || []).filter(Boolean)
        const cellMatch = (cellText.match(regexp) || []).filter(Boolean)

        if (!isEmpty(textMatch) && !isEmpty(cellMatch)) {
          const mark = getMarkCell(rowText, regexp)
          setTableCells([cell], mark)
        } else {
          setTableCells([cell], rowText)
        }
      })
    },
    [getCellText, getTableCells, getMarkCell, setTableCells, data],
  )

  return { onChange: handleQueryChange }
}

export default useTableSearch

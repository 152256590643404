import React, { ReactNode, useLayoutEffect, useRef, useState } from 'react'

import { useTheme } from 'styled-components'
import { LayoutProps, TypographyProps } from 'styled-system'

import { Box } from 'components/ui/__v2__/Grid'
import { Popover } from 'components/ui/__v2__/Popover'
import { TextOverflow } from 'components/ui/__v2__/TextOverflow'

interface PopoverStyles {
  popoverMaxWidth?: number
  popoverPlacement?: string
}
interface TextOverflowProps extends LayoutProps, TypographyProps {}

interface Props extends PopoverStyles, TextOverflowProps {
  children: ReactNode
}

export function TextOverflowWithPopover({
  children,
  popoverPlacement,
  popoverMaxWidth,
  ...rest
}: Props) {
  const { zIndices } = useTheme()

  const containerRef = useRef<HTMLDivElement | null>(null)
  const [showHelper, setShowHelper] = useState(false)

  useLayoutEffect(() => {
    if (
      containerRef.current &&
      containerRef.current.clientWidth < containerRef.current.scrollWidth
    ) {
      setShowHelper(true)
    }

    return () => {
      containerRef.current = null
      setShowHelper(false)
    }
  }, [containerRef])

  return (
    // @ts-ignore
    <Popover
      content={
        <Box maxWidth={popoverMaxWidth || 300} overflow="scroll">
          {children}
        </Box>
      }
      disabled={!showHelper}
      interactive
      placement={popoverPlacement || 'top'}
      withMaxHeight
      zIndex={zIndices.popup}
    >
      <TextOverflow ref={containerRef} showHelper={showHelper} {...rest}>
        {children}
      </TextOverflow>
    </Popover>
  )
}

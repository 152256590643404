import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  background-color: ${themeGet('colors.WHITE')};
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  width: 100%;
`

export const Title = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: 700;
`

export const Note = styled.div`
  width: 100%;
  word-break: break-all;
  color: ${themeGet('colors.DARK_TEXT')};
  font-size: 14px;
  text-align: justify;
  hyphens: auto;
  white-space: pre-wrap;
`

export const BranchContainer = styled.div`
  display: flex;
  margin-bottom: 8px;

  & > svg {
    margin-right: 4px;
  }
`
const Text = styled.div`
  font-size: 12px;
  line-height: 14px;
`
export const Time = styled(Text)`
  margin-bottom: 8px;
`
export const BranchName = styled(Text)``
export const DepartmentJobName = styled(Text)`
  margin-bottom: 16px;
`

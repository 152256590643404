export const shopTypes = {
  ADD_PRODUCT: 'ADD-PRODUCT',
  REMOVE_PRODUCT: 'REMOVE-PRODUCT',
  CLEAN_CART: 'CLEAN-CART',
  INCREMENT_ORDER: 'INCREMENT-ORDER',
  DECREMENT_ORDER: 'DECREMENT-ORDER',
  SET_CART_FROM_LOCAL_STORAGE: 'SET-CART-FROM-LOCAL-STORAGE',
}

export const addProduct = ({ id, productName, price, orderCount, amount }) => ({
  type: shopTypes.ADD_PRODUCT,
  payload: {
    id,
    productName,
    price,
    orderCount,
    amount,
  },
})

export const removeProduct = ({ id }) => ({
  type: shopTypes.REMOVE_PRODUCT,
  payload: { id },
})

export const cleanCart = () => ({
  type: shopTypes.CLEAN_CART,
})

export const incrementOrder = id => ({
  type: shopTypes.INCREMENT_ORDER,
  id,
})

export const decrementOrder = id => ({
  type: shopTypes.DECREMENT_ORDER,
  id,
})

export const setCartFromLocalStorage = () => ({
  type: shopTypes.SET_CART_FROM_LOCAL_STORAGE,
})

import React from 'react'

import { Span } from 'components/ui/__v2__/Grid'
import { Spinner } from 'components/ui/Spinner'

import { i18n } from 'i18n'

import { LoadMoreContainer, LoadMoreHandler } from './styles'

type Props = {
  hasMore: boolean
  fetchingMore: boolean
  onClick: () => void
}

export function LoadMoreStatus({ hasMore, fetchingMore, onClick }: Props) {
  const renderContent = () => {
    if (fetchingMore)
      return (
        <>
          <Spinner size={14} />
          <Span>{i18n('loadMore.loading')}</Span>
        </>
      )
    if (!hasMore) return <Span>{i18n('loadMore.noMoreData')}</Span>

    return (
      <LoadMoreHandler onClick={onClick}>
        {i18n('loadMore.load')}
      </LoadMoreHandler>
    )
  }

  return <LoadMoreContainer>{renderContent()}</LoadMoreContainer>
}

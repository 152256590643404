import * as colors from './colors'

export const table = {
  badge: {
    backgroundColor: colors.ATHENS_GRAY_20,
    color: colors.EBONY_CLAY,
    padding: '3px 4px',
    border: {
      radius: '4px',
      width: '1px',
      color: colors.ATHENS_GRAY_40,
    },
  },
  headerIcon: {
    color: colors.DODGER_BLUE,
  },
  sortableHeader: {
    color: colors.ATHENS_GRAY_20,
  },
  rowSelect: {
    size: 18,
  },
}

import { all, fork, take } from 'redux-saga/effects'

import get from 'lodash/get'

import { api } from 'constants/config'

import { areasToStringFilters } from 'helpers/areas'
import { downloadFileAsync } from 'helpers/files'

import { CREATE_EXPORT_AVAILABILITY_TOKEN } from 'store/actions/availability'

function* exportAvailabilityCSV() {
  const { filesUrl } = api

  while (true) {
    const { payload } = yield take(CREATE_EXPORT_AVAILABILITY_TOKEN.SUCCESS)
    const { data, areas, startAt, endAt, locale, companyId } = payload

    const token = get(data, 'token', '')

    if (token) {
      let url = `${filesUrl}/companies/${companyId}/availabilities_export?token=${token}&locale=${locale}&filter[date][between][]=${startAt}&filter[date][between][]=${endAt}`

      url += areasToStringFilters(areas)

      downloadFileAsync(url)
    }
  }
}

export default function* root() {
  yield all([fork(exportAvailabilityCSV)])
}

import React, { useState } from 'react'

import {
  autoPlacement,
  autoUpdate,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react'

import { Flex } from 'components/ui/__v2__/Grid'

import { ItemContainer, MoreHorizontalIcon, PopoverContainer } from './styles'

type Props = {
  children: React.ReactNode
  settingsContent?: React.ReactNode
  active?: boolean
  stopPropagation?: boolean
  onClick?: () => void
}

export function Item({
  children,
  settingsContent,
  active,
  stopPropagation = true,
  onClick,
}: Props) {
  const [isOpen, setOpen] = useState(false)

  // TODO: create a new Popover component for this
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: (open, event) => {
      event?.preventDefault()
      event?.stopPropagation()
      setOpen(open)
    },
    middleware: [
      autoPlacement({
        alignment: 'start',
      }),
    ],
    whileElementsMounted: autoUpdate,
  })
  const click = useClick(context)
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    click,
  ])

  return (
    <ItemContainer highlighted={!!active} onClick={onClick}>
      <Flex flexDirection="column" gap={1} width="100%">
        {children}
      </Flex>

      {/* TODO: create a new Popover component for this */}
      {!!settingsContent && (
        <Flex
          ref={refs.setReference}
          {...getReferenceProps({
            onClick(event) {
              if (!stopPropagation) return
              event.stopPropagation()
            },
          })}
        >
          <MoreHorizontalIcon size={18} />

          {isOpen && (
            <PopoverContainer
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {settingsContent}
            </PopoverContainer>
          )}
        </Flex>
      )}
    </ItemContainer>
  )
}

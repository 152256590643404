import { useState } from 'react'

import { useAppContext } from 'hooks'

import { download } from '../services/JSON'

export function useExportProfileLogs() {
  const [exporting, setExporting] = useState(false)
  const { companyId } = useAppContext()

  async function exportProfileLogs(queryString: string) {
    setExporting(true)
    await download({
      companyId,
      queryString,
      kind: 'users/logs_export',
    })
    setExporting(false)
  }

  return {
    exporting,
    exportProfileLogs,
  }
}

import { createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  LOAD_MORE_NOTIFICATIONS,
  LOAD_NOTIFICATION,
  LOAD_NOTIFICATION_COVER,
  LOAD_NOTIFICATION_TRADE_SHIFT,
  LOAD_NOTIFICATIONS,
  NOTIFICATION_READY,
  RECEIVE_NOTIFICATION,
  TOGGLE_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
} from 'store/actions/notifications'

const initialState = Immutable({
  notifications: {},
  notification: {},
  isLoading: false,
  isLoaded: false,
  notificationShiftTrade: null,
  notificationShiftCover: null,
  isNotificationReady: false,
  isNotificationsShown: false,
  isLoadingMoreNotifications: false,
})

const handlers = {
  [LOAD_NOTIFICATIONS.REQUEST]: state => state.merge({ isLoading: true }),

  [LOAD_NOTIFICATIONS.SUCCESS]: createLoadHandler('notifications'),
  [RECEIVE_NOTIFICATION]: createLoadHandler('notifications'),
  [UPDATE_NOTIFICATION.SUCCESS]: createLoadHandler('notifications'),

  [LOAD_NOTIFICATIONS.FAILURE]: state =>
    state.merge({
      isLoading: false,
      isLoaded: false,
    }),

  [LOAD_NOTIFICATION.REQUEST]: state =>
    state.merge({ isNotificationReady: false }),

  [LOAD_NOTIFICATION.SUCCESS]: createLoadHandler('notifications', {
    singular: true,
    withLoading: false,
    mapToKey: 'notification',
    addToState: {
      isNotificationReady: true,
    },
  }),

  [LOAD_NOTIFICATION_TRADE_SHIFT.SUCCESS]: createLoadHandler(
    'shiftTradeSchedules',
    {
      singular: true,
      withLoading: false,
      mapToKey: 'notificationShiftTrade',
    },
  ),

  [LOAD_NOTIFICATION_COVER.SUCCESS]: createLoadHandler('shiftCovers', {
    singular: true,
    withLoading: false,
    mapToKey: 'notificationShiftCover',
  }),

  [LOAD_NOTIFICATION.FAILURE]: state =>
    state.merge({ isNotificationReady: false }),

  [NOTIFICATION_READY]: state => state.merge({ isNotificationReady: true }),

  [TOGGLE_NOTIFICATIONS]: state =>
    state.merge({
      isNotificationsShown: !state.isNotificationsShown,
    }),
  [LOAD_MORE_NOTIFICATIONS.REQUEST]: state =>
    state.merge({
      isLoadingMoreNotifications: true,
    }),
  [LOAD_MORE_NOTIFICATIONS.SUCCESS]: createLoadHandler('notifications', {
    addToState: {
      isLoadingMoreNotifications: false,
    },
  }),
  [LOAD_MORE_NOTIFICATIONS.FAILURE]: state =>
    state.merge({
      isLoadingMoreNotifications: false,
    }),
  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

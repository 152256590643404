import React from 'react'

import { Flex } from 'components/ui/__v2__/Grid'

import { Filters } from './components'
import { TableHeaderSort } from './TableHeaderSort'
import { TableHeaderFilterSortProps } from './types'

export function TableHeaderFilterSort<SortFields>({
  pagination,
  children,
  fieldName,
  filterType,
  filterOperator,
  selectOptions,
}: TableHeaderFilterSortProps<SortFields>) {
  const baseProps = {
    fieldName: (fieldName as unknown) as string,
    pagination,
    filterOperator,
    selectOptions,
  }

  const FilterComponent = Filters[filterType]

  return (
    <Flex flexDirection="column">
      <TableHeaderSort<SortFields>
        fieldName={fieldName}
        pagination={pagination}
      >
        {children}
      </TableHeaderSort>

      <FilterComponent {...baseProps} />
    </Flex>
  )
}

import styled from 'styled-components'

import { Grid, Span } from 'components/ui/__v2__/Grid'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 940px;
`

export const SectionTitle = styled(Span)`
  font-size: 14px;
  line-height: 32px;
  font-weight: bold;
`

export const Layout = styled(Grid)`
  grid-template-columns: 200px 200px auto;
  grid-gap: ${({ theme }) => theme.space[2]}px;
  width: 100%;
`

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`

export const Fallback = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`

export const SubtleText = styled(Span)`
  font-size: 12px;
  color: ${({ theme }) => theme.layout.darkBorder};
`

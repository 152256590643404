import { useState } from 'react'

import { download } from 'API/services/JSON'

import { useAppContext, useLocale } from 'hooks'

import { EvaluatedTimesQueryParams } from 'pages/EmployerApp/EvaluatedTime/hooks/types'

import { showDefaultErrorToast } from 'services/Toasts'

import { evaluatedTimesQueryParamsToFilterToMicropayQueryString } from './mapper'

export function useEvaluatedTimeMicropayExport() {
  const [exporting, setExporting] = useState(false)
  const { companyId } = useAppContext()
  const locale = useLocale()

  async function exportEvaluatedTimeMicropay(
    filter: EvaluatedTimesQueryParams['filters'],
  ) {
    setExporting(true)
    try {
      await download({
        companyId,
        queryString: evaluatedTimesQueryParamsToFilterToMicropayQueryString(
          locale,
          filter,
        ),
        kind: 'micropay_evaluated_times_export',
      })
    } catch (error) {
      showDefaultErrorToast()
    }

    setExporting(false)
  }

  return {
    exportingEvaluatedTimeMicropay: exporting,
    exportEvaluatedTimeMicropay,
  }
}

import { useCallback } from 'react'

import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import {
  CreateTimeEntriesMutation,
  TimeEntriesByCursorQuery,
  TimeEntrySummariesByCursorQuery,
} from './GraphQL'

export function useCreateTimeEntries() {
  const [innerCreate, { loading: creating, error }] = useApolloMutation<
    MutationData<'createTimeEntries'>,
    Gateway.MutationCreateTimeEntriesArgs
  >(CreateTimeEntriesMutation, {
    refetchQueries: compact(
      [TimeEntriesByCursorQuery, TimeEntrySummariesByCursorQuery].map(
        getOperationName,
      ),
    ),
  })

  const createTimeEntries = useCallback(
    async (input: Gateway.CreateTimeEntriesInput) => {
      const result = await innerCreate({
        variables: {
          input,
        },
      })

      return graphQLResultHasError(result)
    },
    [innerCreate],
  )

  return { createTimeEntries, creating, createError: error }
}

import React from 'react'

import { useTheme } from 'styled-components'

import { Flex, Span } from 'components/ui/__v2__/Grid'
import { Spinner } from 'components/ui/Spinner'

import { i18n } from 'i18n'

type Props = {
  loading: boolean
  dataIsEmpty?: boolean
  emptyDataTitle?: string
  children: React.ReactNode
}

export function Body({
  loading,
  dataIsEmpty,
  emptyDataTitle,
  children,
}: Props) {
  const theme = useTheme()

  if (loading) {
    return (
      <Flex alignItems="center" height="200px" justifyContent="center">
        <Spinner size={30} />
      </Flex>
    )
  }

  if (dataIsEmpty && !loading) {
    return (
      <Flex alignItems="center" justifyContent="center">
        <Span color={theme.colors.CHARCOAL_80}>
          {emptyDataTitle ?? i18n('common.tableNoData')}
        </Span>
      </Flex>
    )
  }

  return (
    <Flex
      flexDirection="column"
      gap={1}
      height="100%"
      overflow="auto"
      pb={3}
      px={3}
    >
      {children}
    </Flex>
  )
}

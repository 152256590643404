import ReactDataTable from 'react-data-table-component'
import PropTypes from 'prop-types'

import { MdArrowDownward } from 'react-icons/md'

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { when } from 'components/ui/__v3__/__utils__/styled-system'
import { Checkbox as UICheckbox } from 'components/ui/Checkbox'

import deepTextSearchFunction from '../../utils/deepTextSearchFunction'
import extendColumnsBySearchAndActions from '../../utils/extendColumnsBySearchAndActions'
import extendDataBySearch from '../../utils/extendDataBySearch'
import extendSelectableRowDisabled from '../../utils/extendSelectableRowDisabled'
import searchIgnoredSortFunction from '../../utils/searchIgnoredSortFunction'

const Checkbox = styled(UICheckbox).attrs({
  borderRadius: 2,
  size: 16,
})``

export const SortIcon = styled(MdArrowDownward)`
  fill: ${themeGet('layout.neutral')};
  stroke: ${themeGet('layout.neutral')};
`

const DataTable = styled(ReactDataTable).attrs(props => {
  const { searchable } = props

  const sortFunction = searchable
    ? searchIgnoredSortFunction
    : deepTextSearchFunction

  return {
    sortFunction,
    columns: extendColumnsBySearchAndActions(props),
    data: extendDataBySearch(props),
    selectableRowDisabled: extendSelectableRowDisabled(props),
  }
})`
  --font-size: 12px;
  --sort-icon-size: 16px;
  --cell-font-weight: ${themeGet('fontWeights.semi')};
  --header-font-weight: ${themeGet('fontWeights.bold')};
  --text-transform: uppercase;
  --border: thin solid ${themeGet('layout.lighterBorder')};
  --min-height: 32px;

  overflow-x: auto;

  .rdt {
    &_Table {
      height: auto;
      border: ${props =>
        props.borderColor ? `1px solid ${props.borderColor}` : 'var(--border)'};
      ${props => props.transparent && 'background-color: transparent;'}

      ${when(
        'progressPending',
        css`
          display: none;
        `,
      )};
    }

    &_TableBody {
      overflow-y: overlay;
      overflow-x: hidden;
      ::-webkit-scrollbar {
        width: 0;
      }
    }

    &_TableCol_Sortable {
      width: 100%;

      > div {
        margin-right: 60px;
      }

      .__rdt_custom_sort_icon__ {
        position: absolute;
        right: 8px;

        ${SortIcon} {
          width: var(--sort-icon-size) !important;
          height: var(--sort-icon-size) !important;
        }
      }
    }

    &_TableRow {
      transition: background-color 0.15s, color 0.15s;
      flex-shrink: 0;
      min-height: var(--min-height);

      &:not(:last-of-type) {
        border-bottom: ${props =>
          props.borderColor
            ? `1px solid ${props.borderColor}`
            : 'var(--border)'};
      }
      ${props => props.transparent && 'background-color: transparent;'}

      ${when(
        'highlightOnHover',
        css`
          &:hover {
            outline: none;
            border-bottom: var(--border);
            background-color: ${themeGet('layout.darkerBg')};

            &:last-of-type {
              border-bottom: none;
            }
          }
        `,
      )};
    }

    &_TableHeadRow {
      border-bottom: ${props =>
        props.borderColor ? `1px solid ${props.borderColor}` : 'var(--border)'};
      min-height: 36px;
      ${props => props.transparent && 'background-color: transparent;'}

      > .rdt_TableCol {
        font-size: var(--font-size);
        font-weight: var(--header-font-weight);
        text-transform: var(--text-transform);
        padding: 6px 20px 8px 6px;

        &:not(:last-of-type) {
          border-right: ${props =>
            props.borderColor
              ? `1px solid ${props.borderColor}`
              : 'var(--border)'};
        }

        &:last-of-type {
          ${props =>
            props.actionCellWidth &&
            `width: ${props.actionCellWidth}px !important;`}
        }
      }
    }

    &_TableCell {
      font-weight: var(--cell-font-weight);
      font-size: var(--font-size);
      padding: 6px 20px 8px 6px;

      &:not(:last-of-type) {
        border-right: ${props =>
          props.borderColor
            ? `1px solid ${props.borderColor}`
            : 'var(--border)'};
      }

      div {
        white-space: initial;
        overflow: initial;
        text-overflow: initial;
      }
    }
  }

  ${when(
    'actionable',
    css`
      .rdt_TableCol:last-of-type {
        visibility: hidden;
      }

      .rdt_TableCol:last-of-type,
      .rdt_TableCell:last-of-type {
        min-width: initial;
        flex-shrink: initial;
        flex-basis: initial;
        flex-grow: initial;
      }
    `,
  )};

  ${when(
    ['searchable', 'selectableRows'],
    css`
      .rdt_TableRow:first-of-type .rdt_TableCell:first-of-type > ${Checkbox} {
        visibility: hidden;
        pointer-events: none;
      }
    `,
  )};
`

DataTable.propTypes = {
  ...ReactDataTable.propTypes,
  actionComponent: PropTypes.elementType,
  actionable: PropTypes.bool,
  searchComponent: PropTypes.elementType,
  searchable: PropTypes.bool,
}

DataTable.defaultProps = {
  ...ReactDataTable.defaultProps,
  actionComponent: null,
  actionable: false,
  searchComponent: null,
  searchable: false,
}

export default DataTable

import { AvailabilityRequestState } from 'constants/gatewayGraphQL'

import i18n from 'i18n'

import {
  CreateAvailabilityRequestArgs,
  CreateAvailabilityRequestKinds,
  Requestor,
  RequestSubmissionStatus,
} from './types'

export function argsToResponseMessage({
  requestType,
  isRequestorEmployee,
  availabilitiesAutoApprove,
}: CreateAvailabilityRequestArgs) {
  let title: string = ''
  let content: string = ''

  const requestor = isRequestorEmployee ? Requestor.Employee : Requestor.Manager
  const requestSubmissionStatus =
    isRequestorEmployee && !availabilitiesAutoApprove
      ? RequestSubmissionStatus.AwaitingApproval
      : RequestSubmissionStatus.Confirmed

  const requestorSubmissionKey = `${requestor}.${requestSubmissionStatus}`

  switch (requestType) {
    case CreateAvailabilityRequestKinds.Create:
      title = i18n(`availability.toasts.${requestorSubmissionKey}.create.title`)
      content = i18n(
        `availability.toasts.${requestorSubmissionKey}.create.content`,
      )
      break

    case CreateAvailabilityRequestKinds.UpdateOne:
      title = i18n(`availability.toasts.${requestorSubmissionKey}.edit.title`)
      content = i18n(
        `availability.toasts.${requestorSubmissionKey}.edit.content`,
      )
      break

    case CreateAvailabilityRequestKinds.UpdateAll:
      title = i18n(`availability.toasts.${requestorSubmissionKey}.edit.title`)
      content = i18n(
        `availability.toasts.${requestorSubmissionKey}.edit.content`,
      )
      break

    case CreateAvailabilityRequestKinds.UpdateAllFromDate:
      title = i18n(`availability.toasts.${requestorSubmissionKey}.edit.title`)
      content = i18n(
        `availability.toasts.${requestorSubmissionKey}.edit.content`,
      )
      break

    case CreateAvailabilityRequestKinds.DeleteOne:
      title = i18n(`availability.toasts.${requestorSubmissionKey}.delete.title`)
      content = i18n(
        `availability.toasts.${requestorSubmissionKey}.delete.content`,
      )
      break

    case CreateAvailabilityRequestKinds.DeleteAll:
      title = i18n(`availability.toasts.${requestorSubmissionKey}.delete.title`)
      content = i18n(
        `availability.toasts.${requestorSubmissionKey}.delete.content`,
      )
      break

    case CreateAvailabilityRequestKinds.DeleteAllFromDate:
      title = i18n(`availability.toasts.${requestorSubmissionKey}.delete.title`)
      content = i18n(
        `availability.toasts.${requestorSubmissionKey}.delete.content`,
      )
      break

    default:
      break
  }

  return {
    title,
    content,
  }
}

export function getErrorTitle(requestType: CreateAvailabilityRequestKinds) {
  switch (requestType) {
    case CreateAvailabilityRequestKinds.Create:
      return i18n(`availability.toasts.error.create.title`)

    case CreateAvailabilityRequestKinds.UpdateOne:
    case CreateAvailabilityRequestKinds.UpdateAll:
    case CreateAvailabilityRequestKinds.UpdateAllFromDate:
      return i18n(`availability.toasts.error.edit.title`)

    case CreateAvailabilityRequestKinds.DeleteOne:
    case CreateAvailabilityRequestKinds.DeleteAll:
    case CreateAvailabilityRequestKinds.DeleteAllFromDate:
      return i18n(`availability.toasts.error.delete.title`)

    default:
      return i18n(`alertMessages.default.error.title`)
  }
}

export function statusToFilter(status: AvailabilityRequestState | undefined) {
  if (status === undefined) return null
  // Note: lowercase is requiried since Ruby accepts lowerCase, but GW sends UPPER
  return {
    state: {
      in: [status.toLowerCase() as Lowercase<AvailabilityRequestState>],
    },
  }
}

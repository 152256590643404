import { DateTime } from 'luxon'

import { Utils } from 'services/Utils'

import { DAY_SECONDS, HALF_OF_DAY } from './constants'

export function parseTimeString(time: string) {
  const withDelimiter = time.includes(':')

  return withDelimiter ? time.split(':') : Utils.Strings.splitAt(time, 2)
}

export function getStartDate(timezone: string) {
  return DateTime.local({ zone: timezone }).startOf('day')
}

export function formatInputValue(date: DateTime) {
  const is12HourFormat = Utils.DateTime.is12HourFormat(date)

  return is12HourFormat ? date.toFormat('hh:mm') : date.toFormat('HH:mm')
}

export function normalizeAmPm(value: number) {
  return value >= HALF_OF_DAY
    ? value - HALF_OF_DAY
    : DAY_SECONDS + value - HALF_OF_DAY
}

export function checkIsPm(date: DateTime) {
  return date.hour >= 12
}

import { TemporalWorkflow } from './types'

export const INITIAL_STATE: TemporalWorkflow = {
  temporalWorkflowId: undefined,
  temporalRunId: undefined,
}

export const CLASSIFIED_TIME_TEMPORAL_WORKFLOW_STATE_KEY =
  'classifiedTime/temporalWorkflow'
export const CLASSIFIED_TIME_TEMPORAL_WORKFLOW_SET =
  'classifiedTime/temporalWorkflow/set'
export const CLASSIFIED_TIME_TEMPORAL_WORKFLOW_UNSET =
  'classifiedTime/temporalWorkflow/unset'

export const EVALUATED_TIME_TEMPORAL_WORKFLOW_STATE_KEY =
  'evaluatedTime/temporalWorkflow'
export const EVALUATED_TIME_TEMPORAL_WORKFLOW_SET =
  'evaluatedTime/classifiedTime/temporalWorkflow/set'
export const EVALUATED_TIME_TEMPORAL_WORKFLOW_UNSET =
  'evaluatedTime/classifiedTime/temporalWorkflow/unset'

import { ApolloLink } from '@apollo/client'

import { gatewayErrorLink } from './gatewayErrorLink'
import { hathorErrorLink } from './hathorErrorLink'

export function errorLink() {
  return ApolloLink.split(
    operation => operation.getContext().hathor === true,
    hathorErrorLink(),
    gatewayErrorLink(),
  )
}

import { useCallback } from 'react'

import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import {
  CreateUpdateTimeEntriesMutation,
  TimeEntriesByCursorQuery,
  TimeEntrySummariesByCursorQuery,
} from './GraphQL'

export function useCreateUpdateTimeEntries() {
  const [innerCreateUpdate, { loading, error }] = useApolloMutation<
    null,
    {
      createInput: Gateway.CreateTimeEntriesInput
      updateInput: Gateway.UpdateTimeEntriesInput
    }
  >(CreateUpdateTimeEntriesMutation)

  const createUpdate = useCallback(
    async ({
      createInput,
      updateInput,
      withTimeEntriesRefetch,
    }: {
      createInput: Gateway.CreateTimeEntriesInput
      updateInput: Gateway.UpdateTimeEntriesInput
      withTimeEntriesRefetch?: boolean
    }) => {
      const result = await innerCreateUpdate({
        variables: {
          createInput,
          updateInput,
        },
        ...(withTimeEntriesRefetch && {
          refetchQueries: compact(
            [TimeEntriesByCursorQuery, TimeEntrySummariesByCursorQuery].map(
              getOperationName,
            ),
          ),
        }),
      })

      return graphQLResultHasError(result)
    },
    [innerCreateUpdate],
  )

  return { createUpdate, upserting: loading, createError: error }
}

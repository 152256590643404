/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */

import React from 'react'

import styled, { keyframes } from 'styled-components'
import { layout, space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import once from 'lodash/once'

import { radius } from 'components/ui/__v3__/__utils__/styled-system'

import themes from 'theme'

import { Icon } from '../components/ui/Icon'

export const BRAND_TYPE = {
  DAWAMY: 'dawamy',
  WORKAXLE: 'workaxle',
  KEYWORDS: 'keywords',
  DEMO: 'demo',
  GARDA: 'garda',
  ETFO: 'etfo',
  ALDO: 'aldo',
  ARITZIA: 'aritzia',
  NETEASE: 'netease',
  ICC: 'icc',
  TBS: 'tbs',
  LOTOQUBEC: 'lq',
}

const BRANDS_WITH_DEFAULT_I18N = [
  BRAND_TYPE.WORKAXLE,
  BRAND_TYPE.ALDO,
  BRAND_TYPE.NETEASE,
  BRAND_TYPE.DEMO,
]

const DOMAIN_BRANDS = {
  // WORKAXLE
  'dev.workaxle.com': BRAND_TYPE.WORKAXLE,
  'staging.workaxle.com': BRAND_TYPE.WORKAXLE,
  'app.workaxle.com': BRAND_TYPE.WORKAXLE,
  // DAWAMY (Nahdi)
  'dawamy.dev.workaxle.com': BRAND_TYPE.DAWAMY,
  'dawamy.staging.workaxle.com': BRAND_TYPE.DAWAMY,
  'dawamy.workaxle.com': BRAND_TYPE.DAWAMY,
  // KEYWORDS
  'keywords.dev.workaxle.com': BRAND_TYPE.KEYWORDS,
  'keywords.staging.workaxle.com': BRAND_TYPE.KEYWORDS,
  'keywords.workaxle.com': BRAND_TYPE.KEYWORDS,
  // DEMO
  'demo.dev.workaxle.com': BRAND_TYPE.DEMO,
  'demo.staging.workaxle.com': BRAND_TYPE.DEMO,
  // ETFO
  'etfo.dev.workaxle.com': BRAND_TYPE.ETFO,
  'etfo.staging.workaxle.com': BRAND_TYPE.ETFO,
  'etfo.workaxle.com': BRAND_TYPE.ETFO,
  // ALDO
  'aldo.dev.workaxle.com': BRAND_TYPE.ALDO,
  'aldo.staging.workaxle.com': BRAND_TYPE.ALDO,
  'aldo-uat.workaxle.com': BRAND_TYPE.ALDO,
  'aldo.workaxle.com': BRAND_TYPE.ALDO,
  // GARDA
  'garda.dev.workaxle.com': BRAND_TYPE.GARDA,
  'garda.staging.workaxle.com': BRAND_TYPE.GARDA,
  'garda.workaxle.com': BRAND_TYPE.GARDA,
  'gskedair.as.workaxle.ca': BRAND_TYPE.GARDA,
  'gskedair.as.garda.com': BRAND_TYPE.GARDA,
  // NETEASE
  'netease.dev.workaxle.com': BRAND_TYPE.NETEASE,
  'netease.staging.workaxle.com': BRAND_TYPE.NETEASE,
  'netease.workaxle.com': BRAND_TYPE.NETEASE,
  // ICC
  'icc.dev.workaxle.com': BRAND_TYPE.ICC,
  'icc.staging.workaxle.com': BRAND_TYPE.ICC,
  'icc.workaxle.com': BRAND_TYPE.ICC,
  // TBS
  'tbs.dev.workaxle.com': BRAND_TYPE.TBS,
  'tbs.staging.workaxle.com': BRAND_TYPE.TBS,
  'tbs.workaxle.com': BRAND_TYPE.TBS,
  // LOTOQUBEC
  'lq.dev.workaxle.com': BRAND_TYPE.LOTOQUBEC,
  'lq.staging.workaxle.com': BRAND_TYPE.LOTOQUBEC,
  'lq.workaxle.com': BRAND_TYPE.LOTOQUBEC,
}

const LogoImg = styled.img`
  border-radius: ${radius('normal')};

  ${layout};
  ${space};
`

const BRAND_CONFIG = {
  // --------------------- Dawamy config ---------------------
  [BRAND_TYPE.DAWAMY]: once(() => {
    const sign = require(`../assets/images/${BRAND_TYPE.DAWAMY}/sign.jpg`)
    const loading = require(`../assets/images/${BRAND_TYPE.DAWAMY}/loader.gif`)
    const logo = require(`../assets/images/${BRAND_TYPE.DAWAMY}/logo.png`)

    const LoaderImg = styled.img`
      ${layout};
      ${space};
    `

    const Loader = props => {
      return (
        <LoaderImg {...props} alt="loader" src={loading.default} width={240} />
      )
    }

    // eslint-disable-next-line react/prop-types
    const Logo = ({ size, width, keepSize, ...props }) => {
      let height = size ?? width
      const modifier = 1.15 // size modifier

      const intSize = parseInt(size, 10)
      const intWidth = parseInt(width, 10)

      /**
       * Making Dawamy logo slightly bigger, as it's not squared as WorkAxle's logo
       */
      if (!isNaN(intSize) && !keepSize) {
        height = intSize + intSize * modifier
      } else if (!isNaN(intWidth) && !keepSize) {
        height = intWidth + intWidth * modifier
      }

      return (
        <LogoImg {...props} alt="logo" height={height} src={logo.default} />
      )
    }

    return {
      i18nBrandHook: createBrandHook(BRAND_TYPE.DAWAMY),
      Loader,
      Logo,
      signIn: sign.default,
      signUp: sign.default,
      primaryColor: 'layout.dawamyPrimary',
      manifest: `${BRAND_TYPE.DAWAMY}/manifest.json`,
      favicon: `${BRAND_TYPE.DAWAMY}/favicon.png`,
    }
  }),
  // ---------------------------------------------------------

  // -------------------- WorkAxle config --------------------
  [BRAND_TYPE.WORKAXLE]: once(() => {
    const loader = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/loader.svg`)
    const logo = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/logo.svg`)
    const signInUp = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/sign-in-up.jpg`)

    const dash = keyframes`
      to {
        stroke-dashoffset: 240;
      }
    `

    const Loader = styled(Icon).attrs({ glyph: loader.default })`
      stroke-width: 2px;
      stroke: ${themeGet('layout.primary')};
      width: 120px;

      stroke-dasharray: 60;
      animation: ${dash} 2s linear infinite;

      ${layout};
      ${space};
    `

    const Logo = styled(Icon).attrs({ glyph: logo.default })`
      fill: ${themeGet('layout.primary')};

      ${layout};
      ${space};
    `

    return {
      i18nBrandHook: createBrandHook(BRAND_TYPE.WORKAXLE),
      Loader,
      Logo,
      signIn: signInUp.default,
      signUp: signInUp.default,
      primaryColor: 'layout.primary',
      manifest: `${BRAND_TYPE.WORKAXLE}/manifest.json`,
      favicon: `${BRAND_TYPE.WORKAXLE}/favicon.png`,
    }
  }),
  // ---------------------------------------------------------

  // -------------------- Keywords config --------------------
  [BRAND_TYPE.KEYWORDS]: once(() => {
    const loader = require(`../assets/images/${BRAND_TYPE.KEYWORDS}/loader.svg`)
    const logo = require(`../assets/images/${BRAND_TYPE.KEYWORDS}/logo.svg`)
    const signInUp = require(`../assets/images/${BRAND_TYPE.KEYWORDS}/sign-in-up.jpg`)

    const dash = keyframes`
      to {
        stroke-dashoffset: 240;
      }
    `

    const Loader = styled(Icon).attrs({ glyph: loader.default })`
      stroke-width: 2px;
      stroke: ${themeGet('layout.primary')};
      width: 120px;

      stroke-dasharray: 60;
      animation: ${dash} 2s linear infinite;

      ${layout};
      ${space};
    `

    const Logo = styled(Icon).attrs({ glyph: logo.default })`
      fill: ${themeGet('layout.primary')};

      ${layout};
      ${space};
    `

    return {
      i18nBrandHook: createBrandHook(BRAND_TYPE.KEYWORDS),
      Loader,
      Logo,
      signIn: signInUp.default,
      signUp: signInUp.default,
      primaryColor: 'layout.text',
      manifest: `${BRAND_TYPE.KEYWORDS}/manifest.json`,
      favicon: `${BRAND_TYPE.KEYWORDS}/favicon.png`,
    }
  }),
  // ---------------------------------------------------------

  // -------------------- Demo config --------------------
  [BRAND_TYPE.DEMO]: once(() => {
    const loader = require(`../assets/images/${BRAND_TYPE.DEMO}/loader.svg`)
    const logo = require(`../assets/images/${BRAND_TYPE.DEMO}/logo.png`)
    const signInUp = require(`../assets/images/${BRAND_TYPE.DEMO}/sign.png`)

    const dash = keyframes`
    to {
      stroke-dashoffset: 240;
    }
  `

    const Loader = styled(Icon).attrs({ glyph: loader.default })`
      stroke-width: 2px;
      stroke: ${themeGet('layout.primary')};
      width: 120px;

      stroke-dasharray: 60;
      animation: ${dash} 2s linear infinite;

      ${layout};
      ${space};
    `

    // eslint-disable-next-line react/prop-types
    const Logo = ({ size, width, keepSize, ...props }) => {
      return (
        <LogoImg
          {...props}
          alt="logo"
          height={size ?? width}
          // Note: Nordea only adjustment
          mb="4px"
          src={logo.default}
        />
      )
    }

    return {
      i18nBrandHook: createBrandHook(BRAND_TYPE.DEMO),
      Loader,
      Logo,
      signIn: signInUp.default,
      signUp: signInUp.default,
      primaryColor: 'layout.primary',
      manifest: `${BRAND_TYPE.DEMO}/manifest.json`,
      favicon: `${BRAND_TYPE.DEMO}/favicon.png`,
    }
  }),

  // -------------------- Garda config --------------------
  [BRAND_TYPE.GARDA]: once(() => {
    const loader = require(`../assets/images/${BRAND_TYPE.GARDA}/loader.svg`)
    const logo = require(`../assets/images/${BRAND_TYPE.GARDA}/logo.png`)
    const signInUp = require(`../assets/images/${BRAND_TYPE.GARDA}/sign.jpg`)

    const dash = keyframes`
    to {
      stroke-dashoffset: 240;
    }
  `

    const Loader = styled(Icon).attrs({ glyph: loader.default })`
      stroke-width: 2px;
      stroke: ${themeGet('layout.gardaPrimary')};
      width: 120px;

      stroke-dasharray: 60;
      animation: ${dash} 2s linear infinite;

      ${layout};
      ${space};
    `

    // eslint-disable-next-line react/prop-types
    const Logo = ({ size, width, keepSize, ...props }) => {
      return (
        <LogoImg
          {...props}
          alt="logo"
          height={size ?? width}
          src={logo.default}
        />
      )
    }

    return {
      i18nBrandHook: createBrandHook(BRAND_TYPE.GARDA),
      Loader,
      Logo,
      signIn: signInUp.default,
      signUp: signInUp.default,
      primaryColor: 'layout.gardaPrimary',
      manifest: `${BRAND_TYPE.GARDA}/manifest.json`,
      favicon: `${BRAND_TYPE.GARDA}/favicon.png`,
    }
  }),

  // ==============================================================================================
  // ETFO config
  // ==============================================================================================
  [BRAND_TYPE.ETFO]: once(() => {
    const loader = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/loader.svg`)
    const logo = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/logo.svg`)
    const signInUp = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/sign-in-up.jpg`)

    const dash = keyframes`
      to {
        stroke-dashoffset: 240;
      }
    `

    const Loader = styled(Icon).attrs({ glyph: loader.default })`
      stroke-width: 2px;
      stroke: ${themeGet('layout.primary')};
      width: 120px;

      stroke-dasharray: 60;
      animation: ${dash} 2s linear infinite;

      ${layout};
      ${space};
    `

    const Logo = styled(Icon).attrs({ glyph: logo.default })`
      fill: ${themeGet('layout.primary')};

      ${layout};
      ${space};
    `

    return {
      i18nBrandHook: createBrandHook(BRAND_TYPE.ETFO, true),
      Loader,
      Logo,
      signIn: signInUp.default,
      signUp: signInUp.default,
      primaryColor: 'layout.sobeysPrimary',
      manifest: `${BRAND_TYPE.WORKAXLE}/manifest.json`,
      favicon: `${BRAND_TYPE.WORKAXLE}/favicon.png`,
    }
  }),

  // ==============================================================================================
  // ALDO config
  // ==============================================================================================
  [BRAND_TYPE.ALDO]: once(() => {
    const loader = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/loader.svg`)
    const logo = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/logo.svg`)
    const signInUp = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/sign-in-up.jpg`)

    const dash = keyframes`
      to {
        stroke-dashoffset: 240;
      }
    `

    const Loader = styled(Icon).attrs({ glyph: loader.default })`
      stroke-width: 2px;
      stroke: ${themeGet('layout.primary')};
      width: 120px;

      stroke-dasharray: 60;
      animation: ${dash} 2s linear infinite;

      ${layout};
      ${space};
    `

    const Logo = styled(Icon).attrs({ glyph: logo.default })`
      fill: ${themeGet('layout.primary')};

      ${layout};
      ${space};
    `

    return {
      i18nBrandHook: createBrandHook(BRAND_TYPE.ALDO),
      Loader,
      Logo,
      signIn: signInUp.default,
      signUp: signInUp.default,
      primaryColor: 'layout.primary',
      manifest: `${BRAND_TYPE.WORKAXLE}/manifest.json`,
      favicon: `${BRAND_TYPE.WORKAXLE}/favicon.png`,
    }
  }),

  // ==============================================================================================
  // NetEase config
  // ==============================================================================================
  [BRAND_TYPE.NETEASE]: once(() => {
    const loader = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/loader.svg`)
    const logo = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/logo.svg`)
    const signInUp = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/sign-in-up.jpg`)

    const dash = keyframes`
      to {
        stroke-dashoffset: 240;
      }
    `

    const Loader = styled(Icon).attrs({ glyph: loader.default })`
      stroke-width: 2px;
      stroke: ${themeGet('layout.primary')};
      width: 120px;

      stroke-dasharray: 60;
      animation: ${dash} 2s linear infinite;

      ${layout};
      ${space};
    `

    const Logo = styled(Icon).attrs({ glyph: logo.default })`
      fill: ${themeGet('layout.primary')};

      ${layout};
      ${space};
    `

    return {
      i18nBrandHook: createBrandHook(BRAND_TYPE.NETEASE),
      Loader,
      Logo,
      signIn: signInUp.default,
      signUp: signInUp.default,
      primaryColor: 'layout.primary',
      manifest: `${BRAND_TYPE.WORKAXLE}/manifest.json`,
      favicon: `${BRAND_TYPE.WORKAXLE}/favicon.png`,
    }
  }),

  // ==============================================================================================
  // ICC config
  // ==============================================================================================
  [BRAND_TYPE.ICC]: once(() => {
    const loader = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/loader.svg`)
    const logo = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/logo.svg`)
    const signInUp = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/sign-in-up.jpg`)

    const dash = keyframes`
      to {
        stroke-dashoffset: 240;
      }
    `

    const Loader = styled(Icon).attrs({ glyph: loader.default })`
      stroke-width: 2px;
      stroke: ${themeGet('layout.primary')};
      width: 120px;

      stroke-dasharray: 60;
      animation: ${dash} 2s linear infinite;

      ${layout};
      ${space};
    `

    const Logo = styled(Icon).attrs({ glyph: logo.default })`
      fill: ${themeGet('layout.primary')};

      ${layout};
      ${space};
    `

    return {
      i18nBrandHook: createBrandHook(BRAND_TYPE.NETEASE),
      Loader,
      Logo,
      signIn: signInUp.default,
      signUp: signInUp.default,
      primaryColor: 'layout.primary',
      manifest: `${BRAND_TYPE.WORKAXLE}/manifest.json`,
      favicon: `${BRAND_TYPE.WORKAXLE}/favicon.png`,
    }
  }),

  // ==============================================================================================
  // TBS config
  // ==============================================================================================
  [BRAND_TYPE.TBS]: once(() => {
    const loader = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/loader.svg`)
    const logo = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/logo.svg`)
    const signInUp = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/sign-in-up.jpg`)

    const dash = keyframes`
      to {
        stroke-dashoffset: 240;
      }
    `

    const Loader = styled(Icon).attrs({ glyph: loader.default })`
      stroke-width: 2px;
      stroke: ${themeGet('layout.primary')};
      width: 120px;

      stroke-dasharray: 60;
      animation: ${dash} 2s linear infinite;

      ${layout};
      ${space};
    `

    const Logo = styled(Icon).attrs({ glyph: logo.default })`
      fill: ${themeGet('layout.primary')};

      ${layout};
      ${space};
    `

    return {
      i18nBrandHook: createBrandHook(BRAND_TYPE.WORKAXLE),
      Loader,
      Logo,
      signIn: signInUp.default,
      signUp: signInUp.default,
      primaryColor: 'layout.primary',
      manifest: `${BRAND_TYPE.WORKAXLE}/manifest.json`,
      favicon: `${BRAND_TYPE.WORKAXLE}/favicon.png`,
    }
  }),

  // ==============================================================================================
  // LotoQuebec config
  // ==============================================================================================
  [BRAND_TYPE.LOTOQUBEC]: once(() => {
    const loader = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/loader.svg`)
    const logo = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/logo.svg`)
    const signInUp = require(`../assets/images/${BRAND_TYPE.WORKAXLE}/sign-in-up.jpg`)

    const dash = keyframes`
      to {
        stroke-dashoffset: 240;
      }
    `

    const Loader = styled(Icon).attrs({ glyph: loader.default })`
      stroke-width: 2px;
      stroke: ${themeGet('layout.primary')};
      width: 120px;

      stroke-dasharray: 60;
      animation: ${dash} 2s linear infinite;

      ${layout};
      ${space};
    `

    const Logo = styled(Icon).attrs({ glyph: logo.default })`
      fill: ${themeGet('layout.primary')};

      ${layout};
      ${space};
    `

    return {
      i18nBrandHook: createBrandHook(BRAND_TYPE.LOTOQUBEC),
      Loader,
      Logo,
      signIn: signInUp.default,
      signUp: signInUp.default,
      primaryColor: 'layout.primary',
      manifest: `${BRAND_TYPE.WORKAXLE}/manifest.json`,
      favicon: `${BRAND_TYPE.WORKAXLE}/favicon.png`,
    }
  }),
}

export function getConfigType() {
  return DOMAIN_BRANDS[window.location.hostname] ?? BRAND_TYPE.WORKAXLE
}

export function isDawamyEnvironment() {
  return getConfigType() === BRAND_TYPE.DAWAMY
}

export function isEtfoEnvironment() {
  return getConfigType() === BRAND_TYPE.ETFO
}

export function isGardaEnvironment() {
  return getConfigType() === BRAND_TYPE.GARDA
}

export function isIccEnvironment() {
  return getConfigType() === BRAND_TYPE.ICC
}

export function isTbsEnvironment() {
  return getConfigType() === BRAND_TYPE.TBS
}

export function isLotoQuebecEnvironment() {
  return getConfigType() === BRAND_TYPE.LOTOQUBEC
}

export function isDemoEnvironment() {
  return getConfigType() === BRAND_TYPE.DEMO
}

export function getBrandConfig() {
  const type = getConfigType()
  return (BRAND_CONFIG[type] ?? BRAND_CONFIG[BRAND_TYPE.WORKAXLE])()
}

function createBrandHook(brand, custom = false) {
  return i18n => (key, ...args) => {
    if (key === 'brand') {
      if (BRANDS_WITH_DEFAULT_I18N.includes(brand)) {
        return i18n('workaxle')
      }
      return i18n(brand)
    }

    let argsOptions = {}
    args.forEach(argItem => {
      argsOptions = { ...argsOptions, ...argItem, custom, brand }
    })

    return i18n(key, argsOptions)
  }
}

export const theme = isGardaEnvironment()
  ? themes.gardaTheme
  : themes.lightTheme

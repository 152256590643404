import styled from 'styled-components'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'

export const Warning = styled.div<SpaceProps & LayoutProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]}px;
  border: 2px solid ${({ theme }) => theme.toasts.secondaryColor.warning};
  border-radius: 4px;
  text-align: justify;
  margin-top: ${({ theme }) => theme.space[2]}px;
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[2]}px;
  ${space}
  ${layout}
`

import { Control, FieldValues, Path, useController } from 'react-hook-form'

import { SelectOption } from 'Types/common'

type Props<T extends FieldValues> = {
  control: Control<T>
  name: Path<T>
  returnOptionObject: boolean
  isMulti: boolean
  options: SelectOption<string | number>[]
}

export function useFormSelectAttributes<T extends FieldValues>({
  control,
  name,
  returnOptionObject,
  isMulti,
  options,
}: Props<T>) {
  const {
    field: { onChange, value, ref, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  let handleChange
  if (isMulti) {
    handleChange = (option: SelectOption[] | null) =>
      returnOptionObject
        ? onChange(option ?? null)
        : onChange(option?.map(item => item.value) ?? null)
  } else {
    handleChange = (option: SelectOption | null) =>
      returnOptionObject
        ? onChange(option ?? null)
        : onChange(option?.value ?? null)
  }

  let selectedValue
  if (isMulti) {
    selectedValue = returnOptionObject
      ? value
      : options.filter(item =>
          (value as (string | number)[])?.includes(item.value),
        ) ?? null
  } else {
    selectedValue = returnOptionObject
      ? value
      : options.find(item => item.value === value) ?? null
  }

  return {
    ref,
    onBlur,
    error,
    handleChange,
    selectedValue,
  }
}

import { useMemo, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import Utils from 'services/Utils'

import { ClassifiedTimesAggregatedByEmployeeByCursorQueryData } from './GraphQL/Queries/types'
import { ClassifiedTimesAggregatedByEmployeeByCursorQuery } from './GraphQL'
import { ClassifiedTimesAggregatedFilter } from './types'

type Props = {
  page: CursorPage
  filter: ClassifiedTimesAggregatedFilter
}

export function useClassifiedTimesAggregatedByEmployeeByCursor({
  page,
  filter,
}: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })
  const [loadingMore, setLoadingMore] = useState(false)

  const { data, loading, refetch, fetchMore } = useApolloQuery<
    ClassifiedTimesAggregatedByEmployeeByCursorQueryData,
    Gateway.QueryClassifiedTimesAggregatedByEmployeeByCursorArgs
  >(ClassifiedTimesAggregatedByEmployeeByCursorQuery, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        date: Utils.GraphQL.betweenFilter(filter.date),
        ...(filter.employeeIds && {
          employeeId: Utils.GraphQL.inFilter(filter.employeeIds),
        }),
      },
      paging: cursorPageToGraphqlPaging(page),
    },
    onCompleted(data) {
      const newPageMeta =
        data.classifiedTimesAggregatedByEmployeeByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const classifiedTimesAggregatedByEmployee = useMemo(
    () =>
      data?.classifiedTimesAggregatedByEmployeeByCursor?.edges.map(
        edge => edge.node,
      ) ?? [],
    [data],
  )

  const loadMore = async (lastCursor: string) => {
    setLoadingMore(true)
    await fetchMore({
      variables: {
        paging: { limit: page.size, startingAfter: lastCursor },
      },
    })

    setLoadingMore(false)
  }

  return {
    classifiedTimesAggregatedByEmployee,
    loading,
    loadingMore,
    currentPageMeta,
    refetch,
    loadMore,
  }
}

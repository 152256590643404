import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import { FormPostImage } from 'components/ui/__v2__/Form'
import { Flex, Span } from 'components/ui/Grid'

import _ from 'i18n'

import { NoAnswer } from '../styles'

function LabeledPostPicture({
  name,
  isRequired,
  isReadOnly,
  onChange,
  // isMulti,
  label,
  ...rest
}) {
  const element = props => {
    if (isReadOnly && (!props.input.value || isEmpty(props.input.value))) {
      return <NoAnswer>{_('formBuilder.fallbacks.noPicturePosted')}</NoAnswer>
    }

    return (
      <FormPostImage
        {...props}
        {...rest}
        isReadOnly={isReadOnly}
        name={name}
        required={isRequired}
        width="100%"
        onBlur={onChange}
      />
    )
  }

  return (
    <Flex flexDirection="column">
      <Span as="label" htmlFor={name} mr={2}>
        {label}
        {isRequired && <sup>*</sup>}
      </Span>
      <Field name={name}>{element}</Field>
    </Flex>
  )
}

LabeledPostPicture.defaultProps = {
  isReadOnly: false,
}

LabeledPostPicture.propTypes = {
  ...Field.propTypes,
  isReadOnly: PropTypes.bool,
}

export default LabeledPostPicture

import React from 'react'

import { DateTime } from 'luxon'

import { Flex } from 'components/ui/__v2__/Grid'

import { i18n } from 'i18n'

import { TextItem } from './styles'

interface Props {
  type: Gateway.PayFrequency['type']
  startDate: Gateway.PayFrequency['startDate']
}

// TODO: Move up?
export function PayFrequencyDetails({ type, startDate }: Props) {
  return (
    <Flex flexDirection="column">
      <TextItem>{i18n(`payFrequency.types.${type}`)}</TextItem>
      <TextItem>
        {i18n('payFrequency.startsOn', {
          startDate: DateTime.fromISO(startDate).toLocaleString(
            DateTime.DATE_MED,
          ),
        })}
      </TextItem>
    </Flex>
  )
}

import replace from 'lodash/replace'

import { api } from 'constants/config'

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getNotification } from 'store/selectors/notifications'

export const LOAD_NOTIFICATIONS = createAsyncAction('notification/LOAD_LIST')
export const LOAD_NOTIFICATION = createAsyncAction('notification/LOAD')
export const RECEIVE_NOTIFICATION = 'notification/RECEIVE'
export const UPDATE_NOTIFICATION = createAsyncAction('notification/UPDATE')
export const LOAD_NOTIFICATION_TIMEOFF = createAsyncAction(
  'notification/LOAD_NOTIFICATION_TIMEOFF',
)
export const LOAD_NOTIFICATION_TRADE_SHIFT = createAsyncAction(
  'notification/LOAD_NOTIFICATION_TRADE_SHIFT',
)
export const LOAD_NOTIFICATION_COVER = createAsyncAction(
  'notification/LOAD_NOTIFICATION_COVER',
)
export const LOAD_NOTIFICATION_ABSENCE = createAsyncAction(
  'notification/LOAD_NOTIFICATION_ABSENCE',
)
export const CALL_NOTIFICATION_LINK = createAsyncAction(
  'notification/CALL_NOTIFICATION_LINK',
)
export const NOTIFICATION_READY = 'notification/READY'
export const TOGGLE_NOTIFICATIONS = 'notification/TOGGLE'
export const LOAD_MORE_NOTIFICATIONS = createAsyncAction(
  'notifications/LOAD_MORE_NOTIFICATIONS',
)

const NOTIFICATIONS_LIMIT = 50
const notificationsIncludes = [
  'fromUser.profile.profileAvatar',
  'toUser.profile.profileAvatar',
]

const trimmedNotificationLink = link => {
  const { rubyUrl } = api
  const replaceHttp = url =>
    url.indexOf('https:') !== -1
      ? replace(url, 'https://', '')
      : replace(url, 'http://', '')

  const formatedLink = replaceHttp(link)
  const formatedApiUrl = replaceHttp(rubyUrl)
  return replace(formatedLink, formatedApiUrl, '')
}

export const loadNotifications = (limit = NOTIFICATIONS_LIMIT) =>
  apiCall({
    endpoint: `/notifications`,
    types: LOAD_NOTIFICATIONS,

    query: {
      include: notificationsIncludes.join(),
      'page[limit]': limit,
    },
  })

export const loadMoreNotifications = pageLastId =>
  apiCall({
    endpoint: `/notifications`,
    types: LOAD_MORE_NOTIFICATIONS,
    query: {
      'page[last_id]': pageLastId,
      'page[limit]': NOTIFICATIONS_LIMIT,
      include: notificationsIncludes.join(),
    },
  })

export const loadNotification = notificationId =>
  apiCall({
    endpoint: `/notifications/${notificationId}`,
    types: LOAD_NOTIFICATION,
    query: {
      include: 'fromUser.profile.profileAvatar,toUser.profile.profileAvatar',
    },
  })

export const loadNotificationTimeOff = showLink =>
  apiCall({
    endpoint: trimmedNotificationLink(showLink),
    types: LOAD_NOTIFICATION_TIMEOFF,
    query: {
      include: 'employee',
    },
  })

export const loadNotificationAbsence = showLink =>
  apiCall({
    endpoint: trimmedNotificationLink(showLink),
    types: LOAD_NOTIFICATION_ABSENCE,
    query: {
      include: 'schedule,employee',
    },
  })

export const loadNotificationTradeShift = showLink =>
  apiCall({
    endpoint: trimmedNotificationLink(showLink),
    query: {
      include: [
        'schedule',
        'schedule.employee',
        'shiftTrade',
        'schedule.shiftsJob.shift',
        'shiftTrade.fromSchedule.shiftsJob.shift',
        'shiftTrade.fromSchedule.employee.profile',
        'shiftTrade.acceptedSchedule.employee.profile',
        'schedule.shift.timeBucket',
        'schedule.shift.timeBucket.timeBucketParent',
        'shiftTrade.fromSchedule.shiftsJob.shift.timeBucket',
        'shiftTrade.fromSchedule.shiftsJob.shift.timeBucket.timeBucketParent',
      ].join(),
    },
    types: LOAD_NOTIFICATION_TRADE_SHIFT,
  })

export const loadNotificationCover = showLink =>
  apiCall({
    endpoint: trimmedNotificationLink(showLink),
    query: {
      include: [
        'fromEmployee.user.profile',
        'acceptedEmployee',
        'schedule',
        'schedule.shiftsJob.shift',
        'schedule.shift.timeBucket',
        'schedule.shift.timeBucket.timeBucketParent',
      ].join(),
    },
    types: LOAD_NOTIFICATION_COVER,
  })

export const receiveNotification = payload => ({
  type: RECEIVE_NOTIFICATION,
  payload,
  meta: {
    isSuccess: true,
  },
})

export const updateNotification = attributes => (dispatch, getState) => {
  const notification = getNotification(getState())
  dispatch(
    apiCall({
      method: 'PATCH',
      endpoint: `/notifications/${notification.id}`,
      types: UPDATE_NOTIFICATION,
      query: {
        data: {
          type: 'notifications',
          attributes,
        },
      },
    }),
  )
}

export const callNotificationLink = (
  link,
  comment,
  scheduleConflictType,
  notification,
  linkAction,
) => {
  return apiCall({
    method: 'POST',
    endpoint: trimmedNotificationLink(link),
    types: CALL_NOTIFICATION_LINK,
    query: {
      data: {
        attributes: {
          comment,
          scheduleConflictType,
        },
      },
    },
    payload: {
      linkAction,
      notification,
    },
  })
}

export const toggleNotifications = () => ({
  type: TOGGLE_NOTIFICATIONS,
})

// Saga only
export const notificationReady = () => ({
  type: NOTIFICATION_READY,
})

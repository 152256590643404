import React from 'react'

import { VscRequestChanges as RequestIcon } from 'react-icons/vsc'

import { ButtonProps, IconButton } from 'components/ui/__v3__/Button'

type Props = {
  onRequest: () => void
} & ButtonProps

export function Request({ onRequest, ...rest }: Props) {
  return (
    <IconButton {...rest} secondary onClick={onRequest}>
      <RequestIcon />
    </IconButton>
  )
}

import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import { fromStingToLocale } from 'components/blocks/__v2__/EffectivePeriods/helpers'
import { SpacedColumn } from 'components/ui/__v2__/Grid'

import _ from 'i18n'

function EffectivePeriodsCell({ row }) {
  const effectivePeriods = get(row, ['effectiveDates'])
  /**
   * The below covers the Totals Footer case
   */
  if (isNull(effectivePeriods)) return ''

  /**
   * No periods specified
   */
  if (isEmpty(effectivePeriods))
    return (
      <SpacedColumn space={1}>{`${_('timeline.not_available')} - ${_(
        'timeline.not_available',
      )}`}</SpacedColumn>
    )

  return (
    <SpacedColumn space={1}>
      {effectivePeriods.map(({ start, end }) => (
        <div key={start}>{`${
          fromStingToLocale(start) ?? _('timeline.not_available')
        } - ${fromStingToLocale(end) ?? _('timeline.not_available')}`}</div>
      ))}
    </SpacedColumn>
  )
}

EffectivePeriodsCell.propTypes = {
  row: PropTypes.object.isRequired,
}

export default EffectivePeriodsCell

import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'

import { i18n } from 'i18n'

Yup.setLocale({
  mixed: { required: i18n('validation.required') },
  string: {
    required: i18n('validation.required'),
    min: ({ min }: { min: string }) => i18n('validation.min', { min }),
    max: ({ max }: { max: string }) => i18n('validation.max', { max }),
  },
  number: {
    required: i18n('validation.required'),
  },
})

type ObjectShapeValues = ObjectShape extends Record<string, infer V> ? V : never
export type Shape<T extends Record<any, any>> = Partial<
  Record<keyof T, ObjectShapeValues>
>
export type Optional<T> = T | null | undefined

export default Yup
export const yup = Yup

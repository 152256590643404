import { useApolloMutation } from 'API/services/Apollo'

import { DeleteTimeTypeMutation } from './GraphQL'

export function useDeleteTimeType() {
  const [innerDelete, { loading: deleting }] = useApolloMutation<
    MutationData<'deleteTimetype'>,
    Gateway.MutationDeleteTimetypeArgs
  >(DeleteTimeTypeMutation, {
    // TODO: Make reusable updater
    update: cache => {
      cache.evict({ fieldName: 'timetypesByOffset' })
    },
  })

  const deleteTimetype = async (id: string) => {
    await innerDelete({ variables: { input: { id } } })
  }

  return { deleteTimetype, deleting }
}

import React, { useCallback, useEffect, useMemo } from 'react'
import {
  Control,
  useController,
  UseFormTrigger,
  useWatch,
} from 'react-hook-form'

import { isEqual, pick } from 'lodash'

import { FormEarningTypePicker } from 'components/blocks/__v3__/HookForm/FormEarningTypePicker'
import { FormTimeBucketChildrenPicker } from 'components/blocks/__v3__/HookForm/FormTimeBucketChildrenPicker'
import { Divider } from 'components/ui/__v2__/Divider'
import { Flex, Span } from 'components/ui/__v2__/Grid'
import { InputLabel, LabelText } from 'components/ui/__v3__'
import {
  FormSelect,
  FormText,
  FormTimeSelect,
} from 'components/ui/__v3__/HookForm'
import { Error } from 'components/ui/__v3__/HookForm/components'

import { useI18n, useLocale, useTimeBucketPermissions } from 'hooks'
import usePrevious from 'hooks/usePrevious'

import { i18n } from 'i18n'

import Utils from 'services/Utils'

import {
  DurationValue,
  HelperAction,
  HelperRow,
  HelperStatus,
  Layout,
  TimeEntryStatus,
} from './styles'

import { draftTimesheetRecordGuard, secondsDuration } from '../../helpers'
import { PositionOption, TimesheetFormState } from '../../types'

type Props = {
  disabled: boolean
  control: Control<TimesheetFormState>
  index: number
  trigger: UseFormTrigger<TimesheetFormState>
  positionOptions: PositionOption[]
  onRemoveDraft: (index: number) => void
  onUndoChanges: (index: number) => void
  onDeleteExisting: (id: number) => void
}

export function TimesheetRecord({
  disabled,
  control,
  positionOptions,
  index,
  trigger,
  onRemoveDraft,
  onDeleteExisting,
  onUndoChanges,
}: Props) {
  const t = useI18n('weeklyTimesheets')
  const locale = useLocale()
  const name = `entries.${index}` as const

  const {
    canViewSelectTimeBuckets,
    canModifySelectTimeBuckets,
  } = useTimeBucketPermissions()

  const {
    fieldState: { error, isDirty },
  } = useController({
    control,
    name,
  })

  const timesheetRecord = useWatch({
    control,
    name,
  })

  const draft = draftTimesheetRecordGuard(timesheetRecord)

  // ====================================================================
  // Note: this is a workaround to error name update when startSeconds or endSeconds change, there should be a better way
  const startEnd = pick(timesheetRecord, ['startSeconds', 'endSeconds'])
  const prevStartEnd = usePrevious(
    pick(timesheetRecord, ['startSeconds', 'endSeconds']),
  )
  useEffect(() => {
    if (!isEqual(startEnd, prevStartEnd)) {
      trigger()
    }
  }, [trigger, prevStartEnd, startEnd])

  // ====================================================================
  const {
    submittedAt,
    state,
    startSeconds,
    endSeconds,
    position,
  } = timesheetRecord

  const handleDeleteClick = useCallback((id: number) => onDeleteExisting(id), [
    onDeleteExisting,
  ])

  const timezoneCode = useMemo(
    () =>
      position
        ? Utils.DateTime.zoneOffsetName(position.data.location.timezone, locale)
        : '',
    [locale, position],
  )

  const submitted = !!submittedAt

  const renderHelper = useCallback(() => {
    if (draft) {
      return (
        <HelperStatus>
          <Span>{t('helper.statuses.draft')}</Span>
          {!disabled && (
            <HelperAction onClick={() => onRemoveDraft(index)}>
              {t('helper.actions.remove')}
            </HelperAction>
          )}
        </HelperStatus>
      )
    }

    if (!draft && isDirty) {
      return (
        <HelperStatus>
          <Span>{t('helper.statuses.modified')}</Span>
          {!disabled && (
            <HelperAction onClick={() => onUndoChanges(index)}>
              {t('helper.actions.undo')}
            </HelperAction>
          )}
        </HelperStatus>
      )
    }

    if (!draft && !submitted) {
      return (
        <HelperStatus>
          <Span>{t('helper.statuses.unsubmitted')}</Span>
          {!disabled && (
            <HelperAction onClick={() => handleDeleteClick(timesheetRecord.id)}>
              {t('helper.actions.delete')}
            </HelperAction>
          )}
        </HelperStatus>
      )
    }

    if (state) {
      return (
        <HelperStatus>
          <TimeEntryStatus state={state}>
            {t(`helper.statuses.${state.toLowerCase()}`)}
          </TimeEntryStatus>
          {!disabled && (
            <HelperAction onClick={() => handleDeleteClick(timesheetRecord.id)}>
              {t('helper.actions.delete')}
            </HelperAction>
          )}
        </HelperStatus>
      )
    }

    return <></>
  }, [
    disabled,
    draft,
    handleDeleteClick,
    index,
    isDirty,
    onRemoveDraft,
    onUndoChanges,
    state,
    submitted,
    t,
    timesheetRecord.id,
  ])

  return (
    <Flex flexDirection="column">
      <Layout>
        <FormSelect
          control={control}
          isClearable={false}
          isDisabled={disabled}
          labelText={t('fields.position')}
          name={`entries.${index}.position`}
          options={positionOptions}
        />
        <FormTimeSelect
          control={control}
          disabled={disabled}
          labelText={`${t('fields.startTime')} (${timezoneCode})`}
          name={`entries.${index}.startSeconds`}
        />
        <FormTimeSelect
          control={control}
          disabled={disabled}
          labelText={`${t('fields.endTime')} (${timezoneCode})`}
          name={`entries.${index}.endSeconds`}
        />
        <div>
          <InputLabel>
            <LabelText labelText={t('fields.duration')} />
          </InputLabel>
          <DurationValue>
            {Utils.DateTime.formatDuration(
              secondsDuration(startSeconds, endSeconds),
            )}
          </DurationValue>
        </div>

        {canViewSelectTimeBuckets && (
          <FormTimeBucketChildrenPicker
            control={control}
            isClearable
            isDisabled={!canModifySelectTimeBuckets || disabled}
            isSearchable
            labelContent={i18n('common.costGroupSubGroup')}
            name={`entries.${index}.timeBucketChild`}
            placeholder={i18n('common.selectCostGroupSubGroup')}
            withParentsNames
          />
        )}

        <FormEarningTypePicker
          control={control}
          isClearable
          isDisabled={disabled}
          labelText={t('fields.earningType')}
          name={`entries.${index}.earningType`}
        />
        <FormText
          control={control}
          disabled={disabled}
          labelText={t('fields.activityDetails')}
          name={`entries.${index}.activityDetails`}
        />
      </Layout>
      <HelperRow>
        {renderHelper()}
        {/* @ts-ignore */}
        <Divider horizontal />
        {error && <Error error={error} />}
      </HelperRow>
    </Flex>
  )
}

import { ApolloLink } from '@apollo/client'

import { gatewayAuthLink } from './gatewayAuthLink'
import { hathorAuthLink } from './hathorAuthLink'

export function authLink() {
  return ApolloLink.split(
    operation => operation.getContext().hathor === true,
    hathorAuthLink(),
    gatewayAuthLink(),
  )
}

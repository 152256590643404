import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Button } from '../Button'
import { Flex } from '../Grid'

const background = props =>
  props.light ? themeGet('colors.WHITE') : themeGet('layout.lightBorder')

export const Container = styled(Flex)`
  background: ${background};
  justify-content: space-between;
  cursor: pointer;
  position: relative;
`

export const Element = styled(Flex)`
  font-size: 12px;
  align-items: center;
  justify-content: center;
  flex: 1;
  user-select: none;
  color: ${themeGet('layout.lightText')};
`

export const Toggle = styled(Button).attrs({ squared: true, p: 0 })`
  font-weight: normal;
  position: absolute;
  font-size: 12px;
`

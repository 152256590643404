import React, { useCallback } from 'react'

import { API } from 'API'

import { Select } from 'components/ui/__v3__/Select'
import { PickerProps } from 'components/ui/__v3__/Select/types'

import { createpDebounce } from 'helpers/debounce'

import { entityToOption } from 'services/Options'

const SEARCH_DEBOUNCE_TIMEOUT = 350

type Props = { withArchived?: boolean } & Omit<
  PickerProps,
  | 'async'
  | 'closeMenuOnSelect'
  | 'defaultOptions'
  | 'formatOptionLabel'
  | 'isLoading'
  | 'loadOptions'
  | 'onMenuOpen'
>

export function TimeBucketParentsPicker({
  isMulti,
  withArchived = false,
  onChange,
  ...rest
}: Props) {
  const {
    timeBucketParents,
    timeBucketParentsLoading,
    loadTimeBucketParents,
    refetchTimeBucketParents,
  } = API.TimeBucket.parentsPicker()

  const defaultOptions = timeBucketParents.map(entityToOption) ?? []

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoadOptions = useCallback(
    createpDebounce(
      async (inputValue: string, callback: Function) => {
        const refetchedData = await refetchTimeBucketParents({
          inputValue,
          archived: withArchived,
        })
        const newOptions = refetchedData.map(entityToOption)

        callback(newOptions)
      },
      undefined,
      SEARCH_DEBOUNCE_TIMEOUT,
    ),
    [refetchTimeBucketParents],
  )

  // NOTE: to refresh the default values
  const handleInputChange = (
    inputValue: string,
    action: { action: string },
  ) => {
    if (action.action !== 'input-change' || inputValue) return

    refetchTimeBucketParents({ archived: withArchived })
  }

  const handleMenuOpen = () => {
    loadTimeBucketParents(withArchived)
  }

  return (
    <Select
      // @ts-ignore
      async
      closeMenuOnSelect={!isMulti}
      defaultOptions={defaultOptions}
      isLoading={timeBucketParentsLoading}
      isMulti={isMulti}
      loadOptions={handleLoadOptions}
      onChange={onChange}
      onInputChange={handleInputChange}
      onMenuOpen={handleMenuOpen}
      {...rest}
    />
  )
}

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
const MIME_TYPES = {
  anyImage: 'image/*',
  jpeg: 'image/jpeg',
  png: 'image/png',
  anyVideo: 'video/*',
}

export const PHOTOS_VIDEOS = [MIME_TYPES.anyImage, MIME_TYPES.anyVideo].join(
  ', ',
)

export const RASTER_PICS = MIME_TYPES.anyImage

import ReactModal from 'react-modal'

import { FiX } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import withStyledClassNames from 'with-styled-class-names'

import RemoveIcon from 'components/ui/Icons/remove'

import { Flex } from '../Grid'

export const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: ${props => props.width}px;
  outline: none;
  background-color: ${themeGet('colors.WHITE')};
  padding: 45px;
  box-shadow: 0 1px 30px 0 ${themeGet('colors.SHADOW')};
`

export const Text = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1 0;
  font-family: ${themeGet('font')};
  font-size: 18px;
  font-weight: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 25px;

  > b {
    font-weight: 500;
  }
`

export const Buttons = styled(Flex)`
  justify-content: space-between;
  width: 100%;
`

export const StyledRemoveIcon = styled(RemoveIcon)`
  position: absolute;
  bottom: 3px;
  left: 10px;
  stroke: ${themeGet('colors.WHITE')};
`

export const StyledClose = styled(FiX)`
  position: absolute;
  top: 4px;
  right: 4px;
  height: 23.8px;
  width: 23.8px;
  color: ${themeGet('colors.DEFAULT_TEXT')};
  cursor: pointer;
`
export const Modal = withStyledClassNames(
  {
    className: {
      base: css`
        outline: none;
        opacity: 0;
        transform: scale(0.4);
        max-height: 96vh;
        ${props =>
          props.isOverflow &&
          css`
            overflow-x: hidden;
            overflow-y: auto;
          `};
        border-radius: 2px;
        box-shadow: 0 10px 40px 0 ${themeGet('colors.SHADOW')};

        transition: all ${themeGet('transitionTime')};
        transition-delay: 100ms;
      `,
      afterOpen: css`
        opacity: 1;
        transform: scale(1);
      `,
      beforeClose: css`
        opacity: 0;
      `,
    },
    overlayClassName: {
      base: css`
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${themeGet('colors.OVERLAY')};
        // TODO: get z-index from theme
        z-index: 9999;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;

        transition: all ${themeGet('transitionTime')};
      `,
      afterOpen: css`
        opacity: 1;
      `,
      beforeClose: css`
        opacity: 0;
      `,
    },
  },
  ReactModal,
)

import React from 'react'

import { ShiftAssignment } from 'components/blocks/__v3__'
import { Modal } from 'components/ui/__v3__/'

import { i18n } from 'i18n'

type Props = {
  shiftId: string
  onClose: () => void
}

export function ShiftAssignModal({ shiftId, onClose }: Props) {
  return (
    <Modal.Container isOpen>
      <Modal.Close onClose={onClose} />
      <Modal.Title>{i18n('shiftAssignModal.title')}</Modal.Title>

      <ShiftAssignment shiftId={shiftId} />
    </Modal.Container>
  )
}

import { useCallback, useEffect, useMemo } from 'react'

import { DateTime } from 'luxon'
import { StoreonModule } from 'storeon'
import { useStoreon } from 'storeon/react'
import { AreasFilter } from 'Types/common'

import isEqual from 'lodash/isEqual'

import { areasToOptionsWithoutEntities } from 'helpers/areas'

import { useAppContext } from 'hooks'

import Utils from 'services/Utils'

export const UNFILLED_STATE_KEY = 'unfilledShifts'

type DateRangeType = {
  start: string
  end: string
}

export interface UnfilledPositionsState {
  [UNFILLED_STATE_KEY]: UnfilledPositionsSubState
}

export interface UnfilledPositionsEvents {
  'unfilledShifts/set': Partial<UnfilledPositionsSubState>
}

interface UnfilledPositionsSubState {
  areas: AreasFilter
  disabled: boolean
  dateRange: DateRangeType
}

const INITIAL_STATE = {
  areas: {},
  disabled: false,
  dateRange: Utils.DateTime.getCurrentWeek(),
}

const unfilledPositionsModule: StoreonModule<
  UnfilledPositionsState,
  UnfilledPositionsEvents
> = store => {
  store.on('@init', () => ({
    [UNFILLED_STATE_KEY]: INITIAL_STATE,
  }))

  store.on('unfilledShifts/set', (state, variables) => {
    const currentState = state[UNFILLED_STATE_KEY]
    const nextState = { ...currentState, ...variables }

    if (isEqual(nextState, currentState)) return null
    return { [UNFILLED_STATE_KEY]: nextState }
  })
}

export function useUnfilledPositionsState() {
  const {
    companySettings: { calendarStartDay },
  } = useAppContext()

  const { [UNFILLED_STATE_KEY]: state, dispatch } = useStoreon<
    UnfilledPositionsState,
    UnfilledPositionsEvents
  >(UNFILLED_STATE_KEY)

  const updateState = useCallback(
    (variables: Partial<UnfilledPositionsSubState>) =>
      dispatch('unfilledShifts/set', variables),
    [dispatch],
  )

  useEffect(() => {
    updateState({ dateRange: Utils.DateTime.getCurrentWeek(calendarStartDay) })
  }, [calendarStartDay, updateState])

  const onAreasChange = useCallback(
    (selectedAreas: AreasFilter) =>
      updateState({
        areas: areasToOptionsWithoutEntities(selectedAreas),
      }),
    [updateState],
  )

  const areas = useMemo(() => state.areas, [state])

  const dateRange = useMemo(() => {
    const storageStartIsoDate = state?.dateRange?.start
    const storageEndIsoDate = state?.dateRange?.end

    // Fallback to cover data missing in LS at first render
    const { start, end } = Utils.DateTime.getCurrentWeek(calendarStartDay)

    const startISODate = storageStartIsoDate ?? start
    const endISODate = storageEndIsoDate ?? end

    return {
      start: DateTime.fromISO(startISODate),
      end: DateTime.fromISO(endISODate),
    }
  }, [calendarStartDay, state])

  const onResetDateRange = useCallback(
    () =>
      updateState({
        dateRange: Utils.DateTime.getCurrentWeek(calendarStartDay),
      }),
    [calendarStartDay, updateState],
  )

  const onDateRangeChange = useCallback(
    ({ start, end }) =>
      updateState({
        dateRange: {
          start: start?.toISODate(),
          end: end?.toISODate(),
        },
      }),
    [updateState],
  )
  const isDisabled = useMemo(() => state?.disabled, [state?.disabled])

  const isInitialWeek = useMemo(
    () =>
      DateTime.fromISO(
        Utils.DateTime.getCurrentWeek(calendarStartDay).start,
      ).hasSame(dateRange.start, 'day'),
    [calendarStartDay, dateRange.start],
  )

  const onRemove = useCallback(() => updateState({ disabled: true }), [
    updateState,
  ])

  const onRestore = useCallback(() => updateState({ disabled: false }), [
    updateState,
  ])

  return {
    isInitialWeek,
    areas,
    dateRange,
    onResetDateRange,
    onAreasChange,
    onDateRangeChange,
    isDisabled,
    onRemove,
    onRestore,
  }
}

export default unfilledPositionsModule

import { BsDownload, BsTrash } from 'react-icons/bs'

import styled, { css } from 'styled-components'
import {
  border,
  flexbox,
  layout,
  position,
  space,
  typography,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { radius, when } from 'components/ui/__v3__/__utils__/styled-system'

export const InputBorder = styled.div`
  border-radius: ${radius('normal')};
  border: 1px solid ${themeGet('input.border.default')};
  background-color: ${themeGet('input.bg.default')};
  padding: 3px 4px;
  line-height: 1.15;
  min-height: 24px;
  cursor: pointer;

  ${when(
    'errored',
    css`
      border-color: ${themeGet('input.border.errored')};
    `,
  )};

  ${when(
    'disabled',
    css`
      background-color: ${themeGet('input.bg.disabled')};
      border: 1px solid ${themeGet('input.border.disabled')};
      cursor: not-allowed;
    `,
  )};

  ${layout};
  ${space};
  ${flexbox};
  ${typography}
  ${position};
`

export const TriangleDown = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid;

  ${space};
`

export const DownloadIcon = styled(BsDownload)`
  color: inherit;
`

export const RemoveIcon = styled(BsTrash)`
  color: inherit;
`

export const IconButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  color: ${themeGet('layout.primary')};
`

export const BorderIconButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  align-items: center;
  border-radius: ${radius('normal')};
  border: 1px solid ${themeGet('input.border.default')};
  background-color: ${themeGet('input.bg.default')};
  padding: 3px 4px;
  line-height: 1.15;
  min-height: 24px;
  cursor: pointer;
  color: ${themeGet('layout.primary')};

  &:disabled {
    border: 1px solid ${themeGet('input.border.disabled')};
    background-color: ${themeGet('input.bg.disabled')};
    color: ${themeGet('layout.lightText')};
    cursor: not-allowed;
  }

  ${space};
`

export const IconLink = styled.a.attrs({ target: '_blank' })`
  display: flex;
  color: ${themeGet('layout.primary')};

  ${space};
  ${flexbox};
`

export const PopupContainer = styled.div`
  font-weight: 400;
  font-size: 14px;
`

export const FilesList = styled.div`
  display: flex;
  flex-direction: column;
  width: min-content;
  padding: 0 ${themeGet('space.2')}px;
  max-height: 12rem;
  overflow-y: scroll;

  & > *:not(:first-child) {
    border-top: 1px solid ${themeGet('input.border.default')};
  }

  ${space};
  ${border};
`

export const FileRow = styled.div`
  display: flex;
  align-items: center;
  padding: ${themeGet('space.2')}px 0;

  & > *:not(:last-child) {
    margin-right: ${themeGet('space.3')}px;
  }

  ${space};
`

export const FileLink = styled.a.attrs({ target: '_blank' })`
  display: flex;
  color: inherit;
  width: max-content;

  ${space};
  ${flexbox};
`

export const NoFilesText = styled.div`
  white-space: nowrap;
  text-transform: uppercase;
`

export const PlaceholderText = styled.span`
  color: ${themeGet('input.placeholder')};
  white-space: nowrap;
`

import styled, { css } from 'styled-components'

type Args = { color: string }
const colorCss = ({ color }: Args) =>
  css`
    background: ${color};
  `

export const Color = styled.div<Args>`
  min-width: 12px;
  height: 12px;
  border-radius: 2px;
  ${colorCss}
`

export const ValueContainerWrapper = styled.div`
  flex: 1;
  max-height: 80px;
  overflow: auto;
`

export const TimetypeItemContainer = styled.div`
  cursor: pointer;
`

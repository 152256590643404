import React from 'react'

import { Emoji } from 'emoji-mart-lite'

// Example: MessageContent:girl::skin-tone-4::male-farmer::skin-tone-4::girl:
export const createEmojiContent = (content, size = 22) => {
  const emojiColonsSkinRegex = /:{1}([A-Za-z0-9-_+])+:{2}skin-tone([A-Za-z0-9-_+])+:{1}/g
  const emojiColonsRegex = /:{1}([A-Za-z0-9-_+])+:{1}/g
  const colonsSkinRegex = /\|{1}([A-za-z0-9-_+])+:{2}skin-tone([A-za-z0-9-_+])+\|{1}/g
  const colonsRegex = /\|{1}([A-za-z0-9-_+])+\|{1}/g

  let emojiContent = content

  if (
    emojiColonsSkinRegex.test(emojiContent) ||
    emojiColonsRegex.test(emojiContent)
  ) {
    emojiContent = emojiContent
      .replace(
        emojiColonsSkinRegex,
        match => `~~|~~|${match.slice(1, match.length - 1)}|~~|~~`,
      )
      .replace(
        emojiColonsRegex,
        match => `~~|~~|${match.slice(1, match.length - 1)}|~~|~~`,
      )
      .split('~~|~~')
      .map((contentPiece, index) => {
        if (
          colonsSkinRegex.test(contentPiece) ||
          colonsRegex.test(contentPiece)
        ) {
          return (
            <Emoji
              emoji={`:${contentPiece.slice(1, contentPiece.length - 1)}:`}
              key={index}
              size={size}
            />
          )
        }
        return contentPiece
      })
  }
  return emojiContent
}

import { createGlobalStyle } from 'styled-components'
import normalize from 'styled-normalize'
import { themeGet } from '@styled-system/theme-get'

import { scrollbar } from './styles'

import 'emoji-mart-lite/css/emoji-mart.css'
import 'react-day-picker/lib/style.css'
import '../../node_modules/cropperjs/dist/cropper.css'

export default createGlobalStyle`
  ${normalize};

  body {
    color: ${themeGet('layout.text')};
    background-color: ${themeGet('layout.bg')};
    font-size: 14px;
    font-family: ${themeGet('font')};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  p {
    padding: 0;
    margin: 0;
  }

  b, strong {
    font-weight: ${themeGet('fontWeights.bold')};
  }

  sup {
    top: unset;
    font-size: 100%;
    color: ${themeGet('layout.primary')};
  }

  .collapse-transition {
    transition: height 250ms cubic-bezier(.4, 0, .2, 1);
  }

  * {
    ${scrollbar};
  }

  .Resizer {
    background: #000;
    opacity: 0.2;
    // TODO: get z-index from theme
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }

  .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }

  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }

  .Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
  }

  .Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
  }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity ${themeGet('layout.transition.normal')} ease-in-out;
    z-index: 9999;
    background-color: ${themeGet('modal.overlay.bg')} !important;
    border-radius: 4px;

    &--after-open {
      opacity: 1;
    }

    &--before-close {
      opacity: 0;
    }
  }

  .ReactModal__Content {
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    max-height: 90vh;
    transform: scale3d(0, 0, 0);
    opacity: 0;
    transition-duration: ${themeGet('layout.transition.normal')};
    transition-property: opacity, transform;
    transition-timing-function: ease-in-out;

    padding: 24px;
    margin: auto;
    border: none;
    font-family: ${themeGet('fonts.main')};
    transition: all 0.2s ease;

    &--after-open {
      transform: scale3d(1, 1, 1);
      opacity: 1;
    }

    &--before-close {
      transform: scale3d(0, 0, 0);
      opacity: 0;
    }
  }
`

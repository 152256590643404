import { DateTime } from 'luxon'
import { create, enforce, test } from 'vest'

import { i18n } from 'i18n'

import { MultipleSliceValueFormState, ValidatorProperties } from '../types'

export const validator = (validatorProperties?: ValidatorProperties) =>
  create(({ slices }: MultipleSliceValueFormState) => {
    slices.forEach((slice, index, all) => {
      // START DATE
      if (index === 0) {
        // Must start with minus infinity
        test(
          `slices.${index}.effectiveDates.start`,
          i18n('multiSliceForm.validation.mustBeInfinity'),
          () => {
            enforce(slice.effectiveDates.start).isNullish()
          },
        )
      } else {
        test(
          `slices.${index}.effectiveDates.start`,
          i18n('validation.required'),
          () => {
            enforce(slice.effectiveDates.start).isNotBlank()
          },
        )

        // Must be one day after previous end date
        test(
          `slices.${index}.effectiveDates.start`,
          i18n('multiSliceForm.validation.mustBeOneDayAfterPreviousEndDate'),
          () => {
            const previousEndDate = all.at(index - 1)?.effectiveDates?.end
            const startDate = slice.effectiveDates.start

            if (!previousEndDate || !startDate) return true

            return (
              // Note: it is improtant to have both datetimes in same zone
              DateTime.fromISO(startDate, { zone: 'utc' })
                .diff(
                  DateTime.fromISO(previousEndDate, { zone: 'utc' }),
                  'days',
                )
                .toObject().days === 1
            )
          },
        )
      }

      // END DATE
      if (index === all.length - 1) {
        // Must end with  plus infinity
        test(
          `slices.${index}.effectiveDates.end`,
          i18n('multiSliceForm.validation.mustBeInfinity'),
          () => {
            enforce(slice.effectiveDates.end).isNullish()
          },
        )
      } else {
        test(
          `slices.${index}.effectiveDates.end`,
          i18n('validation.required'),
          () => {
            enforce(slice.effectiveDates.end).isNotBlank()
          },
        )

        // Must be al least one day after current start date
        test(
          `slices.${index}.effectiveDates.end`,
          i18n('multiSliceForm.validation.mustBeLaterThanStartDate'),
          () => {
            const startDate = slice.effectiveDates.start
            const endDate = slice.effectiveDates.end

            if (!startDate || !endDate) return true

            return DateTime.fromISO(endDate) > DateTime.fromISO(startDate)
          },
        )
      }

      // TODO: remove these functions and pass dynamic ones instead
      // VALUE
      if (validatorProperties?.mandatory) {
        test(`slices.${index}.value`, i18n('validation.required'), () => {
          enforce(slice.value).isNotBlank()
        })
      }

      if (validatorProperties?.uniqueValue && all[index - 1]) {
        // Must not have the value same as the next slice
        test(
          `slices.${index}.value`,
          i18n('multiSliceForm.validation.mustNotBeSameValue'),
          () => {
            enforce(slice.value).notEquals(slices[index - 1].value)
          },
        )
      }

      // TODO: remove these functions and pass dynamic ones instead
      // DECIMAL VALUE CHECK
      if (validatorProperties?.decimalValue) {
        test(
          `slices.${index}.value`,
          i18n('multiSliceForm.validation.mustBeDecimal'),
          () => {
            const decimalRegex = /^\d+(\.\d+)?$/
            enforce(slice.value).matches(decimalRegex)
          },
        )
      }
    })
  })

import filter from 'lodash/filter'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import keys from 'lodash/keys'
import map from 'lodash/map'
import merge from 'lodash/merge'
import omit from 'lodash/omit'

import { createReducer } from 'helpers/redux'

import {
  CLEAR_HISTORY,
  RECEIVED_DELETED_MESSAGE,
  RECEIVED_MESSAGE,
} from 'store/actions/groupChat'

const initialState = {}

const handlers = {
  [CLEAR_HISTORY.SUCCESS]: (state, { payload }) => {
    const {
      data: { chatId },
    } = payload
    const clearedMessages = isNull(chatId)
      ? []
      : filter(state, ['relationships.chat.data.id', chatId])
    const clearedMessagesIds = isEmpty(clearedMessages)
      ? []
      : map(clearedMessages, 'id')
    const clearedState = isEmpty(clearedMessagesIds)
      ? state
      : omit(state, clearedMessagesIds)
    return clearedState
  },
  [RECEIVED_DELETED_MESSAGE]: (state, { payload }) => {
    const { deletedId } = payload
    const newState = isEmpty(deletedId) ? state : omit(state, [deletedId])
    return newState
  },
  [RECEIVED_MESSAGE]: (state, { payload }) => {
    const {
      data: { message, chatId },
    } = payload
    const nowTime = new Date().toISOString()
    const stateMessagesIds = keys(state)
    const messageId = message.id.toString()
    const isReceivedEdited = indexOf(stateMessagesIds, messageId) !== -1
    const time = isReceivedEdited
      ? { updatedAt: nowTime }
      : { createdAt: nowTime }
    const messageFile = get(message, 'file', {})
    const mergeFields = { ...time, file: { ...messageFile } }

    return merge({}, state, {
      [message.id]: {
        id: messageId,
        type: 'messages',
        attributes: merge({}, message, mergeFields),
        relationships: {
          chat: {
            data: {
              id: chatId,
              type: 'chats',
            },
          },
          user: {
            data: {
              id: message.userId,
              type: 'users',
            },
          },
        },
      },
    })
  },
}

export default createReducer(initialState, handlers)

import { useApolloLazyQuery } from 'API/services/Apollo'

import isNil from 'lodash/isNil'

import {
  EmploymentLevelSortingField,
  SortingDirection,
} from 'constants/gatewayGraphQL'
import { PAGE_SIZE } from 'constants/pagination'

import Utils from 'services/Utils'

import { EmploymentLevelPickerFields } from './GraphQL/Fragments/types'
import {
  EmploymentLevelsPickerQuery,
  EmploymentLevelsPickerQueryData,
} from './GraphQL'

export function useEmploymentLevelsPicker() {
  const [
    loadInner,
    { loading: employmentLevelsLoading, data, refetch: innerRefetch },
  ] = useApolloLazyQuery<
    EmploymentLevelsPickerQueryData,
    Gateway.QueryEmploymentLevelsByCursorArgs
  >(EmploymentLevelsPickerQuery, {
    fetchPolicy: 'network-only',
  })

  const loadEmploymentLevels = ({
    archived,
    employmentTypeIds,
  }: {
    archived?: boolean
    employmentTypeIds?: string[]
  }) => {
    loadInner({
      variables: {
        paging: { limit: PAGE_SIZE['10'] },
        sorting: {
          field: EmploymentLevelSortingField.SeniorityLevel,
          direction: SortingDirection.Asc,
        },
        filter: {
          archived: !isNil(archived)
            ? Utils.GraphQL.eqFilter(archived)
            : undefined,
          employmentTypeId: !isNil(employmentTypeIds)
            ? Utils.GraphQL.inFilter(employmentTypeIds)
            : undefined,
        },
      },
    })
  }

  const refetchEmploymentLevels = async ({
    inputValue,
    archived,
    employmentTypeIds,
  }: {
    inputValue?: string
    archived?: boolean
    employmentTypeIds?: string[]
  } = {}) => {
    if (!innerRefetch) return []

    const result = await innerRefetch({
      filter: {
        name: inputValue ? { ilike: inputValue } : undefined,
        archived: !isNil(archived)
          ? Utils.GraphQL.eqFilter(archived)
          : undefined,
        employmentTypeId: !isNil(employmentTypeIds)
          ? Utils.GraphQL.inFilter(employmentTypeIds)
          : undefined,
      },
    })
    return dataToNodes(result.data)
  }

  const employmentLevels = dataToNodes(data)

  return {
    employmentLevels,
    employmentLevelsLoading,
    refetchEmploymentLevels,
    loadEmploymentLevels,
  }
}

function dataToNodes(
  data?: EmploymentLevelsPickerQueryData,
): EmploymentLevelPickerFields[] {
  return data?.employmentLevelsByCursor?.edges.map(edge => edge.node) ?? []
}

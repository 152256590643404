import { useGeneratePulledFileLink } from './useGeneratePulledFileLink'
import { useInvalidatePulledFile } from './useInvalidatePulledFile'
import { usePulledFile } from './usePulledFile'
import { usePulledFilesByCursor } from './usePulledFilesByCursor'
import { useStartProcessing } from './useStartProcessing'

export const PulledFileAPI = {
  byId: usePulledFile,
  byCursor: usePulledFilesByCursor,
  startProcessing: useStartProcessing,
  invalidate: useInvalidatePulledFile,
  generatePulledFileLink: useGeneratePulledFileLink,
}

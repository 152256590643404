import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const SALARY_REVIEW = {
  LOAD: createAsyncAction('salary_review/LOAD'),
  CREATE: createAsyncAction('salary_review/CREATE'),
  UPDATE: createAsyncAction('salary_review/UPDATE'),
  DELETE: createAsyncAction('salary_review/DELETE'),
}

export const SALARY_REVIEW_KIND = {
  LOAD: createAsyncAction('salary_review_kind/LOAD'),
  CREATE: createAsyncAction('salary_review_kind/CREATE'),
  UPDATE: createAsyncAction('salary_review_kind/UPDATE'),
  DELETE: createAsyncAction('salary_review_kind/DELETE'),
}
const INCLUDE_SALARY_REVIEW = [
  'employee',
  'kind',
  'attachment',
  'createdBy.profile',
  'updatedBy.profile',
].join(',')
const INCLUDE_SALARY_REVIEW_KIND = 'company'

export const loadReviews = ({
  employeeId,
  number,
  size,
  filter,
  sort = '-revision_date,created_at',
}) => dispatch => {
  const paged = !!number && !!size
  const page = paged ? { page: { number, size } } : null
  return dispatch(
    apiCall({
      endpoint: `/employees/${employeeId}/salary_revisions`,
      types: SALARY_REVIEW.LOAD,
      method: 'GET',
      query: {
        include: INCLUDE_SALARY_REVIEW,
        ...page,
        ...filter,
        sort,
      },
      paged,
    }),
  )
}

export const createReview = ({
  employeeId,
  attributes,
  attachmentId,
  kindId,
}) => dispatch => {
  const attachment = attachmentId
    ? {
        attachment: {
          data: {
            id: attachmentId,
            type: 'files',
          },
        },
      }
    : null
  dispatch(
    apiCall({
      endpoint: `/employees/${employeeId}/salary_revisions`,
      types: SALARY_REVIEW.CREATE,
      method: 'POST',
      query: {
        include: INCLUDE_SALARY_REVIEW,
        data: {
          attributes,
          relationships: {
            ...attachment,
            kind: {
              data: {
                id: kindId,
                type: 'salaryRevisionKinds',
              },
            },
          },
        },
      },
    }),
  )
}

export const updateReview = ({
  salaryRevisionId,
  attributes,
  relationships,
}) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/salary_revisions/${salaryRevisionId}`,
      types: SALARY_REVIEW.UPDATE,
      method: 'PATCH',
      query: {
        include: INCLUDE_SALARY_REVIEW,
        data: {
          attributes,
          relationships,
        },
      },
    }),
  )
}

export const deleteReview = salaryRevisionId => dispatch =>
  dispatch(
    apiCall({
      endpoint: `/salary_revisions/${salaryRevisionId}`,
      types: SALARY_REVIEW.DELETE,
      method: 'DELETE',
      payload: {
        deletedId: salaryRevisionId,
      },
    }),
  )

export const loadReviewKinds = (
  number = null,
  size = null,
  sort = '-name,created_at',
) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())
  const paged = !!number && !!size
  const page = paged ? { number, size } : {}
  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/salary_revision_kinds`,
      types: SALARY_REVIEW_KIND.LOAD,
      method: 'GET',
      query: {
        include: INCLUDE_SALARY_REVIEW_KIND,
        page,
        sort,
      },
      paged,
    }),
  )
}

// Probably API boilerplate, not used in the app
// export const createReviewKind = ({ attributes }) => (dispatch, getState) => {
//   const companyId = getCompanyId(getState())
//   dispatch(
//     apiCall({
//       endpoint: `/companies/${companyId}/salary_revision_kinds`,
//       types: SALARY_REVIEW_KIND.CREATE,
//       method: 'POST',
//       query: {
//         include: INCLUDE_SALARY_REVIEW_KIND,
//         data: {
//           attributes,
//         },
//       },
//     }),
//   )
// }

// export const updateReviewKind = ({
//   salaryReviewKindId,
//   attributes,
// }) => dispatch => {
//   return dispatch(
//     apiCall({
//       endpoint: `/salary_revision_kinds/${salaryReviewKindId}`,
//       types: SALARY_REVIEW_KIND.UPDATE,
//       method: 'PATCH',
//       query: {
//         include: INCLUDE_SALARY_REVIEW_KIND,
//         data: {
//           attributes,
//         },
//       },
//     }),
//   )
// }

// export const deleteReviewKind = salaryReviewKindId => dispatch =>
//   dispatch(
//     apiCall({
//       endpoint: `/salary_revision_kinds/${salaryReviewKindId}`,
//       types: SALARY_REVIEW_KIND.DELETE,
//       method: 'DELETE',
//       payload: {
//         deletedId: INCLUDE_SALARY_REVIEW_KIND,
//       },
//     }),
//   )

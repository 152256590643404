import styled from 'styled-components'

type ColorProps = {
  color: string
}

const colorCss = ({ color }: ColorProps) => `background: ${color};`

export const Color = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  margin-right: 2px;
  ${colorCss}
`

import React from 'react'
import { Control } from 'react-hook-form'

import { Flex, Span } from 'components/ui/__v2__/Grid'
import { FormMaskedNumeric, FormSwitch } from 'components/ui/__v3__/HookForm'

import { POSITIVE_NUMBER_MASK } from 'constants/settings'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { IconText } from '../styles'
import { GlobalSettingsFormState } from '../types'

type Props = {
  control: Control<GlobalSettingsFormState, any>
  label: string
  formName: 'timeoffSettingsThreshold' | 'timecardSettingsThreshold'
  enabled: boolean
}

const LIMIT_MASK = POSITIVE_NUMBER_MASK(3)

export function FormLimitAdjustments({
  control,
  label,
  formName,
  enabled,
}: Props) {
  const t = useI18n<typeof TRANSLATIONS.settings.limitAdjustments>(
    'settings.limitAdjustments',
  )

  return (
    <FormSwitch<GlobalSettingsFormState>
      control={control}
      label={
        <Flex alignItems="center" gap={1}>
          <Span>{label}</Span>

          <FormMaskedNumeric<GlobalSettingsFormState>
            control={control}
            disabled={!enabled}
            icon={<IconText>{t('days')}</IconText>}
            mask={LIMIT_MASK}
            name={`${formName}.value`}
            required={enabled}
            width={50}
          />

          <Span>{t('inThePast')}</Span>
        </Flex>
      }
      name={`${formName}.enabled`}
    />
  )
}

import { useApproveTimeoffRequest } from './useApproveTimeoffRequest'
import { useCreateTimeoff } from './useCreateTimeoff'
import { useDeleteTimeoffsRequest } from './useDeleteTimeoffsRequest'
import { useMoveTimeoffRequest } from './useMoveTimeoffRequest'
import { useRejectTimeoffRequest } from './useRejectTimeoffRequest'
import { useTimeoffsByCursor } from './useTimeoffsByCursor'
import { useTimeoffsByOffset } from './useTimeoffsByOffset'

export const TimeoffsAPI = {
  approve: useApproveTimeoffRequest,
  reject: useRejectTimeoffRequest,
  delete: useDeleteTimeoffsRequest,
  move: useMoveTimeoffRequest,
  getByOffset: useTimeoffsByOffset,
  getByCursor: useTimeoffsByCursor,
  create: useCreateTimeoff,
}
export * from './types'

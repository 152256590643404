import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'

import {
  copyPasteShiftFilterToAPI,
  CopyShiftConflictsQuery,
  CopyShiftConflictsQueryData,
} from './GraphQL'
import { CopyPasteShiftFilter } from './types'

type UseCopyShiftConflictsProps = {
  weekOffsets?: number[]
  locationId?: string
  startOn?: string
  filter: CopyPasteShiftFilter
}

export function useCopyShiftConflicts(
  { weekOffsets, locationId, startOn, filter }: UseCopyShiftConflictsProps,
  queryOptions: Partial<QueryHookOptions>,
) {
  const apiFilter = copyPasteShiftFilterToAPI(filter)
  const hasApiFilter = Object.values(apiFilter).length > 0

  const { data, loading, refetch } = useApolloQuery<
    CopyShiftConflictsQueryData,
    Gateway.QueryCopyShiftConflictsArgs
  >(CopyShiftConflictsQuery, {
    ...queryOptions,

    variables: {
      input: {
        payload: {
          weekOffsets,
          locationId: locationId!,
          startOn: startOn!,

          ...(hasApiFilter && {
            copyFilter: apiFilter,
            pasteFilter: apiFilter,
          }),
        },
      },
    },
  })

  const conflictingSchedules =
    data?.copyShiftConflicts?.conflictingSchedules ?? []
  const conflictingTimeoffs =
    data?.copyShiftConflicts?.conflictingTimeoffs ?? []

  return { conflictingSchedules, conflictingTimeoffs, loading, refetch }
}

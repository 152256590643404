import { DateTime } from 'luxon'

import { EXPORT_TYPES } from 'constants/payroll'

import {
  areasToFiltersPayrollExport,
  areasToRelationships,
} from 'helpers/areas'
import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const PAYROLL_EXPORT = createAsyncAction('payroll/EXPORT')
export const CREATE_TOKEN = createAsyncAction('payroll/CREATE_TOKEN')
export const TOGGLE_TAB = 'payroll/TOGGLE_TAB'
export const LOAD_NETHRIS_TABLE = createAsyncAction(
  'payroll/LOAD_NETHRIS_TABLE',
)
export const LOAD_MORE_NETHRIS_TABLE = createAsyncAction(
  'payroll/LOAD_MORE_NETHRIS_TABLE',
)
export const PAYROLL_ORACLE_EXPORT = createAsyncAction('payroll/ORACLE_EXPORT')

export const payrollOracleExport = ({ month, year }) => (
  dispatch,
  getState,
) => {
  const companyId = getCompanyId(getState())

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/oracle/payroll_export`,
      method: 'POST',
      types: PAYROLL_ORACLE_EXPORT,
      query: {
        data: {
          attributes: {
            month,
            year,
          },
        },
      },
    }),
  )
}

export const payrollExport = ({
  locationId,
  startDate,
  endDate,
  type,
  departments = null,
  jobs = null,
  jobsEmployees = null,
  branchPayrollId = null,
  applyRules = true,
}) =>
  apiCall({
    method: 'POST',
    endpoint: '/employees/payroll_export',
    types: PAYROLL_EXPORT,
    query: {
      data: {
        attributes: {
          startDate: DateTime.fromISO(startDate).toFormat("yyyy'-'MM'-'dd"),
          endDate: DateTime.fromISO(endDate).toFormat("yyyy'-'MM'-'dd"),
          type,
          branchId: locationId,
          ...(branchPayrollId && { branchPayrollId }),
          applyRules,
        },
        relationships: {
          ...areasToRelationships({ departments, jobs, jobsEmployees }),
          branch: { data: { type: 'branches', id: locationId } },
        },
      },
    },
    payload: {
      data: {
        locationId,
        branchPayrollId,
      },
    },
  })

export const loadNethrisTable = ({
  locationId,
  departments = null,
  jobs = null,
  jobsEmployees = null,
  from,
  to,
  page = 1,
  size = 100,
  types = null,
}) =>
  apiCall({
    endpoint: '/employees/payroll_export',
    types: types || LOAD_NETHRIS_TABLE,
    query: {
      filter: {
        start_date: from.toFormat("yyyy'-'MM'-'dd"),
        end_date: to.toFormat("yyyy'-'MM'-'dd"),
        type: EXPORT_TYPES.nethris,
        branch_id: locationId,
        ...areasToFiltersPayrollExport({ departments, jobs, jobsEmployees }),
      },
      page: {
        number: page,
        size,
      },
    },
    paged: true,
  })

export const loadMoreNethrisTable = params =>
  loadNethrisTable({ ...params, types: LOAD_MORE_NETHRIS_TABLE })

export const createToken = url =>
  apiCall({
    method: 'POST',
    endpoint: '/token',
    types: CREATE_TOKEN,
    query: { url },
    payload: { url },
    isRaw: true,
  })

export const toggleTab = () => ({
  type: TOGGLE_TAB,
})

import { ApolloCache } from '@apollo/client'
import { FragmentOptions } from 'API/services/utils'

export function shiftUnfilledPositionsCountUpdater(
  cache: ApolloCache<any>,
  id: Gateway.Shift['id'],
  unfilledPositionsCount: number,
) {
  const fragmentOptions = FragmentOptions.Shift(id)

  const shift = cache.readFragment<Gateway.Shift>({
    id: fragmentOptions.id,
    fragment: fragmentOptions.fragment,
    fragmentName: fragmentOptions.fragmentName,
  })

  if (!shift) return

  cache.writeFragment<Gateway.Shift>({
    id: fragmentOptions.id,
    fragment: fragmentOptions.fragment,
    fragmentName: fragmentOptions.fragmentName,
    data: {
      ...shift,
      unfilledPositions: unfilledPositionsCount,
    },
  })
}

import ArchiveEmploymentTypeMutation from './archiveEmploymentType.graphql'
import CreateEmploymentTypeMutation from './createEmploymentType.graphql'
import DeleteEmploymentTypeMutation from './deleteEmploymentType.graphql'
import UnarchiveEmploymentTypeMutation from './unarchiveEmploymentType.graphql'
import UpdateEmploymentTypeMutation from './updateEmploymentType.graphql'

export {
  ArchiveEmploymentTypeMutation,
  CreateEmploymentTypeMutation,
  DeleteEmploymentTypeMutation,
  UnarchiveEmploymentTypeMutation,
  UpdateEmploymentTypeMutation,
}

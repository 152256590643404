import React, { ChangeEvent, useEffect, useRef } from 'react'

import { Checkbox } from 'components/ui/__v2__/Checkbox'

interface Props {
  isSelected: boolean
  onToggle: (event: ChangeEvent<HTMLInputElement>) => void
  isDisabled: boolean
  indeterminate?: boolean
}

export function IndeterminateCheckbox({
  isSelected,
  onToggle,
  isDisabled,
  indeterminate,
}: Props) {
  const ref = useRef<HTMLInputElement>()

  useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !isSelected && indeterminate
    }
  }, [ref, indeterminate, isSelected])

  return (
    <Checkbox
      // @ts-ignore
      checked={isSelected}
      disabled={isDisabled}
      ref={ref}
      size={18}
      onChange={onToggle}
    />
  )
}

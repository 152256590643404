export const NOTIFICATION_TYPE = {
  all: 'all',
  tradeShift: 'trade',
  cover: 'cover',
  timeOff: 'timeoff',
  cantMake: 'absence',
  role: 'role',
  interview: 'interview',
  jobOffer: 'invitation',
  additional: 'additional',
  announcement: 'announcement',
  comment: 'comment',
  schedule: 'schedule',
  shift: 'shift',
  shiftsLateArrival: 'shift_late_arrival',
  shiftsNoShow: 'shift_no_show',
  availabilityChangeRequest: 'availability_change_request',
  timeEntry: 'time_entry',
  pauseTimer: 'pause_timer',
  employeeRelocationRequest: 'employee_relocation_request',
  temporaryAccessLevel: 'temporary_access_level',
  balanceAdjustment: 'balance_adjustment',
  movingRequest: 'moving_request',
  scheduleDestroyed: 'schedule_destroyed',
} as const

export const NOTIFICATION_SECTIONS = {
  actions: [
    NOTIFICATION_TYPE.tradeShift,
    NOTIFICATION_TYPE.cover,
    NOTIFICATION_TYPE.timeOff,
    NOTIFICATION_TYPE.cantMake,
    NOTIFICATION_TYPE.employeeRelocationRequest,
    NOTIFICATION_TYPE.temporaryAccessLevel,
    NOTIFICATION_TYPE.balanceAdjustment,
    NOTIFICATION_TYPE.movingRequest,
    NOTIFICATION_TYPE.scheduleDestroyed,
  ],
  news: [NOTIFICATION_TYPE.announcement],
  locationRules: [
    NOTIFICATION_TYPE.shiftsLateArrival,
    NOTIFICATION_TYPE.shiftsNoShow,
  ],
  availabilitiesCharge: [NOTIFICATION_TYPE.availabilityChangeRequest],
  all: Object.values(NOTIFICATION_TYPE),
} as const

export const NOTIFICATION_STATUS = {
  requested: 'requested',
  accepted: 'accepted',
  rejected: 'rejected',
  deleted: 'deleted',
} as const

export const NOTIFICATION_STATE = {
  new: 'new',
  processed: 'processed',
} as const

export const NOTIFICATION_LINKS = {
  accept: 'accept',
  reject: 'reject',
  show: 'show',
} as const

import { ApolloClient } from '@apollo/client'
import { PulledFilesByCursorQuery } from 'API/PulledFile/GraphQL/Queries'

export function updatePulledFilesByCursorCache(
  client: ApolloClient<any>,
  {
    variables,
    edges,
    pageInfo,
  }: {
    variables: Gateway.QueryPulledFilesByCursorArgs
    edges: Gateway.PulledFilesByCursor['edges']
    pageInfo: Gateway.PulledFilesByCursor['pageInfo']
  },
) {
  return client.writeQuery({
    query: PulledFilesByCursorQuery,
    variables,
    data: {
      pulledFilesByCursor: {
        __typename: 'pulledFilesByCursor',
        edges,
        pageInfo,
      },
    },
  })
}

import { useCallback, useState } from 'react'

import { DEFAULT_JSON_API_OFFSET_PAGING } from 'API/services/JsonApi'
import { CustomLeave } from 'Types/app'

import isBoolean from 'lodash/isBoolean'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import set from 'lodash/set'

import { denormalize } from 'helpers/redux'

import { useMount } from 'hooks'

import { i18n } from 'i18n'

import { showError } from 'services/API'
import apiCall from 'services/API/coreApi'
import { showToast } from 'services/Toasts'

import { LEAVES_JSON_API_TYPE } from './config'

type LeaveByEmployeeAPIFilters = {
  effectiveDates?: {
    from: string
    to: string
  }
  partial?: boolean
  name?: string
}
export type LazyEmployeeLeavesArgs = {
  employeeId?: string
  filters: Omit<LeaveByEmployeeAPIFilters, 'name'>
  page?: JsonApi.OffsetPagingInput
}

// TODO: Optimize rerenders here, or switch to react-query
export function useLazyEmployeeLeavesByOffset({
  employeeId,
  filters,
  page,
}: LazyEmployeeLeavesArgs) {
  const [fetching, setFetching] = useState(false)
  const [total, setTotal] = useState(0)

  const [leaves, setLeaves] = useState<CustomLeave[]>([])

  const loadLeavesByEmployee = useCallback(
    // eslint-disable-next-line consistent-return
    async (name?: string) => {
      try {
        setFetching(true)
        const result = await apiCall({
          method: 'GET',
          endpoint: `/employees/${employeeId}/custom_leave_days`,
          query: {
            include,
            filter: prepareFilters({ ...filters, name }),
            page: page ?? DEFAULT_JSON_API_OFFSET_PAGING,
          },
        })

        if (!result?.ok) {
          showToast({
            type: 'error',
            content: i18n('availabilityRequests.title'),
            title: i18n('common.somethingWentWrong'),
          })
          return undefined
        }
        const data = result?.payload?.data

        // @ts-ignore
        setTotal(Object.values(data?.meta)[0]?.meta?.recordCount ?? 0)

        const leaveIds = map(data?.[LEAVES_JSON_API_TYPE], 'id')

        const denormalizedLeaves = isEmpty(leaveIds)
          ? []
          : (denormalize(data, LEAVES_JSON_API_TYPE, leaveIds) as CustomLeave[])

        setLeaves(denormalizedLeaves)
        return denormalizedLeaves
      } catch (error) {
        showError(error)
      } finally {
        setFetching(false)
      }
    },
    [employeeId, filters, page],
  )

  useMount(() => {
    employeeId && loadLeavesByEmployee()
  })

  return {
    leaves,
    fetching,
    total,
    loadLeavesByEmployee,
  }
}

const include = [
  'employees.profile',
  'branches',
  'departments',
  'jobs',
  'earning_type',
].join(',')

/** @deprecated rework to a set of utils */
const prepareFilters = (filters: LeaveByEmployeeAPIFilters) => {
  const filter = {}
  if (filters.name) set(filter, 'name', { ilike: filters?.name })
  if (filters.effectiveDates)
    set(filter, 'effective_dates', {
      between: [filters?.effectiveDates?.from, filters?.effectiveDates?.to],
    })
  if (isBoolean(filters.partial))
    set(filter, 'partial', { eq: filters.partial })

  return filter
}

import { useState } from 'react'

import {
  autoPlacement,
  autoUpdate,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react'

// TODO: Don't reuse this, needs proper implementation
export function usePopover() {
  const [open, onOpenChange] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange,
    middleware: [autoPlacement()],
    whileElementsMounted: autoUpdate,
  })
  const click = useClick(context)
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    click,
  ])

  return {
    open,
    refs,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
  }
}

import React from 'react'
import { components, OptionProps } from 'react-select'

import { TimetypeItemContainer } from './styles'
import { TimetypeItem } from './TimetypeItem'

import { TimeTypePickerOption } from '../types'

export function TimetypeOption({
  data,
  ...rest
}: OptionProps<TimeTypePickerOption, true>) {
  return (
    <components.Option data={data} {...rest}>
      <TimetypeItemContainer>
        <TimetypeItem color={data.color} label={data.label} />
      </TimetypeItemContainer>
    </components.Option>
  )
}

import { all, fork, take } from 'redux-saga/effects'

import { LocalStorage } from 'services/LocalStorage'

import { SET_BRANCH } from 'store/actions/viewer'

// TODO: refactor Clock page and remove this saga
export function* reloadAfterSwitchBranch() {
  while (true) {
    const action = yield take(SET_BRANCH)

    LocalStorage.setItem('branchId', action?.payload?.branchId)
    window.location.reload()
  }
}

export default function* root() {
  yield all([fork(reloadAfterSwitchBranch)])
}

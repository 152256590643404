import { useMemo, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { EmployeePickerReturnOption } from 'components/blocks/__v3__'
import { DayPickerOnChangeIntervalProp } from 'components/ui/__v2__/DayPicker/types'

import {
  assignmentsTableParamsFilterToApiFilter,
  assignmentsTableParamsSortingToApiSorting,
} from './adapters'
import {
  AutoSchedulingAssignmentByCursorQueryData,
  AutoSchedulingAssignmentsByCursorQuery,
} from './GraphQL'

type Props = {
  paging: CursorPage
  filter: {
    taskId: string
    shiftStartAt: DayPickerOnChangeIntervalProp
    hasEmployee: boolean
    employee: EmployeePickerReturnOption | null
  }
  sorting: {
    direction: Gateway.SortingDirection
    field: Gateway.AutoSchedulingAssignmentsSortingField
  }
}

export function useAutoSchedulingAssignmentsByCursor({
  paging,
  filter,
  sorting,
}: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })
  const [loadingMore, setLoadingMore] = useState(false)

  const { data, loading, refetch, fetchMore } = useApolloQuery<
    AutoSchedulingAssignmentByCursorQueryData,
    Gateway.QueryAutoSchedulingAssignmentsByCursorArgs
  >(AutoSchedulingAssignmentsByCursorQuery, {
    fetchPolicy: 'network-only',
    variables: {
      sorting: assignmentsTableParamsSortingToApiSorting(sorting),
      filter: assignmentsTableParamsFilterToApiFilter(filter),
      paging: cursorPageToGraphqlPaging(paging),
    },
    onCompleted(data) {
      const newPageMeta = data?.autoSchedulingAssignmentsByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const autoSchedulingAssignments = useMemo(
    () =>
      data?.autoSchedulingAssignmentsByCursor?.edges.map(edge => edge.node) ??
      [],
    [data],
  )

  const loadMore = async (lastCursor: string) => {
    setLoadingMore(true)
    await fetchMore({
      variables: {
        paging: { limit: paging.size, startingAfter: lastCursor },
      },
    })

    setLoadingMore(false)
  }

  return {
    autoSchedulingAssignments,
    loading,
    loadingMore,
    currentPageMeta,
    refetch,
    loadMore,
  }
}

import styled from 'styled-components'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'

import { Flex } from 'components/ui/__v2__/Grid'

type StyleProps = LayoutProps & SpaceProps

export const ErrorText = styled(Flex)<StyleProps>`
  border: 1px solid ${({ theme }) => theme.toasts.primaryColor.error};
  border-radius: ${({ theme }) => theme.space.at(1)}px;
  padding: ${({ theme }) => theme.space.at(1)}px;
  color: ${({ theme }) => theme.toasts.secondaryColor.error};
  background: ${({ theme }) => theme.toasts.primaryColor.error};
  font-family: monospace;
  font-size: 10px;
  line-height: 11px;
  text-align: justify;
  ${space}
  ${layout}
`

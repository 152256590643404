import { areasFilterToGraphql } from 'API/services/utils'

import {
  ShiftSortingField,
  ShiftsPositionCondition,
  ShiftsShiftTagIdsFilterCondition,
  SortingDirection,
} from 'constants/gatewayGraphQL'
import { ShiftSortingKinds } from 'constants/ids'

import Utils from 'services/Utils'

import { ShiftAPIItem, ShiftFilter, ShiftSort } from '../../types'

// TODO: Cover with unit test
export function shiftFilterToAPI(
  stateFilters: ShiftFilter,
): Gateway.ShiftFilter {
  const timeBucketFilter = Utils.TimeBucket.selectedTimeBucketsToFilters({
    timeBucketChildren: stateFilters.timeBucketChildren,
    timeBucketParents: stateFilters.timeBucketParents,
  })

  const apiFilter: Gateway.ShiftFilter = {
    ...areasFilterToGraphql(stateFilters.areas),
    locationId: { in: [stateFilters.areas.locations.id] },

    startAt: {
      between: [stateFilters.startOn.from, stateFilters.startOn.to],
    },

    positionCondition:
      stateFilters.positionCondition || ShiftsPositionCondition.All,

    hasOpenJob: stateFilters.hasOpenJob,

    ...timeBucketFilter,
  }

  if (stateFilters.shiftTags?.length) {
    const ids = stateFilters.shiftTags.map(({ value }) => value.toString())
    if (stateFilters.shiftTagsFilterMode === 'and') {
      apiFilter.tags = {
        ids,
        condition: ShiftsShiftTagIdsFilterCondition.All,
      }
    }

    if (stateFilters.shiftTagsFilterMode === 'or') {
      apiFilter.tags = {
        ids,
        condition: ShiftsShiftTagIdsFilterCondition.Any,
      }
    }
  }

  return apiFilter
}

export function shiftToAPIITem(shift: Gateway.Shift): ShiftAPIItem {
  return {
    ...shift,
    range: {
      start: shift.range.start,
      end: shift.range.end,
    },
  }
}

export function shiftSortToAPI(sorting: ShiftSort): Gateway.ShiftSorting {
  switch (sorting.value) {
    case ShiftSortingKinds.newestFirst:
      return {
        field: ShiftSortingField.StartAt,
        direction: SortingDirection.Asc,
      }

    case ShiftSortingKinds.latestFirst:
      return {
        field: ShiftSortingField.StartAt,
        direction: SortingDirection.Desc,
      }

    case ShiftSortingKinds.nameAscending:
      return {
        field: ShiftSortingField.Name,
        direction: SortingDirection.Asc,
      }

    case ShiftSortingKinds.nameDescending:
      return {
        field: ShiftSortingField.Name,
        direction: SortingDirection.Desc,
      }

    case ShiftSortingKinds.filledFirst:
      return {
        field: ShiftSortingField.Position,
        direction: SortingDirection.Asc,
      }

    case ShiftSortingKinds.unfilledFirst:
    default:
      return {
        field: ShiftSortingField.Position,
        direction: SortingDirection.Desc,
      }
  }
}

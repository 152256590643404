import { DateTime, Duration, Interval, WeekdayNumbers } from 'luxon'

/* create constants because 'luxon' don't work with infinity
   we need infinity to create interval without start/end
*/

export const ONE_HUNDRED_YEAR_DURATION = Duration.fromObject({ years: 100 })
export const TWO_HUNDRED_YEAR_INTERVAL = Interval.fromDateTimes(
  DateTime.now().minus(ONE_HUNDRED_YEAR_DURATION),
  DateTime.now().plus(ONE_HUNDRED_YEAR_DURATION),
)

export const WEEK_DAY_NUMBER = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
} as const

export const WEEK_START_DAY_OFFSET: {
  [key in WeekdayNumbers]: number
} = {
  [WEEK_DAY_NUMBER.SUNDAY]: 1,
  [WEEK_DAY_NUMBER.MONDAY]: 0,
  [WEEK_DAY_NUMBER.TUESDAY]: -1,
  [WEEK_DAY_NUMBER.WEDNESDAY]: -2,
  [WEEK_DAY_NUMBER.THURSDAY]: -3,
  [WEEK_DAY_NUMBER.FRIDAY]: -4,
  [WEEK_DAY_NUMBER.SATURDAY]: -5,
} as const

export const NUMBER_OF_DAYS_IN_WEEK = 7

import { createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import difference from 'lodash/difference'
import get from 'lodash/get'
import union from 'lodash/union'
import xor from 'lodash/xor'

import { SHIFTS_VIEW_SORT_OPTIONS } from 'constants/shifts'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { UPDATE_BRANCH } from 'store/actions/company/branches'
import {
  CLOSE_ASSIGN,
  COPY_PREV_WEEK_DONE,
  CREATE_SHIFT,
  DELETE_SHIFTS,
  DESELECT_ALL_SHIFTS,
  INIT,
  INIT_DONE,
  LOAD_RANGE,
  LOAD_SHIFT_ASSIGNED_EMPLOYEES,
  LOAD_SHIFT_AVAILABLE_EMPLOYEES,
  SELECT_ALL_SHIFTS,
  SET_COPY_PREV_WEEK_ACTIVE,
  SHIFT_WEEK,
  TOGGLE_SHIFT,
  TOGGLE_VIEW_SORT_OPTION,
  UPDATE_VIEW_FILTER_JOBS,
} from 'store/actions/employees/shifts'

const initialState = Immutable({
  error: '',
  shifts: [],
  isCopying: false,
  isShiftsLoading: false,
  isLoading: false,
  isLoaded: false,
  week: null,
  availableEmployees: [],
  isLoadingAvailable: true,
  assignedEmployees: [],
  isLoadingAssignedEmployees: true,
  selectedShiftsIds: [],
  filterViewJobIds: [],
  viewSortOption: SHIFTS_VIEW_SORT_OPTIONS.time,
  viewSortOrder: 'asc',
  holidays: [],
  isLoadingHolidays: false,
})

const handleDeleteShifts = (state, { payload }) => {
  const deletedShifts = get(payload, 'data.shifts', {})
  const deletedShiftIds = Object.keys(deletedShifts)

  return state.merge({
    shifts: Array.from(state.shifts).filter(
      shiftId => !deletedShiftIds.includes(shiftId),
    ),
    selectedShiftsIds: difference(state.selectedShiftsIds, deletedShiftIds),
  })
}

const handleError = (state, action) =>
  state.merge({
    error: action.payload,
    isLoading: false,
    isLoaded: false,
    isShiftsLoading: false,
  })

const handlers = {
  [SHIFT_WEEK]: (state, action) => state.merge({ week: action.payload.week }),

  [INIT]: state => state.merge({ isLoading: true }),

  [CREATE_SHIFT.SUCCESS]: createLoadHandler('shifts', {
    addToState: { isShiftsLoading: false },
  }),

  [LOAD_RANGE.REQUEST]: state => state.merge({ isShiftsLoading: true }),
  [LOAD_RANGE.SUCCESS]: createLoadHandler('shifts', {
    addToState: { isShiftsLoading: false },
  }),
  [LOAD_RANGE.FAILURE]: handleError,

  [DELETE_SHIFTS.SUCCESS]: handleDeleteShifts,

  [LOAD_SHIFT_AVAILABLE_EMPLOYEES.SUCCESS]: createLoadHandler('employees', {
    mapToKey: 'availableEmployees',
    withLoading: false,
    addToState: {
      isLoadingAvailable: false,
    },
  }),

  [CLOSE_ASSIGN]: state =>
    state.merge({
      availableEmployees: [],
      assignedEmployees: [],
      isLoadingAvailable: true,
      isLoadingAssignedEmployees: false,
    }),

  [INIT_DONE]: state =>
    state.merge({
      isLoading: false,
      isLoaded: true,
    }),

  [SET_COPY_PREV_WEEK_ACTIVE]: state => state.merge({ isCopying: true }),

  [COPY_PREV_WEEK_DONE]: state => state.merge({ isCopying: false }),

  [LOAD_SHIFT_ASSIGNED_EMPLOYEES.SUCCESS]: createLoadHandler('employees', {
    mapToKey: 'assignedEmployees',
    withLoading: false,
    addToState: {
      isLoadingAssignedEmployees: false,
    },
  }),

  [TOGGLE_SHIFT]: (state, { payload: { shiftId } }) =>
    state.merge({
      selectedShiftsIds: xor(state.selectedShiftsIds, [shiftId]),
    }),

  [SELECT_ALL_SHIFTS]: (state, { payload: { allShiftsIds } }) =>
    state.merge({
      selectedShiftsIds: union(state.selectedShiftsIds, allShiftsIds),
    }),

  [DESELECT_ALL_SHIFTS]: state => state.merge({ selectedShiftsIds: [] }),

  [UPDATE_VIEW_FILTER_JOBS]: (state, { payload }) =>
    state.merge({
      filterViewJobIds: get(payload, 'jobIds', []),
    }),
  [TOGGLE_VIEW_SORT_OPTION]: (state, { payload }) => {
    const { sortOption } = payload
    const { viewSortOrder: prevViewSortOrder } = state
    let viewSortOrder = prevViewSortOrder
    if (sortOption === state.viewSortOption) {
      viewSortOrder = prevViewSortOrder === 'asc' ? 'desc' : 'asc'
    }
    return state.merge({
      viewSortOption: sortOption,
      viewSortOrder,
    })
  },

  // Need when updating weekStartDay in settings
  [UPDATE_BRANCH.SUCCESS]: state =>
    state.merge({
      week: null,
    }),

  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers)

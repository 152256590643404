import { useApolloMutation } from 'API/services/Apollo'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { UpdateShiftsMutation } from './GraphQL'

export function useUpdateShifts() {
  const t = useI18n<typeof TRANSLATIONS.shifts.toasts>('shifts.toasts')

  const [innerUpdateMany, { loading }] = useApolloMutation<
    MutationData<'updateShifts'>,
    Gateway.MutationUpdateShiftsArgs
  >(UpdateShiftsMutation, {
    silent: true,
  })

  const updateMany = async (input: Gateway.UpdateShiftsInput) => {
    try {
      await innerUpdateMany({
        variables: {
          input,
        },
      })

      showToast({
        type: 'success',
        title: t('updateShifts.success.title'),
        content: t('updateShifts.success.message'),
      })

      return false
    } catch (error) {
      // Temporary solution ( we have 500 status code here, can't bind to the status code)
      // TODO: Remove this after normalizing will be added at microservices.
      const errorMessage = (error as Error).message
      switch (errorMessage) {
        case 'Shifts must be future':
          showToast({
            type: 'error',
            title: t('updateShifts.error.title'),
            content: t('updateShifts.error.messages.shiftsMustBeFuture'),
          })
          break
        case 'Conflict with schedule':
          showToast({
            type: 'error',
            title: t('updateShifts.error.title'),
            content: t('updateShifts.error.messages.conflictWithSchedule'),
          })
          break
        default:
          showToast({
            type: 'error',
            title: t('updateShifts.error.title'),
            content: t('updateShifts.error.message'),
          })
          break
      }

      return true
    }
  }

  return { updateMany, updating: loading }
}

import {
  ATHENS_GRAY_40,
  CAROUSEL_PINK,
  EBONY_CLAY,
  FOAM,
  PICTON_BLUE,
  PROVINCIAL_PINK,
  SMASHED_PUMPKIN,
  TELEMAGENTA,
} from 'theme/__v2__/light/colors'

export const ITEM_KINDS = {
  location: {
    kind: 'location',
    textColor: SMASHED_PUMPKIN,
    backgroundColor: PROVINCIAL_PINK,
  },
  department: {
    kind: 'department',
    textColor: PICTON_BLUE,
    backgroundColor: FOAM,
  },
  role: {
    kind: 'role',
    textColor: TELEMAGENTA,
    backgroundColor: CAROUSEL_PINK,
  },
  clearAll: {
    kind: 'clearAll',
    textColor: EBONY_CLAY,
    backgroundColor: ATHENS_GRAY_40,
  },
}

export const AREAS_ITEM_TYPES = {
  locations: 'locations',
  departments: 'departments',
  jobs: 'jobs',
  employees: 'employees',
  jobsEmployees: 'jobsEmployees',
}

export const LOCATION_DEPARTMENT_DISPLAY_FILTER = {
  employee: 'employee',
  managed: 'managed',
}

export const CASCADE_ORDER = [
  AREAS_ITEM_TYPES.employees,
  AREAS_ITEM_TYPES.jobsEmployees,
  AREAS_ITEM_TYPES.jobs,
  AREAS_ITEM_TYPES.departments,
  AREAS_ITEM_TYPES.locations,
]

export const LOAD_MORE_SIZE = 100
export const BATCH_LOAD_MORE_SIZE = 100

export const AREAS_FILTER_TYPES = {
  branchId: 'branchId',
  departmentId: 'departmentId',
  jobId: 'jobId',
}

export const ManagedAreasPickerDisplay = {
  locations: LOCATION_DEPARTMENT_DISPLAY_FILTER.managed,
  departments: LOCATION_DEPARTMENT_DISPLAY_FILTER.managed,
  jobs: LOCATION_DEPARTMENT_DISPLAY_FILTER.managed,
}

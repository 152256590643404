import React from 'react'

import { FiRefreshCcw } from 'react-icons/fi'

import { Flex, Span } from 'components/ui/__v2__/Grid'
import { Button, IconButton } from 'components/ui/__v3__/Button'

import { useI18n } from 'hooks/useI18n'
import { PaginationVariables } from 'hooks/useOffsetPagination'

import { TableHeaderContainer } from '../styles'
import { TableHeaderProps } from '../types'

export function TableHeader<Query, QueryVariables extends PaginationVariables>({
  name,
  noResetFilter,
  pagination: { handleFilterReset, refetch },
}: TableHeaderProps<Query, QueryVariables>) {
  const t = useI18n('table')

  return (
    <TableHeaderContainer>
      <Span as="h2" fontSize={16}>
        {name}
      </Span>

      <Flex>
        {!noResetFilter && (
          <Button mr={3} secondary onClick={handleFilterReset}>
            {t('resetFilters')}
          </Button>
        )}

        <IconButton secondary onClick={() => refetch()}>
          <FiRefreshCcw />
        </IconButton>
      </Flex>
    </TableHeaderContainer>
  )
}

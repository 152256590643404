import { FiMoreHorizontal } from 'react-icons/fi'

import styled, { css } from 'styled-components'

type ItemContainerProps = {
  highlighted: boolean
}

const highlightedCss = ({ highlighted }: ItemContainerProps) =>
  highlighted &&
  css`
    border-color: ${({ theme }) => theme.colors.SMASHED_PUMPKIN};
    background-color: ${({ theme }) => theme.colors.PROVINCIAL_PINK};
  `

export const ItemContainer = styled.div<ItemContainerProps>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.CHARCOAL_60};
  border-radius: 6px;
  padding: 6px ${({ theme }) => theme.space[2]}px;
  gap: ${({ theme }) => theme.space[3]}px;
  transition: all ease-in-out ${({ theme }) => theme.layout.transition.normal};
  ${highlightedCss}
`
// TODO: create a new Popover component for this
export const PopoverContainer = styled.div`
  background: ${({ theme }) => theme.layout.bg};
  border-radius: ${({ theme }) => theme.space[2]}px;
  box-shadow: ${({ theme }) => theme.tooltip.shadow};
  border-color: ${({ theme }) => theme.layout.border};
  padding: 0 ${({ theme }) => theme.space[2]}px;
  border-style: solid;
  z-index: ${({ theme }) => theme.zIndices.popup};
  overflow: hidden;
`

export const MoreHorizontalIcon = styled(FiMoreHorizontal)`
  cursor: pointer;
`

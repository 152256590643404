import { DateTime, Interval } from 'luxon'
import RRule from 'rrule'

import compact from 'lodash/compact'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import findKey from 'lodash/findKey'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import toNumber from 'lodash/toNumber'

export const WEEK_DAYS = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']

export const REPEATS = {
  DAILY: 'availability.repeats.day',
  WEEKLY: 'availability.repeats.weekly',
  TWO_WEEK: 'availability.repeats.biWeekly',
}

export const getCurrentMonth = currentDate => {
  const currentDateTime = DateTime.fromISO(currentDate)
  const monthStart = currentDateTime.startOf('month')
  const monthEnd = currentDateTime.endOf('month')
  const monthInterval = Interval.fromDateTimes(
    DateTime.fromISO(monthStart),
    DateTime.fromISO(monthEnd),
  )
  return {
    monthStart,
    monthEnd,
    monthInterval,
  }
}

export const isRepeatConditionNotDaily = condition =>
  condition !== findRepeatKey(REPEATS.DAILY)

// TODO: We need to get rid of this shite when refactoring and use RRule and TS properly
export const createRecurrenceString = (
  condition,
  days,
  untilDate,
  timeZone = 'local',
) => {
  let freq = ''
  let interval = 1
  let byweekday =
    days?.length > 0 ? compact(days?.map(dayOfWeek => RRule[dayOfWeek])) : null

  if (condition === 'DAILY') {
    freq = RRule.DAILY
    byweekday = null
  } else if (condition === 'WEEKLY') {
    freq = RRule.WEEKLY
  } else if (condition === 'TWO_WEEK') {
    freq = RRule.WEEKLY
    interval = 2
  }

  const rule = new RRule({
    freq,
    until: DateTime.fromISO(untilDate, { zone: timeZone })
      .endOf('day')
      .toJSDate(),
    interval,
    byweekday,
  })

  return rule.toString().replace('RRULE:', '')
}

export const findRepeatKey = value =>
  findKey(REPEATS, repeat => repeat === value)

const getDayNumberFromISO = date => DateTime.fromISO(date).day

/**
 * @param {string} date ISO date to ignore dST
 * @returns {DateTime} DST-ignored DateTime
 */
export function ignoreDST(date) {
  // In some countries, time moves forward by an hour at the start of
  // the summer months, then back again at the start of winter months.
  // This is known as Daylight Savings Time (DST).
  const dateTime = date instanceof DateTime ? date : DateTime.fromISO(date)

  if (dateTime.isInDST) {
    if (dateTime.month < 6) {
      // DST started
      return dateTime.minus({ hours: 1 })
    }
    // DST ends
    return dateTime.plus({ hours: 1 })
  }

  return dateTime
}

/**
 * If having DST now, but sending non-DST DateTime, then add one hours to DateTime
 * If having non-DST now, but sending DST DateTime, then subtract one hour from DateTime
 *
 * @param {DateTime} dateTime DateTime to ignore DST offset
 * @returns {DateTime} DST-ignored DateTime
 */
export const ignoreDSTInRange = (fromDateTime, toDateTime) => {
  if (fromDateTime.isInDST && !toDateTime.isInDST) {
    return toDateTime.plus({ hours: 1 })
  }

  if (!fromDateTime.isInDST && toDateTime.isInDST) {
    return toDateTime.minus({ hours: 1 })
  }

  return toDateTime
}

export const stringToColor = str => {
  let hash = 0
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff
    colour += `00${value.toString(16)}`.substr(-2)
  }
  return colour
}

export const getAvailabilitiesByDays = (
  currentDate,
  employeeAvailabilities,
  settings,
) => {
  const timezone = get(settings, 'timezone') || 'local'

  const frame = getCalendarFrame(currentDate, [])
  forEach(employeeAvailabilities, availability => {
    const { events, id } = availability
    forEach(events, event => {
      const { startAt } = event

      const key = DateTime.fromISO(startAt).setZone(timezone).toISODate()

      if (Array.isArray(frame[key])) {
        const prevAvailabilities = frame[key]
        const isAlreadyAdded = !!find(
          prevAvailabilities,
          item => item.id === id,
        )
        if (!isAlreadyAdded) {
          frame[key] = [...prevAvailabilities, availability]
        }
      }
    })
  })
  return frame
}

export const getTimeoffsByDays = (currentDate, employeeTimeoffs) => {
  if (isEmpty(employeeTimeoffs)) {
    return []
  }
  const frame = getCalendarFrame(currentDate, [])

  forEach(employeeTimeoffs, timeoff => {
    const { startAt, finishAt } = timeoff
    const currentDateTime = DateTime.fromISO(currentDate)
    const startDateTime = DateTime.fromISO(startAt)
    const finishDateTime = DateTime.fromISO(finishAt)
    if (
      currentDateTime.hasSame(startDateTime, 'month') ||
      currentDateTime.hasSame(finishDateTime, 'month')
    ) {
      const startDay = startDateTime.day
      const finishDay = finishDateTime.day
      let dayDiff = finishDay - startDay + 1
      let key = startAt
      while (dayDiff > 0) {
        if (Array.isArray(frame[key])) {
          const prevTimeoffs = frame[key]
          frame[key] = [...prevTimeoffs, timeoff]
          key = DateTime.fromUSO(key).plus({ days: 1 }).toISODate()
          dayDiff -= 1
        } else {
          dayDiff -= 1
        }
      }
    }
  })
  return frame
}

export const getEmployeeEventsTimesByDay = (dayAvailabilities, dayNumber) =>
  reduce(
    dayAvailabilities,
    (eventsTimes, availability) => {
      const { events } = availability
      const dayEvent = find(
        events,
        event => getDayNumberFromISO(event.startAt) === dayNumber,
      )
      const { startAt, endAt } = dayEvent
      const formatEventTime = time => DateTime.fromISO(time).toFormat('T')
      const eventTime = `${formatEventTime(startAt)}-${formatEventTime(endAt)}`
      eventsTimes = [...eventsTimes, eventTime]
      return eventsTimes
    },
    [],
  )

export const getDaysOfMonth = (currentDate, calendarStartDay = 7) => {
  const { monthStart, monthEnd } = getCurrentMonth(currentDate)
  const currentDateTime = DateTime.fromISO(currentDate)

  // start from Sunday
  let firstWeekDayOfMonth = monthStart.weekday
  firstWeekDayOfMonth =
    firstWeekDayOfMonth === calendarStartDay ? 0 : firstWeekDayOfMonth

  const lastDayOfMonth = monthEnd.day
  const lastDayOfLastMonth = currentDateTime.minus({ months: 1 }).endOf('month')
    .day

  return { firstWeekDayOfMonth, lastDayOfMonth, lastDayOfLastMonth }
}

export const getCalendarFrame = (currentDate, defaultValue = null) => {
  const currentDateTime = DateTime.fromISO(currentDate)

  const { lastDayOfMonth } = getDaysOfMonth(currentDate)

  const calendarFrame = {}

  let dayDate
  let dayIndex = 0

  for (let row = 0; row < 6; row += 1) {
    for (let column = 0; column < 7; column += 1) {
      dayIndex += 1

      const { nextDate } = getNextCalendarDay(
        currentDateTime,
        dayIndex,
        lastDayOfMonth,
      )
      dayDate = nextDate

      calendarFrame[dayDate.toISODate()] = defaultValue
    }
  }

  return calendarFrame
}

export const getNextCalendarDay = (
  currentDateTime,
  dayIndex,
  lastDayOfMonth,
) => {
  const isNextMonth = dayIndex > lastDayOfMonth
  const nextDate = currentDateTime.set({ day: dayIndex })
  return { nextDate, isNextMonth }
}

const findRecurrenceByKey = (recurrence, key) => {
  const recurrenceArray = recurrence.split(';')
  return find(recurrenceArray, item => item.indexOf(key) !== -1)
}

export const parseRecurrenceFeq = recurrence => {
  const KEY = 'FREQ='
  const freqRecurrence = findRecurrenceByKey(recurrence, KEY)
  const recurrenceInterval = getRecurrenceInterval(recurrence)
  let freqKey = freqRecurrence.split('=')[1]
  if (freqKey === findRepeatKey(REPEATS.WEEKLY) && recurrenceInterval === 2) {
    freqKey = findRepeatKey(REPEATS.TWO_WEEK)
  }
  return freqKey
}

export const parseRecurrenceDays = recurrence => {
  const KEY = 'BYDAY='
  const byDayRecurrence = findRecurrenceByKey(recurrence, KEY)
  if (byDayRecurrence) {
    const daysString = byDayRecurrence.split('=')[1]
    return daysString.split(',')
  }
  return []
}

const getRecurrenceInterval = recurrence => {
  const KEY = 'INTERVAL='
  const intervalRecurrence = findRecurrenceByKey(recurrence, KEY)
  if (intervalRecurrence) {
    return toNumber(intervalRecurrence.split('=')[1])
  }
  return null
}

export const VIEW_OPTIONS_KEYS = {
  all: 'all',
  availabilities: 'availabilities',
  preferences: 'preferences',
  timeoffs: 'timeoffs',
  holidays: 'holidays',
}

export const VIEW_OPTIONS = {
  all: 'notifications.all',
  availabilities: 'employees.availabilities',
  preferences: 'availability.preferences',
  timeoffs: 'availability.timeOffs',
  holidays: 'settings.holidaySettings',
}

export const isAvailableEmployeeOnShift = (
  employee,
  shiftStartAt,
  shiftFinishAt,
) => {
  const { availabilities } = employee
  const shiftInterval = Interval.fromDateTimes(
    DateTime.fromISO(shiftStartAt),
    DateTime.fromISO(shiftFinishAt),
  )
  const events = reduce(
    availabilities,
    (items, availability) => [...items, ...get(availability, 'events', [])],
    [],
  )
  return (
    findIndex(events, eventItem => {
      const { endAt: eventEndAt, startAt: eventStartAt } = eventItem
      const eventInterval = Interval.fromDateTimes(
        DateTime.fromISO(eventStartAt),
        DateTime.fromISO(eventEndAt),
      )
      return (
        eventInterval.engulfs(shiftInterval) ||
        eventInterval.equals(shiftInterval)
      )
    }) !== -1
  )
}

export const AMOUNT_FIELDS = {
  minHours: 'minHours',
  maxHours: 'maxHours',
  minShifts: 'minShifts',
  maxShifts: 'maxShifts',
}

export const PREFERENCES_LIMITS = {
  hoursWeek: { min: 0, max: 99 },
  shiftsWeek: { min: 0, max: 99 },
}

export const getPreferencesByDays = (
  currentDate,
  employeePreferences,
  settings,
) => {
  const timezone = get(settings, 'timezone') || 'local'

  const frame = getCalendarFrame(currentDate)

  forEach(employeePreferences, preference => {
    const { startOn, recurrenceUntil, weekDays, recurrence } = preference
    const isTwoWeek =
      typeof recurrence === 'string' && recurrence.indexOf('INTERVAL=2') !== -1
    let startDate = DateTime.fromISO(startOn).setZone(timezone).set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    const endDate = DateTime.fromISO(recurrenceUntil, { zone: timezone })

    // It needs to count if recurrence contains two week interval
    let weekCount = 1
    let dayIndex = 1

    const getDaysDiff = date =>
      endDate.diff(date, ['days', 'hours']).toObject().days
    let daysDiff = getDaysDiff(startDate)
    let isStart = true
    while (daysDiff >= 0 && !Object.is(daysDiff, -0)) {
      const isFinish = daysDiff <= 0
      const isWeekEven = isTwoWeek && weekCount % 2 === 0
      if (!isWeekEven) {
        const dateWeekDay = startDate.weekday === 7 ? 0 : startDate.weekday
        const isRepresentDuration = indexOf(weekDays, dateWeekDay) === -1
        frame[startDate.toISODate()] = {
          ...preference,
          isStart,
          isFinish,
          isRepresentDuration,
        }
      }

      startDate = startDate.plus({ days: 1 })
      daysDiff = getDaysDiff(startDate)
      if (isStart) {
        isStart = false
      }

      if (isTwoWeek) {
        // Get week count to skip each even week
        if (dayIndex % 7 === 0) {
          weekCount += 1
          dayIndex = 0
        }
        dayIndex += 1
      }
    }
  })
  return frame
}

export const KIND = {
  SHORT: 'short',
  EXPANDED: 'expanded',
}

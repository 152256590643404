import React from 'react'
import StyledPropTypes from '@styled-system/prop-types'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import {
  border,
  flexbox,
  layout,
  position,
  space,
  typography,
} from 'styled-system'
import { omit, pick } from '@styled-system/props'
import { themeGet } from '@styled-system/theme-get'

import {
  either,
  propGet,
  when,
} from 'components/ui/__v3__/__utils__/styled-system'

import Helper from './Helper'

const CustomCheckbox = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: var(--size);
  height: var(--size);

  border-radius: 2px;
  border: thin solid ${themeGet('checkbox.border.default')};

  ${when(
    'disabled',
    css`
      background-color: ${themeGet('checkbox.bg.disabled')};
      border-color: ${themeGet('checkbox.border.disabled')};
    `,
  )};

  transition: all 0.17s ease-in-out;

  &:after {
    position: absolute;
    content: '';
    left: calc(var(--size) / 2);
    top: calc(var(--size) / 2);
    width: 0;
    height: 0;
  }

  ${border};
`

const CheckboxContainer = styled.label`
  --size: ${propGet('varSize')}px;
  --border-size: 2px;
  --icon-top: calc(50% - var(--border-size));

  position: relative;
  cursor: ${either('disabled', 'not-allowed', 'pointer')};
  width: var(--size);
  min-height: var(--size);
  display: inline-block;
  flex-shrink: 0;

  ${layout};
  ${space};
`

const HiddenCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  position: absolute;
  opacity: 0;
  width: var(--size);
  height: var(--size);

  &:indeterminate ~ ${CustomCheckbox} {
    background-color: ${either(
      ['disabled', 'inactive'],
      themeGet('checkbox.bg.disabled'),
      themeGet('checkbox.bg.checked'),
      true,
    )};
    border-color: ${either(
      ['disabled', 'inactive'],
      themeGet('checkbox.border.disabled'),
      themeGet('checkbox.border.checked'),
      true,
    )};

    &:after {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: var(--border-size);
      background: ${either(
        ['disabled', 'inactive'],
        themeGet('checkbox.icon.border.disabled'),
        themeGet('checkbox.icon.border.default'),
        true,
      )};
    }
  }

  &:checked ~ ${CustomCheckbox} {
    background-color: ${either(
      ['disabled', 'inactive'],
      themeGet('checkbox.bg.disabled'),
      themeGet('checkbox.bg.checked'),
      true,
    )};
    border-color: ${either(
      ['disabled', 'inactive'],
      themeGet('checkbox.border.disabled'),
      themeGet('checkbox.border.checked'),
      true,
    )};

    &:after {
      left: 50%;
      top: calc(50% - var(--border-size) / 2);
      transform: translate(-50%, -50%) rotate(45deg);
      width: calc(var(--size) / 4);
      height: calc(var(--size) / 2);
      border: solid
        ${either(
          ['disabled', 'inactive'],
          themeGet('checkbox.border.disabled'),
          themeGet('checkbox.icon.border.default'),
          true,
        )};
      border-width: 0 2px 2px 0;
    }
  }

  ${when(
    'disabled',
    css`
      pointer-events: none;
    `,
  )};
`

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: ${themeGet('space.1')}px;
  }

  ${layout};
  ${space};
  ${flexbox};
  ${typography}
  ${position};
`

const Checkbox = React.forwardRef((props, ref) => {
  const {
    disabled,
    className,
    size,
    inactive,
    helper,
    wrapper,
    id,
    ...rest
  } = props

  return (
    <CheckboxContainer
      className={className}
      disabled={disabled}
      inactive={inactive}
      varSize={size}
      {...wrapper}
    >
      <HiddenCheckbox
        disabled={disabled}
        id={id}
        inactive={inactive}
        ref={ref}
        {...omit(rest)}
      />
      <CustomCheckbox disabled={disabled} inactive={inactive} {...pick(rest)} />
      {helper && <Helper>{helper}</Helper>}
    </CheckboxContainer>
  )
})

Checkbox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  helper: PropTypes.node,
  id: PropTypes.string,
  inactive: PropTypes.bool,
  size: PropTypes.number,
  wrapper: PropTypes.shape({
    ...StyledPropTypes.layout,
  }),
}

Checkbox.defaultProps = {
  className: undefined,
  disabled: false,
  id: null,
  inactive: false,
  size: 24,
  helper: null,
  wrapper: {},
}

export default Checkbox

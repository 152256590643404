import {
  createDeleteHandler,
  createFields,
  createReducerHandlers,
} from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { REMOVE_USER_FROM_COMPANY } from 'store/actions/employees/employees'
import {
  CLEAR_STAFF,
  CLEAR_STAFF_GRID,
  LOAD_MORE_STAFF,
  LOAD_MORE_STAFF_GRID,
  LOAD_MORE_STAFF_SILENT,
  LOAD_STAFF,
  LOAD_STAFF_GRID,
  LOAD_STAFF_SILENT,
} from 'store/actions/staff'

const initialState = Immutable({
  ...createFields('staff'),

  ...createFields('staff', 'staffGrid'),
})

const handlers = {
  ...createReducerHandlers('staff', LOAD_STAFF, { withReplace: true }),

  ...createReducerHandlers('staff', LOAD_MORE_STAFF, { withReplace: false }),

  ...createReducerHandlers('staff', LOAD_STAFF_SILENT, {
    withReplace: true,
    withLoading: false,
  }),

  ...createReducerHandlers('staff', LOAD_MORE_STAFF_SILENT, {
    withReplace: false,
    withLoading: false,
  }),

  ...createReducerHandlers('staff', LOAD_STAFF_GRID, {
    withReplace: true,
    mapToKey: 'staffGrid',
  }),

  ...createReducerHandlers('staff', LOAD_MORE_STAFF_GRID, {
    withReplace: false,
    mapToKey: 'staffGrid',
  }),

  [REMOVE_USER_FROM_COMPANY.SUCCESS]: createDeleteHandler('staff'),

  [CLEAR_STAFF]: state => {
    return state.merge({
      staff: [],
      isLoadingStaff: false,
      isLoadedStaff: false,
      pagedStaff: null,
    })
  },

  [CLEAR_STAFF_GRID]: state => {
    return state.merge({
      staffGrid: [],
      isLoadingStaffGrid: false,
      isLoadedStaffGrid: false,
      pagedStaffGrid: null,
    })
  },

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import styled, { css, StyledComponent } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { inputSelectFocusedCss } from 'components/styles'
import { Span } from 'components/ui/__v2__/Grid'
import { when } from 'components/ui/__v3__/__utils__/styled-system'

interface SelectContainerProps {
  open?: boolean
}
export const SelectContainer = styled.div<SelectContainerProps>`
  height: 32px;
  min-width: 138px;
  max-width: 160px;
  padding: 8px;

  box-sizing: border-box;
  cursor: pointer;
  font-weight: normal;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${themeGet('colors.WHITE')};

  border-radius: 4px;
  border: 1px solid ${themeGet('input.border.default')};

  ${when(
    '!open',
    css`
      &:hover {
        border-color: ${themeGet('selects.hover.border.color.default')};
      }
    `,
  )}

  ${when(
    'open',
    css`
      ${inputSelectFocusedCss}
    `,
  )}
`

export const Placeholder = styled.span`
  color: ${themeGet('input.placeholder')};
`
export const SelectedInfoContainer = styled(Span)`
  max-width: 98px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
// ======================================================================================
// Menu
export const MenuContainer = styled.div`
  top: 36px;

  position: absolute;

  background-color: ${themeGet('colors.WHITE')};

  z-index: 100000;

  border-radius: 4px;
  border: 1px solid ${themeGet('input.border.default')};
`

export const OptionsContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
`
interface OptionProps {
  selected?: boolean
}
export const Option = styled.div<OptionProps>`
  padding: 8px;
  cursor: pointer;

  display: flex;
  justify-content: space-between;

  font-weight: normal;

  :hover {
    background-color: ${themeGet('selects.backgroundColor.default')};
  }

  ${when(
    'selected',
    css`
      background-color: ${themeGet('colors.PROVINCIAL_PINK')};
    `,
  )}
`
export const InputContainer = styled.div`
  padding: 8px;
  position: sticky;
  top: 0;

  background-color: ${themeGet('colors.WHITE')};
  border-bottom: 1px solid ${themeGet('input.border.default')};
`
// ======================================================================================
// Icons
const IconContainer = styled.div`
  padding: 2px;

  display: flex;
  align-items: center;
`

export const CheckIconContainer = styled(IconContainer)`
  color: ${themeGet('buttons.icon.border.default')};
`

interface IndicatorIconContainerProps {
  open?: boolean
}
export const IndicatorIconContainer = styled<
  StyledComponent<'div', any, IndicatorIconContainerProps, never>
>(IconContainer)`
  transition: ${themeGet('selects.transition')};

  ${when(
    'open',
    css`
      transform: ${themeGet('selects.transform.rotate')};
    `,
  )}
`

export const ClearIconContainer = styled(IconContainer)`
  color: ${themeGet('buttons.icon.color.secondary')};

  :hover {
    color: ${themeGet('colors.BLACK')};
  }
`

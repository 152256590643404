import { ShiftValidationConflictAPIItem } from 'API/RuleValidation/GraphQL/Fragments/types'

import { CONFLICT_PRIORITY } from 'constants/conflict'
import { ConflictType } from 'constants/gatewayGraphQL'

import { i18n } from 'i18n'

export function getConflictTypeText(conflictType: ConflictType): string {
  return i18n(`conflict.conflictType.${conflictType}`)
}

export function sortConflictsByConflictTypePriority(
  conflicts: ShiftValidationConflictAPIItem[],
) {
  if (conflicts.length === 0) return []

  return [...conflicts].sort((a, b) => {
    return CONFLICT_PRIORITY[a.conflictType] - CONFLICT_PRIORITY[b.conflictType]
  })
}

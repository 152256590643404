import React from 'react'
import { FieldError } from 'react-hook-form'

import { IconError } from 'components/ui/__v3__/Input/Errors'

import { ValidationError } from './styles'

interface Props {
  error: FieldError
}

export function Error({ error }: Props) {
  return (
    <ValidationError>
      <IconError>{error.message ?? ''}</IconError>
    </ValidationError>
  )
}

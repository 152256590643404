import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { Flex } from 'components/ui/__v2__/Grid'
import { InputLabel } from 'components/ui/__v3__'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import {
  ClassifierSourcePicker,
  ClassifierSourcePickerProps,
} from '../ClassifierSourcePicker'

type Props<T extends FieldValues> = ControllerBaseProps<T> &
  LabelProps &
  Omit<ClassifierSourcePickerProps, 'onChange' | 'value' | 'onBlur'>

export function FormClassifierSourcePicker<T extends FieldValues>({
  control,
  name,
  labelText,
  required = false,
  ...rest
}: Props<T>) {
  const {
    field: { onChange, value, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  return (
    <Flex flexDirection="column">
      {labelText && (
        <InputLabel>
          <LabelText labelText={labelText} required={required} />
        </InputLabel>
      )}
      <ClassifierSourcePicker
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        {...rest}
      />
      {error && <Error error={error} />}
    </Flex>
  )
}

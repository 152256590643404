import React, { useContext } from 'react'

export const RolesSectionContext = React.createContext({
  roleManagement: {},
  withWage: undefined,
})

export function useRolesSectionContext() {
  return useContext(RolesSectionContext)
}

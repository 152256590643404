import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Span } from 'components/ui/Grid'

export const Answer = styled(Span)`
  color: ${themeGet('layout.primary')};
`

export const NoAnswer = styled(Span)`
  font-style: italic;
  color: ${themeGet('colors.CHARCOAL_80')};
`

import { useCallback, useEffect } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'

import { DateTime } from 'luxon'

import { first, isNil, last } from 'lodash'

import Utils from 'services/Utils'

import {
  MultipleSliceValueFormState,
  useMultiSliceForm,
  ValidatorProperties,
} from './useMultiSliceForm'

type Props = {
  values: MultipleSliceValueFormState
  defaultValueType?: 'string' | 'checkbox'
  validatorProperties?: ValidatorProperties
}

export function useMultiSliceFormController({
  values,
  defaultValueType = 'string',
  validatorProperties,
}: Props) {
  const {
    control,
    isValidating,
    isSubmitting,
    isValid,
    isDirty,
    trigger,
    innerHandleSubmit,
  } = useMultiSliceForm({
    values,
    validatorProperties,
  })

  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'slices',
  })

  const watched = useWatch({ control, name: 'slices' })
  const currentRangeCoversInfinity =
    isNil(first(watched)?.effectiveDates.start) &&
    isNil(last(watched)?.effectiveDates.end)

  useEffect(() => {
    trigger()
  }, [trigger, watched])

  const innerRemove = (index: number) => {
    remove(index)

    trigger()
  }

  const transformValueByType = useCallback(
    (value?: string) => {
      if (!value) return value

      switch (defaultValueType) {
        // Note: For Checkbox, we want to alternate the string representation of the boolean
        case 'checkbox':
          return Utils.CustomField.alternateCheckboxValue(value)

        default:
          return value
      }
    },
    [defaultValueType],
  )

  const innerInsert = useCallback(
    (index: number) => {
      const insertingAfterItem = watched.at(index)

      if (!insertingAfterItem) return

      const {
        effectiveDates: { end: insertedAfterEndDate },
        value,
      } = insertingAfterItem

      const insertedDefaultValue = transformValueByType(value)

      insert(index + 1, {
        existing: false,
        value: insertedDefaultValue,
        effectiveDates: {
          start: insertedAfterEndDate
            ? DateTime.fromISO(insertedAfterEndDate)
                .plus({ days: 1 })
                .toISODate()
            : undefined,
        },
      })

      trigger()
    },
    [insert, trigger, transformValueByType, watched],
  )

  return {
    isValidating,
    isSubmitting,
    isValid,
    isDirty,
    fields,
    currentRangeCoversInfinity,
    watched,
    control,
    innerHandleSubmit,
    innerRemove,
    innerInsert,
  }
}

import { DateTime } from 'luxon'
import { CustomField } from 'Types/app'
import { test } from 'vest'

import { isEmpty } from 'lodash'

import { CustomCvFieldFieldType } from 'constants/gatewayGraphQL'

import { i18n } from 'i18n'

import { Select } from './Select'

export function getCustomFieldFormFieldName(customField: CustomField): string {
  return customField.id
}

export function getCustomFieldSelectOptions(data: CustomField['data']) {
  return data ? data.options.map(Select.generateOption) : []
}

export function explainEffectiveDates(
  dates:
    | Gateway.Maybe<Gateway.DateTimeRange>
    | Gateway.Maybe<Gateway.DateRange>,
  { relative }: { relative: boolean },
) {
  const { start, end } = dates ?? {}

  const startStr = DateTime.fromISO(
    DateTime.fromISO(start, { zone: 'utc' }).toISODate(),
  ).toLocaleString(DateTime.DATE_SHORT)
  const endStr = DateTime.fromISO(
    DateTime.fromISO(end, { zone: 'utc' }).toISODate(),
  ).toLocaleString(DateTime.DATE_SHORT)

  if (!dates) return i18n('settings.customFieldsPage.effectiveDates.always')
  if (!start && !end)
    return i18n('settings.customFieldsPage.effectiveDates.always')

  if (relative) {
    if (end)
      return i18n(
        'settings.customFieldsPage.effectiveDates.relativeToNow.definedDate',
        {
          date: endStr,
        },
      )

    return i18n(
      'settings.customFieldsPage.effectiveDates.relativeToNow.undefinedDate',
    )
  }

  if (start && !end) {
    return `${startStr} - N/A`
  }

  if (!start && end) {
    return `N/A - ${endStr}`
  }

  if (start && end) {
    return `${startStr} - ${endStr}`
  }

  return i18n('settings.customFieldsPage.effectiveDates.always')
}

export function vestValueValidation({
  fieldName,
  fieldType,
  value,
}: {
  fieldType: Gateway.CustomCvFieldFieldType
  fieldName: string
  value: string
}) {
  if (isEmpty(value)) {
    return undefined
  }

  switch (fieldType) {
    case CustomCvFieldFieldType.Url:
      return test(fieldName, i18n('validation.invalidUrl'), () => {
        return validURL(value)
      })
    default:
      return undefined
  }
}

function validURL(value: string) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator
  return !!pattern.test(value)
}

export function alternateCheckboxValue(
  value: string,
): App.CustomFieldCheckboxValue {
  if (value === 'true') return 'false'
  return 'true'
}

import React from 'react'

import { useTheme } from 'styled-components'

import { Flex, Span } from 'components/ui/__v2__/Grid'

import { i18n } from 'i18n'

import { Badge } from './styles'

type Props = {
  primaryText: string
  secondaryText?: string
  archived?: boolean
}
export function Card({ primaryText, secondaryText, archived }: Props) {
  const theme = useTheme()
  return (
    <>
      <Span>{primaryText}</Span>
      <Flex alignItems="center" justifyContent="space-between">
        {secondaryText && (
          <Span
            color={theme.colors.CHARCOAL_80}
            fontSize="12px"
            lineHeight="18px"
          >
            {secondaryText}
          </Span>
        )}
        {archived && <Badge>{i18n('common.archived')}</Badge>}
      </Flex>
    </>
  )
}

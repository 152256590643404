import { useMemo, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import {
  SortingDirection,
  TimeEntrySortingField,
} from 'constants/gatewayGraphQL'

import {
  TimeEntriesByCursorQuery,
  TimeEntriesByCursorQueryData,
} from './GraphQL'

type Args = {
  paging: CursorPage
  filter: Gateway.TimeEntryFilter
  sorting?: Gateway.TimeEntrySorting[]
}

export function useTimeEntriesByCursor({ paging, filter, sorting }: Args) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, loading, refetch } = useApolloQuery<
    TimeEntriesByCursorQueryData,
    Gateway.QueryTimeEntriesByCursorArgs
  >(TimeEntriesByCursorQuery, {
    variables: {
      sorting: sorting ?? [
        { field: TimeEntrySortingField.Id, direction: SortingDirection.Desc },
      ],
      filter,
      paging: cursorPageToGraphqlPaging(paging),
    },
    fetchPolicy: 'network-only',

    onCompleted(data) {
      const newPageMeta = data.timeEntriesByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const timeEntries = useMemo(
    () => data?.timeEntriesByCursor?.edges.map(edge => edge.node) ?? [],
    [data?.timeEntriesByCursor],
  )

  return {
    timeEntries,
    loading,
    refetch,
    currentPageMeta,
  }
}

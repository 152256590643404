import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import { loadBranchManagers } from 'store/actions/company/managers'
import {
  getBranchManagers,
  getIsLoading,
} from 'store/selectors/company/managers'

import Permissions from './Permissions'

export default connect(
  createStructuredSelector({
    managers: getBranchManagers,
    isLoading: getIsLoading,
  }),
  {
    onLoadManagers: loadBranchManagers,
  },
)(Permissions)

import { API } from 'API'
import { DateTime } from 'luxon'

export function useShiftValidation() {
  const { validate, validating } = API.RuleValidation.validateShift()

  const validateShift = async ({
    employeeIds,
    shift,
  }: {
    employeeIds: string[]
    shift: Gateway.Shift
  }) =>
    validate({
      employeeIds,
      period: {
        start: DateTime.fromISO(shift.range.start).toISODate(),
        end: DateTime.fromISO(shift.range.end).plus({ weeks: 1 }).toISODate(),
      },
      shiftPayload: {
        // TODO: revisit it https://github.com/workaxle/workaxle-desktop/pull/3916#discussion_r1723289740
        id: shift.id,
        range: {
          start: shift.range.start,
          end: shift.range.end,
        },
      },
    })

  return {
    validatingShift: validating,
    validateShift,
  }
}

export type UseShiftValidationReturn = ReturnType<typeof useShiftValidation>

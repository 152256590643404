import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { COMPLETE_REFETCH } from 'store/actions/app'
import { LOG_OUT } from 'store/actions/auth'

const initialState = Immutable({
  rehydrationComplete: false,
  isRefetched: false,
})

const completeRefetch = state =>
  state.merge({
    isRefetched: true,
  })

const handlers = {
  [COMPLETE_REFETCH]: completeRefetch,

  [LOG_OUT]: state => state.merge({ isRefetched: false, isRefetching: false }),
}

export default createReducer(initialState, handlers)

import { COMPONENT_TYPES } from 'helpers/formBuilder'

import CheckboxGroup from './CheckboxGroup'
import LabeledDatePicker from './LabeledDatePicker'
import LabeledFileUpload from './LabeledFileUpload'
import LabeledPostPicture from './LabeledPostPicture'
import LabeledSelect from './LabeledSelect'
import LabeledTextfield from './LabeledTextfield'
import RadioGroup from './RadioGroup'
import SingleCheckbox from './SingleCheckbox'

const componentMapper = {
  [COMPONENT_TYPES.TEXT_FIELD]: LabeledTextfield,
  [COMPONENT_TYPES.RADIO]: RadioGroup,
  [COMPONENT_TYPES.CHECKBOXES]: CheckboxGroup,
  [COMPONENT_TYPES.CHECKBOX]: SingleCheckbox,
  [COMPONENT_TYPES.SELECT]: LabeledSelect,
  [COMPONENT_TYPES.DATE_PICKER]: LabeledDatePicker,
  [COMPONENT_TYPES.FILE_UPLOAD]: LabeledFileUpload,
  [COMPONENT_TYPES.POST_PICTURE]: LabeledPostPicture,
}

export default componentMapper

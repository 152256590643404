import { useApolloMutation } from 'API/services/Apollo'

import { UpdatePayFrequencyMutation } from './GraphQL'

export function useUpdatePayFrequency() {
  const [updatePayFrequency, { loading: updating }] = useApolloMutation<
    MutationData<'updatePayFrequency'>,
    Gateway.MutationUpdatePayFrequencyArgs
  >(UpdatePayFrequencyMutation, {
    translationKey: 'payFrequency.toasts.update',
  })

  const update = async (
    id: string,
    variables: Gateway.UpdatePayFrequencyInput,
  ) => {
    await updatePayFrequency({
      variables: {
        id,
        input: {
          ...variables,
        },
      },
    })
  }

  return { update, updating }
}

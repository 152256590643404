import { UnfilledPositionsQuery } from 'API/Metrics/GraphQL'
import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'

type QueryProps = Pick<QueryHookOptions, 'skip' | 'pollInterval'> | undefined
export function useUnfilledPositions(
  variables: Gateway.QueryUnfilledPositionsArgs,
  queryProps?: QueryProps,
) {
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'unfilledPositions'>,
    Gateway.QueryUnfilledPositionsArgs
  >(UnfilledPositionsQuery, {
    variables,
    fetchPolicy: 'network-only',
    skip: queryProps?.skip,
    pollInterval: queryProps?.pollInterval,
  })

  return {
    unfilledPositions: data?.unfilledPositions || [],
    loading,
    refetch,
  }
}

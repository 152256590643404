import { FiClock } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { layout, space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { inputSelectFocusedCss } from 'components/styles'
import {
  either,
  radius,
  when,
} from 'components/ui/__v3__/__utils__/styled-system'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1; // for safari
  min-width: 120px;
  max-width: 120px;
  min-height: 32px;
  position: relative;

  border-radius: ${radius('normal')};
  border: 1px solid ${themeGet('input.border.default')};

  background-color: ${themeGet('input.bg.default')};
  caret-color: ${themeGet('input.caret')};

  transition: all ease-in-out ${themeGet('layout.transition.fast')};

  &:hover {
    border-color: ${themeGet('colors.MANATEE')};
    ${when(
      'isEdit',
      css`
        border-color: ${themeGet('layout.primary')};
      `,
    )};
    ${when(
      'popoverisVisible',
      css`
        border-color: ${themeGet('layout.primary')};
      `,
    )};
  }

  ${when('isEdit', inputSelectFocusedCss)};
  ${when('popoverisVisible', inputSelectFocusedCss)};

  ${space}
  ${layout}
`

export const Disabled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: #d2d2d266;
  border-radius: 4px;

  cursor: not-allowed;
  touch-action: none;

  z-index: 100;
`

export const TimeInputWrapper = styled.div`
  padding-left: 32px;
  display: flex;
  max-width: 80px;
`

export const TimeInput = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1; // for safari
  background: ${either(
    'disabled',
    themeGet('colors.CHARCOAL_40'),
    themeGet('checkbox.bg.default'),
  )};
  color: ${either(
    'disabled',
    themeGet('layout.lightText'),
    themeGet('colors.TEXT'),
  )};
  border: none;
  border-radius: ${either('disabled', '0', '4px')};
  outline: none;
  min-width: 34px;
`

export const AmPm = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 26px;
  cursor: pointer;
  user-select: none;

  :hover {
    color: ${themeGet('layout.primary')};
  }
`

export const TimeOptions = styled.div`
  text-align: center;
  min-height: 20px;
  max-height: 220px;
  overflow: scroll;
  background-color: ${themeGet('input.bg.default')};
  position: relative;
  z-index: ${themeGet('zIndices.dropdown')};

  ${layout}
`

export const TimeOption = styled.div`
  cursor: ${either('disabled', 'not-allowed', 'pointer')};
  padding: 2px 4px;
  white-space: nowrap;
  background-color: ${themeGet('input.bg.default')};

  color: ${either(
    'disabled',
    '#DCE0E0', // TODO: this color is coming from .DayPicker-Day--disabled, but it's better to change .DayPicker-Day--disabled color intead,
    themeGet('layout.text'),
  )};

  :hover {
    color: ${either(
      'disabled',
      themeGet('layout.lightBorder'),
      themeGet('layout.primary'),
    )};
  }

  ${when(
    'selected',
    css`
      color: ${themeGet('layout.primary')};
    `,
  )};
`

export const Opener = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 4px;
  margin: 0 8px;
  cursor: ${either('disabled', 'default', 'pointer')};

  background: ${either(
    'disabled',
    themeGet('checkbox.bg.disabled'), // TODO: this color is coming from .DayPicker-Day--disabled, but it's better to change .DayPicker-Day--disabled color intead,
    themeGet('checkbox.bg.default'),
  )};
  color: ${either(
    'disabled',
    themeGet('layout.lightText'), // TODO: this color is coming from .DayPicker-Day--disabled, but it's better to change .DayPicker-Day--disabled color intead,
    themeGet('colors.TEXT'),
  )};

  :hover {
    color: ${either('disabled', 'none', themeGet('layout.primary'))};
  }
`

export const ClockIcon = styled(FiClock)`
  color: ${themeGet('layout.primary')};
  width: 16px;
  height: 16px;
`

import { useEffect, useMemo, useState } from 'react'

import { ShiftJobsByCursorNode } from 'API/ShiftJob/GraphQL'

import usePrevious from 'hooks/usePrevious'

export function usePreselectShiftJob(shiftJobs: ShiftJobsByCursorNode[]) {
  const [selectedShiftJobId, setSelectedShiftJobId] = useState<
    string | undefined
  >(undefined)

  const prevSelectedShiftId = usePrevious(selectedShiftJobId)

  // Note: Should only run for the initial selection
  useEffect(() => {
    if (!selectedShiftJobId && !prevSelectedShiftId) {
      setSelectedShiftJobId(shiftJobs[0]?.id)
    }
  }, [prevSelectedShiftId, selectedShiftJobId, shiftJobs])

  const selectedShiftJob = useMemo(
    () => shiftJobs.find(shiftJob => shiftJob.id === selectedShiftJobId),
    [selectedShiftJobId, shiftJobs],
  )

  return [selectedShiftJob, setSelectedShiftJobId] as const
}

import { useState } from 'react'

import { useLocale } from 'hooks'

import { i18n } from 'i18n'

import { AuthService } from 'services/Auth'
import { showToast } from 'services/Toasts'

// TODO: Refactor to proper API call
export function useNLPInference() {
  const locale = useLocale()

  const [inferring, setInferring] = useState(false)
  const [inferSuccess, setInferSuccess] = useState(false)

  async function launchInference(query: string) {
    setInferring(true)
    setInferSuccess(false)

    const response = await fetch(
      'https://api.dev.workaxle.com/v1/nlp/inference',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(await AuthService.getAuthHeaders()),
        },
        body: JSON.stringify({ query, locale }),
      },
    )

    if (response.ok) {
      const data = await response.json()
      setInferring(false)
      setInferSuccess(true)
      return data?.response as string
    }

    showToast({
      type: 'error',
      title: i18n('alertMessages.default.error.title'),
      content: i18n('alertMessages.default.error.message'),
    })
    setInferring(false)
    return null
  }

  return {
    launchInference,
    loadingInference: inferring,
    loadingInferenceSuccess: inferSuccess,
  }
}

import { toastr } from 'react-redux-toastr'

import { all, fork, race, take } from 'redux-saga/effects'

import _ from 'i18n'

import { DISCIPLINARY } from 'store/actions/employees/disciplinary'

function* watchDeleteDisciplinaryRecord() {
  while (true) {
    const { success } = yield race({
      success: take(DISCIPLINARY.DELETE.SUCCESS),
      error: take(DISCIPLINARY.DELETE.FAILURE),
    })
    if (success) {
      toastr.success(_('administrativeRecord.toasts.deleteSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      toastr.error(_('common.somethingWentWrong'), {
        disableCloseButtonFocus: true,
      })
    }
  }
}

function* watchCreateDisciplinaryRecord() {
  while (true) {
    const { success } = yield race({
      success: take(DISCIPLINARY.CREATE.SUCCESS),
      error: take(DISCIPLINARY.CREATE.FAILURE),
    })
    if (success) {
      toastr.success(_('administrativeRecord.toasts.createSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      toastr.error(_('common.somethingWentWrong'), {
        disableCloseButtonFocus: true,
      })
    }
  }
}

function* watchEditDisciplinaryRecord() {
  while (true) {
    const { success } = yield race({
      success: take(DISCIPLINARY.UPDATE.SUCCESS),
      error: take(DISCIPLINARY.UPDATE.FAILURE),
    })
    if (success) {
      toastr.success(_('administrativeRecord.toasts.editSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      toastr.error(_('common.somethingWentWrong'), {
        disableCloseButtonFocus: true,
      })
    }
  }
}

export default function* root() {
  yield all([
    fork(watchDeleteDisciplinaryRecord),
    fork(watchCreateDisciplinaryRecord),
    fork(watchEditDisciplinaryRecord),
  ])
}

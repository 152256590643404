import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import {
  EmploymentTypesByCursorQuery,
  UnarchiveEmploymentTypeMutation,
} from './GraphQL'

export function useUnarchiveEmploymentType() {
  const [innerUnarchive, { loading: unarchiving }] = useApolloMutation<
    MutationData<'unarchiveEmploymentType'>,
    Gateway.MutationUnarchiveEmploymentTypeArgs
  >(UnarchiveEmploymentTypeMutation, {
    refetchQueries: compact([getOperationName(EmploymentTypesByCursorQuery)]),
  })

  const unarchiveEmploymentType = async (id: string) =>
    innerUnarchive({
      variables: {
        id,
      },
    })

  return { unarchiveEmploymentType, unarchiving }
}

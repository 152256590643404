import { ApolloCache } from '@apollo/client'
import { FragmentOptions } from 'API/services/utils'

export function evictRulesets(
  cache: ApolloCache<any>,
  ids?: Array<Gateway.Ruleset['id']>,
) {
  if (!ids?.length) return

  ids.forEach(id => {
    cache.evict({ id: FragmentOptions.Ruleset(id).id })
  })

  cache.gc()
}

export function rulesetStateUpdater(
  cache: ApolloCache<any>,
  id: Gateway.Ruleset['id'],
  state: Gateway.RulesetState,
) {
  const fragmentOptions = FragmentOptions.Ruleset(id)

  const ruleset = cache.readFragment<Gateway.Ruleset>({
    id: fragmentOptions.id,
    fragment: fragmentOptions.fragment,
    fragmentName: fragmentOptions.fragmentName,
  })

  if (!ruleset) return

  cache.writeFragment<Gateway.Ruleset>({
    id: fragmentOptions.id,
    fragment: fragmentOptions.fragment,
    fragmentName: fragmentOptions.fragmentName,
    data: {
      ...ruleset,
      state,
    },
  })
}

import { all, fork, put, take } from 'redux-saga/effects'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import {
  addPost,
  DELETE_FILE,
  editPost,
  removeLocalFile,
  UPLOAD_FILE,
  uploadFile,
} from 'store/actions/news'

function* fileDeleted() {
  while (true) {
    const { payload } = yield take(DELETE_FILE.SUCCESS)

    const post = get(payload, 'updatingPost')
    const isUpdate = get(payload, 'isUpdate')
    const uploadingFile = get(payload, 'uploadingFile')

    if (isUpdate && isEmpty(uploadingFile)) {
      yield put(editPost(post))
    } else if (isUpdate && !isEmpty(uploadingFile)) {
      const { postFile, localPostFile } = uploadingFile
      // Upload File -> Edit Post
      yield put(uploadFile(postFile, localPostFile, post, isUpdate))
    }
  }
}

function* fileUploaded() {
  while (true) {
    const { payload } = yield take(UPLOAD_FILE.SUCCESS)

    const localFiles = get(payload, 'files')
    const localFileId = Object.keys(localFiles)[0]
    const localFile = localFiles[localFileId]

    const uploadedFiles = get(payload, 'data.files')
    const uploadedId = Object.keys(uploadedFiles)[0]
    const uploadedFile = uploadedFiles[uploadedId]

    const post = get(payload, 'post')
    const isEditingPost = get(payload, 'isEditing')
    const branchId = get(payload, 'branchId')

    if (isEditingPost) {
      yield put(editPost(post, uploadedFile.id))
    } else {
      yield put(addPost(post, uploadedFile.id, branchId))
    }

    yield put(removeLocalFile(localFile.id))
  }
}

export default function* root() {
  yield all([fork(fileUploaded), fork(fileDeleted)])
}

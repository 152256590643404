import { useCallback, useState } from 'react'

import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'

import {
  ScheduleSortingField,
  SortingDirection,
} from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

import {
  SchedulesByShiftJobByCursorQuery,
  SchedulesByShiftJobByCursorQueryData,
} from '../GraphQL'

type Props = {
  shiftJobId: string
  paging: Gateway.CursorPaging
}

export function useSchedulesByCursor(
  { shiftJobId, paging }: Props,
  queryOptions: Partial<QueryHookOptions>,
) {
  const [loadingMore, setLoadingMore] = useState(false)

  const { data, loading, refetch, fetchMore, updateQuery } = useApolloQuery<
    SchedulesByShiftJobByCursorQueryData,
    Gateway.QuerySchedulesByCursorArgs
  >(SchedulesByShiftJobByCursorQuery, {
    ...queryOptions,
    variables: {
      filter: { shiftJobId: Utils.GraphQL.eqFilter(shiftJobId) },
      sorting: [
        {
          field: ScheduleSortingField.Id,
          direction: SortingDirection.Desc,
        },
      ],
      paging,
    },
  })

  const schedules = data?.schedulesByCursor?.edges.map(edge => edge.node) ?? []

  const loadMore = useCallback(
    async (lastCursor: string) => {
      setLoadingMore(true)
      await fetchMore({
        variables: {
          paging: { limit: paging.limit, startingAfter: lastCursor },
        },
      })

      setLoadingMore(false)
    },
    [fetchMore, paging.limit],
  )

  return {
    schedules,
    loadingInitial: loading && !loadingMore,
    refetch,
    pageInfo: data?.schedulesByCursor?.pageInfo,
    loadMore,
    loadingMore,
    updateQuery,
  }
}

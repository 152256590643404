import qs from 'qs'

import Utils from 'services/Utils'

import { workersCouncilExportParams } from './types'

export function scheduleExportParamsToQueryString(
  filter: workersCouncilExportParams['filter'],
) {
  return qs.stringify(
    {
      filter: {
        period: Utils.JsonApi.betweenFilter(filter.period, {
          includeEndDate: false,
        }),
      },
    },
    { skipNulls: true, arrayFormat: 'brackets' },
  )
}

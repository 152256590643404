import { Socket } from 'phoenix'
import { eventChannel } from 'redux-saga'
import { all, call, cancel, fork, put, select, take } from 'redux-saga/effects'

import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import noop from 'lodash/noop'
import omitBy from 'lodash/omitBy'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import { chatApi } from 'constants/config'
import {
  MESSAGE_TYPES,
  playIncomingMessageRingtone,
  SYSTEM_MESSAGES,
} from 'constants/groupChat'

import * as routes from 'helpers/routes'

import { AuthService } from 'services/Auth'

import { COMPLETE_REFETCH } from 'store/actions/app'
import { LOG_OUT } from 'store/actions/auth'
import {
  chatMuted,
  chatUnmuted,
  DELETE_MESSAGE,
  EDIT_MESSAGE,
  HOLD_CHAT_CHANNEL,
  leftChat,
  loadMutedChats,
  markChatReaded,
  receiveCreatedChat,
  receiveDeletedMessage,
  receiveMessage,
  receiveSystemMessage,
  SEND_MESSAGE,
  UNHOLD_CHAT_CHANNEL,
  unholdChatChannel,
  updateUnreadCounts,
} from 'store/actions/groupChat'
import {
  getActiveChatId,
  getActiveChatUnreadCount,
  getMutedChatIds,
} from 'store/selectors/groupChat'
import { getLocation } from 'store/selectors/router'
import { getId, getUserSettings } from 'store/selectors/viewer'

const PUSH_TIMEOUT = 10000

const CHAT_ACTIONS = {
  createMessage: 'create-message',
  updateMessage: 'update-message',
  deleteMessage: 'delete-message',
  messageDeleted: 'message-deleted',
}

const USER_ACTIONS = {
  chatCreated: 'chat-created',
  unreadCountsUpdate: 'unread-counts-updated',
  mutedChatsUpdated: 'muted-chats-updated',
  chatMuted: 'chat-muted',
  chatUnmuted: 'chat-unmuted',
  youAdded: 'you-added',
  youDeleted: 'you-deleted',
  messageCreated: 'message-created',
  messageUpdated: 'message-updated',
}

function* createMessagePusher(channel) {
  while (true) {
    const {
      payload: { message, fileId, contentType },
    } = yield take(SEND_MESSAGE)
    const requestPayload = isNull(fileId)
      ? { content: message, contentType: MESSAGE_TYPES.text }
      : { file_id: fileId, contentType }

    const activeChatUnreadCount = yield select(getActiveChatUnreadCount)
    if (!isNull(activeChatUnreadCount)) {
      yield put(markChatReaded())
    }
    channel
      .push(CHAT_ACTIONS.createMessage, { ...requestPayload }, PUSH_TIMEOUT)
      .receive('ok', noop)
      .receive('error', noop)
      .receive('timeout', noop)
  }
}

function* updateMessagePusher(channel) {
  while (true) {
    const {
      payload: { messageId, message },
    } = yield take(EDIT_MESSAGE)
    const requestPayload = { id: messageId, content: message }
    channel
      .push(CHAT_ACTIONS.updateMessage, { ...requestPayload }, PUSH_TIMEOUT)
      .receive('ok', noop)
      .receive('error', noop)
      .receive('timeout', noop)
  }
}

function* deleteMessagePusher(channel) {
  while (true) {
    const {
      payload: { messageId },
    } = yield take(DELETE_MESSAGE)
    const requestPayload = { id: messageId }
    channel
      .push(CHAT_ACTIONS.deleteMessage, { ...requestPayload }, PUSH_TIMEOUT)
      .receive('ok', noop)
      .receive('error', noop)
      .receive('timeout', noop)
  }
}

function* chatChannelListener(chatChannelEmitter) {
  while (true) {
    const { msg } = yield take(chatChannelEmitter)
    if (!isEmpty(msg)) {
      const msgId = toString(get(msg, 'id', ''))
      if (msgId) {
        yield put(receiveDeletedMessage(msgId))
      }
    }
  }
}

function* userChannelListener(receiveUserEmitter) {
  let initCountsUpdate = true
  while (true) {
    const { data, action } = yield take(receiveUserEmitter)
    const { chatSound } = yield select(getUserSettings)
    switch (action) {
      case USER_ACTIONS.chatCreated: {
        if (chatSound) {
          playIncomingMessageRingtone()
        }
        yield put(receiveCreatedChat(data))
        break
      }
      case USER_ACTIONS.unreadCountsUpdate: {
        if (initCountsUpdate) {
          initCountsUpdate = false
        } else {
          const mutedChatIds = yield select(getMutedChatIds)
          const activeChatId = yield select(getActiveChatId)
          const location = yield select(getLocation)
          const unmutedCounts = omitBy(data.messages, (count, chatId) => {
            const isMuted = mutedChatIds.indexOf(toNumber(chatId)) !== -1
            const isOpened =
              chatId === activeChatId && location.pathname === routes.chatPath()
            return isMuted || count === 0 || isOpened
          })
          if (chatSound && !isEmpty(unmutedCounts)) {
            playIncomingMessageRingtone()
          }
        }
        yield put(updateUnreadCounts(data.messages))
        break
      }
      case USER_ACTIONS.mutedChatsUpdated: {
        const chatIds = get(data, 'chatIds', [])
        yield put(loadMutedChats(chatIds))
        break
      }
      case USER_ACTIONS.chatMuted: {
        const chatId = get(data, 'id', '')
        if (chatId) {
          yield put(chatMuted(chatId))
        }
        break
      }
      case USER_ACTIONS.chatUnmuted: {
        const chatId = get(data, 'id', '')
        if (chatId) {
          yield put(chatUnmuted(chatId))
        }
        break
      }
      case USER_ACTIONS.youAdded: {
        const chat = get(data, 'chat', null)
        if (chat) {
          yield put(receiveCreatedChat(chat))
        }
        break
      }
      case USER_ACTIONS.youDeleted: {
        const chatId = toString(get(data, 'chatId', ''))
        if (chatId) {
          const viewerId = yield select(getId)
          yield put(leftChat(chatId, viewerId))
          yield put(unholdChatChannel())
        }
        break
      }
      case USER_ACTIONS.messageUpdated:
      case USER_ACTIONS.messageCreated: {
        const msgUserId = get(data, 'userId', '')
        const viewerId = yield select(getId)
        const isMsgByViewer = msgUserId.toString() === viewerId
        const isMessageUpdated = action === CHAT_ACTIONS.messageUpdated
        const chatId = toString(get(data, 'chatId', ''))
        if (chatId) {
          // System Message example: left/joined chat
          const systemMessage = find(
            SYSTEM_MESSAGES,
            message => message === data.content && data.kind === 'system',
          )
          if (systemMessage) {
            // Is viewer removed from group by another user
            const isViewerLeft =
              systemMessage === SYSTEM_MESSAGES.userLeft && isMsgByViewer
            if (!isViewerLeft) {
              // update users list
              yield put(receiveSystemMessage(data))
            }
          }
          yield put(receiveMessage(data, chatId, isMessageUpdated))
        }
        break
      }
      default:
        break
    }
  }
}

function createChatChannelEmitter(channel) {
  return eventChannel(emitter => {
    channel.on(CHAT_ACTIONS.messageDeleted, msg =>
      emitter({ msg, action: CHAT_ACTIONS.messageDeleted }),
    )

    return () => {
      channel.leave().receive('ok', noop)
    }
  })
}

function createUserChannelEmitter(userChannel) {
  return eventChannel(emitter => {
    forEach(USER_ACTIONS, action => {
      userChannel.on(action, data => emitter({ data, action }))
    })

    return () => {
      userChannel.leave().receive('ok', noop)
    }
  })
}

function* chatChannelHold(socket, chatId, token) {
  const chatChannel = socket.channel(`chat:${chatId}`, { token })
  chatChannel.join().receive('ok', data => data)
  const chatChannelEmitter = yield call(createChatChannelEmitter, chatChannel)
  const chatListenerTask = yield all([
    fork(createMessagePusher, chatChannel),
    fork(updateMessagePusher, chatChannel),
    fork(deleteMessagePusher, chatChannel),
    fork(chatChannelListener, chatChannelEmitter),
  ])

  while (yield take(UNHOLD_CHAT_CHANNEL)) {
    yield cancel(...chatListenerTask)
    chatChannel.leave()
  }
}

function* userChannelHold(socket, viewerId, token) {
  const userChannel = socket.channel(`user:${viewerId}`, { token })
  userChannel.join().receive('ok', data => data)
  const userChannelEmitter = yield call(createUserChannelEmitter, userChannel)
  const userListenerTask = yield all([
    fork(userChannelListener, userChannelEmitter),
  ])

  yield take(LOG_OUT)
  yield cancel(...userListenerTask)
  userChannel.leave()
}

function* createChatListener() {
  const viewerId = yield select(getId)
  const token = yield AuthService.getAccessToken()

  const socket = new Socket(chatApi.listenerUrl, { params: { token } })
  socket.connect()

  yield fork(userChannelHold, socket, viewerId, token)

  while (true) {
    const {
      payload: { chatId },
    } = yield take(HOLD_CHAT_CHANNEL)
    const holdChannelTask = yield fork(chatChannelHold, socket, chatId, token)
    yield take(UNHOLD_CHAT_CHANNEL)
    yield cancel(holdChannelTask)
  }
}

export default function* main() {
  while (yield take(COMPLETE_REFETCH)) {
    const createListenerTask = yield fork(createChatListener)
    yield take(LOG_OUT)
    yield cancel(createListenerTask)
  }
}

import { DateTime, Interval } from 'luxon'

import { fromPairs, random, round } from 'lodash'

import { BI_DEMO_STATES } from 'constants/demandForecastingDemo'

import Utils from 'services/Utils'

import {
  HOLIDAY_PEAKS_INTERVALS,
  PROMOTION_PEAKS_INTERVALS,
  SCHEDULED_RANGE_INTERVAL,
} from '../config'
import { DataByState } from '../types'

const SCHEDULED_FTE_MIN = 2
const SCHEDULED_FTE_MAX = 3

export function randomFTE(min = SCHEDULED_FTE_MIN, max = SCHEDULED_FTE_MAX) {
  return round(random(min, max, true), 1)
}

type GenerateFTEForIntervalArgs = {
  interval: Interval
  holidayAddition: number
  promoAddition: number
  weekendAddition: number
  safetyAddition: number
}

export function generateFTEForInterval({
  interval,
  holidayAddition = 0,
  promoAddition = 0,
  weekendAddition = 0,
  safetyAddition = 0,
}: GenerateFTEForIntervalArgs) {
  const datetimes = Utils.DateTime.datetimeListFromStartEnd({
    start: interval.start,
    end: interval.end,
  })

  return datetimes.map(date => {
    function adjustedRandomFTE() {
      let count = randomFTE() + safetyAddition

      const promo = inList(date, PROMOTION_PEAKS_INTERVALS)
      const holiday = inList(date, HOLIDAY_PEAKS_INTERVALS)
      const weekend = [6, 7].includes(date.weekday)

      if (promo) {
        count += promoAddition
      } else if (holiday) {
        count += holidayAddition
      } else if (weekend) {
        count += weekendAddition
      }
      return count
    }

    return {
      date,
      Cashier: adjustedRandomFTE(),
      Manager: adjustedRandomFTE(),
      Stocker: adjustedRandomFTE(),
    }
  })
}

function inList(iterable: DateTime, list: Interval[]) {
  return Boolean(list.find(interval => interval.contains(iterable)))
}

// export function generateScheduledDataForAllStores() {
//   return fromPairs(
//     BI_DEMO_LOCATIONS.map(store => [
//       store.id,
//       generateFTEForInterval({
//         interval: SCHEDULED_RANGE_INTERVAL,
//         holidayAddition: random(0, 2, false),
//         promoAddition: random(0, 2, false),
//         weekendAddition: random(0, 2, false),
//         safetyAddition: 0,
//       }),
//     ]),
//   )
// }

export function generateScheduledDataForAllStates(): DataByState {
  return fromPairs(
    BI_DEMO_STATES.map(({ state, storeCount }) => [
      state,
      generateFTEForInterval({
        interval: SCHEDULED_RANGE_INTERVAL,
        holidayAddition: 1 * storeCount,
        promoAddition: 0 * storeCount,
        weekendAddition: 0.25 * storeCount,
        safetyAddition: 0,
      }),
    ]),
  )
}

export function generateForecastedForAllStates({
  interval,
  holidayAddition,
  promoAddition,
  weekendAddition,
  safetyAddition,
}: GenerateFTEForIntervalArgs): DataByState {
  return fromPairs(
    BI_DEMO_STATES.map(({ state, storeCount }) => [
      state,
      generateFTEForInterval({
        interval,
        holidayAddition: holidayAddition * storeCount,
        promoAddition: promoAddition * storeCount,
        weekendAddition: weekendAddition * storeCount,
        safetyAddition: safetyAddition * storeCount,
      }),
    ]),
  )
}

import styled, { css } from 'styled-components'
import { height, HeightProps } from 'styled-system'

import { BODY_CELL_HEIGHT, HEADER_CELL_HEIGHT } from './constants'

type CellProps = {
  width?: number
}

const widthCss = ({ width }: CellProps) =>
  width &&
  css`
    min-width: ${width}px;
    max-width: ${width}px;
  `

const commonCellCss = () => css`
  padding: ${({ theme }) => theme.space[1]}px ${({ theme }) => theme.space[2]}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.GRAY_200};
  border-right: 1px solid ${({ theme }) => theme.colors.GRAY_200};

  &:last-child {
    border-right: none;
  }
`

export const TableContainer = styled.div<HeightProps>`
  ${height}
`

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;

  tr {
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`

export const TableFoot = styled.tfoot`
  position: relative;
`

export const HeaderTr = styled.tr`
  background-color: ${({ theme }) => theme.colors.WHITE};
`

export const HeaderCell = styled.th<CellProps>`
  text-align: left;
  height: ${HEADER_CELL_HEIGHT}px;

  ${commonCellCss}
  ${widthCss}
`

export const BodyCell = styled.td<CellProps>`
  height: ${BODY_CELL_HEIGHT}px;

  ${commonCellCss}
  ${widthCss}
`

export const FooterCell = styled.tr`
  position: relative;
`

export const FooterLoaderContainer = styled.tr`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

import {
  createRelationAddHandler,
  createRelationDeleteHandler,
} from 'redux-json-api-handlers'

import { createReducer } from 'helpers/redux'

import { CREATE_JOBS_TAG, DELETE_JOBS_TAGS } from 'store/actions/company/jobs'

const initialState = {}

const handlers = {
  [CREATE_JOBS_TAG.SUCCESS]: createRelationAddHandler('jobsTags', 'job'),
  [DELETE_JOBS_TAGS.SUCCESS]: createRelationDeleteHandler('jobsTags'),
}

export default createReducer(initialState, handlers)

import {
  AiFillCheckCircle,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
} from 'react-icons/ai'
import { GrRevert } from 'react-icons/gr'

import styled from 'styled-components'

import { BaseButton } from 'components/ui/__v3__/Button/BaseButton'

export const UnassignIcon = styled(AiOutlineMinusCircle)`
  color: ${({ theme }) => theme.toasts.secondaryColor.error};
`

export const UndoIcon = GrRevert

export const AssignIcon = styled(AiOutlinePlusCircle)`
  color: ${({ theme }) => theme.toasts.secondaryColor.success};
`

export const AssignedIcon = styled(AiFillCheckCircle)`
  color: ${({ theme }) => theme.toasts.secondaryColor.success};
`
export const IconButton = styled(BaseButton)`
  background: none;

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    & > svg {
      color: ${({ theme }) => theme.layout.disabled} !important;
    }
  }
`

import React, { useMemo } from 'react'

import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from 'react-icons/fi'

import { Span } from 'components/ui/__v2__/Grid'
import { IconButton } from 'components/ui/__v3__/Button'
import { Select } from 'components/ui/__v3__/Select'

import {
  generateRowsPerPageSelectOption,
  PageSelectOption,
} from 'helpers/pagination'

import { useI18n } from 'hooks/useI18n'

import { PageSizeSelect } from './components'
import { PaginationContainer } from './styles'

type Props = {
  total: number
  limit: number
  offset: number

  handleLimitChange: (limit: number) => void
  handleOffsetChange: (offset: number) => void

  fixedPageSize?: boolean
}

export function OffsetPagination({
  total,
  limit,
  offset,

  handleLimitChange,
  handleOffsetChange,

  fixedPageSize = false, // TODO - remove this prop after IntegrationEvents Pagination is fixed
}: Props) {
  const t = useI18n('table')

  const totalPages = Math.ceil(total / limit)
  const currentPage = offset / limit + 1
  const disabledPrevPage = offset === 0
  const disabledNextPage = currentPage >= totalPages

  const PAGE_SELECT_OPTIONS = useMemo(
    () =>
      new Array(totalPages)
        .fill(1)
        .map((n, i) => generateRowsPerPageSelectOption(n + i)),
    [totalPages],
  )

  const handlePageSelect = (option: PageSelectOption) =>
    handleOffsetChange((option.value - 1) * limit)

  const handleFirstPageClick = () => handleOffsetChange(0)
  const handleNextPageClick = () => handleOffsetChange(offset + limit)
  const handlePrevPageClick = () => handleOffsetChange(offset - limit)
  const handleLastPageClick = () => handleOffsetChange(limit * (totalPages - 1))

  const endRangeText = offset + limit
  const rangeInfoText = `${total ? offset + 1 : total} - ${
    total > endRangeText ? endRangeText : total
  } ${t('of')} ${total}`

  return (
    <PaginationContainer>
      <Span mr={3}>{t('page')}</Span>
      <div>
        {/* TODO: Update Select and remove div and ignore */}
        <Select
          // @ts-ignore
          isClearable={false}
          options={PAGE_SELECT_OPTIONS}
          searchable
          value={generateRowsPerPageSelectOption(currentPage)}
          width={62}
          withPortal
          onChange={handlePageSelect}
        />
      </div>

      <Span ml={3} mr={4}>
        {rangeInfoText}
      </Span>

      <IconButton
        disabled={disabledPrevPage}
        mr={1}
        secondary
        onClick={handleFirstPageClick}
      >
        <FiChevronsLeft />
      </IconButton>

      <IconButton
        disabled={disabledPrevPage}
        mr={1}
        secondary
        onClick={handlePrevPageClick}
      >
        <FiChevronLeft />
      </IconButton>

      <IconButton
        disabled={disabledNextPage}
        mr={1}
        secondary
        onClick={handleNextPageClick}
      >
        <FiChevronRight />
      </IconButton>

      <IconButton
        disabled={disabledNextPage}
        mr={3}
        secondary
        onClick={handleLastPageClick}
      >
        <FiChevronsRight />
      </IconButton>

      {/* TODO - remove this after IntegrationEvents Pagination is fixed */}
      {!fixedPageSize && (
        <>
          <Span mr={3}>{t('rowsPerPage')}</Span>
          <PageSizeSelect size={limit} onSizeChange={handleLimitChange} />
        </>
      )}
    </PaginationContainer>
  )
}

import { ShiftAPIItem } from 'API'
import { DateTime } from 'luxon'

import { getSecondsDiff } from 'helpers/date'

import Utils from 'services/Utils'

import { ShiftMoveModalFormState } from '../types'

export function mapFormDataToServerData({
  values,
  timezone,
}: {
  values: ShiftMoveModalFormState
  timezone: string
}) {
  const scheduleIds = Array.from(values.selectedScheduleIds)

  const isOvernightShift = values.startTime >= values.endTime
  const startAt = DateTime.fromISO(values.date)
    .setZone(timezone, { keepLocalTime: true })
    .plus({
      seconds: values.startTime,
    })
    .toUTC()
    .toISO()
  const endAt = DateTime.fromISO(values.date)
    .setZone(timezone, { keepLocalTime: true })
    .plus({
      seconds: values.endTime,
      days: isOvernightShift ? 1 : 0,
    })
    .toUTC()
    .toISO()

  return {
    scheduleIds,
    startAt,
    endAt,
    reduceQuantity: values.reduceQuantity,
  }
}

export function mapServerDataToFormData(shift: ShiftAPIItem) {
  const startAt = Utils.Shift.getStart(shift)
  const finishAt = Utils.Shift.getEnd(shift)

  const startDay = startAt.startOf('day')
  const finishDay = finishAt.startOf('day')

  return {
    shiftJobIds: shift.shiftJobs.map(shiftJob => shiftJob.id),
    defaultDate: startAt.startOf('day').toISODate(),
    defaultStartTime: getSecondsDiff(startAt, startDay),
    defaultEndTime: getSecondsDiff(finishAt, finishDay),
  }
}

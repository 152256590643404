import { StoreonModule } from 'storeon'

import { INIT } from './constants'

export const COPY_SHIFTS_KEY = 'copyShifts'
export const COPY_SHIFTS_SET = 'copyShifts/set'
export const COPY_SHIFTS_UNSET = 'copyShifts/unset'

export interface CopyShiftsState {
  [COPY_SHIFTS_KEY]: string | undefined
}

export interface CopyShiftsPayload {
  backgroundTaskId: string
}

export interface CopyShiftsEvents {
  [COPY_SHIFTS_SET]: Pick<CopyShiftsPayload, 'backgroundTaskId'>
  [COPY_SHIFTS_UNSET]: never
}

const INITIAL_STATE: CopyShiftsState = {
  [COPY_SHIFTS_KEY]: undefined,
}

export const copyShiftsModule: StoreonModule<
  CopyShiftsState,
  CopyShiftsEvents
> = store => {
  store.on(INIT, () => ({ ...INITIAL_STATE }))
  store.on(COPY_SHIFTS_SET, (state, { backgroundTaskId }) => ({
    ...state,
    [COPY_SHIFTS_KEY]: backgroundTaskId,
  }))
  store.on(COPY_SHIFTS_UNSET, state => ({
    ...state,
    [COPY_SHIFTS_KEY]: undefined,
  }))
}

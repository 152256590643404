import React from 'react'

import { ShiftValidationRuleAPIItem } from 'API'

import { ConflictType } from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

type Props = {
  conflictType: ConflictType
  rule: ShiftValidationRuleAPIItem
}

// TODO: add common i18n for this component & RuleRenderer->ShiftRestTime
export function ConsecutiveDays({ conflictType, rule }: Props) {
  return (
    <>
      If employees are assigned to shifts on{' '}
      <b>{rule.parameters.consecutiveDays?.fromDays} days</b> invoke a{' '}
      <b>{Utils.Conflict.getConflictTypeText(conflictType)}.</b>
    </>
  )
}

import { useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import {
  ScheduleSortingField,
  SortingDirection,
} from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

import {
  SchedulesShiftAssignmentsQuery,
  SchedulesShiftAssignmentsQueryData,
} from '../GraphQL'

type Props = {
  shiftJobIds: string[]
  paging: CursorPage
}

export function useSchedulesShiftAssignments({ shiftJobIds, paging }: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })
  const [schedulesLoadingMore, setSchedulesLoadingMore] = useState(false)

  const { data, loading, fetchMore } = useApolloQuery<
    SchedulesShiftAssignmentsQueryData,
    Gateway.QuerySchedulesByCursorArgs
  >(SchedulesShiftAssignmentsQuery, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      filter: { shiftJobId: Utils.GraphQL.inFilter(shiftJobIds) },
      sorting: [
        {
          field: ScheduleSortingField.Id,
          direction: SortingDirection.Desc,
        },
      ],
      paging: cursorPageToGraphqlPaging(paging),
    },
    onCompleted(data) {
      const newPageMeta = data.schedulesByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const schedules = data?.schedulesByCursor?.edges.map(edge => edge.node) ?? []

  const schedulesLoadMore = async () => {
    setSchedulesLoadingMore(true)

    await fetchMore({
      variables: {
        paging: {
          limit: paging.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })

    setSchedulesLoadingMore(false)
  }

  return {
    schedules,
    schedulesLoading: loading && !schedulesLoadingMore,
    schedulesPageMeta: currentPageMeta,
    schedulesLoadingMore,
    schedulesLoadMore,
  }
}

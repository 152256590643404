import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import {
  cursorPageToJsonApiPage,
  getCursorPageMeta,
  isCursorPageMetaChanged,
  transformResponseToEntities,
} from 'API/services/utils'

import { TimeCardsQueryParams } from 'pages/EmployerApp/TimeCards'

import { TIME_CARD_JSON_API_TYPE, TimeCardQueryKeys } from './constants'
import { timecardsQuery, TimecardsQueryEntityReturn } from './Json'

export type UseTimeCardsByOffsetArgs = {
  filters: TimeCardsQueryParams['filters']
  sort: JsonApi.TimeCardsSorting
  page: JsonApi.CursorPagingInput
}
/** @deprecated */
export function useTimeCardsByCursor({
  filters,
  sort,
  page,
}: UseTimeCardsByOffsetArgs) {
  const [currentPageMeta, setCurrentPageMeta] = useState<JsonApi.CursorPaging>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })
  const { data, isFetching, error, refetch } = useQuery({
    queryKey: TimeCardQueryKeys.byCursor(page, sort, filters),
    queryFn: () =>
      timecardsQuery({
        filters,
        sort,
        page: cursorPageToJsonApiPage(page),
      }),
    select: (response: JsonApi.Response) => {
      const {
        entities,
      } = transformResponseToEntities<TimecardsQueryEntityReturn>({
        response,
        jsonApiType: TIME_CARD_JSON_API_TYPE,
      })

      const newPageMeta = getCursorPageMeta(response)
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) setCurrentPageMeta(newPageMeta)

      return entities
    },
    structuralSharing: false,
    refetchOnWindowFocus: false,
  })

  return {
    timecards: data ?? [],
    loading: isFetching,
    timecardsError: error,
    refetchTimecards: refetch,
    currentPageMeta,
  }
}

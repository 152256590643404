export const GRAY_100 = '#fafafa'
export const GRAY_200 = '#f2f2f2'
export const GRAY_300 = '#f4f4f4'
export const GRAY_400 = '#ececec'
export const GRAY_500 = '#dedede'
export const GRAY_550 = '#dbdbdb'
export const GRAY_600 = '#b8b8b8'
export const GRAY_700 = '#888888'
export const GRAY_800 = '#4d4d4d'
export const GRAY_900 = '#3e3e3e'
export const GRAY = '#868585'
export const LIGHT_GRAY = '#565656'
export const LIGHTER_GRAY = '#979797'
export const DUSTY_GRAY = '#9c9c9c'
export const WARM_GRAY = '#7b7b7b'
export const WILD_SAND = '#f6f6f6'
export const GRAYISH_BROWN = '#4c4c4c'
export const WHITE_FIVE = '#d8d8d8'

export const SHADOW = 'rgba(0, 0, 0, 0.2)'
export const PANEL_SHADOW = 'rgba(89, 88, 88, 0.3)'

export const BLACK = '#000000'
export const WHITE = '#ffffff'
export const CONCRETE = '#f3f3f3'
export const MINE = '#2f2f2f'
export const BUNTING = '#18234c'
export const BOULDER = '#767676'
export const DODGER = '#48beff'
export const CHILL = '#119da4'
export const LIMA = '#7ed321'
export const ROYAL_BLUE = '#2668E1'
export const ROYAL = '#516bf0'
export const PICTON_BLUE = '#36B9E9'
export const IRON = '#dadbdc'

export const ALABASTER = '#f7f7f7'
export const SILVER = '#c1c1c1'
export const SILVER_LIGHT = '#c5c5c5'
export const SILVER_CHALICE = '#a4a2a2'
export const SILVER_SAND = '#bcbdbe'
export const SCORPION_LIGHT = '#606060'
export const SCORPION = '#575757'
export const SANDY_BROWN = '#f39c6b'

export const ROUGE = '#a23b72'
export const ALTO = '#d7d7d7'
export const LIGHT_ALTO = 'rgba(215, 215, 215, 0.5)'
export const TOREA = '#0e34a0'
export const TUNDORA = '#4D4C4C'
export const TUNDORA_DARK = '#414141'
export const LIGHT_TUNDORA = '#4E4E4E'
export const TERRACOTTA = '#de7c54'
export const TAPESTRY = '#9B507E'

export const MERCURY = '#e3e3e3'
export const FLAMINGO = '#f26430'
export const BITTERSWEET = '#ff5e5e'
export const EARLS_GREEN = '#BCB837'
export const MISCHKA = '#dcdee6'
export const NEVADA = '#6d6e72'

export const ICONS_COLOR = SCORPION
export const HOVER_ICONS_BG = DODGER

export const APP_BG = '#e6e7ee'
export const DARK_BG = BUNTING

export const CONIFER = '#91dc5a'
export const MONZA = '#d80027'

// Text
export const DEFAULT_TEXT = MINE
export const DARK_TEXT = SCORPION
export const LIGHT_TEXT = BOULDER
export const HOVER_LIGHT_TEXT = TOREA
export const WARNING_TEXT = FLAMINGO

// Section
export const TABLE_BORDER = '#c7c7c7'

// Button
export const BUTTON_DODGER = DODGER
export const BUTTON_DANGER = ROUGE
export const BUTTON_DEFAULT = GRAY
export const BUTTON_SUCCESS = CHILL
export const BUTTON_ALT = TOREA

export const BUTTON_PINK = '#f7567c'
export const BUTTON_GREEN = '#36de84'

export const CLEAR = 'rgba(255, 255, 255, 0.1)'
export const OVERLAY = 'rgba(215, 215, 215, 0.71)'

export const HEROTITLE = '#f37574'

export const CIRCLE_ICON_HOVER = HOVER_LIGHT_TEXT

// Input
export const INPUT_BORDER = ALTO
export const INPUT_PLACEHOLDER = SILVER_CHALICE
export const INPUT_ACTIVE = DODGER
export const INPUT_ERROR = '#d0021b'
export const INPUT_WARNING = '#f89b6a'

export const NEWS_ANNOUNCEMENT_BG = SANDY_BROWN
export const NEWS_SHADOW = '0 4px 4px 1px rgba(0, 0, 0, 0.11)'

// Nav Columns
export const NAV_COLUMNS_BG = '#f7f8fa'
export const NAV_COLUMNS_BG_SECOND = WHITE
export const NAV_COLUMNS_ANGLE_COLOR = MINE
export const NAV_EDIT_ITEM_BG = TOREA
export const NAV_EDIT_ITEM_BG_SECOND = DODGER

// Schedule
export const SCHEDULE_BORDER_DARK = ALTO
export const SCHEDULE_BORDER_LIGHT = '#f4efef'

// Colors from guide
export const ONBOARDING_LIGHT_GREY = SCHEDULE_BORDER_LIGHT
export const ONBOARDING_GREY = '#969696'

// Colors from design
export const ONBOARDING_HINT_TEXT = '#606060'
export const ONBOARDING_TITLE = '#7c7c7c'
export const ONBOARDING_LABEL = '#666666'

export const ONBOARDING_INPUT_BORDER = '#dad7d7'
export const ONBOARDING_INPUT_PLACEHOLDER = '#c8c5c5'
export const ONBOARDING_CONTENT = '#eeeeee'
export const ONBOARDING_HR = GRAY_200
export const ONBOARDING_PHOTO_BORDER = LIGHTER_GRAY

// Chat
export const CONVERSATION_BG = ' #f7f8fa'
export const CONVERSATION_RIGHT_BG = '#19275e'
export const CONVERSATION_EDIT_BG = '#19275e'
export const CONVERSATION_DELETE_OVERLAY = 'rgba(42, 68, 104, 0.78)'

// SCROLLBAR
export const SCROLLBAR = WHITE
export const SCROLLBAR_THUMB = ALTO

// SWITCH
export const SWITCH_TRACK = 'rgba(34, 31, 31, 0.26)'
export const SWITCH_ACTIVE = '#009688'

// LEFT TAB PANEL
export const TAB_ACTIVE_BG = '#060c34'
export const TABS_BG = 'linear-gradient(to bottom, #18234c, #192b6e)'

// MAP
export const IN_POINT = '#49beff'
export const OUT_POINT = '#f7567c'

export const PALETTE = {
  yellow: '#ffe94d',
  orange: '#f7a959',
  red: '#de6f6f',
  pink: '#e997d3',
  purple: '#a797e8',
  blue: '#97cce8',
  green: '#9fe897',
  black: '#9b9b9b',
  grey: '#e0e0e0',
  white: '#ffffff',
  lightBlue: '#50d1dd',
}

export const MINE_SHAFT = '#323232'
export const BISCAY = '#182658'
export const TURQUOISE = '#2EC4B6'
export const ATHENS_GRAY = '#f6f6f9'
export const GALLERY = '#f0f0f0'

export const EMPEROR = '#535353'
export const AZURE_RADIANCE = '#007EFF'
export const BOMBAY = '#acadb2'
export const SEA_SHELL = '#f1f1f1'
export const DARK_YELLOW_ORANGE = '#ef542b'
export const MALIBU = '#67b1ff'
export const DODGER_BLUE = '#3398ff'
export const SHARK = '#1d1e1f'
export const SILVER_CHALICE_LIGHT = '#A5A5A5'
export const ROLLING_STONE = '#777879'

export default {
  IN_POINT,
  OUT_POINT,
  APP_BG,
  BLACK,
  BUNTING,
  BITTERSWEET,
  WHITE,
  CONCRETE,
  EARLS_GREEN,
  MISCHKA,
  NEVADA,
  MINE,
  WHITE_FIVE,
  WILD_SAND,
  SHADOW,
  PANEL_SHADOW,
  ROYAL_BLUE,
  TUNDORA,
  ICONS_COLOR,
  HOVER_ICONS_BG,
  DARK_BG,
  SCORPION_LIGHT,
  MERCURY,
  DODGER,
  GRAY,
  ALABASTER,
  SILVER,
  SILVER_LIGHT,
  SILVER_CHALICE,
  SILVER_SAND,
  TABS_BG,
  DEFAULT_TEXT,
  LIGHT_TEXT,
  DARK_TEXT,
  HOVER_LIGHT_TEXT,
  WARNING_TEXT,
  ROUGE,
  CHILL,
  LIMA,
  ALTO,
  LIGHT_ALTO,
  TOREA,
  TERRACOTTA,
  FLAMINGO,
  TABLE_BORDER,
  BUTTON_DODGER,
  BUTTON_DANGER,
  BUTTON_DEFAULT,
  BUTTON_SUCCESS,
  BUTTON_PINK,
  BUTTON_ALT,
  BUTTON_GREEN,
  CLEAR,
  HEROTITLE,
  OVERLAY,
  CIRCLE_ICON_HOVER,
  INPUT_BORDER,
  INPUT_PLACEHOLDER,
  INPUT_ACTIVE,
  INPUT_ERROR,
  INPUT_WARNING,
  NEWS_ANNOUNCEMENT_BG,
  NEWS_SHADOW,
  NAV_COLUMNS_BG,
  NAV_COLUMNS_BG_SECOND,
  NAV_COLUMNS_ANGLE_COLOR,
  NAV_EDIT_ITEM_BG,
  NAV_EDIT_ITEM_BG_SECOND,
  SCHEDULE_BORDER_DARK,
  SCHEDULE_BORDER_LIGHT,
  ONBOARDING_LIGHT_GREY,
  ONBOARDING_GREY,
  ONBOARDING_HINT_TEXT,
  ONBOARDING_TITLE,
  ONBOARDING_LABEL,
  ONBOARDING_INPUT_BORDER,
  ONBOARDING_INPUT_PLACEHOLDER,
  ONBOARDING_CONTENT,
  ONBOARDING_HR,
  ONBOARDING_PHOTO_BORDER,
  CONVERSATION_BG,
  CONVERSATION_RIGHT_BG,
  CONVERSATION_EDIT_BG,
  CONVERSATION_DELETE_OVERLAY,
  SANDY_BROWN,
  SCROLLBAR,
  SCROLLBAR_THUMB,
  SWITCH_TRACK,
  SWITCH_ACTIVE,
  TAB_ACTIVE_BG,
  GRAY_100,
  GRAY_200,
  GRAY_300,
  GRAY_400,
  GRAY_500,
  GRAY_550,
  GRAY_600,
  GRAY_700,
  GRAY_800,
  GRAY_900,
  WARM_GRAY,
  GRAYISH_BROWN,
  LIGHT_GRAY,
  LIGHTER_GRAY,
  DUSTY_GRAY,
  PALETTE,
  LIGHT_TUNDORA,
  PICTON_BLUE,
  IRON,
  TAPESTRY,
  CONIFER,
  MONZA,
  TUNDORA_DARK,
  MINE_SHAFT,
  BISCAY,
  TURQUOISE,
  ATHENS_GRAY,
  GALLERY,
  ROYAL,
  EMPEROR,
  AZURE_RADIANCE,
  BOMBAY,
  SEA_SHELL,
  DARK_YELLOW_ORANGE,
  MALIBU,
  DODGER_BLUE,
  SHARK,
  SILVER_CHALICE_LIGHT,
  ROLLING_STONE,
}

import I18n from 'i18n-js'
import { useStoreon } from 'storeon/react'

import {
  LOCALE_KEY,
  LocaleState,
  SET_LOCALE_EVENT,
} from 'services/StoreOn/Locale/locale'

export function useSetLocale() {
  const { dispatch } = useStoreon<LocaleState>(LOCALE_KEY)

  return (locale: string) => {
    I18n.locale = locale
    return dispatch(SET_LOCALE_EVENT, locale)
  }
}

import { useLocale } from 'hooks/Locale'
import { useAppContext } from 'hooks/useAppContext'

export function useApolloHeaders() {
  const { company } = useAppContext()
  const locale = useLocale()

  return {
    'company-id': company.microserviceId,
    'core-company-id': company.coreId,
    'accept-language': locale,
  }
}

import { useApolloMutation } from 'API/services/Apollo'

import { UpdateRuleMutation } from './GraphQL'

export function useUpdateRule() {
  const [innerUpdate, { loading: creating }] = useApolloMutation<
    MutationData<'updateRule'>,
    Gateway.MutationUpdateRuleArgs
  >(UpdateRuleMutation)

  const updateRule = async (input: Gateway.UpdateRuleInput) =>
    innerUpdate({
      variables: {
        input,
      },
    })

  return { updateRule, creating }
}

import { Ability, AbilityBuilder } from '@casl/ability'

import forEach from 'lodash/forEach'
import forIn from 'lodash/forIn'
import includes from 'lodash/includes'

import { AccessLevel } from 'constants/ids'

import { WidgetSections } from 'pages/Dashboard/constants'

import { AppContextState } from 'services/AppContext'
import Utils from 'services/Utils'

import { ACLSubjects, Actions } from './config'
import {
  BuilderFeatures,
  CustomFieldsFeatures,
  IntegrationsFeatures,
  PayrollManagementFeatures,
  ProfileFeatures,
  ScheduleFeatures,
  SettingsFeatures,
  StaffManagementFeatures,
  TimeBucketFeatures,
  TimeCardFeatures,
  TimeoffFeatures,
  WeeklyTimesheetsFeatures,
} from './features'
import {
  disableEmployeeArchive,
  disableEmployeeProfileEdit,
  restrictEmployeeSections,
} from './helpers'
import {
  aldoTree,
  defaultTree,
  deluxeTree,
  etfoTree,
  fpsTree,
  gardaTree,
  hrwizeTree,
  iccTree,
  keywordsTree,
  nahdiTree,
  sobeysTree,
} from './trees'
import { AllFeatures } from './types'

const ACL_FEATURES_TREE = {
  [ACLSubjects.ProfileFeature]: ProfileFeatures,
  [ACLSubjects.SchedulesFeature]: ScheduleFeatures,
  [ACLSubjects.StaffManagementFeature]: StaffManagementFeatures,
  [ACLSubjects.TimecardManagementFeature]: TimeCardFeatures,
  [ACLSubjects.WeeklyTimesheetsManagementFeature]: WeeklyTimesheetsFeatures,
  [ACLSubjects.PayrollManagementFeature]: PayrollManagementFeatures,
  [ACLSubjects.TimeoffFeature]: TimeoffFeatures,
  [ACLSubjects.BuilderFeature]: BuilderFeatures,
  [ACLSubjects.MetricsFeature]: WidgetSections,
  [ACLSubjects.IntegrationsFeature]: IntegrationsFeatures,
  [ACLSubjects.CustomFieldsFeature]: CustomFieldsFeatures,
  [ACLSubjects.SettingsFeature]: SettingsFeatures,
  [ACLSubjects.TimeBucketFeature]: TimeBucketFeatures,
} as const

type AbilityForProps = Pick<AppContextState, 'viewer' | 'company'>

export const abilityFor = ({
  viewer,
  company: { identity, settings },
}: AbilityForProps) => {
  const { can: allow, build } = new AbilityBuilder(Ability)
  let viewerAccessLevel = Utils.Viewer.getViewerCurrentAccessLevel(viewer)

  if (
    Utils.Viewer.isViewerCurrentlyEmployee(viewer) &&
    Utils.Viewer.isViewerAdminOwner(viewer)
  ) {
    viewerAccessLevel = Utils.Viewer.getViewerMainAccessLevel(viewer)
  }

  let tree = defaultTree

  if (identity.Nahdi) {
    tree = nahdiTree
  }
  if (identity.Sobeys) {
    tree = sobeysTree
  }
  if (identity.Deluxe) {
    tree = deluxeTree
  }
  if (identity.Keywords) {
    tree = keywordsTree
  }
  if (identity.Hrwize) {
    tree = hrwizeTree
  }
  if (identity.Aldo) {
    tree = aldoTree
  }
  if (identity.Etfo) {
    tree = etfoTree
  }
  if (identity.Garda) {
    tree = gardaTree
  }
  if (identity.Fps) {
    tree = fpsTree
  }
  if (identity.Icc) {
    tree = iccTree
  }

  const profileEditEnabledAndCantWritePersonalDetails =
    settings.hasProfileEditControlToggle && !viewer.canWritePersonalDetails

  // This "hack" will be removed after refactoring access level system
  if (
    settings.disableEmployeeProfileEdit ||
    profileEditEnabledAndCantWritePersonalDetails
  ) {
    disableEmployeeProfileEdit(tree)
  }

  // This "hack" will be removed after refactoring access level system
  if (profileEditEnabledAndCantWritePersonalDetails) {
    restrictEmployeeSections(tree)
  }

  // This "hack" will be removed after refactoring access level system
  if (viewer.role.manager && !settings.managersCanArchiveEmployees) {
    disableEmployeeArchive(tree)
  }

  function getACLSubject(featureId: AllFeatures): ACLSubjects {
    let ACLSubjectName
    forEach(ACL_FEATURES_TREE, (feature, subject) => {
      const subjectValues = Object.values(feature)
      if (subjectValues.find(item => featureId === item)) {
        ACLSubjectName = subject
      }
    })
    // @ts-ignore // FIXME:
    return ACLSubjectName
  }

  function accessPermissionToAction({
    actors,
    action,
    featureId,
    featureKeyId,
  }: {
    actors: AccessLevel[]
    action: Actions
    featureId: AllFeatures
    // @ts-ignore // FIXME:
    featureKeyId
  }) {
    forEach(actors, actor => {
      actor === viewerAccessLevel &&
        allow(action, getACLSubject(featureId), {
          featureId,
          ...(featureKeyId && { [featureKeyId]: true }),
        })
    })
  }

  const allActions = Object.values(Actions)

  forIn(tree, (featuresMap, featureId) => {
    forEach(featuresMap, (feature, featureKeyId) => {
      // @ts-ignore // FIXME:
      if (allActions.includes(featureKeyId)) {
        accessPermissionToAction({
          actors: feature,
          // @ts-ignore // FIXME:
          action: featureKeyId,
          // @ts-ignore // FIXME:
          featureId,
        })

        return
      }

      forIn(feature, (actors, action) => {
        // @ts-ignore // FIXME:
        accessPermissionToAction({ actors, action, featureId, featureKeyId })
      })
    })
  })

  return build()
}

export function canView(actions: Actions[]) {
  return includes(actions, Actions.view)
}

export function canDelete(actions: Actions[]) {
  return includes(actions, Actions.remove)
}

export function canModify(actions: Actions[]) {
  return includes(actions, Actions.edit)
}

export function canCreate(actions: Actions[]) {
  return includes(actions, Actions.create)
}

export function canCopy(actions: Actions[]) {
  return includes(actions, Actions.copy)
}

export function cantView(actions: Actions[]) {
  return !canView(actions)
}

export function cantDelete(actions: Actions[]) {
  return !canDelete(actions)
}

export function cantModify(actions: Actions[]) {
  return !canModify(actions)
}

export function cantCreate(actions: Actions[]) {
  return !canCreate(actions)
}

export function cantCopy(actions: Actions[]) {
  return !canCopy(actions)
}

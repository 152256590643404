import React from 'react'
import PropTypes from 'prop-types'

import { Input } from 'components/ui/__v3__/Input'
import { IconError } from 'components/ui/__v3__/Input/Errors'

import { ValidationError } from './styles'

/**
 * @see https://final-form.org/docs/react-final-form/types/FieldRenderProps for FormRenderProps props
 * @see {@link ../Input/Input.js} for Input props
 */
const FormInput = React.forwardRef(
  ({ input, meta, showHelper, ...rest }, ref) => {
    return (
      <Input
        errored={meta.error && meta.touched}
        ref={ref}
        {...input}
        helper={
          showHelper &&
          meta.error &&
          meta.touched && (
            <ValidationError>
              <IconError>{meta.error}</IconError>
            </ValidationError>
          )
        }
        {...rest}
      />
    )
  },
)

FormInput.defaultProps = {
  showHelper: true,
}

FormInput.propTypes = {
  ...Input.propTypes,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  showHelper: PropTypes.bool,
}

export default FormInput

import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { i18n } from 'i18n'

import coreApiCall from 'services/API/coreApi'
import { showCoreAPIErrorToast, showToast } from 'services/Toasts'

import { loadEmployee } from 'store/actions/employees/employees'

export function useEmployeeRelocation() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const relocateEmployee = async ({
    employeeId,
    jobId,
    departmentId,
    locationId,
  }: {
    employeeId: string
    jobId: string
    departmentId: string
    locationId: string
  }) => {
    setLoading(true)

    const response = (await coreApiCall({
      endpoint: `/employees/${employeeId}/relocation_requests`,
      method: 'POST',
      query: {
        data: {
          relationships: {
            branch: {
              data: {
                id: locationId,
                type: 'branch',
              },
            },
            department: {
              data: {
                id: departmentId,
                type: 'department',
              },
            },
            job: {
              data: {
                id: jobId,
                type: 'jobs',
              },
            },
          },
        },
      },
    })) as JsonApi.Response

    if (response.ok) {
      showToast({
        type: 'info',
        title: i18n('people.relocationRequestSent'),
      })
      await dispatch(loadEmployee(employeeId, { withLoading: false }))
    } else {
      showCoreAPIErrorToast({ response })
    }

    setLoading(false)

    return response
  }

  return { relocateEmployee, loading }
}

import React, { useState } from 'react'

import { ShiftConflictActionSelector } from 'components/blocks/__v3__/ShiftConflictActionSelector'
import { SpacedColumn, Span } from 'components/ui/__v2__/Grid'
import { Modal } from 'components/ui/__v2__/Modal'
import { Button } from 'components/ui/__v3__/Button'

import { ScheduleConflictActionTypes } from 'constants/ids'

import { useAppContext, useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

type Props = {
  onSubmitIntent: (conflictAction: ScheduleConflictActionTypes) => void
  onClose: () => void
}

export function ApproveRequestModal({ onSubmitIntent, onClose }: Props) {
  const t = useI18n<typeof TRANSLATIONS.availabilityRequests>(
    'availabilityRequests',
  )

  const { isKeywords } = useAppContext()

  const [
    conflictAction,
    setConflictAction,
  ] = useState<ScheduleConflictActionTypes>(
    isKeywords
      ? ScheduleConflictActionTypes.Unassign
      : ScheduleConflictActionTypes.None,
  )

  return (
    <Modal isOpen>
      {/* @ts-ignore TODO remove tsnocheck after typing <Modal> */}
      <Modal.Close onClose={onClose} />
      {/* @ts-ignore TODO remove tsnocheck after typing <Modal> */}
      <Modal.Title>{t('modals.approve.title')}</Modal.Title>

      <SpacedColumn my={5} space={2}>
        <Span>{t('modals.approve.content')}</Span>
        <ShiftConflictActionSelector
          description={t('conflictDescription')}
          excludeNoneAction={isKeywords}
          name="conflictAction"
          value={conflictAction}
          onChange={setConflictAction}
        />
      </SpacedColumn>

      {/* @ts-ignore TODO remove tsnocheck after typing <Modal> */}
      <Modal.Actions justifyContent="center">
        <Button mr={17} secondary width={180} onClick={onClose}>
          {t('modals.cancel')}
        </Button>
        <Button width={180} onClick={() => onSubmitIntent(conflictAction)}>
          {t('modals.approve.action')}
        </Button>
        {/* @ts-ignore TODO remove tsnocheck after typing <Modal> */}
      </Modal.Actions>
    </Modal>
  )
}

import { DateTime } from 'luxon'

import difference from 'lodash/difference'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import omit from 'lodash/omit'
import reduce from 'lodash/reduce'
import uniq from 'lodash/uniq'

import { ACCESS_LEVEL } from './ids'

export const PAY_PERIODS = {
  daily: 'Daily',
  weekly: 'Weekly',
  biWeekly: 'Bi-weekly',
  monthly: 'Monthly',
}

export const EXPORT_TYPES = {
  detailed: 'detailed',
  summary: 'summary',
  nethris: 'nethris',
  fullNethris: 'full_nethris',
}

export const calculateTimesheetIntervalDate = (date, payPeriod) => {
  const dateTime = DateTime.fromISO(date)
  const calcDate = duration => dateTime.plus(duration).toISO()

  switch (payPeriod) {
    case PAY_PERIODS.weekly:
      return calcDate({ weeks: 1 })
    case PAY_PERIODS.biWeekly:
      return calcDate({ weeks: 2 })
    case PAY_PERIODS.monthly:
      return calcDate({ months: 1 })
    default:
      return null
  }
}

export const calculatePayrollIntervalDate = (endDate, payPeriod) => {
  const endDateTime = DateTime.fromISO(endDate)
  const calcDate = duration =>
    endDateTime.minus(duration).plus({ days: 1 }).toISO()

  switch (payPeriod) {
    case PAY_PERIODS.daily:
      return endDate
    case PAY_PERIODS.weekly:
      return calcDate({ weeks: 1 })
    case PAY_PERIODS.biWeekly:
      return calcDate({ weeks: 2 })
    case PAY_PERIODS.monthly:
      return endDateTime.startOf('month').toISO()
    default:
      return null
  }
}

const getEmployeesJobIds = employees =>
  reduce(
    employees,
    (uniqJobIds, employee) => {
      const jobsEmployees = get(employee, 'jobsEmployees', [])
      const employeeJobIds = map(jobsEmployees, 'job.id')
      uniqJobIds = [...uniqJobIds, ...employeeJobIds]
      return uniq(uniqJobIds)
    },
    [],
  )

export const clearBranches = (viewer, branches, employees, isBranchManager) => {
  const employeesJobIds = getEmployeesJobIds(employees)
  if (viewer.accessLevel === ACCESS_LEVEL.manager && !isBranchManager) {
    let departments = get(viewer, 'managers[0].departments', [])
    departments = filterDepartments(departments, employeesJobIds)
    departments = exludeEmptyJobsFromDepartments(
      departments,
      employeesJobIds,
      employees,
    )
    return departments
  }

  let branchesByAccess
  if (
    viewer.accessLevel === ACCESS_LEVEL.owner ||
    viewer.accessLevel === ACCESS_LEVEL.admin
  ) {
    branchesByAccess = branches
  } else if (viewer.accessLevel === ACCESS_LEVEL.manager && isBranchManager) {
    branchesByAccess = get(viewer, 'managers[0].branches', [])
  }

  branchesByAccess = filterBranches(branchesByAccess, employeesJobIds)
  branchesByAccess = exludeEmptyDepartmentsFromBranches(
    branchesByAccess,
    employeesJobIds,
    employees,
  )
  return branchesByAccess
}

const filterBranches = (branches, employeesJobIds) =>
  filter(branches, branch => {
    const branchDepartments = get(branch, 'departments', [])

    const branchJobsIds = reduce(
      branchDepartments,
      (jobsIds, department) => {
        const departmentJobsIds = map(get(department, 'jobs', []), 'id')
        jobsIds = [...jobsIds, ...departmentJobsIds]
        return jobsIds
      },
      [],
    )

    // TODO: never use isEqual unless you knows what are you doing
    return !isEqual(difference(employeesJobIds, branchJobsIds), employeesJobIds)
  })

const filterDepartments = (departments, employeesJobIds) =>
  filter(departments, department => {
    const departmentJobIds = map(get(department, 'jobs', []), 'id')
    // TODO: never use isEqual unless you knows what are you doing
    return !isEqual(
      difference(employeesJobIds, departmentJobIds),
      employeesJobIds,
    )
  })

const filterJobs = (jobs, employeesJobIds) =>
  filter(jobs, job => indexOf(employeesJobIds, job.id) !== -1)

const exludeEmptyJobsFromDepartments = (
  departments,
  employeesJobIds,
  employees,
) =>
  reduce(
    departments,
    (result, depaertment) => {
      let jobs = filterJobs(get(depaertment, 'jobs', []), employeesJobIds)
      if (!isEmpty(jobs)) {
        jobs = appeendEmployeesAccordingJob(jobs, employees)
        const swap = omit(depaertment, 'jobs')
        result = [...result, { ...swap, jobs }]
      }
      return result
    },
    [],
  )

const exludeEmptyDepartmentsFromBranches = (
  branches,
  employeesJobIds,
  employees,
) =>
  reduce(
    branches,
    (result, branch) => {
      let departments = filterDepartments(
        get(branch, 'departments', []),
        employeesJobIds,
      )

      departments = exludeEmptyJobsFromDepartments(
        departments,
        employeesJobIds,
        employees,
      )
      if (!isEmpty(departments)) {
        const swap = omit(branch, 'departments')
        result = [...result, { ...swap, departments }]
      }
      return result
    },
    [],
  )

const appeendEmployeesAccordingJob = (jobs, employees) =>
  reduce(
    jobs,
    (result, job) => {
      let emoloyeesWithJob = filter(employees, employee => {
        const jobsEmployees = get(employee, 'jobsEmployees', [])
        const isCurrentJob = !!find(jobsEmployees, ['job.id', job.id])
        return isCurrentJob
      })
      emoloyeesWithJob = map(emoloyeesWithJob, employeeWithJob => {
        const { jobsEmployees } = employeeWithJob
        const currentJob = find(jobsEmployees, ['job.id', job.id])
        const swap = omit(employeeWithJob, 'jobsEmployees')

        return { ...swap, jobsEmployees: currentJob }
      })
      result = [...result, { ...job, employees: emoloyeesWithJob }]
      return result
    },
    [],
  )

export const openCSVLink = csvLink => {
  const a = document.createElement('A')
  a.href = csvLink
  a.download = csvLink.substr(csvLink.lastIndexOf('/') + 1)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const executeCsv = (url, funcToExec) => {
  const myRequest = new Request(url, {
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  })
  fetch(myRequest)
    .then(response => {
      if (response.status === 200) {
        openCSVLink(url)
      } else if (response.status === 204) {
        setTimeout(() => funcToExec(url, funcToExec), 2000)
      }
      throw new Error('Something went wrong on api server!')
    })
    .catch(error => {
      // console.error(error)
    })
}

export const getJobsJobsEmployeesIds = jobs =>
  reduce(
    jobs,
    (result, job) => {
      const { employees } = job
      const ids = map(employees, 'jobsEmployees.id')
      return [...result, ...ids]
    },
    [],
  )

export const NETHRIS_TABLE_COLUMNS = {
  employee: { name: 'Employee', sorting: true },
  department: { name: 'Department', sorting: true },
  departmentExportCode: { name: 'Department Export Code', sorting: true },
  role: { name: 'Role', sorting: true },
  jobExportCode: { name: 'Role Export Code', sorting: true },
  exportCode: { name: 'Export Code', sorting: true },
  scheduledHours: { name: 'Scheduled Hours', sorting: true },
  actualHours: { name: 'Actual Hours', sorting: true },
  unpaidTimeOff: { name: 'Unpaid Time-off', sorting: false },
  paidTimeOff: { name: 'Paid Time-off', sorting: false },
  regularHours: { name: 'Regular Hours', sorting: true },
  overtimeHours: { name: 'Overtime Hours', sorting: true },
  totalHours: { name: 'Total Hours', sorting: true },
  rate: { name: 'Rate', sorting: true },
  overtimeRate: { name: 'Overtime Rate', sorting: true },
  cost: { name: 'Cost', sorting: false },
}

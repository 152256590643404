import { useEffect, useRef } from 'react'

function preventDefault(event) {
  event.preventDefault()
}

export function useDisableLabel() {
  const ref = useRef(null)

  useEffect(() => {
    let label = null

    if (ref.current) {
      label = ref.current.closest('label')

      if (label) {
        label.addEventListener('click', preventDefault)
      }
    }

    return () => {
      if (label) {
        label.removeEventListener('click', preventDefault)
      }
    }
  }, [])

  return ref
}

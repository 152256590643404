import React, { useCallback, useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'
import { FieldValues, useController } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import {
  FlexboxProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
} from 'styled-system'

import concat from 'lodash/concat'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'

import { Flex } from 'components/ui/__v2__/Grid'
import { Popover } from 'components/ui/__v2__/Popover'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { Input, InputLabel } from 'components/ui/__v3__/Input'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import { i18n } from 'i18n'

import { uploadFiles as uploadFilesAction } from 'store/actions/common/files'

import FilesPopup from './FilesPopup'
import {
  BorderIconButton,
  DownloadIcon,
  InputBorder,
  PlaceholderText,
  TriangleDown,
} from './styles'

type StyleProps = FlexboxProps & LayoutProps & PositionProps & SpaceProps
type DropzoneProps = {
  disabled?: boolean
  multiple?: boolean
  maxFiles?: number
}

type Props<T extends FieldValues> = ControllerBaseProps<T> &
  StyleProps &
  DropzoneProps &
  LabelProps

/** @deprecated this is a fast attempt, use a headless dropzone, split into form and non-form components, make proper typings */
export function FormFileUpload<T extends FieldValues>({
  control,
  name,

  labelText,
  required,

  disabled = false,
  multiple = false,
  maxFiles = 1,
  ...style
}: Props<T>) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  const dispatch = useDispatch()

  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  /**
   * In case input is not an array (single file)
   */

  // @ts-ignore
  const files = useMemo(() => flatten([value || []]), [value])

  const handleDropdownButtonClick = useCallback(() => {
    setShowDropdownMenu(x => !x)
  }, [])

  const handleClickOutside = useCallback(() => {
    setShowDropdownMenu(false)
  }, [])

  const handleRemoveFile = useCallback(
    id => () => {
      onChange(files.filter((file: $TSFixMe) => file.id !== id))
    },
    [files, onChange],
  )

  const handleDrop = useCallback(
    async inputFiles => {
      try {
        if (files.length + inputFiles.length > maxFiles) {
          // @ts-ignore
          toastr.error(i18n('common.maxFilesError', { maxFiles }))
          return
        }
        const resultFiles = await dispatch(uploadFilesAction(inputFiles))
        if (multiple) {
          onChange(concat(files || [], resultFiles))
        } else {
          onChange(resultFiles)
        }
      } catch (error) {
        // @ts-ignore
        toastr.error(i18n('common.somethingWentWrong'))
      }
    },
    [files, maxFiles, dispatch, multiple, onChange],
  )

  return (
    <Flex {...style} flexDirection="column">
      {labelText && (
        <InputLabel>
          <LabelText labelText={labelText} required={required} />
        </InputLabel>
      )}
      <Flex>
        <Dropzone disabled={disabled} multiple={multiple} onDrop={handleDrop}>
          {({ getRootProps, getInputProps }) => (
            <InputBorder
              {...getRootProps()}
              // @ts-ignore
              disabled={disabled}
              errored={Boolean(error)}
              flexGrow={1}
            >
              <Input {...getInputProps()} />
              <PlaceholderText>
                {multiple
                  ? i18n('common.selectFiles')
                  : i18n('common.selectFile')}
                ...
              </PlaceholderText>
            </InputBorder>
          )}
        </Dropzone>
        {/* @ts-ignore */}
        <Popover
          appendTo={document.body}
          content={
            <FilesPopup
              canDownload
              canRemoveFile
              files={files}
              onRemoveFile={handleRemoveFile}
            />
          }
          interactive
          placement="bottom-end"
          visible={showDropdownMenu}
          onClickOutside={handleClickOutside}
        >
          <BorderIconButton
            disabled={isEmpty(files)}
            // @ts-ignore
            ml={1}
            onClick={handleDropdownButtonClick}
          >
            <DownloadIcon />
            {/* @ts-ignore */}
            <TriangleDown ml={1} />
          </BorderIconButton>
        </Popover>
      </Flex>
      {error && <Error error={error} />}
    </Flex>
  )
}

import React from 'react'
import { TypeOptions } from 'react-toastify'

import { FiAlertTriangle, FiCheck, FiInfo, FiX } from 'react-icons/fi'

import {
  ErrorIconContainer,
  InfoIconContainer,
  SuccessIconContainer,
  WarningIconContainer,
} from './styles'

interface IconProps {
  type: TypeOptions
}
export function Icon({ type, ...rest }: IconProps) {
  switch (type) {
    case 'success':
      return (
        <SuccessIconContainer {...rest}>
          <FiCheck size={12} />
        </SuccessIconContainer>
      )

    case 'info':
      return (
        <InfoIconContainer {...rest}>
          <FiInfo size={12} />
        </InfoIconContainer>
      )

    case 'warning':
      return (
        <WarningIconContainer {...rest}>
          <FiAlertTriangle size={12} />
        </WarningIconContainer>
      )

    case 'error':
      return (
        <ErrorIconContainer {...rest}>
          <FiX size={12} />
        </ErrorIconContainer>
      )

    default:
      return null
  }
}

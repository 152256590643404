import React, { useCallback } from 'react'

import { useTheme } from 'styled-components'

import { API } from 'API'

import { Flex, Span } from 'components/ui/__v2__/Grid'
import { Select } from 'components/ui/__v3__/Select'
import { PickerProps } from 'components/ui/__v3__/Select/types'

import { createpDebounce } from 'helpers/debounce'

import { groupOptions } from './helpers'
import { EmploymentLevelPickerOption } from './types'

const SEARCH_DEBOUNCE_TIMEOUT = 350

export type EmploymentLevelPickerProps = {
  withArchived?: boolean
  employmentTypeIds?: string[]
} & Omit<
  PickerProps,
  | 'async'
  | 'closeMenuOnSelect'
  | 'defaultOptions'
  | 'formatOptionLabel'
  | 'isLoading'
  | 'loadOptions'
  | 'onMenuOpen'
  | 'onInputChange'
  | 'withPortal'
  | 'isSearchable'
>

export function EmploymentLevelPicker({
  isMulti,
  withArchived = false,
  employmentTypeIds,
  onChange,
  ...rest
}: EmploymentLevelPickerProps) {
  const theme = useTheme()

  const {
    employmentLevels,
    employmentLevelsLoading,
    loadEmploymentLevels,
    refetchEmploymentLevels,
  } = API.EmploymentLevel.picker()
  const defaultOptions = groupOptions(employmentLevels) ?? []

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoadOptions = useCallback(
    createpDebounce(
      async (inputValue: string, callback: Function) => {
        const refetchedData = await refetchEmploymentLevels({
          inputValue,
          archived: withArchived,
          employmentTypeIds,
        })
        const newOptions = groupOptions(refetchedData)

        callback(newOptions)
      },
      undefined,
      SEARCH_DEBOUNCE_TIMEOUT,
    ),
    [refetchEmploymentLevels],
  )

  // NOTE: to refresh the default values
  const handleInputChange = (
    inputValue: string,
    action: { action: string },
  ) => {
    if (action.action !== 'input-change' || inputValue) return

    refetchEmploymentLevels({ archived: withArchived, employmentTypeIds })
  }

  const handleMenuOpen = () => {
    loadEmploymentLevels({ archived: withArchived, employmentTypeIds })
  }

  const formatOptionLabel = (option: EmploymentLevelPickerOption) => {
    return (
      <Flex flexDirection="column">
        {!!option.data.employmentType?.name && (
          <Span
            color={theme.colors.GRAY}
            fontSize="9px"
            lineHeight="7px"
            ml="-1px"
          >
            {option.data.employmentType.name}
          </Span>
        )}
        {option.label}
      </Flex>
    )
  }

  return (
    <Select
      // @ts-ignore
      async
      closeMenuOnSelect={!isMulti}
      defaultOptions={defaultOptions}
      formatOptionLabel={formatOptionLabel}
      isLoading={employmentLevelsLoading}
      isMulti={isMulti}
      isSearchable
      loadOptions={handleLoadOptions}
      withPortal
      onChange={onChange}
      onInputChange={handleInputChange}
      onMenuOpen={handleMenuOpen}
      {...rest}
    />
  )
}

import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'

import { AREAS_ITEM_TYPES } from 'constants/areas'

const AREAS_CASCADE_DEPENDENCIES = {
  [AREAS_ITEM_TYPES.locations]() {
    return {
      name: AREAS_ITEM_TYPES.locations,
      cascadeDependencies: [this[AREAS_ITEM_TYPES.departments]()],
    }
  },
  [AREAS_ITEM_TYPES.departments]() {
    return {
      name: AREAS_ITEM_TYPES.departments,
      cascadeDependencies: [this[AREAS_ITEM_TYPES.jobs]()],
    }
  },
  [AREAS_ITEM_TYPES.jobs]() {
    return {
      name: AREAS_ITEM_TYPES.jobs,
      cascadeDependencies: [
        this[AREAS_ITEM_TYPES.employees](),
        this[AREAS_ITEM_TYPES.jobsEmployees](),
      ],
    }
  },
  [AREAS_ITEM_TYPES.employees]() {
    return { name: AREAS_ITEM_TYPES.employees }
  },
  [AREAS_ITEM_TYPES.jobsEmployees]() {
    return { name: AREAS_ITEM_TYPES.jobsEmployees }
  },
}

function removeCascadeDependencies(values, removedItemType) {
  function recursiveDependenciesCleaner(initialValues, dependencyType) {
    const valueDependenciesTree = AREAS_CASCADE_DEPENDENCIES[dependencyType]()

    // recursion exit condition when we reach the end of the dependency tree
    if (!valueDependenciesTree.cascadeDependencies) {
      if (initialValues[valueDependenciesTree.name]) {
        return { ...initialValues, [valueDependenciesTree.name]: null }
      }
      return initialValues
    }

    const valuesWithoutDependencies = valueDependenciesTree.cascadeDependencies.reduce(
      (acc, current) => {
        let accValues = initialValues
        // due to the fact that there can be more than one dependency in cascadeDependencies
        // determine if there have already been changes in one of these dependencies
        // if so, we need to save the cleanup progress (acc)
        if (!isEmpty(acc)) {
          accValues = acc
        }

        const valueWithoutDependencies = recursiveDependenciesCleaner(
          accValues,
          current.name,
        )
        return { ...acc, ...valueWithoutDependencies }
      },
      {},
    )

    // we are on the highest level or not
    const nextItemValue =
      dependencyType === removedItemType ? initialValues[dependencyType] : null

    // this constant is needed to avoid extra fields in the object Areas
    const currentValueObject = initialValues[valueDependenciesTree.name]
      ? { [valueDependenciesTree.name]: nextItemValue }
      : {}

    return {
      ...valuesWithoutDependencies,
      ...currentValueObject,
    }
  }
  return recursiveDependenciesCleaner(values, removedItemType)
}

export function getNextAreasValue({
  previousValue,
  newItems,
  changedItemType,
  cascade,
}) {
  let nextValue = { ...previousValue }
  if (cascade) {
    nextValue = removeCascadeDependencies(previousValue, changedItemType)
  }

  const omittedFalseValues = pickBy(
    { ...nextValue, [changedItemType]: newItems },
    value => !isEmpty(value) && value,
  )

  return omittedFalseValues
}

import React, { ReactNode } from 'react'

import { Flex } from 'components/ui/__v2__/Grid'

type Props = {
  children: ReactNode
}

export function Filter({ children }: Props) {
  return (
    <Flex flexDirection="column" fontWeight="initial">
      {children}
    </Flex>
  )
}

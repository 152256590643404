import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'components/ui/__v2__/Grid'

export const IconContainer = styled(Flex).attrs({ alignItems: 'center' })`
  cursor: pointer;
  color: ${themeGet('layout.primary')};
  height: 20px;
`

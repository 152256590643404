import { DateTime } from 'luxon'

import {
  DAILY_WORKING_HOURS,
  LATEST_DAY_TIME,
  SEC_IN_DAY,
  SEC_IN_HOUR,
  SEC_IN_MIN,
} from 'constants/shifts'

import { isDisabledPastTime } from 'helpers/date'

export function getTimeWithDisabledPast({ date, startTime, endTime, zone }) {
  let newStartTime = startTime
  let newEndTime = endTime

  const now = DateTime.local().setZone(zone)

  const nowStartTime =
    now.hour * SEC_IN_HOUR + now.minute * SEC_IN_MIN + now.second

  if (date && isDisabledPastTime(date)) {
    let nextStartAt = startTime

    if (nowStartTime > startTime) {
      nextStartAt = (now.hour + 1) * SEC_IN_HOUR
      newStartTime = nextStartAt
    }

    if (nowStartTime > endTime) {
      newEndTime = (now.hour + DAILY_WORKING_HOURS) * SEC_IN_HOUR
    }

    if (nextStartAt === endTime) {
      newEndTime += SEC_IN_HOUR
    }

    if (newEndTime > LATEST_DAY_TIME * SEC_IN_HOUR) {
      newEndTime -= SEC_IN_DAY
    }
  }

  return [newStartTime, newEndTime]
}

export function getShiftTimezone(shift) {
  return shift?.branch?.settings?.timezone ?? 'local'
}

export function getShiftOffset(shift, day) {
  const timeZone = getShiftTimezone(shift)
  const dateToPaste = DateTime.fromISO(day, { zone: timeZone })
  const nextDateParams = {
    year: dateToPaste.year,
    month: dateToPaste.month,
    day: dateToPaste.day,
  }

  const nextStartAt = DateTime.fromISO(shift?.startAt)
    .setZone(timeZone)
    .set(nextDateParams)
    .toUTC()
  let nextFinishAt = DateTime.fromISO(shift?.finishAt)
    .setZone(timeZone)
    .set(nextDateParams)
    .toUTC()

  if (nextStartAt > nextFinishAt) {
    nextFinishAt = nextFinishAt.plus({ days: 1 })
  }

  return [nextStartAt.toISO(), nextFinishAt.toISO()]
}

export const setShiftTime = (shift, startTime, finishTime) => {
  if (!startTime && !finishTime) {
    return {
      startAt: undefined,
      finishAt: undefined,
    }
  }
  const timezone = getShiftTimezone(shift)
  const [startHour, startMinute] = startTime.split(':')
  const [finishHour, finishMinute] = finishTime.split(':')

  const startAtDate = DateTime.fromISO(shift.startAt).setZone(timezone)
  const finishAtDate = DateTime.fromISO(shift.finishAt).setZone(timezone)

  const startAt = startAtDate.set({ hour: startHour, minute: startMinute })
  let finishAt = finishAtDate.set({ hour: finishHour, minute: finishMinute })

  const wasOvernightShift = !startAt.hasSame(finishAt, 'day')

  const isOvernightShift = startTime >= finishTime

  if (isOvernightShift && !wasOvernightShift) {
    finishAt = finishAt.plus({ days: 1 })
  }
  if (!isOvernightShift && wasOvernightShift) {
    finishAt = finishAt.minus({ days: 1 })
  }

  return {
    startAt: startAt.toUTC().toISO(),
    finishAt: finishAt.toUTC().toISO(),
  }
}

export function pausesComparator(a, b) {
  return a.id === b.id
}

export function updatedPausesComparator(a, b) {
  return (
    pausesComparator(a, b) && a.paid === b.paid && a.duration === b.duration
  )
}

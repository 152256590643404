import React, { PropsWithChildren } from 'react'

import { BoxProps, Flex } from 'components/ui/__v2__/Grid'

interface ModalActionsProps extends BoxProps {
  children: React.ReactNode
}
export function ModalActionsContainer({
  children,
  ...rest
}: PropsWithChildren<ModalActionsProps>) {
  return (
    // @ts-ignore TODO: investigate issue with 'color' prop
    <Flex alignItems="center" {...rest}>
      {children}
    </Flex>
  )
}

import styled from 'styled-components'

import SvgIcons from 'assets/images/__v2__/svg'

import { SvgIcon } from 'components/ui/__v2__/SvgIcon'

const Prayer = styled(SvgIcon).attrs({
  glyph: SvgIcons.Prayer,
})``

Prayer.defaultProps = {
  size: 18,
}

export default Prayer

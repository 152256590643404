import { Schedule, Shift } from 'Types/schedule'

export function isEmployeeAssignedToShift(
  employeeId: string,
  shift: Shift,
): boolean {
  if (!shift.shiftsJobs) return false
  return Boolean(
    shift.shiftsJobs
      .map(shiftsJob => shiftsJob.schedules)
      .flat()
      .find(schedule => schedule.employee.id === employeeId),
  )
}

export function getEmployeeScheduleFromShift(
  employeeId: string,
  shift: Shift,
): Schedule | undefined {
  return shift.shiftsJobs
    .map(shiftsJob => shiftsJob.schedules)
    .flat()
    .find(schedule => schedule.employee.id === employeeId)
}

import React, { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router'

import { Button, ButtonProps } from 'components/ui/__v3__'

import { rootPath } from 'helpers/routes'

import { i18n } from 'i18n'

type Props = Omit<ButtonProps, 'onClick'> & {
  route?: string
  text?: string
}

export function BackButton({ route, text, ...buttonProps }: Props) {
  const history = useHistory()
  const location = useLocation()
  const hasHistory = location.key

  const onClick = useCallback(() => {
    if (route) {
      history.push(route)
      return
    }

    if (hasHistory) {
      history.goBack()
      return
    }

    history.push(rootPath())
  }, [hasHistory, history, route])

  return (
    <Button {...buttonProps} onClick={onClick}>
      {text ?? i18n('actions.back')}
    </Button>
  )
}

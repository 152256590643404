import {
  BULK_BUDGET_RELATION_TYPES,
  bulkBudgetRelationAddHandler,
} from 'constants/dailySales'

import { createReducer } from 'helpers/redux'

import { CREATE_OR_UPDATE_BULK_BUDGET } from 'store/actions/company/branches'

const initialState = {}

const handlers = {
  [CREATE_OR_UPDATE_BULK_BUDGET.SUCCESS]: bulkBudgetRelationAddHandler(
    BULK_BUDGET_RELATION_TYPES.laborCosts,
  ),
}

export default createReducer(initialState, handlers)

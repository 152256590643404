import { IconType } from 'react-icons/lib'
import { MdFilterList } from 'react-icons/md'

import styled, { StyledComponent } from 'styled-components'
import styledMap from 'styled-map'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'components/ui/__v2__/Grid'

export const AdditionalFiltersContainer = styled(Flex)`
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const MultiButtonFrame = styled(Flex)`
  position: relative;
  display: flex;
  border: 1px solid ${themeGet('colors.ALTO')};
  flex: 1;
  border-radius: 4px;
  max-width: 72px;
  width: 72px;
  cursor: pointer;
  margin-right: 10px;
`

interface StyledMdFilterListProps {
  filled?: boolean
}
export const StyledMdFilterList = styled<
  StyledComponent<IconType, any, StyledMdFilterListProps, never>
  // @ts-ignore
>(MdFilterList)`
  cursor: pointer;
  vertical-align: top !important;

  color: ${styledMap`
    filled: ${themeGet('layout.primary')};
    default: ${themeGet('layout.disabled')};
  `};
`

export const FilterFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1.5;
`

export const SortFrame = styled.div`
  display: flex;
  border-left: 1px solid ${themeGet('colors.ALTO')};
  align-items: center;
  justify-content: center;
  flex: 1;
`

export const TriangleDown = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid ${themeGet('colors.GRAY')};
`

export const DropdownContainer = styled(Flex)`
  width: 280px;
  border-radius: 5px;
  padding: 12px 15px;
  position: absolute;
  // TODO: get z-index from theme
  z-index: 2;
  background: ${themeGet('colors.WHITE')};
  top: 34px;
  right: 28px;
  box-shadow: 0 2px 10px ${themeGet('colors.SHADOW')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const DropdownRow = styled(Flex)`
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: -8px;
  width: 100%;
`

import { createRelationDeleteHandler } from 'redux-json-api-handlers'

import { createReducer, createRelationAddHandler } from 'helpers/redux'

import {
  ADD_COMMENT,
  ADD_REACTION,
  DELETE_COMMENT,
  DELETE_REACTION,
} from 'store/actions/news'

const initialState = {}

const handlers = {
  [ADD_COMMENT.SUCCESS]: createRelationAddHandler('comments'),
  [DELETE_COMMENT.SUCCESS]: createRelationDeleteHandler('comments'),
  [ADD_REACTION.posts.SUCCESS]: createRelationAddHandler('emojis'),
  [DELETE_REACTION.posts.SUCCESS]: createRelationDeleteHandler('emojis'),
}

export default createReducer(initialState, handlers)

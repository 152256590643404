import { TimeEntryReturn } from 'API/TimeCard/GraphQL'

import { DraftTimesheetRecord, ExistingTimesheetRecord } from '../types'

export function draftTimesheetRecordGuard(
  timeEntry: DraftTimesheetRecord | ExistingTimesheetRecord | TimeEntryReturn,
): timeEntry is DraftTimesheetRecord {
  return timeEntry.id === undefined
}

export function existingTimesheetRecordGuard(
  timeEntry: DraftTimesheetRecord | ExistingTimesheetRecord | TimeEntryReturn,
): timeEntry is ExistingTimesheetRecord {
  return timeEntry.id !== undefined
}

export const LOCATION_JSON_API_TYPE = 'branches'

export const LocationQueryKey = Object.freeze({
  all: () => [LOCATION_JSON_API_TYPE],

  byId: ({ id, include }: { id: string; include: string }) => [
    LOCATION_JSON_API_TYPE,
    id,
    { include },
  ],

  byCursor: ({ include }: { include?: string }) => [
    LOCATION_JSON_API_TYPE,
    { include },
  ],
})

export const LOCATION_INCLUDE = [
  'jobs',
  'departments',
  'hierarchyNodes',
  'prayerSettings',
].join()

import { useCreatePayFrequency as create } from './useCreatePayFrequency'
import { useDeletePayFrequency as remove } from './useDeletePayFrequency'
import { useLazyPayFrequenciesByOffset as getByOffsetLazy } from './useLazyPayFrequenciesByOffset'
import { usePayFrequenciesByOffset as getByOffset } from './usePayFequenciesByOffset'
import { usePayFrequencyByEmployee as getByEmployee } from './usePayFrequencyByEmployee'
import { useUpdatePayFrequency as update } from './useUpdatePayFrequency'

export const PayFrequencyAPI = {
  getByOffset,
  getByOffsetLazy,
  getByEmployee,
  create,
  remove,
  update,
}

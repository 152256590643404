import { useState } from 'react'

import { download } from 'API/services/JSON'
import qs from 'qs'
import { SelectOption } from 'Types/common'

import { EmployeePickerReturnOption } from 'components/blocks/__v3__'

import { useAppContext } from 'hooks'

import Utils from 'services/Utils'

export type ExportAldoPayrollArgs = {
  period: { from: string; to: string }
  employees: EmployeePickerReturnOption[] | null
  changeTypes: SelectOption<Gateway.AldoPayrollRowChangeType>[] | null
  targetSystem: SelectOption<Gateway.AldoPayrollRowTargetSystem> | null
}

export function useExportAldoPayroll() {
  const [exporting, setExporting] = useState(false)
  const { companyId } = useAppContext()

  async function exportAldoPayroll(args: ExportAldoPayrollArgs) {
    const { period, targetSystem, employees, changeTypes } = args
    const queryString = qs.stringify(
      {
        filter: {
          start_date: Utils.JsonApi.betweenFilter(period, {
            includeEndDate: false,
          }),
          ...(targetSystem && {
            target_system: Utils.JsonApi.eqFilter(
              targetSystem.value.toLowerCase(),
            ),
          }),
          ...(changeTypes && {
            change_type: Utils.JsonApi.inFilter(
              changeTypes.map(changeType => changeType.value.toLowerCase()),
            ),
          }),
          ...(employees && {
            employee_id: Utils.JsonApi.inFilter(
              employees.map(employee => employee.value),
            ),
          }),
        },
      },
      { skipNulls: true, arrayFormat: 'brackets' },
    )

    setExporting(true)
    await download({
      companyId,
      queryString,
      kind: 'aldo_payroll_export',
    })
    setExporting(false)
  }

  return {
    exporting,
    exportAldoPayroll,
  }
}

import { useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { GroupsByCursorQuery, GroupsByCursorQueryData } from './GraphQL'

type Props = {
  page: CursorPage
  sorting: Gateway.GroupSorting
  enabled?: boolean
}

export function useGroupsByCursor({ page, sorting, enabled }: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, loading, refetch } = useApolloQuery<
    GroupsByCursorQueryData,
    Gateway.QueryGroupsByCursorArgs
  >(GroupsByCursorQuery, {
    variables: {
      sorting,
      paging: cursorPageToGraphqlPaging(page),
    },
    fetchPolicy: 'network-only',
    skip: !enabled,

    onCompleted(completedData) {
      const newPageMeta = completedData.groupsByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const groups = data?.groupsByCursor?.edges.map(edge => edge.node) ?? []

  return {
    groups,
    loading,
    currentPageMeta,
    refetch,
  }
}

export enum CurrentStateWidgets {
  CurrentlyClockedIn = 'currently_working',
  ActiveUsers = 'active',
  PendingUsers = 'pending',
  ArchivedUsers = 'archived',
  ScheduledToday = 'scheduledToday',
  PendingTradeRequests = 'pendingTradeRequests',
  PendingCoverRequests = 'pendingCoverRequests',
  PendingTimeoffRequests = 'pendingTimeoffRequests',
  PendingAvailabilityRequests = 'pendingAvailabilityRequests',
  ExpiringCustomCvFieldValues = 'expiringCustomCvFieldValues',
  LastEditedCustomFields = 'lastEditedCustomFields',
}

export enum DeviationStateWidgets {
  LateOcurrences = 'lateOcurrences',
  Absences = 'absences',
  PendingTimecards = 'pendingTimecards',
}

export const UNFILLED_SHIFTS_WIDGET = 'unfilledShifts'
export const WEEKLY_HOURS_WIDGET = 'weeklyHours'

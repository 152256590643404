import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { Flex } from 'components/ui/__v2__/Grid'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'

import {
  ShiftConflictActionSelector,
  ShiftConflictActionSelectorProps,
} from '../ShiftConflictActionSelector'

// type StyleProps = FlexboxProps & LayoutProps & PositionProps & SpaceProps
type Props<T extends FieldValues> = ControllerBaseProps<T> &
  Omit<ShiftConflictActionSelectorProps, 'name' | 'onChange' | 'value'>

export function FormShiftConflictActionSelector<T extends FieldValues>({
  control,
  name,
  ...rest
}: Props<T>) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  return (
    <Flex {...rest} flexDirection="column">
      <ShiftConflictActionSelector
        {...rest}
        name={name}
        value={value}
        onChange={onChange}
      />
      {error && <Error error={error} />}
    </Flex>
  )
}

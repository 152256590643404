import React from 'react'
import { Controller, FieldValues } from 'react-hook-form'

import DateRangePicker from 'components/blocks/__v3__/DateRangePicker'
import { DateRangePickerProps } from 'components/blocks/__v3__/DateRangePicker/types'
import { Flex, Span } from 'components/ui/__v2__/Grid'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import { Error } from './components'

type DateRange = {
  start: string | null
  end: string | null
}

type AdditionalProps = Pick<
  DateRangePickerProps,
  'disablePast' | 'disableFuture'
>

type Props<T> = ControllerBaseProps<T> & AdditionalProps & LabelProps

export function FormSeparateDateRangePicker<T extends FieldValues>({
  control,
  name,
  labelText,
  required,
  disablePast,
  disableFuture,
}: Props<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const dateRange = value as DateRange

        return (
          <Flex flexDirection="column">
            <InputLabel>
              {labelText && (
                <Span>
                  {labelText} {required && <sup>*</sup>}
                </Span>
              )}
            </InputLabel>
            <DateRangePicker
              disableFuture={disableFuture}
              disablePast={disablePast}
              end={dateRange.end}
              minimalDays={2}
              start={dateRange.start}
              onChange={onChange}
            />
            {error && <Error error={error} />}
          </Flex>
        )
      }}
    />
  )
}

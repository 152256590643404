import React, { memo } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Button } from 'components/ui/__v2__/Button'
import { Modal } from 'components/ui/__v2__/Modal'
import { Spinner } from 'components/ui/Spinner'

import _ from 'i18n'

import { Buttons, Content, Title } from './styles'

const ConfirmationModal = ({
  isOpen,
  onClose,
  title,
  message,
  onConfirm,
  confirmText,
  cancelText,
  loading,
}) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Close onClose={onClose} />

      <Title>{title}</Title>

      <Content>{message}</Content>

      <Buttons>
        <Button secondary onClick={onClose}>
          {cancelText ?? _('actions.cancel')}
        </Button>

        <Button onClick={onConfirm}>
          {loading && <Spinner size={22} />}
          {confirmText ?? _('actions.yesRemove')}
        </Button>
      </Buttons>
    </Modal>
  )
}

ConfirmationModal.defaultProps = {
  isOpen: false,
  title: '',
  cancelText: null,
  confirmText: null,
  message: '',
  loading: false,
  onClose: noop,
  onConfirm: noop,
}

ConfirmationModal.propTypes = {
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  isOpen: PropTypes.bool,
  loading: PropTypes.bool,
  message: PropTypes.node || PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default memo(ConfirmationModal)

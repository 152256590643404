import { i18n } from 'i18n'

// TODO: Temporary solution, translated errors should come from Gateway
export const EMPLOYMENT_TYPE_ERRORS_I18N: {
  create: {
    messages: string[]
    translation: string
  }
  update: {
    message: string
    translation: string
  }
} = {
  create: {
    messages: [
      "13 INTERNAL: undefined method `external_id' for an instance of",
      'Employment Type external_id must be unique',
    ],
    translation: i18n('employmentTerms.employmentTypes.toasts.externalId'),
  },
  update: {
    message: 'Employment Type external_id must be unique',
    translation: i18n('employmentTerms.employmentTypes.toasts.externalId'),
  },
}

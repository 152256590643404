import { StoreonModule } from 'storeon'

import { omit } from 'lodash'

import { INIT } from './constants'

export const DELUXE_PAYROLL_SYNC_KEY = 'deluxePayrollSync'
export const DELUXE_PAYROLL_SYNC_SET = 'deluxePayrollSync/set'
export const DELUXE_PAYROLL_SYNC_UNSET = 'deluxePayrollSync/unset'

export interface DeluxePayrollSyncState {
  [DELUXE_PAYROLL_SYNC_KEY]: Record<string, string>
}

export interface DeluxePayrollSyncPayload {
  payrollAccountId: string
  syncProcessId: string
}

export interface DeluxePayrollSyncEvents {
  [DELUXE_PAYROLL_SYNC_SET]: DeluxePayrollSyncPayload
  [DELUXE_PAYROLL_SYNC_UNSET]: string
}

const INITIAL_STATE: DeluxePayrollSyncState['deluxePayrollSync'] = {}

export const deluxePayrollSyncModule: StoreonModule<
  DeluxePayrollSyncState,
  DeluxePayrollSyncEvents
> = store => {
  store.on(INIT, () => ({
    [DELUXE_PAYROLL_SYNC_KEY]: INITIAL_STATE,
  }))

  store.on(
    DELUXE_PAYROLL_SYNC_SET,
    (state, { payrollAccountId, syncProcessId }) => ({
      [DELUXE_PAYROLL_SYNC_KEY]: {
        ...state[DELUXE_PAYROLL_SYNC_KEY],
        [payrollAccountId]: syncProcessId,
      },
    }),
  )

  store.on(DELUXE_PAYROLL_SYNC_UNSET, (state, payrollAccountId) => ({
    [DELUXE_PAYROLL_SYNC_KEY]: omit(
      state[DELUXE_PAYROLL_SYNC_KEY],
      payrollAccountId,
    ),
  }))
}

import { Content } from './Content'
import { Header } from './Header'
import { Layout } from './Layout'
import { Title } from './Title'

export const Page = {
  Layout,
  Title,
  Content,
  Header,
}

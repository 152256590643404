import React from 'react'

import { DateTime } from 'luxon'

import { Divider } from 'components/ui/__v2__/Divider'

import Utils from 'services/Utils'

import { Actor, LogsRow, LogsTime } from './styles'

type Props = {
  // TODO: Gateway ShiftLog Type
  data: $TSFixMe
}

export function LogItem({ data }: Props) {
  const { id, updatedBy, createdAt } = data

  const actorName = updatedBy
  Utils.User.buildFullName(updatedBy.profile)

  const actor = `${actorName}`
  const createdAtDt = DateTime.fromISO(createdAt)
  const timestamp = `${createdAtDt.toLocaleString(
    DateTime.DATETIME_MED,
  )} at ${createdAtDt.toLocaleString(DateTime.TIME_SIMPLE)}`

  return (
    <LogsRow key={id}>
      <Actor>{actor}</Actor>
      {/* //TODO: ADD shift log item */}
      <LogsTime>{timestamp}</LogsTime>
      <Divider width={1} />
    </LogsRow>
  )
}

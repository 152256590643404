import React from 'react'

import { SelectOptionWithId } from 'Types/common'

import { Select } from 'components/ui/__v3__/Select'
import { PickerProps } from 'components/ui/__v3__/Select/types'

import { formatOptionLabel } from 'helpers/areas'

import { OPTIONS } from './constants'

export type ClassifierSourcePickerProps = Omit<
  PickerProps,
  'formatOptionLabel' | 'options' | 'value' | 'withPortal' | 'onChange'
> & {
  onChange: (value: SelectOptionWithId[]) => void
  value?: SelectOptionWithId
}

export function ClassifierSourcePicker({
  onChange,
  value,
  ...rest
}: ClassifierSourcePickerProps) {
  return (
    <Select
      // @ts-ignore
      formatOptionLabel={formatOptionLabel()}
      options={OPTIONS}
      value={value}
      withPortal
      onChange={onChange}
      {...rest}
    />
  )
}

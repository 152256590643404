export const LOGS_EVENTS = {
  pauseTimerUpdate: 'pause_timer_update',
  timeEntryUpdate: 'time_entry_update',
  unauthorise: 'unauthorise',
  authorise: 'authorise',
  adjust: 'adjust',
  pauseTimerAdjust: 'pause_timer_adjust',
  timeEntryCreate: 'time_entry_create',
  autoRound: 'auto_round',
  autoApprove: 'auto_approve',
  autoAdjust: 'auto_adjust',
  restore: 'restore',
  prayerAutoAdjust: 'prayer_auto_adjust',
  autoClockOut: 'auto_clock_out',
  autoClockOutAfterElapsedTime: 'auto_clock_out_after_elapsed_time',
  pendThroughTimeoff: 'pend_through_timeoff',
  autoApproveConflict: 'auto_approve_conflict',
  jobUpdate: 'job_update',
  approveFailed: 'approve_failed',
  hardStop: 'hard_stop',
} as const

export enum TimeCardStatus {
  Future = 'future',
  Active = 'active',
  NotAvailable = 'not_available',
  Absence = 'absence',
  Pending = 'pending',
  Approved = 'approved',
  Discarded = 'discarded',
}

export enum WageTypes {
  Hour = 'hour',
  Year = 'year',
}

export enum TimeCardKind {
  Manual = 'manual',
  OpenClock = 'open_clock',
  Scheduled = 'scheduled',
  OffSite = 'off_site',
  EmergencyOpenClock = 'emergency_open_clock',
  WeeklyTimesheet = 'weekly_timesheet',
}

export const TIME_CARD_KIND = {
  MANUAL: 'manual',
  OPEN_CLOCK: 'open_clock',
  SCHEDULED: 'scheduled',
  OFF_SITE: 'off_site',
  EMERGENCY_OPEN_CLOCK: 'emergency_open_clock',
  WEEKLY_TIMESHEET: 'weekly_timesheet',
} as const

import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { SENT_INVITE } from 'store/actions/employees/employees'
import {
  TOGGLE_GLOBAL_SETTINGS,
  TOGGLE_INVITE,
  TOGGLE_NOTIFICATIONS_SETTINGS,
  TOGGLE_TOKEN,
} from 'store/actions/header'

const initialState = Immutable({
  isShownGlobalSettings: false,
  isShownToken: false,
  isShownNotificationsSettings: false,
  isShownJobSeekingSettings: false,
  isShownInvite: false,
  isShownCustomFields: false,
})

const handlers = {
  [TOGGLE_NOTIFICATIONS_SETTINGS]: state =>
    state.merge({
      isShownNotificationsSettings: !state.isShownNotificationsSettings,
    }),
  [TOGGLE_INVITE]: (state, { payload: { inviteType } }) =>
    state.merge({
      isShownInvite: state.isShownInvite !== inviteType ? inviteType : false,
    }),
  [SENT_INVITE.SUCCESS]: state =>
    state.merge({
      isShownInvite: false,
    }),

  [TOGGLE_GLOBAL_SETTINGS]: state =>
    state.merge({ isShownGlobalSettings: !state.isShownGlobalSettings }),
  [TOGGLE_TOKEN]: state => state.merge({ isShownToken: !state.isShownToken }),
}

export default createReducer(initialState, handlers)

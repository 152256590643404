import { StoreonModule } from 'storeon'

import { INIT } from './constants'

export const ALDO_PAYROLL_CALC_KEY = 'aldoPayrollCalc'
export const ALDO_PAYROLL_CALC_SET = 'aldoPayrollCalc/set'
export const ALDO_PAYROLL_CALC_UNSET = 'aldoPayrollCalc/unset'

export const ALDO_PAYROLL_SYNC_KEY = 'aldoPayrollSync'
export const ALDO_PAYROLL_SYNC_SET = 'aldoPayrollSync/set'
export const ALDO_PAYROLL_SYNC_UNSET = 'aldoPayrollSync/unset'

export interface AldoPayrollSyncState {
  [ALDO_PAYROLL_CALC_KEY]: string | undefined
  [ALDO_PAYROLL_SYNC_KEY]: string | undefined
}

export interface AldoPayrollSyncPayload {
  calcProcessId: string
  syncProcessId: string
}

export interface AldoPayrollSyncEvents {
  [ALDO_PAYROLL_CALC_SET]: Pick<AldoPayrollSyncPayload, 'calcProcessId'>
  [ALDO_PAYROLL_CALC_UNSET]: never

  [ALDO_PAYROLL_SYNC_SET]: Pick<AldoPayrollSyncPayload, 'syncProcessId'>
  [ALDO_PAYROLL_SYNC_UNSET]: never
}

const INITIAL_STATE: AldoPayrollSyncState = {
  [ALDO_PAYROLL_CALC_KEY]: undefined,
  [ALDO_PAYROLL_SYNC_KEY]: undefined,
}

export const aldoPayrollSyncModule: StoreonModule<
  AldoPayrollSyncState,
  AldoPayrollSyncEvents
> = store => {
  store.on(INIT, () => ({ ...INITIAL_STATE }))

  store.on(ALDO_PAYROLL_CALC_SET, (state, { calcProcessId }) => ({
    ...state,
    [ALDO_PAYROLL_CALC_KEY]: calcProcessId,
  }))
  store.on(ALDO_PAYROLL_CALC_UNSET, state => ({
    ...state,
    [ALDO_PAYROLL_CALC_KEY]: undefined,
  }))

  store.on(ALDO_PAYROLL_SYNC_SET, (state, { syncProcessId }) => ({
    ...state,
    [ALDO_PAYROLL_SYNC_KEY]: syncProcessId,
  }))
  store.on(ALDO_PAYROLL_SYNC_UNSET, state => ({
    ...state,
    [ALDO_PAYROLL_SYNC_KEY]: undefined,
  }))
}

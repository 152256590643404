import { DateTime } from 'luxon'

import { DayPickerState } from './types'

export function selectingFirstDay(
  from: DateTime | null,
  to: DateTime | null,
  day: Date,
): boolean {
  const selectedDateTime = DateTime.fromJSDate(day)

  const isBeforeFirstDay = from && selectedDateTime < from

  const isRangeSelected = !!(from && to)
  return !from || isBeforeFirstDay || isRangeSelected
}

export function stateToResult(state: DayPickerState, dayOnly: boolean) {
  const { from, to } = state

  if (dayOnly) return from ? from.toISODate() : null

  return {
    from: from ? from.toISODate() : null,
    to: to ? to.toISODate() : null,
  }
}

import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import {
  ArchiveEmploymentLevelMutation,
  EmploymentLevelsByCursorQuery,
} from './GraphQL'

export function useArchiveEmploymentLevel() {
  const [innerArchive, { loading: archiving }] = useApolloMutation<
    MutationData<'archiveEmploymentLevel'>,
    Gateway.MutationArchiveEmploymentLevelArgs
  >(ArchiveEmploymentLevelMutation, {
    refetchQueries: compact([getOperationName(EmploymentLevelsByCursorQuery)]),
  })

  const archiveEmploymentLevel = async (id: string) =>
    innerArchive({
      variables: {
        id,
      },
    })

  return { archiveEmploymentLevel, archiving }
}

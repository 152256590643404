import { useMemo, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import { isCursorPageMetaChanged } from 'API/services/utils'

import {
  CustomCvFieldValuesByCursorQuery,
  CustomCvFieldValuesByCursorQueryData,
} from './GraphQL'

export function useCustomCvFieldValuesByCursor({
  paging,
  filter,
  sorting,
}: Gateway.QueryCustomCvFieldValuesByCursorArgs) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, loading, refetch } = useApolloQuery<
    CustomCvFieldValuesByCursorQueryData,
    Gateway.QueryCustomCvFieldValuesByCursorArgs
  >(CustomCvFieldValuesByCursorQuery, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      sorting,
      filter,
      paging,
    },

    onCompleted(data) {
      const newPageMeta = data.customCvFieldValuesByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const cvFieldValues = useMemo(
    () => data?.customCvFieldValuesByCursor?.edges.map(edge => edge.node) ?? [],
    [data?.customCvFieldValuesByCursor],
  )

  return {
    cvFieldValues,
    loading,
    refetch,
    currentPageMeta,
  }
}

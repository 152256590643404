import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import {
  EmploymentLevelSettingsByCursorQuery,
  UpsertEmploymentLevelSettingsMutation,
} from './GraphQL'

export function useUpsertEmploymentLevelSettings() {
  const [innerUpsert, { loading: upserting }] = useApolloMutation<
    MutationData<'upsertEmploymentLevelSettings'>,
    Gateway.MutationUpsertEmploymentLevelSettingsArgs
  >(UpsertEmploymentLevelSettingsMutation, {
    refetchQueries: compact([
      getOperationName(EmploymentLevelSettingsByCursorQuery),
    ]),
  })

  const upsertEmploymentLevelSetting = async (
    params: Gateway.UpsertEmploymentLevelSettingsInput,
  ) =>
    innerUpsert({
      variables: {
        input: params,
      },
    })

  return { upsertEmploymentLevelSetting, upserting }
}

// ================================================================================================
// Staff
// ================================================================================================
export enum StaffAccessLevel {
  Admin = 'admin',
  LocationManager = 'location_manager',
  DepartmentManager = 'department_manager',
  Employee = 'employee',
  TemporaryAccessLevel = 'temporary_access_level',
}

export enum StaffStatus {
  Pending = 'pending',
  Active = 'active',
  Archived = 'archived',
  CurrentlyWorking = 'currently_working',
}
// ================================================================================================
// Custom CV Field
// ================================================================================================
export enum CustomCvFieldType {
  Text = 'text',
  Email = 'email',
  Url = 'url',
  Phone = 'phone',
  Number = 'number',
  Decimal = 'decimal',
  Percent = 'percent',
  Date = 'date',
  Checkbox = 'checkbox',
  Dropdown = 'dropdown',
}

// ================================================================================================
// User
// ================================================================================================

export enum Gender {
  Male = 'male',
  Female = 'female',
}

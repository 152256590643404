import React from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { omit, pick } from '@styled-system/props'
import { themeGet } from '@styled-system/theme-get'

import { either, when } from 'components/ui/__v3__/__utils__/styled-system'

import { withDisabledOnClick } from 'helpers/misc'

import { Flex } from '../Grid'
import { Popover } from '../Popover'

const MenuOption = styled(Flex).attrs(withDisabledOnClick)`
  cursor: ${either('disabled', 'not-allowed', 'pointer')};
  background: ${either('disabled', themeGet('layout.disabled'), 'transparent')};
  user-select: none;
  color: ${themeGet('layout.text')};

  ${when(
    'disabled',
    css`
      border-bottom: thin solid ${themeGet('layout.lightBg')};

      &:last-of-type {
        border-bottom: none;
      }
    `,
  )};

  &:hover {
    background: ${either(
      'disabled',
      themeGet('layout.disabled'),
      themeGet('layout.highlight'),
    )};
  }
`

MenuOption.defaultProps = {
  px: 4,
  py: 2,
}

const MenuContent = styled(Flex)`
  font-weight: normal;
  font-size: 14px;
`

MenuContent.defaultProps = {
  py: 2,
  flexDirection: 'column',
}

function Menu({ ...rest }) {
  return <Popover interactive noArrow p={0} {...pick(rest)} {...omit(rest)} />
}

Menu.Option = MenuOption
Menu.Content = MenuContent

Menu.propTypes = {
  ...Popover.propTypes,
  borderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offset: PropTypes.arrayOf(PropTypes.number),
}

Menu.defaultProps = {
  borderWidth: 'thin',
  offset: [0, 4],
}

export default Menu

import {
  CustomLabelDaysPicker,
  DepartmentsPicker,
  EmployeesPicker,
  FormTemplatePicker,
  JobsEmployeesPicker,
  JobsPicker,
  LocationsPicker,
  StaffPicker,
} from 'components/blocks/__v2__'
import { GroupsPicker } from 'components/blocks/__v3__/GroupsPicker'
import { PayFrequenciesPicker } from 'components/blocks/__v3__/PayFrequenciesPicker'

import FormProfileAvatar from './FormProfileAvatar/FormProfileAvatar'
import FormCheckbox from './FormCheckbox'
import FormDayPicker from './FormDayPicker'
import FormFileUpload from './FormFileUpload'
import FormInput from './FormInput'
import FormMaskedInput from './FormMaskedInput'
import FormMultiSelect from './FormMultiSelect'
import FormPhone from './FormPhone'
import createFormPicker from './FormPicker'
import FormPlainTextSelect from './FormPlainTextSelect'
import FormPostImage from './FormPostImage'
import FormRadio from './FormRadio'
import FormSelect from './FormSelect'
import FormSwitch from './FormSwitch'
import FormTimeSelect from './FormTimeSelect'
import WhenFieldChanges from './WhenFieldChanges'

const FormCustomLabelDaysPicker = createFormPicker(CustomLabelDaysPicker)
const FormLocationsPicker = createFormPicker(LocationsPicker)
const FormDepartmentsPicker = createFormPicker(DepartmentsPicker)
const FormJobsPicker = createFormPicker(JobsPicker)
const FormEmployeesPicker = createFormPicker(EmployeesPicker)
const FormStaffPicker = createFormPicker(StaffPicker)
const FormJobsEmployeesPicker = createFormPicker(JobsEmployeesPicker)
const FormFormTemplatePicker = createFormPicker(FormTemplatePicker)
const FormGroupsPicker = createFormPicker(GroupsPicker)
const FormPayFrequenciesPicker = createFormPicker(PayFrequenciesPicker)

export {
  FormCheckbox,
  FormCustomLabelDaysPicker,
  FormDayPicker,
  FormDepartmentsPicker,
  FormEmployeesPicker,
  FormFileUpload,
  FormFormTemplatePicker,
  FormGroupsPicker,
  FormInput,
  FormJobsEmployeesPicker,
  FormJobsPicker,
  FormLocationsPicker,
  FormMaskedInput,
  FormMultiSelect,
  FormPayFrequenciesPicker,
  FormPhone,
  FormPlainTextSelect,
  FormPostImage,
  FormProfileAvatar,
  FormRadio,
  FormSelect,
  FormStaffPicker,
  FormSwitch,
  FormTimeSelect,
  WhenFieldChanges,
}

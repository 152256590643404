import styled from 'styled-components'

import SvgIcons from 'assets/images/__v2__/svg'

import { SvgIcon } from 'components/ui/__v2__/SvgIcon'

const CrossRound = styled(SvgIcon).attrs({
  glyph: SvgIcons.CrossRound,
})``

CrossRound.defaultProps = {
  size: 14,
}

export default CrossRound

import { EmploymentLevelPickerFields } from 'API/EmploymentLevel/GraphQL/Fragments/types'

import groupBy from 'lodash/groupBy'

import { EmploymentLevelPickerOption } from '../types'

export function employmentLevelToOption(
  employmentLevel: EmploymentLevelPickerFields,
): EmploymentLevelPickerOption {
  return {
    label: employmentLevel.name,
    value: employmentLevel.id,
    data: employmentLevel,
  }
}

export function groupOptions(employmentLevels: EmploymentLevelPickerFields[]) {
  const options = employmentLevels.map(employmentLevelToOption)
  const groupedOptions = groupBy(
    options,
    option => option.data.employmentType?.name,
  )

  const newOptions = Object.entries(groupedOptions)
    .map(([key, value]) => ({
      label: key,
      options: value,
    }))
    .sort()

  return newOptions
}

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'

import { when } from 'components/ui/__v3__/__utils__/styled-system'

import { BaseButton } from './BaseButton'
import { ButtonProps } from './Button'

const xSmallSizeCss = css`
  height: ${({ theme }) => theme.buttons.height.xSmall}px;
  width: ${({ theme }) => theme.buttons.height.xSmall}px;
`
const smallSizeCss = css`
  height: ${({ theme }) => theme.buttons.height.small}px;
  width: ${({ theme }) => theme.buttons.height.small}px;
`
const defaultSizeCss = css`
  height: ${({ theme }) => theme.buttons.height.default}px;
  width: ${({ theme }) => theme.buttons.height.default}px;
`

const sizeCss = ({ small, xSmall }: ButtonProps) => {
  if (xSmall) {
    return xSmallSizeCss
  }
  if (small) {
    return smallSizeCss
  }
  return defaultSizeCss
}

export const IconButton = styled(BaseButton)`
  ${sizeCss};

  color: ${mapToTheme('buttons.icon.color')};
  background-color: ${mapToTheme('buttons.icon.backgroundColor')};

  border-color: ${mapToTheme('buttons.icon.border')};

  &:hover {
    color: ${mapToTheme('buttons.icon.hover.color')};
    border-color: ${mapToTheme('buttons.icon.hover.border')};
    background-color: ${mapToTheme('buttons.icon.hover.backgroundColor')};
  }

  &:active {
    color: ${mapToTheme('buttons.icon.active')};
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    background-color: ${mapToTheme('buttons.disabled.backgroundColor')};
  }

  ${when(
    'secondary',
    css`
      border-style: solid;
      border-width: 1px;
    `,
  )}
`

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

export const LOAD_SECTORS = createAsyncAction('sectors/LOAD')

export const loadSectors = () => {
  return apiCall({
    endpoint: '/sectors',
    types: LOAD_SECTORS,
  })
}

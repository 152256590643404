import coreApiCall from 'services/API/coreApi'

type LocationQueryArgs = {
  locationId: string
  include?: string
}

export function locationQuery({ locationId, include }: LocationQueryArgs) {
  const query: JsonApi.LocationArgs = {
    include,
  }

  return coreApiCall({
    endpoint: `/branches/${locationId}`,
    query,
  })
}

import React from 'react'
import { FieldError, FieldValues, useController } from 'react-hook-form'

import { DateTime } from 'luxon'

import { DayPicker } from 'components/ui/__v2__/DayPicker'
import { DayRangeProps } from 'components/ui/__v2__/DayPicker/types'
import { Flex } from 'components/ui/__v2__/Grid'

import { Error } from './components'
import { ControllerBaseProps } from './types'

export type DayRangeValue = {
  from?: string | null
  to?: string | null
}

type DayRangeError = {
  from: FieldError
  to: FieldError
}
function isDayRangeError(
  error: FieldError | DayRangeError,
): error is DayRangeError {
  return (error as DayRangeError).from !== undefined
}

export type FormDayRangePickerProps<
  T extends FieldValues
> = ControllerBaseProps<T> & Omit<DayRangeProps, 'dayOnly' | 'onChange'>

export function FormDayRangePicker<T extends FieldValues>({
  control,
  name,
  ...rest
}: FormDayRangePickerProps<T>) {
  const {
    field: fieldProps,
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  const { onChange, value, onBlur } = fieldProps

  const { from, to } = value as DayRangeValue

  return (
    <Flex flexDirection="column">
      <DayPicker
        {...rest}
        dayOnly={false}
        from={from ? DateTime.fromISO(from).toJSDate() : undefined}
        to={to ? DateTime.fromISO(to).toJSDate() : undefined}
        onBlur={onBlur}
        onChange={onChange}
      />
      {error && <Error error={isDayRangeError(error) ? error.from : error} />}
    </Flex>
  )
}

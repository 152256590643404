import React from 'react'

import { themeGet } from '@styled-system/theme-get'

const OutlineSvgIcon = () => {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66696 8.86672C5.14561 8.86672 5.53363 8.47871 5.53363 8.00007C5.53363 7.52142 5.14561 7.13341 4.66696 7.13341C4.18831 7.13341 3.80029 7.52142 3.80029 8.00007C3.80029 8.47871 4.18831 8.86672 4.66696 8.86672Z"
        fill={`${themeGet('layout.primary')}`}
      />
      <path
        d="M8.0003 8.86672C8.47894 8.86672 8.86696 8.47871 8.86696 8.00007C8.86696 7.52142 8.47894 7.13341 8.0003 7.13341C7.52165 7.13341 7.13363 7.52142 7.13363 8.00007C7.13363 8.47871 7.52165 8.86672 8.0003 8.86672Z"
        fill={`${themeGet('layout.primary')}`}
      />
      <path
        d="M12.2003 8.00007C12.2003 8.47871 11.8123 8.86672 11.3336 8.86672C10.855 8.86672 10.467 8.47871 10.467 8.00007C10.467 7.52142 10.855 7.13341 11.3336 7.13341C11.8123 7.13341 12.2003 7.52142 12.2003 8.00007Z"
        fill={`${themeGet('layout.primary')}`}
      />
    </svg>
  )
}

export default OutlineSvgIcon

import { useMemo } from 'react'

import { useApolloQuery } from 'API/services/Apollo'

import {
  EmployeeJobsByCursorQuery,
  EmployeeJobsByCursorQueryData,
} from './GraphQL'

export function useEmployeeJobsByCursor({
  paging,
  filter,
  sorting,
}: Gateway.QueryEmployeeJobsByCursorArgs) {
  const { data, loading, refetch } = useApolloQuery<
    EmployeeJobsByCursorQueryData,
    Gateway.QueryEmployeeJobsByCursorArgs
  >(EmployeeJobsByCursorQuery, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      sorting,
      filter,
      paging,
    },
  })

  const employeeJobs = useMemo(
    () => data?.employeeJobsByCursor?.edges.map(edge => edge.node) ?? [],
    [data?.employeeJobsByCursor],
  )

  return {
    employeeJobs,
    loading,
    refetch,
    pageInfo: data?.employeeJobsByCursor?.pageInfo,
  }
}

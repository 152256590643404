import { ScheduleConflictActionTypes } from 'constants/ids'

import coreApiCall from 'services/API/coreApi'

import { MutateAvailabilityRequestArgs } from '../../types'

export function mutateAvailabilityRequestMutation({
  notes,
  conflictAction,
  requestId,
  mode,
}: MutateAvailabilityRequestArgs) {
  const query: JsonApi.MutateAvailabilityRequestArgs = {
    notes,
    schedule_conflict_type: conflictAction ?? ScheduleConflictActionTypes.None,
  }

  return coreApiCall({
    method: 'POST',
    endpoint: `/availability_requests/${requestId}/${mode}`,
    query,
  })
}

import { useState } from 'react'

import { download } from 'API/services/JSON'
import qs from 'qs'

import { useAppContext, useLocale } from 'hooks'

export function useExportAutoSchedulingAssignments() {
  const [exporting, setExporting] = useState(false)
  const { companyId } = useAppContext()
  const locale = useLocale()

  async function exportAutoSchedulingAssignments(filter: {
    autoSchedulingTaskId: string
  }) {
    const queryString = qs.stringify(
      {
        locale,
        // Note: hasEmployee is not included in the exposed filters, so only using the autoSchedulingTaskId for the user to filter in Excel later
        filter: {
          auto_scheduling_task_id: {
            eq: filter.autoSchedulingTaskId,
          },
        },
      },
      { skipNulls: true, arrayFormat: 'brackets' },
    )

    setExporting(true)
    await download({
      companyId,
      queryString,
      kind: 'auto_scheduling_assignments_export',
    })
    setExporting(false)
  }

  return {
    exporting,
    exportAutoSchedulingAssignments,
  }
}

import * as colors from './colors'

export const toasts = {
  primaryColor: {
    success: colors.SUCCESS_LIGHT,
    info: colors.HAWKES_BLUE,
    warning: colors.WARNING_LIGHT,
    error: colors.ERROR_LIGHT,
    text: colors.EBONY_CLAY,
  },
  secondaryColor: {
    success: colors.SUCCESS,
    info: colors.DODGER_BLUE,
    warning: colors.WARNING,
    error: colors.ERROR,
  },
}

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

export const ANNUAL_REVIEW = {
  LOAD: createAsyncAction('annual_review/LOAD'),
  CREATE: createAsyncAction('annual_review/CREATE'),
  UPDATE: createAsyncAction('annual_review/UPDATE'),
  DELETE: createAsyncAction('annual_review/DELETE'),
}

const INCLUDE = ['employee', 'attachment'].join(',')

export const loadPerformanceReview = ({
  employeeId,
  number,
  size,
  sort = '-date,created_at',
}) => dispatch => {
  const paged = !!number && !!size
  const page = paged ? { number, size } : {}

  return dispatch(
    apiCall({
      endpoint: `/employees/${employeeId}/perfomance_reviews`,
      types: ANNUAL_REVIEW.LOAD,
      method: 'GET',
      query: {
        include: INCLUDE,
        page,
        sort,
      },
      paged,
    }),
  )
}

export const createPerformanceReview = ({
  employeeId,
  attributes,
  attachmentId,
}) => dispatch => {
  const attachment = attachmentId
    ? {
        attachment: {
          data: {
            id: attachmentId,
            type: 'files',
          },
        },
      }
    : null
  dispatch(
    apiCall({
      endpoint: `/employees/${employeeId}/perfomance_reviews`,
      types: ANNUAL_REVIEW.CREATE,
      method: 'POST',
      query: {
        include: INCLUDE,
        data: {
          attributes,
          relationships: {
            ...attachment,
          },
        },
      },
    }),
  )
}

export const updatePerformanceReview = ({
  performanceReviewId,
  attributes,
  relationships,
}) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/perfomance_reviews/${performanceReviewId}`,
      types: ANNUAL_REVIEW.UPDATE,
      method: 'PATCH',
      query: {
        include: INCLUDE,
        data: {
          attributes,
          relationships,
        },
      },
    }),
  )
}

export const deletePerformanceReview = performanceReviewId => dispatch =>
  dispatch(
    apiCall({
      endpoint: `/perfomance_reviews/${performanceReviewId}`,
      types: ANNUAL_REVIEW.DELETE,
      method: 'DELETE',
      payload: {
        deletedId: performanceReviewId,
      },
    }),
  )

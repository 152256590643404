import React, { PropsWithChildren } from 'react'

import { MdInfo } from 'react-icons/md'

import styled from 'styled-components'

const IconErrorText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.input.errors.icon.color};
`

const IconContainer = styled.span`
  color: ${({ theme }) => theme.icons.primary};
  margin-right: 4px;
  cursor: help;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
`

interface IconErrorProps {}
export const IconError = (props: PropsWithChildren<IconErrorProps>) => {
  const { children } = props

  return (
    <>
      <IconContainer>
        <MdInfo />
      </IconContainer>
      <IconErrorText>{children}</IconErrorText>
    </>
  )
}

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import {
  DepartmentsPicker,
  EmployeesPicker,
  JobsEmployeesPicker,
  JobsPicker,
  LocationsPicker,
} from 'components/blocks/__v2__'
import { Switch } from 'components/ui/__v2__/Switch'

import { AREAS_ITEM_TYPES } from 'constants/areas'

import { i18n } from 'i18n'

import Utils from 'services/Utils'

import {
  ColumnHeader,
  Container,
  GlobalSwitch,
  PickerItem,
  Pickers,
  PickersContainer,
} from './styles'

import { getNextAreasValue } from '../helper/change'

const initialValues = {
  [AREAS_ITEM_TYPES.locations]: [],
  [AREAS_ITEM_TYPES.departments]: [],
  [AREAS_ITEM_TYPES.jobs]: [],
  [AREAS_ITEM_TYPES.employees]: [],
  [AREAS_ITEM_TYPES.jobsEmployees]: [],
}

export const PICKER_ORIENTATION = { COLUMN: 'column', ROW: 'row' }

function Picker({
  cascade,
  departmentIds,
  exclude,
  display,
  include,
  isClearable,
  isDisabled,
  isMulti,
  jobIds,
  locationIds,
  orientation,
  preload,
  value,
  withEmployees,
  withGlobal,
  withJobsEmployees,
  withPortal,
  withSwitcher,
  withLocations,
  withDepartments,
  withRoles,
  onChange,
  onPreload,
  ...rest
}) {
  const handleChangeLocations = useCallback(
    options => {
      if (options?.type !== 'change') {
        const nextValue = getNextAreasValue({
          previousValue: value,
          newItems: options,
          changedItemType: AREAS_ITEM_TYPES.locations,
          cascade,
        })

        onChange(nextValue)
      }
    },
    [cascade, onChange, value],
  )

  const handleChangeDepartments = useCallback(
    options => {
      if (options?.type !== 'change') {
        const nextValue = getNextAreasValue({
          previousValue: value,
          newItems: options,
          changedItemType: AREAS_ITEM_TYPES.departments,
          cascade,
        })

        onChange(nextValue)
      }
    },
    [cascade, onChange, value],
  )

  const handleChangeJobs = useCallback(
    options => {
      if (options?.type !== 'change') {
        const nextValue = getNextAreasValue({
          previousValue: value,
          newItems: options,
          changedItemType: AREAS_ITEM_TYPES.jobs,
          cascade,
        })

        onChange(nextValue)
      }
    },
    [cascade, onChange, value],
  )

  const handleChangeEmployees = useCallback(
    options => {
      if (options?.type !== 'change') {
        const nextValue = getNextAreasValue({
          previousValue: value,
          newItems: options,
          changedItemType: AREAS_ITEM_TYPES.employees,
          cascade: false,
        })
        onChange(nextValue)
      }
    },
    [onChange, value],
  )

  const handleChangeJobsEmployees = useCallback(
    options => {
      if (options?.type !== 'change') {
        const nextValue = getNextAreasValue({
          previousValue: value,
          newItems: options,
          changedItemType: AREAS_ITEM_TYPES.jobsEmployees,
          cascade: false,
        })
        onChange(nextValue)
      }
    },
    [onChange, value],
  )

  const handleChangeGlobal = useCallback(
    ({ target: { checked } }) => {
      onChange({ ...initialValues, global: checked })
    },
    [onChange],
  )

  const createPreloadHandler = useCallback(
    type => loader => (...args) => onPreload(type, ...[...args, loader]),
    [onPreload],
  )

  const {
    global = false,
    locations = [],
    departments = [],
    employees = [],
    jobs = [],
    jobsEmployees = [],
  } = value

  const localLocationIds = Utils.Areas.getAreasIds(locations)
  const localDepartmentIds = Utils.Areas.getAreasIds(departments)
  const localJobIds = Utils.Areas.getAreasIds(jobs)

  const applyTo = [
    i18n('common.departments').toLowerCase(),
    i18n('common.roles').toLowerCase(),
  ]

  if (!withLocations) {
    applyTo.unshift(i18n('common.locations').toLowerCase())
  }

  if (withEmployees || withJobsEmployees) {
    applyTo.push(i18n('common.employees').toLowerCase())
  }

  return (
    <Container {...rest}>
      {withGlobal && (
        <GlobalSwitch>
          {i18n('common.applyToAll')} {applyTo.join('/')}
          <Switch checked={global} ml={2} onChange={handleChangeGlobal} />
        </GlobalSwitch>
      )}
      <Pickers isOpen={!global}>
        <PickersContainer orientation={orientation}>
          {withLocations && (
            <PickerItem>
              <ColumnHeader>{i18n('common.locationsSeparator')}</ColumnHeader>
              <LocationsPicker
                display={display?.locations ?? false}
                exclude={exclude?.locations}
                include={include?.locations}
                isClearable={isClearable?.locations ?? true}
                isDisabled={isDisabled?.locations ?? false}
                isMulti={isMulti[AREAS_ITEM_TYPES.locations]}
                preload={preload?.locations ?? false}
                value={locations}
                withPortal={withPortal}
                withSwitcher={withSwitcher}
                onChange={handleChangeLocations}
                onPreload={createPreloadHandler('locations')}
              />
            </PickerItem>
          )}

          {withDepartments && (
            <PickerItem>
              <ColumnHeader>{i18n('common.departmentsSeparator')}</ColumnHeader>
              <DepartmentsPicker
                cacheOptions={false}
                display={display?.departments ?? false}
                exclude={exclude?.departments}
                include={include?.departments}
                isClearable={isClearable?.departments ?? true}
                isDisabled={isDisabled?.departments ?? false}
                isMulti={isMulti[AREAS_ITEM_TYPES.departments]}
                locationIds={locationIds ?? (cascade ? localLocationIds : null)}
                preload={preload?.departments ?? false}
                value={departments}
                withPortal={withPortal}
                withSwitcher={withSwitcher}
                onChange={handleChangeDepartments}
                onPreload={createPreloadHandler('departments')}
              />
            </PickerItem>
          )}

          {withRoles && (
            <PickerItem>
              <ColumnHeader>{i18n('common.rolesSeparator')}</ColumnHeader>
              <JobsPicker
                cacheOptions={false}
                departmentIds={
                  departmentIds ?? (cascade ? localDepartmentIds : null)
                }
                display={display?.jobs ?? null}
                exclude={exclude?.jobs}
                include={include?.jobs}
                isClearable={isClearable?.jobs ?? true}
                isDisabled={isDisabled?.jobs ?? false}
                isMulti={isMulti[AREAS_ITEM_TYPES.jobs]}
                locationIds={locationIds ?? (cascade ? localLocationIds : null)}
                preload={preload?.jobs ?? false}
                value={jobs}
                withPortal={withPortal}
                withSwitcher={withSwitcher}
                onChange={handleChangeJobs}
                onPreload={createPreloadHandler('jobs')}
              />
            </PickerItem>
          )}

          {withEmployees && (
            <PickerItem>
              <ColumnHeader mt={3}>{i18n('common.employees')}</ColumnHeader>

              <EmployeesPicker
                cacheOptions={false}
                departmentIds={
                  departmentIds ?? (cascade ? localDepartmentIds : null)
                }
                exclude={exclude?.employees}
                include={include.employees}
                isClearable={isClearable?.employees ?? true}
                isDisabled={isDisabled?.employees ?? false}
                isMulti={isMulti[AREAS_ITEM_TYPES.employees]}
                jobIds={jobIds ?? (cascade ? localJobIds : null)}
                locationIds={locationIds ?? (cascade ? localLocationIds : null)}
                preload={preload?.employees ?? false}
                showJobs
                value={employees}
                withPortal={withPortal}
                withSwitcher={withSwitcher}
                onChange={handleChangeEmployees}
                onPreload={createPreloadHandler('employees')}
              />
            </PickerItem>
          )}
          {withJobsEmployees && (
            <PickerItem>
              <ColumnHeader>{i18n('common.employees')}</ColumnHeader>

              <JobsEmployeesPicker
                cacheOptions={false}
                departmentIds={
                  departmentIds ?? (cascade ? localDepartmentIds : null)
                }
                exclude={exclude?.jobsEmployees}
                include={include.jobsEmployees}
                isClearable={isClearable?.jobsEmployees ?? true}
                isDisabled={isDisabled?.jobsEmployees ?? false}
                isMulti={isMulti[AREAS_ITEM_TYPES.jobsEmployees]}
                jobIds={jobIds ?? (cascade ? localJobIds : null)}
                locationIds={locationIds ?? (cascade ? localLocationIds : null)}
                preload={preload?.jobsEmployees ?? false}
                showJobs
                value={jobsEmployees}
                withPortal={withPortal}
                withSwitcher={withSwitcher}
                onChange={handleChangeJobsEmployees}
                onPreload={createPreloadHandler('jobsEmployees')}
              />
            </PickerItem>
          )}
        </PickersContainer>
      </Pickers>
    </Container>
  )
}

Picker.defaultProps = {
  cascade: false,
  departmentIds: null,
  display: getAreasDefaultProps(null),
  exclude: getAreasDefaultProps(null),
  include: getAreasDefaultProps(null),
  isClearable: getAreasDefaultProps(true),
  isDisabled: getAreasDefaultProps(false),
  isMulti: getAreasDefaultProps(true),
  jobIds: null,
  locationIds: null,
  preload: getAreasDefaultProps(false),
  orientation: PICKER_ORIENTATION.COLUMN,
  value: getAreasDefaultProps([]),
  withEmployees: false,
  withGlobal: false,
  withJobsEmployees: false,
  withPortal: true,
  withSwitcher: false,
  withLocations: true,
  withDepartments: true,
  withRoles: true,
  onChange: noop,
  onPreload: noop,
}

Picker.propTypes = {
  cascade: PropTypes.bool,
  departmentIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  display: PropTypes.shape(getAreasPropsShape(PropTypes.string)),
  exclude: PropTypes.shape(getAreasPropsShape(PropTypes.array)),
  include: PropTypes.shape(getAreasPropsShape(PropTypes.string)),
  isClearable: PropTypes.shape(getAreasPropsShape(PropTypes.bool)),
  isDisabled: PropTypes.shape(getAreasPropsShape(PropTypes.bool)),
  isMulti: PropTypes.shape(getAreasPropsShape(PropTypes.bool)),
  jobIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  locationIds: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  orientation: PropTypes.oneOf(Object.values(PICKER_ORIENTATION)),
  preload: PropTypes.shape(getAreasPropsShape(PropTypes.bool)),
  value: PropTypes.shape(
    getAreasPropsShape(
      PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    ),
  ),
  withDepartments: PropTypes.bool,
  withEmployees: PropTypes.bool,
  withGlobal: PropTypes.bool,
  withJobsEmployees: PropTypes.bool,
  withLocations: PropTypes.bool,
  withPortal: PropTypes.bool,
  withRoles: PropTypes.bool,
  withSwitcher: PropTypes.bool,
  // eslint-disable-next-line react/sort-prop-types
  onChange: PropTypes.func,
  onPreload: PropTypes.func,
}

export default Picker

function getAreasPropsShape(type) {
  return {
    locations: type,
    departments: type,
    jobs: type,
    employees: type,
    jobsEmployees: type,
  }
}

function getAreasDefaultProps(defaultValue) {
  return {
    locations: defaultValue,
    departments: defaultValue,
    jobs: defaultValue,
    jobsEmployees: defaultValue,
    employees: defaultValue,
  }
}

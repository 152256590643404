import React from 'react'

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { PICTON_BLUE } from 'theme/__v2__/light/colors'

type HoursChartProps = {
  data: {
    name: string
    hours: number
  }[]
  hidden: boolean
}

const BAR_CHART_STYLES = {
  top: 5,
  right: 30,
  bottom: 5,
  left: -10,
}

export function HoursChart({ data, hidden }: HoursChartProps) {
  if (hidden) return null
  return (
    <ResponsiveContainer height="40%" width="105%">
      <BarChart data={data} margin={BAR_CHART_STYLES}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="hours" fill={PICTON_BLUE} name="Heures totales" />
      </BarChart>
    </ResponsiveContainer>
  )
}

import { createDeleteHandler, createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { EMPLOYEE_STATE_FILTERS, INVITE_ERROR_ID } from 'constants/employees'
import { ACCESS_LEVEL } from 'constants/ids'

import { createReducer, getFirstEntity } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  ARCHIVE_EMPLOYEE,
  CHANGE_EMPLOYEE_PASSWORD,
  CHECK_ACTIVE_TIMER,
  EMPLOYEE_DATA_READY,
  EMPLOYEE_UPDATE_AVATAR,
  EMPLOYEE_UPLOAD_AVATAR,
  FILTER_EMPLOYEES,
  LOAD_EMPLOYEE_BY_ID,
  LOAD_EMPLOYEE_BY_ID_SILENT,
  LOAD_EMPLOYEE_STATISTICS,
  PROCESS_FAILURE_INVITE,
  REACTIVATE_EMPLOYEE,
  REMOVE_USER_FROM_COMPANY,
  SELECT_MANAGER,
  SENT_INVITE,
  TOGGLE_ARCHIVE,
  UNLOAD_EMPLOYEE,
  UPDATE_EMPLOYEE_CUSTOM_CV_FIELDS,
} from 'store/actions/employees/employees'

const initialState = Immutable({
  error: '',
  employees: [],
  employee: null,
  managerId: null,
  employeeStatistics: {},
  newEmployeeAvatarId: null,
  isUploadingEmployeeAvatar: false,
  isUpdatingEmployeeAvatar: false,
  isLoading: false,
  isStatisticsLoading: false,
  isLoaded: false,
  isEmployeeLoaded: false,
  isEmployeeLoading: false,
  employeeCustomCvFields: [],
  isEmployeeCustomCvFieldsLoading: false,
  showArchive: false,
  hasActiveTimerEntry: false,
  filter: EMPLOYEE_STATE_FILTERS.all,
  alreadyInvitedError: null,
  allEmployeeIds: [],
})

const handlers = {
  [EMPLOYEE_UPLOAD_AVATAR.REQUEST]: state =>
    state.merge({ isUploadingEmployeeAvatar: true }),
  [EMPLOYEE_UPLOAD_AVATAR.SUCCESS]: createLoadHandler('files', {
    singular: true,
    mapToKey: 'newEmployeeAvatarId',
    addToState: {
      isUploadingEmployeeAvatar: false,
    },
    withLoading: false,
  }),
  [EMPLOYEE_UPLOAD_AVATAR.FAILURE]: state =>
    state.merge({ isUploadingEmployeeAvatar: false }),
  [EMPLOYEE_UPDATE_AVATAR.REQUEST]: state =>
    state.merge({ isUpdatingEmployeeAvatar: true }),
  [EMPLOYEE_UPDATE_AVATAR.SUCCESS]: state =>
    state.merge({ isUpdatingEmployeeAvatar: false }),
  [EMPLOYEE_UPDATE_AVATAR.FAILURE]: state =>
    state.merge({ isUpdatingEmployeeAvatar: false }),

  [REMOVE_USER_FROM_COMPANY.SUCCESS]: (state, action) => {
    const result = createDeleteHandler('employees')(state, action)

    const employees = get(result, 'employees')
    const employee = get(result, 'employee')

    return state.merge({
      employees,
      employee,
    })
  },

  [UPDATE_EMPLOYEE_CUSTOM_CV_FIELDS.SUCCESS]: createLoadHandler(
    'customCvFieldValues',
    { withLoading: false, mapToKey: 'employeeCustomCvFields' },
  ),

  [LOAD_EMPLOYEE_BY_ID.REQUEST]: state =>
    state.merge({
      employee: null,
      isEmployeeLoading: true,
      isEmployeeLoaded: false,
    }),

  [LOAD_EMPLOYEE_BY_ID.SUCCESS]: createLoadHandler('employees', {
    mapToKey: 'employee',
    singular: true,
  }),

  [LOAD_EMPLOYEE_BY_ID_SILENT.SUCCESS]: createLoadHandler('employees', {
    withLoading: false,
    mapToKey: 'employee',
    singular: true,
  }),

  [UNLOAD_EMPLOYEE]: state => state.merge({ employee: null, managerId: null }),

  [SELECT_MANAGER]: (state, action) =>
    state.merge({ managerId: action.payload.managerId }),

  [LOAD_EMPLOYEE_STATISTICS.REQUEST]: state =>
    state.merge({ isStatisticsLoading: true }),

  [LOAD_EMPLOYEE_STATISTICS.SUCCESS]: (state, { payload }) => {
    const employee = getFirstEntity(payload.data.employees)
    const statistics = get(
      payload,
      `data.meta./employees/${employee.id}.meta`,
      {},
    )
    return state.merge({
      employeeStatistics: statistics,
      isStatisticsLoading: false,
    })
  },

  [LOAD_EMPLOYEE_STATISTICS.FAILURE]: state =>
    state.merge({
      employeeStatistics: {},
      isStatisticsLoading: false,
    }),

  [EMPLOYEE_DATA_READY]: state =>
    state.merge({
      isEmployeeLoading: false,
      isEmployeeLoaded: true,
    }),

  [ARCHIVE_EMPLOYEE.SUCCESS]: createLoadHandler('employees'),

  [REACTIVATE_EMPLOYEE.SUCCESS]: createLoadHandler('employees'),

  [CHANGE_EMPLOYEE_PASSWORD.SUCCESS]: createLoadHandler('employees'),

  [SENT_INVITE.FAILURE]: (state, { payload }) => {
    const errors = get(payload, 'errors', [])
    const error = find(errors, item => item.id === INVITE_ERROR_ID)
    if (error) {
      const accessLevel = get(error, 'meta.accessLevel')
      const locations = get(error, 'meta.branches')
      const userId = get(error, 'meta.userId')
      if (accessLevel === ACCESS_LEVEL.owner) {
        // just show toast in this case
        return state
      }
      return state.merge({
        alreadyInvitedError: { userId, accessLevel, locations },
      })
    }
    return state
  },
  [PROCESS_FAILURE_INVITE]: state => state.merge({ alreadyInvitedError: null }),

  [FILTER_EMPLOYEES]: (state, { payload }) =>
    state.merge({
      filter: payload.filter,
    }),

  [CHECK_ACTIVE_TIMER.SUCCESS]: (state, { payload }) => {
    const userId = get(
      payload.data,
      `employees.${state.employee}.relationships.user.data.id`,
      '',
    )
    const hasActiveTimerEntry = !isEmpty(
      get(payload.data, `users.${userId}.relationships.activeTimeEntry.data`),
    )
    return state.merge({ hasActiveTimerEntry })
  },

  [TOGGLE_ARCHIVE]: (state, { payload }) =>
    state.merge({ showArchive: payload.showArchive }),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import React, { ReactNode } from 'react'

import { PayFrequencyAPI } from 'API'

import { TextOverflow } from 'components/ui/__v2__/TextOverflow'
import { Select } from 'components/ui/__v3__/Select'

import { i18n } from 'i18n'

import {
  payFrequencyEntityToOption,
  PayFrequencyOption,
} from 'services/Options'

import { PayFrequencyDetails } from './PayFrequencyDetails'
import { OptionContainer } from './styles'
import { PayFrequenciesPickerProps } from './types'

export function PayFrequenciesPicker({
  isDisabled,
  placeholder,
  loadingText,
  value,
  isMulti,
  ...rest
}: PayFrequenciesPickerProps) {
  const {
    loadPayFrequencies,
    defaultPayFrequencies,
    loading,
    refetch,
  } = PayFrequencyAPI.getByOffsetLazy({})

  // prettier-ignore
  const defaultOptions = defaultPayFrequencies.map(payFrequencyEntityToOption) ?? []

  const handleLoadOptions = async (inputValue: string, callback: Function) => {
    const refetchedData =
      refetch && (await refetch({ filter: { name: { like: inputValue } } }))
    const newOptions =
      refetchedData?.data.payFrequenciesByOffset?.rows.map(
        payFrequencyEntityToOption,
      ) ?? []

    callback(newOptions)
  }

  function renderLoadingMessage() {
    return loadingText ?? `${i18n('common.loading')}...`
  }

  function formatOptionLabel(option: PayFrequencyOption): ReactNode {
    return (
      <OptionContainer pr={2}>
        <TextOverflow>{option.label}</TextOverflow>
        <PayFrequencyDetails
          startDate={option.data.startDate}
          type={option.data.type}
        />
      </OptionContainer>
    )
  }

  return (
    <Select
      // TODO: remove ts-ignore when TS will be added to the Select
      // @ts-ignore
      async
      closeMenuOnSelect={!isMulti}
      defaultOptions={defaultOptions}
      formatOptionLabel={formatOptionLabel}
      isClearable={false}
      isDisabled={isDisabled}
      isLoading={loading}
      isMulti={isMulti}
      loadOptions={handleLoadOptions}
      loadingMessage={renderLoadingMessage}
      placeholder={placeholder}
      value={value}
      withPortal
      onMenuOpen={loadPayFrequencies}
      {...rest}
    />
  )
}

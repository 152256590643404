import { EmploymentTypeEssentialFields } from 'API/EmploymentType'

import { EmploymentTypePickerOption } from './types'

export function employmentTypeToOption<T extends EmploymentTypeEssentialFields>(
  employmenyType: T,
): EmploymentTypePickerOption<T> {
  return {
    id: employmenyType.id,
    value: employmenyType.id,
    label: employmenyType.name,
    data: employmenyType,
  }
}

import { useCallback, useEffect, useMemo } from 'react'

import { arrayMoveImmutable } from 'array-move'
import { DateTime } from 'luxon'
import { StoreonModule } from 'storeon'
import { useStoreon } from 'storeon/react'
import { Areas } from 'Types/app'

import isEqual from 'lodash/isEqual'
import without from 'lodash/without'

import { DeviationStateWidgets } from 'constants/dashboard'

import { areasToOptionsWithoutEntities } from 'helpers/areas'

import { useAppContext } from 'hooks'

import Utils from 'services/Utils'

export const DEVATIONS_STATE_KEY = 'deviations'

type DateRangeType = {
  start: string
  end: string
}

export interface DeviationsState {
  [DEVATIONS_STATE_KEY]: DeviationsSubState
}

export interface DeviationsEvents {
  'deviations/set': Partial<DeviationsSubState>
}

interface DeviationsSubState {
  areas: Areas
  widgets: DeviationStateWidgets[]
  dateRange: DateRangeType
}

const INITIAL_STATE = {
  areas: {},
  widgets: Object.values(DeviationStateWidgets),
  dateRange: Utils.DateTime.getCurrentWeek(),
}

const deviationsModule: StoreonModule<
  DeviationsState,
  DeviationsEvents
> = store => {
  store.on('@init', () => ({
    [DEVATIONS_STATE_KEY]: INITIAL_STATE,
  }))

  store.on('deviations/set', (state, variables) => {
    const currentState = state[DEVATIONS_STATE_KEY]
    const nextState = { ...currentState, ...variables }

    if (isEqual(nextState, currentState)) return null
    return { [DEVATIONS_STATE_KEY]: nextState }
  })
}

export function useDeviationStateWidgets() {
  const {
    companySettings: { calendarStartDay },
  } = useAppContext()

  const { [DEVATIONS_STATE_KEY]: state, dispatch } = useStoreon<
    DeviationsState,
    DeviationsEvents
  >(DEVATIONS_STATE_KEY)

  const updateState = useCallback(
    (variables: Partial<DeviationsSubState>) =>
      dispatch('deviations/set', variables),
    [dispatch],
  )

  useEffect(() => {
    updateState({
      dateRange: Utils.DateTime.getCurrentWeek(calendarStartDay),
    })
  }, [calendarStartDay, updateState])

  const enabledWidgets = useMemo(() => state?.widgets ?? [], [state?.widgets])

  const onRemoveWidget = useCallback(
    (removedWidget: DeviationStateWidgets) =>
      updateState({
        widgets: without(enabledWidgets, removedWidget),
      }),
    [enabledWidgets, updateState],
  )

  const onRestoreWidget = useCallback(
    (restoredWidget: DeviationStateWidgets) =>
      updateState({ widgets: [...enabledWidgets, restoredWidget] }),
    [enabledWidgets, updateState],
  )

  const onMoveWidget = useCallback(
    (fromIndex: number, toIndex: number) =>
      updateState({
        widgets: arrayMoveImmutable(enabledWidgets, fromIndex, toIndex),
      }),
    [enabledWidgets, updateState],
  )

  const onAreasChange = useCallback(
    (selectedAreas: Areas) =>
      updateState({
        areas: areasToOptionsWithoutEntities(selectedAreas),
      }),
    [updateState],
  )

  const areas = useMemo(() => state.areas, [state])

  const dateRange = useMemo(() => {
    const storageStartIsoDate = state?.dateRange?.start
    const storageEndIsoDate = state?.dateRange?.end

    // Fallback to cover data missing in LS at first render
    const { start, end } = Utils.DateTime.getCurrentWeek(calendarStartDay)

    const startISODate = storageStartIsoDate ?? start
    const endISODate = storageEndIsoDate ?? end

    return {
      start: DateTime.fromISO(startISODate),
      end: DateTime.fromISO(endISODate),
    }
  }, [calendarStartDay, state])

  const onDateRangeChange = useCallback(
    ({ start, end }) =>
      updateState({
        dateRange: {
          start: start?.toISODate(),
          end: end?.toISODate(),
        },
      }),
    [updateState],
  )

  const onResetDateRange = useCallback(
    () =>
      updateState({
        dateRange: Utils.DateTime.getCurrentWeek(calendarStartDay),
      }),
    [calendarStartDay, updateState],
  )

  const isInitialWeek = useMemo(
    () =>
      DateTime.fromISO(
        Utils.DateTime.getCurrentWeek(calendarStartDay).start,
      ).hasSame(dateRange.start, 'day'),
    [calendarStartDay, dateRange.start],
  )

  return {
    onRemoveWidget,
    onRestoreWidget,
    onMoveWidget,
    onAreasChange,
    areas,
    enabledWidgets,
    dateRange,
    onDateRangeChange,
    onResetDateRange,
    isInitialWeek,
  }
}

export default deviationsModule

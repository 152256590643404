import * as colors from './colors'

export default {
  height: {
    big: 48,
    small: 24,
    xSmall: 20,
    default: 32,
  },
  fontSize: {
    big: 18,
    small: 12,
    xSmall: 12,
    default: 14,
  },
  fontWeight: {
    small: 'normal',
    xSmall: 'normal',
    default: 500,
  },
  padding: {
    big: `0 24px`,
    small: `0 12px`,
    xSmall: `0 6px`,
    default: `0 16px`,
  },
  color: {
    secondary: colors.BLACK,
    default: colors.WHITE,
    disabled: colors.NOBEL,
  },
  backgroundColor: {
    error: colors.ERROR,
    success: colors.POSITIVE,
    light: colors.GARDA_60,
    secondary: colors.ATHENS_GRAY_20,
    default: colors.GARDA_100,
  },
  icon: {
    padding: {
      big: `8px`,
      small: `2px`,
      xSmall: `2px`,
      default: `6px`,
    },
    backgroundColor: {
      secondary: colors.WHITE,
      error: colors.ERROR,
      default: colors.GARDA_100,
    },
    color: {
      secondary: colors.SPUN_PEARL,
      default: colors.WHITE,
      success: colors.POSITIVE,
    },
    border: {
      secondary: colors.ATHENS_GRAY_40,
      default: colors.GARDA_100,
    },
    active: {
      secondary: colors.SPUN_PEARL,
      default: colors.GARDA_80,
    },
    hover: {
      border: {
        secondary: colors.CHARCOAL_60,
        default: colors.GARDA_120,
      },
      backgroundColor: {
        secondary: colors.WHITE,
        default: colors.GARDA_120,
      },
      color: {
        secondary: colors.SPUN_PEARL,
        default: colors.WHITE,
      },
    },
    disabled: {
      backgroundColor: {
        secondary: colors.ATHENS_GRAY_40,
        default: colors.ATHENS_GRAY_40,
      },
      color: {
        secondary: colors.SPUN_PEARL,
        default: colors.SPUN_PEARL,
      },
    },
  },
  border: {
    radius: {
      squared: 4,
      default: 24,
    },
    color: {
      white: colors.PAPER_WHITE_60,
      error: colors.NEGATIVE,
      success: colors.POSITIVE,
      light: colors.GARDA_60,
      secondary: colors.ATHENS_GRAY_40,
      default: colors.GARDA_100,
    },
  },
  active: {
    border: {
      color: {
        error: colors.NEGATIVE_LIGHT,
        success: colors.POSITIVE_LIGHT,
        default: colors.WHITE,
      },
    },
    backgroundColor: {
      error: colors.NEGATIVE_LIGHT,
      success: colors.POSITIVE_LIGHT,
      light: colors.GARDA_40,
      secondary: colors.ATHENS_GRAY_40,
      default: colors.GARDA_80,
    },
  },
  hover: {
    border: {
      color: {
        error: colors.NEGATIVE_DARK,
        success: colors.POSITIVE_DARK,
        default: colors.ATHENS_GRAY_40,
      },
    },
    backgroundColor: {
      error: colors.NEGATIVE_DARK,
      success: colors.POSITIVE_DARK,
      light: colors.GARDA_80,
      secondary: colors.ATHENS_GRAY_40,
      default: colors.GARDA_120,
    },
  },
  disabled: {
    border: {
      color: {
        default: colors.CHARCOAL_40,
      },
    },
    backgroundColor: {
      default: colors.CHARCOAL_40,
    },
  },
}

import { useCallback, useMemo } from 'react'

import { arrayMoveImmutable } from 'array-move'
import { StoreonModule } from 'storeon'
import { useStoreon } from 'storeon/react'
import { AreasFilter } from 'Types/common'

import isEqual from 'lodash/isEqual'
import without from 'lodash/without'

import { CurrentStateWidgets } from 'constants/dashboard'

import { areasToOptionsWithoutEntities } from 'helpers/areas'

export const CURRENT_STATE_KEY = 'currentState'

export interface CurrentState {
  [CURRENT_STATE_KEY]: CurrentStateSubState
}

export interface CurrentStateEvents {
  'currentState/set': Partial<CurrentStateSubState>
}

interface CurrentStateSubState {
  areas: AreasFilter
  widgets: CurrentStateWidgets[]
}

const INITIAL_STATE = {
  areas: {},
  widgets: Object.values(CurrentStateWidgets),
}

const currentStateModule: StoreonModule<
  CurrentState,
  CurrentStateEvents
> = store => {
  store.on('@init', () => ({
    [CURRENT_STATE_KEY]: INITIAL_STATE,
  }))

  store.on('currentState/set', (state, variables) => {
    const currentState = state[CURRENT_STATE_KEY]
    const nextState = { ...currentState, ...variables }

    if (isEqual(nextState, currentState)) return null
    return { [CURRENT_STATE_KEY]: nextState }
  })
}

export default currentStateModule

export function useCurrentStateWidgets() {
  const { [CURRENT_STATE_KEY]: state, dispatch } = useStoreon<
    CurrentState,
    CurrentStateEvents
  >(CURRENT_STATE_KEY)

  const updateState = useCallback(
    (variables: Partial<CurrentStateSubState>) =>
      dispatch('currentState/set', variables),
    [dispatch],
  )

  const enabledWidgets = useMemo(() => state?.widgets ?? [], [state?.widgets])

  const onRemoveWidget = useCallback(
    (removedWidget: CurrentStateWidgets) =>
      updateState({
        widgets: without(enabledWidgets, removedWidget),
      }),
    [enabledWidgets, updateState],
  )

  const onRestoreWidget = useCallback(
    (restoredWidget: CurrentStateWidgets) =>
      updateState({ widgets: [...enabledWidgets, restoredWidget] }),
    [enabledWidgets, updateState],
  )

  const onMoveWidget = useCallback(
    (fromIndex: number, toIndex: number) =>
      updateState({
        widgets: arrayMoveImmutable(enabledWidgets, fromIndex, toIndex),
      }),
    [enabledWidgets, updateState],
  )

  const onAreasChange = useCallback(
    (selectedAreas: AreasFilter) =>
      updateState({
        areas: areasToOptionsWithoutEntities(selectedAreas),
      }),
    [updateState],
  )

  const areas = useMemo(() => state.areas, [state])

  return {
    onRemoveWidget,
    onRestoreWidget,
    onMoveWidget,
    onAreasChange,
    areas,
    enabledWidgets,
  }
}

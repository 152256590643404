import * as Sentry from '@sentry/browser'
import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import createSentryMiddleware from 'redux-sentry-middleware'
import thunk from 'redux-thunk'

import { APP_ENV, sentry } from 'constants/config'

import history from 'services/History'
import { LocalStorage } from 'services/LocalStorage'

import cookiesMiddleware from 'store/middleware/cookies'
import notObject from 'store/middleware/notObject'
import toastr from 'store/middleware/toastr'
import rootReducer from 'store/reducers'
import { viewerInitialState } from 'store/reducers/viewer'
import rootSaga from 'store/sagas'
import { cookiesSelector } from 'store/selectors/cookies'

if (APP_ENV !== 'development') {
  Sentry.init({
    dsn: sentry.dsn,
  })
}

export default () => {
  const middleware = [
    thunk,
    notObject,
    toastr,
    routerMiddleware(history),
    cookiesMiddleware(cookiesSelector),
  ]

  if (APP_ENV !== 'development') {
    middleware.push(createSentryMiddleware(Sentry))
  }

  const sagaMiddleware = createSagaMiddleware({
    onError(error) {
      if (APP_ENV !== 'development') {
        Sentry.captureException(error, {
          tags: { type: 'redux-saga' },
        })
      } else {
        // eslint-disable-next-line no-console
        console.log('Saga exception:', error)
      }
    },
  })

  middleware.push(sagaMiddleware)

  let enhancer
  if (APP_ENV !== 'production') {
    enhancer = compose(
      applyMiddleware(...middleware),
      // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? // eslint-disable-next-line no-underscore-dangle
          window.__REDUX_DEVTOOLS_EXTENSION__()
        : a => a,
    )
  } else if (
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.location.hash === '#magic.exe'
  ) {
    enhancer = compose(
      applyMiddleware(...middleware),
      // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? // eslint-disable-next-line no-underscore-dangle
          window.__REDUX_DEVTOOLS_EXTENSION__()
        : a => a,
    )
  } else {
    enhancer = applyMiddleware(...middleware)
  }

  // Restore persistent data
  const branchId = LocalStorage.getItem('branchId')
  const preloadedState = {
    viewer: viewerInitialState.merge({
      branchId,
    }),
  }
  const store = createStore(rootReducer(history), preloadedState, enhancer)

  sagaMiddleware.run(rootSaga)

  if (module.hot) {
    module.hot.accept('./reducers', () =>
      // eslint-disable-next-line global-require
      store.replaceReducer(require('./reducers').default),
    )
  }

  return store
}

import numeral from 'numeral'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import parseInt from 'lodash/parseInt'

import {
  calculateScheduledTimes,
  getDayScheduledActualData,
  getEmployeesScheduledActualData,
  getShiftsByDays,
  getTimeoffsByDays,
} from 'constants/schedule'

import { convertMinutesToHM, getDayIndex, getWeekFromDate } from 'helpers/date'

import _ from 'i18n'

export const HOURS_LIMITS = {
  weekly: { min: 1, max: 80 },
  daily: { min: 1, max: 18 },
}

export const NUMBER_REG_EXP = /^[0-9\b]+$/

export const PAYROLL_RULES_STATES = {
  active: 'active',
  deleted: 'deleted',
}

export const BUDGET_SETTINGS = {
  byBranch: 'by_branch',
  byDepartment: 'by_department',
}

const isExceedLimit = (limit, hours, minutes) =>
  hours > limit || (hours === limit && minutes > 0)

export const checkExceedingRule = (
  branchSettings,
  employeeWeekSchedules,
  acceptedTimeoffs,
  shiftsJob,
  excludeShiftsJob = {}, // trade shift case
) => {
  const { dailyWorkingHoursLimit, workingHoursLimit } = branchSettings
  const {
    shift: { startAt },
  } = shiftsJob
  const week = getWeekFromDate(startAt)
  const dayIndex = getDayIndex(startAt)
  const excludeDate = get(excludeShiftsJob, 'shift.startAt', '')

  let excludeMinutes = 0
  let isExceedDailyLimit = false
  let isExceedWeeklyLimit = false
  const isExcludeSameDay = excludeDate
    ? getDayIndex(excludeDate) === dayIndex
    : false

  const { pendingMinutes: selectedShiftMinutes } = calculateScheduledTimes({
    shiftsJob,
    state: 'pending',
  })

  if (!isEmpty(excludeShiftsJob)) {
    const { pendingMinutes: excludeShiftMinutes } = calculateScheduledTimes({
      shiftsJob: excludeShiftsJob,
      state: 'pending',
    })
    excludeMinutes = excludeShiftMinutes
  }

  const parseTime = time => map(time.split(':'), parseInt)

  if (!isEmpty(employeeWeekSchedules)) {
    const shiftsByDays = getShiftsByDays(employeeWeekSchedules, week)
    const timeOffsByDays = getTimeoffsByDays(
      employeeWeekSchedules,
      week,
      acceptedTimeoffs,
    )
    if (dailyWorkingHoursLimit && !isEmpty(shiftsByDays)) {
      const dayShifts = shiftsByDays[dayIndex]
      const dayTimeoff = timeOffsByDays[dayIndex]
      if (!isEmpty(dayShifts)) {
        const {
          dayData: { scheduledTime },
          pendingDayMinutes,
        } = getDayScheduledActualData(dayShifts, dayTimeoff)

        let dailyMinutes =
          scheduledTime + pendingDayMinutes + selectedShiftMinutes
        if (isExcludeSameDay) {
          dailyMinutes -= excludeMinutes
        }

        const [dailyScheduledHours, dailyScheduledMinutes] = parseTime(
          convertMinutesToHM(dailyMinutes),
        )
        isExceedDailyLimit = isExceedLimit(
          dailyWorkingHoursLimit,
          dailyScheduledHours,
          dailyScheduledMinutes,
        )
      }
    }

    if (workingHoursLimit && !isEmpty(shiftsByDays)) {
      const { scheduledHours, pendingHours } = getEmployeesScheduledActualData(
        shiftsByDays,
        timeOffsByDays,
        false,
      )
      const weeklyMinutes =
        scheduledHours + pendingHours + selectedShiftMinutes - excludeMinutes
      const [weeklyScheduledHours, weeklyScheduledMinutes] = parseTime(
        convertMinutesToHM(weeklyMinutes),
      )
      isExceedWeeklyLimit = isExceedLimit(
        workingHoursLimit,
        weeklyScheduledHours,
        weeklyScheduledMinutes,
      )
    }
  }
  // just check exceeding rules for the selected shift if week schedules is empty
  if (!isExceedDailyLimit && dailyWorkingHoursLimit) {
    const minutes = isExcludeSameDay
      ? selectedShiftMinutes - excludeMinutes
      : selectedShiftMinutes
    const [selectedShiftHours, selectedShtMinutes] = parseTime(
      convertMinutesToHM(minutes),
    )
    isExceedDailyLimit = isExceedLimit(
      dailyWorkingHoursLimit,
      selectedShiftHours,
      selectedShtMinutes,
    )
  }
  if (!isExceedWeeklyLimit && workingHoursLimit) {
    const minutes = selectedShiftMinutes - excludeMinutes
    const [selectedShiftHours, selectedShtMinutes] = parseTime(
      convertMinutesToHM(minutes),
    )
    isExceedWeeklyLimit = isExceedLimit(
      workingHoursLimit,
      selectedShiftHours,
      selectedShtMinutes,
    )
  }

  return { isExceedDailyLimit, isExceedWeeklyLimit }
}

export const getExceedLimitsText = (
  branchSettings,
  isExceedDailyLimit,
  isExceedWeeklyLimit,
) => {
  const { dailyWorkingHoursLimit, workingHoursLimit } = branchSettings
  if (isExceedDailyLimit && isExceedWeeklyLimit) {
    return `${dailyWorkingHoursLimit} ${_('common.hrsDay')} ${_(
      'actions.and',
    )} ${workingHoursLimit} ${_('common.hrsWeek')}`
  }
  if (isExceedDailyLimit) {
    return `${dailyWorkingHoursLimit} ${_('common.hrsDay')}`
  }
  if (isExceedWeeklyLimit) {
    return `${workingHoursLimit} ${_('common.hrsWeek')}`
  }
  return ''
}

export const CUSTOM_FIELD_TYPE = {
  text: 'text',
  email: 'email',
  url: 'url',
  phone: 'phone',
  number: 'number',
  decimal: 'decimal',
  percent: 'percent',
  date: 'date',
  checkbox: 'checkbox',
  dropdown: 'dropdown',
}

export const CUSTOM_FIELD_TYPES = [
  'text',
  'email',
  'url',
  'phone',
  'number',
  'decimal',
  'percent',
  'date',
  'checkbox',
  'dropdown',
]

export const CUSTOM_FIELD_DEFAULT_VALUE_TYPES = [
  'text',
  'email',
  'url',
  'phone',
  'number',
  'decimal',
  'percent',
  'date',
  'checkbox',
  'dropdown',
]

export const isWrongLimit = (limit, typedLimit) => {
  const { min, max } = limit
  return !(
    /^\d{1,2}$/.test(typedLimit) &&
    typedLimit >= min &&
    typedLimit <= max
  )
}

const BEACON_UUID_REG_EXP = /[0-9a-fA-F]/
const BEACON_KONTAKT_REG_EXP = /[0-9a-zA-Z]/

export const BEACON_KIND = {
  UUID: 'uuid',
  KONTAKT: 'kontakt',
}

export const BEACON_OPTIONS = [
  {
    label: 'UUID',
    value: BEACON_KIND.UUID,
  },
  {
    label: 'Kontakt',
    value: BEACON_KIND.KONTAKT,
  },
]

export const BEACON_KONTAKT_MASK = new Array(12).fill(BEACON_KONTAKT_REG_EXP)

export const BEACON_UUID_MASK = [
  ...new Array(8).fill(BEACON_UUID_REG_EXP),
  '-',
  ...new Array(4).fill(BEACON_UUID_REG_EXP),
  '-',
  ...new Array(4).fill(BEACON_UUID_REG_EXP),
  '-',
  ...new Array(4).fill(BEACON_UUID_REG_EXP),
  '-',
  ...new Array(12).fill(BEACON_UUID_REG_EXP),
]

export const GEOLOCATION_RADIUS_MASK = {
  mask: createNumberMask({
    prefix: '',
    thousandsSeparatorSymbol: ' ',
    integerLimit: 5,
    allowDecimal: false,
    allowNegative: false,
    includeThousandsSeparator: true,
    allowLeadingZeroes: false,
  }),
  pipe: validatedValue => {
    const clearedFromDelimiters = numeral(validatedValue)?.value()
    if (!isNaN(clearedFromDelimiters) && clearedFromDelimiters >= 1)
      return validatedValue
    return false
  },
}

export const PERCENT_MASK = createNumberMask({
  prefix: '',
  suffix: '%',
  allowDecimal: true,
  allowNegative: true,
  decimalSymbol: ',',
  includeThousandsSeparator: false,
})

export const NUMBER_MASK = createNumberMask({
  prefix: '',
  allowDecimal: false,
  allowNegative: true,
  includeThousandsSeparator: false,
})

export const BALANCE_ADJUSTMENT_MASK = createNumberMask({
  prefix: '',
  integerLimit: 2,
  allowDecimal: false,
  allowNegative: false,
  includeThousandsSeparator: false,
})

export const NUMBER_TOKENS = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: ' ',
  integerLimit: 8,
  allowDecimal: false,
  allowNegative: false,
  includeThousandsSeparator: true,
  allowLeadingZeroes: false,
})

export const POSITIVE_NUMBER_MASK = integerLimit =>
  createNumberMask({
    integerLimit,
    prefix: '',
    allowDecimal: false,
    allowNegative: false,
    includeThousandsSeparator: false,
  })

export const DECIMAL_MASK = createNumberMask({
  prefix: '',
  allowDecimal: true,
  requireDecimal: true,
  allowNegative: true,
  includeThousandsSeparator: false,
  decimalLimit: 4,
})

export const DISTANCE_MASK = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: ' ',
  integerLimit: 5,
  allowDecimal: false,
  allowNegative: false,
  includeThousandsSeparator: true,
  allowLeadingZeroes: false,
})

export const DURATION_MASK = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: ' ',
  decimalLimit: 2,
  integerLimit: 6,
  allowDecimal: true,
  requireDecimal: false,
  allowNegative: false,
  includeThousandsSeparator: true,
  allowLeadingZeroes: false,
})

export const PRIORITY_MASK = createNumberMask({
  prefix: '',
  integerLimit: 3,
  allowDecimal: false,
  allowNegative: false,
})

export const TIME_MASK = createNumberMask({
  prefix: '',
  allowDecimal: true,
  allowNegative: false,
  allowLeadingZeroes: true,
  decimalSymbol: ':',
  includeThousandsSeparator: false,
})

// eslint-disable-next-line prettier/prettier
export const NAS_NUMBER_MASK = [
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
]
export const NAS_NUMBER_REGEX = /\d{3} \d{3} \d{3}/

// eslint-disable-next-line prettier/prettier
export const PHONE_MASK = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export const PHONE_REGEX = /\(\d{3}\) \d{3}-\d{4}/

export const MONEY_MASK = integerLimit => {
  return createNumberMask({
    prefix: '',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ' ',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    integerLimit,
    allowNegative: false,
    allowLeadingZeroes: false,
  })
}

export function getMandatoryConstraintsFromType(type) {
  let mandatoryConstraint = {
    presence: {
      allowEmpty: false,
    },
  }

  if (type === CUSTOM_FIELD_TYPE.checkbox) {
    mandatoryConstraint = {
      inclusion: {
        within: [true],
        message: `^${_('common.required')}`,
      },
    }
  }

  return {
    ...mandatoryConstraint,
    ...getConstraintsFromType(type),
  }
}

export function getConstraintsFromType(type) {
  switch (type) {
    case CUSTOM_FIELD_TYPE.phone:
      return {
        format: /^[0-9]*$/, // only numbers regexp
      }
    case CUSTOM_FIELD_TYPE.decimal:
      return {
        numericality: {
          onlyInteger: false,
        },
        format: /^\d+(\.\d+)?$/, // decimal regexp
      }
    case CUSTOM_FIELD_TYPE.percent:
      return {
        format: /\(?\d+(?:,\d+)? ?%\)? */, // percent regexp
      }
    case CUSTOM_FIELD_TYPE.number:
      return {
        numericality: {
          onlyInteger: true,
        },
      }
    case CUSTOM_FIELD_TYPE.url:
      return {
        url: true,
      }
    case CUSTOM_FIELD_TYPE.email:
      return {
        email: true,
      }
    default:
      return {}
  }
}

export const DATE_FORMAT = 'dd/MM/yyyy'

import {
  createDeleteHandler,
  createFields,
  createReducerHandlers,
} from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { CNESST, CNESST_STATUSES } from 'store/actions/employees/CNESST'

export const CNESST_FIELD = 'cnesstRecord'
export const CNESST_TYPE = 'cnesstRecords'

export const CNESST_STATUSES_FIELD = 'cnesstStatus'
export const CNESST_STATUSES_TYPE = 'cnesstStatuses'

const initialState = Immutable({
  ...createFields(CNESST_TYPE, CNESST_FIELD),
  ...createFields(CNESST_STATUSES_TYPE, CNESST_STATUSES_FIELD),
})

const handlers = {
  ...createReducerHandlers(CNESST_TYPE, CNESST.LOAD, {
    withReplace: true,
    mapToKey: CNESST_FIELD,
  }),
  ...createReducerHandlers(CNESST_TYPE, CNESST.CREATE, {
    withReplace: false,
    mapToKey: CNESST_FIELD,
  }),
  ...createReducerHandlers(CNESST_TYPE, CNESST.UPDATE),
  [CNESST.DELETE.SUCCESS]: createDeleteHandler(CNESST_FIELD),

  ...createReducerHandlers(CNESST_STATUSES_TYPE, CNESST_STATUSES.LOAD, {
    withReplace: true,
    mapToKey: CNESST_STATUSES_FIELD,
  }),
  ...createReducerHandlers(CNESST_STATUSES_TYPE, CNESST_STATUSES.CREATE, {
    withReplace: true,
    mapToKey: CNESST_STATUSES_FIELD,
  }),
  ...createReducerHandlers(CNESST_STATUSES_TYPE, CNESST_STATUSES.UPDATE),
  [CNESST_STATUSES.DELETE.SUCCESS]: createDeleteHandler(CNESST_STATUSES_FIELD),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

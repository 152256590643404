import React from 'react'

import { FlexboxProps, SpaceProps } from 'styled-system'

import { Flex } from 'components/ui/__v2__/Grid'

type Props = SpaceProps &
  FlexboxProps & {
    children: React.ReactNode
  }

export function Footer({ children, ...rest }: Props) {
  return (
    <Flex
      alignItems="center"
      justifyContent="end"
      px={3}
      width="100%"
      {...rest}
    >
      {children}
    </Flex>
  )
}

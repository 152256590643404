import { useApolloMutation } from 'API/services/Apollo'

import { UpdateShiftTagMutation } from './GraphQL'

export function useUpdateShiftTag() {
  const [updateShiftTag, { loading: updating }] = useApolloMutation<
    MutationData<'updateShiftTag'>,
    Gateway.MutationUpdateShiftTagArgs
  >(UpdateShiftTagMutation, { translationKey: 'shiftTags.toasts.update' })

  const update = async (
    id: Gateway.MutationUpdateShiftTagArgs['id'],
    variables: Gateway.MutationUpdateShiftTagArgs['input'],
  ) => {
    const response = await updateShiftTag({
      variables: {
        id,
        input: {
          ...variables,
        },
      },
    })

    // FIXME: remove this hack after error stadardization and proper handling
    let errored
    if (response.data?.updateShiftTag) {
      errored = false
    } else {
      errored = true
    }

    return errored
  }

  return { update, updating }
}

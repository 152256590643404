import { useState } from 'react'

import { i18n } from 'i18n'

import coreApiCall from 'services/API/coreApi'
import { showDefaultErrorToast, showToast } from 'services/Toasts'

export function useEmployeeActivation() {
  const [loading, setLoading] = useState(false)

  const activateEmployee = async (id: string) => {
    setLoading(true)
    const response = (await coreApiCall({
      endpoint: `/employees/${id}/signed_up`,
      method: 'PUT',
    })) as JsonApi.Response

    if (response.ok) {
      showToast({
        type: 'success',
        title: i18n('employeeProfile.employeeStatus.successToast'),
      })
    } else {
      showDefaultErrorToast()
    }

    setLoading(false)

    return response
  }

  return { activateEmployee, loading }
}

import { useApolloQuery } from 'API/services/Apollo'

import { TimeClassificationWorkflowStatusQuery } from './GraphQL'

type Props = {
  temporalWorkflowId?: string
  temporalRunId?: string
  pollInterval: number
}

export function useTimeClassificationWorkflowStatus({
  temporalWorkflowId,
  temporalRunId,
  pollInterval,
}: Props) {
  const { data, loading, error } = useApolloQuery<
    QueryData<'timeClassificationWorkflowStatus'>,
    Gateway.QueryTimeClassificationWorkflowStatusArgs
  >(TimeClassificationWorkflowStatusQuery, {
    fetchPolicy: 'cache-and-network',
    skip: !temporalWorkflowId || !temporalRunId,

    pollInterval,
    variables: {
      input: {
        temporalRunId: temporalRunId!,
        temporalWorkflowId: temporalWorkflowId!,
      },
    },
  })
  const workflowStatus = data?.timeClassificationWorkflowStatus.workflowStatus

  return {
    workflowStatus,
    workflowStatusLoading: loading,
    workflowStatusError: !!error,
  }
}

import { HttpLink, split } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'

import { api } from 'constants/config'

export function gatewayHttpLink() {
  const uri = `${api.gatewayUrl}/graphql`

  return split(
    operation => operation.getContext().batch === true,
    new BatchHttpLink({
      uri,
      headers: { batch: 'true' },
      fetchOptions: { mode: 'no-cors' },
    }),
    new HttpLink({ uri }),
  )
}

import React from 'react'

import {
  FiMinusSquare as CollapseIcon,
  FiPlusSquare as ExpandIcon,
} from 'react-icons/fi'

import { IconContainer } from './styles'

interface Props {
  expanded: boolean
  onToggleClick: (event: unknown) => void
}
export function ExpandHandle({ expanded, onToggleClick }: Props) {
  return (
    <IconContainer onClick={onToggleClick}>
      {expanded ? <CollapseIcon /> : <ExpandIcon />}
    </IconContainer>
  )
}

import React from 'react'

import { flexRender } from '@tanstack/react-table'

import { Td, TFoot } from './styles'
import { DataTableProps } from './types'

export function TableFooter({ table, loading }: DataTableProps) {
  if (loading) {
    return null
  }

  return (
    <TFoot>
      {table.getFooterGroups().map(footerGroup => (
        <tr key={footerGroup.id}>
          {footerGroup.headers.map(header => (
            <Td key={header.id} {...table.options.meta}>
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.footer,
                    header.getContext(),
                  )}
            </Td>
          ))}
        </tr>
      ))}
    </TFoot>
  )
}

import { graphQLResultHasError } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { StartPauseTimersMutation } from './GraphQL'

export function useStartPauseTimers() {
  const [innerStartMany, { loading }] = useApolloMutation<
    MutationData<'startPauseTimers'>,
    Gateway.MutationStartPauseTimersArgs
  >(StartPauseTimersMutation, {
    translationKey: 'schedule.garda.pauseTimers.toasts.startPause',
    update: cache => {
      // Note: temporary fix, refetchQueries doesn't seem to work properly
      cache.evict({ fieldName: 'schedulesByCursor' })
    },
  })

  const startMany = async (input: Gateway.StartPauseTimersInput) => {
    const result = await innerStartMany({ variables: { input } })
    return graphQLResultHasError(result)
  }

  return { startMany, startingPauseTimers: loading }
}

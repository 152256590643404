import * as Columns from './Columns'
import * as Controls from './Controls'
import { Headers } from './Headers'
import { CursorPagination, OffsetPagination } from './Pagination'
import { DataTable, TableContainer, TableTitle } from './styles'
import { TableBody } from './TableBody'
import { TableFooter } from './TableFooter'
import { TableHeader } from './TableHeader'

export const Table = {
  Element: DataTable,
  Header: TableHeader,
  Body: TableBody,
  Footer: TableFooter,
  Pagination: { Cursor: CursorPagination, Offset: OffsetPagination },
  Container: TableContainer,
  Title: TableTitle,
  Controls,
  Headers,
  Columns,
}

import { ClassifierSource } from 'constants/gatewayGraphQL'

import { classifierSourceToOption } from './helpers'

const SOURCES_ARRAY = [
  ClassifierSource.CustomCvField,
  ClassifierSource.Branch,
  ClassifierSource.Department,
  ClassifierSource.Job,
  ClassifierSource.EmploymentTerm,
]
export const OPTIONS = SOURCES_ARRAY.map(classifierSourceToOption)

export function isCursorPageMetaChanged(
  prevCursorMeta: JsonApi.CursorPaging | Gateway.CursorPageInfo,
  nextCursorMeta: JsonApi.CursorPaging | Gateway.CursorPageInfo,
) {
  return (
    prevCursorMeta.startCursor !== nextCursorMeta.startCursor ||
    prevCursorMeta.endCursor !== nextCursorMeta.endCursor ||
    prevCursorMeta.hasNextPage !== nextCursorMeta.hasNextPage ||
    prevCursorMeta.hasPreviousPage !== nextCursorMeta.hasPreviousPage
  )
}

import { groupBy, random, sortBy, sum, toPairs, uniq } from 'lodash'

export type DemoLocation = {
  id: string

  country: 'Canada' | 'USA' | 'Australia'
  state: string
  region: string

  locationName: string
  locationCode: string

  address: string

  coordinates: {
    lat: number
    lng: number
  }
}

type HistoricDemandWidgetData = {
  totalWeekSales: number
  totalPlannedSales: number
  weekSalesData: number[]
  plannedSalesDate: number[]

  totalWeekTraffic: number
  totalPlannedTraffic: number
  weekTrafficData: number[]
  plannedTrafficData: number[]
}

export enum DemandForecastingRoles {
  Cashier = 'Cashier',
  Stocker = 'Stocker',
  Manager = 'Manager',
}

export enum DemandForecastingAlgorithms {
  Linear = 'Linear Regression',
  TimeSeries = 'Time series',
  Neural = 'Neural Network',
}

export type HistoricDemandFlatDataSlice = DemoLocation &
  HistoricDemandWidgetData

export const BI_DEMO_LOCATIONS: DemoLocation[] = [
  {
    id: '1',
    country: 'USA',
    state: 'Indiana',
    region: '',
    locationName: 'Circle Centre Mall',
    locationCode: '0001',
    address: 'IN, USA',
    coordinates: {
      lat: 39.766392,
      lng: -86.159882,
    },
  },
  {
    id: '2',
    country: 'USA',
    state: 'Oklahoma',
    region: '',
    locationName: 'Penn Square Mall',
    locationCode: '0002',
    address: 'OK, USA',
    coordinates: {
      lat: 35.524792,
      lng: -97.544594,
    },
  },
  {
    id: '3',
    country: 'USA',
    state: 'Kansas',
    region: '',
    locationName: 'Legends Outlets Kansas',
    locationCode: '0003',
    address: 'KS, USA',
    coordinates: {
      lat: 39.126659,
      lng: -118.337349,
    },
  },
  {
    id: '4',
    country: 'USA',
    state: 'California',
    region: '',
    locationName: 'Baldwin Hills Crenshaw',
    locationCode: '0004',
    address: 'CA, USA',
    coordinates: {
      lat: 34.010799,
      lng: -118.337349,
    },
  },
  {
    id: '5',
    country: 'USA',
    state: 'California',
    region: '',
    locationName: 'Japanese Village Plaza',
    locationCode: '0005',
    address: 'CA, USA',

    coordinates: {
      lat: 34.048927,
      lng: -118.240532,
    },
  },
  {
    id: '6',
    country: 'USA',
    state: 'California',
    region: '',
    locationName: 'Westfield Century City',
    locationCode: '0006',
    address: 'Los Angeles, CA, USA',
    coordinates: {
      lat: 34.058662,
      lng: -118.419174,
    },
  },
  {
    id: '7',
    country: 'USA',
    state: 'California',
    region: '',
    locationName: 'The Grove',
    locationCode: '0007',
    address: 'Los Angeles, CA, USA',
    coordinates: {
      lat: 34.071972,
      lng: -118.357674,
    },
  },
  {
    id: '8',
    country: 'USA',
    state: 'New York',
    region: '',
    locationName: 'Atlantic Terminal Mall',
    locationCode: '0008',
    address: 'NY, USA',
    coordinates: {
      lat: 40.68457,
      lng: -73.97715,
    },
  },
  {
    id: '9',
    country: 'USA',
    state: 'New Jersey',
    region: '',
    locationName: 'Newport Centre',
    locationCode: '0009',
    address: 'NJ, USA',
    coordinates: {
      lat: 40.727123,
      lng: -74.0383,
    },
  },
  {
    id: '10',
    country: 'USA',
    state: 'New York',
    region: '',
    locationName: 'Cross County Center',
    locationCode: '0010',
    address: 'NY, USA',
    coordinates: {
      lat: 40.92733,
      lng: -73.855873,
    },
  },
  {
    id: '11',
    country: 'USA',
    state: 'New York',
    region: '',
    locationName: 'Palisades Center',
    locationCode: '0011',
    address: 'NY, USA',
    coordinates: {
      lat: 41.097057,
      lng: -73.955849,
    },
  },
  {
    id: '12',
    country: 'USA',
    state: 'New York',
    region: '',
    locationName: 'Brookfield Place',
    locationCode: '0012',
    address: 'NY, USA',
    coordinates: {
      lat: 40.712719,
      lng: -74.015411,
    },
  },
  {
    id: '13',
    country: 'USA',
    state: 'New York',
    region: '',
    locationName: 'Roosevelt Field',
    locationCode: '0013',
    address: 'NY, USA',
    coordinates: {
      lat: 40.738037,
      lng: -73.613075,
    },
  },
  {
    id: '14',
    country: 'USA',
    state: 'New York',
    region: '',
    locationName: 'The Mall at Bay Plaza',
    locationCode: '0014',
    address: 'NY, USA',
    coordinates: {
      lat: 40.864319,
      lng: -73.827774,
    },
  },
  {
    id: '15',
    country: 'USA',
    state: 'Idaho',
    region: '',
    locationName: 'Boise Towne Square',
    locationCode: '0015',
    address: 'ID, USA',
    coordinates: {
      lat: 43.60849,
      lng: -116.278969,
    },
  },
  {
    id: '16',
    country: 'USA',
    state: 'New York',
    region: '',
    locationName: 'Gateway Center',
    locationCode: '0016',
    address: 'NY, USA',
    coordinates: {
      lat: 40.652733,
      lng: -73.872002,
    },
  },
  {
    id: '17',
    country: 'USA',
    state: 'New Jersey',
    region: '',
    locationName: 'Westfield Garden State Plaza',
    locationCode: '0017',
    address: 'NJ, USA',
    coordinates: {
      lat: 40.917709,
      lng: -74.077263,
    },
  },
  {
    id: '18',
    country: 'USA',
    state: 'New York',
    region: '',
    locationName: 'Kings Plaza Shopping Center',
    locationCode: '0018',
    address: 'NY, USA',
    coordinates: {
      lat: 40.610279,
      lng: -73.920891,
    },
  },
  {
    id: '19',
    country: 'USA',
    state: 'New York',
    region: '',
    locationName: 'Woodbury Common Premium Outlets',
    locationCode: '0019',
    address: 'NY, USA',
    coordinates: {
      lat: 41.31707,
      lng: -74.125473,
    },
  },
  {
    id: '20',
    country: 'USA',
    state: 'New York',
    region: '',
    locationName: 'Green Acres Mall',
    locationCode: '0020',
    address: 'NY, USA',
    coordinates: {
      lat: 40.662994,
      lng: -73.721886,
    },
  },
  {
    id: '21',
    country: 'USA',
    state: 'Texas',
    region: '',
    locationName: 'San Marcos Premium Outlets',
    locationCode: '0021',
    address: 'TX, USA',
    coordinates: {
      lat: 29.828913,
      lng: -97.981583,
    },
  },

  {
    id: '22',
    country: 'USA',
    state: 'California',
    region: '',
    locationName: 'Hollywood & Highland',
    locationCode: '0022',
    address: 'Los Angeles, USA',
    coordinates: {
      lat: 34.10231,
      lng: -118.340027,
    },
  },
  {
    id: '23',
    country: 'USA',
    state: 'New York',
    region: '',
    locationName: 'Queens Center',
    locationCode: '0023',
    address: 'NY, USA',
    coordinates: {
      lat: 40.73447,
      lng: -73.86972,
    },
  },
  {
    id: '24',
    country: 'USA',
    state: 'Virginia',
    region: '',
    locationName: 'Tysons Corner Center',
    locationCode: '0024',
    address: 'VA, USA',
    coordinates: {
      lat: 38.91713,
      lng: -77.222237,
    },
  },
  {
    id: '25',
    country: 'USA',
    state: 'Texas',
    region: '',
    locationName: 'Houston Galleria',
    locationCode: '0025',
    address: 'TX, USA',
    coordinates: {
      lat: 29.7407,
      lng: -95.4636,
    },
  },
  {
    id: '26',
    country: 'USA',
    state: 'Nevada',
    region: '',
    locationName: 'Grand Canyon Parkway',
    locationCode: '0026',
    address: 'Spring Valley, NV, USA',
    coordinates: {
      lat: 36.113251,
      lng: -115.308922,
    },
  },
  {
    id: '27',
    country: 'USA',
    state: 'Pennsylvania',
    region: '',
    locationName: 'Millcreek Mall Complex',
    locationCode: '0027',
    address: 'Erie, PA, USA',
    coordinates: {
      lat: 42.069286,
      lng: -80.09903,
    },
  },
  {
    id: '28',
    country: 'USA',
    state: 'California',
    region: '',
    locationName: 'Del Amo Fashion Center',
    locationCode: '0028',
    address: 'Torrance, CA, USA',
    coordinates: {
      lat: 33.828072,
      lng: -118.349792,
    },
  },
  {
    id: '29',
    country: 'USA',
    state: 'Florida',
    region: '',
    locationName: 'Aventura Mall',
    locationCode: '0029',
    address: 'FL, USA',
    coordinates: {
      lat: 25.958055,
      lng: -80.143059,
    },
  },
  {
    id: '30',
    country: 'USA',
    state: 'Minnesota',
    region: '',
    locationName: 'Mall of America',
    locationCode: '0030',
    address: 'MN, USA',
    coordinates: {
      lat: 44.854168,
      lng: -93.242226,
    },
  },
  {
    id: '31',
    country: 'USA',
    state: 'California',
    region: '',
    locationName: 'South Coast Plaza',
    locationCode: '0031',
    address: 'CA, USA',
    coordinates: {
      lat: 33.691113,
      lng: -117.889442,
    },
  },
  {
    id: '32',
    country: 'USA',
    state: 'Pennsylvania',
    region: '',
    locationName: 'King of Prussia Mall',
    locationCode: '0032',
    address: 'King of Prussia, PN, USA',
    coordinates: {
      lat: 40.088333,
      lng: -75.390282,
    },
  },
  {
    id: '33',
    country: 'USA',
    state: 'North Carolina',
    region: '',
    locationName: 'Shoppes of Kildaire',
    locationCode: '0033',
    address: 'Cary, NC, USA',
    coordinates: {
      lat: 35.759682,
      lng: -78.781761,
    },
  },
  {
    id: '34',
    country: 'Canada',
    state: 'Ontario',
    region: '',
    locationName: 'Dufferin Mall',
    locationCode: '0034',
    address: 'Toronto, ON, Canada',
    coordinates: {
      lat: 43.655853,
      lng: -79.435959,
    },
  },
  {
    id: '35',
    country: 'Canada',
    state: 'Ontario',
    region: '',
    locationName: 'CF Fairview Mall',
    locationCode: '003',
    address: 'Toronto, ON, Canada',
    coordinates: {
      lat: 43.777882,
      lng: -79.345879,
    },
  },
  {
    id: '36',
    country: 'Canada',
    state: 'Ontario',
    region: '',
    locationName: 'Yorkdale Shopping Centre',
    locationCode: '0036',
    address: 'Toronto, Canada',
    coordinates: {
      lat: 43.725414,
      lng: -79.452293,
    },
  },
  {
    id: '37',
    country: 'Canada',
    state: 'Ontario',
    region: '',
    locationName: 'CF Toronto Eaton Centre',
    locationCode: '0037',
    address: '',
    coordinates: {
      lat: 43.654434,
      lng: -113.623055,
    },
  },
  {
    id: '38',
    country: 'Canada',
    state: 'Alberta',
    region: '',
    locationName: 'West Edmonton Mall',
    locationCode: '0038',
    address: 'Edmonton, AB, Canada',
    coordinates: {
      lat: 53.522778,
      lng: -113.623055,
    },
  },
  {
    id: '39',
    country: 'Canada',
    state: 'Ontario',
    region: '',
    locationName: 'CF Rideau Centre',
    locationCode: '0039',
    address: 'ON, Canada',
    coordinates: {
      lat: 45.425446,
      lng: -75.692375,
    },
  },
  {
    id: '40',
    country: 'Canada',
    state: 'Alberta',
    region: '',
    locationName: 'Manulife Place',
    locationCode: '0040',
    address: 'Edmonton, Canada',
    coordinates: {
      lat: 53.541492,
      lng: -113.494659,
    },
  },

  {
    id: '41',
    country: 'Australia',
    state: 'Victoria',
    region: '',
    locationName: 'Bourke StreetMall',
    locationCode: '0001',
    address: '284 Bourke St, Melbourne',
    coordinates: {
      lat: -37.81276042840492,
      lng: 144.96397988113534,
    },
  },
  {
    id: '42',
    country: 'Australia',
    state: 'New South Wales',
    region: '',
    locationName: 'Westfield Sydney',
    locationCode: '0002',
    address: 'Cnr Pitt Street Mall & Market St, Sydney',
    coordinates: {
      lat: -33.87051109427346,
      lng: 151.20820731058924,
    },
  },
  {
    id: '43',
    country: 'Australia',
    state: 'Victoria',
    region: '',
    locationName: 'Westfield Doncaster',
    locationCode: '0003',
    address: '619 Doncaster Road, Doncaster',
    coordinates: {
      lat: -37.784899492877166,
      lng: 145.1262027972515,
    },
  },
  {
    id: '44',
    country: 'Australia',
    state: 'New South Wales',
    region: '',
    locationName: 'Westfield Bondi Junction',
    locationCode: '0004',
    address: '500 Oxford street, Bondi Junction',
    coordinates: {
      lat: -33.89139135410782,
      lng: 151.2505119259326,
    },
  },
  {
    id: '45',
    country: 'Australia',
    state: 'Victoria',
    region: '',
    locationName: 'Chadstone Shopping Centre',
    locationCode: '0005',
    address: '1341 Dandenong Road, Chadstone',
    coordinates: {
      lat: -37.88696965567037,
      lng: 145.08485951074874,
    },
  },
  {
    id: '46',
    country: 'Australia',
    state: 'Australian Capital Territory',
    region: '',
    locationName: 'Canberra Centre',
    locationCode: '0006',
    address: '37 Bunda Street, Canberra',
    coordinates: {
      lat: -35.279521837283085,
      lng: 149.13416823947938,
    },
  },
  {
    id: '47',
    country: 'Australia',
    state: 'Victoria',
    region: '',
    locationName: 'Highpoint Shopping Centre',
    locationCode: '0007',
    address: '120-200 Rosamond Road, Maribyrnong',
    coordinates: {
      lat: -37.772644038291695,
      lng: 144.88963384142932,
    },
  },
  {
    id: '48',
    country: 'Australia',
    state: 'New South Wales',
    region: '',
    locationName: 'Westfield Chatswood',
    locationCode: '0008',
    address: '1 Anderson Street, Chatswood',
    coordinates: {
      lat: -37.77266099945391,
      lng: 144.88958019725095,
    },
  },
  {
    id: '49',
    country: 'Australia',
    state: 'South Australia',
    region: '',
    locationName: 'Burnside Village',
    locationCode: '0009',
    address: '447 Portrush Road, Glenside',
    coordinates: {
      lat: -34.94135240725132,
      lng: 138.6412266683016,
    },
  },
  {
    id: '50',
    country: 'Australia',
    state: 'New South Wales',
    region: '',
    locationName: 'Macquarie Centre',
    locationCode: '0010',
    address: 'Corner Herring Rd & Waterloo Rd, North Ryde',
    coordinates: {
      lat: -33.77729208054567,
      lng: 151.1182258701067,
    },
  },
  {
    id: '51',
    country: 'Australia',
    state: 'Western Australia',
    region: '',
    locationName: 'Garden City Shopping Centre',
    locationCode: '0011',
    address: '125 Riseley Street, Booragoon',
    coordinates: {
      lat: -32.03380107470019,
      lng: 115.83553545469924,
    },
  },
  {
    id: '52',
    country: 'Australia',
    state: 'Queensland',
    region: '',
    locationName: 'Robina Town Centre',
    locationCode: '0012',
    address: 'Robina Town Centre Drive, Robina',
    coordinates: {
      lat: -28.072968711467745,
      lng: 153.37774429689046,
    },
  },
]

export const BI_DEMO_STATES = toPairs(
  groupBy(BI_DEMO_LOCATIONS, 'state'),
).map(([state, data]) => ({ state, storeCount: data.length }))

export const BI_STORE_OPTIONS = BI_DEMO_LOCATIONS.map(item => ({
  value: item.id,
  label: item.locationName,
}))

export const BI_STATE_OPTIONS = uniq(
  BI_DEMO_LOCATIONS.map(item => item.state),
).map(state => ({ label: state, value: state }))

export const BI_GROUPED_STATE_OPTIONS = sortBy(
  toPairs(groupBy(BI_DEMO_LOCATIONS, 'country')).map(([country, stores]) => {
    return {
      label: country,
      options: uniq(stores.map(item => item.state))
        .sort()
        .map(state => ({
          label: state,
          value: state,
        })),
    }
  }),
  item => item.label,
)

function generateWeeklyData(min: number, max: number): number[] {
  return Array.from(Array(7).keys()).map(
    (_, idx) =>
      random(min, max) *
      ([5, 6].includes(idx)
        ? // Weekends
          random(1.0, 2.5)
        : 1),
  )
}

const NORMAL_DAILY_SALES = 25000
const NORMAL_DAILY_TRAFFIC = 880
const OFFSET_FROM_NORMAL = 1.25

function generateWidgetData(locations: DemoLocation[]) {
  return sortBy(
    locations.map(loc => {
      const weekSalesData = generateWeeklyData(
        NORMAL_DAILY_SALES / OFFSET_FROM_NORMAL,
        NORMAL_DAILY_SALES * OFFSET_FROM_NORMAL,
      )
      const totalWeekSales = sum(weekSalesData)

      const plannedSalesDate = generateWeeklyData(
        NORMAL_DAILY_SALES / OFFSET_FROM_NORMAL,
        NORMAL_DAILY_SALES * OFFSET_FROM_NORMAL,
      )

      const totalPlannedSales = sum(plannedSalesDate)

      const weekTrafficData = generateWeeklyData(
        NORMAL_DAILY_TRAFFIC / OFFSET_FROM_NORMAL,
        NORMAL_DAILY_TRAFFIC * OFFSET_FROM_NORMAL,
      )

      const totalWeekTraffic = sum(weekTrafficData)

      const plannedTrafficData = generateWeeklyData(
        NORMAL_DAILY_TRAFFIC / OFFSET_FROM_NORMAL,
        NORMAL_DAILY_TRAFFIC * OFFSET_FROM_NORMAL,
      )

      const totalPlannedTraffic = sum(plannedTrafficData)

      return {
        ...loc,
        weekSalesData,
        totalWeekSales,
        plannedSalesDate,
        totalPlannedSales,
        weekTrafficData,
        totalWeekTraffic,
        plannedTrafficData,
        totalPlannedTraffic,
      }
    }),
    item => [item.country, item.state, item.locationName],
  )
}

export const BI_DEMO_WIDGET_DATA = generateWidgetData(BI_DEMO_LOCATIONS)

import { createRelationAddHandler, createRelationDeleteHandler } from 'rapidux'

import { createReducer } from 'helpers/redux'

import {
  DELETE_TEMPORARY_BRANCH_ACCESS,
  DELETE_TEMPORARY_DEPARTMENT_ACCESS,
  SET_TEMPORARY_ACCESS_LEVEL,
} from 'store/actions/users'

const initialState = {}

const handlers = {
  [SET_TEMPORARY_ACCESS_LEVEL.SUCCESS]: (...args) => ({
    ...createRelationAddHandler('managers', 'allManagedBranches')(...args),
    ...createRelationAddHandler('managers', 'allManagedDepartments')(...args),
  }),

  [DELETE_TEMPORARY_BRANCH_ACCESS.SUCCESS]: createRelationDeleteHandler(
    'allManagedBranches',
  ),

  [DELETE_TEMPORARY_DEPARTMENT_ACCESS.SUCCESS]: createRelationDeleteHandler(
    'allManagedDepartments',
  ),
}

export default createReducer(initialState, handlers)

import React, { useMemo, useState } from 'react'

import { debounce } from 'lodash'

import { FilterOperator } from 'constants/pagination'

import { HandleSelectChange, TableFilterProps } from './types'

import { SEARCH_DEBOUNCE_TIMEOUT } from '../../constants'
import { TableSelect } from '../TableSelect'

export function SelectFilter({
  fieldName: field,
  pagination: { handleFilterChange = () => null, options },
  selectOptions,
}: TableFilterProps) {
  const operator = FilterOperator.In
  const filter = options?.filter
  const lastFieldUpdated = options?.lastFieldUpdated
  const [value, setValue] = useState(filter?.[field]?.[operator] ?? [])

  const handleSelectChange = useMemo(() => {
    return debounce(
      value =>
        handleFilterChange({
          field,
          operator,
          value,
          lastFieldUpdated: String(field),
        }),
      SEARCH_DEBOUNCE_TIMEOUT,
    )
  }, [field, handleFilterChange, operator])

  const handleChange: HandleSelectChange = value => {
    setValue(value)
    handleSelectChange(value)
  }

  return (
    <TableSelect
      autoFocus={field === lastFieldUpdated}
      options={selectOptions}
      value={value}
      onChange={handleChange}
    />
  )
}

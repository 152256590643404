import { connect } from 'react-redux'

import { createStructuredSelector } from 'reselect'

import { clearJobs, initJobs, loadJobs } from 'store/actions/areas'
import { getInitialJobs } from 'store/selectors/areas'

import JobsPicker from './JobsPicker'

const selectors = createStructuredSelector({
  initial: getInitialJobs,
})

const actions = {
  onInit: initJobs,
  onLoad: loadJobs,
  onClear: clearJobs,
}

export default connect(selectors, actions)(JobsPicker)

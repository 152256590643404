import { DateTime } from 'luxon'

import { KIND } from 'constants/availability'

import { areasToFilters } from 'helpers/areas'
import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const LOAD = createAsyncAction('company/availabilities/LOAD')
export const LOAD_DAY = createAsyncAction('company/availabilities/LOAD_DAY')
export const CREATE = createAsyncAction('company/availabilities/CREATE')
export const CLEAR_DAY = 'company/availabilities/CLEAR_DAY'

const availabilitiesForDayInclude = [
  'holidays',
  'acceptedTimeoffs.employee.branch',
  'acceptedTimeoffs.employee.profile.profileAvatar',
  'requestedTimeoffs.employee.branch',
  'requestedTimeoffs.employee.profile.profileAvatar',
  'preferences.employee.branch',
  'preferences.employee.profile.profileAvatar',
  'availabilities.employee.branch',
  'availabilities.employee.profile.profileAvatar',
  'acceptedTimeoffs.customLeaveDay',
  'requestedTimeoffs.customLeaveDay',
]

const availabilitiesInclude = {
  [KIND.SHORT]: ['holidays'],
  [KIND.EXPANDED]: availabilitiesForDayInclude,
}

const DEFAULT_AVAILAVBILITIES_SHORT_SUMMARY_PAGE = 31 // Note: one record per day
const DEFAULT_AVAILAVBILITIES_EXTENDED_SUMMARY_PAGE = 1 // There's always one record per day

export const loadAvailabilitiesSummary = ({
  from = null,
  to = null,
  areas = {},
  kind = KIND.SHORT,
  display,
} = {}) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  const startDate = (from || DateTime.utc().startOf('month')).toISODate()
  const endDate = (to || DateTime.utc().endOf('month')).toISODate()

  const query = {
    include: availabilitiesInclude[kind].join(),
    kind,
    filter: {
      date: {
        between: [startDate, endDate],
      },
      ...areasToFilters(areas),
    },
    page: { size: DEFAULT_AVAILAVBILITIES_SHORT_SUMMARY_PAGE },
  }

  if (display) {
    query.display = display
  }

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/employees/availabilities_summary`,
      types: LOAD,
      query,
      paged: true,
    }),
  )
}

export const loadExpandedAvailabilitiesSummary = ({
  day,
  areas = {},
  size = DEFAULT_AVAILAVBILITIES_EXTENDED_SUMMARY_PAGE,
} = {}) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  const startDate = day.startOf('day').toISO()
  const endDate = day.endOf('day').toISO()

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/employees/availabilities_summary`,
      types: LOAD_DAY,
      query: {
        include: availabilitiesForDayInclude.join(),
        kind: KIND.EXPANDED,
        filter: {
          date: {
            between: [startDate, endDate],
          },
          ...areasToFilters(areas),
        },
        page: { size },
      },
      paged: true,
    }),
  )
}

export const clearAvailabilities = () => ({
  type: CLEAR_DAY,
})

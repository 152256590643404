/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback } from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { pick } from 'lodash'

import { Checkbox } from 'components/ui/__v2__/Checkbox'
import { Flex } from 'components/ui/__v2__/Grid'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import { Error } from './components'
import { ControllerBaseProps } from './types'

import { LabelText } from '../Input/LabelText'

// Note: Update after typing the Checbox
type SpecificProps = {
  disabled?: boolean
  labelPlacement?: 'bottom' | 'top' | 'right'
  size?: number
  /**
    Note: We need this because for custom fields the form needs to store 'true' or 'false' as a string
    Quite awkward solution, but least risky at the moment
  */
  stringState?: boolean
  showTooltip?: boolean
}
export type FormCheckboxProps<T extends FieldValues> = ControllerBaseProps<T> &
  Omit<LabelProps, 'required'> &
  SpecificProps

export function FormCheckbox<T extends FieldValues>({
  control,
  disabled = false,
  name,
  size = 24,
  stringState,
  labelPlacement = 'right',
  showTooltip = true,
  ...rest
}: FormCheckboxProps<T>) {
  const {
    field: { onChange, value, ref, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  const checked = stringState ? value === 'true' : value
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target

      if (stringState) {
        onChange(checked ? 'true' : 'false')
      } else {
        onChange(checked)
      }
    },
    [onChange, stringState],
  )

  return (
    <Flex flexDirection="column">
      <Flex
        alignItems="center"
        flexDirection={flexDirection(labelPlacement)}
        justifyContent="start"
      >
        <label htmlFor={name}>
          <LabelText
            showTooltip={showTooltip}
            {...pick(rest, ['labelText', 'typography', 'wrapper', 'content'])}
          />
        </label>
        <Checkbox // @ts-ignore
          checked={checked}
          disabled={disabled}
          id={name}
          name={name}
          ref={ref}
          size={size}
          wrapper={{ mr: 2 }}
          onBlur={onBlur}
          onChange={handleChange}
        />
      </Flex>
      {error && <Error error={error} />}
    </Flex>
  )
}

function flexDirection(
  labelPlacement: SpecificProps['labelPlacement'],
): 'row-reverse' | 'column' | 'column-reverse' {
  switch (labelPlacement) {
    case 'bottom':
      return 'column-reverse'
    case 'right':
      return 'row-reverse'
    case 'top':
      return 'column'
    default:
      return 'row-reverse'
  }
}

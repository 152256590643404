import { DateTime } from 'luxon'
import RRule from 'rrule'

import first from 'lodash/first'
import has from 'lodash/has'

import { MONTH_OPTIONS } from 'components/blocks/__v2__/Repeat/constants'

import i18n from 'i18n'

import { ReferenceYearEndDateKinds } from './ids'

const TIME_FORMAT = "yyyy'-'MM'-'dd"
const formatTime = (() => {
  const cachedAnswer = {}
  return time => {
    if (has(cachedAnswer, time)) return cachedAnswer[time]
    cachedAnswer[time] = DateTime.fromFormat(time, TIME_FORMAT)
    return cachedAnswer[time]
  }
})()

export const isDayContainsTimeOff = (timeoff, date) => {
  const { state, startAt, finishAt } = timeoff || {}

  if (state === 'accepted') {
    const formattedStart = formatTime(startAt)
    const formattedFinish = formatTime(finishAt)
    const formattedDate = DateTime.fromISO(date)

    const isStartDay = formattedStart.hasSame(formattedDate, 'day')
    const isFinishDay = formattedFinish.hasSame(formattedDate, 'day')
    const isInDaysRange =
      formattedDate.startOf('day').diff(formattedStart) > 0 &&
      formattedDate.endOf('day').diff(formattedFinish) < 0

    return isStartDay || isFinishDay || isInDaysRange
  }

  return false
}

export const LEAVE_POLICY_KINDS = {
  unlimited: 'unlimited',
  manual: 'manual',
}

export const leavePolicyOptions = () =>
  Object.values(LEAVE_POLICY_KINDS).map(policyKindToOption)

export function policyKindToOption(value) {
  return { value, label: i18n(`leavesSummary.policyKinds.${value}`) }
}

export const referenceYearEndDateOptions = () =>
  Object.values(Object.values(ReferenceYearEndDateKinds)).map(
    referenceYearEndDateToOption,
  )

export function referenceYearEndDateToOption(option) {
  return {
    value: option,
    label: i18n(`recurrence.select.referenceYearEndDate.${option}`),
  }
}

export function getLeaveYearEndMonthAndDay(rruleString) {
  const rule = RRule.fromString(rruleString)

  const { bymonthday, bymonth } = rule.options

  return {
    month: MONTH_OPTIONS.find(option => option.bymonth === first(bymonth)),
    day: first(bymonthday),
  }
}

export function leaveYearEndDateRuleStringToReferenceYearText(
  rruleString,
  year = null,
) {
  const rule = RRule.fromString(rruleString)
  const { bymonthday, bymonth } = rule.options

  const start = DateTime.fromObject({
    month: bymonth,
    day: bymonthday,
  })
    .plus({ days: 1 })
    .set({ year })

  const end = start.plus({ years: 1, days: -1 })

  return year
    ? `${start.toLocaleString(DateTime.DATE_MED)} - ${end.toLocaleString(
        DateTime.DATE_MED,
      )}`
    : `${start.toFormat('LLL dd')} - ${end.toFormat('LLL dd')}`
}

export function hireDateToReferenceYearText(hiredAt, year = null) {
  const start = DateTime.fromISO(hiredAt).set({ year })
  const end = start.plus({ years: 1, days: -1 })

  return year
    ? `${start.toLocaleString(DateTime.DATE_MED)} - ${end.toLocaleString(
        DateTime.DATE_MED,
      )}`
    : `${start.toFormat('LLL dd')} - ${end.toFormat('LLL dd')}`
}

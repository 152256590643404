import ArchiveEmploymentLevelMutation from './archiveEmploymentLevel.graphql'
import CreateEmploymentLevelMutation from './createEmploymentLevel.graphql'
import DeleteEmploymentLevelMutation from './deleteEmploymentLevel.graphql'
import UnarchiveEmploymentLevelMutation from './unarchiveEmploymentLevel.graphql'
import UpdateEmploymentLevelMutation from './updateEmploymentLevel.graphql'

export {
  ArchiveEmploymentLevelMutation,
  CreateEmploymentLevelMutation,
  DeleteEmploymentLevelMutation,
  UnarchiveEmploymentLevelMutation,
  UpdateEmploymentLevelMutation,
}

import React, { useCallback, useEffect } from 'react'
import { FieldValues, useWatch } from 'react-hook-form'

import { CustomCVFieldFilterCandidate } from 'components/blocks/__v3__/CustomCvFieldsPicker/types'
import { FormText } from 'components/ui/__v3__/HookForm'

import { CustomCvFieldFieldType } from 'constants/gatewayGraphQL'

import usePrevious from 'hooks/usePrevious'

import { FieldValuePairProps } from './types'

import { FormCustomCvFieldsPicker } from '../../../FormCustomCvFieldsPicker'
import { FormCustomFieldInputRenderer } from '../../../FormCustomFieldRenderer'
import { optionProvidedGuard } from '../../helpers'
import { FieldValuePairState } from '../../types'

export function FieldValuePair<T extends FieldValues>({
  control,
  selectedFieldIds,
  onValueReset,
  name,
}: FieldValuePairProps<T>) {
  // @ts-ignore Note: there's a TS performance issue, should be resolved in RHC v8
  const item = useWatch<T>({ control, name }) as FieldValuePairState

  const fieldSelected = optionProvidedGuard(item)

  const prevCustomFieldOption = usePrevious<FieldValuePairState['field']>(
    item?.field,
  )

  // Resets the value if another field is picked
  useEffect(() => {
    if (fieldChanged(item?.field, prevCustomFieldOption)) {
      // @ts-ignore
      onValueReset()
    }
  }, [item?.field, name, onValueReset, prevCustomFieldOption])

  const filterOutSelected = useCallback(
    (candidate: CustomCVFieldFilterCandidate) => {
      return !selectedFieldIds.includes(candidate.data.id)
    },
    [selectedFieldIds],
  )

  return (
    <>
      <FormCustomCvFieldsPicker<T>
        control={control}
        // @ts-ignore
        filterOption={filterOutSelected}
        isClearable={false}
        // @ts-ignore
        name={`${name}.field`}
      />
      {fieldSelected ? (
        <FormCustomFieldInputRenderer<T>
          content={{ maxWidth: '150px' }}
          control={control}
          // @ts-ignore
          fieldType={item.field.customCvField.fieldType}
          labelText={
            item.field.customCvField.fieldType ===
            CustomCvFieldFieldType.Checkbox
              ? item.field.customCvField.name ??
                item.field.customCvField.description
              : undefined
          }
          // @ts-ignore
          name={`${name}.value`}
          options={item.field.customCvField.data.options}
          showCheckboxTooltip={false}
        />
      ) : (
        <FormText<T>
          control={control}
          disabled
          // @ts-ignore
          name={`${name}.value`}
        />
      )}
    </>
  )
}

function fieldChanged(
  current: FieldValuePairState['field'],
  prev: FieldValuePairState['field'],
): boolean {
  if (!prev) return false

  return prev.id !== current?.id
}

import React, { useCallback, useMemo } from 'react'

import { ShiftJobsByCursorNode } from 'API/ShiftJob/GraphQL'

import { Select } from 'components/ui/__v3__'
import { IconError } from 'components/ui/__v3__/Input/Errors'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { PositionOption } from './components'
import { ShiftJobOption } from './types'

import { useShiftAssignmentContext } from '../../context'

type Props = {
  shiftJobs: ShiftJobsByCursorNode[]
  deferredLoading: boolean
}

export function Positions({ shiftJobs, deferredLoading }: Props) {
  const t = useI18n<typeof TRANSLATIONS.shiftAssign.positions>(
    'shiftAssign.positions',
  )
  const {
    selectedShiftJob,
    missingAssignments,
    hasUnappliedChanges,
    handleChangeSelectedShiftJob,
  } = useShiftAssignmentContext()

  const options = useMemo(
    () =>
      shiftJobs.map(shiftJob => {
        const selected = shiftJob.id === selectedShiftJob?.id
        return shiftJobToOption({
          shiftJob,
          currentUnfilledPositions: selected
            ? missingAssignments
            : shiftJob.unfilledPositions,
        })
      }),
    [missingAssignments, selectedShiftJob?.id, shiftJobs],
  )
  const currentOption = useMemo(
    () =>
      // Note: Fallback to non-selected
      selectedShiftJob
        ? shiftJobToOption({
            shiftJob: selectedShiftJob,
            currentUnfilledPositions: missingAssignments,
          })
        : null,
    [missingAssignments, selectedShiftJob],
  )
  const onSelect = useCallback(
    (option: ShiftJobOption) => {
      handleChangeSelectedShiftJob(option.value)
    },
    [handleChangeSelectedShiftJob],
  )
  const formatOptions = useCallback(
    (option: ShiftJobOption) => <PositionOption option={option} />,
    [],
  )

  const showHelper = hasUnappliedChanges

  return (
    <Select
      // @ts-ignore
      formatOptionLabel={formatOptions}
      helper={showHelper && <IconError>{t('hasUnsavedChanges')}</IconError>}
      isClearable={false}
      isDisabled={
        hasUnappliedChanges || options.length === 1 || deferredLoading
      }
      labelContent={t('title')}
      options={options}
      value={currentOption}
      withPortal
      // Note: this is a hack to align the button properly
      wrapper={{ paddingBottom: showHelper ? undefined : '20px' }}
      onChange={onSelect}
    />
  )
}

function shiftJobToOption({
  shiftJob,
  currentUnfilledPositions,
}: {
  shiftJob: ShiftJobsByCursorNode
  currentUnfilledPositions: number
}): ShiftJobOption {
  const { job, id, department } = shiftJob
  return {
    label: `${department.name} / ${job.name}`,
    value: id,
    data: shiftJob,
    currentUnfilledPositions,
  }
}

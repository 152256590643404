import { StoreonModule } from 'storeon'
import { DateLocale } from 'Types/app'

import { getDefaultLocale } from './helper'

import { INIT } from '../constants'

export const LOCALE_KEY = 'locale'

export const SET_LOCALE_EVENT = 'locale/set'

const DEFAULT_LOCALE = getDefaultLocale()

export interface LocaleState {
  [LOCALE_KEY]: keyof DateLocale
}

export interface LocaleEvents {
  [SET_LOCALE_EVENT]: keyof DateLocale
}

export const INITIAL_STATE: LocaleState = {
  [LOCALE_KEY]: DEFAULT_LOCALE,
}

export const localeModule: StoreonModule<LocaleState, LocaleEvents> = store => {
  store.on(INIT, () => INITIAL_STATE)

  store.on(SET_LOCALE_EVENT, (_, newLocale) => {
    return { [LOCALE_KEY]: newLocale }
  })
}

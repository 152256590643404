import {
  MAX_DAILY_HOURS,
  MAX_MINUTES_IN_HOUR,
} from 'constants/dailyWorkingHours'

function getHoursSelectOptions() {
  const hoursOptions = []
  for (let i = 0; i <= MAX_DAILY_HOURS; i += 1) {
    hoursOptions.push({ value: i, label: `${i}` })
  }
  return hoursOptions
}
export const HOURS_OPTIONS = getHoursSelectOptions()

function getMinutesSelectOptions() {
  const minutesOptions = []
  for (let i = 0; i < MAX_MINUTES_IN_HOUR; i += 1) {
    minutesOptions.push({ value: i, label: `${i}` })
  }
  return minutesOptions
}
export const MINUTES_OPTIONS = getMinutesSelectOptions()
export const DEFAULT_WEEK_START_DAY = 7

import { useApolloLazyQuery } from 'API/services/Apollo'

import {
  PayFrequencySortingField,
  SortingDirection,
} from 'constants/gatewayGraphQL'
import { PAGE_SIZES } from 'constants/pagination'

import { PayFrequenciesPickerQuery } from './GraphQL'

type Props = {
  paging?: Gateway.QueryPayFrequenciesByOffsetArgs['paging']
  sorting?: Gateway.QueryPayFrequenciesByOffsetArgs['sorting']
  filter?: Gateway.QueryPayFrequenciesByOffsetArgs['filter']
}

type QueryReturnData = {
  payFrequenciesByOffset: {
    __typename?: 'PayFrequenciesByOffset'
    rows: Array<
      Pick<Gateway.PayFrequency, 'id' | 'name' | 'type' | 'startDate'>
    >
  }
}

export function useLazyPayFrequenciesByOffset({
  paging,
  sorting,
  filter,
}: Props) {
  const [loadPayFrequencies, { data, loading, refetch }] = useApolloLazyQuery<
    QueryReturnData,
    Gateway.QueryPayFrequenciesByOffsetArgs
  >(PayFrequenciesPickerQuery, {
    variables: {
      filter,
      sorting: sorting ?? DEFAULT_SORTING,
      paging: paging ?? DEFAULT_PAGING,
    },
    fetchPolicy: 'network-only',
  })

  const defaultPayFrequencies = data?.payFrequenciesByOffset?.rows ?? []

  return {
    loadPayFrequencies,
    defaultPayFrequencies,
    loading,
    refetch,
  }
}

const DEFAULT_PAGING = {
  limit: PAGE_SIZES[0],
  offset: 0,
}

const DEFAULT_SORTING = [
  {
    field: PayFrequencySortingField.Name,
    direction: SortingDirection.Asc,
  },
]

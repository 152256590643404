import merge from 'lodash/merge'

import { createReducer } from 'helpers/redux'

import { UPDATE_COMPANY_SETTINGS } from 'store/actions/company/companies'

const initialState = {}

const handlers = {
  [UPDATE_COMPANY_SETTINGS.SUCCESS]: (state, { payload }) => {
    const { companyId, settings } = payload
    return merge({}, state, {
      [companyId]: {
        attributes: {
          settings,
        },
      },
    })
  },
}

export default createReducer(initialState, handlers)

import { useApolloMutation } from 'API/services/Apollo'
import { SelectOption } from 'Types/common'

import { isEmpty } from 'lodash'

import { EmployeePickerReturnOption } from 'components/blocks/__v3__'

import Utils from 'services/Utils'

import { StartAldoPayrollRowsSyncMutation } from './GraphQL'

type StartAldoPayrollRowsCalculationArgs = {
  period: { from: string; to: string }
  employees: EmployeePickerReturnOption[] | null
  changeTypes: SelectOption<Gateway.AldoPayrollRowChangeType>[] | null
  targetSystem: SelectOption<Gateway.AldoPayrollRowTargetSystem> | null
}

export function useStartAldoPayrollRowsSync({
  period,
  employees,
  changeTypes,
  targetSystem,
}: StartAldoPayrollRowsCalculationArgs) {
  const variables: Gateway.MutationStartAldoPayrollRowsSyncArgs = {
    period: Utils.GraphQL.betweenFilter(period),
    filter: {
      ...(targetSystem && {
        targetSystem: Utils.GraphQL.eqFilter(targetSystem.value),
      }),
      ...(!isEmpty(changeTypes) && {
        changeType: Utils.GraphQL.inFilter(
          changeTypes?.map(changeType => changeType.value),
        ),
      }),
      ...(!isEmpty(employees) && {
        employeeId: Utils.GraphQL.inFilter(
          employees?.map(employee => employee.value),
        ),
      }),
    },
  }

  const [startAldoPayrollRowsSync, { loading }] = useApolloMutation<
    MutationData<'startAldoPayrollRowsSync'>,
    Gateway.MutationStartAldoPayrollRowsSyncArgs
  >(StartAldoPayrollRowsSyncMutation, {
    variables,
    translationKey: 'aldo.payroll.toasts.startSync',
  })

  return {
    startSync: startAldoPayrollRowsSync,
    starting: loading,
  }
}

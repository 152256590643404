import { ToastContainer as LibToastContainer } from 'react-toastify'

import styled from 'styled-components'

export const ToastContainer = styled(LibToastContainer)`
  z-index: ${({ theme }) => theme.zIndices.popup};

  .Toastify__toast--success {
    color: ${({ theme }) => theme.toasts.primaryColor.text};
    background: ${({ theme }) => theme.toasts.primaryColor.success};
  }
  .Toastify__progress-bar--success {
    background-color: ${({ theme }) => theme.toasts.secondaryColor.success};
  }

  .Toastify__toast--error {
    color: ${({ theme }) => theme.toasts.primaryColor.text};
    background: ${({ theme }) => theme.toasts.primaryColor.error};
  }
  .Toastify__progress-bar--error {
    background-color: ${({ theme }) => theme.toasts.secondaryColor.error};
  }

  .Toastify__toast--info {
    color: ${({ theme }) => theme.toasts.primaryColor.text};
    background: ${({ theme }) => theme.toasts.primaryColor.info};
  }
  .Toastify__progress-bar--info {
    background-color: ${({ theme }) => theme.toasts.secondaryColor.info};
  }

  .Toastify__toast--warning {
    color: ${({ theme }) => theme.toasts.primaryColor.text};
    background: ${({ theme }) => theme.toasts.primaryColor.warning};
  }
  .Toastify__progress-bar--warning {
    background-color: ${({ theme }) => theme.toasts.secondaryColor.warning};
  }

  .Toastify__close-button {
    color: ${({ theme }) => theme.toasts.primaryColor.text};
  }
`

import React from 'react'

import { TextOverflowWithPopover } from 'components/blocks/__v3__/TextOverflowWithPopover'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import { LabelTextContainer } from './styles'

export function LabelText({
  showTooltip = true,
  labelText,
  required,
  wrapper,
  content,
  typography,
}: LabelProps) {
  return labelText ? (
    <LabelTextContainer {...wrapper}>
      {showTooltip ? (
        <TextOverflowWithPopover {...content} {...typography}>
          {labelText}
        </TextOverflowWithPopover>
      ) : (
        labelText
      )}
      &nbsp;
      {required && <sup>*</sup>}{' '}
    </LabelTextContainer>
  ) : null
}

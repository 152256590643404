import { useCallback, useMemo, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import {
  shiftFilterToAPI,
  ShiftsByCursorQuery,
  shiftSortToAPI,
  shiftToAPIITem,
} from './GraphQL'
import { ShiftFilter, ShiftSort } from './types'

export function useShiftsByCursor({
  page,
  filter,
  sort,
  enabled = true,
}: {
  page: CursorPage
  filter: ShiftFilter
  sort: ShiftSort
  enabled?: boolean
}) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, loading, refetch, fetchMore } = useApolloQuery<
    QueryData<'shiftsByCursor'>,
    Gateway.QueryShiftsByCursorArgs
  >(ShiftsByCursorQuery, {
    fetchPolicy: 'network-only',
    skip: !enabled,

    variables: {
      sorting: shiftSortToAPI(sort),
      filter: shiftFilterToAPI(filter),
      paging: cursorPageToGraphqlPaging(page),
    },

    onCompleted(data) {
      const newPageMeta = data.shiftsByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const shifts = useMemo(
    () =>
      data?.shiftsByCursor?.edges?.map(edge => shiftToAPIITem(edge.node)) ?? [],
    [data?.shiftsByCursor],
  )

  const shiftsFetchMore = useCallback(async () => {
    await fetchMore({
      variables: {
        paging: {
          limit: page.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })
  }, [currentPageMeta.endCursor, fetchMore, page.size])

  return {
    shifts,
    shiftsLoading: loading,
    shiftsRefetch: refetch,
    shiftsFetchMore,
    shiftsPageMeta: currentPageMeta,
  }
}

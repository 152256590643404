import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import { GroupsByCursorQuery, UpdateGroupMutation } from './GraphQL'

export function useUpdateGroup() {
  const [innerUpdate, { loading: updating }] = useApolloMutation<
    MutationData<'updateGroup'>,
    Gateway.MutationUpdateGroupArgs
  >(UpdateGroupMutation, {
    translationKey: 'settings.groups.toasts.updateGroup',
    refetchQueries: compact([getOperationName(GroupsByCursorQuery)]),
  })

  const updateGroup = async (id: string, values: Gateway.UpdateGroupInput) => {
    return innerUpdate({
      variables: {
        id,
        input: values,
      },
    })
  }

  return { updateGroup, updating }
}

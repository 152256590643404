import { useApolloMutation } from 'API/services/Apollo'

import { UpdateShiftMutation } from './GraphQL'

export function useUpdateShift() {
  const [updateShift, { loading }] = useApolloMutation<
    MutationData<'updateShift'>,
    Gateway.MutationUpdateShiftArgs
  >(UpdateShiftMutation)

  const update = async (input: Gateway.UpdateShiftInput) => {
    return updateShift({
      variables: {
        input,
      },
    })
  }

  return { update, updating: loading }
}

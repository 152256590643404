import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { Switch } from 'components/ui/Switch'

import _ from 'i18n'

import SettingsModal from './SettingsModal'
import { InputLabel, InputRow } from './styles'

class NotificationSetting extends PureComponent {
  constructor(props) {
    super(props)

    const { notifications } = props.userSettings

    this.state = {
      isChecked: notifications,
    }
  }

  toggleNotification = () => {
    this.setState(prevState => ({ isChecked: !prevState.isChecked }))
  }

  handleSubmit = () => {
    const { userSettings, onUpdateUserSettings, onClose } = this.props
    const { isChecked } = this.state
    if (isChecked === userSettings.notifications) {
      onClose()
    } else {
      const settings = {
        ...userSettings,
        ...{
          notifications: isChecked,
        },
      }
      onUpdateUserSettings(settings)
      onClose()
    }
  }

  render() {
    const { onClose } = this.props
    const { isChecked } = this.state
    return (
      <SettingsModal
        subTitle={_('settings.notifications').toUpperCase()}
        onClose={onClose}
        onSubmit={this.handleSubmit}
      >
        <InputRow>
          <InputLabel>{_('settings.requireEmployerConfirmation')}</InputLabel>
          <Switch checked={isChecked} onChange={this.toggleNotification} />
        </InputRow>
      </SettingsModal>
    )
  }
}

NotificationSetting.propTypes = {
  userSettings: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateUserSettings: PropTypes.func.isRequired,
}

export default NotificationSetting

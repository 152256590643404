import { useState } from 'react'

import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus'
import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'

import { compact } from 'lodash'

import { IntegrationEventsByCursor } from './GraphQL'

import Utils from '../../services/Utils'
import { CursorPage } from '../../Types/common'

export function useIntegrationEventsByCursor({
  page,
  filter,
  sorting,
}: {
  page: CursorPage
  filter: { integrationId?: string }
  sorting?: Gateway.IntegrationEventCursorSorting
}) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, refetch, networkStatus } = useApolloQuery<
    QueryData<'integrationEventsByCursor'>,
    Gateway.QueryIntegrationEventsByCursorArgs
  >(IntegrationEventsByCursor, {
    variables: {
      sorting,
      ...(filter.integrationId && {
        filter: { integrationId: Utils.GraphQL.eqFilter(filter.integrationId) },
      }),
      paging: cursorPageToGraphqlPaging(page),
    },
    onCompleted(data) {
      const newPageMeta = data.integrationEventsByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) setCurrentPageMeta(newPageMeta)
    },
    nextFetchPolicy: 'network-only',
  })

  const isLoading = isNetworkRequestInFlight(networkStatus)
  const integrationEvents = compact(
    data?.integrationEventsByCursor?.edges?.map(({ node }) => node && node) ??
      [],
  )

  return {
    integrationEvents,
    isLoading,
    refetch,
    currentPageMeta,
  }
}

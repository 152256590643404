import { useApolloQuery } from 'API/services/Apollo'

import { EffectiveEmployeeTimetypesQuery } from './GraphQL'

type Args = {
  employeeId: string
}

export function useEmployeeEffectiveTimeTypes({ employeeId }: Args) {
  const { data, loading, refetch, error } = useApolloQuery<
    QueryData<'effectiveEmployeeTimetypes'>,
    Gateway.QueryEffectiveEmployeeTimetypesArgs
  >(EffectiveEmployeeTimetypesQuery, {
    variables: {
      employeeCoreId: Number(employeeId),
    },
    fetchPolicy: 'network-only',
  })

  const effectiveTimeTypes = data?.effectiveEmployeeTimetypes

  return {
    effectiveTimeTypes,
    loading,
    refetch,
    error,
  }
}

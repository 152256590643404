import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-final-form'
import PropTypes from 'prop-types'

import forEach from 'lodash/forEach'

import ConfirmationModal from 'components/modals/ConfirmationModal'
import { IconError } from 'components/ui/__v3__/Input/Errors'

import _ from 'i18n'

import { ValidationError } from './styles'

import { Checkbox } from '../Checkbox'
/**
 * @see https://final-form.org/docs/react-final-form/types/FieldRenderProps for FormRenderProps props
 * @see {@link ../Checkbox/Checkbox.js} for Checkbox props
 * @param dependentFields if checkbox is unchecked then values of dependentFields are cleared
 * @param checkedWarning if present - will open a cofirmation modal upon check with etnered message, change is only triggered upon confirmation
 * @param uncheckedWarning if present- will open a cofirmation modal upon uncheck with etnered message, change is only triggered upon confirmation
 */

function FormCheckbox({
  input,
  meta,
  checkedWarning,
  dependentFields,
  uncheckedWarning,
  onBlur,
  ...rest
}) {
  const DEFAULT_CONFIRMATION = useMemo(
    () => ({
      isOpen: false,
      message: '',
      title: _('common.dataChangeWarning'),
      confirmText: _('actions.yesProceed'),
      cancelText: _('actions.cancel'),
    }),
    [],
  )
  const [confirmation, setConfirmation] = useState(DEFAULT_CONFIRMATION)
  const { change } = useForm()

  const { name, value } = input

  const handleChange = useCallback(
    checked => {
      change(name, checked)

      if (!checked) {
        forEach(dependentFields, field => {
          change(field, null)
        })
      }

      if (onBlur) {
        onBlur()
      }
    },
    [name, change, dependentFields, onBlur],
  )
  const handleClose = useCallback(() => setConfirmation(DEFAULT_CONFIRMATION), [
    DEFAULT_CONFIRMATION,
  ])

  const handleValueInput = useCallback(
    event => {
      event.persist()
      const wasChecked = event.target.checked
      const isShouldTriggerWarning =
        (wasChecked && checkedWarning) || (!wasChecked && uncheckedWarning)
      if (isShouldTriggerWarning) {
        setConfirmation(ps => ({
          ...ps,
          isOpen: true,
          message: wasChecked ? checkedWarning : uncheckedWarning,
          onConfirm: () => {
            handleChange(wasChecked)
            handleClose()
          },
        }))
      } else {
        handleChange(wasChecked)
      }
    },
    [checkedWarning, handleChange, handleClose, uncheckedWarning],
  )

  return (
    <>
      {(checkedWarning || uncheckedWarning) && (
        <ConfirmationModal {...confirmation} onClose={handleClose} />
      )}
      <Checkbox
        {...input}
        checked={value}
        helper={
          meta.error &&
          meta.touched && (
            <ValidationError>
              <IconError> {meta.error}</IconError>
            </ValidationError>
          )
        }
        onChange={handleValueInput}
        {...rest}
      />
    </>
  )
}

FormCheckbox.defaultProps = {
  checkedWarning: null,
  dependentFields: [],
  uncheckedWarning: null,
}

FormCheckbox.propTypes = {
  ...Checkbox.propTypes,
  checkedWarning: PropTypes.string,
  dependentFields: PropTypes.array,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  uncheckedWarning: PropTypes.string,
}

export default FormCheckbox

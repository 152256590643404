import { DateTime } from 'luxon'

type BaseTimeEntry = Pick<Gateway.TimeEntry, 'startAt' | 'endAt'> & {
  location: Pick<Gateway.Location, 'settings'>
}

export function getStart(timeEntry: BaseTimeEntry): DateTime {
  return DateTime.fromISO(timeEntry.startAt).setZone(
    timeEntry.location.settings.timezone,
  )
}

export function getEnd(timeEntry: BaseTimeEntry): DateTime {
  return DateTime.fromISO(timeEntry.endAt).setZone(
    timeEntry.location.settings.timezone,
  )
}

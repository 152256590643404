import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import {
  DeleteEmploymentLevelMutation,
  EmploymentLevelsByCursorQuery,
} from './GraphQL'

export function useDeleteEmploymentLevel() {
  const [innerDelete, { loading: deleting }] = useApolloMutation<
    MutationData<'deleteEmploymentLevel'>,
    Gateway.MutationDeleteEmploymentLevelArgs
  >(DeleteEmploymentLevelMutation, {
    refetchQueries: compact([getOperationName(EmploymentLevelsByCursorQuery)]),
  })

  const deleteEmploymentLevel = async (id: string) =>
    innerDelete({
      variables: {
        id,
      },
    })

  return { deleteEmploymentLevel, deleting }
}

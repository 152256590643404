import React from 'react'

import { flexRender } from '@tanstack/react-table'

import { PaginationVariables } from 'hooks/useOffsetPagination'

import { TableHeader } from './components/TableHeader'
import { TablePagination } from './components/TablePagination'
import { TableBody } from './components'
import {
  Container,
  StyledTable,
  TableContainer,
  Tf,
  Th,
  Thead,
  Tr,
} from './styles'
import { TableProps } from './types'

/** @deprecated */
export function TableOld<Query, QueryVariables extends PaginationVariables>({
  table,
  cellBorder,
  pagination,
  total,
  name,
  height,
  width,
  noHeader,
  noActions,
  noResetFilter,
  loading,
}: TableProps<Query, QueryVariables>) {
  return (
    <Container width={width}>
      {!noHeader && (
        <TableHeader<Query, QueryVariables>
          name={name}
          noResetFilter={noResetFilter}
          pagination={pagination}
        />
      )}

      <TableContainer height={height}>
        <StyledTable>
          {/* TODO: styles, move to component */}
          <Thead>
            {table.getHeaderGroups().map(headerGroup => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <Th
                    cellBorder={cellBorder}
                    colSpan={header.colSpan}
                    key={header.id}
                    noActions={noActions}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          <TableBody
            cellBorder={cellBorder}
            loading={loading}
            noActions={noActions}
            table={table}
            total={total}
          />

          {/* TODO: make optional, styles, move to component, make sticky. issue with 2 tr */}
          <tfoot>
            {table.getFooterGroups().map(footerGroup => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map(header => (
                  <Tf cellBorder={cellBorder} key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext(),
                        )}
                  </Tf>
                ))}
              </tr>
            ))}
          </tfoot>
        </StyledTable>
      </TableContainer>

      <TablePagination<Query, QueryVariables>
        pagination={pagination}
        total={total}
      />
    </Container>
  )
}

import { useCallback } from 'react'

import { useStoreon } from 'storeon/react'

import { GARDA_REPORTS_STATE_KEY } from './module'
import {
  GardaReportsEvents,
  GardaReportsPayload,
  GardaReportsState,
  RemoveGardaReportPayload,
  UpdateGardaReportPayload,
} from './types'

export function useGardaReportsState() {
  const { [GARDA_REPORTS_STATE_KEY]: state, dispatch } = useStoreon<
    GardaReportsState,
    GardaReportsEvents
  >(GARDA_REPORTS_STATE_KEY)

  const reports = state

  const addReport = useCallback(
    (payload: GardaReportsPayload) => dispatch('gardaReports/set', payload),
    [dispatch],
  )

  const removeReport = useCallback(
    (payload: RemoveGardaReportPayload) =>
      dispatch('gardaReports/remove', payload),
    [dispatch],
  )

  const updateReport = useCallback(
    (payload: UpdateGardaReportPayload) =>
      dispatch('gardaReports/update', payload),
    [dispatch],
  )

  return { addReport, updateReport, removeReport, reports }
}

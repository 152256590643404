import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const url = props => props.url

export const Image = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 360px;
  height: 270px;
  background: url(${url}) center center no-repeat;
  background-size: contain;
  padding: ${themeGet('space.2')}px;
`

import React from 'react'
import { Field } from 'react-final-form'
import { PropTypes } from 'prop-types'

import { FormCheckbox } from 'components/ui/__v2__/Form'
import { SpacedRow, Span } from 'components/ui/__v2__/Grid'

import { Answer } from '../styles'

function SingleCheckbox({
  disabled,
  name,
  isRequired,
  isReadOnly,
  onChange,
  placeholder,
  label,
  ...rest
}) {
  return (
    <Field name={name}>
      {props => (
        <SpacedRow alignItems="center" space={1}>
          <FormCheckbox
            {...props}
            {...rest}
            disabled={disabled || isReadOnly}
            placeholder={placeholder}
            size={18}
            onBlur={onChange}
          />
          {isReadOnly && props.input.value ? (
            <Answer>
              {label}
              {isRequired && <sup>*</sup>}
            </Answer>
          ) : (
            <Span>
              {label}
              {isRequired && <sup>*</sup>}
            </Span>
          )}
        </SpacedRow>
      )}
    </Field>
  )
}

SingleCheckbox.defaultProps = {
  disabled: false,
  isReadOnly: false,
}

SingleCheckbox.propTypes = {
  disabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,

  ...Field.propTypes,
}

export default SingleCheckbox

import { CursorPage } from 'Types/common'

export function cursorPageToGraphqlPaging(
  page: CursorPage,
): Gateway.CursorPaging {
  return {
    limit: page.size,
    startingAfter: page.startingAfter,
    endingBefore: page.endingBefore,
  }
}

import { createSelector } from 'reselect'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { filterSchedulesByWeek } from 'constants/schedule'

import { getCurrentWeek, getWeekFromDate } from 'helpers/date'
import { denormalize } from 'helpers/redux'

import { getBranchSettings } from 'store/selectors/viewer'

import { getData } from '../data'
import {
  getNotificationShiftCover,
  getNotificationShiftTrade,
} from '../notifications'

// undoable history API (past, present, future)
export const getState = state => state.employees.schedules.present
// export const getPastState = state => state.employees.schedules.past
export const getFutureState = state => state.employees.schedules.future

export const getWeek = createSelector(
  getState,
  getBranchSettings,
  (schedules, branchSettings) => {
    const workStartWeekDay = get(branchSettings, 'workStartWeekDay', 1)

    return !isEmpty(branchSettings) && isEmpty(schedules.week)
      ? getCurrentWeek(workStartWeekDay)
      : schedules.week
  },
)

export const getSelectedRowsEmployeeId = createSelector(
  getState,
  schedules => schedules.selectedRowsEmployeeId,
)

export const getSelectedColumnsDayIndex = createSelector(
  getState,
  schedules => schedules.selectedColumnsDayIndex,
)

export const getIsLoadingSchedules = createSelector(
  getState,
  schedules => schedules.isLoadingSchedules,
)
export const getIsLoading = createSelector(
  getState,
  schedules => schedules.isLoading,
)

export const getIsLoaded = createSelector(
  getState,
  schedules => schedules.isLoaded,
)

export const getIsLoadingSchedulesLogs = createSelector(
  getState,
  schedules => schedules.isLoadingSchedulesLogs,
)

export const getScheduleIds = createSelector(getState, schedules =>
  Array.from(schedules.schedules),
)

export const getSchedulesLogsIds = createSelector(getState, schedules =>
  Array.from(schedules.schedulesLogs),
)

export const getSchedulesLogs = createSelector(
  getData,
  getSchedulesLogsIds,
  (data, schedulesLogsIds) =>
    !isEmpty(schedulesLogsIds)
      ? denormalize(data, 'schedulesLogs', schedulesLogsIds)
      : [],
)

export const getAcceptedTimeoffIds = createSelector(getState, schedules =>
  Array.from(schedules.acceptedTimeoffs),
)

export const getTimersUpdating = createSelector(
  getState,
  schedules => schedules.timersUpdating,
)

export const getAcceptedTimeoffs = createSelector(
  getData,
  getAcceptedTimeoffIds,
  (data, acceptedTimeoffIds) =>
    !isEmpty(acceptedTimeoffIds)
      ? denormalize(data, 'timeoffs', acceptedTimeoffIds)
      : [],
)

export const getSchedules = createSelector(
  getData,
  getScheduleIds,
  (data, scheduleIds) =>
    !isEmpty(scheduleIds) ? denormalize(data, 'schedules', scheduleIds) : [],
)

export const getNotificationWeekSchedules = createSelector(
  getSchedules,
  getNotificationShiftCover,
  getNotificationShiftTrade,
  (schedules, notificationShiftCover, notificationShiftTrade) => {
    const shiftStartAt =
      get(notificationShiftCover, 'schedule.shiftsJob.shift.startAt', '') ||
      get(notificationShiftTrade, 'schedule.shiftsJob.shift.startAt', '')
    if (shiftStartAt) {
      const week = getWeekFromDate(shiftStartAt)
      return filterSchedulesByWeek(schedules, week)
    }
    return []
  },
)

export const getIsShowAdjust = createSelector(
  getState,
  state => state.isShowAdjust,
)

export const getIsAdjusting = createSelector(
  getState,
  state => state.isAdjusting,
)

export const getIsShowActualDelete = createSelector(
  getState,
  state => state.isShowActualDelete,
)

export const getIsActualDeleting = createSelector(
  getState,
  state => state.isActualDeleting,
)

export const getActualTimeDeleteErrorDetail = createSelector(
  getState,
  state => state.actualTimeDeleteErrorDetail,
)

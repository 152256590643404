/**
 * TODO: restructure this mess
 */

export const rootPath = () => `/`
export const accessPath = () => `${rootPath()}access`
export const authPath = () => `${rootPath()}auth`
export const notPartPath = () => `${rootPath()}notPart`
export const peoplePath = () => `${rootPath()}people`
export const peopleGridPath = () => `${rootPath()}people/grid`
export const peopleEmployeePath = employeeId =>
  `${rootPath()}people/employee/${employeeId || ':id'}`
export const peopleEmployeeGeneralPath = employeeId =>
  `${peopleEmployeePath(employeeId)}/general`
export const peopleEmployeeLocationsPath = employeeId =>
  `${peopleEmployeePath(employeeId)}/locations`
export const peopleEmployeeHealthPath = employeeId =>
  `${peopleEmployeePath(employeeId)}/health`
export const peopleEmployeeCNESST = employeeId =>
  `${peopleEmployeePath(employeeId)}/CNESST`
export const peopleEmployeeReviewEvaluationPath = employeeId =>
  `${peopleEmployeePath(employeeId)}/review-and-evaluation`
export const peopleEmployeeAdministrativeRecordPath = employeeId =>
  `${peopleEmployeePath(employeeId)}/administrative-record`
export const peopleEmployeeAvailabilityPath = employeeId =>
  `${peopleEmployeePath(employeeId)}/availability`
export const employeeAvailabilityRequestsPath = employeeId =>
  `${peopleEmployeePath(employeeId)}/availability-requests`
export const employeeTimeoffsPath = employeeId =>
  `${peopleEmployeePath(employeeId)}/timeoff`
export const peopleManagerPath = managerId =>
  `${rootPath()}people/manager/${managerId || ':id'}`
export const peopleProfilEditsLogPath = () => `${rootPath()}people/logs`
export const newsPath = () => `${rootPath()}news`
export const chatPath = () => `${rootPath()}chat`
export const userProfilePath = () => `${rootPath()}user`
export const profilePath = () => `${rootPath()}profile`
export const employeeProfilePath = () => `${rootPath()}employeeProfile` // Employee app - full profile
export const employeeProfileTab = (locationPath, tabPath) =>
  `${locationPath}${tabPath}`
// Schedule
export const schedulePath = () => `${rootPath()}schedule`
export const scheduleShiftsPath = () => `${schedulePath()}/shifts`
export const scheduleShiftsWeeklyPath = () => `${schedulePath()}/shifts-weekly`
export const scheduleEmployeeDailyPath = () =>
  `${schedulePath()}/employee-daily`
export const scheduleAutomaticAssignmentTasksPath = () =>
  `${schedulePath()}/assignment-tasks`
export const scheduleLocationsPath = () => `${rootPath()}schedule/locations`
export const tradeShiftPath = () => `${rootPath()}tradeShift`
export const requestTimeOffPath = () => `${rootPath()}requestTimeOff`
export const findCoverPath = () => `${rootPath()}findCover`
export const cantMakePath = () => `${rootPath()}cantMake`
// Timecards
export const timeCardsPath = () => `${rootPath()}timeCards`
export const timeCardsByEmployeePath = () => `${timeCardsPath()}/employee`
export const hathorShop = () => `${rootPath()}hathorShop`
export const cart = () => `${rootPath()}cart`
export const hathorOrderManagement = () => `${rootPath()}hathorOrderManagement`
export const myHathorRewards = () => `${rootPath()}myHathorRewards`
export const newHathorProduct = () => `${rootPath()}newHathorProduct`
export const timeCardsAutoSettings = () => `${rootPath()}timeCardsAutoSettings`
export const createBranchPath = () => `${rootPath()}create-branch`
export const invitationsPath = () => `${rootPath()}invitations`
export const clocksPath = () => `${rootPath()}clocks`

export const billingPath = () => `${rootPath()}billing`
export const billingPayments = () => `${billingPath()}/payments`
export const billingNewPayment = () => `${billingPath()}/payments/new`
export const billingInvoices = () => `${billingPath()}/invoices`
export const billingSubscriptions = () => `${billingPath()}/subscriptions`

export const payrollPath = () => `${rootPath()}payroll`
export const prayerBreaks = () => `${rootPath()}prayerBreaks`
export const timeAndAttendancePath = () => `${rootPath()}timeAndAttendance`
export const classifiersPath = () => `${rootPath()}classifiers`
export const groupsPath = () => `${rootPath()}groups`
export const timeBuckets = () => `${rootPath()}time-buckets`
export const adminPanelPath = () => `${rootPath()}adminPanel`
export const locationsPath = () => `${rootPath()}locations`
export const locationPath = locationId =>
  `${rootPath()}locations/${locationId || ':locationId'}`
export const locationTimeAndAttendancePath = locationId =>
  `${rootPath()}locations/${locationId || ':locationId'}/timeAndAttendance`
export const locationSettingsPath = locationId =>
  `${rootPath()}locations/${locationId || ':locationId'}/settings`
export const locationDepartmentsRolesSettingsPath = locationId =>
  `${rootPath()}locations/${locationId || ':locationId'}/departmentsAndRoles`
export const prayerBreakSettings = locationId =>
  `${rootPath()}locations/${locationId || ':locationId'}/prayerBreak`
export const massImport = () => `${rootPath()}import`
export const dailyWorkingHours = () => `${rootPath()}dailyWorkingHours`
export const availabilityPath = () => `${rootPath()}availability`
export const clientsPath = () => `${rootPath()}clients`
export const gardaReportsPath = () => `${rootPath()}garda-reports`

export const notificationsPath = () => `${rootPath()}notifications`
export const ruleBuilderPath = () => `${rootPath()}ruleBuilder`
export const reportBuilderPath = () => `${rootPath()}reportBuilder`
export const taskBuilderPath = () => `${rootPath()}taskBuilder`
export const taskBuilderAddPath = () => `${rootPath()}taskBuilder/create`
export const taskBuilderEditPath = taskId =>
  `${rootPath()}taskBuilder/${taskId || ':taskId'}`
export const reportBuilderViewReportPath = reportId =>
  `${rootPath()}reportBuilder/view/${reportId || ':reportId'}`
export const reportBuilderEditReportPath = reportId =>
  `${rootPath()}reportBuilder/${reportId || ':reportId'}`
export const reportBuilderAddReportPath = () =>
  `${rootPath()}reportBuilder/create`

/**
 * Form builder
 */
export const formBuilderPath = () => `${rootPath()}formBuilder`
export const formBuilderAddPath = () => `${rootPath()}formBuilder/create`
export const formBuilderEditPath = formId =>
  `${rootPath()}formBuilder/${formId || ':formId'}`

export const timeoffsPath = () => `${rootPath()}timeoffs`

export const uiKit = () => `${rootPath()}uiKit`
export const rewardBuilder = () => `${rootPath()}rewardBuilder`
export const customLabelDays = () => `${rootPath()}customLabelDays`
export const vaultPage = () => `${rootPath()}vault`
export const editProduct = () => `${rootPath()}editProduct`
export const importManagement = () => `${rootPath()}importManagement`

export const dayHourEquivalence = () => `${rootPath()}dayHourEquivalence`

export const companyVaultSettings = () => `${rootPath()}companyVaultSettings`
export const CREATE_ROUTE = 'create'

export const dashboard = () => `${rootPath()}dashboard`

// Time type
export const timeTypesPath = () => `${rootPath()}timeType`

// Work schedule
export const workSchedulesPath = () => `${rootPath()}work-schedule`
export const createWorkSchedulePath = () => `${workSchedulesPath()}/create`
export const editWorkSchedulePath = workScheduleId =>
  `${workSchedulesPath()}/${workScheduleId || ':workScheduleId'}`

// Work week
export const workWeekPath = () => `${rootPath()}work-week`

// Pay Frequecy
export const payFrequencyPath = () => `${rootPath()}pay-frequency`

// Payroll Account
export const payrollAccountPath = () => `${rootPath()}payroll-account`

// Shift Tags
export const shiftTagsPath = () => `${rootPath()}shift-tags`

// Global search
export const globalSearchPath = () => `${rootPath()}global-search`
// Earning type
export const earningTypePath = () => `${rootPath()}earning-type`

export const availabilityRequestsPath = () =>
  `${rootPath()}availability-requests`

// Integrations
export const integrationEventsPath = () => `${rootPath()}integrations`
export const integrationSettingsPath = () =>
  `${rootPath()}integrations-settings`

export const timeEvaluationLogsPath = () => `${rootPath()}time-evaluation-logs`

export const demandForecastingPath = () => `${rootPath()}demand-forecasting`

// Demo rule-builder
export const ruleBuilderPoCPath = () => `${rootPath()}rule-builder`
export const ruleBuilderPoCCreatePath = () => `${rootPath()}rule-builder/create`
export const ruleBuilderPoCEditPath = ruleId =>
  `${rootPath()}rule-builder/${ruleId || ':ruleId'}`

// Custom fields
export const customFieldsPath = () => `${rootPath()}custom-fields`
export const customFieldValuesPath = () => `${rootPath()}custom-field-values`
// Weekly Timesheets

export const weeklyTimeSheetsPoCPath = () =>
  `${rootPath()}weekly-timesheets-poc`
export const weeklyTimeSheetsPath = () => `${rootPath()}weekly-timesheets`
export const employmentTermsPath = () => `${rootPath()}employment-terms`

// Workflow / Rule Builder
export const workflowsPath = () => `${rootPath()}workflows`
export const createRulesetPath = workflowId =>
  `${rootPath()}workflows/${workflowId ?? ':workflowId'}/create-ruleset`
export const modifyRulesetPath = rulesetId =>
  `${rootPath()}workflows/ruleset/${rulesetId ?? ':rulesetId'}/modify`
export const viewRulesetPath = rulesetId =>
  `${rootPath()}workflows/ruleset/${rulesetId ?? ':rulesetId'}/view`

export const classifiedTimePath = () => `${rootPath()}classified-time`
export const timeEvaluationPath = () => `${rootPath()}time-evaluation`

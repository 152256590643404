import { useCallback } from 'react'

import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import {
  DiscardTimeEntriesMutation,
  TimeEntriesByCursorQuery,
  TimeEntrySummariesByCursorQuery,
} from './GraphQL'

export function useDiscardTimeEntries() {
  const [innerDiscard, { loading, error }] = useApolloMutation<
    MutationData<'discardTimeEntries'>,
    Gateway.MutationDiscardTimeEntriesArgs
  >(DiscardTimeEntriesMutation, {
    refetchQueries: compact(
      [TimeEntriesByCursorQuery, TimeEntrySummariesByCursorQuery].map(
        getOperationName,
      ),
    ),
  })

  const discardTimeEntries = useCallback(
    async (input: Gateway.TimeEntryIdsInput) => {
      const result = await innerDiscard({
        variables: {
          input,
        },
      })

      return graphQLResultHasError(result)
    },
    [innerDiscard],
  )

  return { discardTimeEntries, discarding: loading, discardError: error }
}

// Note: we're using the same entity key - i.e. staff, but making sure include is in the queryKey so the data for different applications is not mixed in cache

export const StaffQueryKey = Object.freeze({
  picker: (include?: string, filter?: Record<string, any>) => [
    'staff',
    include,
    filter,
  ],
  count: (include?: string, filter?: Record<string, any>) => [
    'staff',
    include,
    filter,
  ],
})

export const STAFF_JSON_API_TYPE = 'staff'

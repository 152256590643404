import { createSelector } from 'reselect'

import { AS_EMPLOYEE, CURRENT_BRANCH } from 'constants/cookiesKeys'

import { getActiveBranchId, getAsEmployee } from './viewer'

export const cookiesSelector = createSelector(
  getActiveBranchId,
  getAsEmployee,
  (branchId, asEmployee) => {
    return {
      [CURRENT_BRANCH]: branchId,
      [AS_EMPLOYEE]: asEmployee,
    }
  },
)

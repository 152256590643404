import React from 'react'

import { SpaceProps } from 'styled-system'

import { Flex, Span } from 'components/ui/__v2__/Grid'
import { Sortable } from 'components/ui/__v3__/DataTable/Headers/Sortable'

import { SortingDirection } from 'constants/gatewayGraphQL'

type Props = SpaceProps & {
  title: string
  direction: SortingDirection
  onToggle: (direction: SortingDirection) => void
}

export function Sort({ title, direction, onToggle, ...rest }: Props) {
  return (
    <Flex {...rest} px={3}>
      <Sortable direction={direction} onToggle={onToggle}>
        <Span fontSize="12px" lineHeight="18px">
          {title}
        </Span>
      </Sortable>
    </Flex>
  )
}

import { FiCopy } from 'react-icons/fi'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { either } from 'components/ui/__v3__/__utils__/styled-system'

const CopyIcon = styled(FiCopy).attrs({ size: 18 })`
  color: ${either(
    'disabled',
    themeGet('layout.disabled'),
    themeGet('layout.lightText'),
  )};
`

export default CopyIcon

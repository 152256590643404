import { StoreonModule } from 'storeon'

import { QUERY_PARAMS_SET, QUERY_PARAMS_STATE_KEY } from './constants'
import { QueryParamsEvents, QueryParamsState } from './types'

const INITIAL_STATE = {}

export const queryParamsModule: StoreonModule<
  QueryParamsState,
  QueryParamsEvents
> = store => {
  store.on('@init', () => ({
    [QUERY_PARAMS_STATE_KEY]: INITIAL_STATE,
  }))

  store.on(QUERY_PARAMS_SET, (state, variables) => ({
    [QUERY_PARAMS_STATE_KEY]: {
      ...state[QUERY_PARAMS_STATE_KEY],
      ...variables,
    },
  }))
}

const getItem = (key: string) => {
  return JSON.parse(localStorage.getItem(key)!)
}

const setItem = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value))
}

const removeItem = (key: string) => {
  localStorage.removeItem(key)
}

const clear = () => {
  localStorage.clear()
}

export const LocalStorage = {
  getItem,
  setItem,
  removeItem,
  clear,
}
export default LocalStorage

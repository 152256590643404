import { useApolloMutation } from 'API/services/Apollo'

import { PublishRulesetMutation } from './GraphQL'

export function usePublishRuleset() {
  const [innerPublishRuleset, { loading }] = useApolloMutation<
    MutationData<'publishRuleset'>,
    Gateway.MutationPublishRulesetArgs
  >(PublishRulesetMutation, {
    translationKey: 'ruleset.toasts.publish',
  })

  const publishRuleset = async ({ id }: Gateway.PublishRulesetInput) => {
    return innerPublishRuleset({
      variables: {
        id,
      },
    })
  }

  return { publishRuleset, updating: loading }
}

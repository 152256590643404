import { FiCheck, FiX } from 'react-icons/fi'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Checked = styled(FiCheck).attrs({ size: 16 })`
  color: ${themeGet('toasts.secondaryColor.success')};
`
export const NotChecked = styled(FiX).attrs({ size: 16 })`
  color: ${themeGet('buttons.icon.color.secondary')};
`

import { PAGE_SIZE } from 'constants/pagination'

import { currentWeek, formatISOToYearMonthDayHoursMillis } from 'helpers/date'
import { getCurrentEmployee } from 'helpers/employee'
import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getDepartmentOption } from 'store/selectors/employeeApp/schedules'
import { getBranchId, getViewer } from 'store/selectors/viewer'

export const LOAD_EMPLOYEE_SCHEDULE = createAsyncAction(
  'schedules/LOAD_EMPLOYEE_SCHEDULE',
)
export const LOAD_DEPARTMENT_SCHEDULES = createAsyncAction(
  'schedule/LOAD_DEPARTMENT_SCHEDULES',
)

// -- DEPRECATED

/** @deprecated */
export const INIT = 'schedules/INIT'
/** @deprecated */
export const INIT_DONE = 'schedules/INIT_DONE'
/** @deprecated */
export const CHANGE_WEEK = createAsyncAction('schedules/CHANGE_WEEK')
/** @deprecated */
export const SELECT_SCHEDULE = 'schedules/SELECT_SCHEDULE'
/** @deprecated */
export const RESET_SUCCESSFUL = 'schedules/RESET_SUCCESSFUL'
/** @deprecated */
export const CHANGE_DEPARTMENT_OPTION = 'schedule/CHANGE_DEPARTMENT_OPTION'
/** @deprecated */
export const CHANGE_SORT_OPTION = 'schedule/CHANGE_SORT_OPTION'
/** @deprecated */
export const TOGGLE_TAB = 'schedule/TOGGLE_TAB'

/** @deprecated */
export const initDone = () => ({ type: INIT_DONE })

/** @deprecated */
export const loadEmployeeSchedules = (from, to) => (dispatch, getState) => {
  const state = getState()
  const viewer = getViewer(state)
  const branchId = getBranchId(state)
  const employee = getCurrentEmployee(viewer.employees, branchId)

  const employeeId = employee.id
  return dispatch(
    apiCall({
      endpoint: `/employees/${employeeId}/relationships/schedules`,
      query: {
        include: 'shifts_job.shift,shifts_job.job',
        filter: {
          shifts_start_at:
            from || formatISOToYearMonthDayHoursMillis(currentWeek.start),
          shifts_finish_at:
            to || formatISOToYearMonthDayHoursMillis(currentWeek.end),
        },
        page: { size: PAGE_SIZE['50'] },
      },
      types: LOAD_EMPLOYEE_SCHEDULE,
      paged: true,
    }),
  )
}

/** @deprecated */
export const changeWeek = week => ({
  type: CHANGE_WEEK,
  payload: { week },
})

/** @deprecated */
export const changeWeekAndLoad = newWeek => dispatch => {
  const { start: weekStart, end: weekEnd } = newWeek
  const start = formatISOToYearMonthDayHoursMillis(weekStart)
  const finish = formatISOToYearMonthDayHoursMillis(weekEnd)
  dispatch(loadEmployeeSchedules(start, finish))
  dispatch(loadDepartmentSchedules(weekStart, weekEnd))
  dispatch(changeWeek(newWeek))
}

const departmentSchedulesInclude = [
  'shifts_job.shift',
  'shifts_job.job',
  'shifts_job.job.department',
  'employee.user.profile.profileAvatar',
]

/** @deprecated */
export const loadDepartmentSchedules = (from, to) => (dispatch, getState) => {
  const branchId = getBranchId(getState())
  const departmentId = getDepartmentOption(getState())

  const departmentFilter =
    departmentId && departmentId !== 'all'
      ? { department_id: departmentId }
      : {}

  const startAt = from || currentWeek.start
  const finishAt = to || currentWeek.end

  return dispatch(
    apiCall({
      endpoint: `/branches/${branchId}/relationships/schedules`,
      types: LOAD_DEPARTMENT_SCHEDULES,
      query: {
        include: departmentSchedulesInclude.join(),
        filter: {
          shifts_start_at: formatISOToYearMonthDayHoursMillis(startAt),
          shifts_finish_at: formatISOToYearMonthDayHoursMillis(finishAt),
          ...departmentFilter,
          page: { size: PAGE_SIZE['50'] },
        },
      },
      paged: true,
    }),
  )
}

// --

import { createDeleteHandler, createLoadHandler } from 'redux-json-api-handlers'
import Immutable from 'seamless-immutable'

import get from 'lodash/get'
import map from 'lodash/map'
import mergeWith from 'lodash/mergeWith'
import reject from 'lodash/reject'
import without from 'lodash/without'

import { createReducer, getFirstEntity, mergeCustomizer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  ADD_POST,
  DELETE_POST,
  LOAD_MORE_POSTS,
  LOAD_POSTS,
  RECEIVE_DELETED_POST,
  RECEIVE_POST,
  REMOVE_LOCAL_FILE,
  UPLOAD_FILE,
} from 'store/actions/news'

const initialState = Immutable({
  posts: [],
  files: {},
  isReady: false,
  isLoading: false,
  isLoaded: false,
})

const handlers = {
  [LOAD_POSTS.REQUEST]: state => {
    return state.merge({
      isLoading: true,
      isLoaded: false,
    })
  },

  [LOAD_POSTS.SUCCESS]: createLoadHandler('posts', {
    withReplace: true,
  }),

  [LOAD_MORE_POSTS.REQUEST]: state => {
    return state.merge({
      isLoading: true,
    })
  },

  [LOAD_MORE_POSTS.SUCCESS]: (state, { payload }) => {
    const branches = get(payload, 'data.branches', null)
    if (!branches) {
      return state.merge({
        isLoading: false,
        isLoaded: true,
      })
    }

    const branchId = Object.keys(branches)[0]
    const currentPosts = map(state.posts)
    const posts = get(
      payload,
      `data.meta./branches/${branchId}/relationships/posts.data`,
      {},
    )
    const newPosts = map(posts, 'id')
    const postsIds = currentPosts.concat(newPosts)

    return state.merge({
      posts: postsIds,
      isLoading: false,
      isLoaded: true,
    })
  },

  [LOAD_MORE_POSTS.FAILURE]: state => {
    return state.merge({
      isLoading: false,
    })
  },

  [ADD_POST.SUCCESS]: (state, { payload }) => {
    const currentPosts = map(state.posts)
    const newPosts = Object.keys(payload?.data?.posts)[0]
    const postsIds = [newPosts].concat(currentPosts)

    return state.merge({
      posts: postsIds,
      isLoading: false,
      isLoaded: true,
    })
  },

  [RECEIVE_POST]: createLoadHandler('posts'),

  [UPLOAD_FILE.REQUEST]: (state, { payload }) => {
    return state.merge({
      files: mergeWith({}, state.files, payload.files, mergeCustomizer()),
    })
  },

  [UPLOAD_FILE.FAILURE]: (state, { payload }) => {
    const file = getFirstEntity(get(payload, 'files', {}))
    return state.merge({
      file: reject(state.files, item => item.id === file.id),
    })
  },

  [REMOVE_LOCAL_FILE]: (state, { payload }) => {
    const fileId = get(payload, 'fileId')
    return state.merge({
      files: reject(state.files, item => item.id === fileId),
    })
  },

  [DELETE_POST.SUCCESS]: createDeleteHandler('posts'),

  [RECEIVE_DELETED_POST]: (state, { payload }) => {
    const post = getFirstEntity(get(payload, 'data.posts', {}))
    return state.merge({
      posts: without(state.posts, post.id),
    })
  },

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import styled, { css } from 'styled-components'

export const FilterModesWrapper = styled.div`
  display: flex;
  align-items: center;
  text-transform: lowercase;
`
const activeCss = ({ active }: { active: boolean }) =>
  active &&
  css`
    opacity: 1;
    border-bottom: 3px solid ${({ theme }) => theme.layout.primary};
  `
export const FilterModeOption = styled.span<{ active: boolean }>`
  font-size: 12px;
  text-align: center;
  width: 29px;
  border-bottom: 3px solid ${({ theme }) => theme.switch.border.default};
  opacity: 0.5;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  cursor: pointer;

  ${activeCss};
`

import { useQuery } from '@tanstack/react-query'
import { STALE_TIME } from 'API/services/JsonApi'
import { transformResponseToEntities } from 'API/services/utils'
import { Branch } from 'Types/app'

import Utils from 'services/Utils'

import { EmployeeShiftJobsAPIItem, EmployeeShiftJobsReturn } from './types'

import {
  employeeQuery,
  EmployeeQueryKey,
  EMPLOYEES_JSON_API_TYPE,
} from '../Json'

const EMPLOYEE_WITH_SHIFT_JOBS_INCLUDE = [
  'jobsEmployees.companySetting',
  'jobsEmployees.department',
  'jobsEmployees.job',
  'user.profile',
  'branch',
].join(',')

export function useEmployeeWithShiftJobs(id: number | undefined) {
  const { data, isFetching } = useQuery({
    queryKey: EmployeeQueryKey.byId(id!, EMPLOYEE_WITH_SHIFT_JOBS_INCLUDE),
    queryFn: () =>
      employeeQuery({
        employeeId: id!,
        include: EMPLOYEE_WITH_SHIFT_JOBS_INCLUDE,
      }),
    select: (response: JsonApi.Response) => {
      const {
        entities,
      } = transformResponseToEntities<EmployeeShiftJobsAPIItem>({
        response,
        jsonApiType: EMPLOYEES_JSON_API_TYPE,
      })

      return toReturn(entities[0])
    },
    staleTime: STALE_TIME.SLOW,
    enabled: Boolean(id),
    structuralSharing: false,
    refetchOnWindowFocus: false,
  })

  return {
    employeeWithShiftJobs: data,
    loadingEmployeeWithShiftJobs: isFetching,
  }
}

// Note: Workaround this is to fitler out the deleted branches, that are no longer there, but still included parially
function includedBranchHealthy(branch: Branch) {
  return !!branch.settings
}

function toReturn(apiItem: EmployeeShiftJobsAPIItem): EmployeeShiftJobsReturn {
  return {
    id: Number(apiItem.id),
    name: Utils.User.buildFullName(apiItem.user.profile),
    jobsEmployees: apiItem.jobsEmployees.filter(jobEmployee =>
      includedBranchHealthy(jobEmployee.branch),
    ),
    primaryBranchTimezone: apiItem.branch!.settings.timezone,
  }
}

import { getOperationName } from '@apollo/client/utilities'
import { UnfilledPositionsQuery } from 'API/Metrics/GraphQL'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import { ArchiveShiftsMutation } from './GraphQL'

export function useArchiveShifts() {
  const [archiveShifts, { loading: archiving }] = useApolloMutation<
    MutationData<'archiveShifts'>,
    Gateway.MutationArchiveShiftsArgs
  >(ArchiveShiftsMutation, { translationKey: 'shifts.toasts.archive' })

  const archive = async (
    params: Gateway.MutationArchiveShiftsArgs['input'],
  ) => {
    const { ids, reason } = params

    return archiveShifts({
      variables: {
        input: {
          ids,
          reason,
        },
      },
      refetchQueries: compact([getOperationName(UnfilledPositionsQuery)]),
    })
  }

  return { archive, archiving }
}

import { i18n } from 'i18n'

import { yup } from 'services/FormValidation'

export const validator = yup.object().shape({
  employee: yup.object().required(),

  location: yup.object().required(),

  date: yup.string().when('partial', {
    is: true,
    then: yup.string().required(),
  }),

  leaveType: yup
    .object()
    .nullable()
    .shape({
      value: yup.string().required(),
    })
    .required(),

  startTime: yup.number().when('partial', {
    is: true,
    then: yup
      .number()
      .required()
      .test(
        'fromEqualsTo',
        i18n('timeOffModal.validation.toEqualsFrom'),
        (value, ctx) => value !== ctx.parent.endTime,
      ),
  }),

  endTime: yup.number().when('partial', {
    is: true,
    then: yup
      .number()
      .required()
      .test(
        'toEqualsFrom',
        i18n('timeOffModal.validation.fromEqualsTo'),
        (value, ctx) => value !== ctx.parent.startTime,
      ),
  }),

  period: yup.object().when('partial', {
    is: false,
    then: yup.object().shape({
      from: yup.string().required(),
    }),
  }),
})

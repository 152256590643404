import { createRelationAddHandler } from 'redux-json-api-handlers'

import difference from 'lodash/difference'
import filter from 'lodash/filter'
import get from 'lodash/get'
import indexOf from 'lodash/indexOf'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import map from 'lodash/map'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import reduce from 'lodash/reduce'

import { createReducer } from 'helpers/redux'

import { DELETE } from 'store/actions/company/shifts'
import { STOP_TIMER } from 'store/actions/employeeApp/timeClock'
import { PUBLISH } from 'store/actions/employees/schedules'
import {
  CREATE_PAUSES,
  CREATE_SHIFTS_JOBS,
  DELETE_SHIFTS,
  UPDATE_SHIFTS_JOBS,
} from 'store/actions/employees/shifts'
import { LOAD_NOTIFICATION_ABSENCE } from 'store/actions/notifications'

const initialState = {}

const handlers = {
  [CREATE_SHIFTS_JOBS.SUCCESS]: createRelationAddHandler(
    'shiftsJobs',
    'schedules',
  ),
  [CREATE_PAUSES.SUCCESS]: createRelationAddHandler('pauses', 'schedules'),
  [STOP_TIMER.SUCCESS]: (state, { payload }) => {
    const scheduleId = get(payload, 'relationId', '')
    const timeEntry = Object.values(get(payload, 'data.timeEntries', {}))[0]
    if (!isEmpty(timeEntry)) {
      return merge({}, state, {
        [scheduleId]: {
          relationships: {
            timeEntry: {
              data: { id: timeEntry.id, type: 'timeEntries' },
            },
          },
        },
      })
    }
    return state
  },
  [LOAD_NOTIFICATION_ABSENCE.SUCCESS]: (state, { payload }) => {
    const absences = get(payload, 'data.absences', {})
    const schedules = get(payload, 'data.schedules', {})
    const absenceId = isEmpty(absences) ? '' : Object.keys(absences)[0]
    const scheduleId = isEmpty(schedules) ? '' : Object.keys(schedules)[0]
    if (absenceId && scheduleId) {
      return merge({}, state, {
        [scheduleId]: {
          relationships: {
            absences: {
              data: [
                ...get(state, `${scheduleId}.relationships.absences.data`, []),
                { id: absenceId, type: 'absences' },
              ],
            },
          },
        },
      })
    }
    return state
  },
  [UPDATE_SHIFTS_JOBS.SUCCESS]: (state, { payload }) => {
    // Remove old unpublished schedules
    const {
      data: { shiftsJobs, schedules },
    } = payload

    const shiftsJobsIds = isEmpty(shiftsJobs) ? null : Object.keys(shiftsJobs)
    const shiftsJobSchedules = isNull(shiftsJobsIds)
      ? []
      : filter(
          state,
          schedule =>
            indexOf(
              shiftsJobsIds,
              get(schedule, 'relationships.shiftsJob.data.id', null),
            ) !== -1,
        )

    const newUnpublishedScheduleIds = map(
      filter(schedules, ['attributes.state', 'unpublished']),
      'id',
    )
    const allUnpublishedScheduleIds = map(
      filter(shiftsJobSchedules, ['attributes.state', 'unpublished']),
      'id',
    )
    const oldUnpublishedScheduleIds = difference(
      allUnpublishedScheduleIds,
      newUnpublishedScheduleIds,
    )

    return isEmpty(oldUnpublishedScheduleIds)
      ? state
      : omit(state, oldUnpublishedScheduleIds)
  },

  [DELETE_SHIFTS.SUCCESS]: (state, { payload }) => {
    const shifts = get(payload, 'data.shifts', {})
    const shiftsShiftsJobsIds = get(payload, 'shiftsShiftsJobsIds')
    let deletedShiftsJobsIds = []
    if (!isEmpty(shifts)) {
      const shiftsJobsIds = pick(shiftsShiftsJobsIds, Object.keys(shifts))
      deletedShiftsJobsIds = reduce(
        shiftsJobsIds,
        (oneArr, ids) => [...oneArr, ...ids],
        [],
      )
    }
    const deletedSchedulesIds = reduce(
      state,
      (ids, schedule) => {
        const scheduleShiftJobId = get(
          schedule,
          'relationships.shiftsJob.data.id',
          null,
        )
        const isDeletedShiftJobId =
          indexOf(deletedShiftsJobsIds, scheduleShiftJobId) !== -1
        if (isDeletedShiftJobId) {
          ids.push(schedule.id)
        }
        return ids
      },
      [],
    )
    return isEmpty(deletedSchedulesIds)
      ? state
      : omit(state, deletedSchedulesIds)
  },
  [PUBLISH.SUCCESS]: (state, { payload }) => {
    const {
      unpublishedIds,
      nonExistentIds,
      data: { schedules },
    } = payload
    const schedulesIds = isEmpty(schedules) ? [] : Object.keys(schedules)
    const initDeleteIds = [...unpublishedIds, ...nonExistentIds]
    const deleteIds = filter(
      initDeleteIds,
      id => schedulesIds.indexOf(id) === -1,
    )
    return omit(state, deleteIds)
  },
  [DELETE.SUCCESS]: (state, { payload }) => {
    return omit(state, payload.scheduleIds)
  },
}

export default createReducer(initialState, handlers)

import { useArchiveTimeBucket } from './useArchiveTimeBucket'
import { useCreateTimeBucketChild } from './useCreateTimeBucketChild'
import { useCreateTimeBucketParent } from './useCreateTimeBucketParent'
import { useDeleteTimeBucket } from './useDeleteTimeBucket'
import { useTimeBucketChildrenByCursor } from './useTimeBucketChildrenByCursor'
import { useTimeBucketChildrenPicker } from './useTimeBucketChildrenPicker'
import { useTimeBucketParentsByCursor } from './useTimeBucketParentsByCursor'
import { useTimeBucketParentsPicker } from './useTimeBucketParentsPicker'
import { useUnarchiveTimeBucket } from './useUnarchiveTimeBucket'
import { useUpdateTimeBucketChild } from './useUpdateTimeBucketChild'
import { useUpdateTimeBucketParent } from './useUpdateTimeBucketParent'

export * from './GraphQL/Fragments/types'
export * from './types'

export const TimeBucketAPI = {
  createChild: useCreateTimeBucketChild,
  createParent: useCreateTimeBucketParent,

  parentsPicker: useTimeBucketParentsPicker,
  childrenPicker: useTimeBucketChildrenPicker,

  childByCursor: useTimeBucketChildrenByCursor,
  parentByCursor: useTimeBucketParentsByCursor,

  updateChild: useUpdateTimeBucketChild,
  updateParent: useUpdateTimeBucketParent,

  archive: useArchiveTimeBucket,
  unarchive: useUnarchiveTimeBucket,

  delete: useDeleteTimeBucket,
}

import { toastr } from 'react-redux-toastr'

import { getResponseMeta } from 'API/services/utils'
import { all, fork, race, select, take } from 'redux-saga/effects'

import { ACCESS_LEVEL } from 'constants/ids'

import _ from 'i18n'

import { MOVE_TIMEOFFS } from 'store/actions/employees/moveTimeOff'
import { getViewer } from 'store/selectors/viewer'

import { CREATE_TIME_OFF, LOAD_TIME_OFFS } from '../actions/common/timeoff'

const DUPLICATE_REQUESTED_DAY_ERROR_MESSAGE =
  'Your selection contains at least 1 day which has already been requested; Please revise your selection.'

const DUPLICATE_APPROVED_DAY_ERROR_MESSAGE =
  'Your selection contains at least 1 day which has already been approved; Please revise your selection.'

const EMPLOYEE_HAS_NO_ROLE_SELECT_PERIOD =
  'The employee has no role during the given period:'

const DAILY_WORKING_HOURS_EXCEEDED = 'Timeoffs cannot exceed'

const MOVE_TIMEOFF_PENDING = 'There is a pending moving request'

const MOVE_TIMEOFF_TO_PAST =
  'The request conflicts with an already existing public holiday; please revise your selection.'
const BALANCE_EXCEEDED_FOR_CHOSEN_LEAVE = 'Balance exceeded for chosen leave.'

function* watchCreateTimeoff() {
  while (true) {
    const { success, error } = yield race({
      success: take(CREATE_TIME_OFF.SUCCESS),
      error: take(CREATE_TIME_OFF.FAILURE),
    })

    if (success) {
      const {
        payload: { isTimeoffRequest },
      } = success
      const meta = getResponseMeta(success)

      if (meta?.warning) {
        toastr.warning(meta.warning, { disableCloseButtonFocus: true })
      }
      if (isTimeoffRequest) {
        toastr.success(_('requestTimeOff.sendFeedback.successSent'), {
          disableCloseButtonFocus: true,
        })
      } else {
        toastr.success(_('requestTimeOff.sendFeedback.successAssigned'), {
          disableCloseButtonFocus: true,
        })
      }
    } else if (error) {
      const viewer = yield select(getViewer)
      const {
        payload: { isTimeoffRequest },
      } = error
      const isEmployee = viewer.currentAccessLevel === ACCESS_LEVEL.employee
      let message = _('common.somethingWentWrong')

      if (
        error.error?.find(
          e => e.detail === DUPLICATE_REQUESTED_DAY_ERROR_MESSAGE,
        )
      ) {
        message = _('requestTimeOff.sendFeedback.requestedAlready')
      } else if (
        error.error?.find(
          e => e.detail === DUPLICATE_APPROVED_DAY_ERROR_MESSAGE,
        )
      ) {
        message = _('requestTimeOff.sendFeedback.approvedAlready')
      } else if (
        error.error[0]?.detail.includes(EMPLOYEE_HAS_NO_ROLE_SELECT_PERIOD)
      ) {
        message = isEmployee
          ? _('requestTimeOff.sendFeedback.employeeHasNoRole.employees')
          : _('requestTimeOff.sendFeedback.employeeHasNoRole.managers')
      } else if (
        error.error[0]?.detail.includes(BALANCE_EXCEEDED_FOR_CHOSEN_LEAVE)
      ) {
        message = isTimeoffRequest
          ? _('requestTimeOff.sendFeedback.balanceExceededEmployee')
          : _('requestTimeOff.sendFeedback.balanceExceededManager')
      } else if (
        error.error[0]?.detail.startsWith(DAILY_WORKING_HOURS_EXCEEDED)
      ) {
        message = _('requestTimeOff.sendFeedback.exceedsDailyBudget')
      }

      toastr.error(message, {
        disableCloseButtonFocus: true,
      })
    }
  }
}

function* watchMoveTimeoff() {
  while (true) {
    const { success, error } = yield race({
      success: take(MOVE_TIMEOFFS.SUCCESS),
      error: take(MOVE_TIMEOFFS.FAILURE),
    })
    if (success) {
      toastr.success(_('moveTimeoffs.timeoffSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else if (error) {
      let message = _('common.somethingWentWrong')

      if (error.error?.find(e => e.detail === MOVE_TIMEOFF_PENDING)) {
        message = _('moveTimeoffs.timeoffPending')
      } else if (error.error?.find(e => e.detail === MOVE_TIMEOFF_TO_PAST)) {
        message = _('moveTimeoffs.timeoffMovePast')
      }

      toastr.error(message, {
        disableCloseButtonFocus: true,
      })
    }
  }
}

function* watchLoadErrors() {
  while (true) {
    const error = yield take(LOAD_TIME_OFFS.FAILURE)

    if (error)
      toastr.error(_('timeoffTable.toasts.loadError'), {
        disableCloseButtonFocus: true,
      })
  }
}

export default function* root() {
  yield all([
    fork(watchCreateTimeoff),
    fork(watchLoadErrors),
    fork(watchMoveTimeoff),
  ])
}

import { graphQLResultHasError } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { MoveSchedulesFromShiftMutation } from './GraphQL'

export function useMoveSchedulesFromShift() {
  const [innerMove] = useApolloMutation<
    MutationData<'moveSchedulesFromShift'>,
    Gateway.MutationMoveSchedulesFromShiftArgs
  >(MoveSchedulesFromShiftMutation, {
    translationKey: 'shiftMoveModal.toasts.move',
  })

  const move = async (input: Gateway.MoveSchedulesFromShiftInput) => {
    const result = await innerMove({
      variables: {
        input,
      },
    })

    const hasError = graphQLResultHasError(result)
    if (hasError) {
      return null
    }

    return result.data?.moveSchedulesFromShift.backgroundTaskId
  }

  return { move }
}

import React from 'react'

import { Flex, Span } from 'components/ui/__v2__/Grid'

import { LONG_DASH } from 'constants/schedule'

import { FoldableLogItems } from './components'

type Props = {
  message: string | null
  foldable?: boolean
}

export function ProfileLogMessage({ message, foldable }: Props) {
  if (!message) return <Span>{LONG_DASH}</Span>

  let copiedMessage = message
  const duplicatedSymbols = message.indexOf('\\n')

  if (duplicatedSymbols >= 0) {
    copiedMessage = replaceSymbolAt(duplicatedSymbols, '\n', message)
  }

  const elements = copiedMessage.split('\n')

  if (elements.length === 0) return <Span>{message}</Span>

  if (foldable) {
    return <FoldableLogItems elements={elements} />
  }

  return (
    <Flex flexDirection="column">
      {elements.map(element => (
        <Span key="element">{element}</Span>
      ))}
    </Flex>
  )
}

function replaceSymbolAt(index: number, replacement: string, string: string) {
  return (
    string.substring(0, index) +
    replacement +
    string.substring(index + replacement.length + 1)
  )
}

import { PAGE_SIZE } from 'constants/pagination'

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

export const LOAD_LEAVES_SUMMARY = createAsyncAction('leaves_summary/LOAD')
export const UPDATE_LEAVE_BALANCE = createAsyncAction('leave_balance/UPDATE')
export const CLEAR_LEAVES_SUMMARY = 'leaves_summary/СLEAR'

export const loadLeavesSummary = ({ employeeId, year }) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/employees/${employeeId}/leaves_summary`,
      types: LOAD_LEAVES_SUMMARY,
      method: 'GET',
      query: {
        include: 'leave.employeeLeaveBalances',
        display: 'all',
        filter: { year: { eq: year } },
        page: { size: PAGE_SIZE['100'] },
      },
      paged: true,
    }),
  )
}

export const adjustLeavesBalance = ({
  employeeId,
  attributes,
  leaveId,
}) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/employees/${employeeId}/leave_balance_adjustments`,
      types: UPDATE_LEAVE_BALANCE,
      method: 'POST',
      query: {
        include: 'leave.employeeLeaveBalances',
        data: {
          attributes,
          relationships: {
            leave: {
              data: {
                id: leaveId,
                type: 'customLeaveDays',
              },
            },
          },
        },
      },
      payload: { employeeId, year: attributes.year },
    }),
  )
}

export const clearLeavesSummary = () => ({
  type: CLEAR_LEAVES_SUMMARY,
})

import { useCallback, useEffect, useState } from 'react'

import { DEFAULT_JSON_API_OFFSET_PAGING } from 'API/services/JsonApi'
import {
  getOffsetPageMeta,
  transformResponseToEntities,
} from 'API/services/utils'

import { useAppContext, useLocale } from 'hooks'

import { i18n } from 'i18n'

import { ProfileLogsPageQueryParams } from 'pages/EmployerApp/People/pages/ProfileEditLogsPage/hooks'

import { showError } from 'services/API'
import { showToast } from 'services/Toasts'

import { PROFILE_LOGS_JSON_API_TYPE } from './constants'
import { profileLogsQuery } from './Json'
import { ProfileLogAPIItem } from './types'

type Props = {
  sort: ProfileLogsPageQueryParams['sort']
  filters: ProfileLogsPageQueryParams['filters']
  page?: JsonApi.OffsetPagingInput
}

export function useProfileLogsByOffset({ filters, sort, page }: Props) {
  const { companyId } = useAppContext()

  const [fetching, setFetching] = useState(false)
  const [fetchingMore, setFetchingMore] = useState(false)
  const [total, setTotal] = useState(0)
  const [nextPage, setNextPage] = useState<number | null>(null)

  const locale = useLocale()

  const [profileLogItems, setProfileLogItems] = useState<ProfileLogAPIItem[]>(
    [],
  )

  const baseLoadQuery = useCallback(
    async page =>
      profileLogsQuery({
        companyId,
        locale,
        filters,
        sort,
        page,
      }),
    [companyId, filters, locale, sort],
  )

  const loadProfileLogItems = useCallback(async () => {
    try {
      setFetching(true)
      const response: JsonApi.Response = await baseLoadQuery(
        page ?? DEFAULT_JSON_API_OFFSET_PAGING,
      )

      if (!response?.ok) {
        showToast({
          type: 'error',
          content: i18n('profileEditLogsGrid.title'),
          title: i18n('common.somethingWentWrong'),
        })
      } else {
        const { entities } = transformResponseToEntities<ProfileLogAPIItem>({
          response,
          jsonApiType: PROFILE_LOGS_JSON_API_TYPE,
        })

        const { recordCount, nextPage } = getOffsetPageMeta(response)

        setTotal(recordCount)
        setNextPage(nextPage)
        setProfileLogItems(entities)
      }
    } catch (error) {
      showError(error)
    } finally {
      setFetching(false)
    }
  }, [baseLoadQuery, page])

  const loadMoreProfileLogItems = useCallback(async () => {
    try {
      setFetchingMore(true)
      const response: JsonApi.Response = await baseLoadQuery({
        size: page?.size || DEFAULT_JSON_API_OFFSET_PAGING.size,
        number: nextPage,
      })

      if (!response?.ok) {
        showToast({
          type: 'error',
          content: i18n('profileEditLogsGrid.title'),
          title: i18n('common.somethingWentWrong'),
        })
      } else {
        const { entities } = transformResponseToEntities<ProfileLogAPIItem>({
          response,
          jsonApiType: PROFILE_LOGS_JSON_API_TYPE,
        })

        const { recordCount, nextPage } = getOffsetPageMeta(response)

        setTotal(recordCount)
        setNextPage(nextPage)
        setProfileLogItems(ps => [...ps, ...entities])
      }
    } catch (error) {
      showError(error)
    } finally {
      setFetchingMore(false)
    }
  }, [baseLoadQuery, nextPage, page?.size])

  useEffect(() => {
    loadProfileLogItems()
  }, [loadProfileLogItems])

  return {
    profileLogItems,
    loadMoreProfileLogItems,
    fetching,
    fetchingMore,
    total,
    nextPage,
    refetch: loadProfileLogItems,
  }
}

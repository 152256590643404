import { createFields, createReducerHandlers } from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { FORM_TEMPLATES } from 'store/actions/company/formTemplates'

export const FORM_TEMPLATE_TYPE = 'form'
export const FORM_TEMPLATE_FIELD = 'forms'

const initialState = Immutable({
  ...createFields(FORM_TEMPLATE_TYPE, FORM_TEMPLATE_FIELD),
})

const handlers = {
  ...createReducerHandlers(FORM_TEMPLATE_TYPE, FORM_TEMPLATES.LOAD, {
    withReplace: true,
    mapToKey: FORM_TEMPLATE_FIELD,
  }),
  ...createReducerHandlers(FORM_TEMPLATE_TYPE, FORM_TEMPLATES.UPDATE),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import colors, { GRAY_700 } from './colors'

/**
 * @deprecated
 * @see {@link src\theme\__v2__\light\index.js}
 */
export const main = {
  breakpoints: [32, 48, 64, 80],
  space: [0, 4, 8, 16, 32, 64, 128],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96],
  weights: [400, 700],
  name: 'Light theme',
  colors: { ...colors },
  font: 'Lato, sans-serif',
  radius: 0,
  buttonShadow: `box-shadow: 0 2px 5px 0 rgba(0,0,0,0.11)`,
  buttonShadowPressed: `box-shadow: 0 1.5px 5px 0 ${GRAY_700}`,
  transitionTime: '.17s',
  tabsWidth: '72px',
  tabsHeight: '80px',
  firstTabHeight: '81px',
}

export default {
  main,
}

import React from 'react'
import { useForm, useFormState } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'

import { SpacedColumn, Span } from 'components/ui/__v2__/Grid'
import { Modal } from 'components/ui/__v2__/Modal'
import { Button } from 'components/ui/__v3__/Button'
import { FormText } from 'components/ui/__v3__/HookForm'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import Yup from 'services/FormValidation'

type Props = {
  mode: 'reject' | 'withdraw'
  onSubmitIntent: (note: string) => void
  onClose: () => void
}

type FormState = {
  note: string
}

export function WithdrawRejectRequestModal({
  mode,
  onSubmitIntent,
  onClose,
}: Props) {
  const t = useI18n<typeof TRANSLATIONS.availabilityRequests.modals>(
    'availabilityRequests.modals',
  )

  const { control, handleSubmit } = useForm<FormState>({
    defaultValues: { note: '' },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const { isValid } = useFormState({ control })

  const title = mode === 'reject' ? t('reject.title') : t('withdraw.title')
  // prettier-ignore
  const content = mode === 'reject' ? t('reject.content') : t('withdraw.content')
  const action = mode === 'reject' ? t('reject.action') : t('withdraw.action')

  const onValidNote = ({ note }: FormState) => {
    onSubmitIntent(note.trim())
  }

  return (
    <Modal isOpen>
      {/* @ts-ignore TODO remove tsnocheck after typing <Modal> */}
      <Modal.Close onClose={onClose} />
      {/* @ts-ignore TODO remove tsnocheck after typing <Modal> */}
      <Modal.Title>{title}</Modal.Title>

      <SpacedColumn my={5} space={2}>
        <Span>{content}</Span>
        <form>
          <FormText
            as="textarea"
            control={control}
            disableResize
            height="60px"
            labelText={t('notes')}
            name="note"
          />
        </form>
      </SpacedColumn>

      {/* @ts-ignore TODO remove tsnocheck after typing <Modal> */}
      <Modal.Actions justifyContent="center">
        <Button mr={17} secondary width={180} onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          disabled={!isValid}
          width={180}
          onClick={handleSubmit(onValidNote)}
        >
          {action}
        </Button>
        {/* @ts-ignore TODO remove tsnocheck after typing <Modal> */}
      </Modal.Actions>
    </Modal>
  )
}

const MAX_NOTE_CHAR_COUNT = 256
const schema = Yup.object({
  note: Yup.string().max(MAX_NOTE_CHAR_COUNT),
})

import { useApolloMutation } from 'API/services/Apollo'

import { CreateWorkflowMutation } from './GraphQL'

export function useCreateWorkflow() {
  const [innerCreate, { loading: creating }] = useApolloMutation<
    MutationData<'createWorkflow'>,
    Gateway.MutationCreateWorkflowArgs
  >(CreateWorkflowMutation, {
    translationKey: 'workflow.toasts.create',
    silent: true,
  })

  const createWorkflow = async (input: Gateway.CreateWorkflowInput) =>
    innerCreate({
      variables: {
        input,
      },
    })

  return { createWorkflow, creating }
}

import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const FORM_TEMPLATES = {
  LOAD: createAsyncAction('form templates/LOAD'),
  CREATE: createAsyncAction('form templates/CREATE'),
  UPDATE: createAsyncAction('form templates/UPDATE'),
  DELETE: createAsyncAction('form templates/DELETE'),
}

const include = 'createdBy.profile'

function nameToFilter(formName) {
  return formName
    ? {
        name: {
          ilike: `%${formName}%`,
        },
      }
    : null
}

function stateToFilter(queryState) {
  return queryState
    ? {
        state: {
          eq: queryState,
        },
      }
    : null
}

export const loadFormTemplates = ({
  number,
  size,
  filters,
  sort = '-name',
}) => (dispatch, getState) => {
  const paged = !!number && !!size
  const page = paged ? { page: { number, size } } : null
  const companyId = getCompanyId(getState())
  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/forms`,
      types: FORM_TEMPLATES.LOAD,
      method: 'GET',
      query: {
        include,
        ...page,
        filter: {
          ...nameToFilter(filters?.name),
          ...stateToFilter(filters?.state),
        },
        sort,
      },
      paged,
    }),
  )
}

export const createFormTemplates = attributes => (dispatch, getState) => {
  const companyId = getCompanyId(getState())
  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/forms`,
      types: FORM_TEMPLATES.CREATE,
      method: 'POST',
      query: {
        data: {
          attributes,
        },
      },
    }),
  )
}

export const updateFormTemplate = ({ formTemplateId, attributes }) => (
  dispatch,
  getState,
) => {
  const companyId = getCompanyId(getState())
  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/forms/${formTemplateId}`,
      types: FORM_TEMPLATES.UPDATE,
      method: 'PATCH',
      query: {
        include,
        data: {
          attributes,
        },
      },
    }),
  )
}

export const deleteFormTemplates = formTemplateIds => (dispatch, getState) => {
  const companyId = getCompanyId(getState())
  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/relationships/forms`,
      types: FORM_TEMPLATES.DELETE,
      method: 'DELETE',
      query: {
        data: formTemplateIds.map(id => ({ type: 'forms', id })),
      },
      payload: {
        deletedIds: formTemplateIds,
      },
    }),
  )
}

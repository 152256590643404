export const WHITE = '#ffffff'
export const BLACK = '#000000'
export const RED = '#e86464'
export const YELLOW = '#f3d784'
export const GREEN = '#35d17d'
export const BLUE = '#61b4f4'
export const PURPLE = '#9d84e8'
export const PINK = '#ef95c5'

export const PUMPKIN_120 = '#C23C00'
export const PUMPKIN_100 = '#f48961' // old primary
export const PUMPKIN_80 = '#f6a181'
export const PUMPKIN_60 = '#f8b8a0'
export const PUMPKIN_40 = '#ffdccc'
export const PUMPKIN_20 = '#fde7df'
export const SMASHED_PUMPKIN = '#fc6d2d'

export const CHARCOAL_120 = '#3b3c3c'
export const CHARCOAL_100 = '#4a4b4c'
export const CHARCOAL_80 = '#777879'
export const CHARCOAL_60 = '#a5a5a5'
export const CHARCOAL_40 = '#d2d2d2'
export const CHARCOAL_20 = '#dbdbdb'
export const CHARCOAL_0 = '#ebebeb'

export const PAPER_WHITE_100 = '#f1f1f1'
export const PAPER_WHITE_80 = '#f4f4f4'
export const PAPER_WHITE_60 = '#f7f7f7'
export const PAPER_WHITE_40 = '#f9f9f9'
export const PAPER_WHITE_20 = '#fcfcfc'

export const ATHENS_GRAY_40 = '#EFEFF3'
export const ATHENS_GRAY_20 = '#F5F6F9'

export const TEXT = '#1d1e1f'

export const POSITIVE = '#5DAA5E'
export const POSITIVE_LIGHT = '#c3dfc3'
export const POSITIVE_DARK = '#27ac64'
export const NEGATIVE = '#CD4D52'
export const NEGATIVE_LIGHT = '#CD4D52'
export const NEGATIVE_DARK = '#ff3800'
export const NEGATIVE_SOFT = '#ee6166'
export const NEGATIVE_RUDE = '#7c2024'
export const NEUTRAL = '#3398ff'
export const NEUTRAL_LIGHT = '#5bacff'
export const NEUTRAL_SOFT = '#8babc4'
export const VIOLET_SOFT = '#c389b1'
export const YELLOW_SOFT = '#E5CC4A'
export const LIGHT_BLUE = '#50d1dd'
export const SLATE_GRAY = '#F5F6F9'
export const PROVINCIAL_PINK = '#FEF3EF'
export const SPUN_PEARL = '#B1B2C1'
export const MANATEE = '#8E8E9E'
export const FOAM = '#E9FAFD'
export const CAROUSEL_PINK = '#FCEBF1'
export const EBONY_CLAY = '#222338'

export const ERROR = '#F44336'
export const ERROR_LIGHT = '#FEECEB'
export const SUCCESS = '#4CAF50'
export const SUCCESS_LIGHT = '#EFF7EF'
export const WARNING = '#ffc107'
export const WARNING_LIGHT = '#FFF9E6'
export const WARNING_DARK = '#C79100'
export const DODGER_BLUE = '#2196F3'
export const BONDI_BLUE = '#009DBB'
export const HAWKES_BLUE = '#EAF2FD'
export const SEASHELL = '#F1F1F1'
export const NOBEL = '#B4B3B2'

export const GARDA_120 = 'aa2219'
export const GARDA_100 = '#d52a1f'
export const GARDA_80 = '#dd554c'
export const GARDA_60 = '#e67f79'
export const GARDA_40 = '#eeaaa5'
export const GARDA_20 = '#f7d4d2'

export const DAWAMY = '#0e4651'
export const KEYWORDS = '#263A7B'
export const SOBEYS = '#47A147'
export const RITEAID = '#044c9b'
export const TELEMAGENTA = '#de3b72'
export const PICTON_BLUE = '#27CEED'

export const SUNBURST_YELLOW = '#fcce15'
export const PALE_LEMON = '#fcfcca'
export const CRIMSON_FLAME = '#e81313'
export const BLUSH_PINK = '#fff0ef'

import React, { useMemo } from 'react'
import { Control, UseFormSetValue, useWatch } from 'react-hook-form'
import { TableVirtuoso } from 'react-virtuoso'

import { useTheme } from 'styled-components'

import { API } from 'API'

import { Flex, Span } from 'components/ui/__v2__/Grid'
import { Spinner } from 'components/ui/Spinner'

import { PAGE_SIZE } from 'constants/pagination'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import {
  BODY_CELL_HEIGHT,
  HEADER_CELL_HEIGHT,
  MAX_CELLS_IN_VISIBLE_CONTAINER,
} from './constants'
import { useTableSelection } from './hooks'
import { Table, TableContainer, TableFoot } from './styles'
import { TableFooter } from './TableFooter'
import { TableHeader } from './TableHeader'
import { TableItem } from './TableItem'

import { SELECTED_SCHEDULE_IDS_FIELD } from '../../constants'
import { ShiftMoveModalFormState } from '../../types'

type Props = {
  control: Control<ShiftMoveModalFormState>
  disabled: boolean
  shiftJobIds: string[]
  setValue: UseFormSetValue<ShiftMoveModalFormState>
}

export function EmployeesTable({
  control,
  disabled,
  shiftJobIds,
  setValue,
}: Props) {
  const theme = useTheme()
  const t = useI18n<typeof TRANSLATIONS.shiftMoveModal.table>(
    'shiftMoveModal.table',
  )

  const {
    schedules = [],
    schedulesLoading,
    schedulesLoadingMore,
    schedulesLoadMore,
    schedulesPageMeta,
  } = API.Schedule.byCursorForShiftAssignments({
    shiftJobIds,
    paging: { size: PAGE_SIZE['10'] },
  })

  const selectedScheduleIds = useWatch({
    control,
    name: SELECTED_SCHEDULE_IDS_FIELD,
  })

  const {
    schedulesCheckedCount,
    toggleSelection,
    toggleAllSelection,
  } = useTableSelection({
    schedules,
    selectedScheduleIds,
    setValue,
  })

  const handleEndReached = async () => {
    if (!schedulesPageMeta.hasNextPage || schedulesLoadingMore) return

    await schedulesLoadMore()
  }

  // NOTE: to ensure that TableVirtuoso does not have the same fixed height all the time
  // but adjusts dynamically based on the number of items
  const tableHeight = useMemo(() => {
    if (schedules.length >= MAX_CELLS_IN_VISIBLE_CONTAINER)
      return (
        MAX_CELLS_IN_VISIBLE_CONTAINER * BODY_CELL_HEIGHT + HEADER_CELL_HEIGHT
      )

    return schedules.length * BODY_CELL_HEIGHT + HEADER_CELL_HEIGHT
  }, [schedules])

  return (
    <Flex flexDirection="column" gap={2}>
      <Span>{t('title')}</Span>

      <Flex flexDirection="column" gap={1}>
        {schedulesLoading ? (
          <Flex
            alignItems="center"
            height={BODY_CELL_HEIGHT + HEADER_CELL_HEIGHT}
            justifyContent="center"
          >
            <Spinner size={30} />
          </Flex>
        ) : (
          <>
            <TableContainer height={tableHeight}>
              <TableVirtuoso
                components={{
                  Table,
                  TableFoot,
                }}
                data={schedules}
                endReached={handleEndReached}
                fixedFooterContent={() => (
                  <TableFooter loading={schedulesLoadingMore} />
                )}
                fixedHeaderContent={() => (
                  <TableHeader
                    checked={selectedScheduleIds.size === schedules.length}
                    disabled={disabled}
                    toggleAllSelection={toggleAllSelection}
                  />
                )}
                itemContent={(_, data) => (
                  <TableItem
                    checked={selectedScheduleIds.has(data.id)}
                    data={data}
                    disabled={disabled}
                    key={data.id}
                    toggleSelection={() => toggleSelection(data.id)}
                  />
                )}
                totalCount={schedules.length}
              />
            </TableContainer>
          </>
        )}

        <Flex gap={1}>
          <Span>{t('selectedEmployees')}:</Span>
          <Span color={theme.layout.primary}>{schedulesCheckedCount}</Span>
        </Flex>
      </Flex>
    </Flex>
  )
}

import React from 'react'

import { ShiftValidationRuleAPIItem } from 'API'

import Utils from 'services/Utils'

import { HoursRangeCondition } from './HoursRangeCondition'

type Props = {
  rule: ShiftValidationRuleAPIItem
}

// TODO: add common i18n for this component & RuleRenderer->MultipleShiftLength
export function EmployeeAssignmentThreshold({ rule }: Props) {
  return (
    <>
      The employee is assigned to shifts totaling{' '}
      <HoursRangeCondition
        formSubPath="employeeAssignmentThreshold"
        rule={rule}
      />{' '}
      within a{' '}
      {Utils.Rule.getOvertimeTypeText(
        rule.parameters.employeeAssignmentThreshold?.overtimeType,
      )}
      .
    </>
  )
}

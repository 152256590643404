import { useApolloMutation } from 'API/services/Apollo'

import { DeleteWorkWeekMutation } from './GraphQL'

export function useDeleteWorkWeek() {
  const [deleteWorkWeek, { loading: removing }] = useApolloMutation<
    MutationData<'deleteWorkWeek'>,
    Gateway.MutationDeleteWorkWeekArgs
  >(DeleteWorkWeekMutation, {
    translationKey: 'workWeek.toasts.delete',
  })

  const remove = async (workWeekId: string) => {
    await deleteWorkWeek({
      variables: { input: { id: workWeekId } },
    })
  }

  return { remove, removing }
}

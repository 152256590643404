import { BiCalendarCheck as ScheduleIcon } from 'react-icons/bi'

import styled, { css } from 'styled-components'

type ContainerProps = {
  opaque?: boolean
}

const opaqueCss = ({ opaque }: ContainerProps) =>
  opaque &&
  css`
    opacity: 30%;
  `

export const AssignmentContainer = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${opaqueCss}
`

export const PublishedIcon = styled(ScheduleIcon)`
  color: ${({ theme }) => theme.layout.negativeSoft};
`

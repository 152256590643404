import { useApolloMutation } from 'API/services/Apollo'

import { CreateWorkWeekMutation } from './GraphQL'

export function useCreateWorkWeek() {
  const [createWorkWeek, { loading: creating }] = useApolloMutation<
    MutationData<'createWorkWeek'>,
    Gateway.MutationCreateWorkWeekArgs
  >(CreateWorkWeekMutation, {
    translationKey: 'workWeek.toasts.create',
  })

  const create = async (
    variables: Omit<Gateway.CreateWorkWeekInput, 'companyId'>,
  ) => {
    await createWorkWeek({
      variables: {
        input: {
          ...variables,
        },
      },
    })
  }

  return { create, creating }
}

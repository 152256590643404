import { StoreonModule } from 'storeon'

import { INIT } from './constants'

export const NLP_INFERENCE_KEY = 'nlpInference'

export const OPEN_NLP_INFERENCE = 'nlpInference/open'
export const CLOSE_NLP_INFERENCE = 'nlpInference/close'

export interface NLPInferenceState {
  [NLP_INFERENCE_KEY]: {
    isOpen: boolean
  }
}

export interface NLPInferenceEvents {
  [OPEN_NLP_INFERENCE]: never
  [CLOSE_NLP_INFERENCE]: never
}

export const INITIAL_STATE: NLPInferenceState['nlpInference'] = {
  isOpen: false,
}

export const nlpInferenceModule: StoreonModule<
  NLPInferenceState,
  NLPInferenceEvents
> = store => {
  store.on(INIT, () => ({
    [NLP_INFERENCE_KEY]: INITIAL_STATE,
  }))

  store.on(OPEN_NLP_INFERENCE, state => ({
    [NLP_INFERENCE_KEY]: {
      ...state[NLP_INFERENCE_KEY],
      isOpen: true,
    },
  }))
  store.on(CLOSE_NLP_INFERENCE, state => ({
    [NLP_INFERENCE_KEY]: {
      ...state[NLP_INFERENCE_KEY],
      isOpen: false,
    },
  }))
}

import React from 'react'

import { FiCheck as ApproveIcon } from 'react-icons/fi'

import { ButtonProps, IconButton } from 'components/ui/__v3__/Button'

type Props = {
  onApprove: () => void
} & ButtonProps

export function Approve({ onApprove, ...rest }: Props) {
  return (
    <IconButton {...rest} secondary onClick={onApprove}>
      <ApproveIcon />
    </IconButton>
  )
}

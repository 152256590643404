import styled from 'styled-components'
import { flexbox, FlexboxProps } from 'styled-system'

type MenuItemProps = FlexboxProps

export const MenuItem = styled.button<MenuItemProps>`
  cursor: pointer;
  transition: all 0.17s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.WHITE};
  border: none;
  padding: ${({ theme }) => theme.space[2]}px;

  &:hover {
    color: ${({ theme }) => theme.layout.primary};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.CHARCOAL_60};

    &:hover {
      color: ${({ theme }) => theme.colors.CHARCOAL_60};
    }
  }
  ${flexbox}
`

import {
  BlankCustomCvFieldPair,
  FieldValuePairType,
} from 'components/blocks/__v3__/HookForm/FormCustomCvFieldsWithValues/types'

export function customFieldValueOptionsToFilter(
  customFieldValues: Array<FieldValuePairType | BlankCustomCvFieldPair>,
) {
  const fields = []

  for (const { field, value } of customFieldValues ?? []) {
    if (field?.id && value) {
      fields.push({ field_id: field.id, value })
    }
  }

  if (!fields.length) {
    return {}
  }

  return {
    custom_fields: {
      operator: 'and',
      in: fields,
    },
  }
}

export function customFieldValueOptionsToBulkUpdateData(
  customFieldValues: Array<FieldValuePairType | BlankCustomCvFieldPair>,
) {
  const updateData = []

  for (const { field, value } of customFieldValues) {
    if (field?.id && value) {
      updateData.push({
        attributes: {
          value,
        },

        relationships: {
          field: {
            data: {
              id: field.id,
            },
          },
        },
      })
    }
  }

  return updateData
}

import { graphQLResultHasError } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import {
  UnpublishSchedulesMutation,
  UnpublishSchedulesMutationData,
} from '../GraphQL'

export function useUnpublishSchedules() {
  const [innerUnpublishSchedules, { loading }] = useApolloMutation<
    UnpublishSchedulesMutationData,
    Gateway.MutationUnpublishSchedulesArgs
  >(UnpublishSchedulesMutation, {
    translationKey: 'schedule.unpublishSchedules.toasts',
  })

  async function unpublishSchedules(filter: Gateway.UnpublishSchedulesFilter) {
    const result = await innerUnpublishSchedules({
      variables: {
        filter,
      },
    })

    const hasError = graphQLResultHasError(result)

    return {
      hasError,
      schedules: result.data?.unpublishSchedules ?? [],
    }
  }

  return {
    unpublishSchedules,
    unpublishingSchedules: loading,
  }
}

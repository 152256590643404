import { reducer as toastr } from 'react-redux-toastr'

import { combineReducers } from 'redux'

import { shop } from './shop/shop'
import app from './app'
import areas from './areas'
import common from './common'
import company from './company'
import customLabelDays from './customLabelDays'
import data from './data'
import employeeApp from './employeeApp'
import employees from './employees'
import groupChat from './groupChat'
import header from './header'
import locations from './locations'
import news from './news'
import notifications from './notifications'
import payroll from './payroll'
import payrollRules from './payrollRules'
import reportBuilder from './reportBuilder'
import router from './router'
import staff from './staff'
import viewer from './viewer'

export default history =>
  combineReducers({
    data,
    app,
    common,

    company,
    viewer,
    employees,

    notifications,
    groupChat,
    news,
    header,

    router: router(history),
    toastr,

    employeeApp,

    locations,

    payroll,
    payrollRules,
    reportBuilder,
    staff,
    areas,

    shop,

    customLeaveDays: customLabelDays,
  })

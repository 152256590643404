import { getEntities } from 'rapidux'
import { createSelector } from 'reselect'

import isEmpty from 'lodash/isEmpty'

import { denormalize } from 'helpers/redux'

import { getData } from '../data'

export const getState = state => state.company.branches

export const getStateEmployees = state => state.employees.employees

export const getEmployeeIds = createSelector(getStateEmployees, state =>
  Array.from(state.employees),
)

export const getIsLoading = createSelector(getState, state => state.isLoading)

export const getBranchIds = createSelector(getState, state =>
  Array.from(state.branches),
)

export const getRawImageFiles = createSelector(
  getState,
  state => state.imageFiles,
)

export const getIsProcessingImageFiles = createSelector(
  getState,
  state => state.isProcessingImageFiles,
)

/** @deprecated */
export const getBranches = createSelector(
  getData,
  getBranchIds,
  (data, branchIds) => {
    return !isEmpty(branchIds) ? denormalize(data, 'branches', branchIds) : []
  },
)

export const getImageFiles = createSelector(
  getData,
  getRawImageFiles,
  (data, imageFiles) =>
    !isEmpty(imageFiles) ? denormalize(data, 'files', imageFiles) : [],
)

export const getBranch = getEntities(getState, getData, {
  type: 'branches',
  field: 'branch',
  singular: true,
})

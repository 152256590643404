import React from 'react'
import PropTypes from 'prop-types'

import { LONG_DASH } from 'constants/schedule'

import { Container, DownloadIcon, FileLink, IconLink } from './styles'

const FileDownload = ({ file }) => {
  return file ? (
    <Container>
      <FileLink href={file?.content?.url} pr={4}>
        {file?.name}
      </FileLink>
      <IconLink href={file?.content?.url}>
        <DownloadIcon />
      </IconLink>
    </Container>
  ) : (
    <>{LONG_DASH}</>
  )
}
FileDownload.defaultProps = {
  file: null,
}

FileDownload.propTypes = {
  file: PropTypes.object,
}

export default FileDownload

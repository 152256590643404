import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { ACCESS_LEVEL } from './ids'

export const FILE_TYPES = {
  FILE: 'file',
  VIDEO: 'video',
  IMAGE: 'image',
  ALL: 'all', // to simplify clear preview file function (PostEditor)
}

export const getIsDisabledPostEdit = (viewer, post) => {
  const viewerAccessLevel = get(viewer, 'accessLevel', null)
  const isDepartmentManager = !isEmpty(get(viewer, 'managers[0].departments'))
  const viewerId = get(viewer, 'id')
  return (
    (viewerAccessLevel === ACCESS_LEVEL.employee || isDepartmentManager) &&
    viewerId !== get(post, 'user.id', null)
  )
}

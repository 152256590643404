import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'
import Shared from 'services/Shared'

import { getCompanyId } from 'store/selectors/viewer'

export const CREATE_EXPORT_AVAILABILITY_TOKEN = createAsyncAction(
  'availability/CREATE_EXPORT_TOKEN',
)

export const createExportAvailabilityToken = ({ areas, startAt, endAt }) => (
  dispatch,
  getState,
) => {
  const companyId = getCompanyId(getState())
  const locale = Shared.getStoreOnLocale()

  return dispatch(
    apiCall({
      method: 'POST',
      endpoint: '/token',
      types: CREATE_EXPORT_AVAILABILITY_TOKEN,
      isRaw: true,
      payload: {
        areas,
        startAt,
        endAt,
        locale,
        companyId,
      },
    }),
  )
}

import { useApolloQuery } from 'API/services/Apollo'
import { cursorPageToGraphqlPaging } from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { CustomCvFieldsByCursorQuery } from './GraphQL'

type Args = {
  filter?: Gateway.CustomCvFieldsFilter
  paging: CursorPage
}

export function useCustomCvFieldsByCursor({ filter, paging }: Args) {
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'customCvFieldsByCursor'>,
    Gateway.QueryCustomCvFieldsByCursorArgs
  >(CustomCvFieldsByCursorQuery, {
    fetchPolicy: 'network-only',
    variables: { filter, paging: cursorPageToGraphqlPaging(paging) },
  })

  const customCvFields =
    data?.customCvFieldsByCursor?.edges?.map(edge => edge.node) ?? []

  return {
    customCvFields,
    loading,
    refetch,
    pageInfo: data?.customCvFieldsByCursor?.pageInfo,
  }
}

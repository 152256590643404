import React, { memo, useCallback, useState } from 'react'
import { toastr } from 'react-redux-toastr'
import PropTypes from 'prop-types'

import copy from 'copy-to-clipboard'

import isEmpty from 'lodash/isEmpty'

import { SpacedRow } from 'components/ui/__v2__/Grid'
import { TextOverflow } from 'components/ui/__v2__/TextOverflow'

import { LONG_DASH } from 'constants/schedule'

import _ from 'i18n'

import Utils from 'services/Utils'

import { CoordinatesColumn, CopyIcon } from './styles'

function CoordinatesCell({ latLng, withCopy, withShowOnMap }) {
  const [isAnimated, setAnimated] = useState(false)

  if (!latLng || isEmpty(latLng)) return LONG_DASH

  const { lat, lng } = latLng

  const handleCoordinatesClick = useCallback(() => {
    const googleMapUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    Utils.Url.openInNewTab(googleMapUrl)
  }, [lat, lng])

  const handleCopyClick = useCallback(
    event => {
      event.stopPropagation()

      copy(`${lat}, ${lng}`)

      toastr.info(_('common.coordinatesCopiedToClipboard'))

      setAnimated(true)
      setTimeout(() => setAnimated(false), 1000)
    },
    [lat, lng],
  )

  return (
    <SpacedRow
      alignItems="center"
      justifyContent="space-between"
      space={1}
      width="100%"
    >
      <CoordinatesColumn
        onClick={withShowOnMap ? handleCoordinatesClick : null}
      >
        <TextOverflow>{lat}</TextOverflow>
        <TextOverflow>{lng}</TextOverflow>
      </CoordinatesColumn>
      {withCopy && (
        <CopyIcon isAnimated={isAnimated} onClick={handleCopyClick} />
      )}
    </SpacedRow>
  )
}

CoordinatesCell.defaultProps = {
  latLng: null,
  withCopy: true,
  withShowOnMap: true,
}

CoordinatesCell.propTypes = {
  latLng: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
  withCopy: PropTypes.bool,
  withShowOnMap: PropTypes.bool,
}

export default memo(CoordinatesCell)

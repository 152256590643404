import React, { useState } from 'react'

import { Box, Flex, SpacedColumn, Span } from 'components/ui/__v2__/Grid'
import { Popover } from 'components/ui/__v2__/Popover'
import { Radio } from 'components/ui/__v2__/Radio'
import { Button } from 'components/ui/__v3__'

import { useAppContext, useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

type Props = {
  disabled: boolean
  loading: boolean
  onConfirm: ({
    unassignConflictingSchedules,
  }: {
    unassignConflictingSchedules: boolean
  }) => Promise<void>
}

export function SubmitButton({ disabled, loading, onConfirm }: Props) {
  const t = useI18n<typeof TRANSLATIONS.shiftAssign>('shiftAssign')
  const { company } = useAppContext()
  const hasShiftConflictActions = company.identity.Keywords

  const [confirmationVisible, setConfirmationVisible] = useState(false)
  const [
    unassignConflictingSchedules,
    setUnassignConflictingSchedules,
  ] = useState(false)

  const handleApplyClick = () => {
    if (confirmationVisible) return
    setConfirmationVisible(true)
  }

  const handleConfirmClick = () => {
    onConfirm({ unassignConflictingSchedules })
    setUnassignConflictingSchedules(false)
    setConfirmationVisible(false)
  }

  return (
    // @ts-ignore
    <Popover
      content={
        <SpacedColumn>
          <Box>{t('confirmation.message')}</Box>

          {hasShiftConflictActions && (
            <>
              <Box>{t('confirmation.conflicts.message')}</Box>
              <Flex alignItems="center" as="label">
                <Radio
                  // @ts-ignore
                  checked={!unassignConflictingSchedules}
                  size={16}
                  value={false}
                  onChange={() => setUnassignConflictingSchedules(false)}
                />
                <Span ml={2}>{t(`confirmation.conflicts.options.ignore`)}</Span>
              </Flex>
              <Flex alignItems="center" as="label">
                <Radio
                  // @ts-ignore
                  checked={unassignConflictingSchedules}
                  size={16}
                  value
                  onChange={() => setUnassignConflictingSchedules(true)}
                />
                <Span ml={2}>
                  {t(`confirmation.conflicts.options.unassign`)}
                </Span>
              </Flex>
            </>
          )}

          <Button small onClick={handleConfirmClick}>
            {t('confirmation.action')}
          </Button>
        </SpacedColumn>
      }
      interactive
      maxWidth={hasShiftConflictActions ? 300 : 200}
      visible={confirmationVisible}
      onClickOutside={() => setConfirmationVisible(false)}
    >
      {/* Note without the div Tippy couldn't find the DOM reference correctly */}
      <div>
        <Button
          disabled={disabled}
          loading={loading}
          onClick={handleApplyClick}
        >
          {t('apply')}
        </Button>
      </div>
    </Popover>
  )
}

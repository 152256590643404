import React from 'react'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Spinner } from '../../Spinner'

const StyledSpinner = styled(Spinner)`
  fill: ${themeGet('layout.primary')};
`

const LoadingMore = styled.div.attrs({
  children: <StyledSpinner />,
})`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 13px;
  font-weight: 300;
  padding: 15px 0;
`

export { LoadingMore }

import styled from 'styled-components'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'

export const FilterHandle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.layout.primary};
`

export const CandidatesTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]}px;
`

export const AvailableTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: normal;
`

export const PopoverContainer = styled.div<LayoutProps & SpaceProps>`
  background: ${({ theme }) => theme.layout.bg};
  border-radius: ${({ theme }) => theme.space[2]}px;
  box-shadow: ${({ theme }) => theme.tooltip.shadow};
  border-color: ${({ theme }) => theme.layout.border};
  padding: ${({ theme }) => theme.space[2]}px;
  border-style: solid;
  z-index: ${({ theme }) => theme.zIndices.popup};
  ${layout}
  ${space}
`

import { useState } from 'react'

import { NetworkStatus } from '@apollo/client'
import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { RelayStylePaginationVariables } from 'services/Apollo/types'

import { scheduleFilterToAPI } from '../adapters'
import {
  SchedulesGardaByCursorQueryData,
  SchedulesGardaListViewQuery,
} from '../GraphQL'
import { ScheduleFilter } from '../types'

type Props = {
  filter: ScheduleFilter
  page: CursorPage
  sorting: Gateway.ScheduleSorting[]
}

const POLL_INTERVAL = 2 * 60 * 1000

export function useSchedulesGardaListView({ filter, page, sorting }: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, networkStatus, refetch } = useApolloQuery<
    SchedulesGardaByCursorQueryData,
    Gateway.QuerySchedulesByCursorArgs & RelayStylePaginationVariables
  >(SchedulesGardaListViewQuery, {
    variables: {
      filter: scheduleFilterToAPI(filter),
      sorting,
      paging: cursorPageToGraphqlPaging(page),
      // TODO: temporary solution, need to refactor read method at relayStylePagination to have pagination by page, not only load more
      disableMerge: true,
    },
    pollInterval: POLL_INTERVAL,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',

    onCompleted(completedData) {
      const newPageMeta = completedData.schedulesByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const schedules = data?.schedulesByCursor?.edges.map(edge => edge.node) ?? []

  return {
    schedules,
    loading:
      networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.setVariables ||
      networkStatus === NetworkStatus.refetch,
    refetch,
    currentPageMeta,
  }
}

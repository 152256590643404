import React from 'react'
import { components, OptionProps } from 'react-select'

import { TextOverflowWithPopover } from 'components/blocks/__v3__/TextOverflowWithPopover'

import { OptionContainer } from './styles'

type OptionItem = {
  value: string
  label: string
}

export function Option({ data, ...rest }: OptionProps<OptionItem, true>) {
  return (
    <components.Option data={data} {...rest}>
      <OptionContainer>
        <TextOverflowWithPopover popoverPlacement="right-end" width="100%">
          {data.label}
        </TextOverflowWithPopover>
      </OptionContainer>
    </components.Option>
  )
}

import React, { useMemo } from 'react'

import { TypographyProps } from 'styled-system'

import { ShiftValidationRuleAPIItem } from 'API'

import { Span } from 'components/ui/__v2__/Grid'

import { ConflictType, RuleType } from 'constants/gatewayGraphQL'

import {
  ConsecutiveDays,
  DayOfWeek,
  EmployeeAssignmentThreshold,
  EmployeeDaysOffWeekThreshold,
  ShiftHoliday,
  ShiftRestTime,
  SingleShiftLengthThreshold,
} from './components'

type Props = TypographyProps & {
  conflictType: ConflictType
  rule: ShiftValidationRuleAPIItem
  fontSize?: string | number
  lineHeight?: string | number
}

export function ShiftValidationConflictRuleConditions({
  rule,
  fontSize = '12px',
  lineHeight = '16px',
  conflictType,
  ...rest
}: Props) {
  const ruleContent = useMemo(() => {
    switch (rule.ruleType) {
      case RuleType.EmployeeAssignmentThreshold:
        return <EmployeeAssignmentThreshold rule={rule} />

      case RuleType.EmployeeDaysOffThreshold:
        return <EmployeeDaysOffWeekThreshold rule={rule} />

      case RuleType.ShiftLengthThreshold:
        return <SingleShiftLengthThreshold rule={rule} />

      case RuleType.ShiftRestTime:
        return <ShiftRestTime rule={rule} />

      case RuleType.DayOfWeek:
        return <DayOfWeek rule={rule} />

      case RuleType.ShiftHoliday:
        return <ShiftHoliday />

      case RuleType.ConsecutiveDays:
        return <ConsecutiveDays conflictType={conflictType} rule={rule} />
      default:
        return null
    }
  }, [rule, conflictType])

  return (
    <Span fontSize={fontSize} lineHeight={lineHeight} {...rest}>
      {ruleContent}
    </Span>
  )
}

import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { EMPLOYMENT_LEVEL_ERRORS_I18N } from './errors'
import {
  CreateEmploymentLevelMutation,
  EmploymentLevelsByCursorQuery,
} from './GraphQL'

export function useCreateEmploymentLevel() {
  const t = useI18n<
    typeof TRANSLATIONS.employmentTerms.employmentLevel.toasts.create
  >('employmentTerms.employmentLevel.toasts.create')

  const [innerCreate, { loading: creating }] = useApolloMutation<
    MutationData<'createEmploymentLevel'>,
    Gateway.MutationCreateEmploymentLevelArgs
  >(CreateEmploymentLevelMutation, {
    refetchQueries: compact([getOperationName(EmploymentLevelsByCursorQuery)]),
    silent: true,
    // TODO: Temporary solution, translated errors should come from Gateway
    onError: error => {
      const serverErrorMessage = error.message
      const isEmploymentLevelError = EMPLOYMENT_LEVEL_ERRORS_I18N.create.messages.some(
        errorMessage => serverErrorMessage.includes(errorMessage),
      )

      showToast({
        type: 'error',
        title: t('error.title'),
        content: isEmploymentLevelError
          ? EMPLOYMENT_LEVEL_ERRORS_I18N.create.translation
          : t('error.message'),
      })
    },
    onCompleted: () => {
      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const createEmploymentLevel = async (
    params: Gateway.CreateEmploymentLevelInput,
  ) =>
    innerCreate({
      variables: {
        input: params,
      },
    })

  return { createEmploymentLevel, creating }
}

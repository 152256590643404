import { useApolloMutation } from 'API/services/Apollo'

import { DeleteGroupMutation } from './GraphQL'

export function useDeleteGroup() {
  const [innerDelete, { loading: deleting }] = useApolloMutation<
    MutationData<'deleteGroup'>,
    Gateway.MutationDeleteGroupArgs
  >(DeleteGroupMutation, {
    translationKey: 'settings.groups.toasts.deleteGroup',
  })

  const deleteGroup = async (id: string) => {
    return innerDelete({
      variables: {
        id,
      },
    })
  }

  return { deleteGroup, deleting }
}

import { toastr } from 'react-redux-toastr'

import { push } from 'connected-react-router'
import { all, fork, put, race, take } from 'redux-saga/effects'

import { formBuilderPath } from 'helpers/routes'

import _ from 'i18n'

import { FORM_TEMPLATES } from 'store/actions/company/formTemplates'

const NAME_ALREADY_TAKEN_DETAIL = 'is already taken'
function getErrorFeedback(error) {
  let message = _('common.somethingWentWrong')
  if (error.error?.find(e => e.detail === NAME_ALREADY_TAKEN_DETAIL)) {
    message = _('formBuilder.toasts.formNameTaken')
  }
  toastr.error(message, {
    disableCloseButtonFocus: true,
  })
}

function* watchLoadErrors() {
  while (true) {
    const error = yield take(FORM_TEMPLATES.LOAD.FAILURE)

    if (error)
      toastr.error(_('formBuilder.toasts.loadFailure'), {
        disableCloseButtonFocus: true,
      })
  }
}

function* watchDeleteTask() {
  while (true) {
    const { success } = yield race({
      success: take(FORM_TEMPLATES.DELETE.SUCCESS),
      error: take(FORM_TEMPLATES.DELETE.FAILURE),
    })
    if (success) {
      toastr.success(_('formBuilder.toasts.deleteSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      toastr.error(_('common.somethingWentWrong'), {
        disableCloseButtonFocus: true,
      })
    }
  }
}

function* watchCreateTask() {
  while (true) {
    const { success, error } = yield race({
      success: take(FORM_TEMPLATES.CREATE.SUCCESS),
      error: take(FORM_TEMPLATES.CREATE.FAILURE),
    })
    if (success) {
      yield put(push(formBuilderPath()))
      toastr.success(_('formBuilder.toasts.createSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      getErrorFeedback(error)
    }
  }
}

function* watchEditTask() {
  while (true) {
    const { success, error } = yield race({
      success: take(FORM_TEMPLATES.UPDATE.SUCCESS),
      error: take(FORM_TEMPLATES.UPDATE.FAILURE),
    })
    if (success) {
      yield put(push(formBuilderPath()))
      toastr.success(_('formBuilder.toasts.updateSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      getErrorFeedback(error)
    }
  }
}

export default function* root() {
  yield all([
    fork(watchDeleteTask),
    fork(watchCreateTask),
    fork(watchEditTask),
    fork(watchLoadErrors),
  ])
}

import React, { ReactNode } from 'react'

import { FiArrowDown as DownIcon, FiArrowUp as UpIcon } from 'react-icons/fi'

import { Flex, Span } from 'components/ui/__v2__/Grid'

import { SortingDirection } from 'constants/gatewayGraphQL'

import { HeaderIconContainer } from '../../styles'

type Props = {
  children: ReactNode
  direction: SortingDirection | undefined
  onToggle: (direction: SortingDirection) => void
}
export function Sortable({ children, direction, onToggle }: Props) {
  const sortedAscending = direction === SortingDirection.Asc
  return (
    <Flex
      alignItems="center"
      // @ts-ignore
      cursor="pointer"
      fontWeight="initial"
      onClick={() =>
        onToggle(sortedAscending ? SortingDirection.Desc : SortingDirection.Asc)
      }
    >
      <Span fontWeight="bold">{children}</Span>
      <HeaderIconContainer ml={2}>
        {direction && (sortedAscending ? <UpIcon /> : <DownIcon />)}
      </HeaderIconContainer>
    </Flex>
  )
}

import styled from 'styled-components'

// TODO: THis needs to be centralized
export const HelperText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.layout.darkBorder};
  gap: ${({ theme }) => theme.space[1]}px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
`

import {
  createDeleteHandler,
  createFields,
  createLoadHandler,
  createReducerHandlers,
} from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  CREATE_PRAYER_BREAK,
  DELETE_PRAYER_BREAK,
  LOAD_PRAYER_BREAKS,
} from 'store/actions/company/prayerBreaks'

const initialState = Immutable({
  ...createFields('prayerSettings'),
})

export const PRAYER_SETTINGS_TYPE = 'prayerSettings'

const handleBeforeLoad = state => {
  return state.merge({
    isLoading: true,
    isLoaded: false,
  })
}

const handleAfterLoad = state => {
  return state.merge({
    isLoading: false,
    isLoaded: false,
  })
}

const handlers = {
  ...createReducerHandlers(PRAYER_SETTINGS_TYPE, LOAD_PRAYER_BREAKS, {
    withReplace: true,
  }),

  [CREATE_PRAYER_BREAK.REQUEST]: handleBeforeLoad,
  [CREATE_PRAYER_BREAK.SUCCESS]: createLoadHandler(PRAYER_SETTINGS_TYPE),
  [CREATE_PRAYER_BREAK.FAILURE]: handleAfterLoad,

  [DELETE_PRAYER_BREAK.REQUEST]: handleBeforeLoad,
  [DELETE_PRAYER_BREAK.SUCCESS]: (...args) => {
    return handleAfterLoad(createDeleteHandler(PRAYER_SETTINGS_TYPE)(...args))
  },
  [DELETE_PRAYER_BREAK.FAILURE]: handleAfterLoad,

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { BiFirstPage, BiLastPage } from 'react-icons/bi'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'

import { withTheme } from 'styled-components'

import { Button } from 'components/ui/__v2__/Button'
import { Flex, Span } from 'components/ui/__v2__/Grid'
import { Select } from 'components/ui/__v3__/Select'

import { useI18n } from 'hooks/useI18n'

import { PageList, PaginationWrapper } from './styles'

import { DEFAULT_PAGINATION } from '../../constants'

function Pagination({
  rowsPerPage,
  rowCount,
  currentPage,
  theme,
  onChangeRowsPerPage,
  onChangePage,
}) {
  const t = useI18n('table')
  const numPages = getNumberOfPages(rowCount, rowsPerPage)

  const disabledLesser = currentPage === 1
  const disabledGreater = currentPage === numPages

  const handlePrevious = useCallback(() => onChangePage(currentPage - 1), [
    currentPage,
    onChangePage,
  ])

  const handleNext = useCallback(() => onChangePage(currentPage + 1), [
    currentPage,
    onChangePage,
  ])

  const handleFirst = useCallback(() => onChangePage(1), [onChangePage])

  const handleLast = useCallback(
    () => onChangePage(getNumberOfPages(rowCount, rowsPerPage)),
    [onChangePage, rowCount, rowsPerPage],
  )

  const handleRowsPerPage = useCallback(
    option => onChangeRowsPerPage(option.value, currentPage),
    [currentPage, onChangeRowsPerPage],
  )

  const selectOptions = useMemo(() => {
    return DEFAULT_PAGINATION.map(num => ({
      label: num,
      value: num,
    }))
  }, [])

  const defaultSelectValue = useMemo(
    () => ({
      label: rowsPerPage,
      value: rowsPerPage,
    }),
    [rowsPerPage],
  )

  const lastIndex = currentPage * rowsPerPage
  const firstIndex = lastIndex - rowsPerPage + 1

  const range =
    currentPage === numPages
      ? `${firstIndex}-${rowCount} ${t('of')} ${rowCount}`
      : `${firstIndex}-${lastIndex} ${t('of')} ${rowCount}`

  return (
    <PaginationWrapper appTheme={theme} className="rdt_Pagination">
      <Span mr={2} primary>
        {t('rowsPerPage')}
      </Span>
      <Flex text width={55}>
        <Select
          defaultValue={defaultSelectValue}
          isClearable={false}
          options={selectOptions}
          onChange={handleRowsPerPage}
        />
      </Flex>
      <Span mx={4} primary>
        {range}
      </Span>
      <PageList>
        <Button
          disabled={disabledLesser}
          icon
          mr={2}
          outline
          onClick={handleFirst}
        >
          <BiFirstPage size={24} />
        </Button>

        <Button
          disabled={disabledLesser}
          icon
          mr={2}
          outline
          onClick={handlePrevious}
        >
          <MdNavigateBefore size={24} />
        </Button>

        <Button
          disabled={disabledGreater}
          icon
          mr={2}
          outline
          onClick={handleNext}
        >
          <MdNavigateNext size={24} />
        </Button>

        <Button disabled={disabledGreater} icon outline onClick={handleLast}>
          <BiLastPage size={24} />
        </Button>
      </PageList>
    </PaginationWrapper>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
}

export default withTheme(Pagination)

function getNumberOfPages(rowCount, rowsPerPage) {
  return Math.ceil(rowCount / rowsPerPage)
}

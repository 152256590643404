import { useMemo } from 'react'

import { useApolloLazyQuery } from 'API/services/Apollo'

import { PAGE_SIZE } from 'constants/pagination'

import { CustomCvFieldsPickerQuery } from './GraphQL'

export type UsePickerCustomCvFieldsArgs = {
  classifier?: boolean
}

export function usePickerCustomCvFields({
  classifier,
}: UsePickerCustomCvFieldsArgs) {
  const [load, { loading, refetch: innerRefetch, data }] = useApolloLazyQuery<
    // Note: The Field renderer currently depends on the data field, so we cannot return just id and name, refactor once Field renderer is refactored
    QueryData<'customCvFieldsByCursor'>,
    Gateway.QueryCustomCvFieldsByCursorArgs
  >(CustomCvFieldsPickerQuery, {
    variables: {
      filter: { classifier },
      paging: { limit: PAGE_SIZE['10'] },
    },
    fetchPolicy: 'no-cache',
  })

  const refetch = async (inputValue: string) => {
    if (!innerRefetch) return []
    const result = await innerRefetch({
      filter: { name: `%${inputValue}%`, classifier },
    })
    return dataToNodes(result.data)
  }

  const customFields = useMemo(() => dataToNodes(data), [data])

  return { customFields, loading, refetch, load }
}

function dataToNodes(
  data: QueryData<'customCvFieldsByCursor'> | undefined,
): Gateway.CustomCvField[] {
  return data?.customCvFieldsByCursor?.edges?.map(({ node }) => node) ?? []
}

import React from 'react'

import { LayoutProps, SpaceProps } from 'styled-system'

import { Container } from './styles'

import { TextOverflowWithPopover } from '../TextOverflowWithPopover'

interface Props extends LayoutProps, SpaceProps {
  filled: number
  total: number
}

export function FilledAndTotalIndicator({ filled, total, ...rest }: Props) {
  const deviation = filled < total

  return (
    <Container deviation={deviation} {...rest}>
      <TextOverflowWithPopover maxWidth={'100px'}>
        {`${filled} / ${total}`}
      </TextOverflowWithPopover>
    </Container>
  )
}

import React, { useCallback, useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useField } from 'react-final-form'
import { toastr } from 'react-redux-toastr'
import PropTypes from 'prop-types'

import concat from 'lodash/concat'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import { ValidationError } from 'components/ui/__v2__/Form/styles'
import { Flex } from 'components/ui/__v2__/Grid'
import { Popover } from 'components/ui/__v2__/Popover'
import { IconError } from 'components/ui/__v3__/Input/Errors'

import _ from 'i18n'

import FilesPopup from './FilesPopup'
import {
  BorderIconButton,
  DownloadIcon,
  InputBorder,
  PlaceholderText,
  TriangleDown,
} from './styles'

function FormFileUpload({
  canDownload,
  canDelete,
  canUpload,
  maxFiles,
  multiple,
  name,
  required,
  uploadFiles,
  onBlur,
  ...rest
}) {
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)

  const { input, meta } = useField(name)
  /**
   * In case input is not an array (single file)
   */
  const files = useMemo(() => flatten([input.value || []]), [input.value])

  const handleDropdownButtonClick = useCallback(() => {
    setShowDropdownMenu(x => !x)
  }, [])

  const handleClickOutside = useCallback(() => {
    setShowDropdownMenu(false)
  }, [])

  const handleChange = useCallback(
    value => {
      input.onChange(value)
      onBlur && onBlur()
    },
    [input, onBlur],
  )

  const handleRemoveFile = useCallback(
    id => () => {
      handleChange(files.filter(file => file.id !== id))
    },
    [files, handleChange],
  )

  const handleDrop = useCallback(
    async inputFiles => {
      try {
        if (files.length + inputFiles.length > maxFiles) {
          toastr.error(_('common.maxFilesError', { maxFiles }))
          return
        }
        const resultFiles = await uploadFiles(inputFiles)
        if (multiple) {
          handleChange(concat(files || [], resultFiles))
        } else {
          handleChange(resultFiles)
        }
      } catch (error) {
        toastr.error(_('common.somethingWentWrong'))
      }
    },
    [multiple, files, uploadFiles, maxFiles, handleChange],
  )

  const canRemoveFile = useMemo(() => {
    if (multiple) {
      return !(required && files?.length === 1)
    }
    return !required
  }, [multiple, required, files])

  return (
    <Flex flexDirection="column" width="100%">
      <Flex>
        <Dropzone
          disabled={!canUpload}
          multiple={multiple}
          onDrop={handleDrop}
          {...rest}
        >
          {({ getRootProps, getInputProps }) => (
            <InputBorder
              {...getRootProps()}
              {...rest.rootProps}
              disabled={!canUpload}
              errored={meta.error && meta.touched}
              flexGrow={1}
            >
              <input {...getInputProps()} {...rest.InputProps} />
              <PlaceholderText>
                {multiple ? _('common.selectFiles') : _('common.selectFile')}...
              </PlaceholderText>
            </InputBorder>
          )}
        </Dropzone>
        <Popover
          appendTo={document.body}
          content={
            <FilesPopup
              canDownload={canDownload}
              canRemoveFile={canDelete && canRemoveFile}
              files={files}
              onRemoveFile={handleRemoveFile}
            />
          }
          interactive
          placement="bottom-end"
          visible={showDropdownMenu}
          onClickOutside={handleClickOutside}
        >
          <BorderIconButton
            disabled={isEmpty(files)}
            ml={1}
            onClick={handleDropdownButtonClick}
          >
            <DownloadIcon />
            <TriangleDown ml={1} />
          </BorderIconButton>
        </Popover>
      </Flex>
      {meta.error && meta.touched && (
        <ValidationError>
          <IconError>{meta.error}</IconError>
        </ValidationError>
      )}
    </Flex>
  )
}

FormFileUpload.defaultProps = {
  canDownload: true,
  canUpload: true,
  canDelete: true,
  maxFiles: 50,
  multiple: false,
  required: false,
  onBlur: noop,
}

FormFileUpload.propTypes = {
  ...Dropzone.propTypes,
  canDelete: PropTypes.bool,
  canDownload: PropTypes.bool,
  canUpload: PropTypes.bool,
  maxFiles: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  uploadFiles: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
}

export default FormFileUpload

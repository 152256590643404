import { toastr } from 'react-redux-toastr'

import { all, fork, put, race, take } from 'redux-saga/effects'

import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

import { executeCsv } from 'constants/payroll'

import _ from 'i18n'

import {
  CREATE_TOKEN,
  createToken,
  LOAD_NETHRIS_TABLE,
  PAYROLL_EXPORT,
} from 'store/actions/payroll'

function* takeExportLink() {
  while (true) {
    const { payload } = yield take(PAYROLL_EXPORT.SUCCESS)
    const payrollExport = get(payload, 'data.payrollExport', null)
    const key = Object.keys(payrollExport)[0]
    const exportLink = isNull(payrollExport)
      ? null
      : get(payrollExport, `${key}.links.self`)
    if (!isNull(exportLink)) {
      yield put(createToken(exportLink))
    }
  }
}

function* takeToken() {
  while (true) {
    const { payload } = yield take(CREATE_TOKEN.SUCCESS)
    const token = get(payload, 'data.token', null)
    const url = isNull(token) ? null : `${get(payload, 'url')}?token=${token}`
    if (!isNull(url)) {
      executeCsv(url, executeCsv)
    }
  }
}

function* handlePermissionError() {
  const PERMISSION_ERROR_DETAIL = "doesn't have permission to payroll"
  while (true) {
    const { loadTable, payrollExport } = yield race({
      loadTable: take(LOAD_NETHRIS_TABLE.FAILURE),
      payrollExport: take(PAYROLL_EXPORT.FAILURE),
    })
    if (loadTable || payrollExport) {
      const action = isEmpty(loadTable) ? payrollExport : loadTable
      const errors = get(action, 'payload.errors', [])
      const permissionError = find(
        errors,
        error => get(error, 'detail', null) === PERMISSION_ERROR_DETAIL,
      )
      if (!isEmpty(permissionError)) {
        toastr.info(_('payroll.doesntHavePermissionToPayroll'))
      }
    }
  }
}

export default function* root() {
  yield all([
    fork(takeExportLink),
    fork(takeToken),
    fork(handlePermissionError),
  ])
}

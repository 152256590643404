import { useApolloQuery } from 'API/services/Apollo'

import Utils from 'services/Utils'

import { IntegrationEventsByOffset } from './GraphQL'

type Args = Pick<
  Gateway.QueryIntegrationEventsByOffsetArgs,
  'paging' | 'sorting'
> & { filter: { integrationId?: string } }

// prettier-ignore
export function useIntegrationEventsByOffset({ paging, filter, sorting }: Args) {
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'integrationEventsByOffset'>,
    Gateway.QueryIntegrationEventsByOffsetArgs
  >(IntegrationEventsByOffset, {
    variables: {
      sorting,
      filter: filter.integrationId
        ? { integrationId: Utils.GraphQL.eqFilter(filter.integrationId) }
        : undefined,
      paging,
    },
    fetchPolicy: 'network-only',
  })

  const integrationEvents = data?.integrationEventsByOffset?.rows ?? []

  return {
    integrationEvents,
    loading,
    refetch,
    total: data?.integrationEventsByOffset?.pageInfo.total ?? 0,
  }
}

import CreateCustomCvField from './createCustomCvField.graphql'
import DeleteCustomCvField from './deleteCustomCvField.graphql'
import UpdateCustomCvField from './updateCustomCvField.graphql'
import UpdateCustomCvFields from './updateCustomCvFields.graphql'

export {
  CreateCustomCvField,
  DeleteCustomCvField,
  UpdateCustomCvField,
  UpdateCustomCvFields,
}

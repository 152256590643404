import { StoreonModule } from 'storeon'

import { merge } from 'lodash'
import isEqual from 'lodash/isEqual'

import { GardaReportsEvents, GardaReportsState } from './types'

export const GARDA_REPORTS_STATE_KEY = 'gardaReports'

const INITIAL_STATE: GardaReportsState['gardaReports'] = {}

export const gardaReportsModule: StoreonModule<
  GardaReportsState,
  GardaReportsEvents
> = store => {
  store.on('@init', () => ({
    [GARDA_REPORTS_STATE_KEY]: INITIAL_STATE,
  }))

  store.on('gardaReports/set', (state, variables) => {
    const currentState = state[GARDA_REPORTS_STATE_KEY]
    const { kind, reportId } = variables

    const nextState = merge({}, currentState, {
      [kind]: {
        [reportId]: variables,
      },
    })

    if (isEqual(currentState, nextState)) return null

    return { [GARDA_REPORTS_STATE_KEY]: nextState }
  })

  store.on('gardaReports/remove', (state, variables) => {
    const currentState = state[GARDA_REPORTS_STATE_KEY]
    const { kind, reportId } = variables

    if (!currentState[kind] || !currentState[kind][reportId]) return null

    delete currentState[kind][reportId]

    if (Object.keys(currentState[kind]).length === 0) delete currentState[kind]

    return { [GARDA_REPORTS_STATE_KEY]: { ...currentState } }
  })

  store.on('gardaReports/update', (state, variables) => {
    const currentState = state[GARDA_REPORTS_STATE_KEY]
    const { kind, reportId } = variables

    const nextState = merge({}, currentState, {
      [kind]: {
        [reportId]: variables,
      },
    })

    if (isEqual(currentState, nextState)) return null

    return { [GARDA_REPORTS_STATE_KEY]: nextState }
  })
}

import { captureException } from '@sentry/browser'
import normalize from 'json-api-normalizer'
import qs from 'qs'
import Request from 'superagent'

import forOwn from 'lodash/forOwn'
import noop from 'lodash/noop'

import { api, IS_PRODUCTION } from 'constants/config'

import { AuthService } from 'services/Auth'

const sendMethod = HTTPMethod =>
  HTTPMethod === 'post' ||
  HTTPMethod === 'put' ||
  HTTPMethod === 'patch' ||
  HTTPMethod === 'delete'
    ? 'send'
    : 'query'

const sendArguments = (HTTPMethod, query) =>
  HTTPMethod === 'post' ||
  HTTPMethod === 'put' ||
  HTTPMethod === 'patch' ||
  HTTPMethod === 'delete'
    ? query
    : qs.stringify(query, { arrayFormat: 'brackets' })

const defaultOptions = {
  url: api?.rubyUrl,
  endpoint: '',
  method: 'GET',
  query: {},
  headers: {},
  file: null,
  fileFieldName: 'content',
  fileParams: null,
  isRaw: false,
  withoutAuthorization: false,
  onProgress: noop,
}

const absoluteUrl = new RegExp('^(?:[a-z]+:)?//', 'i')

/**
 * @description TODO: refactor, move to API/services and mark as depricated
 */
export default async function apiCall(options) {
  const agent = Request.agent()
  const {
    url,
    endpoint,
    method,
    file,
    fileFieldName,
    fileParams,
    query,
    headers,
    payload,
    isRaw,
    withoutAuthorization,
    onProgress,
  } = { ...defaultOptions, ...options }

  const allHeaders = {
    ...headers,
    'cluster-id': (await AuthService.getAuthHeaders())['cluster-id'],
  }

  if (!withoutAuthorization) {
    allHeaders.authorization = (
      await AuthService.getAuthHeaders()
    ).Authorization
  }

  const HTTPMethod = method.toLowerCase()

  const fullUrl = absoluteUrl.test(endpoint) ? endpoint : url + endpoint

  const request = agent[HTTPMethod](fullUrl)

  if (file) {
    request.attach(fileFieldName, file)
    request.query(fileParams)

    forOwn(query, (value, key) => {
      request.field(key, value)
    })
  } else {
    request[sendMethod(HTTPMethod)](sendArguments(HTTPMethod, query))
  }

  return new Promise(resolve => {
    request
      .set(allHeaders)
      .on('progress', onProgress)
      .end((error, req) => {
        if (IS_PRODUCTION) {
          captureException(error) // Sentry exception capture
        }

        let body = req?.body
        const meta = {
          endpoint,
        }

        if (payload) {
          body = { ...body, payload }
        }

        if (error) {
          const failureData = {
            ok: false,
            meta,
            payload,
            error: req?.body?.errors ?? [],
            req,
          }
          resolve(failureData)
        } else {
          const rawBodyData = body?.data ?? { ...body }
          let normalized = null

          // json-api-normalizer requires non-null body as argument
          if (body) {
            normalized = !isRaw
              ? normalize(body, {
                  endpoint,
                  camelizeKeys: true,
                })
              : rawBodyData
          }

          const successData = {
            ok: true,
            meta,
            isRaw,
            payload: { ...payload, data: normalized },
          }

          resolve(successData)
        }
      })
  })
}

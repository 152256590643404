import React, { useState } from 'react'
import { InputActionMeta } from 'react-select'

import { API } from 'API'
import { SelectOption } from 'Types/common'

import { Select } from 'components/ui/__v3__'

import { createDebounce } from 'helpers/debounce'

import { i18n } from 'i18n'

import { CustomLeaveDayPickerProps } from './types'

export function CustomLeaveDayPicker({
  onSelect,
  value,
  isClearable,
  isDisabled,
  loadingText,
  placeholder,
  ...rest
}: CustomLeaveDayPickerProps) {
  const [search, setSearch] = useState('')
  const {
    customLeaveDays,
    customLeaveDaysLoading,
    customLeaveDaysEnableQuery,
  } = API.CustomLeaveDay.picker(search)

  const options: SelectOption[] = customLeaveDays.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  const handleInputChange = createDebounce(
    (inputValue: string, { action }: InputActionMeta) => {
      if (action === 'input-change' || action === 'set-value') {
        setSearch(inputValue)
      }
    },
  )

  const handleChange = (
    option: SelectOption<string>[] & SelectOption<string>,
  ) => {
    onSelect(option)
  }

  const renderLoadingMessage = () => {
    return loadingText ?? `${i18n('common.loading')}...`
  }

  return (
    <Select
      // @ts-ignore
      closeMenuOnSelect
      filterOption={null}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={customLeaveDaysLoading}
      isSearchable
      loadingMessage={renderLoadingMessage}
      options={options}
      placeholder={placeholder}
      value={value}
      withPortal
      onChange={handleChange}
      onInputChange={handleInputChange}
      onMenuClose={() => customLeaveDaysEnableQuery(false)}
      onMenuOpen={() => customLeaveDaysEnableQuery(true)}
      {...rest}
    />
  )
}

import { createSelector } from 'reselect'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { getCurrentWeek } from 'helpers/date'

import { getBranchSettings } from 'store/selectors/viewer'

export const getState = state => state.employeeApp.schedules

export const getWeek = createSelector(
  getState,
  getBranchSettings,
  (schedules, branchSettings) => {
    const workStartWeekDay = get(branchSettings, 'workStartWeekDay', 1)

    return !isEmpty(branchSettings) && !schedules.isWeekChangeLoader
      ? getCurrentWeek(workStartWeekDay)
      : schedules.week
  },
)

export const getFromId = createSelector(getState, state => state.fromId)

export const getDepartmentOption = createSelector(
  getState,
  state => state.departmentOption,
)

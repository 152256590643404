import { useApolloMutation } from 'API/services/Apollo'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { RULESET_ERRORS_I18N } from './errors'
import { UpdateRulesetMutation } from './GraphQL'

export function useUpdateRuleset() {
  const t = useI18n<typeof TRANSLATIONS.ruleset.toasts.update>(
    'ruleset.toasts.update',
  )

  const [innerUpdate, { loading: updating }] = useApolloMutation<
    MutationData<'updateRuleset'>,
    Gateway.MutationUpdateRulesetArgs
  >(UpdateRulesetMutation, {
    update: cache => {
      cache.evict({ fieldName: 'ruleset' })
      cache.evict({ fieldName: 'rulesByCursor' })

      cache.gc()
    },
    silent: true,
    // TODO: Temporary solution, translated errors should come from Gateway
    onError: error => {
      const isUniqueCodeError = error.message.includes(
        RULESET_ERRORS_I18N.uniqueCode.message,
      )

      showToast({
        type: 'error',
        title: t('error.title'),
        content: isUniqueCodeError
          ? RULESET_ERRORS_I18N.uniqueCode.translation
          : t('error.message'),
      })
    },
    onCompleted: () => {
      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const updateRuleset = async (input: Gateway.UpdateRulesetInput) =>
    innerUpdate({
      variables: {
        input,
      },
    })

  return { updateRuleset, updating }
}

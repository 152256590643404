import React, { useMemo, useState } from 'react'

import { debounce } from 'lodash'

import { DayPicker } from 'components/ui/__v2__/DayPicker'

import { FilterOperator } from 'constants/pagination'

import { isoToJsDate } from 'helpers/date'

import { TableFilterProps } from './types'

import { SEARCH_DEBOUNCE_TIMEOUT } from '../../constants'

export function DateFilter({
  fieldName: field,
  filterOperator,
  pagination: { handleFilterChange = () => null, options },
}: TableFilterProps) {
  const operator = filterOperator ?? FilterOperator.Equal
  const filter = options?.filter
  const lastFieldUpdated = options?.lastFieldUpdated
  const [inputValue, setInputValue] = useState(
    filter?.[field]?.[operator] ?? '',
  )

  const handleSearchChange = useMemo(() => {
    return debounce(
      value =>
        handleFilterChange({
          field,
          operator,
          value,
          lastFieldUpdated: String(field),
        }),
      SEARCH_DEBOUNCE_TIMEOUT,
    )
  }, [field, handleFilterChange, operator])

  const handleChange = (value: string | null) => {
    setInputValue(value)
    handleSearchChange(value)
  }

  return (
    <DayPicker
      autoFocus={field === lastFieldUpdated}
      dayOnly
      fontWeight="normal"
      from={isoToJsDate(inputValue)}
      width={138}
      withInput
      onChange={handleChange}
    />
  )
}

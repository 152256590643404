import { createReducer } from 'helpers/redux'

import { UPDATE_PAUSES } from 'store/actions/employees/shifts'

const initialState = {}

const handlers = {
  [UPDATE_PAUSES.SUCCESS]: (state, { payload }) => {
    return { ...state, ...payload.data.pauses }
  },
}

export default createReducer(initialState, handlers)

import { createRelationDeleteHandler } from 'redux-json-api-handlers'

import { dailySalesRelationAddHandler } from 'constants/dailySales'

import { createReducer, createRelationAddHandler } from 'helpers/redux'

import {
  CREATE_DEPARTMENT_BUDGET,
  CREATE_LABOR_COST,
  CREATE_SALE_AMOUNT,
  DELETE_LABOR_COST,
  DELETE_SALE_AMOUNT,
} from 'store/actions/company/departments'
import { ADD_JOB, DELETE_JOB } from 'store/actions/company/jobs'

const initialState = {}

const handlers = {
  [ADD_JOB.SUCCESS]: createRelationAddHandler('jobs', 'department'),
  [DELETE_JOB.SUCCESS]: createRelationDeleteHandler('jobs', 'department'),
  [CREATE_DEPARTMENT_BUDGET.SUCCESS]: dailySalesRelationAddHandler(
    'departmentBudgets',
    'budgets',
  ),
  [CREATE_LABOR_COST.SUCCESS]: dailySalesRelationAddHandler(
    'departmentLaborCosts',
    'laborCosts',
  ),
  [CREATE_SALE_AMOUNT.SUCCESS]: dailySalesRelationAddHandler(
    'departmentSales',
    'sales',
  ),
  [DELETE_SALE_AMOUNT.SUCCESS]: createRelationDeleteHandler('sales'),
  [DELETE_LABOR_COST.SUCCESS]: createRelationDeleteHandler('laborCosts'),
}

export default createReducer(initialState, handlers)

import React from 'react'
import { useForm, useFormState } from 'react-final-form'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { getNextAreasValue } from 'components/blocks/AreasPicker/helper/change'
import { IconError } from 'components/ui/__v3__/Input/Errors'

import { ValidationError } from './styles'

function createFormPicker(PickerComponent) {
  function FormPicker({ input, meta, onBlur, ...rest }) {
    const { values } = useFormState()
    const { value, name } = input
    const { change } = useForm()

    function areasProcessing(option) {
      const nextValue = getNextAreasValue({
        previousValue: values,
        newItems: option,
        changedItemType: rest.changedItemType,
        cascade: rest.cascade,
      })
      const existingFormNames = Object.keys(values)

      existingFormNames.forEach(formName => {
        if (nextValue[formName]) {
          // change areas that have changed.
          change(formName, nextValue[formName])
        } else {
          // remove areas that have disappeared due to dependencies
          change(formName, null)
        }
      })

      // if it's a new area
      if (!existingFormNames.includes(name)) {
        change(name, nextValue[name])
      }
    }

    const handleChange = option => {
      const isNotChangeWhenMultiOrChangeWithoutMulti =
        (option?.type !== 'change' && rest.isMulti) || !rest.isMulti

      if (isNotChangeWhenMultiOrChangeWithoutMulti && rest.cascade) {
        areasProcessing(option)
      } else if (isNotChangeWhenMultiOrChangeWithoutMulti) {
        change(name, option)
      }

      if (onBlur) {
        onBlur()
      }
    }

    return (
      <PickerComponent
        errored={meta.error && meta.touched}
        {...input}
        helper={
          meta.error &&
          meta.touched && (
            <ValidationError>
              <IconError> {meta.error}</IconError>
            </ValidationError>
          )
        }
        value={value || null}
        onChange={handleChange}
        {...rest}
      />
    )
  }

  FormPicker.defaultProps = {
    onBlur: noop,
  }

  FormPicker.propTypes = {
    ...PickerComponent.propTypes,
    input: PropTypes.object.isRequired,
    onBlur: PropTypes.func,
  }

  return FormPicker
}

export default createFormPicker

import styled from 'styled-components'

export const PaginationContainer = styled.div`
  height: 64px;
  padding: 0 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  justify-self: end;

  border-top: 1px solid ${({ theme }) => theme.layout.border};
`

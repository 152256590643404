import React, { useLayoutEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { scrollbarWidth as getScrollbarWidth } from '@xobotyi/scrollbar-width'

import DataTable, { SortIcon } from './components/DataTable'
import * as Icons from './components/Icons'
import PaginationPlugin from './components/Pagination'
import SearchPlugin from './components/Search'
import TableContext from './context'
import { NoDataWrapper, ProgressComponent } from './styles'

const DEFAULT_PAGINATION = [10, 25, 50, 100]

function TableWrapper({
  actionable,
  searchable,
  data,
  selectableRows,
  className,
  ...rest
}) {
  const scrollbarWidth = useMemo(() => getScrollbarWidth(), [])

  useLayoutEffect(() => {
    /**
     * The table head column of actions (last one) is empty when using 'actions'.
     * It leads to an issue with flexbox, where space is not spread properly.
     * The only wait is to always sync width of 'actions' cell with 'actions' head column.
     */

    /**
     * Upd 2021-07-05
     * In order to support more than one actionable table in single page the sync has to be table-wise.
     * Not sure if using className to identify tables is the best way, but seems safe enough.
     */
    const root = className
      ? document.querySelector(`.${className}`)
      : window.document

    if (actionable) {
      const cell = root.querySelector('.rdt_TableCell:last-of-type')
      const col = root?.querySelector('.rdt_TableCol:last-of-type')

      if (col && cell) {
        col.style.width = `${cell.offsetWidth}px`
      }
    }

    if (searchable && selectableRows) {
      const cell = root.querySelector('.rdt_TableCell:first-of-type')
      const checkbox = cell?.querySelector('input[type="checkbox"]')
      checkbox?.remove()
    }
  })

  return (
    <DataTable
      actionable={actionable}
      className={className}
      data={data}
      scrollbarWidth={scrollbarWidth}
      searchable={searchable}
      selectableRows={selectableRows}
      {...rest}
      noDataComponent={<NoDataWrapper />}
      paginationComponent={PaginationPlugin}
      progressComponent={<ProgressComponent />}
    />
  )
}

TableWrapper.Icons = Icons
TableWrapper.Context = TableContext
TableWrapper.Plugins = {
  Search: SearchPlugin,
}

TableWrapper.propTypes = {
  ...DataTable.propTypes,
  className: PropTypes.string,
  noHeader: PropTypes.bool,
  overflowX: PropTypes.bool,
  paginationRowsPerPageOptions: PropTypes.array,
  responsive: PropTypes.bool,
  sortIcon: PropTypes.node,
}

TableWrapper.defaultProps = {
  className: null,
  noHeader: true,
  overflowX: false,
  paginationRowsPerPageOptions: DEFAULT_PAGINATION,
  responsive: false,
  sortIcon: <SortIcon />,
}

export default TableWrapper

import { DateTime, Interval } from 'luxon'
import { CustomLeave } from 'Types/app'

import { DatesListItem } from 'components/blocks/__v3__/HookForm/FormSelectedDatesList'

export function currentLeaveCoversNewEffectiveDates(
  leaveEffectiveRanges: CustomLeave['effectiveDates'],
  newEffectivePeriod: { from: string; to: string },
): boolean {
  if (!leaveEffectiveRanges) return true
  const newInterval = Interval.fromDateTimes(
    DateTime.fromISO(newEffectivePeriod.from),
    DateTime.fromISO(newEffectivePeriod.to),
  )
  return Boolean(
    leaveEffectiveRanges.find(effectiveDateRange => {
      const { start, end } = effectiveDateRange
      const startDatetime = start
        ? DateTime.fromISO(start)
        : DateTime.local().minus({ years: 20 })

      const endDateTime = end
        ? DateTime.fromISO(end)
        : DateTime.local().plus({ years: 20 })

      const rangeInterval = Interval.fromDateTimes(startDatetime, endDateTime)

      return rangeInterval.engulfs(newInterval)
    }),
  )
}

export function excludedDates(date: DatesListItem) {
  return date.value !== true
}

export function includedDates(date: DatesListItem) {
  return date.value === true
}

export function getTimeoffStartDate(
  partial: boolean,
  date: string,
  startTime: number,
  periodFrom: string,
) {
  return partial
    ? DateTime.fromISO(date).plus({ seconds: startTime })
    : DateTime.fromISO(periodFrom)
}

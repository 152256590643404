import CancelCircle from './CancelCircle'
import CheckRound from './CheckRound'
import Cloud from './Cloud'
import CrossRound from './CrossRound'
import Edit from './Edit'
import File from './File'
import FileText from './FileText'
import Holiday from './Holiday'
import LocationPoint from './LocationPoint'
import PlusRound from './PlusRound'
import Prayer from './Prayer'
import Tool from './Tool'
import Trash from './Trash'

export const Icons = {
  Holiday,
  Tool,
  Edit,
  FileText,
  CrossRound,
  CheckRound,
  File,
  LocationPoint,
  PlusRound,
  Trash,
  Cloud,
  Prayer,
  CancelCircle,
}

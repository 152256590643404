import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import {
  EmploymentLevelsByCursorQuery,
  UnarchiveEmploymentLevelMutation,
} from './GraphQL'

export function useUnarchiveEmploymentLevel() {
  const [innerUnarchive, { loading: unarchiving }] = useApolloMutation<
    MutationData<'unarchiveEmploymentLevel'>,
    Gateway.MutationUnarchiveEmploymentLevelArgs
  >(UnarchiveEmploymentLevelMutation, {
    refetchQueries: compact([getOperationName(EmploymentLevelsByCursorQuery)]),
  })

  const unarchiveEmploymentLevel = async (id: string) =>
    innerUnarchive({
      variables: {
        id,
      },
    })

  return { unarchiveEmploymentLevel, unarchiving }
}

import React from 'react'
import PropTypes from 'prop-types'

import { FiX } from 'react-icons/fi'

import { Button } from 'components/ui/__v2__/Button'
import { CornerIcon } from 'components/ui/CornerIcon'
import { ModalWindow } from 'components/ui/ModalWindow'

import _ from 'i18n'

import {
  Container,
  Content,
  ContentTitle,
  HeaderDivider,
  ModalTitle,
} from './styles'

const SettingsModal = ({ subTitle, onClose, onSubmit, children }) => (
  <ModalWindow callback={onClose}>
    <Container>
      <CornerIcon onClick={onClose}>
        <FiX size={24} />
      </CornerIcon>
      <ModalTitle>{_('settings.title')}</ModalTitle>
      <HeaderDivider />
      <Content>
        <ContentTitle>{subTitle}</ContentTitle>
        {children}
      </Content>
      <Button onClick={onSubmit}>{_('actions.save')}</Button>
    </Container>
  </ModalWindow>
)

SettingsModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  subTitle: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default SettingsModal

import styled from 'styled-components'
import { color, fontSize, space, width } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from '../Grid'

const CornerIcon = styled(Flex)`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  border-radius: 6px;
  transition: all ${themeGet('transitionTime')};
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;

  ${space};
  ${width};
  ${fontSize};
  ${color};

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

export default CornerIcon

import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { either, propGet } from 'components/ui/__v3__/__utils__/styled-system'

import { gap, GapProps } from 'theme/styles'

export type BoxProps = BackgroundProps &
  BorderProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  ShadowProps &
  SpaceProps &
  GapProps &
  TypographyProps

export const Box = styled.div<BoxProps>`
  box-sizing: border-box;
  color: ${mapToTheme('layout')};
  cursor: ${propGet('cursor', 'unset')};

  ${space};
  ${color};
  ${typography};
  ${layout};
  ${background};
  ${border};
  ${position};
  ${shadow};
  ${flexbox};
  ${grid};
  ${gap};
`

type SpanProps = BoxProps & {
  inline?: boolean
  as?: string
}

export const Span = styled(Box)<SpanProps>`
  display: ${either('inline', 'inline', 'inline-block')};
`

Span.propTypes = {
  ...Box.propTypes,
}

Span.defaultProps = {
  as: 'span',
}

export const Flex = styled(Box)``

Flex.defaultProps = {
  display: 'flex',
}

Flex.propTypes = {
  ...Box.propTypes,
}

export const Grid = styled(Box)``

Grid.defaultProps = {
  display: 'grid',
}

Grid.propTypes = {
  ...Box.propTypes,
}

type SpacedFlexProps = BoxProps & {
  space?: number
}

const spacedColumnMargin = ({ space = 2 }: SpacedFlexProps) =>
  themeGet(`space.${space}`)
export const SpacedColumn = styled(Flex)<SpacedFlexProps>`
  flex-direction: column;

  & > *:not(:first-child) {
    margin-top: ${spacedColumnMargin}px;
  }
`

const spacedRowMargin = ({ space = 2 }: SpacedFlexProps) =>
  themeGet(`space.${space}`)
export const SpacedRow = styled(Flex)<SpacedFlexProps>`
  flex-direction: row;

  & > *:not(:last-child) {
    margin-right: ${spacedRowMargin}px;
  }
`

export interface Constraints {
  dailyShiftLimit?: number
  minRestTime?: number
  minOvernightRestTime?: number
  availabilityAsHardConstraint: boolean
}

export interface LaunchAutoAssignParams {
  attributes: {
    strategy: 'assign'
    startDate: string
    endDate: string
    solveDuration: number
    constraints: Constraints
    filter?: {
      job_id?: { in: number[] }
      shift_tag_ids?: { [x: string]: number[] }
      positions?: string
      time_bucket_id?: {
        in_or_descendant_of: string[]
      }
    }
  }
  relationships: {
    branch: {
      data: {
        type: 'branches'
        id: number
      }
    }
    department: {
      data: {
        type: 'departments'
        id?: number
      }
    }
  }
}

export type AutoAssignQueryResult = {
  state: AutoAssignStatus
  result: 'success' | 'failure'
  failureReason?: string
}

export enum AutoAssignStatus {
  Pending = 'pending',
  InProgress = 'in_progress',
  Finished = 'finished',
}

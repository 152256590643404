import { useQuery } from '@tanstack/react-query'
import { STALE_TIME } from 'API/services/JsonApi'
import { getOffsetPageMeta } from 'API/services/utils'

import { useAppContext } from 'hooks'

import { StaffQueryKey } from './constant'
import { staffQuery, StaffQueryArgs } from './Json'

export function useStaffCount({ filters }: Pick<StaffQueryArgs, 'filters'>) {
  const { companyId } = useAppContext()

  const { data, isFetching, isError } = useQuery({
    queryKey: StaffQueryKey.count(undefined, filters),
    queryFn: () =>
      staffQuery({
        companyId,
        filters,
        page: { size: 1, number: 1 },
      }),
    select: (response: JsonApi.Response) => {
      return getOffsetPageMeta(response).recordCount
    },
    structuralSharing: false,
    refetchOnWindowFocus: false,
    staleTime: STALE_TIME.VERY_SLOW,
  })

  return {
    count: data ?? 0,
    isFetching,
    isError,
  }
}

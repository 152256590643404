import { createSelector } from 'reselect'

import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

import { THEME_NAMES } from 'constants/config'
import { EMPLOYEE_STATE_FILTERS } from 'constants/employees'
import { ACCESS_LEVEL, EmployeeStatuses } from 'constants/ids'
import { SUBSCRIPTION_STATUS } from 'constants/products'

import { denormalize } from 'helpers/redux'

import Utils from 'services/Utils'

import { getBranchIds } from './company/branches'
import { getData } from './data'

export const getState = state => state.viewer

export const getId = createSelector(getState, state => state.id)
export const getAsEmployee = createSelector(getState, state => state.asEmployee)

export const getViewer = createSelector(
  getData,
  getId,
  getAsEmployee,
  (data, id, asEmployee) => {
    const viewer = id ? denormalize(data, 'users', id) : {}

    viewer.currentAccessLevel = asEmployee
      ? ACCESS_LEVEL.employee
      : viewer.accessLevel

    return viewer
  },
)

/** @deprecated */
export const getViewerBranchIds = createSelector(
  getData,
  getViewer,
  getBranchIds,
  (__, viewer, branchIds) => {
    switch (viewer.currentAccessLevel) {
      case ACCESS_LEVEL.owner:
      case ACCESS_LEVEL.admin:
        return branchIds
      case ACCESS_LEVEL.manager:
        return Utils.Viewer.getManagerBranchIds(viewer)
      case ACCESS_LEVEL.employee:
        return Utils.Viewer.getEmployeeBranchIds(viewer)
      default:
        return []
    }
  },
)

export const getViewerBranches = createSelector(
  getData,
  getViewerBranchIds,
  (data, branchIds) => {
    return !isEmpty(branchIds)
      ? denormalize(data, 'branches', branchIds.sort())
      : []
  },
)

/** @deprecated */
export const getActiveBranchId = createSelector(
  getState,
  getViewerBranchIds,
  (state, viewerBranchIds) => {
    return state.branchId ?? viewerBranchIds?.[0]
  },
)

/** @deprecated */
export const getBranchId = getActiveBranchId

export const getCompanyId = createSelector(getState, state => state.companyId)
/** @deprecated */
export const getCompany = createSelector(
  getData,
  getCompanyId,
  (data, companyId) =>
    companyId ? denormalize(data, 'companies', companyId) : {},
)

export const getBranch = createSelector(
  getData,
  getBranchId,
  (data, branchId) => (branchId ? denormalize(data, 'branches', branchId) : {}),
)

export const getBranchSettings = createSelector(getBranch, branch =>
  get(branch, 'settings', {}),
)

export const getBranchTimezone = createSelector(getBranchSettings, settings =>
  get(settings, 'timezone', 'local'),
)

export const getBranchImages = createSelector(
  getBranch,
  branch => branch.images,
)

export const getAccessLevel = createSelector(getViewer, viewer =>
  get(viewer, 'accessLevel'),
)

export const getIsLoaded = createSelector(getState, state => state.isLoaded)
export const getIsBranchSwitching = createSelector(
  getState,
  state => state.isBranchSwitching,
)

export const getProducts = createSelector(getCompany, company => {
  const products = []

  forEach(get(company, 'subscriptions', []), subscription => {
    if (
      subscription.status === SUBSCRIPTION_STATUS.trialing ||
      subscription.status === SUBSCRIPTION_STATUS.active
    ) {
      products.push(...subscription.products)
    }
  })
  return map(products, 'name')
})

export const getUserSettings = createSelector(getViewer, viewer =>
  viewer ? viewer.settings : {},
)

export const getThemeName = createSelector(getUserSettings, settings =>
  get(settings, 'theme', THEME_NAMES.main),
)

export const getIsBranchManager = createSelector(getViewer, viewer => {
  return !isEmpty(viewer?.managers?.[0]?.allManagedBranches)
})

export const getIsDepartmentManager = createSelector(
  getViewer,
  viewer => !isEmpty(viewer?.managers?.[0]?.allManagedDepartments),
)
/** @deprecated */
export const getIsOwnerAdmin = createSelector(
  getViewer,
  viewer =>
    viewer.accessLevel === ACCESS_LEVEL.owner ||
    viewer.accessLevel === ACCESS_LEVEL.admin,
)

export const getIsEnableClockInGeolocationFromDesktop = createSelector(
  getBranchSettings,
  branchSettings => get(branchSettings, 'clockInGeolocationFromDesktop', true),
)

/** @deprecated */
export const getViewerEmployee = createSelector(
  getViewer,
  getBranchId,
  (viewer, branchId) => {
    const viewerEmployees = get(viewer, 'employees', [])
    return find(
      viewerEmployees,
      employee => get(employee, ['branch', 'id']) === branchId,
    )
  },
)

export const getViewerDepartments = createSelector(getViewer, viewer =>
  get(viewer, 'managers[0].departments'),
)

export const getViewerEmployeeId = createSelector(
  getViewerEmployee,
  viewerEmployee => get(viewerEmployee, 'id', null),
)

export const getViewerEmployeeJobs = createSelector(
  getViewerEmployee,
  viewerEmployee => get(viewerEmployee, 'jobs', []),
)

export const getViewerEmployeeJobIds = createSelector(
  getViewerEmployeeJobs,
  jobs => map(jobs, 'id'),
)

export const getBranchSettingsBudget = createSelector(
  getBranchSettings,
  branchSettings => get(branchSettings, 'budget'),
)

export const getIsShowLostAccessModal = createSelector(
  getState,
  state => state.isShowLostAccessModal,
)

export const getIsMaskShiftEndTime = createSelector(
  getBranchSettings,
  branchSettings => get(branchSettings, 'maskShiftEndTime', false),
)

export const getIsViewerArchivedInActiveBranch = createSelector(
  getBranchId,
  getViewer,
  (branchId, viewer) => {
    const viewerAccessLevel = get(viewer, 'accessLevel', null)
    if (viewerAccessLevel === ACCESS_LEVEL.employee) {
      const viewerEmployees = get(viewer, 'employees', [])
      const branchEmployee = find(viewerEmployees, employee => {
        const employeeBranchId = get(employee, 'branch.id', null)
        return employeeBranchId === branchId
      })
      const branchEmployeeState = get(branchEmployee, 'state', null)
      return (
        !isEmpty(branchEmployee) &&
        branchEmployeeState === EMPLOYEE_STATE_FILTERS.archived
      )
    }
    if (viewerAccessLevel === ACCESS_LEVEL.manager) {
      // Only mutants can be archived
      const employee = Utils.Viewer.getViewerEmployee(viewer)
      return employee?.state === EmployeeStatuses.Archived
    }
    return false
  },
)

export const getViewerArchivedBranchIds = createSelector(getViewer, viewer => {
  const viewerAccessLevel = get(viewer, 'accessLevel', null)
  if (viewerAccessLevel === ACCESS_LEVEL.employee) {
    const viewerEmployees = get(viewer, 'employees', [])

    return reduce(
      viewerEmployees,
      (ids, employee) => {
        const employeeState = get(employee, 'state', null)
        if (employeeState === EMPLOYEE_STATE_FILTERS.archived) {
          const branchId = get(employee, 'branch.id', '')
          if (branchId) {
            ids.push(branchId)
          }
        }
        return ids
      },
      [],
    )
  }
  return []
})

export const getGeneratedToken = createSelector(
  getState,
  state => state.generatedToken,
)

export const getGeneratedTokenError = createSelector(
  getState,
  state => state.generatedTokenError,
)

export const getGeneratedTokenLoading = createSelector(
  getState,
  state => state.generatedTokenLoading,
)

export const getIsAllowCreatePreferences = createSelector(
  getBranchSettings,
  branchSettings => get(branchSettings, 'allowCreatePreferences', true),
)

export const getBranchTags = createSelector(getBranch, branch =>
  get(branch, 'company.locationTags', []),
)

export const getCounters = createSelector(getState, state => state.counters)

export const getNotificationsCount = createSelector(
  getCounters,
  counters => get(counters, 'notifications') || 0,
)

import { DateTime } from 'luxon'

import { fromPairs, omit, toPairs } from 'lodash'

import { DataByState } from 'pages/EmployerApp/Experimental/DemandForecasting/types'

import { DemandForecastingStoreData } from './DemandForecasting'

export function applicationToStoreOn(
  applicationData: DataByState,
): DemandForecastingStoreData {
  return fromPairs(
    toPairs(applicationData).map(([state, data]) => [
      state,
      data.map(item => ({
        date: item.date.toISODate(),
        ...omit(item, 'date'),
      })),
    ]),
  )
}

export function storeOnToApplication(
  storeOnData: DemandForecastingStoreData,
): DataByState {
  return fromPairs(
    toPairs(storeOnData).map(([state, data]) => [
      state,
      data.map(item => ({
        date: DateTime.fromISO(item.date),
        ...omit(item, 'date'),
      })),
    ]),
  )
}

import React from 'react'

import { LoadingMore } from 'components/ui/__v2__/LoadingMore'

import { FooterCell, FooterLoaderContainer } from './styles'

type Props = {
  loading: boolean
}

export function TableFooter({ loading }: Props) {
  if (!loading) return null

  return (
    <FooterCell>
      <FooterLoaderContainer>
        <LoadingMore />
      </FooterLoaderContainer>
    </FooterCell>
  )
}

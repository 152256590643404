import { useMemo, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { TimeEvaluationLogsByCursorQuery } from './GraphQL'
import {
  timeEvaluationLogFilterToAPI,
  timeEvaluationLogSortingToAPI,
  timeEvaluationLogToAPIITem,
} from './mappers'
import { TimeEvaluationLogFilter, TimeEvaluationLogSort } from './types'

export function useCustomCvFieldValuesByCursor({
  page,
  filter,
  sort,
}: {
  page: CursorPage
  filter: TimeEvaluationLogFilter
  sort: TimeEvaluationLogSort
}) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, loading, refetch } = useApolloQuery<
    QueryData<'timeEvaluationLogsByCursor'>,
    Gateway.QueryTimeEvaluationLogsByCursorArgs
  >(TimeEvaluationLogsByCursorQuery, {
    fetchPolicy: 'network-only',

    variables: {
      sorting: timeEvaluationLogSortingToAPI(sort),
      filter: timeEvaluationLogFilterToAPI(filter),
      paging: cursorPageToGraphqlPaging(page),
    },

    onCompleted(data) {
      const newPageMeta = data.timeEvaluationLogsByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const timeEvaluationLogs = useMemo(
    () =>
      data?.timeEvaluationLogsByCursor?.edges?.map(edge =>
        timeEvaluationLogToAPIITem(edge.node),
      ) ?? [],
    [data?.timeEvaluationLogsByCursor],
  )

  return {
    timeEvaluationLogs,
    timeEvaluationLogsLoading: loading,
    timeEvaluationLogsRefetch: refetch,
    timeEvaluationLogsPageMeta: currentPageMeta,
  }
}

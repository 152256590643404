import { createSelector } from 'reselect'

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { denormalize } from 'helpers/redux'

import { getData } from '../data'

export const getState = state => state.employeeApp.timeClock

export const getIsLoading = createSelector(getState, state => state.isLoading)

export const getIsLoaded = createSelector(getState, state => state.isLoaded)

const getSchedulesIds = createSelector(getState, state => state.schedules)

export const getSchedules = createSelector(
  getData,
  getSchedulesIds,
  (data, schedulesIds) =>
    !isEmpty(schedulesIds)
      ? denormalize(data, 'schedules', map(schedulesIds))
      : [],
)

export const getScheduleId = createSelector(getState, state => state.scheduleId)

export const getSchedule = createSelector(
  getData,
  getScheduleId,
  (data, scheduleId) =>
    !isEmpty(scheduleId) ? denormalize(data, 'schedules', scheduleId) : [],
)

export const getTimeEntryId = createSelector(getState, state => state.entryId)

export const getTimeEntry = createSelector(
  getData,
  getTimeEntryId,
  (data, timeEntryId) =>
    !isEmpty(timeEntryId) ? denormalize(data, 'timeEntries', timeEntryId) : [],
)

export const getTime = createSelector(getState, state => state.time)
export const getStartTime = createSelector(getState, state => state.startTime)
export const getEndTime = createSelector(getState, state => state.endTime)

export const getIsSyncInProgress = createSelector(
  getState,
  state => state.syncInProgress,
)

export const getIsPendingScheduledClockIn = createSelector(
  getState,
  state => state.syncPending,
)

export const getPauseTimerId = createSelector(
  getState,
  state => state.pauseTimerId,
)
export const getPauseTimer = createSelector(
  getData,
  getPauseTimerId,
  (data, pauseTimerId) =>
    !isEmpty(pauseTimerId)
      ? denormalize(data, 'pauseTimers', pauseTimerId)
      : [],
)

export const getIsLogTab = createSelector(getState, state => state.isLogTab)

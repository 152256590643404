import { flatten, isArray } from 'lodash'

export function formatErrorDetail(
  error: Record<string, string | (string | string[])[]>,
  skipErrorField?: boolean,
) {
  if (isArray(error.detail)) {
    if (skipErrorField && error.detail.length > 1) {
      const newErrorDetail = error.detail.slice(1)
      return flatten(newErrorDetail).join(' ')
    }

    return flatten(error.detail).join(' ')
  }

  return error.detail
}

import { getEntities } from 'rapidux'

import { CUSTOM_LABEL_DAYS_TYPE } from 'store/reducers/customLabelDays'

import { getData } from './data'

const getState = state => state[CUSTOM_LABEL_DAYS_TYPE]

export const getCustomLabelDays = getEntities(getState, getData, {
  type: CUSTOM_LABEL_DAYS_TYPE,
  sorted: true,
})

/* eslint-disable */
import Immutable from 'seamless-immutable'
import { createLoadHandler } from 'redux-json-api-handlers'
import { createReducerHandlers } from 'rapidux'

import { createReducer } from 'helpers/redux'

import union from 'lodash/union'

import { LOAD_TIMERS, ADD_TIMER_ID } from 'store/actions/employeeApp/timeLog'

import { CREATE_TIME_ENTRY } from 'store/actions/employees/schedules'

import { LOG_OUT } from 'store/actions/auth'

import { STOP_TIMER } from 'store/actions/employeeApp/openClock'

const initialState = Immutable({
  timeEntries: [],
  isLoading: false,
})

const handlers = {
  [LOAD_TIMERS.REQUEST]: state => state.merge({ isLoading: true }),

  [LOAD_TIMERS.SUCCESS]: createLoadHandler('timeEntries', {
    withReplace: true,
  }),

  [LOAD_TIMERS.FAILURE]: state => state.merge({ isLoading: false }),

  [STOP_TIMER.SUCCESS]: createLoadHandler('timeEntries'),

  [ADD_TIMER_ID]: (state, { payload: { timerId } }) =>
    state.update('timeEntries', timeEntries => union(timeEntries, [timerId])),

  ...createReducerHandlers('timeEntries', CREATE_TIME_ENTRY),

  [LOG_OUT]: state => state.merge(initialState),
}

export default createReducer(initialState, handlers)

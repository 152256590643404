import { createSelector } from 'reselect'

import isEmpty from 'lodash/isEmpty'

import { denormalize } from 'helpers/redux'

import { getData } from '../data'

export const getState = state => state.employeeApp.openClock

export const getNote = createSelector(getState, state => state.note)

export const getIsEmergency = createSelector(getState, state => state.emergency)

export const getSelectedTimeBucket = createSelector(
  getState,
  state => state.selectedTimeBucket,
)

export const getClockingTime = createSelector(
  getState,
  state => state.clockingTime,
)

export const getIsPendingOpenClockIn = createSelector(
  getState,
  state => state.isPendingClockIn,
)

export const getIntervalStartTime = createSelector(
  getState,
  state => state.intervalStartTime,
)

export const getIntervalEndTime = createSelector(
  getState,
  state => state.intervalEndTime,
)

export const getIsClocking = createSelector(getState, state => state.isClocking)

export const getTimeEntryId = createSelector(
  getState,
  state => state.timeEntryId,
)

export const getTimeEntry = createSelector(
  getData,
  getTimeEntryId,
  (data, timeEntryId) =>
    !isEmpty(timeEntryId) ? denormalize(data, 'timeEntries', timeEntryId) : {},
)

export const getSelectedJobId = createSelector(
  getState,
  state => state.selectedJobId,
)

export const getSelectedJobData = createSelector(
  getState,
  state => state.selectedJobData,
)

/** @deprecated */
export const getSelectedJob = createSelector(
  getData,
  getSelectedJobId,
  (data, selectedJobId) =>
    selectedJobId ? denormalize(data, 'jobs', selectedJobId) : {},
)

export const getPauseTimerId = createSelector(
  getState,
  state => state.pauseTimerId,
)

export const getPauseTimer = createSelector(
  getData,
  getPauseTimerId,
  (data, pauseTimerId) =>
    !isEmpty(pauseTimerId)
      ? denormalize(data, 'pauseTimers', pauseTimerId)
      : [],
)

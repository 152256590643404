import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { size, space } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { either } from 'components/ui/__v3__/__utils__/styled-system'
import placeholder from 'components/ui/Avatar/placeholder.png'

const AvatarHolder = styled.div`
  position: relative;
  overflow: visible !important;
  line-height: 0;

  ${space};
`

const Dot = styled.div`
  position: absolute;
  bottom: -1px;
  right: -2px;
  height: 10px;
  width: 10px;
  border: 2px solid white;
  background: ${themeGet('layout.positive')};
  border-radius: 5px;
`

const borderedCss = ({ bordered, borderColor }) =>
  bordered &&
  css`
    border: thin solid ${({ theme }) => borderColor ?? theme.layout.border};
  `

const StyledAvatar = styled.img`
  display: inline-block;
  flex-shrink: 0;
  background-color: ${themeGet('colors.ONBOARDING_CONTENT')};
  border-radius: ${either('square', 0, either('circle', '50%', '4px'))};
  position: relative;

  ${size};
  ${borderedCss}
`

StyledAvatar.propTypes = {
  circle: PropTypes.bool,
}

const Avatar = React.forwardRef(
  ({ active, small, bordered, borderColor, src, ...rest }, ref) => {
    const handleError = useCallback(event => {
      event.target.onerror = null
      event.target.src = placeholder
    }, [])

    return (
      <AvatarHolder {...rest} ref={ref}>
        <StyledAvatar
          borderColor={borderColor}
          bordered={bordered}
          size={rest.size}
          square={rest.square}
          src={src || 'invalid'}
          onError={handleError}
        />
        {active && <Dot />}
      </AvatarHolder>
    )
  },
)

Avatar.defaultProps = {
  active: false,
  borderColor: null,
  bordered: false,
  small: false,
  size: 34,
  width: null,
  height: null,
  src: null,
  circle: false,
  square: false,
}

Avatar.propTypes = {
  active: PropTypes.bool,
  borderColor: PropTypes.string,
  bordered: PropTypes.bool,
  circle: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  small: PropTypes.bool,
  square: PropTypes.bool,
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default Avatar

import { useMemo } from 'react'

import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query'
import {
  getNextCursorPageParam,
  getPrevCursorPageParam,
  transformResponseToEntities,
} from 'API/services/utils'

import { PAGE_SIZE } from 'constants/pagination'

import { TimeCardsQueryParams } from 'pages/EmployerApp/TimeCards'

import { TIME_CARD_JSON_API_TYPE, TimeCardQueryKeys } from './constants'
import { timecardsQuery, TimecardsQueryEntityReturn } from './Json'

export type UseTimeCardsByCursorArgs = {
  filters: TimeCardsQueryParams['filters']
  sort: TimeCardsQueryParams['sort']
  initialSize?: number
  increment?: number
}

/** @deprecated */
export function useTimeCardsInfinite({
  filters,
  sort,
  initialSize = PAGE_SIZE['50'],
  increment = PAGE_SIZE['50'],
}: UseTimeCardsByCursorArgs) {
  const {
    data,
    isFetching,
    error,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: TimeCardQueryKeys.infinite(sort, filters),
    queryFn: ({ pageParam = { size: initialSize } }) =>
      timecardsQuery({ filters, sort, page: pageParam }),
    getPreviousPageParam: page => getPrevCursorPageParam(page, increment),
    getNextPageParam: page => getNextCursorPageParam(page, increment),
    select: ({ pages, pageParams }: InfiniteData<JsonApi.Response>) => {
      return {
        pages: pages.map(response => {
          return transformResponseToEntities<TimecardsQueryEntityReturn>({
            response,
            jsonApiType: TIME_CARD_JSON_API_TYPE,
          }).entities
        }),
        pageParams,
      }
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    structuralSharing: false,
  })

  const flattenData = useMemo(() => data?.pages?.flatMap(page => page) ?? [], [
    data,
  ])

  return {
    timecards: flattenData,
    isFetching,
    error,
    refetch,
    hasNextPage: Boolean(hasNextPage),
    fetchNextPage,
    isFetchingNextPage,
    isRefetching,
  }
}

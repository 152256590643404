import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'

import { either, when } from 'components/ui/__v3__/__utils__/styled-system'

const Link = styled(NavLink)`
  background-color: transparent;
  text-decoration: underline;
  cursor: ${either('disabled', 'not-allowed', 'pointer')};

  color: ${mapToTheme('links.color')};

  &:focus,
  &:hover {
    color: ${mapToTheme('links.hover')};
  }

  &:active {
    color: ${mapToTheme('links.active')};
  }

  ${when(
    'disabled',
    css`
      pointer-events: none;
      cursor: not-allowed;
      color: ${mapToTheme('links.disabled')};
    `,
  )};
`

Link.propTypes = {
  disabled: PropTypes.bool,
}

Link.defaultProps = {
  disabled: false,
}

export default Link

import { useCallback, useState } from 'react'

import { ApolloQueryResult } from '@apollo/client'

import { SortingDirection } from 'constants/gatewayGraphQL'

import { FilterParams, Options, PaginationVariables, Sorting } from './types'

/** @deprecated */
export function useOffsetPagination<
  Query,
  QueryVariables extends PaginationVariables
>(
  refetch: (
    variables?: Partial<QueryVariables>,
  ) => Promise<ApolloQueryResult<Query>>,
  { paging, sorting, filter }: PaginationVariables,
  externalFilter?: boolean,
) {
  const [options, setOptions] = useState<Options>({
    paging: {
      limit: paging.limit,
      offset: paging.offset,
    },

    sorting: {
      field: sorting?.at(0)?.field ?? '',
      direction: sorting?.at(0)?.direction ?? SortingDirection.Asc,
    },

    filter,
    lastFieldUpdated: '',
  })

  const handleRefetch = useCallback(
    (queryVariables?: Partial<QueryVariables & PaginationVariables>) =>
      refetch(queryVariables),
    [refetch],
  )

  const handleUpdateOptionsAndRefetch = (newOptions: Options) => {
    setOptions(newOptions)
    // @ts-ignore TODO wait all types from server
    handleRefetch({
      paging: newOptions.paging,
      sorting: [newOptions.sorting],
      filter: externalFilter ? filter : newOptions.filter, // FIXME: Note: This is a very messy workaround to unblock external filter being passed, this must be reworked properly
    })
  }

  const handleLimitChange = (limit: number) => {
    handleUpdateOptionsAndRefetch({
      ...options,
      paging: {
        limit,
        offset: 0,
      },
    })
  }

  const handleOffsetChange = (offset: number) => {
    handleUpdateOptionsAndRefetch({
      ...options,
      paging: {
        ...options.paging,
        offset,
      },
    })
  }

  const handleSortChange = (sorting: Partial<Sorting>) => {
    handleUpdateOptionsAndRefetch({
      ...options,
      sorting: {
        ...options.sorting,
        ...sorting,
      },
      lastFieldUpdated: '',
    })
  }

  const handleFilterChange = ({
    field,
    operator,
    value,
    lastFieldUpdated,
  }: FilterParams) =>
    handleUpdateOptionsAndRefetch({
      ...options,
      filter: {
        ...options.filter,
        [field]: {
          ...(options.filter?.[field] ? options.filter[field] : {}),
          [operator]: value,
        },
      },
      lastFieldUpdated,
    })

  // TODO: refactor to use external filters
  const handleExternalFilterChange = (externalFilter: Record<string, any>) =>
    setOptions({
      ...options,
      filter: {
        ...options.filter,
        ...externalFilter,
      },
    })

  const handleFilterReset = () =>
    handleUpdateOptionsAndRefetch({
      ...options,
      filter,
      lastFieldUpdated: '',
    })

  return {
    options,

    handleLimitChange,
    handleOffsetChange,
    handleSortChange,
    handleFilterChange,
    handleFilterReset,
    handleExternalFilterChange,

    refetch,
  }
}

export type Pagination = ReturnType<typeof useOffsetPagination>

import { useApolloQuery } from 'API/services/Apollo'

import { ReportQuery } from './GraphQL'

export function useReport({
  id,
  enabled,
  pollInterval,
}: {
  id: string
  enabled?: boolean
  pollInterval?: number
}) {
  const { data, loading } = useApolloQuery<
    QueryData<'report'>,
    Gateway.QueryReportArgs
  >(ReportQuery, {
    fetchPolicy: 'network-only',
    skip: !enabled,
    pollInterval,

    variables: {
      id,
    },
  })

  return {
    report: data?.report,
    reportLoading: loading,
  }
}

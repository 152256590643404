import { useCallback, useState } from 'react'

import { isNetworkRequestInFlight } from '@apollo/client/core/networkStatus'
import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import {
  ScheduleSortingField,
  SortingDirection,
} from 'constants/gatewayGraphQL'
import { PAGE_SIZE } from 'constants/pagination'

import {
  SchedulesByShiftJobByCursorQueryData,
  ShiftsWeeklySchedulesByCursorQuery,
} from '../GraphQL'
import { ScheduleFilter } from '../types'

export function useShiftsWeeklySchedulesByCursor({
  page,
  filter,
}: {
  page: CursorPage
  filter: ScheduleFilter
}) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const {
    data,
    refetch,
    fetchMore,
    updateQuery,
    networkStatus,
  } = useApolloQuery<
    SchedulesByShiftJobByCursorQueryData,
    Gateway.QuerySchedulesByCursorArgs
  >(ShiftsWeeklySchedulesByCursorQuery, {
    variables: {
      sorting: [
        {
          field: ScheduleSortingField.Id,
          direction: SortingDirection.Desc,
        },
      ],
      filter: { shiftJobId: { eq: filter.shiftJobId } },
      paging: cursorPageToGraphqlPaging(page),
    },

    onCompleted(data) {
      const newPageMeta = data.schedulesByCursor.pageInfo

      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
    fetchPolicy: 'cache-only', // To prevent initial loading
  })

  const schedules = data?.schedulesByCursor?.edges.map(edge => edge.node) ?? []

  const schedulesLoading = isNetworkRequestInFlight(networkStatus)

  const schedulesLoadMore = useCallback(async () => {
    await fetchMore({
      variables: {
        paging: {
          limit: PAGE_SIZE[10],
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })
  }, [currentPageMeta.endCursor, fetchMore])

  return {
    schedules,
    schedulesLoading,
    schedulesRefetch: refetch,
    schedulesLoadMore,
    schedulesPageInfo: currentPageMeta,
    schedulesUpdateQuery: updateQuery,
  }
}

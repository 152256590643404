import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import { SpacedColumn } from 'components/ui/__v2__/Grid'

import { LONG_DASH } from 'constants/schedule'

import FormItem from './FormItem'

function FormSubmissionCell({ formSubmissions }) {
  if (isEmpty(formSubmissions)) return LONG_DASH

  // TODO: Maybe refactor to reuse the TaskBuilder version

  const render = useMemo(
    () =>
      formSubmissions.map(formSubmission => (
        <FormItem formSubmission={formSubmission} key={formSubmission.id} />
      )),
    [formSubmissions],
  )

  return (
    <>
      <SpacedColumn space={1}>{render}</SpacedColumn>
    </>
  )
}

FormSubmissionCell.defaultProps = {
  formSubmissions: [],
}

FormSubmissionCell.propTypes = {
  formSubmissions: PropTypes.array,
}

export default memo(FormSubmissionCell)

import React, { useMemo } from 'react'

import { ColumnDef } from '@tanstack/react-table'

import { Flex } from 'components/ui/__v2__/Grid'

import { IndeterminateCheckbox } from './components'

type Props = {
  horizontallyCentered?: boolean
}

export function Select<T>({ horizontallyCentered }: Props = {}): ColumnDef<
  T,
  any
> {
  return useMemo(
    () => ({
      id: 'select',
      header: ({ table }) => (
        <Flex
          alignItems={horizontallyCentered ? 'center' : 'flex-start'}
          height={'100%'}
        >
          <IndeterminateCheckbox
            indeterminate={table.getIsSomeRowsSelected()}
            isDisabled={
              !table.getCoreRowModel().rows.find(row => row.getCanSelect())
            }
            isSelected={table.getIsAllRowsSelected()}
            onToggle={table.getToggleAllRowsSelectedHandler()}
          />
        </Flex>
      ),
      cell: ({ row }) => (
        <Flex
          alignItems={horizontallyCentered ? 'center' : 'flex-start'}
          height={'100%'}
        >
          <IndeterminateCheckbox
            isDisabled={!row.getCanSelect()}
            isSelected={row.getIsSelected()}
            onToggle={row.getToggleSelectedHandler()}
          />
        </Flex>
      ),
    }),
    [horizontallyCentered],
  )
}

import mergeWith from 'lodash/mergeWith'
import reduce from 'lodash/reduce'
import set from 'lodash/set'

import { mergeCustomizer } from 'helpers/redux'

import branches from './branches'
import branchLaborCosts from './branchLaborCosts'
import branchSales from './branchSales'
import chats from './chats'
import comments from './comments'
import companies from './companies'
import departments from './departments'
import employees from './employees'
import jobs from './jobs'
import jobsEmployees from './jobsEmployees'
import managers from './managers'
import messages from './messages'
import pauses from './pauses'
import posts from './posts'
import schedules from './schedules'
import shifts from './shifts'
import timeEntries from './timeEntries'
import timeEntriesEmployeeSummary from './timeEntriesEmployeeSummary'
import users from './users'

const reducers = {
  branches,
  branchLaborCosts,
  branchSales,
  chats,
  comments,
  companies,
  departments,
  employees,
  jobs,
  jobsEmployees,
  managers,
  messages,
  pauses,
  posts,
  schedules,
  shifts,
  timeEntries,
  timeEntriesEmployeeSummary,

  users,
}

export default (state, action) =>
  mergeWith(
    {},
    reduce(
      reducers,
      (acc, reducer, key) => set(acc, key, reducer(state[key], action)),
      state,
    ),
    mergeCustomizer,
  )

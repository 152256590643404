import React from 'react'

import { BsPersonRaisedHand as BiddingIcon } from 'react-icons/bs'

import { Flex, SpacedRow } from 'components/ui/__v2__/Grid'
import { TextOverflow } from 'components/ui/__v2__/TextOverflow'

import { UserAccessLevels } from 'constants/ids'

import { i18n } from 'i18n'

import Utils from 'services/Utils'

import { RedirectIcon, SecondaryText } from './styles'

type Props = {
  profile: { firstName?: string; lastName?: string }
  hasBid?: boolean
  newTabUrl?: string
  accessLevel?: UserAccessLevels
}

export function FullName({ profile, hasBid, newTabUrl, accessLevel }: Props) {
  const handleClick = () =>
    newTabUrl ? Utils.Url.openInNewTab(newTabUrl) : null

  return (
    <Flex flexDirection="column">
      <SpacedRow alignItems="center" justifyContent="space-between">
        <TextOverflow>{Utils.User.buildFullName(profile)}</TextOverflow>
        {hasBid && <BiddingIcon />}
        {newTabUrl && (
          <Flex>
            <RedirectIcon onClick={handleClick} />
          </Flex>
        )}
      </SpacedRow>
      {accessLevel && (
        <SecondaryText>{i18n(`roles.${accessLevel}`)}</SecondaryText>
      )}
    </Flex>
  )
}

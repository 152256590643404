import styled from 'styled-components'

export const LogsTime = styled.div`
  font-size: 12px;
  line-height: 1.67;
`
export const LogsRow = styled.div`
  line-height: 1.43;
  padding-bottom: ${({ theme }) => `${theme.space[2]}px`};
  color: ${({ theme }) => theme.layout.gray};
  flex-shrink: 0;
`
export const Actor = styled.span`
  color: ${({ theme }) => theme.layout.text};
  font-weight: 600;
`

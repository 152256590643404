import React from 'react'
import PropTypes from 'prop-types'

import { TimeSelect } from 'components/blocks/__v2__'

function FormTimeSelect({
  input: { value, onChange, onBlur },
  meta,
  disabled,
  ...rest
}) {
  return (
    <TimeSelect
      isDisabled={disabled}
      maxWidth={115}
      p={1}
      value={value || 0}
      onBlur={onBlur}
      onChange={onChange}
      {...rest}
    />
  )
}

export default FormTimeSelect

FormTimeSelect.defaultProps = {
  disabled: false,
}

FormTimeSelect.propTypes = {
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

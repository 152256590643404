import React from 'react'

import { TimeType } from 'Types/schedule'

import { Flex } from 'components/ui/__v2__/Grid'

import { i18n } from 'i18n'

import Utils from 'services/Utils'

import { Color } from './styles'

import { BadgeContainer } from '../components/Badge/styles'
import { Cell } from '../styles'

interface Props {
  timetypes: Gateway.Timetype[] | TimeType[]
}

export function Timetypes({ timetypes }: Props) {
  if (timetypes.length === 0) {
    return <span>{i18n('table.cells.timetypes.empty')}</span>
  }

  return (
    <Cell>
      {timetypes.map(({ id, name, color }) => (
        <BadgeContainer key={id}>
          <Flex alignItems="center">
            <Color color={Utils.Color.hexToColorOption(color).value} />
            {name}
          </Flex>
        </BadgeContainer>
      ))}
    </Cell>
  )
}

import { useCallback, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import {
  TimeBucketChildrenByCursorQuery,
  TimeBucketChildrenByCursorQueryData,
} from './GraphQL'
import { TimeBucketChildFilter } from './types'

export function useTimeBucketChildrenByCursor({
  page,
  filter,
  sort,
  enabled = true,
}: {
  page: CursorPage
  filter: TimeBucketChildFilter
  sort: Gateway.TimeBucketSorting
  enabled?: boolean
}) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, loading, refetch, fetchMore } = useApolloQuery<
    TimeBucketChildrenByCursorQueryData,
    Gateway.QueryTimeBucketChildrenByCursorArgs
  >(TimeBucketChildrenByCursorQuery, {
    fetchPolicy: 'network-only',
    skip: !enabled,

    variables: {
      sorting: sort,
      filter: {
        parentId: { eq: filter.parentId },
        ...(!filter.seeArchived && { archived: { eq: false } }),
        ...(filter.search && { search: { ilike: filter.search } }),
      },
      paging: cursorPageToGraphqlPaging(page),
    },

    onCompleted(data) {
      const newPageMeta = data.timeBucketChildrenByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const timeBucketChildren =
    data?.timeBucketChildrenByCursor?.edges.map(({ node }) => node) ?? []

  const timeBucketChildrenFetchMore = useCallback(async () => {
    await fetchMore({
      variables: {
        paging: {
          limit: page.size,
          startingAfter: currentPageMeta.endCursor,
        },
      },
    })
  }, [currentPageMeta.endCursor, fetchMore, page.size])

  return {
    timeBucketChildren,
    timeBucketChildrenLoading: loading,
    timeBucketChildrenRefetch: refetch,
    timeBucketChildrenFetchMore,
    timeBucketChildrenPageMeta: currentPageMeta,
  }
}

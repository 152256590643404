import { MdClose } from 'react-icons/md'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { either } from 'components/ui/__v3__/__utils__/styled-system'

const CloseIcon = styled(MdClose).attrs({ size: 22 })`
  color: ${either(
    'disabled',
    themeGet('layout.disabled'),
    themeGet('layout.negative'),
  )};
`

export default CloseIcon

import { getOperationName } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import compact from 'lodash/compact'

import {
  TimeBucketChildrenByCursorQuery,
  TimeBucketParentsByCursorQuery,
  UnarchiveTimeBucketMutation,
} from './GraphQL'

export function useUnarchiveTimeBucket() {
  const [unarchive, { loading: unarchiving }] = useApolloMutation<
    MutationData<'unarchiveTimeBucket'>,
    Gateway.MutationUnarchiveTimeBucketArgs
  >(UnarchiveTimeBucketMutation, {
    // TODO: split messages for parent and child
    translationKey: 'settings.timeBuckets.toasts.unarchive',
  })

  const unarchiveTimeBucket = async (
    id: Gateway.MutationUnarchiveTimeBucketArgs['id'],
    child?: boolean,
  ) => {
    return unarchive({
      variables: {
        id,
      },
      refetchQueries: compact([
        getOperationName(
          child
            ? TimeBucketChildrenByCursorQuery
            : TimeBucketParentsByCursorQuery,
        ),
      ]),
    })
  }

  return { unarchiveTimeBucket, unarchiving }
}

import { useForm, useWatch } from 'react-hook-form'

import { vestResolver } from '@hookform/resolvers/vest'

import { useDebouncedValue } from 'hooks'

import { validator } from './validators'

import { CandidatesFilterFormState } from '../types'

export function useCandidateFiltersForm() {
  const { control } = useForm<CandidatesFilterFormState>({
    mode: 'all',
    resolver: vestResolver(validator),
    defaultValues: {
      customCvFields: [{ field: undefined, value: undefined }],
    },
  })

  const watchedCustomCvFields = useWatch({
    control,
    name: 'customCvFields',
  })

  const customCvFieldsFilter = useDebouncedValue(watchedCustomCvFields)

  return { customCvFieldsFilter, control }
}

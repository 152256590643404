import React, { ChangeEvent, useCallback } from 'react'

import { Flex, SpacedColumn, Span } from 'components/ui/__v2__/Grid'
import { Radio } from 'components/ui/__v2__/Radio'
import { InputLabel } from 'components/ui/__v3__/Input'

import { LeaveDurationTypes } from 'constants/ids'

import { i18n } from 'i18n'

export type LeaveDurationTypeSelectorArgs = {
  partial: boolean
  name: string
  onChange: (partial: boolean) => void
}

export function LeaveDurationTypeSelector({
  partial,
  name,
  onChange,
}: LeaveDurationTypeSelectorArgs) {
  const optionEnums = [LeaveDurationTypes.WholeDay, LeaveDurationTypes.Partial]

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value as LeaveDurationTypes

      onChange(value === LeaveDurationTypes.Partial)
    },
    [onChange],
  )

  const value = partial
    ? LeaveDurationTypes.Partial
    : LeaveDurationTypes.WholeDay

  return (
    <Flex flexDirection="column" minWidth="140px">
      <InputLabel>{i18n('leaveDurationTypes.description')}</InputLabel>
      <SpacedColumn>
        {optionEnums.map(optionEnum => (
          <Flex alignItems="center" as="label" key={optionEnum}>
            <Radio
              // @ts-ignore
              checked={value === optionEnum}
              name={name}
              size={16}
              value={optionEnum}
              onChange={handleChange}
            />
            <Span ml={2}>
              {i18n(`leaveDurationTypes.options.${optionEnum}`)}
            </Span>
          </Flex>
        ))}
      </SpacedColumn>
    </Flex>
  )
}

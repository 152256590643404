import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Title = styled.h2`
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  margin: 0 33px 20px;
  color: ${themeGet('colors.EMPEROR')};
  font-family: ${themeGet('font')};
  padding: 0;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  padding: ${themeGet('space.4')}px ${themeGet('space.2')}px;
  width: 100%;
`

export const Content = styled.div`
  font-size: ${themeGet('fontSizes.1')};
  margin: ${themeGet('space.5')}px ${themeGet('space.2')}px
    ${themeGet('space.2')}px ${themeGet('space.5')}px;
`

import { ButtonHTMLAttributes, DetailedHTMLProps, Ref } from 'react'

import styled from 'styled-components'
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export type BaseButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  LayoutProps &
  SpaceProps &
  TypographyProps &
  FlexboxProps &
  PositionProps &
  ShadowProps & {
    big?: boolean
    disabled?: boolean
    error?: boolean
    secondary?: boolean
    small?: boolean
    success?: boolean
    xSmall?: boolean
    // use React.Ref instead of React.LegacyRef to prevent type incompatibility errors with styled-components types
    ref?: Ref<HTMLButtonElement>
  }

export const BaseButton = styled.button<BaseButtonProps>`
  cursor: pointer;
  transition: all 0.17s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: ${themeGet('buttons.border.radius.squared')}px;

  ${layout};
  ${space};
  ${typography};
  ${flexbox};
  ${position};
  ${shadow};
`

import PropTypes from 'prop-types'

import { IoCopyOutline } from 'react-icons/io5'

import styled, { css, keyframes } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { SpacedColumn } from 'components/ui/__v2__/Grid'

const styleLink = ({ onClick }) =>
  onClick &&
  css`
    color: ${themeGet('layout.primary')};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  `

export const CoordinatesColumn = styled(SpacedColumn).attrs({ space: 1 })`
  overflow: hidden;
  text-overflow: hidden;
  ${styleLink}
  max-width: 100%;
  user-select: none;
`

const bounceAnimation = keyframes`
  0% {transform: translateY(0)};
  70% {transform: translateY(-5px)};
  90% {transform: translateY(1px)};
  100% {transform: translateY(0)};
`

const animate = ({ isAnimated }) =>
  isAnimated
    ? css`
        animation: ${bounceAnimation} 0.4s ease-in-out normal;
      `
    : null
export const CopyIcon = styled(IoCopyOutline)`
  cursor: pointer;
  color: ${themeGet('layout.primary')};
  size: 14px;
  ${animate}
`

CopyIcon.defaultProps = {
  isAnimated: false,
}

CopyIcon.propTypes = {
  isAnimated: PropTypes.bool,
}

import { ModalActionsContainer } from './ModalActions'
import { ModalClose } from './ModalClose'
import { ModalContainer } from './ModalContainer'
import { ModalTitle } from './styles'

export const Modal = {
  Container: ModalContainer,
  Title: ModalTitle,
  Close: ModalClose,
  Actions: ModalActionsContainer,
}

import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  CLEAR_ATTENDANCE,
  UPDATE_ATTENDANCE,
} from 'store/actions/company/settings'

const initialState = Immutable({
  isAttendanceSettingsLoading: false,
  isAttendanceSettingsLoaded: false,
  attendanceSettingsError: false,
})

const handlers = {
  [UPDATE_ATTENDANCE.REQUEST]: state =>
    state.merge({
      isAttendanceSettingsLoading: true,
      isAttendanceSettingsLoaded: false,
      attendanceSettingsError: false,
    }),
  [UPDATE_ATTENDANCE.SUCCESS]: state =>
    state.merge({
      isAttendanceSettingsLoading: false,
      isAttendanceSettingsLoaded: true,
      attendanceSettingsError: false,
    }),
  [UPDATE_ATTENDANCE.FAILURE]: state =>
    state.merge({
      isAttendanceSettingsLoading: false,
      isAttendanceSettingsLoaded: false,
      attendanceSettingsError: true,
    }),

  [CLEAR_ATTENDANCE]: state =>
    state.merge({
      isAttendanceSettingsLoading: false,
      isAttendanceSettingsLoaded: false,
      attendanceSettingsError: false,
    }),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

import { all, fork, put, take } from 'redux-saga/effects'

import { SELECT_SCHEDULE } from 'store/actions/employeeApp/schedules'
import { loadSuggestedSchedules } from 'store/actions/employeeApp/tradeShift'

export function* loadSuggested() {
  while (true) {
    yield take(SELECT_SCHEDULE)

    yield put(loadSuggestedSchedules())
  }
}

export default function* rootTradeShift() {
  yield all([fork(loadSuggested)])
}

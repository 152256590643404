import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import coreApiCall from 'services/API/coreApi'
import { showToast } from 'services/Toasts'

import { TimeCardQueryKeys } from './constants'

function approveTimeCard({ timeCardIds }: { timeCardIds: string[] }) {
  return coreApiCall({
    method: 'POST',
    endpoint: '/time_entries/restore',
    query: {
      data: timeCardIds.map(id => ({ id, type: 'timeEntries' })),
    },
  })
}
/** @deprecated */
export function useUnapproveTimeCard() {
  const queryClient = useQueryClient()

  const t = useI18n<typeof TRANSLATIONS.timeline.toasts.unapproval>(
    'timeline.toasts.unapproval',
  )

  const { mutateAsync, isLoading } = useMutation(
    TimeCardQueryKeys.all(),
    approveTimeCard,
    {
      onSuccess: (response: JsonApi.Response) => {
        if (!response?.ok) {
          showToast({
            type: 'error',
            title: t('error.title'),
            content: t('error.message'),
          })
        } else {
          showToast({
            type: 'success',
            title: t('success.title'),
            content: t('success.message'),
          })

          queryClient.invalidateQueries(TimeCardQueryKeys.all())
        }
      },
    },
  )

  return {
    unapproveTimeCard: mutateAsync,
    unapprovingTimeCard: isLoading,
  }
}

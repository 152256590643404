import React from 'react'
import PropTypes from 'prop-types'

import { TiWarningOutline } from 'react-icons/ti'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import Tippy from '@tippyjs/react/headless'

import { Flex } from 'components/ui/__v2__/Grid'
import { Popover } from 'components/ui/__v2__/Popover'

const WarningIcon = styled(TiWarningOutline)`
  color: ${themeGet('layout.primary')};
  cursor: help;
`

function WarningPopover({ iconSize, popoverContent, ...rest }) {
  return (
    <Popover content={popoverContent} {...rest}>
      <Flex alignItems="center">
        <WarningIcon size={iconSize} />
      </Flex>
    </Popover>
  )
}

WarningPopover.defaultProps = {
  iconSize: 20,
}

WarningPopover.propTypes = {
  ...Tippy.propTypes,
  iconSize: PropTypes.number,
  popoverContent: PropTypes.any.isRequired,
}

export default WarningPopover

import get from 'lodash/get'
import isString from 'lodash/isString'
import mergeWith from 'lodash/mergeWith'
import omit from 'lodash/omit'

import { mergeCustomizer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import { HANDLE_EMPLOYEE_DESTROY } from 'store/actions/company/branches'

import handlersReducer from './handlers'

const initialState = {}

export default (state = initialState, action) => {
  const data = get(action, 'payload.data')
  // const meta = get(action, 'meta', {})
  const isRaw = get(action, 'isRaw', false)

  if (action.type === LOG_OUT) {
    return initialState
  }

  /**
   * ⚠️ SH*T CODE DETECTED! 💩
   * Need to refactor this in more appropriate way
   */
  const validNonApiEvents = [HANDLE_EMPLOYEE_DESTROY]

  const isValidNonApiEvent =
    isString(action.type) &&
    validNonApiEvents.some(event => action.type.startsWith(event))

  if (isValidNonApiEvent || (data && /* meta.isSuccess && */ !isRaw)) {
    const nextState = mergeWith({}, state, omit(data, 'meta'), mergeCustomizer)
    // Listen only for API success events & valid non-API events
    return handlersReducer(nextState, action)
  }

  return state
}

import { StoreonModule } from 'storeon'

import {
  EVALUATED_TIME_TEMPORAL_WORKFLOW_SET,
  EVALUATED_TIME_TEMPORAL_WORKFLOW_STATE_KEY,
  EVALUATED_TIME_TEMPORAL_WORKFLOW_UNSET,
  INITIAL_STATE,
} from '../constants'
import {
  EvaluatedTimeTemporalWorkflowEvents,
  EvaluatedTimeTemporalWorkflowState,
} from '../types'

export const evaluatedTimeTemporalWorkflowModule: StoreonModule<
  EvaluatedTimeTemporalWorkflowState,
  EvaluatedTimeTemporalWorkflowEvents
> = store => {
  store.on('@init', () => ({
    [EVALUATED_TIME_TEMPORAL_WORKFLOW_STATE_KEY]: INITIAL_STATE,
  }))

  store.on(
    EVALUATED_TIME_TEMPORAL_WORKFLOW_SET,
    (state, { temporalWorkflowId, temporalRunId }) => ({
      ...state,
      [EVALUATED_TIME_TEMPORAL_WORKFLOW_STATE_KEY]: {
        ...state[EVALUATED_TIME_TEMPORAL_WORKFLOW_STATE_KEY],
        temporalWorkflowId,
        temporalRunId,
      },
    }),
  )

  store.on(EVALUATED_TIME_TEMPORAL_WORKFLOW_UNSET, state => ({
    ...state,
    [EVALUATED_TIME_TEMPORAL_WORKFLOW_STATE_KEY]: INITIAL_STATE,
  }))
}

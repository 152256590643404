import * as colors from './colors'

export const selects = {
  height: {
    big: 38,
    xSmall: 16,
    default: 32,
  },
  width: {
    default: '100%',
  },
  margin: {
    default: 20,
  },
  padding: {
    xSmall: '3px 4px',
    default: '6px',
  },
  color: {
    secondary: colors.CHARCOAL_40,
    disabled: colors.CHARCOAL_60,
    default: colors.SPUN_PEARL,
    error: colors.NEGATIVE,
    light: colors.WHITE,
    dark: colors.EBONY_CLAY,
  },
  backgroundColor: {
    disabled: colors.SEASHELL,
    default: colors.ATHENS_GRAY_20,
    light: colors.WHITE,
  },
  border: {
    radius: 4,
    color: {
      secondary: colors.SMASHED_PUMPKIN,
      default: colors.ATHENS_GRAY_40,
      disabled: colors.CHARCOAL_40,
      error: colors.ERROR,
    },
  },
  transform: {
    rotate: 'rotate(180deg)',
  },
  transition: 'all 0.2s ease',
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  outline: 'none',
  cursor: 'pointer',
  hover: {
    border: {
      color: {
        secondary: colors.SMASHED_PUMPKIN,
        default: colors.MANATEE,
      },
    },
  },
}

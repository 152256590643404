import { useApolloMutation } from 'API/services/Apollo'

import { GeneratePulledFileLinkMutation } from './GraphQL'

type Props = {
  silent?: boolean
}
export function useGeneratePulledFileLink({ silent = false }: Props) {
  const [innerGeneratePulledFileLink, { loading }] = useApolloMutation<
    MutationData<'generatePulledFileLink'>,
    Gateway.MutationGeneratePulledFileLinkArgs
  >(GeneratePulledFileLinkMutation, { silent })

  async function generatePulledFileLink(id: string) {
    const result = await innerGeneratePulledFileLink({
      variables: {
        id,
      },
    })
    return result.data?.generatePulledFileLink
  }

  return {
    generatePulledFileLink,
    generatingPulledFileLink: loading,
  }
}

import { useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import {
  getOffsetPageMeta,
  transformResponseToEntities,
} from 'API/services/utils'

import { TimeCardsQueryParams } from 'pages/EmployerApp/TimeCards'

import { TIME_CARD_JSON_API_TYPE, TimeCardQueryKeys } from './constants'
import { timecardsQuery, TimecardsQueryEntityReturn } from './Json'

export type UseTimeCardsByOffsetArgs = {
  filters: TimeCardsQueryParams['filters']
  sort: JsonApi.TimeCardsSorting
  page: JsonApi.OffsetPagingInput
}

/** @deprecated: Use cursor instead */
export function useTimeCardsByOffset({
  filters,
  page,
  sort,
}: UseTimeCardsByOffsetArgs) {
  const [total, setTotal] = useState(0)
  const { data, isFetching, error, refetch } = useQuery({
    queryKey: TimeCardQueryKeys.byOffset(page, sort, filters),
    queryFn: () =>
      timecardsQuery({
        filters,
        sort,
        page,
      }),
    select: (response: JsonApi.Response) => {
      const {
        entities,
      } = transformResponseToEntities<TimecardsQueryEntityReturn>({
        response,
        jsonApiType: TIME_CARD_JSON_API_TYPE,
      })

      const { recordCount } = getOffsetPageMeta(response)

      if (total !== recordCount) setTotal(recordCount)

      return entities
    },
    structuralSharing: false,
    refetchOnWindowFocus: false,
  })

  return {
    timecards: data ?? [],
    loadingTimecards: isFetching,
    timecardsError: error,
    refetchTimecards: refetch,
    totalTimecards: total,
  }
}

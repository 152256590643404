import styled from 'styled-components'
import { maxWidth, MaxWidthProps } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const BadgeContainer = styled.div<MaxWidthProps>`
  display: flex;
  flex-direction: column;
  background: ${themeGet('table.badge.backgroundColor')};
  color: ${themeGet('table.badge.color')};
  padding: ${themeGet('table.badge.padding')};
  border-width: ${themeGet('table.badge.border.width')};
  border-color: ${themeGet('table.badge.border.color')};
  border-radius: ${themeGet('table.badge.border.radius')};

  ${maxWidth}
`

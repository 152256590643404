import { LOCALE_KEY } from './StoreOn/Locale/locale'
import { Store } from './StoreOn'

// Shared services is used to solve circular dependency

interface SharedServices {
  storeOn?: Store
}

const services: SharedServices = {}

function checkHasValue<T extends keyof SharedServices>(serviceName: T) {
  const service = services[serviceName]
  if (!service) {
    throw new Error(`${serviceName} not found in Shared`)
  }

  return service
}
// ================================================================================================
function setStoreOn(storeOn: SharedServices['storeOn']) {
  services.storeOn = storeOn
}

function getStoreOn() {
  return checkHasValue('storeOn')
}

function getStoreOnLocale() {
  return getStoreOn()?.get()?.[LOCALE_KEY] ?? 'en'
}
// ================================================================================================

export const Shared = {
  getStoreOn,
  setStoreOn,
  getStoreOnLocale,
}

export default Shared

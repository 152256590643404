import React from 'react'

import { useTheme } from 'styled-components'

import { useStoreon } from 'storeon/react'

import { Grid } from 'components/ui/__v2__/Grid'
import { Button, Modal } from 'components/ui/__v3__'

import { i18n } from 'i18n'

import { StoreonEvents, StoreonState } from 'services/StoreOn'

import { Buttons, Content, Title } from './styles'

export function ConfirmationModal() {
  const theme = useTheme()
  const {
    confirmationModal: {
      isOpen,
      handleConfirm,
      content,
      title,
      cancelText,
      confirmText,
    },
    dispatch,
  } = useStoreon<StoreonState, StoreonEvents>('confirmationModal')

  const handleClose = () => {
    dispatch('confirmationModal/close')
    handleConfirm(false)
  }

  return (
    <Modal.Container
      customStyles={{ overlay: { zIndex: theme.zIndices.popup } }}
      isOpen={isOpen}
    >
      <Grid gridTemplateColumns="auto 24px" gridTemplateRows="auto">
        <Title>{title}</Title>

        <Modal.Close onClose={handleClose} />
      </Grid>

      <Content>{content}</Content>

      <Buttons>
        <Button secondary onClick={handleClose}>
          {cancelText ?? i18n('actions.cancel')}
        </Button>

        <Button onClick={() => handleConfirm(true)}>
          {confirmText ?? i18n('actions.yesDelete')}
        </Button>
      </Buttons>
    </Modal.Container>
  )
}

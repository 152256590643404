export const TOGGLE_NOTIFICATIONS_SETTINGS =
  'header/TOGGLE_NOTIFICATIONS_SETTINGS'

export const TOGGLE_INVITE = 'header/TOGGLE_INVITE'

export const TOGGLE_GLOBAL_SETTINGS = 'header/TOGGLE_GLOBAL_SETTINGS'

export const TOGGLE_TOKEN = 'header/TOGGLE_TOKEN'

export const toggleNotificationsSettings = () => ({
  type: TOGGLE_NOTIFICATIONS_SETTINGS,
})

export const toggleInvite = inviteType => ({
  type: TOGGLE_INVITE,
  payload: {
    inviteType,
  },
})

export const toggleGlobalSettings = () => ({ type: TOGGLE_GLOBAL_SETTINGS })

export const toggleToken = () => ({ type: TOGGLE_TOKEN })

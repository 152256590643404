import styled from 'styled-components'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'

type ContainerProps = LayoutProps & SpaceProps

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[3]}px;
  border: 1px solid ${({ theme }) => theme.colors.CHARCOAL_40};
  border-radius: 6px;
  height: 100%;
  overflow: hidden;
  padding-bottom: ${({ theme }) => theme.space[2]}px;
  ${layout}
  ${space}
`

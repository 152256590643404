import React from 'react'
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'

import FormInput from './FormInput'

function FormMaskedInput({ input, meta, mask, ...rest }) {
  return (
    <MaskedInput
      {...input}
      mask={mask}
      render={(ref, props) => (
        <FormInput input={props} meta={meta} ref={ref} {...rest} />
      )}
    />
  )
}

FormMaskedInput.propTypes = {
  ...FormInput.propTypes,
  mask: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
}

export default FormMaskedInput

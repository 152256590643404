import qs from 'qs'
import { all, fork, take } from 'redux-saga/effects'

import { api } from 'constants/config'

import { downloadFileAsync } from 'helpers/files'

import {
  EXPORT_PREVIEW_CSV,
  EXPORT_REPORT_CSV,
} from 'store/actions/reportBuilder'

function* exportReportCSV() {
  const { filesUrl } = api

  while (true) {
    const { payload } = yield take(EXPORT_REPORT_CSV.SUCCESS)
    const { data, companyId, reportId } = payload

    const token = data?.token

    if (token) {
      const url = `${filesUrl}/companies/${companyId}/report_templates/${reportId}/?token=${token}`
      downloadFileAsync(url)
    }
  }
}

function* exportPreviewCSV() {
  const { filesUrl } = api

  while (true) {
    const { payload } = yield take(EXPORT_PREVIEW_CSV.SUCCESS)
    const { data, companyId, report } = payload

    const token = data?.token

    if (token) {
      const params = qs.stringify(
        { token, ...report },
        { arrayFormat: 'brackets' },
      )
      const url = `${filesUrl}/companies/${companyId}/report_templates/preview?${params}`
      downloadFileAsync(url)
    }
  }
}

export default function* root() {
  yield all([fork(exportReportCSV), fork(exportPreviewCSV)])
}

import {
  RuleEmployeeFilterGroupCondition,
  RuleOvertimeType,
  RuleParametersComparison,
  RuleParametersCondition,
} from 'constants/gatewayGraphQL'

import { i18n } from 'i18n'

export function getComparisonText(
  comparison?: RuleParametersComparison,
): string {
  if (!comparison) return i18n('rule.unknown')

  return i18n(`rule.comparison.${comparison}`)
}

export function getConditionText(condition?: RuleParametersCondition): string {
  if (!condition) return i18n('rule.unknown')

  return i18n(`rule.condition.${condition}`)
}

export function getOvertimeTypeText(overtimeType?: RuleOvertimeType): string {
  if (!overtimeType) return i18n('rule.unknown')

  return i18n(`rule.overtimeTypes.${overtimeType}`)
}

export function getGroupConditionText(
  condition?: RuleEmployeeFilterGroupCondition,
): string {
  if (!condition) return i18n('rule.unknown')

  return i18n(`rule.groupCondition.${condition}`)
}

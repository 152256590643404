import { useMemo } from 'react'

import { useApolloQuery } from 'API/services/Apollo'

import {
  AutoSchedulingTaskQuery,
  AutoSchedulingTaskQueryData,
} from './GraphQL/Queries'
import { fillDisplayState } from './GraphQL/Queries/adapters'

export function useAutoSchedulingTask(id: string) {
  const { data, loading, refetch } = useApolloQuery<
    AutoSchedulingTaskQueryData,
    Gateway.QueryAutoSchedulingTaskArgs
  >(AutoSchedulingTaskQuery, {
    fetchPolicy: 'network-only',
    variables: {
      id,
    },
  })

  const autoSchedulingTask = useMemo(
    () =>
      data?.autoSchedulingTask
        ? fillDisplayState(data.autoSchedulingTask)
        : undefined,
    [data],
  )

  return {
    autoSchedulingTask,
    fetching: loading,
    refetch,
  }
}

import { all, fork, put, take, takeLatest } from 'redux-saga/effects'

import { INIT, STOP_TIMER } from 'store/actions/employeeApp/timeClock'
import { addTimerId, loadTimers } from 'store/actions/employeeApp/timeLog'

function* initialLoad() {
  while (true) {
    yield take(INIT)

    yield put(loadTimers())
  }
}

export function* addTimer(response) {
  const { timeEntries } = response.payload.data
  const id = Object.keys(timeEntries)[0]

  yield put(addTimerId(id))
}

export default function* timeLog() {
  yield all([fork(initialLoad), takeLatest(STOP_TIMER.SUCCESS, addTimer)])
}

import { MAX_MINUTES_IN_HOUR } from 'constants/dailyWorkingHours'

import { GlobalSettingsFormState } from './types'

export function mapFormStateToServerData(
  formState: GlobalSettingsFormState,
  hasAutoShiftNameFeature: boolean,
) {
  const {
    disableEmployeeProfileEdit,
    managersCanArchiveEmployees,
    allowFutureManualTimecardApprove,
    earlyClockIn,
    autoShiftName,
    earlyClockInLimitHours,
    earlyClockInLimitMinutes,
    sendScheduleEmails,
    showWagesToManagers,
    disableEmployeeBreaks,
    allowDepartmentManagerMutualApprove,
    disableDepartmentManagerLeaveRequestAutoApproval,
    calendarStartDay,
    rapidClockInMode,
    timeoffSettingsThreshold,
    timecardSettingsThreshold,
  } = formState

  const earlyClockInLimit =
    earlyClockInLimitHours * MAX_MINUTES_IN_HOUR + earlyClockInLimitMinutes

  const rapidClockInModeLimit = Number(rapidClockInMode.limit)

  return {
    allowDepartmentManagerMutualApprove,
    disableEmployeeProfileEdit,
    managersCanArchiveEmployees,
    disableEmployeeBreaks,
    allowFutureManualTimecardApprove,
    earlyClockIn,
    earlyClockInLimit,
    sendScheduleEmails,
    showWagesToManagers,
    calendarStartDay,
    disableDepartmentManagerLeaveRequestAutoApproval,
    ...(hasAutoShiftNameFeature && {
      autoShiftName,
    }),
    rapidClockInMode: {
      enabled: rapidClockInMode.enabled,
      ...(rapidClockInModeLimit && {
        limit: rapidClockInModeLimit,
      }),
    },
    timeoffSettings: {
      updateThreshold: {
        enabled: timeoffSettingsThreshold.enabled,
        ...(timeoffSettingsThreshold.value && {
          value: Number(timeoffSettingsThreshold.value),
        }),
      },
    },
    timecardSettings: {
      updateThreshold: {
        enabled: timecardSettingsThreshold.enabled,
        ...(timecardSettingsThreshold.value && {
          value: Number(timecardSettingsThreshold.value),
        }),
      },
    },
  }
}

import PropTypes from 'prop-types'

import styled from 'styled-components'
import { color, height, space } from 'styled-system'

const Divider = styled.hr`
  border: 0;
  border-bottom: 1px solid;
  margin-top: 2px;
  margin-bottom: 2px;

  ${height};
  ${space};
  ${color};
`

Divider.propTypes = {
  h: PropTypes.string,
  height: PropTypes.string,
}

export default Divider

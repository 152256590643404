import { useExportProfileLogs } from './useExportProfileLogs'
import { useProfileLogsByCursor as getByCursor } from './useProfileLogsByCursor'
import { useProfileLogsByOffset as getByOffset } from './useProfileLogsByOffset'
import { useProfileLogsCount as count } from './useProfileLogsCount'
import { useProfileLogsInfinite as infinite } from './useProfileLogsInfinite'

export * from './constants'
export * from './Json'
export * from './types'

export const ProfileLogsAPI = {
  getByOffset,
  getByCursor,
  count,
  extract: useExportProfileLogs,
  infinite,
}

import React, { useCallback } from 'react'
import { useForm } from 'react-final-form'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { Helper } from 'components/ui/__v3__/Input'
import { IconError } from 'components/ui/__v3__/Input/Errors'

import { isoToJsDate } from 'helpers/date'

import { ValidationError } from './styles'

import { DayPicker } from '../DayPicker'
import { Box } from '../Grid'

/**
 * @see https://final-form.org/docs/react-final-form/types/FieldRenderProps for FormRenderProps props
 * @see {@link ../DayPicker/DayPicker.js} for DayPicker props
 */
const FormDayPicker = React.forwardRef(
  (
    { format, input, meta, wrapper, onBlur, mustBeTouchedToShowError, ...rest },
    ref,
  ) => {
    const { change } = useForm()

    const { name } = input

    const handleChange = useCallback(
      value => {
        change(name, value)
        if (onBlur) {
          onBlur()
        }
      },
      [name, change, onBlur],
    )

    const from = get(input, ['value', 'from'], get(input, 'value', null))
    const to = get(input, ['value', 'to'], get(input, 'value', null))

    return (
      <Box {...wrapper} ref={ref}>
        <DayPicker
          errored={meta.error && meta.touched}
          format={format}
          from={isoToJsDate(from)}
          name={name}
          to={isoToJsDate(to)}
          onBlur={input.onBlur}
          onChange={handleChange}
          onFocus={input.onFocus}
          {...rest}
        />
        {meta.error && (mustBeTouchedToShowError ? meta.touched : true) && (
          <Helper>
            <ValidationError>
              <IconError>{meta.error}</IconError>
            </ValidationError>
          </Helper>
        )}
      </Box>
    )
  },
)

FormDayPicker.defaultProps = {
  format: null,
  mustBeTouchedToShowError: true,
}

FormDayPicker.propTypes = {
  ...DayPicker.propTypes,
  format: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  mustBeTouchedToShowError: PropTypes.bool,
}

export default FormDayPicker

import {
  createRelationAddHandler,
  createRelationDeleteHandler,
} from 'redux-json-api-handlers'

import first from 'lodash/first'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import map from 'lodash/map'
import merge from 'lodash/merge'
import omit from 'lodash/omit'
import set from 'lodash/set'

import {
  bulkBudgetRelationAddHandler,
  dailySalesRelationAddHandler,
} from 'constants/dailySales'

import { createReducer } from 'helpers/redux'

import {
  ADD_LOCATION_TAG,
  CREATE_BRANCH_BUDGET,
  CREATE_BRANCH_LABOR_COST,
  CREATE_BRANCH_SALE_AMOUNT,
  CREATE_OR_UPDATE_BULK_BUDGET,
  DELETE_BRANCH_LABOR_COST,
  DELETE_BRANCH_SALE_AMOUNT,
  DELETE_IMAGE_FILE,
  DELETE_LOCATION_TAG,
  HANDLE_EMPLOYEE_DESTROY,
  UPLOAD_IMAGE_FILE,
} from 'store/actions/company/branches'
import {
  ADD_DEPARTMENT,
  DELETE_DEPARTMENT,
} from 'store/actions/company/departments'
import {
  CREATE_PRAYER_BREAK,
  DELETE_PRAYER_BREAK,
} from 'store/actions/company/prayerBreaks'
import { UPDATE_ATTENDANCE } from 'store/actions/company/settings'
import { ADD_DEPARTMENTS_AND_ROLES } from 'store/actions/locations'
import { PAYROLL_EXPORT } from 'store/actions/payroll'
import { DEPARTMENTS_TYPE } from 'store/reducers/areas'
import { PRAYER_SETTINGS_TYPE } from 'store/reducers/company/prayerBreaks'

const initialState = {}

const handlers = {
  [ADD_LOCATION_TAG.SUCCESS]: (state, { payload }) => {
    const { tags, branchId } = payload
    const prevTags = get(state[branchId], 'relationships.locationTags.data', [])
    const newTags = map(tags, id => ({
      id,
      type: 'locationTags',
    }))
    return merge({}, state, {
      [branchId]: {
        relationships: {
          locationTags: {
            data: [...prevTags, ...newTags],
          },
        },
      },
    })
  },
  [DELETE_LOCATION_TAG.SUCCESS]: createRelationDeleteHandler('locationTags'),
  [UPLOAD_IMAGE_FILE.SUCCESS]: createRelationAddHandler('files', 'images'),
  [DELETE_IMAGE_FILE.SUCCESS]: createRelationDeleteHandler('images'),
  [CREATE_BRANCH_BUDGET.SUCCESS]: dailySalesRelationAddHandler(
    'branchBudgets',
    'budgets',
  ),
  [CREATE_BRANCH_LABOR_COST.SUCCESS]: dailySalesRelationAddHandler(
    'branchLaborCosts',
    'laborCosts',
  ),
  [CREATE_BRANCH_SALE_AMOUNT.SUCCESS]: dailySalesRelationAddHandler(
    'branchSales',
    'sales',
  ),
  [DELETE_BRANCH_SALE_AMOUNT.SUCCESS]: createRelationDeleteHandler('sales'),
  [DELETE_BRANCH_LABOR_COST.SUCCESS]: createRelationDeleteHandler('laborCosts'),
  [CREATE_OR_UPDATE_BULK_BUDGET.SUCCESS]: bulkBudgetRelationAddHandler(),
  [PAYROLL_EXPORT.SUCCESS]: (state, { payload }) => {
    const {
      data: { branchId, branchPayrollId },
    } = payload
    let needUpdate = false
    if (!isEmpty(branchPayrollId)) {
      const prevBranchPayrollId = get(
        state,
        `[${branchId}].attributes.branchPayrollId`,
        {},
      )
      needUpdate = prevBranchPayrollId !== branchPayrollId
    }
    return needUpdate
      ? merge({}, state, {
          [branchId]: {
            attributes: {
              branchPayrollId,
            },
          },
        })
      : state
  },

  [HANDLE_EMPLOYEE_DESTROY.DATA]: (state, { payload }) =>
    merge({}, omit(state, [payload.branchId])),

  [UPDATE_ATTENDANCE.SUCCESS]: (state, action) => {
    const attendanceSettings = get(action, ['payload', 'attendanceSettings'])
    const timeSettings = get(action, ['payload', 'timeSettings'])

    let nextState = {}

    if (attendanceSettings) {
      forEach(get(action, ['payload', 'areas', 'locations']), id => {
        set(state, [id, 'attributes', 'attendanceSettings'], attendanceSettings)
      })

      nextState = merge(nextState, state)
    }

    if (timeSettings) {
      forEach(get(action, ['payload', 'areas', 'locations']), id => {
        set(state, [id, 'attributes', 'timeSettings'], timeSettings)
      })

      nextState = merge(nextState, state)
    }

    return nextState
  },

  [ADD_DEPARTMENT.SUCCESS]: (state, { payload }) => {
    const branchId = payload?.relationId
    const departments = payload?.data?.departments

    if (!branchId) return state

    const nextState = merge({}, state, {
      [branchId]: {
        relationships: {
          departments: {
            data: [
              ...(state[branchId]?.relationships?.departments?.data ?? []),
              {
                id: first(keys(departments)),
                type: DEPARTMENTS_TYPE,
              },
            ],
          },
        },
      },
    })

    return nextState
  },

  // [ADD_DEPARTMENT.SUCCESS]: createRelationAddHandler('departments', 'branch'),
  [ADD_DEPARTMENTS_AND_ROLES.SUCCESS]: createRelationAddHandler(
    'departments',
    'branch',
  ),
  [DELETE_DEPARTMENT.SUCCESS]: createRelationDeleteHandler('departments'),

  [CREATE_PRAYER_BREAK.SUCCESS]: (state, { payload }) => {
    const branchId = first(keys(get(payload, ['data', 'branches'])))
    const prayerSettings = get(payload, ['data', 'prayerSettings']) || {}

    return merge({}, state, {
      [branchId]: {
        relationships: {
          prayerSettings: {
            data: {
              id: first(keys(prayerSettings)),
              type: PRAYER_SETTINGS_TYPE,
            },
          },
        },
      },
    })
  },

  [DELETE_PRAYER_BREAK.SUCCESS]: (state, { payload }) => {
    const { relationIds } = payload

    const changedBranches = {}

    relationIds.forEach(relationId => {
      changedBranches[relationId] = {
        relationships: {
          prayerSettings: {
            data: null,
          },
        },
      }
    })

    return merge({}, state, changedBranches)
  },
}

export default createReducer(initialState, handlers)

import {
  createDeleteHandler,
  createFields,
  createLoadHandler,
  createReducerHandlers,
} from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import {
  CREATE_CUSTOM_LABEL_DAY,
  DELETE_CUSTOM_LABEL,
  LOAD_CUSTOM_LABEL_DAYS,
  UPDATE_CUSTOM_LABEL,
} from 'store/actions/customLabelDays'

export const CUSTOM_LABEL_DAYS_TYPE = 'customLeaveDays'

const initialState = Immutable({
  ...createFields(CUSTOM_LABEL_DAYS_TYPE),
})

const handlers = {
  ...createReducerHandlers(CUSTOM_LABEL_DAYS_TYPE, LOAD_CUSTOM_LABEL_DAYS, {
    withReplace: true,
  }),

  [CREATE_CUSTOM_LABEL_DAY.SUCCESS]: createLoadHandler(CUSTOM_LABEL_DAYS_TYPE),

  [UPDATE_CUSTOM_LABEL.SUCCESS]: createLoadHandler(CUSTOM_LABEL_DAYS_TYPE),

  [DELETE_CUSTOM_LABEL.SUCCESS]: createDeleteHandler(CUSTOM_LABEL_DAYS_TYPE),
}

export default createReducer(initialState, handlers)

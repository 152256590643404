export interface PageSelectOption {
  label: string
  value: number
}

export function generateRowsPerPageSelectOption(
  value: number,
): PageSelectOption {
  return {
    label: String(value),
    value,
  }
}

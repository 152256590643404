import { BsDownload } from 'react-icons/bs'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const DownloadIcon = styled(BsDownload).attrs({ size: 18 })`
  color: ${themeGet('layout.lightText')};
`

export default DownloadIcon

import { graphQLResultHasError } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { useI18n } from 'hooks'

import { TRANSLATIONS } from 'i18n'

import { showToast } from 'services/Toasts'

import { RULESET_ERRORS_I18N } from './errors'
import { CreateRulesetMutation } from './GraphQL'

export function useCreateRuleset() {
  const t = useI18n<typeof TRANSLATIONS.ruleset.toasts.create>(
    'ruleset.toasts.create',
  )

  const [innerCreate, { loading: creating }] = useApolloMutation<
    MutationData<'createRuleset'>,
    Gateway.MutationCreateRulesetArgs
  >(CreateRulesetMutation, {
    silent: true,
    // TODO: Temporary solution, translated errors should come from Gateway
    onError: error => {
      const isUniqueCodeError = error.message.includes(
        RULESET_ERRORS_I18N.uniqueCode.message,
      )

      showToast({
        type: 'error',
        title: t('error.title'),
        content: isUniqueCodeError
          ? RULESET_ERRORS_I18N.uniqueCode.translation
          : t('error.message'),
      })
    },
    onCompleted: () => {
      showToast({
        type: 'success',
        title: t('success.title'),
        content: t('success.message'),
      })
    },
  })

  const createRuleset = async (input: Gateway.CreateRulesetInput) => {
    const result = await innerCreate({
      variables: {
        input,
      },
    })

    return graphQLResultHasError(result) || !!result.errors
  }

  return { createRuleset, creating }
}

import { ScheduleConflictActionTypes } from 'constants/ids'

import coreApiCall from 'services/API/coreApi'

export type CreateTimeoffMutationArgs = {
  reason?: string
  scheduleConflictType?: ScheduleConflictActionTypes // Think of making it requried for assign
  employeeId: string
  leaveTypeId: string
  locationId?: string
  documentId?: string
} & (
  | {
      partial: false
      startAt: string
      finishAt: string
      excludedDates: string[]
    }
  | {
      partial: true
      startTime: string
      endTime: string
    }
)

export function createTimeoffMutation(
  args: CreateTimeoffMutationArgs,
  include?: string,
): Promise<JsonApi.Response> {
  const query: JsonApi.TimeoffCreateArgs = {
    ...(include && { include }),
    data: {
      attributes: {
        reason: args.reason,
        scheduleConflictType: args.scheduleConflictType,

        ...(args.partial
          ? {
              partial: args.partial,
              startTime: args.startTime,
              endTime: args.endTime,
            }
          : {
              partial: args.partial,
              startAt: args.startAt,
              finishAt: args.finishAt,
              excludedDates: args.excludedDates,
            }),
      },
      relationships: {
        employee: {
          data: {
            type: 'employees',
            id: args.employeeId,
          },
        },
        custom_leave_day: {
          data: {
            type: 'customLeaveDays',
            id: args.leaveTypeId,
          },
        },

        ...(args.locationId && {
          branch: {
            data: {
              type: 'branches',
              id: args.locationId,
            },
          },
        }),

        ...(args.documentId && {
          document: {
            data: {
              type: 'files',
              id: args.documentId,
            },
          },
        }),
      },
    },
  }

  return coreApiCall({
    endpoint: '/timeoffs',
    method: 'POST',
    query,
  })
}

import { useApolloMutation } from 'API/services/Apollo'

import { DeletePayFrequencyMutation } from './GraphQL'

export function useDeletePayFrequency() {
  const [deletePayFrequency, { loading: removing }] = useApolloMutation<
    MutationData<'deletePayFrequency'>,
    Gateway.MutationDeletePayFrequencyArgs
  >(DeletePayFrequencyMutation, {
    translationKey: 'payFrequency.toasts.delete',
  })

  const remove = async (payFrequencyId: string) => {
    await deletePayFrequency({
      variables: { id: payFrequencyId },
    })
  }

  return { remove, removing }
}

import ms from 'ms'

import { PAGE_SIZE } from 'constants/pagination'

export const DEFAULT_JSON_API_OFFSET_PAGING: JsonApi.OffsetPagingInput = {
  number: 1,
  size: PAGE_SIZE['10'],
}

export const DEFAULT_JSON_API_CURSOR_PAGING: JsonApi.CursorPagingInput = {
  size: PAGE_SIZE['10'],
}

export const STALE_TIME = {
  VERY_SLOW: ms('15m'),
  SLOW: ms('5m'),
  FAST: ms('15s'),
}

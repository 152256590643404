import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-final-form'
import PropTypes from 'prop-types'

import find from 'lodash/find'
import map from 'lodash/map'

import { IconError } from 'components/ui/__v3__/Input/Errors'
import { Select } from 'components/ui/__v3__/Select'

import { ValidationError } from './styles'

/**
 * @see https://final-form.org/docs/react-final-form/types/FieldRenderProps for FormRenderProps props
 * @see {@link ../Select/Select.js} for Select props
 */
const FormMultiSelect = React.forwardRef(
  ({ input, meta, options, onBlur, ...rest }, ref) => {
    const { value, name } = input

    const { change } = useForm()

    const selectOption = useMemo(
      () => map(value, x => find(options, ['value', x])),
      [options, value],
    )

    const handleChange = useCallback(
      selectedOptions => {
        const values = map(selectedOptions, x => x.value)
        change(name, values)
        if (onBlur) {
          onBlur()
        }
      },
      [name, change, onBlur],
    )

    return (
      <Select
        errored={meta.error && meta.touched}
        ref={ref}
        {...input}
        closeMenuOnSelect={false}
        helper={
          meta.error &&
          meta.touched && (
            <ValidationError>
              <IconError> {meta.error}</IconError>
            </ValidationError>
          )
        }
        isMulti
        options={options}
        value={selectOption}
        onChange={handleChange}
        {...rest}
      />
    )
  },
)

FormMultiSelect.propTypes = {
  ...Select.propTypes,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export default FormMultiSelect

import React, { useCallback, useMemo } from 'react'

import { LeavesAPI } from 'API'
import { LazyEmployeeLeavesArgs } from 'API/Leaves/useLazyEmployeeLeavesByOffset'

import CustomLeaveDay from 'components/blocks/CustomLeaveDay'
import { Select } from 'components/ui/__v3__/Select'

import { createpDebounce } from 'helpers/debounce'

import { labelDayToOption } from 'services/Options'

export type LeaveTypePickerReturnOption = ReturnType<typeof labelDayToOption>

export type LeaveByEmployeePickerArgs = {
  value: LeaveTypePickerReturnOption
  onSelect: (option: LeaveTypePickerReturnOption) => void
  onBlur: () => void

  employeeId?: string
  partial?: boolean
  effectiveDates?: LazyEmployeeLeavesArgs['filters']['effectiveDates']

  isDisabled?: boolean
  isMulti?: boolean
  isClearable?: boolean
}

export function LeaveByEmployeePicker({
  value,
  onSelect,

  employeeId,
  effectiveDates,
  partial,

  ...rest
}: LeaveByEmployeePickerArgs) {
  const formatOptions = useCallback(
    ({ data }) => <CustomLeaveDay leaveDay={data} />,
    [],
  )

  const filters = useMemo(
    () => ({
      partial,
      effectiveDates,
    }),
    [effectiveDates, partial],
  )
  const { fetching, loadLeavesByEmployee, leaves } = LeavesAPI.byEmployeeLazy({
    employeeId,
    filters,
  })

  const handleChange = (option: LeaveTypePickerReturnOption) => {
    onSelect(option)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInput = useCallback(
    createpDebounce(async (inputValue: string, callback: Function) => {
      const searchedLeaves = await loadLeavesByEmployee(inputValue)
      const newOptions = searchedLeaves
        ? searchedLeaves.map(labelDayToOption)
        : []
      callback(newOptions)
    }),
    [loadLeavesByEmployee],
  )

  const defaultOptions = useMemo(() => leaves.map(labelDayToOption), [leaves])

  return (
    <Select
      // @ts-ignore
      async
      closeMenuOnSelect={!rest.isMulti}
      defaultOptions={defaultOptions}
      formatOptionLabel={formatOptions}
      isLoading={fetching}
      isSearchable
      loadOptions={handleInput}
      value={value}
      withPortal
      onChange={handleChange}
      onMenuOpen={loadLeavesByEmployee}
      {...rest}
    />
  )
}

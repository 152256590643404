import { useApolloQuery } from 'API/services/Apollo'

import { ShiftTagsByOffsetQuery } from './GraphQL'

type Props = {
  paging: Gateway.QueryShiftTagsByOffsetArgs['paging']
  filter?: Gateway.ShiftTagFilter
}

export function useShiftTagsByOffset({ paging, filter }: Props) {
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'shiftTagsByOffset'>,
    Gateway.QueryShiftTagsByOffsetArgs
  >(ShiftTagsByOffsetQuery, {
    variables: {
      paging,
      filter,
    },
    fetchPolicy: 'network-only',
  })

  const shiftTags = data?.shiftTagsByOffset?.rows ?? []

  return {
    shiftTags,
    fetching: loading,
    refetch,
    total: data?.shiftTagsByOffset?.pageInfo.total ?? 0,
  }
}

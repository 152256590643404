import { useCallback } from 'react'

import { useStoreon } from 'storeon/react'

import { RuleBuilderPoCState } from 'pages/EmployerApp/Builder/RulePoC/types'

import {
  RULE_BUILDER_KEY,
  RuleBuilderEvents,
  RuleBuilderState,
} from './RuleBuilder'

export function useRuleBuilderData() {
  const { [RULE_BUILDER_KEY]: state, dispatch } = useStoreon<
    RuleBuilderState,
    RuleBuilderEvents
  >(RULE_BUILDER_KEY)

  const addRule = useCallback(
    (ruleData: RuleBuilderPoCState) => dispatch('ruleBuilder/add', ruleData),
    [dispatch],
  )

  const modifyRule = useCallback(
    (ruleData: RuleBuilderPoCState & { id: string }) =>
      dispatch('ruleBuilder/modify', ruleData),
    [dispatch],
  )

  const deleteRule = useCallback(
    (id: string) => dispatch('ruleBuilder/delete', id),
    [dispatch],
  )

  const getRuleById = useCallback(
    (id: string) => {
      return state.find(rule => rule.id === id)
    },
    [state],
  )

  const toggleRule = useCallback(
    (id: string) => dispatch('ruleBuilder/toggle', id),
    [dispatch],
  )

  const copyRule = useCallback(
    (id: string) => dispatch('ruleBuilder/copy', id),
    [dispatch],
  )

  const changePriority = useCallback(
    (id: string, priority: number | undefined) =>
      dispatch('ruleBuilder/changePriority', { id, priority }),
    [dispatch],
  )

  return {
    addRule,
    modifyRule,
    deleteRule,
    rules: state,
    getRuleById,
    toggleRule,
    copyRule,
    changePriority,
  }
}

import ArchiveShiftsMutation from './ArchiveShifts.graphql'
import CopyShiftsMutation from './CopyShifts.graphql'
import CreateShiftsWithAssignmentsMutation from './CreateShiftsWithAssignments.graphql'
import MoveSchedulesFromShiftMutation from './MoveSchedulesFromShift.graphql'
import UpdateShiftMutation from './UpdateShift.graphql'
import UpdateShiftsMutation from './UpdateShifts.graphql'

export {
  ArchiveShiftsMutation,
  CopyShiftsMutation,
  CreateShiftsWithAssignmentsMutation,
  MoveSchedulesFromShiftMutation,
  UpdateShiftMutation,
  UpdateShiftsMutation,
}

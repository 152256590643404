import { createFields, createReducerHandlers } from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import {
  CLEAR,
  CLEAR_DEPARTMENTS,
  CLEAR_EMPLOYEES,
  CLEAR_JOBS,
  CLEAR_JOBS_EMPLOYEES,
  CLEAR_LOCATIONS,
  CLEAR_STAFF,
  INIT_DEPARTMENTS,
  INIT_EMPLOYEES,
  INIT_JOBS,
  INIT_JOBS_EMPLOYEES,
  INIT_LOCATIONS,
  INIT_STAFF,
} from 'store/actions/areas'
import { LOG_OUT } from 'store/actions/auth'

export const LOCATIONS_TYPE = 'branches'

export const DEPARTMENTS_TYPE = 'departments'

export const JOBS_TYPE = 'jobs'

export const EMPLOYEES_TYPE = 'employees'

export const JOBS_EMPLOYEES_TYPE = 'jobsEmployees'

export const STAFF_TYPE = 'staff'

export const LOCATIONS_FIELD = 'areasLocations'

export const DEPARTMENTS_FIELD = 'areasDepartments'

export const JOBS_FIELD = 'areasJobs'

export const EMPLOYEES_FIELD = 'areasEmployees'

export const JOBS_EMPLOYEES_FIELD = 'areasJobsEmployees'

export const STAFF_FIELD = 'areasStaff'

const locationsFields = createFields(LOCATIONS_TYPE, LOCATIONS_FIELD)
const departmentsFields = createFields(DEPARTMENTS_TYPE, DEPARTMENTS_FIELD)
const jobsFields = createFields(JOBS_TYPE, JOBS_FIELD)
const employeesFields = createFields(EMPLOYEES_TYPE, EMPLOYEES_FIELD)

const jobsEmployeesFields = createFields(
  JOBS_EMPLOYEES_TYPE,
  JOBS_EMPLOYEES_FIELD,
)

const staffFields = createFields(STAFF_TYPE, STAFF_FIELD)

const initialState = Immutable({
  ...locationsFields,
  ...departmentsFields,
  ...jobsFields,
  ...employeesFields,
  ...jobsEmployeesFields,
  ...staffFields,
})

const handlers = {
  ...createReducerHandlers(LOCATIONS_TYPE, INIT_LOCATIONS, {
    withReplace: true,
    mapToKey: LOCATIONS_FIELD,
  }),

  [CLEAR_LOCATIONS]: state => {
    return state.merge(locationsFields)
  },

  ...createReducerHandlers(DEPARTMENTS_TYPE, INIT_DEPARTMENTS, {
    withReplace: true,
    mapToKey: DEPARTMENTS_FIELD,
  }),

  [CLEAR_DEPARTMENTS]: state => {
    return state.merge(departmentsFields)
  },

  ...createReducerHandlers(JOBS_TYPE, INIT_JOBS, {
    withReplace: true,
    mapToKey: JOBS_FIELD,
  }),

  [CLEAR_JOBS]: state => {
    return state.merge(jobsFields)
  },

  ...createReducerHandlers(EMPLOYEES_TYPE, INIT_EMPLOYEES, {
    withReplace: true,
    mapToKey: EMPLOYEES_FIELD,
  }),

  [CLEAR_STAFF]: state => state.merge(staffFields),

  ...createReducerHandlers(STAFF_TYPE, INIT_STAFF, {
    withReplace: true,
    mapToKey: STAFF_FIELD,
  }),

  [CLEAR_EMPLOYEES]: state => state.merge(employeesFields),

  ...createReducerHandlers(JOBS_EMPLOYEES_TYPE, INIT_JOBS_EMPLOYEES, {
    withReplace: true,
    mapToKey: JOBS_EMPLOYEES_FIELD,
  }),

  [CLEAR_JOBS_EMPLOYEES]: state => state.merge(jobsEmployeesFields),

  [CLEAR]: () => initialState,

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

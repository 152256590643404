import * as Areas from './Areas'
import * as ClassifierUtils from './Classifier'
import * as ColorUtils from './Colors'
import * as ConflictUtils from './Conflict'
import * as CustomField from './CustomFields'
import * as DateTimeUtils from './DateTime'
import * as EmployeeUtils from './Employee'
import * as GraphQL from './GraphQL'
import JsonApi from './JsonApi'
import { Monetary } from './Monetary'
import * as NumberUtils from './Numbers'
import * as ObjectUtils from './Object'
import * as Role from './Role'
import * as RuleUtils from './Rule'
import * as Schedule from './Schedule'
import * as ScheduleAcceptanceUtils from './ScheduleAcceptance'
import { Select } from './Select'
import * as ShiftUtils from './Shift'
import * as Strings from './Strings'
import * as Table from './Table'
import * as TimeBucketUtils from './TimeBucket'
import * as TimeEntryUtils from './TimeEntry'
import * as TimeoffUtils from './Timeoff'
import * as TimeRangeUtils from './TimeRange'
import * as UrlUtils from './Url'
import * as UserUtils from './User'
import * as Viewer from './Viewer'

export const Utils = {
  User: UserUtils,
  DateTime: DateTimeUtils,
  Number: NumberUtils,
  Employee: EmployeeUtils,
  Monetary,
  GraphQL,
  Url: UrlUtils,
  Color: ColorUtils,
  CustomField,
  Role,
  Strings,
  Select,
  Viewer,
  Areas,
  Schedule,
  JsonApi,
  Table,
  TimeEntry: TimeEntryUtils,
  Shift: ShiftUtils,
  Object: ObjectUtils,
  Timeoff: TimeoffUtils,
  TimeBucket: TimeBucketUtils,
  Rule: RuleUtils,
  Conflict: ConflictUtils,
  TimeRange: TimeRangeUtils,
  Classifier: ClassifierUtils,
  ScheduleAcceptance: ScheduleAcceptanceUtils,
}

export default Utils

import React from 'react'

import { Popover } from 'components/ui/__v2__/Popover'

import { i18n } from 'i18n'

import { AssignIcon, IconButton } from './styles'

type Props = {
  existing?: boolean
  disabled?: boolean
  onClick: () => void
}

export function AssignButton({ existing, disabled, onClick }: Props) {
  return (
    // @ts-ignore
    <Popover
      content={i18n(`shiftAssign.assign.${existing ? 'existing' : 'intended'}`)}
    >
      <IconButton disabled={disabled} onClick={onClick}>
        <AssignIcon />
      </IconButton>
    </Popover>
  )
}

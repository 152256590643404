import pDebounce from 'p-debounce'

import debounce from 'lodash/debounce'

const DEBOUNCE_TIMEOUT = 750

export function createDebounce(fn, options, timeout = DEBOUNCE_TIMEOUT) {
  return debounce(fn, timeout, options)
}

export function createpDebounce(
  fn,
  options = { leading: true, trailing: true },
  wait = DEBOUNCE_TIMEOUT,
) {
  return pDebounce(fn, wait, options)
}

import { useApolloMutation } from 'API/services/Apollo'

import { UpdateClassifierMutation } from './GraphQL'

export function useUpdateClassifier() {
  const [innerUpdate, { loading }] = useApolloMutation<
    MutationData<'updateClassifier'>,
    Gateway.MutationUpdateClassifierArgs
  >(UpdateClassifierMutation, {
    entity: 'settings.classifiers.title',
    mutationType: 'update',
  })

  async function updateClassifier({
    input,
    id,
  }: {
    input: Gateway.UpdateClassifierInput
    id: string
  }) {
    const result = await innerUpdate({
      variables: {
        id,
        input,
      },
    })
    return Boolean(result.errors)
  }

  return {
    updateClassifier,
    updatingClassifier: loading,
  }
}

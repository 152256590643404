import React, { Fragment, memo, useMemo } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import arrayMutators from 'final-form-arrays'

import noop from 'lodash/noop'

import componentMapper from 'components/blocks/__v2__/FormBuilderBlocks'
import { Button } from 'components/ui/__v2__/Button'
import { Divider } from 'components/ui/__v2__/Divider'
import { Flex } from 'components/ui/Grid'

import { parseValidationSchema } from 'helpers/formBuilder'

import useValidate from 'hooks/useValidate'

import _ from 'i18n'

import {
  FormContainer,
  FormDescription,
  FormElement,
  FormHeader,
  FormTitle,
} from './styles'

function FormGenerator({
  formObject,
  isReadOnly,
  initialValues,
  onValidForm,
  onClose,
}) {
  const { name, description, fields } = formObject

  const validationSchema = useMemo(() => {
    return parseValidationSchema(fields)
  }, [fields])

  const fieldsRender = useMemo(
    () =>
      fields
        .filter(field => !field.hideField)
        .map(field => {
          const { component: componentType, ...config } = field
          const Component = componentMapper[componentType]
          return (
            <Fragment key={config.name}>
              <FormElement>
                <Component {...{ ...config, isReadOnly }} />
              </FormElement>
              <Divider />
            </Fragment>
          )
        }),
    [fields, isReadOnly],
  )

  return (
    <Form
      initialValues={initialValues}
      mutators={{ ...arrayMutators }}
      validate={useValidate(validationSchema)}
      onSubmit={onValidForm}
    >
      {({ handleSubmit }) => (
        <FormContainer>
          <FormHeader>
            <FormTitle>{name}</FormTitle>
            <FormDescription>{description}</FormDescription>
          </FormHeader>
          <Divider />
          {fieldsRender}
          <Flex justifyContent="space-around" mt={3}>
            {onClose && (
              <Button secondary onClick={onClose}>
                {_('actions.close')}
              </Button>
            )}
            {onValidForm && (
              <Button onClick={handleSubmit}>{_('actions.save')}</Button>
            )}
          </Flex>
        </FormContainer>
      )}
    </Form>
  )
}

FormGenerator.defaultProps = {
  isReadOnly: false,
  onClose: noop,
  onValidForm: noop,
}

FormGenerator.propTypes = {
  formObject: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
  onClose: PropTypes.func,
  onValidForm: PropTypes.func,
}

export default memo(FormGenerator)

import React, { useCallback, useMemo } from 'react'

import { API } from 'API'

import { Select } from 'components/ui/__v3__/Select'

import { employmentTypeToOption } from './helpers'
import { EmploymentTypePickerOption, EmploymentTypePickerProps } from './types'

export function EmploymentTypePicker({
  value,
  onSelect,
  withArchived = false,
  withWorkflows,
  isMulti,
  ...rest
}: EmploymentTypePickerProps) {
  const {
    loadEmploymentTypes,
    employmentTypes,
    fetching,
  } = API.EmploymentType.picker({ withArchived, withWorkflows })

  const handleChange = isMulti
    ? (option: EmploymentTypePickerOption[] | null) => onSelect(option)
    : (option: EmploymentTypePickerOption | null) => onSelect(option)

  const handleMenuOpen = useCallback(() => {
    if (employmentTypes.length > 0) return
    loadEmploymentTypes()
  }, [employmentTypes.length, loadEmploymentTypes])

  const defaultOptions = useMemo(
    () => employmentTypes.map(employmentTypeToOption),
    [employmentTypes],
  )

  return (
    <Select
      // @ts-ignore
      closeMenuOnSelect={!isMulti}
      isClearable
      isLoading={fetching}
      isMulti={isMulti}
      isSearchable
      options={defaultOptions}
      value={value}
      withPortal
      onChange={handleChange}
      onMenuOpen={handleMenuOpen}
      {...rest}
    />
  )
}

import { AreasFilter } from 'Types/common'

type GraphQLAreasFilter = {
  locationId?: Gateway.InputMaybe<Gateway.IdFilter>
  departmentId?: Gateway.InputMaybe<Gateway.IdFilter>
  jobId?: Gateway.InputMaybe<Gateway.IdFilter>
  employeeId?: Gateway.InputMaybe<Gateway.IdFilter>
}

export function areasFilterToGraphql(areas?: AreasFilter) {
  if (!areas) return {}

  const filters: GraphQLAreasFilter = {}

  if (areas.locations) {
    if (Array.isArray(areas.locations)) {
      filters.locationId =
        areas.locations.length > 0
          ? { in: areas.locations.map(location => location.id!) }
          : undefined
    } else {
      filters.locationId = { in: [areas.locations.id] }
    }
  }

  if (areas.departments) {
    if (Array.isArray(areas.departments)) {
      filters.departmentId =
        areas.departments.length > 0
          ? { in: areas.departments.map(department => department.id!) }
          : undefined
    } else {
      filters.departmentId = { in: [areas.departments.id] }
    }
  }

  if (areas.jobs) {
    if (Array.isArray(areas.jobs)) {
      filters.jobId =
        areas.jobs.length > 0
          ? { in: areas.jobs.map(job => job.id!) }
          : undefined
    } else {
      filters.jobId = { in: [areas.jobs.id] }
    }
  }

  if (areas.employees) {
    const employeeIds = Array.isArray(areas.employees)
      ? areas.employees.map(employee => employee.id!)
      : [areas.employees.id!]

    filters.employeeId = {
      in: employeeIds,
    }
  }

  return filters
}

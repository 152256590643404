import { AiOutlinePauseCircle } from 'react-icons/ai'
import { MdKeyboardVoice } from 'react-icons/md'

import styled, { keyframes } from 'styled-components'

const fromRightToLeft = keyframes`
  0% { transform: translateX(60%); }
  100% { transform: translate(0); }
`
const fromLeftToRight = keyframes`
  0% { transform: translateX(-60%); }
  100% { transform: translate(0); }
`

export const NLPContainer = styled.div<{
  open: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 5%;
  left: ${props => (props.open ? '70vw' : '100vw')};
  width: 30vw;
  height: 100vh;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 5px;
  animation-name: ${props => (props.open ? fromRightToLeft : fromLeftToRight)};
  animation-timing-function: ease-out;
  animation-duration: 0.4s;

  background: ${({ theme }) => theme.layout.bg};
  z-index: ${({ theme }) => theme.zIndices.stickyHeader};
`

export const VoiceIcon = styled(MdKeyboardVoice)`
  color: ${({ theme }) => theme.layout.primary};
  :hover {
    cursor: pointer;
  }
`

export const ReplyContainer = styled.p`
  line-height: 24px;
  border-radius: 8px;
  padding: 22px 16px;
  padding-right: 30px;
  margin: 10px;
  min-height: 20vh;
  max-height: 50vh;
  width: 95%;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  white-space: pre-line;
  overflow-y: scroll;

  color: ${({ theme }) => theme.layout.text};
  background: ${({ theme }) => theme.input.bg.default};
  font-family: ${({ theme }) => theme.font};
`

export const PauseIcon = styled(AiOutlinePauseCircle)`
  color: ${({ theme }) => theme.labels.color.pending};
  stroke: ${({ theme }) => theme.layout.primary};
  stroke-width: 1px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`

export const TabsContainer = styled.div`
  margin-top: 30px;
  width: 60%;
`

import differenceWith from 'lodash/differenceWith'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import remove from 'lodash/remove'

import { PAGE_SIZE } from 'constants/pagination'
import { SHIFTS_REPEAT } from 'constants/shifts'

import { formatISOToYearMonthDayHoursMillis } from 'helpers/date'
import { createAsyncAction } from 'helpers/redux'
import { pausesComparator, updatedPausesComparator } from 'helpers/shifts'

import apiCall from 'services/API'

import { repeatShifts } from 'store/actions/helpers/shifts'
import { getWeek } from 'store/selectors/employees/shifts'
import { getBranchId } from 'store/selectors/viewer'

export const INIT = 'shifts/INIT'
export const INIT_DONE = 'shifts/INIT_DONE'
export const SHIFT_WEEK = 'shifts/SHIFT_WEEK'
export const LOAD_RANGE = createAsyncAction('shifts/LOAD_RANGE')
export const LOAD_EMPLOYEE_SHIFTS = createAsyncAction(
  'shifts/LOAD_EMPLOYEE_SHIFTS',
)
export const CREATE_SHIFT = createAsyncAction('shifts/CREATE')

export const EDIT_SHIFT = createAsyncAction('shifts/EDIT')
export const DELETE_SHIFTS = createAsyncAction('shifts/DELETE_SHIFTS')
export const CREATE_SHIFTS_JOBS = createAsyncAction('shifts/CREATE_JOBS')
export const UPDATE_SHIFTS_JOBS = createAsyncAction('shifts/UPDATE_JOBS')
export const DELETE_SHIFTS_JOBS = createAsyncAction('shifts/DELETE_JOBS')
export const CREATE_PAUSES = createAsyncAction('shifts/CREATE_PAUSES')
export const UPDATE_PAUSES = createAsyncAction('shifts/UPDATE_PAUSES')
export const DELETE_PAUSES = createAsyncAction('shifts/DELETE_PAUSES')
export const LOAD_SHIFT_AVAILABLE_EMPLOYEES = createAsyncAction(
  'shifts/LOAD_SHIFT_AVAILABLE_EMPLOYEES',
)
export const CLOSE_ASSIGN = 'shifts/CLOSE_ASSIGN'
export const COPY_PREV_WEEK_DONE = 'shifts/COPY_PREV_WEEK_DONE'
export const SET_COPY_PREV_WEEK_ACTIVE = 'shifts/SET_COPY_PREV_WEEK_ACTIVE'
export const LOAD_SHIFT_ASSIGNED_EMPLOYEES = createAsyncAction(
  'shifts/LOAD_SHIFT_ASSIGNED_EMPLOYEES',
)
export const TOGGLE_SHIFT = 'shifts/TOGGLE_SHIFT'
export const SELECT_ALL_SHIFTS = 'shifts/SELECT_ALL_SHIFTS'
export const DESELECT_ALL_SHIFTS = 'shifts/DESELECT_ALL_SHIFTS'
export const UPDATE_VIEW_FILTER_JOBS = 'shifts/UPDATE_VIEW_JOBS'

export const TOGGLE_VIEW_SORT_OPTION = 'shifts/TOGGLE_VIEW_SORT_OPTIONS'

export const init = () => ({ type: INIT })

export const initDone = () => ({ type: INIT_DONE })

const shiftsInclude = [
  'shiftsJobs.schedules.employee.profile',
  'pauses',
  'shiftsJobs.job',
  'shiftsJobs.shiftJobGroups',
  'shiftsJobs.shiftJobGroups.group',
]

export const loadShifts = (from, to) => (dispatch, getState) => {
  const branchId = getBranchId(getState())
  const week = getWeek(getState())
  const startAt = isEmpty(from) ? week.start : from
  const endAt = isEmpty(to) ? week.end : to
  dispatch(
    apiCall({
      endpoint: `/branches/${branchId}/relationships/shifts`,
      types: LOAD_RANGE,
      query: {
        filter: {
          start_at: formatISOToYearMonthDayHoursMillis(startAt),
          finish_at: formatISOToYearMonthDayHoursMillis(endAt),
        },
        include: shiftsInclude.join(),
        page: { size: PAGE_SIZE['50'] },
      },
      paged: true,
    }),
  )
}

const createShiftInclude = [
  'shiftsJobs.schedules.employee',
  'shiftsJobs.job',
  'shiftsJobs.shiftJobGroups',
  'shiftsJobs.shiftJobGroups.group',
  'pauses',
  'branch',
  'shiftTags',
  'timeBucket',
  'timeBucket.timeBucketParent',
]

export const createShift = ({
  branchId,
  name,
  timeFrom,
  timeTo,
  color,
  days,
  roles,
  repeat,
  pauses,
  untilDate,
  note,
  shiftTags,
  timezone,
  blockTrading,
  blockCoverage,
  timeBucketId,
} = {}) => {
  const [fromHrs, fromMins] = timeFrom.split(':')
  const [toHrs, toMins] = timeTo.split(':')

  const isOvernightShift = timeFrom >= timeTo

  const shifts = map(days, day => {
    const startAt = day.set({ hour: fromHrs, minute: fromMins })
    let finishAt = day.set({ hour: toHrs, minute: toMins })

    if (isOvernightShift) {
      finishAt = finishAt.plus({ days: 1 })
    }

    return {
      type: 'shifts',
      attributes: {
        name,
        shift_tag_ids: shiftTags,
        startAt: startAt
          .setZone(timezone, { keepLocalTime: true }) // Keeping the time, but switching the zone
          .toUTC()
          .toISO(),
        ...(note ? { note } : {}),
        finishAt: finishAt
          .setZone(timezone, { keepLocalTime: true })
          .toUTC()
          .toISO(),
        color,
        branchId,
        block_trading: blockTrading,
        block_donating: blockCoverage,
        ...(timeBucketId && { time_bucket_id: timeBucketId }),
      },
    }
  })

  const isRepeat = repeat === SHIFTS_REPEAT.UNTIL
  const repeatedShifts = repeatShifts(shifts, isRepeat, untilDate)

  const rolesWithGroups = roles.map(role => {
    return {
      ...role,
      shiftJobGroups: role.shiftJobGroups?.map(group => ({
        groupId: group.value,
        context: 'must',
      })),
    }
  })

  return apiCall({
    endpoint: '/shifts',
    method: 'POST',
    query: {
      data: repeatedShifts,
      include: createShiftInclude.join(),
    },
    types: CREATE_SHIFT,
    payload: {
      roles: rolesWithGroups,
      pauses,
    },
  })
}

export const createEmptyShifts = data => {
  return apiCall({
    endpoint: '/shifts',
    method: 'POST',
    query: {
      data,
      include: createShiftInclude.join(),
    },
    types: CREATE_SHIFT,
    payload: {
      isImport: true,
    },
  })
}

const editShiftInclude = [
  'shiftsJobs.schedules.shiftsJob',
  'shiftsJobs.schedules.employee',
  'shiftsJobs.schedules.shift',
  'shiftsJobs.job',
  'shiftsJobs.shiftJobGroups',
  'shiftsJobs.shiftJobGroups.group',
  'pauses',
  'branch',
  'shiftTags',
  'timeBucket',
  'timeBucket.timeBucketParent',
]

export const editShift = (
  shift,
  {
    name,
    startAt,
    finishAt,
    color,
    pauses,
    note,
    shiftTags,
    blockTrading,
    blockCoverage,
    timeBucketId,
  },
) => dispatch => {
  const oldPauses = map(shift?.pauses ?? [], pause => ({
    id: pause.id,
    duration: pause.duration,
    paid: pause.paid,
  }))

  const droppedPauses = differenceWith(oldPauses, pauses, pausesComparator)
  const updatedPauses = differenceWith(
    pauses,
    oldPauses,
    updatedPausesComparator,
  )
  const newPauses = remove(updatedPauses, item => !item.id)

  const data = {
    type: 'shifts',
    attributes: {
      name,
      note,
      shift_tag_ids: shiftTags,
      startAt,
      finishAt,
      block_trading: blockTrading,
      block_donating: blockCoverage,
      time_bucket_id: timeBucketId,
      ...(color ? { color } : {}),
    },
  }

  return dispatch(
    apiCall({
      endpoint: `/shifts/${shift.id}`,
      method: 'PATCH',
      query: {
        data,
        include: editShiftInclude.join(),
      },
      types: EDIT_SHIFT,
      payload: {
        droppedPauses,
        newPauses,
        updatedPauses,
        // ...payload,
      },
    }),
  )
}

export const createShiftsJobs = (
  { shifts, roles },
  isLastCopy = false,
) => dispatch => {
  const data = roles.reduce((jobs, role) => {
    shifts.forEach(shiftId => {
      jobs.push({
        type: 'shifts_jobs',
        attributes: {
          quantity: role.amount,
          shiftJobGroups: role?.shiftJobGroups,
          open: role.open,
        },
        relationships: {
          job: {
            data: {
              type: 'jobs',
              id: role.id,
            },
          },
          department: {
            data: {
              type: 'departments',
              id: role.departmentId,
            },
          },
          shift: {
            data: {
              type: 'shifts',
              id: shiftId,
            },
          },
        },
      })
    })

    return jobs
  }, [])

  dispatch(
    apiCall({
      endpoint: '/shifts_jobs',
      method: 'POST',
      query: {
        data,
        include: 'shift',
      },
      types: CREATE_SHIFTS_JOBS,
      payload: {
        roles,
        isLastCopy,
      },
    }),
  )
}

const availableEmployeesInclude = [
  'availabilities',
  'conflictShiftsJobs.job.department',
  'conflictShiftsJobs.shift.branch',
  'jobs',
  'jobsEmployees.job.department',
  'jobsEmployees.jobsTags',
  'preferences',
  'profile.customCvFieldValues.customCvField',
  'profile.profileAvatar',
  'schedules',
  'user.profile',
]
const EMPLOYEES_PAGE_SIZE = 999
export const loadShiftAvailableEmployees = shiftId =>
  apiCall({
    endpoint: `/shifts/${shiftId}/available_employees`,
    query: {
      include: availableEmployeesInclude.join(),
      page: { size: EMPLOYEES_PAGE_SIZE },
    },
    types: LOAD_SHIFT_AVAILABLE_EMPLOYEES,
    paged: true,
  })

export const updateShiftsJobsInclude = [
  'schedules.employee.profile',
  'schedules.shiftsJob',
  'schedules.shiftsJob.shiftJobGroups',
  'schedules.shiftsJob.shiftJobGroups.group',
  'shift.shiftsJobs.shiftJobGroups',
  'shift.shiftsJobs.shiftJobGroups.group',
]

export const updateShiftsJobsEmployees = (
  shiftsJobsEmployees,
  isLastCopy = false,
) => dispatch => {
  const data = map(Array.from(shiftsJobsEmployees.keys()), key => ({
    id: key,
    type: 'shifts_jobs',
    relationships: {
      employees: {
        data: shiftsJobsEmployees.get(key).map(employee => ({
          type: 'employees',
          id: employee,
        })),
      },
    },
  }))

  return dispatch(
    apiCall({
      endpoint: '/shifts_jobs',
      method: 'PATCH',
      query: {
        include: updateShiftsJobsInclude.join(),
        data,
      },
      types: UPDATE_SHIFTS_JOBS,
      payload: {
        isLastCopy,
      },
    }),
  )
}

export const closeAssign = () => ({
  type: CLOSE_ASSIGN,
})

export const setCopyPrevWeekActive = () => ({
  type: SET_COPY_PREV_WEEK_ACTIVE,
})

export const copyPrevWeekDone = () => ({
  type: COPY_PREV_WEEK_DONE,
})

const assignedEmployeesInclude = [
  'availabilities',
  'jobsEmployees.job',
  'jobsEmployees.jobsTags',
  'preferences',
  'profile.profileAvatar',
  'schedules',
]

export const loadShiftAssignedEmployees = shiftId =>
  apiCall({
    endpoint: `/shifts/${shiftId}/employees`,
    query: {
      include: assignedEmployeesInclude.join(),
      page: { size: EMPLOYEES_PAGE_SIZE },
    },
    types: LOAD_SHIFT_ASSIGNED_EMPLOYEES,
    paged: true,
  })

export const updateFilterViewJobs = jobIds => ({
  type: UPDATE_VIEW_FILTER_JOBS,
  payload: { jobIds },
})

export const createPauses = ({ shifts, pauses }) => dispatch => {
  const data = pauses.reduce((allPauses, pause) => {
    const { duration, paid } = pause

    shifts.forEach(shiftId => {
      allPauses.push({
        type: 'pauses',
        attributes: {
          duration,
          paid,
        },
        relationships: {
          shifts: {
            data: {
              type: 'shifts',
              id: shiftId,
            },
          },
        },
      })
    })
    return allPauses
  }, [])

  dispatch(
    apiCall({
      endpoint: '/pauses',
      method: 'POST',
      query: {
        data,
        include: 'shift',
      },
      types: CREATE_PAUSES,
    }),
  )
}

export const updatePauses = ({ shifts, pauses }) => dispatch => {
  const data = pauses.reduce((allPauses, pause) => {
    const { duration, paid, id } = pause
    shifts.forEach(shiftId => {
      allPauses.push({
        id,
        type: 'pauses',
        attributes: {
          duration,
          paid,
        },
        relationships: {
          shifts: {
            data: {
              type: 'shifts',
              id: shiftId,
            },
          },
        },
      })
    })
    return allPauses
  }, [])
  dispatch(
    apiCall({
      endpoint: '/pauses',
      method: 'PATCH',
      query: {
        data,
        include: 'shift',
      },
      types: UPDATE_PAUSES,
    }),
  )
}

export const deletePauses = (shiftId, pauses) => dispatch => {
  dispatch(
    apiCall({
      endpoint: '/pauses',
      method: 'DELETE',
      query: {
        data: pauses,
      },
      types: DELETE_PAUSES,
      payload: {
        deletedItems: pauses,
        relationId: shiftId,
      },
    }),
  )
}

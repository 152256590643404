export default function extendsSelectableRowDisabled({
  selectableRowDisabled,
}) {
  return row => {
    if (row.search) {
      return true
    }

    if (selectableRowDisabled) {
      return selectableRowDisabled(row)
    }

    return false
  }
}

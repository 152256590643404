import React from 'react'

import loadable from '@loadable/component'

import { SpinnerBig } from 'components/ui/Spinner'

export const Archived = loadable(
  () => import('./Archived' /* webpackChunkName: "pages-archived" */),
  { fallback: <SpinnerBig /> },
)

import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import {
  FlexboxProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
} from 'styled-system'

import { Flex } from 'components/ui/__v2__/Grid'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'

import { LeaveDurationTypeSelector } from '../LeaveDurationTypeSelector'

type StyleProps = FlexboxProps & LayoutProps & PositionProps & SpaceProps
type Props<T extends FieldValues> = ControllerBaseProps<T> & StyleProps

export function FormLeaveDurationTypeSelector<T extends FieldValues>({
  control,
  name,
  ...rest
}: Props<T>) {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  return (
    <Flex {...rest} flexDirection="column">
      <LeaveDurationTypeSelector
        name={name}
        partial={value}
        onChange={onChange}
      />
      {error && <Error error={error} />}
    </Flex>
  )
}

import React, { useState } from 'react'
import { Virtuoso } from 'react-virtuoso'

import { ShiftLogApi } from 'API/ShiftLog'

import { Box, Flex, SpacedRow } from 'components/ui/__v2__/Grid'
import { LoadMoreStatus, Select } from 'components/ui/__v3__'
import { Spinner } from 'components/ui/Spinner'

import { useI18n } from 'hooks'

import { LogItem } from './components'
import { Fallback } from './styles'

type Props = { shiftId: string }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function ShiftLogs({ shiftId }: Props) {
  const t = useI18n('shiftLogs')

  const [kind] = useState(null)

  const { shiftLogsLoading, shiftLogs } = ShiftLogApi.byCursor()

  if (shiftLogsLoading)
    return (
      <Box maxHeight="50vh" minHeight="300px">
        <Flex alignItems="center" justifyContent="center" width="100%">
          <Spinner primary size={28} />
        </Flex>
      </Box>
    )

  return (
    <>
      <SpacedRow>
        <Select
          // @ts-ignore
          isClearable
          isSearchable
          labelContent={t('kindLabel')}
          options={[]}
          placeholder={t('kindPlaceholder')}
          value={kind}
          withPortal
        />
      </SpacedRow>
      <Box maxHeight="50vh" minHeight="300px" overflow="auto">
        {shiftLogs.length > 0 ? (
          <>
            <Virtuoso<any>
              data={shiftLogs}
              itemContent={(_, logItem) => <LogItem data={logItem} />}
            />

            <LoadMoreStatus
              // TODO: connect to the API
              fetchingMore={shiftLogsLoading}
              hasMore={false}
              onClick={() => {}}
            />
          </>
        ) : (
          <Fallback>{t('noActionsLoggedYet')}</Fallback>
        )}
      </Box>
    </>
  )
}

import { useApolloMutation } from 'API/services/Apollo'

import { CloneRulesetMutation } from './GraphQL'

export function useCloneRuleset() {
  const [innerClone, { loading: updating }] = useApolloMutation<
    MutationData<'cloneRuleset'>,
    Gateway.MutationCloneRulesetArgs
  >(CloneRulesetMutation, {
    translationKey: 'ruleset.toasts.clone',
  })

  const cloneRuleset = async (input: Gateway.CloneRulesetInput) =>
    innerClone({
      variables: {
        input,
      },
    })

  return { cloneRuleset, updating }
}

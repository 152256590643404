import { useApolloLazyQuery } from 'API/services/Apollo'

import isNil from 'lodash/isNil'

import {
  SortingDirection,
  TimeBucketSortingField,
} from 'constants/gatewayGraphQL'
import { PAGE_SIZE } from 'constants/pagination'

import Utils from 'services/Utils'

import {
  TimeBucketChildCoreWithParentAPIItem,
  TimeBucketChildrenPickerQuery,
  TimeBucketChildrenPickerQueryData,
} from './GraphQL'

export function useTimeBucketChildrenPicker() {
  const [
    loadTimeBucketsChildrenInner,
    { loading: timeBucketsChildrenLoading, data, refetch: innerRefetch },
  ] = useApolloLazyQuery<
    TimeBucketChildrenPickerQueryData,
    Gateway.QueryTimeBucketChildrenByCursorArgs
  >(TimeBucketChildrenPickerQuery, {
    fetchPolicy: 'network-only',
  })

  const loadTimeBucketsChildren = ({
    archived,
    parentIds,
  }: {
    parentIds?: string[]
    archived?: boolean
  }) => {
    loadTimeBucketsChildrenInner({
      variables: {
        paging: { limit: PAGE_SIZE['10'] },
        sorting: {
          direction: SortingDirection.Asc,
          field: TimeBucketSortingField.Name,
        },
        filter: {
          parentId: parentIds?.length
            ? Utils.GraphQL.inFilter(parentIds)
            : undefined,
          archived: !isNil(archived)
            ? Utils.GraphQL.eqFilter(archived)
            : undefined,
        },
      },
    })
  }

  const refetchTimeBucketsChildren = async ({
    inputValue,
    parentIds,
    archived,
    withParentsNames,
  }: {
    inputValue?: string
    parentIds?: string[]
    archived?: boolean
    withParentsNames?: boolean
  } = {}) => {
    if (!innerRefetch) return []

    let searchFilter: Gateway.TimeBucketChildFilter = {}

    if (withParentsNames && inputValue) {
      searchFilter = {
        parentOrChildSearch: {
          ilike: inputValue,
        },
      }
    } else if (inputValue) {
      searchFilter = {
        search: {
          ilike: inputValue,
        },
      }
    }

    const result = await innerRefetch({
      filter: {
        ...searchFilter,
        parentId: parentIds?.length
          ? Utils.GraphQL.inFilter(parentIds)
          : undefined,
        archived: !isNil(archived)
          ? Utils.GraphQL.eqFilter(archived)
          : undefined,
      },
    })
    return dataToNodes(result.data)
  }

  const timeBucketsChildren = dataToNodes(data)

  return {
    timeBucketsChildren,
    timeBucketsChildrenLoading,
    refetchTimeBucketsChildren,
    loadTimeBucketsChildren,
  }
}

function dataToNodes(
  data?: TimeBucketChildrenPickerQueryData,
): TimeBucketChildCoreWithParentAPIItem[] {
  return data?.timeBucketChildrenByCursor?.edges.map(edge => edge.node) ?? []
}

import React, { useMemo } from 'react'
import { toastr } from 'react-redux-toastr'
import PropTypes from 'prop-types'

import FormGenerator from 'components/blocks/__v2__/FormGenerator/FormGenerator'
import { Modal } from 'components/ui/__v2__/Modal'

import { getInitialValuesFromConfig } from 'helpers/formBuilder'

import _ from 'i18n'

function FormPreview({ isOpen, onClose, formObject }) {
  const { fields } = formObject

  function onValidForm() {
    toastr.success(_('formBuilder.toasts.simulationSuccess'))
  }

  const initialValues = useMemo(() => getInitialValuesFromConfig(fields), [
    fields,
  ])

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Close onClose={onClose} />
      <Modal.Title>{_('formBuilder.formPreview')}</Modal.Title>
      <FormGenerator
        formObject={formObject}
        initialValues={initialValues}
        onClose={onClose}
        onValidForm={onValidForm}
      />
    </Modal>
  )
}

FormPreview.propTypes = {
  ...Modal.propTypes,
  formObject: PropTypes.object.isRequired,
}

export default FormPreview

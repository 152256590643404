import React, { useCallback, useMemo, useState } from 'react'

import { trim } from 'lodash'

import { i18n } from 'i18n'

import { useDebouncedValue } from './useDebouncedValue'

import { Input } from '../components/ui/__v3__/Input'

type Props = {
  token: string
}

const DEBOUNCE_DELAY = 500

export function useHardConfirmation({ token }: Props) {
  const [input, setInput] = useState('')
  const debouncedInput = useDebouncedValue(input, DEBOUNCE_DELAY)

  const renderConfirmationInput = useCallback(() => {
    return (
      <Input
        labelText={i18n('hardConfirmation.label')}
        placeholder={i18n('hardConfirmation.placeholder')}
        required
        value={input}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setInput(e.target.value)
        }
      />
    )
  }, [input])

  const confirmed = useMemo(() => trim(debouncedInput) === token, [
    debouncedInput,
    token,
  ])

  return { confirmed, renderConfirmationInput }
}

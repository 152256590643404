import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { vestResolver } from '@hookform/resolvers/vest'

import { isEqual } from 'lodash'

import usePrevious from 'hooks/usePrevious'

import { validator } from './helpers'
import { MultipleSliceValueFormState, ValidatorProperties } from './types'

type Props = {
  values: MultipleSliceValueFormState
  validatorProperties?: ValidatorProperties
}

export function useMultiSliceForm({ values, validatorProperties }: Props) {
  const prevCvFieldValues = usePrevious(values.slices)

  const {
    formState: { isValidating, isSubmitting, isValid, isDirty },
    control,
    trigger,
    reset,
    handleSubmit: innerHandleSubmit,
  } = useForm<MultipleSliceValueFormState>({
    defaultValues: values,
    resolver: vestResolver(validator(validatorProperties)),
    mode: 'onBlur',
  })

  useEffect(() => {
    if (!isEqual(prevCvFieldValues, values.slices)) {
      reset(values)
    }
  }, [values, prevCvFieldValues, reset])

  return {
    control,
    isValidating,
    isSubmitting,
    isValid,
    isDirty,
    innerHandleSubmit,
    trigger,
  }
}

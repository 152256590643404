import { AccessLevel } from 'constants/ids'

export enum Actions {
  create = 'create',
  view = 'view',
  edit = 'update',
  remove = 'remove',
  doAction = 'doAction',
  copy = 'copy',
  assign = 'assign',
}

export const management = [
  AccessLevel.Owner,
  AccessLevel.Admin,
  AccessLevel.BranchManager,
  AccessLevel.DepartmentManager,
]

export const mutants = [
  AccessLevel.BranchManager,
  AccessLevel.DepartmentManager,
  AccessLevel.Employee,
]
export const everyone = [
  AccessLevel.Owner,
  AccessLevel.Admin,
  AccessLevel.BranchManager,
  AccessLevel.DepartmentManager,
  AccessLevel.Employee,
]
export const administration = [AccessLevel.Owner, AccessLevel.Admin]
export const noone: AccessLevel[] = []

export enum ProfileConditions {
  OwnProfile = 'isOwnProfile',
  OthersProfile = 'isOtherProfile',
}

export enum ScheduleConditions {
  WithAssignedEmployee = 'withAssignedEmployee',
  WithoutAssignedEmployee = 'withoutAssignedEmployee',
}

export enum ACLSubjects {
  ProfileFeature = 'ProfileFeature',
  SchedulesFeature = 'SchedulesFeature',
  StaffManagementFeature = 'StaffManagementFeature',
  TimecardManagementFeature = 'TimecardManagementFeature',
  WeeklyTimesheetsManagementFeature = 'WeeklyTimesheetsManagementFeature',
  PayrollManagementFeature = 'PayrollManagementFeature',
  TimeoffFeature = 'TimeoffFeature',
  BuilderFeature = 'BuilderFeature',
  MetricsFeature = 'MetricsFeature',
  IntegrationsFeature = 'IntegrationsFeature',
  CustomFieldsFeature = 'CustomFieldsFeature',
  SettingsFeature = 'SettingsFeature',
  TimeBucketFeature = 'TimeBucketFeature',
}

import { SelectOption } from 'Types/common'

import { capitalize } from 'lodash'

interface RoleOption extends SelectOption {
  departmentId: string
}

function transformText(
  value: string,
  transform: 'none' | 'capitalize' | 'upperCase' | 'lowerCase',
) {
  if (transform === 'capitalize') return capitalize(value)
  if (transform === 'upperCase') return value.toUpperCase()
  if (transform === 'lowerCase') return value.toLowerCase()
  return value
}

export const Select = Object.freeze({
  generateOption<T extends string | number = string>(
    value: T,
  ): SelectOption<T> {
    return {
      label: transformText(String(value), 'none'),
      value,
    }
  },

  generateUpperCaseOption<T extends string | number = string>(
    value: T,
  ): SelectOption<T> {
    return {
      label: transformText(String(value), 'upperCase'),
      value,
    }
  },

  generateCapitalizeOption<T extends string | number = string>(
    value: T,
  ): SelectOption<T> {
    return {
      label: transformText(String(value), 'capitalize'),
      value,
    }
  },

  // In order to the highlighting with the same roles
  // in different departments is not highlighted as one
  checkRoleOptionForSelected(
    option: RoleOption,
    selectedOptions: Array<RoleOption>,
  ) {
    return selectedOptions.find(
      selectedOption =>
        option.value === selectedOption.value &&
        option.departmentId === selectedOption.departmentId,
    )
  },
})

import { useMemo } from 'react'

import { useApolloQuery } from 'API/services/Apollo'

import {
  ClassifiedTimesByOffsetQuery,
  ClassifiedTimesByOffsetQueryData,
} from './GraphQL'

export function useClassifiedTimesByOffset({
  offset,
  filter,
  limit,
  sorting,
}: {
  offset: number
  limit: number
  filter?: Gateway.ClassifiedTimeFilterInput
  sorting: Gateway.ClassifiedTimeSortingInput[]
}) {
  const { data, loading, refetch } = useApolloQuery<
    ClassifiedTimesByOffsetQueryData,
    Gateway.QueryClassifiedTimesByOffsetArgs
  >(ClassifiedTimesByOffsetQuery, {
    variables: {
      filter,
      paging: { offset, limit },
      sorting,
    },
    fetchPolicy: 'network-only',
  })

  const classifiedTimes = useMemo(
    () => data?.classifiedTimesByOffset?.rows ?? [],
    [data],
  )

  const totalClassifiedTimes = useMemo(
    () => data?.classifiedTimesByOffset?.pageInfo?.total ?? 0,
    [data],
  )

  return {
    classifiedTimes,
    totalClassifiedTimes,
    loadingClassifiedTimes: loading,
    refetchClassifiedTimes: refetch,
  }
}

import { StoreonModule } from 'storeon'

import {
  EVALUATED_TIME_SYNC_KEY,
  EVALUATED_TIME_SYNC_SET,
  EVALUATED_TIME_SYNC_UNSET,
  INITIAL_STATE,
} from './constants'
import { EvaluatedTimeSyncEvents, EvaluatedTimeSyncState } from './types'

import { INIT } from '../../constants'

export const evaluatedTimeSyncModule: StoreonModule<
  EvaluatedTimeSyncState,
  EvaluatedTimeSyncEvents
> = store => {
  store.on(INIT, () => ({ ...INITIAL_STATE }))

  store.on(EVALUATED_TIME_SYNC_SET, (state, { syncProcessId }) => ({
    ...state,
    [EVALUATED_TIME_SYNC_KEY]: syncProcessId,
  }))
  store.on(EVALUATED_TIME_SYNC_UNSET, state => ({
    ...state,
    [EVALUATED_TIME_SYNC_KEY]: undefined,
  }))
}

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import set from 'lodash/set'
import snakeCase from 'lodash/snakeCase'
import startsWith from 'lodash/startsWith'

import { ReferenceYearEndDateKinds } from 'constants/ids'
import { CUSTOM_LABEL_TYPE } from 'constants/labelDayType'

import { areasToRelationships } from 'helpers/areas'
import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

export const LOAD_CUSTOM_LABEL_DAYS = createAsyncAction('customLabelDays/LOAD')
export const CREATE_CUSTOM_LABEL_DAY = createAsyncAction(
  'customLabelDays/CREATE',
)
export const DELETE_CUSTOM_LABEL = createAsyncAction('customLabelDays/DELETE')
export const UPDATE_CUSTOM_LABEL = createAsyncAction('customLabelDays/UPDATE')

const customLabelDaysIncludes = [
  'employees.profile',
  'branches',
  'departments',
  'jobs',
  'earning_type',
  'groups',
  'default_time_type',
]

const FILTERS = {
  name: 'name',
  effectiveDates: 'effective_dates',
  year: 'year',
  partial: 'partial',
}

export const loadCustomLabelDays = ({
  sort = 'id',
  filters = {
    branchIds: [],
    departmentIds: [],
    jobIds: [],
    employeeIds: [],
    effectiveDates: [],
    name: null,
    year: null,
  },
  number = 1,
  size = 10,
  paged = true,
} = {}) => dispatch => {
  const filter = {}
  const page = paged ? { number, size } : {}

  if (!isEmpty(filters.branchIds)) {
    filter.branch_id = { in: filters.branchIds }
  }
  if (!isEmpty(filters.departmentIds)) {
    filter.department_id = { in: filters.departmentIds }
  }
  if (!isEmpty(filters.jobIds)) {
    filter.job_id = { in: filters.jobIds }
  }
  if (!isEmpty(filters.employeeIds)) {
    filter.employee_id = { in: filters.employeeIds }
  }
  if (!isEmpty(filters.year)) filter.year = { eq: filters.year }

  if (!isEmpty(filters.name))
    set(filter, FILTERS.name, { ilike: `${get(filters, 'name')}` })

  if (!isEmpty(filters.effectiveDates)) {
    filter.effective_date = filters.effectiveDates.map(({ start, end }) => ({
      between: [start, end],
    }))
  }

  const desc = startsWith(sort, '-')

  return dispatch(
    apiCall({
      endpoint: '/custom_leave_days',
      types: LOAD_CUSTOM_LABEL_DAYS,
      query: {
        include: join(customLabelDaysIncludes, ','),
        sort: (desc ? '-' : '') + snakeCase(sort),
        filter,
        page,
      },
      paged,
    }),
  )
}

export const loadCustomLabelDaysForEmployee = ({
  employeeId,
  filters = { name: null, effectiveDates: null, partial: null },
}) => {
  const filter = {}

  if (!isEmpty(filters.name))
    set(filter, FILTERS.name, { ilike: `${get(filters, 'name')}` })
  if (!isEmpty(filters.effectiveDates))
    set(filter, FILTERS.effectiveDates, {
      between: [
        get(filters, 'effectiveDates.from'),
        get(filters, 'effectiveDates.to'),
      ],
    })
  if (!isEmpty(filters.partial))
    set(filter, FILTERS.partial, { eq: filters.partial })

  return apiCall({
    endpoint: `/employees/${employeeId}/custom_leave_days`,
    types: LOAD_CUSTOM_LABEL_DAYS,
    query: {
      include: join(customLabelDaysIncludes, ','),
      filter,
    },
  })
}

function groupOptionsToRelationships(groupOptions, { withEmpty = false } = {}) {
  if (!groupOptions && withEmpty) {
    return { groups: { data: [] } }
  }

  return {
    groups: {
      data: groupOptions
        ? groupOptions.map(group => ({ id: group.value, type: 'groups' }))
        : [],
    },
  }
}

export const createCustomLabelDay = ({
  global,
  areas,
  description,
  effectiveDates,
  kind,
  leaveId,
  name,
  paid,
  recurrence,
  partial,
  startOn,
  finishOn,
  useAsLabel,
  earningTypeId,
  createAsTimeoff,
  allowMoving,
  scheduled,
  policyKind,
  referenceYearEndRecurrence,
  referenceYearEndKind,
  statutory,
  groups,
  blockEmployeeRequest,
  defaultTimeTypeId,
}) => dispatch => {
  const attributes = {
    description,
    effectiveDates,
    finishOn: null,
    leave_id: leaveId,
    earningTypeId,
    partial,
    name,
    paid,
    scheduled,
    startOn: null,
    useAsLabel,
    global,
    defaultTimeTypeId,
  }

  if (!kind) {
    attributes.policyKind = policyKind
    attributes.blockEmployeeRequest = blockEmployeeRequest
    attributes.referenceYearEndKind = referenceYearEndKind
    if (referenceYearEndKind === ReferenceYearEndDateKinds.Static)
      attributes.referenceYearEndRecurrence = referenceYearEndRecurrence
  }

  if (kind === CUSTOM_LABEL_TYPE.WEEKLY_VACATION) {
    attributes.kind = kind
    attributes.recurrence = recurrence
    attributes.startOn = startOn
    attributes.referenceYearEndRecurrence = referenceYearEndRecurrence
  }

  if (kind === CUSTOM_LABEL_TYPE.HOLIDAY) {
    attributes.kind = kind
    attributes.recurrence = recurrence
    attributes.createAsTimeoff = createAsTimeoff
    attributes.allowMoving = allowMoving
    attributes.statutory = statutory
  }

  if (scheduled) {
    attributes.startOn = startOn
    attributes.finishOn = finishOn
  }

  return dispatch(
    apiCall({
      endpoint: '/custom_leave_days',
      types: CREATE_CUSTOM_LABEL_DAY,
      method: 'POST',
      query: {
        include: join(customLabelDaysIncludes, ','),
        data: {
          attributes,
          relationships: {
            ...areasToRelationships(areas),
            ...groupOptionsToRelationships(groups),
          },
        },
      },
    }),
  )
}

export const updateCustomLabel = ({
  areas,
  global,
  description,
  effectiveDates,
  entityId,
  kind,
  leaveId,
  name,
  paid,
  partial,
  recurrence,
  startOn,
  useAsLabel,
  earningTypeId,
  createAsTimeoff,
  allowMoving,
  scheduled,
  policyKind,
  referenceYearEndRecurrence,
  statutory,
  finishOn,
  groups,
  blockEmployeeRequest,
  defaultTimeTypeId,
}) => dispatch => {
  const attributes = {
    description,
    effectiveDates,
    finishOn: null,
    leave_id: leaveId,
    earningTypeId,
    name,
    paid,
    partial,
    scheduled,
    startOn: null,
    useAsLabel,
    global,
    defaultTimeTypeId,
  }

  if (!kind) {
    attributes.policyKind = policyKind
    attributes.referenceYearEndRecurrence = referenceYearEndRecurrence
    attributes.blockEmployeeRequest = blockEmployeeRequest
  }

  if (kind === CUSTOM_LABEL_TYPE.WEEKLY_VACATION) {
    attributes.kind = kind
    attributes.recurrence = recurrence
    attributes.startOn = startOn
    attributes.policyKind = policyKind
    attributes.referenceYearEndRecurrence = referenceYearEndRecurrence
  }

  if (kind === CUSTOM_LABEL_TYPE.HOLIDAY) {
    attributes.kind = kind
    attributes.recurrence = recurrence
    attributes.createAsTimeoff = createAsTimeoff
    attributes.allowMoving = allowMoving
    attributes.statutory = statutory
  }

  if (scheduled) {
    attributes.startOn = startOn
    attributes.finishOn = finishOn
  }

  return dispatch(
    apiCall({
      endpoint: `/custom_leave_days/${entityId}`,
      types: CREATE_CUSTOM_LABEL_DAY,
      method: 'PATCH',
      query: {
        include: join(customLabelDaysIncludes, ','),
        data: {
          attributes,
          relationships: {
            ...areasToRelationships(areas, { withEmpty: true }),
            ...groupOptionsToRelationships(groups, { withEmpty: true }),
          },
        },
      },
    }),
  )
}

export const deleteCustomLabel = customLabelDayId => dispatch => {
  return dispatch(
    apiCall({
      endpoint: `/custom_leave_days/${customLabelDayId}`,
      method: 'DELETE',
      types: DELETE_CUSTOM_LABEL,
      payload: {
        // TODO: revert after backend changes id to strings
        deletedId: `${customLabelDayId}`,
      },
    }),
  )
}

export const loadUsedLeaveNames = ({
  filters = {
    employeeIds: [],
  },
  display = 'used',
} = {}) => (dispatch, getState) => {
  const filter = {}
  const companyId = getCompanyId(getState())

  if (!isEmpty(filters.employeeIds)) {
    filter.employee_id = { in: filters.employeeIds }
  }

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/custom_leave_days`,
      types: LOAD_CUSTOM_LABEL_DAYS,
      query: {
        filter,
        display,
      },
    }),
  )
}

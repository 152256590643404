import { graphQLResultHasError } from '@apollo/client/utilities'
import { useApolloMutation } from 'API/services/Apollo'

import { DeleteShiftTagMutation } from './GraphQL'

export function useRemoveShiftTag() {
  const [deleteShiftTag, { loading: removing }] = useApolloMutation<
    MutationData<'deleteShiftTag'>,
    Gateway.MutationDeleteShiftTagArgs
  >(DeleteShiftTagMutation, { translationKey: 'shiftTags.toasts.delete' })

  const remove = async (id: Gateway.MutationDeleteShiftTagArgs['id']) => {
    const result = await deleteShiftTag({ variables: { id } })
    return graphQLResultHasError(result)
  }

  return { remove, removing }
}

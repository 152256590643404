import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import PropTypes from 'prop-types'

import DateRangePicker from 'components/blocks/__v3__/DateRangePicker'
import { Button } from 'components/ui/__v2__/Button'
import { Flex } from 'components/ui/__v2__/Grid'
import Modal from 'components/ui/__v2__/Modal/Modal'

import { i18n } from 'i18n'

export function FormDateRangePickerModal({ isOpen, handleCloseModal, input }) {
  const { change, submit } = useForm()
  const { name, value } = input

  const [effectiveDates, setEffectiveDates] = useState(value)
  const [effectiveDatesSubmitted, setEffectiveDatesSubmitted] = useState(false)

  const handleChangeEffectivePeriod = useCallback(dates => {
    setEffectiveDates({ ...dates })
  }, [])

  const handleSubmit = async () => {
    change(name, effectiveDates)
    setEffectiveDatesSubmitted(true)
  }

  useEffect(() => {
    if (effectiveDatesSubmitted) {
      submit()
      handleCloseModal()
    }
  }, [effectiveDatesSubmitted, handleCloseModal, submit])

  return (
    <Modal isOpen={isOpen} onRequestClose={handleCloseModal}>
      <Modal.Close onClose={handleCloseModal} />
      <Modal.Title>{i18n('employees.changeEffectivePeriod')}</Modal.Title>

      <Flex pb={5} pt={4} px={4} width={500}>
        <DateRangePicker
          end={effectiveDates.end}
          minimalDays={2}
          start={effectiveDates.start}
          onChange={handleChangeEffectivePeriod}
        />
      </Flex>

      <Modal.Actions justifyContent="flex-end">
        <Button mr={4} secondary width={100} onClick={handleCloseModal}>
          {i18n('actions.cancel')}
        </Button>
        <Button width={100} onClick={handleSubmit}>
          {i18n('actions.confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

FormDateRangePickerModal.defaultProps = {
  isOpen: false,
}

FormDateRangePickerModal.propTypes = {
  handleCloseModal: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
}

import { useApolloMutation } from 'API/services/Apollo'

import { EffectiveFieldValuePair } from 'pages/EmployerApp/People/pages/PeopleTable/components/CustomFieldEdit/types'
import { StaffFilters } from 'pages/EmployerApp/People/pages/PeopleTable/hooks/types'

import { BulkUpdateCustomCvFieldValuesMutation } from './GraphQL'
import { mapFormToBulkUpdateInput } from './helpers'

export function useBulkUpdateCustomCvFieldValues() {
  const [
    bulkUpdateCustomCvFieldValues,
    { loading: updating },
  ] = useApolloMutation<
    MutationData<'bulkUpdateCustomCvFieldValues'>,
    Gateway.MutationBulkUpdateCustomCvFieldValuesArgs
  >(BulkUpdateCustomCvFieldValuesMutation, {
    translationKey: 'people.customFields.edit',
  })

  async function bulkUpdate(
    filters: StaffFilters,
    payloads: EffectiveFieldValuePair[],
  ) {
    const response = await bulkUpdateCustomCvFieldValues({
      variables: { input: mapFormToBulkUpdateInput(filters, payloads) },
    })
    return response.data?.bulkUpdateCustomCvFieldValues.backgroundTaskId
  }

  return {
    bulkUpdate,
    updating,
  }
}

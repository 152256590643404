import { useCreateTimeType } from './useCreateTimeType'
import { useDeleteTimeType } from './useDeleteTimetype'
import { useEmployeeEffectiveTimeTypes } from './useEmployeeEffectiveTimeTypes'
import { usePickerTimeTypes } from './usePickerTimeTypes'
import { useTimeTypesByOffset } from './useTimeTypesByOffset'
import { useUpdateTimeType } from './useUpdateTimeType'

export const TimeTypesAPI = {
  byOffset: useTimeTypesByOffset,
  employeeEffective: useEmployeeEffectiveTimeTypes,
  delete: useDeleteTimeType,
  create: useCreateTimeType,
  update: useUpdateTimeType,
  picker: usePickerTimeTypes,
}

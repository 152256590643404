import { DateTime } from 'luxon'
import { Schedule, ScheduleAreasFilter } from 'Types/schedule'

import {
  EmployeePickerReturnOption,
  FilterMode,
} from 'components/blocks/__v3__'
import { ShiftTagOption } from 'components/blocks/__v3__/ShiftTagsPicker/types'

import { areasToFilters } from 'helpers/areas'

type MapScheduleFiltersToServerDataProps = {
  from?: DateTime
  to?: DateTime
  areas?: ScheduleAreasFilter
  shiftTags?: ShiftTagOption[]
  shiftTagsFilterMode?: FilterMode
  selectedSchedules?: Map<string, Schedule>
  selectedEmployees?: EmployeePickerReturnOption[]
}

type Filter = {
  // Areas
  branch_id?: JsonApi.AreasFilter['branch_id']
  department_id?: JsonApi.AreasFilter['department_id']
  job_id?: JsonApi.AreasFilter['job_id']

  // Other filters
  start_at?: JsonApi.BetweenFilter
  shift_tag_ids?: JsonApi.ArrayFilter
  employee_id?: JsonApi.AreasFilter['employee_id']
}

type ServerPayload = {
  schedule_ids?: string[]
  filter?: Filter
}

export function mapScheduleFiltersToServerData({
  from,
  to,
  areas,
  shiftTags,
  shiftTagsFilterMode,
  selectedSchedules,
  selectedEmployees,
}: MapScheduleFiltersToServerDataProps) {
  const payload: ServerPayload = {}

  if (selectedSchedules) {
    payload.schedule_ids = Array.from(selectedSchedules.keys())
  } else {
    const filter: Filter = {
      ...(areas && { ...areasToFilters(areas) }),
    }

    if (from && to) {
      filter.start_at = {
        between: [from.toISO(), to.toISO()],
      }
    }

    if (shiftTags?.length) {
      const shiftTagIds = shiftTags.map(option => String(option.value))
      if (shiftTagsFilterMode === 'and') {
        filter.shift_tag_ids = { all: shiftTagIds }
      }

      if (shiftTagsFilterMode === 'or') {
        filter.shift_tag_ids = { any: shiftTagIds }
      }
    }

    if (selectedEmployees?.length) {
      filter.employee_id = { in: selectedEmployees.map(option => option.value) }
    }

    payload.filter = filter
  }

  return payload
}

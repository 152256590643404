import styled from 'styled-components'

// TODO: придумать как выпилить !important
export const PaginationWrapper = styled.nav`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
  background-color: transparent !important;

  ${({ theme }) => theme.pagination.style};

  border-top: none;
`

export const PageList = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  white-space: nowrap;
`

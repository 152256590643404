import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

export function accessLevelsToFilters(accessLevels) {
  const filters = {}

  if (!isEmpty(accessLevels)) {
    const allLevels = []

    forEach(accessLevels, accessLevel => {
      const value = accessLevel.value ?? accessLevel

      allLevels.push(value)
    })

    filters.access_level = { in: allLevels }
  }

  return filters
}

export function statusesToFilters(statuses = []) {
  const filters = {}

  const items = map(statuses, 'value')

  if (!isEmpty(items)) {
    filters.status = {
      in: items,
    }
  }

  return filters
}

export function searchToFilters(search = '') {
  const filters = {}

  if (search.length > 2) {
    filters.search = {
      ilike: search,
    }
  }

  return filters
}

import React from 'react'

import { Flex } from 'components/ui/__v2__/Grid'
import { Popover } from 'components/ui/__v2__/Popover'

import { i18n } from 'i18n'

import { PublishedIcon } from './styles'

export function PublishedSign() {
  return (
    // @ts-ignore
    <Popover content={i18n('shiftAssign.isPublished')}>
      <Flex>
        <PublishedIcon />
      </Flex>
    </Popover>
  )
}

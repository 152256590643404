import React from 'react'

import { FiAlertTriangle, FiSlash } from 'react-icons/fi'

import { useTheme } from 'styled-components'

import { ConflictType } from 'constants/gatewayGraphQL'

type Props = {
  size?: number | string
  conflictType: ConflictType | null
}

export function ShiftValidationConflictTypeIcon({
  size = '14px',
  conflictType,
}: Props) {
  const theme = useTheme()

  if (conflictType === ConflictType.HardError)
    return <FiSlash color={theme.colors.ERROR} size={size} />

  if (conflictType === ConflictType.SoftWarning)
    return <FiAlertTriangle color={theme.colors.WARNING} size={size} />

  return null
}

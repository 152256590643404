import { FiXCircle } from 'react-icons/fi'
import { LuTimerOff } from 'react-icons/lu'
import { PiCheckCircle, PiClockCountdown } from 'react-icons/pi'
import { RiTimerFlashLine } from 'react-icons/ri'

import { ScheduleAcceptanceState } from './gatewayGraphQL'

export enum ScheduleAcceptanceJsonApiState {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected',
  TimeOut = 'time_out',
}

export const SCHEDULE_ACCEPTANCE_JSON_API_STATE_TO_GRAPHQL_STATE = {
  [ScheduleAcceptanceJsonApiState.Accepted]: ScheduleAcceptanceState.Accepted,
  [ScheduleAcceptanceJsonApiState.Pending]: ScheduleAcceptanceState.Pending,
  [ScheduleAcceptanceJsonApiState.Rejected]: ScheduleAcceptanceState.Rejected,
  [ScheduleAcceptanceJsonApiState.TimeOut]: ScheduleAcceptanceState.TimedOut,
}

export const SCHEDULE_ACCEPTANCE_STATE_ICONS = {
  [ScheduleAcceptanceState.Accepted]: PiCheckCircle,
  [ScheduleAcceptanceState.Pending]: PiClockCountdown,
  [ScheduleAcceptanceState.AutoAccepted]: RiTimerFlashLine,
  [ScheduleAcceptanceState.Rejected]: FiXCircle,
  [ScheduleAcceptanceState.TimedOut]: LuTimerOff,
}

import { connectRouter } from 'connected-react-router'
import { parse } from 'qs'

import get from 'lodash/get'
import merge from 'lodash/merge'

export default history => (state, action) => {
  const nextState = connectRouter(history)(state, action)

  if (nextState === state || nextState.location === null) {
    return nextState
  }

  return merge({}, nextState, {
    prevLocation: get(state, 'location'),
    location: {
      query: parse(get(action.payload, 'search', '').substr(1)),
    },
  })
}

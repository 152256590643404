import styled from 'styled-components'

import { noScrollbar } from 'imports/styles/scrollbar'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]}px;
  padding: ${({ theme }) => theme.space[2]}px;
  max-height: 500px;
  font-weight: normal;
  overflow-y: scroll;

  ${noScrollbar}
`

import { FiX } from 'react-icons/fi'
import { MdCheck } from 'react-icons/md'

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Button } from 'components/ui/__v2__/Button'
import { Modal as UIModal } from 'components/ui/__v2__/Modal'
import { Divider } from 'components/ui/Divider'

import { noScrollbar } from 'imports/styles/scrollbar'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 440px;
  min-height: 220px;
  align-items: center;
  padding: 10px 0 29px 0;
  background-color: ${themeGet('colors.WHITE')};
`

export const LocaleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px;
  width: 380px;
  background-color: ${themeGet('colors.WHITE')};
  justify-content: space-between;

  input {
    width: 320px;
    height: 36px;
    margin-top: 15px;
  }

  ${Button} {
    margin-top: 15px;
  }

  button {
    width: 320px;
    &:last-child {
      margin-top: 5px;
    }
  }
`

export const Modal = styled(UIModal).attrs({
  customStyles: () => ({
    content: {
      width: 380,
    },
  }),
})``

export const LocaleTitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: ${themeGet('colors.DARK_TEXT')};
  margin-bottom: 16px;
`

export const LocaleOption = styled.div`
  display: flex;
  align-items: center;
  transition: all 0.5s;
  cursor: pointer;
  text-align: left;
  width: 110px;
  padding-bottom: 15px;
  font-size: 16px;
  font-weight: 300;
  color: ${themeGet('colors.LIGHT_TEXT')};

  ${props =>
    props.active &&
    css`
      color: ${themeGet('colors.HOVER_LIGHT_TEXT')};

      ${StyledMdCheck} {
        visibility: visible;
      }
    `};
`

export const LocaleOptionName = styled.span`
  margin-left: 10px;
`

export const StyledClose = styled(FiX)`
  position: absolute;
  cursor: pointer;
  top: 2px;
  right: 2px;
  color: ${themeGet('colors.DARK_TEXT')};
`

export const StyledMdCheck = styled(MdCheck)`
  visibility: hidden;
`

export const ModalTitle = styled.span`
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${themeGet('colors.DARK_TEXT')};
`
export const ContentTitle = styled.span`
  font-size: 14px;
  color: ${themeGet('colors.LIGHT_TEXT')};
`

export const Content = styled.div`
  padding: 20px 0;
  width: 100%;
  text-align: center;
`

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 22px;
`
export const InputLabel = styled.div`
  width: 228px;
  text-align: right;
  font-size: 16px;
  color: ${themeGet('colors.DEFAULT_TEXt')};
`

export const HeaderDivider = styled(Divider)`
  width: 100%;
  height: 2px;
  color: ${themeGet('colors.ALTO')};
`

export const TokenText = styled.div`
  margin-top: 20px;
  width: 100%;
  border: 1px solid ${themeGet('colors.ALTO')};
  border-radius: 4px;
  background: ${themeGet('colors.GRAY_200')};
  position: relative;
  padding: 4px 8px;
`

export const TokenString = styled.textarea`
  font-family: monospace;
  font-size: 16px;
  width: calc(100% - 48px);
  height: 20px;
  background: none;
  border: none;
  outline: none;
  white-space: nowrap;
  resize: none;

  ${noScrollbar};
`

export const TokenTip = styled.div`
  margin-top: 8px;
  color: ${themeGet('colors.ALTO')};
  text-align: center;
`

export const TokenError = styled.div`
  margin-top: 8px;
  color: ${themeGet('colors.INPUT_ERROR')};
  text-align: center;
`

export const Copy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 55px;
  text-transform: uppercase;
  color: ${themeGet('colors.ALTO')};
  cursor: pointer;
  font-weight: bold;

  :hover {
    color: ${themeGet('colors.DARK_TEXT')};
  }
`

export const CopySuccess = styled.div`
  margin-top: 8px;
  color: ${themeGet('colors.CHILL')};
  text-align: center;
`

import React from 'react'

import { FilledAndTotalIndicator } from 'components/blocks/__v3__/FilledAndTotalIndicator'
import { TextOverflowWithPopover } from 'components/blocks/__v3__/TextOverflowWithPopover'
import { SpacedRow } from 'components/ui/__v2__/Grid'

import { ShiftJobOption } from '../types'

type Props = {
  option: ShiftJobOption
}

export function PositionOption({ option }: Props) {
  const {
    label,
    currentUnfilledPositions,
    data: { totalPositions },
  } = option

  return (
    <SpacedRow alignContent="space-between" alignItems="Center">
      <TextOverflowWithPopover>{label}</TextOverflowWithPopover>
      <FilledAndTotalIndicator
        filled={totalPositions - currentUnfilledPositions}
        total={totalPositions}
      />
    </SpacedRow>
  )
}

import EvaluatedTimesAggregatedByEmployeeByCursorQuery from './EvaluatedTimesAggregatedByEmployeeByCursor.graphql'
import EvaluatedTimesByCursorQuery from './EvaluatedTimesByCursor.graphql'
import TimeEvaluationWorkflowStatusQuery from './TimeEvaluationWorkflowStatus.graphql'

export * from './types'
export {
  EvaluatedTimesAggregatedByEmployeeByCursorQuery,
  EvaluatedTimesByCursorQuery,
  TimeEvaluationWorkflowStatusQuery,
}

import React from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import InfoPopover from 'components/blocks/__v2__/InfoPopover'
import { SpacedRow } from 'components/ui/__v2__/Grid'

import _ from 'i18n'

import { SelectAll } from './styles'

function BatchSelect({ handleSelect, hasInput, isDisabled, size }) {
  return (
    <SpacedRow alignItems="center">
      <SelectAll disabled={isDisabled} onClick={handleSelect}>
        {hasInput
          ? _('areas.departments.selectFilteredDepartments')
          : _('areas.departments.selectAllDepartments')}
      </SelectAll>

      <InfoPopover
        iconSize={10}
        placement="bottom-start"
        popoverContent={_('areas.recordsLimit', {
          limit: size,
        })}
      />
    </SpacedRow>
  )
}

BatchSelect.defaultProps = {
  handleSelect: noop,
  isDisabled: true,
  size: 100,
}

BatchSelect.propTypes = {
  handleSelect: PropTypes.func,
  hasInput: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  size: PropTypes.number,
}

export default BatchSelect

import styled from 'styled-components'

import { Button } from 'components/ui/__v2__/Button'
import { Flex } from 'components/ui/__v2__/Grid'

export const Container = styled(Flex)`
  flex: 1;
  gap: 8px;
  flex-direction: column;
`

export const ActionButton = styled(Button).attrs({ small: true })`
  font-size: 20px;
  height: 24px;
  width: 24px;
`
export const InfoIconContainer = styled.div`
  margin-left: 5px;
  margin-top: 4px;
`

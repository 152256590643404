import * as colors from './colors'

export default {
  color: {
    light: colors.PAPER_WHITE_20,
    asText: colors.TEXT,
    default: colors.CHARCOAL_60,
    pending: colors.GARDA_120,
    inProgress: colors.GREEN,
    finish: colors.GREEN,
    error: colors.ERROR,
  },
}

import {
  getOperationName,
  graphQLResultHasError,
} from '@apollo/client/utilities'
import { SchedulesGardaListViewQuery } from 'API/Schedule/GraphQL'
import { useApolloMutation } from 'API/services/Apollo'

import { compact } from 'lodash'

import { CreateShiftsWithAssignmentsMutation } from './GraphQL'

export function useCreateShiftsWithAssignments() {
  const [innerCreateMany, { loading }] = useApolloMutation<
    MutationData<'createShiftsWithAssignments'>,
    Gateway.MutationCreateShiftsWithAssignmentsArgs
  >(CreateShiftsWithAssignmentsMutation, {
    refetchQueries: compact([getOperationName(SchedulesGardaListViewQuery)]),
  })

  const createMany = async (
    input: Gateway.CreateShiftsWithAssignmentsInput,
  ) => {
    const result = await innerCreateMany({
      variables: {
        input,
      },
    })

    return graphQLResultHasError(result)
  }

  return { createMany, creating: loading }
}

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Button } from 'components/ui/__v3__/Button'
import { Select } from 'components/ui/__v3__/Select'

import { i18n } from 'i18n'

import {
  AdditionalFiltersContainer,
  DropdownContainer,
  DropdownRow,
  FilterFrame,
  MultiButtonFrame,
  SortFrame,
  StyledMdFilterList,
  TriangleDown,
} from './styles'
import { FilterConfig } from './types'

interface AdditionalFiltersProps {
  configs: FilterConfig[]
  onChange: (filters: any) => void
  filters: Record<string, any>
  children?: React.ReactNode
  filterStyles?: {
    zIndex?: number
  }
}
export function AdditionalFilters({
  configs,
  onChange,
  filters,
  children,
  filterStyles,
}: AdditionalFiltersProps) {
  const initialState = useMemo(
    () =>
      configs.reduce(
        (acc, config) => ({
          ...acc,
          [config.label]: filters[config.label],
        }),
        {} as Record<string, any>,
      ),
    [configs, filters],
  )
  const [filtersState, setFiltersState] = useState<Record<string, any>>(
    initialState,
  )
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const toggleRef = useRef(null)
  const dropdownRef = useRef(null)

  const handleFilterChange = (key: string) => (newValue: any) => {
    setFiltersState(state => ({ ...state, [key]: newValue }))
  }

  const handleClickOutsideMenu = useCallback(
    event => {
      const canHideDropdown =
        dropdownRef.current &&
        toggleRef.current &&
        dropdownIsOpen &&
        // @ts-ignore TODO: fix this
        !dropdownRef.current.contains(event.target) &&
        // @ts-ignore TODO: fix this
        !toggleRef.current.contains(event.target)

      if (canHideDropdown) {
        setDropdownIsOpen(false)
        setFiltersState(initialState)
      }
    },
    [dropdownIsOpen, initialState],
  )

  const toggleDropdown = useCallback(() => {
    dropdownIsOpen && setFiltersState(initialState)
    setDropdownIsOpen(!dropdownIsOpen)
  }, [dropdownIsOpen, initialState])

  useEffect(() => {
    window.addEventListener('click', handleClickOutsideMenu, true)
    window.addEventListener('touched', handleClickOutsideMenu, true)

    return () => {
      window.removeEventListener('click', handleClickOutsideMenu, true)
      window.removeEventListener('touched', handleClickOutsideMenu, true)
    }
  })

  const applyFilters = useCallback(() => {
    setDropdownIsOpen(false)

    onChange(filtersState)
  }, [onChange, filtersState])

  return (
    <AdditionalFiltersContainer zIndex={filterStyles?.zIndex}>
      <MultiButtonFrame height={24} ref={toggleRef}>
        <FilterFrame>
          <StyledMdFilterList
            filled={dropdownIsOpen}
            size={22}
            onClick={toggleDropdown}
          />
        </FilterFrame>

        <SortFrame onClick={toggleDropdown}>
          <TriangleDown />
        </SortFrame>
      </MultiButtonFrame>

      {dropdownIsOpen && (
        <DropdownContainer ref={dropdownRef}>
          {configs.map(filterConfig => {
            return (
              <DropdownRow key={filterConfig.label}>
                <Select
                  isClearable={false}
                  {...filterConfig}
                  // @ts-ignore
                  value={filtersState[filterConfig.label]}
                  onChange={handleFilterChange(filterConfig.label)}
                />
              </DropdownRow>
            )
          })}

          {children}

          <Button mt={2} mx="auto" onClick={applyFilters}>
            {i18n('common.apply')}
          </Button>
        </DropdownContainer>
      )}
    </AdditionalFiltersContainer>
  )
}

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { i18n } from 'i18n'

import { showError } from 'services/API'
import { showToast } from 'services/Toasts'

import { TimeoffQueryKeys } from './constant'
import { approveTimeoffRequestMutation } from './Json'

export function useApproveTimeoffRequest() {
  const queryClient = useQueryClient()

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: approveTimeoffRequestMutation,
    onSuccess: (response: JsonApi.Response) => {
      if (response.ok) {
        showToast({
          type: 'success',
          title: i18n('timeoffTable.action.success.approve'),
          content: i18n('alertMessages.default.success.message'),
        })

        queryClient.invalidateQueries(TimeoffQueryKeys.all())
      } else {
        showError(response)
      }
    },
  })

  return {
    approveTimeoffRequest: mutateAsync,
    isApprovingTimeoffRequest: isLoading,
  }
}

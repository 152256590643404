import { useMemo, useState } from 'react'

import { useApolloQuery } from 'API/services/Apollo'
import {
  cursorPageToGraphqlPaging,
  isCursorPageMetaChanged,
} from 'API/services/utils'
import { CursorPage } from 'Types/common'

import { RulesByCursorQuery, RulesByCursorQueryData } from './GraphQL'

type Props = {
  paging: CursorPage
  filter?: Gateway.RulesByCursorFilterInput
  sorting: Gateway.RuleSortingInput[]
}

export function useRulesByCursor({ paging, filter, sorting }: Props) {
  const [
    currentPageMeta,
    setCurrentPageMeta,
  ] = useState<Gateway.CursorPageInfo>({
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: null,
    endCursor: null,
  })

  const { data, refetch, loading } = useApolloQuery<
    RulesByCursorQueryData,
    Gateway.QueryRulesByCursorArgs
  >(RulesByCursorQuery, {
    fetchPolicy: 'network-only',
    variables: {
      filter,
      paging: cursorPageToGraphqlPaging(paging),
      sorting,
    },

    onCompleted(data) {
      const newPageMeta = data.rulesByCursor.pageInfo
      const pageMetaChanged = isCursorPageMetaChanged(
        currentPageMeta,
        newPageMeta,
      )

      if (pageMetaChanged) {
        setCurrentPageMeta(newPageMeta)
      }
    },
  })

  const rules = useMemo(
    () => data?.rulesByCursor?.edges.map(edge => edge.node) ?? [],
    [data],
  )

  return {
    rules,
    loading,
    currentPageMeta,
    refetch,
  }
}

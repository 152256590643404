import { EmployeeJobWageType } from 'constants/gatewayGraphQL'
import { WageType } from 'constants/ids'

// Note: Adding the `WageType` enum until we're typesafe on useAddRole where it leaked
export function employeeJobWageTypeToJsonApi(
  wageType: EmployeeJobWageType | WageType,
) {
  switch (wageType) {
    case EmployeeJobWageType.Hour:
    case WageType.Hour:
      return 'hour'
    case EmployeeJobWageType.Year:
    case WageType.Year:
      return 'year'
    default:
      throw new Error('Unexpected wage type')
  }
}

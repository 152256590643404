/* eslint-disable no-console */
import { onError } from '@apollo/client/link/error'

import forEach from 'lodash/forEach'

import { AuthService } from 'services/Auth'

export function gatewayErrorLink() {
  return onError(
    ({ response, graphQLErrors, networkError, operation, forward }) => {
      if (networkError) console.error(`[Network error]: ${networkError}`)

      if (graphQLErrors) {
        forEach(graphQLErrors, ({ message, location, path }) => {
          if (message === 'PersistedQueryNotFound') return

          console.debug(
            `[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`,
          )
        })
      }

      // TODO: check the need of this
      if (operation.operationName === 'Me' && response) {
        response.errors = null
      }

      const isUnauthorizedError =
        networkError?.statusCode === 401 ||
        graphQLErrors?.[0]?.extensions?.name === 'AuthenticationError' ||
        graphQLErrors?.[0]?.extensions?.code === 'UNAUTHENTICATED' ||
        graphQLErrors?.[0]?.message === 'generic.notAuthorized'

      if (isUnauthorizedError) {
        AuthService.logOut()
      }

      return forward(operation)
    },
  )
}

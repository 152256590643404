import React, { useEffect, useState } from 'react'

import { TimeoffAPIItem } from 'API'

import { ShiftConflictActionSelector } from 'components/blocks/__v3__/ShiftConflictActionSelector'
import { SpacedColumn } from 'components/ui/__v2__/Grid'
import { Modal } from 'components/ui/__v3__'
import { Button } from 'components/ui/__v3__/Button'

import { ScheduleConflictActionTypes } from 'constants/ids'

import { i18n } from 'i18n'

import { Utils } from 'services/Utils'

interface Props {
  onClose: () => void
  onSubmitIntent: (conflictAction: ScheduleConflictActionTypes) => void
  approving: boolean
  timeoff: null | TimeoffAPIItem
}

export function ApproveTimeoffModal({
  onClose,
  onSubmitIntent,
  approving,
  timeoff,
}: Props) {
  const [
    conflictAction,
    setConflictAction,
  ] = useState<ScheduleConflictActionTypes>(ScheduleConflictActionTypes.Split)

  // Check if start date is in the past and set default value for conflict type
  const isStartDateInThePast = Utils.Timeoff.isTimeoffStartDateInThePast({
    startISODate: timeoff?.startAt,
    startISOTime: timeoff?.startTime,
    partial: timeoff?.partial,
    timezone: timeoff?.employee.branch?.settings.timezone || 'local',
  })
  useEffect(() => {
    if (!isStartDateInThePast) {
      setConflictAction(ScheduleConflictActionTypes.Split)
    } else {
      setConflictAction(ScheduleConflictActionTypes.Error)
    }
  }, [isStartDateInThePast])

  return (
    <Modal.Container isOpen>
      <Modal.Close onClose={onClose} />
      <Modal.Title>{i18n('timeoffTable.sectionTitle')}</Modal.Title>

      <SpacedColumn my={5} space={2}>
        <ShiftConflictActionSelector
          description={i18n('timeOffModal.conflictDescription')}
          excludeNoneAction
          name="conflictAction"
          value={conflictAction}
          withErrorConflict
          onChange={setConflictAction}
        />
      </SpacedColumn>

      <Modal.Actions justifyContent="center">
        <Button mr={17} secondary width={180} onClick={onClose}>
          {i18n('actions.cancel')}
        </Button>
        <Button
          disabled={approving}
          width={180}
          onClick={() => onSubmitIntent(conflictAction)}
        >
          {i18n('actions.confirm')}
        </Button>
      </Modal.Actions>
    </Modal.Container>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import noop from 'lodash/noop'
import values from 'lodash/values'

import { Flex } from 'components/ui/__v2__/Grid'
import Popover from 'components/ui/__v2__/Popover/Popover'

import i18n from 'i18n'

import {
  AngleLeft,
  AngleRight,
  AngleWrapper,
  CalendarIcon,
  Container,
  Content,
  ContentWrapper,
} from './styles'

const DIRECTION = {
  NEXT: 'next',
  PREV: 'prev',
}

const MODE = {
  DAY: 'day',
  WEEK: 'week',
  WEEK_AND_YEAR: 'weekAndYear',
  MONTH: 'month',
}

const LUXON_MODE_ALIAS = {
  [MODE.DAY]: MODE.DAY,
  [MODE.WEEK]: MODE.WEEK,
  [MODE.WEEK_AND_YEAR]: MODE.WEEK,
  [MODE.MONTH]: MODE.MONTH,
}

const DATE_FORMATS = {
  [MODE.DAY]: DateTime.DATE_SHORT,
  [MODE.WEEK]: { month: 'short', day: 'numeric' },
  [MODE.WEEK_AND_YEAR]: { month: 'short', day: 'numeric', year: 'numeric' },
  [MODE.MONTH]: { month: 'full', year: 'full' },
}

/**
 * @deprecated
 * @see {@link components/blocks/__v3__/DateSwitch}
 */
function DateSwitch({
  date,
  mode: formatMode,
  timeZone,
  onChange,
  disabled,
  disabledNextPage,
  disabledPrevPage,
  onReset = noop,
  hideTooltip = true,
  ...rest
}) {
  const [currentStartDate, setCurrentStartDate] = useState(
    date?.start ?? date ?? DateTime.fromObject({}, { zone: timeZone }),
  )

  const dateTimeMode = LUXON_MODE_ALIAS[formatMode]

  useEffect(() => {
    if (date?.valueOf() !== currentStartDate?.valueOf()) {
      setCurrentStartDate(date?.start ?? date)
    }
  }, [date, currentStartDate])

  const handleSwitch = useCallback(
    direction => () => {
      let nextStartDate = null

      switch (direction) {
        case DIRECTION.PREV:
          nextStartDate = currentStartDate.minus({ [dateTimeMode]: 1 })
          break
        case DIRECTION.NEXT:
          nextStartDate = currentStartDate.plus({ [dateTimeMode]: 1 })
          break
        default:
          return
      }

      setCurrentStartDate(nextStartDate)

      onChange({
        start: nextStartDate,
        end: nextStartDate.plus({ days: 6 }),
      })
    },
    [currentStartDate, dateTimeMode, onChange],
  )

  const content = useMemo(() => {
    if (dateTimeMode === MODE.DAY || dateTimeMode === MODE.MONTH) {
      return currentStartDate.toLocaleString(DATE_FORMATS[formatMode])
    }

    const startDate = date?.start ?? currentStartDate.startOf(dateTimeMode)
    const endDate = date?.end ?? currentStartDate.endOf(dateTimeMode)
    const formatDate = d => d.toLocaleString(DATE_FORMATS[formatMode])

    return `${formatDate(startDate)} - ${formatDate(endDate)}`
  }, [currentStartDate, date?.end, date?.start, dateTimeMode, formatMode])

  return (
    <Container disabled={disabled} {...rest}>
      <AngleWrapper
        disabled={disabledPrevPage}
        left
        onClick={
          !disabled && !disabledPrevPage ? handleSwitch(DIRECTION.PREV) : null
        }
      >
        <AngleLeft />
      </AngleWrapper>

      <ContentWrapper>
        <Popover
          content={<Flex>{i18n('common.goBackToCrrentWeek')}</Flex>}
          disabled={hideTooltip}
        >
          <Flex>
            <CalendarIcon onClick={onReset} />
          </Flex>
        </Popover>

        <Content ml={1}>{content}</Content>
      </ContentWrapper>

      <AngleWrapper
        disabled={disabledNextPage}
        right
        onClick={
          !disabled && !disabledNextPage ? handleSwitch(DIRECTION.NEXT) : null
        }
      >
        <AngleRight />
      </AngleWrapper>
    </Container>
  )
}

DateSwitch.defaultProps = {
  date: DateTime.local(),
  disabledPrevPage: false,
  disabledNextPage: false,
  disabled: false,
  hideTooltip: false,
  mode: MODE.DAY,
  timeZone: 'local',
  onChange: noop,
  onReset: noop,
}

DateSwitch.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(DateTime)]),
  disabled: PropTypes.bool,
  disabledNextPage: PropTypes.bool,
  disabledPrevPage: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  mode: PropTypes.oneOf(values(MODE)),
  timeZone: PropTypes.string,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
}

DateSwitch.MODE = MODE

export default DateSwitch

import validate from 'validate.js/validate'

import { isEmpty } from 'lodash'
import find from 'lodash/find'
import isNumber from 'lodash/isNumber'
import pick from 'lodash/pick'

import _ from 'i18n'

import {
  cleanNumericString,
  displayableMonetary,
  prepareMonetaryToSubmit,
} from './monetary'

const MONETARY_OPTIONS = {
  GREATER_THEN: 'greaterThenCents',
  LOWER_THEN: 'lowerThenCents',
}

const TOKEN_OPTIONS = {
  LIMIT: 'limit',
  TICKER: 'ticker',
}

/**
 * Validates masked monetary strings towards upper and lower limits
 *
 * @param {string} value - Monetary string, i.e. 12,22
 * Available options:
 * - greaterThenCents
 * - lowerThenCents
 */
export function monetaryValidator(value, options) {
  if (!value) return null

  const centsValue = prepareMonetaryToSubmit(value)

  const greaterThenCentsThreshold = pick(
    options,
    MONETARY_OPTIONS.GREATER_THEN,
  )?.[MONETARY_OPTIONS.GREATER_THEN]
  const lowerThenCentsThreshold = pick(options, MONETARY_OPTIONS.LOWER_THEN)?.[
    MONETARY_OPTIONS.LOWER_THEN
  ]

  if (
    greaterThenCentsThreshold &&
    isNumber(greaterThenCentsThreshold) &&
    centsValue < greaterThenCentsThreshold
  ) {
    return `^${_('salaryRevision.salaryTable.validation.mustBeHigher', {
      cents: displayableMonetary(greaterThenCentsThreshold),
    })}`
  }

  if (
    lowerThenCentsThreshold &&
    isNumber(lowerThenCentsThreshold) &&
    centsValue > lowerThenCentsThreshold
  ) {
    return `^${_('salaryRevision.salaryTable.validation.mustBeLower', {
      cents: displayableMonetary(lowerThenCentsThreshold),
    })}`
  }

  return null
}

/**
 * At least one weekday option has to be selected
 */

export function weeekDaysValidator(value) {
  if (!find(value, ({ enabled }) => enabled)) return `^${_('common.required')}`
  return null
}

export function tokensValidator(value, options) {
  if (!value) return null

  const parsedValue = cleanNumericString(value)
  const limit = options?.[TOKEN_OPTIONS.LIMIT]
  const ticker = options?.[TOKEN_OPTIONS.TICKER]

  if (isNumber(limit) && parsedValue > +limit) {
    return `^${_('vaultPage.notEnoughTokens', { limit, ticker })}`
  }

  return null
}

export function arrayValidator(array, options) {
  if (!array) return null

  const errors = []

  for (const value of array) {
    const error = validate(value, options)

    if (error) {
      errors.push(error)
    }
  }

  return isEmpty(errors) ? null : errors
}

export function numericalRangeError(lower, upper) {
  return `${lower} - ${upper}`
}

import React, { memo } from 'react'
import { Field, Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import noop from 'lodash/noop'

import { Button } from 'components/ui/__v2__/Button'
import { FormDayPicker, FormInput } from 'components/ui/__v2__/Form'
import { Flex, SpacedColumn } from 'components/ui/__v2__/Grid'
import { Modal } from 'components/ui/__v3__'
import { InputLabel } from 'components/ui/__v3__/Input'

import useValidate from 'hooks/useValidate'

import { i18n } from 'i18n'

import { schema } from './schema'
import { Fields } from './types'

// TODO: refactor to ts and hook form
const MoveTimeoffModal = ({
  onClose,
  onMoveTimeoff,
  movibleTimeoff,
  moving,
}) => {
  const title = i18n('moveTimeoffs.title')

  const initialValues = {
    [Fields.CurrentHolidayDate]: movibleTimeoff.startAt,
    [Fields.StartDate]: null,
    [Fields.Comment]: null,
  }

  const validate = useValidate(schema)

  function getDisabledMoveTimeoffDays() {
    const initialStartDate = movibleTimeoff.startAt
      ? DateTime.fromISO(movibleTimeoff.startAt)
      : null
    const currentDate = DateTime.now()

    if (!initialStartDate) {
      return null
    }
    if (initialStartDate > currentDate) {
      return { before: currentDate.plus({ days: 1 }).toJSDate() }
    }
    if (initialStartDate < currentDate) {
      return { before: initialStartDate.plus({ days: 1 }).toJSDate() }
    }
    return { before: initialStartDate.plus({ days: 1 }).toJSDate() }
  }

  return (
    <Modal.Container isOpen onRequestClose={onClose}>
      <Modal.Close onClose={onClose} />
      <Modal.Title>{title}</Modal.Title>

      <Form
        initialValues={initialValues}
        validate={validate}
        onSubmit={onMoveTimeoff}
      >
        {({ handleSubmit }) => (
          <SpacedColumn space={5}>
            <Flex flexDirection="column" minWidth={400}>
              <Flex mb={2}>
                <InputLabel htmlFor={Fields.CurrentHolidayDate} width={1}>
                  {i18n('moveTimeoffs.currentHolidayDate')}
                  <Field name={Fields.CurrentHolidayDate}>
                    {props => (
                      <FormDayPicker
                        {...props}
                        dayOnly
                        disabled
                        width="100%"
                        withInput
                      />
                    )}
                  </Field>
                </InputLabel>
              </Flex>

              <Flex mb={2}>
                <InputLabel htmlFor={Fields.StartDate}>
                  {i18n('moveTimeoffs.newHolidayDate')}
                  <Field name={Fields.StartDate}>
                    {props => (
                      <FormDayPicker
                        {...props}
                        dayOnly
                        disabledDays={getDisabledMoveTimeoffDays()}
                        width="100%"
                        withInput
                      />
                    )}
                  </Field>
                </InputLabel>
              </Flex>

              <Flex mb={2}>
                <InputLabel htmlFor={Fields.Comment} width={1}>
                  {i18n('moveTimeoffs.comment')}
                  <Field name={Fields.Comment}>
                    {props => (
                      <FormInput
                        {...props}
                        as="textarea"
                        maxHeight="700px"
                        maxWidth="400px"
                        minHeight="40px"
                        minWidth="100%"
                      />
                    )}
                  </Field>
                </InputLabel>
              </Flex>
            </Flex>

            <Modal.Actions>
              <Button secondary onClick={onClose}>
                {i18n('actions.cancel')}
              </Button>
              <Button disabled={moving} onClick={handleSubmit}>
                {i18n('timeoffTable.action.name.request')}
              </Button>
            </Modal.Actions>
          </SpacedColumn>
        )}
      </Form>
    </Modal.Container>
  )
}

MoveTimeoffModal.defaultProps = {
  movibleTimeoff: null,
  moving: false,
  onClose: noop,
  onMoveTimeoff: noop,
}

MoveTimeoffModal.propTypes = {
  movibleTimeoff: PropTypes.object,
  moving: PropTypes.bool,
  onClose: PropTypes.func,
  onMoveTimeoff: PropTypes.func,
}

export default memo(MoveTimeoffModal)

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import { Input } from 'components/ui/__v3__/Input'

import { NUMBER_REG_EXP } from 'constants/settings'

import FormInput from './FormInput'

/**
 * @see https://final-form.org/docs/react-final-form/types/FieldRenderProps for FormRenderProps props
 * @see {@link ../FormInput.js} for FormInput props
 */
const FormPhone = React.forwardRef(({ input, ...props }, ref) => {
  const handleChange = useCallback(
    event => {
      const { value } = event.target

      if (!NUMBER_REG_EXP.test(value) && !isEmpty(value)) {
        return
      }

      input.onChange(event)
    },
    [input],
  )

  return (
    <FormInput input={input} {...props} ref={ref} onChange={handleChange} />
  )
})

FormPhone.propTypes = {
  ...Input.propTypes,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export default FormPhone

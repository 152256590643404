import React from 'react'

import { SpaceProps } from 'styled-system'

import { Flex } from 'components/ui/__v2__/Grid'
import { Popover } from 'components/ui/__v2__/Popover'
import TradeShiftBlockedIcon from 'components/ui/Icons/trade-shift-blocked'

import { i18n } from 'i18n'

type Props = SpaceProps

export function TradesBlockedInfo({ ...rest }: Props) {
  return (
    // @ts-ignore
    <Popover
      content={i18n('schedule.trades.blockedTooltip')}
      interactive
      offset={[0, 5]}
      trigger="mouseenter"
    >
      <Flex {...rest}>
        <TradeShiftBlockedIcon />
      </Flex>
    </Popover>
  )
}

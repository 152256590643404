import { ProfileLogsQuerySorting } from './Json'

export enum ProfileLogEventKind {
  Manual = 'manual',
  System = 'system',
}

export enum ProfileLogTriggers {
  ProfileUpdate = 'profile_update',
  CustomCvFieldValueUpdate = 'custom_cv_field_value_update',
  AccessLevelUpdate = 'access_level_update',
  RemovedFromCompany = 'removed_from_company',
  SupervisorUpdate = 'supervisor_update',
  PasswordChanged = 'password_changed',
  FaceIdReset = 'face_id_reset',
  CustomCvFieldAttachmentAdded = 'custom_cv_field_value_attachment_added',
  CustomCvFieldAttachmentRemoved = 'custom_cv_field_value_attachment_removed',
  CustomCvFieldAttachmentUpdated = 'custom_cv_field_value_attachment_updated',
  EmployeeArchived = 'employee_archived',
  EmployeeRestored = 'employee_restored',
  EmployeeTagAdded = 'employee_tag_added',
  EmployeeTagRemoved = 'employee_tag_removed',
  InitRelocationRequest = 'init_relocation_request',
  AcceptRelocationRequest = 'accept_relocation_request',
  RejectRelocationRequest = 'reject_relocation_request',
  AdminRelocationRequest = 'admin_relocation_request',
  UpdateEffectiveDate = 'update_effective_date',
  RoleAdded = 'role_added',
  RoleRemoved = 'role_removed',
  JobDeleted = 'job_deleted',
  DisciplinaryRecordAdded = 'disciplinary_record_added',
  DisciplinaryRecordUpdated = 'disciplinary_record_updated',
  DisciplinaryRecordDeleted = 'disciplinary_record_deleted',
  EmployeeUpdated = 'employee_updated',
  WageUpdated = 'wage_updated',
  BalanceAdjustment = 'balance_adjustment',
  TimeoffAdded = 'timeoff_added',
  TimeoffUpdated = 'timeoff_updated',
  TimeoffDeleted = 'timeoff_deleted',
  AbsenceAdded = 'wiptec_absence_added',
  AbsenceUpdated = 'wiptec_absence_updated',
  AbsenceDeleted = 'wiptec_absence_deleted',
  SalaryRevisionAdded = 'salary_revision_added',
  SalaryRevisionUpdated = 'salary_revision_updated',
  SalaryRevisionDeleted = 'salary_revision_deleted',
  PerformanceReviewAdded = 'perfomance_review_added',
  PerformanceReviewUpdated = 'perfomance_review_updated',
  PerformanceReviewDeleted = 'perfomance_review_deleted',
  WorkAgreementAdded = 'work_agreement_added',
  WorkAgreementUpdated = 'work_agreement_updated',
  WorkAgreementDeleted = 'work_agreement_deleted',
  TrainingAdded = 'training_added',
  TrainingUpdated = 'training_updated',
  TrainingDeleted = 'training_deleted',
  SkillAdded = 'skill_added',
  SkillUpdated = 'skill_updated',
  SkillDeleted = 'skill_deleted',
  CNESSTRecordAdded = 'cnesst_record_added',
  CNESSTRecordUpdated = 'cnesst_record_updated',
  CNESSTRecordDeleted = 'cnesst_record_deleted',
}

export enum ActionSourceKinds {
  App = 'app',
  Fusion = 'fusion',
}

export const ProfileLogQueryKeys = {
  all: () => ['usersLogs'],
  infinite: (sort?: ProfileLogsQuerySorting, filter?: Record<string, any>) => [
    'usersLogs',
    filter,
    sort,
  ],
  byCursor: (
    page: JsonApi.CursorPagingInput,
    sort?: ProfileLogsQuerySorting,
    filter?: Record<string, any>,
  ) => ['usersLogs', filter, sort, page],
}

export const PROFILE_LOGS_JSON_API_TYPE = 'usersLogs'

import { useACL } from 'hooks/useACL'

import { Actions } from 'services/ACL/config'
import { TimeBucketFeatures } from 'services/ACL/features'

import useAppContext from './useAppContext'

export function useTimeBucketPermissions() {
  const { canPerformTimeBucketsAction } = useACL()
  // NOTE: we can enable the feature in the first place
  const {
    company: {
      settings: { enableTimebuckets },
    },
  } = useAppContext()

  const canViewSelectTimeBuckets =
    canPerformTimeBucketsAction({
      featureId: TimeBucketFeatures.SelectTimeBuckets,
      action: Actions.view,
    }) && enableTimebuckets

  const canModifySelectTimeBuckets =
    canPerformTimeBucketsAction({
      featureId: TimeBucketFeatures.SelectTimeBuckets,
      action: Actions.edit,
    }) && enableTimebuckets

  const canViewTimeBucketName =
    canPerformTimeBucketsAction({
      featureId: TimeBucketFeatures.AccessTimeBucketName,
      action: Actions.doAction,
    }) && enableTimebuckets

  return {
    canViewSelectTimeBuckets,
    canModifySelectTimeBuckets,
    canViewTimeBucketName,
  }
}

import { createRelationAddHandler } from 'redux-json-api-handlers'

import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import mergeWith from 'lodash/mergeWith'

import { createReducer, mergeCustomizer } from 'helpers/redux'

import {
  CREATE_PAUSES,
  CREATE_SHIFTS_JOBS,
  DELETE_PAUSES,
  DELETE_SHIFTS_JOBS,
} from 'store/actions/employees/shifts'

const initialState = {}

const handlers = {
  [CREATE_SHIFTS_JOBS.SUCCESS]: createRelationAddHandler('shiftsJobs', 'shift'),
  [CREATE_PAUSES.SUCCESS]: createRelationAddHandler('pauses', 'shift'),
  [DELETE_PAUSES.SUCCESS]: (state, { payload }) => {
    const relationsName = 'pauses'
    const deletedItems = get(payload, 'deletedItems')
    const relationId = get(payload, 'relationId')

    if (isEmpty(deletedItems) || !relationId) {
      return state
    }
    const lookup = keyBy(deletedItems, item => item.id)

    const data = filter(
      state[relationId].relationships[relationsName].data,
      item => !lookup[item.id],
    )

    return mergeWith(
      {},
      state,
      {
        [relationId]: {
          relationships: {
            [relationsName]: {
              data,
            },
          },
        },
      },
      mergeCustomizer,
    )
  },
  // TODO: replace with handlers
  [DELETE_SHIFTS_JOBS.SUCCESS]: (state, { payload }) => {
    const relationsName = 'shiftsJobs'
    const deletedItems = get(payload, 'deletedItems')
    const relationId = get(payload, 'relationId')

    if (isEmpty(deletedItems) || !relationId) return state

    const lookup = keyBy(deletedItems, item => item.id)

    const data = filter(
      state[relationId]?.relationships?.[relationsName]?.data,
      item => !lookup[item.id],
    )

    return mergeWith(
      {},
      state,
      {
        [relationId]: {
          relationships: {
            [relationsName]: {
              data,
            },
          },
        },
      },
      mergeCustomizer,
    )
  },
}

export default createReducer(initialState, handlers)

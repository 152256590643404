import React from 'react'

import { AiOutlineClose as RejectIcon } from 'react-icons/ai'

import { ButtonProps, IconButton } from 'components/ui/__v3__/Button'

type Props = {
  onReject: () => void
} & ButtonProps

export function Reject({ onReject, ...rest }: Props) {
  return (
    <IconButton {...rest} secondary onClick={onReject}>
      <RejectIcon />
    </IconButton>
  )
}

import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { Input } from 'components/ui/__v3__/Input'
import { InputProps, TextAreaProps } from 'components/ui/__v3__/Input/types'

import { Error } from './components'
import { ControllerBaseProps } from './types'

export type FormTextProps<T extends FieldValues> = ControllerBaseProps<T> &
  InputProps &
  TextAreaProps

export function FormText<T extends FieldValues>({
  name,
  control,
  ...rest
}: FormTextProps<T>) {
  const {
    field: { onChange, value, ref, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  return (
    <Input
      {...rest}
      errored={!!error}
      helper={error && <Error error={error} />}
      ref={ref}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
    />
  )
}

import { create, enforce, test } from 'vest'

import { i18n } from 'i18n'

import { NLPInferenceState } from './types'

export const validator = create((data: NLPInferenceState) => {
  test('query', i18n('validation.required'), () => {
    enforce(data.query).isNotBlank()
  })
})

import React, { ComponentType, createContext } from 'react'

import { AppContextState } from './types'

export const AppContext = createContext({} as AppContextState)

export function withAppContext(Component: ComponentType) {
  return function ComponentWithAppContext(props: any) {
    return (
      <AppContext.Consumer>
        {context => <Component {...props} {...context} />}
      </AppContext.Consumer>
    )
  }
}

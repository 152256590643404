import React from 'react'

import { Flex } from 'components/ui/__v2__/Grid'

import { Checked, NotChecked } from './styles'

interface CheckIconProps {
  checked: unknown
}
export function CheckIcon({ checked }: CheckIconProps) {
  return (
    <Flex alignItems="center">{checked ? <Checked /> : <NotChecked />}</Flex>
  )
}

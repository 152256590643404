import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { Flex } from 'components/ui/__v2__/Grid'
import { Error } from 'components/ui/__v3__/HookForm/components/Error'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import {
  TimeBucketChildrenPicker,
  TimeBucketChildrenPickerProps,
} from '../TimeBucketComponents'

type Props<T extends FieldValues> = ControllerBaseProps<T> &
  LabelProps &
  Omit<TimeBucketChildrenPickerProps, 'onChange' | 'value' | 'onBlur'>

export function FormTimeBucketChildrenPicker<T extends FieldValues>({
  control,
  name,
  labelText,
  required,
  ...rest
}: Props<T>) {
  const {
    field: fieldProps,
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  const { onChange, onBlur, value } = fieldProps

  return (
    <Flex flexDirection="column">
      {labelText && (
        <InputLabel>
          <LabelText labelText={labelText} required={required} />
        </InputLabel>
      )}
      <TimeBucketChildrenPicker
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        {...rest}
      />
      {error && <Error error={error} />}
    </Flex>
  )
}

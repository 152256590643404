import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { FiX } from 'react-icons/fi'

import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

import { Button } from 'components/ui/__v2__/Button'
import { CornerIcon } from 'components/ui/CornerIcon'
import { ModalWindow } from 'components/ui/ModalWindow'

import _ from 'i18n'

import Permissions from './Permissions'
import { ButtonsRow, Container, Title } from './styles'

class LocationRulesSettings extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      payrollAccessIds: null,
      isDiffPayrollAccess: false,
    }
  }

  handleChange = (payrollAccessIds, isDiffPayrollAccess) => {
    this.setState({ payrollAccessIds, isDiffPayrollAccess })
  }

  handleSet = () => {
    const { onSave, onClose } = this.props
    const { payrollAccessIds, isDiffPayrollAccess } = this.state

    if (isEmpty(payrollAccessIds)) return

    const settings = {
      payrollAccessIds,
    }

    onSave({
      attributes: {
        settings,
      },
      branchImageId: -1,
      isProfileSettingsPage: false,
      isLocationRules: true,
      isDiffPayrollAccess,
    })

    onClose()
  }

  render() {
    const { location, onClose } = this.props

    return (
      <ModalWindow callback={onClose}>
        <Container>
          <CornerIcon onClick={onClose}>
            <FiX size={24} />
          </CornerIcon>

          <Title>{_('settings.permissions')}</Title>

          <Permissions location={location} onChange={this.handleChange} />

          <ButtonsRow>
            <Button mr={12} secondary width="100%" onClick={onClose}>
              {_('actions.cancel')}
            </Button>
            <Button ml={12} width="100%" onClick={this.handleSet}>
              {_('actions.set')}
            </Button>
          </ButtonsRow>
        </Container>
      </ModalWindow>
    )
  }
}

LocationRulesSettings.defaultProps = {
  onClose: noop,
  onSave: noop,
}

LocationRulesSettings.propTypes = {
  location: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
}

export default LocationRulesSettings

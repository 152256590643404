import { EARNING_TYPE_CODE_REGEX } from 'constants/deluxe'

import { EarningTypePickerOption } from './types'

export function earningTypeToOption(
  earningType: Gateway.EarningType,
): EarningTypePickerOption {
  return {
    id: earningType.id,
    value: earningType.id,
    label: `${earningType.code} - ${earningType.name}`,
  }
}

export function inputToFilter(input: string): Gateway.EarningTypeFilter {
  if (input.match(EARNING_TYPE_CODE_REGEX)) {
    return { code: { ilike: input } }
  }
  return { name: { ilike: input } }
}

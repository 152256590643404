import { connect } from 'react-redux'

import { updateCompanySettings } from 'store/actions/company/companies'

import { GlobalSettingsModal } from './GlobalSettingsModal'

const actions = {
  onUpdateCompanySettings: updateCompanySettings,
}

export default connect(null, actions)(GlobalSettingsModal)

import { ScheduleConflictActionTypes } from 'constants/ids'

import coreApiCall from 'services/API/coreApi'

interface ApproveTimeoffRequestMutationArgs {
  timeoffId: string
  scheduleConflictType: ScheduleConflictActionTypes
  comment?: string
}

export function approveTimeoffRequestMutation({
  timeoffId,
  comment,
  scheduleConflictType,
}: ApproveTimeoffRequestMutationArgs): Promise<JsonApi.Response> {
  const query: JsonApi.TimeoffApproveArgs = {
    data: {
      attributes: {
        comment,
        scheduleConflictType,
      },
    },
  }

  return coreApiCall({
    endpoint: `/timeoffs/${timeoffId}/accept`,
    method: 'POST',
    query,
  })
}

import CloneRulesetMutation from './CloneRuleset.graphql'
import CreateRulesetMutation from './CreateRuleset.graphql'
import DeleteRulesetMutation from './DeleteRuleset.graphql'
import PublishRulesetMutation from './PublishRuleset.graphql'
import UnpublishRulesetMutation from './UnpublishRuleset.graphql'
import UpdateRulesetMutation from './UpdateRuleset.graphql'

export {
  CloneRulesetMutation,
  CreateRulesetMutation,
  DeleteRulesetMutation,
  PublishRulesetMutation,
  UnpublishRulesetMutation,
  UpdateRulesetMutation,
}

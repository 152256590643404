import { useStoreon } from 'storeon/react'

import { i18n } from 'i18n'

import { StoreonEvents, StoreonState } from 'services/StoreOn'
import {
  CLOSE_CONFIRMATION_MODAL,
  CONFIRMATION_MODAL_KEY,
  ConfirmationModalPayload,
  ModalCallback,
  OPEN_CONFIRMATION_MODAL,
} from 'services/StoreOn/modules'

export function useConfirmationModal() {
  const { dispatch } = useStoreon<StoreonState, StoreonEvents>(
    CONFIRMATION_MODAL_KEY,
  )

  const getConfirmation = ({
    title,
    content,
    cancelText, // optional, default message in modal component
    confirmText, // optional, default message in modal component
  }: Omit<ConfirmationModalPayload, 'handleConfirm'>): Promise<boolean> => {
    const promise = new Promise<boolean>(resolve => {
      const handleConfirm: ModalCallback = confirmed => {
        dispatch(CLOSE_CONFIRMATION_MODAL)

        resolve(confirmed)
      }

      dispatch(OPEN_CONFIRMATION_MODAL, {
        title,
        content,
        handleConfirm,
        cancelText,
        confirmText,
      })
    })

    return promise
  }

  const getDeleteConfirmation = ({
    entityName,
    entityItemName,
  }: {
    entityName: string
    entityItemName: string
  }): Promise<boolean> => {
    return getConfirmation({
      title: i18n('confirmationModal.delete.title', { entityName }),
      content: i18n('confirmationModal.delete.message', { entityItemName }),
    })
  }

  return {
    getConfirmation,
    getDeleteConfirmation,
  }
}

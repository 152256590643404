import { Interval } from 'luxon'
import { create, each, test } from 'vest'

import { TimeEntryState } from 'constants/gatewayGraphQL'

import { i18n } from 'i18n'

import {
  entriesIntersectionWithDay,
  entriesToIntervals,
  FULL_DAY_SECONDS,
} from './mappers'

import { TimesheetFormState } from '../types'

export const validator = (dayIntervals: Interval[]) =>
  create((data: TimesheetFormState) => {
    // Note: fallback to [] here because the field turns undeifined when empty, liekly due to the way how the workarouneded field array methods work
    const entries = data?.entries ?? []
    const entriesIntervals = entriesToIntervals(entries)

    test(
      'entries.root',
      i18n('weeklyTimesheets.validation.cantSaveSubmitEmptySheet'),
      () => {
        if (entries.length === 0) return false
        return true
      },
    )

    test(
      'entries.root',
      i18n('weeklyTimesheets.validation.cantHaveMoreThan24hrsWithinDay'),
      () => {
        return dayIntervals.every(dayInterval => {
          const intersectionsWithDayIntervals = entriesIntersectionWithDay(
            entriesIntervals,
            dayInterval,
          )
          const dayDuration = intersectionsWithDayIntervals.reduce(
            (acc, interval) => acc + interval.length('seconds'),
            0,
          )

          return dayDuration <= FULL_DAY_SECONDS
        })
      },
    )

    each(entries, (entry, idx) => {
      if (
        (entry.state === TimeEntryState.Pending || !entry.state) &&
        entry.submittedAt
      ) {
        test(
          `entries.${idx}`,
          i18n('weeklyTimesheets.validation.cantHaveOverlapsWithOtherRecords'),
          () => {
            const targetInterval = entriesIntervals.at(idx)!
            const otherIntervals = entriesIntervals.filter(
              (_, index) => index !== idx,
            )

            return !otherIntervals.some(interval =>
              interval.overlaps(targetInterval),
            )
          },
          `${idx}-overlap`,
        )
      }
    })
  })

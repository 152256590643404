import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { vestResolver } from '@hookform/resolvers/vest'
import { ShiftAPIItem } from 'API'

import { SELECTED_SCHEDULE_IDS_FIELD } from '../constants'
import { mapServerDataToFormData, validator } from '../helpers'
import { ShiftMoveModalFormState } from '../types'

export function useShiftMoveForm(shift: ShiftAPIItem) {
  const {
    shiftJobIds,
    defaultDate,
    defaultStartTime,
    defaultEndTime,
  } = useMemo(() => mapServerDataToFormData(shift), [shift])

  const {
    control,
    formState: { isSubmitting, isValid },
    handleSubmit: formHandleSubmit,
    setValue,
    watch,
  } = useForm<ShiftMoveModalFormState>({
    mode: 'onBlur',
    resolver: vestResolver(validator),
    defaultValues: {
      date: defaultDate,
      startTime: defaultStartTime,
      endTime: defaultEndTime,
      selectedScheduleIds: new Set(),
      reduceQuantity: false,
    },
  })

  const [watchedStartTime, watchedEndTime] = watch(['startTime', 'endTime'])
  const [watchedSelectedScheduleIds, watchedReduceQuantity] = watch([
    SELECTED_SCHEDULE_IDS_FIELD,
    'reduceQuantity',
  ])

  const isAllPositionsSelected =
    watchedSelectedScheduleIds.size === shift.totalPositions

  const disableReduceQuantity = isAllPositionsSelected || isSubmitting
  const disableConfirmButton = !isValid || isSubmitting
  const differenceBetweenTimes = watchedEndTime - watchedStartTime

  useEffect(() => {
    if (!isAllPositionsSelected || !watchedReduceQuantity) return

    setValue('reduceQuantity', false)
  }, [isAllPositionsSelected, watchedReduceQuantity, setValue])

  return {
    control,
    isSubmitting,
    differenceBetweenTimes,
    disableReduceQuantity,
    disableConfirmButton,
    shiftJobIds,
    formHandleSubmit,
    setValue,
  }
}

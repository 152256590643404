import { useApolloMutation } from 'API/services/Apollo'

import {
  TestValidateShiftMutation,
  TestValidateShiftMutationData,
} from './GraphQL'

export function useTestShiftValidation() {
  const [innerTestShiftValidation, { loading }] = useApolloMutation<
    TestValidateShiftMutationData,
    Gateway.MutationTestValidateShiftArgs
  >(TestValidateShiftMutation, {
    translationKey: 'ruleValidation.shiftValidation.toasts.testShiftValidation',
  })

  async function testShiftValidation(input: Gateway.TestValidateShiftInput) {
    const result = await innerTestShiftValidation({
      variables: {
        input,
      },
    })

    return result.data?.testValidateShift ?? []
  }

  return {
    testShiftValidation,
    testingShiftValidation: loading,
  }
}

export function useLinkDownload() {
  const download = (
    url: string,
    attributes?: Array<{ qualifiedName: string; value: string }>,
  ): void => {
    const link = document.createElement('a')
    link.href = url
    link.download = ''

    if (attributes?.length) {
      attributes.forEach(({ qualifiedName, value }) => {
        link.setAttribute(qualifiedName, value)
      })
    }

    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
  }

  return { download }
}

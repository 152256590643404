import { DefaultTheme } from 'styled-components'

import { ScheduleAcceptanceState } from 'constants/gatewayGraphQL'
import {
  SCHEDULE_ACCEPTANCE_JSON_API_STATE_TO_GRAPHQL_STATE,
  SCHEDULE_ACCEPTANCE_STATE_ICONS,
  ScheduleAcceptanceJsonApiState,
} from 'constants/scheduleAcceptance'

import { i18n } from 'i18n'

// NOTE: in case we pass the status from the JSON API
export function mapJsonAPiStateToGraphQlState({
  state,
  autoAccept,
}: {
  state: ScheduleAcceptanceJsonApiState | ScheduleAcceptanceState
  autoAccept?: boolean
}): ScheduleAcceptanceState {
  if (autoAccept) return ScheduleAcceptanceState.AutoAccepted

  if (
    Object.values(ScheduleAcceptanceJsonApiState).includes(
      state as ScheduleAcceptanceJsonApiState,
    )
  ) {
    return SCHEDULE_ACCEPTANCE_JSON_API_STATE_TO_GRAPHQL_STATE[
      state as ScheduleAcceptanceJsonApiState
    ]
  }
  return state as ScheduleAcceptanceState
}

export function getStateColor({
  state,
  theme,
  autoAccept,
}: {
  state: ScheduleAcceptanceJsonApiState | ScheduleAcceptanceState
  theme: DefaultTheme
  autoAccept?: boolean
}) {
  const statusColors = {
    [ScheduleAcceptanceState.Pending]: theme.colors.WARNING_DARK,
    [ScheduleAcceptanceState.Accepted]: theme.colors.SUCCESS,
    [ScheduleAcceptanceState.AutoAccepted]: theme.colors.BONDI_BLUE,
    [ScheduleAcceptanceState.Rejected]: theme.colors.INPUT_ERROR,
    [ScheduleAcceptanceState.TimedOut]: theme.colors.NOBEL,
  }

  const definedState = mapJsonAPiStateToGraphQlState({ state, autoAccept })
  return statusColors[definedState]
}

export function getStateIcon({
  state,
  autoAccept,
}: {
  state: ScheduleAcceptanceJsonApiState | ScheduleAcceptanceState
  autoAccept?: boolean
}) {
  const definedState = mapJsonAPiStateToGraphQlState({ state, autoAccept })
  return SCHEDULE_ACCEPTANCE_STATE_ICONS[definedState]
}

export function getStateText({
  state,
  autoAccept,
}: {
  state: ScheduleAcceptanceJsonApiState | ScheduleAcceptanceState
  autoAccept?: boolean
}) {
  const definedState = mapJsonAPiStateToGraphQlState({ state, autoAccept })
  return i18n(`scheduleAcceptance.scheduleAcceptanceStatus.${definedState}`)
}

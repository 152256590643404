import { useApolloMutation } from 'API/services/Apollo'

import { DeleteClassifierMutation } from './GraphQL'

export function useDeleteClassifier() {
  const [innerDelete, { loading }] = useApolloMutation<
    MutationData<'deleteClassifier'>,
    Gateway.MutationDeleteClassifierArgs
  >(DeleteClassifierMutation, {
    entity: 'settings.classifiers.title',
    mutationType: 'delete',
    update: cache => cache.evict({ fieldName: 'classifiersByCursor' }),
  })

  async function deleteClassifier(id: string) {
    const result = await innerDelete({
      variables: {
        id,
      },
    })
    return Boolean(result.errors)
  }

  return {
    deleteClassifier,
    deletingClassifier: loading,
  }
}

import EmployeeJobWithEmploymentTermsFieldsFragment from 'API/EmployeeJob/GraphQL/Fragments/EmployeeJobWithEmploymentTermsFields.graphql'
import RulesetFieldsFragment from 'API/Ruleset/GraphQL/Fragments/RulesetFields.graphql'
import ShiftFieldsFragment from 'API/Shift/GraphQL/Fragments/ShiftFields.graphql'
import ShiftJobFieldsFragment from 'API/ShiftJob/GraphQL/Fragments/ShiftJobFields.graphql'

export const FragmentOptions = {
  Shift: (id: string) => ({
    id: `Shift:${id}`,
    fragment: ShiftFieldsFragment,
    fragmentName: 'ShiftFields',
  }),
  ShiftJob: (id: string) => ({
    id: `ShiftJob:${id}`,
    fragment: ShiftJobFieldsFragment,
    fragmentName: 'ShiftJobFields',
  }),
  EmployeeJobWithEmploymentTerms: (id: string) => ({
    id: `EmployeeJob:${id}`,
    fragment: EmployeeJobWithEmploymentTermsFieldsFragment,
    fragmentName: 'EmployeeJobWithEmploymentTermsFields',
  }),
  Ruleset: (id: string) => ({
    id: `Ruleset:${id}`,
    fragment: RulesetFieldsFragment,
    fragmentName: 'RulesetFields',
  }),
}

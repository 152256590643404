import get from 'lodash/get'
import has from 'lodash/has'
import isString from 'lodash/isString'
import orderBy from 'lodash/orderBy'

/**
 * Custom sort function. Sorts text deeply in HTML.
 * @see https://github.com/jbetancur/react-data-table-component#sorting
 */
export default function deepTextSearchFunction(rows, field, direction) {
  const predicate = row => {
    const prop = get(row, field)
    if (isString(prop)) {
      return prop.toLowerCase()
    }
    if (has(row, 'customSortSelectors')) {
      return get(row, `customSortSelectors.${field}`)
    }
    return prop
  }
  return orderBy(rows, predicate, direction)
}

import React from 'react'
import { FieldValues, useController } from 'react-hook-form'

import { LayoutProps, SpaceProps } from 'styled-system'

import { Flex } from 'components/ui/__v2__/Grid'
import { Error } from 'components/ui/__v3__/HookForm/components'
import { ControllerBaseProps } from 'components/ui/__v3__/HookForm/types'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import { EmployeePicker, EmployeePickerArgs } from '../EmployeePicker'

type Props<T extends FieldValues> = ControllerBaseProps<T> &
  LabelProps &
  LayoutProps &
  SpaceProps &
  Omit<EmployeePickerArgs, 'value' | 'onSelect' | 'onBlur'>

export function FormEmployeePicker<T extends FieldValues>({
  control,
  name,
  labelText,
  required,
  ...rest
}: Props<T>) {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    control,
    name,
  })

  return (
    <Flex flexDirection="column">
      {labelText && (
        <InputLabel>
          <LabelText labelText={labelText} required={required} />
        </InputLabel>
      )}
      <EmployeePicker
        {...rest}
        value={value}
        onBlur={onBlur}
        onSelect={onChange}
      />
      {error && <Error error={error} />}
    </Flex>
  )
}

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import * as Sentry from '@sentry/browser'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { eventId: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true })
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  handleShowReport = () => {
    const { eventId } = this.state
    Sentry.showReportDialog({ eventId })
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return (
        <button type="button" onClick={this.handleShowReport}>
          Report feedback
        </button>
      )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary

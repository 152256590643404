import { Environment } from './ids'

const { REACT_APP_CI, REACT_APP_CIRCLE_BRANCH } = process.env

const CRA_PREFIX = 'REACT_APP_'

const PREFIX =
  REACT_APP_CI === 'true'
    ? {
        develop: `${CRA_PREFIX}DEVELOP_`,
        refactor: `${CRA_PREFIX}REFACTOR_`,
        master: `${CRA_PREFIX}STAGING_`,
        production: `${CRA_PREFIX}PRODUCTION_`,
      }[REACT_APP_CIRCLE_BRANCH ?? ''] || CRA_PREFIX
    : CRA_PREFIX

const getEnv = (env: string) => process.env[`${PREFIX}${env}`] ?? ''

export const APP_ENV = getEnv('ENV') as Environment

export const APP_ENVIRONMENT = Object.freeze({
  [Environment.Development]: APP_ENV === Environment.Development,
  [Environment.Staging]: APP_ENV === Environment.Staging,
  [Environment.Production]: APP_ENV === Environment.Production,
})

// Note: Used for temporary pr-deploy
export const PUBLIC_PATH = process.env.REACT_APP_PUBLIC_PATH
export const PR_NUMBER = process.env.REACT_APP_PR_NUMBER

export const BUILD_NUMBER = process.env.REACT_APP_CIRCLE_BUILD_NUM

// TODO: revise and remove unused
const webHttps = getEnv('WEB_HTTPS') === 'true' ? 'https' : 'http'
const apiHttps = getEnv('API_HTTPS') === 'true' ? 'https' : 'http'
const apiWss = getEnv('API_HTTPS') === 'true' ? 'wss' : 'ws'
const hathorApiHttps = getEnv('NODE_API_HTTPS') === 'true' ? 'https' : 'http'
const hathorApiWss = getEnv('NODE_API_HTTPS') === 'true' ? 'wss' : 'ws'

export const api = {
  rubyUrl: `${apiHttps}://${getEnv('API_URL')}/v1`,
  gatewayUrl: `${apiHttps}://${getEnv('API_URL')}/v2`,
  filesUrl: `${apiHttps}://${getEnv('FILES_URL')}`,
  gatewayWsUrl: `${apiWss}://${getEnv('API_URL')}/v2`,
  hathorUrl: `${hathorApiHttps}://${getEnv('NODE_API_URL')}`,
  hathorWsUrl: `${hathorApiWss}://${getEnv('NODE_API_URL')}`,
}

export const chatApi = {
  url: `https://${getEnv('CHAT_URL')}/api/v1`,
  s3Url: `https://${getEnv('CHAT_FILES_URL')}`,
  filesUrl: `https://${getEnv('CHAT_FILES_URL')}/files/`,
  listenerUrl: `wss://${getEnv('CHAT_URL')}/socket`,
}

export const domain = `${webHttps}://${getEnv('WEB_URL')}`

export const auth0 = {
  domain: getEnv('AUTH0_DOMAIN'),
  clientId: getEnv('AUTH0_CLIENT_ID'),
  audience: getEnv('AUTH0_AUDIENCE'),
  redirectUri: `${window.location.origin}/auth`,
}

export const cookie = {
  sharedDomain: `.${getEnv('API_URL')}`,
  prefix: 'workaxle',
}

export const google = {
  mapsApiKey: getEnv('GMAPS_API_KEY'),
}

export const pusher = {
  token: getEnv('PUSHER_TOKEN'),
  options: {
    authEndpoint: `${api?.rubyUrl}/pusher/auth`,
    encrypted: true,
  },
}

export const sentry = {
  dsn: getEnv('SENTRY_DSN'),
}

export const stripe = {
  key: getEnv('STRIPE_KEY'),
}

export const fullstory = {
  org: getEnv('FULLSTORY_ORG'),
}

export const IS_PRODUCTION = APP_ENV.startsWith('production')

export const flatfile = {
  shiftKey: getEnv('FLATFILE_SHIFT_EMBED_KEY'),
}

export const misc = {
  theme: 'main',
  /** @deprecated */
  disableBilling: getEnv('DISABLE_BILLING') === 'true',
  /**
   * Hathor is disabled on prod only
   */
  disableHathor: getEnv('DISABLE_HATHOR') === 'true',
}

export const THEME_NAMES = {
  /** @deprecated In favor of light theme */
  main: 'main',
  light: 'light',
  /** Not implemented yet */
  dark: 'dark',
}

export default {
  APP_ENV,
  cookie,
  api,
  auth0,
  domain,
  pusher,
  sentry,
  fullstory,
  misc,
  IS_PRODUCTION,
}

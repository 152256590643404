import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

export const LOAD_VIEWER = createAsyncAction('viewer/LOAD')
export const LOAD_VIEWER_BRANCHES = createAsyncAction('viewer/LOAD_BRANCHES')
export const LOAD_VIEWER_COMPANY = createAsyncAction('viewer/LOAD_COMPANY')
export const RECEIVE_PROFILE = 'viewer/RECEIVE_PROFILE'

export const UPDATE_USER_SETTINGS = createAsyncAction(
  'viewer/UPDATE_USER_SETTING',
)

export const SET_DEFAULT_PAYMENT_SOURCE = createAsyncAction(
  'viewer/SET_DEFAULT_PAYMENT_SOURCE',
)

export const UNSUBSCRIBE = createAsyncAction('viewer/UNSUBSCRIBE')

export const LOAD_INVOICES = createAsyncAction('viewer/LOAD_INVOICES')

export const SET_BRANCH = 'viewer/SET_BRANCH'
export const SET_COMPANY = 'viewer/SET_COMPANY'
export const SET_AS_EMPLOYEE = 'viewer/SET_AS_EMPLOYEE'

export const TOGGLE_THEME = 'viewer/TOGGLE_THEME'

export const GENERATE_TOKEN = createAsyncAction('viewer/GENERATE_TOKEN')
export const LOAD_COUNTERS = createAsyncAction('viewer/LOAD_COUNTERS')

export const GET_EMAIL_BY_CODE = createAsyncAction('viewer/GET_EMAIL_BY_CODE')

export const toggleTheme = themeName => dispatch => {
  dispatch(updateUserSettings({ theme: themeName }))
}

export const setBranch = branchId => ({
  type: SET_BRANCH,
  payload: { branchId },
})

export const setAsEmployee = asEmployee => ({
  type: SET_AS_EMPLOYEE,
  payload: { asEmployee },
})

const viewerIncludes = [
  'profile.profileAvatar',
  'managers.departments.branch',
  'managers.all_managed_branches.branch',
  'managers.all_managed_departments.department.branch',
  'employees',
  'employees.branch',
  'employees.jobsEmployees.companySetting',
]

export const loadViewer = () =>
  apiCall({
    endpoint: '/user',
    query: {
      include: viewerIncludes.join(),
    },
    types: LOAD_VIEWER,
  })

const viewerCompanyIncludes = [
  'subscriptions.products',
  'subscriptions.plans',
  // 'locationTags',
]

export const loadViewerCompany = () => {
  return apiCall({
    endpoint: '/user/relationships/company',
    query: {
      include: viewerCompanyIncludes.join(),
    },
    types: LOAD_VIEWER_COMPANY,
  })
}

const viewerBranchesIncludes = [
  'images',
  'sector',
  'locationTags',
  'departments.branch',
  'departments.jobs.department',
  'departments.jobs.branch',
  'prayerSettings',
  'prayerSettings.branch',
]

export const loadViewerBranches = (
  { number, size } = { number: 1, size: 10 },
) => {
  return apiCall({
    endpoint: '/user/relationships/branches',
    query: {
      include: viewerBranchesIncludes.join(),
      page: {
        number,
        size,
      },
    },
    paged: true,
    types: LOAD_VIEWER_BRANCHES,
  })
}
/** @deprecated */
export const updateUserSettings = settings => {
  return apiCall({
    method: 'PATCH',
    endpoint: `/user`,
    types: UPDATE_USER_SETTINGS,
    query: {
      data: {
        type: 'users',
        attributes: {
          settings,
        },
      },
    },
  })
}

export const setCompany = companyId => ({
  type: SET_COMPANY,
  companyId,
})

export const unsubscribe = id => {
  return apiCall({
    endpoint: `/subscriptions/${id}/cancel`,
    method: 'POST',
    types: UNSUBSCRIBE,
  })
}

export const loadCounters = () => {
  return apiCall({
    endpoint: '/user/relationships/unread_counts',
    types: LOAD_COUNTERS,
    isRaw: true,
  })
}

export const receiveProfile = payload => ({
  type: RECEIVE_PROFILE,
  payload,
  meta: {
    isSuccess: true,
  },
})

export const generateToken = ({ scope } = { scope: 'api:read' }) => {
  return apiCall({
    endpoint: '/token',
    method: 'POST',
    isRaw: true,
    query: {
      scope,
    },
    types: GENERATE_TOKEN,
  })
}

import React from 'react'

import { ShiftValidationRuleAPIItem } from 'API'

import { HoursRangeCondition } from './HoursRangeCondition'

type Props = {
  rule: ShiftValidationRuleAPIItem
}

// TODO: add common i18n for this component & RuleRenderer->ShiftRestTime
export function ShiftRestTime({ rule }: Props) {
  return (
    <>
      A shift gap of{' '}
      <HoursRangeCondition formSubPath="shiftRestTime" rule={rule} /> was
      detected
    </>
  )
}

import { useApolloMutation } from 'API/services/Apollo'
import { v4 as uuid } from 'uuid'

import {
  TestEvaluateTimeMutation,
  TestEvaluateTimeMutationData,
} from './GraphQL'

export function useTestEvaluateTime() {
  const [innerSimulate, { loading }] = useApolloMutation<
    TestEvaluateTimeMutationData,
    Gateway.MutationTestClassifyTimeArgs
  >(TestEvaluateTimeMutation, {
    translationKey: 'timeEvaluation.toasts.testTimeEvaluation',
  })

  async function simulateTimeEvaluation(input: Gateway.TestEvaluateTimeInput) {
    const result = await innerSimulate({
      variables: {
        input,
      },
    })

    return (
      result.data?.testEvaluateTime.map(item => ({
        ...item,
        listId: uuid(),
      })) ?? []
    )
  }

  return {
    simulateTimeEvaluation,
    simulating: loading,
  }
}

export const LABEL_DAY_TYPE = {
  SYSTEM: 'system',
  CUSTOM: 'custom',
  HOLIDAY: 'holiday',
}

export const SYSTEM_LABEL_TYPE = {
  ABSENCE: 'absence',
  WORKED: 'worked',
  PARTIALLY_WORKED: 'partially_worked',
  BLANK: 'blank',
}

export const CUSTOM_LABEL_TYPE = {
  WEEKLY_VACATION: 'weekly_vacation',
  ANNUAL_VACATION: 'annual_vacation',
  UNPAID_VACATION: 'unpaid_vacation',
  PAID_VACATION: 'paid_vacation',
  HOLIDAY: 'holiday',
}

export const LABEL_COLORS = {
  default: '#212121',
  [SYSTEM_LABEL_TYPE.BLANK]: '#F48961',
  [SYSTEM_LABEL_TYPE.WORKED]: '#63CD94',
  [SYSTEM_LABEL_TYPE.PARTIALLY_WORKED]: '#F3D784',
  [CUSTOM_LABEL_TYPE.WEEKLY_VACATION]: '#61B4F4',
  [CUSTOM_LABEL_TYPE.ANNUAL_VACATION]: '#50D1DD',
  [CUSTOM_LABEL_TYPE.PAID_VACATION]: '#EF95C5',
  [CUSTOM_LABEL_TYPE.UNPAID_VACATION]: '#9D84E8',
  [SYSTEM_LABEL_TYPE.ABSENCE]: '#F46161',
  [LABEL_DAY_TYPE.HOLIDAY]: '#0E34A0',
}

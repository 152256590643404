import { createAsyncAction } from 'helpers/redux'

import apiCall from 'services/API'

import { getCompanyId } from 'store/selectors/viewer'

/**
 * https://api.staging.workaxle.com/doc#task
 */
export const TASK_TEMPLATE = {
  LOAD: createAsyncAction('task template/LOAD'),
  LOAD_BY_ID: createAsyncAction('task template/LOAD_BY_ID'),
  CREATE: createAsyncAction('task template/CREATE'),
  UPDATE: createAsyncAction('task template/UPDATE'),
  DELETE: createAsyncAction('task template/DELETE'),
  CLEAR_TASK_BY_ID: 'task template/CLEAR_TASK_BY_ID',
}

const include = [
  'branches',
  'departments',
  'employees',
  'employees.profile',
  'jobs',
  'tasks_forms.form',
  'subtasks',
].join(',')

function nameToFilter(taskName) {
  return taskName
    ? {
        name: {
          ilike: `%${taskName}%`,
        },
      }
    : null
}

export const loadTaskTemplate = ({ taskId }) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/tasks/${taskId}`,
      types: TASK_TEMPLATE.LOAD_BY_ID,
      method: 'GET',
      query: {
        include,
      },
    }),
  )
}

export const loadTaskTemplates = ({
  number,
  size,
  filters,
  sort = '-name',
}) => (dispatch, getState) => {
  const paged = !!number && !!size
  const page = paged ? { page: { number, size } } : null
  const companyId = getCompanyId(getState())
  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/tasks`,
      types: TASK_TEMPLATE.LOAD,
      method: 'GET',
      query: {
        include,
        ...page,
        filter: {
          ...nameToFilter(filters.name),
        },
        sort,
      },
      paged,
    }),
  )
}

export const createTaskTemplates = ({ attributes, relationships }) => (
  dispatch,
  getState,
) => {
  const companyId = getCompanyId(getState())
  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/tasks`,
      types: TASK_TEMPLATE.CREATE,
      method: 'POST',
      query: {
        include,
        data: {
          attributes,
          relationships,
        },
      },
    }),
  )
}

export const updateTaskTemplate = ({
  taskTemplateId,
  attributes,
  relationships,
}) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())
  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/tasks/${taskTemplateId}`,
      types: TASK_TEMPLATE.UPDATE,
      method: 'PATCH',
      query: {
        include,
        data: {
          attributes,
          relationships,
        },
      },
    }),
  )
}

export const deleteTaskTemplates = taskTemplateIds => (dispatch, getState) => {
  const companyId = getCompanyId(getState())
  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/relationships/tasks`,
      types: TASK_TEMPLATE.DELETE,
      method: 'DELETE',
      query: {
        data: taskTemplateIds.map(id => ({ type: 'task', id })),
      },
      payload: {
        deletedIds: taskTemplateIds,
      },
    }),
  )
}

export const clearTask = () => ({
  type: TASK_TEMPLATE.CLEAR_TASK_BY_ID,
})

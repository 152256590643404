import { StoreonModule } from 'storeon'

import {
  MOVE_SCHEDULES_FROM_SHIFT_SET,
  MOVE_SCHEDULES_FROM_SHIFT_STATE_KEY,
  MOVE_SCHEDULES_FROM_SHIFT_UNSET,
} from './constants'
import {
  MoveSchedulesFromShiftEvents,
  MoveSchedulesFromShiftState,
} from './types'

const INITIAL_STATE: MoveSchedulesFromShiftState = {
  [MOVE_SCHEDULES_FROM_SHIFT_STATE_KEY]: undefined,
}

export const moveSchedulesFromShiftModule: StoreonModule<
  MoveSchedulesFromShiftState,
  MoveSchedulesFromShiftEvents
> = store => {
  store.on('@init', () => ({
    ...INITIAL_STATE,
  }))

  store.on(MOVE_SCHEDULES_FROM_SHIFT_SET, (state, backgroundTaskId) => ({
    ...state,
    [MOVE_SCHEDULES_FROM_SHIFT_STATE_KEY]: backgroundTaskId,
  }))
  store.on(MOVE_SCHEDULES_FROM_SHIFT_UNSET, state => ({
    ...state,
    [MOVE_SCHEDULES_FROM_SHIFT_STATE_KEY]: undefined,
  }))
}

import { EARNING_TYPE_CODE_REGEX } from 'constants/deluxe'

import { AppContextCompanyIdentity } from 'services/AppContext'

import { EarningTypePickerOption } from './types'

export function earningTypeToOptionDefault(
  earningType: Pick<Gateway.EarningType, 'id' | 'name'>,
): EarningTypePickerOption {
  return {
    id: earningType.id,
    value: earningType.id,
    label: earningType.name,
  }
}

export function earningTypeToOptionDeluxe(
  earningType: Pick<Gateway.EarningType, 'id' | 'code' | 'name'>,
): EarningTypePickerOption {
  return {
    id: earningType.id,
    value: earningType.id,
    label: `${earningType.code} - ${earningType.name}`,
  }
}

export function earningTypeToOptionAldo(
  earningType: Pick<Gateway.EarningType, 'id' | 'externalId' | 'name'>,
): EarningTypePickerOption {
  return {
    id: earningType.id,
    value: earningType.id,
    label: `${earningType.externalId} - ${earningType.name}`,
  }
}

export function earningTypeToOption(identity: AppContextCompanyIdentity) {
  if (identity.Deluxe) return earningTypeToOptionDeluxe
  if (identity.Aldo) return earningTypeToOptionAldo
  return earningTypeToOptionDefault
}

export function inputToFilter(input: string): Gateway.EarningTypeFilter {
  if (input.match(EARNING_TYPE_CODE_REGEX)) {
    return { code: { ilike: input } }
  }
  return { name: { ilike: input } }
}

import { QueryHookOptions, useApolloQuery } from 'API/services/Apollo'

import {
  ScheduleSortingField,
  SortingDirection,
} from 'constants/gatewayGraphQL'

import Utils from 'services/Utils'

import {
  MissingQualificationsByCursorQuery,
  MissingQualificationsByCursorQueryData,
} from '../GraphQL'

type Props = {
  shiftJobIds?: string[]
  shiftIds?: string[]
  scheduleIds?: string[]
  paging: Gateway.CursorPaging
}

export function useMissingQualificationsByCursor(
  { shiftJobIds, shiftIds, scheduleIds, paging }: Props,
  queryOptions: Partial<QueryHookOptions>,
) {
  const { data, loading, refetch } = useApolloQuery<
    MissingQualificationsByCursorQueryData,
    Gateway.QuerySchedulesByCursorArgs
  >(MissingQualificationsByCursorQuery, {
    fetchPolicy: 'network-only',
    ...queryOptions,
    variables: {
      filter: {
        ...(shiftJobIds && { shiftJobId: Utils.GraphQL.inFilter(shiftJobIds) }),
        ...(shiftIds && { shiftId: Utils.GraphQL.inFilter(shiftIds) }),
        ...(scheduleIds && { id: Utils.GraphQL.inFilter(scheduleIds) }),
      },
      sorting: [
        {
          field: ScheduleSortingField.Id,
          direction: SortingDirection.Desc,
        },
      ],
      paging,
    },
  })

  const missingQualifications =
    data?.missingQualificationsByCursor?.edges.map(edge => edge.node) ?? []

  return {
    missingQualifications,
    loading,
    refetchMissingQualifications: refetch,
    pageInfo: data?.missingQualificationsByCursor?.pageInfo,
  }
}

import {
  createDeleteHandler,
  createFields,
  createReducerHandlers,
} from 'rapidux'
import Immutable from 'seamless-immutable'

import { createReducer } from 'helpers/redux'

import { LOG_OUT } from 'store/actions/auth'
import {
  SALARY_REVIEW,
  SALARY_REVIEW_KIND,
} from 'store/actions/employees/salaryReview'

export const SALARY_REVIEWS_TYPE = 'salaryRevisions'
export const SALARY_REVIEWS_FIELD = 'salaryRevision'

export const SALARY_REVIEW_KINDS_TYPE = 'salaryRevisionKinds'
export const SALARY_REVIEW_KINDS_FIELD = 'salaryRevisionKind'

const initialState = Immutable({
  ...createFields(SALARY_REVIEWS_TYPE, SALARY_REVIEWS_FIELD),
  ...createFields(SALARY_REVIEW_KINDS_TYPE, SALARY_REVIEW_KINDS_FIELD),
})

const handlers = {
  ...createReducerHandlers(SALARY_REVIEWS_TYPE, SALARY_REVIEW.LOAD, {
    withReplace: true,
    mapToKey: SALARY_REVIEWS_FIELD,
  }),
  ...createReducerHandlers(SALARY_REVIEWS_TYPE, SALARY_REVIEW.CREATE, {
    withReplace: false,
    mapToKey: SALARY_REVIEWS_FIELD,
  }),
  ...createReducerHandlers(SALARY_REVIEWS_TYPE, SALARY_REVIEW.UPDATE),
  [SALARY_REVIEW.DELETE.SUCCESS]: createDeleteHandler(SALARY_REVIEWS_FIELD),

  ...createReducerHandlers(SALARY_REVIEW_KINDS_TYPE, SALARY_REVIEW_KIND.LOAD, {
    withReplace: true,
    mapToKey: SALARY_REVIEW_KINDS_FIELD,
  }),
  ...createReducerHandlers(
    SALARY_REVIEW_KINDS_TYPE,
    SALARY_REVIEW_KIND.CREATE,
    {
      withReplace: true,
      mapToKey: SALARY_REVIEW_KINDS_FIELD,
    },
  ),
  ...createReducerHandlers(SALARY_REVIEW_KINDS_TYPE, SALARY_REVIEW_KIND.UPDATE),
  [SALARY_REVIEW_KIND.DELETE.SUCCESS]: createDeleteHandler(
    SALARY_REVIEW_KINDS_FIELD,
  ),

  [LOG_OUT]: () => initialState,
}

export default createReducer(initialState, handlers)

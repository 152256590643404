import { toastr } from 'react-redux-toastr'

import { all, fork, race, take } from 'redux-saga/effects'

import _ from 'i18n'

import {
  SALARY_REVIEW,
  SALARY_REVIEW_KIND,
} from 'store/actions/employees/salaryReview'

function* watchLoadFailure() {
  while (true) {
    const error = yield take(SALARY_REVIEW.LOAD.FAILURE)

    if (error)
      toastr.error(_('salaryRevision.salaryTable.toasts.reviewLoadFailure'), {
        disableCloseButtonFocus: true,
      })
  }
}

function* watchLoadTypesFailure() {
  while (true) {
    const error = yield take(SALARY_REVIEW_KIND.LOAD.FAILURE)

    if (error)
      toastr.error(
        _('salaryRevision.salaryTable.toasts.reviewKindLoadFailure'),
        {
          disableCloseButtonFocus: true,
        },
      )
  }
}

function* watchDeleteReview() {
  while (true) {
    const { success } = yield race({
      success: take(SALARY_REVIEW.DELETE.SUCCESS),
      error: take(SALARY_REVIEW.DELETE.FAILURE),
    })
    if (success) {
      toastr.success(_('salaryRevision.salaryTable.toasts.deleteSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      toastr.error(_('common.somethingWentWrong'), {
        disableCloseButtonFocus: true,
      })
    }
  }
}

function* watchCreateReview() {
  while (true) {
    const { success } = yield race({
      success: take(SALARY_REVIEW.CREATE.SUCCESS),
      error: take(SALARY_REVIEW.CREATE.FAILURE),
    })
    if (success) {
      toastr.success(_('salaryRevision.salaryTable.toasts.createSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      toastr.error(_('common.somethingWentWrong'), {
        disableCloseButtonFocus: true,
      })
    }
  }
}

function* watchEditReview() {
  while (true) {
    const { success } = yield race({
      success: take(SALARY_REVIEW.UPDATE.SUCCESS),
      error: take(SALARY_REVIEW.UPDATE.FAILURE),
    })
    if (success) {
      toastr.success(_('salaryRevision.salaryTable.toasts.editSuccess'), {
        disableCloseButtonFocus: true,
      })
    } else {
      toastr.error(_('common.somethingWentWrong'), {
        disableCloseButtonFocus: true,
      })
    }
  }
}

export default function* root() {
  yield all([
    fork(watchDeleteReview),
    fork(watchCreateReview),
    fork(watchEditReview),
    fork(watchLoadFailure),
    fork(watchLoadTypesFailure),
  ])
}

import React, { Fragment, useCallback } from 'react'
import { FieldValues, useFieldArray, useWatch } from 'react-hook-form'

import compact from 'lodash/compact'

import { Flex } from 'components/ui/__v2__/Grid'
import { Button } from 'components/ui/__v3__'
import { InputLabel } from 'components/ui/__v3__/Input'
import { LabelText } from 'components/ui/__v3__/Input/LabelText'
import { LabelProps } from 'components/ui/__v3__/Input/types'

import { i18n } from 'i18n'

import { FieldValuePairProps } from './components/FieldValuePair/types'
import { DeleteIconButton, FieldValuePair } from './components'
import { MAX_CUSTOM_FIELD_CONDITIONS } from './config'
import { optionIsBlankGaurd } from './helpers'
import { InputGrid } from './styles'
import { FieldValuePairState } from './types'

type SpecificProps = {
  canDeleteLast?: boolean
  addButtonIsSecondary?: boolean
  maxValuesCount?: number
}
type Props<T extends FieldValues> = Omit<
  FieldValuePairProps<T>,
  'selectedFieldIds' | 'onValueReset'
> &
  LabelProps &
  SpecificProps

export function FormCustomCvFieldsWithValues<T extends FieldValues>({
  canDeleteLast = true,
  control,
  name,
  labelText,
  required,
  addButtonIsSecondary,
  maxValuesCount,
}: Props<T>) {
  // @ts-ignore
  const watched = useWatch<T>({
    control,
    // @ts-ignore
    name,
  }) as Array<FieldValuePairState>

  const { fields, remove, append, update } = useFieldArray<T>({
    control,
    name,
  })

  const cantAdd =
    watched?.length >= (maxValuesCount ?? MAX_CUSTOM_FIELD_CONDITIONS) ||
    hasItemsWithBlanks(watched)

  // Add is blocked if a) limit is reached ,) some item has no field picked
  const handleAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (cantAdd) return
    append({
      // @ts-ignore
      field: undefined,
      value: undefined,
    })
  }

  const handleResetValue = useCallback(
    (index: number) => {
      update(
        index,
        // @ts-ignore
        { field: watched.at(index).field, value: undefined },
      )
    },
    [update, watched],
  )

  // We want to exclude these as options
  const selectedFieldIds = compact(watched.map(item => item.field)).map(
    field => field.id,
  )

  const disableDeleteButton = !canDeleteLast && fields.length === 1

  return (
    <Flex flexDirection="column">
      <InputLabel>
        <LabelText labelText={labelText} required={required} />
      </InputLabel>
      <InputGrid>
        {fields.map((field, index) => (
          <Fragment key={field.id}>
            <FieldValuePair<T>
              control={control}
              // @ts-ignore
              name={`${name}.${index}`}
              selectedFieldIds={selectedFieldIds}
              onValueReset={() => handleResetValue(index)}
            />
            <DeleteIconButton
              disabled={disableDeleteButton}
              onClick={() => remove(index)}
            />
          </Fragment>
        ))}
      </InputGrid>
      <Button
        disabled={cantAdd}
        mt={2}
        secondary={addButtonIsSecondary}
        small
        onClick={handleAdd}
      >
        {i18n('customFieldsWithValuesPicker.actions.add')}
      </Button>
    </Flex>
  )
}

function hasItemsWithBlanks(fields: FieldValuePairState[]): boolean {
  return fields.some(optionIsBlankGaurd)
}

// TODO: Move to API and refactor

import { AreasFilter } from 'Types/common'

import { isArray, trim } from 'lodash'

export function eqFilter<T = string>(value: T) {
  return { eq: value }
}

export function inFilter<T = string>(values: T[] | undefined) {
  if (!values) return {}
  if (values.length === 0) return {}
  return { in: values }
}

type GraphQlAreasFilter = {
  branchId?: Gateway.InputMaybe<Gateway.IdFilter>
  departmentId?: Gateway.InputMaybe<Gateway.IdFilter>
  jobId?: Gateway.InputMaybe<Gateway.IdFilter>
  employeeId?: Gateway.InputMaybe<Gateway.IdFilter>
}

export function inAreasFilter(
  areas: AreasFilter | undefined,
): GraphQlAreasFilter {
  if (!areas) return {}

  const filters: GraphQlAreasFilter = {}

  if (areas.locations) {
    if (isArray(areas.locations)) {
      filters.branchId =
        areas.locations.length > 0
          ? { in: areas.locations.map(location => location.id!) }
          : undefined
    } else {
      filters.branchId = { in: [areas.locations.id] }
    }
  }

  if (areas.departments) {
    if (isArray(areas.departments)) {
      filters.departmentId =
        areas.departments.length > 0
          ? { in: areas.departments.map(department => department.id!) }
          : undefined
    } else {
      filters.departmentId = { in: [areas.departments.id] }
    }
  }

  if (areas.jobs) {
    if (isArray(areas.jobs)) {
      filters.jobId =
        areas.jobs.length > 0
          ? { in: areas.jobs.map(job => job.id!) }
          : undefined
    } else {
      filters.jobId = { in: [areas.jobs.id] }
    }
  }

  if (areas.employees) {
    const employeeIds = Array.isArray(areas.employees)
      ? areas.employees.map(employee => employee.id!)
      : [areas.employees.id!]

    filters.employeeId = {
      in: employeeIds,
    }
  }

  return filters
}

export function lteDateFilter(value: string) {
  return { lte: value }
}

export function betweenFilter({ from, to }: { from: string; to: string }) {
  return { between: [from, to] }
}

export function ilikeFilter(text: string) {
  return { ilike: trim(text) }
}

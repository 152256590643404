import { createRelationDeleteHandler } from 'redux-json-api-handlers'

import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNull from 'lodash/isNull'
import map from 'lodash/map'
import merge from 'lodash/merge'
import mergeWith from 'lodash/mergeWith'
import omit from 'lodash/omit'
import toString from 'lodash/toString'

import { CHAT_KINDS, SYSTEM_MESSAGES } from 'constants/groupChat'

import { createReducer, mergeCustomizer } from 'helpers/redux'

import {
  LEFT_CHAT,
  LOAD_CHAT_MESSAGES,
  RECEIVED_CREATED_CHAT,
  RECEIVED_MESSAGE,
  RECEIVED_SYSTEM_MESSAGE,
} from 'store/actions/groupChat'

const initialState = {}

const handlers = {
  [RECEIVED_MESSAGE]: (state, { payload }) => {
    const {
      data: { message, chatId, isMessageUpdated },
    } = payload
    const messageId = toString(get(message, 'id', null))
    const stateChat = get(state, [chatId], {})
    const nowTime = new Date().toISOString()
    return !isEmpty(stateChat) && !isMessageUpdated && messageId
      ? merge({}, state, {
          [chatId]: {
            attributes: {
              updatedAt: nowTime,
            },
            relationships: {
              message: {
                data: {
                  id: messageId,
                  type: 'messages',
                },
              },
            },
          },
        })
      : state
  },
  [LOAD_CHAT_MESSAGES.SUCCESS]: (state, { payload }) => {
    const chats = get(payload, 'data.chats', {})
    const chatId = !isEmpty(chats) ? Object.keys(chats)[0] : null

    const messages = get(payload, 'data.messages', {})
    const messageKeys = isEmpty(messages) ? [] : Object.keys(messages)
    const lastMessageId = isEmpty(messageKeys)
      ? null
      : get(messages, `[${messageKeys[messageKeys.length - 1]}].id`, null)

    const needUpdate =
      !isNull(lastMessageId) &&
      // TODO: never use isEqual unless you knows what are you doing
      !isEqual(
        get(state, `[${chatId}].relationships.message.id`, {}),
        lastMessageId,
      )

    return needUpdate
      ? merge({}, state, {
          [chatId]: {
            relationships: {
              message: {
                data: {
                  id: lastMessageId,
                  type: 'messages',
                },
              },
            },
          },
        })
      : state
  },
  [RECEIVED_CREATED_CHAT]: (state, { payload }) => {
    const {
      data: { chat },
    } = payload
    const newChatId = toString(get(chat, 'id', ''))
    const chatUsers = get(chat, 'users', [])
    const chatName = get(chat, 'name', '')
    const nowTime = new Date().toISOString()
    const fieldsToMerge = {
      updatedAt: nowTime,
      createdAt: nowTime,
      message: null,
      kind:
        chatUsers.length === 2 && isEmpty(chatName)
          ? CHAT_KINDS.private
          : CHAT_KINDS.group,
    }
    return newChatId
      ? merge({}, state, {
          [newChatId]: {
            id: newChatId,
            type: 'chats',
            attributes: merge({}, omit(chat, ['id', 'users']), fieldsToMerge),
            relationships: {
              users: {
                data: map(chatUsers, user => ({ id: user.id, type: 'users' })),
              },
            },
          },
        })
      : state
  },
  [RECEIVED_SYSTEM_MESSAGE]: (state, { payload }) => {
    const {
      data: { message },
    } = payload
    const chatId = get(message, 'chatId', '')
    const user = get(message, 'user', {})
    const systemMessage = get(message, 'content', '')
    const currentChatUsers = get(
      state,
      `${chatId}.relationships.users.data`,
      [],
    )

    if (chatId && !isEmpty(user) && systemMessage) {
      const userId = user.id.toString()
      const userListIndex = findIndex(currentChatUsers, {
        id: `${userId}`,
      })
      const isUserAlreadyExist = userListIndex !== -1
      // if user joinded chat - Add him to chats relationships
      switch (systemMessage) {
        case SYSTEM_MESSAGES.userJoined: {
          if (isUserAlreadyExist) {
            return state
          }

          return mergeWith(
            {},
            state,
            {
              [chatId]: {
                relationships: {
                  users: {
                    data: [
                      ...currentChatUsers,
                      {
                        id: userId,
                        type: 'users',
                      },
                    ],
                  },
                },
              },
            },
            mergeCustomizer,
          )
        }
        // if user left chat - delete him from chats relationships
        case SYSTEM_MESSAGES.userLeft: {
          if (isUserAlreadyExist) {
            return mergeWith(
              {},
              state,
              {
                [chatId]: {
                  relationships: {
                    users: {
                      data: [
                        ...currentChatUsers.slice(0, userListIndex),
                        ...currentChatUsers.slice(
                          userListIndex + 1,
                          currentChatUsers.length,
                        ),
                      ],
                    },
                  },
                },
              },
              mergeCustomizer,
            )
          }
          return state
        }
        default:
          return state
      }
    }
    return state
  },
  [LEFT_CHAT]: createRelationDeleteHandler('users'),
}

export default createReducer(initialState, handlers)

import { useApolloQuery } from 'API/services/Apollo'

import { CustomCvFieldQuery } from './GraphQL'

export function useCustomCvField(
  id: Gateway.Scalars['ID'],
  { skip }: { skip?: boolean } = {},
) {
  const { data, loading, refetch } = useApolloQuery<
    QueryData<'customCvField'>,
    Gateway.QueryCustomCvFieldArgs
  >(CustomCvFieldQuery, {
    variables: { id },
    skip,
  })

  return { customField: data?.customCvField, loading, refetch }
}
